import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { RiAddFill, RiExternalLinkFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { goToTop } from "../../../../components/scroll-to/ScrollTo";
import { getThunkDropdown } from "../../../../store/features/dropdown/dropdown-list/slice";
import {
  addThunkFundDocumentList,
  deleteThunkFundDocument,
  getThunkFundDocumentList,
} from "../../../../store/features/operation/manage-fund-documents/fund-document-list/slice";
import { getThunkFundDocumentTypeDropdown } from "../../../../store/features/operation/manage-fund-documents/fund-document-type/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getSasKeyThunk } from "../../../../store/sas-key/slice";
import {
  convertUTCToLocalDate,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import { isValidUrlRegexNew } from "../../../../utils/investing-approval/multi-investing-account/validation";
import {
  errorToastMessage,
  infoToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

export default function ManageFundDocuments() {
  const dispatch = useAppDispatch();
  const fileRef = useRef<any>(null);
  const toast = useRef<Toast>(null);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [DocName, setDocName] = useState("");
  const [docLink, setDocLink] = useState("");
  const [visibleViewUpload, setVisibleViewUpload] = useState(false);
  // const [documentLinkUpdate, setDocumentLinkUpdate] = useState("");
  const [fundDocumentId, setFundDocumentId] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const stateDropDownLists = useAppSelector((state) => state.dropdownList.data);
  const [contentType, setContentType] = useState("");
  const [loading, setLoading] = useState(false);
  const [exitDate, setExitDate] = useState<any>();
  const [sasKey, setsasKey] = useState("");
  const regexTest = /(.*?).(pdf|png|jpeg|txt|jpg|mp4)$/;
  const listFundDocument = useAppSelector(
    (state) => state.fundDocumentList.data
  );

  const [documentTypeDropdown, setDocumentTypeDropdown] = useState<any>([]);
  const [filterMediaType, setFilterMediaType] = useState<any>([]);
  const [openDailog, setOpenDailog] = useState(false);

  const unfiltereddocumentTypeDropdown = useAppSelector(
    (state) => state.fundDocumentTypeDropdown.data
  );

  const filteredDocumentsTypeDropdown = useAppSelector(
    (state) => state.fundDocumentTypeDropdown.filteredDropdown
  );

  const formik = useFormik({
    initialValues: {
      documentTypeId: 0,
      documentDescription: "",
      documentUrl: "",
      mediaType: "File",
      documentName: "",
      files: undefined,
      disablePublicImgOnEdit: false,
    },
    validate: (data) => {
      const errors: any = {};

      if (!data.documentName) {
        errors.documentName = "Select document name";
      }
      if (!data.documentTypeId) {
        errors.documentTypeId = "Select document type";
      }
      if (data.mediaType === "URL" && !data.documentUrl) {
        errors.documentUrl = "Document url is required";
      }
      if (
        data.mediaType === "URL" &&
        data.documentUrl &&
        !isValidUrlRegexNew(data.documentUrl)
      ) {
        errors.documentUrl = "Enter a valid url";
      }

      if (data.mediaType !== "URL" && !data.files) {
        errors.files = "Select file to upload";
      }
      return errors;
    },
    onSubmit: (data) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      uploadDoc();
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    if (edit === true && formik.values.disablePublicImgOnEdit === false) {
      // filteredDocumentsTypeDropdown
      setDocumentTypeDropdown(filteredDocumentsTypeDropdown || []);
    } else {
      // unfiltereddocumentTypeDropdown
      setDocumentTypeDropdown(unfiltereddocumentTypeDropdown || []);
    }
  }, [
    edit,
    unfiltereddocumentTypeDropdown,
    filteredDocumentsTypeDropdown,
    formik.values.disablePublicImgOnEdit,
  ]);
  const uploadFile = async (e: any) => {
    const file = e.files[0];
    const regex =
      /(.*?).(docx|doc|pdf|xml|bmp|ppt|xls|png|jpeg|xlsx|txt|pptx|rtf|jpg|.mp4|.m4v)$/;

    if (!regex.test(file.name.toLowerCase())) {
      errorToastMessage(
        toast,
        "Upload failed. Please Use Images,Excel,Word,Power points, Plain Text Documents,Video format only  "
      );
      fileRef?.current.clear();
      return;
    }

    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 10) {
      errorToastMessage(
        toast,
        "Upload failed. Please ensure the file size does not exceed 10mb"
      );
      fileRef?.current.clear();
      return;
    }
    setDocName(file.name);
    formik.setFieldValue("files", file);
    infoToastMessage(toast, "Please save the selected file");
    setsasKey("");
    setDocLink(URL.createObjectURL(file));
    setContentType(file.type);
    fileRef?.current.clear();
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const resetState = () => {
    formik.resetForm();
    setFilterMediaType([]);
    setAdd(false);
    setEdit(false);
    setDocName("");
    setDocLink("");
    setFundDocumentId(0);
    setContentType("");
  };
  const uploadDoc = async (fileuploaded?: any) => {
    if (
      (formik.values.files === undefined || formik.values.files === "") &&
      formik.values.documentUrl === ""
    ) {
      errorToastMessage(toast, "Please Select File or url");
      setLoading(false);
      return;
    }

    if (
      formik.values.mediaType !== "" &&
      formik.values.documentName !== "" &&
      formik.values.documentTypeId !== 0
    ) {
      const data = new FormData();

      if (formik.values.mediaType === "URL") {
        data.append("documentLink", formik.values.documentUrl);
      }
      if (formik.values.mediaType === "File") {
        data.append(
          "files",
          formik.values.files === undefined ? " " : formik.values.files
        );
        // data.append("documentLink", documentLinkUpdate);
        data.append("documentLink", docLink);
        data.append("docType", contentType);
      }
      setLoading(true);
      data.append("doNotCompress", "false");
      data.append("documentDesc", formik.values.documentDescription);
      data.append("docExtension", "");
      data.append("mediaType", formik.values.mediaType);
      data.append("id", fundDocumentId.toString());
      data.append("documentName", formik.values.documentName);
      data.append("docTypeId", formik.values.documentTypeId.toString());
      data.append("legalEntityID", fundDropdown.fundid.toString());
      data.append("docFileName", DocName);
      // data.append("productId", "1078");

      try {
        await dispatch(addThunkFundDocumentList(data)).then((response) => {
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (response.meta.requestStatus === "fulfilled") {
            if (response.payload.success === false) {
              errorToastMessage(toast, "File not Uploaded");
            } else {
              successToastMessage(toast, "File Uploaded");
              resetState();
            }
          }
        });
        setLoading(false);
        fileRef?.current.clear();
      } catch (error) {
        // fileRef?.current.clear();
        // formik.resetForm();
        console.log("Opps something went wrong", error);
      }
    } else {
      setLoading(false);
    }
  };
  const generateSASKey = (legalEntityId: any) => {
    return dispatch(getSasKeyThunk(legalEntityId))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          return response.payload.data.sasKey;
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onAddClick = () => {
    formik.resetForm();
    setAdd(true);
    setEdit(false);
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "mediaType") {
      setDocName("");
      setDocLink("");
      setContentType("");
      if (fileRef.current !== null) {
        fileRef?.current.clear();
        formik.setFieldValue("files", "");
      }
    }
    formik.setFieldValue(name, value);
  };
  const checkUrlAllowed = (e: any) => {
    const selectedDocType = documentTypeDropdown.find(
      (type: any) => type.key === e.value
    );
    console.log("selectedDocType", e, selectedDocType);
    if (selectedDocType?.urlToggle !== 1) {
      formik.setFieldValue("mediaType", "File");
      const filteredMediaTypes = stateDropDownLists.mediaTypes.filter(
        (item) => item.value !== "URL"
      );
      setFilterMediaType(filteredMediaTypes);
    } else {
      setFilterMediaType(stateDropDownLists.mediaTypes);
    }
  };
  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = async (id: any) => {
      checkUrlAllowed({ value: `${rowData?.docTypeId}` });
      setDocName("");
      formik.setFieldValue("documentTypeId", rowData.docTypeId.toString());
      let key = "";
      if (
        rowData.docTypeId === 25 ||
        rowData.docTypeId === 26 ||
        rowData.docTypeId === 27 ||
        rowData.docTypeId === 28
      ) {
        formik.setFieldValue("disablePublicImgOnEdit", true);
        setsasKey("");
      } else {
        setLoading(true);
        key = await generateSASKey(fundDropdown.fundid.toString());
        setsasKey(key);
        formik.setFieldValue("disablePublicImgOnEdit", false);
      }
      formik.setFieldValue("documentName", rowData.docName);
      formik.setFieldValue(
        "documentDescription",
        rowData.documentDesc ? rowData.documentDesc : ""
      );
      if (rowData.docType === null) {
        formik.setFieldValue("documentUrl", rowData.documentLink);
        formik.setFieldValue("mediaType", "URL");
      } else {
        // setDocumentLinkUpdate(rowData.documentLink);
        setDocLink(rowData.documentLink);
        formik.setFieldValue("mediaType", "File");
        formik.setFieldValue("files", rowData.documentLink);
      }
      setContentType(rowData.docType);

      setFundDocumentId(rowData.id);
      setDocName(rowData.docFileName ? rowData.docFileName : "");
      setAdd(false);
      setEdit(true);
      goToTop();
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog widthdeletebtn align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={() => handleEdit(rowData.Id)}
          >
            <span className="text-white">Edit</span>
          </Button>
        </div>
      </div>
    );
  };

  const deleteButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleDelete = (id: any) => {
      //
      resetState();
      setFundDocumentId(rowData.id);
      setOpenDialog(true);
      // setDocName("");
      // formik.resetForm();
      // setDocLink("");
      // setEdit(false);
      // setAdd(false);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog widthdeletebtn align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={() => handleDelete(rowData.Id)}
          >
            <span className="text-white">Delete</span>
          </Button>
        </div>
      </div>
    );
  };

  const ViewTemplateButton = (rowData: any) => {
    const viewUploadedFile = (key: any) => {
      setDocLink(rowData.documentLink);
      setVisibleViewUpload(true);
    };

    const openDoc = async (e: any) => {
      e.preventDefault();
      if (
        rowData.name !== "LegalEntityLogo" &&
        rowData.name !== "LegalEntityBanner" &&
        rowData.name !== "LoginSiteImageForWeb" &&
        rowData.name !== "LoginSiteImageForMob"
      ) {
        let key = "";
        setLoading(true);
        key = await generateSASKey(fundDropdown.fundid.toString());
        setsasKey(key);
        viewUploadedFile(key);
      } else {
        setsasKey("");
        viewUploadedFile("");
      }
    };

    return (
      <React.Fragment>
        {rowData.docType !== null ? (
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-text iconButton"
            // severity="danger"
            // onClick={(e) => {
            //   e.preventDefault();
            //   viewUploadedFile(rowData.id);
            // }}
            onClick={(e) => openDoc(e)}
          />
        ) : (
          <NavLink
            to={
              rowData.documentLink === null
                ? ""
                : rowData?.documentLink.startsWith("http")
                ? rowData.documentLink
                : `http://${rowData.documentLink}`
            }
            target={rowData.documentLink === null ? undefined : "_blank"}
            className="icons-Link"
          >
            <div>
              <RiExternalLinkFill />
            </div>
          </NavLink>
        )}
      </React.Fragment>
    );
  };
  const formatDate = (rowData: any) => {
    const formDate: any = convertUTCToLocalDate(
      rowData.uploadedOn,
      "mm/dd/yyyy"
    );
    return formDate;
  };

  const dataTableElements = [
    {
      header: "View Document",
      body: ViewTemplateButton,
      bodyClassName: "text-center",
      style: { minWidth: "130px" },
    },
    {
      field: "docName",
      header: "Document Name",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.docName}>
          {rowData?.docName}
        </div>
      ),
    },
    {
      field: "name",
      header: "Document Type",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.name}>
          {rowData?.name}
        </div>
      ),
    },
    {
      field: "year",
      header: "Year",
      style: { minWidth: "200px" },
    },
    {
      field: "uploadedOn",
      header: "Uploaded On",
      dataType: "date",
      body: formatDate,
      filterElement: dateFilterTemplate,
      style: { minWidth: "200px" },
    },
    {
      field: "documentDesc",
      header: "Document Desc",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.documentDesc}>
          {rowData?.documentDesc}
        </div>
      ),
    },
    {
      // field: "edit",
      header: "",
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
      filter: false,
      style: { minWidth: "150px" },
    },
    {
      // field: "delete",
      header: "",
      body: (rowData: any) => deleteButton(rowData), // Pass rowData to deleteButton
      style: { minWidth: "150px" },
    },
  ];

  const onDelete = () => {
    setLoading(true);
    const deleteParms = {
      id: fundDocumentId,
      legalEntityId: fundDropdown.fundid,
    };
    dispatch(deleteThunkFundDocument(deleteParms)).then((response) => {
      // call the list
      if (response.meta.requestStatus === "rejected") {
        errorToastMessage(toast);
      } else if (response.meta.requestStatus === "fulfilled") {
        if (response.payload._response.success === true) {
          successToastMessage(toast, "Data is deleted successfully");
          setLoading(false);
          setFundDocumentId(0);
        } else {
          errorToastMessage(toast, "Data is not deleted");
        }
        setLoading(false);
      }
    });

    setOpenDialog(false);
  };
  const onCancel = () => {
    setFundDocumentId(0);
    setOpenDialog(false);
  };
  useEffect(() => {
    setExitDate(getListDateConversion(listFundDocument, ["uploadedOn"]));
  }, [listFundDocument]);

  useEffect(() => {
    setLoading(true);
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameters = {
        legalEntityID: fundDropdown.fundid,
        supplierID: 0,
      };

      dispatch(getThunkDropdown(parameters));
      dispatch(getThunkFundDocumentList(parameters)).finally(() => {
        setLoading(false);
      });
      setExitDate([]);
      resetState();
    }
  }, [fundDropdown]);

  // useEffect(() => {
  //   document.body.classList.add("no-scroll");
  //   document.body.style.overflowX = "unset";
  //   document.documentElement.style.overflowX = "unset";
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //     document.body.style.overflowX = "hidden";
  //     document.documentElement.style.overflowX = "hidden";
  //   };
  // }, []);

  useEffect(() => {
    if (profileData !== null && profileData !== undefined) {
      const parameter = {
        UserName: profileData.emailID,
        // UserTypeId: 0,
        UserId: profileData.userID,
      };
      dispatch(getThunkFundDocumentTypeDropdown(parameter));
    }
  }, [profileData]);

  const openDoc = async (e: any) => {
    e.preventDefault();
    // let key = "";
    // setLoading(true);
    // key = await generateSASKey(fundDropdown.fundid.toString());
    // setsasKey(key);
    setVisibleViewUpload(true);
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Dialog
        header=""
        maximizable
        visible={visibleViewUpload}
        style={window.innerWidth > 600 ? { width: "50%" } : { width: "100%" }}
        // className=".dialog2-width"
        onHide={() => {
          setVisibleViewUpload(false);
        }}
        closable={true}
      >
        <div className="m-0">
          {!docLink.includes("jpeg") &&
          !docLink.includes("jpg") &&
          !docLink.includes("png") &&
          !docLink.includes("pdf") &&
          !docLink.includes("mp4") &&
          !docLink.includes("txt") &&
          !regexTest.test(DocName ? DocName.toLowerCase() : "") ? (
            <div>The File is Downloading!!!</div>
          ) : (
            <></>
          )}

          <iframe
            src={docLink + sasKey}
            allowFullScreen
            style={{ width: "100%", height: "80vh" }}
          ></iframe>
        </div>
      </Dialog>

      <ConfirmDailogbox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onConfirm={onDelete}
        onCancel={onCancel}
        textContent="Are you sure you want to delete ?"
      />

      <div className="grid">
        <Toast ref={toast} className="themeToast" />

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Manage Fund Documents</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                exitDate === undefined ||
                exitDate?.length === 0 ||
                exitDate === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
        <div className="grid col-12">
          <div className="col-12">
            <div className="card tabWithoutboxshadow">
              {/* <Card> */}
              <div className="grid justify-content-start">
                {" "}
                {!add ||
                  (edit && <div className="tabHeader-wrap mb-3 col-6"></div>)}
                {!add && !edit && (
                  <div className="tabHeader-wrap md:col-6 col-12">
                    <Button
                      onClick={onAddClick}
                      // className={
                      //   showTab ? "tabHeadText Active" : "tabHeadText "
                      // }
                      className="btn-navActive mr-0 m-2"
                    >
                      <span className="ml-auto mr-auto ">
                        {" "}
                        Add Document{" "}
                        <span className="pl-2">
                          <RiAddFill />
                        </span>
                      </span>
                    </Button>
                  </div>
                )}
                {add && (
                  <div className="tabHeader-wrap mb-1 col-6 p-3">
                    <span className="tabHeadText Active"> Add Document</span>
                  </div>
                )}
                {edit && (
                  <div className="tabHeader-wrap mb-1 col-6 p-3">
                    <span className="tabHeadText Active"> Edit Document</span>
                  </div>
                )}
              </div>
              {add || edit ? (
                <>
                  <div className="">
                    <div className="formgrid grid px-2">
                      <div className="d-block sm:d-flex sm:flex-column col-4 sm:col-6 mb-2">
                        <label className="inputLabel">Document Name *</label>
                        <InputText
                          name="documentName"
                          value={formik.values.documentName}
                          onChange={(e) => handleChange(e)}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("documentName"),
                            "card-inputBox": true,
                            "p-filled": true,
                          })}
                          placeholder="Enter document name*"
                        />
                        {getFormErrorMessage("documentName")}
                      </div>
                      <div className="d-flex flex-column col-4 sm:col-6 ">
                        <label className="inputLabel">Document Type *</label>
                        <Dropdown
                          inputId="documentTypeId"
                          name="documentTypeId"
                          value={formik.values.documentTypeId}
                          options={
                            documentTypeDropdown === null
                              ? []
                              : documentTypeDropdown
                          }
                          filter
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select document type*"
                          onChange={(e) => {
                            checkUrlAllowed(e);
                            handleChange(e);
                          }}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("documentTypeId"),
                            "card-inputBox": true,
                          })}
                        />
                        {getFormErrorMessage("documentTypeId")}
                      </div>
                      <div className="d-flex flex-column col-4 sm:col-6 ">
                        <label className="inputLabel">Media *</label>
                        <Dropdown
                          inputId="mediaType"
                          name="mediaType"
                          disabled={filterMediaType.length === 0}
                          value={formik.values.mediaType}
                          options={
                            stateDropDownLists === null
                              ? []
                              : filterMediaType.length > 0
                              ? filterMediaType
                              : stateDropDownLists.mediaTypes
                          }
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select media type*"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("mediaType"),
                            "card-inputBox": true,
                          })}
                        />
                        {getFormErrorMessage("mediaType")}
                      </div>
                    </div>

                    <div className="formgrid grid px-2">
                      <div className="d-flex flex-column col-4 sm:col-6 justify-content-center">
                        {formik.values.mediaType === "URL" ? (
                          <>
                            <label className="inputLabel">Document Url *</label>
                            <InputText
                              name="documentUrl"
                              value={formik.values.documentUrl}
                              onChange={(e: any) => {
                                handleChange(e);
                              }}
                              className={classNames({
                                "p-invalid": isFormFieldInvalid("documentUrl"),
                                "card-inputBox": true,
                              })}
                              placeholder="Enter Document Url"
                            />

                            {getFormErrorMessage("documentUrl")}
                          </>
                        ) : (
                          <>
                            <label className="inputLabel">&nbsp;</label>
                            <FileUpload
                              mode="basic"
                              name="demo"
                              ref={fileRef}
                              auto={true}
                              // maxFileSize={10_000_000}
                              customUpload={true}
                              chooseLabel="Select File"
                              className="btn-dialog border-round-md w-100 UploadAccreBtn mb-1"
                              uploadHandler={uploadFile}
                            />
                            <div className="d-flex flex-column">
                              {DocName === "" || DocName === undefined ? (
                                getFormErrorMessage("files")
                              ) : (
                                <label
                                  className="inputLabel fs-6 mb-1"
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  File Name:{" "}
                                  <NavLink
                                    className="document-link"
                                    onClick={(e) => openDoc(e)}
                                    to={""}
                                    title={DocName}
                                  >
                                    {DocName}
                                  </NavLink>
                                </label>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="d-flex flex-column col-4 sm:col-6">
                        <label className="inputLabel">
                          Document Description
                        </label>

                        <InputText
                          name="documentDescription"
                          value={formik.values.documentDescription}
                          onChange={(e) => handleChange(e)}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid(
                              "documentDescription"
                            ),
                            "card-inputBox": true,
                            "p-filled": true,
                          })}
                          placeholder="Enter document description"
                        />
                        {getFormErrorMessage("documentDescription")}
                      </div>
                      <div className="d-flex  col-2 sm:col-6 align-items-center">
                        <Button
                          className=" btn-nav w-full justify-content-center"
                          onClick={(e) => {
                            e.preventDefault();
                            resetState();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="d-flex  col-2 sm:col-6 align-items-center">
                        <Button
                          type="submit"
                          className="btn-navActive w-full justify-content-center"
                          onClick={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    </div>

                    <div className="formgrid grid"></div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className=" col-12">
                <DataTableComponent
                  valueData={exitDate || []}
                  fieldsElements={dataTableElements}
                  isPaginationAllowed={true}
                  scrollable={true}
                  className="gridcell deletebtn"
                  columnResizeMode="expand"
                  maxHeight={"calc(-250px + 100vh)"}
                  isFullView={true}
                  setOpenDailog={setOpenDailog}
                  openDailog={openDailog}
                  resizableColumns={true}
                />
              </div>
              {/* </Card> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
