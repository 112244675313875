import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getEarningsDataPooledFundsService,
  getReverseDataPooledfundsService,
} from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IGetEarningsState } from "./type";

const initialState: IGetEarningsState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkEarningsDataContinuous = createAsyncThunk(
  "getEarningsDataContinuous/getData",
  async (data: any) => {
    try {
      const response = await getEarningsDataPooledFundsService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const getThunkReverseDataContinuous = createAsyncThunk(
  "getEarningsDataContinuous/reverseData",
  async (data: any) => {
    try {
      const response = await getReverseDataPooledfundsService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getEarningsDataContinuous",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkEarningsDataContinuous.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getThunkEarningsDataContinuous.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log("hr", action.payload._response);
        if (action.payload._response.data !== null) {
          state.data = action.payload._response.data.output;
        }
        state.success = action.payload._response.success;
        state.message = action.payload._response.message;
      }
    );
    builder.addCase(
      getThunkEarningsDataContinuous.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
        // throw new Error(error(action.error));
      }
    );
    builder.addCase(getThunkReverseDataContinuous.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getThunkReverseDataContinuous.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log("hr", action.payload._response);
        if (action.payload._response.data !== null) {
          state.data = action.payload._response.data.output;
        }
        state.success = action.payload._response.success;
        state.message = action.payload._response.message;
      }
    );
    builder.addCase(getThunkReverseDataContinuous.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      state.error = error(action.payload);
      throw new Error(state.error);
    });
  },
});

export default slice.reducer;
