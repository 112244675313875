export const initialAddFolderState = {
  containerName: "",
  LegalEntityId: 0,
  ParentFolderFolderName: "",
  NewFolderName: "",
  ParentFolderID: 0,
  TypeId: 0,
  Description: "",
  IsShared: false,
};
