// import { IOpportunity, ITenantDetail } from "../../types";
import apiFactory from "../../utils/api";
import { envVariable as envVar } from "../../utils/sponsor/helper";

export function getBlobSasKeyService(LegalEntityId: any) {
  return apiFactory().get(
    `${
      envVar!.REACT_APP_API_ASSETS_SPNG_URL
    }/Assets/GetSASKey?LegalEntityId=${LegalEntityId}`
  );
}
