import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";

// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";
import DataTableComponent from "../../../components/data-table/DataTableComponent";

import Loader from "../../../components/loader/Loader";
import { GetDailyAccountValueThunk } from "../../../store/features/accounting/dailyamu/getdailyaccountvalue/slice";
import { GetStartDateThunk } from "../../../store/features/accounting/dailyamu/getstartdate/slice";
import { ProcessDailyAccountThunk } from "../../../store/features/accounting/dailyamu/processdailyaccount/slice";
import { getThunkPortfolioRegisterInvestorListDropdown } from "../../../store/features/dropdown/registeredinvestorlist/slice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import currencyFormat from "../../../utils/currency-formatter";
import {
  convertLocalDateToUTC,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../utils/date-formatter";

import { exportExcel } from "../../../utils/excel-dataformat";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function DailyAum() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const endDateDetail: any = useAppSelector((state) => state.GetEndDate.data);
  const investorList = useAppSelector((state) => state.registerinvestor?.data);
  const accountValue = useAppSelector(
    (state) => state.GetDailyAccountValue?.data
  );
  const [accountValueData, setAccountValueData] = useState<any>([]);
  const processedValue = useAppSelector(
    (state) => state.ProcessDailyAccount?.data
  );
  const [click, setClick] = useState<any>(false);

  const [alert, setAlert] = useState(false);
  const [investor, setInvestor] = useState();
  const [investorClick, setInvestorClick] = useState(false);
  const [loading, setloading] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);

  console.log("investor", endDateDetail);

  //   const profileData = useAppSelector((state) => state.profile.data![0]);
  //   const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  // const formatDate = (rowData: any) => {
  //   console.log("rowdata", rowData);
  //   const dateFormat = formatDateMMDDYYY(rowData);
  //   return dateFormat;
  // };

  const formik = useFormik({
    initialValues: {
      StartDate: "",
      EndDate: "",
    },
    validate: (data: any) => {
      const errors: any = {};

      console.log(" validate", data);
      if (!data.StartDate) {
        errors.StartDate = "Please select start date";
      }
      if (!data.EndDate) {
        errors.EndDate = "Please select end date";
      }
      if (new Date(data.EndDate) < new Date(data.StartDate)) {
        errors.EndDate = "End date cannot be before the start date";
      }
      console.log("datestart", data.StartDate);

      return errors;
    },
    onSubmit: async (data: any) => {
      //
      setClick(true);
      setloading(true);
      setInvestorClick(false);

      const formattedEndDate = convertLocalDateToUTC(formik.values.EndDate);

      const req = {
        LegalEntityId: fundDropdown.fundid,
        StartDate:
          endDateDetail !== null
            ? convertLocalDateToUTC(endDateDetail)
            : convertLocalDateToUTC(formik.values.StartDate),
        EndDate: formattedEndDate,
      };

      dispatch(ProcessDailyAccountThunk(req))
        .then((response: any) => {
          setloading(false);
          formik.setFieldValue("EndDate", "");
          formik.setTouched({}, false);

          const a = {
            LegalEntityId: fundDropdown.fundid,
          };
          console.log("inthen", response.payload._response.success);

          successToastMessage(toast, "Saved successfully");
          dispatch(GetStartDateThunk(a))
            .then((res: any) => {
              setloading(false);
              if (res.payload._response.data.startDate) {
                const date = new Date(res.payload._response.data.startDate);
                formik.setFieldValue("StartDate", date!);
                console.log("ormik.values.StartDate", date);
              } else {
                //
              }
            })
            .catch(() => {
              setloading(false);
              console.log("inthen --catch 1");
            });
        })
        .catch(() => {
          setloading(false);
          errorToastMessage(toast);
          console.log("inthen --catch");

          const b = {
            LegalEntityId: fundDropdown.fundid,
          };
          dispatch(GetStartDateThunk(b))
            .then((res: any) => {
              setloading(false);
              if (res.payload._response.data.startDate) {
                const date = new Date(res.payload._response.data.startDate);
                formik.setFieldValue("StartDate", date!);
                console.log("ormik.values.StartDate", date);
              } else {
                console.log("==============else=========== 1");
              }
            })
            .catch(() => {
              setloading(false);
            });
        })
        .finally(() => {
          setloading(false);
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  console.log("datestart", formik.values.StartDate);

  useEffect(() => {
    if (fundDropdown.fundid) {
      setloading(true);

      const req = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetStartDateThunk(req))
        .then((res: any) => {
          setloading(false);
          setClick(false);
          setInvestorClick(false);
          if (res.payload._response.data.startDate) {
            const date = new Date(res.payload._response.data.startDate);
            console.log("=======data===", res.payload._response.data.startDate);
            formik.setFieldValue("StartDate", date);
          } else {
            console.log("==============else=========== 2");
            formik.setFieldValue("StartDate", "");
          }
          formik.setFieldValue("EndDate", "");
        })
        .catch(() => {
          setloading(false);
        });

      dispatch(getThunkPortfolioRegisterInvestorListDropdown(req));
    }
  }, [fundDropdown.fundid]);

  const onInvestorSelect = (idData: any) => {
    const data = {
      LegalEntityId: fundDropdown.fundid,
      PartnerId: idData!,
    };
    dispatch(GetDailyAccountValueThunk(data));
  };

  // useEffect(() => {
  //   // console.log("useEffect EndDate", new Date(endDateDetail));
  //   if (endDateDetail) {
  //     const date = new Date(endDateDetail);
  //     formik.setFieldValue("StartDate", date);
  //   }
  // }, [endDateDetail]);

  const dataTableElements = [
    {
      field: "transactionDate",
      header: "Transaction Date",
      dataType: "date",
      body: (rowData: any) =>
        formatDateMMDDYYY(new Date(rowData.transactionDate)),
      filterElement: dateFilterTemplate,
    },

    {
      field: "dailyAccountValue",
      header: "Daily Account Value",
      body: (rowData: any) => currencyFormat(rowData.dailyAccountValue),
    },
  ];
  const exportExcelHeader = [
    {
      field: "transactionDate",
      header: "Transaction Date",
      dataType: "date",
    },
    {
      field: "dailyAccountValue",
      header: "Daily Account Value",
      dataType: "currency",
    },
  ];

  useEffect(() => {
    if (accountValue !== undefined && accountValue.length > 0) {
      setAccountValueData(
        getListDateConversion(accountValue, ["transactionDate"])
      );
    } else {
      setAccountValueData([]);
    }
  }, [accountValue]);

  return (
    <>
      <div className="grid">
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Daily AUM</h1>
          </div>
          <div className=" col-12 md:col-3 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(
                  exportExcelHeader,
                  processedValue || accountValueData || [],
                  "Daily AUM",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                (processedValue === undefined ||
                  processedValue?.length === 0 ||
                  processedValue === null) &&
                (accountValueData === undefined ||
                  accountValueData.length === 0 ||
                  accountValueData === null)
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center gap-2 ml-2 mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  (processedValue === undefined ||
                    processedValue?.length === 0 ||
                    processedValue === null) &&
                  (accountValueData === undefined ||
                    accountValueData.length === 0 ||
                    accountValueData === null)
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />

        <Dialog
          visible={alert}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setAlert(false)}
        >
          <div className=" align-self-center flex align-items-center justify-content-center">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setAlert(false);
                formik.resetForm();
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Ok
            </Button>
          </div>
        </Dialog>
        <div className="col-12">
          <Card>
            <h4 className="cardHeaderWithoutIcon mb-2">Calculate Daily AUM</h4>
            <div className="formgrid grid px-2">
              <div className="d-block sm:d-flex sm:flex-column md:col-6 col-5 ">
                <label className="inputLabel">Start Date *</label>

                <Calendar
                  name="StartDate"
                  value={formik.values.StartDate}
                  onChange={(e) => {
                    formik.setFieldValue("StartDate", e.value);
                    console.log("convertLocalDateToUTC", e.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("StartDate"),
                    "card-inputBox": true,
                  })}
                  // dateFormat="mm-dd-yy"
                  dateFormat="mm/dd/yy"
                  disabled={endDateDetail!}
                  placeholder="Start Date*"
                />
                {getFormErrorMessage("StartDate")}
              </div>
              <div className="d-block sm:d-flex sm:flex-column md:col-6 col-5 ">
                <label className="inputLabel">End Date *</label>

                <Calendar
                  name="EndDate"
                  value={formik.values.EndDate}
                  onChange={(e) => {
                    // const selectedYear = new Date(
                    //   Number(e.value)
                    // ).getFullYear();
                    // formik.setFieldValue("EndDate", selectedYear);
                    formik.setFieldValue("EndDate", e.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("EndDate"),
                    "card-inputBox": true,
                  })}
                  // dateFormat="mm-dd-yy"
                  dateFormat="mm/dd/yy"
                  viewDate={new Date()}
                  placeholder="End Date*"
                />
                {getFormErrorMessage("EndDate")}
              </div>
              <div className="d-flex sm:d-flex sm:flex-column align-items-center justify-content-center col-2">
                <label className="inputLabel"></label>
                <Button
                  className="blueButtonShadow w-9rem "
                  onClick={(e) => {
                    e.preventDefault();
                    // setIsFormSubmitted(true);
                    console.log("clicked");
                    formik.handleSubmit();
                  }}
                >
                  Calculate{" "}
                </Button>
              </div>
            </div>
            <h4 className="cardHeaderWithoutIcon">Review Daily AUM</h4>
            <div className="formgrid grid px-2">
              <div className="d-block sm:d-flex sm:flex-column md:col-6 col-12 ">
                <label className="inputLabel">Investing account</label>
                <Dropdown
                  name="Investor"
                  style={{ width: "100%" }}
                  options={investorList}
                  optionLabel="investorName"
                  optionValue="investorID"
                  placeholder="Select Investing account"
                  onChange={(e) => {
                    setInvestor(e.value);
                    setClick(false);
                    onInvestorSelect(e.value);
                    setInvestorClick(true);
                  }}
                  value={investor}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Investor"),
                    "card-inputBox": true,
                  })}
                />
              </div>
            </div>
          </Card>

          <div className="card my-3 tabWithoutboxshadow">
            {click && (
              <>
                {" "}
                <Card>
                  <DataTableComponent
                    valueData={processedValue!}
                    fieldsElements={dataTableElements}
                    excelFieldsElements={exportExcelHeader}
                    isPaginationAllowed={true}
                    fileName="Daily AUM"
                    className="gridcell deletebtn"
                    columnResizeMode="expand"
                    resizableColumns={true}
                    openDailog={openDailog}
                    setOpenDailog={setOpenDailog}
                    scrollable={true}
                    maxHeight={"calc(-180px + 100vh)"}
                  />
                </Card>
              </>
            )}
            {investorClick && investor && (
              <>
                <Card>
                  <DataTableComponent
                    valueData={accountValueData || []}
                    fieldsElements={dataTableElements}
                    isPaginationAllowed={true}
                    excelFieldsElements={exportExcelHeader}
                    fileName="Daily AUM"
                    className="gridcell deletebtn"
                    columnResizeMode="expand"
                    resizableColumns={true}
                    openDailog={openDailog}
                    setOpenDailog={setOpenDailog}
                    scrollable={true}
                    maxHeight={"calc(-180px + 100vh)"}
                  />
                </Card>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
