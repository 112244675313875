import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { addInvestmentStep1Service } from "../../../../services/investment/investment";
import {
  addInvestmentService,
  getInvestmentByIdService,
} from "../../../../services/investment/investment";
import error from "../../../../utils/error";
import { initialInvestmentState } from "../create/payload";
import { IAddInvestmentState } from "./type";

const initialState: IAddInvestmentState = {
  success: false,
  message: "",
  loading: "loading",
  result: undefined,
  error: "",
  data: initialInvestmentState,
};
export const addThunkInvestment = createAsyncThunk(
  "investment/createInvestment",
  async (data: any) => {
    console.log("slice wala code  -", data);
    try {
      const response = await addInvestmentService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const getThunkInvestmentById = createAsyncThunk(
  "investment/getInvestmentById",
  async (data: any) => {
    try {
      const response = await getInvestmentByIdService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "investment",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      state.data = action.payload;
    },
    // setFormInitial: (state, action) => {
    //   console.log("op", action.payload);
    //   state = action.payload;
    //   console.log("s", state.data);
    //   //
    // },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(addThunkInvestment.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(addThunkInvestment.fulfilled, (state, action) => {
      state.loading = "succeeded";

      if (action.payload.data !== null) {
        state.data = action.payload.data.productDetails[0];
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(addThunkInvestment.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
      throw new Error(error(state.error));
    });

    builder.addCase(getThunkInvestmentById.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkInvestmentById.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("d", action.payload);
      if (action.payload.data !== null) {
        // state.data = action.payload.data.prodductDetails[0][0];
        state.data = action.payload.data.investorDetails[0];
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(getThunkInvestmentById.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
      throw new Error(error(state.error));
    });
  },
});
export const { setFormData } = slice.actions;
export default slice.reducer;
