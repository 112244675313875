import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { getOpportunityImagesDataService } from "../../../services/opportunity/opportunity";
import { IOpportunityOpportunityImages } from "../../../types/index";
import error from "../../../utils/error";

interface OpportunityImagesState {
  Data?: IOpportunityOpportunityImages[] | [];
  loading: string;
  error?: string | null;
}
const initialState: OpportunityImagesState = {
  Data: [],
  loading: "loading",
  error: undefined,
};
export const getOpportunityImagesDataThunk = createAsyncThunk(
  "opportunity/getOpportunityImagesData",
  async (id: number) => {
    try {
      const response = await getOpportunityImagesDataService(id);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "opportunityImageData",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getOpportunityImagesDataThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      getOpportunityImagesDataThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.Data = action.payload.data;
      }
    );
    builder.addCase(getOpportunityImagesDataThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export const getOpportunityImageData = (state: RootState) => {
  return state.opportunityImageData.Data;
};

// else {
//   const iMG = state.opportunityImageData.Data;
//   const filterImg = iMG!.filter((D) => {
//     return D.productLink !== null;
//   });
//   return filterImg;
// const images = useSelector(state => Object.keys(state.Reducer.images)
// .filter(x => x === someID)
// .reduce((arr, key) => {
//   arr.push(state.Reducer.images[key].data);
//   return arr;
// }, []), shallowEqual);
export const getOpportunityImageDataStatus = (state: RootState) =>
  state.opportunityImageData.loading;
// export const getAllOpportunitiesError = (state: RootState) =>
//   state.opportunity.error;

export default slice.reducer;
