import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUploadedAccredDocumentLink } from "../../../../../../services/investor/investor";
import error from "../../../../../../utils/error";
import {
  IUploadAccredDocumentPayload,
  IUploadedAccredDocumentLinkResponse,
} from ".";

interface IEsignDocument {
  data?: string;
  legalEntityID?: number;
  loading: string;
  error?: string | null;
  other?: IUploadedAccredDocumentLinkResponse;
}

const initialState: IEsignDocument = {
  data: undefined,
  loading: "loading",
  legalEntityID: undefined,
  error: undefined,
  other: undefined,
};
export const getUploadedAccredDocumentLinkThunk = createAsyncThunk(
  "accredutation/getUploadedAccredDocumentLink",
  async (data: IUploadAccredDocumentPayload) => {
    try {
      const response = await getUploadedAccredDocumentLink(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "accredutation/getUploadedAccredDocumentLink",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getUploadedAccredDocumentLinkThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      getUploadedAccredDocumentLinkThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data
          ? action.payload._response.data.dataresult.accreddDocumentLink
          : undefined;
        state.legalEntityID = action.payload._response.data
          ? action.payload._response.data.dataresult.legalEntityID
          : undefined;

        state.other = action.payload._response.data
          ? action.payload._response.data.dataresult
          : undefined;
      }
    );
    builder.addCase(
      getUploadedAccredDocumentLinkThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
