import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetPartnerLevelCashDistributionsDetailsService } from "../../../../../services/taxmanagement/tax";
import error from "../../../../../utils/error";
import {
  IGetPartnerLevelCashDistributionsDetailsState,
  IGetPartnerLevelCashDistributionsDetailsparameters,
} from ".";

const initialState: IGetPartnerLevelCashDistributionsDetailsState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  data1: "",
};
export const GetPartnerLevelCashDistributionsDetailsThunk = createAsyncThunk(
  "TaxManagement/GetPartnerLevelCashDistributionsDetails",
  async (data: IGetPartnerLevelCashDistributionsDetailsparameters) => {
    try {
      const response = await GetPartnerLevelCashDistributionsDetailsService(
        data
      );
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "TaxManagement/GetPartnerLevelCashDistributionsDetails",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(
      GetPartnerLevelCashDistributionsDetailsThunk.pending,
      (state) => {
        state.loading = "loading";
        //   state.error = undefined;
      }
    );
    builder.addCase(
      GetPartnerLevelCashDistributionsDetailsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data
          ? action.payload._response.data[0]?.[0]?.result
          : [];
        state.data1 = action.payload._response.data
          ? action.payload._response.data[1]?.[0]?.result
          : [];
        state.data2 = action.payload._response.data
          ? action.payload._response.data[2]?.[0]?.result
          : [];
      }
    );
    builder.addCase(
      GetPartnerLevelCashDistributionsDetailsThunk.rejected,
      (state) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
