import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEarningsErrataReportService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";
import { IEarningsErrataReportState } from ".";

const initialState: IEarningsErrataReportState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  TotalAmountdata: [],
};
export const getEarningsErrataReportCommits = createAsyncThunk(
  "earningserratareport",
  async (data: any) => {
    try {
      const response = await getEarningsErrataReportService(data);
      console.log("res", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "earningserratareport",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getEarningsErrataReportCommits.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getEarningsErrataReportCommits.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data
          ? action.payload._response.data[1]
          : [];
        state.TotalAmountdata = action.payload._response.data
          ? action.payload._response.data[0]
          : [];
      }
    );
    builder.addCase(
      getEarningsErrataReportCommits.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
  },
});
// export const { resetEarningsErrataReport } = slice.actions;

export default slice.reducer;
