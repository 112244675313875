import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addInvestorDocumentService,
  deleteInvestorDocumentService,
  getInvestorDocumentListService,
} from "../../../../../services/investor/investor";
import error from "../../../../../utils/error";
import { IInvestorDocumentListState, IInvestorParam } from ".";

const initialState: IInvestorDocumentListState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkInvestorDocumentList = createAsyncThunk(
  "InvestorDocument/get",
  async (data: IInvestorParam) => {
    try {
      const response = await getInvestorDocumentListService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const addThunkInvestorDocument = createAsyncThunk(
  "InvestorDocument/add",
  async (data: any) => {
    try {
      const response = await addInvestorDocumentService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const deleteThunkInvestorDocument = createAsyncThunk(
  "InvestorDocument/delete",
  async (data: any) => {
    try {
      const response = await deleteInvestorDocumentService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "InvestorDocument",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkInvestorDocumentList.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkInvestorDocumentList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("After slice response", action.payload);
      state.data =
        action.payload._response.data !== null
          ? action.payload._response.data
          : [];
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(getThunkInvestorDocumentList.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });

    builder.addCase(addThunkInvestorDocument.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(addThunkInvestorDocument.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      state.data =
        action.payload._response.data !== null
          ? action.payload._response.data
          : [];
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(addThunkInvestorDocument.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
    builder.addCase(deleteThunkInvestorDocument.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(deleteThunkInvestorDocument.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      // state.data =
      //   action.payload._response.data !== null
      //     ? action.payload._response.data
      //     : [];
      // state.success = action.payload._response.success;
      // state.message = action.payload._response.message;
    });
    builder.addCase(deleteThunkInvestorDocument.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
