import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSetupESignListService } from "../../../../../services/e-sign/e-sign-ondemand";
import error from "../../../../../utils/error";
import { ISetUpSignState } from ".";

const initialState: ISetUpSignState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkSetupEsignList = createAsyncThunk(
  "setupEsign",
  async (data: any) => {
    try {
      const response = await getSetupESignListService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "setupEsign",
  initialState,
  reducers: {
    setForm: (state, action) => {
      state.data = action.payload;
      //
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkSetupEsignList.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkSetupEsignList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("set", action.payload);
      state.data = action.payload.data !== null ? action.payload.data : [];
      state.message =
        action.payload.message === null ? undefined : action.payload.message;

      // console.log("state.data 1", state.data);
    });
    builder.addCase(getThunkSetupEsignList.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});
export const { setForm } = slice.actions;
export default slice.reducer;
