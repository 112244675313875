import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AddInvestmentReturnServicePooledFund } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IAddInvestmentReturnState } from "./type";

const initialState: IAddInvestmentReturnState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: undefined,
};
export const addThunkInvestmentReturnPooledFund = createAsyncThunk(
  "addInvestmentReturnPooledFunds",
  async (data: any) => {
    try {
      console.log("data", data);
      const response = await AddInvestmentReturnServicePooledFund(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "addInvestmentReturnPooledFunds",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(addThunkInvestmentReturnPooledFund.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      addThunkInvestmentReturnPooledFund.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log("hr", action.payload._response);
        //   state.data =
        //     action.payload._response.data !== null
        //       ? action.payload._response.data.output
        //       : [];
        if (action.payload._response.data !== null) {
          state.data = action.payload._response.data;
        }
        state.success = action.payload._response.success;
        state.message = action.payload._response.message;
      }
    );
    builder.addCase(
      addThunkInvestmentReturnPooledFund.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
        throw new Error(error(action.error));
      }
    );
  },
});

export default slice.reducer;
