import { Accordion, AccordionTab } from "primereact/accordion";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../components/loader/Loader";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addPoolFundsActions } from "../../../store/poolfunds/add-pool-funds-state";
import { resetAllInvesterListPoolFund } from "../../../store/poolfunds/get-all-Investor-list-pool-funds";
import {
  getAllocatedInvestorsByInvestmentPoolfundsThunk,
  resetAllocaterInvestoresByInvestmentPoolFund,
} from "../../../store/poolfunds/get-allocatedinvestors-Investment-poolfunds";
import StepProgressBar from "../StepProgressBar";
import AllocatedInvestor from "./AllocatedInvester";
import CurrentAllocation from "./CurrentAllocation";
import PoolFundStep1 from "./Step1";
import PoolFundStep2 from "./Step2";
import PoolFundStep3 from "./Step3";

const subLabels: { [key: number]: string } = {
  1: "Select Investment and Investor Account(s)",
  2: "Select Dates",
  3: "Allocate Units",
};
const mainLabels: { [key: number]: string } = {
  1: "Select Investment and Investor Account(s)",
  2: "Allocation Dates",
  3: "Allocate Units",
};
const AddPoolFunds = () => {
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<any>([0, 1]);
  const totalSteps = 3;
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const addPoolFundData = useAppSelector((state) => state.AddPoolFunds.data);
  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(1);
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prevState) => (prevState === 1 ? 1 : prevState - 1));
  };
  const getAllAllocatedInvesterPoolFundData = () => {
    setLoading(true);
    const parameter = {
      legalEntityId: fundDropdown.fundid,
      investmentId: addPoolFundData.investmentId,
    };
    dispatch(getAllocatedInvestorsByInvestmentPoolfundsThunk(parameter))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    if (
      fundDropdown.fundid !== undefined &&
      fundDropdown.fundid > 0 &&
      addPoolFundData.investmentId !== 0 &&
      addPoolFundData.investmentId !== undefined
    ) {
      getAllAllocatedInvesterPoolFundData();
    }
  }, [addPoolFundData.investmentId, tabIndex]);

  const onMountFn = () => {
    setCurrentStep(1);
    dispatch(addPoolFundsActions.resetAddPoolFundData());
    dispatch(resetAllInvesterListPoolFund());
    dispatch(resetAllocaterInvestoresByInvestmentPoolFund());
  };
  useEffect(() => {
    onMountFn();
    return () => {
      onMountFn();
    };
  }, [fundDropdown.fundid]);
  return (
    <>
      {loading ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader"> Allocations</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <div className="grid">
        <div className="grid pt-0 col-12">
          <div className="col-12 pt-0 pr-0">
            <div className="card my-3 tabWithoutboxshadow">
              <Card>
                <StepProgressBar
                  subLabel={subLabels[currentStep]}
                  mainLabel={mainLabels[currentStep]}
                  currentStep={currentStep}
                  noOfSteps={totalSteps}
                  isCapital={false}
                />
                {currentStep === 1 && (
                  <PoolFundStep1
                    toast={toast}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                  />
                )}
                {currentStep === 2 && (
                  <PoolFundStep2
                    toast={toast}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                  />
                )}
                {currentStep === 3 && (
                  <PoolFundStep3
                    toast={toast}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                  />
                )}
                <div id="allocatedInvestor">
                  <Accordion
                    className="p-0"
                    multiple={true}
                    activeIndex={tabIndex}
                    onTabChange={(e) => setTabIndex(e.index)}
                  >
                    <AccordionTab header="Current Allocations">
                      <CurrentAllocation />
                    </AccordionTab>
                    <AccordionTab header="Allocations History">
                      <AllocatedInvestor />
                    </AccordionTab>
                  </Accordion>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPoolFunds;
