import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { getPlaidLinkTokenService } from "../../../../services/finance/finance";
// import { checkOTPACHService } from "../../../../services/finance/finance";
// import { ILegalEntityAndPartnerID } from "../../../../types/index";
import { checkOTPACHService } from "../../../../services/Dwolla/dwolla";
import { ICheckACHOTPSParameter } from "../../../../types";
import error from "../../../../utils/error";

interface IPlaidLinkToken {
  data: string;
  loading: string;
  error?: string | null;
}

const initialState: IPlaidLinkToken = {
  data: "",
  loading: "loading",
  error: undefined,
};

export const checkOTPACHThunk = createAsyncThunk(
  "finance/opt/checkOTPACH",
  async (data: ICheckACHOTPSParameter) => {
    try {
      const response = await checkOTPACHService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "finance/opt/checkOTPACH",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(checkOTPACHThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(checkOTPACHThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      //

      state.data = action.payload._response.message;
      // state.currentPage += 1;
    });
    builder.addCase(checkOTPACHThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
