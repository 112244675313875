import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { addBankWithPlaidService } from "../../../../services/finance/finance";
import { addBankWithPlaidService } from "../../../../services/Dwolla/dwolla";
// import { IAddBankWithPlaidRequestBody } from "../../../../types";
import error from "../../../../utils/error";

interface IPlaidLinkToken {
  data: string;
  loading: string;
  error?: string | null;
}

const initialState: IPlaidLinkToken = {
  data: "",
  loading: "loading",
  error: undefined,
};

export const addBankWithPlaidThunk = createAsyncThunk(
  "plaid/addBankWithPlaid",
  async (metaData: any) => {
    try {
      const response = await addBankWithPlaidService(metaData);
      return response.data;
    } catch (error_) {
      console.log(error_);
      // throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "plaid/getPlaidLinkToken",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(addBankWithPlaidThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(addBankWithPlaidThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      //
      state.data = action.payload._response.data;
      // state.currentPage += 1;
    });
    builder.addCase(addBankWithPlaidThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
