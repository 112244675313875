import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadDwollaDocumentsService } from "../../../services/Dwolla/dwolla";
import error from "../../../utils/error";

const initialState: any = {
  success: false,
  message: "",
  loading: "loading",
  data: [],
};
export const uploadDwollaDocumentsThunk = createAsyncThunk(
  "dwollaCompo/uploadDwollaDocuments",
  async (data: any) => {
    try {
      const response = await uploadDwollaDocumentsService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "dwollaCompoUploadDoc",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(uploadDwollaDocumentsThunk.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(uploadDwollaDocumentsThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";

      state.data =
        action.payload.data !== null ? action.payload.data : initialState.data;
    });
    builder.addCase(uploadDwollaDocumentsThunk.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default slice.reducer;
