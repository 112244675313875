import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { postDeleteBankAccountService } from "../../../services/finance/finance";
import { postDeleteBankAccountService } from "../../../services/Dwolla/dwolla";
import { IDeleteBankAccountPayLoad } from "../../../types";
import error from "../../../utils/error";

interface IPostDeleteBankAccount {
  data: [];
  loading: string;
  error?: string | null;
}

const initialState: IPostDeleteBankAccount = {
  data: [],
  loading: "loading",
  error: undefined,
};

export const deleteBankAccountThunk = createAsyncThunk(
  "finance/deleteBankAccount",
  async (data: IDeleteBankAccountPayLoad) => {
    try {
      const response = await postDeleteBankAccountService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "finance/deleteBankAccount",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(deleteBankAccountThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(deleteBankAccountThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";

      state.data = action.payload._response.data;
    });
    builder.addCase(deleteBankAccountThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
      throw state.error;
    });
  },
});

export default slice.reducer;
