import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { getAllColorService } from "../../services/portfolio/portfolio";
// import apiFactory from "../../utils/api";
import { getUserMenuService } from "../../../services/profile/menus/menus";
import error from "../../../utils/error";
import { IUserMenuState } from ".";

const initialState: IUserMenuState = {
  success: false,
  message: "",
  data: [],
  loading: "",
};
// const initialState: IUserMenuState = {
//   success: false,
//   message: "",
//   data: undefined,
// };
export const getThunkUserMenu = createAsyncThunk("menus", async () => {
  try {
    const response = await getUserMenuService();
    return response.data;
  } catch (error_) {
    console.log(error_);
    throw new Error(error(error_));
  }
});

const slice = createSlice({
  name: "menus",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkUserMenu.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkUserMenu.fulfilled, (state, action) => {
      state.loading = "succeeded";
      // console.log("action.payload._response.", action.payload._response.data);

      state.data =
        action.payload._response.data === null
          ? []
          : action.payload._response.data;

      // console.log("menuSlice", state.data);
    });
    builder.addCase(getThunkUserMenu.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
