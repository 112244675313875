import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { ILegalEntityAndPartnerID } from "../../../../types/index";
import { getPartnerInvestorInfoService } from "../../../services/finance/finance";
import { IGetPartnerInvestorInfo } from "../../../types";
import error from "../../../utils/error";

interface IGetPartnerInvestorInfoinitialState {
  data: IGetPartnerInvestorInfo | undefined;
  loading: string;
  error?: string | null;
}

const initialState: IGetPartnerInvestorInfoinitialState = {
  data: undefined,
  loading: "loading",
  error: undefined,
};

export const getPartnerInvestorInfoThunk = createAsyncThunk(
  "finance/getPartnerInvestorInfo",
  async (data: { partnerID: any }) => {
    try {
      const response = await getPartnerInvestorInfoService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "finance/getPartnerInvestorInfo",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getPartnerInvestorInfoThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getPartnerInvestorInfoThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      //
      state.data = action.payload._response.data;
      // state.currentPage += 1;
    });
    builder.addCase(getPartnerInvestorInfoThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
