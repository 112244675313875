// import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DataTableServerComponent from "../../../components/data-table/DataTableServerComponent";
import Loader from "../../../components/loader/Loader";
import { GetEarningTransactionsThunk } from "../../../store/features/operation/searchearningstransactions/getearningtransactions/slice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
// import currencyFormat from "../../../utils/currency-formatter";
import {
  convertUTCToLocalDate,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../utils/date-formatter";

const SearchEarningsTransactions: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  // const [tableParams, setTableParams] = useState({
  //   first: 0,
  //   rows: 20,
  //   page: 0,
  // });
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const accountValue = useAppSelector(
    (state) => state.GetEarningTransactions?.data
  );
  const [loading, setloading] = useState<any>(false);
  const [accountValueState, setAccountValueState] = useState<any>([]);

  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  console.log("accountValue", accountValue);

  useEffect(() => {
    if (fundDropdown.fundid) {
      setloading(true);
      const req = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetEarningTransactionsThunk(req))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    }
  }, [fundDropdown.fundid]);

  const [shouldFreeze, setShouldFreeze] = useState(window.innerWidth >= 768);
  useEffect(() => {
    const handleResize = () => {
      setShouldFreeze(window.innerWidth >= 768);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log(shouldFreeze);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  useEffect(() => {
    if (accountValue !== undefined)
      setAccountValueState(
        getListDateConversion(accountValue, [
          "startDate",
          "endDate",
          "earningsProcessedDate",
        ])
      );
  }, [accountValue]);

  const stateNameWithCountry = (rowData: any) => {
    console.log("tetsts", rowData);
    const name = rowData.usState
      ? rowData.usState +
        (rowData.country && rowData.country !== "United States"
          ? ", " + rowData.country
          : "")
      : "";
    return name;
  };
  console.log("testing", accountValueState);
  const dataTableElements = [
    {
      field: "productID",
      header: "Id",
      // frozen: shouldFreeze && true,
      style: { minWidth: "100px" },
    },

    {
      field: "productName",
      header: "Investment Name",
      // frozen: shouldFreeze && true,
      style: { minWidth: "250px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.productName}>
          {rowData?.productName}
        </div>
      ),
    },
    {
      field: "investmentReturnType",
      header: "Earnings",
      // header: "Investment Return Type",
      // frozen: shouldFreeze && true,
      style: { minWidth: "150px" },
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
      // frozen: shouldFreeze && true,
      // style: { minWidth: "170px" },
      // frozen: shouldFreeze && true,
      style: {
        minWidth: "170px",
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      field: "investorName",
      header: "Investor Name",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.investorName}>
          {rowData?.investorName}
        </div>
      ),
    },
    {
      field: "externalInvestmentID",
      header: "Investment ID",
      // header: "External Investment ID",
      style: { minWidth: "200px" },
    },
    {
      field: "investmentReturnID",
      header: "Return ID ",
      style: { minWidth: "150px" },
    },
    {
      field: "units",
      header: "Units",
      style: { minWidth: "100px" },
    },
    {
      field: "startDate",
      header: "Start Date ",
      style: { minWidth: "150px" },
      dataType: "date",
      body: (rowData: any) => convertUTCToLocalDate(rowData.startDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "endDate",
      header: "End Date ",
      style: { minWidth: "150px" },
      dataType: "date",
      body: (rowData: any) =>
        rowData.endDate !== null && rowData.endDate !== ""
          ? formatDate(rowData.endDate)
          : rowData.endDate,
      filterElement: dateFilterTemplate,
    },
    {
      field: "earningsProcessedDate",
      header: "Processed Date ",
      style: { minWidth: "150px" },
      dataType: "date",
      body: (rowData: any) =>
        rowData.earningsProcessedDate !== null &&
        rowData.earningsProcessedDate !== ""
          ? formatDate(rowData.earningsProcessedDate)
          : rowData.earningsProcessedDate,
      filterElement: dateFilterTemplate,
    },
    {
      field: "daysInvested",
      header: "Days Invested",
      style: { minWidth: "200px" },
    },
    {
      field: "earningsRatePDPU",
      header: "Earning Rate PDPU",
      body: (rowData: any) =>
        rowData.earningsRatePDPU
          ? rowData.earningsRatePDPU.toFixed(2)
          : rowData.earningsRatePDPU,
      style: { minWidth: "200px" },
    },
    {
      field: "earningsShare",
      header: "Earning Share",
      style: {
        minWidth: "150px",
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      field: "productListingAddress",
      header: "Address",
      style: { minWidth: "150px" },
    },
    {
      field: "city",
      header: "City",
      style: { minWidth: "150px" },
    },
    {
      field: "usState",
      header: "State",
      style: { minWidth: "200px" },
      body: stateNameWithCountry,
    },
    {
      field: "zipCode",
      header: "Zip Code",
      style: { minWidth: "150px" },
    },
  ];
  const excelTableHeaders = [
    {
      field: "productID",
      header: "ID",
    },
    {
      field: "productName",
      header: "Investment Name",
    },
    {
      field: "investmentReturnType",
      header: "Earnings",
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
    },
    {
      field: "investorName",
      header: "Investor Name",
    },
    {
      field: "externalInvestmentID",
      header: "Investment ID",
    },
    {
      field: "investmentReturnID",
      header: "Return ID ",
    },
    {
      field: "units",
      header: "Units",
    },
    {
      field: "startDate",
      header: "Start Date ",
      dataType: "date",
    },
    {
      field: "endDate",
      header: "End Date ",
      dataType: "date",
    },
    {
      field: "earningsProcessedDate",
      header: "Processed Date ",
      dataType: "date",
    },
    {
      field: "daysInvested",
      header: "Days Invested",
    },
    {
      field: "earningsRatePDPU",
      header: "Earning Rate PDPU",
    },
    {
      field: "earningsShare",
      header: "Earning Share",
    },
    {
      field: "productListingAddress",
      header: "Address",
    },
    {
      field: "city",
      header: "City",
    },
    {
      field: "usState",
      header: "State",
    },
    {
      field: "zipCode",
      header: "Zip Code",
    },
  ];

  // const onPage = (e: any) => {
  //   console.log("e onpage", e);
  //   setTableParams(e);
  // };

  return (
    <>
      {loading && <Loader />}
      <Toast ref={toast} className="themeToast" />
      <DataTableComponent
        valueData={accountValueState || []}
        scrollable={true}
        columnResizeMode="expand"
        isFullView={true}
        maxHeight={"calc(-178px + 100vh)"}
        HeadingName="Search Earnings Transactions"
        className="gridcell"
        fieldsElements={dataTableElements}
        isPaginationAllowed={true}
        isDownload={accountValue && true}
        // onPage={onPage}
        // tableParams={tableParams}
        // pageCount={accountValue?.pageCount}
        fileName="Search Earnings Transactions"
        excelFieldsElements={excelTableHeaders}
      />
    </>
  );
};

export default SearchEarningsTransactions;
