import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCalculateEarningByPecentageService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";

const initialState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const calculateEarningPercentageThunk = createAsyncThunk(
  "calculateEarningPercentage",
  async (data: any) => {
    try {
      console.log("data", data);
      const response = await getCalculateEarningByPecentageService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "calculateEarningPercentage",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(calculateEarningPercentageThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      calculateEarningPercentageThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log("hr", action.payload._response);
        state.data = action.payload._response.success
          ? action.payload._response.data.output
          : [];

        state.success = action.payload._response.success;
        state.message = action.payload._response.message;
      }
    );
    builder.addCase(
      calculateEarningPercentageThunk.rejected,
      (state, action) => {
        state.loading = "failed";
      }
    );
  },
});

export default slice.reducer;
