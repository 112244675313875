import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
// import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";

import { RiAddFill } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";
import Loader from "../../../../components/loader/Loader";
import { BindUserInformationThunk } from "../../../../store/features/accountatatement/rolemanagement/manageuser/binduser/slice";
// import { DeleteAdminUserThunk } from "../../../../store/features/accountatatement/rolemanagement/manageuser/deleteuser/slice";
// import { ManageUserListThunk } from "../../../../store/features/accountatatement/rolemanagement/manageuser/getmanageuserlist/slice";
// import { InformationByUsernameThunk } from "../../../../store/features/accountatatement/rolemanagement/manageuser/infobyusername/slice";
import { GetInfoByEmailThunk } from "../../../../store/features/accountatatement/rolemanagement/manageuser/infobyemail/slice";
import { SaveUserLoginDetailsThunk } from "../../../../store/features/accountatatement/rolemanagement/manageuser/savemanageuser/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { regexForEmail } from "../../../../utils/sponsor/constants";
import {
  errorToastMessage,
  infoToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function ManageUser() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const GetUserList = useAppSelector(
    (state) => state.BindUserInformation?.data
  );

  const [id, setId] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [editClick, setEditClick] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState<any>(false);
  const [openDialog, setOpenDialog] = useState(false);
  // const [visibleDilog, setVisibleDilog] = useState(false);
  const [dialogData, setDilogData] = useState("");
  const [update, setUpdate] = useState(false);
  // const togglePasswordVisibility = () => {
  //   setShowPassword((prevState) => !prevState);
  // };
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const infoByEmail = useAppSelector((state) => state.getInfoByEmail);
  const [openDailog, setOpenDailog] = useState(false);
  const [isAdd, setIsAdd] = useState(false);

  const param = {
    LegalEntityId: fundDropdown.fundid,
    UserTypeId: 6,
  };
  console.log("InfoEmail", infoByEmail);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      mobile: "",
    },
    validate: (data: any) => {
      //
      const errors: any = {};
      console.log("validation data", data);

      if (!data.email) {
        errors.email = "Please enter email address";
      }
      // if (!data.password) {
      //   errors.password = "Please enter password";
      // }

      if (data.email && !regexForEmail.test(data.email)) {
        errors.email = "Enter valid email";
      }
      if (!data.firstName) {
        errors.firstName = "Please enter first name";
      }
      if (!data.lastName) {
        errors.lastName = "Please enter last name";
      }
      if (!data.mobile) {
        errors.mobile = "Please enter mobile no";
      }
      console.log("errors===", errors);
      console.log("data.mobile===", data.mobile);

      return errors;
    },
    onSubmit: async (data: any) => {
      if (infoByEmail.success) {
        setOpenDialog(true);
      } else {
        const reqBody = {
          action: editClick ? "Update" : "Create",
          displayName: "",
          givenName: formik.values.firstName,
          surname: formik.values.lastName,
          identities: {
            signInType: "",
            issuerAssignedId: formik.values.email
              ? formik.values.email?.trim()
              : formik.values.email,
          },
          userId: editClick ? id : 0,
          password: "",
          legalEntityId: fundDropdown.fundid,
          mobileNo: formik.values.mobile,
          // eslint-disable-next-line unicorn/no-null
          azureObjectId: null,
        };
        dispatch(SaveUserLoginDetailsThunk(reqBody))
          .then((res: any) => {
            //   dispatch(ManageUserListThunk(param));
            dispatch(BindUserInformationThunk(param)).then(() => {
              if (res.payload.message === "Data already exist") {
                setloading(false);
                formik.resetForm();
                infoToastMessage(toast, "User already exist");
                console.log("response", res.payload.message);
              } else {
                setloading(false);
                formik.resetForm();
                successToastMessage(toast, "Data saved successfully");
                console.log("response", res.payload.message);
              }
            });
          })
          .finally(() => {
            setDilogData("");
            setEditClick(false);
            setIsAdd(false);
          });
      }
    },
  });
  const onSave = () => {
    console.log("InSave");
    setOpenDialog(false);
    setloading(true);

    const reqBody = {
      action: editClick ? "Update" : "Create",
      displayName: "",
      givenName: formik.values.firstName,
      surname: formik.values.lastName,
      identities: {
        signInType: "",
        issuerAssignedId: formik.values.email
          ? formik.values.email?.trim()
          : formik.values.email,
      },
      userId: editClick ? id : 0,
      password: "",
      legalEntityId: fundDropdown.fundid,
      mobileNo: formik.values.mobile,
      // eslint-disable-next-line unicorn/no-null
      azureObjectId: null,
    };
    const reqBody2 = {
      action: "Update",
      displayName: "",
      givenName: formik.values.firstName,
      surname: formik.values.lastName,
      identities: {
        signInType: "",
        issuerAssignedId: formik.values.email
          ? formik.values.email?.trim()
          : formik.values.email,
      },
      userId: id,
      password: "",
      legalEntityId: fundDropdown.fundid,
      mobileNo: formik.values.mobile,
      // eslint-disable-next-line unicorn/no-null
      azureObjectId: null,
    };
    const resBody3 = update ? reqBody2 : reqBody;
    dispatch(SaveUserLoginDetailsThunk(resBody3))
      .then((res: any) => {
        //   dispatch(ManageUserListThunk(param));
        dispatch(BindUserInformationThunk(param)).then(() => {
          if (res.payload.message === "Data already exist") {
            setloading(false);
            formik.resetForm();
            infoToastMessage(toast, "User already exist");
            console.log("response", res.payload.message);
          } else {
            setloading(false);
            formik.resetForm();
            successToastMessage(toast, "Data saved successfully");
            console.log("response", res.payload.message);
          }
        });
      })
      .finally(() => {
        setDilogData("Are You Sure");
        setEditClick(false);
        setIsAdd(false);
      });
  };
  const onCancel = () => {
    setOpenDialog(false);
    // setDilogData("Are You Sure");
  };

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling animation
    });
  };
  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = () => {
      //   setId(rowData.key);
      console.log("eemmaaiill", rowData);
      formik.setFieldValue("firstName", rowData.firstName);
      formik.setFieldValue("lastName", rowData.lastName);
      formik.setFieldValue("password", rowData.password);
      formik.setFieldValue("email", rowData.userName);
      formik.setFieldValue("mobile", rowData.mobile);
      setId(rowData.userId);
      setloading(true);
      const parms = {
        email: rowData.userName,
        isEdited: true,
      };
      dispatch(GetInfoByEmailThunk(parms))
        .then((res) => {
          console.log("Bansi", res);
          if (res.payload.success === true) {
            // formik.setFieldValue("mobile", res.payload.data.mobile);
            // formik.setFieldValue("firstName", res.payload.data.firstName);
            // formik.setFieldValue("lastName", res.payload.data.lastName);
            // formik.setFieldValue("password", res.payload.data.password);
            // setId(res.payload.data.userId);
            // setUpdate(true);
            setDilogData(res.payload.message);
          } else if (res.payload.success === false) {
            // infoToastMessage(toast, "Email Not Found");
          }
        })
        .finally(() => setloading(false));

      setEditClick(true);
      handleScrollToTop();
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={handleEdit}
          >
            <span className="text-white">EDIT</span>
          </Button>
        </div>
      </div>
    );
  };
  console.log("EmailDataHere", infoByEmail);
  const deleteButton = (rowData: any) => {
    const handleDelete = () => {
      //   setId(rowData.key);
      console.log("eemmaaiill", rowData);
      formik.setFieldValue("firstName", rowData.firstName);
      formik.setFieldValue("lastName", rowData.lastName);
      formik.setFieldValue("password", rowData.password);
      formik.setFieldValue("email", rowData.userName);
      formik.setFieldValue("mobile", rowData.mobile);
      setId(rowData.userId);
      setVisible(true);
      setEditClick(false);
      setIsAdd(false);
      formik.resetForm();
    };
    return (
      <div className="flex flex-column ">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={handleDelete}
          >
            <span className="text-white">DELETE</span>
          </Button>
        </div>
      </div>
    );
  };
  const onDelete = () => {
    setEditClick(false);
    setIsAdd(false);
    const reqBody = {
      action: "Delete",
      displayName: "",
      givenName: formik.values.firstName,
      surname: formik.values.lastName,
      identities: {
        signInType: "",
        issuerAssignedId: formik.values.email
          ? formik.values.email?.trim()
          : formik.values.email,
      },
      userId: id,
      password: "",
      legalEntityId: fundDropdown.fundid,
      mobileNo: formik.values.mobile,
      // eslint-disable-next-line unicorn/no-null
      azureObjectId: null,
    };
    // const deleteParms = {
    //   LEDId: fundDropdown.fundid,
    //   UserId: id,
    // };
    setloading(true);
    dispatch(SaveUserLoginDetailsThunk(reqBody)).then(() => {
      dispatch(BindUserInformationThunk(param));
      setloading(false);
      formik.resetForm();
      successToastMessage(toast, "User deleted successfully ");
    });
    setVisible(false);
    formik.resetForm();
  };

  const formatPhoneNumber = (value: string) => {
    // Remove non-digit characters
    const phoneNumberOnly = value ? value!.replace(/\D/g, "") : value;

    let maskedPhoneNumber = "";

    if (phoneNumberOnly) {
      if (phoneNumberOnly.length <= 3) {
        maskedPhoneNumber = phoneNumberOnly;
      } else if (phoneNumberOnly.length <= 6) {
        maskedPhoneNumber =
          phoneNumberOnly.slice(0, 3) + "-" + phoneNumberOnly.slice(3);
      } else {
        maskedPhoneNumber =
          phoneNumberOnly.slice(0, 3) +
          "-" +
          phoneNumberOnly.slice(3, 6) +
          "-" +
          phoneNumberOnly.slice(6, 10);
      }
    }

    return maskedPhoneNumber;
  };

  const handleFocusOut = (e: any) => {
    if (e.target.value !== "") {
      console.log("Focus", e.target.value, regexForEmail.test(e.target.value));
      if (!regexForEmail.test(e.target.value)) {
        // Toaster Message
        errorToastMessage(toast, "Please Enter Valid Url Format");
      } else {
        setloading(true);
        const parms = {
          email: e.target.value ? e.target.value?.trim() : e.target.value,
          isEdited: false,
        };
        // success,reject case handle
        dispatch(GetInfoByEmailThunk(parms))
          .then((res) => {
            console.log("Bansi", res);
            if (res.payload.success === true) {
              formik.setFieldValue("mobile", res.payload.data.mobile);
              formik.setFieldValue("firstName", res.payload.data.firstName);
              formik.setFieldValue("lastName", res.payload.data.lastName);
              formik.setFieldValue("password", res.payload.data.password);
              setId(res.payload.data.userId);
              setUpdate(true);
              setDilogData(res.payload.message);
            } else if (res.payload.success === false) {
              // infoToastMessage(toast, "Email Not Found");
            }
          })
          .finally(() => setloading(false));
      }
    }
  };

  const dataTableElements = [
    {
      field: "userName",
      header: "User Name",
      style: { minWidth: "240px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.userName}>
          {rowData?.userName}
        </div>
      ),
    },
    {
      // field: "targetReturn",
      header: "",
      style: { minWidth: "200px" },
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
    },
    {
      // field: "estExitDate",
      header: "",
      style: { minWidth: "200px" },
      body: (rowData: any) => deleteButton(rowData),
    },
  ];

  //   const resetForm = () => {
  //     setName("");
  //   };

  useEffect(() => {
    // dispatch(ManageUserListThunk(param));
    setloading(true);
    // dispatch(BindUserInformationThunk(param)).then(() => {
    //   formik.setFieldValue("firstName", "");
    //   formik.setFieldValue("lastName", "");
    //   formik.setFieldValue("password", "");
    //   formik.setFieldValue("email", "");
    //   formik.setFieldValue("mobile", "");
    //   setloading(false);
    // });
    formik.setFieldValue("firstName", "");
    formik.setFieldValue("lastName", "");
    formik.setFieldValue("password", "");
    formik.setFieldValue("email", "");
    formik.setFieldValue("mobile", "");
  }, []);
  useEffect(() => {
    // dispatch(ManageUserListThunk(param));
    if (fundDropdown.fundid) {
      setEditClick(false);
      setIsAdd(false);
      setloading(true);
      dispatch(BindUserInformationThunk(param)).then(() => {
        formik.setFieldValue("firstName", "");
        formik.setFieldValue("lastName", "");
        formik.setFieldValue("password", "");
        formik.setFieldValue("email", "");
        formik.setFieldValue("mobile", "");
        setId(0);
        setloading(false);
        formik.resetForm();
      });
      formik.setFieldValue("firstName", "");
      formik.setFieldValue("lastName", "");
      formik.setFieldValue("password", "");
      formik.setFieldValue("email", "");
      formik.setFieldValue("mobile", "");
      setId(0);
    }
  }, [fundDropdown.fundid]);

  // useEffect(() => {
  //   if (formik.values.email) {
  //     const reqParam = {
  //       Username: formik.values.email,
  //     };
  //     dispatch(InformationByUsernameThunk(reqParam)).then((res: any) => {
  //       const data = res.payload.data ? res.payload.data[0] : [];
  //       console.log("info", data);

  //       formik.setFieldValue("firstName", data.firstName);
  //       formik.setFieldValue("lastName", data.lastName);
  //       formik.setFieldValue("mobile", data.mobileNo);
  //     });
  //   }
  // }, [formik.values.email]);

  return (
    <>
      <Dialog
        visible={openDialog}
        className="w-100 md:w-4 DialogInvestment"
        onHide={() => setOpenDialog(false)}
      >
        <h4
          className="pageHeader  mb-5 pt-5"
          dangerouslySetInnerHTML={{ __html: dialogData }}
        ></h4>
        <div className=" flex  flex-wrap align-self-center  align-items-center justify-content-between">
          <Button
            onClick={(e) => {
              e.preventDefault();
              onSave();
            }}
            className="blueButtonShadow flex flex-1 flex align-items-center justify-content-center  font-bold  m-2 px-5 py-3 border-round"
          >
            Yes
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
            className="blueButtonShadow flex flex-1  align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round"
          >
            No
          </Button>
        </div>
      </Dialog>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}
        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Manage Users</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                GetUserList === undefined ||
                GetUserList?.length === 0 ||
                GetUserList === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            This user account has access to other funds. By deleting this user,
            the user will lose access only to this fund.
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={onDelete}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>

        <div className="col-12">
          {!isAdd && !editClick && (
            <Button
              className="btn-navActive w-9rem mb-1"
              onClick={(e) => {
                setIsAdd(true);
              }}
            >
              Add User
              <span className="pl-2">
                <RiAddFill />
              </span>
            </Button>
          )}
          {!isAdd && editClick && (
            <div className="tabHeader-wrap col-6">
              <span className="tabHeadText Active"> Edit User</span>
            </div>
          )}
          {isAdd && !editClick && (
            <div className="tabHeader-wrap col-6">
              <span className="tabHeadText Active"> Add User</span>
            </div>
          )}
          {(isAdd || editClick) && (
            <>
              <div className="formgrid grid px-2">
                <div
                  className={
                    editClick
                      ? "d-block sm:d-flex sm:flex-column  md:col-6 col-4 emailClass"
                      : "d-block sm:d-flex sm:flex-column  md:col-6 col-4 "
                  }
                >
                  <label className="inputLabel">Email *</label>
                  <InputText
                    name="email"
                    value={formik.values.email}
                    onChange={(e) =>
                      formik.setFieldValue("email", e.target.value)
                    }
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("email"),
                      "card-inputBox": true,
                    })}
                    onBlur={handleFocusOut}
                    placeholder="Email"
                    disabled={editClick}
                  />
                  {getFormErrorMessage("email")}
                </div>

                <div className="d-block sm:d-flex sm:flex-column  md:col-6 col-4 ">
                  <label className="inputLabel">First Name *</label>
                  <InputText
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={(e) =>
                      formik.setFieldValue("firstName", e.target.value)
                    }
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("firstName"),
                      "card-inputBox": true,
                    })}
                    placeholder="First Name"
                  />
                  {getFormErrorMessage("firstName")}
                </div>
                <div className="d-block sm:d-flex sm:flex-column md:col-6 col-4">
                  <label className="inputLabel">Last Name *</label>
                  <InputText
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={(e) =>
                      formik.setFieldValue("lastName", e.target.value)
                    }
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("lastName"),
                      "card-inputBox": true,
                    })}
                    placeholder="Last Name"
                  />
                  {getFormErrorMessage("lastName")}
                </div>
              </div>
              <div className="formgrid grid px-2">
                <div className="d-block sm:d-flex sm:flex-column  md:col-6 col-4 mt-1">
                  <label className="inputLabel">Mobile No *</label>
                  <div className="p-inputgroup flex-1 mb-2">
                    <span className="p-inputgroup-addon p-4">+1 </span>
                    <input
                      name="mobile"
                      id="phone"
                      type="text"
                      // value={phoneNumber}
                      value={
                        formik.values.mobile
                          ? formatPhoneNumber(
                              formik.values.mobile.split("-").join("")
                            )
                          : formik.values.mobile
                      }
                      // onChange={handleChange}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "mobile",
                          formatPhoneNumber(e.target.value).split("-").join("")
                        );
                        console.log("onchange", e.target.value);
                      }}
                      placeholder="xxx-xxx-xxxx"
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("mobile"),
                        "card-inputB pl-3": true,
                      })}
                    />
                  </div>

                  {getFormErrorMessage("mobile")}
                </div>

                <div className="align-items-center d-flex justify-content-start col-4">
                  <Button
                    className="mr-4 btn-nav w-7rem justify-content-center"
                    onClick={(e) => {
                      e.preventDefault();
                      // setIsFormSubmitted(true);
                      console.log("clicked");
                      formik.resetForm();
                      setEditClick(false);
                      setIsAdd(false);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="btn-nav w-7rem justify-content-center"
                    onClick={(e) => {
                      e.preventDefault();
                      // setIsFormSubmitted(true);
                      console.log("clicked");
                      formik.handleSubmit();
                    }}
                    type="button"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </>
          )}

          <div className="card tabWithoutboxshadow mt-2">
            {/* <Card> */}
            <DataTableComponent
              valueData={GetUserList!}
              fieldsElements={dataTableElements}
              isPaginationAllowed={true}
              className="gridcell deletebtn"
              columnResizeMode="expand"
              scrollable={true}
              isFullView={true}
              setOpenDailog={setOpenDailog}
              openDailog={openDailog}
            />
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
