import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getGAAPAccountingProfitLossService } from "../../../../services/gaap-accounting/gaap-accounting";
import error from "../../../../utils/error";
import {
  IGetGAAPAccountingProfitLossParameter,
  getGAAPAccountingProfitLossState,
} from ".";

const initialState: getGAAPAccountingProfitLossState = {
  data: {
    transactionName: undefined,
    value: undefined,
    netProfit: 0,
    totalOperatingExpenses: 0,
  },
  loading: "loading",
  error: undefined,
};

export const getGAAPAccountingProfitLossThunk = createAsyncThunk(
  "gaapAccounting/getGAAPAccountingProfitLoss",
  async (data: IGetGAAPAccountingProfitLossParameter) => {
    try {
      const response = await getGAAPAccountingProfitLossService(data);

      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getGAAPAccountingProfitLoss",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getGAAPAccountingProfitLossThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      getGAAPAccountingProfitLossThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";

        if (
          action.payload._response.data &&
          action.payload._response.data[0] &&
          action.payload._response.data[0][0] &&
          action.payload._response.data[1]
        ) {
          state.data.value = action.payload._response.data[0][0];
          state.data.transactionName = action.payload._response.data[1];

          const expenseTotal: number = action.payload._response.data[1].reduce(
            (total: number, transaction: any) =>
              total + transaction.expenseValue,
            0
          );
          const value = action.payload._response.data[0][0];
          state.data.totalOperatingExpenses = expenseTotal;
          state.data.netProfit =
            value.totalIncome - value.totalCostGoodSold - expenseTotal;
        }
      }
    );
    builder.addCase(
      getGAAPAccountingProfitLossThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // errorFundlogo
        // action.payload contains error information
        state.error = error(action.payload);
        // state.error = action.payload
      }
    );
  },
});

export default slice.reducer;
