import { FormikValues } from "formik";
import React, { useEffect } from "react";

interface IProps {
  formik: FormikValues;
}

const ScrollToError: React.FC<IProps> = ({ formik }) => {
  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const collection = document.querySelectorAll(".p-error");
      collection[0]?.previousElementSibling!.scrollIntoView({
        block: "nearest",
        inline: "center",
        behavior: "smooth",
      });
    }
  }, [formik]);

  return <></>;
};

export default ScrollToError;
