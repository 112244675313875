import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllInvestorListforPoolFunds } from "../../../services/PoolFund";
import error from "../../../utils/error";
import { GetAllInvestorListPoolFundType } from "./type";

const initialState: GetAllInvestorListPoolFundType = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    managerFees: [],
    output: [],
    pooledFundsInvestmentDetails: {},
  },
};
export const getAllInvestorListPoolFunds = createAsyncThunk(
  "getAllInvestorListPoolFunds",
  async (data: any) => {
    try {
      console.log("data", data);
      const response = await getAllInvestorListforPoolFunds(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getAllInvestorListPoolFunds",
  initialState,
  reducers: {
    resetAllInvesterListPoolFund(state) {
      state.data = initialState.data;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getAllInvestorListPoolFunds.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(getAllInvestorListPoolFunds.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("hr getAllInvestorListPoolFunds", action.payload.success);
      state.data = action.payload.success
        ? action.payload.data
        : initialState.data;
      state.success = action.payload.success;
      state.message = action.payload.message;
    });
    builder.addCase(getAllInvestorListPoolFunds.rejected, (state, action) => {
      state.loading = "failed";
      throw new Error(error(action.error));
    });
  },
});
export const { resetAllInvesterListPoolFund } = slice.actions;
export default slice.reducer;
