import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAccountNickNameforInvestorExistsService } from "../../../../../../services/investor/investor";
import error from "../../../../../../utils/error";
import { IIsAccountNickNameforInvestorExistsPayload } from ".";

interface IIsAccountNickNameforInvestorExists {
  data?: boolean;
  loading: string;
  error?: string | null;
  additionalMembers?: [];
}

const initialState: IIsAccountNickNameforInvestorExists = {
  data: undefined,
  loading: "loading",
  error: undefined,
  additionalMembers: undefined,
};

export const isAccountNickNameforInvestorExistsThunk = createAsyncThunk(
  "investorAccount/isAccountNickNameforInvestorExists",
  async (data: IIsAccountNickNameforInvestorExistsPayload) => {
    try {
      const response = await isAccountNickNameforInvestorExistsService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "investorAccount/isAccountNickNameforInvestorExists",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(
      isAccountNickNameforInvestorExistsThunk.pending,
      (state) => {
        state.loading = "loading";
        state.error = undefined;
      }
    );
    builder.addCase(
      isAccountNickNameforInvestorExistsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data
          ? action.payload._response.data
          : "";
      }
    );
    builder.addCase(
      isAccountNickNameforInvestorExistsThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
