export const protectedURL = new Set([
  "Enroll",
  "ManageOffering",
  "OpportunityDetails",
  "ApprovedInvestor",
]);
export const parentUrlObj: any = {
  Enroll: "/investorsList?source=inRegistration",
  ManageOffering: "/ManageOffering",
  OpportunityDetails: "/Opportunities/AllOpportunities",
  ApprovedInvestor: "/investorsList",
};
