// import { IDeleteInvestorparameters } from "../../store/features/investor/delete-investor/deleteinvestor";
import { IDeleteInvestingAccountEnrollmentPayload } from "../../store/Investor/investor-approval/delete-investing-account-enrollment";
import { IAccountTypeId } from "../../store/Investor/investor-approval/get-lookup-by-accountId";
import { IgetStepStatusPayload } from "../../store/Investor/investor-approval/get-step-status";
import { IGetAccrediationLetterPayload } from "../../store/Investor/investor-approval/verify-agreement/accreditation/get-accrediation-letter";
import { IGetInvAccrediationStatusPayload } from "../../store/Investor/investor-approval/verify-agreement/accreditation/get-inv-accrediation-status";
import { IUploadAccredDocumentPayload } from "../../store/Investor/investor-approval/verify-agreement/accreditation/get-uploaded-accred-document-link";
import { IUploadAccredPara } from "../../store/Investor/investor-approval/verify-agreement/accreditation/upload-acrred-file-link";
import { IGetIAEnrollmentDataPayload } from "../../store/Investor/investor-approval/verify-agreement/digi-signer/get-ia-enrollment-data";
import { IGetESignDocuemtParameter } from "../../store/Investor/investor-approval/verify-agreement/e-sign-document";
import { IgetDocumentLinkSignatureDualSignerPayload } from "../../store/Investor/investor-approval/verify-agreement/get-document-link-signature-dual-signer";
import { IAddUpdateIAEnrollentInfoPayload } from "../../store/Investor/investor-approval/verify-agreement/investing-account/add-update-ia-enrollent-Info";
import { IDeleteInvestingAccountPayload } from "../../store/Investor/investor-approval/verify-agreement/investing-account/delete-investing-account-data";
import { IInvestingAccountDetailsPayload } from "../../store/Investor/investor-approval/verify-agreement/investing-account/get-investing-account-details";
import { IIsAccountNickNameforInvestorExistsPayload } from "../../store/Investor/investor-approval/verify-agreement/investing-account/is-account-nick-name-for-Investor-exists";
import { IGetInvestorProfileDetailsPayload } from "../../store/Investor/investor-approval/verify-profile/get-data";
import { IGetPersona } from "../../store/Investor/investor-approval/verify-profile/get-persona-img-url";
import { IInvestorProfilePayload } from "../../store/Investor/investor-approval/verify-profile/post-data";
import { IGetInvestorInviteListparameters } from "../../store/Investor/invite-investor/get-investor-invite-list";
import { IDeleteManagementFeesparameters } from "../../store/Investor/setupinvestorfees/deletemanagementfees";
import { IGetFeeListparameters } from "../../store/Investor/setupinvestorfees/getinvestorfees";
import { ISaveManagementFeesparameters } from "../../store/Investor/setupinvestorfees/savemanagementfees";
import { IViewapproveregistrationsparameters } from "../../store/features/investor";
import { IDeleteInvestorparameters } from "../../store/features/investor/delete-investor";
import { IGetStatusAndFlagParameters } from "../../store/features/investor/get-investor-status";
import { IInvestorParam } from "../../store/features/investor/manage-investor-document/investor-document-list";
import { IUpdateCountryStatusparameters } from "../../store/features/investor/managecountrymaster/updatecountrystatus";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function GetInvestorIAListService(
  data: IViewapproveregistrationsparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/GetInvestorIAList?LegalEntityId=${
      data.LegalEntityId
    }&ApprovalStatus=${data.ApprovalStatus}`
  );
}

export function GetCountriesService() {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/CountryMaster/GetCountries`
  );
}

export function UpdateCountryStatusService(
  data: IUpdateCountryStatusparameters
) {
  return apiFactory().put(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/CountryMaster/UpdateCountryStatus?CountryID=${
      data.CountryID
    }&IsActiveFlag=${data.IsActiveFlag}
    `
  );
}

export function deleteInvestorService(data: IDeleteInvestorparameters) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/DeleteInvestor?InvestorId=${
      data.investorId
    }&WalkinUserId=${data.walkinUserId}&LegalEntityId=${
      data.legalEntityId
    }&InvestingAccountId=${data.investingAccountId}`
  );
}

export function GetFeeListService(data: IGetFeeListparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Fees/GetFeeList?LegalEntityId=${data.LegalEntityId}
    `
  );
}

export function SaveManagementFeesService(data: ISaveManagementFeesparameters) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_OPERATION_URL}/Fees/SaveManagementFees
    `,
    data
  );
}

export function DeleteManagementFeesService(
  data: IDeleteManagementFeesparameters
) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Fees/DeleteManagementFees?ManagementId=${data.ManagementId}`
  );
}

export function getInvestingAccountsLookupsService() {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/GetInvestingAccountLookups
    `
  );
}

export function getLookUpbyAccountIdService(data: IAccountTypeId) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/GetLookUpbyAccountId?AccountTypeId=${data.accountTypeId}`
  );
}

export function getESignDocumentService(data: IGetESignDocuemtParameter) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/GetESignDocument?LegalEntityId=${
      data.legalEntityID
    }&InvestorId=${data.investorID}&LoginId=${data.userID}&InvestingAccountId=${
      data.investingAccountID
    }`
  );
}

export function GetDocumentLinkSignatureDualSignerService(
  data: IgetDocumentLinkSignatureDualSignerPayload
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/GetDocumentLinkSignatureDualSigner?InvestorId=${
      data.investorId
    }&LegalEntityId=${data.legalEntityId}&InvestingAccountID=${
      data.investingAccountId
    }&IsResend=${data.isResend}`
  );
}

export function deleteInvestingAccountEnrollmentService(
  data: IDeleteInvestingAccountEnrollmentPayload
) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/DeleteInvestingAccountEnrollment?InvestorId=${
      data.investorId
    }&LegalEntityId=${data.legalEntityId}&InvestingAccountID=${
      data.investingAccountId
    }`
  );
}

export function getIAEnrollmentDataService(data: IGetIAEnrollmentDataPayload) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/GetIAEnrollmentData?LegalEntityId=${
      data.legalEntityId
    }&InvestorId=${data.investorId}&InvestingAccountID=${
      data.investingAccountId
    }`
  );
}

export function getInvestorProfileDetailService(
  data: IGetInvestorProfileDetailsPayload
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/getInvestorProfileDetails?LegalEntityId=${
      data.LegalEntityId
    }&InvestorId=${data.InvestorId}&InvestingAccountId=${
      data.InvestingAccountId
    }`
  );
}

export function addInvestorProfileService(data: IInvestorProfilePayload) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestingAccount/AddInvestorProfileDetails`,
    data
  );
}

export function getUploadedAccredDocumentLink(
  data: IUploadAccredDocumentPayload
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/GetUploadedDocumentLink?LegalEntityId=${
      data.legalEntityId
    }&InvestorId=${data.investorId}`
  );
}

export function getInvAccrediationStatusService(
  data: IGetInvAccrediationStatusPayload
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/GetInvAccrediationStatusDetails?InvestorId=${
      data.investorId
    }&LegalEntityId=${data.legalEntityId}&InvestingAccountId=${
      data.investingAccountId
    }`
  );
}

export function getEmbedUIAcreddLinkService(
  data: IGetInvAccrediationStatusPayload
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/GetEmbedUIAcredd?InvestorId=${
      data.investorId
    }&LegalEntityId=${data.legalEntityId}&AccreddTransactionID=${
      data.accreddTransactionId
    }&IsAccredited=${data.isAccredited}`
  );
}

export function getAccrediationLetterService(
  data: IGetAccrediationLetterPayload
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestingAccount/GetAccrediationLetter?ExternalUniqueID=${
      data.ExternalUniqueId
    }&InvestorId=${data.investorId}&LegalEntityId=${data.legalEntityId}`
  );
}

export function uploadAcrredFileLink(data: IUploadAccredPara) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/UploadAccredFile?LegalEntityId=${
      data.legalEntityId
    }&InvestorId=${data.investorId}`,
    data.filename,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function getInvestingAccountDetailsByIdService(
  data: IInvestingAccountDetailsPayload
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/GetInvestingAccountDetailsById?InvestingAccountId=${
      data.investingAccountID
    }&LegalEntityId=${data.legalEntityID}`
  );
}

export function deleteInvestingAccountDataService(
  data: IDeleteInvestingAccountPayload
) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestingAccount/DeleteInvestingAccountData?InvestingAccountId=${
      data.investingAccountId
    }&TrusteeId=${data.trusteeId}&LLCMemberId=${data.llcMemberId}&PartnerId=${
      data.partnerId
    }&ExecutiveMemberId=${data.executiveMemberId}`
  );
}

export function addUpdateIAEnrollentInfoService(
  data: IAddUpdateIAEnrollentInfoPayload
) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/AddUpdateIAEnrollentInfo`,
    data
  );
}

export function getStepStatusService(data: IgetStepStatusPayload) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/GetStepStatus?LegalEntityId=${
      data.legalEntityId
    }&InvestorId=${data.investorId}&InvestingAccountID=${
      data.investingAccountId
    }`
  );
}

export function isAccountNickNameforInvestorExistsService(
  data: IIsAccountNickNameforInvestorExistsPayload
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/IsAccountNickNameforInvestorExists?InvestorId=${
      data.InvestorId
    }&AccountNickName=${data.AccountNickName}`
  );
}

export function getPersonaService(data: IGetPersona) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/getPersonaImgUrl?investorId=${
      data.investorID
    }&inquiryId=${data.inquiryId}&inqStatus=${data.inqStatus}&Type=${data.Type}`
  );
}

export function addUpdateInvestorCumIAInfoService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/AddUpdateInvestorCumIAInfo`,
    data
  );
}

// manage investor document

export function getInvestorDocumentListService(data: IInvestorParam) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorDocument/GetInvestorDocList?LPPartnerId=${
      data.LPPartnerId
    }&LegalEntityId=${data.LegalEntityId}`
  );
}
export function getInvestorDocumentTypeDropdownService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorDocument/GetInvestorAndDocumentTypeDropdownList?LegalEntityId=${
      data.legalEntityId
    }`
  );
}
export function addInvestorDocumentService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorDocument/AddUpdateInvestorDocument`,
    data
  );
}

export function deleteInvestorDocumentService(data: any) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorDocument/DeleteInvDocument?DocId=${data.docId}&PartnerId=${
      data.partnerId
    }`
  );
}
// end

export function getInvestorInviteListService(
  data: IGetInvestorInviteListparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InviteInvestor/GetInvestorInviteList?LegalEntityId=${data.legalEntityId}`
  );
}

export function approveDeleteInvestorInviteService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InviteInvestor/ApproveDeleteInvestorInvite`,
    data
  );
}

export function addUpdateInvestorInviteService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InviteInvestor/AddUpdateInvestorInvite`,
    data
  );
}

export function getStatusAndFlagService(data: IGetStatusAndFlagParameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorApproval/GetStatusAndFlag?InvestorId=${
      data.InvestorId
    }&LegalEntityId=${data.LegalEntityId}&InvestingAccountID=${
      data.InvestingAccountId
    }`
  );
}
