import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { addInvestmentStep1Service } from "../../../../services/investment/investment";
import {
  AddProjectUpdateService,
  DeleteProjectUpdateService,
  GetProjectUpdateService,
} from "../../../../../services/investment/investment";
import error from "../../../../../utils/error";
import { IProjectUpdateState } from ".";

const initialState: IProjectUpdateState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkProjectUpdates = createAsyncThunk(
  "projectUpdate/getProject",
  async (data: any) => {
    try {
      console.log("hhh", data);
      const response = await GetProjectUpdateService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const addThunkProjectUpdate = createAsyncThunk(
  "projectUpdate/addProject",
  async (data: any) => {
    try {
      const response = await AddProjectUpdateService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const deleteThunkProjectUpdateById = createAsyncThunk(
  "projectUpdate/deleteProject",
  async (data: any) => {
    try {
      const response = await DeleteProjectUpdateService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "projectUpdate",
  initialState,
  reducers: {
    // setSection: (state, action) => {
    //   state.data = action.payload;
    //   //
    // },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkProjectUpdates.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkProjectUpdates.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("state", action.payload);
      state.data =
        action.payload.data !== null && action.payload.data?.length !== 0
          ? action.payload.data
          : [];
      state.message =
        action.payload.message === null ? undefined : action.payload.message;

      state.success = action.payload.success;
    });
    builder.addCase(getThunkProjectUpdates.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });

    builder.addCase(addThunkProjectUpdate.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(addThunkProjectUpdate.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("state", action.payload);
      state.data =
        action.payload.data !== null && action.payload.data?.length !== 0
          ? action.payload.data
          : [];
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
      state.success = action.payload.success;
    });
    builder.addCase(addThunkProjectUpdate.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
    builder.addCase(deleteThunkProjectUpdateById.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(deleteThunkProjectUpdateById.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("state", action.payload.data.length);
      state.data =
        action.payload.data !== null && action.payload.data?.length !== 0
          ? action.payload.data
          : [];
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
      state.success = action.payload.success;
    });
    builder.addCase(deleteThunkProjectUpdateById.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});

// export const { setSection } = slice.actions;
export default slice.reducer;
