import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import AmountDisplay from "../../../components/amount-display";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
import Loader from "../../../components/loader/Loader";
// import { getExitUnitsTotalDataByLPPartnerID } from "../../../services/ManagerFees";
import { checkEarningsBeforeReverse } from "../../../services/PoolFund";
import { getThunkInvestmentIdByProduct } from "../../../store/features/operation/allocate-earning/get-investment-id-product/slice";
import { getThunkEarningsEntries } from "../../../store/features/operation/reverse-earning-allocation/get-earnings-entries/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  resetInvestorDropDown,
  reverseAllocatedPoolFundsByStakeIdThunk,
} from "../../../store/poolfunds/exit-pool-funds";
import {
  getAfterEarningsStatckbyInvestmentReturnIDThunk,
  resetAllEarningsStatckbyInvestmentReturn,
} from "../../../store/poolfunds/get-after-earning-stake-byinvestment";
import {
  getAllocatedInvestorsByInvestmentPoolfundsThunk,
  resetAllocaterInvestoresByInvestmentPoolFund,
} from "../../../store/poolfunds/get-allocatedinvestors-Investment-poolfunds";
import { getThunkAllInvestmentDealPooledFundByLegalEntityId } from "../../../store/poolfunds/get-investmentdeal-for-pooledfund";
import { PoolFundOutPutType } from "../../../types";
import currencyFormat from "../../../utils/currency-formatter";
import {
  convertUTCToLocalDate,
  getListDateConversion,
} from "../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
import ReverseEarningSection from "../../features/operation/reverse-earning-allocation/ReverseEarningSectionContinuous";

const ReverseFund: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [loading, setLoading] = useState<boolean>(false);
  const [productId, setPropductId] = useState<number>(0);
  const [investmentId, setInvestmentId] = useState<number>(0);
  const [isOpenRevers, setIsOpenReverse] = useState<boolean>(false);
  const [investerStakeData, setInvestorStakeData] = useState<
    PoolFundOutPutType[]
  >([]);
  const [stakeParam, setStakeParam] = useState<PoolFundOutPutType | any>();
  const [rowSelectData, setRowSelectData] = useState<any>();
  const [openDialog, setOpenDialog] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const profileData = useAppSelector((state) => state.profile.data![0]);

  const investmentList = useAppSelector(
    (state) => state.getAllInvestorDealListPoolFunds.data.investments
  );

  const allocatedInvesterData = useAppSelector(
    (state) => state.getAllAllocatedInvesterPoolFund.data.output
  );

  const allocatedInvester = useMemo(() => {
    const result = getListDateConversion(allocatedInvesterData, [
      "maxStartDate",
    ]);
    if (result.length > 0) {
      result[0].isButton = true;
    }
    return result;
  }, [allocatedInvesterData]);

  const getAllAllocatedInvesterPoolFundData = () => {
    setLoading(true);
    const parameter = {
      legalEntityId: fundDropdown.fundid,
      investmentId,
    };
    dispatch(getAllocatedInvestorsByInvestmentPoolfundsThunk(parameter))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        userId: profileData?.userID,
      };
      dispatch(
        getThunkAllInvestmentDealPooledFundByLegalEntityId(parameter)
      ).finally(() => {});
    }
    return () => {
      dispatch(resetAllocaterInvestoresByInvestmentPoolFund());
    };
  }, [fundDropdown]);

  console.log(
    "rowSelectData",
    rowSelectData,
    "jjj",
    stakeParam,
    investerStakeData
  );

  const handleReverse = () => {
    setLoading(true);
    const paramReverse = {
      legalEntityId: fundDropdown.fundid,
      investmentId,
      stakeTrackingId: stakeParam.stakeTrackingID,
    };

    dispatch(reverseAllocatedPoolFundsByStakeIdThunk(paramReverse))
      .then((res) => {
        setLoading(false);
        if (res.payload.success) {
          successToastMessage(toast, res.payload.message);
        } else {
          errorToastMessage(
            toast,
            res.payload.message || "Something went wrong!"
          );
        }

        setIsOpenReverse(false);
        setStakeParam("");
        getAllAllocatedInvesterPoolFundData();
      })
      .catch(() => {
        errorToastMessage(toast, "Something went wrong!");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (fundDropdown.fundid && productId) {
      setInvestorStakeData([]);
      dispatch(resetInvestorDropDown());
      setLoading(true);
      const param = { productId };
      dispatch(getThunkInvestmentIdByProduct(param))
        .then((response) => {
          if (
            response.payload._response.data !== null &&
            response.payload._response.data.externalInvestmentId.key !== 0 &&
            response.payload._response.data.externalInvestmentId !== 0
          ) {
            setInvestmentId(
              response.payload._response.data.externalInvestmentId.key
            );

            const parameter = {
              legalEntityId: fundDropdown.fundid,
              investmentId:
                response.payload._response.data.externalInvestmentId.key,
            };
            dispatch(getAllocatedInvestorsByInvestmentPoolfundsThunk(parameter))
              .then(() => setLoading(false))
              .catch(() => setLoading(false));
          } else {
            errorToastMessage(toast, "Investment Id is not found");
            setInvestmentId(0);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
        });
    }
  }, [fundDropdown.fundid, productId]);

  useEffect(() => {
    const pId = searchParams.get("productId");
    if (pId) {
      setPropductId(+pId);
    }
    return () => {};
  }, [searchParams.get("productId")]);

  const investmentDropdownList: any = useMemo(
    () =>
      investmentList!.length > 0
        ? investmentList?.map((item: any) => {
            return {
              name: item.productName,
              code: item.productID,
            };
          })
        : [],
    [investmentList]
  );

  const onReveserClick = (rowData: PoolFundOutPutType) => {
    const checkBeforeReverse = async () => {
      setLoading(true);
      try {
        const param = {
          lPPartnerId: rowData.lpPartnerId,
          investmentId: rowData.investmentID,
          stakeTrackingID: rowData.stakeTrackingID,
        };
        const response = await checkEarningsBeforeReverse(param);
        if (response.data.success)
          setStakeParam({ ...rowData, ...response.data.data.output?.[0] });
        else setStakeParam(rowData);
        setIsOpenReverse(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("errrr", error);
      }
    };
    return (
      <>
        {rowData?.isButton ? (
          <Button
            className="btn-navActive"
            onClick={(e) => {
              e.preventDefault();
              checkBeforeReverse();
            }}
          >
            Reverse
          </Button>
        ) : (
          <Button
            className="btn-navActive"
            onClick={(e) => {
              e.preventDefault();
            }}
            disabled={true}
          >
            Reverse
          </Button>
        )}
      </>
    );
  };

  const dataTableElement = [
    {
      field: "lpPartnerName",
      header: "Investor Name",
      // style: { width: "70px" },
      noFilter: true,
    },

    { field: "earningsPayoutTiming", header: "Earning Timing", noFilter: true },
    {
      field: "addOnUnits",
      header: "Allocated Investment Balance",
      body: (rowData: PoolFundOutPutType) => (
        <AmountDisplay amount={rowData?.addOnUnits ? rowData?.addOnUnits : 0}>
          {currencyFormat(rowData.addOnUnits)}
        </AmountDisplay>
      ),
    },
    {
      field: "unitsType",
      header: "Units Type",
    },
    {
      field: "maxStartDate",
      header: "Previous Allocation Date",
      dataType: "date",
      body: (rowData: PoolFundOutPutType) =>
        convertUTCToLocalDate(rowData.maxStartDate),
    },
    {
      field: "maxStartDate",
      header: "End Date",
      dataType: "date",
      body: (rowData: PoolFundOutPutType) =>
        rowData.endDate ? convertUTCToLocalDate(rowData.endDate) : "N/A",
    },
    { field: "assetClass", header: "Class" },
    { header: "Reverse", body: onReveserClick },
  ];

  const handleChange = (e: any) => {
    const { value } = e.target;
    setPropductId(value);
  };

  const onDialogCancel = (val: boolean) => {
    setStakeParam("");
    setIsOpenReverse(val);
    setOpenDialog(val);
    setRowSelectData({});
  };

  const handleDistribute = (rowData: any) => {
    setRowSelectData(rowData);
    setLoading(true);
    const parameter = {
      investmentReturnTypeId: rowData.investmentReturnTypeID,
      investmentReturnId: rowData.investmentReturnID,
      investmentId: rowData.investmentID,
    };
    dispatch(getThunkEarningsEntries(parameter)).then(() => {
      setLoading(false);
      setOpenDialog(true);
      setIsOpenReverse(false);
    });
    dispatch(
      getAfterEarningsStatckbyInvestmentReturnIDThunk({
        investmentReturnId: rowData.investmentReturnID,
        investmentId: rowData.investmentID,
      })
    );
  };

  const onDistubuteDailogClose = (val: boolean) => {
    setOpenDialog(val);
    setIsOpenReverse(val);
    setStakeParam("");
    setRowSelectData({});
    getAllAllocatedInvesterPoolFundData();
    dispatch(resetAllEarningsStatckbyInvestmentReturn());
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <Dialog
        visible={false}
        onHide={() => {
          setIsOpenReverse(false);
          // setInvestorStakeData([]);
        }}
        className="w-100 md:w-10 pb-1"
        headerClassName="no-header"
      >
        <div className="mt-2">
          <h4 className="pageHeader mt-2 flex justify-content-center">
            Stake Reverse
          </h4>
          <div className="mt-2">
            {/* <DataTableComponent
              valueData={investerStakeData || []}
              fieldsElements={dataTableElementExit}
              className="allocateSliceTable"
              noGridLines={true}
            /> */}
          </div>
        </div>
      </Dialog>
      <ReverseEarningSection
        openDialog={openDialog}
        setOpenDialog={onDistubuteDailogClose}
        rowSelectData={rowSelectData}
        setRowSelectData={setRowSelectData}
      />

      <Dialog
        visible={isOpenRevers}
        className="w-100 md:w-3 pb-1"
        onHide={() => onDialogCancel(false)}
      >
        <h4 className="pageHeader text-center mb-5 pt-5">
          {stakeParam?.investmentReturnID && stakeParam?.investmentReturnID > 0
            ? `You can not reverse this allocation. if you want to reverse this allocation please reverse  earning (${stakeParam.investmentReturnID})`
            : "Are you sure, you want to reverse the units"}
        </h4>
        {stakeParam?.investmentReturnID &&
        stakeParam?.investmentReturnID > 0 ? (
          <div className=" flex  flex-wrap align-self-center  align-items-center justify-content-between">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleDistribute(stakeParam);
              }}
              className="blueButtonShadow flex flex-1  align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round"
            >
              View Earning
            </Button>
          </div>
        ) : (
          <>
            <div className=" flex  flex-wrap align-self-center  align-items-center justify-content-between">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  stakeParam?.investmentReturnID &&
                  stakeParam?.investmentReturnID > 0
                    ? onDialogCancel(false)
                    : handleReverse();
                }}
                className="blueButtonShadow flex flex-1 flex align-items-center justify-content-center  font-bold  m-2 px-5 py-3 border-round"
              >
                Yes
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onDialogCancel(false);
                }}
                className="blueButtonShadow flex flex-1  align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round"
              >
                No
              </Button>
            </div>
          </>
        )}
      </Dialog>

      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Reverse Allocations</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <div className="grid">
        <div className="grid pt-0 col-12">
          <div className="col-12 pt-0 pr-0">
            <div className="card my-3 tabWithoutboxshadow">
              <Card>
                <div className="mt-1">
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
                      <label className="inputLabel">Select Investment</label>
                      <Dropdown
                        inputId="investment"
                        name="investment"
                        value={productId}
                        options={investmentDropdownList}
                        filter
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Select Investment"
                        className="card-inputBox"
                        // showOnFocus
                        onChange={(e) => {
                          e.preventDefault();
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="formgrid grid px-2 mt-3">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
                      <h6 className="mt-2">
                        Most recent allocations have to be reversed first. Also,
                        if there are earnings processed after this allocation,
                        please reverse those first.
                      </h6>
                    </div>
                  </div>
                </div>
                <DataTableComponent
                  valueData={allocatedInvester || []}
                  fieldsElements={dataTableElement}
                  noGridLines={true}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReverseFund;
