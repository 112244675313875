// import { Button } from "primereact/button";
// import { Dialog } from "primereact/dialog";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getOpportunityRelevantDocumentsData,
  getOpportunityRelevantDocumentsStatus,
  getOpportunityRelevantDocumentsThunk,
} from "../../store/opportunity/relevant-documents/slice";

interface IProps {
  id: string;
}

const OpportunityRelevantDocument: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();

  // const [visible, setVisible] = useState(false);
  // const footerContent = (
  //   <div>
  //     <Button
  //       label="No"
  //       icon="pi pi-times"
  //       onClick={() => setVisible(false)}
  //       className="p-button-text"
  //     />
  //     <Button
  //       label="Yes"
  //       icon="pi pi-check"
  //       onClick={() => setVisible(false)}
  //       autoFocus
  //     />
  //   </div>
  // );
  // Relevant documents
  const relevantDocumentsData = useAppSelector(
    getOpportunityRelevantDocumentsData
  );
  const relevantDocumentsStatus = useAppSelector(
    getOpportunityRelevantDocumentsStatus
  );

  // const openPDF = (e: any) => {
  //   e.preventDefault();
  //   setVisible(true);
  // };

  useEffect(() => {
    dispatch(getOpportunityRelevantDocumentsThunk(props.id));
  }, [dispatch]);

  return (
    <>
      {relevantDocumentsStatus === "succeeded" ? (
        relevantDocumentsData!.length > 0 ? (
          relevantDocumentsData!.map((docs, i) => {
            return (
              <div key={i}>
                <p className="reldocumentHeader">{docs.name}</p>
                <ul className="reldocumentlist">
                  {docs.description.map((decs, j) => (
                    <li key={j}>
                      <a
                        // onClick={openPDF}
                        // href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                        href={decs.productLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {decs.documentDesc}
                      </a>
                      {/* <Dialog
                      header=""
                      maximizable
                      visible={visible}
                      // style={{ width: "50vw" }}
                      style={{ width: "50vw" }}
                      onHide={() => setVisible(false)}
                      // footer={footerContent}
                      // closeOnEscape={true}
                      closable={true}
                      className=" w-100 md:w-5"
                    >
                      <iframe
                        src="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                        style={{ width: "100%", height: "700px" }}
                      ></iframe>
                    </Dialog> */}
                    </li>

                    // https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf
                  ))}
                </ul>
              </div>
            );
          })
        ) : (
          <p className="m-0">No additional documents available</p>
        )
      ) : (
        // <h1>content area</h1>
        <Skeleton className="col-12 md:col-6" height="5rem"></Skeleton>
      )}
    </>
  );
};

export default OpportunityRelevantDocument;
