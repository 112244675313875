import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDwollaDocumentTypeService } from "../../../services/Dwolla/dwolla";
import error from "../../../utils/error";
import { DocumentTypeInitType } from "./type";

const initialState: DocumentTypeInitType = {
  success: false,
  message: "",
  loading: "loading",
  data: [],
};
export const getDwollaDocumentTypeThunk = createAsyncThunk(
  "dwollaCompo/DwollaDocumentType",
  async () => {
    try {
      const response = await getDwollaDocumentTypeService();
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "dwollaCompo",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getDwollaDocumentTypeThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getDwollaDocumentTypeThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";

      state.data =
        action.payload.data !== null ? action.payload.data : initialState.data;
    });
    builder.addCase(getDwollaDocumentTypeThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
