import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect } from "react";
import { setForm } from "../../../../../store/features/investment/create/slice";
// import { setFormData } from "../../../../../store/features/investment/new-investment";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  convertLocalDateToUTC,
  convertUTCToLocalDate,
} from "../../../../../utils/date-formatter";

type CreateInvestmentStep6Props = {
  handlePrevious: () => void;
  handleNext: any;
  toast: any;
};
const CreateInvestmentStep6: React.FC<CreateInvestmentStep6Props> = ({
  handlePrevious,
  handleNext,
  toast,
}) => {
  const { data: stateInvestment } = useAppSelector(
    (state) => state.addInvestment
  );
  const dispatch = useAppDispatch();
  const stateDropDownLists = useAppSelector((state) => state.dropdownList.data);

  // const profileData = useAppSelector((state) => state.profile.data![0]);
  // const pageUpdateName = useAppSelector((state) => state.updatePage.data);

  const formik = useFormik({
    initialValues: {
      interestRate: "",
      assetClassID: "",
      investmentLengthID: "",
      estExitDate: "",
    },
    validate: (data) => {
      //
      const errors: any = {};
      if (!data.interestRate) {
        errors.interestRate = "Interest rate is required";
      }
      if (data.interestRate && data.interestRate.length > 50) {
        errors.interestRate =
          "Enter interest rate with maximum length of 50 characters";
      }

      if (!data.assetClassID) {
        errors.assetClassID = "Asset class is required";
      }
      if (!data.investmentLengthID) {
        errors.investmentLengthID = "Investment length is required";
      }

      if (!data.estExitDate) {
        errors.estExitDate = "Estimate exit date is required";
      }
      return errors;
    },
    onSubmit: () => {
      handleNext(stateInvestment);
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const { values, setFieldValue, handleSubmit } = formik;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleChangeState = () => {
    dispatch(
      setForm({
        ...stateInvestment,
        interestRate: values.interestRate,
        assetClassID: values.assetClassID,
        investmentLengthID: values.investmentLengthID,
        estExitDate: convertLocalDateToUTC(values.estExitDate),
      })
    );
  };

  useEffect(() => {
    setFieldValue("interestRate", stateInvestment?.interestRate);
    setFieldValue("assetClassID", stateInvestment?.assetClassID);
    setFieldValue("investmentLengthID", stateInvestment?.investmentLengthID);
    setFieldValue(
      "earningsPayoutTiming",
      stateInvestment?.earningsPayoutTiming
    );
    setFieldValue(
      "estExitDate",
      stateInvestment?.estExitDate === null ||
        stateInvestment?.estExitDate === ""
        ? stateInvestment?.estExitDate
        : convertUTCToLocalDate(stateInvestment?.estExitDate!)
    );
  }, []);
  return (
    <>
      <Toast ref={toast} className="themeToast" />
      <div className="formgrid grid px-2">
        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
          <label className="inputLabel">IRR/Interest Rate</label>
          <InputText
            name="interestRate"
            // value={
            //   values.interestRate === undefined ||
            //   values.interestRate === 0
            //     ? ""
            //     : values.interestRate
            // }
            value={values.interestRate}
            onChange={(e) => handleChange(e)}
            className={classNames({
              "p-invalid": isFormFieldInvalid("interestRate"),
              "card-inputBox": true,
            })}
            // maxLength={10}
            placeholder="Enter Interest Rate"
          />
          {getFormErrorMessage("interestRate")}
        </div>
        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
          <label className="inputLabel">Asset Class</label>
          <Dropdown
            inputId="assetClassID"
            name="assetClassID"
            value={values.assetClassID}
            options={
              stateDropDownLists === null
                ? []
                : stateDropDownLists.assetClassDetails
            }
            filter
            showOnFocus
            optionLabel="value"
            optionValue="key"
            placeholder="Select Asset Class"
            // className="card-inputBox"
            onChange={(e) => {
              handleChange(e);
            }}
            className={classNames({
              "p-invalid": isFormFieldInvalid("assetClassID"),
              "card-inputBox": true,
            })}
          />

          {getFormErrorMessage("assetClassID")}
        </div>
      </div>
      <div className="formgrid grid px-2">
        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
          <label className="inputLabel">How long is the Investment?</label>
          <Dropdown
            inputId="investmentLengthID"
            name="investmentLengthID"
            value={values.investmentLengthID}
            options={
              stateDropDownLists === null
                ? []
                : stateDropDownLists.investmentLength
            }
            filter
            showOnFocus
            optionLabel="value"
            optionValue="key"
            placeholder="Select Duration"
            // className="card-inputBox"
            onChange={(e) => {
              handleChange(e);
            }}
            className={classNames({
              "p-invalid": isFormFieldInvalid("investmentLengthID"),
              "card-inputBox": true,
            })}
          />

          {getFormErrorMessage("investmentLengthID")}
        </div>
        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
          <label className="inputLabel">Estimated Exit Date</label>

          <Calendar
            name="estExitDate"
            value={
              values.estExitDate === "" || values.estExitDate === null
                ? undefined
                : new Date(values.estExitDate)
            }
            onChange={(e) => handleChange(e)}
            className={classNames({
              "p-invalid": isFormFieldInvalid("estExitDate"),
              "card-inputBox": true,
            })}
            placeholder="Select Exit Date"
            readOnlyInput
          />

          {getFormErrorMessage("estExitDate")}
        </div>
      </div>

      <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
        <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
          <label className="inputLabel">&nbsp;</label>

          <Button
            className="btn-nav btnFocus"
            // tabIndex={0}

            onClick={(e) => {
              e.preventDefault();
              handlePrevious();
            }}
          >
            <span className="ml-auto mr-auto ">Back</span>
          </Button>
          <label className="inputLabel">&nbsp;</label>
        </div>
        <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
          <label className="inputLabel">&nbsp;</label>
          <Button
            className="btn-dialog btnFocus"
            type="submit"
            // tabIndex={1}
            onClick={(e) => {
              e.preventDefault();
              handleChangeState();
              handleSubmit();
            }}
          >
            <span className="ml-auto mr-auto ">Next</span>
          </Button>
          <label className="inputLabel">&nbsp;</label>
        </div>
      </div>
    </>
  );
};

export default CreateInvestmentStep6;
