import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { getOpportunityProjectUpdatesService } from "../../../services/opportunity/opportunity";
import { IOpportunityProjectUpdates } from "../../../types/index";
import error from "../../../utils/error";

interface IOpportunityProjectUpdatesState {
  Data?: IOpportunityProjectUpdates[] | null;
  loading: string;
  error?: string | null;
}
const initialState: IOpportunityProjectUpdatesState = {
  Data: undefined,
  loading: "loading",
  error: undefined,
};
export const getOpportunityProjectUpdatesThunk = createAsyncThunk(
  "opportunity/getOpportunityProjectUpdates",
  async (id: string) => {
    try {
      const response = await getOpportunityProjectUpdatesService(id);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "opportunityProjectUpdate",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getOpportunityProjectUpdatesThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      getOpportunityProjectUpdatesThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.Data = action.payload.data;
        //
      }
    );
    builder.addCase(
      getOpportunityProjectUpdatesThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});

export const getOpportunityProjectUpdatesData = (state: RootState) => {
  return state.opportunityProjectUpdate.Data;
};
export const getOpportunityProjectUpdatesStatus = (state: RootState) =>
  state.opportunityProjectUpdate.loading;
// export const getAllOpportunitiesError = (state: RootState) =>
//   state.opportunity.error;

export default slice.reducer;
