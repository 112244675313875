import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getAllMapService } from "../../services/portfolio/portfolio";
import { IMap } from "../../types";
// import { RootState } from "../../store";
// import { IPortfolio } from "../../types/index";
// import apiFactory from "../../utils/api";
import error from "../../utils/error";
interface IPortfolioState {
  data?: {}[] | null;
  gmap?: IMap[] | [];

  loading: string;
  error?: string | null;
}

const initialState: IPortfolioState = {
  data: undefined,
  gmap: undefined,
  loading: "loading",
  error: undefined,
};

// change the endpoint
export const getThunkMap = createAsyncThunk("Portfolio/GMap", async () => {
  try {
    const response = await getAllMapService();
    return response.data;
  } catch (error_) {
    throw new Error(error(error_));
  }
});

const slice = createSlice({
  name: "Portfolio/GMap",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkMap.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getThunkMap.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.gmap = action.payload;
    });
    builder.addCase(getThunkMap.rejected, (state, action) => {
      state.loading = "failed";

      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export const getAllPortfoliosData = (state: RootState) => {
  return state.portfolio.data;
};
// export const getAllOpportunitiesStatus = (state: RootState) =>
//   state.opportunity.loading;
// export const getAllOpportunitiesError = (state: RootState) =>
//   state.opportunity.error;
export default slice.reducer;
