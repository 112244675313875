import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReportTypeListService } from "../../../../services/gaap-accounting/gaap-accounting";
import error from "../../../../utils/error";
import { getReportTypeListState } from ".";

const initialState: getReportTypeListState = {
  data: undefined,
  loading: "loading",
  error: undefined,
};

export const getReportTypeListThunk = createAsyncThunk(
  "gaapAccounting/getReportTypeList",
  async () => {
    try {
      const response = await getReportTypeListService();

      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getReportTypeList",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getReportTypeListThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getReportTypeListThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload._response.data;
    });
    builder.addCase(getReportTypeListThunk.rejected, (state, action) => {
      state.loading = "failed";
      // errorFundlogo
      // action.payload contains error information
      state.error = error(action.payload);
      // state.error = action.payload
    });
  },
});

export default slice.reducer;
