import { useFormik } from "formik";
import { Button } from "primereact/button";

import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../../components/loader/Loader";
import { goToTop } from "../../../../../components/scroll-to/ScrollTo";
import { getInvestingAccountsLookupsThunk } from "../../../../../store/Investor/investor-approval/investing-accounts-lookups/slice";
import { getThunkDropdown } from "../../../../../store/features/dropdown/dropdown-list/slice";
import {
  addThunkInvestment,
  setForm,
} from "../../../../../store/features/investment/create/slice";
import { setPageName } from "../../../../../store/features/investment/update/update-page/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../../utils/toast-message";

interface IProps {
  handlePrevious: any;
  handleNext: any;
  offering: any;
}
export const CreateStep2 = (props: IProps) => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const stateInvestment = useAppSelector((state) => state.addInvestment);
  const stateDrop = useAppSelector((state) => state.dropdownList.data);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const pageUpdateName = useAppSelector((state) => state.updatePage.data);
  const countryDropdown = useAppSelector(
    (state) => state.investingAccountsLookups.countriesDropdown
  );
  const countryValue: any = countryDropdown
    ? countryDropdown.find((item: any) => item.value === "United States")
    : 0;

  const formik = useFormik({
    initialValues: {
      productName: "",
      supplierProductCode: "",
      isPhysicalAddress: "yes",
      productListingAddress: "",
      address2: "",
      city: "",
      stateCode: "",
      zipCode: "",
      countryId: countryValue.key,
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (!data.productName) {
        errors.productName = "Name is required";
      }
      if (!data.supplierProductCode) {
        errors.supplierProductCode = "Lookup code is required";
      }
      if (
        data.supplierProductCode &&
        !/^[\d A-Za-z-]+$/.test(data.supplierProductCode)
      ) {
        errors.supplierProductCode =
          "Lookup Code can only contain letters, numbers, hyphens (dashes) and spaces";
      }
      if (!data.isPhysicalAddress) {
        errors.isPhysicalAddress = "This field is required";
      }

      if (data.isPhysicalAddress === "yes") {
        if (data.zipCode) {
          if (/^[0^-]*$/.test(data.zipCode)) {
            errors.zipCode = "Invalid zipcode";
          } else if (
            data.zipCode?.length !== 5 &&
            data.zipCode?.length !== 10 &&
            formik.values.countryId === countryValue.key
          ) {
            errors.zipCode = "Zip code should be 5 or 9 digits";
          }
        } else {
          errors.zipCode = "Zipcode is required";
        }
        if (!data.countryId) {
          errors.countryId = "Country is required";
        }
        if (!data.city) {
          errors.city = "City is required";
        } else if (!/^(\D*)$/.test(data.city)) {
          errors.city = "Invalid city";
        }

        if (!data.stateCode) {
          errors.stateCode = "Select a state";
        }
        if (!data.productListingAddress) {
          errors.productListingAddress = "Address is required";
        }
      }

      return errors;
    },
    onSubmit: async (data) => {
      dispatch(setPageName({ ...pageUpdateName, createPage: false }));
      setLoading(true);
      await dispatch(addThunkInvestment(stateInvestment.data!))
        .then((response) => {
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (response.meta.requestStatus === "fulfilled") {
            if (response.payload.success === false) {
              errorToastMessage(toast, "Lookup code already exists");
            } else if (
              response.payload.data.productDetails[0].productID !== 0
            ) {
              successToastMessage(toast, stateInvestment.message);
              props.handleNext();
            }
          }
        })
        .catch((error: any) => {
          console.log("error", error);
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  console.log("stat3ee", stateInvestment, formik.values);
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleChangeState = () => {
    dispatch(
      setForm({
        ...stateInvestment.data,
        createdBy: profileData?.userID,
        productName: formik.values.productName,
        supplierProductCode: formik.values.supplierProductCode,
        isPhysicalAddress: formik.values.isPhysicalAddress,
        productListingAddress: formik.values.productListingAddress,
        address2: formik.values.address2,
        city: formik.values.city,
        stateCode: formik.values.stateCode,
        zipCode: formik.values.zipCode,
        countryId: formik.values.countryId,
      })
    );
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "zipCode": {
        if (formik.values.countryId === countryValue.key) {
          const digitsOnly = value.replace(/\D/g, "");
          // Add hyphen after every 5 digits if length is greater than 5
          let formattedValue = digitsOnly;
          console.log("tetseref", formik.values.countryId, countryValue);
          if (formattedValue.length > 5) {
            formattedValue = formattedValue.replace(/(\d{5})/g, "$1-");
          }
          //
          formik.setFieldValue(name, formattedValue);
        } else {
          formik.setFieldValue(name, value);
        }

        break;
      }
      case "isPhysicalAddress": {
        formik.setFieldValue(
          "countryId",
          value === "yes" ? countryValue.key : 0
        );

        formik.setFieldValue("productListingAddress", "");
        formik.setFieldValue("address2", "");
        formik.setFieldValue("city", "");
        formik.setFieldValue("stateCode", "");
        formik.setFieldValue("zipCode", "");

        formik.setFieldValue(name, value);

        break;
      }
      case "countryId": {
        formik.setFieldValue("stateCode", "");
        formik.setFieldValue("zipCode", "");
        formik.setFieldValue(name, value);
        break;
      }
      default: {
        formik.setFieldValue(name, value);
      }
    }
  };
  const parameters = {
    legalEntityID: stateInvestment.data?.legalEntityID,
    supplierID: stateInvestment.data?.supplierID,
  };
  useEffect(() => {
    (async () => {
      dispatch(getThunkDropdown(parameters));
      await dispatch(getInvestingAccountsLookupsThunk());
      formik.setFieldValue("productName", stateInvestment.data?.productName);
      formik.setFieldValue(
        "supplierProductCode",
        stateInvestment.data?.supplierProductCode
      );
      formik.setFieldValue("zipCode", stateInvestment.data?.zipCode);
      formik.setFieldValue(
        "isPhysicalAddress",
        stateInvestment.data?.isPhysicalAddress
      );
      formik.setFieldValue("stateCode", stateInvestment.data?.stateCode);
      formik.setFieldValue(
        "productListingAddress",
        stateInvestment.data?.productListingAddress
      );
      formik.setFieldValue("address2", stateInvestment.data?.address2);
      formik.setFieldValue("productState", stateInvestment.data?.productState);
      formik.setFieldValue("city", stateInvestment.data?.city);
      formik.setFieldValue(
        "countryId",
        stateInvestment.data?.countryId === 0 ||
          stateInvestment.data?.countryId === null
          ? countryValue.key
          : stateInvestment.data?.countryId
      );
    })();
    goToTop();
  }, []);
  console.log("tetststst", stateInvestment.data, formik.values);
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <div>
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader"></h1>
          </div>
        </div>
        <div className=" h-100 center-card">
          <div className="col-12 " style={{ width: "80%" }}>
            <div className="card my-3">
              <Card>
                <div className="headercard d-flex grid">
                  <div className="col-12 md:col-6">
                    <h2 style={{ fontWeight: "bold" }}>
                      Create New {props.offering}
                    </h2>
                  </div>
                  <div className="col-12 md:col-6 end-div">
                    <h6>Basic Info - Step 2</h6>
                  </div>
                </div>
                <div>
                  <ProgressBar
                    value={28}
                    showValue={false}
                    className="m-2"
                  ></ProgressBar>
                </div>

                <form>
                  <div className="mt-4">
                    <div className="formgrid grid px-2">
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                        <label className="inputLabel">
                          {stateInvestment.data?.productState === "SoftCommit"
                            ? "Deal Name"
                            : "Investment Name"}
                        </label>
                        <InputText
                          name="productName"
                          value={formik.values.productName}
                          onChange={(e) => handleChange(e)}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("productName"),
                            "card-inputBox": true,
                          })}
                          placeholder="Enter Name"
                        />
                        {getFormErrorMessage("productName")}
                      </div>
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                        <label className="inputLabel">Lookup Code</label>
                        <InputText
                          name="supplierProductCode"
                          value={formik.values.supplierProductCode}
                          onChange={(e) => handleChange(e)}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid(
                              "supplierProductCode"
                            ),
                            "card-inputBox": true,
                          })}
                          placeholder="Enter lookup code "
                        />

                        {getFormErrorMessage("supplierProductCode")}
                      </div>
                    </div>
                    <div className="d-flex flex-column p-2 col-12">
                      <label className="inputLabel">
                        Does this investment have a physical address?
                      </label>
                      <div className="d-flex mt-2">
                        <div className="flex align-items-center pr-4">
                          <RadioButton
                            inputId="isPhysicalAddress"
                            name="isPhysicalAddress"
                            value="yes"
                            onChange={(e) => handleChange(e)}
                            checked={formik.values.isPhysicalAddress === "yes"}
                          />

                          <label
                            htmlFor="isPhysicalAddress"
                            className="ml-2 mb-0"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="flex align-items-center pr-4">
                          <RadioButton
                            inputId="isPhysicalAddress"
                            name="isPhysicalAddress"
                            value="no"
                            onChange={(e) => handleChange(e)}
                            checked={formik.values.isPhysicalAddress === "no"}
                          />

                          <label
                            htmlFor="isPhysicalAddress"
                            className="ml-2 mb-0"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      {getFormErrorMessage("isPhysicalAddress")}
                    </div>
                    {formik.values.isPhysicalAddress === "yes" ? (
                      <>
                        <div className="formgrid grid px-2">
                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                            <label className="inputLabel">Address</label>
                            <InputText
                              name="productListingAddress"
                              value={formik.values.productListingAddress}
                              onChange={(e) => handleChange(e)}
                              className={classNames({
                                "p-invalid": isFormFieldInvalid(
                                  "productListingAddress"
                                ),
                                "card-inputBox": true,
                              })}
                              placeholder="Enter Address line 1"
                            />
                            {getFormErrorMessage("productListingAddress")}
                          </div>
                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                            <label className="inputLabel">
                              Apartment,Suite,etc.
                            </label>
                            <InputText
                              name="address2"
                              value={formik.values.address2}
                              onChange={(e) => handleChange(e)}
                              className={classNames({
                                "p-invalid": isFormFieldInvalid("address2"),
                                "card-inputBox": true,
                              })}
                              placeholder="Enter Address line 2"
                            />

                            {getFormErrorMessage("address2")}
                          </div>
                        </div>
                        <div className="formgrid grid px-2">
                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                            <label className="inputLabel">City</label>
                            <InputText
                              name="city"
                              value={formik.values.city}
                              onChange={(e) => handleChange(e)}
                              className={classNames({
                                "p-invalid": isFormFieldInvalid("city"),
                                "card-inputBox": true,
                              })}
                              placeholder="City"
                            />
                            {getFormErrorMessage("city")}
                          </div>
                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                            <label className="inputLabel">Country</label>
                            <Dropdown
                              inputId="countryId"
                              name="countryId"
                              value={formik.values.countryId}
                              options={
                                countryDropdown === null ? [] : countryDropdown
                              }
                              filter
                              resetFilterOnHide={true}
                              showFilterClear={true}
                              optionLabel="value"
                              optionValue="key"
                              placeholder="Select Country"
                              // className="card-inputBox"
                              showOnFocus
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              className={classNames({
                                "p-invalid": isFormFieldInvalid("countryId"),
                                "card-inputBox": true,
                              })}
                            />

                            {getFormErrorMessage("countryId")}
                          </div>
                        </div>
                        <div className="formgrid grid px-2">
                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                            <label className="inputLabel">State</label>
                            {formik.values.countryId === countryValue.key ? (
                              <>
                                {" "}
                                <Dropdown
                                  inputId="stateCode"
                                  name="stateCode"
                                  value={formik.values.stateCode}
                                  options={
                                    stateDrop === null
                                      ? []
                                      : stateDrop.stateDetails
                                  }
                                  filter
                                  resetFilterOnHide={true}
                                  showFilterClear={true}
                                  optionLabel="value"
                                  optionValue="key"
                                  placeholder="Select State"
                                  // className="card-inputBox"
                                  showOnFocus
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  className={classNames({
                                    "p-invalid":
                                      isFormFieldInvalid("stateCode"),
                                    "card-inputBox": true,
                                  })}
                                />
                              </>
                            ) : (
                              <>
                                {" "}
                                <InputText
                                  name="stateCode"
                                  value={formik.values.stateCode}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  className={classNames({
                                    "p-invalid":
                                      isFormFieldInvalid("stateCode"),
                                    "card-inputBox": true,
                                  })}
                                  placeholder="State"
                                  maxLength={100}
                                />
                              </>
                            )}

                            {getFormErrorMessage("stateCode")}
                          </div>
                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                            <label className="inputLabel">ZIP Code</label>
                            <InputText
                              name="zipCode"
                              value={formik.values.zipCode}
                              onChange={handleChange}
                              className={classNames({
                                "p-invalid": isFormFieldInvalid("zipCode"),
                                "card-inputBox": true,
                              })}
                              maxLength={
                                formik.values.countryId === countryValue.key
                                  ? 10
                                  : 20
                              }
                              placeholder="Enter zip code"
                            />
                            {formik.errors.zipCode &&
                              formik.touched.zipCode && (
                                <div className="error">
                                  {getFormErrorMessage("zipCode")}{" "}
                                </div>
                              )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}

                    <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
                      <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                        <Button
                          className="btn-nav btnFocus"
                          onClick={(e) => {
                            e.preventDefault();
                            props.handlePrevious();
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth", // Smooth scrolling
                            });
                          }}
                        >
                          <span className="ml-auto mr-auto ">Back</span>
                        </Button>
                        {/* <Button className="BtnBlue w-47">View Details</Button> */}
                      </div>
                      <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                        <Button
                          className="btn-dialog btnFocus"
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            handleChangeState();
                            formik.handleSubmit();
                            goToTop();
                          }}
                        >
                          <span className="ml-auto mr-auto ">Next</span>
                        </Button>
                        <label className="inputLabel">&nbsp;</label>
                      </div>
                    </div>
                  </div>
                </form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
