import { IDeleteCustodianparameters } from "../../store/features/master/deletecustodian";
import { IGetVideoLinkMenuInfoparameters } from "../../store/features/master/getvideolinkmenuinfo";
import { ISaveCustodianDetailsparameters } from "../../store/features/master/savecustodiandetails";
import { ISaveMenuDetailsparameters } from "../../store/features/master/savemenudetails";
import { IUpdateMenuDetailsparameters } from "../../store/features/master/updatemenudetails";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";
import { IGetCustodianListparameters } from "./../../store/features/master/getcustodianlist/index";

export function GetMenuListService() {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL}/MenuMaster/GetMenuList`
  );
}

export function SaveMenuDetailsService(data: ISaveMenuDetailsparameters) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/MenuMaster/SaveMenuDetails
    `,
    data
  );
}
export function UpdateMenuDetailsService(data: IUpdateMenuDetailsparameters) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/MenuMaster/UpdateMenuDetails
    `,
    data
  );
}

export function GetParentMenuListService() {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/MenuMaster/GetParentMenuList`
  );
}

export function GetStateListService() {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL}/Fund/GetStateList`
  );
}
export function SaveCustodianDetailsService(
  data: ISaveCustodianDetailsparameters
) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/CustodiansDetailsCUD`,
    data
  );
}

export function GetCustodianListService(data: IGetCustodianListparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/GetCustodianList?LegalEntityId=${data.LegalEntityId}
    `
  );
}

export function DeleteCustodianService(data: IDeleteCustodianparameters) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/Fund/DeleteCustodian?Id=${data.Id}&LegalEntityId=${data.LegalEntityId}`
  );
}

export function GetVideoLinkMenuInfoService(
  data: IGetVideoLinkMenuInfoparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/HelpVideos/GetVideoLinkMenuInfo?MenuId=${data.MenuId}&ParentId=${
      data.ParentId
    }
    `
  );
}
