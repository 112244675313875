import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../../components/loader/Loader";
import { IInvestmentData } from "../../../../../store/features/investment/create/index";
import { initialInvestmentState } from "../../../../../store/features/investment/create/payload";
import {
  addThunkInvestment,
  setForm,
} from "../../../../../store/features/investment/create/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../../utils/toast-message";
import StepProgressBar from "../../../../poolfunds/StepProgressBar";
import CreateInvestmentStep1 from "../create-forms/Step1";
import CreateInvestmentStep10 from "../create-forms/Step10";
import CreateInvestmentStep11 from "../create-forms/Step11";
import CreateInvestmentStep2 from "../create-forms/Step2";
import CreateInvestmentStep3 from "../create-forms/Step3";
import CreateInvestmentStep4 from "../create-forms/Step4";
import CreateInvestmentStep5 from "../create-forms/Step5";
import CreateInvestmentStep6 from "../create-forms/Step6";
import CreateInvestmentStep7 from "../create-forms/Step7";
// import CreateInvestmentStep8 from "../create-forms/Step8";
import CreateInvestmentStep9 from "../create-forms/Step9";

const mainLabels: { [key: number]: string } = {
  1: "Create New Offering",
  2: "Create New Offering",
  3: "Create New Offering",
  4: "Create New {lable} {productState}",
  5: "Create New {lable} {productState}",
  6: "Create New {lable} {productState}",
  7: "Create New {lable} {productState}",
  // 8: "Create New {lable} {productState}",
  8: "Confirm New {productState}",
  9: "Create New {lable} {productState}",
  10: "Create New {lable} {productState}",
};
const subLabels: { [key: number]: string } = {
  1: "Offering setup",
  2: "Offering setup",
  3: "Offering setup",
  4: "Basic Info",
  5: "Basic Info",
  6: "Basic Info",
  7: "Basic Info",
  // 8: "Basic Info",
  8: "Confirm Details",
  9: "Documents Info",
  10: "Images",
};

const CreateInvestmentDeals: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const totalSteps = 11;
  const toast = useRef<Toast>(null);
  const { data: stateInvestment, message } = useAppSelector(
    (state) => state.addInvestment
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setForm({ ...stateInvestment, ...initialInvestmentState }));
  }, []);

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(1);
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prevState) => (prevState === 1 ? 1 : prevState - 1));
  };
  const onSubmitForm = async (
    values: IInvestmentData,
    checkLookUp: boolean = false
  ) => {
    setLoading(true);
    await dispatch(addThunkInvestment(values!))
      .then((response) => {
        if (response.meta.requestStatus === "rejected") {
          setLoading(false);
          errorToastMessage(toast);
        } else if (response.meta.requestStatus === "fulfilled") {
          if (checkLookUp && !response.payload.success) {
            setLoading(false);
            errorToastMessage(toast, "Lookup code already exists");
          } else if (response.payload.data.productDetails[0].productID !== 0) {
            successToastMessage(
              toast,
              message || "Successfully added investment"
            );
            setTimeout(() => {
              setLoading(false);
              handleNext();
            }, 1000);
          }
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        setLoading(false);
        errorToastMessage(toast);
      });
  };
  return (
    <>
      <Toast ref={toast} className="themeToast" />
      {loading ? <Loader /> : <></>}
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Manage Offering</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <div className="grid">
        <div className="grid pt-0 col-12">
          <div className="col-12 pt-0 pr-0">
            <div className="card my-3 tabWithoutboxshadow">
              <Card>
                <StepProgressBar
                  subLabel={subLabels[currentStep]}
                  mainLabel={mainLabels[currentStep]
                    .replace(
                      "{lable}",
                      stateInvestment?.isPooledFund ? "Continuous" : "Fixed"
                    )
                    .replace(
                      "{productState}",
                      stateInvestment?.productState === "SoftCommit"
                        ? "Deal"
                        : "Investment"
                    )}
                  currentStep={currentStep}
                  noOfSteps={totalSteps}
                />
                <div className="mt-6" />
                {currentStep === 1 && (
                  <CreateInvestmentStep1
                    toast={toast}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                  />
                )}
                {currentStep === 2 && (
                  <CreateInvestmentStep2
                    toast={toast}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                  />
                )}
                {currentStep === 3 && (
                  <CreateInvestmentStep3
                    toast={toast}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                  />
                )}
                {currentStep === 4 && (
                  <CreateInvestmentStep4
                    toast={toast}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                  />
                )}
                {currentStep === 5 && (
                  <CreateInvestmentStep5
                    toast={toast}
                    handleNext={onSubmitForm}
                    handlePrevious={handlePrevious}
                  />
                )}
                {currentStep === 6 && (
                  <CreateInvestmentStep6
                    toast={toast}
                    handleNext={onSubmitForm}
                    handlePrevious={handlePrevious}
                  />
                )}
                {currentStep === 7 && (
                  <CreateInvestmentStep7
                    toast={toast}
                    handleNext={onSubmitForm}
                    handlePrevious={handlePrevious}
                  />
                )}
                {/* {currentStep === 8 && (
                  <CreateInvestmentStep8
                    toast={toast}
                    handleNext={onSubmitForm}
                    handlePrevious={handlePrevious}
                  />
                )} */}
                {currentStep === 8 && (
                  <CreateInvestmentStep9
                    toast={toast}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                  />
                )}
                {currentStep === 9 && (
                  <CreateInvestmentStep10
                    toast={toast}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                  />
                )}
                {currentStep === 10 && (
                  <CreateInvestmentStep11
                    toast={toast}
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                  />
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateInvestmentDeals;
