// import { getConfig } from "../../b2c/env-config";
import { IAccountStatementparameters } from "../../store/features/accountatatement";
import { ILegalEntityparameters } from "../../store/features/dropdown";
import { IAllUsersparameters } from "../../store/features/getallusers";
import apiFactory from "../../utils/api";
import { envVariable as envVar, envVariable } from "../../utils/sponsor/helper";

// import { getEnvConfig } from "../../utils/investor/helper";

// const env: string = getEnvConfig();
// const envVaiable = getConfig(env);

interface Iparameters {
  investorID: number;
  partnerID: number;
  legalEntityID: number;
}

export function getAllPortfolioService(data: Iparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/Portfolio/getDashboard?LegalEntityId=${data.legalEntityID}&InvestorId=${
      data.investorID
    }&PartnerID=${data.partnerID}`
  );
}

export function getAllPortfolioGetLegalEntityListService(
  data: ILegalEntityparameters
) {
  const value = encodeURIComponent(data.Email).replace("%20", "+");
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetLegalEntityListForAdminDropdown?Email=${value}`
  );
}
export function getAllUsersListService(data: IAllUsersparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetUserList?LegalEntityId=${data.LegalEntityId}`
  );
}

export function getAllMapService() {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_INVESTOR_SPNG_URL}/Portfolio/getMapApiKey?PartnerID=0`
  );
}

export function getPortfolioAssetDropdownService() {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_INVESTOR_SPNG_URL}/Portfolio/getFunds`
  );
}

export function getAllPortfolioInvestmentService(obj: any) {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_INVESTOR_SPNG_URL}/Portfolio/getInvestments?PartnerId=${obj.partnerID}&Category=${obj.fund}&InvestmentStatus=${obj.activeTab}&LegalEntityId=${obj.legalEntityID}&AvailablePageNumber=${obj.activePage}&PageSize=10`
  );
}

export function getAllPortfolioInvestorListService(obj: any) {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_INVESTOR_SPNG_URL}/Portfolio/GetInvestorList?LegalEntityId=${obj.LegalEntityId}`
  );
}

export function getAllPortfolioTransactionService(obj: any) {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_INVESTOR_SPNG_URL}/Portfolio/getTransactions?LPPartnerID=${obj.partnerID}&LegalEntityID=${obj.legalEntityID}&Category=${obj.activeTab}&PageSize=10&PageNumber=${obj.activePage}`
  );
}

export function getAllColorService() {
  const tenant = localStorage.getItem("tenentName");
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ASSETS_SPNG_URL
    }/Assets/getFundColor?fundName==${tenant}`
  );
  // return apiFactory().get(
  //   `${
  //     envVaiable!.REACT_APP_API_ASSETS_SPNG_URL
  //   }api/Assets/getFundColor?fundName=${tenant}`
  // );
}

export function getAllPortfolioInvestmentDropdownService(data: any) {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_INVESTOR_SPNG_URL}/Portfolio/getCategories`
  );
}
export function getAllPortfolioProductListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetProductList?LegalEntityId=${data.legalEntityId}`
  );
}
export function getAllPortfolioGetInvestmentReturnTypeListService() {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetInvestmentReturnTypeList`
  );
}

export function getAccountStatementService(data: IAccountStatementparameters) {
  return apiFactory().get(`${
    envVar!.REACT_APP_API_FINANCE_URL
  }/Plaid/getAccountStatement?LPPartnerId=${data.LPPartnerId}&LegalEntityId=${
    data.LegalEntityId
  }
      `);
}
