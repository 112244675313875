import { Button } from "primereact/button";
// import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
// import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import DataTableComponent from "../../../../../components/data-table/DataTableComponent";
import ConfirmDailogbox from "../../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import InvestmentDropdownFilter from "../../../../../components/investment-filter/InvestmentDropdownFilter";
import Loader from "../../../../../components/loader/Loader";
import { getThunkAllInvestmentByUserId } from "../../../../../store/features/investment/all-list/slice";
import { getThunkInvestmentById } from "../../../../../store/features/investment/create/slice";
import { getThunkInvestmentIdByProduct } from "../../../../../store/features/operation/allocate-earning/get-investment-id-product/slice";
import { initialPayloadGetAllocatedListData } from "../../../../../store/features/operation/allocation-slices/get-allocated-investor";
import {
  getThunkAllocatedInvestorListData,
  setAllocatedInvestorState,
} from "../../../../../store/features/operation/allocation-slices/get-allocated-investor/slice";
import { reverseThunkAllocationSlice } from "../../../../../store/features/operation/allocation-slices/reverse-allocation-slice/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import currencyFormat from "../../../../../utils/currency-formatter";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../../utils/toast-message";

export default function ReverseAllocationSlice() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [allocatedInvestmentList, setAllocatedInvestmentList] = useState<any>();
  // const [productId, setProductId] = useState(0);
  const [investmentId, setInvestmentId] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lpPartnerStakeTrackingId, setLpPartnerStakeTrackingId] = useState(0);
  const [productObjectByFilter, setProductObjectByFilter] = useState<any>({
    productID: 0,
    lookupCodeObj: "",
    address: "",
    zipCode: "",
  });
  const [distributionDoneDialog, setDistributionDoneDialog] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  console.log("yey", lpPartnerStakeTrackingId);

  const profileData = useAppSelector((state) => state.profile.data![0]);
  const allocatedInvestor = useAppSelector(
    (state) => state.getAllocatedInvestorList.data
  );
  const investmentList = useAppSelector(
    (state) => state.getListInvestmentsUserId.data.investments
  );
  const investmentDropdownList: any = [];

  investmentList?.map((item: any) => {
    //
    return investmentDropdownList.push({
      name: item.productName,
      code: item.productID,
    });
  });

  // const handleChange = (e: any) => {
  //   const { value } = e.target;
  //   setProductId(value);
  // };
  const reverseAllocationButton = (rowData: any) => {
    const handleReverseClick = () => {
      if (rowData.isEarningDistributed) {
        //
        setDistributionDoneDialog(true);
      } else {
        setOpenDialog(true);
        setLpPartnerStakeTrackingId(rowData.stakeTrackingID);
      }
    };
    return (
      <Button
        type="button"
        className="btn-allocationActive align-items-center justify-content-center p-1 w-70"
        onClick={(e) => {
          e.preventDefault();
          handleReverseClick();
          // onSelect();
        }}
        disabled={!rowData.isReversible}
      >
        <span>Reverse</span>
      </Button>
    );
  };
  // const dateFilterTemplate = (options: any) => {
  //   return (
  //     <Calendar
  //       value={options.value}
  //       onChange={(e) => options.filterCallback(e.value, options.index)}
  //       dateFormat="mm/dd/yy"
  //       placeholder="mm/dd/yyyy"
  //       mask="99/99/9999"
  //     />
  //   );
  // };
  const dataTableElement = [
    {
      // field: "cashEquivalent",
      header: "",
      body: reverseAllocationButton,
      style: { maxWidth: "10rem" },
    },
    {
      field: "rowNumber",
      header: "Lot#",
      style: { maxWidth: "7rem" },
      headerStyle: { maxWidth: "7rem" },
    },
    {
      field: "lpPartnerName",
      header: "Investor Account",
    },
    {
      field: "cashBalance",
      header: "Cash Balance",

      body: (rowData: any) => currencyFormat(rowData.cashBalance),
    },
    {
      field: "maxStartDate",
      header: "Allocation Date",
      body: (rowData: any) => formatDateMMDDYYY(rowData.maxStartDate),
      // dataType: "date",
      // filterElement: dateFilterTemplate,
    },
    {
      field: "units",
      header: "Allocated Units",
      bodyClassName: "p-2",
    },
  ];

  const onReverse = () => {
    setLoading(true);
    setOpenDialog(false);
    const parameter = {
      legalEntityId: fundDropdown.fundid,
      LPPartnerStakeTrackingId: lpPartnerStakeTrackingId,
    };

    dispatch(reverseThunkAllocationSlice(parameter))
      .then((resp) => {
        if (resp.payload._response.success) {
          successToastMessage(toast, "Allocation reversed successfully");
          setLpPartnerStakeTrackingId(0);
          const allocationData = {
            legalEntityId: fundDropdown.fundid,
            investmentId,
          };
          dispatch(getThunkAllocatedInvestorListData(allocationData)).finally(
            () => {
              setLoading(false);
            }
          );
        } else {
          errorToastMessage(toast, resp.payload._response.message);
          setLoading(false);
        }
      })
      .catch(() => {
        errorToastMessage(toast);
        setLoading(false);
      });
    // .finally(() => {
    //   setLoading(false);
    // });
  };
  const onCancel = () => {
    setLpPartnerStakeTrackingId(0);
    setOpenDialog(false);
  };
  useEffect(() => {
    dispatch(setAllocatedInvestorState(initialPayloadGetAllocatedListData));
    // setProductId(0);
    setProductObjectByFilter({
      productID: 0,
      lookupCodeObj: "",
      address: "",
      zipCode: "",
    });
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      setLoading(true);
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        userId: profileData?.userID,
      };
      dispatch(getThunkAllInvestmentByUserId(parameter)).finally(() => {
        setLoading(false);
      });
    }
  }, [fundDropdown]);
  useEffect(() => {
    if (productObjectByFilter.productID !== 0) {
      setLoading(true);
      const parameter = { productId: productObjectByFilter.productID };
      dispatch(getThunkInvestmentIdByProduct(parameter))
        .then((response) => {
          if (
            response.payload._response.data !== null &&
            response.payload._response.data.externalInvestmentId.key !== 0 &&
            response.payload._response.data.externalInvestmentId !== 0
          ) {
            dispatch(
              getThunkInvestmentById(productObjectByFilter?.productID)
            ).then((resp) => {
              console.log(
                "resp.payload.data.investorDetails[0]",
                resp.payload.data.investorDetails[0]
              );
              setProductObjectByFilter({
                ...productObjectByFilter,
                lookupCodeObj:
                  resp.payload.data.investorDetails[0].supplierProductCode,
                address:
                  resp.payload.data.investorDetails[0].productListingAddress,
                zipCode: resp.payload.data.investorDetails[0].zipCode,
              });
            });
            const allocationData = {
              legalEntityId: fundDropdown.fundid,
              investmentId:
                response.payload._response.data.externalInvestmentId.key,
            };
            setInvestmentId(
              response.payload._response.data.externalInvestmentId.key
            );
            dispatch(getThunkAllocatedInvestorListData(allocationData))
              .catch(() => {
                errorToastMessage(toast);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            errorToastMessage(toast, "Investment Id is not found");
            setProductObjectByFilter({
              productID: 0,
              lookupCodeObj: "",
              address: "",
              zipCode: "",
            });
            setLoading(false);
          }
        })
        .catch(() => {
          errorToastMessage(toast);
          setLoading(false);
        });
    } else {
      //
      dispatch(setAllocatedInvestorState(initialPayloadGetAllocatedListData));
    }
  }, [productObjectByFilter.productID]);
  useEffect(() => {
    const newData = allocatedInvestor.output.filter(
      (data: any) => data.partnerType !== "GP"
    );

    console.log("yes", newData);
    setAllocatedInvestmentList(
      getListDateConversion(newData, ["maxStartDate"])
    );
  }, [allocatedInvestor]);
  const handleOk = () => {
    setDistributionDoneDialog(false);
  };

  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <ConfirmDailogbox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onConfirm={onReverse}
        onCancel={onCancel}
        textContent="Are you sure you want to reverse this allocation?"
      />
      <Dialog
        visible={distributionDoneDialog}
        className="w-100 md:w-3"
        headerClassName="no-header"
        contentClassName=" m-0"
        closable={false}
        blockScroll
        onHide={() => setDistributionDoneDialog(false)}
      >
        <p className="pageHeader text-center pt-5">
          Earnings for this investor have been distributed. You cannot reverse
          this allocation. If you want to reverse this allocation, please
          reverse the earnings.
        </p>

        <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
          <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleOk();
              }}
              className="btn-navActive"
            >
              <span className="ml-auto mr-auto"> Ok</span>
            </Button>
          </div>
        </div>
      </Dialog>

      <div className=" h-100 center-card">
        <div className="col-12 ">
          <div className="card my-3">
            <Card>
              <div className="headercard d-flex grid">
                <div className="col-12 md:col-6">
                  <h2 style={{ fontWeight: "bold" }}>Reverse Allocation</h2>
                </div>
                <div className="col-12 md:col-6 end-div">
                  <h6> </h6>
                </div>
              </div>

              <div>
                <ProgressBar
                  value={100}
                  showValue={false}
                  className="m-2"
                ></ProgressBar>
              </div>

              <div>
                <div className="mt-4">
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
                      {/* <label className="inputLabel">Select Investment</label>
                      <Dropdown
                        inputId="investment"
                        name="investment"
                        value={productId}
                        options={
                          investmentDropdownList === null
                            ? []
                            : investmentDropdownList
                        }
                        filter
                        resetFilterOnHide={true}
                        showFilterClear={true}
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Select Investment"
                        className="card-inputBox"
                        // showOnFocus
                        onChange={(e) => {
                          e.preventDefault();
                          handleChange(e);
                        }}
                      /> */}

                      <InvestmentDropdownFilter
                        listPage={false}
                        setProductObjectByFilter={setProductObjectByFilter}
                        productObjectByFilter={productObjectByFilter}
                        productState="PreFunded"
                        pooledFund="Fixed"
                      />
                      <div className="formgrid grid">
                        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                          <label className="inputLabel">Investment Id </label>
                          <InputText
                            name="productId"
                            value={
                              productObjectByFilter?.productID === 0
                                ? ""
                                : productObjectByFilter?.productID
                            }
                            // value={investmentID === 0 ? "" : investmentID}
                            // onChange={(e) => handleChange(e)}
                            className="card-inputBox"
                            placeholder="Investment Id"
                            disabled
                          />
                        </div>
                        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                          <label className="inputLabel">Lookup Code </label>
                          <InputText
                            name="lookupCode"
                            value={productObjectByFilter?.lookupCodeObj}
                            // onChange={(e) => handleChange(e)}
                            className="card-inputBox"
                            placeholder="Lookup code"
                            disabled
                          />
                        </div>
                        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                          <label className="inputLabel">Zip Code </label>
                          <InputText
                            name="zipCode"
                            value={productObjectByFilter?.zipCode}
                            // onChange={(e) => handleChange(e)}
                            className="card-inputBox"
                            placeholder="Zip Code"
                            disabled
                          />
                        </div>
                        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                          <label className="inputLabel">Address </label>
                          <InputText
                            name="address"
                            value={productObjectByFilter?.address}
                            // onChange={(e) => handleChange(e)}
                            className="card-inputBox"
                            placeholder="Address"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableComponent
                valueData={allocatedInvestmentList || []}
                // valueData={testdata || []}
                fieldsElements={dataTableElement}
                noGridLines={true}
                isPaginationAllowed={true}
                className="allocateSliceTable"
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
