import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function uploadPersonaImgFileServices(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_KYC_PERSONA_URL
    }/Persona/UploadPersonaImgfile?LegalEntityId=${
      data.legalEntityId
    }&InvestorId=${data.investorId}&InvestingAccountId=${
      data.investingAccountId
    }`,
    data.filename,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}
