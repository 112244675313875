import { createSlice } from "@reduxjs/toolkit";

import { IAssetResponse } from "../../types";

interface IDot {
  data: IAssetResponse;
  loading: string;
  error?: string | null;
}

const initialState: IDot = {
  data: {
    url: "",
    banner: "",
    image: "",
    Dropdown: "",
  },
  loading: "loading",
  error: undefined,
};

const slice = createSlice({
  name: "getAssetResponseStatus",
  initialState,
  reducers: {
    setUrlState: (state, action) => {
      console.log("setUrlState", state, action);
      state.data.url = action.payload;
    },
    setBannerState: (state, action) => {
      console.log("setBannerState", state, action);

      state.data.banner = action.payload;
    },
    setImageState: (state, action) => {
      console.log("setImageState", state, action);

      state.data.image = action.payload;
    },
    setDropdownState: (state, action) => {
      console.log("setImageState", state, action);

      // state.data.image = action.payload;
    },
  },
});
export const { setUrlState, setBannerState, setImageState, setDropdownState } =
  slice.actions;

// export const { setUrlState, setDropdownState } = slice.actions;
export default slice.reducer;
