import { Button } from "primereact/button";
// import { Calendar } from "primereact/calendar";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
// import { getThunkPortfolioAssetDropdown } from "../../../../store/assetdropdown/slice";
import { getPortfolioGetLegalEntityList } from "../../../../store/features/dropdown/slice";
import { getUserProfileDetailsThunk } from "../../../../store/features/profile/slice";
import { GetApprovedAccreddInvestorsForAllFundsReport } from "../../../../store/features/reports/get-approved-accredd-investors-for-allFunds/slice";
import { GetInvestorsVerifiedByAccreddReport } from "../../../../store/features/reports/getinvestors-verified-by-accredd/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  convertUTCToLocalDate,
  getListDateConversion,
  // getListDateConversion,
} from "../../../../utils/date-formatter";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
// import InvestorLoginHistorySkeleton from "./InvestorLoginHistorySkeleton";

export default function GetInvestorsVerifiedByAccredd() {
  const dispatch = useAppDispatch();

  const InvestorsVerifiedByAccredd = useAppSelector(
    (state) => state.GetInvestorsVerifiedByAccredd.data
  );

  const getAllFundes = useAppSelector(
    (state) => state.GetApprovedAccreddInvestorsForAllFunds.data
  );
  const [loading, setloading] = useState<any>(false);
  const [exitDate, setExitDate] = useState<any>();
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const dataTableElements = [
    {
      field: "investorName",
      header: "Investor Account",
    },
    {
      field: "emailAddress",
      header: "Email Address",
      //   body: formatDate,
      //   filterElement: dateFilterTemplate,
    },
    {
      field: "modifiedDate",
      header: "Updated Date",
      dataType: "date",
      body: (rowData: any) => {
        return rowData.modifiedDate
          ? convertUTCToLocalDate(rowData.modifiedDate, "mm/dd/yyyy")
          : rowData.modifiedDate;
      },
      filterElement: dateFilterTemplate,
    },
  ];

  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = () => {
      const parameters = {
        LegalEntityId: rowData.legalEntityID,
      };

      (async () => {
        // dispatch(getThunkPortfolioAssetDropdown()).then(() => {
        // dispatch(getUserProfileDetailsThunk({ Slug: tenentName! }));
        // });

        dispatch(getUserProfileDetailsThunk({ Slug: rowData.urlName })).then(
          (res: any) => {
            if (res.payload?._response?.data !== null) {
              const LegalEntityparameters = {
                Email: res.payload?._response?.data[0]?.emailID,
              };
              dispatch(getPortfolioGetLegalEntityList(LegalEntityparameters));
            }
          }
        );
      })();

      setloading(true);
      dispatch(GetInvestorsVerifiedByAccreddReport(parameters))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
      dispatch(GetApprovedAccreddInvestorsForAllFundsReport(parameters))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });

      //
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={handleEdit}
          >
            <span className="text-white">Show Details</span>
          </Button>
        </div>
      </div>
    );
  };
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const AllFundDataTableElements = [
    {
      field: "investorCount",
      header: "Investor Count",
    },
    {
      field: "emailAddress",
      header: "Email Address",
      //   body: formatDate,
      //   filterElement: dateFilterTemplate,
    },
    {
      field: "legalEntityName",
      header: "LegalEntity Name",
    },
    {
      // field: "legalEntityName",
      header: "",
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
    },
  ];
  const dropdown = useAppSelector((state) => state.navbardropdown.data);

  useEffect(() => {
    if (dropdown.fundid !== undefined) {
      const parameters = {
        LegalEntityId: dropdown.fundid,
      };
      setloading(true);
      dispatch(GetInvestorsVerifiedByAccreddReport(parameters))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
      dispatch(GetApprovedAccreddInvestorsForAllFundsReport(parameters))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    }
  }, [dropdown.fundid]);

  useEffect(() => {
    setExitDate(
      getListDateConversion(InvestorsVerifiedByAccredd, ["modifiedDate"])
    );
  }, [InvestorsVerifiedByAccredd]);

  return (
    <>
      {" "}
      <>
        {loading && <Loader />}
        {/* <div className="grid">
          {loading && <Loader />}
          <div className="col-12 md:col-6 mt-2">
            <div className="card pgHeaderWrap md:my-2 mt-3">
              <h1 className="pageHeader">Investors Verified By Accredd</h1>
              <p className="pageSubHeader"> </p>
            </div>
          </div>
          <div className="col-12 md:col-6 mt-2">
            <div></div>
          </div>
        </div> */}
        {/* <div className="grid"> */}
        {/* <div className="col-12">
          <div className="card my-3 tabWithoutboxshadow"> */}
        {/* <Card> */}
        {/* <h4 className="cardHeader"> Investors Verified By Accredd</h4>
        <div className="row my-4 d-flex justify-content-between"></div> */}
        {dropdown.fundid !== 0 && (
          <>
            {" "}
            <DataTableComponent
              valueData={exitDate || []}
              fieldsElements={dataTableElements}
              isPaginationAllowed={true}
              scrollable={true}
              HeadingName="Investors Verified By Accredd"
              isFullView={true}
              maxHeight="calc(-128px + 100vh)"
              className="gridcell"
            />
          </>
        )}

        {dropdown.fundid === 0 && (
          <>
            {" "}
            <DataTableComponent
              valueData={getAllFundes! || []}
              fieldsElements={AllFundDataTableElements}
              isPaginationAllowed={true}
              scrollable={true}
              HeadingName="Investors Verified By Accredd"
              maxHeight="calc(-128px + 100vh)"
              className="gridcell"
            />
          </>
        )}

        {/* <DataTableComponent
                  valueData={exitDate}
                  fieldsElements={dataTableElements}
                /> */}
        {/* </Card> */}
        {/* </div>
        </div> */}
        {/* </div> */}
      </>
    </>
  );
}
