// import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

// import { getSetupFundReport } from "../../../../store/features/reports/Setup-Fund-Report/slice";
import Loader from "../../../../components/loader/Loader";
import { getThunkOutstandingBlueSkyFilingReport } from "../../../../store/features/reports/Outstanding-Bluesky-Filings/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";

export default function OutstandingBlueskyFilings() {
  const dispatch = useAppDispatch();

  const outstanding = useAppSelector(
    (state) => state.OutstandingBlueSkyFiling.data
  );
  // const [exitDate, setExitDate] = useState<any>();
  const [loading, setloading] = useState<any>(false);

  const dataTableElements = [
    {
      field: "legalEntityName",
      header: "Fund Name",
      style: { minWidth: "240px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.legalEntityName}>
          {rowData?.legalEntityName}
        </div>
      ),
    },
    {
      field: "filingType",
      header: "Type",
      style: { minWidth: "240px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.filingType}>
          {rowData?.filingType}
        </div>
      ),
      //   body: formatDate,
      //   filterElement: dateFilterTemplate,
    },
    {
      field: "stateName",
      header: "State",
    },
    {
      field: "status",
      header: "Status",
    },
  ];

  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  useEffect(() => {
    if (dropdown.fundid !== undefined) {
      const parameters = {
        legalEntityId: dropdown.fundid,
      };
      console.log("parameters", parameters);
      setloading(true);
      dispatch(getThunkOutstandingBlueSkyFilingReport(parameters))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    } else {
      setloading(false);
    }
  }, [dropdown.fundid]);
  // useEffect(() => {
  //   setExitDate(getExitDateConversion(outstanding));
  // }, [outstanding]);
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      {loading && <Loader />}
      {/* <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Outstanding Blue Sky Filling Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div> */}
      {/* <div className="grid">
        <div className="col-12"> */}
      {/* <div className="card my-3 tabWithoutboxshadow"> */}
      {/* <Card>
              <h4 className="cardHeader">
                {" "}
                Outstanding Blue Sky Filling Report
              </h4>
              <div className="row my-4 d-flex justify-content-between"></div> */}
      <DataTableComponent
        valueData={outstanding || []}
        fieldsElements={dataTableElements}
        isPaginationAllowed={true}
        isDownload={true}
        scrollable={true}
        isFullView={true}
        maxHeight={"calc(-145px + 100vh)"}
        HeadingName="Outstanding Blue Sky Filling Report"
        fileName="Outstanding Blue Sky Filling Report"
        className="gridcell"
        excelFieldsElements={dataTableElements}
      />
      {/* </Card> */}
      {/* </div> */}
      {/* </div>
      </div> */}
    </>
  );
}
