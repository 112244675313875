import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLookupCodeListService } from "../../../../services/dropdown/dropdown";
import error from "../../../../utils/error";
import { ISupplierState } from ".";

const initialState: ISupplierState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    lookupDetails: [],
    city: [],
    stateDetails: [],
  },
};
export const getThunkLookupCode = createAsyncThunk(
  "lookupcodeList",
  async (data: any) => {
    try {
      const response = await getLookupCodeListService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "lookupcodeList",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkLookupCode.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkLookupCode.fulfilled, (state, action) => {
      state.loading = "succeeded";
      if (action.payload.data !== null) {
        state.data = action.payload._response.data;
      }
    });
    builder.addCase(getThunkLookupCode.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
