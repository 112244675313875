// import isEqual from "lodash/isEqual";
import _ from "lodash";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../components/loader/Loader";
import { getThunkDropdown } from "../../../../store/features/dropdown/dropdown-list/slice";
import { setAddCancelState } from "../../../../store/features/operation/setup-fund/create-update-state/slice";
import { getThunkSetupFundEntityList } from "../../../../store/features/operation/setup-fund/get-setup-entity-list/slice";
import { GetSetupFundPayload } from "../../../../store/features/operation/setup-fund/get-setup-fund";
import {
  getThunkSetupFund,
  setSetupFundForm,
  // setSetupFundForm,
} from "../../../../store/features/operation/setup-fund/get-setup-fund/slice";
import { setStateChangeCheck } from "../../../../store/features/operation/setup-fund/setup-fund-state-check/slice";
import { setSetupTabNameState } from "../../../../store/features/operation/setup-fund/setup-fund-tabname/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { errorToastMessage } from "../../../../utils/toast-message";
import SetupFundAndManager from "./SetupFundAndManager";
import SetupFundBankProfile from "./SetupFundBankProfile";
import SetupFundEdgarFiling from "./SetupFundEdgarFiling";
import SetupFundThirdParty from "./SetupFundThirdParty";
import {
  BankMailInfoFunction,
  EdgarFilingFunction,
  FundManagerInfoFunction,
  ThirdPartyApiFunction,
} from "./setup-fund-state";

interface IProps {
  pageName: any;
}

export default function SetupFundForm(props: IProps) {
  const { pageName } = props;
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const tabNameField = useAppSelector((state) => state.setupFundTabName.data);
  const SetupFundInfo = useAppSelector((state) => state.getSetupFundInfo.data);
  const statecheckSetupFund = useAppSelector(
    (state) => state.statecheckSetupFund.data
  );
  const addCancelSetup = useAppSelector(
    (state) => state.addCancelSetupState.data
  );
  const entityListId = useAppSelector(
    (state) => state.getSetupFundEntityList.data
  );
  let content;
  switch (tabNameField.tabName) {
    case "Fund":
      content = <SetupFundAndManager pageName={pageName} />;
      break;

    case "Bank":
      content = <SetupFundBankProfile pageName={pageName} />;
      break;
    case "EdgarFiling":
      content = <SetupFundEdgarFiling pageName={pageName} />;
      break;
    case "ThirdParty":
      content = <SetupFundThirdParty pageName={pageName} />;
      break;

    default:
      content = <></>;
      break;
  }
  useEffect(() => {
    if (pageName === "Reports") {
      dispatch(
        setAddCancelState({ ...addCancelSetup, add: false, cancel: false })
      );
    }
  }, []);

  useEffect(() => {
    if (addCancelSetup.add === true) {
      console.log("r in fund form", addCancelSetup);
      dispatch(
        setSetupTabNameState({
          ...tabNameField,
          tabName: "Fund",
          clickPageName: "Fund",
        })
      );
      dispatch(setSetupFundForm({ data: GetSetupFundPayload }));
    }
  }, [addCancelSetup.add]);
  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameters = {
        legalEntityID: fundDropdown.fundid,
        supplierID: 0,
      };
      dispatch(
        setSetupTabNameState({
          ...tabNameField,
          tabName: "Fund",
          clickPageName: "Fund",
        })
      );

      dispatch(getThunkDropdown(parameters));
      if (addCancelSetup.add !== true) {
        dispatch(
          setAddCancelState({ ...addCancelSetup, fundId: fundDropdown.fundid })
        );
      }
    }
  }, [fundDropdown, addCancelSetup.add]);
  useEffect(() => {
    if (
      addCancelSetup.fundId !== undefined &&
      addCancelSetup.fundId !== 0 &&
      addCancelSetup.add !== true
    ) {
      console.log("s in fund form", addCancelSetup.add);
      const parameter = {
        legalEntityId: addCancelSetup.fundId,
      };
      dispatch(getThunkSetupFundEntityList(parameter));
    }
  }, [addCancelSetup]);
  useEffect(() => {
    console.log("ad FundForm", addCancelSetup);
    if (
      addCancelSetup.add !== true &&
      addCancelSetup.fundId !== 0 &&
      addCancelSetup.fundId !== undefined &&
      entityListId?.gpllcEntityId
    ) {
      console.log("t in fund form", addCancelSetup.add);
      const parameter = {
        legalEntityId: addCancelSetup.fundId,
        gpLegalEntityId: entityListId?.gpllcEntityId || 0,
      };

      setLoading(true);
      dispatch(getThunkSetupFund(parameter))
        .then((response) => {
          setLoading(false);
          if (response.payload._response.success === false) {
            errorToastMessage(toast, response.payload._response.message);

            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
          errorToastMessage(toast);
          // dispatch(setSetupFundForm({ data: GetSetupFundPayload }));
          // setFieldName("");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [entityListId?.gpllcEntityId, addCancelSetup.add, addCancelSetup.fundId]);

  useEffect(() => {
    console.log("useEffect", SetupFundInfo);
    console.log("ddd", entityListId);

    //
    setLoading(true);
    const list = ThirdPartyApiFunction(
      SetupFundInfo?.thirdPartyAPIkeys,
      statecheckSetupFund.thirdPartyApi
    );
    const fundManagerTab = FundManagerInfoFunction(
      SetupFundInfo,
      statecheckSetupFund.fundManageApiData,
      entityListId
    );
    const bankMailInfoTab = BankMailInfoFunction(
      SetupFundInfo?.fundbankAndMailingDetails,
      statecheckSetupFund.bankMailInfoApiData
    );
    const edgarFilingTab = EdgarFilingFunction(
      SetupFundInfo?.edgarDetails,
      statecheckSetupFund.edgarFilingApiData
    );
    console.log("testing", fundManagerTab);
    (async () => {
      await dispatch(
        setStateChangeCheck({
          ...statecheckSetupFund,
          thirdPartyApi: list,
          fundManageApiData: fundManagerTab,
          bankMailInfoApiData: bankMailInfoTab,
          edgarFilingApiData: edgarFilingTab,
        })
      );
      setLoading(false);
    })();
  }, [SetupFundInfo, entityListId]);
  console.log("check state", statecheckSetupFund);

  const tabChangeHandler = (tab?: any) => {
    setLoading(true);
    switch (tabNameField.tabName) {
      case "Fund":
        // // eslint-disable-next-line no-case-declarations
        // const changes = _.differenceWith(
        //   _.toPairs(statecheckSetupFund.fundManageApiData.PreviousState),
        //   _.toPairs(statecheckSetupFund.fundManageApiData.NewState),
        //   _.isEqual
        // );
        // console.log("chances", changes);
        // console.log("gjjjjjjjjjjjj", Object.fromEntries(changes));

        if (
          !_.isEqual(
            statecheckSetupFund.fundManageApiData.PreviousState,
            statecheckSetupFund.fundManageApiData.NewState
          )
        ) {
          console.log("hello testing");
          setLoading(false);
          dispatch(
            setSetupTabNameState({
              ...tabNameField,
              openDialog: true,
              clickPageName: tab,
            })
          );
        } else {
          dispatch(
            setSetupTabNameState({
              ...tabNameField,
              clickPageName: tab,
              tabName: tab,
            })
          );
          setLoading(false);
        }
        break;

      case "Bank":
        if (
          !_.isEqual(
            statecheckSetupFund.bankMailInfoApiData.PreviousState,
            statecheckSetupFund.bankMailInfoApiData.NewState
          )
        ) {
          //  console.log("hello testing");
          setLoading(false);
          dispatch(
            setSetupTabNameState({
              ...tabNameField,
              openDialog: true,
              clickPageName: tab,
            })
          );
        } else {
          dispatch(
            setSetupTabNameState({
              ...tabNameField,
              clickPageName: tab,
              tabName: tab,
            })
          );
          setLoading(false);
        }
        break;
      case "EdgarFiling":
        if (
          !_.isEqual(
            statecheckSetupFund.edgarFilingApiData.PreviousState,
            statecheckSetupFund.edgarFilingApiData.NewState
          )
        ) {
          // console.log("hello testing");
          setLoading(false);
          dispatch(
            setSetupTabNameState({
              ...tabNameField,
              openDialog: true,
              clickPageName: tab,
            })
          );
        } else {
          dispatch(
            setSetupTabNameState({
              ...tabNameField,
              clickPageName: tab,
              tabName: tab,
            })
          );
          setLoading(false);
        }
        break;
      case "ThirdParty":
        if (
          !_.isEqual(
            statecheckSetupFund.thirdPartyApi.PreviousState,
            statecheckSetupFund.thirdPartyApi.NewState
          )
        ) {
          console.log("hello testing");
          setLoading(false);
          dispatch(
            setSetupTabNameState({
              ...tabNameField,
              openDialog: true,
              clickPageName: tab,
            })
          );
        } else {
          dispatch(
            setSetupTabNameState({
              ...tabNameField,
              clickPageName: tab,
              tabName: tab,
            })
          );
          setLoading(false);
        }
        break;

      default:
        setLoading(false);
        break;
    }
  };
  console.log("setup state", SetupFundInfo);
  return (
    <>
      <Dialog
        visible={tabNameField.openDialog}
        className="w-100 md:w-4"
        onHide={() => {
          dispatch(
            setSetupTabNameState({ ...tabNameField, openDialog: false })
          );
        }}
        headerClassName="no-header"
        contentClassName="top-lr-content"
      >
        <p className="pageHeader text-center mb-5 pt-5">
          Your unsaved edits will be discarded. Would you like to proceed?
        </p>

        <div className="flex flex-row flex-wrap sm:flex-none  col-12">
          <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  setSetupTabNameState({
                    ...tabNameField,
                    openDialog: false,
                    tabName: tabNameField.clickPageName,
                    changeState: false,
                  })
                );
              }}
              className="btn-nav"
            >
              <span className="ml-auto mr-auto"> Ok</span>
            </Button>
          </div>
          <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  setSetupTabNameState({
                    ...tabNameField,
                    openDialog: false,
                    // changeState: true,
                  })
                );
              }}
              className="btn-navActive "
            >
              <span className="ml-auto mr-auto"> Cancel</span>
            </Button>
          </div>
        </div>
      </Dialog>

      <Toast ref={toast} className="themeToast" />
      {loading === true ? <Loader /> : <></>}

      <div>
        <div className="m-0 ">
          <div className="col-12 p-0">
            <div className="tabHeader-wrap grid">
              <div className=" col-6 md:col-3">
                <Button
                  className={
                    tabNameField.tabName === "Fund"
                      ? "tabHeadText Active"
                      : "tabHeadText"
                  }
                  onClick={(e) => {
                    e.preventDefault();

                    tabChangeHandler("Fund");
                  }}
                >
                  <span className="ml-auto mr-auto ">Fund & Manager Info</span>
                </Button>
              </div>

              <div className=" col-6 md:col-3">
                <Button
                  className={
                    tabNameField.tabName === "Bank"
                      ? "tabHeadText Active"
                      : "tabHeadText"
                  }
                  disabled={
                    SetupFundInfo?.fundProfileDetails?.fundTypeId === null ||
                    SetupFundInfo?.fundProfileDetails?.fundName === undefined
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    tabChangeHandler("Bank");
                  }}
                >
                  <span className="ml-auto mr-auto ">Bank / Mailing Info</span>
                </Button>
              </div>
              <div className=" col-6 md:col-3">
                <Button
                  className={
                    tabNameField.tabName === "ThirdParty"
                      ? "tabHeadText Active"
                      : "tabHeadText"
                  }
                  disabled={
                    SetupFundInfo?.fundProfileDetails?.fundTypeId === null ||
                    SetupFundInfo?.fundProfileDetails?.fundName === undefined
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    tabChangeHandler("ThirdParty");
                  }}
                >
                  <span className="ml-auto mr-auto ">
                    Third Party Integration
                  </span>
                </Button>
              </div>
              <div className=" col-6 md:col-3">
                <Button
                  className={
                    tabNameField.tabName === "EdgarFiling"
                      ? "tabHeadText Active"
                      : "tabHeadText"
                  }
                  disabled={
                    SetupFundInfo?.fundProfileDetails?.fundTypeId === null ||
                    SetupFundInfo?.fundProfileDetails?.fundName === undefined
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    tabChangeHandler("EdgarFiling");
                  }}
                >
                  <span className="ml-auto mr-auto ">Edgar Filing</span>
                </Button>
              </div>
            </div>{" "}
          </div>
          <div className="h-100">{content}</div>
        </div>
      </div>
    </>
  );
}
