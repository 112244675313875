import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { addInvestmentStep1Service } from "../../../../services/investment/investment";
import {
  bulkUpdateIndexThunkSectionService,
  getSectionByProductIdService,
} from "../../../../../../services/investment/investment";
import error from "../../../../../../utils/error";
import { ISectionListParameter, ISectionListState } from ".";

const initialState: ISectionListState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};

export const getThunkSectionProductById = createAsyncThunk(
  "section/getsectionproductbyId",
  async (data: ISectionListParameter) => {
    try {
      const response = await getSectionByProductIdService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const bulkUpdateIndexThunkSection = createAsyncThunk(
  "section/bulkUpdateindexThunkInvestmentFile",
  async (data: any) => {
    try {
      console.log("sli", data);
      const response = await bulkUpdateIndexThunkSectionService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "section",
  initialState,
  reducers: {
    // setSection: (state, action) => {
    //   state.data = action.payload;
    //   //
    // },
  },
  extraReducers(builder): void {
    // Investment

    builder.addCase(getThunkSectionProductById.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkSectionProductById.fulfilled, (state, action) => {
      state.loading = "succeeded";
      if (action.payload.data !== null) {
        state.data = action.payload.data;
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(getThunkSectionProductById.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
    builder.addCase(bulkUpdateIndexThunkSection.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(bulkUpdateIndexThunkSection.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("state", action.payload);
      if (action.payload.data !== null) {
        state.data = action.payload.data;
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(bulkUpdateIndexThunkSection.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});

// export const { setSection } = slice.actions;
export default slice.reducer;
