import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
// import { Column } from "primereact/column";
// import { ColumnGroup } from "primereact/columngroup";
// import { Row } from "primereact/row";
// import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { K1ReconciliationByInvestorThunk } from "../../../store/features/taxmanagement/k1reconciliationbyinvestot/k1reconciliationbyinvestor/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { exportExcel } from "../../../utils/sponsor/helper";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";

export default function K1ReconciliationByInvestor() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const [openDailog, setOpenDailog] = useState(false);
  const previousYear: any = new Date().getFullYear();
  const currentYear = previousYear - 1;
  // const portState = useAppSelector((state) => state.GetMenuList.loading);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const list = useAppSelector(
    (state) => state.K1ReconciliationByInvestor?.data2
  );
  const title = useAppSelector(
    (state) => state.K1ReconciliationByInvestor?.data1
  );

  const [dataCreateElemnts, setDataCreateElemnts] = useState<any>();
  const [dataTableElements, setdataTableElements] = useState<any>();
  const [stateName, setName] = useState<any>(false);
  const [loading, setloading] = useState<any>(false);

  // const datatableArrayObj: any = [];
  const i = title! ? JSON.parse(title!) : [];

  const prepareData = (lists: any, titles: any) => {
    if (lists && title) {
      // console.log("prepareData===", data);
      const values = lists ? JSON.parse(lists!) : [];
      const items = titles! ? JSON.parse(titles!) : [];

      console.log("K1ReconciliationByInvestor list", values);
      console.log("K1ReconciliationByInvestor title", items);

      console.log("values", values);
      console.log("items", items);

      for (const value of values) {
        delete value.GeneratedRank;
        delete value.K1MasterId;
      }
      console.log("values===", values);
      console.log("items====", items);
      // console.log();
      return values;
    } else {
      return [];
    }
  };

  const createcolumns = (data: any) => {
    if (data !== undefined && data !== "" && data.length > 0) {
      //   console.log("datatableArrayObj====", datatableArrayObj);
      console.log("datatableArrayObj[0]====", data);
      console.log("datatableArrayObj key====", Object.keys(data[0]));

      // const value = Object.values(data[0]);
      const headerData = Object.keys(data[0]).map((key, index) => {
        console.log("createcolumns", data);

        const headerValue =
          i.find((item: any) => item.LPPartnerID === Number(key))
            ?.PartnerName || key;

        return {
          field: key,
          header: ["K1LabelName", "ChildLabelName"].includes(key)
            ? ""
            : headerValue,
          style: { minWidth: "300px" },
        };
      });
      const partnerIDIndex = headerData?.findIndex(
        (item: any) => item.field === "K1LabelName"
      );
      const partnerNameIndex = headerData?.findIndex(
        (item: any) => item.field === "ChildLabelName"
      );

      // If PartnerID and PartnerName are found, move them to indexes 0 and 1 respectively
      if (partnerIDIndex !== -1) {
        const partnerIDField = headerData?.splice(partnerIDIndex, 1)[0];
        headerData?.splice(0, 0, partnerIDField);
      }

      if (partnerNameIndex !== -1) {
        const partnerNameField = headerData?.splice(partnerNameIndex, 1)[0];
        headerData?.splice(1, 0, partnerNameField);
      }

      console.log("headerData", headerData);
      return headerData;
    } else {
      return [];
    }
  };
  // const newData: any[] = []; // Declare an array to hold the new data
  useEffect(() => {
    // prepareData(list, title);
    const preData = prepareData(list, title);
    setdataTableElements(preData);
    setDataCreateElemnts(createcolumns(preData));
  }, [list, title]);
  console.log("dataTableElements", dataTableElements);
  const formik = useFormik({
    initialValues: {
      Date: currentYear.toString(),
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter year";
      }

      return errors;
    },
    onSubmit: () => {
      //  const pdata = prepareData(datatableArrayObj)
      // setdataTableElements(prepareData(datatableArrayObj));
      // setDataCreateElemnts(createcolumns(datatableArrayObj));
      setloading(true);

      const params = {
        LegalEntityId: fundDropdown.fundid,
        TaxYear: formik.values.Date,
      };

      dispatch(K1ReconciliationByInvestorThunk(params))
        .then((res: any) => {
          console.log("clicked");
          setName(false);
          // setdataTableElements(prepareData(datatableArrayObj));
          // setDataCreateElemnts(createcolumns(datatableArrayObj));
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    },
  });
  useEffect(() => {
    if (formik.values.Date.toString() === currentYear.toString()) {
      setloading(true);

      const params = {
        LegalEntityId: fundDropdown.fundid,
        TaxYear: formik.values.Date,
      };

      dispatch(K1ReconciliationByInvestorThunk(params))
        .then((res: any) => {
          console.log("clicked");
          setName(false);
          // setdataTableElements(prepareData(datatableArrayObj));
          // setDataCreateElemnts(createcolumns(datatableArrayObj));
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    } else {
      setName(true);
    }
  }, [formik.values.Date]);
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  //   const footerGroup = (
  //     <ColumnGroup>
  //       <Row>
  //         <Column
  //           footer="Total Calculated "
  //           colSpan={2}
  //           // footerStyle={{ textAlign: "right" }}
  //         />
  //         {footerList.map((i: any) => {
  //           return <Column key={i.ChildName} footer={i.Amount} />;
  //         })}
  //       </Row>
  //       <Row>
  //         <Column
  //           footer="total Reported from K-1 Form  "
  //           colSpan={2}
  //           // footerStyle={{ textAlign: "right" }}
  //         />
  //         {footerList.map((i: any) => {
  //           return <Column key={i.ChildName} footer={i.Amount} />;
  //         })}
  //       </Row>
  //       <Row>
  //         <Column
  //           footer="Delta"
  //           colSpan={2}
  //           // footerStyle={{ textAlign: "right" }}
  //         />
  //         <Column />
  //       </Row>
  //     </ColumnGroup>
  //   );

  useEffect(() => {
    setName(true);
  }, [formik.values.Date]);
  useEffect(() => {
    setName(true);
    formik.resetForm();
  }, [fundDropdown.fundid]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">K1 Reconciliation by Investor</h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(dataTableElements, "K1 Reconciliation by Investor")
              }
              data-pr-tooltip="exportExcel"
              disabled={
                dataTableElements === undefined ||
                dataTableElements?.length === 0 ||
                dataTableElements === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  dataTableElements === undefined ||
                  dataTableElements?.length === 0 ||
                  dataTableElements === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <>
              {" "}
              <div className="">
                <div className="formgrid grid col">
                  <div className="d-block sm:d-flex sm:flex-column mr-2  md:col-6 col-12">
                    {/* <label className="inputLabel">Year *</label> */}
                    <div className="card flex justify-content-center">
                      <Calendar
                        name="Date"
                        value={
                          formik.values.Date === null ||
                          formik.values.Date === ""
                            ? undefined
                            : new Date(+formik.values.Date, 0, 1)
                        }
                        onChange={(e) => {
                          const selectedYear = new Date(
                            Number(e.value)
                          ).getFullYear();
                          formik.setFieldValue("Date", selectedYear);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Date"),
                          "card-inputBox": true,
                        })}
                        placeholder="Select year"
                        view="year"
                        dateFormat="yy"
                      />
                      {getFormErrorMessage("Date")}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="mr-4 btn-nav w-7rem justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        // setIsFormSubmitted(true);
                        // console.log("clicked");
                        formik.resetForm();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      className="btn-nav w-7rem justify-content-center"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        // setIsFormSubmitted(true);
                        formik.handleSubmit();
                      }}
                    >
                      Show
                    </Button>
                  </div>
                </div>
              </div>
            </>{" "}
            <div className="">
              {dataTableElements && !stateName && (
                <DataTableComponent
                  valueData={dataTableElements}
                  fieldsElements={dataCreateElemnts}
                  // footerElement={footerGroup}
                  // isDownload={true}
                  isPaginationAllowed={true}
                  fileName="K1 Reconcilation By Investor"
                  scrollable={true}
                  className="gridcell"
                  columnResizeMode="expand"
                  maxHeight={"calc(-265px + 100vh)"}
                  isFullView={true}
                  setOpenDailog={setOpenDailog}
                  openDailog={openDailog}
                />
              )}
            </div>
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
