import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AddK1InputSupplierFormService } from "../../../../../services/taxmanagement/tax";
import error from "../../../../../utils/error";
import { IK1InputSupplierSaveFormState } from ".";

const initialState: IK1InputSupplierSaveFormState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const addThunkK1InputSupplierForm = createAsyncThunk(
  "addK1InputSupplierForm",
  async (data: any) => {
    try {
      const response = await AddK1InputSupplierFormService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "addK1InputSupplierForm",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(addThunkK1InputSupplierForm.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(addThunkK1InputSupplierForm.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("set", action.payload);
      //   state.data =
      //     action.payload._response.data !== null
      //       ? action.payload._response.data
      //       : [];
      //   state.success = action.payload._response.success;
      //   state.message =
      //     action.payload._response.message === null
      //       ? undefined
      //       : action.payload._response.message;
    });
    builder.addCase(addThunkK1InputSupplierForm.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});
export default slice.reducer;
