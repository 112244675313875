import { IGetAdminMenuUsersDetailsparameters } from "../../store/features/admin/getadminmenuusersdetails";
import { ISendDynamicMailparameters } from "../../store/features/admin/senddynamicmail";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";
import { IEnableOrDisableUserInADparameters } from "./../../store/features/admin/enableordisableuserInad/index";
export function GetAdminMenuUsersDetailsService(
  data: IGetAdminMenuUsersDetailsparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/AdminMenu/GetAdminMenuUsersDetails?LegalEntityId=${data.LegalEntityId}`
  );
}

export function EnableOrDisableUserInADService(
  data: IEnableOrDisableUserInADparameters
) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/AdminMenu/EnableOrDisableUserInAD`,
    data
  );
}

export function SendDynamicMailService(data: ISendDynamicMailparameters) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/AdminMenu/SendDynamicMail`,
    data
  );
}
