import axios from "axios";
// import { getConfig } from "../b2c/env-config";
import tokenService from "../services/tenant/token.service";
// import { getEnvConfig } from "./sponsor/helper";
// tokenService
// const env: string = getEnvConfig();
// const envVariable = getConfig(env);
// Todo : Make default URL based on Environment ['dev', 'staging', 'test', 'prod']
const getDefaultPath = () => {
  // const currentURL = window.location.pathname.split("/");
  // const baseUrl = envVariable!.REACT_APP_API_BASEURL;
  const baseUrl = "";
  // switch (currentURL[1]) {
  //   case "login-page":
  //   case "tenant":
  //     baseUrl = defaultHostUrl;
  //     break;
  //   case "gateway":
  //     baseUrl = defaultGatewayUrl;
  //     break;
  //   case "saas":
  //     baseUrl = defaultSaasUrl;
  //     if (currentURL[2] === "search-data") {
  //       baseUrl = defaultSaasSearchUrl;
  //     }
  //     console.log("SASS URL : " + baseUrl);
  //     break;
  //   default:
  //     break;
  // }
  return baseUrl;
};
const transformResponse = (input: string) => {
  try {
    return JSON.parse(input);
  } catch {
    //  Ignore ;
  }
};
const buildHeader = (obj = {}) => {
  const header = {
    // Accept: "application/json",
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "true",
  };
  Object.assign(header, obj);
  return header;
};
const apiFactory = (baseUrl: string = getDefaultPath(), header = {}) => {
  const service = axios.create({
    baseURL: baseUrl,
    headers: buildHeader(header),
    transformResponse: [
      (data) => {
        if (typeof data === "string") {
          return transformResponse(data);
        }
        return data;
      },
    ],
  });
  service.interceptors.request.use(
    (config: any) => {
      const token = tokenService.getLocalAccessToken();
      // console.log("token", token);
      if (token) {
        config.headers.Authorization = "Bearer " + token; // for Spring Boot back-end
        //  config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (_error) => {
      return _error;
    }
  );
  // service.interceptors.response.use(
  //   (res) => {
  //     return res;
  //   },
  //   async (err) => {
  //     const originalConfig = err.config;
  //     if (
  //       originalConfig.url !== "/api/login" &&  //       originalConfig.url !== "/api/refresh-access-token" &&  //       err.response && // Access Token was expired
  //       err.response.status === 401 &&  //       !originalConfig._retry
  //     ) {
  //       originalConfig._retry = true;
  //       const rs = await service.post(
  //         `${defaultHostUrl}api/refresh-access-token`,
  //         {
  //           refreshToken: tokenService.getLocalRefreshToken(),
  //         }
  //       );
  //       const accessToken = rs.data.access_token;
  //       tokenService.updateLocalAccessToken(accessToken);
  //       return service(originalConfig);
  //     }
  //     throw err;
  //   }
  // );
  return service;
};
export default apiFactory;
