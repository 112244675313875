import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { goToTop } from "../../../../components/scroll-to/ScrollTo";
import { setProductId } from "../../../../store/features/investment/investment-filter/investment-filter-id/slice";
import { addThunkInvestmentReturnPooledFund } from "../../../../store/features/operation/allocate-earning/add-investment-return-continuous";
import { getThunkCalculateEarningPooledfunds } from "../../../../store/features/operation/allocate-earning/calculate-earnings-pooledfund";
import {
  getThunkAllocateEarningPooledFund,
  getThunkDistributeEarningPooledFund,
} from "../../../../store/features/operation/allocate-earning/get-earning-data-pooledfund";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  convertLocalDateToUTC,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

const AllocateEarningContinuousList: React.FC<any> = ({
  productObjectByFilter,
  setProductObjectByFilter,
  investmentID,
  setInvestmentId,
  setEditData,
  openDailog,
  setOpenDailog,
  setLoadForm,
  setShowAddEarnings,
}) => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [investmentReturnID, setInvestmentReturnId] = useState(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [returnDateValue, setReturnDateValue] = useState<any>("");
  const [emailStatus, setEmailStatus] = useState("N");
  const [equityCashDistributionDateValue, setEquityCashDistributionDateValue] =
    useState<any>("");
  const [earningObj, setEarningObj] = useState({
    supplier: "",
    investmentName: "",
    investmentReturnType: "",
    returnAmount: 0,
    returnDate: "",
    investmentReturnTypeId: 0,
    equityDistriAllocationDate: "",
    managerFees: 0,
    managerFeesPreSet: 0,
    isReinvest: false,
  });
  const resetProductObjState = () => {
    setProductObjectByFilter({
      productID: 0,
      lookupCodeObj: "",
      address: "",
      zipCode: "",
      fundingDate: "",
    });
  };
  const earningData = useAppSelector(
    (state) => state.getAllocatedEarningDataPooledFund.data.investmentReturns
  );
  const calculateEarning = useAppSelector(
    (state) => state.getCalculateEarningPooledfund.data
  );
  const calculateEarningSummary = useAppSelector(
    (state) => state.getCalculateEarningPooledfund.summary
  );
  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };
  const computeSum = (
    totalFieldName: string,
    calculateDataForEarning = calculateEarning,
    isFormatCurrency = true
  ) => {
    if (calculateDataForEarning !== undefined) {
      const { length } = calculateDataForEarning;

      let count = 0;
      for (let i = 0; i < length; i += 1) {
        count += Number(
          typeof calculateDataForEarning[i][totalFieldName] === "object"
            ? calculateDataForEarning[i][totalFieldName]
            : calculateDataForEarning[i][totalFieldName] || 0
        );
      }

      return isFormatCurrency ? currencyFormat(count) : count;
    }
    return isFormatCurrency ? currencyFormat("0") : 0;
  };
  console.log("calculateEarningSummary", calculateEarningSummary);

  console.log("sssss", returnDateValue);
  console.log("sssss123456", equityCashDistributionDateValue);

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const distributeButton = (rowData: any) => {
    const handleDistribute = () => {
      setIsEdit(false);

      dispatch(setProductId({ productId: 0, resetFilters: true }));
      resetProductObjState();
      // eslint-disable-next-line unicorn/no-null
      setEditData(null);

      setLoading(true);
      const parameter = {
        investmentReturnTypeID: rowData.investmentReturnTypeID,
        investmentReturnID: rowData.investmentReturnID,
      };
      setInvestmentReturnId(rowData.investmentReturnID);
      dispatch(getThunkCalculateEarningPooledfunds(parameter))
        .then((response) => {
          if (rowData.investmentReturnTypeID === 3) {
            setOpenConfirmBox(true);
          } else {
            setOpenDialog(true);
            setLoading(true);
          }

          if (
            response.payload._response.success !== false &&
            response.payload._response.data.output.length > 0
          ) {
            const total = computeSum(
              "earningsShare",
              response.payload._response.data.output
            );
            console.log("total,", total);

            if (currencyFormat(rowData.returnAmount) !== total) {
              setErrorMessage(
                `Return Amount and total of proposed distribution is different. Please check the data before distributing this . If the difference is just a few cents you can proceed to distribute. If the difference is much larger, we highly recommend you contact support@avestorinc.com for further assistance.`
              );
            } else {
              setErrorMessage("");
              setErrorMessage2("");
            }
          } else {
            if (response.payload._response.message !== "No data found") {
              setErrorMessage(response.payload._response.message);
            } else {
              setErrorMessage("");
            }
            setErrorMessage2("");
            setLoading(false);
          }
        })
        .catch(() => {
          errorToastMessage(toast);
          setInvestmentReturnId(0);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });

      setEarningObj({
        supplier: rowData.supplier,
        investmentName: rowData.product,
        investmentReturnType: rowData.investmentReturnType,
        returnAmount: rowData.returnAmount,
        returnDate: formatDate(rowData.returnDate),
        investmentReturnTypeId: rowData.investmentReturnTypeID,
        equityDistriAllocationDate: "",
        managerFees: rowData.fixedAmount,
        isReinvest: rowData.isReinvest,
        managerFeesPreSet: rowData.managerFeesPreSetage,
      });
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              handleDistribute();
            }}
          >
            <span className="text-white">Distribute</span>
          </Button>
        </div>
      </div>
    );
  };
  const editButton = (rowData: any) => {
    const handleEdit = () => {
      console.log("rowdata", rowData);

      const editData = {
        returnDate: rowData.returnDate,
        returnType: rowData.investmentReturnTypeID,
        allocationDate: rowData.equityCashDistiAllocationDate,
        amount: rowData.returnAmount + rowData.fixedAmount,
        managerFeesPreSet: rowData.preSetPercentage,
        managerFees: rowData.fixedAmount,
        investedEarning: rowData.returnAmount,
        productId: rowData.productId,
        isReinvest: rowData.isReinvest,
        investmentReturnID: rowData.investmentReturnID,
      };

      setEditData(editData);
      setIsEdit(true);
      setInvestmentReturnId(rowData.investmentReturnID);
      setInvestmentId(rowData.externalInvestmentID);
      // setReturnDateValue(convertLocalDateToUTC(rowData.returnDate));
      setReturnDateValue(rowData.returnDate);
      setEquityCashDistributionDateValue(rowData.equityCashDistiAllocationDate);
      dispatch(
        setProductId({ productId: rowData.productId, resetFilters: false })
      );
      setProductObjectByFilter({
        ...productObjectByFilter,
        productID: rowData.productId,
      });
      setLoadForm(true);
      setShowAddEarnings(false);
      goToTop();
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              handleEdit();
            }}
          >
            <span className="text-white">Edit</span>
          </Button>
        </div>
      </div>
    );
  };
  const deleteButton = (rowData: any) => {
    const handleDelete = () => {
      //   formik.resetForm();
      //   formik.values.amount = 0;
      //   formik.setFieldValue("productId", productObjectByFilter?.productID);

      dispatch(setProductId({ productId: 0, resetFilters: true }));
      resetProductObjState();
      // eslint-disable-next-line unicorn/no-null
      setEditData(null);
      setInvestmentReturnId(rowData.investmentReturnID);
      setEarningObj({
        supplier: rowData.supplier,
        investmentName: rowData.product,
        investmentReturnType: rowData.investmentReturnType,
        returnAmount: rowData.returnAmount,
        returnDate:
          rowData.returnDate === null
            ? ""
            : convertLocalDateToUTC(rowData.returnDate),
        investmentReturnTypeId: rowData.investmentReturnTypeID,
        equityDistriAllocationDate:
          rowData.equityCashDistiAllocationDate === null
            ? rowData.equityCashDistiAllocationDate
            : convertLocalDateToUTC(rowData.equityCashDistiAllocationDate),
        managerFees: rowData.managerFees,
        isReinvest: rowData.isReinvest,
        managerFeesPreSet: rowData.managerFeesPreSet,
      });
      setInvestmentId(rowData.externalInvestmentID);
      setIsEdit(false);
      setOpenDeleteDialog(true);
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              handleDelete();
            }}
          >
            <span className="text-white">Delete</span>
          </Button>
        </div>
      </div>
    );
  };

  const onCancelDelete = () => {
    setInvestmentReturnId(0);
    setInvestmentId(0);
    setEarningObj({
      supplier: "",
      investmentName: "",
      investmentReturnType: "",
      returnAmount: 0,
      returnDate: "",
      investmentReturnTypeId: 0,
      equityDistriAllocationDate: "",
      managerFees: 0,
      isReinvest: true,
      managerFeesPreSet: 0,
    });
    // formik.resetForm();
    setOpenDeleteDialog(false);
  };
  const onDelete = () => {
    setOpenDeleteDialog(false);
    setLoading(true);
    if (investmentReturnID !== 0) {
      const parameter = {
        action: "Delete",
        investmentReturnId: investmentReturnID,
        investmentId: investmentID,
        returnTypeId: earningObj.investmentReturnTypeId,
        returnAmount: earningObj.returnAmount,
        returnDate: earningObj.returnDate,
        equityCashDistiAllocationDate: earningObj.equityDistriAllocationDate,
      };
      dispatch(addThunkInvestmentReturnPooledFund(parameter))
        .then((response) => {
          successToastMessage(toast, "Data is deleted successfully");
          setEarningObj({
            supplier: "",
            investmentName: "",
            investmentReturnType: "",
            returnAmount: 0,
            returnDate: "",
            investmentReturnTypeId: 0,
            equityDistriAllocationDate: "",
            managerFees: 0,
            isReinvest: true,
            managerFeesPreSet: 0,
          });
          const param = {
            legalEntityID: fundDropdown.fundid,
            investmenId: investmentID,
          };
          dispatch(getThunkAllocateEarningPooledFund(param));
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
          setInvestmentReturnId(0);
          setInvestmentId(0);
        });
    }
  };
  const handleDistribution = () => {
    setLoading(true);
    const parameter = {
      investmentReturnTypeId: earningObj.investmentReturnTypeId,
      investmentReturnId: investmentReturnID,
      legalEntityId: fundDropdown.fundid,
      emailStatus,
    };
    dispatch(getThunkDistributeEarningPooledFund(parameter))
      .then((response) => {
        if (response.meta.requestStatus === "rejected") {
          errorToastMessage(toast);
          setLoading(false);
        } else if (response.meta.requestStatus === "fulfilled") {
          setLoading(false);
          successToastMessage(toast, "Processed Successfully.");
          setOpenDialog(false);
          setInvestmentReturnId(0);
          setEarningObj({
            supplier: "",
            investmentName: "",
            investmentReturnType: "",
            returnAmount: 0,
            returnDate: "",
            investmentReturnTypeId: 0,
            equityDistriAllocationDate: "",
            managerFees: 0,
            isReinvest: true,
            managerFeesPreSet: 0,
          });
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onConfirm = () => {
    setOpenDialog(true);
    setOpenConfirmBox(false);
    setEmailStatus("Y");
  };
  const onCancel = () => {
    setOpenDialog(true);
    setOpenConfirmBox(false);
    setEmailStatus("N");
  };

  // const [shouldFreeze, setShouldFreeze] = useState(window.innerWidth >= 768);
  // useEffect(() => {
  //   const handleResize = () => {
  //     setShouldFreeze(window.innerWidth >= 768);
  //   };

  //   // Attach the event listener
  //   window.addEventListener("resize", handleResize);

  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  console.log("calculateEarning", calculateEarning, isEdit);
  const dataTableElement = [
    {
      field: "product",
      header: "Investment Name",
      style: { minWidth: "220px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.product}>
          {rowData?.product}
        </div>
      ),
    },
    {
      field: "supplierCode",
      header: "Lookup Code",
      style: { minWidth: "175px" },
    },
    {
      field: "returnDate",
      header: "Return Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.returnDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "175px" },
    },
    {
      field: "returnAmount",
      header: "Return Amount",
      body: (rowData: any) => currencyFormat(rowData.returnAmount),
      // frozen: shouldFreeze && true,
      className: "123",
      style: { minWidth: "175px" },
    },
    {
      field: "investmentReturnType",
      header: "Investment Return Type",
      style: { minWidth: "220px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.investmentReturnType}>
          {rowData?.investmentReturnType}
        </div>
      ),
      // frozen: true,
    },

    {
      field: "unitPriceCalculated",
      header: "Unit Price Calculated",
      style: { minWidth: "175px" },
    },
    { field: "supplier", header: "Supplier", style: { minWidth: "175px" } },

    {
      field: "investmentReturnID",
      header: "Return Id",
      style: { minWidth: "175px" },
    },
    // { field: "productId", header: "Investment Id" },
    // { field: "externalInvestmentID", header: "External Investment Id" },
    {
      field: "actualExitDate",
      header: "Actual Exit Date",
      dataType: "date",
      style: { minWidth: "175px" },
      body: (rowData: any) =>
        rowData.actualExitDate === null
          ? ""
          : formatDate(rowData.actualExitDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "",
      header: "Distribute",
      body: distributeButton,
      style: { minWidth: "175px" },
    },
    { field: "", header: "", body: editButton, style: { minWidth: "175px" } },
    { field: "", header: "", body: deleteButton, style: { minWidth: "175px" } },
  ];
  const dataTableElementDistribute = [
    {
      field: "investmentReturnID",
      header: "Return Id",
      style: { minWidth: "160px" },
    },
    {
      field: "externalInvestmentID",
      header: "External Investment Id",
      style: { minWidth: "220px" },
    },
    {
      field: "lpPartnerID",
      header: "LP Partner Id",
      style: { minWidth: "160px" },
    },
    {
      field: "invName",
      header: "Partner Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.invName}>
          {rowData?.invName}
        </div>
      ),
    },
    {
      field: "units",
      header: "Units",
      body: (rowData: any) => rowData.units,
      style: { minWidth: "160px" },
    },
    {
      field: "earningsShare",
      header: "Earnings Share",
      style: { minWidth: "160px" },
      body: (rowData: any) =>
        currencyFormat(Number.parseFloat(rowData.earningsShare).toFixed(2)),
    },
    {
      field: "startDate",
      header: "Start Date",
      dataType: "date",
      style: { minWidth: "160px" },
      body: (rowData: any) => formatDate(rowData.startDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "endDate",
      header: "End Date",
      dataType: "date",
      style: { minWidth: "160px" },
      body: (rowData: any) => formatDate(rowData.endDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "earningsDate",
      header: "Earnings Date",
      dataType: "date",
      style: { minWidth: "160px" },
      body: (rowData: any) => formatDate(rowData.earningsDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "equityCashDistiAllocationDate",
      header: "EqAllocDate",
      dataType: "date",
      style: { minWidth: "160px" },
      body: (rowData: any) => formatDate(rowData.equityCashDistiAllocationDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "earningsProcessedDate",
      header: "Earnings Processed Date",
      dataType: "date",
      style: { minWidth: "220px" },
      body: (rowData: any) => formatDate(rowData.earningsProcessedDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "daysInvested",
      header: "Days Invested",
      style: { minWidth: "160px" },
    },
    {
      field: "earningsRatePDPU",
      header: "Earnings Rate PDPU",
      style: { minWidth: "220px" },
    },
  ];

  const dataTableElementSummary = [
    {
      field: "investmentReturnID",
      header: "Return Id",
      style: { minWidth: "160px" },
    },
    { field: "invName", header: "Partner Name", style: { minWidth: "160px" } },
    {
      field: "lpPartnerID",
      header: "lp Partner ID",
      style: { minWidth: "160px" },
    },
    {
      field: "externalInvestmentID",
      header: "External Investment Id",
      style: { minWidth: "160px" },
    },
    {
      field: "units",
      header: "Investment Balance",
      body: (rowData: any) => currencyFormat(rowData.units),
      style: { minWidth: "160px" },
    },
    {
      field: "earningsShare",
      header: "Earnings Share",
      style: { minWidth: "160px" },
      body: (rowData: any) =>
        currencyFormat(Number.parseFloat(rowData.earningsShare).toFixed(2)),
    },
  ];

  const footerGroup = (data: any) => {
    return (
      <ColumnGroup>
        {data.managerFees !== 0 && (
          <Row>
            <Column footer="Manager fees:" colSpan={5} />
            <Column footer={currencyFormat(data.managerFees)} colSpan={8} />
          </Row>
        )}
        {calculateEarning?.length !== 0 && (
          <Row>
            <Column footer="Investor Earning:" colSpan={5} />
            <Column footer={computeSum("earningsShare")} colSpan={8} />
          </Row>
        )}
        {calculateEarning?.length !== 0 && (
          <Row>
            <Column footer="Total:" colSpan={5} />
            <Column
              footer={currencyFormat(
                computeSum("earningsShare", calculateEarning, false) +
                  data.managerFees
              )}
              colSpan={8}
            />
          </Row>
        )}
      </ColumnGroup>
    );
  };

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      setLoading(true);
      const param = {
        legalEntityID: fundDropdown.fundid,
        investmenId: investmentID,
      };
      dispatch(getThunkAllocateEarningPooledFund(param))
        .then((response) => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fundDropdown.fundid, investmentID]);

  const allocateEarningsData = useMemo(() => {
    return earningData?.length > 0
      ? getListDateConversion(earningData, [
          "returnDate",
          "actualExitDate",
          "equityCashDistiAllocationDate",
        ])
      : [];
  }, [earningData]);

  const calculateEarningData = useMemo(() => {
    return calculateEarning.length > 0
      ? getListDateConversion(calculateEarning, [
          "startDate",
          "endDate",
          "earningsDate",
          "equityCashDistiAllocationDate",
          "earningsProcessedDate",
        ])
      : [];
  }, [calculateEarning]);

  const onHideDialog = () => {
    setEarningObj({
      supplier: "",
      investmentName: "",
      investmentReturnType: "",
      returnAmount: 0,
      returnDate: "",
      investmentReturnTypeId: 0,
      equityDistriAllocationDate: "",
      managerFees: 0,
      isReinvest: true,
      managerFeesPreSet: 0,
    });
    setInvestmentReturnId(0);
    setOpenDialog(false);
  };
  return (
    <>
      {loading ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <ConfirmDailogbox
        openDialog={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        onConfirm={onDelete}
        onCancel={onCancelDelete}
        textContent="Are you sure you want to delete ?"
      />
      <Dialog
        visible={openDialog}
        onHide={() => onHideDialog()}
        headerClassName="no-header"
        contentClassName="top-lr-content"
        style={{ width: "90vw" }}
      >
        {loading === true ? <Loader /> : <></>}
        <div className="mt-4">
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Supplier </label>
              <InputText
                name="supplier"
                value={earningObj.supplier}
                // onChange={(e) => handleChange(e)}

                className="card-inputBox"
                disabled
              />
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Investment Name </label>
              <InputText
                name="investmentName"
                value={earningObj.investmentName}
                // onChange={(e) => handleChange(e)}
                className="card-inputBox"
                disabled
              />
            </div>
          </div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">Investment Return Type </label>
              <InputText
                name="investmentReturnType"
                value={earningObj.investmentReturnType}
                // onChange={(e) => handleChange(e)}

                className="card-inputBox"
                disabled
              />
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">Return Amount</label>
              <InputNumber
                name="returnAmount"
                value={earningObj.returnAmount}
                // onChange={(e) => handleChange(e)}
                className="card-inputBox"
                mode="currency"
                currency="USD"
                locale="en-US"
                disabled
              />
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">Return Date </label>
              <InputText
                name="returnDate"
                value={earningObj.returnDate}
                // onChange={(e) => handleChange(e)}
                className="card-inputBox"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <DataTableComponent
            valueData={calculateEarningData}
            fieldsElements={dataTableElementDistribute}
            isPaginationAllowed={true}
            scrollable={true}
            className="gridcell deletebtn"
            footerElement={footerGroup(earningObj)}
          />
        </div>

        <small className="p-error flex justify-content-center errormsg-size mt-3">
          {errorMessage}
        </small>
        <small className="p-error flex justify-content-center mt-1  errormsg-size">
          {errorMessage2}
        </small>
        <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
          <div className="d-flex flex-column p-2 col-12 sm:col-2 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleDistribution();
              }}
              // disabled={calculateEarning.length === 0 || errorMessage !== ""}
              disabled={
                computeSum("earningsShare", calculateEarning, false) === 0
              }
              className="blueButtonShadow "
            >
              <span className="ml-auto mr-auto">Distribute</span>
            </Button>
          </div>
        </div>
        <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
          <span className="inputLabel">Earning Summary </span>
        </div>
        <div className="mt-4">
          <DataTableComponent
            valueData={calculateEarningSummary}
            fieldsElements={dataTableElementSummary}
            isPaginationAllowed={true}
            scrollable={true}
            className="gridcell deletebtn"
            // footerElement={footerGroup}
          />
        </div>
      </Dialog>

      <ConfirmDailogbox
        openDialog={openConfirmBox}
        setOpenDialog={setOpenConfirmBox}
        onConfirm={onConfirm}
        onCancel={onCancel}
        textContent=" Would you like to notify investor about this investment exit?"
      />

      <>
        <DataTableComponent
          resizableColumns={true}
          columnResizeMode="expand"
          valueData={allocateEarningsData}
          fieldsElements={dataTableElement}
          isPaginationAllowed={true}
          scrollable={true}
          openDailog={openDailog}
          setOpenDailog={setOpenDailog}
          className="gridcell deletebtn"
        />
      </>
    </>
  );
};

export default AllocateEarningContinuousList;
