import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useMemo, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
import Loader from "../../../components/loader/Loader";
import { scrollToSection } from "../../../components/scroll-to/ScrollTo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addNewPoolfundsUnitsThunk } from "../../../store/poolfunds/add-new-poolfunds-units";
import { addPoolFundsActions } from "../../../store/poolfunds/add-pool-funds-state";
import { InvestorListType } from "../../../store/poolfunds/add-pool-funds-state/type";
import { getAllocatedInvestorsByInvestmentPoolfundsThunk } from "../../../store/poolfunds/get-allocatedinvestors-Investment-poolfunds";
import { ManagerFeesType } from "../../../types";
import currencyFormat from "../../../utils/currency-formatter";
import { convertLocalDateToUTC } from "../../../utils/date-formatter";
import { errorToastMessage } from "../../../utils/toast-message";
import StepButtons from "../StepButtons";

type PoolFundStep1Props = {
  handlePrevious: () => void;
  handleNext: () => void;
  toast: any;
};

const PoolFundStep3: React.FC<PoolFundStep1Props> = ({
  handleNext,
  handlePrevious,
  toast,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasUnitError, sethasUnitError] = useState(false);
  const [hasCashError, sethascashError] = useState(false);
  const [hasFinalUnitError, setHasFinalUnitError] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState<any>();
  const [manualData, setManualData] = useState<any>();
  const [manualFeesId, setManualFeesId] = useState<any>("");
  const [managerFee, setManagerFee] = useState<any>("");
  const dispatch = useAppDispatch();
  const addPoolFundData = useAppSelector((state) => state.AddPoolFunds.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const managerFees: any = useAppSelector(
    (state) => state.getAllInvestorPoolFund.data.managerFees
  );
  const pooledFundsInvestmentDetails: any = useAppSelector(
    (state) => state.getAllInvestorPoolFund.data.pooledFundsInvestmentDetails
  );

  useEffect(() => {
    const investorList: any = [...addPoolFundData?.investorList];
    const list: any = [];
    for (const item of investorList) {
      const investorObj = {
        lpPartnerId: item.lpPartnerId,
        unitsToAllocateError: "",
        cashEquivalentError: "",
        finalUnitError: "",
        isFieldRequiredError: true,
      };
      list.push(investorObj);
    }
    setErrorMessageState(list);
  }, []);
  console.log("errorMessageState", errorMessageState);

  useEffect(() => {
    if (errorMessageState !== undefined) {
      sethasUnitError(
        errorMessageState.some(
          (error: any) => error.unitsToAllocateError !== ""
        )
      );
      sethascashError(
        errorMessageState.some((error: any) => error.cashEquivalentError !== "")
      );
      setHasFinalUnitError(
        errorMessageState.some((error: any) => error.finalUnitError !== "")
      );
    }
  }, [errorMessageState]);
  const calculatedEarning = useMemo(() => {
    let totalUnits = 0;
    const list = addPoolFundData.investorList;
    for (const element of list) {
      if (element.unitsToAllocate !== undefined)
        totalUnits +=
          Number(element.unitsToAllocate) - Number(element.managerFees);
    }
    return totalUnits;
  }, [addPoolFundData.investorList]);

  const cashEquField = (rowData: InvestorListType) => {
    let selectedError: any;
    let error: any = false;
    if (errorMessageState !== undefined) {
      selectedError = errorMessageState.find(
        (data: any) => data.lpPartnerId === rowData.lpPartnerId
      );
      console.log("list dd testing", selectedError, errorMessageState);
      error = selectedError.cashEquivalentError !== "";
      console.log("ddd", error);
    }
    console.log("addPoolFundData.investorList", addPoolFundData.investorList);

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <label className="w-100">
            {currencyFormat(rowData.cashEquivalent)}
          </label>
          {error === true ? (
            <RiErrorWarningLine
              title="Amount allocated is higher than available cash balance"
              className="iconError"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const editUnits = (rowData: InvestorListType) => {
    let error = false;
    let selectedError: any;
    if (errorMessageState !== undefined) {
      selectedError = errorMessageState.find(
        (data: any) => data.lpPartnerId === rowData.lpPartnerId
      );
      error = selectedError.unitsToAllocateError !== "";
    }
    const handleInputChange = (e: any, data: InvestorListType) => {
      const newValue = e.value;
      const res = managerFees?.find(
        (per: ManagerFeesType) =>
          newValue >= per.lowerFundingAmount &&
          newValue < per.upperFundingAmount
      );
      const percent = res?.preSetPercentage ? res?.preSetPercentage : 0;
      console.log("res", res, percent);
      const fees = (newValue * 1 * percent) / 100;
      const updatedRowData = {
        ...data,
        unitsToAllocate: newValue,
        cashEquivalent: newValue * 1,
        managerFees: data.managerFees
          ? data.managerFees
          : res?.fixedAmount
          ? res?.fixedAmount
          : fees,
      };

      dispatch(
        addPoolFundsActions.setAddPoolFundData({
          ...addPoolFundData,
          investorList: addPoolFundData.investorList.map(
            (list: InvestorListType) => {
              if (list.lpPartnerId === updatedRowData.lpPartnerId) {
                return updatedRowData;
              }
              return list;
            }
          ),
        })
      );
      const modifyErrorList = (
        unitsError: any,
        cashError: any,
        finalUnitError: any = ""
      ) =>
        errorMessageState.map((obj: any) => {
          const newData =
            obj?.lpPartnerId === data.lpPartnerId && newValue > 0
              ? {
                  ...obj,
                  unitsToAllocateError: unitsError,
                  cashEquivalentError: cashError,
                  finalUnitError,
                }
              : obj?.lpPartnerId === data.lpPartnerId && newValue === null
              ? {
                  ...obj,
                  unitsToAllocateError: "",
                  cashEquivalentError: "",
                  finalUnitError: "",
                }
              : obj;
          return newData;
        });
      const remainingTotal = data.cashBalance - newValue;
      console.log("remainingTotal", remainingTotal);
      if (remainingTotal < 0 && newValue > data.cashBalance && newValue !== 0) {
        setErrorMessageState(
          modifyErrorList(
            "Amount allocated is higher than available cash balance",
            "Amount allocated is higher than available cash balance"
          )
        );
      } else if (newValue > data.cashBalance && newValue !== 0) {
        setErrorMessageState(
          modifyErrorList(
            "",
            "Amount allocated is higher than available cash balance"
          )
        );
      } else if (remainingTotal < 0) {
        setErrorMessageState(
          modifyErrorList(
            "Amount allocated is higher than available cash balance",
            ""
          )
        );
      } else if (
        updatedRowData.unitsToAllocate - updatedRowData.managerFees <=
        0
      ) {
        setErrorMessageState(
          modifyErrorList("", "", "Final Allocation shoud not be nagative")
        );
      } else {
        const resetErrors = errorMessageState.map((obj: any) => ({
          ...obj,
          unitsToAllocateError: "",
          finalUnitError: "",
        }));
        const resetCashErrors = resetErrors.map((obj: any) =>
          obj?.lpPartnerId === data.lpPartnerId
            ? { ...obj, cashEquivalentError: "" }
            : obj
        );
        setErrorMessageState(resetCashErrors);
      }
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <InputNumber
            name="txtunits"
            id="txtunits"
            value={
              rowData.unitsToAllocate !== 0
                ? rowData.unitsToAllocate
                : undefined
            }
            onChange={(e) => handleInputChange(e, rowData)}
            onValueChange={(e) => handleInputChange(e, rowData)}
            className="w-100"
            min={0}
            mode="currency"
            currency="USD"
            locale="en-US"
            minFractionDigits={2}
            placeholder="Enter Amount"
          />
          {error === true ? (
            <RiErrorWarningLine
              title="Amount allocated is higher than available cash balance"
              className="iconError"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const finalAllocationAmount = (rowData: InvestorListType) => {
    let error = false;
    let selectedError: any;
    if (errorMessageState !== undefined) {
      selectedError = errorMessageState.find(
        (data: any) => data.lpPartnerId === rowData.lpPartnerId
      );
      error = selectedError.finalUnitError !== "";
    }
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <span>
            {rowData.managerFees && rowData.unitsToAllocate
              ? currencyFormat(rowData.unitsToAllocate - rowData.managerFees)
              : currencyFormat(rowData?.unitsToAllocate || 0)}
          </span>
          {error === true ? (
            <RiErrorWarningLine
              title="Final Allocation (after fees) Should not be negative number"
              className="iconError"
              size={25}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const handleFeesInput = () => {
    const updatedRowData = {
      ...manualData,
      managerFees: managerFee,
    };
    dispatch(
      addPoolFundsActions.setAddPoolFundData({
        ...addPoolFundData,
        investorList: addPoolFundData.investorList.map(
          (list: InvestorListType) => {
            if (list.lpPartnerId === updatedRowData.lpPartnerId) {
              return updatedRowData;
            }
            return list;
          }
        ),
      })
    );
    const modifyErrorList = (
      unitsError: any,
      cashError: any,
      finalUnitError: any = ""
    ) =>
      errorMessageState.map((obj: any) => {
        const newData =
          obj?.lpPartnerId === updatedRowData.lpPartnerId &&
          updatedRowData.unitsToAllocate - updatedRowData.managerFees <= 0
            ? {
                ...obj,
                finalUnitError,
              }
            : obj?.lpPartnerId === updatedRowData.lpPartnerId
            ? {
                ...obj,
                finalUnitError: "",
              }
            : obj;
        return newData;
      });
    if (updatedRowData.unitsToAllocate - updatedRowData.managerFees <= 0) {
      setErrorMessageState(
        modifyErrorList("", "", "Final Allocation shoud not be nagative")
      );
    } else {
      const resetCashErrors = errorMessageState.map((obj: any) =>
        obj?.lpPartnerId === updatedRowData.lpPartnerId
          ? { ...obj, finalUnitError: "" }
          : obj
      );
      setErrorMessageState(resetCashErrors);
    }
    setManagerFee("");
    setManualData("");
    setManualFeesId("");
  };

  const handleManagerFees = (data: InvestorListType) => {
    return (
      <>
        <div className="flex flex-column">
          <div className="flex align-items-center">
            <label className="mr-2">{currencyFormat(data.managerFees)}</label>
            <Button
              className="btn-navActive justify-content-center"
              onClick={(e) => {
                e.preventDefault();
                setManagerFee(data.managerFees ? data.managerFees : "");
                setManualData(data);
                setManualFeesId(data.lpPartnerId);
              }}
            >
              Enter Manually
            </Button>
          </div>
        </div>
      </>
    );
  };

  const handleReInvested = (data: InvestorListType) => {
    const handleCheckBox = (e: any) => {
      const updatedRowData = {
        ...data,
        isReinvested: e.checked,
      };
      dispatch(
        addPoolFundsActions.setAddPoolFundData({
          ...addPoolFundData,
          investorList: addPoolFundData.investorList.map(
            (list: InvestorListType) => {
              if (list.lpPartnerId === updatedRowData.lpPartnerId) {
                return updatedRowData;
              }
              return list;
            }
          ),
        })
      );
    };

    return (
      <div className="flex align-items-center gap-1">
        {!pooledFundsInvestmentDetails?.isReinvest &&
        data.isReinvstedDisabled ? (
          <Checkbox
            name="data"
            checked={data.isReinvest}
            disabled={data.isReinvstedDisabled}
          />
        ) : (
          <Checkbox
            name="data"
            onChange={(e) => handleCheckBox(e)}
            checked={data.isReinvested}
            disabled={pooledFundsInvestmentDetails?.isReinvest}
          />
        )}
        {!pooledFundsInvestmentDetails?.isReinvest &&
          data.isReinvstedDisabled && (
            <>
              <Tooltip
                target=".custom-target-warning-reinvest"
                position="left"
                style={{ width: "300px", maxHeight: "350px" }}
              />
              <i
                className="custom-target-warning-reinvest pi pi-exclamation-circle p-overlay-badge warningIcon"
                data-pr-tooltip="Reinvestment option is available at investment level and it is selected when an investor is first allocated to an investment and before any earnings have been processed for that investment."
                data-pr-position="right top"
                style={{ fontSize: "1.3rem", cursor: "pointer" }}
              ></i>
            </>
          )}
      </div>
    );
  };

  const dataTableElement = [
    {
      field: "name",
      header: "Selected Investor",

      style: { width: "12rem" },
    },
    {
      field: "requestChange",
      header: "Request Change",
      body: (rowData: InvestorListType) => (
        <div className="amount-green">
          {currencyFormat(rowData.requestChange)}
        </div>
      ),
    },
    // {
    //   field: "softCommit_Amount",
    //   header: "Soft Commit Amount",
    // },
    {
      field: "cashBalance",
      header: "Cash Balance",
      body: (rowData: InvestorListType) =>
        rowData?.unitsToAllocate
          ? currencyFormat(
              rowData.cashBalance - rowData?.unitsToAllocate,
              rowData.cashBalance <= 0 && rowData.cashBalance > -1 ? 4 : 2
            )
          : currencyFormat(
              rowData.cashBalance,
              rowData.cashBalance <= 0 && rowData.cashBalance > -1 ? 4 : 2
            ),
    },
    {
      field: "unitsToAllocate",
      header: "Allocate Amount ",
      body: editUnits,
      bodyClassName: "p-1",
    },

    {
      header: "Allocation Amount (before fees)",
      body: cashEquField,
    },
    {
      header: "Manager Fees",
      body: handleManagerFees,
    },
    {
      header: "Final Allocation (after fees)",
      body: finalAllocationAmount,
    },
    {
      header: "Earnings Reinvested",
      body: handleReInvested,
    },
  ];

  const onFinalize = () => {
    // dispatch(addPoolFundsActions.resetAddPoolFundData());
    const hasError = errorMessageState.some(
      (error: any) =>
        error.unitsToAllocateError !== "" || error.cashEquivalentError !== ""
    );
    // it will return true
    const hasNull = addPoolFundData?.investorList.some(
      (unitCheck: any) =>
        unitCheck.unitsToAllocate === null ||
        unitCheck.unitsToAllocate === 0 ||
        unitCheck.unitsToAllocate - unitCheck.managerFees <= 0
    );
    if (!hasError && !hasNull) {
      setLoading(true);
      const allList = [...addPoolFundData.investorList];
      const list = allList.map((item: InvestorListType) => {
        return {
          lpPartnerId: item.lpPartnerId,
          allocationDate: convertLocalDateToUTC(
            item.allocationDate || new Date()
          ),
          allocatedUnits:
            item.unitsToAllocate && item.managerFees
              ? item.unitsToAllocate - item.managerFees
              : item.unitsToAllocate,
          prevAllocatedUnits: 0,
          lpPartnerStakeTrackingId: 0,
          managerFees: item.managerFees,
          isReinvest: item.isReinvested,
        };
      });
      const parameter = {
        action: "Create",
        legalEntityId: fundDropdown.fundid,
        investmentId: addPoolFundData.investmentId,
        stakeTrackingId: 0,
        totalUnits: 0,
        unitPrice: 0,
        poolfundsUnitsData: list,
      };
      dispatch(addNewPoolfundsUnitsThunk(parameter))
        .then((response: any) => {
          dispatch(
            addPoolFundsActions.setAddPoolFundData({
              ...addPoolFundData,
              investorList: [],
              showAllocatedInvestor: true,
              isDateChange: false,
            })
          );
          handleNext();
          setLoading(false);
          const param = {
            legalEntityId: fundDropdown.fundid,
            investmentId: addPoolFundData.investmentId,
          };
          dispatch(getAllocatedInvestorsByInvestmentPoolfundsThunk(param));
          scrollToSection("allocatedInvestor");
          console.log("response new add", response);
        })
        .catch((error: any) => {
          setLoading(false);
          errorToastMessage(toast);
          console.log("err", error);
        });
    } else if (hasNull) {
      errorToastMessage(toast, "Please allocate units for all investors");
    } else {
      errorToastMessage(toast, "Please check the allocated units");
    }
  };

  const onChange = () => {
    // reseting the state of investor
    const list = addPoolFundData.investorList.map((data: InvestorListType) => {
      return {
        ...data,
        unitsToAllocate: 0,
        cashEquivalent: 0,
      };
    });
    console.log("list", list);
    dispatch(
      addPoolFundsActions.setAddPoolFundData({
        ...addPoolFundData,
        investorList: list,
        showAllocatedInvestor: false,
      })
    );

    handlePrevious();
  };

  const onDialogCancel = () => {
    setManagerFee("");
    setManualData("");
    setManualFeesId("");
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Dialog
        visible={!!manualFeesId}
        onHide={() => onDialogCancel()}
        className="w-100 md:w-3 pb-1"
        headerClassName="no-header"
      >
        <div className="mt-2">
          <h4 className="pageHeader mt-2 flex justify-content-center">
            Please add manager fees{" "}
          </h4>
          <div className="p-2 col-12 flex justify-content-center">
            <div className="mt-2 flex">
              <div className="flex justify-content-center align-items-center pr-4 mb-1">
                <InputNumber
                  value={managerFee}
                  min={0}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  minFractionDigits={2}
                  onChange={(e) => setManagerFee(e.value)}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row flex-wrap sm:flex-none p-0 col-12 justify-content-center ">
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-nav"
                onClick={(e) => {
                  e.preventDefault();
                  onDialogCancel();
                }}
              >
                <span className="ml-auto mr-auto ">Cancel</span>
              </Button>
            </div>
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-navActive"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleFeesInput();
                }}
              >
                <span className="ml-auto mr-auto ">Submit</span>
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <div className="mt-4">
        <div className="formgrid grid px-2">
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-12">
            <label className="inputLabel pr-2 flex align-items-center">
              Investment Name:
              <span className="ml-1 bold">
                {addPoolFundData.investmentName}
              </span>
            </label>
          </div>
        </div>
        <div className="formgrid grid px-2">
          {/* <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
            <label className="inputLabel">Available Units:</label>
            <h3>{100} </h3>
          </div>
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
            <label className="inputLabel">Units Remaining:</label>
            <h3>{20}</h3>
          </div> */}
        </div>
        <div className="formgrid grid px-2">
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
            <label className="inputLabel">Allocate Accounts</label>
          </div>
        </div>
      </div>
      <DataTableComponent
        valueData={addPoolFundData.investorList || []}
        fieldsElements={dataTableElement}
        noGridLines={true}
        className="allocateSliceTable"
      />
      <div className="formgrid grid px-2">
        <div className="iconError mb-3  p-2">
          <ul>
            {(hasCashError || hasUnitError) && (
              <li>Amount allocated is higher than available cash balance </li>
            )}
            {hasFinalUnitError && (
              <li>
                Final Allocation (after fees) Should not be negative number
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="formgrid grid px-2">
        <div className="d-block sm:d-flex sm:flex-column p-3 col-12 flex align-items-end">
          <label className="inputLabel">Total amount being allocated:</label>
          <h3>{currencyFormat(calculatedEarning)}</h3>
        </div>
      </div>
      <StepButtons
        firstBtnLabel="Back"
        secondBtnLabel="Submit"
        handleFirstBtn={onChange}
        handleSecondBtn={onFinalize}
      />
    </>
  );
};

export default PoolFundStep3;
