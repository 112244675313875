import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    tabName: "Fund",
    openDialog: false,
    clickPageName: "Fund",
    changeState: false,
  },
};

const slice = createSlice({
  name: "setupFundTabName",
  initialState,
  reducers: {
    setSetupTabNameState: (state, action) => {
      console.log(" as", action.payload);
      state.data = action.payload;
      //
    },
  },
});
export const { setSetupTabNameState } = slice.actions;
export default slice.reducer;
