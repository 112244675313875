import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestmentsAndDealsCardsByLegalEntityIdForPooledFunds } from "../../../services/investment/investment";
import error from "../../../utils/error";
import { IInvestmentGetAllState } from "./typs";

const initialState: IInvestmentGetAllState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    investments: [],
    deals: [],
  },
};
export const getThunkAllInvestmentDealPooledFundByLegalEntityId =
  createAsyncThunk(
    "getThunkAllInvestmentDealPooledFundByLegalEntityId",
    async (data: any) => {
      try {
        const response =
          await getInvestmentsAndDealsCardsByLegalEntityIdForPooledFunds(data);
        return response.data;
      } catch (error_) {
        console.log("er", error_);
        throw new Error(error(error_));
      }
    }
  );

const slice = createSlice({
  name: "getThunkAllInvestmentDealPooledFundByLegalEntityId",
  initialState,
  reducers: {
    // setForm: (state, action) => {
    //   state.data = action.payload;
    //   //
    // },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(
      getThunkAllInvestmentDealPooledFundByLegalEntityId.pending,
      (state) => {
        state.loading = "loading";
        //   state.error = undefined;
      }
    );
    builder.addCase(
      getThunkAllInvestmentDealPooledFundByLegalEntityId.fulfilled,
      (state, action) => {
        state.loading = "succeeded";

        if (action.payload.data !== null) {
          state.data.investments = action.payload.data.investments;
          state.data.deals = action.payload.data.deals;
        } else {
          state.data.investments = [];
          state.data.deals = [];
        }
        state.message =
          action.payload.message === null ? undefined : action.payload.message;

        // console.log("state.data 1", state.data);
      }
    );
    builder.addCase(
      getThunkAllInvestmentDealPooledFundByLegalEntityId.rejected,
      (state, action) => {
        state.loading = "failed";
        console.log("errorslice", action.error);
        state.error = error(action.error);
      }
    );
  },
});

export default slice.reducer;
