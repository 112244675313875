import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import DataTableComponent from "../../../components/data-table/DataTableComponent";

import Loader from "../../../components/loader/Loader";
import { addUpdateInvestorInviteThunk } from "../../../store/Investor/invite-investor/add-update-investor-invite/slice";
import { IApproveDeleteInvestorInvitePayload } from "../../../store/Investor/invite-investor/approve-delete-investor-invite";
import { approveDeleteInvestorInviteThunk } from "../../../store/Investor/invite-investor/approve-delete-investor-invite/slice";
import { getInvestorInviteListThunk } from "../../../store/Investor/invite-investor/get-investor-invite-list/slice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { convertUTCToLocalDate } from "../../../utils/date-formatter";
import { regexForEmail } from "../../../utils/sponsor/constants";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";

export default function SendInviteToRegister() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const isInvitationSentRef = useRef(0);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const inviteList = useAppSelector(
    (state) => state.getInvestorInviteList.data
  );
  const inviteListApiStatus = useAppSelector(
    (state) => state.getInvestorInviteList.loading
  );
  const [openDialog, setOpenDailog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [approvalDialog, setApprovalDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [add, setAdd] = useState(true);

  const [edit, setEdit] = useState(false);
  const [both, setBoth] = useState(false);
  const [investorDetails, setInvestorDetails] =
    useState<IApproveDeleteInvestorInvitePayload>();

  //   const [id, setId] = useState<any>();

  //   const profileData = useAppSelector((state) => state.profile.data![0]);

  const getInvestorInviteList = () => {
    const req = {
      legalEntityId: fundDropdown.fundid,
    };
    dispatch(getInvestorInviteListThunk(req));
  };
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling animation
    });
  };

  // console.log("funddropdownModel", funddropdownModel);
  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      walkinUserId: 0,
      action: "A",
    },
    validate: (data: any) => {
      //
      const errors: any = {};

      // const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

      if (!data.fname) {
        errors.fname = "First name is required";
      }
      if (!data.lname) {
        errors.lname = "Last name is required";
      }
      if (!data.email) {
        errors.email = "Email is required";
      }
      if (data.email && !regexForEmail.test(data.email)) {
        errors.email = "Enter valid email";
      }

      // if (data.email && !emailRegex.test(data.email)) {
      //   errors.email = "Invalid email address";
      // }

      return errors;
    },
    onSubmit: async (data: any) => {
      console.log("submit form", data);
      const obj = {
        walkinUserId: data.walkinUserId,
        legalEntityId: +fundDropdown!.fundid,
        action: data.action,
        email: data.email.toLowerCase().trim(),
        firstName: data.fname,
        lastName: data.lname,
      };
      setLoader(true);
      dispatch(addUpdateInvestorInviteThunk(obj))
        .then((res: any) => {
          console.log("response", res);
          setLoader(false);
          if (res.payload.success) {
            if (
              res.payload.message !== "Investor Invite Already Exist" &&
              both
            ) {
              successToastMessage(toast, res.payload.message);

              getInvestorInviteList();

              setInvestorDetails({
                walkinUserId: 0,
                flag: "A",
                firstName: formik.values.fname,
                email: formik.values.email,
                legalEntityId: +fundDropdown!.fundid,
                isResend: false,
              });
              setApprovalDialog(true);
            } else {
              successToastMessage(toast, res.payload.message);

              getInvestorInviteList();
            }
          } else {
            errorToastMessage(toast);
          }
        })
        .catch(() => {
          setLoader(false);
          errorToastMessage(toast);
        })
        .finally(() => {
          setAdd(false);
          setEdit(false);
        });

      formik.resetForm();
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const deleteButton = (rowData: any) => {
    return (
      <div className="flex flex-column">
        <div className="flex  align-items-center justify-content-around gap-2">
          <Button
            label={
              rowData.isAdminApprove === "A" ? "Resend Invite" : " Send Invite "
            }
            className="btn-dialog align-items-center justify-content-around sm:mr-2 mx-0 w-160"
            onClick={(e) => {
              e.preventDefault();

              setInvestorDetails({
                walkinUserId: rowData.id,
                flag: "A",
                firstName: rowData.fName,
                email: rowData.emailId,
                legalEntityId: +fundDropdown!.fundid,
                isResend: !!rowData.isAdminApprove,
              });
              setApprovalDialog(true);
            }}
          ></Button>
          <Button
            label="Delete User"
            className={`btn-dialog align-items-center justify-content-around`}
            // disabled={rowData.isAdminApprove === "A"}
            onClick={(e) => {
              e.preventDefault();
              console.log("rowData", rowData);
              setInvestorDetails({
                walkinUserId: rowData.id,
                flag: "R",
                firstName: rowData.fName,
                email: rowData.emailId
                  ? rowData.emailId?.trim()
                  : rowData.emailId,
                legalEntityId: +fundDropdown!.fundid,
                isResend: !!rowData.isAdminApprove,
              });
              // walkinUserId: number;
              // flag: string; // a
              // emailId: string;
              // fName: string;
              // legalEntityId: number;
              rowData.isAdminApprove === "A"
                ? (isInvitationSentRef.current = 1)
                : (isInvitationSentRef.current = 0);

              console.log(
                "isInvitationSentRef.current",
                isInvitationSentRef.current
              );
              setConfirmDialog(true);
            }}
          ></Button>
        </div>
      </div>
    );
  };

  const editButton = (rowData: any) => {
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-around">
          <Button
            label="Edit"
            className="btn-dialog widthdeletebtn align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={(e) => {
              e.preventDefault();

              formik.setFieldValue("fname", rowData.fName);
              formik.setFieldValue("lname", rowData.lName);
              formik.setFieldValue("email", rowData.emailId);
              formik.setFieldValue("walkinUserId", rowData.id);
              formik.setFieldValue("action", "U");

              setAdd(true);
              handleScrollToTop();
            }}
          />
        </div>
      </div>
    );
  };

  const resetForm = () => {
    // setShowTab(true);
    formik.resetForm();
  };
  // const onAddClick = () => {
  //   setAdd(true);
  //   setEdit(false);
  //   formik.resetForm();
  // };

  useEffect(() => {
    if (fundDropdown?.fundid) {
      getInvestorInviteList();
      formik.resetForm();
    }
  }, [fundDropdown.fundid]);

  const dataTableElements = [
    {
      field: "fName",
      header: "First Name",
      style: { minWidth: "170px" },
    },

    {
      field: "lName",
      header: "Last Name",
      style: { minWidth: "170px" },
    },
    {
      field: "emailId",
      header: "Email",
      style: { minWidth: "280px" },
    },
    {
      field: "mailSentDate",
      header: "Email send date",
      body: (rowData: any) =>
        convertUTCToLocalDate(rowData.mailSentDate, "mm/dd/yyyy"),
      style: { minWidth: "200px" },
    },
    {
      // field: "Edit",
      header: "",
      body: (rowData: any) => editButton(rowData),
      // headerClassName: "editheaderclass",
      style: { minWidth: "200px" },
    },

    {
      field: "",
      header: "",
      body: (rowData: any) => deleteButton(rowData),
      style: { minWidth: "350px" },
    },
  ];

  //   const resetForm = () => {
  //     setName("");
  //   };

  return (
    <>
      <div className="col-12 flex">
        <div className="card col-11 p-0 pgHeaderWrap ">
          <h1 className="pageHeader fs-2">Manage Invitations</h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className="col-1 p-0 align-items-center flex justify-content-end">
          <Button
            className="btn-navActive"
            type="button"
            icon="pi pi-window-maximize"
            disabled={
              inviteList === undefined ||
              inviteList?.length === 0 ||
              inviteList === null
            }
            onClick={() => setOpenDailog(true)}
            title="View Full Screen"
          />
        </div>
      </div>
      {/* <div className="card col-11 p-0 mt-1 pgHeaderWrap ">
        <h1 className="pageHeader fs-2">Manage Invitations</h1>
        <p className="pageSubHeader"> </p>
      </div> */}
      {inviteListApiStatus === "succeeded" ? (
        // <div className="grid">
        //   <div className="col-12">
        <div className="card">
          {/* <Card> */}
          <div className="mr-3 col-6">
            {" "}
            {!add ||
              (edit && <div className="tabHeader-wrap mb-3 col-6"></div>)}
            <div className="tabHeader-wrap">
              <h4 className="cardHeader"> Send invite to User </h4>
            </div>
          </div>
          <>
            {" "}
            {/* <div className="mb-4"> */}
            <div className="row align-items-center col-12 m-0 p-0">
              <div className="col-12 md:col-4 py-0 m-0 mb-md-0">
                <label className="inputLabel">First Name *</label>
                <div className="d-flex align-items-center">
                  <InputText
                    name="name"
                    value={formik.values.fname}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\d/g, ""); // Remove digits
                      formik.setFieldValue("fname", newValue);
                    }}
                    placeholder="First Name"
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("fname"),
                      "card-inputBox": true,
                    })}
                  />
                </div>
                {getFormErrorMessage("fname")}
              </div>
              <div className="col-12 md:col-4 py-0 m-0 mb-md-0">
                <label className="inputLabel">Last Name *</label>
                <div className="d-flex align-items-center">
                  <InputText
                    name="lname"
                    value={formik.values.lname}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\d/g, ""); // Remove digits
                      formik.setFieldValue("lname", newValue);
                    }}
                    placeholder="Last Name"
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("lname"),
                      "card-inputBox": true,
                    })}
                  />
                </div>
                {getFormErrorMessage("lname")}
              </div>
              <div className="col-12 md:col-4 py-0 m-0 mb-md-0">
                <label className="inputLabel">Email *</label>
                <div className="d-flex align-items-center">
                  <InputText
                    name="email"
                    value={formik.values.email}
                    onChange={(e) =>
                      formik.setFieldValue("email", e.target.value)
                    }
                    placeholder="Email"
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("email"),
                      "card-inputBox": true,
                    })}
                  />
                </div>
                {getFormErrorMessage("email")}
              </div>
              {/* <div className="col-12 md:col-1 m-0 mb-md-0">
                <Button className=" btn-nav " onClick={resetForm}>
                  <span className="ml-auto mr-auto ">Cancel</span>
                </Button>
              </div> */}
              {/* <div className="col-12 md:col-1 m-0 mb-md-0">
                <Button
                  className="btn-nav "
                  // label="Add to list"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    setBoth(false);
                  }}
                >
                  <span className="ml-auto mr-auto ">Add to list</span>
                </Button>
              </div> */}
              {/* <div className="col-12 md:col-2 m-0 mb-md-0">
                <div className="d-flex align-items-center">
                  <Button
                    className=" btn-nav "
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                      setBoth(true);
                    }}
                  >
                    <span className="ml-auto mr-auto ">
                      Add to list & send Invite
                    </span>
                  </Button>
                </div>
              </div> */}
            </div>
            {/* </div> */}
            <div className="formgrid grid px-2">
              <div className="d-flex flex-column  p-2  col-12 sm:col-4 ">
                <Button className=" btn-nav " onClick={resetForm}>
                  <span className="ml-auto mr-auto ">Cancel</span>
                </Button>
              </div>
              <div className="d-flex flex-column  p-2  col-12 sm:col-4 ">
                <Button
                  className="btn-nav "
                  // label="Add to list"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    setBoth(false);
                  }}
                >
                  <span className="ml-auto mr-auto ">Add to list</span>
                </Button>
              </div>
              <div className="d-flex flex-column  p-2  col-12 sm:col-4 ">
                <Button
                  className=" btn-nav "
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    setBoth(true);
                  }}
                >
                  <span className="ml-auto mr-auto ">
                    Add to list & send Invite
                  </span>
                </Button>
              </div>
            </div>
          </>

          {/* <div className="mt-2"> */}
          <DataTableComponent
            valueData={inviteList}
            fieldsElements={dataTableElements}
            isPaginationAllowed={true}
            openDailog={openDialog}
            setOpenDailog={setOpenDailog}
            scrollable={true}
            columnResizeMode="expand"
            className="gridcell deletebtn "
          />
          {/* </div> */}
          {/* </Card> */}
        </div>
      ) : (
        //   {/* </div>
        // </div> */}
        <Skeleton width="100%" height="150px"></Skeleton>
      )}
      <Dialog
        visible={confirmDialog}
        className="w-75 md:w-3 DialogInvestment"
        onHide={() => setConfirmDialog(false)}
      >
        <h4 className="pageHeader text-justify mb-5 pt-5">
          {isInvitationSentRef.current
            ? "You have already sent an invite to this investor to register. Are you sure you want to delete this record?"
            : "Are you sure you want to delete this record ?"}
        </h4>
        <div className=" align-self-center flex align-items-center justify-content-between">
          <Button
            onClick={() => {
              setLoader(true);
              setConfirmDialog(false);
              if (investorDetails) {
                dispatch(approveDeleteInvestorInviteThunk(investorDetails))
                  .then(() => {
                    setLoader(false);

                    getInvestorInviteList();
                    successToastMessage(toast, "Investor Deleted Successfully");
                  })
                  .catch(() => {
                    setLoader(false);
                    errorToastMessage(toast);
                  });
              }
            }}
            className="btn-dialog w-6 mr-3 align-items-center justify-content-around"
          >
            Yes
          </Button>

          <Button
            onClick={(e) => {
              e.preventDefault();
              setConfirmDialog(false);
            }}
            className="btn-dialog w-6 align-items-center justify-content-around"
          >
            No
          </Button>
        </div>
      </Dialog>
      <Dialog
        visible={approvalDialog}
        className="w-75 md:w-3 DialogInvestment"
        onHide={() => setApprovalDialog(false)}
      >
        <h4 className="pageHeader text-center mb-5 pt-5">
          An invitation to register will be sent to this investor. Registration
          status for the investor can be found in View and Approve
          Registrations.
        </h4>
        <div className=" align-self-center flex align-items-center justify-content-between">
          <Button
            onClick={() => {
              console.log(investorDetails);
              setApprovalDialog(false);
              setLoader(true);
              if (investorDetails) {
                dispatch(approveDeleteInvestorInviteThunk(investorDetails))
                  .then(() => {
                    setLoader(false);
                    getInvestorInviteList();
                  })
                  .catch(() => {
                    setLoader(false);
                    errorToastMessage(toast);
                  });
              }
            }}
            className="btn-dialog w-6 mr-3 align-items-center justify-content-around"
          >
            OK
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setApprovalDialog(false);
            }}
            className="btn-dialog w-6 align-items-center justify-content-around"
          >
            CANCEL
          </Button>
        </div>
      </Dialog>
      {loader && <Loader />}
      <Toast ref={toast} className="themeToast" />
    </>
  );
}
