import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import DataTableComponent from "../../../../../components/data-table/DataTableComponent";
import Loader from "../../../../../components/loader/Loader";
import { goToTop } from "../../../../../components/scroll-to/ScrollTo";
import {
  addThunkInputForm1099,
  deleteThunkInputForm1099,
} from "../../../../../store/features/taxmanagement/1099/input-form-1099/create/slice";
import {
  getThunkInputForm1099List,
  onResetInputForm1099,
} from "../../../../../store/features/taxmanagement/1099/input-form-1099/input-form-list/slice";
import { getThunkSupplierDropDownList } from "../../../../../store/features/taxmanagement/1099/input-form-1099/supplier-dropdown/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import currencyFormat from "../../../../../utils/currency-formatter";
import { exportExcel } from "../../../../../utils/excel-dataformat";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../../utils/toast-message";

export default function InputForm1099() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setloading] = useState<any>(false);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [inputFormId, setInputFormId] = useState(0);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const inputFormList = useAppSelector((state) => state.inputform1099List.data);
  const [openDialog, setOpenDialog] = useState(false);
  const [checked, setChecked] = useState(false);
  const [openDailog, setOpenDailog] = useState(false);

  const supplierDropdown = useAppSelector(
    (state) => state.supplierDropdownList.data
  );
  const previousYear = new Date().getFullYear();
  const currentYear = previousYear - 1;

  console.log("d", inputFormList);
  const formik = useFormik({
    initialValues: {
      supplierId: 0,
      year: currentYear.toString(),
      box1Amount: 0,
      box15State: "",
    },
    validate: (data) => {
      const errors: any = {};
      // if (!data.supplierId) {
      //   errors.supplierId = " Select supplier";
      // }
      if (!data.year) {
        errors.year = "Year is required";
      }

      if (!data.box1Amount) {
        errors.box1Amount = "Box 1 amount is required";
      } else if (!/^[+-]?(\d*\.)?\d+$/.test(data.box1Amount.toString())) {
        errors.box1Amount = "Box 1 amount should be a number";
      }

      if (!data.supplierId) {
        errors.supplierId = "Select supplier name";
      }
      return errors;
    },
    onSubmit: (data) => {
      console.log(data);

      const parameter = {
        id: inputFormId || 0,
        legalEntityId: fundDropdown.fundid,
        taxYear: formik.values.year.toString(),
        supplierId: formik.values.supplierId,
        box1Amount: +formik.values.box1Amount,
        box15State: formik.values.box15State,
        active: true,
        isAllocatedToGP: checked,
      };
      dispatch(addThunkInputForm1099(parameter))
        .then((response) => {
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (response.meta.requestStatus === "fulfilled") {
            if (response.payload._response.success === false) {
              errorToastMessage(toast, "Record already exist");
            } else {
              successToastMessage(toast, "Data is saved successfully");
              setInputFormId(0);
              // console.log("inside");
              // value.getFullYear();
              if (formik.values.year !== "" || formik.values.supplierId !== 0) {
                const parameters = {
                  legalEntityId: fundDropdown.fundid,
                  year: formik.values.year,
                  supplierId: +formik.values.supplierId,
                };
                dispatch(getThunkInputForm1099List(parameters));
              }
              setAdd(false);
              setEdit(false);
              // formik.setFieldValue("box1Amount", "");
              // formik.setFieldValue("box15State", "");
              setChecked(false);
              formik.resetForm();
            }
          }
        })
        .catch((error: any) => {
          console.log("error", error);
          errorToastMessage(toast);
        });
    },
  });

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const onAddClick = () => {
    formik.resetForm();
    // setSoftCommitId(0);
    setAdd(false);
    setEdit(false);
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log("name", name, value);
    if (name === "year") {
      const onlyYear = value.getFullYear();
      console.log(onlyYear);
      formik.setFieldValue(name, onlyYear);
    } else {
      formik.setFieldValue(name, value);
    }
  };
  console.log(
    "yera",
    formik.values.year,
    formik.values.box1Amount,
    formik.values.box15State
  );
  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = (id: any) => {
      formik.resetForm();
      console.log("product", typeof rowData.isAllocatedToGP, typeof checked);
      formik.setFieldValue("supplierId", rowData.supplierId);
      formik.setFieldValue("year", rowData.taxYear);
      formik.setFieldValue("box1Amount", rowData.box1Amount);
      setChecked(rowData.isAllocatedToGP === "True");

      if (rowData.box15State !== null) {
        formik.setFieldValue("box15State", rowData.box15State);
      }
      setInputFormId(rowData.id);

      setAdd(false);
      setEdit(true);
      goToTop();
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={() => handleEdit(rowData.Id)}
          >
            <span className="text-white">Edit</span>
          </Button>
        </div>
      </div>
    );
  };

  const deleteButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleDelete = () => {
      setInputFormId(rowData.id);
      setOpenDialog(true);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={() => handleDelete()}
          >
            <span className="text-white">Delete</span>
          </Button>
        </div>
      </div>
    );
  };

  const formatCurrencyAnyData = (rowData: any) => {
    const finalValue = Number(rowData.box1Amount);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const currencyFilterTemplate = (options: any) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e: any) => options.filterCallback(e.value, options.index)}
        mode="currency"
        currency="USD"
        locale="en-US"
      />
    );
  };
  const dataTableElements = [
    {
      field: "supplierName",
      header: "Supplier Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.supplierName}>
          {rowData?.supplierName}
        </div>
      ),
    },
    {
      field: "taxYear",
      header: "Tax Year",
      style: { width: "210px", minWidth: "180px" },
    },
    {
      field: "box1Amount",
      header: "Box1 Amount",
      dataType: "numeric",
      body: formatCurrencyAnyData,
      filterElement: currencyFilterTemplate,
      style: { width: "210px", minWidth: "180px" },
    },
    {
      field: "box15State",
      header: "Box15 State",
      style: { width: "210px", minWidth: "180px" },
    },
    {
      field: "isAllocatedToGP",
      header: "Is Allocated To GP",
      style: { width: "210px", minWidth: "180px" },
    },
    {
      // field: "edit",
      header: "",
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
      style: { width: "210px", minWidth: "180px" },
    },
    {
      // field: "delete",
      header: "",
      body: (rowData: any) => deleteButton(rowData), // Pass rowData to editButton
      style: { width: "210px", minWidth: "180px" },
    },
  ];

  const excelTableHeaders = [
    {
      field: "supplierName",
      header: "Supplier Name",
    },
    {
      field: "taxYear",
      header: "Tax Year",
    },
    {
      field: "box1Amount",
      header: "Box1 Amount",
      dataType: "currency",
    },
    {
      field: "box15State",
      header: "Box15 State",
    },
    {
      field: "isAllocatedToGP",
      header: "Is Allocated To GP",
    },
  ];

  const onDelete = () => {
    const deleteParms = {
      id: inputFormId,
    };
    dispatch(deleteThunkInputForm1099(deleteParms)).then((response) => {
      // call the list
      if (response.payload._response.success === true) {
        successToastMessage(toast, "Data is Deleted successfully");
        const parameters = {
          legalEntityId: fundDropdown.fundid,
          year: formik.values.year,
          supplierId: +formik.values.supplierId,
        };
        dispatch(getThunkInputForm1099List(parameters));
        setInputFormId(0);
        formik.resetForm();
        setChecked(false);
        formik.setFieldValue("box1Amount", "");
      }
    });
    setOpenDialog(false);
  };

  useEffect(() => {
    console.log("outside");

    if (
      fundDropdown.fundid !== undefined &&
      fundDropdown.fundid > 0 &&
      (formik.values.year !== "" || formik.values.supplierId !== 0)
    ) {
      console.log("inside");
      setloading(true);

      const parameter = {
        legalEntityId: fundDropdown.fundid,
        year: formik.values.year,
        supplierId: +formik.values.supplierId,
      };

      dispatch(getThunkInputForm1099List(parameter)).finally(() => {
        setloading(false);
      });
    }
  }, [fundDropdown.fundid, formik.values.year, formik.values.supplierId]);

  useEffect(() => {
    console.log("outside");

    if (
      fundDropdown.fundid !== undefined &&
      fundDropdown.fundid > 0 &&
      (formik.values.year !== "" || formik.values.supplierId !== 0)
    ) {
      console.log("inside");
      setloading(true);

      const parameter = {
        legalEntityId: fundDropdown.fundid,
        year: formik.values.year,
        supplierId: +formik.values.supplierId,
      };

      dispatch(getThunkInputForm1099List(parameter)).finally(() => {
        setloading(false);
      });
    }
  }, [formik.values.year]);

  useEffect(() => {
    formik.resetForm();
    setChecked(false);
    dispatch(onResetInputForm1099());
    if (fundDropdown.fundid) {
      setloading(true);

      dispatch(
        getThunkSupplierDropDownList({ legalEntityId: fundDropdown.fundid })
      ).finally(() => {
        setloading(false);
      });
    }
  }, [fundDropdown.fundid]);

  let boxAmount = 0;

  // Loop through each investor and accumulate the management fees
  if (inputFormList) {
    for (const investor of inputFormList!) {
      boxAmount += investor.box1Amount;
    }
  }

  console.log("totalManagementFees", boxAmount);
  const formatCurrency = (value: any) => {
    const finalValue = Number(value);
    const parseValue = currencyFormat(Number(finalValue));

    return `${parseValue}`;
  };

  const footer = (
    <ColumnGroup>
      {/* {currentPage === totalPages - 1 ? ( */}
      <Row>
        {/* <Column footer={} /> */}
        <Column
          footer="Total:"
          colSpan={1}
          footerStyle={{ textAlign: "left" }}
        />
        <Column footer="" colSpan={1} footerStyle={{ textAlign: "left" }} />
        <Column footer={formatCurrency(boxAmount).toString()} />
        <Column footer="" colSpan={4} footerStyle={{ textAlign: "left" }} />
      </Row>
      {/* ) : (
        <></>
      )} */}
    </ColumnGroup>
  );

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">
              1099: Input 1099 Forms from Suppliers
            </h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="remove-shadow mr-2"
              type="button"
              // icon="pi pi-file"
              onClick={() =>
                exportExcel(
                  excelTableHeaders,
                  inputFormList,
                  "1099: Input 1099 Forms from Suppliers",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                inputFormList === undefined ||
                inputFormList?.length === 0 ||
                inputFormList === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                inputFormList === undefined ||
                inputFormList?.length === 0 ||
                inputFormList === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
        <Dialog
          visible={openDialog}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setOpenDialog(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Are you sure you want to delete ?
          </h4>
          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={(e) => {
                e.preventDefault();
                onDelete();
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setInputFormId(0);
                setOpenDialog(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>
        <div className="grid col-12 card">
          <div className="col-12">
            <div className="card tabWithoutboxshadow">
              <div className="row grid justify-content-start p-2">
                {!add && !edit && (
                  <div className="d-flex flex-row justify-content-between p-0">
                    <div className="d-block sm:d-flex sm:flex-column col-3 sm:col-6 p-0">
                      <Calendar
                        name="year"
                        value={
                          formik.values.year === null ||
                          formik.values.year === ""
                            ? undefined
                            : new Date(+formik.values.year, 0, 1)
                        }
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("year"),
                          "card-inputBox": true,
                        })}
                        view="year"
                        dateFormat="yy"
                        placeholder="Select year*"
                      />
                    </div>
                    <Button
                      onClick={() => setAdd(true)}
                      className="remove-shadow"
                      type="button"
                    >
                      Add 1099-INT
                    </Button>
                  </div>
                )}

                {add && (
                  <div className="tabHeader-wrap p-0">
                    <Button
                      onClick={onAddClick}
                      // className={
                      //   showTab ? "tabHeadText Active" : "tabHeadText "
                      // }
                      className="tabHeadText Active"
                    >
                      Add 1099-INT{" "}
                    </Button>
                  </div>
                )}
                {edit && (
                  <div className="tabHeader-wrap mb-3 p-0">
                    <span className="tabHeadText Active"> Edit 1099-INT</span>
                  </div>
                )}
              </div>
              {(add || edit) && (
                <div className="mb-2">
                  <div className="formgrid grid">
                    <div className="d-block sm:d-flex sm:flex-column col-3 sm:col-6">
                      <label className="inputLabel">Year</label>

                      <Calendar
                        name="year"
                        value={
                          formik.values.year === null ||
                          formik.values.year === ""
                            ? undefined
                            : new Date(+formik.values.year, 0, 1)
                        }
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("year"),
                          "card-inputBox": true,
                        })}
                        view="year"
                        dateFormat="yy"
                        placeholder="Select year*"
                      />
                      {getFormErrorMessage("year")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column col-3 sm:col-6">
                      <label className="inputLabel">Supplier Name *</label>
                      <Dropdown
                        inputId="supplierId"
                        name="supplierId"
                        value={
                          formik.values.supplierId === 0
                            ? ""
                            : formik.values.supplierId
                        }
                        options={supplierDropdown || []}
                        filter
                        optionLabel="value"
                        optionValue="key"
                        placeholder="Select Supplier"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("supplierId"),
                          "card-inputBox": true,
                        })}
                      />
                      {getFormErrorMessage("supplierId")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column col-3 sm:col-6">
                      <label className="inputLabel">Box 1 Amount *</label>
                      <InputNumber
                        name="box1Amount"
                        value={
                          formik.values.box1Amount === 0
                            ? undefined
                            : formik.values.box1Amount
                        }
                        // value={box1Amount === 0 ? "" : box1Amount}
                        onValueChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("box1Amount"),
                          "w-100": true,
                          "p-filled": true,
                        })}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        min={0}
                        placeholder="Enter Box 1 Amount"
                      />
                      {getFormErrorMessage("box1Amount")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column col-3 sm:col-6">
                      <label className="inputLabel">Box 15 State</label>

                      <InputText
                        name="box15State"
                        value={formik.values.box15State}
                        // value={box1Amount === 0 ? "" : box1Amount}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("box15State"),
                          "card-inputBox": true,
                          "p-filled": true,
                        })}
                        placeholder="Enter Box 15 State"
                      />
                      {getFormErrorMessage("box15State")}
                    </div>
                  </div>
                  <div className="formgrid grid">
                    <div className="card flex-row col-6 mt-2 justify-content-start">
                      <label className="container-checkbox ">
                        <input
                          type="checkbox"
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                        />
                        <span className="checkmark"></span>
                        <span>
                          Check here if all 1099 Interest is allocated to GP
                        </span>
                      </label>
                    </div>
                    <div className="d-flex col-4 justify-content-start">
                      <Button
                        className="mr-4 btn-nav w-7rem justify-content-center"
                        onClick={(e) => {
                          e.preventDefault();
                          formik.resetForm();
                          setEdit(false);
                          setAdd(false);
                          setChecked(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn-nav w-7rem justify-content-center"
                        onClick={(e) => {
                          e.preventDefault();
                          formik.handleSubmit();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-12 p-0">
                <DataTableComponent
                  valueData={inputFormList || []}
                  fieldsElements={dataTableElements}
                  isPaginationAllowed={true}
                  // isDownload={true}
                  fileName="1099FromSupplier"
                  footerElement={inputFormList!.length > 0 && footer}
                  scrollable={true}
                  className="gridcell deletebtn"
                  columnResizeMode="expand"
                  maxHeight={"calc(-225px + 100vh)"}
                  isFullView={true}
                  setOpenDailog={setOpenDailog}
                  openDailog={openDailog}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
