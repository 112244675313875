import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetInvestorIAListService } from "../../../../services/investor/investor";
import error from "../../../../utils/error";
import {
  IViewapproveregistrationsparameters,
  IViewapproveregistrationsState,
} from ".";

const initialState: IViewapproveregistrationsState = {
  success: false,
  message: "",
  loading: "loading",
  data: [],
  isDualsignerEnable: false,
};

// excel-download-investor-list
export const excelDownloadInvestorlistThunk = createAsyncThunk(
  "Investor/excelDownloadInvestorlist",
  async (data: IViewapproveregistrationsparameters) => {
    try {
      const response = await GetInvestorIAListService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Investor/excelDownloadInvestorlist",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(excelDownloadInvestorlistThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      excelDownloadInvestorlistThunk.fulfilled,
      (state, action) => {
        state.data = action.payload.data?.[0] ? action.payload.data[0] : [];
        console.log("slice state.data", state.data);
        state.isDualsignerEnable = action.payload.data?.[0]?.[0]
          ? action.payload.data[0]?.[0].isDualSignerEnabled
          : [];
        state.loading = "succeeded";
        console.log("slice state.isDualsignerEnable", state.isDualsignerEnable);
      }
    );
    builder.addCase(excelDownloadInvestorlistThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
