import { FormikValues } from "formik";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React from "react";
// import { useAppSelector } from "../../../../../store/hooks";

interface IProps {
  // investorId: number;
  // legalEntityId: number;
  investingAccountId: number;
  formik: FormikValues;
  isFormFieldInvalid: any;
  getFormErrorMessage: any;
}

const InternalNotes: React.FC<IProps> = (props) => {
  // Get IA Enrollment Data
  // const getIAEnrollmentDataLinkData = useAppSelector(
  //   (state) => state.getIAEnrollmentData.data
  // );
  // const getIAEnrollmentDataLinkState = useAppSelector(
  //   (state) => state.getIAEnrollmentData.loading
  // );

  return (
    <Accordion activeIndex={[]} className="my-3">
      <AccordionTab
        header="Notes"
        // disabled={
        //   (+props.investingAccountId === 0 ||
        //     (typeof +props.investingAccountId !== "number" &&
        //       !getIAEnrollmentDataLinkData?.isSignCompleted &&
        //       getIAEnrollmentDataLinkData?.signer === "Signer 1") ||
        //     (getIAEnrollmentDataLinkData?.signer === "" &&
        //       getIAEnrollmentDataLinkData?.isOlduser !== true)) ??
        //   false
        // }
      >
        <div className="mb-4">
          <h4 className="sectionHeader">Notes</h4>
          <div className="fromSection">
            <div className="d-flex flex-column p-2">
              <label className="inputLabel">
                Internal Notes (not visible to investor){" "}
              </label>
              <InputText
                name="internalNotes"
                value={props.formik.values.internalNotes}
                onChange={(e) =>
                  props.formik.setFieldValue(
                    "internalNotes",
                    e.target.value?.trimStart()
                  )
                }
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid("internalNotes"),
                  "card-inputBox": true,
                })}
                placeholder="Internal Notes"
              />
              {props.getFormErrorMessage("internalNotes")}
            </div>
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  );
};

export default InternalNotes;
