import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EditFolderFileByIdFileManger } from "../../../../services/Filemanger/filemanager";
import error from "../../../../utils/error";
import { initialRenameFolderState } from "./payload";
import { IAddFolderRenameState } from ".";

const initialState: IAddFolderRenameState = {
  success: false,
  message: "",
  loading: "loading",
  result: undefined,
  error: "",
  data: initialRenameFolderState,
};
export const editFolderFileByIdFileMangerThunk = createAsyncThunk(
  "FileManagement/RenameFolderFilesFM",
  async (data: any) => {
    try {
      const response = await EditFolderFileByIdFileManger(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "renameFolderByFolderIdFileManger",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(editFolderFileByIdFileMangerThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      editFolderFileByIdFileMangerThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(
          "action.payload._response.data==",
          action.payload.response.data.documents
        );
      }
    );
    builder.addCase(editFolderFileByIdFileMangerThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
