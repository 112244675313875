import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
// import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import { RiAddFill } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../../components/loader/Loader";
import { DeleteManagementFeesThunk } from "../../../../store/Investor/setupinvestorfees/deletemanagementfees/slice";
import { GetDAVByEffectiveDateThunk } from "../../../../store/Investor/setupinvestorfees/getdavbyeffectivedate/slice";
import { GetFeeListThunk } from "../../../../store/Investor/setupinvestorfees/getinvestorfees/slice";
import { SaveManagementFeesThunk } from "../../../../store/Investor/setupinvestorfees/savemanagementfees/slice";
import { getThunkPortfolioRegisterInvestorListDropdown } from "../../../../store/features/dropdown/registeredinvestorlist/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  convertUTCToLocalDate,
  convertLocalDateToUTC,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import { successToastMessage } from "../../../../utils/toast-message";

// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function SetupInvestorFees() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  // const todayDate = new Date();

  // const portState = useAppSelector((state) => state.GetMenuList.loading);
  //   const Viewapproveregistor = useAppSelector(
  //     (state) => state.GetInvestorList.data
  //   );
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const investorList = useAppSelector((state) => state.registerinvestor?.data);

  const GetMenuList = useAppSelector((state) => state.GetFeeList?.data);

  //   const [name, setName] = useState<any>("");
  const [accountValue, setAccountValue] = useState<any>();
  const [davDated, setDavDate] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState<any>();
  const [loading, setloading] = useState<any>(false);
  const [existingData, setExistingData] = useState<any>();
  const [maxHeight, setMaxHeight] = useState("calc(-263px + 100vh)");
  const [openDailog, setOpenDailog] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isAdd, setIsAdd] = useState(false);
  // const [error, setError] = useState(false);
  const reqParam = {
    LegalEntityId: fundDropdown.fundid,
  };
  const formik = useFormik({
    initialValues: {
      Date: "",
      Remark: "",
      fees: undefined,
      inv: "",
    },
    validate: (data) => {
      //
      const errors: any = {};
      console.log("errors data", data);
      if (data.Date === "") {
        errors.Date = "Please enter effective date";
      }
      if (!data.fees) {
        errors.fees = "Please enter fee %";
      }
      if (data.fees && data.fees > 100) {
        errors.fees = "% should be less than 100";
      }
      if (!data.inv) {
        errors.inv = "Please select investor account";
      }
      return errors;
    },
    onSubmit: async (data) => {
      const addParam = {
        legalEntityId: fundDropdown.fundid,
        partnerId: +formik.values.inv,
        effectiveDate: convertLocalDateToUTC(formik.values.Date),
        previousDAV: +accountValue ? +accountValue : 0,
        fee: formik.values.fees,
        remark: formik.values.Remark,
        loginId: profileData.userID!,
      };
      setloading(true);
      dispatch(SaveManagementFeesThunk(addParam)).then(() => {
        dispatch(GetFeeListThunk(reqParam))
          .then(() => {
            setloading(false);
          })
          .catch(() => {
            setloading(false);
          });
        formik.resetForm();
        setAccountValue("");
        setIsAdd(false);
        successToastMessage(toast, "Data saved successfully");
      });
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const deleteButton = (rowData: any) => {
    return (
      <div className="flex flex-column ">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center widthdeletebtn"
            onClick={(e) => {
              e.preventDefault();
              setId(rowData.id);

              setVisible(true);
            }}
          >
            <span className="text-white">Delete</span>
          </Button>
        </div>
      </div>
    );
  };
  const onDelete = () => {
    const deleteParms = {
      ManagementId: id,
    };
    dispatch(DeleteManagementFeesThunk(deleteParms)).then(() => {
      setloading(true);
      dispatch(GetFeeListThunk(reqParam))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
      formik.setFieldValue("Annual", "");
      setDavDate("");
      successToastMessage(toast, "Deleted successfully");
    });
    setVisible(false);
  };

  const dataTableElements = [
    {
      field: "legalEntityName",
      header: "Fund Name",
      style: { minWidth: "150px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.legalEntityName}>
          {rowData?.legalEntityName}
        </div>
      ),
    },
    {
      field: "invName",
      header: "Investor Account",
      style: { minWidth: "210px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.invName}>
          {rowData?.invName}
        </div>
      ),
    },
    {
      field: "effectiveDate",
      header: "Effective Date for Fee",
      dataType: "date",
      body: (rowData: any) =>
        convertUTCToLocalDate(rowData.effectiveDate, "mm/dd/yyyy"),
      filterElement: dateFilterTemplate,
      style: { minWidth: "220px" },
    },
    {
      field: "previousDAV",
      header: "Effective Date DAV",
      body: (rowData: any) => currencyFormat(rowData.previousDAV),
      style: { minWidth: "220px" },
    },
    {
      field: "latestDAV",
      header: "Latest DAV ",
      style: { minWidth: "180px" },
    },
    {
      field: "latestDAVDate",
      header: "Latest DAV Date",
      body: (rowData: any) => rowData.latestDAVDate,
      style: { minWidth: "180px" },
    },
    {
      field: "fee",
      header: "Fee %",
      style: { minWidth: "140px" },
    },
    {
      field: "remark",
      header: "Sponsor Note",
      style: { minWidth: "170px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.remark}>
          {rowData?.remark}
        </div>
      ),
    },
    {
      field: "",
      header: "",
      body: (rowData: any) => deleteButton(rowData),
      style: { minWidth: "140px" },
    },
  ];

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setAttribute("autocomplete", "off");
    }
  }, []);

  useEffect(() => {
    if (fundDropdown.fundid) {
      formik.resetForm();
      // formik.setFieldValue("fees", undefined);
      setAccountValue("");
      setDavDate("");
    }
  }, [fundDropdown.fundid]);

  useEffect(() => {
    setExistingData(getListDateConversion(GetMenuList, ["effectiveDate"]));
  }, [GetMenuList]);

  // useEffect(() => {
  //   setloading(true);
  //   dispatch(GetFeeListThunk(reqParam))
  //     .then(() => {
  //       setloading(false);
  //     })
  //     .catch(() => {
  //       setloading(false);
  //     });
  //   dispatch(getThunkPortfolioRegisterInvestorListDropdown(reqParam));
  // }, []);

  useEffect(() => {
    if (fundDropdown.fundid) {
      setloading(true);
      dispatch(GetFeeListThunk(reqParam))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
      dispatch(getThunkPortfolioRegisterInvestorListDropdown(reqParam));
    }
  }, [fundDropdown.fundid]);

  useEffect(() => {
    const q = {
      EffectiveDate: formik.values.Date
        ? convertLocalDateToUTC(formik.values.Date)
        : // eslint-disable-next-line unicorn/no-null
          null,
      PartnerId: +formik.values.inv,
    };
    if (formik.values.inv && formik.values.Date) {
      dispatch(GetDAVByEffectiveDateThunk(q)).then((res: any) => {
        const data = res.payload ? res.payload.data[0].dailyAccountValue : "";
        const davData = res.payload ? res.payload.data[0].transactionDate : "";
        setAccountValue(data);
        const localDate = convertUTCToLocalDate(davData);
        const dateObject = new Date(localDate);

        const formattedDate = dateObject.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        setDavDate(formattedDate);
      });
    }
  }, [formik.values.inv, formik.values.Date]);
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const investorOptionTemplate = (option: any) => {
    return (
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "inherit",
          cursor: "pointer",
        }}
        title={option.investorName}
      >
        {option.investorName}
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} className="themeToast" />
      {loading && <Loader />}
      <div className="col-12 flex">
        <div className="card col-11 p-0 pgHeaderWrap ">
          <h1 className="pageHeader fs-2">Setup Investor Fees</h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className="col-1 p-0 align-items-center flex justify-content-end">
          <Button
            className="btn-navActive"
            type="button"
            icon="pi pi-window-maximize"
            disabled={
              existingData === undefined ||
              existingData?.length === 0 ||
              existingData === null
            }
            onClick={() => setOpenDailog(true)}
            title="View Full Screen"
          />
        </div>
      </div>
      {/* <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}
        <div className="card col-11 p-0 mt-1 pgHeaderWrap ">
          <h1 className="pageHeader fs-2">Setup Investor Fees</h1>
          <p className="pageSubHeader"> </p>
        </div>
      </div> */}
      {/* <div className="grid"> */}
      <Dialog
        visible={visible}
        className="w-75 md:w-3 DialogInvestment"
        onHide={() => setVisible(false)}
      >
        <h4 className="pageHeader text-center mb-5 pt-5">
          Are you sure you want to delete ?
        </h4>

        <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
          <Button
            onClick={onDelete}
            className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
          >
            Yes
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setVisible(false);
            }}
            className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
          >
            No
          </Button>
        </div>
      </Dialog>

      {/* <div className="col-12"> */}
      <div className="card">
        {!isAdd ? (
          <div className="px-1 py-2">
            <Button
              className="btn-navActive w-9rem mb-1"
              onClick={() => {
                setIsAdd(true);
              }}
            >
              Add Fees
              <span className="pl-1">
                <RiAddFill />
              </span>
            </Button>
          </div>
        ) : (
          <div className="tabHeader-wrap col-6">
            <span className="tabHeadText Active"> Add Fees</span>
          </div>
        )}
        {isAdd && (
          <>
            <div className="formgrid grid px-2 col-12 ">
              <div className="col-12 md:col-3 m-0 mb-md-0">
                <div className="">
                  <label className="inputLabel">Investor Account *</label>
                  <Dropdown
                    name="inv"
                    style={{ width: "100%" }}
                    options={investorList}
                    optionLabel="investorName"
                    optionValue="investorID"
                    filter
                    resetFilterOnHide={true}
                    showFilterClear={true}
                    itemTemplate={investorOptionTemplate}
                    placeholder="Investor Account"
                    onChange={(e) => formik.setFieldValue("inv", e.value)}
                    value={formik.values.inv}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("inv"),
                      "card-inputBox": true,
                    })}
                  />
                  {getFormErrorMessage("inv")}
                </div>
              </div>
              <div className="col-12 md:col-3 m-0 mb-md-0">
                <div className="">
                  <label className="inputLabel">Fee* %</label>
                  <InputNumber
                    ref={(el) => {
                      if (el) {
                        const inputElement = el
                          .getElement()
                          .querySelectorAll("input")[0];
                        if (inputElement) {
                          inputElement.setAttribute("autocomplete", "off");
                        }
                      }
                    }}
                    name="fees"
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("fees"),
                      // "card-inputBox": true,
                    })}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      e.value && e.value > 10 && e.value < 99
                        ? setMaxHeight("calc(-305px + 100vh)")
                        : setMaxHeight("calc(-263px + 100vh)");
                      formik.setFieldValue("fees", e.value);
                      console.log("Fees =>", formik.values.fees);
                    }}
                    value={formik.values.fees}
                    placeholder="Fee"
                    maxFractionDigits={2}
                    min={0}
                  />
                  {getFormErrorMessage("fees")}
                </div>
                {formik.values.fees !== undefined &&
                  formik.values.fees > 10 &&
                  formik.values.fees < 99 && (
                    <small className="inputLabelNotification p-0">
                      This number is unusally high for a fee %, do you want to
                      continue?
                    </small>
                  )}
              </div>
              <div className="col-12 md:col-3 m-0 mb-md-0">
                <div className="">
                  <label className="inputLabel">Effective Date *</label>
                  {/* <div className="card flex justify-content-center"> */}
                  <Calendar
                    name="Date"
                    value={
                      formik.values.Date === null || formik.values.Date === ""
                        ? undefined
                        : new Date(formik.values.Date)
                    }
                    onChange={(e) => formik.setFieldValue("Date", e.value)}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("Date"),
                      "card-inputBox": true,
                    })}
                    // minDate={todayDate}
                    placeholder="Effective Date"
                    dateFormat="mm/dd/yy"
                  />
                  {getFormErrorMessage("Date")}
                </div>
              </div>
              <div className="col-12 md:col-3 m-0 mb-md-0">
                <div className="">
                  <label className="inputLabel">Daily Account Value</label>
                  <InputText
                    name="ExpirationDate"
                    className={classNames("card-inputBox ")}
                    onChange={(e) => setAccountValue(e.target.value)}
                    value={accountValue}
                    placeholder="Daily Account Value"
                    disabled
                  />
                  {/* {error && (
                        <>
                          <span className="text-xs text-red-600">
                            Supplier Type is required
                          </span>
                        </>
                      )} */}
                </div>
              </div>
            </div>
            <div className="formgrid grid  col-12">
              <div className="col-12 md:col-3 m-0 mb-md-0">
                <div className="">
                  <label className="inputLabel">DAV Date</label>
                  <InputText
                    name="ExpirationDate"
                    className={classNames("card-inputBox")}
                    onChange={(e) => setDavDate(e.target.value)}
                    placeholder="DAV Date"
                    value={davDated}
                    disabled
                  />
                  {/* {error && (
                        <>
                          <span className="text-xs text-red-600">
                            Supplier Type is required
                          </span>
                        </>
                      )} */}
                </div>
              </div>
              <div className="col-12 md:col-3 mr-2 m-0 mb-md-0">
                <div className="">
                  <label className="inputLabel">Remark</label>
                  <InputText
                    name="Remark"
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("Remark"),
                      "card-inputBox": true,
                    })}
                    onChange={(e) =>
                      formik.setFieldValue("Remark", e.target.value)
                    }
                    placeholder="Remark"
                    value={formik.values.Remark}
                  />
                  {getFormErrorMessage("Remark")}
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <Button
                  className="mr-4 btn-nav w-7rem justify-content-center"
                  onClick={(e) => {
                    e.preventDefault();
                    // setIsFormSubmitted(true);
                    console.log("clicked");
                    formik.resetForm();
                    setAccountValue("");
                    setDavDate("");
                    setIsAdd(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn-nav w-7rem justify-content-center"
                  onClick={(e) => {
                    e.preventDefault();
                    // setIsFormSubmitted(true);
                    console.log("clicked");
                    formik.handleSubmit();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
            {/* </div> */}
            {/* <div className="d-flex justify-content-center mb-3 mt-4">
            <Button
              className="mr-4 btn-nav w-7rem justify-content-center"
              onClick={(e) => {
                e.preventDefault();
                // setIsFormSubmitted(true);
                console.log("clicked");
                formik.resetForm();
                setAccountValue("");
                setDavDate("");
              }}
            >
              Cancel
            </Button>

            <Button
              className="btn-nav w-7rem justify-content-center"
              onClick={(e) => {
                e.preventDefault();
                // setIsFormSubmitted(true);
                console.log("clicked");
                formik.handleSubmit();
              }}
            >
              Save
            </Button>
          </div> */}
          </>
        )}
        {/* <div className="mt-2"> */}
        <DataTableComponent
          valueData={existingData}
          fieldsElements={dataTableElements}
          isPaginationAllowed={true}
          className="gridcell deletebtn "
          scrollable={true}
          maxHeight={maxHeight}
          openDailog={openDailog}
          setOpenDailog={setOpenDailog}
          columnResizeMode="expand"
        />
      </div>
      {/* </Card> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </>
  );
}
