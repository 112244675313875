import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,

  // useJsApiLoader,
} from "@react-google-maps/api";
import { Button } from "primereact/button";
// import { Carousel } from "primereact/carousel";
import { Image } from "primereact/image";
// import { Dialog } from "primereact/dialog";
import { Skeleton } from "primereact/skeleton";
// import { Image } from "primereact/image";
import React, { useEffect, useState } from "react";
import { RiMapPin2Fill, RiCalendar2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ImageNotFound from "../../images/ImageNotFoundNew.png";
import blueIcon from "../../images/blueIcon.png";
import orangeIcon from "../../images/orangeIcon.png";
import yellowIcon from "../../images/yellowIcon.png";
// import { getThunkMap } from "../../store/Googlemap/slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  // getOpportunityImageData,
  getOpportunityImageDataStatus,
  getOpportunityImagesDataThunk,
} from "../../store/opportunity/image-data/slice";
import {
  getOpportunitiyInvestmentDetailsThunk,
  getOpportunityDetailsStatus,
} from "../../store/opportunity/opportunity-investment-details/slice";

export interface IProps {
  location: ILocation[];
}

export interface ILocation {
  id: any;
  category: string;
  data: {
    lat: number;
    lng: number;
  };
}

export interface IDetails {
  p: {
    productID: number;
    productName: string;
    category: string;
    city: string;
    state: string;
    estExitDate: string;
    remainingAllocation: number;
    targetReturn: number;
    productPrimaryImage: string;
  };
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars

const Map: React.FC<IProps> = ({ location }) => {
  const [visible, setVisible] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [Id, setId] = useState<any>();
  const [mapKey, setMapKey] = useState("");

  // const profileData = useAppSelector((state) => state.profile.data![0]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // const parameters = {
  //   investorID: profileData.investorID,
  //   partnerID: +profileData.partnerID,
  //   legalEntityID: profileData.legalEntityID,
  // };

  const handleMarkerClick = (id: string) => {
    // navigate(`../OpportunityDetails/${id}`);
    dispatch(getOpportunityImagesDataThunk(+id));
    dispatch(getOpportunitiyInvestmentDetailsThunk(+id));
    navigate(`../../OpportunityDetails/${id}`);
  };

  const handleDialogClick = (id: any) => {
    if (+id === +Id) {
      setId(id);
      setVisible(true);
      setShowInfoWindow(false);
      dispatch(getOpportunityImagesDataThunk(+id));
      dispatch(getOpportunitiyInvestmentDetailsThunk(+id));
    }
  };

  const handleMouseOver = (id: any) => {
    setId(id);
    setShowInfoWindow(true);
    setVisible(false);
    dispatch(getOpportunityImagesDataThunk(+id));
    dispatch(getOpportunitiyInvestmentDetailsThunk(+id));
  };

  const handleMouseOut = (id: any) => {
    setId(id);
    setShowInfoWindow(false);
  };

  const imageDetailStatus = useAppSelector(getOpportunityImageDataStatus);
  // const imageDetailsData: any = useAppSelector(getOpportunityImageData);
  const detailStatus = useAppSelector(getOpportunityDetailsStatus);

  // const investmentDetailStatus = useAppSelector(getOpportunityDetailsStatus);
  const investmentDetails = useAppSelector(
    (state) => state.opportunityDetails.Data
  );
  const investmentDetailsLoader = useAppSelector(
    (state) => state.opportunityDetails.loading
  );
  const Location: ILocation[] = [];

  for (let i = 0; i < location!.length; i++) {
    Location.push({
      id: location[i].id,
      category: location[i].category,
      data: location![i].data,
    });
  }

  const Img = investmentDetails?.primaryImage;

  // const googleMap = useAppSelector((state) => state.Map.gmap);

  const googleMap1 = useAppSelector((state) =>
    state.Map.gmap ? state.Map?.gmap[0]?.name : ""
  );

  useEffect(() => {
    // dispatch(getThunkMap(parameters));
    setMapKey(googleMap1);
  }, [googleMap1]);

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center: ILocation = {
    id: Id,
    category: "",
    data: {
      lat: 40.044,
      lng: -82.96,
    },
  };

  // let isLoaded = { isLoaded: false };

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyDxJeqToAmydzcirbT050LRih06aCPyTrk",
  //   libraries: ["geometry", "drawing"],
  // });

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   // googleMapsApiKey: "AIzaSyDxJeqToAmydzcirbT050LRih06aCPyTrk",
  //   googleMapsApiKey: googleMap1,
  //   libraries: ["geometry", "drawing"],
  // });

  return (
    <>
      {mapKey ? (
        <LoadScript googleMapsApiKey={googleMap1}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center.data}
            zoom={3}
          >
            {Location.map(({ id, data, category }) => {
              let icon = "Commercial Debt";
              switch (category) {
                case "Commercial Debt":
                  icon = yellowIcon;
                  break;
                case "Residential Debt":
                  icon = blueIcon;
                  break;
                default:
                  icon = orangeIcon;

                // blueIcon, yellowIcon, orangeIcon
              }
              return (
                <>
                  <Marker
                    key={id}
                    position={data}
                    icon={icon}
                    // label={category}
                    // onClick={() => handleMarkerClick(item.id)}
                    onClick={() => handleDialogClick(id)}
                    onMouseOver={() => handleMouseOver(id)}
                    onMouseOut={() => handleMouseOut(id)}
                  >
                    {" "}
                    {showInfoWindow && id === Id && (
                      <InfoWindow
                        key={id}
                        onCloseClick={() => setShowInfoWindow(false)}
                      >
                        {investmentDetailsLoader === "succeeded" ? (
                          <h4 className="mb-0">
                            {" "}
                            {investmentDetails?.productName}
                          </h4>
                        ) : (
                          <Skeleton width="100%" height="50px"></Skeleton>
                        )}
                      </InfoWindow>
                    )}
                    {visible && id === Id && (
                      <InfoWindow
                        key={id}
                        onCloseClick={() => setVisible(false)}
                      >
                        <div className="grid">
                          <div className="col-6">
                            <div className="overflow-hidden">
                              {imageDetailStatus === "succeeded" ? (
                                <Image
                                  src={Img !== null ? Img! : ImageNotFound}
                                  imageClassName="gMapImg"
                                />
                              ) : (
                                <Skeleton
                                  width="100%"
                                  height="350px"
                                ></Skeleton>
                              )}
                              <div className="propIMgTag">Equity</div>
                            </div>
                          </div>
                          <div className="col-6">
                            {detailStatus === "succeeded" ? (
                              <>
                                {" "}
                                <h2 className="pageHeader">
                                  {investmentDetails?.productName}
                                </h2>
                                <div className="mt-4 propAddrMap">
                                  <RiMapPin2Fill />
                                  &nbsp;
                                  <span>
                                    {`${investmentDetails?.cityStateCode}${
                                      investmentDetails?.country !==
                                        "United States" &&
                                      `, ${investmentDetails?.country}`
                                    }`}
                                  </span>
                                </div>
                                <div className="d-flex flex-row mt-4">
                                  <div className="d-flex flex-row p-2">
                                    <RiCalendar2Fill />
                                    &nbsp;
                                    <div className="d-flex flex-column">
                                      <span className="propInfoTleMap">
                                        Date Offered:
                                      </span>
                                      <span className="propInfoTxtMap">
                                        {investmentDetails?.fundingDate}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-row p-2">
                                    <RiCalendar2Fill />
                                    &nbsp;
                                    <div className="d-flex flex-column">
                                      <span className="propInfoTleMap">
                                        Start Date:
                                      </span>
                                      <span className="propInfoTxtMap">
                                        {investmentDetails?.fundingDate}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-row p-2">
                                    <RiCalendar2Fill />
                                    &nbsp;
                                    <div className="d-flex flex-column">
                                      <span className="propInfoTleMap">
                                        Estimated Exit Date:
                                      </span>
                                      <span className="propInfoTxtMap">
                                        {investmentDetails?.estExitDate}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-100 mt-4">
                                  <p className="pt-5 propInterest">
                                    {investmentDetails?.interestRate} %
                                  </p>
                                  <p>Total Interest (Debt) / IRR (Equity)</p>

                                  <div className="col-5">
                                    <Button
                                      key={Id}
                                      label="MORE INFORMATION"
                                      onClick={() => handleMarkerClick(Id)}
                                      className="btn-dialog"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <h1 className="pageHeader">
                                    <Skeleton></Skeleton>{" "}
                                  </h1>
                                  <Skeleton></Skeleton>{" "}
                                  <div className="d-flex flex-row">
                                    <div className="d-flex flex-row p-2">
                                      <Skeleton></Skeleton>{" "}
                                      <div>
                                        <div className="d-flex flex-column">
                                          <Skeleton></Skeleton>{" "}
                                          <span>
                                            <Skeleton></Skeleton>{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row p-2">
                                      <Skeleton></Skeleton>{" "}
                                      <div>
                                        <div className="d-flex flex-column">
                                          <Skeleton></Skeleton>{" "}
                                          <span>
                                            <Skeleton></Skeleton>{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="d-flex flex-row p-2">
                                      <Skeleton></Skeleton>{" "}
                                      <div>
                                        <div className="d-flex flex-column">
                                          <Skeleton></Skeleton>{" "}
                                          <span>
                                            <Skeleton></Skeleton>{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-100 mt-4">
                                  <div className="p-5">
                                    <Skeleton></Skeleton>{" "}
                                  </div>

                                  <div className="col-5">
                                    <Skeleton></Skeleton>{" "}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                </>
              );
            })}
          </GoogleMap>
        </LoadScript>
      ) : (
        <Skeleton width="100%" height="350px"></Skeleton>
      )}
    </>
  );
};

export default Map;
