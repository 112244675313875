import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetInvestmentIdByProductService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IInvestmentIdParameter, IGetInvestmentIdByProductState } from ".";

const initialState: IGetInvestmentIdByProductState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    externalInvestmentId: undefined,
  },
};
export const getThunkInvestmentIdByProduct = createAsyncThunk(
  "getInvestmentIdByProduct",
  async (data: IInvestmentIdParameter) => {
    try {
      console.log("data", data);
      const response = await GetInvestmentIdByProductService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getInvestmentIdByProduct",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkInvestmentIdByProduct.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getThunkInvestmentIdByProduct.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log("hr", action.payload._response);
        if (action.payload._response.data !== null) {
          state.data = action.payload._response.data;
        }
        state.success = action.payload._response.success;
        state.message = action.payload._response.message;
      }
    );
    builder.addCase(getThunkInvestmentIdByProduct.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
      throw new Error(error(action.error));
    });
  },
});

export default slice.reducer;
