import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  DeleteFundService,
  GetDwollaReportsService,
  GetFundListService,
} from "../../services/Dwolla/dwolla";
import error from "../../utils/error";
import { IGetSetupFundListState } from ".";

const initialState: IGetSetupFundListState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const GetfundListThunk = createAsyncThunk(
  "Dwolla/GetFundList",
  async (data: any) => {
    try {
      const response = await GetFundListService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const GetDwollaReportsThunk = createAsyncThunk(
  "Dwolla/GetDwollaReportsList",
  async (data: any) => {
    try {
      const response = await GetDwollaReportsService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const deletefundListThunk = createAsyncThunk(
  "Dwolla/deleteFundList",
  async (data: any) => {
    try {
      const response = await DeleteFundService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Dwolla/FundList",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetfundListThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetfundListThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data[0] : [];
    });
    builder.addCase(GetfundListThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });

    builder.addCase(GetDwollaReportsThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetDwollaReportsThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data : [];
    });
    builder.addCase(GetDwollaReportsThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });

    builder.addCase(deletefundListThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(deletefundListThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data[0] : [];
    });
    builder.addCase(deletefundListThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
