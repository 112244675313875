import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
interface IProps {
  openDialog: boolean;
  setOpenDialog: any;
  onConfirm: any;
  onCancel: any;
  textContent: string;
}

export default function ConfirmDailogbox(props: IProps) {
  return (
    <Dialog
      visible={props.openDialog}
      className="w-75 md:w-3 DialogInvestment"
      onHide={() => props.setOpenDialog(false)}
    >
      <h4 className="pageHeader text-center mb-5 pt-5">{props?.textContent}</h4>
      <div className=" flex  flex-wrap align-self-center  align-items-center justify-content-between">
        <Button
          onClick={(e) => {
            e.preventDefault();
            props.onConfirm();
          }}
          className="blueButtonShadow flex flex-1 flex align-items-center justify-content-center  font-bold  m-2 px-5 py-3 border-round"
        >
          Yes
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            props.onCancel();
          }}
          className="blueButtonShadow flex flex-1  align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round"
        >
          No
        </Button>
      </div>
    </Dialog>
  );
}
