import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
// import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";

import Loader from "../../../../components/loader/Loader";
import { GettoFundListThunk } from "../../../../store/dwolla/fundtotransfer/slice";
import { GetFromFundListThunk } from "../../../../store/dwolla/fundtransfer/slice";
import { FundTransferDwollaCInfoThunk } from "../../../../store/dwolla/transfer/slice";
import { getCustomerTypeThunk } from "../../../../store/finance/plaid/getCustomerType/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

export default function TransferFundsToInvestor() {
  const dispatch = useAppDispatch();
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const fromlist = useAppSelector((state) => state.fromFundList.data);
  const tolist = useAppSelector((state) => state.toFundList.data);
  const [loading, setLoading] = useState(false);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const toast = useRef<Toast>(null);
  const [accType, setAccType] = useState();
  function SaveTransferInfo(data: any) {
    if (data !== undefined) {
      setLoading(true);
      const parameters = {
        FundAmt: data.FundAmt,
        FromLegalOrInvId: data.FromLegalOrInvId,
        ToLegalOrInvId: data.ToLegalOrInvId,
        SourceCustomerType: "Fund",
        DestinationCustomerType: "BusinessInv",
        TranTypeId: 15,
        UserId: profileData?.userID,
        LpPartnerId: data.ToLegalOrInvId,
      };

      dispatch(FundTransferDwollaCInfoThunk(parameters)).then((res: any) => {
        console.log(res);
        setLoading(false);

        if (res?.payload?.success) {
          toast.current?.show({
            severity: "success",
            // summary: "Success",
            life: 5000,
            detail:
              "Transaction has been successfully initiated. Latest transaction status is available on Transaction History tab.",
            // life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            // summary: "Success",
            life: 5000,
            detail: res.payload.message,
            // life: 3000,
          });
        }

        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      FundAmt: undefined,
      FromLegalOrInvId: 0,
      ToLegalOrInvId: 0,
      SourceCustomerType: "Fund",
      // DestinationCustomerType: "BusinessInv",
      DestinationCustomerType: accType,
      TranTypeId: 0,
    },
    validate: (data) => {
      const errors: any = {};
      if (!data.FromLegalOrInvId) {
        errors.FromLegalOrInvId = " Select from";
      }
      if (!data.ToLegalOrInvId) {
        errors.ToLegalOrInvId = "Select to";
      }
      if (!data.FundAmt || data.FundAmt < 0) {
        errors.FundAmt = "Enter amount";
      }

      // const eightDigitRegex = /^\d{1,8}$/;

      // if (data.FundAmt && !eightDigitRegex.test(data.FundAmt)) {
      //   errors.FundAmt = "Number should be 8 digit";
      // }

      if (data.FundAmt && data.FundAmt > 100 * 1000) {
        errors.FundAmt = "Limit per transaction is $100,000 or less";
      }
      return errors;
    },
    onSubmit: (data) => {
      console.log(data);
      SaveTransferInfo(data);
      formik.resetForm();
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  //   const onAddClick = () => {
  //     formik.resetForm();
  //   };

  useEffect(() => {
    formik.resetForm();
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameters = {
        Type: "T",
        legalEntityID: fundDropdown.fundid,
        UserId: 2,
      };
      dispatch(GetFromFundListThunk(parameters));
    }
  }, [fundDropdown]);
  useEffect(() => {
    if (formik.values.ToLegalOrInvId) {
      const req = {
        investorID: profileData.investorID,
        legalEntityID: fundDropdown.fundid,
        partnerID: formik.values.ToLegalOrInvId,
      };
      dispatch(getCustomerTypeThunk(req)).then((res: any) => {
        if (res.payload._response.data) {
          setAccType(res.payload._response.data.accountType);
        }
      });
    }
  }, [formik.values.ToLegalOrInvId]);
  console.log("inside useefectttttF", accType);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const parameters = {
      Type: "A",
      TransferFrom: value,
    };
    dispatch(GettoFundListThunk(parameters));
    formik.setFieldValue(name, value);
  };

  const handleSetformvalue = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Transfer funds to investor</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
        <div className="grid col-12">
          <div className="col-12">
            <div className="card my-3 tabWithoutboxshadow">
              <Card>
                <div className="grid justify-content-start"> </div>
                <>
                  <div className="mb-4 mt-2">
                    <div className="formgrid grid px-2">
                      <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
                        <label className="inputLabel">From *</label>
                        <Dropdown
                          inputId="FromLegalOrInvId"
                          name="FromLegalOrInvId"
                          value={
                            formik.values.FromLegalOrInvId === 0
                              ? ""
                              : formik.values.FromLegalOrInvId
                          }
                          options={fromlist === null ? [] : fromlist}
                          // filter
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select From"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("FromLegalOrInvId"),
                            "card-inputBox": true,
                          })}
                        />
                        {getFormErrorMessage("FromLegalOrInvId")}
                      </div>
                      <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
                        <label className="inputLabel">To *</label>
                        <Dropdown
                          inputId="ToLegalOrInvId"
                          name="ToLegalOrInvId"
                          value={formik.values.ToLegalOrInvId}
                          options={
                            tolist?.transferTo === null
                              ? []
                              : tolist?.transferTo
                          }
                          // filter
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select to"
                          onChange={(e) => {
                            handleSetformvalue(e);
                          }}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("ToLegalOrInvId"),
                            "card-inputBox": true,
                          })}
                        />
                        {getFormErrorMessage("ToLegalOrInvId")}
                      </div>
                    </div>
                    <div className="formgrid grid px-2">
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                        <label className="inputLabel">Amount</label>

                        <InputNumber
                          inputId="FundAmt"
                          name="FundAmt"
                          value={formik.values.FundAmt}
                          onValueChange={(e) => {
                            handleSetformvalue(e);
                          }}
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          placeholder="Enter Amount"
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("FundAmt"),
                            "card-inputBox": true,
                          })}
                        />
                        {getFormErrorMessage("FundAmt")}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center  mt-4 mb-3">
                    <Button
                      className="mr-4 btn-nav w-7rem justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.resetForm();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="btn-nav w-7rem justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                      }}
                    >
                      Initiate Transfer
                    </Button>
                  </div>
                </>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
