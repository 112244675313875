import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function getInvestorTypeService() {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/geInvestorTypeDropdown`
  );
}

export function getInvestorListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetUserList?LegalEntityId=${data.legalEntityId}`
  );
}

export function getViewSoftCommitListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetSoftCommitsByProductId?ProductId=${data}`
  );
}

export function addViewGatherSoftCommitService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/CreateViewGatherSoftCommits`,
    data
  );
}

export function getInvestorBasicInfoService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetInvestorBasicDetails?PartnerId=${
      data.partnerId
    }&isInvestor=${data.isInvestor}`
  );
}

export function deleteViewSoftCommitService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/DeleteSoftCommit?Id=${data.softCommitId}`
  );
}

export function CheckEmailExistService(data: any) {
  const value = encodeURIComponent(data.email);
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/CheckEmailAddress?SoftCommitId=${
      data.softCommitId
    }&Email=${value}`
  );
}
