import {
  thirdPartyApiData,
  fundManageApi,
  bankMailInfoData,
  edgarFilingInfoData,
} from "../../../../store/features/operation/setup-fund/setup-fund-state-check/payload";
import { convertUTCToLocalDate } from "../../../../utils/date-formatter";

export const ThirdPartyApiFunction = (
  thirdPartyAPIkeys: any,
  thirdPartyApi: any
) => {
  // const list = thirdPartyApi;

  const list1 =
    thirdPartyAPIkeys !== undefined
      ? {
          ...thirdPartyApi.PreviousState,
          activeCampaigApiToken: thirdPartyAPIkeys?.activeCampaigApiToken,
          activeCampaignAccountName:
            thirdPartyAPIkeys?.activeCampaignAccountName,
          chargebeeID: thirdPartyAPIkeys?.chargebeeID,
          chargebeePlanID: thirdPartyAPIkeys?.chargebeePlanID,
          chargebeeSubscriptionID: thirdPartyAPIkeys?.chargebeeSubscriptionID,
          goHighLevelAPIToken: thirdPartyAPIkeys?.goHighLevelAPIToken,
          hubSpotAPIKey: thirdPartyAPIkeys?.hubSpotAPIKey,
        }
      : { ...thirdPartyApiData };
  const list = { ...thirdPartyApi, PreviousState: list1 };
  return list;
};

export const FundManagerInfoFunction = (
  setupFundInfo: any,
  fundManagerInfo: any,
  entityListId: any
) => {
  console.log("FundManagerInfoFunction");

  const list1 =
    setupFundInfo?.fundProfileDetails !== undefined
      ? {
          ...fundManagerInfo.PreviousState,
          fundName: setupFundInfo?.fundProfileDetails?.fundName,
          fundInitialSetupDate: entityListId?.dateEstablished,
          fundEntityTypeId: setupFundInfo?.fundProfileDetails?.fundEntityTypeId,
          fundTypeId: setupFundInfo?.fundProfileDetails?.fundTypeId,
          investorSignUp: setupFundInfo?.fundProfileDetails?.isAllowSignUp,
          showFilledDeals: setupFundInfo?.fundProfileDetails?.showFilledDeals,
          fundSlug: setupFundInfo?.fundProfileDetails?.urlName,
          ein: setupFundInfo?.fundProfileDetails?.fundEIN,
          phoneNumber: setupFundInfo?.fundProfileDetails?.mobile,
          address: setupFundInfo?.fundProfileDetails?.lpAddress,
          city: setupFundInfo?.fundProfileDetails?.lpCity,
          state: setupFundInfo?.fundProfileDetails?.addressFundState,
          registeredState: setupFundInfo?.fundProfileDetails?.fundState,
          zipCode: setupFundInfo?.fundProfileDetails?.lpZipCode,
          unitPrice: setupFundInfo?.fundProfileDetails?.unitPrice,
          digisignerPPMTempId:
            setupFundInfo?.fundProfileDetails?.digiTemplateID,
          dualSigner: setupFundInfo?.fundProfileDetails?.isDualSigner,
          signFundPPM: setupFundInfo?.fundProfileDetails?.isPPMSigner,
          fundWebsite: setupFundInfo?.fundProfileDetails?.fundWebsite,
          emailLogoUrl: setupFundInfo?.fundProfileDetails?.logoUrl,
          primaryFundEmail: setupFundInfo?.fundAdminData
            ? setupFundInfo?.fundAdminData?.primaryFundAdminEmail
            : "",
          fundPrimaryColour:
            setupFundInfo?.fundProfileDetails?.fundPrimaryColour !== null
              ? setupFundInfo?.fundProfileDetails?.fundPrimaryColour
              : "#004d82",
          gpllcName: setupFundInfo?.fundGPProfileDetails?.gpllcName,
          gpRegisteredState: setupFundInfo?.fundGPProfileDetails?.gpState,
          gpEin: setupFundInfo?.fundGPProfileDetails?.gpein,
          gpAddress: setupFundInfo?.fundGPProfileDetails?.address,
          gpCity: setupFundInfo?.fundGPProfileDetails?.city,
          gpState: setupFundInfo?.fundGPProfileDetails?.addressGPState,
          gpZipCode: setupFundInfo?.fundGPProfileDetails?.zipCode,
          fundGPEmail: setupFundInfo?.fundGPProfileDetails?.gpEmail,
          previousGPEmail: setupFundInfo?.fundGPProfileDetails?.gpEmail,
        }
      : { ...fundManageApi };
  const list = { ...fundManagerInfo, PreviousState: list1 };
  return list;
};

export const BankMailInfoFunction = (
  bankMailInfoApi: any,
  bankMailInfo: any
) => {
  const list1 =
    bankMailInfoApi !== undefined
      ? {
          ...bankMailInfo.PreviousState,
          bankName: bankMailInfoApi?.bankName,
          bankAddress: bankMailInfoApi?.bankAddess,
          beneficiaryAccountName: bankMailInfoApi?.bankACName,
          beneficiaryAddress: bankMailInfoApi?.beneficiaryAddress,
          beneficiaryAccountNumber: bankMailInfoApi?.bankAccNo,
          achTransferABANumber: bankMailInfoApi?.achabaNo,
          wireTransferABANumber: bankMailInfoApi?.abaNo,
          swiftbicNumber: bankMailInfoApi?.swiftBicCode,
          checkPayableTo: bankMailInfoApi?.payableTo,
          checkAddress: bankMailInfoApi?.mailAddress,
          checkCity: bankMailInfoApi?.mailCity,
          checkState: bankMailInfoApi?.mailState,
          checkCountry: bankMailInfoApi?.mailCountry,
          checkZipCode: bankMailInfoApi?.mailZipCode,
        }
      : { ...bankMailInfoData };
  const list = { ...bankMailInfo, PreviousState: list1 };
  return list;
};

export const EdgarFilingFunction = (
  edgarFilingApi: any,
  edgarFilingInfo: any
) => {
  const list1 =
    edgarFilingApi !== undefined
      ? {
          ...edgarFilingInfo.PreviousState,
          cikNumber: edgarFilingApi?.cikNumber,
          edgarFilingDate:
            edgarFilingApi?.edgarFilingDate !== null &&
            edgarFilingApi?.edgarFilingDate !== ""
              ? convertUTCToLocalDate(
                  edgarFilingApi?.edgarFilingDate,
                  "mm/dd/yyyy"
                )
              : "",
          edgarPassword: edgarFilingApi?.edgarPassword,
          edgarPasswordExpiration:
            edgarFilingApi?.edgarPasswordExpiration !== null &&
            edgarFilingApi?.edgarPasswordExpiration !== ""
              ? convertUTCToLocalDate(
                  edgarFilingApi?.edgarPasswordExpiration,
                  "mm/dd/yyyy"
                )
              : "",
          ccc: edgarFilingApi?.ccc,
          pmac: edgarFilingApi?.pmac,
          passphrase: edgarFilingApi?.passphase,
          SecAccessionNumber: edgarFilingApi?.latestSECAccessionNumber,
        }
      : { ...edgarFilingInfoData };
  const list = { ...edgarFilingInfo, PreviousState: list1 };
  return list;
};
