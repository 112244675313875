import { Card } from "primereact/card";

import { Skeleton } from "primereact/skeleton";
import React, { useEffect } from "react";

import "primeflex/primeflex.scss";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line import/order
// import { useMsal } from "@azure/msal-react";
// import { msal } from "../../components/msal/msal";
// import "./statisticsDashboard.scss";

export interface itemBody {
  id: string;
  name: string;
  category: string;
  price: number;
}

export default function DashboardSkeleton() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    // const tenentName = localStorage.getItem("tenentName");

    if (!token) {
      navigate("/logout");
    }
  }, []);
  return (
    <>
      {" "}
      <div className="grid mt-5">
        <div className="col-12 md:col-6 mt-4 ">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">
              {" "}
              <Skeleton className="col-12 md:col-6" height="5rem"></Skeleton>
            </h1>
            <p className="pageSubHeader">
              <Skeleton className="col-12 md:col-6"></Skeleton>
            </p>
          </div>
        </div>
        <div className="col-12 md:col-6">
          <Skeleton className="col-12 md:col-6 mt-5" height="5rem"></Skeleton>
        </div>
      </div>
      <div className="grid">
        <div className="col-12 md:col-8">
          <div className="card my-3 ">
            <Card>
              <h4 className="cardHeaderWithoutIcon">
                <Skeleton />{" "}
              </h4>
              <p>
                {" "}
                <Skeleton className="col-12 md:col-6" height="5rem"></Skeleton>
              </p>
              <div className="flex flex-column md:flex-row">
                <div className="acc-value-details w:100 md:w-4">
                  <Skeleton
                    className="col-12 md:col-6"
                    height="5rem"
                  ></Skeleton>
                </div>
                <div className="acc-value-details w:100 md:w-4">
                  <Skeleton
                    className="col-12 md:col-6"
                    height="5rem"
                  ></Skeleton>
                </div>
                <div className="acc-value-details w:100 md:w-4">
                  <Skeleton
                    className="col-12 md:col-6"
                    height="5rem"
                  ></Skeleton>
                </div>
              </div>
            </Card>
          </div>

          <div className="activeInvest-wrap card my-3">
            <Card>
              <h4 className="cardHeaderWithoutIcon">
                <Skeleton />{" "}
              </h4>
              <p>
                {" "}
                <Skeleton className="col-12 md:col-6" height="5rem"></Skeleton>
              </p>
              <div>
                <Skeleton className="col-12 md:col-6" height="5rem"></Skeleton>

                {/* <DataTableExample table={assets} /> */}
              </div>
            </Card>
          </div>
          <div className="liquidity-wrap card my-3">
            <Card>
              <h4 className="cardHeaderWithoutIcon">
                {" "}
                <Skeleton />{" "}
              </h4>

              <div>
                <Skeleton className="col-12 md:col-6" height="5rem"></Skeleton>
              </div>
            </Card>
          </div>
          <div className="activeInvest-wrap card">
            <Card>
              <h4 className="cardHeaderWithoutIcon">
                {" "}
                <Skeleton />{" "}
              </h4>
              <Skeleton className="col-12 md:col-6" height="5rem"></Skeleton>
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-4">
          <div className="accvalue-wrap card my-3">
            <Card>
              <h4 className="cardHeaderWithoutIcon">
                {" "}
                <Skeleton />{" "}
              </h4>
              <Skeleton className="col-12 md:col-6" height="5rem"></Skeleton>
            </Card>
          </div>
          <div className="card my-3">
            <Card>
              <h4 className="cardHeaderWithoutIcon">
                {" "}
                <Skeleton />{" "}
              </h4>
              <Skeleton className="col-12 md:col-6" height="5rem"></Skeleton>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
