import { Accordion, AccordionTab } from "primereact/accordion";
import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
import {
  initialPayloadAddState,
  setAllocationSliceInvestorList,
} from "../../../../../store/features/operation/allocation-slices/add-investors-allocation-state/slice";
import { initialPayloadGetState } from "../../../../../store/features/operation/allocation-slices/get-all-investor-allocation";
import { setAllInvestorAllocateData } from "../../../../../store/features/operation/allocation-slices/get-all-investor-allocation/slice";
import { initialPayloadGetAllocatedListData } from "../../../../../store/features/operation/allocation-slices/get-allocated-investor";
import { setAllocatedInvestorState } from "../../../../../store/features/operation/allocation-slices/get-allocated-investor/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import AllocatedInvestor from "../common-allocation-component/AllocatedInvetsor";
import AllocationStep1 from "./AllocationStep1";
import AllocationStep2 from "./AllocationStep2";
import AllocationStep3 from "./AllocationStep3";

export default function AllocationSlices() {
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const allocationSliceInvestorList = useAppSelector(
    (state) => state.allocationSliceInvestorState.data
  );
  const totalSteps = 2; // Update with the total number of steps
  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(0);
    }
  };

  const handlePrevious = () => {
    if (currentStep >= 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  let content;

  switch (currentStep) {
    case 0:
      content = (
        <AllocationStep1
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      );
      break;
    case 1:
      content = (
        <AllocationStep2
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      );
      break;
    case 2:
      content = (
        <AllocationStep3
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      );
      break;
    default:
      content = <></>;
      break;
  }

  // to handle the open Allocated Investors grid
  useEffect(() => {
    setCurrentStep(0);
    dispatch(setAllocationSliceInvestorList(initialPayloadAddState));
    dispatch(setAllInvestorAllocateData(initialPayloadGetState));
    dispatch(setAllocatedInvestorState(initialPayloadGetAllocatedListData));
  }, [fundDropdown]);
  return (
    <>
      <div>
        <div className=" h-100 center-card">
          <div className="col-12 ">
            <div className="card my-3">
              <Card>
                {" "}
                <div>{content}</div>
                <div id="allocatedInvestor" className="p-0 col-12">
                  <Accordion
                    className="p-1"
                    activeIndex={
                      allocationSliceInvestorList.allocatedInvestorTab
                    }
                    onTabChange={(e: any) => {
                      dispatch(
                        setAllocationSliceInvestorList({
                          ...allocationSliceInvestorList,
                          allocatedInvestorTab: e.index,
                        })
                      );
                    }}
                  >
                    <AccordionTab header="Current Allocations" tabIndex={0}>
                      <AllocatedInvestor />
                    </AccordionTab>
                  </Accordion>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
