import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deletePooledfundInvestmentbyProductIdService } from "../../../../services/investment/investment";
import error from "../../../../utils/error";
import { IDeleteDealState } from "./type";

const initialState: IDeleteDealState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: undefined,
};
export const deletePooledfundInvestmentbyProductIdThunk = createAsyncThunk(
  "deletePooledfundInvestmentbyProductId",
  async (data: any) => {
    try {
      const response = await deletePooledfundInvestmentbyProductIdService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
    }
  }
);

const slice = createSlice({
  name: "deletePooledfundInvestmentbyProductId",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(
      deletePooledfundInvestmentbyProductIdThunk.pending,
      (state) => {
        state.loading = "loading";
      }
    );
    builder.addCase(
      deletePooledfundInvestmentbyProductIdThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.message = action.payload.message;
        state.success = action.payload.success;
      }
    );
    builder.addCase(
      deletePooledfundInvestmentbyProductIdThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        state.error = error(action.error);
      }
    );
  },
});

export default slice.reducer;
