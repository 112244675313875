import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
// import { Column } from "primereact/column";
// import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";
// import { RiCheckFill } from "react-icons/ri";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";
import Loader from "../../../components/loader/Loader";
import { GetCountriesThunk } from "../../../store/features/investor/managecountrymaster/getcountries/slice";
import { GetCustodianListThunk } from "../../../store/features/master/getcustodianlist/slice";
import { GetStateListThunk } from "../../../store/features/master/getstatelist/slice";
import { SaveCustodianDetailsThunk } from "../../../store/features/master/savecustodiandetails/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function ManageFundSDIRACustodians() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  //   const Viewapproveregistor = useAppSelector(
  //     (state) => state.GetInvestorList.data
  //   );

  const dropdownModel = useAppSelector((state) => state.legalEntityList.data);

  const GetMenuList = useAppSelector((state) => state.GetCustodianList?.data);
  const GetMenuListApiStatus = useAppSelector(
    (state) => state.GetCustodianList?.loading
  );
  const GetCountriesList = useAppSelector((state) => state.GetCountries?.data);

  const GetStates = useAppSelector((state) => state.GetStateList?.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const [id, setId] = useState<any>();
  const [investingAccountId, setInvestingAccountId] = useState<any>();
  const [delInvestor, setDelInvestor] = useState<any>();

  const [edit, setEdit] = useState<any>(false);
  const [visible, setVisible] = useState<any>(false);
  console.log("GetMenuList", GetMenuList);
  // const options = [
  //   { label: "Option 1", value: 1 },
  //   { label: "Option 2", value: 2 },
  //   { label: "Option 3", value: 3 },
  // ];
  const legalEntityList: { code: number; name: string }[] = [];
  for (let i = 0; i < dropdownModel?.length; i++) {
    legalEntityList.push({
      code: dropdownModel![i].legalEntityID,
      name: dropdownModel![i].legalEntityName,
    });
  }
  // const handleChange = (e: any) => {
  //   return setLeagalentity(e.value);
  //   // Do something with the selected value
  // };
  console.log("edit", edit);

  const formik = useFormik({
    initialValues: {
      State: "",
      Name: "",
      City: "",
      ZipCode: "",
      Phone: "",
      EIN: "",
      Address: "",
      Website: "",
      custodianAccountNumber: "",
      custodianRoutingNumber: "",
      Countries: "",
    },
    validate: (data: any) => {
      const errors: any = {};
      if (!data.State) {
        errors.State = "Please select state";
      }
      if (!data.Name) {
        errors.Name = "Please enter name";
      }
      if (!data.City) {
        errors.City = "Please enter city";
      }
      if (!data.ZipCode) {
        errors.ZipCode = "Please enter zip code";
      }
      if (data.ZipCode) {
        if (data.ZipCode?.length !== 5 && data.ZipCode?.length !== 10) {
          errors.ZipCode = "Zip code should be 5 or 9 digits";
          // isValid = false;
        } else {
          // empty
        }
      }
      if (!data.Phone) {
        errors.Phone = "Please enter phone number";
      }
      if (!data.EIN) {
        errors.EIN = "Please enter EIN";
      } else if (data.EIN.length !== 9) {
        errors.EIN = "Please enter 9 digit ein number";
      }
      if (!data.Address) {
        errors.Address = "Please enter address";
      }
      if (!data.Website) {
        errors.Website = "Please enter website";
      }

      return errors;
    },
    onSubmit: async (data: any) => {
      //
      // const req = {
      //   action: edit ? "update" : "Create",
      //   id: edit ? id : undefined,
      //   name: formik.values.Name,
      //   address: formik.values.Address,
      //   city: formik.values.City,
      //   state: formik.values.State,
      //   zipCode: formik.values.ZipCode,
      //   accEIN: formik.values.EIN,
      //   mobilePhone: formik.values.Phone,
      //   website: formik.values.Website, // remove website
      //   legalEntityId: fundDropdown.fundid,
      // };

      const req = {
        action: edit ? "update" : "Create",
        custodianId: edit ? id : undefined,
        investingAccountId: edit ? investingAccountId : 0, // getting from get api
        investorId: 0, // send 0 for now dummy parameter
        sdiraAccountTitle: "",
        custodianName: formik.values.Name,
        custodianEIN: formik.values.EIN,
        custodianAccountNumber: formik.values.custodianAccountNumber, // add new field
        custodianRoutingNumber: formik.values.custodianRoutingNumber, // add new field
        custodianEmail: "string",
        custodianPhoneNumber: formik.values.Phone,
        addressId: 0, // getting from get api
        address: formik.values.Address,
        address2: "",
        city: formik.values.City,
        countryId: 0,
        state: formik.values.State,
        zipCode: formik.values.ZipCode,
      };

      dispatch(SaveCustodianDetailsThunk(req)).then(() => {
        const r = {
          LegalEntityId: fundDropdown.fundid,
        };
        dispatch(GetCustodianListThunk(r));
        formik.resetForm();
        edit && setEdit(false);
      });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    // Remove hyphens and non-digit characters
    const digitsOnly = value.replace(/\D/g, "");

    // Add hyphen after every 5 digits if length is greater than 5
    let formattedValue = digitsOnly;
    if (formattedValue.length > 5) {
      formattedValue = formattedValue.replace(/(\d{5})/g, "$1-");
    }
    formik.setFieldValue(name, formattedValue);
  };

  const formatPhoneNumber = (value: string) => {
    // Remove non-digit characters
    const phoneNumberOnly = value ? value!.replace(/\D/g, "") : value;

    let maskedPhoneNumber = "";

    if (phoneNumberOnly) {
      if (phoneNumberOnly.length <= 3) {
        maskedPhoneNumber = phoneNumberOnly;
      } else if (phoneNumberOnly.length <= 6) {
        maskedPhoneNumber =
          phoneNumberOnly.slice(0, 3) + "-" + phoneNumberOnly.slice(3);
      } else {
        maskedPhoneNumber =
          phoneNumberOnly.slice(0, 3) +
          "-" +
          phoneNumberOnly.slice(3, 6) +
          "-" +
          phoneNumberOnly.slice(6, 10);
      }
    }

    return maskedPhoneNumber;
  };
  const editButton = (rowData: any) => {
    // Pass rowData as a parameter

    const handleEdit = (i: any) => {
      console.log("id", rowData);
      setEdit(true);
      setId(rowData.id);
      setInvestingAccountId(rowData.investingAccountId);
      formik.setFieldValue("Website", rowData.website);
      formik.setFieldValue("Address", rowData.address);
      formik.setFieldValue("EIN", rowData.custodianEIN);
      formik.setFieldValue("ZipCode", rowData.zipCode);
      formik.setFieldValue("State", rowData.usState);
      formik.setFieldValue("City", rowData.city);
      formik.setFieldValue("Name", rowData.custodianName);
      formik.setFieldValue(
        "Phone",
        formatPhoneNumber(rowData.custodianPhoneNumber)
      );

      //
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={() => handleEdit(rowData.Id)}
          >
            <span className="text-white">Edit</span>
          </Button>
        </div>
      </div>
    );
  };

  const deleteButton = (rowData: any) => {
    // Pass rowData as a parameter

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              // setId(rowData.id);
              console.log("rowData", rowData);
              const reqDel = {
                action: "Delete",
                custodianId: rowData.id,
                investingAccountId: rowData.investingAccountId, // getting from get api
                investorId: 0, // send 0 for now dummy parameter
                sdiraAccountTitle: "",
                custodianName: rowData.custodianName,
                custodianEIN: rowData.custodianEIN,
                custodianAccountNumber: "string", // add new field
                custodianRoutingNumber: "string", // add new field
                custodianEmail: "string",
                custodianPhoneNumber: rowData.custodianPhoneNumber,
                addressId: rowData.addressID, // getting from get api
                address: rowData.address,
                address2: rowData.address2,
                city: rowData.city,
                countryId: 0,
                state: rowData.state,
                zipCode: rowData.zipCode,
              };
              setDelInvestor(reqDel);
              console.log("delete log", rowData);

              setVisible(true);
            }}
          >
            <span className="text-white">Delete</span>
          </Button>
        </div>
      </div>
    );
  };

  const dataTableElements = [
    {
      field: "custodianName",
      header: "Name",
    },
    {
      field: "legalEntityName",
      header: "LegalEntity Name",
    },
    {
      field: "city",
      header: "City",
    },
    {
      field: "usState",
      header: "State",
    },
    {
      field: "zipCode",
      header: "Zip Code",
    },
    {
      field: "custodianEIN",
      header: "Account EIN ",
    },
    {
      field: "custodianPhoneNumber",
      header: "Mobile Phone",
    },
    {
      field: "address",
      header: "Address",
    },
    {
      field: "website",
      header: "Website",
    },
    {
      field: "edit",
      header: "",
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
    },
    {
      field: "delete",
      header: "",
      body: (rowData: any) => deleteButton(rowData), // Pass rowData to deleteButton
    },
  ];

  const onDelete = () => {
    dispatch(SaveCustodianDetailsThunk(delInvestor)).then(() => {
      const par = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetCustodianListThunk(par));
    });
    setVisible(false);
  };

  // const param = {
  //   LegalEntityId: profileData?.legalEntityID,
  // };

  useEffect(() => {
    dispatch(GetStateListThunk());
    // const req = {
    //   LegalEntityId: fundDropdown.fundid,
    // };
    // dispatch(GetCustodianListThunk(req));
    dispatch(GetCountriesThunk());
  }, []);

  useEffect(() => {
    if (fundDropdown?.fundid) {
      const req = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetCustodianListThunk(req));
    }
  }, [fundDropdown.fundid]);

  // useEffect(() => {
  //   setExitDate(getExitDateConversion(GetMenuList));
  // }, [GetMenuList]);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {GetMenuListApiStatus !== "succeeded" && <Loader />}
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Manage Fund SD-IRA Custodians</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <div className="grid">
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Are you sure you want to delete ?
          </h4>
          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={onDelete}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>
        <div className="col-12">
          <div className="card my-3 tabWithoutboxshadow">
            <Card>
              <div className="grid justify-content-start">
                {" "}
                {/* <div className="d-flex justify-content-end  col-6  ">
              <Button
                className=" blueButtonShadow w-7rem"
                onClick={onAddClick}
              >
                Add
              </Button>
            </div> */}
              </div>
              <>
                {" "}
                <div className="mb-4">
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12 ">
                      <label className="inputLabel">Name*</label>
                      <InputText
                        name="Name"
                        value={formik.values.Name}
                        onChange={(e) => {
                          formik.setFieldValue("Name", e.target.value);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Name"),
                          "card-inputBox": true,
                        })}
                        placeholder="Name*"
                      />
                      {getFormErrorMessage("Name")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12">
                      <label className="inputLabel">City *</label>
                      <InputText
                        name="City"
                        value={formik.values.City}
                        onChange={(e) => {
                          formik.setFieldValue("City", e.target.value);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("City"),
                          "card-inputBox": true,
                        })}
                        placeholder="Menu Name"
                      />
                      {getFormErrorMessage("City")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12">
                      <label className="inputLabel">State *</label>
                      <Dropdown
                        name="State"
                        value={formik.values.State}
                        onChange={(e) => {
                          formik.setFieldValue("State", e.target.value);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("State"),
                          "card-inputBox": true,
                        })}
                        options={GetStates}
                        optionLabel="value"
                        optionValue="key"
                        placeholder="Select.."
                        // filter
                      />

                      {getFormErrorMessage("State")}
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12">
                      <label className="inputLabel">Zip Code *</label>
                      <InputText
                        name="ZipCode"
                        value={formik.values.ZipCode}
                        // onChange={(e) => {
                        //   formik.setFieldValue("ZipCode", e.target.value);
                        // }}
                        onChange={handleInputChange}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("ZipCode"),
                          "card-inputBox": true,
                        })}
                        placeholder="Menu Name"
                      />
                      {getFormErrorMessage("ZipCode")}
                    </div>

                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12">
                      <label htmlFor="phone" className="inputLabel">
                        Phone Number
                      </label>

                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon h-3rem">+1 </span>
                        <input
                          name="Phone"
                          value={
                            formik.values.Phone
                              ? formatPhoneNumber(
                                  formik.values.Phone.split("-").join("")
                                )
                              : formik.values.Phone
                          }
                          // onChange={handleChange}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "Phone",
                              formatPhoneNumber(e.target.value)
                                .split("-")
                                .join("")
                            );
                            console.log("onchange", e.target.value);
                          }}
                          placeholder="xxx-xxx-xxxx"
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("phone"),
                            "w-full h-3rem px-2": true,
                          })}
                        />
                      </div>
                      {getFormErrorMessage("phone")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12">
                      <label className="inputLabel">Address*</label>
                      <InputText
                        name="Address"
                        value={formik.values.Address}
                        onChange={(e) => {
                          formik.setFieldValue("Address", e.target.value);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Address"),
                          "card-inputBox": true,
                        })}
                        placeholder="Menu Name"
                      />
                      {getFormErrorMessage("Address")}
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12">
                      <label className="inputLabel">EIN # *</label>
                      <InputText
                        name="EIN"
                        value={formik.values.EIN}
                        onChange={(e) => {
                          formik.setFieldValue("EIN", e.target.value);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("EIN"),
                          "card-inputBox": true,
                        })}
                        placeholder="Menu Name"
                      />
                      {getFormErrorMessage("EIN")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12">
                      <label className="inputLabel">
                        Custodian Account Number
                      </label>
                      <InputText
                        name="custodianAccountNumber"
                        value={formik.values.custodianAccountNumber}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "custodianAccountNumber",
                            e.target.value
                          );
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid(
                            "custodianAccountNumber"
                          ),
                          "card-inputBox": true,
                        })}
                        // placeholder="Menu Name"
                      />
                      {getFormErrorMessage("custodianAccountNumber")}
                    </div>{" "}
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12">
                      <label className="inputLabel">
                        Custodian Routing Number
                      </label>
                      <InputText
                        name="custodianRoutingNumber"
                        value={formik.values.custodianRoutingNumber}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "custodianRoutingNumber",
                            e.target.value
                          );
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid(
                            "custodianRoutingNumber"
                          ),
                          "card-inputBox": true,
                        })}
                        // placeholder="Menu Name"
                      />
                      {getFormErrorMessage("custodianRoutingNumber")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12">
                      <label className="inputLabel">Website*</label>
                      <InputText
                        name="Website"
                        value={formik.values.Website}
                        onChange={(e) => {
                          formik.setFieldValue("Website", e.target.value);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Website"),
                          "card-inputBox": true,
                        })}
                        placeholder="Menu URL"
                      />
                      {getFormErrorMessage("Website")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12">
                      <label className="inputLabel">Country</label>
                      <Dropdown
                        name="Countries"
                        value={formik.values.Countries}
                        onChange={(e) => {
                          formik.setFieldValue("Countries", e.target.value);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Countries"),
                          "card-inputBox": true,
                        })}
                        options={GetCountriesList}
                        optionLabel="countryName"
                        optionValue="id"
                        placeholder="Select.."
                        // filter
                      />

                      {getFormErrorMessage("Countries")}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mb-3 mt-4">
                  <Button
                    className="mr-4 btn-nav w-7rem justify-content-center"
                    onClick={() => {
                      formik.resetForm();
                      setEdit(false);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="btn-nav w-7rem justify-content-center"
                    onClick={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </>

              <div className="mt-2">
                <DataTableComponent
                  valueData={
                    GetMenuList !== undefined && GetMenuList?.length > 0
                      ? GetMenuList
                      : []
                  }
                  fieldsElements={dataTableElements}
                  isPaginationAllowed={true}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
