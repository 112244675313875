import { Button } from "primereact/button";
import { Card } from "primereact/card";
// import { Carousel } from "primereact/carousel";
import { Galleria } from "primereact/galleria";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import React, { useState, useEffect, useRef } from "react";
import "primeflex/primeflex.scss";
import "./OpportunityDetails.scss";
import { RiQuestionFill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import ImageNotFound from "../../images/ImageNotFoundNew.png";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getOpportunityImageData,
  getOpportunityImageDataStatus,
  getOpportunityImagesDataThunk,
} from "../../store/opportunity/image-data/slice";
import {
  getOpportunitiyInverstmentDetailData,
  // getOpportunityDetailsStatus,
} from "../../store/opportunity/opportunity-investment-details/slice";

import OpportunityInvestment from "./OpportunityInvestment";
import OpportunityProjectDetails from "./OpportunityProjectDetails";
import OpportunityProjectUpdates from "./OpportunityProjectUpdates";
import OpportunityRelevantDocument from "./OpportunityRelevantDocument";
import OpportunitySoftCommitPop from "./OpportunitySoftCommitPop";
// import OpportunitySoftCommitPop1 from "./OpportunitySoftCommitPop1";

export interface itemBody {
  id: string;
  name: string;
  category: string;
  price: number;
}
const OpportunityDetails = () => {
  const dispatch = useAppDispatch();
  const navigage = useNavigate();
  const { id } = useParams();

  // const [searchParams] = useSearchParams();
  const [visible, setVisible] = useState<boolean>(false);
  // const from = searchParams.get("from");
  const newUrl = window.location.href.replace("?from=Investment", "");
  const newwUrl = window.location.href.replace("?from=Account Statement", "");
  const newwwUrl = window.location.href.replace("?from=Transaction", "");

  window.history.replaceState({}, document.title, newUrl);
  window.history.replaceState({}, document.title, newwUrl);
  window.history.replaceState({}, document.title, newwwUrl);

  const onCloseModal = () => {
    setVisible(false);
  };

  // useEffect(() => {
  //   // const newUrl = window.location.href.replace("?from=Investment", "");
  //   // window.history.replaceState({}, document.title, newUrl);
  // }, []);

  const imageDetailStatus = useAppSelector(getOpportunityImageDataStatus);
  const imageDetailsData: any = useAppSelector(getOpportunityImageData);

  // console.log("Previous page:", imageDetailsData[0].productLink);

  // const investmentDetailStatus = useAppSelector(getOpportunityDetailsStatus);
  const investmentDetails = useAppSelector(
    getOpportunitiyInverstmentDetailData
  );

  const countryName =
    investmentDetails?.country !== "United States"
      ? investmentDetails?.country
      : "";
  const propsAttribute: any = {
    showNavigators: false,
  };
  if (imageDetailsData.length <= 1) {
    propsAttribute.showIndicators = false;
  } else {
    propsAttribute.numVisible = 1;
    propsAttribute.numScroll = 1;
    propsAttribute.circular = true;
    propsAttribute.autoplayInterval = 15_000;
  }

  useEffect(() => {
    dispatch(getOpportunityImagesDataThunk(+id!));
  }, []);

  const [activeIndex, setActiveIndex] = useState<number>();
  const videoRef = useRef<HTMLVideoElement>(null);
  // const handlePageChange = (event: any) => {
  //   const videoElements = document.querySelectorAll('[id^="video-"]');
  //   console.log("Video Elements ->>>>>>>>>>>>", videoElements);

  //   // Pause all the video elements
  //   for (const videoElement of videoElements) {
  //     console.log("Each Video Element", videoElement);
  //     if (videoElement instanceof HTMLVideoElement) {
  //       videoElement.pause();
  //     }
  //   }
  //   setActiveIndex(event.page);
  // };

  // const [activeIndex, setActiveIndex] = useState<number>(1);
  // const videoRef = useRef<HTMLVideoElement>(null);
  // const handlePageChange = (event: any) => {
  //   // console.log(
  //   //   "SlideChange Here",
  //   //   event,
  //   //   activeIndex,
  //   //   imageDetailsData,
  //   //   imageDetailsData.length,
  //   //   imageDetailsData[activeIndex]?.productLink
  //   // );

  //   const videoElements = document.querySelectorAll('[id^="video-"]');
  //   console.log("Video Elements ->>>>>>>>>>>>", videoElements);

  //   // Pause all the video elements
  //   for (const videoElement of videoElements) {
  //     console.log("Each Video Element", videoElement);
  //     if (videoElement instanceof HTMLVideoElement) {
  //       videoElement.pause();
  //     }
  //   }
  //   setActiveIndex(event.page);
  // };

  // Logic Implement for Image and Videos

  const productTemplate = (product: any) => {
    const img = product?.productLink ? product?.productLink : "";
    const fileExtension = img.includes("mp4")
      ? "mp4"
      : img.split(".").pop()?.toLowerCase();
    // console.log(img);
    console.log("Page Product", product);

    return (
      <div className="product-item">
        <div className="product-item-content">
          <div className="mb-3 carousel-img-wrap">
            {fileExtension === "mp4" ? (
              <video
                src={img}
                controls
                width="100%"
                height="100%"
                ref={videoRef}
                id={`video-${activeIndex}`}
              ></video>
            ) : (
              <img src={img} alt="image" className="product-images" />
            )}
          </div>
        </div>
      </div>
    );
  };

  // const productTemplate = (product: any) => {
  //   const img = product?.productLink ? product?.productLink : "";
  //   return (
  //     <div className="product-item">
  //       <div className="product-item-content">
  //         <div
  //           className="mb-3 carousel-img-wrap"
  //           // style={{
  //           //   backgroundImage: "url('" + img + "')",
  //           // }}
  //         >
  //           <img src={img} alt="image" className="product-images" />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const resetCarousel = () => {
  //   setResetKey((prevKey) => prevKey + 1);
  //   setActiveIndex(0);
  // };

  useEffect(() => {
    if (imageDetailsData?.length > 0) {
      // resetCarousel();
      setActiveIndex(0);
    }
  }, [imageDetailsData]);

  return (
    <>
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            {/* <h1 className="pageHeader">Property Demo Name 1</h1> */}
            <h1 className="pageHeader">{investmentDetails?.productName}</h1>
            <Button
              className="BtnBackpg"
              onClick={(e) => {
                e.preventDefault();
                navigage(-1); // Go back one page

                // navigage(-1);
              }}
            >
              <span className="bi bi-chevron-compact-left"></span> Back to
              {` ${localStorage.getItem("navigationPage")}`}
            </Button>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>

      <div className="grid">
        <div className="col-12 md:col-8">
          <div className="card my-3 imgOverviewWarp">
            <Card>
              {/* <button onClick={resetCarousel}>Force Re-render</button> */}
              <div className="d-flex justify-content-between">
                <h4 className="cardHeader">Images & Overview</h4>
                {investmentDetails?.pooledfunds && (
                  <div className="bagde-btn bagde-btn-bgf ml-2 text-dark fs-5 p-3">
                    {investmentDetails?.pooledfunds}
                  </div>
                )}
              </div>
              {imageDetailStatus === "succeeded" ? (
                <>
                  {/* <Carousel
                    value={
                      imageDetailsData.length > 0 &&
                      imageDetailsData[0].productLink !== null
                        ? imageDetailsData
                        : [{ productLink: ImageNotFound }]
                    }
                    {...propsAttribute}
                    itemTemplate={productTemplate}
                    onPageChange={handlePageChange}
                    page={activeIndex}
                  /> */}
                  {/* <Carousel
                    value={
                      imageDetailsData?.length > 0
                        ? imageDetailsData
                        : [{ productLink: ImageNotFound }]
                    }
                    // numVisible={1}
                    numScroll={1}
                    // key={resetKey}
                    // {...propsAttribute}
                    // responsiveOptions={responsiveOptions}
                    className="custom-carousel"
                    circular
                    autoplayInterval={3000}
                    itemTemplate={productTemplate}
                    page={1}
                    responsiveOptions={responsiveOptions}
                  /> */}
                  <Galleria
                    value={
                      imageDetailsData?.length > 0
                        ? imageDetailsData
                        : [{ productLink: ImageNotFound }]
                    }
                    numVisible={1}
                    circular
                    showItemNavigators
                    showItemNavigatorsOnHover
                    showIndicators
                    showThumbnails={false}
                    item={productTemplate}
                    autoPlay={true}
                  />
                  <div className="d-flex justify-content-between mt-2">
                    <div>
                      <p className="imgOverviewTxt">
                        {investmentDetails?.productName}
                      </p>
                      <p className="imgOverviewAddr">
                        {investmentDetails?.cityStateCode}
                        {countryName && `, ${countryName}`}
                      </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Tooltip
                        target=".custom-target-icon"
                        style={{
                          backgroundColor: "grey",
                          width: "300px",
                        }}
                      />
                      <Button
                        className="BtnBlue pt-0 pb-0"
                        onClick={(e) => {
                          e.preventDefault();
                          setVisible(true);
                        }}
                        disabled={true}
                        tooltip="This option is available for Investors to request soft commit. Managers can ask add soft commit via Manage Soft Commit capability."
                        label={
                          investmentDetails?.productState === "PreFunded"
                            ? "Request Investment"
                            : "Soft Commit"
                        }
                      />
                      <span
                        className="custom-target-icon"
                        data-pr-tooltip={
                          investmentDetails?.productState === "PreFunded"
                            ? "This option is available for Investors to request investment."
                            : "This option is available for Investors to request soft commit. Managers can add soft commits via Manage soft commits capability."
                        }
                        data-pr-position="right"
                      >
                        <RiQuestionFill className="softcommitTooltips" />
                      </span>
                    </div>
                    <OpportunitySoftCommitPop
                      productState={
                        investmentDetails?.productState
                          ? investmentDetails?.productState
                          : ""
                      }
                      ID={id!}
                      openModal={visible}
                      onModalClose={onCloseModal}
                    />
                  </div>
                </>
              ) : (
                <Skeleton width="100%" height="350px"></Skeleton>
              )}
            </Card>
          </div>

          <div className="projectDetails-wrap card my-3">
            <Card>
              <div className="d-flex mb-2">
                <h4 className="cardHeader mr-auto">Project Details</h4>
                {/* <Button className="BtnBlue">VIEW MORE</Button> */}
              </div>
              <div>
                <OpportunityProjectDetails id={id!} />
              </div>
            </Card>
          </div>
          <div className="activeInvest-wrap card">
            <Card>
              <h4 className="cardHeader">Project Updates</h4>
              {/* <div>map</div> */}
              <OpportunityProjectUpdates id={id!} />
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-4">
          <div className="accvalue-wrap card my-3">
            <OpportunityInvestment id={id!} />
          </div>
          <div className="card my-3">
            <Card>
              <h4 className="cardHeader">Relevant Documents</h4>
              <OpportunityRelevantDocument id={id!} />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default OpportunityDetails;
