import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccountStatementService } from "../../../services/portfolio/portfolio";
import error from "../../../utils/error";
import { IAccountStatementparameters, ILegalEntityparametersState } from ".";

const initialState: ILegalEntityparametersState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  stakeDetailsList: [],
  partnerAccountsSummary: [],
  investorPartnerData: undefined,
  error: undefined,
};
export const getAccountStatementThunk = createAsyncThunk(
  "plaid/getAccountStatement",
  async (data: IAccountStatementparameters) => {
    try {
      const response = await getAccountStatementService(data);
      console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "plaid/getAccountStatement",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getAccountStatementThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getAccountStatementThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      //
      state.data = action.payload._response.data;
      state.stakeDetailsList = action.payload._response.data.stakeDetailList
        ? action.payload._response.data.stakeDetailList
        : [];
      state.partnerAccountsSummary = action.payload._response.data
        .stakeDetailList
        ? action.payload._response.data.partnerAccountsSummary
        : [];
      state.investorPartnerData = action.payload._response.data
        .investorPartnerData
        ? action.payload._response.data.investorPartnerData[0]
        : undefined;
      // state.currentPage += 1;
    });
    builder.addCase(getAccountStatementThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
