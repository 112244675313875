import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import "./App.scss";
// import { useNavigate } from "react-router-dom";
import packageJson from "../package.json";
import AuthPage from "./AuthPage";
import withRouter from "./WithRouter";
import withClearCache from "./clear-cache";
// eslint-disable-next-line import/order
import moment from "moment";

// import { MSAL } from "./components/msal/MsalPage";
// import { getThunkMap } from "./store/Googlemap/slice";
// import { getThunkPortfolioAssetDropdown } from "./store/assetdropdown/slice";
// import { GetDwollaTokenThunk } from "./store/dwolla/token/slice";
// import { getThunkColor, setColor } from "./store/features/color/slice";
// import {
//   getFundLogoThunk,
//   getFundBannerThunk,
//   getUserProfileDetailsThunk,
// } from "./store/features/profile/slice";
// import {
//   setBannerState,
//   setImageState,
//   setUrlState,
// } from "./store/getasset/slice";
// import { useAppDispatch, useAppSelector } from "./store/hooks";
// // import { isAccountExistThunk } from "./store/multi-fund-registration/IsFundAccountExist/slice";
// import { isAccountExistThunk } from "./store/multi-fund-registration/IsFundAccountExist/slice";
// import { LightenDarkenColor } from "./utils/helper";
// import { removeConsoleLogs } from "./utils/remove-console";
// import { TOKEN } from "./utils/sponsor/constants";

import LoginPage from "./components/routes/LoginPage";
// import { removeConsoleLogs } from "./utils/remove-console";
// import { checkTokenExp } from "./utils/sponsor/permission.helper";

// import { getThunkMap } from "./store/Googlemap/slice";
// import { getThunkColor, setColor } from "./store/color/slice";
// import { LightenDarkenColor } from "./utils/helper";

const mainApp = () => {
  // useEffect(() => {
  //   removeConsoleLogs(true);
  // }, []);
  return (
    <>
      <AuthenticatedTemplate>
        <AuthPage />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </>
  );
};

const ClearCacheComponent = withClearCache(mainApp);
const buildDateGreaterThan = (
  latestDate: moment.MomentInput,
  currentDate: moment.MomentInput
) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  return !!momLatestDateTime.isAfter(momCurrentDateTime);
};

function App() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload();
    window.location = self.location;
  };
  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;

        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        console.log("Method called new currentVersionDate", currentVersionDate);
        console.log("Method called with latestVersionDate", latestVersionDate);
        if (shouldForceRefresh) {
          setIsLatestBuildDate(false);
          refreshCacheAndReload();
        } else {
          setIsLatestBuildDate(true);
        }
      });
  });

  return <ClearCacheComponent />;
}
export default withRouter(App);
