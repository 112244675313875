import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IGetSetupFundListState } from "..";
import {
  DeleteFundOwnersListService,
  GetFundOwnersListService,
  SaveDwollaOwnerInfoInfoService,
  UpdateCertiOwnersIdThunkService,
} from "../../../services/Dwolla/dwolla";
import error from "../../../utils/error";

const initialState: IGetSetupFundListState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const GetOwnersListThunk = createAsyncThunk(
  "Dwolla/GetFundOwnersList",
  async (data: any) => {
    try {
      const response = await GetFundOwnersListService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const SaveDwollaOwnerInfoThunk = createAsyncThunk(
  "dwollaCompo/SaveFundOwnersList",
  async (data: any) => {
    try {
      const response = await SaveDwollaOwnerInfoInfoService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const UpdateCertiOwnersIdThunk = createAsyncThunk(
  "dwollaCompo/UpdateCertiOwnersId",
  async (data: any) => {
    try {
      const response = await UpdateCertiOwnersIdThunkService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const DeleteOwnersIdThunk = createAsyncThunk(
  "Dwolla/DeleteFundOwnersList",
  async (data: any) => {
    try {
      const response = await DeleteFundOwnersListService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Dwolla/FundOwners",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetOwnersListThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetOwnersListThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data[0] : [];
    });
    builder.addCase(GetOwnersListThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
    builder.addCase(DeleteOwnersIdThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(DeleteOwnersIdThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data[0] : [];
    });
    builder.addCase(DeleteOwnersIdThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });

    builder.addCase(SaveDwollaOwnerInfoThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(SaveDwollaOwnerInfoThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data[0] : [];
    });
    builder.addCase(SaveDwollaOwnerInfoThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });

    builder.addCase(UpdateCertiOwnersIdThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(UpdateCertiOwnersIdThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data[0] : [];
    });
    builder.addCase(UpdateCertiOwnersIdThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
