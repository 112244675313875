import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect } from "react";
import { getThunkDropdown } from "../../../../../store/features/dropdown/dropdown-list/slice";
import { setForm } from "../../../../../store/features/investment/create/slice";
// import { setFormData } from "../../../../../store/features/investment/new-investment";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";

type CreateInvestmentStep5Props = {
  handlePrevious: () => void;
  handleNext: any;
  toast: any;
};
const CreateInvestmentStep5: React.FC<CreateInvestmentStep5Props> = ({
  handlePrevious,
  handleNext,
  toast,
}) => {
  const stateInvestment = useAppSelector((state) => state.addInvestment.data);
  const dispatch = useAppDispatch();
  const stateDrop = useAppSelector((state) => state.dropdownList.data);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  // const pageUpdateName = useAppSelector((state) => state.updatePage.data);
  const countryDropdown = useAppSelector(
    (state) => state.investingAccountsLookups.countriesDropdown
  );
  const countryValue: any = countryDropdown
    ? countryDropdown.find((item: any) => item.value === "United States")
    : 0;

  const formik = useFormik({
    initialValues: {
      productName: "",
      supplierProductCode: "",
      isPhysicalAddress: "yes",
      productListingAddress: "",
      address2: "",
      city: "",
      stateCode: "",
      zipCode: "",
      countryId: countryValue.key,
    },
    validate: (data) => {
      const errors: any = {};

      if (!data.productName) {
        errors.productName = "Name is required";
      }
      if (!data.supplierProductCode) {
        errors.supplierProductCode = "Lookup code is required";
      }
      if (
        data.supplierProductCode &&
        !/^[\d A-Za-z-]+$/.test(data.supplierProductCode)
      ) {
        errors.supplierProductCode =
          "Lookup Code can only contain letters, numbers, hyphens (dashes) and spaces";
      }
      if (!data.isPhysicalAddress) {
        errors.isPhysicalAddress = "This field is required";
      }

      if (data.isPhysicalAddress === "yes") {
        if (data.zipCode) {
          if (/^[0^-]*$/.test(data.zipCode)) {
            errors.zipCode = "Invalid zipcode";
          } else if (
            data.zipCode?.length !== 5 &&
            data.zipCode?.length !== 10 &&
            formik.values.countryId === countryValue.key
          ) {
            errors.zipCode = "Zip code should be 5 or 9 digits";
          }
        } else {
          errors.zipCode = "Zipcode is required";
        }
        if (!data.countryId) {
          errors.countryId = "Country is required";
        }
        if (!data.city) {
          errors.city = "City is required";
        } else if (!/^(\D*)$/.test(data.city)) {
          errors.city = "Invalid city";
        }

        if (!data.stateCode) {
          errors.stateCode = "Select a state";
        }
        if (!data.productListingAddress) {
          errors.productListingAddress = "Address is required";
        }
      }

      return errors;
    },
    onSubmit: async () => {
      handleNext(stateInvestment, true);
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const { values, setFieldValue, handleSubmit } = formik;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const handleChangeState = () => {
    dispatch(
      setForm({
        ...stateInvestment,
        createdBy: profileData?.userID,
        productName: values.productName,
        supplierProductCode: values.supplierProductCode,
        isPhysicalAddress: values.isPhysicalAddress,
        productListingAddress: values.productListingAddress,
        address2: values.address2,
        city: values.city,
        stateCode: values.stateCode,
        zipCode: values.zipCode,
        isFinalstep: false,
        countryId: values.countryId,
      })
    );
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "zipCode": {
        if (formik.values.countryId === countryValue.key) {
          const digitsOnly = value.replace(/\D/g, "");
          // Add hyphen after every 5 digits if length is greater than 5
          let formattedValue = digitsOnly;
          console.log("tetseref", formik.values.countryId, countryValue);
          if (formattedValue.length > 5) {
            formattedValue = formattedValue.replace(/(\d{5})/g, "$1-");
          }
          //
          setFieldValue(name, formattedValue);
        } else {
          setFieldValue(name, value);
        }

        break;
      }
      case "isPhysicalAddress": {
        setFieldValue("countryId", value === "yes" ? countryValue.key : 0);
        setFieldValue("productListingAddress", "");
        setFieldValue("address2", "");
        setFieldValue("city", "");
        setFieldValue("stateCode", "");
        setFieldValue("zipCode", "");
        setFieldValue(name, value);
        break;
      }
      case "countryId": {
        setFieldValue("stateCode", "");
        setFieldValue("zipCode", "");
        setFieldValue(name, value);
        break;
      }
      default: {
        setFieldValue(name, value);
      }
    }
  };

  const parameters = {
    legalEntityID: stateInvestment?.legalEntityID,
    supplierID: stateInvestment?.supplierID,
  };
  useEffect(() => {
    dispatch(getThunkDropdown(parameters));
    setFieldValue("productName", stateInvestment?.productName);
    setFieldValue("supplierProductCode", stateInvestment?.supplierProductCode);
    setFieldValue("zipCode", stateInvestment?.zipCode);
    setFieldValue("isPhysicalAddress", stateInvestment?.isPhysicalAddress);
    setFieldValue("stateCode", stateInvestment?.stateCode);
    setFieldValue(
      "productListingAddress",
      stateInvestment?.productListingAddress
    );
    setFieldValue("address2", stateInvestment?.address2);
    setFieldValue("productState", stateInvestment?.productState);
    setFieldValue("city", stateInvestment?.city);
    formik.setFieldValue(
      "countryId",
      stateInvestment?.countryId === 0 || stateInvestment?.countryId === null
        ? countryValue.key
        : stateInvestment?.countryId
    );
  }, []);
  return (
    <>
      <Toast ref={toast} className="themeToast" />
      <div className="formgrid grid px-2">
        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
          <label className="inputLabel">
            {stateInvestment?.productState === "SoftCommit"
              ? "Deal Name"
              : "Investment Name"}
          </label>
          <InputText
            name="productName"
            value={values.productName}
            onChange={(e) => handleChange(e)}
            className={classNames({
              "p-invalid": isFormFieldInvalid("productName"),
              "card-inputBox": true,
            })}
            placeholder={`Enter name of ${
              stateInvestment?.productState === "SoftCommit"
                ? "deal"
                : "investment"
            }`}
          />
          {getFormErrorMessage("productName")}
        </div>
        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
          <label className="inputLabel">Lookup Code</label>
          <InputText
            name="supplierProductCode"
            value={values.supplierProductCode}
            onChange={(e) => handleChange(e)}
            className={classNames({
              "p-invalid": isFormFieldInvalid("supplierProductCode"),
              "card-inputBox": true,
            })}
            placeholder={`Enter lookup code for ${
              stateInvestment?.productState === "SoftCommit"
                ? "deal"
                : "investment"
            }`}
          />

          {getFormErrorMessage("supplierProductCode")}
        </div>
      </div>
      <div className="d-flex flex-column p-2 col-12">
        <label className="inputLabel">
          Does this investment have a physical address?
        </label>
        <div className="d-flex mt-2">
          <div className="flex align-items-center pr-4">
            <RadioButton
              inputId="isPhysicalAddress"
              name="isPhysicalAddress"
              value="yes"
              onChange={(e) => handleChange(e)}
              checked={values.isPhysicalAddress === "yes"}
            />

            <label htmlFor="isPhysicalAddress" className="ml-2 mb-0">
              Yes
            </label>
          </div>
          <div className="flex align-items-center pr-4">
            <RadioButton
              inputId="isPhysicalAddress"
              name="isPhysicalAddress"
              value="no"
              onChange={(e) => handleChange(e)}
              checked={values.isPhysicalAddress === "no"}
            />

            <label htmlFor="isPhysicalAddress" className="ml-2 mb-0">
              No
            </label>
          </div>
        </div>
        {getFormErrorMessage("isPhysicalAddress")}
      </div>
      {values.isPhysicalAddress === "yes" ? (
        <>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Address</label>
              <InputText
                name="productListingAddress"
                value={formik.values.productListingAddress}
                onChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("productListingAddress"),
                  "card-inputBox": true,
                })}
                placeholder="Enter Address line 1"
              />
              {getFormErrorMessage("productListingAddress")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Apartment,Suite,etc.</label>
              <InputText
                name="address2"
                value={formik.values.address2}
                onChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("address2"),
                  "card-inputBox": true,
                })}
                placeholder="Enter Address line 2"
              />

              {getFormErrorMessage("address2")}
            </div>
          </div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">City</label>
              <InputText
                name="city"
                value={formik.values.city}
                onChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("city"),
                  "card-inputBox": true,
                })}
                placeholder="City"
              />
              {getFormErrorMessage("city")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Country</label>
              <Dropdown
                inputId="countryId"
                name="countryId"
                value={formik.values.countryId}
                options={countryDropdown === null ? [] : countryDropdown}
                filter
                resetFilterOnHide={true}
                showFilterClear={true}
                optionLabel="value"
                optionValue="key"
                placeholder="Select Country"
                // className="card-inputBox"
                showOnFocus
                onChange={(e) => {
                  handleChange(e);
                }}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("countryId"),
                  "card-inputBox": true,
                })}
              />

              {getFormErrorMessage("countryId")}
            </div>
          </div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">State</label>
              {formik.values.countryId === countryValue.key ? (
                <>
                  {" "}
                  <Dropdown
                    inputId="stateCode"
                    name="stateCode"
                    value={formik.values.stateCode}
                    options={stateDrop === null ? [] : stateDrop.stateDetails}
                    filter
                    resetFilterOnHide={true}
                    showFilterClear={true}
                    optionLabel="value"
                    optionValue="key"
                    placeholder="Select State"
                    // className="card-inputBox"
                    showOnFocus
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("stateCode"),
                      "card-inputBox": true,
                    })}
                  />
                </>
              ) : (
                <>
                  {" "}
                  <InputText
                    name="stateCode"
                    value={formik.values.stateCode}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("stateCode"),
                      "card-inputBox": true,
                    })}
                    placeholder="State"
                    maxLength={100}
                  />
                </>
              )}

              {getFormErrorMessage("stateCode")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">ZIP Code</label>
              <InputText
                name="zipCode"
                value={formik.values.zipCode}
                onChange={handleChange}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("zipCode"),
                  "card-inputBox": true,
                })}
                maxLength={
                  formik.values.countryId === countryValue.key ? 10 : 15
                }
                placeholder="Enter zip code"
              />
              {formik.errors.zipCode && formik.touched.zipCode && (
                <div className="error">{getFormErrorMessage("zipCode")} </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <> </>
      )}

      <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
        <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
          <label className="inputLabel">&nbsp;</label>

          <Button
            className="btn-nav btnFocus"
            // tabIndex={0}

            onClick={(e) => {
              e.preventDefault();
              handlePrevious();
            }}
          >
            <span className="ml-auto mr-auto ">Back</span>
          </Button>
          <label className="inputLabel">&nbsp;</label>
        </div>
        <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
          <label className="inputLabel">&nbsp;</label>
          <Button
            className="btn-dialog btnFocus"
            type="submit"
            // tabIndex={1}
            onClick={(e) => {
              e.preventDefault();
              handleChangeState();
              handleSubmit();
            }}
          >
            <span className="ml-auto mr-auto ">Next</span>
          </Button>
          <label className="inputLabel">&nbsp;</label>
        </div>
      </div>
    </>
  );
};

export default CreateInvestmentStep5;
