import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getThunkEarningsDataContinuous } from "../../../../store/features/operation/reverse-earning-allocation/get-earnings-data-continuous";
import { getThunkEarningsEntries } from "../../../../store/features/operation/reverse-earning-allocation/get-earnings-entries/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getAfterEarningsStatckbyInvestmentReturnIDThunk } from "../../../../store/poolfunds/get-after-earning-stake-byinvestment";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  // dateFormatter,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter"; //  getListDateConversion,
import ReverseEarningSection from "./ReverseEarningSectionContinuous";

interface IReverseEarningAllocationContinuous {
  openDailog?: boolean;
  setOpenDailog?: any;
  activeIndex?: any;
}

export default function ReverseEarningAllocationContinuous(
  props: IReverseEarningAllocationContinuous
) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowSelectData, setRowSelectData] = useState<any>();
  const [earningsData, setEarningsData] = useState<any>();
  const earningsInfo = useAppSelector(
    (state) => state.getEarningsDataContinuous.data
  );

  const formatCurrencyAnyData = (rowData: any) => {
    const finalValue = Number(rowData).toFixed(2);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };

  console.log("earningsInfo", earningsInfo);
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const computeSum = (
    totalFieldName: string,
    dataForEarning = earningsInfo
  ) => {
    if (dataForEarning !== undefined) {
      const { length } = dataForEarning;

      let count = 0;
      for (let i = 0; i < length; i += 1) {
        count += Number(
          typeof dataForEarning[i][totalFieldName] === "object"
            ? dataForEarning[i][totalFieldName]
            : dataForEarning[i][totalFieldName] || 0
        );
      }

      return formatCurrencyAnyData(count);
    }
  };
  const formatDate = (rowData: any) => {
    // const dateFormat = dateFormatter(rowData);
    // console.log("convertUTCToLocalDate", rowData);
    const dateFormat = formatDateMMDDYYY(rowData);

    return dateFormat;
  };
  const distributeButton = (rowData: any) => {
    const handleDistribute = () => {
      setRowSelectData(rowData);
      setLoading(true);
      const parameter = {
        investmentReturnTypeId: rowData.investmentReturnTypeID,
        investmentReturnId: rowData.investmentReturnID,
        investmentId: rowData.investmentID,
      };
      dispatch(getThunkEarningsEntries(parameter)).then(() => {
        setLoading(false);
        setOpenDialog(true);
      });
      dispatch(
        getAfterEarningsStatckbyInvestmentReturnIDThunk({
          investmentReturnId: rowData.investmentReturnID,
          investmentId: rowData.investmentID,
        })
      );

      console.log("rowData", rowData);
      // if (rowData.investmentReturnTypeID === 3) {
      //   //
      // } else {
      //   setOpenDialog(true);
      // }
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              handleDistribute();
            }}
          >
            <span className="text-white">Distribution</span>
          </Button>
        </div>
      </div>
    );
  };

  // const [shouldFreeze, setShouldFreeze] = useState(window.innerWidth >= 768);
  // useEffect(() => {
  //   const handleResize = () => {
  //     setShouldFreeze(window.innerWidth >= 768);
  //   };

  //   // Attach the event listener
  //   window.addEventListener("resize", handleResize);

  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const dataTableElement = [
    {
      field: "investmentReturnID",
      header: "Return Id",
      style: { minWidth: "220px" },
      // frozen: shouldFreeze && true,
    },
    {
      field: "",
      header: "Distribution",
      body: distributeButton,
      style: { minWidth: "220px" },
      // frozen: shouldFreeze && true,
    },
    {
      field: "investmentID",
      header: "External Investment Id",
      style: { minWidth: "220px" },
      // frozen: shouldFreeze && true,
    },
    {
      field: "productName",
      header: "Investment Name",
      style: { minWidth: "220px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.productName}>
          {rowData?.productName}
        </div>
      ),
    },
    {
      field: "investmentReturnTypeID",
      header: "Investment Return Type Id",
      style: { minWidth: "220px" },
      // frozen: shouldFreeze && true,
    },
    {
      field: "investmentReturnType",
      header: "Investment Return Type",
      style: { minWidth: "220px" },
      // frozen: shouldFreeze && true,
    },
    {
      field: "returnDate",
      header: "Return Date",
      dataType: "date",
      // frozen: shouldFreeze && true,
      body: (rowData: any) =>
        rowData.returnDate === null ? "" : formatDate(rowData.returnDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "220px" },
    },
    {
      field: "equityCashDistiAllocationDate",
      header: "Equity Allocation Date",
      dataType: "date",
      body: (rowData: any) =>
        rowData.equityCashDistiAllocationDate === null
          ? ""
          : formatDate(rowData.equityCashDistiAllocationDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "220px" },
    },
    {
      field: "amount",
      header: "Amount",
      body: (rowData: any) => formatCurrencyAnyData(rowData.amount),
      style: { minWidth: "215px" },
    },

    {
      field: "partnerDistributionDate",
      header: "Distribution Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.partnerDistributionDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "218px" },
    },
  ];
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total:"
          colSpan={8}
          // footerStyle={{ textAlign: "right" }}
        />
        <Column footer={computeSum("amount")} />
        <Column />
      </Row>
    </ColumnGroup>
  );

  useEffect(() => {
    setLoading(true);
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      dispatch(getThunkEarningsDataContinuous(fundDropdown.fundid)).then(() => {
        setLoading(false);
      });
    }
  }, [fundDropdown]);
  useEffect(() => {
    setEarningsData(
      getListDateConversion(earningsInfo, [
        "returnDate",
        "equityCashDistiAllocationDate",
        "partnerDistributionDate",
      ])
    );
  }, [earningsInfo]);
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      {openDialog && (
        <ReverseEarningSection
          openDialog={openDialog}
          setOpenDialog={(val: any) => {
            setOpenDialog(val);
            setRowSelectData({});
          }}
          rowSelectData={rowSelectData}
          setRowSelectData={setRowSelectData}
        />
      )}

      {/* <Card> */}
      <DataTableComponent
        valueData={earningsData || []}
        fieldsElements={dataTableElement}
        isPaginationAllowed={true}
        footerElement={earningsInfo.length > 0 && footerGroup}
        scrollable={true}
        columnResizeMode="expand"
        resizableColumns={true}
        className="gridcell deletebtn"
        openDailog={props.openDailog}
        setOpenDailog={props.setOpenDailog}
        maxHeight="calc(-235px + 100vh)"
      />
      {/* </Card> */}
    </>
  );
}
