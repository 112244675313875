import { useMsal } from "@azure/msal-react";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { loginRequest } from "../../../../b2c/b2c-config";
import { isFundExistThunk } from "../../../../store/features/fund-exist/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { convertToLowerCase } from "../../../../utils/helper";
import DefaultLogin from "./DefaultLogin";

const NewLogin = () => {
  const { instance } = useMsal();
  const { slug } = useParams();
  const dispatch = useAppDispatch();

  //  Get Tenant name via url
  const isFundExist = useAppSelector((state) => state.isFundExist.data);
  const loading = useAppSelector((state) => state.isFundExist.loading);
  console.log("loading", loading);
  console.log("isFundExist", isFundExist);

  const tenantName = slug!;
  localStorage.setItem("tenentName", convertToLowerCase(slug!));

  useEffect(() => {
    dispatch(isFundExistThunk(""));
  }, []);

  useEffect(() => {
    if (isFundExist) {
      const date = new Date();
      const timeStamp = date.valueOf() + 10 * 1000;
      console.log("Date", timeStamp);
      const loginRequest1 = {
        extraQueryParameters: {
          tenant: tenantName,
          timeStamp: timeStamp.toString(),
        },
        ...loginRequest,
      };
      // setRedirecting(true);
      instance.loginRedirect(loginRequest1).catch((error: any) => {
        console.log(error);
        //   setRedirecting(false);
      });
    }
  }, [isFundExist]);

  return (
    <>
      {loading === "succeeded" ? (
        isFundExist ? (
          <ProgressSpinner />
        ) : (
          <DefaultLogin />
        )
      ) : (
        <ProgressSpinner />
      )}
    </>
  );
};
export default NewLogin;
