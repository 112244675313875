import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  // getUserProfileDetailsService,
  getFundLogoService,
  getFundBannerService,
  getUserProfileDetailsService,
  getFundImageService,
} from "../../../services/profile/profile";
// import { RootState } from "../../../store";
import { IProfile } from "../../../types/index";
import error from "../../../utils/error";

interface IProfileState {
  isLoadFirsttime: boolean;
  data?: IProfile[] | [];
  loading: string;
  error?: string | null;
  id?: number | null;

  sideLogo?: any | null;
  loadingFundlogo?: string;
  errorFundlogo?: string | null;

  imageBanner?: string | null;
  loadingFundBanner?: string;
  errorFundBanner?: string | null;

  fundImage?: string;
  loadingFundImage?: string;
  errorFundImage?: string | null;
}

const initialState: IProfileState = {
  data: [],
  isLoadFirsttime: false,
  sideLogo: undefined,
  imageBanner: undefined,
  loading: "loading",
  error: undefined,
  loadingFundlogo: "loading",
  errorFundlogo: undefined,
  loadingFundBanner: "loading",
  errorFundBanner: undefined,
  id: undefined,

  fundImage: undefined,
  loadingFundImage: "loading",
  errorFundImage: undefined,
};

export const getUserProfileDetailsThunk = createAsyncThunk(
  "profile/getUserProfileDetails",
  async (data: any) => {
    try {
      const response = await getUserProfileDetailsService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

export const getFundLogoThunk = createAsyncThunk(
  "profile/getFundLogo",
  async () => {
    try {
      const response = await getFundLogoService();
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

export const getFundBannerThunk = createAsyncThunk(
  "profile/getSideImages",
  async () => {
    try {
      const response = await getFundBannerService();
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

export const getFundImageThunk = createAsyncThunk(
  "profile/getFundImage",
  async () => {
    try {
      const response = await getFundImageService();
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setFirsLoaidng: (state) => {
      state.isLoadFirsttime = false;
    },

    setLeagalentity: (state, action) => {
      state.id = action.payload;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getUserProfileDetailsThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getUserProfileDetailsThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";

      state.isLoadFirsttime = true;
      // localStorage.setItem("fundURL",action.payload.);
      if (action.payload._response.data !== null) {
        state.data = action.payload._response?.data;
        state.id = action.payload._response?.data[0]?.legalEntityID;
        localStorage.setItem(
          "fundWebsite",
          action.payload._response?.data[0]?.fundWebsite
        );
      }
    });
    builder.addCase(getUserProfileDetailsThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });

    // Side images
    builder.addCase(getFundLogoThunk.pending, (state) => {
      state.loadingFundlogo = "loading";
      state.errorFundlogo = undefined;
    });
    builder.addCase(getFundLogoThunk.fulfilled, (state, action) => {
      state.loadingFundlogo = "succeeded";
      state.sideLogo = action.payload.response.data.blobURL;
    });
    builder.addCase(getFundLogoThunk.rejected, (state, action) => {
      state.loadingFundlogo = "failed";
      // errorFundlogo
      // action.payload contains error information
      state.errorFundlogo = error(action.payload);
      // state.error = action.payload
    });

    // Banner logos
    builder.addCase(getFundBannerThunk.pending, (state) => {
      state.loadingFundBanner = "loading";
      state.errorFundBanner = undefined;
    });
    builder.addCase(getFundBannerThunk.fulfilled, (state, action) => {
      state.loadingFundBanner = "succeeded";
      state.imageBanner = action.payload.response.data.blobURL;
    });
    builder.addCase(getFundBannerThunk.rejected, (state, action) => {
      state.loadingFundBanner = "failed";
      // action.payload contains error information
      state.errorFundBanner = error(action.payload);
    });

    // Side Image
    builder.addCase(getFundImageThunk.pending, (state) => {
      state.loadingFundImage = "loading";
      state.errorFundImage = undefined;
    });
    builder.addCase(getFundImageThunk.fulfilled, (state, action) => {
      state.loadingFundImage = "succeeded";
      state.fundImage = action.payload.response.data.blobURL;
    });
    builder.addCase(getFundImageThunk.rejected, (state, action) => {
      state.loadingFundImage = "failed";
      // action.payload contains error information
      state.errorFundImage = error(action.payload);
    });
  },
});
export const getprofileData = (state: RootState) => {
  return state.profile.data;
};

export const { setFirsLoaidng, setLeagalentity } = slice.actions;

export default slice.reducer;
