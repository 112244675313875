import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { addInvestmentStep1Service } from "../../../../services/investment/investment";
import {
  addInvestmentUploadFileService,
  bulkUpdateindexThunkInvestmentService,
  deleteInvestmentFileService,
  getInvestmentFilebyProductIdService,
  setPrimaryInvestmentFileService,
} from "../../../../services/investment/investment";
import error from "../../../../utils/error";
import { IUploadFileState } from ".";

const initialState: IUploadFileState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const uploadThunkInvestmentFile = createAsyncThunk(
  "files/uploadFile",
  async (data: any) => {
    try {
      const response = await addInvestmentUploadFileService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const getThunkInvestmentFilebyProductid = createAsyncThunk(
  "files/getInvestmentFilebyProductId",
  async (data: any) => {
    try {
      const response = await getInvestmentFilebyProductIdService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const setPrimaryInvestmentFile = createAsyncThunk(
  "files/setPrimaryInvestmentFile",
  async (data: any) => {
    try {
      await setPrimaryInvestmentFileService(data);
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const bulkUpdateindexThunkInvestmentFile = createAsyncThunk(
  "files/bulkUpdateindexThunkInvestmentFile",
  async (data: any) => {
    try {
      const response = await bulkUpdateindexThunkInvestmentService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const deleteThunkInvestmentFile = createAsyncThunk(
  "files/deleteFile",
  async (data: any) => {
    try {
      const response = await deleteInvestmentFileService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "files",
  initialState,
  reducers: {
    // setForm: (state, action) => {
    //   state.data = action.payload;
    //   //
    // },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(uploadThunkInvestmentFile.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(uploadThunkInvestmentFile.fulfilled, (state, action) => {
      state.loading = "succeeded";

      if (action.payload.data !== null) {
        state.data = action.payload.data;
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(uploadThunkInvestmentFile.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
    builder.addCase(
      getThunkInvestmentFilebyProductid.fulfilled,
      (state, action) => {
        state.loading = "succeeded";

        if (action.payload.data !== null) {
          state.data = action.payload.data;
        }
        state.message =
          action.payload.message === null ? undefined : action.payload.message;
      }
    );

    builder.addCase(setPrimaryInvestmentFile.fulfilled, (state, action) => {
      state.loading = "succeeded";
    });

    builder.addCase(bulkUpdateindexThunkInvestmentFile.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });

    builder.addCase(
      bulkUpdateindexThunkInvestmentFile.fulfilled,
      (state, action) => {
        state.loading = "succeeded";

        if (action.payload.data !== null) {
          state.data = action.payload.data;
        }
        state.message =
          action.payload.message === null ? undefined : action.payload.message;
      }
    );
    builder.addCase(
      bulkUpdateindexThunkInvestmentFile.rejected,
      (state, action) => {
        state.loading = "failed";
        console.log("errorslice", action.error);
        //   // action.payload contains error information
        // state.error = error(action.payload);
        state.error = error(action.error);
      }
    );
    builder.addCase(deleteThunkInvestmentFile.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(deleteThunkInvestmentFile.fulfilled, (state, action) => {
      state.loading = "succeeded";

      if (action.payload.data !== null) {
        state.data = action.payload.data;
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(deleteThunkInvestmentFile.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);

      state.error = error(action.error);
    });
  },
});
// export const { setForm } = slice.actions;
export default slice.reducer;
