// import { useMsal } from "@azure/msal-react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
// import { useNavigate } from "react-router-dom";
import { acquireTokenSilently, logoutHelper } from "../../utils/helper";
import { envVariable } from "../../utils/sponsor/helper";

const IdealTimeOut = () => {
  // const { instance } = useMsal();
  // const navigate = useNavigate();
  const [remaining, setRemaining] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const onIdle = () => {
    setOpenDialog(false);
    // const tenant = localStorage.getItem("tenentName");
    localStorage.removeItem("token");
    // instance.logoutRedirect({
    //   postLogoutRedirectUri: "/" + tenant,
    // });

    logoutHelper();
    // navigate("/logout");
  };

  // Tab close event
  // window.addEventListener("beforeunload", function (e) {
  //   // Cancel the event
  //   e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  //   // Chrome requires returnValue to be set
  //   e.returnValue = "";
  // });

  const onPrompt = () => {
    setOpenDialog(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onPrompt,
    // timeout: 9000 * 100,
    timeout: +envVariable.REACT_APP_IDLE_TIME,
    throttle: 500,
    promptBeforeIdle: +envVariable.REACT_APP_IDLE_Prompt_Before_Idle,
    crossTab: true,
    leaderElection: false,
    syncTimers: 200,
  });

  const handleStillHere = () => {
    activate();
    acquireTokenSilently();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Dialog
        visible={openDialog}
        className="w-100 md:w-3"
        onHide={() => setOpenDialog(false)}
        headerClassName="no-header"
        contentClassName="top-lr-content"
      >
        <h3 className="pageHeader text-center mb-5 pt-5">
          Are you still here?
        </h3>
        <p className="text-center">Logging out in {remaining} seconds</p>
        <div className=" align-self-center flex align-items-center justify-content-center">
          <Button
            onClick={(e) => {
              e.preventDefault();
              setOpenDialog(false);
              handleStillHere();
            }}
            className="blueButtonShadow d-block"
          >
            Yes, keep me logged in.
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default IdealTimeOut;
