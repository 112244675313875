// import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { logoutHelper } from "../../../../utils/helper";
import DashboardSkeleton from "../../DashboardSkeleton";

// import Login from "./login";

const LogoutWhenSignupRequestCallForAuthenticatedUser = () => {
  const { slug } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const encodedEmail = queryParams.get("email");
  console.log("encodedEmail", encodedEmail);

  const decodedEmail = encodeURIComponent(encodedEmail!);
  useEffect(() => {
    console.log("decodedEmail", decodedEmail, slug);
    logoutHelper(`signup/${slug}?email=${decodedEmail}`);
  }, []);

  return (
    <>
      <DashboardSkeleton />
    </>
  );
};
export default LogoutWhenSignupRequestCallForAuthenticatedUser;
