import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function getSetupESignListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetSetupeSignonDemandByLegalEntityId?LegalEntityId=${
      data.legalEntityId
    }`
  );
}

export function getAgreementTypeService() {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_INVESTMENT_URL}/Investments/GetAgreementType`
  );
}

export function addSetupESignService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/SaveSetupeSignonDemand`,
    data
  );
}

// execute Esign

export function getStatusService() {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/getExecuteSignonDemandProductStatusDropdown`
  );
}
export function getCommitmentService() {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/getExecuteSignonDemandProductCommitmentList`
  );
}

export function getAllAgreementsService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetExecuteeSignonDemand?LegalEntityId=${
      data.legalEntityID
    }&ProductId=${data.productId}&ProductStatus=${
      data.productStatus
    }&ProductCommitment=${data.productCommitment}&TemplateId=${data.templateId}`
  );
}

export function sendExecuteEsignEmailService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/SendExecuteeSignonDemand`,
    data
  );
}

export function getExecuteEsignDocumentService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/ViewDocumentTemplate?InvestorId=${
      data.investorId
    }&LegalEntityId=${data.legalEntityId}&DocTempId=${
      data.documentTempId
    }&investingAccountId=${data.investingAccountId}`
  );
}

export function GetTemplatebyProductService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetTemplateByProductId?ProductId=${data}`
  );
}
