import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetTransactionTypeService } from "../../../../../services/Accounting/accounting";
import error from "../../../../../utils/error";
import { IGetTransactionTypeState } from ".";

const initialState: IGetTransactionTypeState = {
  success: false,
  message: "",
  loading: "loading",
  result: undefined,
  error: "",
  data: undefined,
};
export const GetTransactionTypeThunk = createAsyncThunk(
  "Accounting/GetTransactionType",
  async () => {
    try {
      const response = await GetTransactionTypeService();
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Accounting/GetTransactionType",
  initialState,
  reducers: {
    setForm: (state, action) => {
      state.data = action.payload;
      //
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetTransactionTypeThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetTransactionTypeThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data : [];

      // if (action.payload.data !== null) {
      // }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(GetTransactionTypeThunk.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});
export const { setForm } = slice.actions;
export default slice.reducer;
