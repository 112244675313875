import { PoolFundsByInvestorIdParamType } from "../../types";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";
type dataType = {
  legalEntityId: number;
  investmentId: number;
  stakeTrackingId?: number;
};
export function getAllInvestorListforPoolFunds(data: dataType) {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/GetAllInvestorListforPoolFunds?LegalEntityId=${data.legalEntityId}&InvestmentId=${data.investmentId}  `
  );
}

export function getAllocatedInvestorsByInvestmentPoolfunds(data: dataType) {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/GetAllocatedInvestorsByInvestmentPoolfunds?LegalEntityId=${data.legalEntityId}&InvestmentId=${data.investmentId}  `
  );
}

export const addNewPoolfundsUnits = (data: any) => {
  return apiFactory().post(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/AddNewPoolfundsUnits`,
    data
  );
};

export const exitAllocatedPoolFunds = (data: any) => {
  return apiFactory().post(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/ExitAllocatedPoolFunds`,
    data
  );
};

export const reverseAllocatedPoolFundsByStakeId = (data: dataType) => {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/ReverseAllocatedPoolFundsByStakeId?LegalEntityId=${data.legalEntityId}&InvestmentId=${data.investmentId}&StakeTrackingId=${data.stakeTrackingId}`
  );
};

export const getAllocatedPoolFundsByInvestorId = (
  data: PoolFundsByInvestorIdParamType
) => {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/GetAllocatedPoolFundsByInvestorId?LegalEntityId=${data.legalEntityId}&InvestmentId=${data.investmentId}&LPPartnerId=${data.lPPartnerId}`
  );
};

export const getAfterEarningsStatckbyInvestmentReturnID = (data: {
  investmentReturnId: number;
  investmentId: number;
}) => {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/GetAfterEarningsStatckbyInvestmentReturnID?InvestmentReturnID=${data.investmentReturnId}&InvestmentId=${data.investmentId}`
  );
};

export const checkEarningsBeforeReverse = (data: {
  lPPartnerId: number;
  investmentId: number;
  stakeTrackingID: number;
}) => {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/CheckEarningsBeforeReverse?StakeTrackingID=${data.stakeTrackingID}&InvestmentId=${data.investmentId}&LPPartnerId=${data.lPPartnerId}`
  );
};

export function getEditAllocatedInvestorsByInvestmentPoolfundsService(
  data: dataType
) {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/GetEditAllocatedInvestorsByInvestmentPoolfunds?LegalEntityId=${data.legalEntityId}&InvestmentId=${data.investmentId}  `
  );
}
