import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Dropdown } from "primereact/dropdown";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";

import { GetProdductListThunk } from "../../../store/features/taxmanagement/getpartnerprodatabyinvestment/getproductlist/slice";
import {
  GetAllocatedInvestmentThunk,
  resetReverseData,
} from "../../../store/features/taxmanagement/ownershipbyproductdata/getallocatedinvestment/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import currencyFormat from "../../../utils/currency-formatter";
import { convertLocalDateToUTC } from "../../../utils/date-formatter";
import { exportExcel } from "../../../utils/sponsor/helper";

// import dateFormatter from "../../../utils/date-formatter";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";
export default function AllocationByInvestment() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  // const productList: any = useAppSelector(
  //   (state) => state.GetProdductList?.data
  // );

  const list = useAppSelector((state) => state.GetAllocatedInvestment?.data);
  const list2 = useAppSelector((state) => state.GetAllocatedInvestment?.data2);

  const [dataCreateElemnts, setDataCreateElemnts] = useState<any>();
  const [dataTableElements, setdataTableElements] = useState<any>();
  const [loading, setloading] = useState<any>(false);
  const [stateName, setName] = useState<any>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [columnSums, setColumnSums] = useState<{ [key: string]: any }>({});
  const [openDailog, setOpenDailog] = useState(false);
  const [cancel, setCancel] = useState(true);

  // const [envName, setName] = useState<any>("");

  // const formatDate = (dateString: string | number | Date) => {
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");

  //   return `${year}-${month}-${day}`;
  // };
  const datatableArrayObj: any = [];
  const newArray: any = [];

  // useEffect(() => {
  //   document.body.classList.add("no-scroll");
  //   document.body.style.overflowX = "unset";
  //   document.documentElement.style.overflowX = "unset";
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //     document.body.style.overflowX = "hidden";
  //     document.documentElement.style.overflowX = "hidden";
  //   };
  // }, []);

  const prepareData = (data: any) => {
    if (data !== undefined && data !== "") {
      const value = JSON.parse(list2!);
      const header = JSON.parse(list!);
      // const footer = JSON.parse(list2!);

      header.splice(
        0,
        0,
        {
          InvestmentName: "LPPartnerID",
          InvestmentId: "LPPartnerID",
        },
        {
          InvestmentName: "PartnerName",
          InvestmentId: "PartnerName",
        }
      );
      const objTotal: any = {};

      for (const val of value) {
        // Loop through the lists to calculate column sums
        let totalSum = 0;
        console.log("prepareData totalObj", val);

        for (const key in val) {
          if (key in objTotal) {
            objTotal[key] += val[key];
          } else {
            objTotal[key] = val[key];
          }

          if (key === "4") {
            totalSum += val[key];
            console.log("4ajsjdsj", totalSum, val[key], key);
          } else {
            totalSum += val[key];
          }
        }
        const newData = Object.fromEntries(
          Object.entries(objTotal).filter(
            ([key]) => key !== "LPPartnerID" && key !== "PartnerName"
          )
        );

        console.log("newData", newData);
        console.log("prepareData objTotal", objTotal);

        setColumnSums(newData);
      } // Update columnSums state

      for (let j = 0; j < value?.length; j++) {
        // let count = 0;
        // let lengthData = 0;
        const obj: any = {};
        for (const key in value[j]) {
          obj[key] =
            key === "LPPartnerID" || key === "PartnerName"
              ? value[j][key]
              : currencyFormat(value[j][key]);

          // count++;
        }

        datatableArrayObj.push(obj);
      }
      console.log("yes", datatableArrayObj);
      return datatableArrayObj.length > 0 ? datatableArrayObj : [];
    } else {
      return [];
    }
  };
  console.log("value", columnSums);

  const createcolumns = (data: any) => {
    console.log("data", data);

    if (data !== undefined && data !== "") {
      const header = JSON.parse(list!);
      console.log("list", header);

      header.splice(
        0,
        0,
        {
          InvestmentName: "LPPartnerID",
          InvestmentId: "Partner Id",
        },
        {
          InvestmentName: "PartnerName",
          InvestmentId: "Investor Account",
        }
      );
      const headerCol = header.map((key: any, index: number) => {
        if (index === 0 || index === 1) {
          return {
            field: key.InvestmentName,
            header: key.InvestmentId,
            style: { minWidth: "220px" },
          };
        }
        return {
          field: key.InvestmentId,
          header: key.InvestmentName,
          style: { minWidth: "510px" },
        };
      });
      return headerCol;
    } else {
      return [];
    }
  };

  const formik = useFormik({
    initialValues: {
      Date: new Date().toString(),
      inv: "",
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Select process date";
      }
      if (data.inv === "") {
        errors.inv = "Please select equity investment";
      }
      return errors;
    },
    onSubmit: async () => {
      setloading(true);

      const req = {
        legalEntityId: fundDropdown.fundid,
        // eslint-disable-next-line unicorn/no-null
        InvestmentId: +formik.values.inv === 0 ? "" : +formik.values.inv,
        yearEndDate: convertLocalDateToUTC(formik.values.Date),
      };
      dispatch(resetReverseData());
      dispatch(GetAllocatedInvestmentThunk(req))
        .then(() => {
          // setDataCreateElemnts(createcolumns(list));

          // setdataTableElements(prepareData(list));
          setName(false);
          setloading(false);

          // const col = createcolumns(list);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    },
  });
  console.log("currentDate", formik.values.Date);

  console.log("outside ussefect if --", dataTableElements);

  useEffect(() => {
    console.log("outside ussefect if", list);

    if (list) {
      setDataCreateElemnts(createcolumns(list));
      setdataTableElements(prepareData(list));
    } else {
      setDataCreateElemnts("");
      setdataTableElements([]);
    }
  }, [list, stateName]);

  console.log("dataTableElements", dataTableElements);
  console.log("dataCreateElemnts", dataCreateElemnts);

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  useEffect(() => {
    setName(true);
    setdataTableElements([]);
    console.log("Data Emptyformik changes", dataTableElements);
  }, [formik.values.Date, formik.values.inv, fundDropdown.fundid]);
  console.log("Data Empty", dataTableElements);

  useEffect(() => {
    const p = {
      LegalEntityId: fundDropdown.fundid,
    };
    setloading(true);
    if (fundDropdown.fundid) {
      dispatch(GetProdductListThunk(p)).then((res: any) => {
        const data = res.payload._response.data;
        // eslint-disable-next-line unicorn/no-null
        if (data) {
          const arr = [{ key: 0, value: "All Investment" }];

          newArray.push(...arr, ...data);
          setTableData(newArray);
        } else {
          setTableData([]);
        }
        setloading(false);
      });
    }

    setdataTableElements([]);
    formik.resetForm();
    setCancel(true);
  }, [fundDropdown.fundid]);

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Calculated Total Cash Distributions "
          colSpan={2}
          // footerStyle={{ textAlign: "right" }}
        />
        {Object.keys(columnSums).map((key, index) => (
          <Column
            key={index}
            footer={
              currencyFormat(columnSums[key])
              // typeof columnSums[key] !== "string"
              //   ? formatCurrency(columnSums[key])
              //   : "1"
            }
          />
        ))}
      </Row>
    </ColumnGroup>
  );

  const handleSubmit = (e: any) => {
    console.log("handleSubmit", e.value);
    formik.setFieldValue("inv", e.value);
    setCancel(false);
  };
  useEffect(() => {
    console.log("invValue", formik.values.inv, cancel);
    if (!cancel) {
      formik.handleSubmit();
    }
  }, [formik.values.inv, formik.values.Date]);

  console.log("Investor allocation", dataTableElements, list, list2);

  return (
    <>
      {/* <div className="grid"> */}
      <Toast ref={toast} className="themeToast" />
      {loading && <Loader />}
      <div className="flex justify-content-between col-12 ">
        <div className="card pgHeaderWrap">
          <h1 className="pageHeader fs-2">
            K-1: Investor allocation % by investment
          </h1>
        </div>
        <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
          <Button
            className="remove-shadow"
            type="button"
            // icon="pi pi-file"
            // rounded
            onClick={() =>
              exportExcel(
                dataTableElements,
                "K-1: Investor allocation % by investment"
              )
            }
            data-pr-tooltip="exportExcel"
            disabled={
              dataTableElements === undefined ||
              dataTableElements?.length === 0 ||
              dataTableElements === null
            }
          >
            <span className="blueButtonShadowText">Download </span>
          </Button>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                dataTableElements === undefined ||
                dataTableElements?.length === 0 ||
                dataTableElements === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className="card ">
        <>
          {" "}
          <div className="formgrid grid col">
            <div className="d-block sm:d-flex sm:flex-column md:col-6 col-5 ">
              <label className="inputLabel">Equity Investment *</label>
              <Dropdown
                name="inv"
                style={{ width: "100%" }}
                options={tableData! || []}
                optionLabel="value"
                optionValue="key"
                placeholder="Select.."
                onChange={handleSubmit}
                value={formik.values.inv}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("inv"),
                  "card-inputBox": true,
                })}
              />
              {getFormErrorMessage("inv")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column md:col-6 col-5">
              <label className="inputLabel">Process On*</label>
              <div className="card flex justify-content-center">
                <Calendar
                  name="Date"
                  value={
                    formik.values.Date === null || formik.values.Date === ""
                      ? undefined
                      : new Date(formik.values.Date)
                  }
                  onChange={(e) => {
                    formik.setFieldValue("Date", e.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Date"),
                    "card-inputBox": true,
                  })}
                  // minDate={todayDate}
                  placeholder="mm/dd/yyyy"
                  // dateFormat="mm-dd-yy"
                />
                {getFormErrorMessage("Date")}
              </div>
            </div>
            <div className="d-flex justify-content-center col-2 mt-4">
              <Button
                className="mr-4 btn-nav w-7rem justify-content-center"
                onClick={(e) => {
                  e.preventDefault();
                  formik.resetForm();
                  dispatch(resetReverseData());
                  setCancel(true);
                }}
              >
                Reset
              </Button>

              {/* <Button
                className="btn-nav w-7rem justify-content-center"
                onClick={(e) => {
                  e.preventDefault();
                  // setIsFormSubmitted(true);
                  console.log("clicked");
                  formik.handleSubmit();
                }}
              >
                Show
              </Button> */}
            </div>
          </div>
        </>{" "}
        <div className="">
          {dataTableElements && dataTableElements.length > 0 && !stateName && (
            <DataTableComponent
              valueData={dataTableElements || []}
              fieldsElements={dataCreateElemnts}
              footerElement={dataTableElements.length > 0 && footerGroup}
              // isDownload={true}
              isPaginationAllowed={true}
              fileName="K-1: Investor allocation % by investment"
              scrollable={true}
              maxHeight="calc(-246px + 100vh)"
              className="gridcell"
              columnResizeMode="expand"
              isFullView={true}
              setOpenDailog={setOpenDailog}
              openDailog={openDailog}
            />
          )}
          {!dataTableElements &&
            dataTableElements?.length === 0 &&
            !stateName && (
              <DataTableComponent
                valueData={[]}
                fieldsElements={[]}
                // isDownload={true}
                // isPaginationAllowed={true}
                fileName="K-1: Investor allocation % by investment"
                scrollable={true}
                className="gridcell"
                maxHeight="calc(-246px + 100vh)"
                columnResizeMode="expand"
                isFullView={true}
                setOpenDailog={setOpenDailog}
                openDailog={openDailog}
              />
            )}
        </div>
      </div>
    </>
  );
}
