import { IGetGAAPAccountingBalanceSheetParameter } from "../../store/features/gaap-accounting/get-gaap-accounting-balance-sheet";
import { IGetGAAPAccountingProfitLossParameter } from "../../store/features/gaap-accounting/get-gaap-accounting-profit-loss";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function getGAAPAccountingBalanceSheetService(
  data: IGetGAAPAccountingBalanceSheetParameter
) {
  console.log(envVariable);
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_GAAP_ACCOUNTING
    }/GAAPReports/GetGAAPAccountingBalanceSheet?SelectedDate=${
      data.selectedDate
    }&ReportTypeId=${data.reportTypeId}`
  );
}

export function getGAAPAccountingProfitLossService(
  data: IGetGAAPAccountingProfitLossParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_GAAP_ACCOUNTING
    }/GAAPReports/GetGAAPAccountingProfitLoss?FromDate=${
      data.fromDate
    }&ToDate=${data.toDate}&ReportTypeId=${data.reportTypeId}`
  );
}

export function getReportTypeListService() {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_GAAP_ACCOUNTING
    }/GAAPReports/GetReportTypeList`
  );
}
