// import { Configuration, LogLevel } from "@azure/msal-browser";
import { Configuration } from "@azure/msal-browser";
import { getEnvConfig } from "../utils/sponsor/helper";
import { getConfig } from "./env-config";

const env = getEnvConfig();

const envVariable: any = getConfig(env);

export const b2cPolicies = {
  names: {
    SignIn: envVariable.REACT_APP_SIGN_IN,
    signUp: envVariable.REACT_APP_SIGN_UP,
    changePassword: envVariable.REACT_APP_SIGN_UP,
    forgotPassword: envVariable.REACT_APP_FORGOT_PASSWORD,
    editProfile: envVariable.REACT_APP_EDIT_PROFILE,
  },
  authorities: {
    SignIn: {
      authority: `${envVariable.REACT_APP_DOMAIN_NAME}${envVariable.REACT_APP_TENANT_NAME}${envVariable.REACT_APP_SIGN_IN}`,
    },
    signUp: {
      authority: `${envVariable.REACT_APP_DOMAIN_NAME}${envVariable.REACT_APP_TENANT_NAME}${envVariable.REACT_APP_SIGN_UP}`,
    },
    changePassword: {
      authority: `${envVariable.REACT_APP_DOMAIN_NAME}${envVariable.REACT_APP_TENANT_NAME}${envVariable.REACT_APP_CHANGE_PASSWORD}`,
    },
    forgotPassword: {
      authority: `${envVariable.REACT_APP_DOMAIN_NAME}${envVariable.REACT_APP_TENANT_NAME}${envVariable.REACT_APP_FORGOT_PASSWORD}`,
    },
    editProfile: {
      authority: `${envVariable.REACT_APP_DOMAIN_NAME}${envVariable.REACT_APP_TENANT_NAME}${envVariable.REACT_APP_EDIT_PROFILE}`,
    },
  },
  authorityDomain: `${envVariable.REACT_APP_AUTHORITY_DOMAIN}`,
};
export const msalConfig: Configuration = {
  auth: {
    clientId: `${envVariable.REACT_APP_CLIENT_ID}`, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.SignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "https://www.avestorinc.com/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (level, message, containsPii) => {
  //       if (containsPii) {
  //         return;
  //       }
  //       switch (level) {
  //         case LogLevel.Error:
  //           console.error(message);
  //           return message;
  //         case LogLevel.Info:
  //           console.info(message);
  //           return message;
  //         case LogLevel.Verbose:
  //           console.debug(message);
  //           return message;
  //         case LogLevel.Warning:
  //           console.warn(message);
  //           return message;
  //         default:
  //           return "";
  //       }
  //     },
  //   },
  // },
  // More configuration here
};
/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [],
};
