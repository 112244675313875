import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import React, { useEffect, useState } from "react";
import {
  RiBriefcase2Line,
  RiDashboard3Line,
  RiFundsBoxLine,
  RiHandCoinLine,
  RiHotelLine,
  RiMoneyDollarBoxLine,
  RiPercentLine,
  RiPieChartLine,
  RiScalesLine,
  RiShareForwardFill,
  RiShoppingBasket2Line,
  RiTeamLine,
} from "react-icons/ri";
import DataTableComponent from "../../components/data-table/DataTableComponent";
import Loader from "../../components/loader/Loader";
import { GetDashboardInfoThunk } from "../../store/dashboard/dashboardmanagement/slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import currencyFormat from "../../utils/currency-formatter";

export default function ManagementDashboard() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const dispatch = useAppDispatch();
  const [dataCreateElemnts, setDataCreateElemnts] = useState<any>();
  const [dataTableElement, setdataTableElements] = useState<any>();
  const [dataSupplierCreateElemnts, setDataSupplierCreateElemnts] =
    useState<any>();
  const [dataTableSupplierElement, setdataTableSupplierElements] =
    useState<any>();
  const [loading, setloading] = useState<any>(false);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const allData = useAppSelector((state) => state.GetDashboardInfo.data);
  const accountOverview = useAppSelector(
    (state) => state.GetDashboardInfo.accountOverview
  );
  const Geographic = useAppSelector(
    (state) => state.GetDashboardInfo.dynamicValues1
  );

  const Supplier = useAppSelector((state) => {
    return state.GetDashboardInfo.dynamicValues2;
  });

  const portfolioSize = useAppSelector(
    (state) => state.GetDashboardInfo.portfolioSize
  );

  console.log("portfolioSize", portfolioSize);
  const deals: any[] = [];

  const [lab, setLabel] = useState<any>();
  const [valPercent, setValPercent] = useState<any>();
  const [val, setVal] = useState(deals[0]);

  portfolioSize?.map((item) => {
    return deals.push(item.activeDeal);
  });
  console.log("deals---", deals.length);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);

    // Extract activeDeal values from the portfolioSize array
    const activeDeals = portfolioSize
      ? portfolioSize!.map((item) => item.activeDeal)
      : [];
    console.log("supplier-", activeDeals.length);

    const data = {
      labels: portfolioSize ? portfolioSize!.map((item) => item.category) : [],
      datasets: [
        {
          data: activeDeals,
          backgroundColor: [
            documentStyle.getPropertyValue("--background-color"),
            documentStyle.getPropertyValue("--lighter-color"),
            documentStyle.getPropertyValue("--Darker-color"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--Darkest-color"),
            documentStyle.getPropertyValue("--Darkest-color"),
            documentStyle.getPropertyValue("--Darkest-color"),
          ],
        },
      ],
    };

    const options = {
      tooltips: false,

      onBlur: () => {},
      cutout: "80%",
      responsive: true,
      plugins: {
        tooltip: {
          enabled: false, // <-- this option disables tooltips
        },
        legend: false,
      },

      onHover: (
        _event: any,
        chartElement: {
          [x: string]: any;
          label: any;
        }[]
      ) => {
        if (chartElement && chartElement[0]) {
          const value =
            data.datasets[chartElement[0].datasetIndex].data[
              chartElement[0].index
            ];
          const label = data.labels[chartElement[0].index];
          // JSON.stringify(data);

          const valPer = (value * 100) / deals[0];
          setValPercent(valPer);
          setVal(value);
          setLabel(label);
        } else {
          setVal(val);
          setLabel(lab);
          setValPercent(valPercent);
        }
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [portfolioSize]);

  useEffect(() => {
    const req = {
      LegalEntityId: fundDropdown.fundid,
    };
    setloading(true);

    dispatch(GetDashboardInfoThunk(req))
      .then(() => {
        setloading(false);
      })
      .catch(() => {
        setloading(false);
      });
  }, [fundDropdown.fundid]);

  const formatCurrencyAnyData = (rowData: any) => {
    const finalValue = Number(rowData);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const formatCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.amount);
  };

  const formatCurrencysnap = (rowData: any) => {
    return formatCurrencyAnyData(rowData.TotalInvested);
  };

  const dataTableaccountOverviews = [
    { field: "amountType", header: "Asset Class" },
    { field: "invGol", header: "Category" },
    { field: "deals", header: "# of Deals" },
    {
      field: "amount",
      header: "Value",
      body: formatCurrency,
    },
  ];

  const prepareData = (data: any) => {
    const values = data ? JSON.parse(data) : [];
    console.log("values", values);
    return values;
    // return values || [];
  };

  const dataTablegeoElements = [
    {
      field: "USState",
      header: "US State",
    },
    {
      field: "Residential_Debt",
      header: "Residential Debt",
    },
    {
      field: "Equity",
      header: "Equity",
    },
    {
      field: "Commercial_Debt",
      header: "Commercial Debt",
    },
    {
      field: "TotalDeals",
      header: "Total Deals",
    },
  ];

  const dataTableoypsupplierElements = [
    {
      field: "SupplierName",
      header: "Supplier Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.SupplierName}>
          {rowData?.SupplierName}
        </div>
      ),
    },
    {
      field: "Commercial_Debt",
      header: "Commercial Debt",
    },
    {
      field: "Residential_Debt",
      header: "Residential Debt",
    },
    {
      field: "Equity",
      header: "Equity",
    },
    {
      field: "TotalInvested",
      header: "Total Invested",
      body: formatCurrencysnap,
    },
  ];

  const createcolumns = (data: any) => {
    if (data.length > 0) {
      const geoData = JSON.parse(data);
      const header = Object.keys(geoData[0]).map((key: any) => ({
        field: key,
        header: key
          .replace(/(_|-)/g, " ")
          .trim()
          .replace(/\w\S*/g, function (str: string) {
            return str.charAt(0).toUpperCase() + str.slice(1);
          })
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2"),
        body: key === "TotalInvested" ? formatCurrencysnap : "",
      }));
      console.log("geoData");

      const partnerIDIndex = header.findIndex(
        (item: any) => item.field === "TotalInvested"
      );

      // If PartnerID and PartnerName are found, move them to indexes 0 and 1 respectively
      if (partnerIDIndex !== -1) {
        const partnerIDField = header.splice(partnerIDIndex, 1)[0];
        header.push(partnerIDField); // Move it to the last index
      }
      return header;
    } else {
      return [];
    }
  };
  // const newData: any[] = []; // Declare an array to hold the new data
  useEffect(() => {
    // prepareData(list, title);
    console.log("useefect", Geographic);
    if (Geographic) {
      const header = createcolumns(Geographic);
      //   console.log(setDataCreateElemnts);
      //   console.log(setdataTableElements);
      console.log(header);
      //   prepareData(Geographic);
      setDataCreateElemnts(header);
      setdataTableElements(prepareData(Geographic));
    }
  }, [Geographic]);

  useEffect(() => {
    // prepareData(list, title);
    console.log("useefect-Supplier", Supplier);
    if (Supplier) {
      const header = createcolumns(Supplier);
      //   console.log(setDataCreateElemnts);
      //   console.log(setdataTableElements);
      console.log(header);
      //   prepareData(Geographic);
      setDataSupplierCreateElemnts(header);
      setdataTableSupplierElements(prepareData(Supplier));
    }
  }, [Supplier]);
  console.log(dataCreateElemnts);
  console.log(dataSupplierCreateElemnts);

  const computeGeographicSum = (totalFieldName: string) => {
    console.log("dataTableElement", dataTableElement);
    if (dataTableElement !== undefined) {
      const { length } = dataTableElement;
      console.log("length", length);
      let count = 0;
      for (let i = 0; i < length; i += 1) {
        count += Number(
          typeof dataTableElement[i][totalFieldName] === "object"
            ? dataTableElement[i][totalFieldName]
            : dataTableElement[i][totalFieldName] || 0
        );
      }

      return count;
    }
  };

  const computeSupplierSum = (totalFieldName: string) => {
    if (dataTableSupplierElement !== undefined) {
      const { length } = dataTableSupplierElement;
      console.log("length", length);
      let count = 0;
      for (let i = 0; i < length; i += 1) {
        count += Number(
          typeof dataTableSupplierElement[i][totalFieldName] === "object"
            ? dataTableSupplierElement[i][totalFieldName]
            : dataTableSupplierElement[i][totalFieldName] || 0
        );
      }
      if (totalFieldName === "TotalInvested") {
        return formatCurrencyAnyData(count);
      }
      return count;
    }
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total:"

          // footerStyle={{ textAlign: "right" }}
        />
        <Column footer={computeGeographicSum("Commercial_Debt")} />
        <Column footer={computeGeographicSum("Equity")} />
        <Column footer={computeGeographicSum("Residential_Debt")} />
        <Column footer={computeGeographicSum("TotalDeals")} />
      </Row>
    </ColumnGroup>
  );
  const footerSupplierGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total:"

          // footerStyle={{ textAlign: "right" }}
        />
        <Column footer={computeSupplierSum("Commercial_Debt")} />
        <Column footer={computeSupplierSum("Residential_Debt")} />
        <Column footer={computeSupplierSum("Equity")} />
        <Column footer={computeSupplierSum("TotalInvested")} />
      </Row>
    </ColumnGroup>
  );

  console.log("Supplier", chartData);
  return (
    <>
      <div className="grid">
        {loading && <Loader />}

        <div className="md:col-8 col-12">
          <div className="card my-3 tabWithoutboxshadow dashboardStats">
            {allData?.map((item: any, i: any) => {
              return (
                <Card key={i}>
                  <div className="grid mb-3">
                    <div className="col-lg-3 col-12">
                      <div className="acc-value-details-icon">
                        <RiTeamLine className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Total Fund Members
                        </p>
                        <p className="acc-value-details-textValue">
                          {item.totalClient}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="acc-value-details-icon">
                        <RiPieChartLine className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Total AUM
                        </p>
                        <p className="acc-value-details-textValue">
                          {" "}
                          {formatCurrencyAnyData(item.totalAUM)}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="acc-value-details-icon">
                        <RiDashboard3Line className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Average Account Size
                        </p>
                        <p className="acc-value-details-textValue">
                          {" "}
                          {formatCurrencyAnyData(item.avgAccSize)}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="acc-value-details-icon">
                        <RiShareForwardFill className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Total Withdrawals{" "}
                        </p>
                        <p className="acc-value-details-textValue">
                          {" "}
                          {formatCurrencyAnyData(item.totalWithdrawal)}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid mb-3">
                    <div className="col-lg-3 col-12">
                      <div className="acc-value-details-icon">
                        <RiScalesLine className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Σ Equity Distributions{" "}
                        </p>
                        <p className="acc-value-details-textValue">
                          {" "}
                          {formatCurrencyAnyData(
                            item.equityCashDistribution
                          )}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="acc-value-details-icon">
                        <RiHandCoinLine className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Σ Principal Returned{" "}
                        </p>
                        <p className="acc-value-details-textValue">
                          {" "}
                          {formatCurrencyAnyData(
                            item.totalPrincipalReturned
                          )}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="acc-value-details-icon">
                        <RiPercentLine className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Σ Interest Income{" "}
                        </p>
                        <p className="acc-value-details-textValue">
                          {" "}
                          {formatCurrencyAnyData(item.totalInterestIncome)}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="acc-value-details-icon">
                        <RiMoneyDollarBoxLine className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Σ Fund Earnings{" "}
                        </p>
                        <p className="acc-value-details-textValue">
                          {" "}
                          {formatCurrencyAnyData(item.totalEarnings)}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
          <div className="card my-3 tabWithoutboxshadow">
            <Card>
              {" "}
              <h4 className="cardHeader">Account Overview</h4>
              <div className="mt-2">
                <DataTableComponent
                  valueData={accountOverview!}
                  fieldsElements={dataTableaccountOverviews}
                  isPaginationAllowed={true}
                  resizableColumns={true}
                  className="gridcell"
                  columnResizeMode="expand"
                />
              </div>{" "}
            </Card>
          </div>
          <div className="card my-3 tabWithoutboxshadow">
            <Card>
              {" "}
              <h4 className="cardHeader">Geographic Snapshot</h4>
              <div className="mt-2">
                <DataTableComponent
                  valueData={dataTableElement}
                  fieldsElements={dataTablegeoElements}
                  isPaginationAllowed={true}
                  footerElement={footerGroup}
                  resizableColumns={true}
                  className="gridcell"
                  columnResizeMode="expand"
                />
              </div>{" "}
            </Card>
          </div>
          <div className="card my-3 tabWithoutboxshadow">
            <Card>
              {" "}
              <h4 className="cardHeader">Supplier Snapshot</h4>
              <div className="mt-2">
                <DataTableComponent
                  valueData={dataTableSupplierElement}
                  fieldsElements={dataTableoypsupplierElements}
                  isPaginationAllowed={true}
                  footerElement={footerSupplierGroup}
                  resizableColumns={true}
                  className="gridcell"
                  columnResizeMode="expand"
                />
              </div>{" "}
            </Card>
          </div>
        </div>
        <div className="md:col-4 col-12 my-3 tabWithoutboxshadow">
          <Card>
            {" "}
            <h4 className="cardHeader">Portfolio Size</h4>
            <div className="card">
              {deals.length === 0 ? (
                <></>
              ) : (
                <>
                  <div>
                    {chartData && (
                      <Chart
                        type="doughnut"
                        data={chartData}
                        options={chartOptions}
                        className="w-full md:w-rem flex justify-content-center"
                        style={{ width: "250px", height: "250px" }} // Set custom width and height
                      />
                    )}

                    <div className="DoughnutChartData d-flex flex-column text-center">
                      <div>
                        <span className="Doughnutcharttext ">{lab}</span>
                      </div>
                      <div className="d-flex">
                        <span className="Doughnutcharttext mx-2">
                          {val} Deals
                        </span>{" "}
                        {/* <span className="Doughnutcharttext">Deals </span> */}
                        {valPercent ? (
                          <>
                            {" "}
                            <span className="Doughnutcharttext">
                              | {Number(valPercent).toFixed(2)}
                            </span>
                            <span className="Doughnutcharttext">%</span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {allData?.map((item: any, i: any) => {
              return (
                <>
                  <div className="row d-flex  justify-content-cente">
                    <div className="acc-value-details w-100 lg:w-50">
                      <div className="acc-value-details-icon">
                        <RiHotelLine className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Active Deals{" "}
                        </p>
                        <>
                          {" "}
                          <p className="acc-value-details-textValue">
                            {item.activeDeals}{" "}
                          </p>
                        </>
                      </div>
                    </div>
                    <div className="acc-value-details w-100 lg:w-50">
                      <div className="acc-value-details-icon">
                        <RiBriefcase2Line className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Short Term Deals
                        </p>
                        <p className="acc-value-details-textValue">
                          {" "}
                          {item.shortTermDeals}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex  justify-content-cente">
                    <div className="acc-value-details w-100 lg:w-50">
                      <div className=" acc-value-details-icon">
                        <RiFundsBoxLine className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Profit Transactions{" "}
                        </p>
                        <>
                          {" "}
                          <p className="acc-value-details-textValue">
                            {" "}
                            {item.profitTran}{" "}
                          </p>
                        </>
                      </div>
                    </div>
                    <div className="acc-value-details w-100 lg:w-50">
                      <div className="acc-value-details-icon">
                        <RiShoppingBasket2Line className="icons-wrap" />
                      </div>
                      <div className=" md:pl-1 acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Exited Deals
                        </p>
                        <p className="acc-value-details-textValue">
                          {" "}
                          {item.exitedDeals}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </Card>
        </div>
      </div>
    </>
  );
}
