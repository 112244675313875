import { Dialog } from "primereact/dialog";
import React from "react";
interface IProps {
  openDialog: boolean;
  setOpenDialog: any;
  documentLink: string | undefined;
  onDialogClose?: any;
}

export default function ShowDocumentDailogbox(props: IProps) {
  return (
    <Dialog
      header=""
      maximizable
      resizable={false}
      visible={props.openDialog}
      style={{ width: "50vw" }}
      onHide={() => props.onDialogClose()}
      className="identityDocWrap"
    >
      <iframe
        className="personaIframe"
        src={props.documentLink}
        style={{ width: "100%", height: "500px" }}
      ></iframe>
    </Dialog>
  );
}
