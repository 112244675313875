import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetInfoByEmailManageUser } from "../../../../../../services/operation/operation";
import error from "../../../../../../utils/error";
import { IGetInfoByEmailParameter, IGetInfoByEmailState } from ".";

const initialState: IGetInfoByEmailState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const GetInfoByEmailThunk = createAsyncThunk(
  "RoleManagement/InfoByEmail",
  async (data: IGetInfoByEmailParameter) => {
    try {
      console.log("GetInfo", data);
      const response = await GetInfoByEmailManageUser(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "RoleManagement/InfoByEmail",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetInfoByEmailThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetInfoByEmailThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data : [];
      state.message = action.payload.message;
      state.success = action.payload.success;
      console.log("ActionData", action.payload);
    });
    builder.addCase(GetInfoByEmailThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
