import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    productId: 0,
    resetFilters: false,
  },
};

const slice = createSlice({
  name: "productfilterbyid",
  initialState,
  reducers: {
    setProductId: (state, action) => {
      console.log(" as90", action.payload);
      state.data = action.payload;
      //
    },
  },
});
export const { setProductId } = slice.actions;
export default slice.reducer;
