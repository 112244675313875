import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import StepProgressBar from "../StepProgressBar";
import EditPoolFundStep1 from "./Step1";

const subLabels: any = {
  1: "Edit Date/Unit",
  2: "Edit Sublabel 2",
  3: "Edit Sublabel 3",
};
const mainLabels: any = {
  1: "Edit Allocations",
  2: "Edit Mainlabel 2",
  3: "Edit Mainlabel 3",
};
const EditPoolFunds: React.FC = () => {
  const toast = useRef<Toast>(null);
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 1;
  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(1);
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prevState) => (prevState === 1 ? 1 : prevState - 1));
  };
  return (
    <>
      <Toast ref={toast} className="themeToast" />
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader"> Edit Allocations</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <div className=" h-100 center-card">
        <div className="col-12 " style={{ width: "890%" }}>
          <div className="card my-3">
            <Card>
              <StepProgressBar
                mainLabel={mainLabels[currentStep]}
                subLabel={subLabels[currentStep]}
                currentStep={currentStep}
                noOfSteps={totalSteps}
              />
              {currentStep === 1 && (
                <EditPoolFundStep1
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  toast={toast}
                />
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPoolFunds;
