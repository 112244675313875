import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getAllPortfolioService } from "../../services/portfolio/portfolio";
import {
  IAccountValue,
  IAsset,
  IDiversificationElement,
  IExitedDeals,
  IInvestment,
  ILiquidity,
  ITotal,
  ITransaction,
  ITransactionPage,
} from "../../types";
// import { RootState } from "../../store";
// import { IPortfolio } from "../../types/index";
// import apiFactory from "../../utils/api";
import error from "../../utils/error";
interface IPortfolioState {
  data?: {}[] | null;
  accountValue?: IAccountValue[] | null;
  assets?: IAsset[] | [];
  liquidities?: ILiquidity[] | null;
  exitedDeals?: IExitedDeals | null;
  investment?: IInvestment[] | [];
  transaction?: ITransaction[] | [];
  transactionPage?: ITransactionPage | null;
  mapDiversification?: IDiversificationElement[] | null;
  accountValueTotal?: ITotal | null;

  loading: string;
  error?: string | null;
}

const initialState: IPortfolioState = {
  data: undefined,
  accountValue: undefined,
  accountValueTotal: undefined,
  loading: "loading",
  error: undefined,
};

interface Iparameters {
  investorID: number;
  partnerID: number;
  legalEntityID: number;
}

// change the endpoint
export const getThunkPortfolio = createAsyncThunk(
  "Portfolio/getDashboard",
  async (data: Iparameters) => {
    try {
      const response = await getAllPortfolioService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Portfolio",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkPortfolio.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getThunkPortfolio.fulfilled, (state, action) => {
      state.loading = "succeeded";

      if (action.payload.data.checkLE === false) {
        state.accountValue = [];
        state.assets = [];
        state.liquidities = [];
        state.exitedDeals = undefined;
        state.mapDiversification = [];
        // state.accountValueTotal = undefined;
      } else {
        state.accountValue = action.payload.data.accountValues;
        state.assets = action.payload.data.assets;
        state.liquidities = action.payload.data.liquidities;
        state.exitedDeals = action.payload.data.exitedDeals;
        state.mapDiversification = action.payload.data.diversifications;
        // state.accountValueTotal = action.payload.data.assetsTotal![0];
      }

      //
      //
      //
    });
    builder.addCase(getThunkPortfolio.rejected, (state, action) => {
      state.loading = "failed";

      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export const getAllPortfoliosData = (state: RootState) => {
  return state.portfolio.data;
};
// export const getAllOpportunitiesStatus = (state: RootState) =>
//   state.opportunity.loading;
// export const getAllOpportunitiesError = (state: RootState) =>
//   state.opportunity.error;
export default slice.reducer;
