import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAccountExistService } from "../../../services/multi-fund-registration/multi-fund-registration";
import { IIsAccountExistPayload } from "../../../types";
import error from "../../../utils/error";

interface IsAccountExist {
  data?: {
    account: boolean;
    isSponsor: boolean;
    isRegisteredFund: boolean;
  };
  loading: string;
  error?: string | null;
}

const initialState: IsAccountExist = {
  data: undefined,
  loading: "loading",
  error: undefined,
};

export const isAccountExistThunk = createAsyncThunk(
  "multi-fund-registration/isFundAccountExist",
  async (data: IIsAccountExistPayload) => {
    try {
      const response = await isAccountExistService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "multi-fund-registration/isFundAccountExist",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(isAccountExistThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(isAccountExistThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("IsAccount", action.payload);
      state.data = action.payload._response.data;
    });
    builder.addCase(isAccountExistThunk.rejected, (state, action) => {
      state.loading = "failed";

      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
