import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetToFundListService } from "../../../services/Dwolla/dwolla";
import error from "../../../utils/error";

const initialState: any = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};

export const GettoFundListThunk = createAsyncThunk(
  "FundTransfer/ToDropdown",
  async (data: any) => {
    try {
      const response = await GetToFundListService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "FundTransfer/ToDropdown",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GettoFundListThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GettoFundListThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data : [];
    });
    builder.addCase(GettoFundListThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
