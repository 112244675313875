import { FormikValues } from "formik";
import { Accordion, AccordionTab } from "primereact/accordion";
// import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
// import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";
import { RiDeleteBin5Fill } from "react-icons/ri";
import ConfirmDailogbox from "../../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../../components/loader/Loader";
import { deleteInvestingAccountDataThunk } from "../../../../../store/Investor/investor-approval/verify-agreement/investing-account/delete-investing-account-data/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  formatPhoneNumberExceptUS,
  formatZipCode,
  isNumberAndHypen,
  onlyCharacter,
} from "../../../../../utils/helper";
import { setCountryCode } from "../../../../../utils/investing-approval/multi-investing-account/set-formik-data";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../../utils/toast-message";

interface IProps {
  information: string;
  title: string;
  fieldNameOfNoRecord: string;
  formik: FormikValues;
  isFormFieldInvalid: any;
  getFormErrorMessage: any;
}

const MemberInformation: React.FC<IProps> = (props) => {
  const toast = useRef<Toast>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<number>(0);
  const [index, setIndex] = useState<number>(0);
  const [loader, setLoader] = useState(false);

  const dispatch = useAppDispatch();

  console.log("loader", loader);

  // const dispatch = useAppDispatch();

  const countriesDropdown = useAppSelector((state) => {
    return state.investingAccountsLookups.countriesDropdown;
  });
  const stateDropdown = useAppSelector((state) => {
    return state.investingAccountsLookups.stateDropdown;
  });

  const approveAndSaveButtonStatus = useAppSelector(
    (State) => State.getStatusAndFlag.data
  );
  // const [selectedDate, setSelectedDate] = useState("");

  const information = props.information;
  const title = props.title;
  const memberInfo = props.formik.values[props.fieldNameOfNoRecord];
  const memberInformation = props.formik.values[information];
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
  const dispalyDate = new Date(eighteenYearsAgo);

  const deleteMember = (id: number, i: number, typeOfMember: string) => {
    const data = {
      investingAccountId: 0,
      trusteeId: 0,
      llcMemberId: 0,
      partnerId: 0,
      executiveMemberId: 0,
    };

    // investingAccountId?: number;
    // trusteeId?: number;
    // llcMemberId?: number;
    // partnerId?: number;
    // executiveMemberId?: number;
    switch (typeOfMember) {
      case "memberInformation":
        data.llcMemberId = id;
        break;
      case "trusteeInfo":
        data.trusteeId = id;
        break;
      case "partnerInfo":
        data.partnerId = id;
        break;
      case "officerInfo":
        data.executiveMemberId = id;
    }

    // "trusteeId": 0,
    // "llcMemberId": 0,
    // "partnerId": 0,
    // "executiveMemberId": 0
    // const data = { llcMemberId: id };
    setLoader(true);

    dispatch(deleteInvestingAccountDataThunk(data))
      .then(() => {
        successToastMessage(toast, "Data has been delete successfully");
        memberInformation.splice(i, 1);
        props.formik.setFieldValue(`${information}`, memberInformation);

        props.formik.setFieldValue(props.fieldNameOfNoRecord, memberInfo - 1);
      })
      .catch(() => {
        errorToastMessage(
          toast,
          "Opps something went wrong. Please try again later"
        );
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const createMember = (num: number) => {
    console.log("num============", num);
    const element = [];

    for (let i = 0; i < num; i++) {
      console.log("num============", i);
      element.push(
        <Accordion activeIndex={0} className="my-3" key={i}>
          <AccordionTab header={`${title} ${i + 1} Information`}>
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="investorHeader">
                  {title} {i + 1} Information
                </h4>
              </div>
              {i !== 0 && approveAndSaveButtonStatus?.isDelFundEnrollEnabled ? (
                <div>
                  <RiDeleteBin5Fill
                    className="pointer"
                    fontSize={25}
                    color="red"
                    onClick={(e) => {
                      console.log(
                        "testing===============",
                        memberInformation[i]?.id,
                        memberInformation
                      );

                      console.log(
                        "props.formik.values.action",
                        props.formik.values.action
                      );

                      if (
                        props.formik.values.action === "Create" ||
                        !memberInformation[i]?.id
                      ) {
                        memberInformation.splice(i, 1);
                        props.formik.setFieldValue(
                          `${information}`,
                          memberInformation
                        );

                        props.formik.setFieldValue(
                          props.fieldNameOfNoRecord,
                          memberInfo - 1
                        );
                      }

                      if (
                        props.formik.values.action === "Update" &&
                        memberInformation[i]?.id
                      ) {
                        setMemberId(memberInformation[i]?.id);
                        setIndex(i);
                        setOpenDialog(true);
                        // const data = { llcMemberId: memberInformation[0].id };
                        // dispatch(deleteInvestingAccountDataThunk(data))
                        //   .then((res) => {
                        //     toast.current?.show({
                        //       severity: "success",
                        //       summary: "Success",
                        //       detail: "Data has been delete successfully",
                        //       life: 3000,
                        //     });
                        //   })
                        //   .catch(() => {
                        //     toast.current?.show({
                        //       severity: "error",
                        //       summary: "Error",
                        //       detail:
                        //         "Opps something went wrong.Please try again later",
                        //       life: 3000,
                        //     });
                        //   });
                      }
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <div className="formgrid grid">
                <div className="field col-12 md:col-6 text-left">
                  <label className="inputLabel">
                    {title} {i + 1} First Name{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    name={`${memberInformation}[${i}].firstName`}
                    value={memberInformation[i]?.firstName}
                    // unmask={true}
                    placeholder="Enter First Name"
                    onChange={(e) => {
                      memberInformation[i].firstName = onlyCharacter(
                        e.target.value
                      );
                      console.log("values", memberInformation);
                      props.formik.setFieldValue(
                        `${information}`,
                        memberInformation
                      );
                    }}
                    className={classNames({
                      "p-invalid": props.isFormFieldInvalid(
                        `${information}`,
                        i,
                        "firstName"
                      ),
                      "card-inputBox": true,
                    })}
                  />
                  {props.getFormErrorMessage(information, i, "firstName")}
                </div>
                <div className="field col-12 md:col-6 text-left">
                  <label className="inputLabel">
                    {title} {i + 1} Last Name{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    name={`${memberInformation}[${i}].lastName`}
                    value={memberInformation[i]?.lastName}
                    // unmask={true}
                    placeholder="Enter Last Name"
                    onChange={(e) => {
                      memberInformation[i].lastName = onlyCharacter(
                        e.target.value
                      );
                      // console.log("values", memberInformation);
                      props.formik.setFieldValue(
                        information,
                        memberInformation
                      );
                    }}
                    className={classNames({
                      "p-invalid": props.isFormFieldInvalid(
                        information,
                        i,
                        "lastName"
                      ),
                      "card-inputBox": true,
                    })}
                  />
                  {props.getFormErrorMessage(information, i, "lastName")}
                </div>
                {information === "officerInfo" ? (
                  <div className="field col-12 md:col-6 text-left">
                    <label className="inputLabel">{title} 1 Title</label>
                    <InputText
                      name={`memberInformation[${i}].title `}
                      value={memberInformation[i]?.title}
                      // unmask={true}
                      placeholder="Enter Title"
                      onChange={(e) => {
                        memberInformation[i].title = e.target.value;
                        console.log("values", memberInformation);
                        props.formik.setFieldValue(
                          information,
                          memberInformation
                        );
                      }}
                      className={classNames({
                        "p-invalid": props.isFormFieldInvalid(
                          information,
                          i,
                          "ssn"
                        ),
                        "card-inputBox": true,
                      })}
                    />
                    {props.getFormErrorMessage(information, i, "title")}
                  </div>
                ) : (
                  <div className="field col-12 md:col-6 text-left">
                    <label className="inputLabel">
                      {title} {i + 1} SSN
                    </label>
                    <InputMask
                      autoClear={false}
                      mask="999-99-9999"
                      // unmask={true}
                      placeholder="xxx-xx-xxxx"
                      name={`memberInformation[${i}].ssn `}
                      value={memberInformation[i]?.ssn}
                      // unmask={true}
                      onChange={(e) => {
                        memberInformation[i].ssn = e.target.value;
                        console.log("values", memberInformation);
                        props.formik.setFieldValue(
                          information,
                          memberInformation
                        );
                      }}
                      className={classNames({
                        "p-invalid": props.isFormFieldInvalid(
                          information,
                          i,
                          "ssn"
                        ),
                        "card-inputBox": true,
                      })}
                    />
                    {props.getFormErrorMessage(information, i, "ssn")}
                  </div>
                )}

                <div className="field col-12 md:col-6 text-left">
                  <label className="inputLabel">
                    {title} {i + 1} Birthdate
                  </label>

                  <Calendar
                    inputId="cal_date"
                    placeholder="mm/dd/yyyy"
                    name={`memberInformation[${i}].birthDate `}
                    value={memberInformation[i]?.birthDate}
                    maxDate={eighteenYearsAgo}
                    viewDate={dispalyDate}
                    className={classNames({
                      "p-invalid": props.isFormFieldInvalid(
                        information,
                        i,
                        "birthDate"
                      ),
                      "card-inputBox": true,
                    })}
                    onChange={(e: any) => {
                      memberInformation[i].birthDate = e.target.value;
                      console.log("values", memberInformation);
                      props.formik.setFieldValue(
                        information,
                        memberInformation
                      );
                    }}
                  />

                  {props.getFormErrorMessage("birthDate")}
                </div>

                <div className="field col-12 md:col-6 text-left">
                  <label className="inputLabel">
                    {title} {i + 1} Phone Number
                  </label>

                  <div className="p-inputgroup inputgrp-h47 flex-1">
                    <span className="p-inputgroup-addon">
                      {memberInformation[i]?.countryCode
                        ? "+" + memberInformation[i]?.countryCode
                        : ""}{" "}
                    </span>
                    {memberInformation[i]?.country !== 229 ? (
                      <InputText
                        maxLength={20}
                        value={
                          memberInformation[i]?.phoneNumber
                            ? formatPhoneNumberExceptUS(
                                memberInformation[i]?.phoneNumber,
                                memberInformation[i]?.country
                              )
                            : memberInformation[i]?.phoneNumber
                        }
                        name={`memberInformation[${i}].phoneNumber`}
                        id={`memberInformation[${i}].phoneNumber`}
                        placeholder=""
                        onChange={(e) => {
                          if (isNumberAndHypen(e.target.value)) {
                            memberInformation[i].phoneNumber =
                              formatPhoneNumberExceptUS(
                                e.target.value,
                                memberInformation[i]?.country
                              );

                            console.log("values", memberInformation);
                            props.formik.setFieldValue(
                              information,
                              memberInformation
                            );
                          }
                        }}
                        className={classNames({
                          "p-invalid": props.isFormFieldInvalid(
                            information,
                            i,
                            "phoneNumber"
                          ),
                          // "card-inputBox": true,
                          inputphoneNo: true,
                        })}
                      />
                    ) : (
                      <InputText
                        maxLength={20}
                        value={
                          memberInformation[i]?.phoneNumber
                            ? formatPhoneNumberExceptUS(
                                memberInformation[i]?.phoneNumber
                                  .split("-")
                                  .join(""),
                                memberInformation[i]?.country
                              )
                            : memberInformation[i]?.phoneNumber
                        }
                        name={`memberInformation[${i}].phoneNumber`}
                        id={`memberInformation[${i}].phoneNumber`}
                        placeholder="xxx-xxx-xxxx"
                        onChange={(e) => {
                          memberInformation[i].phoneNumber =
                            formatPhoneNumberExceptUS(
                              e.target.value,
                              memberInformation[i]?.country
                            )
                              .split("-")
                              .join("");
                          console.log("values", memberInformation);
                          props.formik.setFieldValue(
                            information,
                            memberInformation
                          );
                        }}
                        className={classNames({
                          "p-invalid": props.isFormFieldInvalid(
                            information,
                            i,
                            "phoneNumber"
                          ),
                          // "card-inputBox": true,
                          inputphoneNo: true,
                        })}
                      />
                    )}{" "}
                  </div>
                  {props.getFormErrorMessage(information, i, "phoneNumber")}
                </div>
                <div className="field col-12 md:col-6 text-left">
                  <label className="inputLabel">
                    {title} {i + 1} Email
                  </label>
                  <InputText
                    name={`memberInformation[${i}].email `}
                    value={memberInformation[i]?.email}
                    // unmask={true}
                    placeholder="Enter Email"
                    onChange={(e) => {
                      memberInformation[i].email = e.target.value?.trimStart();
                      console.log("values", memberInformation);
                      props.formik.setFieldValue(
                        information,
                        memberInformation
                      );
                    }}
                    className={classNames({
                      "p-invalid": props.isFormFieldInvalid(
                        information,
                        i,
                        "email"
                      ),
                      "card-inputBox": true,
                    })}
                  />
                  {props.getFormErrorMessage(information, i, "email")}
                </div>
                <div className="field col-12 md:col-6 text-left">
                  <label className="inputLabel">
                    {title} {i + 1} Address Line 1
                  </label>
                  <InputText
                    name={`memberInformation[${i}].addressLine1`}
                    value={memberInformation[i]?.addressLine1}
                    // unmask={true}
                    placeholder="Enter address line 1"
                    onChange={(e) => {
                      memberInformation[i].addressLine1 =
                        e.target.value?.trimStart();
                      console.log("values", memberInformation);
                      props.formik.setFieldValue(
                        information,
                        memberInformation
                      );
                    }}
                    className={classNames({
                      "p-invalid": props.isFormFieldInvalid(
                        information,
                        i,
                        "addressLine1"
                      ),
                      "card-inputBox": true,
                    })}
                  />
                  {props.getFormErrorMessage(information, i, "addressLine1")}
                </div>
                <div className="field col-12 md:col-6 text-left">
                  <label className="inputLabel">
                    {title} {i + 1} Address Line 2
                  </label>
                  <InputText
                    name={`memberInformation[${i}].addressLine2`}
                    value={memberInformation[i]?.addressLine2}
                    // unmask={true}
                    placeholder="Enter address line 2"
                    onChange={(e) => {
                      memberInformation[i].addressLine2 =
                        e.target.value?.trimStart();
                      console.log("values", memberInformation);
                      props.formik.setFieldValue(
                        information,
                        memberInformation
                      );
                    }}
                    className={classNames({
                      "p-invalid": props.isFormFieldInvalid(
                        information,
                        i,
                        "addressLine2"
                      ),
                      "card-inputBox": true,
                    })}
                  />
                  {props.getFormErrorMessage(information, i, "addressLine2")}
                </div>
                <div className="field col-12 md:col-6 text-left">
                  <label className="inputLabel">
                    {title} {i + 1} Country
                  </label>
                  <Dropdown
                    name={`memberInformation[${i}].country`}
                    value={memberInformation[i]?.country}
                    options={countriesDropdown}
                    filter
                    showOnFocus
                    optionLabel="value"
                    optionValue="key"
                    placeholder="Select Country"
                    // className="card-inputBox"
                    onChange={(e) => {
                      memberInformation[i].country = e.target.value;
                      memberInformation[i].zipCode = "";
                      console.log("values", memberInformation);
                      memberInformation[i].countryCode = setCountryCode(
                        e.target.value
                      );
                      props.formik.setFieldValue(
                        information,
                        memberInformation
                      );
                    }}
                    className={classNames({
                      "p-invalid": props.isFormFieldInvalid(
                        information,
                        i,
                        "country"
                      ),
                      "card-inputBox": true,
                    })}
                  />
                  {props.getFormErrorMessage(information, i, "country")}
                </div>
                <div className="field col-12 md:col-6 text-left">
                  <label className="inputLabel">
                    {title} {i + 1} City
                  </label>

                  <InputText
                    name={`memberInformation[${i}].city`}
                    value={memberInformation[i]?.city}
                    // unmask={true}
                    placeholder="Enter City"
                    onChange={(e) => {
                      memberInformation[i].city = onlyCharacter(e.target.value);
                      console.log("values", memberInformation);
                      props.formik.setFieldValue(
                        information,
                        memberInformation
                      );
                    }}
                    className={classNames({
                      "p-invalid": props.isFormFieldInvalid(
                        information,
                        i,
                        "city"
                      ),
                      "card-inputBox": true,
                    })}
                  />
                  {props.getFormErrorMessage(information, i, "city")}
                </div>
                <div className="field col-12 md:col-6 text-left">
                  <label className="inputLabel">
                    {title} {i + 1} State
                  </label>
                  {memberInformation[i]?.country !== 229 ? (
                    <InputText
                      name={`memberInformation[${i}].state`}
                      value={memberInformation[i]?.state}
                      placeholder="Enter your state"
                      onChange={(e) => {
                        memberInformation[i].state = onlyCharacter(
                          e.target.value
                        );
                        props.formik.setFieldValue(
                          information,
                          memberInformation
                        );
                      }}
                      className={classNames({
                        "p-invalid": props.isFormFieldInvalid(
                          information,
                          i,
                          "state"
                        ),
                        "card-inputBox": true,
                      })}
                    />
                  ) : (
                    <Dropdown
                      name={`memberInformation[${i}].state`}
                      value={memberInformation[i]?.state}
                      options={stateDropdown}
                      filter
                      showOnFocus
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select Your State"
                      // className="card-inputBox"
                      onChange={(e) => {
                        memberInformation[i].state = e.target.value;
                        console.log("values", memberInformation);
                        props.formik.setFieldValue(
                          information,
                          memberInformation
                        );
                      }}
                      className={classNames({
                        "p-invalid": props.isFormFieldInvalid(
                          information,
                          i,
                          "state"
                        ),
                        "card-inputBox": true,
                      })}
                    />
                  )}

                  {props.getFormErrorMessage(information, i, "state")}
                </div>
                <div className="field col-12 md:col-6 text-left">
                  <label className="inputLabel">
                    {title} {i + 1} ZIP Code
                  </label>
                  <InputText
                    name={`memberInformation[${i}].zipCode`}
                    value={memberInformation[i]?.zipCode}
                    // unmask={true}
                    placeholder="Enter ZIP Code"
                    maxLength={+memberInformation[i]?.country === 229 ? 10 : 20}
                    onChange={(e) => {
                      memberInformation[i].zipCode = formatZipCode(
                        e.target.value,
                        memberInformation[i]?.country
                      );
                      console.log("values", memberInformation);
                      props.formik.setFieldValue(
                        information,
                        memberInformation
                      );
                    }}
                    className={classNames({
                      "p-invalid": props.isFormFieldInvalid(
                        information,
                        i,
                        "zipCode"
                      ),
                      "card-inputBox": true,
                    })}
                  />
                  {props.getFormErrorMessage(information, i, "zipCode")}
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      );
    }
    return element;
  };
  console.log("memberInfo", memberInfo);

  const onCancel = () => {
    setOpenDialog(false);
  };

  const onConfirm = () => {
    setOpenDialog(false);
    setLoader(true);
    deleteMember(memberId, index, information);
  };

  return (
    <>
      {createMember(memberInfo)}
      <Toast ref={toast} className="themeToast" />
      {loader && <Loader />}
      {/* ConfirmDailogbox */}
      {openDialog && (
        <ConfirmDailogbox
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          onConfirm={onConfirm}
          onCancel={onCancel}
          textContent="Are you sure you want to delete investing account"
        />
      )}
    </>
  );
};

export default MemberInformation;
