import { FieldArray, Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
// import { RiAddFill } from "react-icons/ri";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
import ConfirmDailogbox from "../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import InputNo from "../../../components/input/InputNo";
import Loader from "../../../components/loader/Loader";
import {
  addUpdateManagerFeesConfigThunk,
  deleteManagerFeesConfigByIdThunk,
  getManagerFeesConfigByIdPoolfundsThunk,
  managerFeesActions,
} from "../../../store/ManagerFees";
import { getManagerFeesTypesThunk } from "../../../store/ManagerFees/manager-fees-type";
import { getThunkInvestmentIdByProduct } from "../../../store/features/operation/allocate-earning/get-investment-id-product/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getThunkAllInvestmentDealPooledFundByLegalEntityId } from "../../../store/poolfunds/get-investmentdeal-for-pooledfund";
import { ManagerFeesType } from "../../../types";
import currencyFormat from "../../../utils/currency-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
type managerFeesType = {
  id: number;
  investmentId?: number;
  lowerFundingAmount: number;
  upperFundingAmount: number;
  preSetPercentage: number;
  feesType: number;
  fixedAmount: number;
};
const ManagerFees: React.FC = () => {
  const toast = useRef<Toast>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [add, setAdd] = useState(false);
  const [showTab, setShowTab] = useState(true);
  const [edit, setEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState<number>(0);
  const [investmentId, setInvestmentId] = useState<number>(0);
  const [managerfeesType, setManagerfeesType] = useState<any>();
  const [disableLowerAmount, setDisableLowerAmount] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState({
    id: 0,
    lowerFundingAmount: "",
    upperFundingAmount: "",
    preSetPercentage: 0,
    fixedAmount: 0,
  });
  const [openConfirmBox, setOpenConfirmBox] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const investmentList = useAppSelector(
    (state) => state.getAllInvestorDealListPoolFunds.data.investments
  );
  const managerFees: any = useAppSelector(
    (state) => state.managerFees.data.output
  );
  const managerFeesType = useAppSelector(
    (state) => state.managerFeesTypes.data
  );
  useEffect(() => {
    console.log("datattt", showTab);
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      setLoading(true);
      // console.log("inside");

      const parameter = {
        legalEntityId: fundDropdown.fundid,
      };
      dispatch(
        getThunkAllInvestmentDealPooledFundByLegalEntityId(parameter)
      ).finally(() => {
        setLoading(false);
      });
    }
  }, [fundDropdown.fundid]);
  useEffect(() => {
    if (managerFees && managerFees.length > 0) {
      setInitialValues((prevState) => {
        return {
          ...prevState,
          lowerFundingAmount:
            managerFees[managerFees.length - 1].upperFundingAmount,
        };
      });
      setDisableLowerAmount(
        !!managerFees[managerFees.length - 1].upperFundingAmount
      );
    } else {
      setDisableLowerAmount(false);
    }
  }, [managerFees]);
  const dropDownList: any = useMemo(
    () =>
      investmentList!.length > 0
        ? investmentList?.map((obj: any) => {
            return { name: obj.productName, code: obj.productID };
          })
        : [],
    [investmentList]
  );
  const managerFeesTypeList = useMemo(
    () =>
      managerFeesType!.length > 0
        ? managerFeesType.map((obj: any) => {
            return { name: obj.value, code: obj.key };
          })
        : [],
    [managerFeesType]
  );
  // const onAddClick = (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  //   setFieldValue: any,
  //   resetForm: any
  // ) => {
  //   resetForm();
  //   setFieldValue("productID", productId);
  //   setAdd(true);
  //   setEdit(false);
  //   setShowTab(true);
  // };

  useEffect(() => {
    setAdd(false);
    setEdit(false);
    setShowTab(true);
    setManagerfeesType("");
    setInvestmentId(0);
    setProductId(0);
    setInitialValues({
      id: 0,
      lowerFundingAmount: "",
      upperFundingAmount: "",
      preSetPercentage: 0,
      fixedAmount: 0,
    });
    dispatch(managerFeesActions.resetManagerFees());
    dispatch(getManagerFeesTypesThunk());
  }, [fundDropdown]);

  useEffect(() => {
    if (fundDropdown.fundid && productId && managerfeesType) {
      setLoading(true);
      const param = { productId };
      dispatch(getThunkInvestmentIdByProduct(param))
        .then((response) => {
          if (
            response.payload._response.data !== null &&
            response.payload._response.data.externalInvestmentId.key !== 0 &&
            response.payload._response.data.externalInvestmentId !== 0
          ) {
            setInvestmentId(
              response.payload._response.data.externalInvestmentId.key
            );
            dispatch(
              getManagerFeesConfigByIdPoolfundsThunk({
                id: 0,
                investmentId:
                  response.payload._response.data.externalInvestmentId.key,
                managerFeesType: managerfeesType,
              })
            ).finally(() => setLoading(false));
          } else {
            setInvestmentId(0);
            errorToastMessage(toast, "Investment Id is not found");
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setInvestmentId(0);
          console.log("error", error);
        });
    }
  }, [productId, managerfeesType]);

  useEffect(() => {
    console.log("searchParams", searchParams.getAll, location.search);
    const pId = searchParams.get("productId");
    const feeType = searchParams.get("feeType");
    if (pId && feeType) {
      setProductId(+pId);
      setManagerfeesType(+feeType);
    }
  }, [searchParams.get("productId"), searchParams.get("feeType")]);

  const onSubmit = (data: any, resetForm: any) => {
    if (!managerfeesType) {
      errorToastMessage(toast, "Please select Fees Type");
      return;
    }
    setLoading(true);
    console.log("data", data);

    const managerFeeData = data.managerfees?.map((obj: managerFeesType) => {
      console.log("onb", obj);
      return {
        ...obj,
        feesType: managerfeesType,
        investmentId,
      };
    });
    console.log("dadaadda", data, "managerFeeData", managerFeeData);
    // delete data.productID;
    dispatch(addUpdateManagerFeesConfigThunk(managerFeeData))
      .then((response) => {
        if (response.payload.success) {
          successToastMessage(
            toast,
            response.payload.message || "Successfully added Manager Fees."
          );
          // setProductId(0);
          setAdd(false);
          setEdit(false);
          setShowTab(true);
          setLoading(false);
          resetForm();
        } else {
          setLoading(false);
          errorToastMessage(
            toast,
            response.payload.message || "Failed to add Manager Fees."
          );
        }
        console.log("reess", response);
      })
      .catch((error) => {
        setLoading(false);
        errorToastMessage(toast, "Server Error");
        console.log("err", error);
      });
  };

  const deleteButton = (rowData: ManagerFeesType) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          className="btn-nav"
          title="Delete"
          onClick={(e) => {
            e.preventDefault();
            setDeleteId(rowData.id);
            setOpenConfirmBox(true);
          }}
        />
      </>
    );
  };

  const dataTableElements = [
    {
      field: "investmentId",
      header: "Investment Id",
    },
    {
      field: "lowerFundingAmount",
      header: "Lower Funding Amount",
      body: (rowData: ManagerFeesType) =>
        currencyFormat(rowData.lowerFundingAmount),
    },
    {
      field: "upperFundingAmount",
      header: "Upper Funding Amount",
      body: (rowData: ManagerFeesType) =>
        currencyFormat(rowData.upperFundingAmount),
    },
    {
      field: "preSetPercentage",
      header: "Pre Set Percentage",
      body: (rowData: ManagerFeesType) => `${rowData.preSetPercentage}%`,
    },
    {
      field: "feesType",
      header: "Fees Type",
    },
    {
      field: "fixedAmount",
      header: "Fixed Amount",
      body: (rowData: ManagerFeesType) => currencyFormat(rowData.fixedAmount),
    },
    { header: "Delete", body: deleteButton },
  ];
  const checkErrorObj = (feeErrors: any) => {
    let count = 0;
    feeErrors.map((obj: any) => {
      if (Object.entries(obj).length > 0) {
        count++;
      }
      return obj;
    });
    return count;
  };
  const handleDeleteFees = () => {
    if (!deleteId) {
      setLoading(false);
      setDeleteId(0);
      setOpenConfirmBox(false);
      return;
    }
    setLoading(true);
    dispatch(deleteManagerFeesConfigByIdThunk(deleteId))
      .then((response) => {
        response.payload.success
          ? successToastMessage(
              toast,
              response.payload.message ||
                "Manager Fees Config Deleted Succsessfully"
            )
          : errorToastMessage(
              toast,
              response.payload.message || "Something went wrong!"
            );
        setDeleteId(0);
        setOpenConfirmBox(false);
        dispatch(
          getManagerFeesConfigByIdPoolfundsThunk({
            id: 0,
            investmentId,
            managerFeesType: managerfeesType,
          })
        ).finally(() => setLoading(false));
      })
      .catch((error) => {
        errorToastMessage(toast);
        console.log(error);
        setLoading(false);
      });
  };
  const onDialogCancel = (payload: any) => {
    setOpenConfirmBox(payload);
    setDeleteId(0);
  };
  return (
    <>
      {loading && <Loader />}
      <Toast ref={toast} className="themeToast" />
      {openConfirmBox && (
        <ConfirmDailogbox
          textContent="Are you sure you want to delete this?"
          onCancel={onDialogCancel}
          onConfirm={() => handleDeleteFees()}
          setOpenDialog={onDialogCancel}
          openDialog={openConfirmBox}
        />
      )}
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Setup Manager Fees</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <div className="grid">
        <div className="grid pt-0 col-12">
          <div className="col-12 pt-0 pr-0">
            <div className="card my-3 tabWithoutboxshadow">
              <Card>
                <Formik
                  initialValues={{
                    managerfees: edit ? managerFees : [{ ...initialValues }],
                  }}
                  validate={(data) => {
                    type errorType = {
                      managerfees: managerFeesType[];
                    };
                    const errorsObj: errorType = {
                      managerfees: [],
                    };

                    for (const iterator of data.managerfees) {
                      console.log("iterator", iterator);
                      const obj: any = {};
                      if (
                        iterator.lowerFundingAmount === "" ||
                        iterator.lowerFundingAmount === null ||
                        iterator.lowerFundingAmount === undefined
                      ) {
                        obj.lowerFundingAmount = "This field is required.";
                      }
                      if (
                        iterator.upperFundingAmount === "" ||
                        iterator.upperFundingAmount === null ||
                        iterator.upperFundingAmount === undefined
                      ) {
                        obj.upperFundingAmount = "This field is required.";
                      }
                      if (
                        iterator.upperFundingAmount !== "" &&
                        iterator.lowerFundingAmount >
                          iterator.upperFundingAmount
                      ) {
                        obj.upperFundingAmount =
                          "Value should be greater than  Lower Funding amount";
                      }
                      if (
                        iterator.preSetPercentage === "" ||
                        iterator.preSetPercentage === null ||
                        iterator.preSetPercentage === undefined
                      ) {
                        obj.preSetPercentage = "This field is required.";
                      }
                      // if (
                      //   iterator.feesType === "" ||
                      //   iterator.feesType === null ||
                      //   iterator.feesType === undefined
                      // ) {
                      //   obj.feesType = "This field is required.";
                      // }
                      if (
                        iterator.fixedAmount === "" ||
                        iterator.fixedAmount === null ||
                        iterator.fixedAmount === undefined
                      ) {
                        obj.fixedAmount = "This field is required.";
                      }
                      // if (Object.entries(obj).length > 0)
                      errorsObj.managerfees.push(obj);
                    }
                    console.log(
                      "errors",
                      Object.entries(errorsObj),
                      checkErrorObj(errorsObj.managerfees)
                    );

                    return checkErrorObj(errorsObj.managerfees) > 0
                      ? errorsObj
                      : {};
                  }}
                  onSubmit={(values, { resetForm }) =>
                    onSubmit(values, resetForm)
                  }
                  enableReinitialize
                  render={({
                    values,
                    setFieldValue,
                    resetForm,
                    handleSubmit,
                    errors,
                    touched,
                  }) => (
                    <Form>
                      <div className="formgrid grid px-2">
                        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                          <label className="inputLabel">Investment*</label>
                          <Dropdown
                            inputId="productID"
                            name="productID"
                            value={productId}
                            options={dropDownList === null ? [] : dropDownList}
                            filter
                            optionLabel="name"
                            optionValue="code"
                            resetFilterOnHide={true}
                            showFilterClear={true}
                            placeholder="Select Investment"
                            onChange={(e) => {
                              console.log("id", e.target.value);
                              setProductId(e.target.value);
                              resetForm();
                              setFieldValue("productID", e.target.value);
                              setAdd(false);
                              setEdit(false);
                              setShowTab(true);
                              setInitialValues({
                                id: 0,
                                lowerFundingAmount: "",
                                upperFundingAmount: "",
                                preSetPercentage: 0,
                                fixedAmount: 0,
                              });
                            }}
                            className={classNames("custom-dropdown", {
                              // "p-invalid": isFormFieldInvalid("productID"),
                              "card-inputBox": true,
                            })}
                          />
                        </div>{" "}
                        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                          <label className="inputLabel">Fees Type*</label>
                          <Dropdown
                            inputId="managerfeesType"
                            name="managerfeesType"
                            value={managerfeesType}
                            options={managerFeesTypeList || []}
                            filter
                            optionLabel="name"
                            optionValue="code"
                            resetFilterOnHide={true}
                            showFilterClear={true}
                            placeholder="Select Fees Type"
                            onChange={(e: any) => {
                              setManagerfeesType(e.target.value);
                              setAdd(true);
                              setEdit(false);
                              setShowTab(true);
                              setInitialValues({
                                id: 0,
                                lowerFundingAmount: "",
                                upperFundingAmount: "",
                                preSetPercentage: 0,
                                fixedAmount: 0,
                              });
                            }}
                            className={classNames("custom-dropdown", {
                              // "p-invalid": isFormFieldInvalid("productID"),
                              "card-inputBox": true,
                            })}
                          />
                        </div>{" "}
                        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                          {!add ||
                            (edit && (
                              <div className="flex justify-content-end"></div>
                            ))}
                          {/* {showTab && (
                            <div className="tabHeader-wrap flex justify-content-end">
                              <Button
                                type="button"
                                onClick={(e) =>
                                  onAddClick(e, setFieldValue, resetForm)
                                }
                                className="btn-navActive mr-0 m-2"
                              >
                                <span className="ml-auto mr-auto ">
                                  Add Manager Fees
                                  <span className="pl-2">
                                    <RiAddFill />
                                  </span>
                                </span>
                              </Button>
                            </div>
                          )} */}
                          {edit && (
                            <div className=" tabHeader-wrap flex">
                              <Button className="tabHeadText mt-3 Active">
                                Edit Manager Fees
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>

                      {add || edit ? (
                        <>
                          <div className="mt-2">
                            <div className="d-flex flex-column p-2 col-12 sm:col-12 ">
                              <label className="inputLabel">
                                Define separate fees for various funding amount
                                ranges
                              </label>
                            </div>
                          </div>
                          <div className="mb-4 mt-2">
                            <div className="formgrid grid px-2 align-items-baseline">
                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                                <label className="inputLabel">
                                  Lower Range
                                </label>
                              </div>
                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                                <label className="inputLabel">
                                  Upper Range
                                </label>
                              </div>
                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                                <label className="inputLabel">
                                  Pre Set Percentage
                                </label>
                              </div>
                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                                <label className="inputLabel">
                                  Fixed Amount
                                </label>
                              </div>
                            </div>
                            <FieldArray
                              name="managerfees"
                              render={(arrayHelpers) => (
                                <>
                                  {values.managerfees &&
                                  values.managerfees.length > 0 ? (
                                    values.managerfees.map(
                                      (fees: any, index: any) => (
                                        <div
                                          className="formgrid grid px-2 align-items-baseline"
                                          key={index}
                                        >
                                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                                            <InputNo
                                              name={`managerfees[${index}].lowerFundingAmount`}
                                              value={
                                                index === 0
                                                  ? fees.lowerFundingAmount !==
                                                    ""
                                                    ? fees.lowerFundingAmount
                                                    : undefined
                                                  : values.managerfees?.[
                                                      index - 1
                                                    ]?.upperFundingAmount
                                              }
                                              disabled={
                                                index === 0
                                                  ? disableLowerAmount
                                                  : values.managerfees[
                                                      index - 1
                                                    ]?.upperFundingAmount
                                              }
                                              placeholder="Lower Funding Amount"
                                              min={0}
                                            />
                                          </div>
                                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                                            <InputNo
                                              name={`managerfees[${index}].upperFundingAmount`}
                                              value={
                                                fees.upperFundingAmount !== ""
                                                  ? fees.upperFundingAmount
                                                  : undefined
                                              }
                                              placeholder="Upper Funding Amount"
                                              min={0}
                                            />
                                          </div>

                                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                                            <InputNo
                                              name={`managerfees[${index}].preSetPercentage`}
                                              value={
                                                fees.preSetPercentage !== ""
                                                  ? fees.preSetPercentage
                                                  : undefined
                                              }
                                              handleChangeInput={() => {
                                                setFieldValue(
                                                  `managerfees[${index}].fixedAmount`,
                                                  0
                                                );
                                              }}
                                              minFractionDigits={2}
                                              placeholder="Pre Set Percentage"
                                              min={0}
                                            />
                                          </div>

                                          <div
                                            className={`d-block sm:d-flex sm:flex-column p-2 col-12 ${
                                              edit ? "sm:col-3" : "sm:col-2 "
                                            } annualClass`}
                                          >
                                            <InputNo
                                              name={`managerfees[${index}].fixedAmount`}
                                              value={
                                                fees.fixedAmount !== ""
                                                  ? fees.fixedAmount
                                                  : undefined
                                              }
                                              handleChangeInput={() => {
                                                setFieldValue(
                                                  `managerfees[${index}].preSetPercentage`,
                                                  0
                                                );
                                              }}
                                              placeholder="Fixed amount"
                                              min={0}
                                            />
                                          </div>
                                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-1 annualClass">
                                            <span className="d-flex">
                                              {" "}
                                              {add && (
                                                <Button
                                                  className="btn-navActive"
                                                  icon="pi pi-plus"
                                                  title="Add"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    arrayHelpers.push({
                                                      id: 0,
                                                      lowerFundingAmount: "",
                                                      upperFundingAmount: "",
                                                      preSetPercentage: 0,
                                                      fixedAmount: 0,
                                                    });
                                                  }}
                                                />
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            />
                          </div>
                          <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
                            <div className="d-flex flex-column p-2 col-12 sm:col-2 ">
                              <Button
                                className="btn-nav "
                                onClick={(e) => {
                                  e.preventDefault();
                                  resetForm();
                                  setProductId(0);
                                  resetForm();
                                  setFieldValue("productID", 0);
                                  setManagerfeesType("");
                                  setEdit(false);
                                  setShowTab(true);
                                  setAdd(false);
                                  dispatch(
                                    managerFeesActions.resetManagerFees()
                                  );
                                }}
                              >
                                <span className="ml-auto mr-auto ">Cancel</span>
                              </Button>
                            </div>
                            <div className="d-flex flex-column p-2 col-12 sm:col-2 ">
                              <Button
                                className="btn-navActive "
                                type="submit"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleSubmit();
                                }}
                              >
                                <span className="ml-auto mr-auto ">Save</span>
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Form>
                  )}
                />
                {/* kk */}
                <div className="formgrid grid px-2">
                  <div className="d-flex flex-column p-2 col-12 sm:col-10"></div>
                  <div className="d-flex flex-column p-2 col-12 sm:col-2">
                    {managerFees.length > 0 && (
                      <Button
                        className="blueButtonShadow"
                        type="button"
                        icon="pi pi-file"
                        // rounded
                        onClick={() => {
                          setAdd(false);
                          setEdit(true);
                          setShowTab(false);
                        }}
                      >
                        <span className="blueButtonShadowText">Edit </span>
                      </Button>
                    )}
                  </div>
                </div>

                <div className="mt-2 col-12">
                  <DataTableComponent
                    valueData={managerFees || []}
                    fieldsElements={dataTableElements}
                    isPaginationAllowed={true}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerFees;
