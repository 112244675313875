import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";

// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";
import DataTableComponent from "../../../components/data-table/DataTableComponent";

import Loader from "../../../components/loader/Loader";
import { AssignExpensesToInvThunk } from "../../../store/features/operation/distributeexpensestoinvestors/assignexpensestoinv/slice";
import { GetProRataListThunk } from "../../../store/features/operation/distributeexpensestoinvestors/getproratalist/slice";
import { GetReverseAllocationThunk } from "../../../store/features/operation/distributeexpensestoinvestors/getreverseallocation/slice";
import { SaveNotesForExpenseThunk } from "../../../store/features/operation/distributeexpensestoinvestors/savenotesforexpense/slice";
import { GetcalculateExpensesThunk } from "../../../store/features/taxmanagement/fundexpensesproratacalculation/getcalculateexpenses/slice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import currencyFormat from "../../../utils/currency-formatter";
import {
  convertLocalDateToUTC,
  convertUTCToLocalDate,
  formatDateMMDDYYY,
  getListDateConversion,
  // convertUTCToLocalDate,
} from "../../../utils/date-formatter";
import { exportExcel } from "../../../utils/excel-dataformat";
import { successToastMessage } from "../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function DistributeExpensesInvestors() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const accountValue = useAppSelector((state) => state.GetProRataList?.data);
  const calculateValue = useAppSelector(
    (state) => state.GetcalculateExpenses?.data
  );

  console.log("accountValue", accountValue);

  const [visible, setVisible] = useState<any>(false);
  const [distributeVisible, setDistributeVisible] = useState<any>(false);
  const [reverseVisible, setReverseVisible] = useState<any>(false);
  const [reversealert, setReverseAlert] = useState<any>(false);
  const [loading, setloading] = useState(false);
  const [allocated, setAllocated] = useState<any>();
  const [yearMonth, setYearMonth] = useState<any>();
  const [openDailog, setOpenDailog] = useState(false);

  const formik = useFormik({
    initialValues: {
      Date: "",
      Investor: "",
      Notes: "",
      TransactionDate: "",
      AllocationDate: "",
    },
    validate: (data: any) => {
      const errors: any = {};
      console.log("data", data);

      if (!data.Notes) {
        errors.Notes = "Please select Notes";
      }
      if (!data.TransactionDate) {
        errors.TransactionDate = "Please enter transaction date";
      }
      if (!data.AllocationDate) {
        errors.AllocationDate = "Please enter allocation date";
      } else if (
        data.AllocationDate === "Invalid Date" ||
        Number.isNaN(Date.parse(data.AllocationDate))
      ) {
        errors.AllocationDate = "Please enter allocation date";
      }
      return errors;
    },
    onSubmit: async (data: any) => {
      setDistributeVisible(true);
    },
  });

  useEffect(() => {
    if (fundDropdown.fundid) {
      setloading(true);
      const req = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetProRataListThunk(req))
        .then((res: any) => {})
        .finally(() => {
          setloading(false);
        });
    }
  }, [fundDropdown]);

  const reverseButton = (rowData: any) => {
    // Pass rowData as a parameter
    // setYearMonth(rowData.yearMonth);
    // setAllocated(rowData.allocatedDate);

    const handleReverse = (i: any) => {
      console.log("alllll===", rowData.yearMonth);
      // setExpenses(rowData.monthlyExpenses);
      setYearMonth(rowData.yearMonth);
      setAllocated(rowData.allocatedDate);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog  align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={() => {
              // setId(rowData.ID);
              handleReverse(rowData.Id);
              console.log("Alocate", rowData);
              !rowData.isAllocated
                ? setReverseAlert(true)
                : setReverseVisible(true);
            }}
          >
            <span className="text-white">Reverse Allocation</span>
          </Button>
        </div>
      </div>
    );
  };
  const reverseDate = (inputDate: any) => {
    const dateParts = inputDate && inputDate?.split("/");
    if (dateParts?.length === 3) {
      const [day, month, year] = dateParts;
      const reversedDate = `${year}/${month}/${day}`;
      return reversedDate;
    } else {
      return "Invalid date format";
    }
  };

  const [expenceAllocate, setExpenceAllocate] = useState<number | null>();
  const [perTotDAV, setPerTotDAV] = useState<number | null>();

  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const [notesValue, setNotesValue] = useState(rowData.notes);
    const [activeIndices, setActiveIndices] = useState<number[]>([]);
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
      setNotesValue(rowData.notes);
    }, [rowData.notes]);

    const handleOkClick = () => {
      setloading(true);

      const reqBody = {
        Notes: notesValue,
        Year: rowData.yearMonth,
        LegalEntityId: fundDropdown.fundid, // Assuming fundDropdown is defined somewhere
        YearMonth: rowData.yearMonth,
      };

      dispatch(SaveNotesForExpenseThunk(reqBody))
        .then(() => {
          setloading(false);
          const r = {
            LegalEntityId: fundDropdown.fundid,
          };
          dispatch(GetProRataListThunk(r)).then((res: any) => {
            // const data = res.payload ? res.payload._response.data : [];

            console.log("formik", formik.values);
          });
          // Handle any additional logic after dispatching the action
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    };

    const handleCancelClick = () => {
      // Reset the input value to the original rowData.notes
      setNotesValue(rowData.notes);
      console.log("inside cancel");
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      // Update the state with the new input value
      setNotesValue(e.target.value);
      setIsTyping(true);
      const currentIndex = rowData.index;

      if (!activeIndices.includes(currentIndex)) {
        setActiveIndices([...activeIndices, currentIndex]);
      }
    };
    const handleCellClick = () => {
      // Set isTyping to false when another cell is clicked
      setIsTyping(false);
    };

    const isCurrentInputActive = activeIndices?.includes(rowData.index);

    return (
      <div>
        <div
          className={isTyping && isCurrentInputActive ? "NotesCellWithBtn" : ""}
          onClick={handleCellClick}
        >
          <div>
            {" "}
            <InputText
              type="text"
              value={notesValue}
              onChange={handleInputChange}
              // onFocus={() => setIsTyping(false)}
            />
          </div>
          <>
            {" "}
            {isTyping && isCurrentInputActive && (
              <div className="d-flex justify-content-center mt-2">
                <Button
                  label="Save"
                  className="btn-dialog align-items-center justify-content-center mx-1"
                  onClick={handleOkClick}
                />
                <Button
                  label="Cancel"
                  className="btn-dialog align-items-center justify-content-center mx-1"
                  onClick={handleCancelClick}
                />
              </div>
            )}
          </>
        </div>
      </div>
    );
  };

  const calculateButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleReverse = (i: any) => {
      setloading(true);
      console.log("alllll===", rowData.yearMonth);
      // setExpenses(rowData.monthlyExpenses);
      setYearMonth(rowData.yearMonth);
      setAllocated(rowData.allocatedDate);
      formik.setFieldValue("Notes", rowData.notes);
      formik.setFieldValue("AllocationDate", rowData.allocatedDate);

      const reqBody = {
        LegalEntityId: fundDropdown.fundid,
        Year: rowData.yearMonth,
      };
      dispatch(GetcalculateExpensesThunk(reqBody))
        .then((res: any) => {
          // console.log(
          //   "akjahsdkjakjdhakhsdkjahsdkahsdkahsdkhadhakshdakhs",
          //   res.payload._response.data
          // );
          const sumVal = res.payload._response.data
            ? res.payload._response.data?.reduce(
                (sum: number, item: any) => sum + item.expenceAllocate,
                0
              )
            : 0;
          const sumVal2 = res.payload._response.data
            ? res.payload._response.data?.reduce(
                (sum: number, item: any) => sum + item.perTotDAV,
                0
              )
            : 0;
          setExpenceAllocate(sumVal);
          setPerTotDAV(sumVal2);
          setVisible(true);
        })
        .finally(() => {
          setloading(false);
        });
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog widthdeletebtn align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={() => {
              handleReverse(rowData.Id);
              formik.setFieldValue(
                "AllocationDate",
                new Date(
                  reverseDate(convertUTCToLocalDate(rowData.allocatedDate))
                )
              );
            }}
          >
            <span className="text-white">Calculate</span>
          </Button>
        </div>
      </div>
    );
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  // const dateFilter = (options: any) => {
  //   return (
  //     <Calendar
  //       value={options.value}
  //       onChange={(e) => options.filterCallback(e.value, options.index)}
  //       view="year"
  //       dateFormat="yy"
  //       placeholder="yyyy"
  //     />
  //   );
  // };

  const dataTableElements = [
    {
      field: "legalEntityName",
      header: "Fund",
      style: { minWidth: "230px" },
    },
    {
      field: "monthlyExpenses",
      header: "Monthly Expenses",
      body: (rowData: any) => currencyFormat(rowData.monthlyExpenses),
      style: { minWidth: "230px" },
    },
    {
      field: "yearMonth",
      header: "Year Month",
      style: { minWidth: "230px" },

      // dataType: "date",
      // body: formatDate,
      // filterElement: dateFilter,
    },
    {
      field: "allocatedDate",
      header: "Allocation Date",
      dataType: "date",
      body: (rowData: any) => formatDateMMDDYYY(rowData.allocatedDate), // Pass rowData to editButton
      filterElement: dateFilterTemplate,
      style: { minWidth: "230px" },
    },
    {
      field: "notes",
      header: "Notes",
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
      style: { minWidth: "270px" },
    },
    {
      field: "",
      header: "",
      body: (rowData: any) => calculateButton(rowData), // Pass rowData to editButton
      style: { minWidth: "180px" },
    },
    {
      field: "",
      header: "",
      body: (rowData: any) => reverseButton(rowData), // Pass rowData to editButton
      style: { minWidth: "180px" },
    },
  ];
  const exportExcelHeader = [
    {
      field: "legalEntityName",
      header: "Fund",
    },
    {
      field: "monthlyExpenses",
      header: "Monthly Expenses",
      dataType: "currency",
    },
    {
      field: "yearMonth",
      header: "Year Month",
    },
    {
      field: "allocatedDate",
      header: "Allocation Date",
      dataType: "date",
    },
    {
      field: "notes",
      header: "Notes",
    },
  ];

  const dataTableElementsCalculate = [
    {
      field: "invName",
      header: "Investor Account",
    },
    {
      field: "avgDAV",
      header: "Average DAV",
      body: (rowData: any) => currencyFormat(rowData.avgDAV),
    },
    {
      field: "perTotDAV",
      header: "Pro Rata %",
    },
    {
      field: "expenceAllocate",
      header: "Pro Rata Expense",
      body: (rowData: any) => currencyFormat(rowData.expenceAllocate),
    },
  ];

  //   const resetForm = () => {
  //     setName("");
  //   };
  const [tableData, setTableData] = useState<any>();

  console.log("tableData", tableData);

  useEffect(() => {
    if (accountValue) {
      setTableData(getListDateConversion(accountValue, ["allocatedDate"]));
    }
  }, [accountValue]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onReverse = () => {
    setloading(true);
    const req = {
      LegalEntityId: fundDropdown.fundid,
      YearMonth: yearMonth,
      EntityId: fundDropdown.fundid,
    };

    console.log("inside edit");
    dispatch(GetReverseAllocationThunk(req)).then(() => {
      setloading(false);

      const r = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetProRataListThunk(r)).then((res: any) => {
        // const data = res.payload ? res.payload._response.data : [];
        successToastMessage(toast, "Data is reversed successfully");

        console.log("formik", formik.values);
      });
      setReverseVisible(false);
    });
  };

  const onDistribution = () => {
    const transactionDate = convertLocalDateToUTC(
      formik.values.TransactionDate
    );
    const allocatedDate = convertLocalDateToUTC(formik.values.AllocationDate);
    setloading(true);
    const req = {
      // action: "",
      year: yearMonth,
      legalEntityId: fundDropdown.fundid,
      allocatedDate,
      // PartnerId: profile.investorID,
      // monthlyExpenses: expenses,
      // yearMonth,
      transactionDate,
      notes: formik.values.Notes,
    };
    dispatch(AssignExpensesToInvThunk(req))
      .then(() => {
        const r = {
          LegalEntityId: fundDropdown.fundid,
        };
        dispatch(GetProRataListThunk(r));
      })
      .finally(() => {
        setloading(false);
      });
    setDistributeVisible(false);
    setVisible(false);
  };
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={2} />
        <Column footer={perTotDAV ? currencyFormat(perTotDAV) : ""} />
        <Column
          footer={expenceAllocate ? currencyFormat(expenceAllocate) : ""}
        />
      </Row>
    </ColumnGroup>
  );

  return (
    <>
      {loading && <Loader />}
      <div className="grid">
        <div className="flex justify-content-between col-12 ">
          <div className=" pgHeaderWrap">
            <h1 className="pageHeader fs-2">
              Distribute Expenses to Investors
            </h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className=" col-12 md:col-3 flex  gap-2 justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              onClick={() =>
                exportExcel(
                  exportExcelHeader,
                  tableData,
                  "Distribute Expenses to Investors",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                tableData === undefined ||
                tableData?.length === 0 ||
                tableData === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  tableData === undefined ||
                  tableData?.length === 0 ||
                  tableData === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        <Dialog
          visible={visible}
          className="w-90"
          style={{ overflowX: "auto" }}
          onHide={() => {
            setVisible(false);
            formik.resetForm();
          }}
        >
          <div className="col-12">
            <div
              className="formgrid grid px-2 justify-content-center"
              style={{ overflowX: "auto" }}
            >
              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
                <label className="inputLabel">Notes*: </label>

                <InputText
                  name="Notes"
                  value={formik.values.Notes}
                  onChange={(e) => {
                    formik.setFieldValue("Notes", e.target.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Notes"),
                    "card-inputBox": true,
                  })}
                />
                {getFormErrorMessage("Notes")}
              </div>
              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
                <label className="inputLabel">Transaction Date *</label>

                <Calendar
                  name="TransactionDate"
                  value={formik.values.TransactionDate}
                  onChange={(e) => {
                    formik.setFieldValue("TransactionDate", e.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("TransactionDate"),
                    "card-inputBox": true,
                  })}
                  dateFormat="mm/dd/yy"
                />
                {getFormErrorMessage("TransactionDate")}
              </div>
              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
                <label className="inputLabel">Allocation Date *</label>

                <Calendar
                  name="AllocationDate"
                  value={formik.values.AllocationDate}
                  onChange={(e) => {
                    formik.setFieldValue("AllocationDate", e.value);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("AllocationDate"),
                    "card-inputBox": true,
                  })}
                />
                {getFormErrorMessage("AllocationDate")}
              </div>
            </div>

            <div
              className="card my-3 tabWithoutboxshadow"
              style={{ overflowX: "auto" }}
            >
              {" "}
              {/* <Card> */}
              <DataTableComponent
                valueData={calculateValue!}
                fieldsElements={dataTableElementsCalculate}
                isPaginationAllowed={true}
                footerElement={footerGroup}
                scrollable={true}
                className="gridcell deletebtn"
                columnResizeMode="expand"
                maxHeight={"calc(-250px + 100vh)"}
              />
              {/* </Card> */}
            </div>
            {allocated === null && (
              <div className=" align-self-center flex align-items-center justify-content-center">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                  className="blueButtonShadow w-6rem mx-1 d-block h-auto flex justify-content-center"
                >
                  Distribute
                </Button>
              </div>
            )}
          </div>
        </Dialog>
        <Dialog
          visible={distributeVisible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setDistributeVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            This function should only be performed after all LP expenses are
            provided for full Calendar Year. Are you sure you want to process{" "}
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-evenly mx-0 md:mx-2">
            <Button
              onClick={onDistribution}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setDistributeVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>
        <Dialog
          visible={reverseVisible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setReverseVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Are you sure you want to reverse{" "}
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={onReverse}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setReverseVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>

        <Dialog
          visible={reversealert}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setReverseAlert(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Expenses not allocated yet
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-center">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setReverseAlert(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Ok
            </Button>
          </div>
        </Dialog>
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <DataTableComponent
              valueData={tableData! || []}
              fieldsElements={dataTableElements}
              isPaginationAllowed={true}
              // isDownload={true}
              fileName="Distribute Expenses to Investors"
              scrollable={true}
              className="gridcell deletebtn"
              columnResizeMode="expand"
              maxHeight={"calc(-205px + 100vh)"}
              setOpenDailog={setOpenDailog}
              openDailog={openDailog}
            />
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
