import { createSlice } from "@reduxjs/toolkit";
import { AddPoolFundType } from "./type";

export const initialState: AddPoolFundType = {
  data: {
    productId: 0,
    investmentId: 0,
    investorList: [],
    isDateChange: false,
    showAllocatedInvestor: false,
    investmentName: "",
  },
};

const addPoolFundsSlice = createSlice({
  name: "AddPoolFundSlice",
  initialState,
  reducers: {
    setAddPoolFundData(state, action) {
      state.data = action.payload;
    },
    resetAddPoolFundData(state) {
      state.data = initialState.data;
    },
  },
});

export const addPoolFundsActions = addPoolFundsSlice.actions;
export default addPoolFundsSlice.reducer;
