import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetK1GenDetailsService } from "../../../../../services/taxmanagement/tax";
import error from "../../../../../utils/error";
import { IGetK1GenDetailsState, IGetK1GenDetailsparameters } from ".";

const initialState: IGetK1GenDetailsState = {
  success: false,
  message: "",
  loading: "loading",
  data1: undefined,
  data2: undefined,
};
export const GetK1GenDetailsThunk = createAsyncThunk(
  "TaxManagement/GetK1GenDetails",
  async (data: IGetK1GenDetailsparameters) => {
    try {
      const response = await GetK1GenDetailsService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "TaxManagement/GetK1GenDetails",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetK1GenDetailsThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetK1GenDetailsThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data1 = action.payload
        ? action.payload._response.data.partnerDetails
        : [];
      state.data2 = action.payload
        ? action.payload._response.data.capitalAccCalList
        : [];
    });
    builder.addCase(GetK1GenDetailsThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
