import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getESignDocumentService } from "../../../../../services/investor/investor";
import error from "../../../../../utils/error";
import { IGetESignDocuemtParameter, IGetESignDocuemtResponse } from ".";

interface IEsignDocument {
  data?: IGetESignDocuemtResponse;
  loading: string;
  error?: string | null;
}

// C:\React\avestor\avst-ui-sponsor\src\store\Investor\investor-approval\verify-agreement\e-sign-document\slice.ts

const initialState: IEsignDocument = {
  data: undefined,
  loading: "loading",
  error: undefined,
};
export const getESignDocumentThunk = createAsyncThunk(
  "investor-approval/verify-agreement/e-sign-document/getESignDocument",
  async (data: IGetESignDocuemtParameter) => {
    try {
      const response = await getESignDocumentService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "investor-approval/verify-agreement/e-sign-document/getESignDocument",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getESignDocumentThunk.pending, (state) => {
      state.loading = "loading";
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(getESignDocumentThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload._response.data.objDocDetails;
    });
    builder.addCase(getESignDocumentThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
      throw error(action.payload);
    });
  },
});

export default slice.reducer;
