import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEditAllocatedInvestorsByInvestmentPoolfundsService } from "../../../services/PoolFund";
import { EditPoolFundsDataType } from "./type";

export const initialState: EditPoolFundsDataType = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    productId: 0,
    investmentId: 0,
    investorList: [],
    // eslint-disable-next-line unicorn/no-null
    investmentDetails: null,
    managerFees: [],
    isDateChange: false,
    showAllocatedInvestor: false,
  },
};

export const getAllocatedInvesterEditPooledFundThunk = createAsyncThunk(
  "getAllocatedInvesterEditPooledFund",
  async (data: any) => {
    try {
      console.log("data", data);
      const response =
        await getEditAllocatedInvestorsByInvestmentPoolfundsService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
    }
  }
);

const editPoolFundsSlice = createSlice({
  name: "getAllocatedInvesterEditPooledFund",
  initialState,
  reducers: {
    setEditPoolFundData(state, action) {
      state.data = action.payload;
    },
    resetEditPoolFundData(state) {
      state.data = initialState.data;
    },
    resetInvestorListData(state) {
      state.data.investorList = [];
      // eslint-disable-next-line unicorn/no-null
      state.data.investmentDetails = null;
      state.data.managerFees = [];
    },
  },
  extraReducers(builder): void {
    builder.addCase(
      getAllocatedInvesterEditPooledFundThunk.pending,
      (state) => {
        state.loading = "loading";
      }
    );
    builder.addCase(
      getAllocatedInvesterEditPooledFundThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(
          "hr getAllocatedInvesterEditPooledFundThunk",
          action.payload
        );
        state.data.investorList = action.payload.success
          ? action.payload.data.output
          : initialState.data.investorList;
        state.data.investmentDetails = action.payload.success
          ? action.payload.data.pooledFundsInvestmentDetails
          : initialState.data.investmentDetails;
        state.data.managerFees = action.payload.success
          ? action.payload.data.managerFees
          : initialState.data.managerFees;
        state.success = action.payload.success;
        state.message = action.payload.message;
      }
    );
    builder.addCase(
      getAllocatedInvesterEditPooledFundThunk.rejected,
      (state, action) => {
        state.loading = "failed";
      }
    );
  },
});

export const editPoolFundsActions = editPoolFundsSlice.actions;
export default editPoolFundsSlice.reducer;
