import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestorDropdownListByInvestmentId } from "../../../services/ManagerFees";
import {
  exitAllocatedPoolFunds,
  reverseAllocatedPoolFundsByStakeId,
} from "../../../services/PoolFund";
import error from "../../../utils/error";
import { ExitPoolFundType } from "./type";

const initialState: ExitPoolFundType = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  investorDropDown: [],
};
export const exitAllocatedPoolFundsThunk = createAsyncThunk(
  "exitAllocatedPoolFundsThunk",
  async (data: any) => {
    try {
      console.log("data", data);
      const response = await exitAllocatedPoolFunds(data);
      console.log("response exit", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const reverseAllocatedPoolFundsByStakeIdThunk = createAsyncThunk(
  "reverseAllocatedPoolFundsByStakeIdThunk",
  async (data: any) => {
    try {
      console.log("data", data);
      const response = await reverseAllocatedPoolFundsByStakeId(data);
      console.log("response exit", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const getInvestorDropdownListByInvestmentIdThunk = createAsyncThunk(
  "getInvestorDropdownListByInvestmentIdThunk",
  async (data: number) => {
    try {
      const response = await getInvestorDropdownListByInvestmentId(data);
      return response.data;
    } catch (error__: any) {
      console.log(error__);
      throw new Error(error(error__));
    }
  }
);

const slice = createSlice({
  name: "exitAllocatedPoolFunds",
  initialState,
  reducers: {
    resetInvestorDropDown(state) {
      state.investorDropDown = initialState.investorDropDown;
    },
  },
  extraReducers(builder): void {
    builder.addCase(exitAllocatedPoolFundsThunk.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(exitAllocatedPoolFundsThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("hr exitAllocatedPoolFundsThunk", action.payload.success);
    });
    builder.addCase(exitAllocatedPoolFundsThunk.rejected, (state, action) => {
      state.loading = "failed";
      throw new Error(error(action.error));
    });
    builder.addCase(
      reverseAllocatedPoolFundsByStakeIdThunk.pending,
      (state) => {
        state.loading = "loading";
      }
    );
    builder.addCase(
      reverseAllocatedPoolFundsByStakeIdThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(
          "hr reverseAllocatedPoolFundsByStakeIdThunk",
          action.payload.success
        );
      }
    );
    builder.addCase(
      reverseAllocatedPoolFundsByStakeIdThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        throw new Error(error(action.error));
      }
    );
    builder.addCase(
      getInvestorDropdownListByInvestmentIdThunk.pending,
      (state) => {
        state.loading = "loading";
      }
    );
    builder.addCase(
      getInvestorDropdownListByInvestmentIdThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.investorDropDown = action.payload.success
          ? action.payload.data
          : initialState.investorDropDown;
        state.success = action.payload.success;
        state.message = action.payload.message;
        console.log(
          "hr getInvestorDropdownListByInvestmentIdThunk",
          action.payload.success
        );
      }
    );
    builder.addCase(
      getInvestorDropdownListByInvestmentIdThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        state.investorDropDown = initialState.investorDropDown;
        // throw new Error(error(action.error));
      }
    );
  },
});
export const { resetInvestorDropDown } = slice.actions;
export default slice.reducer;
