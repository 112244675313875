export const currencyFormat = (currency: any, maxDigit: number = 2) => {
  const newCurrency: any = Number(currency);
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: maxDigit,
  }).format(newCurrency);
};

export default currencyFormat;
