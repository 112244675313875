// import { Button } from "primereact/button";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

// import { Card } from "primereact/card";
// import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
// import { RiSearchLine } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getPortfolioInvestmentReturnTypeList } from "../../../../store/features/dropdown/investmentReturnType/slice";

import {
  getInvestmentReturnBySupplier,
  setInvestmentReturnBySupplier,
} from "../../../../store/features/reports/investment-returns-by-supplier-report/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import currencyFormat from "../../../../utils/currency-formatter";
import {
  convertLocalDateToUTC,
  // formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import { exportExcel } from "../../../../utils/excel-dataformat";
// import InvestmentReturnsbySupplierSkeleton from "./InvestmentReturnsbySupplierSkeleton";

export default function InvestmentReturnsbySupplier() {
  const dispatch = useAppDispatch();
  const [loading, setloading] = useState(false);
  //  const portState = useAppSelector((state) => state.legalEntityList.loading);
  const dropdownModel = useAppSelector((state) => state.legalEntityList.data);
  const investmentReturnTypeModel = useAppSelector(
    (state) => state.investmentReturnType.data
  );

  const investmentreturnsBySupplierModel = useAppSelector(
    (state) => state.investmentreturnsBySupplier.data
  );
  // const today = new Date();
  const [startdate, setstartdate] = useState<any>();
  const [enddate, setendDate] = useState<any>();
  const [openDailog, setOpenDailog] = useState(false);

  // const month = today.getMonth();
  // const year = today.getFullYear();
  // const nextMonth = month === 11 ? 0 : month + 1;
  // const nextYear = nextMonth === 0 ? year + 1 : year;
  // const maxDate = new Date();
  // maxDate.setMonth(nextMonth);
  // maxDate.setFullYear(nextYear);

  const [exitDate, setExitDate] = useState<any>();
  const [inverstmentRetrun, setinverstmentRetrun] = useState<any>();
  // console.log("h", misssedExitDates);
  // const getExitDateConversion = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedExitDate = {
  //       ...d,
  //       fundingDate: new Date(d.fundingDate),
  //     };
  //     return modifiedExitDate;
  //   });
  // };
  // const formatDate = (rowData: any) => {
  // const dateFormat = rowData?.toLocaleDateString("en-us", {
  //   day: "2-digit",
  //   month: "2-digit",
  //   year: "numeric",
  // });

  //   const dateFormat = formatDateMMDDYYY(rowData);
  //   return dateFormat;
  // };
  const legalEntityList: { code: number; name: string }[] = [];
  for (let i = 0; i < dropdownModel?.length; i++) {
    legalEntityList.push({
      code: dropdownModel![i].legalEntityID,
      name: dropdownModel![i].legalEntityName,
    });
  }
  const inverstmentRetrunList: { code: number; name: string }[] = [];
  for (let i = 0; i < investmentReturnTypeModel?.length; i++) {
    inverstmentRetrunList.push({
      code: investmentReturnTypeModel![i].investmentReturnTypeID,
      name: investmentReturnTypeModel![i].investmentReturnType,
    });
  }

  const [inverstorerror, setinverstorerror] = useState<any>("");
  const [startdateerror, setstartdateerror] = useState<any>("");
  const [enddateerror, setenddateerror] = useState<any>("");
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  const fatchData = () => {
    setloading(true);
    let isvaild = true;

    setinverstorerror("");
    setstartdateerror("");
    setenddateerror("");
    if (inverstmentRetrun === "") {
      setinverstorerror("Investment return is required");
      setloading(false);
      isvaild = false;
    }
    if (startdate === "") {
      setstartdateerror("Start date is required");
      setloading(false);
      isvaild = false;
    }
    if (enddate === "") {
      setenddateerror("End date is required");
      setloading(false);
      isvaild = false;
    }

    if (isvaild) {
      const filerentityListparameters = {
        legalEntityId: dropdown.fundid,
        startDate:
          startdate !== "" && startdate !== undefined
            ? convertLocalDateToUTC(startdate)
            : startdate,
        endDate:
          enddate !== "" && enddate !== undefined
            ? convertLocalDateToUTC(enddate)
            : enddate,
        invReturnTypeID: inverstmentRetrun,
      };
      dispatch(
        getInvestmentReturnBySupplier(filerentityListparameters)
      ).finally(() => {
        setloading(false);
      });
    }
  };

  const formatCurrency = (rowData: any) => {
    const finalValue = Number(rowData.returnRange);
    const parseValue = currencyFormat(Number(finalValue));

    return `${parseValue}`;
  };
  const dataTableElements = [
    {
      field: "supplierName",
      header: "Supplier Name",
      style: { minWidth: "160px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.supplierName}>
          {rowData?.supplierName}
        </div>
      ),
    },
    {
      field: "returnRange",
      header: "Total",
      body: formatCurrency,
    },
    {
      field: "investmentReturnType",
      header: "Investment Return Type",
    },
  ];
  const exportExcelHeader = [
    {
      field: "supplierName",
      header: "Supplier Name",
    },
    {
      field: "returnRange",
      header: "Total",
      dataType: "currency",
    },
    {
      field: "investmentReturnType",
      header: "Investment Return Type",
    },
  ];
  useEffect(() => {
    setExitDate(
      getListDateConversion(investmentreturnsBySupplierModel, ["fundingDate"])
    );
  }, [investmentreturnsBySupplierModel]);

  // useEffect(() => {
  //   dispatch(getInvestmentReturnBySupplier(entityListparameters));
  // }, []);

  useEffect(() => {
    dispatch(getPortfolioInvestmentReturnTypeList());
  }, []);

  useEffect(() => {
    setendDate("");
    setstartdate("");
    setinverstmentRetrun("");
    dispatch(setInvestmentReturnBySupplier(""));
    setinverstorerror("");
    setstartdateerror("");
    setenddateerror("");
  }, [dropdown.fundid]);

  // const rowDta = 10;
  const dt = useRef<DataTable>(null);
  // const exportCSV = (selectionOnly: any) => {
  //   dt.current!.exportCSV({ selectionOnly });
  // };
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="grid col-12 flex align-items-center p-0">
        <div className="col-12 md:col-9  pl-1 p-0">
          <h1 className="pageHeader fs-2">Distributions by Supplier</h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className="flex justify-content-end gap-2 md:col-3">
          <div className="flex align-items-center gap-2 mb-2">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(
                  exportExcelHeader,
                  exitDate,
                  "Distributions by Supplier",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                exitDate === undefined ||
                exitDate?.length === 0 ||
                exitDate === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
          </div>
          <div className="flex align-items-center gap-2 mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                exitDate === undefined ||
                exitDate?.length === 0 ||
                exitDate === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      {/* <div className="grid"> */}
      {/* <div className="col-12"> */}
      <div className="card">
        {/* <Card> */}
        {/* <h4 className="cardHeader">Distributions by Supplier</h4> */}
        {/* <div className="row my-4 d-flex justify-content-between">
                {" "}
              </div> */}
        <div className="row sm:flex align-items-center col-12 m-0 p-0">
          <div className="col-12 md:col-4 sm:col-2 m-0 mb-md-0 ">
            <label className="inputLabel">Investment Return *</label>

            <Dropdown
              name="Investment Return *"
              value={inverstmentRetrun}
              //   value={}
              onChange={(e: any) => {
                setinverstmentRetrun(e.value);
              }}
              resetFilterOnHide={true}
              showFilterClear={true}
              style={{ width: "100%" }}
              options={inverstmentRetrunList}
              optionLabel="name"
              optionValue="code"
              placeholder="Investment Return *"
              filter
              className={"card-inputBox"}
            />
            {inverstorerror ? (
              <p className="errormsg mb-2">{inverstorerror}</p>
            ) : (
              <p className="errormsg mb-2">&nbsp;</p>
            )}
          </div>
          <div className="col-12 md:col-3 sm:col-2 m-0 mb-md-0 ">
            {/* <div className="d-flex align-items-center"> */}
            <label className="inputLabel">Start Date *</label>
            <Calendar
              id="minmax"
              value={startdate}
              onChange={(e: any) => setstartdate(e.value)}
              // maxDate={maxDate}
              readOnlyInput
              placeholder="Start Date"
              dateFormat="mm/dd/yy"
              className={"card-inputBox"}
            />
            {startdateerror ? (
              <p className="errormsg mb-2">{startdateerror}</p>
            ) : (
              <p className="errormsg mb-2">&nbsp;</p>
            )}
            {/* </div> */}
          </div>
          <div className="col-12 md:col-3 sm:col-2 m-0 mb-md-0 ">
            <label className="inputLabel">End Date *</label>
            <Calendar
              id="minmax"
              value={enddate}
              onChange={(e: any) => setendDate(e.value)}
              // minDate={startdate}
              // maxDate={maxDate}
              readOnlyInput
              placeholder="End Date"
              dateFormat="mm/dd/yy"
              className={"card-inputBox"}
            />
            {enddateerror ? (
              <p className="errormsg mb-2">{enddateerror}</p>
            ) : (
              <p className="errormsg mb-2">&nbsp;</p>
            )}
          </div>
          <div className="col-12 md:col-2 sm:col-2 m-0 mb-2 mb-md-0 align-items-center">
            <Button
              className=" btn-nav w-7rem m-0"
              type="button"
              style={{ marginTop: "40px" }}
              // icon="pi pi-file"
              // rounded
              onClick={() => fatchData()}
              data-pr-tooltip="Show"
            >
              {" "}
              <span className="ml-auto mr-auto ">Show </span>
            </Button>
          </div>
        </div>
      </div>

      {/* <div className="flex align-items-center justify-content-end gap-2">
                <Button
                  className="blueButtonShadow"
                  type="button"
                  // icon="pi pi-file"
                  // rounded
                  onClick={() => exportCSV(false)}
                  data-pr-tooltip="CSV"
                >
                  {" "}
                  <span className="blueButtonShadowText">Download </span>
                </Button>
              </div> */}
      <DataTableComponent
        valueData={exitDate}
        fieldsElements={dataTableElements}
        ref={dt}
        isPaginationAllowed={true}
        scrollable={true}
        openDailog={openDailog}
        setOpenDailog={setOpenDailog}
        // isDownload={true}
        fileName="Distributions by Supplier"
        className="gridcell"
        maxHeight={"calc(-214px + 100vh)"}
      />
      {/* </Card> */}

      {/* </div> */}
      {/* </div> */}
    </>
  );
}
