import { InputNumber } from "primereact/inputnumber";
import React, { useEffect, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import DataTableComponent from "../../../../../components/data-table/DataTableComponent";
import { setAllocationSliceInvestorList } from "../../../../../store/features/operation/allocation-slices/add-investors-allocation-state/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import currencyFormat from "../../../../../utils/currency-formatter";
interface IProps {
  errorMessageState: any;
  setErrorMessageState: any;
}
export default function AllocationSliceUnits(props: IProps) {
  const dispatch = useAppDispatch();
  const [hasUnitError, sethasUnitError] = useState(false);
  const [hasCashError, sethascashError] = useState(false);
  const { errorMessageState, setErrorMessageState } = props;
  const allocationInvestorData = useAppSelector(
    (state) => state.getAllInvestorAllocationData.data
  );
  const allocationSliceInvestorList = useAppSelector(
    (state) => state.allocationSliceInvestorState.data
  );
  const handleCalculatedEarning = (
    list: any = allocationSliceInvestorList.investorList
  ) => {
    let totalUnits = 0;
    for (const element of list) {
      if (element.unitsToAllocate !== undefined)
        totalUnits += Number(element.unitsToAllocate);
    }
    return totalUnits;
  };
  const calculateunitsRemaining = (list?: any) => {
    let remainingTotal = 0;
    const allocated = handleCalculatedEarning(list);
    remainingTotal = Number(
      Number(allocationInvestorData.investmentData?.availableUnits) -
        Number(allocated)
    );

    return remainingTotal;
  };

  // cash calculate function for grid
  const cashEquField = (rowData: any) => {
    let selectedError: any;
    let error: any = false;
    if (errorMessageState !== undefined) {
      selectedError = errorMessageState.find(
        (data: any) => data.lpPartnerId === rowData.lpPartnerId
      );
      error = selectedError.cashEquivalentError !== "";
    }

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <label className={` w-100 ${rowData.classApplytoEdit}`}>
            {currencyFormat(rowData.cashEquivalent)}
          </label>
          {error === true ? (
            <RiErrorWarningLine
              title="Amount allocated is higher than available cash balance"
              className="iconError"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };
  const cashBalance = (rowData: any) => {
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <label className={` w-100 ${rowData.classApplytoEdit}`}>
            {currencyFormat(rowData.remainingCash)}
          </label>
        </div>
      </div>
    );
  };
  // edit units function for grid
  const editUnits = (rowData: any) => {
    let selectedError: any;
    let error: any = false;
    if (errorMessageState !== undefined) {
      selectedError = errorMessageState.find(
        (data: any) => data.lpPartnerId === rowData.lpPartnerId
      );
      error = selectedError.unitsToAllocateError !== "";
    }
    // handle the input change of units
    const handleInputChange = (e: any, data: any) => {
      // const newValue = e.target.value;
      const newValue = e.value;
      const cashCal =
        newValue * allocationInvestorData.investmentData?.unitPrice!;
      const cashBalanceRoundoff = Math.round(data.cashBalance * 100) / 100;

      const list = [...allocationSliceInvestorList.investorList];
      const newList = list.map((item: any) => {
        if (item.lpPartnerId === data.lpPartnerId) {
          return {
            ...item,
            unitsToAllocate: newValue,
            cashEquivalent: cashCal,
            remainingCash: newValue
              ? cashBalanceRoundoff - cashCal
              : cashBalanceRoundoff,
            classApplytoEdit: newValue ? "allocateSliceCellHighLight" : "",
          };
        }
        return item;
      });
      // setInvestorListState(newList);
      dispatch(
        setAllocationSliceInvestorList({
          ...allocationSliceInvestorList,
          investorList: newList,
        })
      );

      const totalallocatedUnits = handleCalculatedEarning(newList);

      const remainingTotal =
        Number(allocationInvestorData.investmentData?.availableUnits) -
        totalallocatedUnits;
      // to set the error message in state
      const modifyErrorList = (unitsError: any, cashError: any) =>
        errorMessageState.map((obj: any) => {
          const newData =
            obj?.lpPartnerId === data.lpPartnerId && newValue > 0
              ? {
                  ...obj,
                  unitsToAllocateError: unitsError,
                  cashEquivalentError: cashError,
                }
              : obj?.lpPartnerId === data.lpPartnerId && newValue === null
              ? { ...obj, unitsToAllocateError: "", cashEquivalentError: "" }
              : obj;
          return newData;
        });

      if (
        remainingTotal < 0 &&
        cashCal > cashBalanceRoundoff &&
        cashCal !== 0
      ) {
        setErrorMessageState(
          modifyErrorList(
            "Total units requested are higher than available units. ",
            "Amount allocated is higher than available cash balance"
          )
        );
      } else if (cashCal > cashBalanceRoundoff && cashCal !== 0) {
        setErrorMessageState(
          modifyErrorList(
            "",
            "Amount allocated is higher than available cash balance"
          )
        );
      } else if (remainingTotal < 0) {
        setErrorMessageState(
          modifyErrorList(
            "Total units requested are higher than available units. ",
            ""
          )
        );
      } else {
        const resetErrors = errorMessageState.map((obj: any) => ({
          ...obj,
          unitsToAllocateError: "",
        }));
        const resetCashErrors = resetErrors.map((obj: any) =>
          obj?.lpPartnerId === data.lpPartnerId
            ? { ...obj, cashEquivalentError: "" }
            : obj
        );
        setErrorMessageState(resetCashErrors);
      }
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center allocationInputBox">
          <InputNumber
            name="txtunits"
            id="txtunits"
            value={
              rowData.unitsToAllocate !== 0
                ? rowData.unitsToAllocate
                : undefined
            }
            onChange={(e) => handleInputChange(e, rowData)}
            onValueChange={(e) => handleInputChange(e, rowData)}
            className="w-100"
            min={0}
            placeholder="Enter units"
          />
          {error === true ? (
            <RiErrorWarningLine
              title="Total units requested are higher than available units. "
              className="iconError"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };
  const dataTableElement = [
    {
      field: "name",
      header: "Investor Account",
      // noFilter: true,
      // noSort: true,
    },
    {
      field: "softCommit_Amount",
      header: "Soft Commit Amount",
      // noFilter: true,
      // noSort: true,
      style: { width: "12rem" },
      body: (rowData: any) => currencyFormat(rowData.softCommit_Amount),
    },
    {
      field: "remainingCash",
      header: "Cash Balance",
      // noFilter: true,
      // noSort: true,
      // body: (rowData: any) => currencyFormat(rowData.remainingCash),
      body: cashBalance,
    },
    {
      field: "unitsToAllocate",
      header: "Units to Allocate",
      body: editUnits,
      bodyClassName: "p-1",
      // noFilter: true,
      // noSort: true,
    },
    {
      field: "cashEquivalent",
      header: "Cash Equivalent",
      body: cashEquField,
      // noFilter: true,
      // noSort: true,
    },
  ];

  console.log("state onchange ");
  // To make the error object
  useEffect(() => {
    const investorList: any = [...allocationSliceInvestorList?.investorList];
    const list: any = [];
    for (const item of investorList) {
      const investorObj = {
        lpPartnerId: item.lpPartnerId,
        unitsToAllocateError: "",
        cashEquivalentError: "",
        isFieldRequiredError: true,
      };
      list.push(investorObj);
    }
    setErrorMessageState(list);
  }, []);

  // to set the state for displaying error message on ui
  useEffect(() => {
    if (errorMessageState !== undefined) {
      sethasUnitError(
        errorMessageState.some(
          (error: any) => error.unitsToAllocateError !== ""
        )
      );
      sethascashError(
        errorMessageState.some((error: any) => error.cashEquivalentError !== "")
      );
    }
  }, [errorMessageState]);

  return (
    <>
      <div>
        <div className="mt-4">
          <div className="formgrid grid px-2">
            <div className="d-flex flex-row p-2 col-12  sm:col-12">
              <label className="inputLabel pr-2 flex align-items-center">
                Investment Name:
              </label>{" "}
              <h3>{allocationSliceInvestorList.investmentName}</h3>
            </div>
          </div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
              <label className="inputLabel">Total Units:</label>
              <h3>
                {allocationInvestorData.investmentData?.totalInvestmentUnits}{" "}
              </h3>
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
              <label className="inputLabel">Available Units:</label>
              {/* <h3>{allocationInvestorData.investmentData?.availableUnits}</h3> */}
              <h3>{calculateunitsRemaining()}</h3>
            </div>
          </div>

          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
              <label className="inputLabel">Allocate Accounts</label>
            </div>
          </div>
        </div>
      </div>

      <DataTableComponent
        valueData={allocationSliceInvestorList?.investorList || []}
        fieldsElements={dataTableElement}
        noGridLines={true}
        className="allocateSliceTable"
      />

      <div className="formgrid grid px-2">
        <div className="iconError mb-3  p-2">
          <ul>
            {hasUnitError ? (
              <li>Total units requested are higher than available units.</li>
            ) : (
              ""
            )}
            {hasCashError ? (
              <li>Amount allocated is higher than available cash balance. </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
      <div className="formgrid grid px-2">
        <div className="d-block sm:d-flex sm:flex-column p-3 col-12 flex align-items-end">
          <label className="inputLabel">Total units being allocated:</label>
          <h3>{handleCalculatedEarning()}</h3>
        </div>
      </div>
    </>
  );
}
