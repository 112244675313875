import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetInvoiceCustomerSubscriptionDetailsService } from "../../../../../services/operation/operation";
import error from "./../../../../../utils/error";
import {
  IGetInvoiceCustomerSubscriptionDetailsState,
  IGetInvoiceCustomerSubscriptionDetailsparameters,
} from ".";

const initialState: IGetInvoiceCustomerSubscriptionDetailsState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const GetInvoiceCustomerSubscriptionDetailsThunk = createAsyncThunk(
  "TaxManagement/GetInvoiceCustomerSubscriptionDetails",
  async (data: IGetInvoiceCustomerSubscriptionDetailsparameters) => {
    try {
      const response = await GetInvoiceCustomerSubscriptionDetailsService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "TaxManagement/GetInvoiceCustomerSubscriptionDetails",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(
      GetInvoiceCustomerSubscriptionDetailsThunk.pending,
      (state) => {
        state.loading = "loading";
        //   state.error = undefined;
      }
    );
    builder.addCase(
      GetInvoiceCustomerSubscriptionDetailsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response
          ? action.payload._response.data[0]
          : undefined;
        console.log("state.data", action.payload._response.data[0]);
      }
    );
    builder.addCase(
      GetInvoiceCustomerSubscriptionDetailsThunk.rejected,
      (state) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
