const SSNRegex = /^\d{3}-\d{2}-\d{4}$/;
const emailRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,64}$/;
const EINRegex = /^\d{2}-\d{7}$/;
// const routingNumberRegex = /^\d{9}$/;
const alphaNumericAndSpecialChar = /^[\w !#$%&./@|-]+$/;
// const alphaNumericAndSpecialChar = /^[A-Z_a-z][\w !#$%&./@|-]+$/;
// console.log("alphaNumericAndSpecialChar", alphaNumericAndSpecialChar);
// const accountNumberRegex = /^\d{20}$/;

const isMemberErrorEmpty = (errors: any, key: string) => {
  let errorEmpyt = true;
  for (const error of errors[key]) {
    if (Object.keys(error).length > 0) {
      errorEmpyt = false;
      break;
    }
  }
  return errorEmpyt;
};
function matchHyphen(str: string) {
  const match = str.match(/-/g);
  return match ? match?.length > 0 : false;
}

function allCharactersZero(str: string) {
  return /^[0^-]*$/.test(str);
}
function validateSingleHyphen(str: string) {
  return !/^(?!.*--)[^-]+(?:-[^-]+)*$/.test(str);
}
export function isValidUrlRegex(url: string) {
  const urlRegex = /^https?:\/\/(?:www\.)?[^\s#$./?]+\.[^\s/]{2,}(\/\S*)?$/i;
  return urlRegex.test(url);
}
export function isValidUrlRegexNew(url: string): boolean {
  const urlRegex = /^(https?:\/\/)?([\w-]+\.[\w.-]+)(:\d+)?(\/[\w%&./=?-]*)?$/i;
  return urlRegex.test(url);
}

function isValidUrl(url: string) {
  try {
    const newUrl = new URL(url);
    if (
      isValidUrlRegex(url) &&
      newUrl.origin === url.slice(0, Math.max(0, newUrl.origin.length))
    )
      return true;
    return false;
  } catch {
    return false;
  }
}

const memberInformationValidation = (data: any, errors: any) => {
  if (
    data?.beneficiaryPhoneNumber &&
    data.beneficiaryCountryId === 229 &&
    data?.beneficiaryPhoneNumber.length !== 10
  ) {
    errors.beneficiaryPhoneNumber = "Phone number should be 10 digits";
  }
  if (data.beneficiaryZipCode) {
    if (
      data.beneficiaryZipCode?.length <= 5 &&
      data.beneficiaryCountryId === 229 &&
      matchHyphen(data.beneficiaryZipCode)
    ) {
      errors.beneficiaryZipCode = "Zip code should be 5 digits only";
    }
    if (
      data.beneficiaryZipCode?.length !== 5 &&
      data.beneficiaryZipCode?.length !== 10 &&
      data.beneficiaryCountryId === 229
    ) {
      errors.beneficiaryZipCode = "Zip code should be 5 or 9 digits";
    } else {
      // empty
    }
    if (
      data.beneficiaryCountryId === 229 &&
      allCharactersZero(data.beneficiaryZipCode.replace(/-/g, ""))
    ) {
      errors.beneficiaryZipCode = "Invalid zip code";
    } else if (allCharactersZero(data.beneficiaryZipCode)) {
      errors.beneficiaryZipCode = "Invalid zip code";
    } else if (validateSingleHyphen(data.beneficiaryZipCode)) {
      errors.beneficiaryZipCode = "Invalid zip code";
    }
  }

  if (data?.beneficiaryEmail && !emailRegex.test(data?.beneficiaryEmail)) {
    errors.beneficiaryEmail = "Please enter a valid email";
  }

  return errors;
};

export const commonValidation = (data: any, errors: any) => {
  if (data.accountType) {
    if (!data?.address) {
      errors.address = "Account mailing address is required";
    }
    if (!data?.countryId) {
      errors.countryId = "Country is required";
    }
    if (!data?.state) {
      errors.state = "State is required";
    }
    if (!data?.zipCode) {
      errors.zipCode = "ZIP code is required";
    }
    if (data.zipCode) {
      if (
        data.zipCode?.length <= 5 &&
        data.countryId === 229 &&
        matchHyphen(data.zipCode)
      ) {
        errors.zipCode = "Zip code should be 5 digits only";
      }
      if (
        data.zipCode?.length !== 5 &&
        data.zipCode?.length !== 10 &&
        data.countryId === 229
      ) {
        errors.zipCode = "Zip code should be 5 or 9 digits";
      } else {
        // empty
      }
      if (
        data.countryId === 229 &&
        allCharactersZero(data.zipCode.replace(/-/g, ""))
      ) {
        errors.zipCode = "Invalid zip code";
      } else if (allCharactersZero(data.zipCode)) {
        errors.zipCode = "Invalid zip code";
      } else if (validateSingleHyphen(data.zipCode)) {
        errors.zipCode = "Invalid zip code";
      }
    }

    if (!data?.myAccountName) {
      errors.myAccountName = "Account name is required";
    }
    if (
      data?.myAccountName &&
      !alphaNumericAndSpecialChar.test(data?.myAccountName)
    ) {
      errors.myAccountName = "Only alpha numberic and special character";
    }
    if (data.isAccredited !== true && data.isAccredited !== false) {
      errors.isAccredited = "Investor accredited is required";
    }
    if (data?.isDualSigner) {
      errors.isDualSigner = "Please complete digi singer";
    }
  }
  return errors;
};

export const individualValidation = (data: any, errors: any) => {
  if (!data?.firstName) {
    errors.firstName = "First name is required";
  }
  if (!data?.lastName) {
    errors.lastName = "Last name is required";
  }
  if (!data?.socialSecurityNumber) {
    errors.socialSecurityNumber = "Social security number  is required";
  }
  if (
    data?.socialSecurityNumber &&
    !SSNRegex.test(data?.socialSecurityNumber)
  ) {
    errors.socialSecurityNumber = "Please enter 9 digit ssn number";
  }

  errors = memberInformationValidation(data, errors);

  // ocial Security number
  return errors;
};

export const singleMemberLLCValidation = (data: any, errors: any) => {
  console.log("singleMemberLLCValidation", data);
  console.log("singleMemberLLCValidation errors", errors);

  if (data?.disRegarded !== true && data?.disRegarded !== false) {
    errors.disRegarded = "Regarded or disregarded is required";
  }
  if (data?.disRegarded === false && !data?.ein) {
    errors.ein = "EIN is required";
  }
  if (data?.disRegarded === false && data?.ein && !EINRegex.test(data?.ein)) {
    errors.ein = "Please enter 9 digit ein number";
  }
  if (data?.disRegarded === true && !data?.socialSecurityNumber) {
    errors.socialSecurityNumber = "Social security number is required";
  }

  if (!data?.entityName) {
    errors.entityName = "Entity name is required";
  }
  if (data?.entityName && !alphaNumericAndSpecialChar.test(data?.entityName)) {
    errors.entityName = "Only alpha numberic and special character";
  }

  if (
    data?.disRegarded === true &&
    data?.socialSecurityNumber &&
    !SSNRegex.test(data?.socialSecurityNumber)
  ) {
    errors.socialSecurityNumber = "Please enter 9 digit social security number";
  }

  errors = memberInformationValidation(data, errors);

  return errors;
};

export const selfDirectedIRAValidation = (data: any, errors: any) => {
  if (!data?.city) {
    errors.city = "City is required";
  }
  if (!data?.accountTitleSDIRA) {
    errors.accountTitleSDIRA = "SDIRA account is required";
  }
  if (
    data?.accountTitleSDIRA &&
    !alphaNumericAndSpecialChar.test(data?.accountTitleSDIRA)
  ) {
    errors.accountTitleSDIRA = "Only alpha numberic and special character";
  }
  if (!data?.custodianEmail) {
    errors.custodianEmail = "Email is required";
  }
  if (data?.custodianEmail && !emailRegex.test(data?.custodianEmail)) {
    errors.custodianEmail = "Please enter a valid email";
  }
  if (!data?.custodianPhoneNumber) {
    errors.custodianPhoneNumber = "Phone number is required";
  }
  if (data?.custodianPhoneNumber && data?.custodianPhoneNumber.length !== 10) {
    errors.custodianPhoneNumber = "Phone number should be 10 digits";
  }
  if (!data?.custodianName) {
    errors.custodianName = "Custodian name is required";
  }
  if (!data?.ein) {
    errors.ein = "Ein is required";
  }
  if (data?.ein && !EINRegex.test(data?.ein)) {
    errors.ein = "Please enter 9 digit ein number";
  }
  if (!data?.custodianRoutingNumber) {
    errors.custodianRoutingNumber = "Routing number is required";
  }
  if (
    data?.custodianRoutingNumber &&
    data.custodianRoutingNumber.toString().length > 100
  ) {
    console.log("custodian", data?.custodianRoutingNumber);
    errors.custodianRoutingNumber = "Invalid Routing number";
  }

  console.log(
    "custodianAccountNumber",
    data?.custodianAccountNumber?.toString().length
  );

  // if (
  //   data?.custodianAccountNumber &&
  //   data?.custodianAccountNumber.toString().length >= 12 &&
  //   data?.custodianAccountNumber.toString().length <= 20
  // ) {
  //   // errors.custodianAccountNumber = "Invalid account number";
  // } else if (!data?.custodianAccountNumber) {
  //   errors.custodianAccountNumber = "Account number is required";
  // } else {
  //   errors.custodianAccountNumber = "Invalid account number";
  // }
  if (!data.custodianAccountNumber) {
    errors.custodianAccountNumber = "Account number is required";
  }
  if (
    data.custodianAccountNumber &&
    data.custodianAccountNumber.toString().length > 100
  ) {
    errors.custodianAccountNumber = "Invalid account number";
  }
  // if (
  //   data?.custodianAccountNumber &&
  //   !accountNumberRegex.test(data?.custodianAccountNumber)
  // ) {
  //   console.log("custodian", data?.custodianAccountNumber);
  //   errors.custodianAccountNumber = "Routing number should be 9 digits";
  // }
  if (!data.custodianWebsite) {
    errors.custodianWebsite = "Custodian website is required";
  }

  if (data.custodianWebsite && !isValidUrl(data.custodianWebsite)) {
    errors.custodianWebsite = "Invalid custodian website";
  }
  console.log("errors", errors);
  return errors;
};

export const solo401kValidation = (data: any, errors: any) => {
  if (!data?.city) {
    errors.city = "City is required";
  }
  if (!data?.ein) {
    errors.ein = "Ein is required";
  }

  if (data?.ein && !EINRegex.test(data?.ein)) {
    errors.ein = "Please enter 9 digit ein number";
  }
  if (!data?.accountTitle) {
    errors.accountTitle = "Account title is required";
  }
  if (
    data?.accountTitle &&
    !alphaNumericAndSpecialChar.test(data?.accountTitle)
  ) {
    errors.accountTitle = "Only alpha numberic and special character";
  }
  return errors;
};

export const jointAccountValidation = (data: any, errors: any) => {
  if (!data?.city) {
    errors.city = "City is required";
  }
  if (!data?.primaryAccountFirstName) {
    errors.primaryAccountFirstName = "First name is required";
  }
  if (!data?.primaryAccountLastName) {
    errors.primaryAccountLastName = "Last name is required";
  }

  if (!data?.primaryAccountHolderSSN) {
    errors.primaryAccountHolderSSN =
      "Primary  social security number is required";
  }
  if (
    data?.primaryAccountHolderSSN &&
    !SSNRegex.test(data?.primaryAccountHolderSSN)
  ) {
    errors.primaryAccountHolderSSN =
      "Please enter 9 digit social security number";
  }
  if (!data?.accountOwnershipId) {
    errors.accountOwnershipId = "Account ownership is required";
  }
  if (!data?.secFirstName) {
    errors.secFirstName = "First name is required";
  }

  if (!data?.secLastName) {
    errors.secLastName = "Last name is required";
  }
  if (!data?.secSSN) {
    errors.secSSN = "Social security number is required";
  }
  if (data?.secSSN && !SSNRegex.test(data?.secSSN)) {
    errors.secSSN = "Please enter 9 digit social security number";
  }
  if (!data?.secPhoneNumber) {
    errors.secPhoneNumber = "Phone number is required";
  }
  if (
    data?.secPhoneNumber &&
    data.secCountryId === 229 &&
    data?.secPhoneNumber.length !== 10
  ) {
    errors.secPhoneNumber = "Phone number should be 10 digits";
    // isValid = false;
  }
  if (!data?.secEmail) {
    errors.secEmail = "Email is required";
  }
  if (data?.secEmail && !emailRegex.test(data?.secEmail)) {
    errors.secEmail = "Please enter a valid email";
  }
  if (!data?.secAddress) {
    errors.secAddress = "Address is required";
  }
  if (!data?.secCountryId) {
    errors.secCountryId = "Country is required";
  }
  if (!data?.secCity) {
    errors.secCity = "City is required";
  }
  if (!data?.secZipCode) {
    errors.secZipCode = "ZIP code is required";
  }
  if (data.secZipCode) {
    if (
      data.secZipCode?.length <= 5 &&
      data.secCountryId === 229 &&
      matchHyphen(data.secZipCode)
    ) {
      errors.secZipCode = "Zip code should be 5 digits only";
    }
    if (
      data.secZipCode?.length !== 5 &&
      data.secZipCode?.length !== 10 &&
      data.secCountryId === 229
    ) {
      errors.secZipCode = "Zip code should be 5 or 9 digits";
    } else {
      // empty
    }
    if (
      data.secCountryId === 229 &&
      allCharactersZero(data.secZipCode.replace(/-/g, ""))
    ) {
      errors.secZipCode = "Invalid zip code";
    } else if (allCharactersZero(data.secZipCode)) {
      errors.secZipCode = "Invalid zip code";
    } else if (validateSingleHyphen(data.secZipCode)) {
      errors.secZipCode = "Invalid zip code";
    }
  }
  if (!data?.secState) {
    errors.secState = "State is required";
  }

  return errors;
};

export const multiLLCMembersValidation = (data: any, errors: any) => {
  console.log("data", data);
  if (!data?.city) {
    errors.city = "City is required";
  }
  if (!data?.ein) {
    errors.ein = "Employer identification number (EIN) is required";
  }
  if (data?.ein && !EINRegex.test(data?.ein)) {
    errors.ein = "Please enter 9 digit ein number";
  }
  if (!data?.entityName) {
    errors.entityName = "Entity name is required";
  }
  if (data?.entityName && !alphaNumericAndSpecialChar.test(data?.entityName)) {
    errors.entityName = "Only alpha numberic and special character";
  }
  if (!data?.taxclassification) {
    errors.taxclassification = "Tax classification is required";
  }

  if (!data?.noBeneficialMemberInformation) {
    errors.noBeneficialMemberInformation = "Beneficial member are required";
  }
  if (data?.memberInformation.length > 0) {
    errors.memberInformation = [];
    for (let i = 0; i < data?.memberInformation.length; i++) {
      const itemErrors: any = {};

      if (!data?.memberInformation[i].firstName) {
        itemErrors.firstName = "First name is required";
      }
      if (!data?.memberInformation[i].lastName) {
        itemErrors.lastName = "Last name is required";
      }

      if (
        data?.memberInformation[i].ssn &&
        !SSNRegex.test(data?.memberInformation[i].ssn)
      ) {
        itemErrors.ssn = "Please enter 9 digit ssn number";
      }

      if (
        data?.memberInformation[i].email &&
        !emailRegex.test(data?.memberInformation[i].email)
      ) {
        itemErrors.email = "Please enter a valid email";
      }

      // /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      // if (!data?.memberInformation[i].ssn) {
      //   itemErrors.ssn = "SSN is required";
      // }
      // if (!data?.memberInformation[i].email) {
      //   itemErrors.email = "Email is required";
      // }
      // if (!data?.memberInformation[i].addressLine1) {
      //   itemErrors.addressLine1 = "Address Line 1 is required";
      // }
      // if (!data?.memberInformation[i].country) {
      //   itemErrors.country = "Country is required";
      // }
      // if (!data?.memberInformation[i].city) {
      //   itemErrors.city = "City is required";
      // }
      // if (!data?.memberInformation[i].zipCode) {
      //   itemErrors.zipCode = "Zip code is required";
      // }
      if (data.memberInformation[i].zipCode) {
        if (
          data.memberInformation[i].zipCode?.length <= 5 &&
          data.memberInformation[i].country === 229 &&
          matchHyphen(data.memberInformation[i].zipCode)
        ) {
          itemErrors.zipCode = "Zip code should be 5 digits only";
        }
        if (
          data.memberInformation[i].zipCode?.length !== 5 &&
          data.memberInformation[i].zipCode?.length !== 10 &&
          data?.memberInformation[i].country === 229
        ) {
          itemErrors.zipCode = "Zip code should be 5 or 9 digits";
        } else {
          // empty
        }
        if (
          data.memberInformation[i].country === 229 &&
          allCharactersZero(data.memberInformation[i].zipCode.replace(/-/g, ""))
        ) {
          itemErrors.zipCode = "Invalid zip code";
        } else if (allCharactersZero(data.memberInformation[i].zipCode)) {
          itemErrors.zipCode = "Invalid zip code";
        } else if (validateSingleHyphen(data.memberInformation[i].zipCode)) {
          itemErrors.zipCode = "Invalid zip code";
        }
      }
      // if (!data?.memberInformation[i].state) {
      //   itemErrors.state = "State is required";
      // }
      // if (!data?.memberInformation[i].phoneNumber) {
      //   itemErrors.phoneNumber = "Phone number is required";
      // }

      if (
        data?.memberInformation[i].phoneNumber &&
        data?.memberInformation[i].country === 229 &&
        data?.memberInformation[i].phoneNumber.length !== 10
      ) {
        itemErrors.phoneNumber = "Phone number should be 10 digits";
      }
      // if (
      //   data?.phoneNumber &&
      //   data.countryId === 229 &&
      //   data?.phoneNumber.length !== 10
      // ) {
      //   errors.phone = "Phone number should be 10 digits";
      // }

      errors.memberInformation.push(itemErrors);
    }

    const isErrorEmpyt = isMemberErrorEmpty(errors, "memberInformation");
    // delete errors.memberInformation Dev 9
    if (isErrorEmpyt) {
      delete errors.memberInformation;
    }
  }
  return errors;
};

export const trustValidation = (data: any, errors: any) => {
  console.log("data?.isEIN", data?.isEIN);
  if (!data?.city) {
    errors.city = "City is required";
  }
  if (data?.isEIN !== true && data?.isEIN !== false) {
    errors.isEIN = " SSN or EIN is required";
  }
  if (data?.isEIN === false && !data?.socialSecurityNumber) {
    errors.socialSecurityNumber = "Social security number is required";
  }
  if (
    data?.isEIN === false &&
    data?.socialSecurityNumber &&
    !SSNRegex.test(data?.socialSecurityNumber)
  ) {
    errors.socialSecurityNumber = "Please enter 9 digit social security number";
  }
  if (data?.isEIN === true && !data?.ein) {
    errors.ein = "EIN is required";
  }
  if (data?.isEIN === true && data?.ein && !EINRegex.test(data?.ein)) {
    errors.ein = "Please enter 9 digit ein number";
  }

  if (!data?.trustEstablishmentPlace) {
    errors.trustEstablishmentPlace = "Trust establishment is required";
  }
  if (
    data?.trustEstablishmentPlace &&
    !alphaNumericAndSpecialChar.test(data?.trustEstablishmentPlace)
  ) {
    errors.trustEstablishmentPlace =
      "Only alpha numberic and special character";
  }
  if (!data?.revocabilityId) {
    errors.revocabilityId = "Revocability is required";
  }
  if (!data?.trustTypeId) {
    errors.trustTypeId = "Trust type is required";
  }
  if (!data?.noOfTrustees) {
    errors.noOfTrustees = "Trustees are required";
  }
  if (data?.trusteeInfo.length > 0) {
    errors.trusteeInfo = [];
    for (let i = 0; i < data?.trusteeInfo.length; i++) {
      const itemErrors: any = {};

      if (!data?.trusteeInfo[i].firstName) {
        itemErrors.firstName = "First name is required";
      }
      if (!data?.trusteeInfo[i].lastName) {
        itemErrors.lastName = "Last name is required";
      }
      // if (!data?.trusteeInfo[i].ssn) {
      //   itemErrors.ssn = "SSN is required";
      // }
      // if (!data?.trusteeInfo[i].email) {
      //   itemErrors.email = "Email is required";
      // }
      if (
        data?.trusteeInfo[i].email &&
        !emailRegex.test(data?.trusteeInfo[i].email)
      ) {
        itemErrors.email = "Please enter a valid email";
      }
      // if (!data?.trusteeInfo[i].addressLine1) {
      //   itemErrors.addressLine1 = "Address Line 1 is required";
      // }
      // if (!data?.trusteeInfo[i].country) {
      //   itemErrors.country = "Country is required";
      // }
      // if (!data?.trusteeInfo[i].city) {
      //   itemErrors.city = "City is required";
      // }
      // if (!data?.trusteeInfo[i].zipCode) {
      //   itemErrors.zipCode = "Zip code is required";
      // }
      if (data.trusteeInfo[i].zipCode) {
        if (
          data.trusteeInfo[i].zipCode?.length <= 5 &&
          data.trusteeInfo[i].country === 229 &&
          matchHyphen(data.trusteeInfo[i].zipCode)
        ) {
          itemErrors.zipCode = "Zip code should be 5 digits only";
        }
        if (
          data.trusteeInfo[i].zipCode?.length !== 5 &&
          data.trusteeInfo[i].zipCode?.length !== 10 &&
          data?.trusteeInfo[i].country === 229
        ) {
          itemErrors.zipCode = "Zip code should be 5 or 9 digits";
        } else {
          // empty
        }
        if (
          data.trusteeInfo[i].country === 229 &&
          allCharactersZero(data.trusteeInfo[i].zipCode.replace(/-/g, ""))
        ) {
          itemErrors.zipCode = "Invalid zip code";
        } else if (allCharactersZero(data.trusteeInfo[i].zipCode)) {
          itemErrors.zipCode = "Invalid zip code";
        } else if (validateSingleHyphen(data.trusteeInfo[i].zipCode)) {
          itemErrors.zipCode = "Invalid zip code";
        }
      }
      // if (!data?.trusteeInfo[i].state) {
      //   itemErrors.state = "State is required";
      // }
      // if (!data?.trusteeInfo[i].phoneNumber) {
      //   itemErrors.phoneNumber = "Phone number is required";
      // }
      if (
        data?.trusteeInfo[i].phoneNumber &&
        data?.trusteeInfo[i].country === 229 &&
        data?.trusteeInfo[i].phoneNumber.length !== 10
      ) {
        itemErrors.phoneNumber = "Phone number should be 10 digits";
      }
      errors.trusteeInfo.push(itemErrors);
    }

    const isErrorEmpyt = isMemberErrorEmpty(errors, "trusteeInfo");
    // delete errors.memberInformation Dev 9
    if (isErrorEmpyt) {
      delete errors.trusteeInfo;
    }
  }

  return errors;
};

export const parternshipValidation = (data: any, errors: any) => {
  if (!data?.city) {
    errors.city = "City is required";
  }
  if (!data?.ein) {
    errors.ein = "Employer identification number (EIN) is required";
  }
  if (data?.ein && !EINRegex.test(data?.ein)) {
    errors.ein = "Please enter 9 digit ein number";
  }
  if (!data?.entityName) {
    errors.entityName = "Entity name is required";
  }
  if (data?.entityName && !alphaNumericAndSpecialChar.test(data?.entityName)) {
    errors.entityName = "Only alpha numberic and special character";
  }

  if (!data?.taxclassification) {
    errors.taxclassification = "Tax classification is required";
  }
  if (!data?.noOfPartnerInfo) {
    errors.noOfPartnerInfo = "Partners  are required";
  }
  if (data?.partnerInfo.length > 0) {
    errors.partnerInfo = [];
    for (let i = 0; i < data?.partnerInfo.length; i++) {
      const itemErrors: any = {};

      if (!data?.partnerInfo[i].firstName) {
        itemErrors.firstName = "First name is required";
      }
      if (!data?.partnerInfo[i].lastName) {
        itemErrors.lastName = "Last name is required";
      }
      // if (!data?.partnerInfo[i].ssn) {
      //   itemErrors.ssn = "SSN is required";
      // }
      // if (!data?.partnerInfo[i].email) {
      //   itemErrors.email = "Email is required";
      // }
      if (
        data?.partnerInfo[i].email &&
        !emailRegex.test(data?.partnerInfo[i].email)
      ) {
        itemErrors.email = "Please enter a valid email";
      }
      // if (!data?.partnerInfo[i].addressLine1) {
      //   itemErrors.addressLine1 = "Address Line 1 is required";
      // }
      // if (!data?.partnerInfo[i].country) {
      //   itemErrors.country = "Country is required";
      // }
      // if (!data?.partnerInfo[i].city) {
      //   itemErrors.city = "City is required";
      // }
      // if (!data?.partnerInfo[i].zipCode) {
      //   itemErrors.zipCode = "Zip code is required";
      // }
      if (data.partnerInfo[i].zipCode) {
        if (
          data.partnerInfo[i].zipCode?.length <= 5 &&
          data.partnerInfo[i].country === 229 &&
          matchHyphen(data.partnerInfo[i].zipCode)
        ) {
          itemErrors.zipCode = "Zip code should be 5 digits only";
        }
        if (
          data.partnerInfo[i].zipCode?.length !== 5 &&
          data.partnerInfo[i].zipCode?.length !== 10 &&
          data?.partnerInfo[i].country === 229
        ) {
          itemErrors.zipCode = "Zip code should be 5 or 9 digits";
        } else {
          // empty
        }
        if (
          data.partnerInfo[i].country === 229 &&
          allCharactersZero(data.partnerInfo[i].zipCode.replace(/-/g, ""))
        ) {
          itemErrors.zipCode = "Invalid zip code";
        } else if (allCharactersZero(data.partnerInfo[i].zipCode)) {
          itemErrors.zipCode = "Invalid zip code";
        } else if (validateSingleHyphen(data.partnerInfo[i].zipCode)) {
          itemErrors.zipCode = "Invalid zip code";
        }
      }
      // if (!data?.partnerInfo[i].state) {
      //   itemErrors.state = "State is required";
      // }
      // if (!data?.partnerInfo[i].phoneNumber) {
      //   itemErrors.phoneNumber = "Phone number is required";
      // }
      if (
        data?.partnerInfo[i].phoneNumber &&
        data?.partnerInfo[i].country === 229 &&
        data?.partnerInfo[i].phoneNumber.length !== 10
      ) {
        itemErrors.phoneNumber = "Phone number should be 10 digits";
      }
      errors.partnerInfo.push(itemErrors);
    }

    const isErrorEmpyt = isMemberErrorEmpty(errors, "partnerInfo");
    // delete errors.partnerInfo Dev 9
    if (isErrorEmpyt) {
      delete errors.partnerInfo;
    }
  }
  return errors;
};

export const corportationValidation = (data: any, errors: any) => {
  if (!data?.city) {
    errors.city = "City is required";
  }
  if (!data?.ein) {
    errors.ein = "Employer identification number (EIN) is required";
  }
  if (data?.ein && !EINRegex.test(data?.ein)) {
    errors.ein = "Please enter 9 digit ein number";
  }
  if (!data?.entityName) {
    errors.entityName = "Entity name is required";
  }
  if (data?.entityName && !alphaNumericAndSpecialChar.test(data?.entityName)) {
    errors.entityName = "Only alpha numberic and special character";
  }
  if (!data?.taxclassification) {
    errors.taxclassification = "Tax classification is required";
  }
  if (!data?.noOfOfficerInfo) {
    errors.noOfOfficerInfo = "Executive officer  are required";
  }
  if (data?.officerInfo.length > 0) {
    errors.officerInfo = [];
    for (let i = 0; i < data?.officerInfo.length; i++) {
      const itemErrors: any = {};

      if (!data?.officerInfo[i].firstName) {
        itemErrors.firstName = "First name is required";
      }
      if (!data?.officerInfo[i].lastName) {
        itemErrors.lastName = "Last name is required";
      }
      // if (!data?.officerInfo[i].ssn) {
      //   itemErrors.ssn = "SSN is required";
      // }
      // if (!data?.officerInfo[i].email) {
      //   itemErrors.email = "Email is required";
      // }
      if (
        data?.officerInfo[i].email &&
        !emailRegex.test(data?.officerInfo[i].email)
      ) {
        itemErrors.email = "Please enter a valid email";
      }
      // if (!data?.officerInfo[i].addressLine1) {
      //   itemErrors.addressLine1 = "Address Line 1 is required";
      // }
      // if (!data?.officerInfo[i].country) {
      //   itemErrors.country = "Country is required";
      // }
      // if (!data?.officerInfo[i].city) {
      //   itemErrors.city = "City is required";
      // }
      // if (!data?.officerInfo[i].zipCode) {
      //   itemErrors.zipCode = "Zip code is required";
      // }
      if (data.officerInfo[i].zipCode) {
        if (
          data.officerInfo[i].zipCode?.length <= 5 &&
          data.officerInfo[i].country === 229 &&
          matchHyphen(data.officerInfo[i].zipCode)
        ) {
          itemErrors.zipCode = "Zip code should be 5 digits only";
        }
        if (
          data.officerInfo[i].zipCode?.length !== 5 &&
          data.officerInfo[i].zipCode?.length !== 10 &&
          data.officerInfo[i].country === 229
        ) {
          itemErrors.zipCode = "Zip code should be 5 or 9 digits";
        } else {
          // empty
        }
        if (
          data.officerInfo[i].country === 229 &&
          allCharactersZero(data.officerInfo[i].zipCode.replace(/-/g, ""))
        ) {
          itemErrors.zipCode = "Invalid zip code";
        } else if (allCharactersZero(data.officerInfo[i].zipCode)) {
          itemErrors.zipCode = "Invalid zip code";
        } else if (validateSingleHyphen(data.officerInfo[i].zipCode)) {
          itemErrors.zipCode = "Invalid zip code";
        }
      }
      // if (!data?.officerInfo[i].state) {
      //   itemErrors.state = "State is required";
      // }
      // if (!data?.officerInfo[i].phoneNumber) {
      //   itemErrors.phoneNumber = "Phone number is required";
      // }
      if (
        data?.officerInfo[i].phoneNumber &&
        data?.officerInfo[i].country === 229 &&
        data?.officerInfo[i].phoneNumber.length !== 10
      ) {
        itemErrors.phoneNumber = "Phone number should be 10 digits";
      }

      errors.officerInfo.push(itemErrors);
    }

    const isErrorEmpyt = isMemberErrorEmpty(errors, "officerInfo");
    // delete errors.partnerInfo Dev 9
    if (isErrorEmpyt) {
      delete errors.officerInfo;
    }
  }
  return errors;
};

export const step1Validation = (data: any, errors: any) => {
  if (!/^[\sA-Za-z]{1,40}$/.test(data?.firstNamePP)) {
    errors.firstNamePP = "Invalid First Name";
    // isValid = false;
  }
  if (!data?.firstNamePP) {
    errors.firstNamePP = "First Name is required";

    // isValid = false;
  }
  if (!/^[\sA-Za-z]{1,40}$/.test(data?.lastNamePP)) {
    errors.lastNamePP = "Invalid Last Name";
    // isValid = false;
  }
  if (!data?.lastNamePP) {
    errors.lastNamePP = "Last Name is required";

    // isValid = false;
  }

  // if (!data?.cityPP) {
  //   errors.cityPP = "City is required";

  if (!data?.addressPP) {
    errors.addressPP = "Address is required";
    // isValid = false;
  }
  if (!data?.phonePP) {
    errors.phonePP = "Phone Number is required";
    // isValid = false;
  }
  if (
    data?.phonePP &&
    data.countryIdPP === 229 &&
    data?.phonePP.length !== 10
  ) {
    errors.phonePP = "Phone number should be 10 digits";
    // isValid = false;
  }

  if (!data?.statePP) {
    errors.statePP = "State is required";
    // isValid = false;
  }
  if (!data?.countryIdPP) {
    errors.countryIdPP = "Country is required";
  }

  if (!data?.zipcodePP) {
    errors.zipcodePP = "Zip code is required";
    // isValid = false;
  }
  if (data.zipcodePP) {
    if (
      data.zipcodePP?.length <= 5 &&
      data.countryIdPP === 229 &&
      matchHyphen(data.zipcodePP)
    ) {
      errors.zipcodePP = "Zip code should be 5 digits only";
    }
    if (
      data.zipcodePP?.length !== 5 &&
      data.zipcodePP?.length !== 10 &&
      data.countryIdPP === 229
    ) {
      errors.zipcodePP = "Zip code should be 5 or 9 digits";
    } else {
      // empty
    }
    if (
      data.countryIdPP === 229 &&
      allCharactersZero(data.zipcodePP.replace(/-/g, ""))
    ) {
      errors.zipcodePP = "Invalid zip code";
    } else if (allCharactersZero(data.zipcodePP)) {
      errors.zipcodePP = "Invalid zip code";
    } else if (validateSingleHyphen(data.zipcodePP)) {
      errors.zipcodePP = "Invalid zip code";
    }
  }

  console.log("step1Validation error message", data);
  return errors;
};
