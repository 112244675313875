import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAllocatedInvestmentService } from "../../../../../services/taxmanagement/tax";
import error from "../../../../../utils/error";
import {
  IGetAllocatedInvestmentState,
  IGetAllocatedInvestmentparameters,
} from ".";

const initialState: IGetAllocatedInvestmentState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  data2: undefined,
};
export const GetAllocatedInvestmentThunk = createAsyncThunk(
  "TaxManagement/GetAllocatedInvestment",
  async (data: IGetAllocatedInvestmentparameters) => {
    try {
      const response = await GetAllocatedInvestmentService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "TaxManagement/GetAllocatedInvestment",
  initialState,
  reducers: {
    resetReverseData: (state) => {
      state.data = undefined;
      state.data2 = undefined;
      console.log("Investor allocation In State", state.data, state.data2);
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetAllocatedInvestmentThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
      state.data = undefined;
      state.data2 = undefined;
    });
    builder.addCase(GetAllocatedInvestmentThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload._response.data
        ? action.payload._response.data[0]?.[0]?.result
        : [];
      state.data2 = action.payload._response.data
        ? action.payload._response.data[1]?.[0]?.result
        : [];
    });
    builder.addCase(GetAllocatedInvestmentThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export const { resetReverseData } = slice.actions;
export default slice.reducer;
