import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLateInvestmenttExitsService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";
import { IMissedExitDateListState, ILateInvestmentParameter } from ".";

const initialState: IMissedExitDateListState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};

export const getThunkLateInvestmentExits = createAsyncThunk(
  "lateInvestmentExits",
  async (data: ILateInvestmentParameter) => {
    try {
      const response = await getLateInvestmenttExitsService(data);
      // console.log("res", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "lateInvestmentExits",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkLateInvestmentExits.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkLateInvestmentExits.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("k", action.payload);
      state.data =
        action.payload._response.data === null
          ? []
          : action.payload._response.data[0];
    });
    builder.addCase(getThunkLateInvestmentExits.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
