import React from "react";
const DateTemplate: React.FC = (date: any) => {
  if (!date.selectable) {
    return <span className="dateDisable">{date.day}</span>;
  }
  if (date.selectable) {
    return <span className="dateHighlight">{date.day}</span>;
  }
  return date.day;
};

export default DateTemplate;
