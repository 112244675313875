import { useFormik } from "formik";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
// import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { TabPanel, TabView } from "primereact/tabview";
import { classNames } from "primereact/utils";
import React, { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import { getGAAPAccountingBalanceSheetThunk } from "../../store/features/gaap-accounting/get-gaap-accounting-balance-sheet/slice";
import { getReportTypeListThunk } from "../../store/features/gaap-accounting/get-report-type-list/slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import currencyFormat from "../../utils/currency-formatter";
import "./BalanceSheet.scss";
import {
  convertLocalDateToUTC,
  dateFormatterModified,
} from "../../utils/date-formatter";

const BalanceSheet: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setloading] = useState(false);
  // const [maxDate, setMaxDate] = useState<any>();
  // const [minDate, setMinDate] = useState<any>();

  const dispatch = useAppDispatch();
  const reportTypeList = useAppSelector(
    (State) => State.getReportTypeList.data
  );

  const getGAAPAccountingBalanceSheetData = useAppSelector(
    (state) => state.getGAAPAccountingBalanceSheet.data
  );
  const loadingState = useAppSelector(
    (state) => state.getGAAPAccountingBalanceSheet.loading
  );

  const getGAAPAccountingBalanceSheetTotal = useAppSelector(
    (state) => state.getGAAPAccountingBalanceSheet.total
  );

  console.log(
    "getGAAPAccountingBalanceSheetData",
    getGAAPAccountingBalanceSheetData,
    "loadingState",
    loadingState,
    "getGAAPAccountingBalanceSheetTotal",
    getGAAPAccountingBalanceSheetTotal
  );

  useEffect(() => {
    dispatch(getReportTypeListThunk());
  }, []);

  const formik = useFormik({
    initialValues: {
      asOf: "",
      selectedDate: "",
      reportTypeId: "",
    },
    validate: (data: any) => {
      console.log("validate", data);
      const errors: any = {};
      if (!data?.selectedDate) {
        errors.selectedDate = "Date is required";
      }
      if (!data?.reportTypeId) {
        errors.reportTypeId = "Report Type is required";
      }

      return errors;
    },
    onSubmit: (data: any) => {
      console.log("onSubmit", data);
      // startDate:
      // startdate !== "" && startdate !== undefined
      //   ? convertLocalDateToUTC(startdate)
      //   : startdate,
      setloading(true);
      dispatch(
        getGAAPAccountingBalanceSheetThunk({
          selectedDate: convertLocalDateToUTC(data.selectedDate),
          reportTypeId: data.reportTypeId,
        })
      )
        .catch(() => {
          console.log("OOps something went wrong");
        })
        .finally(() => {
          setloading(false);
        });
    },
  });

  useEffect(() => {
    if (formik.values.selectedDate) {
      const year = new Date(formik.values.selectedDate);
      // Get the year and month from the given date
      const y = year.getFullYear();
      const m = year.getMonth();

      // Calculate the last day of the next month (which is the last day of the current month)
      const lastDayOfMonth = new Date(y, m + 1, 0);
      console.log("year lastDayOfMonth", lastDayOfMonth);
      // maxDate = lastDayOfMonth;
      // setMaxDate(lastDayOfMonth);
      // setMinDate(year);
    }
  }, [formik.values.selectedDate]);

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);
  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  return (
    <>
      {loading && <Loader />}
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader"> Balance Sheet</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2  flex justify-content-end">
          <Button className="btn-nav">Export</Button>
        </div>
      </div>

      <div className="card my-3 tabWithoutboxshadow">
        <Card>
          <div className="formgrid grid pt-3 align-items-end bg-secondary pb-5">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
              <label className="inputLabel">As of</label>
              <Calendar
                view="month"
                value={formik.values.asOf}
                onChange={(e) => {
                  formik.setFieldValue("asOf", e.value);
                  formik.setFieldValue("selectedDate", e.value);
                }}
                dateFormat="M/yy"
                className={classNames({
                  "p-invalid": isFormFieldInvalid("asOf"),
                  "w-full": true,
                })}
              />
              {getFormErrorMessage("asOf")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
              <label className="inputLabel"></label>
              <Calendar
                value={formik.values.selectedDate}
                onChange={(e) => {
                  formik.setFieldValue("selectedDate", e.value);
                  // setDate(e.value);
                }}
                showIcon
                className={classNames({
                  "p-invalid": isFormFieldInvalid("selectedDate"),
                  "w-full": true,
                })}
              />
              {getFormErrorMessage("selectedDate")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
              <label className="inputLabel">Report Type</label>
              <Dropdown
                value={formik.values.reportTypeId}
                options={reportTypeList}
                onChange={(e) => {
                  formik.setFieldValue("reportTypeId", e.value);
                }}
                optionLabel="value"
                optionValue="key"
                placeholder="Select Report Type"
                filter
                className={classNames({
                  "p-invalid": isFormFieldInvalid("reportTypeId"),
                  "card-inputBox": true,
                })}
              />
              {getFormErrorMessage("reportTypeId")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
              <Button
                className="btn-navActive"
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
                type="submit"
              >
                Update Report
              </Button>
              <br />
              {getFormErrorMessage("")}
            </div>
          </div>

          {loadingState === "succeeded" ? (
            <>
              <div className="flex flex-wrap justify-content-center gap-3 align-items-end mt-5 mb-5">
                <div className="flex flex-column">
                  <label>Cash and Bank</label>
                  <span className="fs-3">
                    {currencyFormat(
                      getGAAPAccountingBalanceSheetData?.totalCashBank
                    )}
                  </span>
                </div>
                <div>
                  <span className="fs-3"> + </span>
                </div>
                <div className="flex flex-column">
                  <label>To be received</label>
                  <span className="fs-3">
                    {currencyFormat(
                      getGAAPAccountingBalanceSheetData?.tobeReceived
                    )}
                  </span>
                </div>
                <div>
                  <span className="fs-3"> - </span>
                </div>
                <div className="flex flex-column">
                  <label>To be paid out</label>
                  <span className="fs-3">
                    {currencyFormat(
                      getGAAPAccountingBalanceSheetData?.tobePaidOut
                    )}
                  </span>
                </div>
                <div>
                  <span className="fs-3"> = </span>
                </div>
                <div className="amount-green">
                  <span className="fs-3">
                    {currencyFormat(getGAAPAccountingBalanceSheetTotal.total)}
                  </span>
                </div>
              </div>
              <div className="border-top" />
              <div className="balancetab">
                <TabView
                  activeIndex={activeIndex}
                  onTabChange={(e) => setActiveIndex(e.index)}
                >
                  <TabPanel header="Summary">
                    <div className="flex-between p-2">
                      <div className="fw-bold text-uppercase">Accounts</div>
                      <div className="fw-bold">
                        {dateFormatterModified(formik.values.selectedDate)}
                      </div>
                    </div>
                    <Accordion multiple activeIndex={[0, 1, 2]}>
                      <AccordionTab header="Assets">
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total cash and Bank</label>
                          </div>
                          <div>
                            <label>
                              {currencyFormat(
                                getGAAPAccountingBalanceSheetData?.totalCashBank
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total other Current Assets</label>
                          </div>
                          <div>
                            <label>
                              {currencyFormat(
                                getGAAPAccountingBalanceSheetData?.totalCurrentAsset
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total Long-term Assets</label>
                          </div>
                          <div>
                            <label>
                              {currencyFormat(
                                getGAAPAccountingBalanceSheetData?.totalLongTermAsset
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div>
                            <label className="fw-bold ml-3">Total Assets</label>
                          </div>
                          <div>
                            <label className="fw-bold">
                              {currencyFormat(
                                getGAAPAccountingBalanceSheetTotal.TotalAssets
                              )}
                            </label>
                          </div>
                        </div>
                      </AccordionTab>
                      <AccordionTab header="Liabilities">
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total Current Liabilities</label>
                          </div>
                          <div>
                            <label>
                              {currencyFormat(
                                getGAAPAccountingBalanceSheetData?.totalCurrentLiabilities
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total Long-term Liabilities</label>
                          </div>
                          <div>
                            <label>
                              {currencyFormat(
                                getGAAPAccountingBalanceSheetData?.totalLongTermLiabilities
                              )}
                            </label>
                          </div>
                        </div>

                        <div className="flex-between p-2 border-bottom">
                          <div>
                            <label className="ml-3 fw-bold">
                              Total Liabilities
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold">
                              {currencyFormat(
                                getGAAPAccountingBalanceSheetTotal.TotalLiabilities
                              )}
                            </label>
                          </div>
                        </div>
                      </AccordionTab>
                      <AccordionTab header="Equity">
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total other Equity</label>
                          </div>
                          <div>
                            <label>
                              {currencyFormat(
                                getGAAPAccountingBalanceSheetData?.totalOtherEquity
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total Retained Earnings</label>
                          </div>
                          <div>
                            <label>
                              {currencyFormat(
                                getGAAPAccountingBalanceSheetData?.totalRetainedEarnings
                              )}
                            </label>
                          </div>
                        </div>

                        <div className="flex-between p-2 border-bottom">
                          <div>
                            <label className="ml-3 fw-bold">Total Equity</label>
                          </div>
                          <div>
                            <label className="fw-bold">
                              {currencyFormat(
                                getGAAPAccountingBalanceSheetTotal.TotalEquity
                              )}
                            </label>
                          </div>
                        </div>
                      </AccordionTab>
                    </Accordion>

                    <div className="mt-4 flex justify-content-center">
                      <Button
                        className="btn-nav border-radius-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveIndex(1);
                        }}
                      >
                        Show Details
                      </Button>
                    </div>
                  </TabPanel>
                  <TabPanel header="Details">
                    <div className="flex-between p-2">
                      <div className="fw-bold text-uppercase">Accounts</div>
                      <div className="fw-bold">
                        {dateFormatterModified(formik.values.selectedDate)}
                      </div>
                    </div>
                    <Accordion multiple activeIndex={[0, 1, 2]}>
                      <AccordionTab header="Assets">
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label> Cash and Bank</label>
                          </div>
                          <div>
                            <label>{currencyFormat("0")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label className="text-primary">
                              Cashflow and capital fund
                            </label>
                          </div>
                          <div>
                            <label>{currencyFormat("288778886")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total cash and bank</label>
                          </div>
                          <div>
                            <label>
                              {currencyFormat(
                                getGAAPAccountingBalanceSheetData?.totalCurrentAsset
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Other current assets</label>
                          </div>
                          <div>
                            <label>
                              {currencyFormat(
                                getGAAPAccountingBalanceSheetData?.totalLongTermAsset
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total current assets</label>
                          </div>
                          <div>
                            <label>{currencyFormat("0")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Long-term Assets</label>
                          </div>
                          <div>
                            <label>{currencyFormat("0")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total Long-term Assets</label>
                          </div>
                          <div>
                            <label>{currencyFormat("0")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div>
                            <label className="ml-3 fw-bold">Total Assets</label>
                          </div>
                          <div>
                            <label className="fw-bold">
                              {currencyFormat("00")}
                            </label>
                          </div>
                        </div>
                      </AccordionTab>
                      <AccordionTab header="Liabilities">
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Current Liabilities</label>
                          </div>
                          <div>
                            <label>{currencyFormat("0")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total Current Liabilities</label>
                          </div>
                          <div>
                            <label>{currencyFormat("0")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Long-term Liabilities</label>
                          </div>
                          <div>
                            <label>{currencyFormat("0")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total Long-term Liabilities</label>
                          </div>
                          <div>
                            <label>{currencyFormat("0")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div>
                            <label className="ml-3 fw-bold">
                              Total Liabilities
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold">
                              {currencyFormat("0")}
                            </label>
                          </div>
                        </div>
                      </AccordionTab>
                      <AccordionTab header="Equity">
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label className="text-primary">
                              Member Capital Contributions
                            </label>
                          </div>
                          <div>
                            <label>{currencyFormat("700000")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Retained Earnings</label>
                          </div>
                          <div>
                            <label>{currencyFormat("700000")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label className="text-primary">
                              Profit between 1 jan 2023 to 31 dec 2023
                            </label>
                          </div>
                          <div>
                            <label>{currencyFormat("-123.22")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div className="ml-3">
                            <label>Total Retained Earnings</label>
                          </div>
                          <div>
                            <label>{currencyFormat("700")}</label>
                          </div>
                        </div>
                        <div className="flex-between p-2 border-bottom">
                          <div>
                            <label className="ml-3 fw-bold">Total Equity</label>
                          </div>
                          <div>
                            <label className="fw-bold">
                              {currencyFormat("1400700")}
                            </label>
                          </div>
                        </div>
                      </AccordionTab>
                    </Accordion>

                    <div className="mt-4 flex justify-content-center">
                      <Button
                        className="btn-nav border-radius-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveIndex(0);
                        }}
                      >
                        Show Summary
                      </Button>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </>
          ) : (
            ""
          )}
        </Card>
      </div>
    </>
  );
};

export default BalanceSheet;
