import { FormikValues } from "formik";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { memo, useEffect, useRef, useState } from "react";
// import { Button } from "react-bootstrap";
import { RiFilePaper2Line } from "react-icons/ri";
import { useParams } from "react-router-dom";
import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { getAccrediationLetterThunk } from "../../../../store/Investor/investor-approval/verify-agreement/accreditation/get-accrediation-letter/slice";
import { getEmbedUIAcreddLinkThunk } from "../../../../store/Investor/investor-approval/verify-agreement/accreditation/get-embed-ui-acredd-link/slice";
import { getInvAccrediationStatusDetailsThunk } from "../../../../store/Investor/investor-approval/verify-agreement/accreditation/get-inv-accrediation-status/slice";
import { getUploadedAccredDocumentLinkThunk } from "../../../../store/Investor/investor-approval/verify-agreement/accreditation/get-uploaded-accred-document-link/slice";
import { uploadAcrredFileThunk } from "../../../../store/Investor/investor-approval/verify-agreement/accreditation/upload-acrred-file-link/slice";
import { getprofileData } from "../../../../store/features/profile/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getSasKeyThunk } from "../../../../store/sas-key/slice";
import { dateFormatterModified } from "../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

interface IProps {
  formik: FormikValues;
  isFormFieldInvalid: any;
  getFormErrorMessage: any;
  investorId: number;
  legalEntityId: number;
  investingAccountId: number;
}

const Accreditation: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  console.log("params", params);

  const [sasKey, setsasKey] = useState("");

  const toast = useRef<Toast>(null);
  const fileRef = useRef<any>(null);
  const [visibleUplodedAccredDocx, setvisibleUplodedAccredDocx] =
    useState(false);
  const [accredVisible, setAccredVisible] = useState(false);
  const [visibleAccred, setVisibleAccred] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [visibleAccredFailedEXpired, setVisibleAccredFailedEXpired] =
    useState(false);

  const uploadedAccredDocumentLink = useAppSelector(
    (state) => state.getUploadedAccredDocumentLink.data
  );
  const uploadedAccredDocumentLegalEntityId = useAppSelector(
    (state) => state.getUploadedAccredDocumentLink.legalEntityID
  );

  const uploadedAccredDocumentOtherData = useAppSelector(
    (state) => state.getUploadedAccredDocumentLink.other
  );

  // Third Party Accred Ebedded Api Url
  const getAccreditLink = useAppSelector(
    (state) => state.getEmbedUIAcreddLink.data
  );

  // Get Unique Link of Third Party Accred Ebedded status
  const uniqueLink = useAppSelector(
    (state) => state.getInvAccrediationStatusDetails.data
  );
  const accredThirdPartyData = useAppSelector(
    (state) => state.getInvAccrediationStatusDetails.output?.rejectionComment
  );
  const accredThirdPartyStatusData = useAppSelector(
    (state) => state.getInvAccrediationStatusDetails.accredStatus
  );
  const getInvAccrediationStatusDetailsLoading = useAppSelector(
    (state) => state.getInvAccrediationStatusDetails.loading
  );

  const sponsorData = useAppSelector(getprofileData);

  const getIAEnrollmentDataLinkData = useAppSelector(
    (state) => state.getIAEnrollmentData.data
  );

  // const getIAEnrollmentDataLinkData = useAppSelector(
  //   (state) => state.getIAEnrollmentData.data
  // );
  // const getIAEnrollmentDataLinkState = useAppSelector(
  //   (state) => state.getIAEnrollmentData.loading
  // );

  const body = {
    investorId: +props.investorId,
    legalEntityId: +props.legalEntityId,
    investingAccountId: +props.investingAccountId,
  };

  const getEmbedUIAcreddLinkApi = async (status = "") => {
    let accreddTransactionID = uniqueLink?.accreddTransactionID
      ? uniqueLink?.accreddTransactionID
      : "";

    if (status === "Failed") {
      accreddTransactionID = "";
    }

    dispatch(
      getEmbedUIAcreddLinkThunk({
        ...body,
        accreddTransactionId: accreddTransactionID,
        isAccredited: uploadedAccredDocumentOtherData?.isAccredited
          ? uploadedAccredDocumentOtherData?.isAccredited
          : false,
      })
    )
      .then((res: any) => {
        console.log("res", res);
        if (res.payload._response.data) {
          setVisibleAccred(true);
        } else {
          errorToastMessage(toast);
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const accredit = async (e: any) => {
    e.preventDefault();

    if (
      accredThirdPartyStatusData === "Processing" ||
      accredThirdPartyStatusData === "Verified"
    ) {
      setAccredVisible(true);
      return;
    }

    if (
      accredThirdPartyStatusData === "Expired" ||
      accredThirdPartyStatusData === "Failed" ||
      uploadedAccredDocumentOtherData?.isExpired ||
      uploadedAccredDocumentOtherData?.isFailed
    ) {
      setVisibleAccredFailedEXpired(true);
      return;
    }

    if (
      accredThirdPartyStatusData === "No submission found with the investor." ||
      !uploadedAccredDocumentOtherData?.isProcessing
    ) {
      setLoader(true);
      await getEmbedUIAcreddLinkApi();
    }

    // if (accredThirdPartyStatusData === "Verified") {
    // alert("Verified");
    // setVisibleAccred(true);
    // }
  };
  const generateSASKey = (legalEntityId: any) => {
    return dispatch(getSasKeyThunk(legalEntityId))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          return response.payload.data.sasKey;
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const uploadDoc = async (accreditPdf: any) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("filename", accreditPdf);
    if (props.investorId && props.legalEntityId) {
      const payload = {
        investorId: +props.investorId,
        legalEntityId: +props.legalEntityId,
      };
      console.log();

      dispatch(uploadAcrredFileThunk({ ...payload, filename: formData }))
        .then(() => {
          successToastMessage(toast, "File Uploaded");
          dispatch(getUploadedAccredDocumentLinkThunk(payload));
          fileRef?.current.clear();
        })
        .catch(() => {
          errorToastMessage(
            toast,
            "Opps something went wrong. Please try again later"
          );
          fileRef?.current.clear();
        })
        .finally(() => {
          setLoader(false);
        });
    }

    // const response = await fetch(`orders/${orderId}/uploadInvoiceFile`, {
    //   method: "POST",
    //   body: formData,
    // });
  };

  const uploadAccredFile = async (e: any) => {
    const file = e.files[0];
    const reader = new FileReader();

    // addEventListener
    reader.addEventListener("load", () => {
      const fileType = file.type;

      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "image/jpg" ||
        fileType === "application/pdf"
      ) {
        setErrorText(false);
        uploadDoc(file);
      } else {
        setErrorText(true);
        fileRef?.current.clear();
      }
    });
    // alert("upload done");

    // reader.onerror = function() {
    //
    // };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (
      accredThirdPartyStatusData === "Verified" &&
      props.investorId &&
      props.legalEntityId
    ) {
      const payload = {
        investorId: +props.investorId,
        legalEntityId: +props.legalEntityId,
      };
      if (uniqueLink!.accreddTransactionID) {
        dispatch(
          getAccrediationLetterThunk({
            ...payload,
            ExternalUniqueId: uniqueLink!.accreddTransactionID,
          })
        ).then(() => {
          dispatch(getUploadedAccredDocumentLinkThunk(payload));
        });
      }
    }
  }, [accredThirdPartyStatusData]);

  useEffect(() => {
    const payload = {
      investorId: +props.investorId,
      legalEntityId: +props.legalEntityId,
      investingAccountId: +props.investingAccountId,
    };
    // dispatch(getUploadedAccredDocumentLinkThunk(payload));
    // dispatch(getInvAccrediationStatusDetailsThunk(payload));
    dispatch(getInvAccrediationStatusDetailsThunk(payload))
      .then((response: any) => {
        console.log("data", response.payload._response.data);
      })
      .catch(() => {})
      .finally(() => {});
  }, []);
  useEffect(() => {
    if (uploadedAccredDocumentLink) {
      props.formik.setFieldValue("isAccreditationCompleted", true);
    } else {
      props.formik.setFieldValue("isAccreditationCompleted", false);
    }
  }, [uploadedAccredDocumentLink]);
  useEffect(() => {
    if (props.investorId && props.legalEntityId) {
      const payload = {
        investorId: +props.investorId,
        legalEntityId: +props.legalEntityId,
        investingAccountId: +props.investingAccountId,
      };
      // dispatch(getUploadedAccredDocumentLinkThunk(payload));
      // dispatch(getInvAccrediationStatusDetailsThunk(payload));
      dispatch(getUploadedAccredDocumentLinkThunk(payload))
        .then((response: any) => {
          console.log(
            "response",
            response.payload._response.data.dataresult.isProcessing
          );
          if (response.payload._response.data.dataresult.isProcessing) {
            dispatch(getInvAccrediationStatusDetailsThunk(payload));
          }
        })
        .catch(() => {})
        .finally(() => {});
    }
  }, [props.investorId, props.legalEntityId]);

  const openAccredDoc = async (e: any) => {
    e.preventDefault();
    if (uploadedAccredDocumentLink) {
      // alert(uploadedAccredDocumentLink);
      let key = "";
      setLoader(true);
      key = await generateSASKey(uploadedAccredDocumentLegalEntityId);
      setsasKey(key);
      setvisibleUplodedAccredDocx(true);
    }

    // setVisible(true);
  };

  return (
    <>
      <Accordion activeIndex={[]} className="my-3">
        <AccordionTab
          header="Accreditation"
          // disabled={
          //   (+props.investingAccountId === 0 ||
          //     (typeof +props.investingAccountId !== "number" &&
          //       !getIAEnrollmentDataLinkData?.isSignCompleted &&
          //       getIAEnrollmentDataLinkData?.signer === "Signer 1") ||
          //     (getIAEnrollmentDataLinkData?.signer === "" &&
          //       getIAEnrollmentDataLinkData?.isOlduser !== true)) ??
          //   false
          // }
        >
          <div className="mb-4">
            <h4 className="sectionHeader">Accreditation</h4>

            <div className="fromSection">
              <div>
                {/* <p className="sectionHeader">
              Accreditation Letter (Optional for 506b funds){" "}
            </p> */}
                <p>
                  If you already have an accreditation letter from your
                  registered investment adviser, CPA, licensed attorney or your
                  registered broker-dealer you can upload it here.
                </p>
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 xl:col-3">
                    <label className="inputLabel d-flex justify-content-start">
                      Upload Accreditation Letter
                    </label>

                    <FileUpload
                      mode="basic"
                      name="demo"
                      // url="/api/upload"
                      ref={fileRef}
                      auto={true}
                      customUpload={true}
                      accept="pdf/*"
                      chooseLabel={
                        typeof uploadedAccredDocumentLink === "string"
                          ? "Replace  Letter"
                          : "Upload  Letter"
                      }
                      className="btn-dialog border-round-sm w-100 UploadAccreBtn"
                      uploadHandler={uploadAccredFile}
                    />
                    {errorText ? (
                      <span className="text-xs text-red-600">
                        Please upload files having extensions : .jpg, .jpeg,
                        .png , .pdf only
                      </span>
                    ) : (
                      <></>
                    )}

                    <Toast ref={toast} className="themeToast"></Toast>
                  </div>
                </div>

                {uploadedAccredDocumentLink ? (
                  <div className="d-flex flex-column p-2 col-12 sm:col-3 xl:col-3">
                    <label className="inputLabel">&nbsp;</label>
                    {/* {buttonAccred} */}
                    <Button
                      className="btn-dialog justify-content-center"
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   if (uploadedAccredDocumentLink) {
                      //     // alert(uploadedAccredDocumentLink);
                      //     setvisibleUplodedAccredDocx(true);
                      //   }
                      // }}
                      onClick={(e) => openAccredDoc(e)}
                    >
                      View / Download
                    </Button>
                  </div>
                ) : (
                  <>
                    <p className="mt-4">
                      {`If you do not have an accreditation letter we have partned with Accredd Inc. to help you procure one.Avestor Inc. does not collect, store or have access to any information you provide to Accredd Inc.By using Accredd Inc you agree to Accredd Inc.Terms of Service and Accredd Inc . Privacy Policy.`}
                    </p>

                    <div className="formgrid grid px-2">
                      <div className="d-flex  p-2 col-12 sm:col-3 xl:col-3">
                        <label className="inputLabel">&nbsp;</label>
                        {/* {buttonAccred} */}
                        <Button
                          onClick={accredit}
                          className="btn-dialog mb-2"
                          disabled={
                            getInvAccrediationStatusDetailsLoading === "loading"
                          }
                        >
                          <RiFilePaper2Line className="flex-start mx-2" />
                          <span className="ml-auto mr-auto">
                            Get New Letter
                          </span>
                        </Button>
                      </div>
                    </div>
                  </>
                )}
                {accredThirdPartyStatusData ? (
                  <div className="grid my-2 px-2">
                    {accredThirdPartyStatusData === "Verified" && (
                      <>
                        <div className="col-9 message-success">
                          <h6>
                            Status from last accreditation letter request: Your
                            request for an accreditation letter has been
                            processed successfully.
                          </h6>
                        </div>
                        <div className="col-3 message-success">
                          <h6>
                            Date :{" "}
                            {dateFormatterModified(
                              getIAEnrollmentDataLinkData?.accredLastStatusDate
                            )}
                          </h6>
                        </div>
                      </>
                    )}
                    {accredThirdPartyStatusData === "Processing" && (
                      <>
                        <div className="col-9 message-info">
                          <h6>
                            Status from last accreditation letter request: Your
                            previous request for an accreditation letter is
                            already being processed. Please check back later.
                          </h6>
                        </div>
                        <div className="col-3 message-info">
                          <h6>
                            Date :{" "}
                            {dateFormatterModified(
                              getIAEnrollmentDataLinkData?.accredLastStatusDate
                            )}
                          </h6>
                        </div>
                      </>
                    )}
                    {accredThirdPartyStatusData === "Failed" && (
                      <>
                        <div className="col-9 message-error">
                          <h6>
                            Status from last accreditation letter request:
                            Accreditation letter request was not processed.
                            Please resubmit the required documents and request
                            the letter again.
                          </h6>
                        </div>
                        <div className="col-3 message-error">
                          <h6>
                            Date :{" "}
                            {dateFormatterModified(
                              getIAEnrollmentDataLinkData?.accredLastStatusDate
                            )}
                          </h6>
                        </div>
                      </>
                    )}
                    {accredThirdPartyStatusData === "Expired" && (
                      <>
                        <div className="col-9 message-error">
                          <h6>
                            Status from last accreditation letter
                            request:Accreditation letter request was not
                            processed. Please resubmit the required documents
                            and request the letter again.
                          </h6>
                        </div>
                        <div className="col-3 message-error">
                          <h6>
                            Date :{" "}
                            {dateFormatterModified(
                              getIAEnrollmentDataLinkData?.accredLastStatusDate
                            )}
                          </h6>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  ""
                )}

                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                    <label className="inputLabel d-flex justify-content-start">
                      Date Last Accreditation Verified
                    </label>
                    <Calendar
                      name="lastAccreditationVerified"
                      value={props.formik.values.lastAccreditationVerified}
                      onChange={(e) =>
                        props.formik.setFieldValue(
                          "lastAccreditationVerified",
                          e.target.value
                        )
                      }
                      className={classNames({
                        "p-invalid": props.isFormFieldInvalid(
                          "lastAccreditationVerified"
                        ),
                      })}
                      placeholder="mm/dd/yyyy"
                    />
                    {props.getFormErrorMessage("lastAccreditationVerified")}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                    <label className="inputLabel d-flex justify-content-start">
                      Date Accreditation Expires
                    </label>

                    <Calendar
                      name="accreditationExpires"
                      value={props.formik.values.accreditationExpires}
                      onChange={(e) =>
                        props.formik.setFieldValue(
                          "accreditationExpires",
                          e.target.value
                        )
                      }
                      className={classNames({
                        "p-invalid": props.isFormFieldInvalid(
                          "accreditationExpires"
                        ),
                      })}
                      placeholder="mm/dd/yyyy"
                    />
                    {props.getFormErrorMessage("accreditationExpires")}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                    <label className="inputLabel">
                      Is the Investor accredited?{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="d-flex mt-2">
                      <div className="flex align-items-center pr-4">
                        <RadioButton
                          inputId="isAccredited1"
                          name="true"
                          value={true}
                          onChange={(e) =>
                            props.formik.setFieldValue("isAccredited", e.value)
                          }
                          className={classNames({
                            "p-invalid":
                              props.isFormFieldInvalid("isAccredited"),
                          })}
                          checked={props.formik.values.isAccredited === true}
                        />

                        <label htmlFor="ingredient1" className="ml-2 mb-0">
                          Yes
                        </label>
                      </div>
                      <div className="flex align-items-center pr-4">
                        <RadioButton
                          inputId="isAccredited2"
                          name="pizza"
                          value={false}
                          onChange={(e) =>
                            props.formik.setFieldValue("isAccredited", e.value)
                          }
                          className={classNames({
                            "p-invalid":
                              props.isFormFieldInvalid("isAccredited"),
                          })}
                          checked={props.formik.values.isAccredited === false}
                        />

                        <label htmlFor="ingredient1" className="ml-2 mb-0">
                          No
                        </label>
                      </div>
                    </div>
                    {props.getFormErrorMessage("isAccredited")}
                  </div>

                  {sponsorData?.[0].fundType === "506b" && (
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-12">
                      <h5 className="text-left text-red-600">
                        <b>Note:</b> You are enrolling in a 506(b) fund.
                        Accreditation letter is optional for 506(b) fund.
                      </h5>
                    </div>
                  )}

                  {sponsorData?.[0].fundType?.toLowerCase() === "reg cf" && (
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-12">
                      <h5 className="text-left text-red-600">
                        <b>Note:</b> You are enrolling in a Reg CF fund.
                        Accreditation letter is optional for Reg CF fund.
                      </h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
      <Dialog
        dismissableMask={true}
        visible={accredVisible}
        className="w-100 md:w-3"
        onHide={() => setAccredVisible(false)}
      >
        <h3 className="pageHeader text-center mb-5">
          {accredThirdPartyStatusData === "Verified" &&
            "Document verified Successfully!"}
          {accredThirdPartyStatusData === "Processing" &&
            "Your previous request for an accreditation letter is already being processed. Please check back later."}
          {accredThirdPartyStatusData === "Failed" &&
            accredThirdPartyData &&
            `Accreditation letter request was not processed. ${accredThirdPartyData} Please resubmit the required documents and request the letter again.`}
          {accredThirdPartyStatusData === "Failed" &&
            !accredThirdPartyData &&
            "Accreditation letter request was not processed. Please resubmit the required documents and request the letter again."}
        </h3>
        <div className=" align-self-center flex align-items-center justify-content-center">
          <Button
            onClick={() => setAccredVisible(false)}
            className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
          >
            OK
          </Button>
        </div>
      </Dialog>
      <Dialog
        header=""
        maximizable
        visible={visibleAccred}
        style={{ width: "50vw" }}
        onHide={() => {
          dispatch(getInvAccrediationStatusDetailsThunk(body));
          setVisibleAccred(false);
        }}
        // footer={footerContent}
        // closeOnEscape={true}
        closable={true}
        // className=" w-100 md:w-5"
      >
        <div className="m-0">
          <iframe
            src={getAccreditLink?.custom_link}
            style={{ width: "100%", height: "80vh" }}
          ></iframe>
        </div>
      </Dialog>
      <Dialog
        header=""
        maximizable
        visible={visibleUplodedAccredDocx}
        style={{ width: "50vw" }}
        onHide={() => {
          dispatch(getInvAccrediationStatusDetailsThunk(body));
          setvisibleUplodedAccredDocx(false);
        }}
        // footer={footerContent}
        // closeOnEscape={true}
        closable={true}
        // className=" w-100 md:w-5"
      >
        <div className="m-0">
          <iframe
            src={uploadedAccredDocumentLink + sasKey}
            style={{ width: "100%", height: "80vh" }}
          ></iframe>
        </div>
      </Dialog>

      {visibleAccredFailedEXpired && (
        <ConfirmDailogbox
          openDialog={visibleAccredFailedEXpired}
          setOpenDialog={setVisibleAccredFailedEXpired}
          onConfirm={() => {
            setVisibleAccredFailedEXpired(false);
            setLoader(true);
            getEmbedUIAcreddLinkApi("Failed");
          }}
          onCancel={() => {
            setVisibleAccredFailedEXpired(false);
          }}
          textContent="Accreditation letter request was not processed. Please resubmit the required documents and request the letter again. Do you want to reapply?"
        ></ConfirmDailogbox>
      )}
      {loader && <Loader />}
    </>
  );
};
export default memo(Accreditation);
