import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
// import { Column } from "primereact/column";

// import { Skeleton } from "primereact/skeleton";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../components/loader/Loader";
import { AddUsageThunk } from "../../../store/features/operation/sendusageunitsforInvoicing/addusage/slice";
import { GetInvoiceCustomerSubscriptionDetailsThunk } from "../../../store/features/operation/sendusageunitsforInvoicing/getInvoicecustomersubscriptiondetails/slice";
import { GetInvoiceDAVThunk } from "../../../store/features/operation/sendusageunitsforInvoicing/getinvoiceDAV/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { convertLocalDateToUTC } from "../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
// import dateFormatter from "../../../utils/date-formatter";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";
export default function SendAUMUsageUnitsForInvoicing() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const getRevision = useAppSelector((state) => state.GetRevisionList.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const productList = useAppSelector((state) => state.GetProdductList?.data);
  const subscriptionDetials = useAppSelector(
    (state) => state.GetInvoiceCustomerSubscriptionDetails.data
  );
  console.log("getRevision", getRevision);
  console.log("productList", productList);
  console.log("productList", subscriptionDetials);
  const [loading, setloading] = useState<any>(false);

  const formik = useFormik({
    initialValues: {
      Date: "",
      CustomerID: "",
      SubscriptionID: "",
      PriceID: "",
      dav: 0,
      GPValue: 0,
      InvestorsCash: 0,
      NetUsageAmount: 0,
      NetUsageUnits: "",
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter Date";
      }
      if (!data.NetUsageUnits) {
        errors.NetUsageUnits = "Usage units should be greater than zero.";
      }
      if (+data.NetUsageUnits.toString() <= 0) {
        errors.NetUsageUnits = "Usage units should be greater than zero.";
      }
      if (
        data.NetUsageUnits.includes(".") &&
        data.NetUsageUnits.split(".")[0].length >= 9
      ) {
        errors.NetUsageUnits = "Usage units should not be more then 9 digit.";
      }
      // if (
      //   data.NetUsageUnits.includes(".") &&
      //   +data.NetUsageUnits.toString() > 21474836.47
      // ) {
      //   errors.NetUsageUnits =
      //     "quantity : is greater than max value 21,474,836.47";
      // }
      // if (
      //   data.NetUsageUnits.includes(".") &&
      //   data.NetUsageUnits.split(".")[0].length > 8
      // ) {
      //   errors.NetUsageUnits =
      //     "round off usage units to " +
      //     data.NetUsageUnits.toString().split(".")[0] +
      //     " or " +
      //     (+data.NetUsageUnits.toString().split(".")[0] + 1);
      // }
      if (!data.NetUsageUnits.includes(".") && data.NetUsageUnits.length > 9) {
        errors.NetUsageUnits = "Usage units should not be more then 9 digit.";
      }
      console.log("data.usage", +data.NetUsageUnits.toString());
      return errors;
    },
    onSubmit: async () => {
      console.log("Entry", formik?.initialValues);
      setloading(true);
      const params = {
        LegalEntityId: fundDropdown.fundid,
        transactionDate: convertLocalDateToUTC(formik.values.Date),
        dailyAccountValue: formik.values.NetUsageUnits.includes(".")
          ? (+formik.values.NetUsageUnits).toFixed(2)
          : +formik.values.NetUsageUnits,
      };
      dispatch(AddUsageThunk(params))
        .then((res: any) => {
          setloading(false);
          console.log("dfghjk", res.payload);
          if (res.payload.success === true) {
            successToastMessage(toast, res.payload.message);
          } else {
            errorToastMessage(toast, res.payload.message);
          }
          // formik.setFieldValue("dav", "");
          // formik.setFieldValue("GPValue", "");
          // formik.setFieldValue("InvestorsCash", "");
          // formik.setFieldValue("Date", "");
          // formik.setFieldValue("NetUsageAmount", "");
          // formik.setFieldValue("NetUsageUnits", "");
          console.log("Exit", formik?.initialValues);
          formik.resetForm();
          formik.setFieldValue(
            "CustomerID",
            subscriptionDetials?.invoiceCustomerId
          );
          formik.setFieldValue("PriceID", subscriptionDetials?.itemPriceID);
          formik.setFieldValue(
            "SubscriptionID",
            subscriptionDetials?.subscriptionId
          );
        })
        .catch(() => {
          setloading(false);
          errorToastMessage(toast);
        })
        .finally(() => {
          setloading(false);
        });
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    if (formik.values.Date) {
      const reqBody = {
        LegalEntityId: fundDropdown.fundid,
        TransactionDate: convertLocalDateToUTC(formik.values.Date),
      };
      setloading(true);

      dispatch(GetInvoiceDAVThunk(reqBody))
        .then((res) => {
          const data = res.payload ? res.payload._response.data : [];
          formik.setFieldValue("dav", data!.dav ? data.dav : 0);
          formik.setFieldValue("GPValue", data.gpValue ? data.gpValue : 0);
          formik.setFieldValue(
            "InvestorsCash",
            data.investorsCashBalance ? data.investorsCashBalance : 0
          );

          const DAV_GPValue = data.dav - data.gpValue;
          const UsageUnits = DAV_GPValue / 10;

          formik.setFieldValue("NetUsageAmount", DAV_GPValue || 0);
          formik.setFieldValue(
            "NetUsageUnits",
            UsageUnits.toFixed(2) || "0.00"
          );
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    }
  }, [formik.values.Date]);

  // useEffect(() => {
  //   const reqBody = {
  //     LegalEntityId: fundDropdown.fundid,
  //   };
  //   setloading(true);
  //   dispatch(GetInvoiceCustomerSubscriptionDetailsThunk(reqBody))
  //     .then((res) => {
  //       const data = res.payload ? res.payload._response.data[0] : [];
  //       formik.setFieldValue(
  //         "CustomerID",
  //         data.invoiceCustomerId ? data.invoiceCustomerId : ""
  //       );
  //       formik.setFieldValue(
  //         "PriceID",
  //         data.itemPriceID ? data.itemPriceID : ""
  //       );
  //       formik.setFieldValue(
  //         "SubscriptionID",
  //         data.subscriptionId ? data.subscriptionId : ""
  //       );
  //       formik.setFieldValue("dav", "");
  //       formik.setFieldValue("GPValue", "");
  //       formik.setFieldValue("InvestorsCash", "");
  //       formik.setFieldValue("Date", "");
  //       formik.setFieldValue("NetUsageAmount", "");
  //       formik.setFieldValue("NetUsageUnits", "");
  //       setloading(false);
  //     })
  //     .catch(() => {
  //       setloading(false);
  //     });
  // }, []);
  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const reqBody = {
        LegalEntityId: fundDropdown.fundid,
      };
      setloading(true);

      dispatch(GetInvoiceCustomerSubscriptionDetailsThunk(reqBody))
        .then((res) => {
          const data = res.payload ? res.payload._response.data[0] : [];
          formik.setFieldValue(
            "CustomerID",
            data.invoiceCustomerId ? data.invoiceCustomerId : ""
          );
          formik.setFieldValue(
            "PriceID",
            data.itemPriceID ? data.itemPriceID : ""
          );
          formik.setFieldValue(
            "SubscriptionID",
            data.subscriptionId ? data.subscriptionId : ""
          );
          formik.setFieldValue("dav", 0);
          formik.setFieldValue("GPValue", 0);
          formik.setFieldValue("InvestorsCash", 0);
          formik.setFieldValue("Date", "");
          formik.setFieldValue("NetUsageAmount", 0);
          formik.setFieldValue("NetUsageUnits", "");
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    }
  }, [fundDropdown.fundid]);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Send AUM Usage Units For Invoicing</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card my-3 tabWithoutboxshadow">
            <Card>
              <>
                {" "}
                <div className="mb-4">
                  <div className="formgrid grid px-2 col">
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12">
                      <label className="inputLabel">Date *</label>
                      <div className="card flex justify-content-center">
                        <Calendar
                          name="Date"
                          value={formik.values.Date}
                          onChange={(e) => {
                            formik.setFieldValue("Date", e.value);
                          }}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("Date"),
                            "card-inputBox": true,
                          })}
                          placeholder="Date"
                          // dateFormat="mm-dd-yy"
                        />
                        {getFormErrorMessage("Date")}
                      </div>
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12 annualClass">
                      <label className="inputLabel">Invoice CustomerID </label>
                      <InputText
                        name="CustomerID"
                        value={formik.values.CustomerID}
                        onChange={(e) =>
                          formik.setFieldValue("CustomerID", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("CustomerID"),
                          "card-inputBox": true,
                        })}
                        disabled
                        // placeholder="Last Name"
                      />
                      {getFormErrorMessage("CustomerID")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12 annualClass">
                      <label className="inputLabel">
                        Invoice SubscriptionID
                      </label>
                      <InputText
                        name="SubscriptionID"
                        value={formik.values.SubscriptionID}
                        onChange={(e) =>
                          formik.setFieldValue("SubscriptionID", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("SubscriptionID"),
                          "card-inputBox": true,
                        })}
                        disabled
                        // placeholder="Last Name"
                      />
                      {getFormErrorMessage("SubscriptionID")}
                    </div>
                  </div>
                  <div className="formgrid grid px-2 col">
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12 annualClass">
                      <label className="inputLabel">
                        Invoice Item Price ID
                      </label>
                      <InputText
                        name="PriceID"
                        value={formik.values.PriceID}
                        onChange={(e) =>
                          formik.setFieldValue("PriceID", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("PriceID"),
                          "card-inputBox": true,
                        })}
                        disabled
                        // placeholder="Last Name"
                      />
                      {getFormErrorMessage("PriceID")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12 annualClass">
                      <label className="inputLabel">DAV ($)</label>
                      {/* <InputText
                        name="dav"
                        value={formik.values.dav}
                        onChange={(e) =>
                          formik.setFieldValue("dav", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("dav"),
                          "card-inputBox": true,
                        })}
                        disabled
                        // placeholder="Last Name"
                      /> */}

                      <InputNumber
                        name="dav"
                        value={formik.values.dav}
                        onValueChange={(e) =>
                          formik.setFieldValue("dav", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("dav"),
                          "w-100": true,
                        })}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        disabled
                      />
                      {getFormErrorMessage("dav")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12 annualClass">
                      <label className="inputLabel">GP Value ($)</label>
                      {/* <InputText
                        name="GPValue"
                        value={formik.values.GPValue}
                        onChange={(e) =>
                          formik.setFieldValue("GPValue", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("GPValue"),
                          "card-inputBox": true,
                        })}
                        disabled
                        // placeholder="Last Name"
                      /> */}

                      <InputNumber
                        name="GPValue"
                        value={formik.values.GPValue}
                        onValueChange={(e) =>
                          formik.setFieldValue("GPValue", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("GPValue"),
                          "w-100": true,
                        })}
                        disabled
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                      {getFormErrorMessage("GPValue")}
                    </div>
                  </div>
                  <div className="formgrid grid px-2 col">
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12 annualClass">
                      <label className="inputLabel">
                        Investors Cash Balance ($)
                      </label>
                      {/* <InputText
                        name="InvestorsCash"
                        value={formik.values.InvestorsCash}
                        onChange={(e) =>
                          formik.setFieldValue("InvestorsCash", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("InvestorsCash"),
                          "card-inputBox": true,
                        })}
                        disabled
                        // placeholder="Last Name"
                      /> */}

                      <InputNumber
                        name="InvestorsCash"
                        value={formik.values.InvestorsCash}
                        onValueChange={(e) =>
                          formik.setFieldValue("InvestorsCash", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("InvestorsCash"),
                          "w-100": true,
                        })}
                        disabled
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                      {getFormErrorMessage("InvestorsCash")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12 annualClass">
                      <label className="inputLabel">Net Usage Amount ($)</label>
                      {/* <InputText
                        name="NetUsageAmount"
                        value={formik.values.NetUsageAmount}
                        onChange={(e) =>
                          formik.setFieldValue("NetUsageAmount", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("NetUsageAmount"),
                          "card-inputBox": true,
                        })}
                        disabled
                        // placeholder="Last Name"
                      /> */}

                      <InputNumber
                        name="NetUsageAmount"
                        value={formik.values.NetUsageAmount}
                        onValueChange={(e) =>
                          formik.setFieldValue("NetUsageAmount", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("NetUsageAmount"),
                          "card-inputBox": true,
                        })}
                        disabled
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                      {getFormErrorMessage("NetUsageAmount")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 md:col-4 col-12">
                      <label className="inputLabel">Net Usage Units *</label>
                      <InputText
                        name="NetUsageUnits"
                        keyfilter="num"
                        value={formik.values.NetUsageUnits}
                        onChange={(e) =>
                          formik.setFieldValue("NetUsageUnits", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("NetUsageUnits"),
                          "card-inputBox": true,
                        })}
                        // placeholder="Last Name"
                      />
                      {getFormErrorMessage("NetUsageUnits")}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mb-3 mt-4">
                  <Button
                    className="mr-4 btn-nav w-8rem justify-content-center"
                    onClick={(e) => {
                      e.preventDefault();
                      formik.setFieldValue("dav", "");
                      formik.setFieldValue("GPValue", "");
                      formik.setFieldValue("InvestorsCash", "");
                      formik.setFieldValue("Date", "");
                      formik.setFieldValue("NetUsageAmount", "");
                      formik.setFieldValue("NetUsageUnits", "");
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="btn-nav w-8rem  justify-content-center"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("clicked");
                      formik.handleSubmit();
                    }}
                  >
                    Send Usage
                  </Button>
                </div>
              </>{" "}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
