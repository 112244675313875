import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { K1ReconciliationLPBreakdownService } from "../../../../../services/taxmanagement/tax";

import error from "./../../../../../utils/error";
import {
  IK1ReconciliationLPBreakdownState,
  IK1ReconciliationLPBreakdownparameters,
} from ".";

const initialState: IK1ReconciliationLPBreakdownState = {
  success: false,
  message: "",
  loading: "loading",
  data1: "",
  data2: "",
};
export const K1ReconciliationLPBreakdownThunk = createAsyncThunk(
  "TaxManagement/K1ReconciliationLPBreakdown",
  async (data: IK1ReconciliationLPBreakdownparameters) => {
    try {
      const response = await K1ReconciliationLPBreakdownService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "TaxManagement/K1ReconciliationLPBreakdown",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(K1ReconciliationLPBreakdownThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      K1ReconciliationLPBreakdownThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data1 = action.payload._response.data
          ? action.payload._response.data[0]?.[0]?.result
          : [];
        state.data2 = action.payload._response.data
          ? action.payload._response.data[1]?.[0]?.result
          : [];
      }
    );
    builder.addCase(K1ReconciliationLPBreakdownThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
