import { useField, useFormikContext } from "formik";
import { InputNumber, InputNumberChangeParams } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import React, { useEffect } from "react";

const InputNo = (props: any) => {
  const [field, meta] = useField(props.name);
  const { setFieldValue, submitCount } = useFormikContext();
  useEffect(() => {
    console.log("in effect", props);

    setFieldValue(props.name, props.value);
  }, [props.value]);
  console.log(submitCount, `meta ${props.name}`, meta);

  const handleChange = (e: InputNumberChangeParams) => {
    setFieldValue(props.name, e.value);
    props.handleChangeInput && props.handleChangeInput();
  };
  return (
    <>
      <InputNumber
        {...field}
        {...props}
        onChange={(e) => handleChange(e)}
        className={classNames({
          "p-invalid": (meta.touched || submitCount > 0) && meta.error,
          "card-inputBox": true,
          "p-filled": true,
        })}
      />
      {(meta.touched || submitCount > 0) && meta.error ? (
        <div className="p-error">{meta.error}</div>
      ) : (
        <></>
      )}
    </>
  );
};

export default InputNo;
