import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeleteUserRoleAccessService } from "./../../../../../../services/operation/operation";
import error from "./../../../../../../utils/error";
import { IDeleteUserRoleAccessState, IDeleteUserRoleAccessparameters } from ".";

const initialState: IDeleteUserRoleAccessState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const DeleteUserRoleAccessThunk = createAsyncThunk(
  "RoleManagement/DeleteUserRoleAccess",
  async (data: IDeleteUserRoleAccessparameters) => {
    try {
      const response = await DeleteUserRoleAccessService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "RoleManagement/DeleteUserRoleAccess",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(DeleteUserRoleAccessThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(DeleteUserRoleAccessThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data : [];
    });
    builder.addCase(DeleteUserRoleAccessThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
