import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetSetupEdgarReportService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { ISetupEdgarReportState } from ".";

const initialState: ISetupEdgarReportState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkSetupFundEdgarReport = createAsyncThunk(
  "getSetupFundEdgarReport",
  async (data: any) => {
    try {
      const response = await GetSetupEdgarReportService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getSetupFundEdgarReport",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkSetupFundEdgarReport.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkSetupFundEdgarReport.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      state.data =
        action.payload._response.data !== null
          ? action.payload._response.data.edgarDetails
          : [];
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(getThunkSetupFundEdgarReport.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
