import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPublicFileService } from "../../../../services/Filemanger/filemanager";
import error from "../../../../utils/error";
import { IFileManagerPublicFiles } from ".";

const initialState: IFileManagerPublicFiles = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    PublicFiles: [],
  },
};
export const getPublicFileListThunk = createAsyncThunk(
  "FileManagement/getSharedFilesOnDocType",
  async (data: any) => {
    try {
      const response = await getPublicFileService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "GetPrivateFileList",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getPublicFileListThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getPublicFileListThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(
        "action.payload._response.data==",
        action.payload.response.data.documents
      );
      state.data.PublicFiles = action.payload
        ? action.payload.response.data.documents
        : [];
    });
    builder.addCase(getPublicFileListThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
