import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { RootState } from "/=..";

import { getAllPortfolioInvestorListService } from "../../services/portfolio/portfolio";
import error from "../../utils/error";

// import { RootState } from "../../store";
// import { IPortfolio } from "../../types/index";
// import apiFactory from "../../utils/api";

interface IPortfolioState {
  data: any;
  dropdown?: [];

  loading: string;
  error?: string | null;
}

const initialState: IPortfolioState = {
  data: [],
  loading: "loading",
  error: undefined,
};

interface Iparameters {
  LegalEntityId: number;
}

export const getThunkPortfolioInvestorListDropdown = createAsyncThunk(
  "Portfolio/getInvestorListDropdown",
  async (data: Iparameters) => {
    try {
      const response = await getAllPortfolioInvestorListService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Portfolio/getInvestorListDropdown",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkPortfolioInvestorListDropdown.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      getThunkPortfolioInvestorListDropdown.fulfilled,
      (state, action) => {
        state.loading = "succeeded";

        state.data = action.payload.data.investorList;
      }
    );
    builder.addCase(
      getThunkPortfolioInvestorListDropdown.rejected,
      (state, action) => {
        state.loading = "failed";

        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});

// export const getAllPortfoliosData = (state: RootState) => {
//   return state.portfolio.data;
// };
// export const getAllOpportunitiesStatus = (state: RootState) =>
//   state.opportunity.loading;
// export const getAllOpportunitiesError = (state: RootState) =>
//   state.opportunity.error;
export default slice.reducer;
