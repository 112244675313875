import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

// import { Skeleton } from "primereact/skeleton";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { GetCapitalLossDetailsThunk } from "../../../store/features/taxmanagement/baddebtexpenses/getcapitallossdetails/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import currencyFormat from "../../../utils/currency-formatter";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../utils/date-formatter";
import { exportExcel } from "../../../utils/sponsor/helper";
// import dateFormatter from "../../../utils/date-formatter";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";
export default function BadDebtExpenses() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const previousYear: any = new Date().getFullYear();
  const currentYear = previousYear - 1;
  const [openDailog, setOpenDailog] = useState(false);

  const getRevision = useAppSelector(
    (state) => state.GetCapitalLossDetails?.data2
  );
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const GetMenuList = useAppSelector(
    (state) => state.GetCapitalLossDetails?.data
  );
  const [CapitalLossDetailData, setCapitalLossDetailData] = useState<any>([]);
  console.log("getRevision", getRevision);

  const [stateName, setName] = useState<any>(false);
  const [isLoading, setIsLoading] = useState(false); // State to control loader
  const [loading, setloading] = useState<any>(false);

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };
  const formik = useFormik({
    initialValues: {
      Date: currentYear.toString(),
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter year";
      }

      return errors;
    },
    onSubmit: async () => {
      setloading(true);
      setIsLoading(true); // Show loader
      const params = {
        LegalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };
      try {
        await dispatch(GetCapitalLossDetailsThunk(params));
        setName(false);
        setloading(false);
      } catch (error) {
        // Handle error if needed
        console.log(error);
      } finally {
        setIsLoading(false); // Hide loader regardless of success or failure
        setloading(false);
      }
    },
  });
  useEffect(() => {
    if (formik.values.Date.toString() === currentYear.toString()) {
      setloading(true);
      setIsLoading(true); // Show loader
      const params = {
        LegalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };
      try {
        dispatch(GetCapitalLossDetailsThunk(params));
        setName(false);
        setloading(false);
      } catch (error) {
        // Handle error if needed
        console.log(error);
      } finally {
        setIsLoading(false); // Hide loader regardless of success or failure
        setloading(false);
      }
    } else {
      setName(true);
    }
  }, [formik.values.Date, fundDropdown.fundid]);
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const dataTableElements = [
    {
      field: "lpPartnerID",
      header: "Partner Id",
      style: { minWidth: "270px" },
    },
    {
      field: "investorAccount",
      header: "Investor Account",
      style: { minWidth: "270px" },
    },
    {
      field: "productID",
      header: "Investment Id",
      style: { minWidth: "270px" },
    },
    {
      field: "productName",
      header: "Investment Name ",
      style: { minWidth: "270px" },
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
      style: { minWidth: "270px" },
    },
    {
      field: "allocateDate",
      header: "Allocate Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.allocateDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "270px" },
    },
    {
      field: "capitalInvested",
      header: "Capital Invested",
      body: (rowData: any) => currencyFormat(rowData.capitalInvested!),
      style: { minWidth: "270px" },
    },
    {
      field: "actualExitDate",
      header: "Investment Exit Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.actualExitDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "270px" },
    },
    {
      field: "totalPrincipalReturned",
      header: "Total Principal Returned",
      body: (rowData: any) => currencyFormat(rowData.totalPrincipalReturned!),
      style: { minWidth: "270px" },
    },
    {
      field: "capitalLoss",
      header: "Bad Debt Expense",
      body: (rowData: any) => currencyFormat(rowData.capitalLoss!),
      style: { minWidth: "270px" },
    },
  ];

  const dataTableElements2 = [
    {
      field: "productName",
      header: "Investor Account",
      style: { minWidth: "270px" },
    },
    {
      field: "capitalLoss",
      header: "Total Short Term Bad Debt Expense for Tax Year",
      body: (rowData: any) => currencyFormat(rowData.capitalLoss),
      style: { minWidth: "270px" },
    },
  ];

  let totalManagementFees = 0;
  let capitalInvested = 0;
  let totalPrincipalReturned = 0;
  let capitalLoss = 0;

  // Loop through each investor and accumulate the management fees
  if (getRevision) {
    for (const investor of getRevision!) {
      totalManagementFees += investor.capitalLoss;
    }
  }
  if (GetMenuList) {
    for (const investor of GetMenuList!) {
      capitalInvested += investor.capitalInvested;
      totalPrincipalReturned += investor.totalPrincipalReturned;
      capitalLoss += investor.capitalLoss;
    }
  }

  const formatCurrency = (value: any) => {
    const finalValue = Number(value);
    const parseValue = currencyFormat(Number(finalValue));

    return `${parseValue}`;
  };

  const footer = (
    <ColumnGroup>
      {/* {currentPage === totalPages - 1 ? ( */}
      <Row>
        <Column
          footer="Total:"
          colSpan={6}
          footerStyle={{ textAlign: "left" }}
        />
        {/* <Column footer={} /> */}
        <Column footer={formatCurrency(capitalInvested).toString()} />
        <Column footer="" colSpan={1} footerStyle={{ textAlign: "left" }} />
        <Column footer={formatCurrency(totalPrincipalReturned).toString()} />
        <Column footer={formatCurrency(capitalLoss).toString()} />
      </Row>
      {/* ) : (
        <></>
      )} */}
    </ColumnGroup>
  );

  const footerGroup = (
    <ColumnGroup>
      {/* {currentPage === totalPages - 1 ? ( */}
      <Row>
        <Column
          footer="Total:"
          colSpan={1}
          footerStyle={{ textAlign: "left" }}
        />
        {/* <Column footer={} /> */}
        <Column footer={formatCurrency(totalManagementFees).toString()} />
      </Row>
      {/* ) : (
        <></>
      )} */}
    </ColumnGroup>
  );

  useEffect(() => {
    if (GetMenuList !== undefined && GetMenuList.length > 0) {
      setCapitalLossDetailData(
        getListDateConversion(GetMenuList, ["allocateDate", "actualExitDate"])
      );
    } else {
      setCapitalLossDetailData([]);
    }
  }, [GetMenuList]);
  useEffect(() => {
    formik.resetForm();
  }, [fundDropdown.fundid]);
  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Bad Debt Expense</h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className=" col-12 md:col-3 flex gap-2 justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              onClick={() =>
                exportExcel(CapitalLossDetailData, "Bad Debt Expense")
              }
              data-pr-tooltip="exportExcel"
              disabled={
                CapitalLossDetailData === undefined ||
                CapitalLossDetailData?.length === 0 ||
                CapitalLossDetailData === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  CapitalLossDetailData === undefined ||
                  CapitalLossDetailData?.length === 0 ||
                  CapitalLossDetailData === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <>
              <div>
                <div className="formgrid grid col">
                  <div className="flex justify-content-between col-12">
                    {/* <label className="inputLabel">Year *</label> */}
                    <div className="card flex col-6 ">
                      <Calendar
                        name="Date"
                        value={
                          formik.values.Date === null ||
                          formik.values.Date === ""
                            ? undefined
                            : new Date(+formik.values.Date, 0, 1)
                        }
                        onChange={(e) => {
                          const selectedYear = new Date(
                            Number(e.value)
                          ).getFullYear();
                          formik.setFieldValue("Date", selectedYear);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Date"),
                          "card-inputBox": true,
                        })}
                        placeholder="Select year"
                        view="year"
                        dateFormat="yy"
                      />
                      {getFormErrorMessage("Date")}
                    </div>
                    <div className="d-flex  ">
                      <Button
                        className="mr-4 btn-nav md:w-14rem justify-content-center "
                        onClick={(e) => {
                          e.preventDefault();
                          formik.resetForm();
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        className="btn-nav md:w-14rem justify-content-center"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          console.log("clicked");
                          formik.handleSubmit();
                        }}
                      >
                        Show Bad Debt Expense
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>{" "}
            {GetMenuList && !stateName && (
              <div className="">
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    {" "}
                    <DataTableComponent
                      valueData={CapitalLossDetailData || []}
                      fieldsElements={dataTableElements}
                      isPaginationAllowed={true}
                      // isDownload={true}
                      footerElement={GetMenuList!.length > 0 && footer}
                      fileName="Bad Debt Expense"
                      scrollable={true}
                      className="gridcell"
                      columnResizeMode="expand"
                      maxHeight={"calc(-250px + 100vh)"}
                      isFullView={true}
                      setOpenDailog={setOpenDailog}
                      openDailog={openDailog}
                    />{" "}
                    <DataTableComponent
                      valueData={getRevision!}
                      fieldsElements={dataTableElements2}
                      isPaginationAllowed={true}
                      footerElement={getRevision!.length > 0 && footerGroup}
                      scrollable={true}
                      className="gridcell"
                      columnResizeMode="expand"
                      maxHeight={"calc(-250px + 100vh)"}
                      isFullView={true}
                      setOpenDailog={setOpenDailog}
                      openDailog={openDailog}
                    />
                  </>
                )}
              </div>
            )}
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
