// import { Image } from "primereact/image";
import React from "react";
import AvestorLogo from "../../../../images/AvestorGreenLogo.png";
import sideImg from "../../../../images/defaultSideImg.png";

const DefaultLogin = () => {
  const today = new Date();
  const formattedDate = `
   ${today.getFullYear()}`;
  return (
    <>
      <div className="defaultLogin">
        <div className="container text-start">
          <div className="dlogo-default">
            <img className=" img-fluid" src={AvestorLogo} />{" "}
          </div>
        </div>
      </div>

      <div className="container text-center ">
        <div className="grid d-flex flex-row align-content-center">
          <div className="col-12 md:col-6 lg:col-8 flex align-items-center justify-content-center">
            <div className="text-start wl-text containerText">
              <p>Welcome to avestor!</p>
              <p>Please use your funds login page to access your account.</p>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-4 flex align-items-start justify-content-start">
            <div>
              <img
                className=" img-fluid"
                src={sideImg}
                width="500"
                // height="350"
                style={{ borderRadius: "100% 0 0 100%" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="defaultFooter">
        <p className="mt-3">
          {" "}
          {`   © ${formattedDate} Avestor, Inc. All Rights Reserved
`}
        </p>
      </div>
    </>
  );
};

export default DefaultLogin;
