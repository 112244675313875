import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { addInvestmentStep1Service } from "../../../../services/investment/investment";
import {
  addSectionService,
  deleteSectionByIdService,
  getSectionByIdService,
} from "../../../../../../services/investment/investment";
import error from "../../../../../../utils/error";
import { ISectionData, ISectionState } from ".";

const initialState: ISectionState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    id: 0,
    productId: 0,
    sectionTitle: "",
    sectionContent: "",
    createdBy: 0,
    status: 0,
  },
};
export const addThunkSection = createAsyncThunk(
  "sectionwrite/addSection",
  async (data: ISectionData) => {
    try {
      const response = await addSectionService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const getThunkSectionById = createAsyncThunk(
  "sectionwrite/sectionById",
  async (data: any) => {
    try {
      const response = await getSectionByIdService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const deleteThunkSectionById = createAsyncThunk(
  "sectionwrite/deletesectionById",
  async (data: any) => {
    try {
      const response = await deleteSectionByIdService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "sectionwrite",
  initialState,
  reducers: {
    setSection: (state, action) => {
      state.data = action.payload;
      //
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(addThunkSection.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(addThunkSection.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("state", action.payload);
      if (action.payload.data !== null) {
        state.data = action.payload.data;
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(addThunkSection.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });

    builder.addCase(getThunkSectionById.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkSectionById.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("state", action.payload);
      if (action.payload.data !== null) {
        state.data = action.payload.data[0];
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(getThunkSectionById.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
    builder.addCase(deleteThunkSectionById.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(deleteThunkSectionById.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("state", action.payload);
      if (action.payload.data !== null) {
        state.data = action.payload.data[0];
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(deleteThunkSectionById.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});

export const { setSection } = slice.actions;
export default slice.reducer;
