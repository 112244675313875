import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Dialog } from "primereact/dialog";

// import { Dropdown } from "primereact/dropdown";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Row } from "primereact/row";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";

// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";
import DataTableComponent from "../../../components/data-table/DataTableComponent";

import Loader from "../../../components/loader/Loader";
// import { GetNonRegisteredInvestorListThunk } from "../../../store/Investor/setupinvestorfees/getnonregisteredinvestorlist/slice";
import { DistributeFeeThunk } from "../../../store/features/accounting/distributeexpensesinvestors/distributefee/slice";
import { CalculateFeeDetailsThunk } from "../../../store/features/accounting/distributemanagementees/calculatefeedetails/slice";
import { GetFeeDetailsThunk } from "../../../store/features/accounting/distributemanagementees/getfeedetails/slice";
import { SaveNotesThunk } from "../../../store/features/accounting/distributemanagementees/savenotes/slice";
import { getThunkPortfolioRegisterInvestorListDropdown } from "../../../store/features/dropdown/registeredinvestorlist/slice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import currencyFormat from "../../../utils/currency-formatter";
import {
  convertLocalDateToUTC,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
// import currencyFormat from ".../utils/currencyFormat";
// import dateFormatter from "../../../../utils/date-formatter";
export default function DistributeManagementFees() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const investorList = useAppSelector((state) => state.registerinvestor?.data);

  const processedValue = useAppSelector((state) => state.GetFeeDetails?.data);
  const [processedValueGrid, setProcessedValueGrid] = useState<any>([]);
  const dialogValue = useAppSelector(
    (state) => state.CalculateFeeDetails?.data
  );
  const [dialogCalculateValue, setDialogCalculateValue] = useState<any>([]);
  const portState = useAppSelector((state) => state.GetFeeDetails.loading);
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  console.log("dropdown", dropdown);
  const [alert, setAlert] = useState(false);
  const [visible, setVisible] = useState<any>(false);
  const [click, setClick] = useState<any>(false);
  const [error, setError] = useState<any>(false);
  const [TimeStampError, setTimeStampError] = useState<any>(false);
  // const [total, setTotal] = useState<any>();
  const [totalFeesState, setTotalFeesState] = useState<any>(0);
  const [monthYear, setMonthYear] = useState<any>();
  const [inv, setInv] = useState<any>();
  const [noteMessage, setNoteMessage] = useState<any>("");

  const [processedDate, setProcessedDate] = useState<any>();
  const [loading, setloading] = useState<any>(false);
  const [display, setDisplay] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);

  //   const profileData = useAppSelector((state) => state.profile.data![0]);
  //   const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const formatDate = (date: any) => {
    const dateFormat = formatDateMMDDYYY(date);
    return dateFormat;
  };
  const formik = useFormik({
    initialValues: {
      Date: "",
      Investor: "",
      Notes: "",
      TimeStamp: "",
      inv: "",
    },
    validate: (data: any) => {
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter Year";
      }

      if (data.inv === "") {
        errors.inv = "Please select investor";
      }

      return errors;
    },
    onSubmit: async (data: any) => {
      //

      setloading(true);
      const req = {
        LegalEntityId: fundDropdown.fundid,
        PartnerId: +formik.values.inv,
        Year: formik.values.Date,
      };
      dispatch(GetFeeDetailsThunk(req))
        .then(() => {
          setClick(true);
          setloading(false);
          setDisplay(true);
        })
        .catch(() => {
          setloading(false);
        });
    },
  });
  console.log(inv);
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const req = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(getThunkPortfolioRegisterInvestorListDropdown(req));
    }
  }, [fundDropdown]);

  useEffect(() => {
    formik.resetForm();

    setDisplay(false);
    setClick(false);
  }, [fundDropdown.fundid]);

  useEffect(() => {
    setDisplay(false);
    setClick(false);
  }, [formik.values.inv, formik.values.Date]);

  const formatCurrency = (rowData: any) => {
    const finalValue = Number(rowData.managementFee);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const reverseButton = (rowData: any) => {
    // Pass rowData as a parameter
    const inputDate = new Date(rowData.effectiveDate);
    const month = inputDate.getMonth() + 1;

    console.log("month", month);
    const req = {
      Month: month,
      // PartnerId: +dropdown.nonregisterid,
      PartnerId: +formik.values.inv,
      Year: formik.values.Date,
    };
    const handleReverse = (i: any) => {};
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog widthdeletebtn align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={() => {
              handleReverse(rowData.Id);

              setMonthYear(rowData.effectiveDate);
              setProcessedDate(rowData.processDate);
              setloading(true);
              console.log("pro", rowData.processDate);
              dispatch(CalculateFeeDetailsThunk(req))
                .then((res: any) => {
                  setVisible(true);
                })
                .finally(() => {
                  setloading(false);
                });

              formik.setFieldValue("Notes", rowData.notes);

              // setTotalFeesState(computeSum())
              // setTotal(rowData.managementFee);
            }}
          >
            <span className="text-white">Calculate</span>
          </Button>
        </div>
      </div>
    );
  };
  const [dialogOpen, setDialogOpen] = useState(false);

  const editButton = (rowData: any) => {
    const [notesValue, setNotesValue] = useState(rowData.notes);
    // Pass rowData as a parameter
    const [activeIndices, setActiveIndices] = useState<number[]>([]);

    const [isTyping, setIsTyping] = useState(false);
    useEffect(() => {
      setNotesValue(rowData.notes);
    }, [rowData.notes]);

    const handleOkClick = () => {
      setloading(true);

      const reqBody = {
        lpPartnerTranId: rowData.lpPartnerAccountTranID,
        notes: notesValue,
      };
      if (rowData.processDate) {
        dispatch(SaveNotesThunk(reqBody))
          .then(() => {
            setloading(false);
            setDialogOpen(false);

            const req = {
              LegalEntityId: fundDropdown.fundid,
              PartnerId: +formik.values.inv,
              Year: formik.values.Date,
            };
            dispatch(GetFeeDetailsThunk(req));
          })
          .catch(() => {
            setloading(false);
          })
          .finally(() => {
            setloading(false);
          });
      } else {
        setDialogOpen(true);
        console.log("notessss", rowData.notes);
        setNotesValue(rowData.notes);
        setloading(false);
      }
    };

    const handleCancelClick = () => {
      // Reset the input value to the original rowData.notes
      setNotesValue(rowData.notes);
      console.log("inside cancel");
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      // Update the state with the new input value
      setNotesValue(e.target.value);
      setIsTyping(true);
      const currentIndex = rowData.index;

      if (!activeIndices.includes(currentIndex)) {
        setActiveIndices([...activeIndices, currentIndex]);
      }
    };
    const handleCellClick = () => {
      // Set isTyping to false when another cell is clicked
      setIsTyping(false);
    };

    const isCurrentInputActive = activeIndices?.includes(rowData.index);

    return (
      <>
        <div>
          <div
            className={
              isTyping && isCurrentInputActive ? "NotesCellWithBtn" : ""
            }
            onClick={handleCellClick}
          >
            <div className="d-flex justify-content-center ">
              {" "}
              <InputText
                type="text"
                title={notesValue}
                value={notesValue}
                onChange={handleInputChange}
                // onFocus={() => setIsTyping(false)}
              />
            </div>
            <>
              {" "}
              {isTyping && isCurrentInputActive && (
                <div className="d-flex justify-content-center mt-2">
                  <Button
                    label="Save"
                    className="btn-dialog align-items-center justify-content-center mx-1"
                    onClick={handleOkClick}
                  />
                  <Button
                    label="Cancel"
                    className="btn-dialog align-items-center justify-content-center mx-1"
                    onClick={handleCancelClick}
                  />
                </div>
              )}
            </>
          </div>
        </div>
      </>
    );
  };

  const formatCurrencyAnyData = (rowData: any) => {
    const finalValue = Number(rowData);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };

  const computeSum = (
    totalFieldName: string
    // calculateDataForEarning = dialogValue
  ) => {
    const tableData: any = dialogValue;
    if (tableData !== undefined) {
      const { length } = tableData;

      let count = 0;
      for (let i = 0; i < length; i += 1) {
        count += Number(
          typeof tableData[i][totalFieldName] === "object"
            ? tableData[i][totalFieldName]
            : tableData[i][totalFieldName] || 0
        );
      }

      return count;
    }
  };

  const dataTableElements = [
    {
      field: "effectiveDate",
      header: "Fee Assessment Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.effectiveDate),
      filterElement: dateFilterTemplate,
    },
    // {
    //   field: "invName",
    //   header: "Investor Name",
    //   body: (rowData: any) => {
    //     setInv(rowData.invName);
    //   },
    // },
    {
      field: "managementFee",
      header: "Fees Allocated $",
      body: formatCurrency,
    },
    {
      field: "processDate",
      header: "Processed On",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.processDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "notes",
      header: "Notes",
      style: { minWidth: "300px" },
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
    },
    {
      field: "",
      header: "",
      body: (rowData: any) => reverseButton(rowData), // Pass rowData to editButton
    },
  ];
  const dataTableElements2 = [
    {
      field: "transactionDate",
      header: "Transaction Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.transactionDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "previousDAV",
      header: "Previous DAV",
      body: (rowData: any) => currencyFormat(rowData.previousDAV),
    },
    {
      field: "feePer",
      header: "Fees %",
      body: (rowData: any) => rowData.feePer + "%",
    },
    {
      field: "totalFees",
      header: "Fee $",
      body: (rowData: any) => currencyFormat(rowData.totalFees),
    },
  ];

  //   const resetForm = () => {
  //     setName("");
  //   };

  console.log("+dropdown.nonregisterid", investorList);
  const addAllocate = () => {
    const reqBody = {
      legalEntityId: fundDropdown.fundid,
      // partnerId: +profileData.partnerID,
      partnerId: +formik.values.inv,
      monthYear: convertLocalDateToUTC(monthYear),
      feeAmount: totalFeesState,
      timeStampDate:
        formik.values.TimeStamp &&
        convertLocalDateToUTC(formik.values.TimeStamp),
      notes: formik.values.Notes,
    };
    console.log("testing", reqBody);
    if (formik.values.Notes) {
      const req = {
        LegalEntityId: fundDropdown.fundid,
        PartnerId: +formik.values.inv,
        Year: formik.values.Date,
      };
      dispatch(DistributeFeeThunk(reqBody)).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          dispatch(GetFeeDetailsThunk(req));
          successToastMessage(toast, "Data allocated successfully");
          setVisible(false);
          formik.setFieldValue("TimeStamp", "");
          formik.setFieldValue("Notes", "");
        } else {
          errorToastMessage(toast);
        }
      });
      setError(false);
      setTimeStampError(false);
    } else {
      !formik.values.Notes && setError(true);
      !formik.values.TimeStamp && setTimeStampError(true);
    }
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={3} />
        {/* <Column footer={"$" + total} /> */}
        <Column footer={formatCurrencyAnyData(computeSum("totalFees"))} />
      </Row>
    </ColumnGroup>
  );

  useEffect(() => {
    if (dialogValue !== undefined && dialogValue.length > 0) {
      const countTotal: any = computeSum("totalFees");
      //  const TotalAmount = countTotal.split("$")[1];
      setTotalFeesState(+countTotal);
      console.log("testt", countTotal);
      if (countTotal <= 0) {
        setNoteMessage(
          "Note: Total Management Fee is $0. Please check before allocate."
        );
      } else {
        setNoteMessage("");
      }

      setDialogCalculateValue(
        getListDateConversion(dialogValue, ["transactionDate"])
      );
    }
  }, [dialogValue]);

  console.log("tets", dialogValue);
  const onHideHandle = () => {
    setError(false);
    formik.setFieldValue("TimeStamp", "");
    formik.setFieldValue("Notes", "");
    // formik.values.TimeStamp = undefined;
    setTimeStampError(false);
    setNoteMessage("");
    setTotalFeesState(0);
    setVisible(false);
  };
  useEffect(() => {
    if (processedValue !== undefined && processedValue.length > 0) {
      setProcessedValueGrid(
        getListDateConversion(processedValue, ["effectiveDate", "processDate"])
      );
    }
  }, [processedValue]);
  return (
    <>
      <div className="grid">
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">
              Charge management fee to investor account
            </h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                processedValueGrid === undefined ||
                processedValueGrid?.length === 0 ||
                processedValueGrid === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        <Dialog
          visible={dialogOpen}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setDialogOpen(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            You can only change when fee is processed.{" "}
          </h4>
          <div className=" align-self-center flex align-items-center justify-content-center">
            <Button
              onClick={() => setDialogOpen(false)}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto"
            >
              Ok
            </Button>
          </div>
        </Dialog>
        <Dialog
          visible={visible}
          className="dialog-width"
          onHide={() => onHideHandle()}
        >
          <div className="col-12">
            <div className="formgrid grid px-2">
              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
                <label className="inputLabel">Time Stamp Date :</label>
                <Calendar
                  name="TimeStamp"
                  value={formik.values.TimeStamp}
                  onChange={(e) => {
                    formik.setFieldValue("TimeStamp", e.value);
                    e.value && setTimeStampError(false);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("TimeStamp"),
                    "card-inputBox": true,
                  })}
                  // dateFormat="mm-dd-yy"
                />
                {TimeStampError && (
                  <>
                    <span className="text-red-500">Enter time stamp date </span>
                  </>
                )}{" "}
              </div>

              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
                <label className="inputLabel">Notes*: </label>

                <InputText
                  name="Notes"
                  value={formik.values.Notes}
                  onChange={(e) => {
                    formik.setFieldValue("Notes", e.target.value);
                    e.target.value && setError(false);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Notes"),
                    "card-inputBox": true,
                  })}
                  // placeholder="Last Name"
                />
                {error && (
                  <>
                    <span className="text-red-500">Enter note</span>
                  </>
                )}
              </div>
              {!processedDate && (
                <div className="d-block sm:d-flex sm:flex-column p-2 col mt-2">
                  <label className="inputLabel"> </label>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setAlert(false);
                      addAllocate();
                    }}
                    className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
                  >
                    Allocate
                  </Button>{" "}
                </div>
              )}

              <div className="d-block sm:d-flex sm:flex-column p-2 col mt-5">
                <label className="inputLabel"> </label>

                <span className="font-bold">{`Total Management Fee : ${formatCurrencyAnyData(
                  computeSum("totalFees")
                )}`}</span>
              </div>
            </div>

            <div className="flex justify-content-center font-bold mt-2">
              {noteMessage}
            </div>

            <div className="card tabWithoutboxshadow">
              {portState === "loading" ? (
                <Skeleton />
              ) : (
                <>
                  {" "}
                  {/* <Card> */}
                  <DataTableComponent
                    valueData={dialogCalculateValue || []}
                    fieldsElements={dataTableElements2}
                    isPaginationAllowed={true}
                    // isDownload={true}
                    footerElement={
                      dialogValue && dialogValue!.length > 0 && footerGroup
                    }
                    scrollable={true}
                    className="gridcell"
                    columnResizeMode="expand"
                    maxHeight={"calc(-250px + 100vh)"}
                  />
                  {/* </Card> */}
                </>
              )}
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={alert}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setAlert(false)}
        >
          <div className=" align-self-center flex align-items-center justify-content-center">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setAlert(false);
                formik.resetForm();
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Ok
            </Button>
          </div>
        </Dialog>

        <div className="col-12">
          <Card>
            <div className="formgrid grid">
              <div className="d-block sm:d-flex sm:flex-column md:col-6 col-5">
                <label className="inputLabel">Year *</label>
                <Calendar
                  name="Date"
                  value={
                    formik.values.Date === null || formik.values.Date === ""
                      ? undefined
                      : new Date(+formik.values.Date, 0, 1)
                  }
                  onChange={(e) => {
                    const selectedYear = new Date(
                      Number(e.value)
                    ).getFullYear();
                    formik.setFieldValue("Date", selectedYear);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Date"),
                    "card-inputBox": true,
                  })}
                  placeholder="Select year"
                  view="year"
                  dateFormat="yy"
                />
                {getFormErrorMessage("Date")}
              </div>
              <div className="d-block sm:d-flex sm:flex-column md:col-6 col-5 ">
                <label className="inputLabel">Investor Name *</label>
                <Dropdown
                  name="inv"
                  style={{ width: "100%" }}
                  options={investorList}
                  optionLabel="investorName"
                  filter
                  optionValue="investorID"
                  placeholder="Select Investor"
                  onChange={(e) => {
                    formik.setFieldValue("inv", e.value);
                    const selectedInvestor = investorList!.find(
                      (investor: any) => investor.investorID === e.value
                    );

                    // Log the investorName
                    console.log(
                      "Selected Investor Name:",
                      selectedInvestor?.investorName
                    );
                    setInv(selectedInvestor?.investorName);
                  }}
                  value={formik.values.inv}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("inv"),
                    "card-inputBox": true,
                  })}
                />
                {getFormErrorMessage("inv")}
              </div>
              <div className="d-flex justify-content-center col-2 align-items-center">
                <Button
                  className="mr-4 btn-nav w-7rem justify-content-center"
                  onClick={(e) => {
                    e.preventDefault();
                    // setIsFormSubmitted(true);
                    // console.log("clicked");
                    formik.resetForm();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  className="btn-nav w-7rem justify-content-center"
                  onClick={(e) => {
                    e.preventDefault();
                    // setIsFormSubmitted(true);
                    // console.log("clicked");
                    formik.handleSubmit();
                  }}
                >
                  Show
                </Button>
              </div>
            </div>
          </Card>

          {click && display && (
            // <div className="card tabWithoutboxshadow">
            <>
              {/* <h4 className="cardHeader mb-3">
                  {" "}
                  {inv}, {formik.values.Date}
                </h4> */}
              <DataTableComponent
                valueData={processedValueGrid || []}
                fieldsElements={dataTableElements} // Use the modified array
                isPaginationAllowed={true}
                scrollable={true}
                className="gridcell deletebtn"
                columnResizeMode="expand"
                resizableColumns={true}
                maxHeight={"calc(-250px + 100vh)"}
                isFullView={true}
                setOpenDailog={setOpenDailog}
                openDailog={openDailog}
                // isDownload={true}
              />
            </>
            // </div>
          )}
        </div>
      </div>
    </>
  );
}
