import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDistributeEarningsPooledFundsService,
  getInvestmentReturnsAndTypePooledfunds,
} from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IGetEarningandReturnTypeState } from "./type";

const initialState: IGetEarningandReturnTypeState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    investmentReturns: [],
    returnType: [],
  },
};
export const getThunkAllocateEarningPooledFund = createAsyncThunk(
  "getAllocateEarningPooledFund/getEarnings",
  async (data: any) => {
    try {
      const response = await getInvestmentReturnsAndTypePooledfunds(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const getThunkDistributeEarningPooledFund = createAsyncThunk(
  "getAllocateEarningPooledFund/distributeEarnings",
  async (data: any) => {
    try {
      const response = await getDistributeEarningsPooledFundsService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getAllocateEarningPooledFund",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkAllocateEarningPooledFund.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getThunkAllocateEarningPooledFund.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log("hr", action.payload._response);
        if (action.payload._response.data !== null) {
          state.data.investmentReturns =
            action.payload._response.data.investmentReturns;
          state.data.returnType =
            action.payload._response.data.investmentReturnType;
        }
        state.success = action.payload._response.success;
        state.message = action.payload._response.message;
      }
    );
    builder.addCase(
      getThunkAllocateEarningPooledFund.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
    builder.addCase(getThunkDistributeEarningPooledFund.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getThunkDistributeEarningPooledFund.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log("distribute", action.payload._response);
        if (action.payload._response.data !== null) {
          state.data.investmentReturns =
            action.payload._response.data.investmentReturns;
          state.data.returnType =
            action.payload._response.data.investmentReturnType;
        }
        state.success = action.payload._response.success;
        state.message = action.payload._response.message;
      }
    );
    builder.addCase(
      getThunkDistributeEarningPooledFund.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
