import axios from "axios";
import JsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";

import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// import imageData from "../../images/AvestorGreenLogo.png";
import RegisterDropdownComponent from "../../../components/dashboard-dropdown/RegisterDropdown";
import Loader from "../../../components/loader/Loader";
import { getAccountStatementThunk } from "../../../store/features/accountatatement/slice";
import { GetInvestorDetailsForAdmin } from "../../../store/features/get-investor-details-for-admin/slice";
import {
  // getFundLogoThunk,
  getUserProfileDetailsThunk,
} from "../../../store/features/profile/slice";
import { setImageState } from "../../../store/getasset/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
// import { getSasKeyThunk } from "../../../store/sas-key/slice";
import currencyFormat from "../../../utils/currency-formatter";
import { envVariable } from "../../../utils/sponsor/helper";

const AccountStatement = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dt = useRef<any>(null);

  //   const profileData = useAppSelector((s) => s.profile.data![0]);
  const stakeDetails = useAppSelector(
    (s) => s.getAccountStatement.stakeDetailsList
  );

  const partnerAccountsSummary = useAppSelector(
    (s) => s.getAccountStatement.partnerAccountsSummary
  );
  const investorPartnerData = useAppSelector(
    (s) => s.getAccountStatement.investorPartnerData
  );

  // const partnerAccountsDetails = useAppSelector(
  //   (s) => s.getAccountStatement.investorPartnerData
  // );
  const imageBanner = useAppSelector(
    (state) => state.getAssetResponseStatus.data
  );

  const [loading, setloading] = useState<any>(false);

  const color = useAppSelector((state) => state.color);
  // const profileData = useAppSelector((state) => state.profile.data![0]);-+
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const capitalAlloc: any[] = [];
  const earningToD: any[] = [];
  const returnToO: any[] = [];

  stakeDetails?.map((item) => {
    return capitalAlloc.push(item.capitalAllocated);
  });

  const assetStat = partnerAccountsSummary?.filter((item) => {
    return item.amount > 0;
  });

  stakeDetails?.map((item) => {
    return earningToD.push(item.earnings);
  });
  stakeDetails?.map((item) => {
    return returnToO.push(item.percentEarningReturn);
  });

  const capitalAllocated = capitalAlloc.reduce((acc, num) => acc + num, 0);
  const earningToDate = earningToD.reduce((acc, num) => acc + num, 0);
  // const percentEarningReturn = returnToO.reduce((acc, num) => acc + num, 0);

  const formatCurrency = (value: string) => {
    const finalValue = Number(value);
    const parseValue = currencyFormat(Number(finalValue));

    return `${parseValue}`;
  };
  const formatPercent = (value: any) => `${value} %`;
  const [currentPage, setCurrentPage] = useState(0);

  const onPage = (event: any) => {
    setCurrentPage(event.first / event.rows);
  };

  // useEffect(() => {
  //   const parameter = {
  //     InvestorId: fundDropdown.registerid,
  //     LegalEntityId: fundDropdown.fundid,
  //   };
  //   const parameters = {
  //     LPPartnerId: fundDropdown.registerid,
  //     LegalEntityId: fundDropdown.fundid,
  //   };
  //   setloading(true);
  //   dispatch(getAccountStatementThunk(parameters))
  //     .then(() => {
  //       setloading(false);
  //     })
  //     .catch(() => {
  //       setloading(false);
  //     });

  //   dispatch(GetInvestorDetailsForAdmin(parameter))
  //     .then(() => {
  //       setloading(false);
  //     })
  //     .catch(() => {
  //       setloading(false);
  //     });
  // }, []);
  useEffect(() => {
    if (
      fundDropdown.fundid !== undefined &&
      fundDropdown.fundid > 0 &&
      fundDropdown.registerid !== undefined
      // &&
      // fundDropdown.registerid > 0
    ) {
      const parameters = {
        LPPartnerId: fundDropdown.registerid,
        LegalEntityId: fundDropdown.fundid,
      };
      setloading(true);
      dispatch(getAccountStatementThunk(parameters))
        .then((res: any) => {
          setloading(false);
          const data = res.payload._response.data.investorPartnerData[0];
          const parameter = {
            InvestorId: data.investorID,
            LegalEntityId: fundDropdown.fundid,
          };
          dispatch(GetInvestorDetailsForAdmin(parameter))
            .then(() => {
              setloading(false);
            })
            .catch(() => {
              setloading(false);
            });
        })
        .catch(() => {
          setloading(false);
        });
    }
  }, [fundDropdown.fundid, fundDropdown.registerid]);

  const doucmentCompleteNameTemplate = (item: any) => {
    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          // const url = `../../OpportunityDetails/${item.productID}?from=Account Statement`;
          localStorage.setItem("navigationPage", "Account Statement");

          navigate(`../../OpportunityDetails/${item.productID}`);
          // window.history.replaceState({}, document.title, url);
          // navigate(`../../OpportunityDetails/${item.productID}`);
        }} // style={{ textDecoration: "underline" }}
        className="tabHeadText footerGridtext cursor-pointer"
      >
        {item.productName}
      </span>
    );
  };

  const cols: any[] = [
    { field: "amountType", header: "" },
    { field: "amount", header: "" },
  ];

  const col2: any[] = [
    {
      field: "productName",
      header: "Investment Name",
    },
    {
      field: "capitalAllocated",
      header: "Capital Allocated",
    },
    {
      field: "earnings",
      header: "Earnings to Date",
    },
    { field: "percentEarningReturn", header: "% Return to Date" },
    { field: "earningsPayoutTiming", header: "Earnings Timing" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportColumns2 = col2.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  useEffect(() => {
    const tenentName = localStorage.getItem("tenentName");
    dispatch(getUserProfileDetailsThunk({ Slug: tenentName! })).then((res) => {
      if (res.payload?._response?.data !== null) {
        // alert(res.payload?._response?.data[0]?.legalEntityID);
        const BaseUrl = envVariable!.REACT_APP_API_ASSETS_SPNG_URL;

        // dispatch(getFundLogoThunk()).then((resp) => {
        //   console.log("loading not working", resp);
        // });

        // console.log("loading not working");

        // dispatch(
        //   getSasKeyThunk(res.payload?._response?.data[0]?.legalEntityID)
        // ).then((response: any) => {
        // console.log("=== test ===");
        // if (response.meta.requestStatus === "fulfilled") {
        //   const key = response.payload.data.sasKey;
        axios
          .get(
            `${BaseUrl}/Assets/getFundLogo?fundName=${
              imageBanner.url ? imageBanner.url : tenentName
            }
            `
          )
          .then((resp) => {
            console.log("base", resp.data.response.data.blobURL);

            dispatch(setImageState(resp.data.response.data.blobURL));
            if (resp.status === 200) {
              // setLogoStatus(true);
            }
            // console.log(resp.data.response.data.blobURL);
          });
        //   }
        // });
      }
    });
  }, []);

  console.log("imageBanner.image", imageBanner.image);
  const handleDownload = () => {
    const doc = new JsPDF("p", "pt");
    const today = new Date();

    const img = new Image();
    img.src = imageBanner.image;
    console.log("imageBanner.image-----", img);

    // const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    // const day = String(today.getDate()).padStart(2, "0");
    // const year = String(today.getFullYear()).slice(2);
    const formattedDate = `${
      today.getMonth() + 1
    }/${today.getDate()}/${today.getFullYear()}`;

    function addBorderToPage() {
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHei = doc.internal.pageSize.getHeight();

      const lineWidth = 2; // Adjust the line width as needed
      doc.setLineWidth(lineWidth);
      doc.setDrawColor(0, 0, 0); // RGB values for black

      // Draw top border
      doc.line(10, 10, pageWidth - 10, 10);

      // Draw right border
      doc.line(pageWidth - 10, 10, pageWidth - 10, pageHei - 10);

      // Draw bottom border
      doc.line(10, pageHei - 10, pageWidth - 10, pageHei - 10);

      // Draw left border
      doc.line(10, 10, 10, pageHei - 10);
    }
    addBorderToPage();

    // doc.addImage(blobImage, "png", 375, 20, 0, 50);
    const pageWidth = doc.internal.pageSize.getWidth();
    const imageWidth = 150; // Adjust the image width as needed
    const imageHeight = 0; // Adjust the image height as needed
    const imageX = pageWidth - imageWidth - 45; // Calculate the X-coordinate position

    // Add the image using the calculated position
    if (imageBanner.image.includes("undefined")) {
      //
    } else {
      doc.addImage(img, "png", imageX, 20, imageWidth, imageHeight);
    }

    doc.setFontSize(10);
    doc.setFont("", "bold"); // Set the font weight to bold
    doc.text(`Statement Date:`, 45, 30);
    doc.text(`Account Holder Name:`, 45, 45);
    doc.text(`Account Holder Address:`, 45, 60);
    doc.setFont("", "normal"); // Set the font weight back to norma
    doc.text(`${formattedDate}`, 120, 30);
    doc.text(
      `${investorPartnerData?.investorName} 
      `,
      150,
      45
    );
    const maxWidthh = 220; // Maximum width for the address text
    const liness = doc.splitTextToSize(
      `${investorPartnerData?.investorAddress1}, ${investorPartnerData?.investorAddress2}`,
      maxWidthh
    );
    doc.text(liness, 160, 60);

    doc.setFontSize(15); // Set a different font size
    doc.text("Account Summary", 45, 130);
    doc.setFontSize(10);

    autoTable(doc, {
      columns: exportColumns,
      body: partnerAccountsSummary!,
      margin: { top: 140 }, // Optional: Set the margin.top value to 20 units
      tableWidth: 250,
      didParseCell: (data: any) => {
        if (data.column.dataKey === "amount") {
          const value = data.cell.raw;
          data.cell.text = formatCurrency(value);
        }

        data.cell.styles.lineWidth = 1;
      },
    });
    // doc.addPage();
    let pageCount = 0; // Variable to store the page count
    let tableHeight = 0; // Variable to store the table height
    let startY = 50;

    // let rowdata = 0;
    // console.log("stakeDetails", stakeDetails);

    // Table 2
    autoTable(doc, {
      columns: exportColumns2,
      body: stakeDetails!,
      headStyles: {
        fillColor: color.Color, // Set the fill color of the header to red (RGB)
        textColor: [255, 255, 255], // Set the text color of the header to white (RGB)
        fontStyle: "bold", // Set the font style of the header to bold
      },
      showFoot: "lastPage",
      foot: [
        // Array representing the first row in the foot section
        [
          "Total (Earnings to Date) :",
          `${Number(capitalAllocated).toFixed(2)}`,
          `${Number(earningToDate).toFixed(2)}`,
          "",
          "",
        ],

        // Array representing the second row in the foot section
      ],
      footStyles: {
        fillColor: color.Color, // Set the fill color of the header to red (RGB)
        textColor: [255, 255, 255], // Set the text color of the header to white (RGB)
        fontStyle: "bold", // Set the font style of the header to bold
      },
      margin: { top: 50 }, // Optional: Set the margin.top value to 20 units
      didParseCell: (data: any) => {
        const row = data.row.index;
        // const col = data.column.index;
        // const tHead = data.doc.internal.getCurrentPageInfo();
        // console.log("exportColumns2", data);
        // rowdata++;

        if (row === 0) {
          data.cell.styles.lineWidth = 1;
        }
        if (row > 0) {
          data.cell.styles.lineWidth = 1;
        }
        if (data.column.dataKey === "capitalAllocated") {
          const value = data.cell.raw;
          // data.cell.text = "$" + value;
          const perVal =
            value !== "Capital Allocated" ? currencyFormat(value) : "$" + value;

          // data.cell.text = value + "%";
          data.cell.text = perVal.replaceAll("$Capital", "Capital");
          if (value === "") {
            data.cell.text = perVal.replaceAll("$", "");
          }

          // data.cell.text = perVal.replaceAll("$Earnings", "Earnings");
        }
        if (data.column.dataKey === "earnings") {
          const value = data.cell.raw;
          console.log("Value==>", value);
          // data.cell.text = "$" + value;
          // console.log("data.sejebsk", data.column.raw);
          // console.log("data.sejebsk", data.cell);
          if (data.cell.section !== "head") {
            const perVal =
              value !== "Earnings to Date"
                ? currencyFormat(value)
                : "$" + value;
            data.cell.text = perVal.replaceAll("$Earnings", "Earnings");
          } else {
            const perVal =
              value !== "Earnings to Date"
                ? currencyFormat(value)
                : "$" + value;
            data.cell.text = perVal.replaceAll("$Earnings", "Earnings");
          }
          // const perVal = "$" + value;

          // data.cell.text = value + "%";

          // data.cell.text = perVal.replaceAll("$Earnings", "Earnings");
        } else if (data.column.dataKey === "percentEarningReturn") {
          const value = data.cell.raw;
          const perVal = value + "%";

          // data.cell.text = value + "%";
          data.cell.text = perVal.replaceAll("Date%", "Date");

          if (value === "") {
            data.cell.text = perVal.replaceAll("%", "");
          }

          // data.header.text = headerText.trim();
          // console.log(" data.cell.text", data.cell.text);
        } else {
          // empty
        }

        // if (row === 0) {
        //   data.cell.styles.fillColor = color.Color; // Set the fill color of the header
        //   data.cell.styles.textColor = 255; // Set the text color of the header
        //   data.cell.styles.fontStyle = "bold"; // Set the font style of the header
        //   data.cell.styles.fontSize = 12; // Set the font size for header cells
        // }
      },

      didDrawPage: (dataArg: any) => {
        pageCount++; // Increment the page count
        tableHeight = dataArg.cursor.y - startY; // Calculate the table height
      },
    });

    for (let i = 2; i <= pageCount; i++) {
      doc.setPage(i);
      addBorderToPage();
    }
    const currentPageNumber = pageCount;
    if (currentPageNumber === pageCount) {
      // Set the font size and alignment for the appended text
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      // doc.text("Appended Text", 50, y); // Modify the text and position as needed
      const additionalText = `
      1. Past performance is not indicative or a guarantee of future results. Any historical returns, expected returns, or probability projections may not reflect actual future performance.
      2. Investments in private placements are speculative and involve a high degree of risk and those investors who cannot afford to lose their entire investment should not invest.
      3. The value of your investments and account values will fluctuate over time and you may gain or lose money. Account values are derived from the initial purchase price of each investment. The current value of each investment may be higher or lower at any given time and the final value of the investment at exit can be lower.
    `;
      const additionalTextHesder = `
    Other Important Information:
  `;

      const maxWidth = doc.internal.pageSize.getWidth() - 60; // Adjust the padding as needed
      const lines = doc.splitTextToSize(additionalText, maxWidth);
      const linesHeader = doc.splitTextToSize(additionalTextHesder, maxWidth);

      // Calculate the y-coordinate where you want to append the text
      const pageHeight = doc.internal.pageSize.getHeight();
      const remainingHeight = pageHeight - (startY + tableHeight + 20); // Add some additional spacing after the table
      startY += remainingHeight > 100 ? tableHeight + 30 : pageHeight - 100;

      // Add the lines of text to the PDF
      if (remainingHeight > 150) {
        doc.setFontSize(12);
        doc.text(
          linesHeader,
          30,
          remainingHeight > 100 ? tableHeight + 60 : pageHeight - 100,
          {
            align: "left",
          }
        );
        doc.setFontSize(10);
        doc.text(lines, 40, startY, { align: "left" });
      } else {
        doc.addPage();
        addBorderToPage();

        // Add the lines of text to the new page
        const newStartY = 50;
        // const newRemainingHeight = pageHeight - newStartY;
        // const newTableHeight = newRemainingHeight - 30;

        doc.setFontSize(12);
        doc.text("Other Important Information:", 30, newStartY, {
          align: "left",
        });

        doc.setFontSize(10);
        const maxWidt = doc.internal.pageSize.getWidth() - 60; // Adjust the padding as needed
        const line = doc.splitTextToSize(additionalText, maxWidt);
        doc.text(line, 40, newStartY + 20, {
          align: "left",
        });
      }
    }

    doc.save("Account Statement.pdf");
  };

  // const totalRecords = stakeDetails!.length;
  // const totalPages = Math.ceil(totalRecords / 10);
  //
  console.log("Stake", stakeDetails!.length);
  const footerGroup = (
    <ColumnGroup>
      {stakeDetails?.length !== 0 && (
        <Row>
          <Column
            footer="Total:"
            colSpan={1}
            footerStyle={{ textAlign: "left" }}
          />
          <Column footer={formatCurrency(capitalAllocated)} />
          <Column footer={formatCurrency(earningToDate)} />
          <Column footer />
          <Column footer />
        </Row>
      )}
    </ColumnGroup>
  );

  const rowDta = 10;

  return (
    <div>
      {loading && <Loader />}

      {/* <form className=" h-100" action="#">
        <div className="tabHeader-wrap">
          <NavLink to="ViewDocuments" className="tabHeadText Active">
            Account Statement{" "}
          </NavLink>
        </div>
      </form> */}
      <div>
        <div id="root" className="grid ">
          <div className="col-12 md:col-6 mt-2">
            <div className="card pgHeaderWrap md:my-2 mt-3">
              <h1 className="pageHeader">Account Statement</h1>
              <span className="pageSubHeader">
                View and download your account statement{" "}
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 mt-3">
            <div className="register-dropdown-wrapper">
              <RegisterDropdownComponent
                showLimitedPartnerDropdown={true}
                showRegisteredInvestorDropdown={false}
              />
            </div>
          </div>
          <div className="col-12 md:col-6 mt-2">
            <div ref={dt}></div>
          </div>
        </div>

        <div className=" h-100">
          <div className="col-12 ">
            <div className="card my-3 ">
              <Card>
                <>
                  <div className="md:flex justify-content-center md:justify-content-between col-12">
                    <h4 className="cardHeader pb-1 col-12 md:col-9">
                      Account Statement
                    </h4>
                    {stakeDetails?.length !== 0 || assetStat?.length !== 0 ? (
                      <Button
                        className="blueButtonShadow px-5 col-12 md:col-3"
                        type="button"
                        // icon="pi pi-file"
                        // rounded
                        onClick={handleDownload}
                        data-pr-tooltip="CSV"
                        // title="akanksha"
                      >
                        {" "}
                        <span className="blueButtonShadowText">
                          {" "}
                          Download Statement{" "}
                        </span>
                      </Button>
                    ) : (
                      <Button
                        className="blueButtonShadow px-5 col-12 md:col-3"
                        type="button"
                        // icon="pi pi-file"
                        // rounded
                        disabled
                        // onClick={handleDownload}
                        data-pr-tooltip="CSV"
                        // title="akanksha"
                      >
                        {" "}
                        <span className="blueButtonShadowText">
                          {" "}
                          Download Statement{" "}
                        </span>
                      </Button>
                    )}
                  </div>
                  <p className="mb-0 mt-2 pb-2 ml-2 font-bold">
                    Account Statement
                  </p>
                  <div
                    className="gridoverlapscroll"
                    style={{
                      maxHeight: "calc(-57px + 100vh)",
                      // overflowX: "auto",
                      // overflowY: "auto",
                      width: "60%",
                    }}
                  >
                    {" "}
                    <DataTable
                      ref={dt}
                      value={partnerAccountsSummary}
                      // header={header}
                      className="gridcell deletebtn"
                      responsiveLayout="scroll"
                      columnResizeMode="expand"
                      resizableColumns={true}
                    >
                      <Column field="amountType" header={false}></Column>
                      <Column
                        field="amount"
                        header={false}
                        body={(rowData) => formatCurrency(rowData.amount)}
                      ></Column>
                    </DataTable>
                  </div>
                  <p className="mb-0 mt-2 pt-2 pb-2 font-bold">
                    Active Investments
                  </p>

                  <div
                    className="gridoverlapscroll"
                    style={{
                      maxHeight: "calc(-57px + 100vh)",
                      // overflowX: "auto",
                      // overflowY: "auto",
                    }}
                  >
                    {" "}
                    <DataTable
                      paginator={stakeDetails!.length > rowDta}
                      rows={10}
                      className="gridcell deletebtn"
                      columnResizeMode="expand"
                      resizableColumns={true}
                      value={stakeDetails}
                      showGridlines
                      responsiveLayout="scroll"
                      ref={dt}
                      footerColumnGroup={footerGroup}
                      first={currentPage * 10}
                      onPage={onPage}
                    >
                      <Column
                        field="productName"
                        header="Investment Name"
                        body={doucmentCompleteNameTemplate}
                        sortable
                        filter
                      ></Column>
                      <Column
                        field="capitalAllocated"
                        header="Capital Allocated"
                        body={(rowData) =>
                          formatCurrency(rowData.capitalAllocated)
                        }
                        sortable
                        filter
                      ></Column>
                      <Column
                        field="earnings"
                        header="Earnings to Date"
                        body={(rowData) => formatCurrency(rowData.earnings)}
                        sortable
                        filter
                      ></Column>
                      <Column
                        field="percentEarningReturn"
                        header="% Return to Date"
                        body={(rowData) =>
                          formatPercent(rowData.percentEarningReturn)
                        }
                        sortable
                        filter
                      ></Column>

                      <Column
                        field="earningsPayoutTiming"
                        //   body={downloadLinkTemplate}
                        header="Earnings Timing"
                        sortable
                        filter
                      ></Column>
                    </DataTable>
                  </div>

                  <div className="flex flex-column mt-5 fa-bold">
                    <span className="cardHeaderWithoutIcon mb-2">
                      {" "}
                      Other Important Information:
                    </span>
                    <span>
                      {" "}
                      1. Past performance is not indicative or a guarantee of
                      future results. Any historical returns, expected returns,
                      or probability projections may not reflect actual future
                      performance.
                    </span>
                    <span>
                      {" "}
                      2. Investments in private placements are speculative and
                      involve a high degree of risk and those investors who
                      cannot afford to lose their entire investment should not
                      invest.
                    </span>
                    <span>
                      {" "}
                      3. The value of your investments and account values will
                      fluctuate over time and you may gain or lose money.
                      Account values are derived from the initial purchase price
                      of each investment. The current value of each investment
                      may be higher or lower at any given time and the final
                      value of the investment at exit can be lower.
                    </span>
                  </div>
                </>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountStatement;
