import { InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { memo, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { b2cPolicies } from "../../b2c/b2c-config";
import Enrollment from "../../pages/enrollment/enrollment";
import DashboardSkeleton from "../../pages/features/DashboardSkeleton";
import DefaultLogin from "../../pages/features/b2c/login/DefaultLogin";
import { useAppSelector } from "../../store/hooks";
import { convertToLowerCase } from "../../utils/helper";
import {
  CHANGEPASSWORD,
  FORGOTPASSWORD,
  LOGIN,
  LOGOUT,
  TOKEN,
} from "../../utils/sponsor/constants";
import DashboardPage from "../routes/DashboardPage";

interface IProps {
  pageName: any;
}

const MSAL = (props: IProps) => {
  const logoLoadingStatus = useAppSelector(
    (state) => state.profile.loadingFundlogo
  );
  // const profileLoadingStatus = useAppSelector((state) => state.profile.loading);
  const isFundExist = useAppSelector((state) => state.isFundExist.data);
  const isAccountExist = useAppSelector((state) => state.isAccountExist.data);
  const isAccountExistLoadingState = useAppSelector(
    (state) => state.isAccountExist.loading
  );
  const isFundExistisLoding = useAppSelector(
    (state) => state.isFundExist.loading
  );
  const loading = useAppSelector((state) => state.isFundExist.loading);
  const { slug } = useParams();
  const { instance, inProgress, accounts } = useMsal();
  let authorityVar = "";
  if (props.pageName === FORGOTPASSWORD) {
    authorityVar = b2cPolicies.authorities.forgotPassword.authority;
  } else if (props.pageName === CHANGEPASSWORD) {
    authorityVar = b2cPolicies.authorities.changePassword.authority;
  }
  let parameters;

  let tenantName: any = slug;
  // const onlyFirstTime = true;
  // const tokenval = localStorage.getItem("token");

  // alert(`tenantName MSAL, ${props.pageName}`);

  // msalRequest - create parameter object and redirect to the page
  function msalRequest(slugName: string) {
    if (props.pageName === LOGOUT) {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/" + slugName,
      });
    } else {
      if (inProgress === InteractionStatus.None) {
        parameters = {
          authority: authorityVar,
          scopes: [],
          extraQueryParameters: { tenant: slugName! },
        };
        instance.loginRedirect(parameters).catch((error: any) => {
          console.log(error);
        });
      }
    }
  }
  function login() {
    if (slug) {
      localStorage.setItem("tenentName", convertToLowerCase(slug!));
    } else {
      localStorage.setItem("tenentName", "noslug");
      tenantName = localStorage.getItem("tenentName")!;
    }
    useEffect(() => {
      if (isFundExist) {
        if (tenantName !== "null" && tenantName !== "noslug") {
          msalRequest(tenantName);
        } else {
          console.log("slugname not provided ====================");
        }
      }
    }, [isFundExist]);
  }
  function forgotPassword() {
    if (slug) {
      localStorage.setItem("tenentName", convertToLowerCase(slug!));
    } else {
      // localStorage.setItem("tenentName", localStorage.getItem("tenentName")!);
      tenantName = localStorage.getItem("tenentName")!;
    }
    useEffect(() => {
      if (tenantName !== "null") {
        msalRequest(tenantName);
      } else {
        console.log("slugname not provided");
      }
    }, []);
  }

  const logout = () => {
    useEffect(() => {
      tenantName = localStorage.getItem("tenentName");
      localStorage.removeItem("token");
      msalRequest(tenantName);
    }, []);
  };
  const changePassword = () => {
    useEffect(() => {
      const token = localStorage.getItem("token");
      tenantName = localStorage.getItem("tenentName");

      if (token) {
        msalRequest(tenantName!);
      } else {
        tenantName = localStorage.getItem("tenentName");
        // localStorage.removeItem("tenentName");
        localStorage.removeItem("token");
        logout();
      }
    }, []);
  };
  useEffect(() => {
    const link = document.querySelector('link[rel="icon"]');

    if (link) {
      link.setAttribute("href", "/favicon.ico");
    }
  }, []);

  // getToken- set the token to localStorage
  const getToken = useCallback(() => {
    const request = {
      scopes: [],
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(request)
      .then((response: any) => {
        localStorage.setItem("token", response.idToken);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [accounts]);

  function tokenInterval() {
    useEffect(() => {
      // const interval = setInterval(() => getToken(), 3000 * 100 * 10);
      // 45 min timer

      const interval = setInterval(() => getToken(), 2700 * 100 * 10);

      return () => clearInterval(interval);
    }, [getToken]);
  }

  useEffect(() => {
    getToken();
  }, []);

  switch (props.pageName) {
    case FORGOTPASSWORD: {
      forgotPassword();
      break;
    }
    case CHANGEPASSWORD: {
      changePassword();
      break;
    }
    case LOGIN: {
      login();
      break;
    }
    case LOGOUT: {
      logout();
      break;
    }
    case TOKEN: {
      tokenInterval();
      break;
    }
  }
  let content;
  props.pageName === TOKEN
    ? (content = (
        <>
          <AuthenticatedTemplate>
            {isFundExistisLoding === "succeeded" &&
            isAccountExistLoadingState === "succeeded" ? (
              <>
                {(isAccountExist?.account === false ||
                  isAccountExist?.isSponsor === false ||
                  isAccountExist?.isRegisteredFund === false) &&
                isAccountExist !== undefined ? (
                  <Enrollment
                    isSponsor={isAccountExist.isSponsor}
                    isRegisteredFund={isAccountExist.isRegisteredFund}
                  />
                ) : logoLoadingStatus === "succeeded" ? (
                  <DashboardPage />
                ) : (
                  <DashboardSkeleton />
                )}
              </>
            ) : (
              <DashboardSkeleton />
            )}
          </AuthenticatedTemplate>
        </>
      ))
    : (content = (
        <>
          {loading === "succeeded" ? (
            isFundExist ? (
              <ProgressSpinner />
            ) : (
              <DefaultLogin />
            )
          ) : (
            <ProgressSpinner />
          )}
        </>
      ));
  return <>{content}</>;
};
export default memo(MSAL);
