// import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import React, { useEffect, useState } from "react";

import DataTableComponent from "../../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../../components/loader/Loader";
import { GetCountriesThunk } from "../../../../store/features/investor/managecountrymaster/getcountries/slice";
import { UpdateCountryStatusThunk } from "../../../../store/features/investor/managecountrymaster/updatecountrystatus/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getListDateConversion } from "../../../../utils/date-formatter";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function ManageCountryMaster() {
  const dispatch = useAppDispatch();

  const dropdownModel = useAppSelector((state) => state.legalEntityList.data);

  const GetCountriesList = useAppSelector((state) => state.GetCountries?.data);

  const [exitDate, setExitDate] = useState<any>();
  const [countryId, setCountryId] = useState<any>();
  const [activeFlag, setActiveFlag] = useState<any>(0);

  //   const [leagalentity, setLeagalentity] = useState<any>();

  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);

  // const options = [
  //   { label: "Option 1", value: 1 },
  //   { label: "Option 2", value: 2 },
  //   { label: "Option 3", value: 3 },
  // ];
  const legalEntityList: { code: number; name: string }[] = [];
  for (let i = 0; i < dropdownModel?.length; i++) {
    legalEntityList.push({
      code: dropdownModel![i].legalEntityID,
      name: dropdownModel![i].legalEntityName,
    });
  }
  // const handleChange = (e: any) => {
  //   return setLeagalentity(e.value);
  //   // Do something with the selected value
  // };

  // const getExitDateConversion = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedExitDate = {
  //       ...d,
  //       firstInvInStateFundingState: new Date(d.firstInvInStateFundingState),
  //       filingDate: d.filingDate ? new Date(d.filingDate) : d.filingDate,
  //       expiryDate: d.expiryDate ? new Date(d.expiryDate) : d.expiryDate,
  //       invDate: d.invDate ? new Date(d.invDate) : d.invDate,
  //     };
  //     return modifiedExitDate;
  //   });
  // };

  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = () => {
      setCountryId(rowData.id);
      console.log("rowData", rowData.active);

      if (rowData.active === "inactive") {
        console.log("inside if", rowData);

        setActiveFlag(0);
      } else {
        console.log("inside else", rowData);

        setActiveFlag(1);
      }
      setVisible(true);
      console.log("activeFlag", activeFlag);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={handleEdit}
          >
            {rowData.active === "inactive" ? (
              <span className="text-white">Enable</span>
            ) : (
              <span className="text-white">Disable</span>
            )}
          </Button>
        </div>
      </div>
    );
  };

  const dataTableElements = [
    {
      field: "countryName",
      header: "Country Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.countryName}>
          {rowData?.countryName}
        </div>
      ),
    },
    {
      field: "active",
      header: "Status",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.active}>
          {rowData?.active}
        </div>
      ),
    },
    {
      // field: "Toggel",
      header: "",
      body: editButton, // Pass rowData to editButton
    },
  ];

  const onDelete = () => {
    const param = {
      CountryID: countryId,
      IsActiveFlag: activeFlag === 0 ? 1 : 0,
    };
    console.log("param  activeflag", activeFlag);
    setloading(true);

    dispatch(UpdateCountryStatusThunk(param))
      .then(() => {
        dispatch(GetCountriesThunk());
        setloading(false);
      })
      .catch(() => {
        setloading(false);
      })
      .finally(() => {
        setloading(false);
      });
    setVisible(false);
  };

  useEffect(() => {
    setloading(true);

    dispatch(GetCountriesThunk())
      .then(() => {
        setloading(false);
      })
      .catch(() => {
        setloading(false);
      })
      .finally(() => {
        setloading(false);
      });
  }, []);

  useEffect(() => {
    console.log("Updated activeFlag:", activeFlag);
  }, [activeFlag]);

  useEffect(() => {
    setExitDate(
      getListDateConversion(GetCountriesList, [
        "firstInvInStateFundingState",
        "filingDate",
        "expiryDate",
        "invDate",
      ])
    );
  }, [GetCountriesList]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      <div className="grid">
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Manage Country Master</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                dataTableElements === undefined ||
                dataTableElements?.length === 0 ||
                dataTableElements === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          {activeFlag === 0 ? (
            <h4 className="pageHeader text-center mb-5 pt-5">
              Are you sure you want to Enable
            </h4>
          ) : (
            <h4 className="pageHeader text-center mb-5 pt-5">
              Are you sure you want to Disable
            </h4>
          )}

          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={onDelete}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>

        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <DataTableComponent
              valueData={
                exitDate
                  ? exitDate.map((item: any) => ({
                      ...item,
                      active: item.active ? "active" : "inactive",
                    }))
                  : exitDate
              }
              fieldsElements={dataTableElements}
              isPaginationAllowed={true}
              className="gridcell deletebtn"
              columnResizeMode="expand"
              maxHeight={"calc(-180px + 100vh)"}
              scrollable={true}
              isFullView={true}
              setOpenDailog={setOpenDailog}
              openDailog={openDailog}
            />
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
