import React from "react";

import SetupFundForm from "../../common-component/set-up-form/SetupFundForm";

export default function SetupFundReport() {
  return (
    <>
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Setup Fund Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <SetupFundForm pageName="Reports" />
    </>
  );
}
