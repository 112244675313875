import { IBindBlueSkyStateDropdownparameters } from "../../store/features/sponser/manage-bluesky-filings/bluesky-state-dropdown";
import { ICheckStateFilingFrequencyparameters } from "../../store/features/sponser/manage-bluesky-filings/checkstatefilingfrequency";
import { IDeleteBlueSkyFilingDataparameters } from "../../store/features/sponser/manage-bluesky-filings/deleteblueskyfilingdata";
import { IGetBlueSkyFillingDetailsparameters } from "../../store/features/sponser/manage-bluesky-filings/getblueskyfillingdetails/index";
import { ISaveBlueskyFilingDataparameters } from "../../store/features/sponser/manage-bluesky-filings/saveblueskyfilingdata";
import { IUpdateBlueskyFilingDataparameters } from "../../store/features/sponser/manage-bluesky-filings/updateblueskyfilingdata";
import { IAddHelpVideoLinkparameters } from "../../store/features/sponser/manage-help-videos/addhelpvideolink";
import { IDeleteHelpVideoLinkparameters } from "../../store/features/sponser/manage-help-videos/delete-help-video";
import { IHelpVedioSubMenuDropdownparameters } from "../../store/features/sponser/manage-help-videos/helpvediosubmenudropdown";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";
import { IDisableEnableMenuLinkparameters } from "./../../store/features/sponser/manage-help-videos/disableenablemenulink/index";
import { IHelpVideoGetVideoLinkparameters } from "./../../store/features/sponser/manage-help-videos/getvideolink/index";

export function GetBlueSkyFillingDetailsService(
  data: IGetBlueSkyFillingDetailsparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/BlueskyFilling/GetBlueSkyFillingDetails?LegalEntityId=${
      data.LegalEntityId
    }`
  );
}

export function BlueSkyStateDropdownService(
  data: IBindBlueSkyStateDropdownparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/BlueskyFilling/BindBlueSkyStateDropdown?LEID=${data.LEID}`
  );
}

export function SaveBlueskyFilingDataService(
  data: ISaveBlueskyFilingDataparameters
) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/BlueskyFilling/SaveBlueskyFilingData
    `,
    data
  );
}

export function UpdateBlueskyFilingDataService(
  data: IUpdateBlueskyFilingDataparameters
) {
  return apiFactory().put(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/BlueskyFilling/UpdateBlueskyFilingData`,
    data
  );
}

export function DeleteBlueSkyFilingDataService(
  data: IDeleteBlueSkyFilingDataparameters
) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/BlueskyFilling/DeleteBlueSkyFilingData?FileId=${data.FileId}
    `
  );
}

export function HelpVedioSubMenuDropdownService(
  data: IHelpVedioSubMenuDropdownparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/HelpVideos/HelpVedioSubMenuDropdown?ParentId=${data.ParentId}`
  );
}
// GetLinkVideo
export function HelpVedioGetVideoLinkService(
  data: IHelpVideoGetVideoLinkparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/HelpVideos/GetVideoLink?MenuUrl=${data.MenuUrl}`
  );
}

export function GetMenuListService() {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL}/MenuMaster/GetMenuList`
  );
}

export function GetParentMenuListService() {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/MenuMaster/GetParentMenuList`
  );
}
export function DisableEnableMenuLinkService(
  data: IDisableEnableMenuLinkparameters
) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/MenuMaster/DisableEnableMenuLink?Id=${data.Id}&Type=${data.Type}
    `
  );
}
export function AddHelpVideoLinkService(data: IAddHelpVideoLinkparameters) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/HelpVideos/HelpVideoCUD`,
    data
  );
}
export function DeleteHelpVideoLinkService(
  data: IDeleteHelpVideoLinkparameters
) {
  return apiFactory().put(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/HelpVideos/DeleteHelpVideo`,
    data
  );
}

export function CheckStateFilingFrequencyService(
  data: ICheckStateFilingFrequencyparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/BlueskyFilling/BlueSkyFilingStateFilingFrequency?LegalEntityID=${
      data.LegalEntityID
    }&State=${data.State}
    `
  );
}

export function BlueSkyFilingPendingStateDataExistService(
  data: ICheckStateFilingFrequencyparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_SPONSOR_BASEURL
    }api/Sponsor/BlueSkyFilingPendingStateDataExist?State=${
      data.State
    }&LegalEntityID=${data.LegalEntityID}
    `
  );
}
