import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
// import { Column } from "primereact/column";
// import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { TabView, TabPanel } from "primereact/tabview";
import React, { useEffect, useState } from "react";

import {
  // RiArrowLeftSLine,
  // RiArrowRightSLine,
  RiSearchLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import "./Investment.scss";

import Error from "../../../../components/ErrorUi";
// import DashboardDropdownComponent from "../../../../components/dashboard-dropdown/DashboardDropdownComponent";

import RegisterDropdownComponent from "../../../../components/dashboard-dropdown/RegisterDropdown";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import Loader from "../../../../components/loader/Loader";
import { getThunkPortfolioInvestmentDropdown } from "../../../../store/Investment/dropdown/slice";
import { getThunkPortfolioInvestment } from "../../../../store/Investment/slice";
// import { getThunkPortfolioNonRegisterInvestorListDropdown } from "../../../../store/features/dropdown/nonregisteredinvestorlist/slice";
// import { getThunkPortfolioRegisterInvestorListDropdown } from "../../../../store/features/dropdown/registeredinvestorlist/slice";
// import { getPortfolioGetLegalEntityList } from "../../../../store/features/dropdown/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ExcelColoumHeader } from "../../../../types";
import currencyFormat from "../../../../utils/currency-formatter";
import { getListDateConversion } from "../../../../utils/date-formatter";
import { exportExcel } from "../../../../utils/excel-dataformat";
import { formatPercent } from "../../../../utils/helper";

// import InvestmentSkeleton from "./InvestmentSkeleton";

// import { getThunkPortfolioInvestmentDropdown } from "../../../store/Investment/dropdown/slice";
// import { getThunkPortfolioInvestment } from "../../../store/Investment/slice";
// import { useAppDispatch, useAppSelector } from "../../../store/hooks";
// import currencyFormat from "../../../utils/currency-formatter";
// import { IInvestmentDropdown } from "../../../types";

export interface IDropdown {
  name: string;
  code: string;
}

const Investment = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activePage] = useState<number>(1);
  const [filterValue, setFilterValue] = useState("");
  const [exitDate, setExitDate] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [selectedFund, setSelectedFund] = useState<any>({
    name: "All",
    code: "All",
  });
  const [openDailog, setOpenDailog] = useState(false);
  const [openDailogCompleted, setOpenDailogCompleted] = useState(false);

  // const [activeTab, setActiveTab] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const dt = useRef<DataTable>(null);

  const inst = useAppSelector((state) => state.portfolioInvestment.investment);
  const dropDown = useAppSelector(
    (state) => state.PortfolioInvestmentDropdown.data
  );
  // const pageDetails = useAppSelector(
  //   (state) => state.portfolioInvestment.investmentPage
  // );

  const portState = useAppSelector(
    (state) => state.PortfolioInvestmentDropdown.loading
  );
  const registerinvestorModel = useAppSelector(
    (state) => state.registerinvestor.data
  );
  const nonregisterinvestorModel = useAppSelector(
    (state) => state.nonregisterinvestor.data
  );
  // const profileData = useAppSelector((state) => state.profile.data![0]);
  console.log("Port5", registerinvestorModel, nonregisterinvestorModel);

  const DropdownList: IDropdown[] = [];

  const Data: any = dropDown!;

  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
  };

  const formatCurrency = (value: string) => {
    const finalValue = Number(value);
    const parseValue = currencyFormat(Number(finalValue));

    return `${parseValue}`;
  };

  const formatCapitalAllocated = (value: string) => {
    const capValue = Number(value);
    const parseValue = currencyFormat(Number(capValue));

    return `${parseValue}`;
  };

  const formatCurrencyOneDec = (value: string) => {
    const finalValue = Number(value).toFixed(2);
    const parseValue = Number(finalValue);

    return `${parseValue}%`;
  };

  const doucmentNameTemplate = (item: any) => {
    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          // const url = `../../OpportunityDetails/${item.productID}`;
          localStorage.setItem("navigationPage", "Investment");
          navigate(`../../OpportunityDetails/${item.productID}`);
          // window.history.replaceState({}, document.title, url);
        }} // style={{ textDecoration: "underline" }}
        className="tabHeadText data-table-link-description left-align"
        title={item.productName}
      >
        {item.productName}
      </span>
    );
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const doucmentCompleteNameTemplate = (item: any) => {
    console.log("doucmentCompleteNameTemplate", item);
    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          navigate(`../../OpportunityDetails/${item.productID}`);
        }} // style={{ textDecoration: "underline" }}
        className="left-align tabHeadText data-table-link-description"
        title={item.productName}
      >
        {item.productName}
      </span>
    );
  };

  useEffect(() => {
    setExitDate(getListDateConversion(inst, ["fundingDate", "actualExitDate"]));
  }, [inst]);
  console.log("DebuggerExitDate", exitDate);
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  useEffect(() => {
    setLoading(true);
    setFilterValue("");
    setSelectedFund({
      name: "All",
      code: "All",
    });
    const parameters = {
      investorID: dropdown.registerid,
      partnerID: dropdown.registerid,
      legalEntityID: dropdown.fundid,
    };
    dispatch(getThunkPortfolioInvestmentDropdown(parameters)).finally(() => {
      setLoading(false);
    });
  }, [dropdown.registerid, dropdown.fundid]);

  useEffect(() => {
    let activeTab = "active";

    if (activeIndex === 1) {
      activeTab = "completed";
    }
    // const fund = selectedFund.name;

    if (
      dropdown.fundid !== 0 &&
      dropdown.fundid !== undefined &&
      dropdown.registerid !== 0 &&
      dropdown.registerid !== undefined
    ) {
      setLoading(true);
      const dropdownparameters = {
        investorID: 0,
        partnerID: dropdown.registerid,
        legalEntityID: dropdown.fundid,
      };
      const obj = {
        activeTab,
        activePage,
        fund: selectedFund.name,
        ...dropdownparameters,
      };
      dispatch(getThunkPortfolioInvestment(obj)).finally(() => {
        setLoading(false);
      });
    }
  }, [
    activeIndex,
    activePage,
    selectedFund.name,
    dropdown.fundid,
    dropdown.registerid,
  ]);

  const excelActiveTableHeaders: ExcelColoumHeader[] = [
    {
      field: "productName",
      header: "Investment Name",
    },
    {
      field: "capitalAllocated",
      header: "Capital Allocated",
      dataType: "currency",
    },
    { field: "earnings", header: "Earnings to Date", dataType: "currency" },
    {
      field: "percentEarningReturn",
      header: "% Earning to Date",
      dataType: "percent",
    },
    {
      field: "earningsTiming",
      header: "Earnings Timing",
    },
    {
      field: "productID",
      header: "Product Id",
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
    },
  ];

  const excelCompletedTableHeaders: ExcelColoumHeader[] = [
    { field: "assetClass", header: "Asset Class" },
    { field: "productName", header: "Investment" },
    { field: "city", header: "City", dataType: "" },
    { field: "usState", header: "State", dataType: "" },
    { field: "country", header: "Country", dataType: "" },
    { field: "interestRate", header: "Projected Return", dataType: "percent" },
    { field: "actualReturn", header: "Actual Return", dataType: "percent" },
    { field: "earningValue", header: "Total Earning", dataType: "currency" },
    {
      field: "principalInvested",
      header: "Principal Invested",
      dataType: "currency",
    },
    {
      field: "principalReturned",
      header: "Principal Returned",
      dataType: "currency",
    },
    { field: "lengthOfInvestment", header: "Length (Days)", dataType: "" },
    { field: "fundingDate", header: "Investemt Date", dataType: "date" },
    { field: "actualExitDate", header: "Exit Date", dataType: "date" },
    {
      field: "productID",
      header: "Product Id",
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
    },
  ];

  const dataTableElementsActive = [
    {
      field: "productName",
      header: "Investment Name",
      body: doucmentNameTemplate,
      style: { minWidth: "300px" },
    },
    {
      field: "capitalAllocated",
      header: "Capital Allocated",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="text-right">
          {formatCapitalAllocated(rowData.capitalAllocated)}
        </div>
      ),
    },
    {
      field: "earnings",
      header: "Earnings to Date",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="text-right">{formatCurrency(rowData.earnings)}</div>
      ),
    },
    {
      field: "percentEarningReturn",
      header: "% Earning to Date",
      style: { minWidth: "180px" },
      body: (rowData: any) => {
        return (
          <div className="text-right">
            {formatCurrencyOneDec(rowData.percentEarningReturn)}
          </div>
        );
      },
    },
    {
      field: "earningsTiming",
      header: "Earnings Timing",
      style: { minWidth: "10px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.earningsTiming}>
          {rowData?.earningsTiming}
        </div>
      ),
    },
  ];

  const dataTableElementCompleted = [
    {
      field: "assetClass",
      header: "Asset Class",
      style: { minWidth: "140px" },
    },
    {
      field: "productName",
      header: "Investment",
      body: doucmentCompleteNameTemplate,
      style: { minWidth: "300px" },
    },
    {
      field: "city",
      header: "City",
    },
    {
      field: "usState",
      header: "State",
    },
    {
      field: "country",
      header: "Country",
      body: (rowData: any) => (
        <div className="text-right">{rowData.country} </div>
      ),
      style: { minWidth: "140px" },
    },
    {
      field: "interestRate",
      header: "Projected Return",
      body: (rowData: any) => (
        <div className="text-right">{formatPercent(rowData.interestRate)} </div>
      ),
      style: { minWidth: "180px" },
    },
    {
      field: "actualReturn",
      header: "Actual Return",
      body: (rowData: any) => (
        <div className="text-right">{formatPercent(rowData.actualReturn)} </div>
      ),
      style: { minWidth: "180px" },
    },
    {
      field: "earningValue",
      header: "Total Earning",
      body: (rowData: any) => (
        <div className="text-right">
          {formatCurrency(rowData.earningValue)}{" "}
        </div>
      ),
      style: { minWidth: "180px" },
    },
    {
      field: "principalInvested",
      header: "Principal Invested",
      body: (rowData: any) => (
        <div className="text-right">
          {formatCurrency(rowData.principalInvested)}{" "}
        </div>
      ),
      style: { minWidth: "180px" },
    },
    {
      field: "principalReturned",
      header: "Principal Returned",
      body: (rowData: any) => (
        <div className="text-right">
          {formatCurrency(rowData.principalReturned)}{" "}
        </div>
      ),
      style: { minWidth: "180px" },
    },
    {
      field: "lengthOfInvestment",
      header: "Length (Days)",
      body: (rowData: any) => (
        <div className="text-right">{rowData.lengthOfInvestment}</div>
      ),
      style: { minWidth: "180px" },
    },
    {
      field: "fundingDate",
      header: "Investment Date",
      body: (rowData: any) => (
        <div className="text-right">{formatDate(rowData.fundingDate)}</div>
      ),
      style: { minWidth: "180px" },
      filterElement: { dateFilterTemplate },
    },
    {
      field: "actualExitDate",
      header: "Exit Date",
      body: (rowData: any) => formatDate(rowData.actualExitDate),
      style: { minWidth: "180px" },
    },
  ];
  console.log("datatat", inst);

  let content;
  switch (portState) {
    case "loading":
      content = <Loader />;

      break;
    case "succeeded":
      for (let i = 0; i < Data?.length; i++) {
        DropdownList.push({
          name: Data[i].category,
          code: Data[i].category,
        });
      }

      // A more complex example:
      content = (
        <>
          {" "}
          {loading && <Loader />}
          <div className="grid">
            <div className="col-12 md:col-6 mt-2">
              <div className="card pgHeaderWrap md:my-2 mt-3">
                <h1 className="pageHeader">Investments</h1>
                <p className="pageSubHeader">
                  Active and completed investment overviews{" "}
                </p>
              </div>
            </div>
            <div className="col-12 md:col-6 mt-3">
              <div className="register-dropdown-wrapper">
                <RegisterDropdownComponent
                  showRegisteredInvestorDropdown={false}
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12">
              <div className="card my-3 ">
                <Card>
                  <div className="flex justify-content-between">
                    <h4 className="cardHeaderWithoutIcon">
                      Investment Details
                    </h4>
                    <div>
                      <Dropdown
                        value={selectedFund}
                        onChange={(e) => {
                          setSelectedFund({
                            name: e.value.name,
                            code: e.value.name,
                          });
                        }}
                        options={DropdownList}
                        optionLabel="name"
                        // optionValue="code"
                        placeholder="Asset Class"
                        className=" w-full md:w-14rem "
                      />
                    </div>
                  </div>
                  <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => {
                      setExitDate([]);
                      setActiveIndex(e.index);
                    }}
                  >
                    <TabPanel header="Active">
                      <div className="my-2 d-flex flex-column md:flex-row justify-content-between  align-items-end">
                        {" "}
                        <div className="md:mb-0 mb-3">
                          <RiSearchLine className="mr-2" />
                          <InputText
                            className="search-width"
                            value={filterValue}
                            onChange={(e) => setFilterValue(e.target.value)}
                            placeholder="Search..."
                          />
                        </div>
                        <div className="flex align-items-center justify-content-end gap-2">
                          <>
                            <Button
                              className="blueButtonShadow"
                              type="button"
                              disabled={inst?.length === 0}
                              // icon="pi pi-file"
                              // rounded
                              onClick={() =>
                                exportExcel(
                                  excelActiveTableHeaders,
                                  inst || [],
                                  "Investment Active Report",
                                  true
                                )
                              }
                              data-pr-tooltip="CSV"
                            >
                              {" "}
                              <span className="blueButtonShadowText">
                                Download{" "}
                              </span>
                            </Button>
                            <Button
                              className="btn-navActive"
                              type="button"
                              icon="pi pi-window-maximize"
                              disabled={
                                exitDate === undefined ||
                                exitDate?.length === 0 ||
                                exitDate === null
                              }
                              onClick={() => setOpenDailog(true)}
                              title="View Full Screen"
                            />
                          </>
                        </div>
                      </div>

                      <DataTableComponent
                        valueData={exitDate}
                        fieldsElements={dataTableElementsActive}
                        isPaginationAllowed={true}
                        globalFilter={filterValue}
                        excelFieldsElements={excelActiveTableHeaders}
                        fileName="Investment Active Report"
                        className="gridcell"
                        // HeadingName="Investment Active Report"
                        columnResizeMode="expand"
                        maxHeight={"calc(-190px + 100vh)"}
                        isDownload={true}
                        scrollable={true}
                        isFullView={true}
                        resizableColumns={true}
                        openDailog={openDailog}
                        setOpenDailog={setOpenDailog}
                      />
                    </TabPanel>
                    <TabPanel header="Completed" key={1}>
                      <div className="my-2 d-flex flex-column md:flex-row justify-content-between  align-items-end">
                        {" "}
                        <div className="md:mb-0 mb-3">
                          <RiSearchLine className="mr-2" />
                          <InputText
                            className="search-width"
                            value={filterValue}
                            onChange={(e) => setFilterValue(e.target.value)}
                            placeholder="Search..."
                          />
                        </div>
                        <div className="flex align-items-center justify-content-end gap-2">
                          <>
                            <Button
                              className="blueButtonShadow"
                              type="button"
                              disabled={inst?.length === 0}
                              // icon="pi pi-file"
                              // rounded
                              onClick={() =>
                                exportExcel(
                                  excelCompletedTableHeaders,
                                  inst || [],
                                  "Investment Completed Report",
                                  true
                                )
                              }
                              data-pr-tooltip="CSV"
                            >
                              {" "}
                              <span className="blueButtonShadowText">
                                Download{" "}
                              </span>
                            </Button>
                            <Button
                              className="btn-navActive"
                              type="button"
                              icon="pi pi-window-maximize"
                              disabled={
                                exitDate === undefined ||
                                exitDate?.length === 0 ||
                                exitDate === null
                              }
                              onClick={() => setOpenDailogCompleted(true)}
                              title="View Full Screen"
                            />
                          </>
                        </div>
                      </div>
                      <DataTableComponent
                        valueData={exitDate}
                        fieldsElements={dataTableElementCompleted}
                        isPaginationAllowed={true}
                        excelFieldsElements={excelCompletedTableHeaders}
                        globalFilter={filterValue}
                        fileName="Investment Completed Report"
                        className="gridcell"
                        // HeadingName="Investment Active Report"
                        columnResizeMode="expand"
                        setOpenDailog={setOpenDailogCompleted}
                        openDailog={openDailogCompleted}
                        maxHeight={"calc(-190px + 100vh)"}
                        isDownload={true}
                        scrollable={true}
                        isFullView={true}
                        resizableColumns={true}
                      />
                    </TabPanel>
                  </TabView>
                  {/* {inst!.length > 0 ? (
                    <div className="flex justify-content-end mt-2">
                      <nav aria-label="...">
                        <ul className="pagination">
                          {pageDetails!.totalPages > 0 ? (
                            <Button
                              onClick={prevPage}
                              type="button"
                              icon={<RiArrowLeftSLine className="" />}
                              className="p-button-text Btnpage BtnPrev"
                              disabled={activePage === 1}
                            >
                              {" "}
                              <span className="pageBtnText">Prev</span>
                            </Button>
                          ) : (
                            <></>
                          )}
                          <li className="page-item active">
                            <span className="BtnBlue rounded ">
                              {activePage}
                            </span>
                          </li>

                          {pageDetails!.totalPages > 0 ? (
                            <li className="page-item active">
                              <span className="font-bold">of </span>
                              <span className="BtnBlue rounded">
                                {pageDetails?.totalPages}
                              </span>
                            </li>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </nav>
                    </div>
                  ) : undefined} */}
                </Card>
              </div>
            </div>
          </div>
        </>
      );

      break;
    case "failed":
      content = <Error />;

      // content = data.map((product: any) => {
      //   return <OpportunityCard p={product} key={product.productID} />;
      // });
      break;
  }

  return <>{content}</>;
};

export default Investment;
