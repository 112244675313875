export const removeConsoleLogs = (function () {
  return function (remove: boolean) {
    if (remove) {
      console.log = function () {};
      // supress all type of consoles
      console.info = function () {};
      console.warn = function () {};
      console.error = function () {};
    }
  };
})();
