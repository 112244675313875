import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDocumentLinkSignatureDualSignerService } from "../../../../../services/investor/investor";
import error from "../../../../../utils/error";
import {
  IgetDocumentLinkSignatureDualSignerPayload,
  IgetDocumentLinkSignatureDualSignerResponse,
} from ".";

interface IEsignDocument {
  data?: IgetDocumentLinkSignatureDualSignerResponse;
  loading: string;
  error?: string | null;
}

// C:\React\avestor\avst-ui-sponsor\src\store\Investor\investor-approval\verify-agreement\e-sign-document\slice.ts

const initialState: IEsignDocument = {
  data: undefined,
  loading: "loading",
  error: undefined,
};
export const getDocumentLinkSignatureDualSignerThunk = createAsyncThunk(
  "investor-approval/verify-agreement/GetDocumentLinkSignatureDualSigner",
  async (data: IgetDocumentLinkSignatureDualSignerPayload) => {
    try {
      const response = await GetDocumentLinkSignatureDualSignerService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "investor-approval/verify-agreement/GetDocumentLinkSignatureDualSigner",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(
      getDocumentLinkSignatureDualSignerThunk.pending,
      (state) => {
        state.loading = "loading";
        state.data = undefined;
        state.error = undefined;
      }
    );
    builder.addCase(
      getDocumentLinkSignatureDualSignerThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data?.resultSignDoc;
      }
    );
    builder.addCase(
      getDocumentLinkSignatureDualSignerThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // action.payload contains error information
        state.error = error(action.payload);
        throw error(action.payload);
      }
    );
  },
});

export default slice.reducer;
