import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addUpdateInvestorCumIAInfoService } from "../../../../services/investor/investor";
import error from "../../../../utils/error";
import {
  IAddUpdateInvestorCumIAInfoState,
  IAddUpdateInvestorCumIAInfoPayload,
} from ".";

// src/store/Investor/invite-investor/get-investor-invite-list

const initialState: IAddUpdateInvestorCumIAInfoState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  error: undefined,
};
export const addUpdateInvestorCumIAInfoThunk = createAsyncThunk(
  "Investor/investor-approval/addUpdateInvestorCumIAInfo",
  async (data: IAddUpdateInvestorCumIAInfoPayload) => {
    try {
      const response = await addUpdateInvestorCumIAInfoService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Investor/investor-approval/addUpdateInvestorCumIAInfo",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(addUpdateInvestorCumIAInfoThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      addUpdateInvestorCumIAInfoThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload.data ? action.payload.data : undefined;
      }
    );
    builder.addCase(
      addUpdateInvestorCumIAInfoThunk.rejected,
      (state, action) => {
        state.loading = "failed";

        //   // action.payload contains error information
        state.error = error(action.payload);
        throw error(action.payload);
      }
    );
  },
});

export default slice.reducer;
