import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDAVByEffectiveDateService } from "../../../../services/investment/investment";
import error from "../../../../utils/error";
import {
  IGetDAVByEffectiveDateState,
  IGetDAVByEffectiveDateparameters,
} from ".";

const initialState: IGetDAVByEffectiveDateState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const GetDAVByEffectiveDateThunk = createAsyncThunk(
  "Investor/GetDAVByEffectiveDate",
  async (data: IGetDAVByEffectiveDateparameters) => {
    try {
      const response = await GetDAVByEffectiveDateService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Investor/GetDAVByEffectiveDate",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetDAVByEffectiveDateThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetDAVByEffectiveDateThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data.investorList : [];
    });
    builder.addCase(GetDAVByEffectiveDateThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
