import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAccessForNotificationService } from "./../../../../../../services/operation/operation";
import error from "./../../../../../../utils/error";
import {
  IGetAccessForNotificationState,
  IGetAccessForNotificationparameters,
} from ".";

const initialState: IGetAccessForNotificationState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const GetAccessForNotificationThunk = createAsyncThunk(
  "RoleManagement/GetAccessForNotification",
  async (data: IGetAccessForNotificationparameters) => {
    try {
      const response = await GetAccessForNotificationService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "RoleManagement/GetAccessForNotification",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetAccessForNotificationThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      GetAccessForNotificationThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload.data ? action.payload.data : [];
      }
    );
    builder.addCase(GetAccessForNotificationThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
