import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getGetConsolidatedDashboardDetailsService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";
import {
  IGetConsolidatedDashboardDetailsParameter,
  IGetConsolidatedDashboardDetailsState,
} from ".";

const initialState: IGetConsolidatedDashboardDetailsState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const GetConsolidatedDashboardDetailsThunk = createAsyncThunk(
  "GetConsolidatedDashboardDetails",
  async (data: IGetConsolidatedDashboardDetailsParameter) => {
    try {
      const response = await getGetConsolidatedDashboardDetailsService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "GetConsolidatedDashboardDetails",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetConsolidatedDashboardDetailsThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      GetConsolidatedDashboardDetailsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data
          ? action.payload._response.data
          : [];
      }
    );
    builder.addCase(
      GetConsolidatedDashboardDetailsThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
