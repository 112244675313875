class TokenService {
  public getLocalAccessToken() {
    // const user: any = JSON.parse(
    //   localStorage.getItem(
    //     "06371af7-fc67-421b-8835-194a1212c0af-b2c_1_login.82fa7491-aeb5-4c22-b435-2472fee17d4c-investordev.b2clogin.com-idtoken-1e1af3ef-5f0c-4a33-b3aa-18ef56d40b43----"
    //   ) || "{}"
    // );
    // let token;
    // if (Object.keys(user).length > 0) {
    //   token = user.secret;
    //   return token;
    // }
    const token = localStorage.getItem("token");
    if (token) {
      return token;
    }
    return "";
  }
}

export default new TokenService();
