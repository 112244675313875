export const emailRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/;
export const initialQuestValue = {
  stage1_namefund1: "",
  stage1_namefund2: "",
  stage1_namefund3: "",
  stage1_namefund4: "",
  stage1_activity1_point1: 0,
  stage1_activity1_point2: 0,
  stage1_activity1_point3: 0,
  stage1_activity1_point4: 0,
  stage1_activity2_point1: 0,
  stage1_activity2_point2: 0,
  stage1_activity2_point3: 0,
  stage1_activity2_point4: 0,
  stage1_activity3_point1: 0,
  stage1_activity3_point2: 0,
  stage1_activity3_point3: 0,
  stage1_activity3_point4: 0,
  stage1_total_point1: 0,
  stage1_total_point2: 0,
  stage1_total_point3: 0,
  stage1_total_point4: 0,
  stage1_bonus1: 0,
  stage1_bonus2: 0,
  stage1_bonus3: 0,
  stage1_bonus4: 0,
  stage2_activity4_point1: 0,
  stage2_activity4_point2: 0,
  stage2_activity4_point3: 0,
  stage2_activity4_point4: 0,
  stage2_activity5_point1: 0,
  stage2_activity5_point2: 0,
  stage2_activity5_point3: 0,
  stage2_activity5_point4: 0,
  stage2_activity6_point1: 0,
  stage2_activity6_point2: 0,
  stage2_activity6_point3: 0,
  stage2_activity6_point4: 0,
  stage2_totalinvester1: 0,
  stage2_totalinvester2: 0,
  stage2_totalinvester3: 0,
  stage2_totalinvester4: 0,
  stage3_roll1_capital1: 0,
  stage3_roll1_capital2: 0,
  stage3_roll1_capital3: 0,
  stage3_roll1_capital4: 0,
  stage3_roll2_capital1: 0,
  stage3_roll2_capital2: 0,
  stage3_roll2_capital3: 0,
  stage3_roll2_capital4: 0,
  stage3_roll3_capital1: 0,
  stage3_roll3_capital2: 0,
  stage3_roll3_capital3: 0,
  stage3_roll3_capital4: 0,
  stage3_roll4_capital1: 0,
  stage3_roll4_capital2: 0,
  stage3_roll4_capital3: 0,
  stage3_roll4_capital4: 0,
  stage3_totalcapital1: 0,
  stage3_totalcapital2: 0,
  stage3_totalcapital3: 0,
  stage3_totalcapital4: 0,
  stage4_apartment_reno1: 0,
  stage4_apartment_reno2: 0,
  stage4_apartment_reno3: 0,
  stage4_apartment_reno4: 0,
  stage4_carwash_newcons1: 0,
  stage4_carwash_newcons2: 0,
  stage4_carwash_newcons3: 0,
  stage4_carwash_newcons4: 0,

  stage4_hotel_property1: 0,
  stage4_hotel_property2: 0,
  stage4_hotel_property3: 0,
  stage4_hotel_property4: 0,

  stage4_oil_gas1: 0,
  stage4_oil_gas2: 0,
  stage4_oil_gas3: 0,
  stage4_oil_gas4: 0,

  stage4_rv_camp1: 0,
  stage4_rv_camp2: 0,
  stage4_rv_camp3: 0,
  stage4_rv_camp4: 0,

  stage4_retail_renovatio1: 0,
  stage4_retail_renovatio2: 0,
  stage4_retail_renovatio3: 0,
  stage4_retail_renovatio4: 0,

  stage4_daycare_purchase1: 0,
  stage4_daycare_purchase2: 0,
  stage4_daycare_purchase3: 0,
  stage4_daycare_purchase4: 0,

  stage4_self_storage_newcons1: 0,
  stage4_self_storage_newcons2: 0,
  stage4_self_storage_newcons3: 0,
  stage4_self_storage_newcons4: 0,

  stage4_senior_assi_livpurchase1: 0,
  stage4_senior_assi_livpurchase2: 0,
  stage4_senior_assi_livpurchase3: 0,
  stage4_senior_assi_livpurchase4: 0,

  stage4_medical_offrenovatio1: 0,
  stage4_medical_offrenovatio2: 0,
  stage4_medical_offrenovatio3: 0,
  stage4_medical_offrenovatio4: 0,

  stage4_hotel_apartment_conversion1: 0,
  stage4_hotel_apartment_conversion2: 0,
  stage4_hotel_apartment_conversion3: 0,
  stage4_hotel_apartment_conversion4: 0,

  stage4_build_rentdevp1: 0,
  stage4_build_rentdevp2: 0,
  stage4_build_rentdevp3: 0,
  stage4_build_rentdevp4: 0,

  stage4_total_capital_invested1: 0,
  stage4_total_capital_invested2: 0,
  stage4_total_capital_invested3: 0,
  stage4_total_capital_invested4: 0,

  stage5_investment1_1: 0,
  stage5_investment1_2: 0,
  stage5_investment1_3: 0,
  stage5_investment1_4: 0,

  stage5_investment2_1: 0,
  stage5_investment2_2: 0,
  stage5_investment2_3: 0,
  stage5_investment2_4: 0,

  stage5_investment3_1: 0,
  stage5_investment3_2: 0,
  stage5_investment3_3: 0,
  stage5_investment3_4: 0,

  stage5_investment4_1: 0,
  stage5_investment4_2: 0,
  stage5_investment4_3: 0,
  stage5_investment4_4: 0,

  stage5_investment5_1: 0,
  stage5_investment5_2: 0,
  stage5_investment5_3: 0,
  stage5_investment5_4: 0,

  stage5_total_capreturned_1: 0,
  stage5_total_capreturned_2: 0,
  stage5_total_capreturned_3: 0,
  stage5_total_capreturned_4: 0,
  winner_1: 0,
  winner_2: 0,
  winner_3: 0,
  winner_4: 0,
  email: "",
};

export const questValidation = (data: any, errors: any) => {
  if (!data?.email) {
    errors.email = "Please enter a email";
  }
  if (data?.email && !emailRegex.test(data?.email)) {
    errors.email = "Please enter a valid email";
  }
  console.log("errors", errors);
  return errors;
};

export const formatQuestData = (data: any) => {
  return {
    emailId: data.email,
    stage1: [
      {
        players: [
          {
            name: "player1",
            fundName: data.stage1_namefund1,
            activityPoints1: data.stage1_activity1_point1,
            activityPoints2: data.stage1_activity2_point1,
            activityPoints3: data.stage1_activity3_point1,
            totalPoints: data.stage1_total_point1,
            stageBonus: data.stage1_bonus1,
          },
          {
            name: "player2",
            fundName: data.stage1_namefund2,
            activityPoints1: data.stage1_activity1_point2,
            activityPoints2: data.stage1_activity2_point2,
            activityPoints3: data.stage1_activity3_point2,
            totalPoints: data.stage1_total_point2,
            stageBonus: data.stage1_bonus2,
          },
          {
            name: "player3",
            fundName: data.stage1_namefund3,
            activityPoints1: data.stage1_activity1_point3,
            activityPoints2: data.stage1_activity2_point3,
            activityPoints3: data.stage1_activity3_point3,
            totalPoints: data.stage1_total_point3,
            stageBonus: data.stage1_bonus3,
          },
          {
            name: "player4",
            fundName: data.stage1_namefund4,
            activityPoints1: data.stage1_activity1_point4,
            activityPoints2: data.stage1_activity2_point4,
            activityPoints3: data.stage1_activity3_point4,
            totalPoints: data.stage1_total_point4,
            stageBonus: data.stage1_bonus4,
          },
        ],
      },
    ],

    stage2: [
      {
        players: [
          {
            name: "player1",
            activityPoints4: data.stage2_activity4_point1,
            activityPoints5: data.stage2_activity5_point1,
            activityPoints6: data.stage2_activity6_point1,
            totalInvestorsInFund: data.stage2_totalinvester1,
          },
          {
            name: "player2",
            activityPoints4: data.stage2_activity4_point2,
            activityPoints5: data.stage2_activity5_point2,
            activityPoints6: data.stage2_activity6_point2,
            totalInvestorsInFund: data.stage2_totalinvester2,
          },
          {
            name: "player3",
            activityPoints4: data.stage2_activity4_point3,
            activityPoints5: data.stage2_activity5_point3,
            activityPoints6: data.stage2_activity6_point3,
            totalInvestorsInFund: data.stage2_totalinvester3,
          },
          {
            name: "player4",
            activityPoints4: data.stage2_activity4_point4,
            activityPoints5: data.stage2_activity5_point4,
            activityPoints6: data.stage2_activity6_point4,
            totalInvestorsInFund: data.stage2_totalinvester4,
          },
        ],
      },
    ],
    stage3: [
      {
        players: [
          {
            name: "player1",
            roll1CapitalRaised: data.stage3_roll1_capital1,
            roll2CapitalRaised: data.stage3_roll2_capital1,
            roll3CapitalRaised: data.stage3_roll3_capital1,
            roll4CapitalRaised: data.stage3_roll4_capital1,
            totalCapitalRaised: data.stage3_totalcapital1,
          },
          {
            name: "player2",
            roll1CapitalRaised: data.stage3_roll1_capital2,
            roll2CapitalRaised: data.stage3_roll2_capital2,
            roll3CapitalRaised: data.stage3_roll3_capital2,
            roll4CapitalRaised: data.stage3_roll4_capital2,
            totalCapitalRaised: data.stage3_totalcapital2,
          },
          {
            name: "player3",
            roll1CapitalRaised: data.stage3_roll1_capital3,
            roll2CapitalRaised: data.stage3_roll2_capital3,
            roll3CapitalRaised: data.stage3_roll3_capital3,
            roll4CapitalRaised: data.stage3_roll4_capital3,
            totalCapitalRaised: data.stage3_totalcapital3,
          },
          {
            name: "player4",
            roll1CapitalRaised: data.stage3_roll1_capital4,
            roll2CapitalRaised: data.stage3_roll2_capital4,
            roll3CapitalRaised: data.stage3_roll3_capital4,
            roll4CapitalRaised: data.stage3_roll4_capital4,
            totalCapitalRaised: data.stage3_totalcapital4,
          },
        ],
      },
    ],
    stage4: [
      {
        players: [
          {
            name: "player1",
            unitApartmentRenovation: data.stage4_apartment_reno1,
            carWashConstruction: data.stage4_carwash_newcons1,
            hotelPropertyUpgrade: data.stage4_hotel_property1,
            oilGasDrilling: data.stage4_oil_gas1,
            campGroundPurchase: data.stage4_rv_camp1,
            retailCenterRenovation: data.stage4_retail_renovatio1,
            dayCareCentrePurchase: data.stage4_daycare_purchase1,
            selfStorageConstruction: data.stage4_self_storage_newcons1,
            seniorAssistedPurchase: data.stage4_senior_assi_livpurchase1,
            medicalOfficeRnovation: data.stage4_medical_offrenovatio1,
            hotelToApartmentConversion: data.stage4_hotel_apartment_conversion1,
            buildToRentDevelopment: data.stage4_build_rentdevp1,
            totalCapitalInvested: data.stage4_total_capital_invested1,
          },
          {
            name: "player2",
            unitApartmentRenovation: data.stage4_apartment_reno2,
            carWashConstruction: data.stage4_carwash_newcons2,
            hotelPropertyUpgrade: data.stage4_hotel_property2,
            oilGasDrilling: data.stage4_oil_gas2,
            campGroundPurchase: data.stage4_rv_camp2,
            retailCenterRenovation: data.stage4_retail_renovatio2,
            dayCareCentrePurchase: data.stage4_daycare_purchase2,
            selfStorageConstruction: data.stage4_self_storage_newcons2,
            seniorAssistedPurchase: data.stage4_senior_assi_livpurchase2,
            medicalOfficeRnovation: data.stage4_medical_offrenovatio2,
            hotelToApartmentConversion: data.stage4_hotel_apartment_conversion2,
            buildToRentDevelopment: data.stage4_build_rentdevp2,
            totalCapitalInvested: data.stage4_total_capital_invested2,
          },
          {
            name: "player3",
            unitApartmentRenovation: data.stage4_apartment_reno3,
            carWashConstruction: data.stage4_carwash_newcons3,
            hotelPropertyUpgrade: data.stage4_hotel_property3,
            oilGasDrilling: data.stage4_oil_gas3,
            campGroundPurchase: data.stage4_rv_camp3,
            retailCenterRenovation: data.stage4_retail_renovatio3,
            dayCareCentrePurchase: data.stage4_daycare_purchase3,
            selfStorageConstruction: data.stage4_self_storage_newcons3,
            seniorAssistedPurchase: data.stage4_senior_assi_livpurchase3,
            medicalOfficeRnovation: data.stage4_medical_offrenovatio3,
            hotelToApartmentConversion: data.stage4_hotel_apartment_conversion3,
            buildToRentDevelopment: data.stage4_build_rentdevp3,
            totalCapitalInvested: data.stage4_total_capital_invested3,
          },
          {
            name: "player4",
            unitApartmentRenovation: data.stage4_apartment_reno4,
            carWashConstruction: data.stage4_carwash_newcons4,
            hotelPropertyUpgrade: data.stage4_hotel_property4,
            oilGasDrilling: data.stage4_oil_gas4,
            campGroundPurchase: data.stage4_rv_camp4,
            retailCenterRenovation: data.stage4_retail_renovatio4,
            dayCareCentrePurchase: data.stage4_daycare_purchase4,
            selfStorageConstruction: data.stage4_self_storage_newcons4,
            seniorAssistedPurchase: data.stage4_senior_assi_livpurchase4,
            medicalOfficeRnovation: data.stage4_medical_offrenovatio4,
            hotelToApartmentConversion: data.stage4_hotel_apartment_conversion4,
            buildToRentDevelopment: data.stage4_build_rentdevp4,
            totalCapitalInvested: data.stage4_total_capital_invested4,
          },
        ],
      },
    ],
    stage5: [
      {
        players: [
          {
            name: "player1",
            investment1: data.stage5_investment1_1,
            investment2: data.stage5_investment2_1,
            investment3: data.stage5_investment3_1,
            investment4: data.stage5_investment4_1,
            investment5: data.stage5_investment5_1,
            totalCapitalReturned: data.stage5_total_capreturned_1,
          },
          {
            name: "player2",
            investment1: data.stage5_investment1_2,
            investment2: data.stage5_investment2_2,
            investment3: data.stage5_investment3_2,
            investment4: data.stage5_investment4_2,
            investment5: data.stage5_investment5_2,
            totalCapitalReturned: data.stage5_total_capreturned_2,
          },
          {
            name: "player3",
            investment1: data.stage5_investment1_3,
            investment2: data.stage5_investment2_3,
            investment3: data.stage5_investment3_3,
            investment4: data.stage5_investment4_3,
            investment5: data.stage5_investment5_3,
            totalCapitalReturned: data.stage5_total_capreturned_3,
          },
          {
            name: "player4",
            investment1: data.stage5_investment1_4,
            investment2: data.stage5_investment2_4,
            investment3: data.stage5_investment3_4,
            investment4: data.stage5_investment4_4,
            investment5: data.stage5_investment5_4,
            totalCapitalReturned: data.stage5_total_capreturned_4,
          },
        ],
      },
    ],
    questWinner: [
      {
        name: "player1",
        fundResults: data.winner_1,
      },
      {
        name: "player2",
        fundResults: data.winner_2,
      },
      {
        name: "player3",
        fundResults: data.winner_3,
      },
      {
        name: "player4",
        fundResults: data.winner_4,
      },
    ],
  };
};
