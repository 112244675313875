import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetInvestorIncomeListService } from "../../../../../../services/taxmanagement/tax";
import error from "../../../../../../utils/error";
import {
  IGetInvestorIncomeListState,
  IGetInvestorIncomeListparameters,
} from ".";

const initialState: IGetInvestorIncomeListState = {
  success: false,
  message: "",
  loading: "loading",
  data1: [],
  data2: [],
  data3: [],
  data: undefined,
};
export const GetInvestorIncomeListThunk = createAsyncThunk(
  "TaxManagement/GetInvestorIncomeList",
  async (data: IGetInvestorIncomeListparameters) => {
    try {
      const response = await GetInvestorIncomeListService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "TaxManagement/GetInvestorIncomeList",
  initialState,
  reducers: {
    incomeData: (state) => {
      state.data = undefined;
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetInvestorIncomeListThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetInvestorIncomeListThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data1 = action.payload._response.data.investorIncome
        ? JSON.parse(
            action.payload._response.data?.investorIncome[0]?.[0]?.result
          )
        : [];
      state.data2 = action.payload._response.data.investorIncome
        ? JSON.parse(
            action.payload._response.data?.investorIncome[1]?.[0]?.result
          )
        : [];
      state.data = action.payload._response.data
        ? action.payload._response.data.supplierIntList[0]?.supplierCashIntrest
        : [];
      state.data3 = action.payload._response.data.investorIncome
        ? action.payload._response.data.investorIncome[2]?.[0]?.result
        : [];
    });
    builder.addCase(GetInvestorIncomeListThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});
export const { incomeData } = slice.actions;

export default slice.reducer;
