import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestorProfileDetailService } from "../../../../../services/investor/investor";
import error from "../../../../../utils/error";
import { IInvestorProfileDetails, IGetInvestorProfileDetailsPayload } from ".";

interface InvestorProfileDetails {
  Data?: IInvestorProfileDetails[] | [];
  loading: string;
  error?: string | null;
  firstName?: string;
  lastName?: string;
  address?: string;
}

const initialState: InvestorProfileDetails = {
  Data: [],
  loading: "loading",
  error: undefined,
  firstName: undefined,
  lastName: undefined,
  address: undefined,
};

export const getInvestorProfileDetailsThunk = createAsyncThunk(
  "Investor/investor-approval/getInvestorProfileDetails",
  async (data: IGetInvestorProfileDetailsPayload) => {
    try {
      const response = await getInvestorProfileDetailService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "Investor/investor-approval/getInvestorProfileDetails",
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      // state.Data = action.payload;

      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      // state.Data = action.payload;

      state.lastName = action.payload;
    },
    setAddress: (state, action) => {
      // state.Data = action.payload;

      state.address = action.payload;
    },
    setInvestorProfileDetailsRestLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getInvestorProfileDetailsThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
      // state.Data = [];
    });
    builder.addCase(
      getInvestorProfileDetailsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.Data = action.payload._response.data?.investorProfile
          ? action.payload._response.data?.investorProfile
          : [];

        // state.redDotStatus = action.payload.data?.statusData
        //   ? action.payload.data.statusData[0]
        //   : {};
        // console.log(action.payload);
      }
    );
    builder.addCase(
      getInvestorProfileDetailsThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});
export const {
  setFirstName,
  setLastName,
  setAddress,
  setInvestorProfileDetailsRestLoading,
} = slice.actions;

export default slice.reducer;
