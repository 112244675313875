import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";

// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";
import DataTableComponent from "../../../components/data-table/DataTableComponent";

import Loader from "../../../components/loader/Loader";
import { DailyAccountProcessListThunk } from "../../../store/features/accounting/reversedailyaum/dailyaccountprocessList/slice";
import {
  ReverseDailyAccountProcessThunk,
  resetReverseData,
} from "../../../store/features/accounting/reversedailyaum/reversedailyaccountprocess/slice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../utils/date-formatter";
import { successToastMessage } from "../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function ReverseDailyAum() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const accountValue = useAppSelector(
    (state) => state.DailyAccountProcessList?.data
  );
  console.log("dailyAccountProcessList->>>>>>", accountValue);
  const reverseAccountValue = useAppSelector(
    (state) => state.ReverseDailyAccountProcess?.data
  );
  const [reverseAccountValueData, setReverseAccountValueData] = useState<any>(
    []
  );
  const [accountValueData, setAccountValueData] = useState<any>([]);
  //   const profileData = useAppSelector((state) => state.profile.data![0]);
  //   const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [click, setClick] = useState<any>(false);
  const [visible, setVisible] = useState<any>(false);
  const [firstVisible, setFirstVisible] = useState<any>(false);
  const [loading, setloading] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);

  const [id, setId] = useState<any>();

  console.log("reverseAccountValue", reverseAccountValue);

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };
  useEffect(() => {
    if (fundDropdown.fundid) {
      const req = {
        LegalEntityId: fundDropdown.fundid,
      };
      setloading(true);
      dispatch(DailyAccountProcessListThunk(req))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
      dispatch(resetReverseData());
      setClick(false);
    }
  }, [fundDropdown.fundid]);

  const reverseButton = (rowData: any, index: any) => {
    const handleFirstRowClick = (row: any) => {
      setId(row.id);
      setVisible(true);
    };

    // Function to handle the reverse button click for other rows
    const handleOtherRowClick = () => {
      setFirstVisible(true);
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog widthdeletebtn align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={() => {
              if (index.props.value[0] === rowData) {
                handleFirstRowClick(rowData);
                console.log("first", index.props.value);
              } else {
                handleOtherRowClick();
                console.log("other", index.props.value);
                setFirstVisible(true);
              }
            }}
          >
            <span className="text-white">Reverse</span>
          </Button>
        </div>
      </div>
    );
  };

  const dataTableElements = [
    {
      field: "startDate",
      header: "Start Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.startDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "endDate",
      header: "End Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.endDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "",
      header: "",
      body: (rowData: any, index: number) => reverseButton(rowData, index), // Pass rowData and index to reverseButton
    },
  ];

  //   const resetForm = () => {
  //     setName("");
  //   };

  const onReverse = () => {
    setloading(true);
    setVisible(false);
    const req = {
      ProcessId: id,
      LegalEntityId: fundDropdown.fundid,
    };

    console.log("inside edit");
    dispatch(ReverseDailyAccountProcessThunk(req)).then(() => {
      successToastMessage(toast, "Reversed Successfully");
      setloading(false);
      setClick(true);
    });
  };

  useEffect(() => {
    if (accountValue !== undefined && accountValue.length > 0) {
      setAccountValueData(
        getListDateConversion(accountValue, ["startDate", "endDate"])
      );
    } else {
      setAccountValueData([]);
    }
  }, [accountValue]);

  useEffect(() => {
    if (reverseAccountValue !== undefined && reverseAccountValue.length > 0) {
      setReverseAccountValueData(
        getListDateConversion(reverseAccountValue, ["startDate", "endDate"])
      );
    } else {
      setReverseAccountValueData([]);
    }
  }, [reverseAccountValue]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      <div className="grid">
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Reverse Daily AUM</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              // disabled={
              //   reverseAccountValueData === undefined ||
              //   reverseAccountValueData?.length === 0 ||
              //   reverseAccountValueData === null
              // }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Are you sure you want to reverse ?
          </h4>
          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={onReverse}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>
        <Dialog
          visible={firstVisible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setFirstVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Daily Account Value for selected period cannot be reversed. Please
            reverse the latest entry first{" "}
          </h4>
          <div className="d-flex justify-content-center ">
            <Button
              onClick={() => setFirstVisible(false)}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Ok
            </Button>
          </div>
        </Dialog>
        <Toast ref={toast} className="themeToast" />

        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            {/* <h4 className="cardHeader mb-3">Reverse AUM</h4> */}

            <DataTableComponent
              valueData={click ? reverseAccountValueData! : accountValueData!}
              fieldsElements={dataTableElements}
              isPaginationAllowed={true}
              scrollable={true}
              className="gridcell deletebtn"
              columnResizeMode="expand"
              maxHeight={"calc(-180px + 100vh)"}
              isFullView={true}
              setOpenDailog={setOpenDailog}
              openDailog={openDailog}
              resizableColumns={true}
            />
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
