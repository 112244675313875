import { Calendar } from "primereact/calendar";
import { ColorPicker } from "primereact/colorpicker";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useEffect, useState } from "react";
import { getThunkFundTypeDropdown } from "../../../../store/features/operation/setup-fund/fund-type-dropdown/slice";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { formatPhoneNumber } from "../../../../utils/helper";
interface IProps {
  pageName: any;
  formik: any;
  getFormErrorMessage: any;
  isFormFieldInvalid: any;
}

export default function SetupFundProfile(props: IProps) {
  const { pageName, formik, getFormErrorMessage, isFormFieldInvalid } = props;
  const [disableField, setDisableField] = useState(false);
  const dispatch = useAppDispatch();
  // const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const fundType = useAppSelector((state) => state.getFundTypeDropdown.data);
  const stateDropDownList = useAppSelector((state) => state.dropdownList.data);
  // const setupFundInfo = useAppSelector((state) => state.getSetupFundInfo.data);
  //  const id = setupFundInfo?.fundProfileDetails?.legalEntityID;
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "zipCode": {
        const digitsOnly = value.replace(/\D/g, "");
        // Add hyphen after every 5 digits if length is greater than 5
        let formattedValue = digitsOnly;
        if (formattedValue.length > 5) {
          formattedValue = formattedValue.replace(/(\d{5})/g, "$1-");
        }
        //
        formik.setFieldValue(name, formattedValue);

        break;
      }
      case "ein": {
        const digitsOnly = value.replace(/\D/g, "");
        let formattedValue = digitsOnly;

        if (formattedValue.length >= 2) {
          formattedValue =
            formattedValue.slice(0, 2) + "-" + formattedValue.slice(2);
        }

        formik.setFieldValue(name, formattedValue);

        break;
      }
      case "fundPrimaryColour": {
        const color = value.startsWith("#") ? value : `#${value}`;
        formik.setFieldValue("fundPrimaryColour", color);

        break;
      }
      default: {
        formik.setFieldValue(name, value);
      }
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Backspace" && formik.values.ein.includes("-")) {
      e.preventDefault();

      // Remove the hyphen when backspacing
      const newEin = formik.values.ein.slice(0, -1);
      formik.setFieldValue("ein", newEin);
    }
  };

  useEffect(() => {
    dispatch(getThunkFundTypeDropdown());
    if (pageName === "Reports") {
      setDisableField(true);
    } else {
      setDisableField(false);
    }
  }, []);
  return (
    <>
      {/* <div>
        <div className=" h-100 center-card">
          <div className="col-12 p-0">
            <div className="card"> */}
      <form>
        <div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">Fund Name *</label>
              <InputText
                name="fundName"
                value={formik.values.fundName}
                onChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("fundName"),
                  "card-inputBox": true,
                })}
                disabled={disableField === true}
                placeholder="Enter fund name"
              />
              {getFormErrorMessage("fundName")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">Fund Slug*</label>
              <InputText
                name="fundSlug"
                value={formik.values.fundSlug}
                onChange={(e) => {
                  formik.errors = {};
                  handleChange(e);
                }}
                // disabled={
                //   disableField === true ||
                //   setupFundInfo?.fundProfileDetails?.legalEntityID !== undefined
                // }
                className={classNames({
                  "p-invalid": isFormFieldInvalid("fundSlug"),
                  "card-inputBox": true,
                })}
                placeholder="Enter fund slug"
              />
              {getFormErrorMessage("fundSlug")}
            </div>

            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <div className="d-flex md:mt-4 md:m-2">
                <div className="flex align-items-center pr-4">
                  <label className="container-checkbox my-3">
                    <span>Allow investor to sign up</span>
                    <input
                      type="checkbox"
                      // value="SoftCommit"
                      name="investorSignup"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "investorSignUp",
                          !formik.values.investorSignUp
                        );
                      }}
                      disabled={disableField === true}
                      checked={formik.values.investorSignUp === true}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="card-subheader">Fund Type *</label>
              <div>
                <Dropdown
                  name="fundTypeId"
                  value={formik.values.fundTypeId}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  options={fundType === null ? [] : fundType?.fundtype}
                  optionLabel="value"
                  optionValue="key"
                  disabled={disableField === true}
                  placeholder="Select fund type"
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("fundTypeId"),
                    "card-inputBox": true,
                  })}
                />
                {getFormErrorMessage("fundTypeId")}
              </div>
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">Entity Type *</label>
              <Dropdown
                inputId="entityType"
                name="entityType"
                value={formik.values.entityType}
                options={fundType === null ? [] : fundType?.entitytype}
                optionLabel="value"
                optionValue="key"
                disabled
                className={classNames({
                  "p-invalid": isFormFieldInvalid("entityType"),
                  "card-inputBox": true,
                })}
              />

              {getFormErrorMessage("entityType")}
            </div>

            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="card-subheader">Fund Initial Setup Date</label>
              <div>
                <Calendar
                  name="fundInitialSetupDate"
                  value={
                    formik.values.fundInitialSetupDate === "" ||
                    formik.values.fundInitialSetupDate === null
                      ? undefined
                      : new Date(formik.values.fundInitialSetupDate)
                  }
                  // onChange={(e) => handleChange(e)}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("fundInitialSetupDate"),
                    "card-inputBox": true,
                  })}
                  disabled
                  // minDate={todayDate}
                  placeholder="Select setup Date"
                  readOnlyInput
                />
                {getFormErrorMessage("fundInitialSetupDate")}
              </div>
            </div>
          </div>

          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Registered in State *</label>
              <Dropdown
                inputId="registeredState"
                name="registeredState"
                value={formik.values.registeredState}
                options={
                  stateDropDownList === null
                    ? []
                    : stateDropDownList.stateDetails
                }
                filter
                optionLabel="value"
                optionValue="key"
                placeholder="Select State"
                onChange={(e) => {
                  handleChange(e);
                }}
                disabled={disableField === true}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("registeredState"),
                  "card-inputBox": true,
                })}
              />

              {getFormErrorMessage("registeredState")}
            </div>

            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">EIN</label>
              <InputText
                name="ein"
                value={formik.values.ein}
                onChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("ein"),
                  "card-inputBox": true,
                })}
                disabled={disableField === true}
                onKeyDown={(e) => handleKeyDown(e)}
                maxLength={10}
                placeholder="XX-XXXXXXX"
              />
              {getFormErrorMessage("ein")}
            </div>
          </div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">Address</label>
              <InputText
                name="address"
                value={formik.values.address}
                onChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("address"),
                  "card-inputBox": true,
                })}
                disabled={disableField === true}
                placeholder="Enter address"
              />
              {getFormErrorMessage("address")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">City *</label>
              <InputText
                name="city"
                value={formik.values.city}
                onChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("city"),
                  "card-inputBox": true,
                })}
                disabled={disableField === true}
                placeholder="City"
              />
              {getFormErrorMessage("city")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">State *</label>
              <Dropdown
                inputId="state"
                name="state"
                value={formik.values.state}
                options={
                  stateDropDownList === null
                    ? []
                    : stateDropDownList.stateDetails
                }
                disabled={disableField === true}
                filter
                optionLabel="value"
                optionValue="key"
                placeholder="Select State"
                // className="card-inputBox"
                onChange={(e) => {
                  handleChange(e);
                }}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("state"),
                  "card-inputBox": true,
                })}
              />

              {getFormErrorMessage("state")}
            </div>
          </div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">ZIP Code *</label>
              <InputText
                name="zipCode"
                value={formik.values.zipCode}
                onChange={handleChange}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("zipCode"),
                  "card-inputBox": true,
                })}
                disabled={disableField === true}
                maxLength={10}
                placeholder="Enter zip code"
              />
              {formik.errors.zipCode && formik.touched.zipCode && (
                <div className="error">{getFormErrorMessage("zipCode")} </div>
              )}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Phone No</label>
              <div className="p-inputgroup inputgrp-h47 flex-1">
                <span className="p-inputgroup-addon">+1</span>
                <InputText
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="xxx-xxx-xxxx"
                  disabled={disableField === true}
                  value={
                    formik.values.phoneNumber
                      ? formatPhoneNumber(
                          formik.values.phoneNumber.split("-").join("")
                        )
                      : formik.values.phoneNumber
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "phoneNumber",
                      formatPhoneNumber(e.target.value).split("-").join("")
                    );
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("phoneNumber"),
                    inputphoneNo: true,
                  })}
                />{" "}
              </div>
              {getFormErrorMessage("phoneNumber")}
            </div>
          </div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">
                Primary Fund Notification Email *
              </label>
              <InputText
                name="primaryFundEmail"
                value={formik.values.primaryFundEmail}
                onChange={handleChange}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("primaryFundEmail"),
                  "card-inputBox": true,
                })}
                disabled={disableField === true}
                // maxLength={50}
                placeholder="Enter Email"
              />

              {getFormErrorMessage("primaryFundEmail")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-8 flex justify-content-center ">
              <div className="inputLabelNotification flex flex-row">
                <div> - </div>
                <div className="ml-2 mb-1">
                  This email will receive all notifications and will be used in
                  the email signature.
                </div>
              </div>
              <div className="inputLabelNotification flex flex-row">
                <div> - </div>
                <div className="ml-2 mb-1">
                  This email will not be granted user access by default. To
                  grant user access, please use Manage users and Manage user
                  access.
                </div>
              </div>
            </div>
            {/* <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4 flex justify-content-center ">
              <div className="inputLabelNotification">
                (  )
              </div>
            </div> */}
          </div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">
                Fund Website{" "}
                <span className="fs-6">
                  (enter the complete URL with http:// or https://)
                </span>
              </label>
              <InputText
                name="fundWebsite"
                value={formik.values.fundWebsite}
                onChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("fundWebsite"),
                  "card-inputBox": true,
                })}
                disabled={disableField === true}
                placeholder="Enter fund Website"
              />
              {getFormErrorMessage("fundWebsite")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Email Logo URL </label>
              <InputText
                name="emailLogoUrl"
                value={formik.values.emailLogoUrl}
                onChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("emailLogoUrl"),
                  "card-inputBox": true,
                })}
                disabled={disableField === true}
                placeholder="Enter email logo url"
              />
              {getFormErrorMessage("emailLogoUrl")}
            </div>
          </div>

          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Fund Color* </label>
              <div className="relative-container ">
                <InputText
                  name="fundPrimaryColour"
                  value={formik.values.fundPrimaryColour}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("fundPrimaryColour"),
                    "card-inputBox": true,
                  })}
                  maxLength={7}
                  disabled={disableField === true}
                  placeholder="Select fund color"
                />

                <ColorPicker
                  format="hex"
                  name="fundPrimaryColour"
                  value={formik.values.fundPrimaryColour}
                  onChange={(e) => {
                    console.log("e", e);
                    handleChange(e);
                  }}
                  className="input-colorpicker"
                />
              </div>
              {getFormErrorMessage("fundPrimaryColour")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Unit Price ($1 or $10)*</label>

              <InputNumber
                name="unitPrice"
                // inputId="currency-us"
                value={
                  formik.values.unitPrice === null ||
                  formik.values.unitPrice === 0
                    ? undefined
                    : formik.values.unitPrice
                }
                onValueChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("unitPrice"),
                  "w-100": true,
                })}
                disabled={disableField === true}
                placeholder="Enter price"
                // mode="currency"
                // currency="USD"
                // locale="en-US"
              />
              {getFormErrorMessage("unitPrice")}
            </div>
          </div>
          <div className="formgrid grid px-2">
            <label style={{ fontSize: "22px" }} className="inputLabel m-2 mb-4">
              PPM
            </label>
          </div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-5">
              <label className="inputLabel">Digisigner PPM Template Id</label>
              <InputText
                name="digisignerPPMTempId"
                value={formik.values.digisignerPPMTempId}
                onChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("digisignerPPMTempId"),
                  "card-inputBox": true,
                })}
                disabled={disableField === true}
                placeholder="Enter template Id"
              />
              {getFormErrorMessage("digisignerPPMTempId")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-2">
              <div className="d-flex mt-2">
                <div className="flex align-items-center ">
                  <label className="container-checkbox my-3">
                    <span>Dual Signer Enabled</span>
                    <input
                      type="checkbox"
                      // value={false}
                      name="dualSigner"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "dualSigner",
                          !formik.values.dualSigner
                        );
                      }}
                      disabled={disableField === true}
                      checked={formik.values.dualSigner === true}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-5">
              <div className="d-flex mt-2">
                <div className="flex align-items-center pr-4">
                  <label className="container-checkbox my-3">
                    <span>
                      Uncheck this box if you don&apos;t want investors to sign
                      Fund PPM
                    </span>
                    <input
                      type="checkbox"
                      // value="SoftCommit"
                      name="signFundPPM"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "signFundPPM",
                          !formik.values.signFundPPM
                        );
                      }}
                      disabled={disableField === true}
                      checked={formik.values.signFundPPM === true}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div hidden className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-5">
              <div className="d-flex mt-2">
                <div className="flex align-items-center pr-4">
                  <label className="container-checkbox my-3">
                    <span>Show Filled Investments</span>
                    <input
                      type="checkbox"
                      // value="SoftCommit"
                      name="showFilledDeals"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "showFilledDeals",
                          !formik.values.showFilledDeals
                        );
                      }}
                      disabled={disableField === true}
                      checked={formik.values.showFilledDeals === true}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
