import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBlobSasKeyService } from "../../services/sas-key/sas-key";
import error from "../../utils/error";

interface ISASState {
  data?: string;
  isLoadFirsttime: boolean;
  loading: string;
  error?: string | null;
}

const initialState: ISASState = {
  data: undefined,
  isLoadFirsttime: false,
  loading: "loading",
  error: undefined,
};

export const getSasKeyThunk = createAsyncThunk(
  "blob/getSasKey",
  async (LegalEntityId: any) => {
    try {
      const response = await getBlobSasKeyService(LegalEntityId);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "blob",
  initialState,
  reducers: {
    setFirsLoaidng: (state) => {
      state.isLoadFirsttime = false;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getSasKeyThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getSasKeyThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload?.data?.sasKey;
      state.isLoadFirsttime = true;
    });
    builder.addCase(getSasKeyThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
      throw new Error(error(state.error));
    });
  },
});

export const { setFirsLoaidng } = slice.actions;

export default slice.reducer;
