import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { exportExcel } from "../../utils/excel-dataformat";

interface IDataTableElements {
  valueData: any[];
  globalFilter?: string;
  ref?: any;
  key?: any;
  excelData?: any;
  footerElement?: any;
  className?: any;
  isPaginationAllowed?: any;
  isSelectionAllowed?: any;
  isDownload?: any;
  openDailog?: any;
  setOpenDailog?: any;
  selectedCustomers?: any;
  maxHeight?: any;
  setSelectedCustomers?: any;
  handleTheSelection?: any;
  noGridLines?: any;
  isFullView?: any;
  scrollable?: any;
  HeadingName?: any;
  perPage?: any;
  fileName?: string; // New prop for the file name
  size?: "small" | "large" | "normal";
  selectionMode?: any;
  onRowSelect?: any;
  onCellClick?: any;
  cellSelection?: boolean;
  resizableColumns?: boolean;
  columnResizeMode?: "fit" | "expand";
  rowClassName?: any;
  excelFieldsElements?: any;
  fieldsElements: {
    field?: string;
    header: string | React.ReactNode;
    headerClassName?: any;
    headerStyle?: any;
    dataType?: string;
    body?: any;
    filterElement?: any;
    className?: any;
    style?: any;
    icon?: any;
    editor?: any;
    frozen?: any;
    alignFrozen?: any;
    // bodyClassName?: string;
    bodyClassName?: string | ((rowData: any) => string) | undefined; // Update the type here
    noFilter?: any;
    noSort?: any;
    sortFunction?: any;
  }[];
}

export default function DataTableComponent(props: IDataTableElements) {
  const { excelFieldsElements, fileName } = props;
  const dt = useRef<DataTable>(null);
  const [openDailog, setOpenDailog] = useState<boolean>(false);
  // const exportCSV = (selectionOnly: any) => {
  //   dt.current!.exportCSV({ selectionOnly });
  // };

  const rowDta = 10;
  // const tableClass = `investmentTbl ${props.className}`;
  console.log("Full View", props.isFullView);

  useEffect(() => {
    if (openDailog || props.openDailog) {
      document.body.classList.add("no-scroll");
      document.body.style.overflowX = "unset";
      document.documentElement.style.overflowX = "unset";
    }
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, [openDailog, props.openDailog]);

  return (
    <>
      <Dialog
        visible={props.openDailog ? true : openDailog}
        className="w-100"
        onHide={() =>
          props.openDailog ? props.setOpenDailog(false) : setOpenDailog(false)
        }
        contentClassName="top-lr-content p-0"
        maximized={true}
      >
        <div className="grid">
          <div className="col-12 pl-2">
            <div
              className="card  tabWithoutboxshadow"
              style={{ width: "100%", position: "relative" }}
            >
              <div
                className="gridoverlapscroll"
                style={{
                  maxHeight: "calc(-57px + 100vh)",
                  // overflowX: "auto",
                  // overflowY: "auto",
                }}
              >
                {" "}
                <DataTable
                  className={
                    props.className ? props.className : "investmentTbl"
                  }
                  paginator={
                    props.isPaginationAllowed
                      ? props?.valueData?.length > rowDta
                      : undefined
                  }
                  resizableColumns={props?.resizableColumns ?? true}
                  columnResizeMode={props?.columnResizeMode ?? "expand"}
                  size={props.size ?? "normal"}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="{first}-{last} of {totalRecords} records"
                  key={props.key}
                  scrollable={props.scrollable === true}
                  scrollHeight={props.scrollable === true ? "" : ""}
                  editMode="column"
                  rows={20}
                  rowsPerPageOptions={[20, 50, 200, 500]}
                  ref={props.ref ? props.ref : dt}
                  value={props.valueData}
                  showGridlines={props.noGridLines === undefined}
                  responsiveLayout="scroll"
                  globalFilter={props.globalFilter}
                  footerColumnGroup={props.footerElement}
                  selectionMode={
                    props.isSelectionAllowed
                      ? "checkbox"
                      : props.selectionMode
                      ? props.selectionMode
                      : "single"
                  }
                  onRowSelect={
                    props.selectionMode ? props.onRowSelect : undefined
                  }
                  selection={props.selectedCustomers}
                  onSelectionChange={(e) => {
                    props.isSelectionAllowed ? (
                      props.handleTheSelection(e.value)
                    ) : (
                      <></>
                    );
                  }}
                  rowClassName={props.rowClassName || ""}
                  // sortOrder={-1}
                >
                  {props.isSelectionAllowed && (
                    <Column
                      header="Select"
                      selectionMode="multiple"
                      headerStyle={{
                        width: "3rem",
                        backgroundColor: "white",
                        padding: "2px",
                      }}
                      bodyStyle={{
                        width: "3rem",
                      }}
                      className="selectbox-style"
                    />
                  )}
                  {props.fieldsElements?.map((data, index) => {
                    data.noFilter = data.noFilter ? data.noFilter : false;
                    data.noSort = data.noSort ? data.noSort : false;
                    return (
                      <Column
                        key={index}
                        field={data.field === "" ? undefined : data.field}
                        header={data.header}
                        headerClassName={data.headerClassName}
                        headerStyle={data.headerStyle}
                        sortable={!!data.field && data.noSort === false}
                        filter={!!data.field && data.noFilter === false}
                        dataType={data.dataType}
                        body={data.body}
                        filterElement={data.filterElement}
                        className={data.className}
                        style={data.style}
                        bodyClassName={data.bodyClassName}
                        frozen={data.frozen === true}
                        alignFrozen={
                          data.alignFrozen ? "left" : data.alignFrozen
                        }
                        editor={data.editor} // Use the editor function from data object
                        // onCellEditComplete={(e) => {
                        //   data.onCellEditComplete(e.value);
                        // }}
                      ></Column>
                    );
                  })}

                  {/* <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column> */}
                </DataTable>
              </div>
              {/* </Card> */}
            </div>
          </div>
        </div>
      </Dialog>
      {props.scrollable ? (
        <>
          {props.HeadingName ? (
            <div className="grid col-12 p-0">
              <div className="col-12 md:col-9  pl-1 p-0">
                <div className="card pgHeaderWrap md:my-2 mt-3">
                  <h1 className="pageHeader fs-2">{props.HeadingName}</h1>
                  <p className="pageSubHeader"> </p>
                </div>
              </div>
              <div className="flex justify-content-end gap-2 md:col-3">
                {props.isDownload ? (
                  <div className="flex align-items-center gap-2 mb-2">
                    <Button
                      className="remove-shadow"
                      type="button"
                      // icon="pi pi-file"
                      // rounded
                      onClick={() =>
                        exportExcel(
                          excelFieldsElements,
                          props.valueData,
                          fileName,
                          excelFieldsElements
                        )
                      }
                      data-pr-tooltip="exportExcel"
                      disabled={
                        props?.valueData === undefined ||
                        props?.valueData?.length === 0 ||
                        props?.valueData === null
                      }
                    >
                      {" "}
                      <span className="blueButtonShadowText">Download </span>
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
                {props.isFullView && props?.valueData?.length > 0 && (
                  <div className="flex align-items-center gap-2 mb-2">
                    <Button
                      className="btn-navActive"
                      type="button"
                      icon="pi pi-window-maximize"
                      onClick={() => setOpenDailog(true)}
                      title="View Full Screen"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="grid">
            <div className="col-12 pl-2">
              <div
                className="card  tabWithoutboxshadow"
                style={{ width: "100%", position: "relative" }}
              >
                <div
                  className="gridoverlapscroll"
                  style={{
                    maxHeight: props.maxHeight
                      ? `${props.maxHeight}`
                      : "calc(-133px + 100vh)",
                    overflowX: "auto",
                    overflowY: "auto",
                  }}
                >
                  {" "}
                  <DataTable
                    className={
                      props.className ? props.className : "investmentTbl"
                    }
                    paginator={
                      props.isPaginationAllowed
                        ? props?.valueData?.length > rowDta
                        : undefined
                    }
                    resizableColumns={props?.resizableColumns ?? true}
                    columnResizeMode={props?.columnResizeMode ?? "expand"}
                    size={props.size ?? "normal"}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{first}-{last} of {totalRecords} records"
                    key={props.key}
                    scrollable={props.scrollable === true}
                    scrollHeight={props.scrollable === true ? "" : ""}
                    editMode="column"
                    rows={20}
                    rowsPerPageOptions={[20, 50, 200, 500]}
                    ref={props.ref ? props.ref : dt}
                    value={props.valueData}
                    showGridlines={props.noGridLines === undefined}
                    responsiveLayout="scroll"
                    globalFilter={props.globalFilter}
                    footerColumnGroup={props.footerElement}
                    selectionMode={
                      props.isSelectionAllowed
                        ? "checkbox"
                        : props.selectionMode
                        ? props.selectionMode
                        : "single"
                    }
                    onRowSelect={
                      props.selectionMode ? props.onRowSelect : undefined
                    }
                    selection={props.selectedCustomers}
                    onSelectionChange={(e) => {
                      props.isSelectionAllowed ? (
                        props.handleTheSelection(e.value)
                      ) : (
                        <></>
                      );
                    }}
                    rowClassName={props.rowClassName || ""}
                    // sortOrder={-1}
                  >
                    {props.isSelectionAllowed && (
                      <Column
                        header="Select"
                        selectionMode="multiple"
                        headerStyle={{
                          width: "3rem",
                          backgroundColor: "white",
                          padding: "2px",
                        }}
                        bodyStyle={{
                          width: "3rem",
                        }}
                        className="selectbox-style"
                      />
                    )}
                    {props.fieldsElements?.map((data, index) => {
                      data.noFilter = data.noFilter ? data.noFilter : false;
                      data.noSort = data.noSort ? data.noSort : false;
                      return (
                        <Column
                          key={index}
                          field={data.field === "" ? undefined : data.field}
                          header={data.header}
                          headerClassName={data.headerClassName}
                          headerStyle={data.headerStyle}
                          sortable={!!data.field && data.noSort === false}
                          filter={!!data.field && data.noFilter === false}
                          dataType={data.dataType}
                          body={data.body}
                          filterElement={data.filterElement}
                          className={data.className}
                          style={data.style}
                          bodyClassName={data.bodyClassName}
                          frozen={data.frozen === true}
                          alignFrozen={
                            data.alignFrozen ? "left" : data.alignFrozen
                          }
                          editor={data.editor} // Use the editor function from data object
                          // onCellEditComplete={(e) => {
                          //   data.onCellEditComplete(e.value);
                          // }}
                        ></Column>
                      );
                    })}

                    {/* <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column> */}
                  </DataTable>
                </div>
                {/* </Card> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {props.isDownload || props.isFullView ? (
            <div className="flex align-items-center justify-content-end gap-2 mb-2">
              {props.isDownload && (
                <Button
                  className="blueButtonShadow"
                  type="button"
                  // icon="pi pi-file"
                  // rounded
                  onClick={() =>
                    exportExcel(
                      excelFieldsElements,
                      props.valueData,
                      fileName,
                      excelFieldsElements
                    )
                  }
                  data-pr-tooltip="exportExcel"
                  disabled={
                    props?.valueData === undefined ||
                    props?.valueData?.length === 0 ||
                    props?.valueData === null
                  }
                >
                  {" "}
                  <span className="blueButtonShadowText">Download </span>
                </Button>
              )}
              {props.isFullView && props?.valueData?.length > 0 && (
                <div className="flex align-items-center gap-2 mb-2">
                  <Button
                    className="btn-navActive"
                    type="button"
                    icon="pi pi-window-maximize"
                    onClick={() => setOpenDailog(true)}
                    title="View Full Screen"
                  />
                </div>
              )}
            </div>
          ) : (
            <></>
          )}

          <DataTable
            className={props.className ? props.className : "investmentTbl"}
            paginator={
              props.isPaginationAllowed
                ? props?.valueData?.length > rowDta
                : undefined
            }
            resizableColumns={props?.resizableColumns ?? false}
            columnResizeMode={props?.columnResizeMode ?? "expand"}
            size={props.size ?? "normal"}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first}-{last} of {totalRecords} records"
            key={props.key}
            scrollable={props.scrollable === true}
            scrollHeight={props.scrollable === true ? "600px" : ""}
            editMode="column"
            rows={props?.perPage ? props.perPage[0] : 20}
            rowsPerPageOptions={
              props?.perPage ? props.perPage : [20, 50, 200, 500]
            }
            ref={props.ref ? props.ref : dt}
            value={props.valueData}
            showGridlines={props.noGridLines === undefined}
            responsiveLayout="scroll"
            globalFilter={props.globalFilter}
            footerColumnGroup={props.footerElement}
            selectionMode={
              props.isSelectionAllowed
                ? "checkbox"
                : props.selectionMode ?? undefined
            }
            onRowSelect={props.selectionMode ? props.onRowSelect : undefined}
            cellSelection={props.cellSelection ?? undefined}
            onCellClick={props.cellSelection ? props.onCellClick : undefined}
            selection={props.selectedCustomers}
            onSelectionChange={(e) => {
              props.isSelectionAllowed ? (
                props.handleTheSelection(e.value)
              ) : (
                <></>
              );
            }}
            rowClassName={props.rowClassName || ""}
            // sortOrder={-1}
          >
            {props.isSelectionAllowed && (
              <Column
                header="Select"
                selectionMode="multiple"
                headerStyle={{
                  width: "3rem",
                  backgroundColor: "white",
                  padding: "2px",
                }}
                bodyStyle={{
                  width: "3rem",
                }}
                className="selectbox-style"
              />
            )}
            {props.fieldsElements?.map((data, index) => {
              data.noFilter = data.noFilter ? data.noFilter : false;
              data.noSort = data.noSort ? data.noSort : false;
              return (
                <Column
                  key={index}
                  field={data.field === "" ? undefined : data.field}
                  header={data.header}
                  headerClassName={data.headerClassName}
                  headerStyle={data.headerStyle}
                  sortable={!!data.field && data.noSort === false}
                  filter={!!data.field && data.noFilter === false}
                  dataType={data.dataType}
                  body={data.body}
                  filterElement={data.filterElement}
                  className={data.className}
                  style={data.style}
                  bodyClassName={data.bodyClassName}
                  frozen={data.frozen === true}
                  alignFrozen={data.alignFrozen ? "left" : data.alignFrozen}
                  editor={data.editor} // Use the editor function from data object
                  // onCellEditComplete={(e) => {
                  //   data.onCellEditComplete(e.value);
                  // }}
                  {...(data.sortFunction && {
                    sortFunction: data.sortFunction,
                  })}
                ></Column>
              );
            })}

            {/* <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column> */}
          </DataTable>
        </>
      )}
    </>
  );
}
