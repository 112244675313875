import { FormikValues } from "formik";
// import { Card } from "primereact/card";
// import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React from "react";
import { useAppSelector } from "../../../../../store/hooks";
import { formatZipCode, onlyCharacter } from "../../../../../utils/helper";
// import { setFormikCountryCode } from "../../../../utils/multi-investing-account/set-formik-data";

interface IProps {
  accountTypeID: number;
  formik: FormikValues;
  isFormFieldInvalid: any;
  getFormErrorMessage: any;
}

const AccountMailingInformation: React.FC<IProps> = (props) => {
  const countriesDropdown = useAppSelector((state) => {
    return state.investingAccountsLookups.countriesDropdown;
  });
  const stateDropdown = useAppSelector((state) => {
    return state.investingAccountsLookups.stateDropdown;
  });

  // const address = useAppSelector((state) => {
  //   return state.investorProfileDetails.Data;
  // });

  // console.log("investorProfileDetails", address);
  // useEffect(() => {
  //   if (props.formik.values.checked) {
  //     props.formik.setFieldValue("address", address?.[0].address);
  //     props.formik.setFieldValue("address2", address?.[0].address2);
  //     props.formik.setFieldValue("countryId", 229);
  //     props.formik.setFieldValue("city", address?.[0].city);
  //     props.formik.setFieldValue("state", address?.[0].usState);
  //     props.formik.setFieldValue("zipCode", address?.[0].zipCode);
  //   }
  //   if (props.formik.values.checked === false) {
  //     props.formik.setFieldValue("address", "");
  //     props.formik.setFieldValue("address2", "");
  //     props.formik.setFieldValue("countryId", 229);
  //     props.formik.setFieldValue("city", "");
  //     props.formik.setFieldValue("state", "");
  //     props.formik.setFieldValue("zipCode", "");
  //   }
  // }, [props.formik.values.checked]);
  return (
    <>
      <div className="row d-flex justify-content-start">
        <div className="col-md-5">
          <h4 className="investorHeader">Account Mailing Information</h4>
        </div>
      </div>
      <div>
        <div className="formgrid grid">
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Mailing Address Line 1<span className="text-red-500">*</span>
            </label>
            <InputText
              name="address"
              value={props.formik.values.address}
              placeholder="Enter address line 1"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "address",
                  e.target.value?.trimStart()
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("address"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("address")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">Mailing Address Line 2</label>
            <InputText
              name="address2"
              value={props.formik.values.address2}
              // unmask={true}
              placeholder="Enter Address Line 2"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "address2",
                  e.target.value?.trimStart()
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("address2"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("address2")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Country <span className="text-red-500">*</span>
            </label>
            <Dropdown
              name="countryId"
              value={props.formik.values.countryId}
              options={countriesDropdown}
              filter
              showOnFocus
              optionLabel="value"
              optionValue="key"
              placeholder="Select Country"
              // className="card-inputBox"
              onChange={(e) => {
                // setFormikCountryCode(
                //   props.formik,
                //   countriesDropdown,
                //   e.value
                // );

                props.formik.setFieldValue("zipCode", "");
                props.formik.setFieldValue("trustEstablishmentPlace", "");
                props.formik.setFieldValue("state", "");
                props.formik.setFieldValue("countryId", e.value);
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("countryId"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("countryId")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              City <span className="text-red-500">*</span>
            </label>
            <InputText
              name="city"
              value={props.formik.values.city}
              placeholder="Enter City"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "city",
                  onlyCharacter(e.target.value?.trimStart())
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("city"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("city")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              State <span className="text-red-500">*</span>
            </label>
            {props.formik.values.countryId !== 229 ? (
              <InputText
                name="state"
                value={props.formik.values.state}
                placeholder="Enter State"
                onChange={(e) => {
                  props.formik.setFieldValue(
                    "state",
                    onlyCharacter(e.target.value)
                  );
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid("state"),
                  "card-inputBox": true,
                })}
              />
            ) : (
              <Dropdown
                name="state"
                value={props.formik.values.state}
                options={stateDropdown}
                showOnFocus
                filter
                optionLabel="value"
                optionValue="key"
                placeholder="Select State"
                // className="card-inputBox"
                onChange={(e) => {
                  console.log("e", e);
                  props.formik.setFieldValue("state", e.value);
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid("state"),
                  "card-inputBox": true,
                })}
              />
            )}

            {props.getFormErrorMessage("state")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              ZIP Code<span className="text-red-500">*</span>
            </label>
            <InputText
              name="zipCode"
              value={props.formik.values.zipCode}
              placeholder="Enter ZIP Code"
              maxLength={props.formik.values.countryId === 229 ? 10 : 20}
              onChange={(e) => {
                const zipCode = formatZipCode(
                  e.target.value,
                  props.formik.values.countryId
                );
                props.formik.setFieldValue("zipCode", zipCode);
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("zipCode"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("zipCode")}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountMailingInformation;
