import React, { useEffect, useState } from "react";
import { initialInvestmentState } from "../../../../../store/features/investment/create/payload";
import { setForm } from "../../../../../store/features/investment/create/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { CreateNewOffering } from "../create-forms/CreateNewOffering";
import { CreateStep2 } from "../create-forms/CreateStep2";
import { CreateStep3 } from "../create-forms/CreateStep3";
import { CreateStep4 } from "../create-forms/CreateStep4";
import { CreateStep5 } from "../create-forms/CreateStep5";
import { CreateStep6 } from "../create-forms/CreateStep6";
import { CreateStep7 } from "../create-forms/CreateStep7";

export default function CreateInvestmentsAndDeals() {
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useAppDispatch();
  const [InvestmentordealStep, setInvestmentordealStep] =
    useState("Investment");
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const stateInvestment = useAppSelector((state) => state.addInvestment);

  const totalSteps = 6; // Update with the total number of steps

  useEffect(() => {
    console.log("on 2 useEffect");
    if (stateInvestment.data?.productState === "SoftCommit") {
      setInvestmentordealStep("Deal");
    } else {
      setInvestmentordealStep("Investment");
    }
  }, [stateInvestment]);

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };
  useEffect(() => {
    setCurrentStep(0);
    dispatch(setForm({ ...stateInvestment.data, ...initialInvestmentState }));
  }, [fundDropdown.fundid]);
  const handlePrevious = () => {
    if (currentStep >= 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  console.log("state", stateInvestment);
  let content;

  switch (currentStep) {
    case 0:
      content = <CreateNewOffering handleNextInvestment={handleNext} />;
      break;
    case 1:
      content = (
        <CreateStep2
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          offering={InvestmentordealStep}
        />
      );
      break;
    case 2:
      content = (
        <CreateStep3
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          offering={InvestmentordealStep}
        />
      );
      break;
    case 3:
      content = (
        <CreateStep4
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          offering={InvestmentordealStep}
        />
      );
      break;
    case 4:
      content = (
        <CreateStep7
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          offering={InvestmentordealStep}
        />
      );
      break;
    case 5:
      content = (
        <CreateStep5
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          offering={InvestmentordealStep}
        />
      );
      break;
    case 6:
      content = (
        <CreateStep6
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          offering={InvestmentordealStep}
        />
      );
      break;
    default:
      content = <></>;
      break;
  }
  return <>{content}</>;
}
