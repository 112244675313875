import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import Loader from "../../../../../components/loader/Loader";
import { setForm } from "../../../../../store/features/investment/create/slice";
import { getSupplierListThunk } from "../../../../../store/features/investment/getsupplierlist/slice";
// import { setFormData } from "../../../../../store/features/investment/new-investment";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { DropdownOptionType } from "../../../../../types";

type CreateInvestmentStep4Props = {
  handlePrevious: () => void;
  handleNext: () => void;
  toast: any;
};
const CreateInvestmentStep4: React.FC<CreateInvestmentStep4Props> = ({
  handlePrevious,
  handleNext,
  toast,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const stateInvetsment = useAppSelector((state) => state.addInvestment.data);
  const GetSupplierList = useAppSelector(
    (state) => state.GetSupplierList?.data
  );
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const supplierDropdownList: DropdownOptionType[] | [] = useMemo(
    () =>
      GetSupplierList?.length && GetSupplierList?.length > 0
        ? GetSupplierList?.map((item: any) => {
            return {
              name: item.supplierName,
              code: item.supplierID,
            };
          })
        : [],
    [GetSupplierList]
  );

  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      supplierID: 0,
    },
    validate: (data) => {
      const errors: any = {};
      if (!data.supplierID) {
        errors.supplierID = "Select a supplier";
      }
      return errors;
    },
    onSubmit: (values) => {
      dispatch(
        setForm({
          ...stateInvetsment,
          supplierID: values.supplierID,
        })
      );
      handleNext();
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const { values, setFieldValue, handleSubmit } = formik;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      setLoading(true);
      const parameters = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(getSupplierListThunk(parameters)).finally(() => {
        setLoading(false);
      });
    }
  }, [fundDropdown.fundid]);
  useEffect(() => {
    setFieldValue("supplierID", stateInvetsment?.supplierID);
  }, [stateInvetsment]);
  return (
    <>
      <Toast ref={toast} className="themeToast" />
      {loading ? <Loader /> : <></>}
      <div className="formgrid grid px-2">
        <div className="d-flex flex-column p-2 col-12 col-md-12 col-sm-12">
          <label className="card-subheader">Select Supplier</label>
          <div>
            <Dropdown
              name="supplierID"
              value={values.supplierID}
              onChange={(e) => {
                setFieldValue("supplierID", e.value);
              }}
              options={
                supplierDropdownList === null ? [] : supplierDropdownList
              }
              filter
              optionLabel="name"
              optionValue="code"
              placeholder="Select supplier from dropdown"
              className={classNames({
                "p-invalid": isFormFieldInvalid("supplierID"),
                "card-inputBox": true,
              })}
            />
            {getFormErrorMessage("supplierID")}
          </div>

          <NavLink
            to="/ManageFundSupplier"
            className="card-subheader link-underline mt-2 col-12 col-md-12 col-sm-12 w-max"
          >
            Don&apos;t see your supplier? Set it up first here.{" "}
          </NavLink>
        </div>
      </div>

      <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
        <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
          <label className="inputLabel">&nbsp;</label>

          <Button
            className="btn-nav btnFocus"
            // tabIndex={0}

            onClick={(e) => {
              e.preventDefault();
              handlePrevious();
            }}
          >
            <span className="ml-auto mr-auto ">Back</span>
          </Button>
          <label className="inputLabel">&nbsp;</label>
        </div>
        <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
          <label className="inputLabel">&nbsp;</label>
          <Button
            className="btn-dialog btnFocus"
            type="submit"
            // tabIndex={1}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <span className="ml-auto mr-auto ">Next</span>
          </Button>
          <label className="inputLabel">&nbsp;</label>
        </div>
      </div>
    </>
  );
};

export default CreateInvestmentStep4;
