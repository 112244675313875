export const initialInvestingAccountValue = {
  action: "Create",
  investingAccountId: 0,
  myAccountName: "",
  accountType: "",
  // firstName: "",
  // lastName: "",
  legalInvestingAccountName: "",
  disRegarded: undefined,
  entityName: "",
  trustEstablishmentPlace: "",
  primaryAccountFirstName: "",
  primaryAccountLastName: "",
  accountOwnershipId: "",

  socialSecurityNumber: "",
  ein: "",
  primaryAccountHolderSSN: "",
  // businessName: "",
  isEIN: "",
  custodianAccountName: "",
  taxclassification: "",
  subAccountType: "",
  revocabilityId: "",
  trustTypeId: "",

  // custoidain
  custodianId: 0,

  accountTitle: "",
  accountTitleSDIRA: "",
  custodianEmail: "",
  custodianWebsite: "",
  custodianPhoneNumber: "",
  custodianName: "",
  custodianRoutingNumber: undefined,
  custodianAccountNumber: undefined,

  // mailing address
  addressId: 0,
  address: "",
  address2: "",
  countryId: 229,
  city: "",
  state: "",
  zipCode: "",
  checked: undefined,
  // Seconday details
  secHolderId: 0,
  secFirstName: "",
  secLastName: "",
  secEmail: "",
  secSSN: "",
  secPhoneNumber: "",
  secAddressId: 0,
  secAddress: "",
  secAddress2: "",
  secCity: "",
  secCountryId: 229,
  secState: "",
  secZipCode: "",
  secCountryCode: "1",

  // beneficiary details
  beneficiaryAddressId: 0,
  beneficiaryId: 0,
  beneficiaryFirstName: "",
  beneficiaryLastName: "",
  beneficiaryRelationship: 0,
  beneficiaryPhoneNumber: "",
  beneficiaryEmail: "",
  beneficiaryAddressLine1: "",
  beneficiaryAddressLine2: "",
  beneficiaryCountryId: 229,
  beneficiaryCity: "",
  beneficiaryZipCode: "",
  beneficiaryState: "",
  beneficiarycountryCode: "1",

  //
  noBeneficialMemberInformation: "",
  memberInformation: [
    {
      id: 0,
      investingAcountId: 0,
      firstName: "",
      lastName: "",
      ssn: "",
      birthDate: "",
      phoneNumber: "",
      email: "",
      addressID: 0,
      addressLine1: "",
      addressLine2: "",
      country: 229,
      city: "",
      zipCode: "",
      state: "",
      createdBy: "",
      updatedBy: "",
      countryCode: "1",
    },
  ],
  noOfTrustees: "",
  trusteeInfo: [
    {
      firstName: "",
      lastName: "",
      ssn: "",
      birthDate: "",
      phoneNumber: "",
      email: "",
      addressLine1: "",
      addressLine2: "",
      country: 229,
      city: "",
      zipCode: "",
      state: "",
      countryCode: "1",
    },
  ],
  noOfPartnerInfo: "",
  partnerInfo: [
    {
      firstName: "",
      lastName: "",
      ssn: "",
      birthDate: "",
      phoneNumber: undefined,
      email: "",
      addressLine1: "",
      addressLine2: "",
      country: 229,
      city: "",
      zipCode: "",
      state: "",
      countryCode: "1",
    },
  ],
  // Executive Officer Information
  noOfOfficerInfo: "",
  officerInfo: [
    {
      firstName: "",
      lastName: "",
      title: "",
      birthDate: "",
      phoneNumber: undefined,
      email: "",
      addressLine1: "",
      addressLine2: "",
      country: 229,
      city: "",
      zipCode: "",
      state: "",
      countryCode: "1",
    },
  ],

  isConfimredAndApproved: false,
  isPPMByPassed: false,
  isAccreditationByPassed: false,

  // isPersonalProfileCompleted: false,
  isRequiredFieldCompleted: false,
  isKYCCompleted: false,
  isAccreditationCompleted: false,
  // isInvestingAccountInformationCompleted: false,
  isFundLegalDocumentsCompleted: false,
};

const initialState = {
  firstNamePP: "",
  lastNamePP: "",
  cityPP: "",
  emailPP: "",
  phonePP: "",
  addressPP: "",
  apartmentPP: "",
  zipcodePP: "",
  statePP: "",
  electronicCommunicationPP: undefined,
  electronicK1DeliveryPP: undefined,
  investorGuidancePP: "",
  internalNotesPP: "",
  isApprovePP: 0,
  form: "",
  countryIdPP: 229,
  documentLinkPP: undefined,
  countryCodePP: "1",

  countryCode: "1",
  ...initialInvestingAccountValue,

  lastAccreditationVerified: "",
  accreditationExpires: "",
  isAccredited: undefined,

  isApprove: 0,
  internalNotes: "",
  isDualSigner: false,
};

export default initialState;
