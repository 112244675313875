import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetReconciliationPopupService } from "../../../../services/Accounting/accounting";
import error from "../../../../utils/error";
import {
  IGetReconciliationPopupState,
  IGetReconciliationPopupparameters,
} from "./index";

const initialState: IGetReconciliationPopupState = {
  success: false,
  message: "",
  loading: "loading",
  data: [],
  status: [],
};
export const GetReconciliationPopupThunk = createAsyncThunk(
  "GetReconciliationPopup",
  async (data: IGetReconciliationPopupparameters) => {
    try {
      const response = await GetReconciliationPopupService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "GetReconciliationPopup",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(GetReconciliationPopupThunk.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(GetReconciliationPopupThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload ? action.payload._response.data : [];
      state.status = action.payload ? action.payload._response.data : [];
    });
    builder.addCase(GetReconciliationPopupThunk.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default slice.reducer;
