import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCalculateEarningsPooledfundsService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { ICalclateParameter, IGetCalculateEarningState } from "./type";

const initialState: IGetCalculateEarningState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
  summary: [],
};
export const getThunkCalculateEarningPooledfunds = createAsyncThunk(
  "getCalculateEarning",
  async (data: ICalclateParameter) => {
    try {
      console.log("data", data);
      const response = await getCalculateEarningsPooledfundsService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getCalculateEarningPooledfunds",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkCalculateEarningPooledfunds.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getThunkCalculateEarningPooledfunds.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log("hr", action.payload._response);
        state.data =
          action.payload._response.data !== null
            ? action.payload._response.data.output
            : [];
        state.summary =
          action.payload._response.data !== null
            ? action.payload._response.data.summary
            : [];

        state.success = action.payload._response.success;
        state.message = action.payload._response.message;
      }
    );
    builder.addCase(
      getThunkCalculateEarningPooledfunds.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
        throw new Error(error(action.error));
      }
    );
  },
});

export default slice.reducer;
