import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../components/loader/Loader";
import { getDocumentDwollaCustomerThunk } from "../../../../store/dwolla/document-dwollacustomer";
import { getDwollaDocumentTypeThunk } from "../../../../store/dwolla/dwolla-document-type";
import { uploadDwollaDocumentsThunk } from "../../../../store/dwolla/upload-dwolla-documents";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

const DwollaCustomerDocument: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const fileRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [docName, setDocName] = useState("");
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const dwollaCustomer = useAppSelector(
    (state) => state.documentDwollacustomer.data
  );
  const dwollaDocumentType = useAppSelector(
    (state) => state.dwollaDocumentType.data
  );
  const getDocumentType = () => {
    setLoading(true);
    dispatch(getDwollaDocumentTypeThunk()).finally(() => setLoading(false));
  };
  useEffect(() => {
    if (fundDropdown.fundid > 0 && fundDropdown.fundid !== undefined) {
      setLoading(true);
      dispatch(getDocumentDwollaCustomerThunk(fundDropdown.fundid)).finally(
        () => {
          setLoading(false);
          getDocumentType();
        }
      );
    }
  }, [fundDropdown.fundid]);

  const formik = useFormik({
    initialValues: {
      investorName: "",
      documentType: "",
      files: "",
    },
    validate: (data: any) => {
      const errors: any = {};
      if (!data.investorName) {
        errors.investorName = "Investor name is required";
      }

      if (!data.documentType) {
        errors.documentType = "Document type is required";
      }

      if (!data.files) {
        errors.files = "Files is required";
      }

      return errors;
    },
    onSubmit: async (values: any) => {
      const data = new FormData();
      data.append("Files", values.files === undefined ? "" : values.files);
      data.append("CustomerId", values.investorName.toString());
      data.append("documentType", values.documentType);
      setLoading(true);
      dispatch(uploadDwollaDocumentsThunk(data))
        .then((response) => {
          setLoading(false);
          if (response.payload.success) {
            successToastMessage(toast, response.payload.message);
            fileRef?.current.clear();
            formik.resetForm();
            setDocName("");
          } else {
            errorToastMessage(toast, response.payload.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          errorToastMessage(toast);
          console.log("error uploadDwollaDocumentsThunk", error);
        });
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const uploadFile = async (e: any) => {
    const file = e.files[0];
    const otherRegex = /(.*?).(pdf|png|jpeg|jpg)$/;
    const regex = /(.*?).(png|jpeg|jpg)$/;
    if (formik.values.documentType === "other") {
      if (!otherRegex.test(file.name.toLowerCase())) {
        errorToastMessage(
          toast,
          "Upload failed. Please Use Images, Plain Text Documents format only "
        );
        fileRef?.current.clear();
        formik.setFieldValue("files", "");
        setDocName("");
        return;
      }
    } else {
      if (!regex.test(file.name.toLowerCase())) {
        errorToastMessage(
          toast,
          "Upload failed. Please Use Images format only "
        );
        fileRef?.current.clear();
        formik.setFieldValue("files", "");
        setDocName("");
        return;
      }
    }

    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 20) {
      errorToastMessage(
        toast,
        "Upload failed. Please ensure the file size does not exceed 20mb"
      );
      fileRef?.current.clear();
      formik.setFieldValue("files", "");
      setDocName("");
      return;
    }
    setDocName(file.name);
    formik.setFieldValue("files", file);
    fileRef?.current.clear();
  };
  const resetForm = () => {
    fileRef?.current.clear();
    formik.resetForm();
    setDocName("");
  };

  return (
    <>
      {loading && <Loader />}
      <Toast ref={toast} className="themeToast" />
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Dwolla Customer Document</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card my-3 tabWithoutboxshadow">
            <Card>
              <div className="grid justify-content-start">
                <div className="tabHeader-wrap mb-3  mr-3">
                  <h4 className="cardHeader"> Document Upload </h4>
                </div>
              </div>
              <>
                <div className="mb-4">
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 md:col-6 ">
                      <label className="inputLabel">Investor Account *</label>
                      <Dropdown
                        name="inv"
                        style={{ width: "100%" }}
                        options={dwollaCustomer || []}
                        optionLabel="key"
                        filter
                        resetFilterOnHide={true}
                        showFilterClear={true}
                        placeholder="Select Investor Account"
                        onChange={(e) =>
                          formik.setFieldValue("investorName", e.value)
                        }
                        value={formik.values.investorName}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("investorName"),
                          "card-inputBox": true,
                        })}
                      />
                      {getFormErrorMessage("investorName")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 md:col-6 ">
                      <label className="inputLabel">Document Type *</label>
                      <Dropdown
                        name="inv"
                        style={{ width: "100%" }}
                        options={dwollaDocumentType || []}
                        optionLabel="key"
                        filter
                        resetFilterOnHide={true}
                        showFilterClear={true}
                        placeholder="Select.."
                        onChange={(e) =>
                          formik.setFieldValue("documentType", e.value)
                        }
                        value={formik.values.documentType}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("documentType"),
                          "card-inputBox": true,
                        })}
                      />
                      {getFormErrorMessage("documentType")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 md:col-6 ">
                      <label className="inputLabel">Upload File *</label>
                      <FileUpload
                        mode="basic"
                        name={"files"}
                        ref={fileRef}
                        auto={true}
                        customUpload={true}
                        chooseLabel="Select File"
                        className="btn-dialog border-round-md w-100 UploadAccreBtn"
                        uploadHandler={uploadFile}
                        disabled={
                          !formik.values.documentType ||
                          !formik.values.investorName
                        }
                      />
                      {getFormErrorMessage("files")}
                      <label className="inputLabel mt-1">{docName}</label>
                    </div>
                  </div>
                </div>
                <div className="formgrid grid px-2">
                  <div className="d-flex flex-column  p-2  col-12 sm:col-4 ">
                    <Button
                      // label="Cancel"
                      className=" btn-navActive"
                      onClick={resetForm}
                    >
                      <span className="ml-auto mr-auto ">Cancel</span>
                    </Button>
                  </div>
                  <div className="d-flex flex-column  p-2  col-12 sm:col-4 ">
                    <Button
                      className="btn-nav "
                      // label="Add to list"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                      }}
                    >
                      <span className="ml-auto mr-auto ">Upload</span>
                    </Button>
                  </div>
                </div>
              </>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default DwollaCustomerDocument;
