import { Accordion, AccordionTab } from "primereact/accordion";
import React from "react";
import currencyFormat from "../../utils/currency-formatter";

interface ITransaction {
  gaapTransactionTypeName: string;
  expenseValue: number;
}
interface Props {
  transactions: ITransaction[];
}

const TransactionList: React.FC<Props> = ({ transactions }) => {
  // Calculate total expense
  const expenseTotal: number = transactions.reduce(
    (total, transaction) => total + transaction.expenseValue,
    0
  );

  return (
    <>
      <Accordion activeIndex={0} className="my-3">
        <AccordionTab header="Operating Expenses">
          {transactions.map((transaction, index) => (
            <div className="flex-between p-2 border-bottom" key={index}>
              <div className="ml-3">
                <label className="text-primary">
                  {transaction.gaapTransactionTypeName}
                </label>
              </div>
              <div>
                <label>{currencyFormat(transaction.expenseValue)}</label>
              </div>
            </div>
          ))}

          <div className="flex-between p-2 border-bottom">
            <div className="ml-3">
              <label> Total Operating Expenses</label>
            </div>
            <div>
              <label>{currencyFormat(expenseTotal)}</label>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </>
  );
};

export default TransactionList;
