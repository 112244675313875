import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetTemplatebyProductService } from "../../../../../services/e-sign/e-sign-ondemand";
import error from "../../../../../utils/error";
import { IGetTemplateByProductState } from ".";

const initialState: IGetTemplateByProductState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkTemplateByProduct = createAsyncThunk(
  "getTemplateByProduct",
  async (data: any) => {
    try {
      const response = await GetTemplatebyProductService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getTemplateByProduct",
  initialState,
  reducers: {
    resetTemplateList: (state) => {
      state.data = [];
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkTemplateByProduct.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkTemplateByProduct.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      state.data =
        action.payload.data !== null ? action.payload.data.templateId : [];
      state.message = action.payload.message;
    });
    builder.addCase(getThunkTemplateByProduct.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export const { resetTemplateList } = slice.actions;
export default slice.reducer;
