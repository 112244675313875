import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetCalculateEarningsService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { ICalclateParameter, IGetCalculateEarningState } from ".";

const initialState: IGetCalculateEarningState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
  summary: [],
};
export const getThunkCalculateEarning = createAsyncThunk(
  "getCalculateEarning",
  async (data: ICalclateParameter) => {
    try {
      console.log("data", data);
      const response = await GetCalculateEarningsService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getCalculateEarning",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkCalculateEarning.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkCalculateEarning.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("hr", action.payload._response);
      state.data =
        action.payload._response.data !== null
          ? action.payload._response.data.output
          : [];
      state.summary =
        action.payload._response.data !== null
          ? action.payload._response.data.summary
          : [];

      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(getThunkCalculateEarning.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
      throw new Error(error(action.error));
    });
  },
});

export default slice.reducer;
