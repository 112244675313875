import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApprovedAccreddInvestorsForAllFundsdService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";
import {
  IGetApprovedAccreddInvestorsForAllFundsParameter,
  IGetApprovedAccreddInvestorsForAllFundsState,
} from ".";

const initialState: IGetApprovedAccreddInvestorsForAllFundsState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};

export const GetApprovedAccreddInvestorsForAllFundsReport = createAsyncThunk(
  "GetApprovedAccreddInvestorsForAllFunds",
  async (data: IGetApprovedAccreddInvestorsForAllFundsParameter) => {
    try {
      const response = await getApprovedAccreddInvestorsForAllFundsdService(
        data
      );
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: " GetApprovedAccreddInvestorsForAllFunds",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment

    builder.addCase(
      GetApprovedAccreddInvestorsForAllFundsReport.pending,
      (state) => {
        state.loading = "loading";
        //   state.error = undefined;
      }
    );
    builder.addCase(
      GetApprovedAccreddInvestorsForAllFundsReport.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data =
          action.payload._response.data !== null
            ? action.payload._response.data
            : [];
      }
    );
    builder.addCase(
      GetApprovedAccreddInvestorsForAllFundsReport.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
