import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestmentAllocationReportService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";
import { IInvestmentAllocationReportState } from ".";

const initialState: IInvestmentAllocationReportState = {
  success: false,
  message: "",
  loading: "loading",
  data: "",
  data2: "",
  totalCount: 0,
};
export const getInvestmentAllocationReport = createAsyncThunk(
  "investmentallocationreport",
  async (data: any) => {
    try {
      console.log("Request", data);
      const response = await getInvestmentAllocationReportService(data);
      console.log(
        "investmentReturnBySupplier",
        response,
        JSON.parse(response?.data._response.data[0][0].result)[0]
      );
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "investmentallocationreport",
  initialState,
  reducers: {
    setInvestmentAllocationReport: (state, action) => {
      state.data = action.payload.data;
      state.data2 = action.payload.data2;
      state.totalCount = action.payload.totalCount;
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(getInvestmentAllocationReport.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getInvestmentAllocationReport.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(
          "Allocation Reports",
          action.payload._response.data[0]?.[0].result
        );
        state.data = action.payload._response.data[0]?.[0]?.result;
        state.data2 = action.payload._response.data[1]?.[0]?.result;
      }
    );
    builder.addCase(getInvestmentAllocationReport.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export const { setInvestmentAllocationReport } = slice.actions;
export default slice.reducer;
