import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOpportunityProjectDetailsService } from "../../../services/opportunity/opportunity";
import { RootState } from "../../../store";
import { IOpportunityProjectDetails } from "../../../types/index";
// import apiFactory from "../../utils/api";
import error from "../../../utils/error";

interface IOpportunityProjectDetailsState {
  Data?: IOpportunityProjectDetails[] | null;
  // currentPage: number;
  loading: string;
  error?: string | null;
}

const initialState: IOpportunityProjectDetailsState = {
  Data: undefined,
  loading: "loading",
  error: undefined,
};

export const getOpportunityProjectDetailsThunk = createAsyncThunk(
  "opportunity/getOpportunityProjectDetails",
  async (id: string) => {
    try {
      const response = await getOpportunityProjectDetailsService(id);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "opportunityProjectDetails",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getOpportunityProjectDetailsThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      getOpportunityProjectDetailsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.Data = action.payload.data;
      }
    );
    builder.addCase(
      getOpportunityProjectDetailsThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});

export const getOpportunityProjectDetailsData = (state: RootState) => {
  return state.opportunityProjectDetails.Data;
};

export const getOpportunityProjectDetailsStatus = (state: RootState) =>
  state.opportunityProjectDetails.loading;
// export const getOpportunityProjectDetailsError = (state: RootState) =>
//   state.opportunityProjectDetails.error;

export default slice.reducer;
