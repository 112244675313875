import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../components/loader/Loader";
import { goToTop } from "../../../../components/scroll-to/ScrollTo";
import { addThunkBankMailingInfo } from "../../../../store/features/operation/setup-fund/add-bank-mail-info/slice";
// import { getThunkSetupFundEntityList } from "../../../../store/features/operation/setup-fund/get-setup-entity-list/slice";
import { getThunkSetupFund } from "../../../../store/features/operation/setup-fund/get-setup-fund/slice";
import { setStateChangeCheck } from "../../../../store/features/operation/setup-fund/setup-fund-state-check/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { onlyNumber } from "../../../../utils/helper";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

interface IProps {
  pageName: any;
}

export default function SetupFundBankProfile(props: IProps) {
  const { pageName } = props;
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const stateDropDownList = useAppSelector((state) => state.dropdownList.data);
  const [disableField, setDisableField] = useState(false);
  const [loading, setLoading] = useState(false);
  const setupFundInfo = useAppSelector((state) => state.getSetupFundInfo.data);
  const addCancelSetup = useAppSelector(
    (state) => state.addCancelSetupState.data
  );
  const statecheckSetupFund = useAppSelector(
    (state) => state.statecheckSetupFund.data
  );
  const entityListId = useAppSelector(
    (state) => state.getSetupFundEntityList.data
  );

  const getSetupFundData = async (
    formik: any,
    legalEntityId?: any,
    gpEntityId?: any
  ) => {
    const parameters = {
      legalEntityId,
      gpLegalEntityId: gpEntityId || 0,
    };

    setLoading(true);
    await dispatch(getThunkSetupFund(parameters))
      .then((response) => {
        if (
          // response.meta.requestStatus === "fulfilled" &&
          response.payload._response.data === null ||
          response.payload._response.data.fundbankAndMailingDetails!.length ===
            0
        ) {
          formik.resetForm();
        }
      })
      .catch(() => {
        // errorToastMessage(toast);
        formik.resetForm();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const formik = useFormik({
    initialValues: {
      bankName: "",
      bankAddress: "",
      beneficiaryAccountName: "",
      beneficiaryAddress: "",
      beneficiaryAccountNumber: "",
      achTransferABANumber: "",
      wireTransferABANumber: "",
      swiftbicNumber: "",
      checkPayableTo: "",
      checkAddress: "",
      checkCity: "",
      checkState: "",
      checkZipCode: "",
      checkCountry: "",
    },
    validate: (data) => {
      const errors: any = {};
      if (!data.bankName) {
        errors.bankName = "Bank name is required";
      }
      if (!data.checkCity) {
        errors.checkCity = "City is required";
      }
      if (!data.checkZipCode) {
        errors.checkZipCode = "Zip code is required";
      }
      // if (!data.beneficiaryAccountNumber) {
      //   errors.beneficiaryAccountNumber =
      //     "Beneficiary account number is required";
      // }
      return errors;
    },
    onSubmit: () => {
      setLoading(true);
      const formData = {
        action:
          setupFundInfo.fundbankAndMailingDetails === undefined
            ? "Create"
            : "Update",
        legalEntityID: setupFundInfo?.fundProfileDetails.legalEntityID,
        payableTo: formik.values.checkPayableTo,
        mailAddress: formik.values.checkAddress,
        mailCity: formik.values.checkCity,
        mailState: formik.values.checkState,
        mailZipCode: formik.values.checkZipCode,
        mailCountry: formik.values.checkCountry,
        bankName: formik.values.bankName,
        bankAddess: formik.values.bankAddress,
        bankACName: formik.values.beneficiaryAccountName,
        bankAccNo: formik.values.beneficiaryAccountNumber,
        beneficiaryAddress: formik.values.beneficiaryAddress,
        abaNo: formik.values.wireTransferABANumber,
        achabaNo: formik.values.achTransferABANumber,
        swiftBicCode: formik.values.swiftbicNumber,
      };

      dispatch(addThunkBankMailingInfo(formData))
        .then((response) => {
          if (response.payload._response.success === false) {
            errorToastMessage(toast);
          } else {
            successToastMessage(toast, "Data saved successfully");
            getSetupFundData(
              formik,
              setupFundInfo?.fundProfileDetails.legalEntityID,
              entityListId?.gpllcEntityId || 0
            );
          }
        })
        .catch((error: any) => {
          console.log("error", error);

          errorToastMessage(toast);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (
      name === "beneficiaryAccountNumber" ||
      name === "achTransferABANumber" ||
      name === "wireTransferABANumber"
    ) {
      const numberValue = onlyNumber(value);
      formik.setFieldValue(name, numberValue);
    } else if (name === "checkZipCode") {
      const digitsOnly = value.replace(/\D/g, "");
      // Add hyphen after every 5 digits if length is greater than 5
      let formattedValue = digitsOnly;
      if (formattedValue.length > 5) {
        formattedValue = formattedValue.replace(/(\d{5})/g, "$1-");
      }
      //
      formik.setFieldValue(name, formattedValue);
    } else {
      formik.setFieldValue(name, value);
    }
  };
  useEffect(() => {
    if (pageName === "Reports") {
      setDisableField(true);
    } else {
      setDisableField(false);
    }
    if (addCancelSetup.add === true) {
      formik.resetForm();
    }
    // else {
    //   if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
    //     getSetupFundData(
    //       formik,
    //       fundDropdown.fundid,
    //       entityListId?.gpllcEntityId || 0
    //     );
    //   }
    // }
  }, [fundDropdown, addCancelSetup]);
  useEffect(() => {
    if (setupFundInfo?.fundbankAndMailingDetails !== undefined) {
      formik.setFieldValue(
        "bankName",
        setupFundInfo?.fundbankAndMailingDetails?.bankName
      );
      formik.setFieldValue(
        "bankAddress",
        setupFundInfo?.fundbankAndMailingDetails?.bankAddess
      );
      formik.setFieldValue(
        "beneficiaryAccountName",
        setupFundInfo?.fundbankAndMailingDetails?.bankACName
      );
      formik.setFieldValue(
        "beneficiaryAddress",
        setupFundInfo?.fundbankAndMailingDetails?.beneficiaryAddress
      );
      formik.setFieldValue(
        "beneficiaryAccountNumber",
        setupFundInfo?.fundbankAndMailingDetails?.bankAccNo
      );
      formik.setFieldValue(
        "achTransferABANumber",
        setupFundInfo?.fundbankAndMailingDetails?.achabaNo
      );
      formik.setFieldValue(
        "wireTransferABANumber",
        setupFundInfo?.fundbankAndMailingDetails?.abaNo
      );
      formik.setFieldValue(
        "swiftbicNumber",
        setupFundInfo?.fundbankAndMailingDetails?.swiftBicCode
      );
      formik.setFieldValue(
        "checkPayableTo",
        setupFundInfo?.fundbankAndMailingDetails?.payableTo
      );
      formik.setFieldValue(
        "checkAddress",
        setupFundInfo?.fundbankAndMailingDetails?.mailAddress
      );
      formik.setFieldValue(
        "checkCity",
        setupFundInfo?.fundbankAndMailingDetails?.mailCity
      );
      formik.setFieldValue(
        "checkState",
        setupFundInfo?.fundbankAndMailingDetails?.mailState
      );
      formik.setFieldValue(
        "checkCountry",
        setupFundInfo?.fundbankAndMailingDetails?.mailCountry
      );
      formik.setFieldValue(
        "checkZipCode",
        setupFundInfo?.fundbankAndMailingDetails?.mailZipCode
      );
    }
  }, [setupFundInfo]);

  useEffect(() => {
    const list = { ...statecheckSetupFund.bankMailInfoApiData };
    list.NewState = formik.values;
    console.log("listing", list.NewState);
    dispatch(
      setStateChangeCheck({ ...statecheckSetupFund, bankMailInfoApiData: list })
    );
  }, [formik.values]);
  console.log("test bank", statecheckSetupFund);
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <div>
        <div className=" h-100 center-card">
          <div className="col-12 ">
            <div className="card my-3">
              <div className="headercard d-flex grid"></div>
              <form>
                <div className="mt-4">
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">Bank Name *</label>
                      <InputText
                        name="bankName"
                        value={formik.values.bankName}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("bankName"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter bank name"
                      />
                      {getFormErrorMessage("bankName")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">Bank Address</label>

                      <InputText
                        name="bankAddress"
                        value={formik.values.bankAddress}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("bankAddress"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter bank address"
                      />

                      {getFormErrorMessage("bankAddress")}
                    </div>
                  </div>

                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">
                        Beneficiary Account Name
                      </label>
                      <InputText
                        name="beneficiaryAccountName"
                        value={formik.values.beneficiaryAccountName}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid(
                            "beneficiaryAccountName"
                          ),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter beneficiary account name"
                      />

                      {getFormErrorMessage("beneficiaryAccountName")}
                    </div>

                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">Beneficiary Address</label>
                      <InputText
                        name="beneficiaryAddress"
                        value={formik.values.beneficiaryAddress}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("beneficiaryAddress"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter beneficiary address"
                      />
                      {getFormErrorMessage("beneficiaryAddress")}
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">
                        Beneficiary Account Number
                      </label>
                      <InputText
                        name="beneficiaryAccountNumber"
                        value={
                          formik.values.beneficiaryAccountNumber === null
                            ? ""
                            : formik.values.beneficiaryAccountNumber
                        }
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid(
                            "beneficiaryAccountNumber"
                          ),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        maxLength={15}
                        placeholder="Enter beneficiary account number"
                      />
                      {getFormErrorMessage("beneficiaryAccountNumber")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">
                        ACH Transfer ABA Number
                      </label>
                      <InputText
                        name="achTransferABANumber"
                        value={
                          formik.values.achTransferABANumber === null
                            ? ""
                            : formik.values.achTransferABANumber
                        }
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid(
                            "achTransferABANumber"
                          ),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        maxLength={9}
                        placeholder="Enter ACH Transfer ABA Number"
                      />
                      {getFormErrorMessage("achTransferABANumber")}
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">
                        Wire Transfer ABA Number
                      </label>
                      <InputText
                        name="wireTransferABANumber"
                        value={
                          formik.values.wireTransferABANumber === null
                            ? ""
                            : formik.values.wireTransferABANumber
                        }
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid(
                            "wireTransferABANumber"
                          ),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        maxLength={9}
                        placeholder="Enter wireTransferABANumber"
                      />

                      {getFormErrorMessage("wireTransferABANumber")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">
                        SWIFT/BIC Number (Intl. Wires)
                      </label>
                      <InputText
                        name="swiftbicNumber"
                        value={formik.values.swiftbicNumber}
                        onChange={handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("swiftbicNumber"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        maxLength={15}
                        placeholder="Enter swift/bic number"
                      />
                      {getFormErrorMessage("swiftbicNumber")}{" "}
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    <label
                      style={{ fontSize: "22px" }}
                      className="inputLabel m-2 mb-4"
                    >
                      Check Mailing Address
                    </label>
                  </div>

                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                      <label className="inputLabel">
                        Make check payable to
                      </label>
                      <InputText
                        name="checkPayableTo"
                        value={formik.values.checkPayableTo}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("checkPayableTo"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter payable to"
                      />
                      {getFormErrorMessage("checkPayableTo")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                      <label className="inputLabel">Address</label>
                      <InputText
                        name="checkAddress"
                        value={formik.values.checkAddress}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("checkAddress"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter address"
                      />
                      {getFormErrorMessage("checkAddress")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                      <label className="inputLabel">City *</label>
                      <InputText
                        name="checkCity"
                        value={formik.values.checkCity}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("checkCity"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter city"
                      />
                      {getFormErrorMessage("checkCity")}
                    </div>
                  </div>

                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                      <label className="inputLabel">State</label>
                      <Dropdown
                        inputId="checkState"
                        name="checkState"
                        value={formik.values.checkState}
                        options={
                          stateDropDownList === null
                            ? []
                            : stateDropDownList.stateDetails
                        }
                        filter
                        optionLabel="value"
                        optionValue="key"
                        placeholder="Select State"
                        // className="card-inputBox"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        disabled={disableField === true}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("state"),
                          "card-inputBox": true,
                        })}
                      />
                      {getFormErrorMessage("checkState")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                      <label className="inputLabel">Zip Code *</label>
                      <InputText
                        name="checkZipCode"
                        value={formik.values.checkZipCode}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("checkZipCode"),
                          "card-inputBox": true,
                        })}
                        maxLength={10}
                        disabled={disableField === true}
                        placeholder="Enter zip code"
                      />
                      {getFormErrorMessage("checkZipCode")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                      <label className="inputLabel">Country</label>
                      <InputText
                        name="checkCountry"
                        value={formik.values.checkCountry}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("checkCountry"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter country"
                      />
                      {getFormErrorMessage("checkCountry")}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {pageName !== "Reports" ? (
              <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
                <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
                  <Button
                    className="btn-dialog "
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      goToTop();
                      formik.handleSubmit();
                    }}
                  >
                    <span className="ml-auto mr-auto ">Save</span>
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
