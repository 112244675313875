import React from "react";
// import { NavLink } from "react-router-dom";
// import "./footer.scss";
const Footer = () => (
  <footer className="footer">
    {/* <div className="d-flex ">
          <div style={{ flexDirection: "row" }}>
            <div className="tabHeader-wrap ">
              <div
                style={{
                  flexDirection: "column",
                  padding: 5,
                  textAlign: "center",
                }}
              >
                {" "}
                <NavLink to="Contact" className="tabHeadText footertext">
                  Contact
                </NavLink>
                <NavLink to="AboutUs" className="tabHeadText footertext">
                  About Us
                </NavLink>
              </div>
              <div
                style={{
                  flexDirection: "column",
                  padding: 5,
                }}
              >
                <NavLink to="Legal" className="tabHeadText footertext">
                  Legal
                </NavLink>
                <NavLink to="" className="tabHeadText footertext">
                  Instagram
                </NavLink>
              </div>
            </div>
          </div>
        </div> */}
    <div className="px-4">
      <div className="grid">
        {/* <NavLink to="Contact" className="col-12 md:col-2">
          <p className="tabHeadText m-0">Contact</p>
        </NavLink>
        <NavLink
          to="AboutUs"
          className="tabHeadText footertext col-12 md:col-2"
        >
          <p className="tabHeadText m-0">About Us</p>
        </NavLink>
      </div>
      <div className="grid">
        <NavLink to="Legal" className="tabHeadText footertext col-12 md:col-2">
          <p className="tabHeadText m-0">Legal</p>
        </NavLink>
        <NavLink to="" className="tabHeadText footertext col-12 md:col-2">
          <p className="tabHeadText m-0">Instagram</p>
        </NavLink> */}
      </div>
    </div>
  </footer>
);

export default Footer;
