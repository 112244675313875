import { IDropdownParameter } from "../../store/features/dropdown/dropdown-list";
// import { ISupplierParmeter } from "../../store/features/dropdown/supplier";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function getAllSupplierListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetSupplier?LegalEntityId=${
      !data.legalEntityId ? 0 : data.legalEntityId
    }&ProductState=${
      data.productState === undefined ? "all" : data.productState
    }`
  );
}

export function getAllDropDownListService(data: IDropdownParameter) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetDropDownListForCreateInv?LegalEntityId=${
      data.legalEntityID
    }&SupplierId=${data.supplierID}`
  );
}

export function getLookupCodeListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetDropDownListForLookupCode?LegalEntityID=${
      data.legalEntityId
    }&SupplierId=${data.supplierId}&ProductState=${
      data.productState === undefined ? "all" : data.productState
    }`
  );
}
