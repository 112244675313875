import { FormikValues } from "formik";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React from "react";
import { useAppSelector } from "../../../../../store/hooks";
import {
  formatZipCode,
  isNumberAndHypen,
  onlyCharacter,
} from "../../../../../utils/helper";
import { setCountryCode } from "../../../../../utils/investing-approval/multi-investing-account/set-formik-data";
interface IProps {
  accountTypeID: number;
  formik: FormikValues;
  isFormFieldInvalid: any;
  getFormErrorMessage: any;
}

const BeneficiaryInformation: React.FC<IProps> = (props) => {
  const countriesDropdown = useAppSelector((state) => {
    return state.investingAccountsLookups.countriesDropdown;
  });

  const stateDropdown = useAppSelector((state) => {
    return state.investingAccountsLookups.stateDropdown;
  });
  const relationshipDropdown = useAppSelector((state) => {
    return state.getLookUpbyAccountId.accountHolderRelationshipDropdown;
  });
  const formatPhoneNumber = (value: string) => {
    const phoneNumberOnly = value ? value!.replace(/\D/g, "") : value;

    console.log("phoneNumberOnly Benefyyiciary Information", phoneNumberOnly);

    let maskedPhoneNumber = "";

    if (phoneNumberOnly) {
      if (props.formik.values.beneficiaryCountryId !== 229) {
        const isValidPhoneNumber = /^[\d-]*$/.test(value);
        if (isValidPhoneNumber) {
          maskedPhoneNumber = value; // Truncate to 20 characters if exceeds
        }
      } else {
        // If countryId is 229, follow the existing masking logic
        if (phoneNumberOnly.length <= 3) {
          maskedPhoneNumber = phoneNumberOnly;
        } else if (phoneNumberOnly.length <= 6) {
          maskedPhoneNumber =
            phoneNumberOnly.slice(0, 3) + "-" + phoneNumberOnly.slice(3);
        } else {
          maskedPhoneNumber =
            phoneNumberOnly.slice(0, 3) +
            "-" +
            phoneNumberOnly.slice(3, 6) +
            "-" +
            phoneNumberOnly.slice(6, 10);
        }
      }
    }

    return maskedPhoneNumber;
  };
  return (
    <>
      <div className="row d-flex justify-content-start">
        <h4 className="investorHeader">Beneficiary Information</h4>
      </div>
      <div>
        <div className="formgrid grid">
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">Beneficiary First Name</label>
            <InputText
              name="beneficiaryFirstName"
              value={props.formik.values.beneficiaryFirstName}
              // unmask={true}
              placeholder="Enter First Name"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "beneficiaryFirstName",
                  onlyCharacter(e.target.value)
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("beneficiaryFirstName"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("beneficiaryFirstName")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">Beneficiary Last Name</label>
            <InputText
              name="beneficiaryLastName"
              value={props.formik.values.beneficiaryLastName}
              // unmask={true}
              placeholder="Enter Last Name"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "beneficiaryLastName",
                  onlyCharacter(e.target.value)
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("beneficiaryLastName"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("beneficiaryLastName")}
          </div>
          <div className="field col-12  text-left">
            <label className="inputLabel">Relationship to Account Holder</label>
            <Dropdown
              name="beneficiaryRelationship"
              value={props.formik.values.beneficiaryRelationship}
              options={relationshipDropdown}
              filter
              showOnFocus
              optionLabel="value"
              optionValue="key"
              placeholder="Select Beneficiary Relationship"
              // className="card-inputBox"
              onChange={(e) => {
                props.formik.setFieldValue("beneficiaryRelationship", e.value);
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid(
                  "beneficiaryRelationship"
                ),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("beneficiaryRelationship")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">Beneficiary Phone Number</label>
            <div className="p-inputgroup inputgrp-h47 flex-1">
              <span className="p-inputgroup-addon">
                {props.formik.values.beneficiarycountryCode
                  ? "+" + props.formik.values.beneficiarycountryCode
                  : ""}{" "}
              </span>
              {props.formik.values.beneficiaryCountryId !== 229 ? (
                <InputText
                  name="beneficiaryPhoneNumber"
                  id="beneficiaryPhoneNumber"
                  placeholder=""
                  maxLength={20}
                  value={
                    props.formik.values.beneficiaryPhoneNumber
                      ? formatPhoneNumber(
                          props.formik.values.beneficiaryPhoneNumber
                        )
                      : props.formik.values.beneficiaryPhoneNumber
                  }
                  onChange={(e) => {
                    if (isNumberAndHypen(e.target.value)) {
                      props.formik.setFieldValue(
                        "beneficiaryPhoneNumber",
                        formatPhoneNumber(e.target.value)
                      );
                    }
                  }}
                  className={classNames({
                    "p-invalid": props.isFormFieldInvalid(
                      "beneficiaryPhoneNumber"
                    ),

                    inputphoneNo: true,
                    // "card-inputBox": true,
                  })}
                />
              ) : (
                <InputText
                  name="beneficiaryPhoneNumber"
                  id="beneficiaryPhoneNumber"
                  placeholder="xxx-xxx-xxxx"
                  maxLength={20}
                  value={
                    props.formik.values.beneficiaryPhoneNumber
                      ? formatPhoneNumber(
                          props.formik.values.beneficiaryPhoneNumber
                            .split("-")
                            .join("")
                        )
                      : props.formik.values.beneficiaryPhoneNumber
                  }
                  onChange={(e) => {
                    props.formik.setFieldValue(
                      "beneficiaryPhoneNumber",
                      formatPhoneNumber(e.target.value).split("-").join("")
                    );
                  }}
                  className={classNames({
                    "p-invalid": props.isFormFieldInvalid(
                      "beneficiaryPhoneNumber"
                    ),

                    inputphoneNo: true,
                    // "card-inputBox": true,
                  })}
                />
              )}
            </div>
            {props.getFormErrorMessage("beneficiaryPhoneNumber")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">Beneficiary Email</label>
            <InputText
              name="beneficiaryEmail"
              value={props.formik.values.beneficiaryEmail}
              // unmask={true}
              placeholder="Enter Email"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "beneficiaryEmail",
                  e.target.value?.trimStart()
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("beneficiaryEmail"),
                "w-full": true,
              })}
            />
            {props.getFormErrorMessage("beneficiaryEmail")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">Beneficiary Address Line 1</label>
            <InputText
              name="beneficiaryAddressLine1"
              value={props.formik.values.beneficiaryAddressLine1}
              // unmask={true}
              placeholder="Enter address line 1"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "beneficiaryAddressLine1",
                  e.target.value?.trimStart()
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid(
                  "beneficiaryAddressLine1"
                ),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("beneficiaryAddressLine1")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">Beneficiary Address Line 2</label>
            <InputText
              name="beneficiaryAddressLine2"
              value={props.formik.values.beneficiaryAddressLine2}
              // unmask={true}
              placeholder="Enter address line 2"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "beneficiaryAddressLine2",
                  e.target.value?.trimStart()
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid(
                  "beneficiaryAddressLine2"
                ),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("beneficiaryAddressLine2")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">Beneficiary Country</label>
            <Dropdown
              name="beneficiaryCountry"
              value={props.formik.values.beneficiaryCountryId}
              options={countriesDropdown}
              filter
              showOnFocus
              optionLabel="value"
              optionValue="key"
              placeholder="Select Country"
              // className="card-inputBox"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "beneficiarycountryCode",
                  setCountryCode(e.value)
                );

                props.formik.setFieldValue("beneficiaryZipCode", "");
                props.formik.setFieldValue("beneficiaryCountryId", e.value);
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("beneficiaryCountry"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("beneficiaryCountry")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">Beneficiary City</label>
            <InputText
              name="beneficiaryCity"
              value={props.formik.values.beneficiaryCity}
              // unmask={true}
              placeholder="Enter City"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "beneficiaryCity",
                  onlyCharacter(e.target.value)
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("beneficiaryCity"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("beneficiaryCity")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">Beneficiary ZIP Code</label>
            <InputText
              name="beneficiaryZipCode"
              value={props.formik.values.beneficiaryZipCode}
              placeholder="Enter ZIP Code"
              maxLength={
                props.formik.values.beneficiaryCountryId === 229 ? 10 : 20
              }
              onChange={(e) => {
                const zipCode = formatZipCode(
                  e.target.value,
                  props.formik.values.beneficiaryCountryId
                );

                props.formik.setFieldValue("beneficiaryZipCode", zipCode);
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("beneficiaryZipCode"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("beneficiaryZipCode")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">Beneficiary State</label>
            {props.formik.values.beneficiaryCountryId !== 229 ? (
              <InputText
                name="beneficiaryState"
                value={props.formik.values.beneficiaryState}
                placeholder="Enter your state"
                onChange={(e) => {
                  props.formik.setFieldValue(
                    "beneficiaryState",
                    onlyCharacter(e.target.value?.trimStart())
                  );
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid("beneficiaryState"),
                  "card-inputBox": true,
                })}
              />
            ) : (
              <Dropdown
                name="beneficiaryState"
                value={props.formik.values.beneficiaryState}
                options={stateDropdown}
                filter
                showOnFocus
                optionLabel="value"
                optionValue="key"
                placeholder="Select Your State"
                // className="card-inputBox"
                onChange={(e) => {
                  props.formik.setFieldValue("beneficiaryState", e.value);
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid("beneficiaryState"),
                  "card-inputBox": true,
                })}
              />
            )}

            {props.getFormErrorMessage("beneficiaryState")}
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneficiaryInformation;
