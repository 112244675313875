import store from "../../../store";
import { convertUTCToLocalDate } from "../../date-formatter";

export const setCountryCode = (countryID: string | number, fun = "testing") => {
  const callCodeObject = store.getState().investingAccountsLookups?.callCode;
  console.log(
    "setFormikFieldsForPersonalProfile",
    countryID,
    fun,
    callCodeObject
  );
  if (countryID && callCodeObject && Object.keys(callCodeObject).length > 0) {
    return callCodeObject[countryID];
  }
  return "1";
};

const setFormikFieldsForMailingAddress = (
  formik: any,
  investingAccountDetails: any
) => {
  console.log(
    "address fileds data=========================================",
    investingAccountDetails
  );
  formik.setFieldValue("addressId", investingAccountDetails?.addressId);
  formik.setFieldValue("address", investingAccountDetails?.address);
  formik.setFieldValue("address2", investingAccountDetails?.address2);
  formik.setFieldValue(
    "countryId",
    investingAccountDetails?.countryId
      ? investingAccountDetails?.countryId
      : 229
  );
  formik.setFieldValue("city", investingAccountDetails?.city);
  formik.setFieldValue("state", investingAccountDetails?.state);
  formik.setFieldValue("zipCode", investingAccountDetails?.zip);
};

const setFormikFieldsForBeneficiaryDetails = (
  formik: any,
  investingAccountDetails: any
) => {
  formik.setFieldValue(
    "beneficiaryAddressId",
    investingAccountDetails?.beneficiaryAddressId
  );
  formik.setFieldValue("beneficiaryId", investingAccountDetails?.beneficiaryId);
  formik.setFieldValue(
    "beneficiaryFirstName",
    investingAccountDetails?.beneficiaryFirstName
  );
  formik.setFieldValue(
    "beneficiaryLastName",
    investingAccountDetails?.beneficiaryLastName
  );
  formik.setFieldValue(
    "beneficiaryRelationship",
    investingAccountDetails?.accountHolderRelationId
  );
  formik.setFieldValue(
    "beneficiaryPhoneNumber",
    investingAccountDetails?.beneficiaryPhoneNumber
  );
  formik.setFieldValue(
    "beneficiaryEmail",
    investingAccountDetails?.beneficiaryEmail
  );
  formik.setFieldValue(
    "beneficiaryAddressLine1",
    investingAccountDetails?.beneficiaryAddress
  );
  formik.setFieldValue(
    "beneficiaryAddressLine2",
    investingAccountDetails?.beneficiaryAddress2
  );

  formik.setFieldValue(
    "beneficiaryCountryId",
    investingAccountDetails?.beneficiaryCountryId
      ? investingAccountDetails?.beneficiaryCountryId
      : 229
  );

  formik.setFieldValue(
    "beneficiarycountryCode",
    setCountryCode(investingAccountDetails?.beneficiaryCountryId)
  );

  formik.setFieldValue(
    "beneficiaryZipCode",
    investingAccountDetails?.beneficiaryZip
  );
  formik.setFieldValue(
    "beneficiaryCity",
    investingAccountDetails?.beneficiaryCity
  );
  formik.setFieldValue(
    "beneficiaryState",
    investingAccountDetails?.beneficiaryState
  );
};

const prepareMemberData = (additionalMembers: any) => {
  const member = [];

  for (const value of additionalMembers) {
    const memberMemeberPayload = {
      id: value.additionalMemberId,
      investingAcountId: value.investingAccountId,
      firstName: value.memberFirstName,
      lastName: value.memberLastName,
      ssn: value.memberSocialSecurityNumber,
      phoneNumber: value.memberPhoneNumber,
      email: value.memberEmail,
      birthDate: value.memberBirthdate ? new Date(value.memberBirthdate) : "",
      addressID: value.memberAddressId,
      llcMemberSequenceId: 0,
      addressLine1: value.memberAddress1,
      addressLine2: value.memberAddress2,
      city: value.memberAddressCity,
      country: value.memberAddressCountryId
        ? value.memberAddressCountryId
        : 229,
      state: value.memberAddressState,
      zipCode: value.memberAddressZip,
      llcMemberCreatedBy: "",
      llcMemberModifiedBy: "",
      countryCode: setCountryCode(value.memberAddressCountryId),
    };
    member.push(memberMemeberPayload);
  }
  return member;
};

export const setFormikFieldsForIndividual = (
  formik: any,
  investingAccountDetails: any
) => {
  formik.setFieldValue(
    "investingAccountId",
    investingAccountDetails?.investingAccountId
  );
  formik.setFieldValue(
    "myAccountName",
    investingAccountDetails?.accountNickName
  );
  formik.setFieldValue("firstName", investingAccountDetails?.firstName);
  formik.setFieldValue("lastName", investingAccountDetails?.lastName);
  formik.setFieldValue(
    "socialSecurityNumber",
    investingAccountDetails?.socialSecurityNumber
  );
  // mailing address
  setFormikFieldsForMailingAddress(formik, investingAccountDetails);
  // Beneficiary details
  setFormikFieldsForBeneficiaryDetails(formik, investingAccountDetails);
};

export const setFormikFieldsForSingleMemberLLC = (
  formik: any,
  investingAccountDetails: any
) => {
  formik.setFieldValue(
    "investingAccountId",
    investingAccountDetails?.investingAccountId
  );
  formik.setFieldValue(
    "myAccountName",
    investingAccountDetails?.accountNickName
  );
  formik.setFieldValue("disRegarded", investingAccountDetails?.disRegarded);
  formik.setFieldValue(
    "socialSecurityNumber",
    investingAccountDetails?.socialSecurityNumber
  );

  formik.setFieldValue("ein", investingAccountDetails?.entityEIN);
  formik.setFieldValue("entityName", investingAccountDetails?.entityName);

  // mailing address
  setFormikFieldsForMailingAddress(formik, investingAccountDetails);
  // Beneficiary details
  setFormikFieldsForBeneficiaryDetails(formik, investingAccountDetails);
};

export const setFormikFieldsForsolo401k = (
  formik: any,
  investingAccountDetails: any
) => {
  formik.setFieldValue(
    "investingAccountId",
    investingAccountDetails?.investingAccountId
  );
  formik.setFieldValue(
    "myAccountName",
    investingAccountDetails?.accountNickName
  );
  formik.setFieldValue(
    "accountTitle",
    investingAccountDetails?.solo401AccountTitle
  );
  formik.setFieldValue(
    "socialSecurityNumber",
    investingAccountDetails?.socialSecurityNumber
  );

  formik.setFieldValue("ein", investingAccountDetails?.entityEIN);
  // mailing address
  setFormikFieldsForMailingAddress(formik, investingAccountDetails);
};

export const setFormikFieldsForJointAccount = (
  formik: any,
  investingAccountDetails: any
) => {
  formik.setFieldValue(
    "investingAccountId",
    investingAccountDetails?.investingAccountId
  );
  formik.setFieldValue(
    "myAccountName",
    investingAccountDetails?.accountNickName
  );
  formik.setFieldValue(
    "primaryAccountFirstName",
    investingAccountDetails?.firstName
  );
  formik.setFieldValue(
    "primaryAccountLastName",
    investingAccountDetails?.lastName
  );
  formik.setFieldValue(
    "primaryAccountHolderSSN",
    investingAccountDetails?.socialSecurityNumber
  );
  formik.setFieldValue(
    "accountOwnershipId",
    investingAccountDetails?.accountOwnershipId
  );

  // mailing address
  setFormikFieldsForMailingAddress(formik, investingAccountDetails);

  formik.setFieldValue("secHolderId", investingAccountDetails?.secHolderId);
  formik.setFieldValue("secFirstName", investingAccountDetails?.secFirstName);
  formik.setFieldValue("secLastName", investingAccountDetails?.secLastName);
  formik.setFieldValue("secEmail", investingAccountDetails?.secEmail);
  formik.setFieldValue(
    "secSSN",
    investingAccountDetails?.secSocialSecurityNumber
  );
  formik.setFieldValue(
    "secPhoneNumber",
    investingAccountDetails?.secPhoneNumber
  );
  formik.setFieldValue("secAddressId", investingAccountDetails?.secAddressId);
  formik.setFieldValue("secAddress", investingAccountDetails?.secAddress);
  formik.setFieldValue("secAddress2", investingAccountDetails?.secAddress2);
  formik.setFieldValue("secCity", investingAccountDetails?.secCity);
  formik.setFieldValue(
    "secCountryId",
    investingAccountDetails?.secCountryId
      ? investingAccountDetails?.secCountryId
      : 229
  );
  formik.setFieldValue("secState", investingAccountDetails?.secState);
  formik.setFieldValue("secZipCode", investingAccountDetails?.secZip);
  formik.setFieldValue(
    "secCountryCode",
    setCountryCode(investingAccountDetails?.secCountryId)
  );
};
export const setFormikFieldsForSelfDirectedIRA = (
  formik: any,
  investingAccountDetails: any
) => {
  formik.setFieldValue(
    "investingAccountId",
    investingAccountDetails?.investingAccountId
  );
  formik.setFieldValue(
    "myAccountName",
    investingAccountDetails?.accountNickName
  );
  formik.setFieldValue(
    "accountTitleSDIRA",
    investingAccountDetails?.sdiraAccountTitle
  );
  formik.setFieldValue(
    "custodianEmail",
    investingAccountDetails?.custodianEmail
  );
  formik.setFieldValue(
    "custodianPhoneNumber",
    investingAccountDetails?.custodianPhoneNumber
  );

  formik.setFieldValue(
    "custodianWebsite",
    investingAccountDetails?.custodianWebsite
  );

  formik.setFieldValue("custodianName", investingAccountDetails?.custodianName);
  formik.setFieldValue("ein", investingAccountDetails?.custodianEIN);
  formik.setFieldValue(
    "custodianRoutingNumber",
    investingAccountDetails?.custodianRoutingNumber
  );
  formik.setFieldValue(
    "custodianAccountNumber",
    investingAccountDetails?.custodianAccountNumber
  );

  // custodian  address
  formik.setFieldValue("custodianId", investingAccountDetails?.custodianId);
  formik.setFieldValue(
    "addressId",
    investingAccountDetails?.custodianAddressId
  );
  formik.setFieldValue("address", investingAccountDetails?.custodianAddress);
  formik.setFieldValue("address2", investingAccountDetails?.custodianAddress2);
  formik.setFieldValue(
    "countryId",
    investingAccountDetails?.custodianCountryId
      ? investingAccountDetails?.custodianCountryId
      : 229
  );
  formik.setFieldValue("city", investingAccountDetails?.custodianCity);
  formik.setFieldValue("state", investingAccountDetails?.custodianState);
  formik.setFieldValue("zipCode", investingAccountDetails?.custodianZip);
};

export const setFormikFieldsForMultiMemberLLC = (
  formik: any,
  investingAccountDetails: any,
  additionalMembers: any
) => {
  formik.setFieldValue(
    "investingAccountId",
    investingAccountDetails?.investingAccountId
  );
  formik.setFieldValue(
    "myAccountName",
    investingAccountDetails?.accountNickName
  );
  formik.setFieldValue("ein", investingAccountDetails?.entityEIN);
  formik.setFieldValue("entityName", investingAccountDetails?.entityName);
  formik.setFieldValue(
    "taxclassification",
    investingAccountDetails?.taxClassificationId
  );
  const multiMemberLLC = prepareMemberData(additionalMembers);

  formik.setFieldValue("memberInformation", multiMemberLLC);
  setFormikFieldsForMailingAddress(formik, investingAccountDetails);

  formik.setFieldValue(
    "noBeneficialMemberInformation",
    investingAccountDetails?.totalMembers
  );
};

export const setFormikFieldsForTrust = (
  formik: any,
  investingAccountDetails: any,
  additionalMembers: any
) => {
  formik.setFieldValue(
    "investingAccountId",
    investingAccountDetails?.investingAccountId
  );
  formik.setFieldValue(
    "myAccountName",
    investingAccountDetails?.accountNickName
  );
  formik.setFieldValue("isEIN", investingAccountDetails?.isEIN);
  formik.setFieldValue("ein", investingAccountDetails?.entityEIN);
  formik.setFieldValue(
    "socialSecurityNumber",
    investingAccountDetails?.socialSecurityNumber
  );
  formik.setFieldValue(
    "trustEstablishmentPlace",
    investingAccountDetails?.trustEstablishmentPlace
  );
  formik.setFieldValue(
    "revocabilityId",
    investingAccountDetails?.revocabilityId
  );

  formik.setFieldValue("trustTypeId", investingAccountDetails?.trustTypeId);

  const trustees = prepareMemberData(additionalMembers);
  formik.setFieldValue("trusteeInfo", trustees);

  formik.setFieldValue("noOfTrustees", investingAccountDetails?.totalMembers);

  setFormikFieldsForMailingAddress(formik, investingAccountDetails);

  // formik.setFieldValue("");
};

export const setFormikFieldsForPartnership = (
  formik: any,
  investingAccountDetails: any,
  additionalMembers: any
) => {
  formik.setFieldValue(
    "investingAccountId",
    investingAccountDetails?.investingAccountId
  );
  formik.setFieldValue(
    "myAccountName",
    investingAccountDetails?.accountNickName
  );
  formik.setFieldValue("ein", investingAccountDetails?.entityEIN);
  formik.setFieldValue("entityName", investingAccountDetails?.entityName);
  formik.setFieldValue(
    "taxclassification",
    investingAccountDetails?.taxClassificationId
  );

  const partners = prepareMemberData(additionalMembers);

  formik.setFieldValue("partnerInfo", partners);

  setFormikFieldsForMailingAddress(formik, investingAccountDetails);
  formik.setFieldValue(
    "noOfPartnerInfo",
    investingAccountDetails?.totalMembers
  );

  // formik.setFieldValue("");
};
export const setFormikFieldsForCorporation = (
  formik: any,
  investingAccountDetails: any,
  additionalMembers: any
) => {
  console.log("investingAccountDetails", investingAccountDetails);
  formik.setFieldValue(
    "investingAccountId",
    investingAccountDetails?.investingAccountId
  );
  formik.setFieldValue(
    "myAccountName",
    investingAccountDetails?.accountNickName
  );
  formik.setFieldValue("ein", investingAccountDetails?.entityEIN);
  formik.setFieldValue("entityName", investingAccountDetails?.entityName);
  formik.setFieldValue(
    "taxclassification",
    investingAccountDetails?.taxClassificationId
  );

  const officers = [];
  for (const value of additionalMembers) {
    const officerPayload = {
      id: value.additionalMemberId,
      investingAcountId: value.memberAddress2,
      firstName: value.memberFirstName,
      lastName: value.memberLastName,
      ssn: value.memberSocialSecurityNumber,
      title: value.executiveOfficersTitle,
      phoneNumber: value.memberPhoneNumber,
      email: value.memberEmail,
      birthDate: value.memberBirthdate ? new Date(value.memberBirthdate) : "",

      addressID: value.memberAddressId,
      llcMemberSequenceId: 0,
      addressLine1: value.memberAddress1,
      addressLine2: value.memberAddress2,
      city: value.memberAddressCity,
      country: value.memberAddressCountryId
        ? value.memberAddressCountryId
        : 229,
      state: value.memberAddressState,
      zipCode: value.memberAddressZip,
      llcMemberCreatedBy: "",
      llcMemberModifiedBy: "",
      countryCode: setCountryCode(value.memberAddressCountryId),
    };
    officers.push(officerPayload);
  }
  formik.setFieldValue("officerInfo", officers);

  setFormikFieldsForMailingAddress(formik, investingAccountDetails);
  formik.setFieldValue(
    "noOfOfficerInfo",
    investingAccountDetails?.totalMembers
  );

  // formik.setFieldValue("");
};

export const setFormikFieldsForPersonalProfile = (
  formik: any,
  step1Data: any
) => {
  if (step1Data) {
    console.log("setFormikFieldsForPersonalProfile", step1Data);
    formik.setFieldValue("firstNamePP", step1Data?.firstName);
    formik.setFieldValue("lastNamePP", step1Data?.lastName);
    formik.setFieldValue("cityPP", step1Data?.city);
    formik.setFieldValue("emailPP", step1Data?.emailAddress);
    formik.setFieldValue("phonePP", step1Data?.mobilePhone);
    formik.setFieldValue("addressPP", step1Data?.address);
    formik.setFieldValue("apartmentPP", step1Data?.address2);
    formik.setFieldValue("zipcodePP", step1Data?.zipCode);
    formik.setFieldValue("statePP", step1Data?.stateProvince);
    // formik.setFieldValue("StatePP", step1Data?.stateProvince);
    formik.setFieldValue(
      "electronicCommunicationPP",
      step1Data?.electronicCommunication
    );
    formik.setFieldValue(
      "electronicK1DeliveryPP",
      step1Data?.electronicK1Delivery
    );
    formik.setFieldValue("investorGuidancePP", step1Data?.investorGuidance);
    formik.setFieldValue("internalNotes", step1Data?.internalNotes);
    formik.setFieldValue(
      "countryIdPP",
      step1Data?.countryId ? step1Data?.countryId : 229
    );

    formik.setFieldValue(
      "countryCodePP",
      setCountryCode(step1Data?.countryId, "countryCodePP")
    );

    formik.setFieldValue("documentLinkPP", step1Data?.documentLink);
    if (step1Data?.documentLink) {
      formik.setFieldValue("isKYCCompleted", true);
    }

    // formik.setValues(obj);
  }
};

export const setFormikFieldsForAccredation = (formik: any, data: any) => {
  console.log(
    "setFormikFieldsForAccredation lastAccreditationVerified data",
    data
  );

  const Expires = data.dateAccreditationExpires
    ? new Date(convertUTCToLocalDate(data.dateAccreditationExpires))
    : "";
  const VerifiedDate = data.lastAccreditationVerifiedDate
    ? new Date(convertUTCToLocalDate(data.lastAccreditationVerifiedDate))
    : "";
  formik.setFieldValue("lastAccreditationVerified", VerifiedDate);
  formik.setFieldValue("accreditationExpires", Expires);
  formik.setFieldValue("isAccredited", data.isInvestorAccredited);
  formik.setFieldValue("isPPMByPassed", data.isPPMByPassed);
  formik.setFieldValue("isAccreditationByPassed", data.isAccreditationByPassed);

  // formik.setFieldValue("internalNotes", data.internalNotes);
  if (
    data.isDualSigner === false &&
    data.signer === "Signer 1" &&
    data.signDocumentLink
  ) {
    formik.setFieldValue("isFundLegalDocumentsCompleted", true);

    formik.setFieldValue("isPPMByPassed", false);
    formik.setFieldValue("isAccreditationByPassed", false);
  } else if (
    data.isDualSigner === true &&
    data.signer === "Signer 2" &&
    data.isSignCompleted === true &&
    data.signDocumentLink
  ) {
    formik.setFieldValue("isFundLegalDocumentsCompleted", true);
    formik.setFieldValue("isPPMByPassed", false);
    formik.setFieldValue("isAccreditationByPassed", false);
  } else {
    formik.setFieldValue("isFundLegalDocumentsCompleted", false);
  }
};
