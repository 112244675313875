import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { parentUrlObj, protectedURL } from "../../utils/protected-route";

const GuardedRoute = () => {
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  console.log(fundDropdown);
  // const fundlegalEntityID = "" + fundDropdown?.fundid;
  const location = useLocation();
  const currentURL = window.location.pathname;
  const slice = currentURL.split("/");

  // legalEntityID when three step completedurl = url.slice(0, url.lastIndexOf('/'));

  const dropdownChanged: string | null =
    localStorage.getItem("dropdownChanged");

  console.log(
    "GuardedRoute condition check",
    // newTenentId !== oldTenentId,
    protectedURL.has(slice[1]),
    slice.length > 2
  );

  if (dropdownChanged === "1" || dropdownChanged === null) {
    const redirectUrl: string = parentUrlObj[slice[1]];
    console.log("GuardedRoute redirectUrl", redirectUrl);
    localStorage.setItem("dropdownChanged", "0");

    return protectedURL.has(slice[1]) && slice.length > 2 ? (
      <Navigate to={redirectUrl} replace state={{ location }} />
    ) : (
      <Outlet />
    );
  }
  return <Outlet />;
};
export default GuardedRoute;
