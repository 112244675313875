import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestingAccountDetailsByIdService } from "../../../../../../services/investor/investor";
import error from "../../../../../../utils/error";
import {
  IInvestingAccountDetailsPayload,
  IInvestingAccountDetailsResponse,
} from ".";

interface InvestorAccount {
  data?: IInvestingAccountDetailsResponse;
  loading: string;
  error?: string | null;
  additionalMembers?: [];
}

const initialState: InvestorAccount = {
  data: undefined,
  loading: "loading",
  error: undefined,
  additionalMembers: undefined,
};

export const getInvestingAccountDetailsByIdThunk = createAsyncThunk(
  "investorAccount/getInvestingAccountDetailsById",
  async (data: IInvestingAccountDetailsPayload) => {
    try {
      const response = await getInvestingAccountDetailsByIdService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "investorAccount/getInvestingAccountDetailsById",
  initialState,
  reducers: {
    setInvestingAccountDetailsByIdSetIntialState: (state, action) => {
      state.loading = action.payload;
    },
    resetInvestingAccountDetailsById(state) {
      state.data = undefined;
      state.error = undefined;
      state.additionalMembers = undefined;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getInvestingAccountDetailsByIdThunk.pending, (state) => {
      state.data = undefined;
      state.loading = "loading";
      state.error = undefined;
      state.additionalMembers = undefined;
    });
    builder.addCase(
      getInvestingAccountDetailsByIdThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data
          ? action.payload._response.data.investingAccountDetails
          : undefined;
        state.additionalMembers = action.payload._response.data
          ? action.payload._response.data.additionalMembers
          : undefined;
      }
    );
    builder.addCase(
      getInvestingAccountDetailsByIdThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});

export const {
  setInvestingAccountDetailsByIdSetIntialState,
  resetInvestingAccountDetailsById,
} = slice.actions;

export default slice.reducer;
