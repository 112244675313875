import { Button } from "primereact/button";
import React from "react";
type StepButtonsProps = {
  firstBtnLabel: string;
  secondBtnLabel: string;
  handleFirstBtn: () => void;
  handleSecondBtn: () => void;
};
const StepButtons: React.FC<StepButtonsProps> = ({
  firstBtnLabel,
  secondBtnLabel,
  handleFirstBtn,
  handleSecondBtn,
}) => {
  return (
    <>
      <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center ">
        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
          <Button
            className="btn-nav"
            onClick={(e) => {
              e.preventDefault();
              handleFirstBtn();
            }}
          >
            <span className="ml-auto mr-auto ">{firstBtnLabel}</span>
          </Button>
        </div>
        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
          <Button
            className="btn-navActive"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSecondBtn();
            }}
          >
            <span className="ml-auto mr-auto ">{secondBtnLabel}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default StepButtons;
