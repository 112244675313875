import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import {
  initialStateCreateSetupFund,
  setAddCancelState,
} from "../../store/features/operation/setup-fund/create-update-state/slice";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { convertToLowerCase } from "../../utils/helper";
import { protectedURL } from "../../utils/protected-route";

interface IDropdownElements {
  leagalentity: any;
  setLeagalentity: any;
  legalEntityList: any;
  registredinverstment: any;
  setregistredinverstment: any;
  registerinverstmenList: any;
  nonregistredinverstment: any;
  setnonregistredinverstment: any;
  nonregisterinverstmenList: any;
}

export default function DashboardDropdownComponent(props: IDropdownElements) {
  const dispatch = useAppDispatch();
  const location = window.location.pathname;
  // const [showregister, setshowregister] = useState(false);
  const addCancel = useAppSelector((state) => state.addCancelSetupState.data);
  // const [showunregister, setshowunregister] = useState(false);
  const [showallfund, setshowallfund] = useState(true);
  // const profileData = useAppSelector((state) => state.profile.data![0]);
  useEffect(() => {
    if (
      location === "/Portfolio/dashboard" ||
      location === "/Portfolio/Investment" ||
      location === "/Portfolio/Transaction" ||
      location === "/Opportunities/AllOpportunities" ||
      location === "/Opportunities/Equity" ||
      location === "/Opportunities/Debt" ||
      location === "/Report/CumulativeEarningReport" ||
      location === "/accountStatement"
    ) {
      // setshowregister(true);
      // setshowunregister(true);
      if (
        props.nonregistredinverstment === undefined ||
        (props.nonregistredinverstment === 0 &&
          props.registredinverstment === 0)
      ) {
        props.setregistredinverstment(props.registerinverstmenList[0]?.code);
      }
    } else {
      // setshowregister(false);
      // setshowunregister(false);
      // props.setregistredinverstment(0);
    }
  }, [location]);

  // useEffect(() => {
  //   if (location === "/accountStatement") {
  //     console.log("method called cuurent account");
  //     setshowregister(true);
  //     if (
  //       props.nonregistredinverstment === undefined ||
  //       (props.nonregistredinverstment === 0 &&
  //         props.registredinverstment === 0)
  //     ) {
  //       props.setregistredinverstment(props.registerinverstmenList[0]?.code);
  //     }
  //   } else {
  //     // setshowunregister(false);
  //     // props.setregistredinverstment(0);
  //   }
  // }, [location, props.registerinverstmenList]);

  useEffect(() => {
    dispatch(setAddCancelState(initialStateCreateSetupFund.data));
    if (
      location === "/Report/SoftCommitsByFund" ||
      location === "/Report/OutstandingBlueskyFilings" ||
      location === "/Report/CurrentOpportunitiesAcrossFunds" ||
      location === "/Report/InvestorsVerifiedByAccreddReport" ||
      location === "/DwollaInvestorReports"
    ) {
      setshowallfund(false);
    } else {
      setshowallfund(true);
    }
  }, [location]);
  return (
    <>
      <div className="d-block sm:d-flex sm:flex-column py-2 px-1 nav-dropdown">
        {/* <p className="pl-2">Select your fund *</p> */}
        <Dropdown
          name="Select your fund *"
          value={props.leagalentity}
          resetFilterOnHide={true}
          showFilterClear={true}
          //   value={}
          onChange={(e) => {
            const leagalentity = [...props.legalEntityList];

            for (const obj of leagalentity) {
              if (obj.code === e.value) {
                console.log("GuardedRoute called", obj.code);
                localStorage.setItem("tenentName", convertToLowerCase(obj.url));
                const currentURL: any = window.location.pathname.split("/");
                if (protectedURL.has(currentURL[1]) && currentURL.length > 2) {
                  localStorage.setItem("dropdownChanged", "1");
                }
                break;
              }
            }

            props.setLeagalentity(e.value);
          }}
          style={{ width: "100%" }}
          options={props.legalEntityList}
          optionLabel="name"
          optionValue="code"
          placeholder="Select your fund"
          filter
          disabled={addCancel.add === true}
          optionDisabled={(option) => {
            // Mention options which needs to be disable
            return showallfund === true
              ? option === props.legalEntityList[0]
              : props.legalEntityList[-1];
          }}
        />
      </div>
      {/* {showregister ? (
        <div className="d-block sm:d-flex sm:flex-column p-2 col-4">
          
          <Dropdown
            name="Limited Partner"
            value={props.registredinverstment}
            //   value={}
            onChange={(e) => {
              props.setregistredinverstment(e.value);
              props.setnonregistredinverstment(0);
            }}
            style={{ width: "100%" }}
            options={props.registerinverstmenList}
            optionLabel="name"
            optionValue="code"
            placeholder="Limited Partner"
            filter
          />
        </div>
      ) : undefined} */}

      {/* {showunregister ? (
        <div className="d-block sm:d-flex sm:flex-column p-2 col-4">
          
          <Dropdown
            name="Non-Register Investment *"
            value={props.nonregistredinverstment}
          
            onChange={(e) => {
              props.setnonregistredinverstment(e.value);
              props.setregistredinverstment(0);
            }}
            style={{ width: "100%" }}
            options={props.nonregisterinverstmenList}
            optionLabel="name"
            optionValue="code"
            placeholder="Non-Register Investment *"
            filter
          />
        </div>
      ) : undefined} */}
    </>
  );
}
