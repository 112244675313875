import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import AmountDisplay from "../../../components/amount-display";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
import Loader from "../../../components/loader/Loader";
import { getExitUnitsTotalDataByLPPartnerID } from "../../../services/ManagerFees";
import { getAllocatedPoolFundsByInvestorId } from "../../../services/PoolFund";
import { getThunkInvestmentIdByProduct } from "../../../store/features/operation/allocate-earning/get-investment-id-product/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  exitAllocatedPoolFundsThunk,
  getInvestorDropdownListByInvestmentIdThunk,
  resetInvestorDropDown,
} from "../../../store/poolfunds/exit-pool-funds";
import { getThunkAllInvestmentDealPooledFundByLegalEntityId } from "../../../store/poolfunds/get-investmentdeal-for-pooledfund";
import {
  ExitUnintType,
  PoolFundOutPutType,
  PoolFundsByInvestorIdParamType,
} from "../../../types";
import currencyFormat from "../../../utils/currency-formatter";
import {
  convertLocalDateToUTC,
  convertUTCToLocalDate,
} from "../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
import DateTemplate from "../../features/common-component/DateTemplate";

const ExitUnitPoolFund: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [exitUnitData, setExitUnitData] = useState<ExitUnintType[] | []>([]);
  const [productId, setPropductId] = useState<number>(0);
  const [investmentId, setInvestmentId] = useState<number>(0);
  const [lPPartnerID, setLpPatnerId] = useState<number>(0);
  const [isOpenRevers, setIsOpenReverse] = useState<boolean>(false);
  const [investerStakeData, setInvestorStakeData] = useState<
    PoolFundOutPutType[]
  >([]);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const profileData = useAppSelector((state) => state.profile.data![0]);

  const investorDropDown = useAppSelector(
    (state) => state.exitPoolFund.investorDropDown
  );
  const investmentList = useAppSelector(
    (state) => state.getAllInvestorDealListPoolFunds.data.investments
  );
  console.log(
    "investmentList",
    investmentList,
    "investorDropDown",
    investorDropDown
  );
  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        userId: profileData?.userID,
      };
      dispatch(
        getThunkAllInvestmentDealPooledFundByLegalEntityId(parameter)
      ).finally(() => {});
    }
  }, [fundDropdown]);

  const getLpPartnerExitData = async (lpPartnerID: number) => {
    setLoading(true);
    try {
      const param = {
        investmentId,
        lPPartnerID: lpPartnerID,
      };
      const response = await getExitUnitsTotalDataByLPPartnerID(param);
      if (response.data.success) {
        setExitUnitData([]);
        setExitUnitData(response.data.data.output);
      } else {
        setExitUnitData([]);
      }
      setLoading(false);
      console.log("res", response.data, response.data.data.output);
    } catch (error_: any) {
      setLoading(false);
      setExitUnitData([]);
      errorToastMessage(toast, error_?.message || "Data not found");
    }
  };

  useEffect(() => {
    if (lPPartnerID) {
      getLpPartnerExitData(lPPartnerID);
    }
  }, [lPPartnerID]);

  useEffect(() => {
    if (fundDropdown.fundid && productId) {
      setExitUnitData([]);
      dispatch(resetInvestorDropDown());
      setLpPatnerId(0);
      setLoading(true);
      const param = { productId };
      dispatch(getThunkInvestmentIdByProduct(param))
        .then((response) => {
          if (
            response.payload._response.data !== null &&
            response.payload._response.data.externalInvestmentId.key !== 0 &&
            response.payload._response.data.externalInvestmentId !== 0
          ) {
            setInvestmentId(
              response.payload._response.data.externalInvestmentId.key
            );
            dispatch(
              getInvestorDropdownListByInvestmentIdThunk(
                response.payload._response.data.externalInvestmentId.key
              )
            ).finally(() => setLoading(false));
          } else {
            errorToastMessage(toast, "Investment Id is not found");
            setInvestmentId(0);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
        });
    }
  }, [fundDropdown.fundid, productId]);

  const investmentDropdownList: any = useMemo(
    () =>
      investmentList!.length > 0
        ? investmentList?.map((item: any) => {
            return {
              name: item.productName,
              code: item.productID,
            };
          })
        : [],
    [investmentList]
  );
  const lpPatnerDropDownList: any = useMemo(
    () =>
      investorDropDown!.length > 0
        ? investorDropDown?.map((obj: any) => {
            return {
              name: obj.lpPartnerName,
              code: obj.lpPartnerID,
            };
          })
        : [],
    [investorDropDown]
  );
  const getReverseStake = async (param: PoolFundsByInvestorIdParamType) => {
    setLoading(true);
    try {
      const response = await getAllocatedPoolFundsByInvestorId(param);
      if (response.data.success) {
        const list = [...response.data.data.output];
        setInvestorStakeData(list);
        setIsOpenReverse(true);
      } else {
        setInvestorStakeData([]);
      }
      setLoading(false);
      console.log("handleViewStakehandleViewStake", response.data.data);
    } catch (error) {
      setLoading(false);
      setInvestorStakeData([]);
      console.log("error", error);
    }
  };

  const editUnit = (rowData: ExitUnintType) => {
    const handleInput = (e: any) => {
      const newVal = e?.value;
      console.log("new", newVal);

      if (newVal > rowData.total) {
        const updateData = { ...rowData, unit: e.value, error: true };
        const newData = [...exitUnitData].map((exitUnit: ExitUnintType) => {
          if (
            exitUnit.total === rowData.total &&
            exitUnit.unitsType === rowData.unitsType
          ) {
            return { ...updateData };
          }
          return exitUnit;
        });

        setExitUnitData(newData);
      } else {
        const updateData = { ...rowData, unit: e.value, error: false };
        const newData = [...exitUnitData].map((exitUnit: ExitUnintType) => {
          if (
            exitUnit.total === rowData.total &&
            exitUnit.unitsType === rowData.unitsType
          ) {
            return { ...updateData };
          }
          return exitUnit;
        });

        setExitUnitData(newData);
      }
    };
    return (
      <>
        <div className="flex flex-column">
          <InputNumber
            placeholder="Enter Amount"
            onChange={(e) => {
              handleInput(e);
            }}
            min={0}
            mode="currency"
            currency="USD"
            locale="en-US"
            minFractionDigits={2}
            className={classNames({ "p-invalid": rowData?.error })}
          />
          {rowData?.error && (
            <small className="p-error flex justify-content-center">
              Value should be less than total amount
            </small>
          )}
        </div>
      </>
    );
  };

  const editDate = (rowData: ExitUnintType) => {
    const handleInput = (e: any) => {
      const newDate = e?.target?.value;
      console.log("new", newDate);

      if (
        new Date(newDate).setHours(0, 0, 0, 0) <
        new Date(rowData?.exitDate!).setHours(0, 0, 0, 0)
      ) {
        const updateData = {
          ...rowData,
          exitDate: e.target.value,
          exitDateError: true,
        };
        const newData = [...exitUnitData].map((exitUnit: ExitUnintType) => {
          if (
            exitUnit.total === rowData.total &&
            exitUnit.unitsType === rowData.unitsType
          ) {
            return { ...updateData };
          }
          return exitUnit;
        });

        setExitUnitData(newData);
      } else {
        const updateData = {
          ...rowData,
          exitDate: e.target.value,
          exitDateError: false,
        };
        const newData = [...exitUnitData].map((exitUnit: ExitUnintType) => {
          if (
            exitUnit.total === rowData.total &&
            exitUnit.unitsType === rowData.unitsType
          ) {
            return { ...updateData };
          }
          return exitUnit;
        });

        setExitUnitData(newData);
      }
    };
    return (
      <>
        <div className="flex flex-column">
          <Calendar
            placeholder="Select Date"
            onChange={(e) => {
              handleInput(e);
            }}
            value={rowData.exitDate ? new Date(rowData.exitDate) : undefined}
            minDate={new Date(rowData.exitDate)}
            className={classNames({ "p-invalid": rowData?.exitDateError })}
            dateTemplate={DateTemplate}
          />
          {rowData?.exitDateError && (
            <small className="p-error flex justify-content-center">
              This Date is earlier than permitted. Date has to be the same or
              greater than the Exit Date{" "}
              {rowData?.exitDate
                ? convertUTCToLocalDate(rowData?.exitDate)
                : ""}
              .
            </small>
          )}
        </div>
      </>
    );
  };
  const viewStake = () => {
    const handleViewStake = async () => {
      try {
        const param = {
          legalEntityId: fundDropdown.fundid,
          investmentId,
          lPPartnerId: lPPartnerID,
        };
        getReverseStake(param);
      } catch (error) {
        setInvestorStakeData([]);
        console.log("error", error);
      }
    };
    return (
      <Button
        icon="pi pi-eye"
        className="btn-nav"
        onClick={(e) => {
          e.preventDefault();
          handleViewStake();
        }}
      />
    );
  };
  const dataTableElementExit = [
    {
      field: "total",
      header: "Total",
      body: (rowData: ExitUnintType) => currencyFormat(rowData.total),
    },
    {
      field: "requestChange",
      header: "Request Change",
      body: (rowData: ExitUnintType) => (
        <div className="amount-red">
          {currencyFormat(rowData.requestChange)}
        </div>
      ),
    },
    { header: "View invested balance", body: viewStake },
    { field: "unitsType", header: "Units Type" },
    { field: "", header: "Amount to return", body: editUnit },
    { header: "Exit Date", body: editDate },
  ];

  const dataTableElementStake = [
    {
      field: "lpPartnerName",
      header: "Investor Name",
      noFilter: true,
    },

    {
      field: "units",
      header: "Allocated Investment Balance",
      body: (rowData: PoolFundOutPutType) => (
        <AmountDisplay amount={rowData.addOnUnits ? rowData.addOnUnits : 0}>
          {currencyFormat(rowData.addOnUnits)}
        </AmountDisplay>
      ),
    },
    { field: "unitsType", header: "Units Type" },
    {
      field: "maxStartDate",
      header: "Start Date",
      dataType: "date",
      body: (rowData: PoolFundOutPutType) =>
        convertUTCToLocalDate(rowData.maxStartDate),
    },
    {
      field: "endDate",
      header: "End Date",
      dataType: "date",
      body: (rowData: PoolFundOutPutType) =>
        rowData.endDate ? convertUTCToLocalDate(rowData.endDate) : "N/A",
    },
    { field: "assetClass", header: "Class" },
  ];
  const filedExitValue = () => {
    return exitUnitData.every((exitUnit: ExitUnintType) => {
      return (
        !exitUnit?.unit ||
        !exitUnit.exitDate ||
        (exitUnit.unit && !exitUnit.exitDate)
      );
    });
  };

  const fieldExitError = () => {
    return exitUnitData.some((exitUnit: ExitUnintType) => {
      return (
        (exitUnit?.unit && exitUnit?.error ? exitUnit.error : false) ||
        (exitUnit?.exitDate && exitUnit?.exitDateError
          ? exitUnit.exitDateError
          : false)
      );
    });
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    setPropductId(value);
  };

  const handleExitUnit = () => {
    setLoading(true);
    const param = exitUnitData
      .filter((unitObj: ExitUnintType) => unitObj?.unit)
      .map((units: ExitUnintType) => {
        return {
          legalEntityId: fundDropdown.fundid,
          investmentId,
          lpPartnerId: lPPartnerID,
          numberOfUnits: units.unit,
          exitdate: convertLocalDateToUTC(units.exitDate),
          earningUnitsType: units.earningUnitsType,
        };
      });

    dispatch(exitAllocatedPoolFundsThunk(param))
      .then((res) => {
        setLoading(false);
        if (res.payload.success) {
          successToastMessage(toast, res.payload.message);
        } else {
          errorToastMessage(
            toast,
            res.payload.message || "Something went wrong!"
          );
        }
        lPPartnerID && getLpPartnerExitData(lPPartnerID);
        console.log("reeeeeee", res);
      })
      .catch(() => {
        setLoading(false);
        errorToastMessage(toast, "Something went wrong!");
      });
    console.log("dailogData", param);
  };
  const onCancel = () => {
    setExitUnitData([]);
    dispatch(resetInvestorDropDown());
    setInvestmentId(0);
    setPropductId(0);
    setLpPatnerId(0);
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <Dialog
        visible={isOpenRevers}
        onHide={() => {
          setIsOpenReverse(false);
          setInvestorStakeData([]);
        }}
        className="w-100 md:w-10 pb-1"
        headerClassName="no-header"
      >
        <div className="mt-2">
          <h4 className="pageHeader mt-2 flex justify-content-center">
            Invested Balance Reverse
          </h4>
          <div className="mt-2">
            <DataTableComponent
              valueData={investerStakeData || []}
              fieldsElements={dataTableElementStake}
              className="allocateSliceTable"
              noGridLines={true}
            />
          </div>
        </div>
      </Dialog>
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Process Exits</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <div className="grid">
        <div className="grid pt-0 col-12">
          <div className="col-12 pt-0 pr-0">
            <div className="card my-3 tabWithoutboxshadow">
              <Card>
                <div className="mt-1">
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
                      <label className="inputLabel">Select Investment</label>
                      <Dropdown
                        inputId="investment"
                        name="investment"
                        value={productId}
                        options={investmentDropdownList}
                        filter
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Select Investment"
                        className="card-inputBox"
                        // showOnFocus
                        onChange={(e) => {
                          e.preventDefault();
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
                      <label className="inputLabel">
                        Select Investor Account
                      </label>
                      <Dropdown
                        inputId="investment"
                        name="investment"
                        value={lPPartnerID}
                        options={lpPatnerDropDownList}
                        filter
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Select Investor Account"
                        className="card-inputBox"
                        // showOnFocus
                        onChange={(e) => {
                          e.preventDefault();
                          setLpPatnerId(e.target.value);
                          fieldExitError();
                          filedExitValue();
                        }}
                      />
                    </div>
                  </div>
                  <div className="formgrid grid px-2 mt-3">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
                      <h6 className="mt-2">
                        Below, you can process investor account exits for single
                        or multiple investments.
                      </h6>
                    </div>
                  </div>
                </div>
                <DataTableComponent
                  valueData={exitUnitData || []}
                  fieldsElements={dataTableElementExit}
                  noGridLines={true}
                />
                <div className="flex flex-row flex-wrap sm:flex-none p-0 col-12 justify-content-center ">
                  <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                    <Button
                      className="btn-nav"
                      onClick={(e) => {
                        e.preventDefault();
                        onCancel();
                      }}
                    >
                      <span className="ml-auto mr-auto ">Cancel</span>
                    </Button>
                  </div>
                  <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                    <Button
                      className="btn-navActive"
                      disabled={filedExitValue() || fieldExitError()}
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        handleExitUnit();
                      }}
                    >
                      <span className="ml-auto mr-auto ">Submit</span>
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExitUnitPoolFund;
