import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvRegistrationService } from "../../../services/stepVerification/step-vetification";
import { IBadgeStatus, ILegalEntityAndPartnerID } from "../../../types/index";
import error from "../../../utils/error";

interface InvestorAccount {
  Data?: number;
  badgeStatus?: IBadgeStatus;
  loading: string;
  error?: string | null;
  step1Status?: string;
  step2_3Status?: string;
}

const initialState: InvestorAccount = {
  Data: undefined,
  badgeStatus: undefined,
  loading: "loading",
  error: undefined,
  step1Status: undefined,
  step2_3Status: undefined,
};
export const getInvRegistrationThunk = createAsyncThunk(
  "threeStepVerification/getInvRegistration",
  async (data: ILegalEntityAndPartnerID) => {
    try {
      const response = await getInvRegistrationService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getInvRegistration",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getInvRegistrationThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getInvRegistrationThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      //
      state.Data = action.payload._response.data
        ? action.payload._response.data[0].invRegistrationId
        : 0;
      state.step1Status = action.payload._response.data
        ? action.payload._response.data[0].stepIDStatus
        : 0;
      state.step2_3Status = action.payload._response.data
        ? action.payload._response.data[0].stepAccreditationStatus
        : 0;
      //
      // state.step1Status = action.payload._response.data[0].stepIDStatus;
      // state.step2_3Status =
      //   action.payload._response.data[0].stepAccreditationStatus;
      //
      state.badgeStatus = action.payload._response.data
        ? action.payload._response.data[0]
        : 0;
      state.error = undefined;
      // state.badgeStatus = action.payload._response.data[0];
    });
    builder.addCase(getInvRegistrationThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
