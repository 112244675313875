import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteInvestingAccountDataService } from "../../../../../../services/investor/investor";
import error from "../../../../../../utils/error";
import { IDeleteInvestingAccountPayload } from ".";
// import { deleteInvestingAccountDataService } from "../../../../services/stepVerification/step-vetification";
// import { IDeleteInvestingAccountPayload } from "../../../../types";
// import error from "../../../../utils/error";

interface IDeleteInvestingAccount {
  data?: string;
  loading: string;
  error?: string | null;
}

const initialState: IDeleteInvestingAccount = {
  data: undefined,
  loading: "loading",
  error: undefined,
};

export const deleteInvestingAccountDataThunk = createAsyncThunk(
  "investorAccount/DeleteInvestingAccountData",
  async (data: IDeleteInvestingAccountPayload) => {
    try {
      const response = await deleteInvestingAccountDataService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "investorSetup/DeleteInvestingAccountData",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(deleteInvestingAccountDataThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      deleteInvestingAccountDataThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
      }
    );
    builder.addCase(
      deleteInvestingAccountDataThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // action.payload contains error information
        state.error = error(action.payload);
        throw error(action.payload);
      }
    );
  },
});

export default slice.reducer;
