import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFundLevelReconcileIncomeService } from "../../../../../../services/taxmanagement/tax";

import error from "../../../../../../utils/error";

import {
  IGetFundLevelReconcileIncomeState,
  IGetFundLevelReconcileIncomeparameters,
} from ".";

const initialState: IGetFundLevelReconcileIncomeState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  data2: undefined,
};
export const GetFundLevelReconcileIncomeThunk = createAsyncThunk(
  "TaxManagement/GetFundLevelReconcileIncome",
  async (data: IGetFundLevelReconcileIncomeparameters) => {
    try {
      const response = await GetFundLevelReconcileIncomeService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "TaxManagement/GetFundLevelReconcileIncome",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetFundLevelReconcileIncomeThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      GetFundLevelReconcileIncomeThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data
          ? action.payload._response.data
          : [];
        state.data2 = action.payload._response.data
          ? action.payload._response.data
          : [];
      }
    );
    builder.addCase(GetFundLevelReconcileIncomeThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
