// import { IAddBankMailInfoData } from "../add-bank-mail-info";
// import { IAddEdgarFilingeData } from "../add-edgar-filing";
// import { IAddFundProfileData } from "../add-fund-profile";
// import { IAddGPProfileData } from "../add-gp-profile";
// import { IAddThirdPartApiData } from "../add-third-party-api";

export const GetSetupFundPayload = {
  fundProfileDetails: undefined,
  fundGPEmailData: undefined,
  fundAdminData: undefined,
  fundGPProfileDetails: undefined,
  fundbankAndMailingDetails: undefined,
  thirdPartyAPIkeys: undefined,
  edgarDetails: undefined,
};
export interface IGetSetupFundData {
  // fundProfileDetails?: any;
  // // fundGPProfileDetails?: IAddGPProfileData;
  // // fundbankAndMailingDetails?: IAddBankMailInfoData;
  // // thirdPartyAPIkeys?: IAddThirdPartApiData;
  // edgarDetails?: any;
  fundGPEmailData: any;
  fundAdminData: any;
  fundProfileDetails: any;
  fundGPProfileDetails: any;
  fundbankAndMailingDetails: any;
  thirdPartyAPIkeys: any;
  edgarDetails: any;
}

export interface IGetSetupFundState {
  success: boolean;
  message: string;
  loading: string;
  error: any;
  data: IGetSetupFundData;
}
