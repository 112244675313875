import { Sidebar } from "primereact/sidebar";
import React from "react";
import "./Chatbot.scss";
import { setChatBotState } from "../../store/features/chat-bot-state/slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { envVariable } from "../../utils/sponsor/helper";

export default function ChatbotComponent() {
  const dispatch = useAppDispatch();
  const chatbotState = useAppSelector((state) => state.chatBotState.data);
  return (
    <Sidebar
      visible={chatbotState.visibleRightState}
      position="right"
      onHide={() => dispatch(setChatBotState({ visibleRightState: false }))}
      className="help"
    >
      <h3>Help Center</h3>
      Virtual Assistant
      <hr></hr>
      <iframe
        src={envVariable?.REACT_APP_Chat_Bot}
        className="top-section"
      ></iframe>
    </Sidebar>
  );
}
