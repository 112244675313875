import React from "react";
import AppRoutes from "../../AppRoutes";

const LoginPage = () => {
  return (
    <div className="redirectingwrap">
      <AppRoutes />
    </div>
  );
};

export default LoginPage;
