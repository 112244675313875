import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { postFundPrimaryAccount } from "../../../services/finance/finance";
import { postFundPrimaryAccount } from "../../../services/Dwolla/dwolla";
import { IPostFundPrimaryAccountPayload } from "../../../types";
import error from "../../../utils/error";

interface IFundPrimaryAccount {
  data: [];
  loading: string;
  error?: string | null;
}

const initialState: IFundPrimaryAccount = {
  data: [],
  loading: "loading",
  error: undefined,
};

export const setFundPrimaryAccountThunk = createAsyncThunk(
  "finance/setFundPrimaryAccount",
  async (data: IPostFundPrimaryAccountPayload) => {
    try {
      const response = await postFundPrimaryAccount(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "finance/setFundPrimaryAccount",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(setFundPrimaryAccountThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(setFundPrimaryAccountThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";

      state.data = action.payload._response.data;
    });
    builder.addCase(setFundPrimaryAccountThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
