import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import DataTableComponent from "../../../../../components/data-table/DataTableComponent";
import Loader from "../../../../../components/loader/Loader";
import { getThunkAllocatedInvestorListData } from "../../../../../store/features/operation/allocation-slices/get-allocated-investor/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../../utils/date-formatter";

export default function AllocatedInvestor() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const allocateInvestor = useAppSelector(
    (state) => state.getAllocatedInvestorList.data
  );
  const [loading, setLoading] = useState(false);
  const [allocatedInvestmentList, setAllocatedInvestmentList] = useState<any>();
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const allocationSliceState = useAppSelector(
    (state) => state.allocationSliceInvestorState.data
  );
  const dataTableElement = [
    {
      field: "lpPartnerName",
      header: "Investor Name",
      // style: { width: "70px" },
    },
    { field: "earningsPayoutTiming", header: "Earning Timing" },
    { field: "cashBalance", header: "Cash Balance" },
    { field: "units", header: "Allocated Units" },
    {
      field: "maxStartDate",
      header: "Previous Allocation Date",
      dataType: "date",
      body: (rowData: any) => formatDateMMDDYYY(rowData.maxStartDate),
    },
    {
      field: "endDate",
      header: "End Date",
      dataType: "date",
      body: (rowData: any) =>
        rowData.endDate ? formatDateMMDDYYY(rowData.endDate) : "",
    },
    { field: "assetClass", header: "Class" },
  ];
  useEffect(() => {
    if (
      fundDropdown.fundid !== undefined &&
      fundDropdown.fundid > 0 &&
      allocationSliceState.investmentId !== 0 &&
      allocationSliceState.investmentId !== undefined
    ) {
      setLoading(true);
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        investmentId: allocationSliceState.investmentId,
      };
      dispatch(getThunkAllocatedInvestorListData(parameter)).finally(() => {
        setLoading(false);
      });
    }
  }, [fundDropdown, allocationSliceState.investmentId]);
  useEffect(() => {
    setAllocatedInvestmentList(
      getListDateConversion(allocateInvestor.output, [
        "maxStartDate",
        "endDate",
      ])
    );
  }, [allocateInvestor]);

  return (
    <>
      <Toast ref={toast} className="themeToast" />
      {loading && <Loader />}
      <DataTableComponent
        valueData={allocatedInvestmentList || []}
        // valueData={testdata || []}
        fieldsElements={dataTableElement}
        noGridLines={true}
        isPaginationAllowed={true}
        className="allocateSliceTable"
      />
    </>
  );
}
