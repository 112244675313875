import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ReverseAllocationSliceService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IReverseAllocationSliceState } from ".";

const initialState: IReverseAllocationSliceState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    output: [],
    investmentData: undefined,
  },
};

export const reverseThunkAllocationSlice = createAsyncThunk(
  "reverseAllocationSlice",
  async (data: any) => {
    try {
      const response = await ReverseAllocationSliceService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "reverseAllocationSlice",
  initialState,
  reducers: {
    // setAllocatedInvestorState: (state, action) => {
    //   state.data = action.payload;
    // },
  },
  extraReducers(builder): void {
    builder.addCase(reverseThunkAllocationSlice.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(reverseThunkAllocationSlice.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("investorList", action.payload._response);
      state.data =
        action.payload._response.data !== null
          ? action.payload._response.data
          : {
              output: [],
              investmentData: undefined,
            };
      console.log("im", state.data);
    });
    builder.addCase(reverseThunkAllocationSlice.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      state.error = error(action.error);
      throw new Error(error(state.error));
    });
  },
});

// export const { setAllocatedInvestorState } = slice.actions;
export default slice.reducer;
