import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";

// import { RiAddFill } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { goToTop } from "../../../../components/scroll-to/ScrollTo";
import { GetNonRegisteredInvestorListThunk } from "../../../../store/Investor/setupinvestorfees/getnonregisteredinvestorlist/slice";
import { getThunkPortfolioRegisterInvestorListDropdown } from "../../../../store/features/dropdown/registeredinvestorlist/slice";
import { getThunkInvestmentByFilter } from "../../../../store/features/investment/investment-filter/slice";
import { getThunkCheckEmailExist } from "../../../../store/features/view-gather-softcommit/check-email-exist/slice";
import {
  addThunkViewGatherSoftCommit,
  deleteThunkViewSoftCommit,
} from "../../../../store/features/view-gather-softcommit/create-softcommit/slice";
import { getThunkInvestorBasicInfo } from "../../../../store/features/view-gather-softcommit/investor-basic-info/slice";
// import { getThunkInvestorList } from "../../../../store/features/view-gather-softcommit/investor-list-dropdown/slice";
import { getThunkInvestorType } from "../../../../store/features/view-gather-softcommit/investortype-dropdown/slice";
import {
  getThunkViewSoftCommit,
  setViewSoftCommitState,
} from "../../../../store/features/view-gather-softcommit/list-softcommits/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { currencyFormat } from "../../../../utils/currency-formatter";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import { regexForEmail } from "../../../../utils/sponsor/constants";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

export default function ViewGatherSoftCommit() {
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState(0);
  const [targetRaiseAmt, setTargetRaiseAmt] = useState<any>(0);
  const [targetCloseDate, setTargetCloseDate] = useState<any>();
  const [emailState, setEmailState] = useState("");
  const [openDailog, setOpenDailog] = useState(false);
  // const investorList = useAppSelector((state) => state.investorList.data);
  const nonRegisterInvestorList = useAppSelector(
    (state) => state.GetNonRegisteredInvestorList.data
  );
  // const checkEmailState = useAppSelector(
  //   (state) => state.checkEmailExistViewGatherCommit.data
  // );
  const registerInvestorList = useAppSelector(
    (state) => state.registerinvestor?.data
  );
  // const registerInvestorList = useAppSelector(
  //   (state) => state.GetRegisteredInvestorList.data
  // );
  const [softCommitId, setSoftCommitId] = useState(0);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [add, setAdd] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showTab, setShowTab] = useState(true);
  const [edit, setEdit] = useState(false);

  const [softCommitList, setSoftCommitList] = useState<any>();
  const [openDialog, setOpenDialog] = useState(false);
  const listDealInvestment = useAppSelector(
    (state) => state.investmentByFilter.data
  );
  const investorTypeDropDown = useAppSelector(
    (state) => state.investorType.data
  );

  const investorBasicInfo = useAppSelector(
    (state) => state.investorBasicInfo.data
  );

  const totalSoftCommit = useAppSelector(
    (state) => state.listViewGatherSoftCommit.data.total
  );

  const profileData = useAppSelector((state) => state.profile.data![0]);
  const viewSoftCommit = useAppSelector(
    (state) => state.listViewGatherSoftCommit.data.softCommits
  );
  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData.createdDate);
    return dateFormat;
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const DropdownList: any = [];

  listDealInvestment?.map((item: any) => {
    //
    return DropdownList.push({
      name: item.productName,
      code: item.productID,
    });
  });
  const submitSoft = (formik: any) => {
    const viewSoftcommit = {
      id: softCommitId || 0,
      legalEntityID: fundDropdown.fundid,
      // productId: formik.values.productID,
      InvestorId:
        formik.values.investorType === 3 ? formik.values.partnerId : 0,
      productId,
      partnerId: formik.values.investorType === 1 ? formik.values.partnerId : 0,
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      email: formik.values.email
        ? formik.values.email?.trim()
        : formik.values.email,
      walkingUserId: 0,
      softAmount: formik.values.softAmount,
      createdBy: profileData?.userID,
      investorType: formik.values.investorType,
    };

    console.log(viewSoftcommit);
    dispatch(addThunkViewGatherSoftCommit(viewSoftcommit))
      .then((response) => {
        if (response.meta.requestStatus === "rejected") {
          errorToastMessage(toast);
        } else if (response.meta.requestStatus === "fulfilled") {
          if (formik.values.investorType === 2) {
            successToastMessage(
              toast,
              "New investor has been added to Invitations - but the invite has not yet been sent"
            );
          } else {
            successToastMessage(toast, "Soft commit added successfully");
          }
          // dispatch(getThunkViewSoftCommit(formik.values.productID));
          dispatch(getThunkViewSoftCommit(productId));
          setAdd(false);
          setEdit(false);
          setShowTab(true);
          setSoftCommitId(0);
          setEmailState("");
          setEdit(false);
          setShowTab(true);
          setAdd(false);
          formik.resetForm();
          formik.setFieldValue("productID", productId);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      // productID: 0,
      investorType: 0,
      partnerId: 0,
      softAmount: 0,
      firstName: "",
      lastName: "",
      email: "",
    },
    validate: (data) => {
      const errors: any = {};
      console.log("data", data);
      if (!data.investorType) {
        errors.investorType = "Select investor type";
      }
      // if (!data.productID) {
      //   errors.productID = "Select Deal/Investment";
      // }
      if (!data.softAmount) {
        errors.softAmount = "Amount is required";
      }

      if (data.softAmount && data.softAmount > 20_000_000_000) {
        errors.softAmount = "Amount should be less than equal to $20B";
      }
      if (formik.values.investorType !== 2) {
        if (!data.partnerId) {
          errors.partnerId = "Select account";
        } else {
          //
        }
      }
      if (formik.values.investorType !== 1) {
        if (!data.firstName) {
          errors.firstName = "First name is required";
        }
        if (!data.lastName) {
          errors.lastName = "Last name is required";
        }
        if (!data.email) {
          errors.email = "Email is required";
        }
        if (data.email && !regexForEmail.test(data.email)) {
          errors.email = "Enter valid email";
        }
      }
      return errors;
    },
    onSubmit: async () => {
      if (formik.values.investorType === 2) {
        console.log("send 1");
        if (formik.values.email !== emailState) {
          console.log("send 12345");
          const parameter = {
            softCommitId: 0,
            email: formik.values.email
              ? formik.values.email?.trim()
              : formik.values.email,
          };

          dispatch(getThunkCheckEmailExist(parameter))
            .then((response) => {
              if (response.payload.data === 1) {
                errorToastMessage(toast, response.payload.message);
                formik.setFieldValue("email", "");
              } else {
                console.log("send 2");
                setLoading(true);
                submitSoft(formik);
              }
            })
            .catch(() => {
              errorToastMessage(toast);
            });
        } else {
          console.log("send 2");
          setLoading(true);
          submitSoft(formik);
        }
      } else {
        console.log("send");
        setLoading(true);
        submitSoft(formik);
      }
    },
  });
  // const onAddClick = () => {
  //   formik.resetForm();
  //   formik.setFieldValue("productID", productId);
  //   setSoftCommitId(0);
  //   setAdd(true);
  //   setEdit(false);
  //   setShowTab(true);
  // };

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      ""
    );
  };

  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = (id: any) => {
      console.log("fghj", rowData);
      if (rowData.investorType === 1) {
        formik.setFieldValue("partnerId", rowData.partnerId);
      } else if (rowData.investorType === 3) {
        console.log("Investor type");
        formik.setFieldValue("partnerId", Number(rowData.walkInUserId));
      }
      formik.setFieldValue("softAmount", rowData.amount);
      formik.setFieldValue("firstName", rowData.firstName);
      formik.setFieldValue("lastName", rowData.lastName);
      formik.setFieldValue("email", rowData.emailAddress);
      formik.setFieldValue("investorType", rowData.investorType);
      setEmailState(rowData.emailAddress);
      // if (rowData.partnerId === 0) {
      //   formik.setFieldValue("investorType", 2);
      // } else if (rowData.partnerId !== 0) {
      //   formik.setFieldValue("investorType", 1);
      // }

      setSoftCommitId(rowData.id);
      setAdd(false);
      setEdit(true);
      setShowTab(false);
      goToTop();
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={() => handleEdit(rowData.Id)}
          >
            <span className="text-white">Edit</span>
          </Button>
        </div>
      </div>
    );
  };

  const deleteButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleDelete = (id: any) => {
      setSoftCommitId(rowData.id);
      formik.resetForm();
      formik.setFieldValue("productID", productId);
      setOpenDialog(true);
      setShowTab(true);
      setAdd(false);
      setEdit(false);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={() => handleDelete(rowData.Id)}
          >
            <span className="text-white">Delete</span>
          </Button>
        </div>
      </div>
    );
  };

  const formatCurrencyAnyData = (rowData: any) => {
    const finalValue = Number(rowData);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const formatCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.amount);
  };

  const dataTableElements = [
    {
      field: "productId",
      header: "Product Id",
      style: { minWidth: "120px" },
    },
    // {
    //   field: "walkInUserId",
    //   header: "WalkIn UserId",
    // },
    // {
    //   field: "partnerId",
    //   header: "Partner Id",
    // },
    {
      field: "productName",
      header: "Deal Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.productName}>
          {rowData?.productName}
        </div>
      ),
    },
    {
      field: "partnerName",
      header: "Partner Name",
      style: { minWidth: "180px" },
    },
    {
      field: "createdDate",
      header: "Soft Commit Date",
      dataType: "date",
      body: formatDate,
      filterElement: dateFilterTemplate,
      style: { minWidth: "200px" },
    },
    {
      field: "amount",
      header: "Amount",
      body: formatCurrency,
      style: { minWidth: "200px" },
    },
    {
      header: " ",
      body: (rowData: any) => deleteButton(rowData), // Pass rowData to editButton
      style: { minWidth: "200px" },
    },
    {
      header: " ",
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
      style: { minWidth: "200px" },
    },
  ];

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log("name", name, value);
    if (name === "investorType") {
      formik.setFieldValue("partnerId", 0);

      formik.setFieldValue("softAmount", 0);
      formik.setFieldValue("firstName", "");
      formik.setFieldValue("lastName", "");
      formik.setFieldValue("email", "");
      formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(name, value);
    }
  };
  //  console.log("poioi", productId);
  const onDelete = () => {
    setLoading(true);
    const deleteParms = {
      softCommitId,
    };
    dispatch(deleteThunkViewSoftCommit(deleteParms)).then((response) => {
      // call the list
      if (response.payload.success === true) {
        successToastMessage(toast, "Data is Deleted successfully");
        if (productId !== 0) {
          dispatch(getThunkViewSoftCommit(productId)).finally(() => {
            setLoading(false);
          });
          setSoftCommitId(0);
        }
      }
    });

    setOpenDialog(false);
  };

  useEffect(() => {
    setLoading(true);
    setTargetCloseDate("");
    setTargetRaiseAmt(0);
    setSoftCommitList([]);
    setAdd(false);
    setEdit(false);
    setShowTab(true);
    formik.resetForm();
    setSoftCommitId(0);
    setProductId(0);
    const resetValue = {
      softCommits: [],
      total: 0,
    };
    dispatch(setViewSoftCommitState({ data: resetValue }));
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      // console.log("inside");
      const parameter = {
        legalEntityId: fundDropdown.fundid,
      };
      dispatch(getThunkInvestmentByFilter(parameter)).finally(() => {
        setLoading(false);
      });
      dispatch(getThunkInvestorType());
    }
  }, [fundDropdown]);

  useEffect(() => {
    if (formik.values.partnerId !== 0) {
      setLoading(true);
      let parameter: any;
      if (formik.values.investorType === 1) {
        parameter = {
          partnerId: formik.values.partnerId,
          isInvestor: false,
        };
      } else if (formik.values.investorType === 3) {
        parameter = {
          partnerId: formik.values.partnerId,
          isInvestor: true,
        };
      }
      dispatch(getThunkInvestorBasicInfo(parameter)).finally(() => {
        setLoading(false);
      });
    }
  }, [formik.values.partnerId]);

  useEffect(() => {
    console.log("product123", listDealInvestment);
    const product = listDealInvestment?.find(
      (inm: any) => inm.productID === productId
    );
    console.log("product12", product);
    setTargetCloseDate(product?.targetCloseDate);
    setTargetRaiseAmt(product?.targetRaiseAmt);
    if (productId !== 0) {
      setLoading(true);
      dispatch(getThunkViewSoftCommit(productId)).finally(() => {
        setLoading(false);
      });
      setSoftCommitId(0);
    }
  }, [productId]);
  useEffect(() => {
    //  console.log("soft", viewSoftCommit);
    formik.setFieldValue("investorType", 0);
    formik.setFieldValue("partnerId", 0);
    formik.setFieldValue("softAmount", 0);
    formik.setFieldValue("firstName", "");
    formik.setFieldValue("lastName", "");
    formik.setFieldValue("email", "");
    setSoftCommitList(
      getListDateConversion(viewSoftCommit, ["targetCloseDate", "createdDate"])
    );
  }, [viewSoftCommit]);

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      //  console.log("inside");
      const parameter = {
        LegalEntityId: fundDropdown.fundid,
      };
      if (formik.values.investorType === 1) {
        dispatch(getThunkPortfolioRegisterInvestorListDropdown(parameter));
      } else if (formik.values.investorType === 3) {
        dispatch(GetNonRegisteredInvestorListThunk(parameter));
      }
    }
  }, [formik.values.investorType]);

  useEffect(() => {
    // console.log("ki", investorBasicInfo);
    if (investorBasicInfo) {
      //  console.log("kioo");
      formik.setFieldValue("firstName", investorBasicInfo.firstName);
      formik.setFieldValue("lastName", investorBasicInfo.lastName);
      formik.setFieldValue("email", investorBasicInfo.emailAddress);
    }
  }, [investorBasicInfo]);

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total:"
          colSpan={4}
          // footerStyle={{ textAlign: "right" }}
        />
        <Column footer={formatCurrencyAnyData(totalSoftCommit)} />
        <Column colSpan={2} />
      </Row>
    </ColumnGroup>
  );
  const onCancel = () => {
    setSoftCommitId(0);
    setOpenDialog(false);
  };
  let allInvestors: any;
  useEffect(() => {
    // formik.setFieldValue("partnerId", 0);
    // formik.setFieldValue("softAmount", "");
    // formik.setFieldValue("firstName", "");
    // formik.setFieldValue("lastName", "");
    // formik.setFieldValue("email", "");

    if (formik.values.investorType === 1) {
      allInvestors = registerInvestorList;
    } else if (formik.values.investorType === 3) {
      allInvestors = nonRegisterInvestorList!;
    }
  }, [
    registerInvestorList,
    nonRegisterInvestorList,
    formik.values.investorType,
  ]);

  // const allInvestors: any = registerInvestorList;
  // allInvestors.push(nonRegisterInvestorList);
  console.log("All Inverstors Here->>>", allInvestors);
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Manage Soft Commits</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
        <Dialog
          visible={openDialog}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setOpenDialog(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Are you sure you want to delete ?
          </h4>
          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={(e) => {
                e.preventDefault();
                onDelete();
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setSoftCommitId(0);
                setOpenDialog(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>

        <ConfirmDailogbox
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          onConfirm={onDelete}
          onCancel={onCancel}
          textContent=" Are you sure you want to delete ?"
        />

        <div className="grid pt-0 col-12">
          <div className="col-12 pt-0 pr-0">
            <div className="card tabWithoutboxshadow">
              <Card>
                <div className="formgrid grid px-2">
                  <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
                    <label className="inputLabel">Deal/Investment*</label>
                    <Dropdown
                      inputId="productID"
                      name="productID"
                      value={productId}
                      options={DropdownList === null ? [] : DropdownList}
                      filter
                      optionLabel="name"
                      optionValue="code"
                      resetFilterOnHide={true}
                      showFilterClear={true}
                      placeholder="Select"
                      onChange={(e) => {
                        console.log("id", e.target.value);
                        setProductId(e.target.value);
                        formik.resetForm();
                        // formik.setFieldValue("productID", e.target.value);
                        setAdd(false);
                        setEdit(false);
                        setShowTab(true);
                      }}
                      // className={classNames({
                      //   "p-invalid": isFormFieldInvalid("productID"),
                      //   "card-inputBox": true,
                      // })}
                      className={classNames("custom-dropdown", {
                        "p-invalid": isFormFieldInvalid("productID"),
                        "card-inputBox": true,
                      })}
                    />
                    {getFormErrorMessage("productID")}
                  </div>{" "}
                  <div className="col-12 sm:col-6 flex justify-content-end">
                    {!add ||
                      (edit && (
                        <div className="flex justify-content-end col-6"></div>
                      ))}
                    {/* {showTab && (
                      <div className="tabHeader-wrap flex justify-content-end col-12 sm:mt-4">
                        <label className="inputLabel">&nbsp;</label>

                        <Button
                          onClick={onAddClick}
                          className="btn-navActive mr-0 m-2"
                        >
                          <span className="ml-auto mr-auto ">
                            {" "}
                            Add Soft Commit{" "}
                            <span className="pl-2">
                              <RiAddFill />
                            </span>
                          </span>
                        </Button>
                      </div>
                    )} */}
                    {edit && (
                      <div className=" tabHeader-wrap flex justify-content-end col-6">
                        <Button className="tabHeadText mt-3 Active">
                          {" "}
                          Edit Soft Commit
                        </Button>
                      </div>
                    )}
                  </div>
                </div>

                {add || edit ? (
                  <>
                    <div className="mb-4 mt-2">
                      <div className="formgrid grid px-2">
                        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6 annualClass">
                          <label className="inputLabel">
                            Target Raise Amount
                          </label>

                          <InputNumber
                            name="targetRaiseAmt"
                            value={targetRaiseAmt === 0 ? "" : targetRaiseAmt}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("targetRaiseAmt"),
                              "card-inputBox": true,
                              "p-filled": true,
                            })}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            disabled
                            placeholder="Target Raise Amount"
                          />
                          {getFormErrorMessage("targetRaiseAmt")}
                        </div>
                        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6 annualClass">
                          <label className="inputLabel">
                            Target Close Date
                          </label>

                          <Calendar
                            name="targetCloseDate"
                            value={
                              targetCloseDate === null || targetCloseDate === ""
                                ? undefined
                                : new Date(targetCloseDate)
                            }
                            // onChange={(e) => dateStateChange(e)}
                            className={classNames({
                              "p-invalid":
                                isFormFieldInvalid("targetCloseDate"),
                              "card-inputBox": true,
                            })}
                            disabled
                            // minDate={todayDate}
                            placeholder="Target Close Date"
                          />
                          {getFormErrorMessage("targetCloseDate")}
                        </div>
                      </div>
                      <div className="formgrid grid px-2">
                        <div className="d-flex flex-column p-2 col-12 ">
                          <label className="inputLabel">Investor Type *</label>
                          <Dropdown
                            inputId="investorType"
                            name="investorType"
                            value={
                              formik.values.investorType === 0
                                ? ""
                                : formik.values.investorType
                            }
                            options={
                              investorTypeDropDown === null
                                ? []
                                : investorTypeDropDown
                            }
                            filter
                            optionLabel="value"
                            optionValue="key"
                            placeholder="Select Investor Type"
                            // className="card-inputBox"
                            onChange={(e) => {
                              handleChange(e);
                              // formik.setFieldValue("productName", e.target.value);
                            }}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("investorType"),
                              "card-inputBox": true,
                            })}
                          />
                          {getFormErrorMessage("investorType")}
                        </div>
                      </div>

                      <div className="formgrid grid px-2">
                        <div className="d-flex flex-column p-2 col-12 sm:col-6 annualClass">
                          <label className="inputLabel">
                            Investor Account *
                          </label>
                          <Dropdown
                            inputId="partnerId"
                            name="partnerId"
                            value={formik.values.partnerId}
                            resetFilterOnHide={true}
                            showFilterClear={true}
                            options={
                              formik.values.investorType === 1
                                ? registerInvestorList
                                : formik.values.investorType === 3
                                ? nonRegisterInvestorList
                                : []
                            }
                            // options={allInvestors === null ? [] : allInvestors}
                            filter
                            optionLabel="investorName"
                            optionValue="investorID"
                            placeholder="Select Investor Account "
                            // className="card-inputBox"
                            onChange={(e) => {
                              handleChange(e);
                              // formik.setFieldValue("partnerId","");
                            }}
                            disabled={formik.values.investorType === 2}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("partnerId"),
                              "card-inputBox": true,
                            })}
                          />
                          {getFormErrorMessage("partnerId")}
                        </div>
                        <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
                          <label className="inputLabel">Amount *</label>
                          <InputNumber
                            name="softAmount"
                            value={
                              formik.values.softAmount === 0
                                ? undefined
                                : formik.values.softAmount
                            }
                            onValueChange={(e) => handleChange(e)}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("softAmount"),
                              "w-100": true,
                              "p-filled": true,
                            })}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            // maxLength={20}
                            placeholder="Enter Amount"
                          />
                          {getFormErrorMessage("softAmount")}
                        </div>
                      </div>
                      <div className="formgrid grid px-2">
                        <div className="d-flex flex-column p-2 col-12 sm:col-4 annualClass">
                          <label className="inputLabel">First Name *</label>
                          <InputText
                            name="firstName"
                            value={formik.values.firstName}
                            onChange={(e) => {
                              const newValue = e.target.value.replace(
                                /\d/g,
                                ""
                              ); // Remove digits
                              formik.setFieldValue("firstName", newValue);
                            }}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("firstName"),
                              "card-inputBox": true,
                              "p-filled": true,
                            })}
                            disabled={
                              formik.values.investorType === 1 ||
                              formik.values.investorType === 3
                            }
                            placeholder="Enter First Name"
                          />
                          {getFormErrorMessage("firstName")}
                        </div>
                        <div className="d-flex flex-column p-2 col-12 sm:col-4 annualClass">
                          <label className="inputLabel">Last Name *</label>
                          <InputText
                            name="lastName"
                            value={formik.values.lastName}
                            // onChange={(e) => handleChange(e)}
                            onChange={(e) => {
                              const newValue = e.target.value.replace(
                                /\d/g,
                                ""
                              ); // Remove digits
                              formik.setFieldValue("lastName", newValue);
                            }}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("lastName"),
                              "card-inputBox": true,
                              "p-filled": true,
                            })}
                            disabled={
                              formik.values.investorType === 1 ||
                              formik.values.investorType === 3
                            }
                            placeholder="Enter Last Name"
                          />
                          {getFormErrorMessage("lastName")}
                        </div>
                        <div className="d-flex flex-column p-2 col-12 sm:col-4 annualClass">
                          <label className="inputLabel">Email *</label>
                          <InputText
                            name="email"
                            value={formik.values.email}
                            onChange={(e) => handleChange(e)}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("email"),
                              "card-inputBox": true,
                              "p-filled": true,
                            })}
                            disabled={
                              formik.values.investorType === 1 ||
                              formik.values.investorType === 3
                            }
                            placeholder="Enter Email"
                          />
                          {getFormErrorMessage("email")}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
                      <div className="d-flex flex-column p-2 col-12 sm:col-2 ">
                        {/* <div className="d-flex justify-content-center  mt-4 mb-3"> */}
                        <Button
                          className="btn-nav "
                          onClick={(e) => {
                            e.preventDefault();
                            setSoftCommitId(0);
                            formik.resetForm();
                            setEdit(false);
                            setShowTab(true);
                            setAdd(false);
                          }}
                        >
                          <span className="ml-auto mr-auto ">Cancel</span>
                        </Button>
                      </div>
                      <div className="d-flex flex-column p-2 col-12 sm:col-2 ">
                        <Button
                          className="btn-navActive "
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                          }}
                        >
                          <span className="ml-auto mr-auto ">Save</span>
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="col-12 pt-0">
                  <div className="flex justify-content-end mb-1 ">
                    {(!edit || productId === 0) && !add && (
                      <Button
                        className="btn-navActive mr-2"
                        type="button"
                        disabled={productId === 0 || add}
                        onClick={() => setAdd(true)}
                        title="Add Soft Commit"
                      >
                        Add Soft Commit
                      </Button>
                    )}
                    <Button
                      className="btn-navActive"
                      type="button"
                      icon="pi pi-window-maximize"
                      disabled={
                        softCommitList === undefined ||
                        softCommitList?.length === 0 ||
                        softCommitList === null
                      }
                      onClick={() => setOpenDailog(true)}
                      title="View Full Screen"
                    />
                  </div>
                  <DataTableComponent
                    valueData={softCommitList || []}
                    fieldsElements={dataTableElements}
                    isPaginationAllowed={true}
                    columnResizeMode="expand"
                    className="gridcell deletebtn"
                    scrollable={true}
                    isFullView={true}
                    setOpenDailog={setOpenDailog}
                    openDailog={openDailog}
                    resizableColumns={true}
                    footerElement={
                      softCommitList && softCommitList.length > 0 && footerGroup
                    }
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
