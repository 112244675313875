const mailingAddressPayload = (data: any) => {
  return {
    addressId: data.addressId,
    address: data.address,
    address2: data.address2,
    city: data.city,
    countryId: +data.countryId,
    state: data.state,
    zipCode: data.zipCode,
  };
};

const beneficiaryDetailsPayload = (data: any) => {
  const beneficiaryData = {
    beneficiaryId: data.beneficiaryId,
    beneficiaryAddressId: data.beneficiaryAddressId,
    beneficiaryAddress: data.beneficiaryAddressLine1,
    beneficiaryAddress2: data.beneficiaryAddressLine2,
    beneficiaryEmail: data.beneficiaryEmail,
    accountHolderRelationId: data.beneficiaryRelationship,
    beneficiaryFirstName: data.beneficiaryFirstName,
    beneficiaryLastName: data.beneficiaryLastName,
    beneficiaryPhoneNumber: data.beneficiaryPhoneNumber,
    investingAccountId: 0,
    beneficiaryCity: data.beneficiaryCity,
    beneficiaryCountryId: +data.beneficiaryCountryId,
    beneficiaryState: data.beneficiaryState,
    beneficiaryZipCode: data.beneficiaryZipCode,
  };

  return beneficiaryData;
};

export const individualPayload = (data: any, investorID: any) => {
  console.log(data);
  const payload = {
    action: data.action,
    investingAccountId: data.investingAccountId,
    investorId: investorID,
    accountTypeId: +data.accountType,
    accountNickName: data.myAccountName,
    socialSecurityNumber: data.socialSecurityNumber,
    firstName: data.firstName,
    lastName: data.lastName,
    // common fields
    ...mailingAddressPayload(data),

    beneficiaryData: beneficiaryDetailsPayload(data),
  };

  return payload;
};

export const singleMemberLLCPayload = (data: any, investorID: any) => {
  console.log("singleMemberLLCPayload", data);
  const payload = {
    action: data.action,
    investingAccountId: data.investingAccountId,
    investorId: +investorID,
    accountTypeId: +data.accountType,
    accountNickName: data.myAccountName,
    disRegarded: data.disRegarded,
    entityEIN: data.ein,
    entityName: data.entityName,
    socialSecurityNumber: data.socialSecurityNumber,

    ...mailingAddressPayload(data),
    beneficiaryData: beneficiaryDetailsPayload(data),
  };

  return payload;
};

export const selfDirectedIRAPayload = (data: any, investorID: any) => {
  console.log("selfDirectedIRAPayload", data);
  const payload = {
    action: data.action,
    investingAccountId: data.investingAccountId,
    investorId: +investorID,
    accountTypeId: +data.accountType,
    accountNickName: data.myAccountName,
    custodianMaster: {
      custodianId: data.custodianId,
      sdiraAccountTitle: data.accountTitleSDIRA,
      custodianName: data.custodianName,
      custodianEIN: data.ein,
      custodianAccountNumber: "" + data.custodianAccountNumber,
      custodianRoutingNumber: "" + data.custodianRoutingNumber,
      custodianEmail: data.custodianEmail,
      custodianWebsite: data.custodianWebsite,
      custodianPhoneNumber: data.custodianPhoneNumber,

      custodianAddressId: data.addressId,
      custodianAddress: data.address,
      custodianAddress2: data.address2,
      custodianCity: data.city,
      custodianCountryId: +data.countryId,
      custodianState: data.state,
      custodianZipCode: data.zipCode,
    },
  };

  return payload;
};

export const solo401kPayload = (data: any, investorID: any) => {
  // console.log("solo401kPayload", data);
  const payload = {
    action: data.action,
    investingAccountId: data.investingAccountId,
    investorId: +investorID,
    accountTypeId: +data.accountType,
    accountNickName: data.myAccountName,
    entityEIN: data.ein,
    solo401AccountTitle: data.accountTitle,
    ...mailingAddressPayload(data),
  };

  return payload;
};

export const jointAccountPayload = (data: any, investorID: any) => {
  console.log("jointAccountPayload", data);
  const payload = {
    action: data.action,
    investingAccountId: data.investingAccountId,
    investorId: +investorID,
    accountTypeId: +data.accountType,
    accountNickName: data.myAccountName,
    firstName: data.primaryAccountFirstName,
    lastName: data.primaryAccountLastName,
    accountOwnershipId: data.accountOwnershipId,
    socialSecurityNumber: data.primaryAccountHolderSSN,
    ...mailingAddressPayload(data),

    secondaryHolderData: {
      secHolderId: data.secHolderId,
      secFirstname: data.secFirstName,
      secLastName: data.secLastName,
      secEmail: data.secEmail,
      secSSN: data.secSSN,
      secPhoneNumber: data.secPhoneNumber,
      secAddressId: data.secAddressId,
      secAddress: data.secAddress,
      secAddress2: data.secAddress2,
      secCity: data.secCity,
      secCountryId: data.secCountryId,
      secState: data.secState,
      secZipCode: data.secZipCode,
    },
  };

  return payload;
};

export const multiMemberLLCPayload = (data: any, investorID: any) => {
  console.log("multiMemberLLCPayload", data, investorID);
  const multiMemberLLC = [];
  for (const value of data.memberInformation) {
    console.log("multiMemberLLCPayload", value);
    const memberMemeberPayload = {
      llcMemberId: value.id,
      llcMemberInvestingAccountId: value.investingAcountId,
      llcMemberFirstName: value.firstName,
      llcMemberLastName: value.lastName,
      llcMemberSocialSecurityNumber: value.ssn,
      llcMemberPhoneNumber: value.phoneNumber,
      llcMemberEmail: value.email,
      birthdate: value.birthDate,
      llcMemberAddressId: value.addressID,
      llcMemberSequenceId: 0,
      llcMemberAddress: value.addressLine1,
      llcMemberAddress2: value.addressLine2,
      llcMemberCity: value.city,
      llcMemberCountryId: value.country,
      llcMemberState: value.state,
      llcMemberZipCode: value.zipCode,
      llcMemberCreatedBy: "" + investorID,
      llcMemberModifiedBy: "" + investorID,
    };
    multiMemberLLC.push(memberMemeberPayload);
  }
  const payload = {
    action: data.action,
    investingAccountId: data.investingAccountId,
    investorId: +investorID,
    accountTypeId: +data.accountType,
    accountNickName: data.myAccountName,
    entityEIN: data.ein,
    entityName: data.entityName,
    taxClassificationId: data.taxclassification,
    ...mailingAddressPayload(data),
    totalMembers: data.noBeneficialMemberInformation,
    multiLLCMembers: multiMemberLLC,
  };

  console.log("multiMemberLLCPayload", payload);

  return payload;
};

export const trustPayload = (data: any, investorID: any) => {
  console.log("trustPayload", data);
  const trustees = [];
  for (const value of data.trusteeInfo) {
    const trusteesPayload = {
      trusteeId: value.id,
      trusteeInvestingAccountId: value.investingAcountId,
      trusteeFirstName: value.firstName,
      trusteeLastName: value.lastName,
      trusteeSocialSecurityNumber: value.ssn,
      trusteePhoneNumber: value.phoneNumber,
      trusteeEmail: value.email,
      birthdate: value.birthDate,
      trusteeAddressId: value.addressID,
      trusteeSequenceId: 0,
      trusteeAddress: value.addressLine1,
      trusteeAddress2: value.addressLine2,
      trusteeCity: value.city,
      trusteeCountryId: value.country,
      trusteeState: value.state,
      trusteeZipCode: value.zipCode,
      trusteeCreatedBy: "" + investorID,
      trusteeModifiedBy: "",
    };
    trustees.push(trusteesPayload);
  }
  const payload = {
    action: data.action,
    investingAccountId: data.investingAccountId,
    investorId: +investorID,
    accountTypeId: +data.accountType,
    accountNickName: data.myAccountName,
    isEIN: data.isEIN,
    socialSecurityNumber: data.socialSecurityNumber,
    trustEstablishmentPlace: data.trustEstablishmentPlace,
    entityEIN: data.ein,
    entityName: data.entityName,
    revocabilityId: data.revocabilityId,
    trustTypeId: data.trustTypeId,
    ...mailingAddressPayload(data),
    totalMembers: data.noOfTrustees,
    trustees,
    // totalMembers: data.noBeneficialMemberInformation,
  };
  console.log("trustPayload", payload);
  return payload;
};

export const partnershipPayload = (data: any, investorID: any) => {
  console.log("partnershipPayload", data);

  const partners = [];

  for (const value of data.partnerInfo) {
    const partnerPayload = {
      partnerId: value.id,
      partnerInvestingAccountId: value.investingAcountId,
      partnerFirstName: value.firstName,
      partnerLastName: value.lastName,
      partnerSocialSecurityNumber: value.ssn,
      partnerPhoneNumber: value.phoneNumber,
      partnerEmail: value.email,
      birthdate: value.birthDate,
      partnerAddressId: value.addressID,
      partnerSequenceId: 0,
      partnerAddress: value.addressLine1,
      partnerAddress2: value.addressLine2,
      partnerCity: value.city,
      partnerCountryId: value.country,
      partnerState: value.state,
      partnerZipCode: value.zipCode,
      partnerCreatedBy: "" + investorID,
      partnerModifiedBy: "",
    };
    partners.push(partnerPayload);
  }

  const payload = {
    action: data.action,
    investingAccountId: data.investingAccountId,
    investorId: +investorID,
    accountTypeId: +data.accountType,
    accountNickName: data.myAccountName,
    entityEIN: data.ein,
    entityName: data.entityName,
    taxClassificationId: data.taxclassification,
    ...mailingAddressPayload(data),
    totalMembers: data.noOfPartnerInfo,
    partners,
  };
  console.log("partnershipPayload", payload);
  return payload;
};

export const corporationPayload = (data: any, investorID: any) => {
  console.log("corporationPayload", data);

  const executives = [];
  // let executivePayload;
  for (const value of data.officerInfo) {
    const executivePayload = {
      executiveId: value.id,
      executiveInvestingAccountId: +value.investingAcountId,
      executiveOfficersTitle: value.title,
      executiveFirstName: value.firstName,
      executiveLastName: value.lastName,
      executiveSocialSecurityNumber: value.ssn,
      executivePhoneNumber: value.phoneNumber,
      executiveEmail: value.email,
      birthdate: value.birthDate,
      executiveAddressId: value.addressID,
      executiveSequenceId: 0,
      executiveAddress: value.addressLine1,
      executiveAddress2: value.addressLine2,
      executiveCity: value.city,
      executiveCountryId: value.country,
      executiveState: value.state,
      executiveZipCode: value.zipCode,
      executiveCreatedBy: "" + investorID,
      executiveModifiedBy: "",
    };
    executives.push(executivePayload);
  }

  const payload = {
    action: data.action,
    investingAccountId: data.investingAccountId,
    investorId: +investorID,
    accountTypeId: +data.accountType,
    accountNickName: data.myAccountName,
    entityEIN: data.ein,
    entityName: data.entityName,
    taxClassificationId: data.taxclassification,

    ...mailingAddressPayload(data),
    // totalMembers: data.noOfOfficerInfo,
    executives,
    totalMembers: data.noOfOfficerInfo,
  };
  console.log("corporationPayload", payload);
  return payload;
};
