import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CheckFundSlugService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { ICheckFundSlugState } from ".";

const initialState: ICheckFundSlugState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    ifSlugExits: true,
  },
};
export const getThunkCheckFundSlug = createAsyncThunk(
  "checkFundSlug",
  async (data: any) => {
    try {
      const response = await CheckFundSlugService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "checkFundSlug",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkCheckFundSlug.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkCheckFundSlug.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);

      state.data =
        action.payload._response.data !== null
          ? action.payload._response.data
          : undefined;
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(getThunkCheckFundSlug.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
