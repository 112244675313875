// import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getOpportunityProjectUpdatesData,
  getOpportunityProjectUpdatesStatus,
  getOpportunityProjectUpdatesThunk,
} from "../../store/opportunity/project-update/slice";

interface IProps {
  id: string;
}
const OpportunityProjectUpdates: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();

  const projectUpdatesData = useAppSelector(getOpportunityProjectUpdatesData);
  const ProjectUpdatesStatus = useAppSelector(
    getOpportunityProjectUpdatesStatus
  );

  useEffect(() => {
    dispatch(getOpportunityProjectUpdatesThunk(props.id));
  }, [dispatch]);

  return (
    <>
      {ProjectUpdatesStatus === "succeeded" ? (
        projectUpdatesData!.length > 0 ? (
          projectUpdatesData!.map((docs, i) => {
            return (
              <div key={i}>
                {/* <p className="reldocumentHeader">{docs.name}</p> */}
                <ul className="reldocumentlist">
                  <li>{docs.log}</li>
                </ul>
              </div>
            );
          })
        ) : (
          <p className="m-0 text-left">No project updates available</p>
        )
      ) : (
        // <Button className="BtnBlue w-100">No Updates Available</Button>
        <Skeleton width="100%" height="150px"></Skeleton>
      )}
    </>
  );
};

export default OpportunityProjectUpdates;
