import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { RiCloseCircleLine, RiCheckboxCircleLine } from "react-icons/ri";

import DataTableComponent from "../../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../../components/loader/Loader";
import { GetAccessForNotificationThunk } from "../../../../store/features/accountatatement/rolemanagement/managesubscription/getaccessfornotification/slice";
import { GetDynamicUserListThunk } from "../../../../store/features/accountatatement/rolemanagement/managesubscription/getdynamicuserlist/slice";
import { GetNotificationListThunk } from "../../../../store/features/accountatatement/rolemanagement/managesubscription/getnotificationlist/slice";
import { DeleteUserRoleAccessThunk } from "../../../../store/features/accountatatement/rolemanagement/manageuseraccess/deleteroleaccess/slice";
import { GetUserListDetailsThunk } from "../../../../store/features/accountatatement/rolemanagement/manageuseraccess/getuserdetails/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { successToastMessage } from "../../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function ManageSubscription() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setloading] = useState(false);
  const GetUserList = useAppSelector(
    (state) => state.GetNotificationList?.data
  );
  const getUserList = useAppSelector((state) => state.GetDynamicUserList?.data);

  const [id, setId] = useState<any>();
  const [visible, setVisible] = useState(false);

  const profileData = useAppSelector((state) => state.profile.data![0]);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const param = {
    LegalEntityId: fundDropdown.fundid,
  };

  const formik = useFormik({
    initialValues: {
      user: "",
    },
    validate: (data: any) => {
      const errors: any = {};

      console.log(" validate", data);
      if (data.user === "") {
        errors.user = "Please select user";
      }

      return errors;
    },
    onSubmit: async (data: any) => {
      console.log(data);

      //   const reqParam = {
      //     legalEntityId: fundDropdown.fundid,
      //     userId: +formik.values.user,
      //     toRoleId: +formik.values.role,
      //     loginId: profileData.userID,
      //   };

      //   dispatch(SaveUserRoleMappingThunk(reqParam)).then(() => {
      //     dispatch(GetUserListDetailsThunk(param));
      //   });
    },
  });
  const reqBody = {
    LEID: fundDropdown.fundid,
    UserId: +formik.values.user,
  };

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const deleteButton = (rowData: any) => {
    console.log("rowwww", rowData);
    return (
      <div className="flex flex-column ">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              setId(rowData.userId);

              setVisible(true);
            }}
          >
            <span className="text-white">REMOVE ACCESS</span>
          </Button>
        </div>
      </div>
    );
  };
  const onDelete = () => {
    setVisible(false);
    setloading(true);
    const deleteParms = {
      UserId: id,
    };
    dispatch(DeleteUserRoleAccessThunk(deleteParms))
      .then(() => {
        dispatch(GetUserListDetailsThunk(param));
        successToastMessage(toast, "Access removed successfully");
      })
      .finally(() => {
        setloading(false);
      });
  };

  const editButton = (rowData: any) => {
    // Pass rowData as a parameter

    const handleEdit = () => {
      //   setCountryId(rowData.id);
      setloading(true);
      const revokData = {
        LEID: +fundDropdown.fundid,
        UserId: +formik.values.user,
        NotificationId: +rowData.notificationId,
        Type: rowData.userId ? "R" : "G",
        LoginId: profileData.userID,
      };

      dispatch(GetAccessForNotificationThunk(revokData))
        .then(() => {
          rowData.userId
            ? successToastMessage(toast, "Access revoked successfully")
            : successToastMessage(toast, "Access granted successfully");
          dispatch(GetNotificationListThunk(reqBody));
        })
        .finally(() => {
          setloading(false);
        });
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={handleEdit}
          >
            {rowData.userId ? (
              <span className="text-white">Revoke</span>
            ) : (
              <span className="text-white">Grant</span>
            )}
          </Button>
        </div>
      </div>
    );
  };

  const stepStatus = (rowData: any) => {
    return rowData.userId ? (
      // Code to execute if condition1 is true
      <RiCheckboxCircleLine className="icons-wrap" />
    ) : (
      // Code to execute if condition2 is true and condition1 is false
      <RiCloseCircleLine className="icons-wrap-cross" />
    );
  };

  const dataTableElements = [
    {
      field: "firstName",
      header: "Access",
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
    },
    {
      field: "lastName",
      header: "Last Name",
      body: stepStatus,
    },
    {
      field: "notificationType",
      header: "Notification Type",
    },
    {
      field: "roleName",
      header: "Role",
    },
    {
      field: "",
      header: "",
      body: (rowData: any) => deleteButton(rowData),
    },
  ];

  //   const resetForm = () => {
  //     setName("");
  //   };

  useEffect(() => {
    setloading(true);
    dispatch(GetDynamicUserListThunk(param)).finally(() => {
      setloading(false);
    });
  }, []);
  useEffect(() => {
    setloading(true);
    dispatch(GetNotificationListThunk(reqBody)).finally(() => {
      setloading(false);
    });
  }, [formik.values.user]);

  useEffect(() => {
    formik.setFieldValue("user", "");
  }, [fundDropdown.fundid]);

  return (
    <>
      {loading && <Loader />}
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Manage Subscriptions</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Are you sure you want to remove access
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-between">
            <Button
              onClick={onDelete}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>

        <div className="col-12">
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
              <label className="inputLabel">Select User *</label>
              <Dropdown
                name="user"
                style={{ width: "100%" }}
                options={getUserList}
                optionLabel="value"
                optionValue="key"
                placeholder="Select.."
                onChange={(e) => formik.setFieldValue("user", e.value)}
                value={formik.values.user}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("user"),
                  "card-inputBox": true,
                })}
              />
              {getFormErrorMessage("user")}
            </div>
          </div>

          {formik.values.user && (
            <div className="card my-3 tabWithoutboxshadow">
              <Card>
                <DataTableComponent
                  valueData={GetUserList!}
                  fieldsElements={dataTableElements}
                  isPaginationAllowed={true}
                />
              </Card>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
