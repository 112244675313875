import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
// import { Column } from "primereact/column";
// import { ColumnGroup } from "primereact/columngroup";
// import { Row } from "primereact/row";
// import { Skeleton } from "primereact/skeleton";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { getThunkPortfolioRegisterInvestorListDropdown } from "../../../store/features/dropdown/registeredinvestorlist/slice";
import {
  GetSummaryDetailsThunk,
  resetGetSummaryDetails,
} from "../../../store/features/taxmanagement/summaryreportbypartner/getsummarydetails/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { exportExcel } from "../../../utils/excel-dataformat";
import { convertExcelHeaders, transformedHeader } from "../../../utils/helper";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";

export default function SummaryReportbyPartner() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const previousYear: any = new Date().getFullYear();
  const currentYear = previousYear - 1;
  // const portState = useAppSelector((state) => state.GetMenuList.loading);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const list = useAppSelector((state) => state.GetSummaryDetails?.data2);
  const title = useAppSelector((state) => state.GetSummaryDetails?.data1);
  const data3 = useAppSelector((state) => state.GetSummaryDetails?.data3);

  console.log("prepareData list", list);
  console.log("prepareData title", title);
  console.log("prepareData data3", data3);
  const supplierCashInterest = useAppSelector(
    (state) => state.GetSummaryDetails.supplierCashIntrest
  );
  const investorList = useAppSelector((state) => state.registerinvestor?.data);

  const [dataCreateElemnts, setDataCreateElemnts] = useState<any>();
  const [dataTableElements, setdataTableElements] = useState<any>();
  const [exportExcelHeader, setExportExcelHeader] = useState<any>([]);
  const [stateName, setName] = useState<any>(false);
  const [loading, setloading] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);

  const datatableArrayObj: any = [];

  console.log("Dupllicate dataCreateElemnts", dataCreateElemnts);
  console.log("Dupllicate dataTableElements", dataTableElements);

  const titleParse = list;
  console.log("-----==---", titleParse);
  // const newData: any[] = []; // Declare an array to hold the new data

  const prepareData = (header: any, val: any, item: any, li: any) => {
    const values = val;
    const header2 = item;
    const header1 = li;
    console.log("prepareData values", values);
    console.log("prepareData header2", header2);
    console.log("prepareData header1", header1);
    console.log("header", header);
    if (values.length > 0 && header2.length > 0 && header1.length > 0) {
      console.log("values", values);
      // for (const valueObj of values) {
      //   const obj: any = {};
      //   console.log("valueObj", valueObj);
      //   // const itemsKey = Object.keys(valueObj);
      //   // for (const key in valueObj) {
      //   //   console.log("values", key);

      //   //   if (itemsKey.includes(key)) {
      //   //     // obj[items[key]?.InvName] = valueObj[key];
      //   //   }
      //   // }

      //   // datatableArrayObj.push(obj);
      // }

      return values;
    } else {
      return [];
    }
  };
  console.log("datatableArrayObj", datatableArrayObj);

  // const createcolumns = (data: any) => {
  //   console.log("data====", data); // Logging the prepared data for debugging

  //   return data.length > 0
  //     ? Object.keys(data[0]).map((key: string) => ({
  //         field: key,
  //         header: key,
  //       }))
  //     : [];
  // };
  const createcolumns = (val: any, item: any, li: any) => {
    const values = val;
    const header2 = item;
    const header1 = li;

    if (values.length > 0 && header2.length > 0 && header1.length > 0) {
      console.log("createcolumns data3 values", values);
      console.log("createcolumns title header2", header2);
      console.log("createcolumns list header1", header1);

      const header1Keys = Object.keys(header1[0]).slice(3);

      // const val1Keys: any = Object.values(values);

      // header1Keys.slice(0, 4);

      // for (const obj of val1Keys) {
      //   delete obj.Id; // Assuming the property name is "ID"

      //   // console.log("createcolumns values", obj);
      // }
      // console.log("header1Keys==", header1Keys);
      const header2Keys = header2.flatMap((obj: any) => Object.values(obj));
      // console.log("val1Keys==", val1Keys);

      const duplicateHeader = [...header1Keys, ...header2Keys];

      const headers = [
        "LableName",
        "ChildName",
        "Totals",
        // "LP Level",
        "LPLevelVal",
        ...new Set(duplicateHeader),
      ];

      console.log("headers--abc", headers);

      // const allData = headers.map((key: any) => ({
      //   field: key,
      //   header: transformedHeader(key),
      //   style: { minWidth: "280px" },
      // }));
      // nope should be Box Name and Code
      const allData = headers.map((key: any) => {
        // Special handling for specific headers
        if (key === "LableName") {
          // Custom logic for LableName
          return {
            field: key,
            header: "Box Name",
            style: { minWidth: "280px" },
          };
        } else if (key === "ChildName") {
          // Custom logic for ChildName
          return {
            field: key,
            header: "Code",
            style: { minWidth: "280px" },
          };
        } else {
          // Default handling for other headers
          return {
            field: key,
            header: transformedHeader(key),
            style: { minWidth: "280px" },
          };
        }
      });

      // const
      console.log("Duplicate allData", allData, header2Keys, header1Keys);
      return allData;
    }
    return [];
  };

  useEffect(() => {
    if (data3?.length && title?.length && list?.length) {
      const header = createcolumns(data3, title, list);
      // console.log(
      //   "header tesing",
      //   header,
      //   convertExcelHeaders(header, "currency", ["LableName", "ChildName"])
      // );
      setDataCreateElemnts(header);
      setExportExcelHeader(
        convertExcelHeaders(header, "currency", ["LableName", "ChildName"])
      );
      setdataTableElements(prepareData(header, data3, title, list));

      // console.log("header tesing", prepareData(header, data3, title, list));
      // setDataCreateElemnts(createcolumns(data3, title, list));
    }
  }, [data3, title, list]);

  const formik = useFormik({
    initialValues: {
      Date: currentYear.toString(),
      Partner: "",
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter year";
      }
      if (data.Partner === "") {
        errors.Partner = "Please select partner name";
      }

      return errors;
    },
    onSubmit: async () => {
      setloading(true);

      const params = {
        LegalEntityId: fundDropdown.fundid,
        TaxYear: formik.values.Date,
        LPPartnerId: +formik.values.Partner,
      };
      //  const pdata = prepareData(datatableArrayObj)

      dispatch(GetSummaryDetailsThunk(params))
        .then(() => {
          setName(false);
          setloading(false);

          // const col = createcolumns(list);
        })
        .catch(() => {
          setloading(false);
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    setName(true);
    dispatch(resetGetSummaryDetails());
    setDataCreateElemnts([]);
    setdataTableElements([]);
  }, [formik.values.Date, formik.values.Partner]);

  useEffect(() => {
    if (fundDropdown.fundid) {
      dispatch(resetGetSummaryDetails());
      setDataCreateElemnts([]);
      setdataTableElements([]);
      const req = {
        LegalEntityId: fundDropdown.fundid,
      };
      console.log("info");
      dispatch(getThunkPortfolioRegisterInvestorListDropdown(req));
      setName(true);

      formik.resetForm();
    }
  }, [fundDropdown.fundid]);

  // useEffect(() => {
  //   document.body.classList.add("no-scroll");
  //   document.body.style.overflowX = "unset";
  //   document.documentElement.style.overflowX = "unset";
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //     document.body.style.overflowX = "hidden";
  //     document.documentElement.style.overflowX = "hidden";
  //   };
  // }, []);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">
              K-1: Part 3 Summary Report by Partner
            </h1>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              onClick={() =>
                exportExcel(
                  exportExcelHeader,
                  dataTableElements,
                  "K-1: Part 3 Summary Report by Partner",
                  true,
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                dataTableElements === undefined ||
                dataTableElements?.length === 0 ||
                dataTableElements === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  dataTableElements === undefined ||
                  dataTableElements?.length === 0 ||
                  dataTableElements === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <>
              {" "}
              <div>
                <div className="formgrid grid  col">
                  <div className="d-block sm:d-flex sm:flex-column md:col-6 col-12">
                    {/* <label className="inputLabel">Year *</label> */}
                    <div className="card flex justify-content-center">
                      <Calendar
                        name="Date"
                        value={
                          formik.values.Date === null ||
                          formik.values.Date === ""
                            ? undefined
                            : new Date(+formik.values.Date, 0, 1)
                        }
                        onChange={(e) => {
                          const selectedYear = new Date(
                            Number(e.value)
                          ).getFullYear();
                          formik.setFieldValue("Date", selectedYear);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Date"),
                          "card-inputBox": true,
                        })}
                        placeholder="Select year"
                        view="year"
                        dateFormat="yy"
                      />
                      {getFormErrorMessage("Date")}
                    </div>
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column md:col-6 col-12">
                    {/* <label className="inputLabel">Partner Name *</label> */}
                    <Dropdown
                      name="Partner"
                      style={{ width: "100%" }}
                      options={investorList}
                      optionLabel="investorName"
                      filter
                      optionValue="investorID"
                      placeholder="Select Partner Name"
                      onChange={(e) => formik.setFieldValue("Partner", e.value)}
                      value={formik.values.Partner}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("Partner"),
                        "card-inputBox": true,
                      })}
                    />
                    {getFormErrorMessage("Partner")}
                  </div>
                </div>
                <div className="d-flex formgrid grid col justify-content-between">
                  <div className="d-block sm:d-flex sm:flex-column md:col-12 col-6">
                    <label className="inputLabel">
                      Total Amount for GP includes supplier cash interest of{" "}
                      {supplierCashInterest !== 0 ? supplierCashInterest : ""}
                    </label>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="mr-4 btn-nav w-7rem justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        // setIsFormSubmitted(true);
                        // console.log("clicked");
                        dispatch(resetGetSummaryDetails());
                        formik.resetForm();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      className="btn-nav w-7rem justify-content-center"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        // setIsFormSubmitted(true);
                        // console.log("clicked");

                        formik.handleSubmit();
                      }}
                    >
                      Show
                    </Button>
                  </div>
                </div>
              </div>
            </>{" "}
            <div className="mt-2">
              {dataTableElements &&
                !stateName &&
                titleParse &&
                data3 !== undefined && (
                  <DataTableComponent
                    valueData={dataTableElements || []}
                    fieldsElements={dataCreateElemnts}
                    // footerElement={footerGroup}
                    // isDownload={true}
                    isPaginationAllowed={true}
                    fileName="SummaryReportByPartner"
                    scrollable={true}
                    className="gridcell"
                    columnResizeMode="expand"
                    maxHeight={"calc(-250px + 100vh)"}
                    isFullView={true}
                    setOpenDailog={setOpenDailog}
                    openDailog={openDailog}
                  />
                )}
              {!stateName && (!data3 || titleParse!.length === 0) && (
                <DataTableComponent
                  valueData={[]}
                  fieldsElements={dataCreateElemnts}
                  // footerElement={footerGroup}
                  // isDownload={true}
                  isPaginationAllowed={true}
                  fileName="SummaryReportByPartner"
                  scrollable={true}
                  className="gridcell"
                  columnResizeMode="expand"
                  maxHeight={"calc(-250px + 100vh)"}
                  isFullView={true}
                  setOpenDailog={setOpenDailog}
                  openDailog={openDailog}
                />
              )}
            </div>
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
