import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getEarningsErrataReportCommits } from "../../../../store/features/reports/earnings-errata-report/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  // dateFormatter,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";

export default function EarningsErrataReport() {
  const [EarningsErrataData, setEarningsErrataData] = useState<any>();
  const [earningsErrataReturnAmountata, setEarningsErrataReturnAmountData] =
    useState<any>();
  // const [earningReport, setEarningReport] = useState("");
  const dispatch = useAppDispatch();

  const EarningsErrata = useAppSelector(
    (state) => state.earningsErrataReducer.data
  );
  const EarningsErrataReturnAmount = useAppSelector(
    (state) => state.earningsErrataReducer.TotalAmountdata
  );

  const [loading, setloading] = useState<any>(false);
  const [tab, setTab] = useState("distribution");
  const [openDailog, setOpenDailog] = useState(false);

  console.log("EarningsErrataReturnAmount", EarningsErrataReturnAmount);
  console.log("EarningsErrata", EarningsErrata);

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  useEffect(() => {
    console.log("Data Empty in Component", EarningsErrataData);
    setEarningsErrataData([]);
    setEarningsErrataReturnAmountData([]);
    setloading(true);
    if (dropdown.fundid !== undefined && dropdown.fundid > 0) {
      const parameters = {
        legalEntityId: dropdown.fundid,
      };
      dispatch(getEarningsErrataReportCommits(parameters))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    } else {
      setloading(false);
    }
  }, [dropdown.fundid]);

  useEffect(() => {
    setEarningsErrataData(
      getListDateConversion(EarningsErrata, [
        "returnDate",
        "partnerDistributionDate",
      ])
    );
    setEarningsErrataReturnAmountData(
      getListDateConversion(EarningsErrataReturnAmount, [
        "returnDate",
        "partnerDistributionDate",
      ])
    );
  }, [EarningsErrata]);

  const dataTableElements = [
    {
      field: "investmentID",
      header: "Investment Id",
      style: { minWidth: "180px" },
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
      style: { minWidth: "200px" },
    },
    {
      field: "productName",
      header: "Investment Name",
      style: { minWidth: "300px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.productName}>
          {rowData?.productName}
        </div>
      ),
    },
    {
      field: "investmentReturnID",
      header: "Investment Return Id",
      style: { minWidth: "200px" },
    },
    {
      field: "investmentReturnType",
      header: "Investment Return Type",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.investmentReturnType}>
          {rowData?.investmentReturnType}
        </div>
      ),
    },
    {
      field: "returnDate",
      header: "Return Date",
      dataType: "date",
      body: (rowData: any) => formatDateMMDDYYY(rowData?.returnDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "200px" },
    },
    {
      field: "amount",
      header: "Amount",
      body: (rowData: any) => currencyFormat(rowData.amount),
      style: { minWidth: "200px" },
    },
    {
      field: "tot",
      header: "Distributed",
      body: (rowData: any) => currencyFormat(rowData.tot),
      style: { minWidth: "200px" },
    },
    {
      field: "partnerDistributionDate",
      header: "Partner Distribution Date",
      dataType: "date",
      body: (rowData: any) =>
        formatDateMMDDYYY(rowData?.partnerDistributionDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "200px" },
    },
  ];
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      <div className="grid">
        {loading && <Loader />}
        <div className="col-12 flex p-0">
          <div className="card col-11 p-0 pgHeaderWrap ">
            <h1 className="pageHeader fs-2">Earnings Errata-Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className="col-1 p-0 align-items-center flex justify-content-end">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                tab === "distribution"
                  ? earningsErrataReturnAmountata === undefined ||
                    earningsErrataReturnAmountata?.length === 0 ||
                    earningsErrataReturnAmountata === null
                  : tab === "partner"
                  ? EarningsErrataData === undefined ||
                    EarningsErrataData?.length === 0 ||
                    EarningsErrataData === null
                  : true
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>

        {/* <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Earnings Errata-Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div> */}
      </div>
      {/* <div className="grid">
        <div className="col-12"> */}
      <div className="tabHeader-wrap">
        <Button
          className={
            tab === "distribution" ? "tabHeadText Active" : "tabHeadText "
          }
          onClick={(e) => {
            e.preventDefault();
            // setTranscationTab("allTransactionReport");
            setTab("distribution");
          }}
        >
          <span className="ml-auto mr-auto ">Distribution Discrepancies</span>
        </Button>
        <Button
          className={tab === "partner" ? "tabHeadText Active" : "tabHeadText "}
          onClick={(e) => {
            e.preventDefault();
            // setTranscationTab("capitalContributionsReport");
            setTab("partner");
          }}
        >
          <span className="ml-auto mr-auto ">
            Partner Account Discrepancies
          </span>
        </Button>
      </div>
      <div className="card p-2 mt-2">
        {tab === "distribution" ? (
          <>
            <h3>
              Return Amount and Total Amount distributed are different for below
              transactions
            </h3>
            {EarningsErrataReturnAmount?.length > 0 ? (
              <DataTableComponent
                valueData={earningsErrataReturnAmountata}
                fieldsElements={dataTableElements}
                scrollable={true}
                columnResizeMode="expand"
                openDailog={openDailog}
                setOpenDailog={setOpenDailog}
                // isFullView={true}
                maxHeight={"calc(-212px + 100vh)"}
                className="gridcell"
              />
            ) : (
              <h4 className="text-center">No records found</h4>
            )}
          </>
        ) : tab === "partner" ? (
          <>
            <h3>
              Returns marked as distributed but partner account was not debited
              or credited
            </h3>
            <DataTableComponent
              valueData={EarningsErrataData}
              fieldsElements={dataTableElements}
              isPaginationAllowed={true}
              scrollable={true}
              openDailog={openDailog}
              setOpenDailog={setOpenDailog}
              columnResizeMode="expand"
              maxHeight={"calc(-212px + 100vh)"}
              className="gridcell"
            />
          </>
        ) : (
          <></>
        )}
        {/* </Card> */}
      </div>
      {/* </div>
      </div> */}
    </>
  );
}
