import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

// import { Skeleton } from "primereact/skeleton";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { GetPartnerCapitalAccCalThunk } from "../../../store/features/taxmanagement/getpartnercapitalacccal/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import currencyFormat from "../../../utils/currency-formatter";
import { exportExcel } from "../../../utils/excel-dataformat";
// import dateFormatter from "../../../utils/date-formatter";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";
export default function PartnerCapitalAccCalculation() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const previousYear: any = new Date().getFullYear();
  const currentYear = previousYear - 1;
  const getRevision = useAppSelector((state) => state.GetRevisionList.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [openDailog, setOpenDailog] = useState(false);

  const productList = useAppSelector((state) => state.GetProdductList?.data);

  console.log("getRevision", getRevision);
  console.log("productList", productList);

  const GetMenuList = useAppSelector(
    (state) => state.GetPartnerCapitalAccCal?.data
  );
  const portState = useAppSelector(
    (state) => state.GetPartnerCapitalAccCal.loading
  );

  const [stateName, setName] = useState<any>(false);
  const [loading, setloading] = useState<any>(false);

  const formik = useFormik({
    initialValues: {
      Date: currentYear.toString(),
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter year";
      }

      return errors;
    },
    onSubmit: async () => {
      setloading(true);

      const params = {
        LegalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };
      dispatch(GetPartnerCapitalAccCalThunk(params))
        .then((res: any) => {
          setName(false);
          setloading(false);

          // setdataTableElements(prepareData(exitDate));
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    },
  });
  useEffect(() => {
    if (
      formik.values.Date.toString() === currentYear.toString() &&
      fundDropdown.fundid
    ) {
      setloading(true);

      const params = {
        LegalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };
      dispatch(GetPartnerCapitalAccCalThunk(params))
        .then((res: any) => {
          setName(false);
          setloading(false);

          // setdataTableElements(prepareData(exitDate));
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    } else {
      setName(true);
    }
  }, [formik.values.Date, fundDropdown.fundid]);
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const dataTableElements = [
    {
      field: "partnerName",
      header: "Investor Account",
      style: { minWidth: "280px" },
    },
    {
      field: "bcb",
      header: "Beginning Capital Account",
      body: (rowData: any) => currencyFormat(rowData.bcb),
      style: { minWidth: "280px" },

      // rowData.bcb && Number.parseFloat(rowData.bcb) < 0
      //   ? "-$" + Math.abs(rowData.bcb).toFixed(2)
      //   : "$" + rowData.bcb.toFixed(2),
    },

    {
      field: "cccy",
      header: "Capital Contributed during the year",
      body: (rowData: any) => currencyFormat(rowData.cccy),
      style: { minWidth: "280px" },

      // rowData.cccy && Number.parseFloat(rowData.cccy) < 0
      //   ? "-$" + Math.abs(rowData.cccy).toFixed(2)
      //   : "$" + rowData.cccy.toFixed(2),
    },

    {
      field: "cyi",
      header: "Current Year Increase (decrease)",
      body: (rowData: any) => currencyFormat(rowData.cyi),
      style: { minWidth: "280px" },

      // rowData.cyi && Number.parseFloat(rowData.cyi) < 0
      //   ? "-$" + Math.abs(rowData.cyi).toFixed(2)
      //   : "$" + rowData.cyi.toFixed(2),
    },

    {
      field: "wd",
      header: "Withdrawal & Distribution",
      body: (rowData: any) => currencyFormat(rowData.wd),
      style: { minWidth: "280px" },

      // rowData.wd && Number.parseFloat(rowData.wd) < 0
      //   ? "-$" + Math.abs(rowData.wd).toFixed(2)
      //   : "$" + rowData.wd.toFixed(2),
    },

    {
      field: "eca",
      header: "Ending Capital Account",
      body: (rowData: any) => currencyFormat(rowData.eca),
      style: { minWidth: "280px" },

      // rowData.eca && Number.parseFloat(rowData.eca) < 0
      //   ? "-$" + Math.abs(rowData.eca).toFixed(2)
      //   : "$" + rowData.eca.toFixed(2),
    },
    {
      field: "boX12Amount",
      header: "Box 12 Amount",
      body: (rowData: any) => currencyFormat(rowData.boX12Amount),
      style: { minWidth: "280px" },
    },
    {
      field: "boX13Amount",
      header: "Box 13 Amount",
      body: (rowData: any) => currencyFormat(rowData.boX13Amount),
      style: { minWidth: "280px" },
    },
    {
      field: "boX18Amount",
      header: "Box 18 Amount",
      body: (rowData: any) => currencyFormat(rowData.boX18Amount),
      style: { minWidth: "280px" },
    },
    {
      field: "boX18CAmount",
      header: "Box 18C Amount",
      body: (rowData: any) => currencyFormat(rowData.boX18CAmount),
      style: { minWidth: "280px" },
    },
    {
      field: "capitalLoss",
      header: "Capital Loss",
      body: (rowData: any) => currencyFormat(rowData.capitalLoss),
      style: { minWidth: "280px" },
    },
    {
      field: "partnershipExpenses",
      header: "Partnership Expenses",
      body: (rowData: any) => currencyFormat(rowData.partnershipExpenses),
      style: { minWidth: "280px" },
    },
    {
      field: "managerFee",
      header: "Manager Fee",
      body: (rowData: any) => currencyFormat(rowData.managerFee),
      style: { minWidth: "280px" },
    },
  ];

  const excelTableHeaders = [
    {
      field: "partnerName",
      header: "Investor Account",
    },
    {
      field: "bcb",
      header: "Beginning Capital Account",
      dataType: "currency",
    },

    {
      field: "cccy",
      header: "Capital Contributed during the year",
      dataType: "currency",
    },

    {
      field: "cyi",
      header: "Current Year Increase (decrease)",
      dataType: "currency",
    },

    {
      field: "wd",
      header: "Withdrawal & Distribution",
      dataType: "currency",
    },

    {
      field: "eca",
      header: "Ending Capital Account",
      dataType: "currency",
    },
    {
      field: "boX12Amount",
      header: "Box 12 Amount",
      dataType: "currency",
    },
    {
      field: "boX13Amount",
      header: "Box 13 Amount",
      dataType: "currency",
    },
    {
      field: "boX18Amount",
      header: "Box 18 Amount",
      dataType: "currency",
    },
    {
      field: "boX18CAAmount",
      header: "Box 18C Amount",
      dataType: "currency",
    },
    {
      field: "capitalLoss",
      header: "Capital Loss",
      dataType: "currency",
    },
    {
      field: "partnershipExpenses",
      header: "Partnership Expenses",
      dataType: "currency",
    },
    {
      field: "managerFee",
      header: "Manager Fee",
      dataType: "currency",
    },
  ];

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  useEffect(() => {
    formik.resetForm();
  }, [fundDropdown.fundid]);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">
              K-1: Part 2 Partners Capital Accounts
            </h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(
                  excelTableHeaders,
                  GetMenuList!,
                  "K-1: Part 2 Partners Capital Accounts",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                GetMenuList === undefined ||
                GetMenuList?.length === 0 ||
                GetMenuList === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  GetMenuList === undefined ||
                  GetMenuList?.length === 0 ||
                  GetMenuList === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* {/ <Card> /} */}
            <>
              <div>
                <div className="formgrid grid px-2 col">
                  <div className="d-block sm:d-flex sm:flex-column mr-3 md:col-6 col-12">
                    {/* {/ <label className="inputLabel">Year </label> */}
                    <div className="card flex justify-content-center">
                      <Calendar
                        name="Date"
                        value={
                          formik.values.Date === null ||
                          formik.values.Date === ""
                            ? undefined
                            : new Date(+formik.values.Date, 0, 1)
                        }
                        onChange={(e) => {
                          const selectedYear = new Date(
                            Number(e.value)
                          ).getFullYear();
                          formik.setFieldValue("Date", selectedYear);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Date"),
                          "card-inputBox": true,
                        })}
                        placeholder="Select year"
                        view="year"
                        dateFormat="yy"
                      />
                      {getFormErrorMessage("Date")}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="mr-4 btn-nav w-7rem justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.resetForm();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      className="btn-nav w-7rem justify-content-center"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        console.log("clicked");
                        formik.handleSubmit();
                      }}
                    >
                      Calculate
                    </Button>
                  </div>
                </div>
              </div>
            </>{" "}
            {GetMenuList && !stateName && (
              <div>
                {portState === "loading" ? (
                  <Skeleton />
                ) : (
                  <DataTableComponent
                    valueData={GetMenuList!}
                    fieldsElements={dataTableElements}
                    isPaginationAllowed={true}
                    // isDownload={true}
                    fileName="Partners Capital Account Calculation"
                    scrollable={true}
                    className="gridcell"
                    columnResizeMode="expand"
                    maxHeight={"calc(-250px + 100vh)"}
                    isFullView={true}
                    setOpenDailog={setOpenDailog}
                    openDailog={openDailog}
                  />
                )}
              </div>
            )}
            {/* {/ </Card> /} */}
          </div>
        </div>
      </div>
    </>
  );
}
