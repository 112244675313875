import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RiAddFill, RiDeleteBin2Line } from "react-icons/ri";
import ReactCrop, { PixelCrop } from "react-image-crop";
import { useNavigate } from "react-router-dom";
import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { IUploadFileData } from "../../../../store/features/investment/upload-file";
import {
  getThunkInvestmentFilebyProductid,
  uploadThunkInvestmentFile,
  deleteThunkInvestmentFile,
  bulkUpdateindexThunkInvestmentFile,
  setPrimaryInvestmentFile,
} from "../../../../store/features/investment/upload-file/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";
import "react-image-crop/src/ReactCrop.scss";
import "react-image-crop/dist/ReactCrop.css";

interface IProps {
  pageName: string;
  handlePrevious?: any;
  handleNext?: any;
  offering?: any;
}
export const FormImages = (props: IProps) => {
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const fileUploadRef = useRef<any>(null);
  const stateInvestment = useAppSelector((state) => state.addInvestment);
  const updatefile = useAppSelector((state) => state.uplodedFiles.data);
  const loading = useAppSelector((state) => state.uplodedFiles.loading);
  const [firstImage, setFirstImage] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [file, setFile] = useState<IUploadFileData[]>();
  const [primaydata, setprimaydata] = useState<IUploadFileData | undefined>();
  const [primary] = useState<string>("00000");
  const [docLink, setDocLink] = useState("");
  const [productImgId, setProductImgId] = useState(0);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const [imagesFile, setImagesFile] = useState<any | undefined>();
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>();
  const imgRef = useRef<any>(null);
  const [crop, setCrop] = useState<any>({
    unit: "px",
    x: 0,
    y: 0,
    height: 150,
    width: 150,
  });
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>({
    unit: "px",
    x: 0,
    y: 0,
    height: 150,
    width: 150,
  });
  useEffect(() => {
    console.log("file", updatefile);
    if (updatefile !== undefined) {
      const newFile = updatefile?.filter(
        (image) => image.docTypeId === 20 || image.docTypeId === 41
      );
      const primaryImgData = updatefile?.filter(
        (image) => image.docTypeId === 21
      );
      setprimaydata(primaryImgData[0]);
      setFile(newFile);
    }

    if (updatefile.length === 0) {
      setFirstImage(true);
    } else {
      setFirstImage(false);
    }
  }, [updatefile, stateInvestment]);
  useEffect(() => {
    if (stateInvestment.data !== undefined) {
      const param = {
        ProductId: stateInvestment.data?.productID,
        IsDoc: false,
      };
      dispatch(getThunkInvestmentFilebyProductid(param));
    }
  }, [stateInvestment]);

  const setCanvasImage = async (
    imageobj: any,
    cropObj: any,
    canvasobj?: any
  ) => {
    console.log("inside canvas");
    console.log("image", imageobj);
    const image = new window.Image();
    image.src = docLink;
    const canvas = document.createElement("canvas");
    const scaleX = imageobj.target.naturalWidth / imageobj.target.width;
    const scaleY = imageobj.target.naturalHeight / imageobj.target.height;
    const ctx = canvas.getContext("2d");
    canvas.width = cropObj.width;
    canvas.height = cropObj.height;
    if (!ctx) {
      throw new Error("Failed to get canvas context");
    }

    console.log("testing", scaleX);
    console.log("testing", scaleY);
    console.log("testing", imageobj.target.width);
    console.log("testing", imageobj.target.height);
    console.log("testing", imageobj.target.naturalWidth);
    console.log("testing", imageobj.target.naturalHeight);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      cropObj.x * scaleX,
      cropObj.y * scaleY,
      cropObj.width * scaleX,
      cropObj.height * scaleY,
      0,
      0,
      cropObj.width,
      cropObj.height
    );
    return new Promise<string>((resolve) => {
      canvas.toBlob((blob) => {
        console.log("mk", blob);
        if (!blob) {
          throw new Error("Failed to get the cropped image blob");
        }

        const reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onloadend = () => {
          const base64data = reader.result as string;
          resolve(base64data);
        };
      }, "image/png");
    });
  };

  function useDebounceEffect(
    fn: (...args: any[]) => void,
    waitTime: number,
    deps: any[]
  ) {
    useEffect(() => {
      const t = setTimeout(() => {
        fn.apply(undefined, deps);
      }, waitTime);

      return () => {
        clearTimeout(t);
      };
    }, deps);
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        console.log("ues");
        const image = await setCanvasImage(imgRef.current, completedCrop);
        console.log("image", image);
        setCroppedImage(image);
      }
    },
    100,
    [completedCrop, docLink]
  );

  const handleFileUpload = async (uploadedfile: any) => {
    // Handle file upload logic here
    console.log("filetype", uploadedfile);
    const data = new FormData();
    console.log("uploadedfile", firstImage);
    if (uploadedfile.type.includes("video")) {
      data.append("documentTypeId", "41");

      data.append("files", uploadedfile, uploadedfile.name);
    } else {
      if (firstImage === true) {
        data.append("documentTypeId", "21");
      } else {
        data.append("documentTypeId", "20");
      }
      const blob = await fetch(croppedImage!).then((res) => res.blob());
      // const blob = "";
      // Append the Blob to the FormData
      data.append("files", blob, uploadedfile.name);
    }

    // data.append("files", uploadedfile === undefined ? " " : uploadedfile);
    data.append("mediaType", "file");
    data.append("doNotCompress", "false");
    data.append("prodImgId", "0");
    data.append("docDesc", "Product Img");
    data.append("docExtension", " ");
    data.append("documentSize", "0");
    data.append("createdBy", profileData?.userID?.toString());
    data.append("imageIndex", "0");
    data.append("documentLink", " ");
    data.append("documentName", "");
    data.append("isDoc", "false");
    data.append("contentType", uploadedfile.type);
    if (stateInvestment.data !== undefined) {
      data.append(
        "legalEntityID",
        stateInvestment.data.legalEntityID.toString()
      );
      data.append("productId", stateInvestment.data.productID.toString());
    }

    try {
      await dispatch(uploadThunkInvestmentFile(data)).then((response) => {
        if (response.meta.requestStatus === "rejected") {
          errorToastMessage(toast);
        } else if (response.meta.requestStatus === "fulfilled") {
          successToastMessage(toast, "File Uploaded");
          if (fileUploadRef.current) {
            fileUploadRef.current.value = ""; // This clears the selected file from the input
          }
          setImagesFile("");
          setOpenDialog(false);
          setProductImgId(0);
          setCrop({ unit: "px", x: 0, y: 0, height: 150, width: 150 });
          setCompletedCrop({ unit: "px", x: 0, y: 0, height: 150, width: 150 });
        }
      });
    } catch (error) {
      console.log("Opps something went wrong", error);
    }
  };

  const uploadFile = async (filess?: any) => {
    const reader = new FileReader();
    if (imagesFile && imagesFile !== undefined && imagesFile !== null) {
      // addEventListener
      reader.addEventListener("load", () => {
        handleFileUpload(imagesFile);
      });
      reader.readAsDataURL(imagesFile);
    } else {
      reader.addEventListener("load", () => {
        handleFileUpload(filess);
      });
      reader.readAsDataURL(filess);
    }
  };

  console.log("df", crop);
  const handleCancel = () => {
    setOpenDialog(false);
    setCrop({ unit: "px", x: 0, y: 0, height: 150, width: 150 });
    setCompletedCrop({ unit: "px", x: 0, y: 0, height: 150, width: 150 });
    // eslint-disable-next-line unicorn/no-null
    setCroppedImage(null);
    if (fileUploadRef.current) {
      fileUploadRef.current.value = ""; // This clears the selected file from the input
    }
  };
  // crop Images functionality
  const cropImages = (e: any) => {
    const filess = e.target.files[0];
    console.log("aser", e.target.files[0]);
    const fileSize = filess?.size / 1024 / 1024;
    console.log(
      "FileType",
      filess.type.toLowerCase().includes("video"),
      fileSize,
      fileSize > 100
    );
    if (filess.type.toLowerCase().includes("video") && fileSize > 100) {
      errorToastMessage(
        toast,
        "Upload failed. Please ensure the Video file size does not exceed 100mb"
      );
      setImagesFile("");
      return;
    } else {
      if (!filess.type.toLowerCase().includes("video") && fileSize > 10) {
        errorToastMessage(
          toast,
          "Upload failed. Please ensure the Image file size does not exceed 10mb"
        );
        fileUploadRef.current.value = "";
        setImagesFile("");
        return;
      }
    }
    if (filess !== undefined) {
      if (!filess.type.includes("video") && !filess.type.includes("image")) {
        errorToastMessage(
          toast,
          "Upload failed. Please Use Images or video format only"
        );
        fileUploadRef.current.value = "";
        setImagesFile("");
        return;
      }
      setImagesFile(e.target.files[0]);
      if (e.target.files && e.target.files[0]) {
        setDocLink(URL.createObjectURL(e.target.files[0]));
      }
      if (!filess.type.includes("video")) {
        setOpenDialog(true);
      } else {
        setImagesFile("");
        uploadFile(filess);
      }
    }
  };

  // crop cordinates setting

  const handleDelete = (id?: any) => {
    setOpenDialogDelete(false);
    const param = {
      prodImgId: productImgId,
      isDoc: false,
    };
    dispatch(deleteThunkInvestmentFile(param)).then((response) => {
      // console.log("res", response);
      if (response.meta.requestStatus === "rejected") {
        errorToastMessage(toast);
      } else if (response.meta.requestStatus === "fulfilled") {
        if (response.payload.success === false) {
          errorToastMessage(toast, "File not Deleted");
        } else {
          successToastMessage(toast, "File deleted");
          setProductImgId(0);
        }
      }
    });
  };

  const handleClick = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.dispatchEvent(new MouseEvent("click"));
    }
  };

  const handleDragStart = (e: any, id: any) => {
    e.dataTransfer.setData("imageId", id);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleTouchStart = (e: any, id: any) => {
    const touch = e.touches[0];
    e.dataTransfer.setData("imageId", id);
    e.dataTransfer.setData("clientX", touch.clientX);
    e.dataTransfer.setData("clientY", touch.clientY);
    console.log("start");
  };

  const handleTouchMove = (e: any) => {
    e.preventDefault();
    console.log("move");
  };

  const updateAllIndex = (data: any) => {
    try {
      dispatch(bulkUpdateindexThunkInvestmentFile(data)).then((response) => {
        if (response.meta.requestStatus === "rejected") {
          errorToastMessage(toast);
        } else if (response.meta.requestStatus === "fulfilled") {
          successToastMessage(toast, "File Uploaded");
        }
      });
    } catch (error) {
      console.log("Opps something went wrong", error);
    }
  };
  const updateFileIndex = () => {
    const afterrearrangefile = JSON.parse(JSON.stringify(file));

    if (file !== undefined) {
      for (let i = 0; i < file?.length; ++i) {
        afterrearrangefile[i]!.imageIndex = i + 1;
      }
    }
    if (afterrearrangefile) {
      updateAllIndex(afterrearrangefile);
    }
  };

  const updateindatabase = (id: any, isPrimary: boolean) => {
    const paramerts = {
      id,
      isPrimary,
    };
    dispatch(setPrimaryInvestmentFile(paramerts));
  };

  const handleTouchEnd = (e: any) => {
    console.log("Touch end");
    const touch = e.changedTouches[0];
    const clientX = e.dataTransfer.getData("clientX");
    const clientY = e.dataTransfer.getData("clientY");
    console.log("ClientX:", clientX);
    console.log("ClientY:", clientY);
    const deltaX = touch.clientX - clientX;
    const deltaY = touch.clientY - clientY;

    if (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5) {
      // Handle touch drag here
      const imageId = e.dataTransfer.getData("imageId");
      const draggedImage = file?.find(
        (image) => image.prodImgId.toString() === imageId
      );

      const dropzoneId = e.currentTarget.dataset.id;

      if (draggedImage?.docTypeId === 41 && dropzoneId === primary) {
        return;
      }
      if (imageId === primary) {
        if (primaydata === undefined) {
          return;
        }
        const dropzoneIndexnew = file?.findIndex(
          (image) => image.prodImgId.toString() === dropzoneId
        );
        const newFile = file?.filter(
          (image) => image.prodImgId !== primaydata?.prodImgId
        );
        newFile?.splice(dropzoneIndexnew!, 0, primaydata!);

        setFile(newFile);
        // eslint-disable-next-line unicorn/no-useless-undefined
        setprimaydata(undefined);
        updateindatabase(primaydata?.prodImgId, false);
        return;
      }

      if (dropzoneId === primary) {
        if (primaydata !== undefined) {
          updateindatabase(primaydata.prodImgId, false);
        }
        updateindatabase(imageId, true);

        const primaryIndex = file?.findIndex(
          (image) => image.prodImgId.toString() === imageId
        );
        const newFile = file?.filter(
          (image) => image.prodImgId.toString() !== imageId
        );

        if (primaydata !== undefined) {
          newFile?.splice(primaryIndex!, 0, primaydata!);
        }
        newFile?.slice(primaryIndex);
        setprimaydata(draggedImage);
        setFile(newFile);
        return;
      }
      const dropzoneIndex = file?.findIndex(
        (image) => image.prodImgId.toString() === dropzoneId
      );
      const newFile = file?.filter(
        (image) => image.prodImgId.toString() !== imageId
      );
      newFile?.splice(dropzoneIndex!, 0, draggedImage!);
      setFile(newFile);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    const imageId = e.dataTransfer.getData("imageId");

    const draggedImage = file?.find(
      (image) => image.prodImgId.toString() === imageId
    );

    const dropzoneId = e.currentTarget.dataset.id;

    if (draggedImage?.docTypeId === 41 && dropzoneId === primary) {
      return;
    }
    if (imageId === primary) {
      if (primaydata === undefined) {
        return;
      }
      const dropzoneIndexnew = file?.findIndex(
        (image) => image.prodImgId.toString() === dropzoneId
      );
      const newFile = file?.filter(
        (image) => image.prodImgId !== primaydata?.prodImgId
      );
      newFile?.splice(dropzoneIndexnew!, 0, primaydata!);

      setFile(newFile);
      // eslint-disable-next-line unicorn/no-useless-undefined
      setprimaydata(undefined);
      updateindatabase(primaydata?.prodImgId, false);
      return;
    }

    if (dropzoneId === primary) {
      if (primaydata !== undefined) {
        updateindatabase(primaydata.prodImgId, false);
      }
      updateindatabase(imageId, true);

      const primaryIndex = file?.findIndex(
        (image) => image.prodImgId.toString() === imageId
      );
      const newFile = file?.filter(
        (image) => image.prodImgId.toString() !== imageId
      );

      if (primaydata !== undefined) {
        newFile?.splice(primaryIndex!, 0, primaydata!);
      }
      newFile?.slice(primaryIndex);
      setprimaydata(draggedImage);
      setFile(newFile);
      return;
    }
    const dropzoneIndex = file?.findIndex(
      (image) => image.prodImgId.toString() === dropzoneId
    );
    const newFile = file?.filter(
      (image) => image.prodImgId.toString() !== imageId
    );
    newFile?.splice(dropzoneIndex!, 0, draggedImage!);
    setFile(newFile);
  };

  const onLoad = useCallback((img: any) => {
    imgRef.current = img;
  }, []);
  const onCancel = () => {
    setOpenDialogDelete(false);
    setProductImgId(0);
  };

  return (
    <>
      {loading === "loading" ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <ConfirmDailogbox
        openDialog={openDialogDelete}
        setOpenDialog={setOpenDialogDelete}
        onConfirm={handleDelete}
        onCancel={onCancel}
        textContent="Are you sure you want to delete ?"
      />
      <Dialog
        visible={openDialog}
        onHide={() => handleCancel()}
        headerClassName="no-header"
        contentClassName="top-lr-content"
        className="dialogImageFix"
      >
        <>
          {loading === "loading" ? <Loader /> : <></>}
          {/* <div className=" col-12 justify-content-center align-items-content">
            <h3 className="font-bold">Preview</h3>
            <div
              className="flex flex-row flex-wrap sm:flex-none flex justify-content-center align-items-content"
              style={{
                height: "360px",
                width: "640px",
                // backgroundColor: "black",
              }}
            >
              <div className="output ProdImg">
                {!!completedCrop && (
                  <>
                    <img src={croppedImage || ""} alt="" className="ProdImg" />
                  </>
                )}
              </div>
            </div>
          </div> */}
          <div>
            <h3 className="inputLabel">
              Use the cropping tool below to select a specific section of the
              image:
            </h3>
          </div>
          <ReactCrop
            crop={crop}
            onChange={(c: any) => setCrop(c)}
            onComplete={(c: any) => {
              setCompletedCrop(c);
            }}
          >
            <img ref={imgRef} alt="Crop me" src={docLink} onLoad={onLoad} />
          </ReactCrop>

          <div className="mt-2">
            <div className="flex flex-row flex-wrap sm:flex-none justify-content-center col-12">
              <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleCancel();
                  }}
                  className="btn-nav "
                >
                  <span className="ml-auto mr-auto"> Cancel</span>
                </Button>
              </div>
              <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    uploadFile(e);
                  }}
                  className="btn-navActive"
                >
                  <span className="ml-auto mr-auto"> Upload</span>
                </Button>
              </div>
            </div>
          </div>
        </>
      </Dialog>
      <form>
        <div className="mt-4">
          <div
            className="card flex flex-row flex-wrap sm:flex-none "
            style={{
              overflowY: "auto",
              height: "470px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div>
                <h5 className="mt-0 m-2 mb-3 md:mb-2">
                  Upload Investment Photos & Videos
                </h5>
                <div
                  // style={{
                  //   width: "270px",
                  //   height: "230px",
                  //   cursor: "pointer",
                  // }}
                  className="Image-container"
                >
                  <div className="upload-imageBox " onClick={handleClick}>
                    <input
                      type="file"
                      ref={fileUploadRef}
                      accept="image/*,video/mp4,video/x-m4v,video/*"
                      style={{ display: "none" }}
                      onChange={(e) => cropImages(e)}
                    />
                    <RiAddFill
                      style={{
                        fontSize: "120px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h5 className="mt-0 m-2 mb-3 md:mb-2">
                  Drag Primary Photo below:
                </h5>
                {/* <div
                  className="Image-container"
                  key={primary}
                  data-id={primary}
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, primary)}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                > */}
                <div
                  className="Image-container"
                  key={primary}
                  data-id={primary}
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, primary)}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onTouchStartCapture={(e) => handleTouchStart(e, primary)}
                  onTouchMoveCapture={handleTouchMove}
                  onTouchEndCapture={handleTouchEnd}
                >
                  <div className="primary-imageBox">
                    {primaydata ? (
                      <Image
                        src={primaydata?.viewLink}
                        // src={URL.createObjectURL(data.name)}
                        alt="Image"
                        draggable="true"
                        onDragStart={(e) =>
                          handleDragStart(e, primaydata?.prodImgId)
                        }
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        imageClassName="ProdImgEdit"
                      />
                    ) : (
                      <span style={{ fontSize: "18px", color: "white" }}>
                        {" "}
                        Drag Primary Photo here
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {file?.map((data, index) => {
                return (
                  <div
                    style={{
                      width: "270px",
                      height: "260px",
                    }}
                    key={data?.prodImgId}
                    data-id={data?.prodImgId}
                    draggable="true" // Make the container draggable
                    onDragStart={(e) => handleDragStart(e, data?.prodImgId)}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "270px",
                        height: "230px",
                        cursor: "grab",
                        borderRadius: "5px",
                        marginTop: "27px",
                      }}
                    >
                      {data.documentType === "Video" ? (
                        <>
                          <video
                            className="ProdImgEdit"
                            preload="true"
                            src={data?.viewLink}
                            onDragStart={(e) =>
                              handleDragStart(e, data?.prodImgId)
                            }
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            controls
                            style={{
                              contain: "size",
                              borderRadius: "5px",
                            }}
                          ></video>
                        </>
                      ) : (
                        <Image
                          src={data?.viewLink}
                          // src={URL.createObjectURL(data.name)}
                          alt="Image"
                          draggable="true"
                          onDragStart={(e) =>
                            handleDragStart(e, data?.prodImgId)
                          }
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                          imageClassName="ProdImgEdit"
                        />
                      )}

                      <span
                        className="deleteImgIcon"
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "22px",
                          width: "30px",
                          height: "30px",
                          background: "white",
                          display: "flex",
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                        }}
                        onClick={() => {
                          setProductImgId(data?.prodImgId);
                          setOpenDialogDelete(true);
                        }}
                      >
                        <RiDeleteBin2Line style={{ fontSize: "35px" }} />
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {props.pageName === "CreateNew" ? (
          <div className="flex flex-row flex-wrap sm:flex-none col-12 ">
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-nav"
                onClick={(e) => {
                  e.preventDefault();
                  props.handlePrevious();
                }}
              >
                <span className="ml-auto mr-auto ">Back</span>
              </Button>
            </div>
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              {updatefile?.length > 0 ? (
                <Button
                  className="btn-dialog "
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    updateFileIndex();
                    props.pageName === "CreateNew" ? (
                      navigate(
                        `/Continuous/ManageOffering/${stateInvestment.data?.productID}`
                      )
                    ) : (
                      <></>
                    );
                  }}
                >
                  <span className="ml-auto mr-auto ">Save</span>
                </Button>
              ) : (
                <Button
                  className="btn-dialog "
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    props.pageName === "CreateNew" ? (
                      navigate(
                        `/Continuous/ManageOffering/${stateInvestment.data?.productID}`
                      )
                    ) : (
                      <></>
                    );
                  }}
                >
                  <span className="ml-auto mr-auto ">Skip and Exit</span>
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-row flex-wrap sm:flex-none justify-content-center col-12 ">
            {" "}
            <div className="d-flex flex-column p-2 col-12  sm:col-3 ">
              {updatefile?.length > 0 ? (
                <Button
                  className="btn-dialog "
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    updateFileIndex();
                    props.pageName === "CreateNew" ? (
                      navigate(
                        `/Continuous/ManageOffering/${stateInvestment.data?.productID}`
                      )
                    ) : (
                      <></>
                    );
                  }}
                >
                  <span className="ml-auto mr-auto ">Save</span>
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </form>
    </>
  );
};
