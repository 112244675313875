import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ManageFeeCudService } from "../../../../services/Accounting/accounting";
import error from "../../../../utils/error";
import { IManageFeeCUDState, IManageFeeCUDparameters } from ".";

const initialState: IManageFeeCUDState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const ManageFeeCUDThunk = createAsyncThunk(
  "Accounting/ManageFeeCUD",
  async (data: IManageFeeCUDparameters) => {
    try {
      const response = await ManageFeeCudService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Accounting/ManageFeeCUD",
  initialState,
  reducers: {
    manageData: (state) => {
      state.data = undefined;
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(ManageFeeCUDThunk.pending, (state) => {
      state.data = undefined;
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(ManageFeeCUDThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload._response?.data!
        ? action.payload._response.data?.ManageFeeCUD
        : [];
    });
    builder.addCase(ManageFeeCUDThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});
export const { manageData } = slice.actions;

export default slice.reducer;
