// import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";

import DataTableComponent from "../../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../../components/loader/Loader";
import { DeleteRoleThunk } from "../../../../store/features/accountatatement/rolemanagement/managerole/deleterole/slice";
import { GetRoleListThunk } from "../../../../store/features/accountatatement/rolemanagement/managerole/getmanagerole/slice";
import { SaveRoleThunk } from "../../../../store/features/accountatatement/rolemanagement/managerole/saverole/slice";
import { UpdateRoleThunk } from "../../../../store/features/accountatatement/rolemanagement/managerole/updatemanagerole/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { successToastMessage } from "../../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function ManageRole() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const GetRoleList = useAppSelector((state) => state.GetRoleList?.data);
  const [openDailog, setOpenDailog] = useState(false);

  // const [exitDate, setExitDate] = useState<any>();

  const [id, setId] = useState<any>();
  const [name, setName] = useState<any>();
  const [nameError, setNameError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editClick, setEditClick] = useState(false);
  const [loading, setloading] = useState<any>(false);

  const profileData = useAppSelector((state) => state.profile.data![0]);

  // const options = [
  //   { label: "Option 1", value: 1 },
  //   { label: "Option 2", value: 2 },
  //   { label: "Option 3", value: 3 },
  // ];

  // const handleChange = (e: any) => {
  //   return setLeagalentity(e.value);
  //   // Do something with the selected value
  // };

  // const getExitDateConversion = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedExitDate = {
  //       ...d,
  //       firstInvInStateFundingState: new Date(d.firstInvInStateFundingState),
  //       filingDate: d.filingDate ? new Date(d.filingDate) : d.filingDate,
  //       expiryDate: d.expiryDate ? new Date(d.expiryDate) : d.expiryDate,
  //       invDate: d.invDate ? new Date(d.invDate) : d.invDate,
  //     };
  //     return modifiedExitDate;
  //   });
  // };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling animation
    });
  };
  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = () => {
      setId(rowData.key);
      setName(rowData.value);
      setNameError(false);
      setEditClick(true);
      handleScrollToTop();
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={handleEdit}
          >
            <span className="text-white">EDIT</span>
          </Button>
        </div>
      </div>
    );
  };

  const deleteButton = (rowData: any) => {
    return (
      <div className="flex flex-column ">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              setId(rowData.key);
              setName(rowData.value);
              setVisible(true);
            }}
            disabled={rowData.disable}
          >
            <span className="text-white">DELETE</span>
          </Button>
        </div>
      </div>
    );
  };
  const onDelete = () => {
    const deleteParms = {
      Id: id,
    };
    dispatch(DeleteRoleThunk(deleteParms)).then(() => {
      const req = {
        // eslint-disable-next-line unicorn/no-null
        UserId: 0,
        LegalEntityId: 0,
      };

      dispatch(GetRoleListThunk(req));
      successToastMessage(toast, "Role deleted successfully ");
    });
    setName("");
    setVisible(false);
  };

  const onSave = () => {
    const parameters = {
      Id: id,
      Role: name,
      LoginId: profileData.userID,
    };
    const param = {
      Role: name,
      LoginId: profileData.userID,
    };
    setloading(true);
    if (editClick) {
      if (name) {
        dispatch(UpdateRoleThunk(parameters)).then(() => {
          const req = {
            // eslint-disable-next-line unicorn/no-null
            UserId: 0,
            LegalEntityId: 0,
          };

          dispatch(GetRoleListThunk(req));
          setloading(false);
          successToastMessage(toast, "Role updated successfully");
        });
        setName("");
        setNameError(false);
        setEditClick(false);
      } else {
        setNameError(true);
        setloading(false);
      }
    } else {
      if (name) {
        dispatch(SaveRoleThunk(param)).then(() => {
          const req = {
            // eslint-disable-next-line unicorn/no-null
            UserId: 0,
            LegalEntityId: 0,
          };

          dispatch(GetRoleListThunk(req));
          setloading(false);
          successToastMessage(toast, "Role saved successfully");
        });
        setName("");
        setNameError(false);
        setEditClick(false);
      } else {
        setNameError(true);
        setloading(false);
      }
    }
  };

  const dataTableElements = [
    {
      field: "value",
      header: "Role Name",
      style: { minWidth: "240px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.value}>
          {rowData?.value}
        </div>
      ),
    },

    {
      field: "",
      header: "",
      style: { minWidth: "160px" },
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
    },
    {
      field: "",
      header: "",
      style: { minWidth: "160px" },
      body: (rowData: any) => deleteButton(rowData),
    },
  ];

  const resetForm = () => {
    setName("");
  };

  useEffect(() => {
    setloading(true);
    const req = {
      // eslint-disable-next-line unicorn/no-null
      UserId: 0,
      LegalEntityId: 0,
    };

    dispatch(GetRoleListThunk(req))
      .then(() => {
        setloading(false);
        // setdataTableElements(prepareData(exitDate));
      })
      .catch(() => {
        setloading(false);
      });
  }, []);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />

        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Manage Roles</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                GetRoleList === undefined ||
                GetRoleList?.length === 0 ||
                GetRoleList === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Are you sure you want to delete ?
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={onDelete}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>

        <div className="col-12">
          <div className="formgrid grid">
            <div className="d-block sm:d-flex sm:flex-column md:col-6 mr-3 col-6">
              {/* <label className="inputLabel">Role Name *</label> */}
              <InputText
                name="MenuName"
                className={classNames("card-inputBox")}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const onlyLetters = inputValue.replace(/[^\sA-Za-z]/g, "");
                  setName(onlyLetters);
                  onlyLetters && setNameError(false);
                }}
                value={name}
                placeholder="Role Name*"
              />
              {nameError ? (
                <>
                  <small className="p-error">Please enter role name </small>
                </>
              ) : (
                <small className="p-error">&nbsp;</small>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <div className="mr-3">
                {/* <label className="inputLabel"></label> */}
                <Button
                  className="btn-nav w-7rem justify-content-center"
                  onClick={onSave}
                >
                  Save
                </Button>
              </div>
              <div className="">
                <Button
                  className="mr-4 btn-nav w-7rem justify-content-center"
                  onClick={resetForm}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>

          <div className="mt-2">
            {/* <Card> */}
            <DataTableComponent
              // valueData={
              //   GetRoleList
              //     ? GetRoleList.map((item: any) => ({
              //         ...item,
              //         active: item.active ? "active" : "inactive",
              //       }))
              //     : GetRoleList
              // }
              valueData={GetRoleList || []}
              fieldsElements={dataTableElements}
              isPaginationAllowed={true}
              className="gridcell deletebtn"
              columnResizeMode="expand"
              scrollable={true}
              maxHeight={"calc(-205px + 100vh)"}
              isFullView={true}
              setOpenDailog={setOpenDailog}
              openDailog={openDailog}
            />
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
