import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createNewSoftCommitService } from "../../../services/opportunity/opportunity";
import { RootState } from "../../../store";
// import apiFactory from "../../utils/api";
import { ISoftCommitparamter } from "../../../types/index";
import error from "../../../utils/error";

interface OpportunityImagesState {
  Data?: string;
  loading: string;
  error?: string | null;
}
const initialState: OpportunityImagesState = {
  Data: undefined,
  loading: "loading",
  error: undefined,
};
export const addNewSoftCommitThunk = createAsyncThunk(
  "opportunity/softCommit",
  async (data: ISoftCommitparamter) => {
    try {
      const response = await createNewSoftCommitService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "opportunitySoftCommit",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(addNewSoftCommitThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(addNewSoftCommitThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.Data = action.payload.data;

      // state.Data = action.payload.opportunitiesData;
      // state.currentPage += 1;
    });
    builder.addCase(addNewSoftCommitThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

// export const getOpportunityImageData = (state: RootState) => {
//   return state.opportunityImageData.Data;
// };

// else {
//   const iMG = state.opportunityImageData.Data;
//   const filterImg = iMG!.filter((D) => {
//     return D.productLink !== null;
//   });
//   return filterImg;
// const images = useSelector(state => Object.keys(state.Reducer.images)
// .filter(x => x === someID)
// .reduce((arr, key) => {
//   arr.push(state.Reducer.images[key].data);
//   return arr;
// }, []), shallowEqual);
export const getOpportunityImageDataStatus = (state: RootState) =>
  state.opportunityImageData.loading;
// export const getAllOpportunitiesError = (state: RootState) =>
//   state.opportunity.error;

export default slice.reducer;
