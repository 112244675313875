import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWirelessTransactionService } from "../../../../services/Dwolla/dwolla";
import {
  IGetWirelessTransactionResponse,
  ILegalEntityAndPartnerID,
} from "../../../../types";
import error from "../../../../utils/error";

interface IGetWirelessTransaction {
  data: IGetWirelessTransactionResponse[];
  loading: string;
  error?: string | null;
}

const initialState: IGetWirelessTransaction = {
  data: [],
  loading: "loading",
  error: undefined,
};

export const getWirelessTransactionThunk = createAsyncThunk(
  "finance/transaction/getWirelessTransaction",
  async (data: ILegalEntityAndPartnerID) => {
    try {
      const response = await getWirelessTransactionService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "finance/transaction/getWirelessTransaction",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getWirelessTransactionThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getWirelessTransactionThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      //
      state.data = action.payload._response.data;
    });
    builder.addCase(getWirelessTransactionThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
