import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAgreementTypeService } from "../../../../../services/e-sign/e-sign-ondemand";
import error from "../../../../../utils/error";
import { IAgreementDropDownState } from ".";

const initialState: IAgreementDropDownState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkAgreementDropDown = createAsyncThunk(
  "agreementTypeList",
  async () => {
    try {
      const response = await getAgreementTypeService();
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "agreementTypeList",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkAgreementDropDown.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkAgreementDropDown.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      if (action.payload.data !== null) {
        state.data = action.payload.data;
      }
    });
    builder.addCase(getThunkAgreementDropDown.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
