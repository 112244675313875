import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
// import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import {
  getThunkAllSoftCommitList,
  setSoftCommitForm,
} from "../../../../store/features/convert-deal-to-investment/list/slice";
import { getThunkAllInvestmentByUserId } from "../../../../store/features/investment/all-list/slice";
import { initialInvestmentState } from "../../../../store/features/investment/create/payload";
import {
  addThunkInvestment,
  getThunkInvestmentById,
  setForm,
  // setFormInitial,
} from "../../../../store/features/investment/create/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  // convertUTCToLocalDate,
  //  dateFormatter,
  convertLocalDateToUTC,
  getListDateConversion,
  formatDateMMDDYYY,
} from "../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

export const DealToInvestment = () => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>();
  const [softList, setSoftList] = useState<any>();
  const [cashList, setCashList] = useState<any>();

  const [loading, setLoading] = useState(false);
  // const [total, setTotal] = useState<any>();
  // const legalEntity = useAppSelector((state) => state.legalEntity.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const stateInvestment = useAppSelector((state) => state.addInvestment);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const listDeals = useAppSelector(
    (state) => state.getListInvestmentsUserId.data.deals
  );
  const softCommitList = useAppSelector((state) => state.softCommitList.data);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogConvertSuccess, setDialogConvertSuccess] = useState(false);
  // const todayDate = new Date();

  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData.createdDate);
    return dateFormat;
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const formatCurrencyAnyData = (rowData: any) => {
    const finalValue = Number(rowData);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const formatCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.amount);
  };
  const dataElement = [
    {
      field: "partnerName",
      header: "Investor Account",
    },
    {
      field: "createdDate",
      header: "Soft Commit Date",
      dataType: "date",
      body: formatDate,
      filterElement: dateFilterTemplate,
    },
    {
      field: "amount",
      header: "Amount",
      body: formatCurrency,
    },
  ];
  const dataElementCash = [
    {
      field: "accountNickName",
      header: "Investor Account",
    },
    {
      field: "cashbalance",
      header: "Cash Balance",
    },
  ];

  const formik = useFormik({
    initialValues: {
      // legalEntityID: 0,
      productID: 0,
      targetRaiseAmt: 0,
      targetCloseDate: "",
      fundingDate: "",
      listPrice: undefined,
      numberOfUnits: 0,
      unitPriceCalculated: 10,
    },
    validate: (data) => {
      //
      const errors: any = {};
      if (!data.productID) {
        errors.supplierID = "Select a Deal";
      }
      if (!data.listPrice) {
        errors.listPrice = " Total Investment is required";
      } else if (!/^[1-9]\d*0$/.test(data.listPrice)) {
        errors.listPrice = "Number should be divisible by 10 ";
      }
      if (data.listPrice && data.listPrice > 20_000_000_000) {
        errors.listPrice =
          "The target raise amount should be less than equal to $20B";
      }
      if (!data.fundingDate) {
        errors.fundingDate = "Funding Date is required";
      }

      return errors;
    },
    onSubmit: async (data) => {
      console.log(data);
      setOpenDialog(false);
      setLoading(true);
      await dispatch(addThunkInvestment(stateInvestment.data!))
        .then((response) => {
          console.log("reponse", response);
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (
            response.meta.requestStatus === "fulfilled" &&
            response.payload.data.productDetails[0].productID !== 0
          ) {
            successToastMessage(toast, stateInvestment.message);
            formik.resetForm();
            setLoading(false);
            const parameter = {
              legalEntityId: fundDropdown.fundid,
              userId: profileData?.userID,
            };
            dispatch(getThunkAllInvestmentByUserId(parameter));
            setDialogConvertSuccess(true);
          }
        })
        .catch((error: any) => {
          console.log("error", error);
          errorToastMessage(toast);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const DropdownList: any = [];

  listDeals?.map((item: any) => {
    //
    return DropdownList.push({
      name: item.productName,
      code: item.productID,
    });
  });
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "listPrice") {
      const units = value / formik.values.unitPriceCalculated;
      formik.setFieldValue("numberOfUnits", units);
      formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(name, value);
    }
  };

  const handleChangeState = () => {
    dispatch(
      setForm({
        ...stateInvestment.data,
        productState: "PreFunded",
        listPrice: formik.values.listPrice,
        unitPriceCalculated: 10,
        fundingDate:
          formik.values.fundingDate === ""
            ? formik.values.fundingDate
            : convertLocalDateToUTC(formik.values.fundingDate),
        numberOfUnits: formik.values.numberOfUnits,
        isFinalstep: true,
      })
    );
  };
  const handleOk = () => {
    console.log("set");
    setDialogConvertSuccess(false);
    formik.resetForm();
    setSoftList([]);
    setCashList([]);

    dispatch(setForm({ ...stateInvestment.data, ...initialInvestmentState }));
  };

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      // console.log("inside");
      setLoading(true);
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        userId: profileData?.userID,
      };
      dispatch(getThunkAllInvestmentByUserId(parameter)).finally(() => {
        setLoading(false);
      });
    }
    formik.resetForm();
    const parameter = {
      softCommits: [],
      cashbalance: [],
      total: [],
    };
    dispatch(setSoftCommitForm(parameter));
  }, [dispatch, fundDropdown]);
  useEffect(() => {
    if (formik.values.productID !== 0) {
      setLoading(true);
      dispatch(getThunkInvestmentById(formik.values.productID!));
      dispatch(getThunkAllSoftCommitList(formik.values.productID)).finally(
        () => {
          setLoading(false);
        }
      );
    }
  }, [formik.values.productID]);

  useEffect(() => {
    console.log("inside useEffect");
    formik.setFieldValue(
      "targetRaiseAmt",
      stateInvestment.data?.targetRaiseAmt
    );
    formik.setFieldValue(
      "targetCloseDate",
      stateInvestment.data?.targetCloseDate
    );

    // setTotal(softCommitList.total);
  }, [stateInvestment]);
  console.log("softCommitList.cashbalance", softCommitList);
  useEffect(() => {
    setSoftList(
      getListDateConversion(softCommitList.softCommits, ["createdDate"])
    );
    setCashList(getListDateConversion(softCommitList.cashbalance, [""]));
  }, [softCommitList]);

  useEffect(() => {
    dispatch(setForm({ ...stateInvestment.data, ...initialInvestmentState }));
  }, [fundDropdown.fundid]);

  const footerGroup = (
    <ColumnGroup>
      {softList?.length !== 0 && (
        <Row>
          <Column
            footer="Total:"
            colSpan={2}
            // footerStyle={{ textAlign: "right" }}
          />
          <Column footer={formatCurrencyAnyData(softCommitList.total)} />
        </Row>
      )}
    </ColumnGroup>
  );
  const handleCancel = () => {
    formik.resetForm();
    setOpenDialog(false);
    setSoftList([]);
    setCashList([]);
    const parameter = {
      softCommits: [],
      total: [],
      cashbalance: [],
    };
    dispatch(setSoftCommitForm(parameter));
  };
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Dialog
        visible={openDialog}
        className="w-100 md:w-4"
        onHide={() => {
          handleCancel();
        }}
        header="Investment Creation"
        headerClassName="text-center flex justify-content-center align-item-center p-6 pageHeader display-1"
        contentClassName="top-lr-content p-0"
      >
        <div className="m-0">
          <hr className="m-0" />
          <form>
            <div className="mt-1">
              <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-12">
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                    <label className="inputLabel">Total Investment</label>
                    <InputNumber
                      name="listPrice"
                      value={
                        formik.values.listPrice === undefined ||
                        formik.values.listPrice === 0
                          ? undefined
                          : formik.values.listPrice
                      }
                      onValueChange={(e) => handleChange(e)}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("listPrice"),
                        "w-100": true,
                      })}
                      // maxLength={17}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      placeholder="Enter Total Investment"
                    />
                    {getFormErrorMessage("listPrice")}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                    <label className="inputLabel">Funding Date</label>

                    <Calendar
                      name="fundingDate"
                      value={
                        formik.values.fundingDate === null ||
                        formik.values.fundingDate === ""
                          ? undefined
                          : new Date(formik.values.fundingDate)
                      }
                      onChange={(e) => handleChange(e)}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("fundingDate"),
                        "card-inputBox": true,
                      })}
                      // minDate={todayDate}
                      placeholder="Select Funding Date"
                      readOnlyInput
                    />
                    {getFormErrorMessage("fundingDate")}
                  </div>
                </div>
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                    <label className="inputLabel">Numbers of Units</label>
                    <InputText
                      name="numberOfUnits"
                      value={
                        formik.values.numberOfUnits === 0 ||
                        formik.values.numberOfUnits === undefined
                          ? ""
                          : formik.values.numberOfUnits
                      }
                      // onChange={(e) => handleChange(e)}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("numberOfUnits"),
                        "card-inputBox": true,
                      })}
                      disabled
                      placeholder="Number of units"
                    />
                    {getFormErrorMessage("numberOfUnits")}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                    <label className="inputLabel">Unit Price ($)</label>
                    <InputNumber
                      name="unitPriceCalculated"
                      value={formik.values.unitPriceCalculated}
                      // onChange={(e) => handleChange(e)}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("unitPriceCalculated"),
                        "w-100": true,
                      })}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      disabled
                      placeholder="Enter unit price"
                    />

                    {getFormErrorMessage("unitPriceCalculated")}
                  </div>
                </div>
                <div className=" align-self-center col-12 flex align-items-center justify-content-center">
                  <Button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeState();
                      formik.handleSubmit();
                    }}
                    className="blueButtonShadow"
                  >
                    <span className="ml-auto mr-auto ">
                      Convert to Investment
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        visible={dialogConvertSuccess}
        className="w-100 md:w-4"
        onHide={() => {
          handleOk();
        }}
        contentClassName="top-lr-content p-0"
        closable={false}
      >
        <div className="m-3">
          <p className="pageHeader text-center mb-3 pt-5">
            Deal was successfully converted into an investment with lookup code
            = {stateInvestment.data?.supplierProductCode} and Investment id ={" "}
            {stateInvestment.data?.productID}
          </p>
        </div>
        <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
          <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleOk();
              }}
              className="blueButtonShadow  "
            >
              <span className="ml-auto mr-auto">Ok</span>
            </Button>
          </div>
        </div>
      </Dialog>
      <div>
        <div className="grid">
          <div className="col-12 md:col-6 mt-2">
            <div className="card pgHeaderWrap md:my-2 mt-3">
              <h1 className="pageHeader">Convert Deal to Investment</h1>
              <p className="pageSubHeader"> </p>
            </div>
          </div>
          <div className="col-12 md:col-6 mt-2">
            <div></div>
          </div>
        </div>

        <div className=" h-100 center-card">
          <div className="col-12 ">
            <div className="card">
              <Card>
                {/* <div className="headercard d-flex grid">
                  <div className="col-12 md:col-6">
                    <h1 style={{ fontWeight: "bold" }}>
                      Convert Deal to Investment
                    </h1>
                  </div>
                  <div className="col-12 md:col-6 end-div">
                    <h4>
                      Need Help? Watch <a>this video</a>
                    </h4>
                  </div> 
                </div> */}
                <form>
                  <div className="mt-4">
                    <label className="inputLabel inputLabelColor ml-2">
                      Deal To Convert
                    </label>
                    <div
                      className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
                      style={{ height: "auto" }}
                    >
                      <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-12">
                        <div className="ml-4">
                          <div className="formgrid grid px-2">
                            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                              <label className="inputLabel">
                                Which deal are you converting?
                              </label>

                              <Dropdown
                                inputId="productID"
                                name="productID"
                                value={formik.values.productID}
                                options={
                                  DropdownList === null ? [] : DropdownList
                                }
                                filter
                                optionLabel="name"
                                optionValue="code"
                                resetFilterOnHide={true}
                                showFilterClear={true}
                                placeholder="Select Deal"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                className={classNames({
                                  "p-invalid": isFormFieldInvalid("productID"),
                                  "card-inputBox": true,
                                })}
                              />
                              {getFormErrorMessage("productID")}
                            </div>
                            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4 annualClass">
                              <label className="inputLabel">
                                Target Raise Amount
                              </label>

                              <InputNumber
                                name="productName"
                                value={
                                  formik.values.targetRaiseAmt === 0
                                    ? undefined
                                    : formik.values.targetRaiseAmt
                                }
                                // onChange={(e) => handleChange(e)}
                                className={classNames({
                                  "p-invalid":
                                    isFormFieldInvalid("targetRaiseAmt"),
                                  "card-inputBox": true,
                                  "p-filled": true,
                                  "single-border": true,
                                })}
                                mode="currency"
                                currency="USD"
                                locale="en-US"
                                disabled
                                placeholder="Target Raise Amount"
                              />
                              {getFormErrorMessage("targetRaiseAmt")}
                            </div>

                            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4 annualClass">
                              <label className="inputLabel">
                                Target Close Date
                              </label>

                              <Calendar
                                name="targetCloseDate"
                                value={
                                  formik.values.targetCloseDate === null ||
                                  formik.values.targetCloseDate === ""
                                    ? undefined
                                    : new Date(formik.values.targetCloseDate)
                                }
                                // onChange={(e) => dateStateChange(e)}
                                className={classNames({
                                  "p-invalid":
                                    isFormFieldInvalid("targetCloseDate"),
                                  "card-inputBox": true,
                                })}
                                disabled
                                // minDate={todayDate}
                                placeholder="Target Close Date"
                                readOnlyInput
                              />
                              {getFormErrorMessage("targetCloseDate")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <form>
                  <div>
                    <div className="formgrid grid px-2">
                      <div className="flex flex-row flex-wrap sm:flex-none col-12 justify-content-center">
                        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                          <label className="inputLabel">&nbsp;</label>
                          <Button
                            className="btn-dialog "
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenDialog(true);
                            }}
                            disabled={formik.values.productID === 0}
                          >
                            <span className="ml-auto mr-auto ">
                              Select and Continue
                            </span>
                          </Button>
                          <label className="inputLabel">&nbsp;</label>
                        </div>
                      </div>

                      <span className="ml-2">
                        {" "}
                        Below is a reference of current cash balance of
                        investors in your fund and also who has indicated a
                        desire to invest with a soft commit. Below data is only
                        for your reference. This capability will only convert a
                        deal into investment. No investor allocations will be
                        done at this stage. After you convert this deal to an
                        investment, you can proceed to{" "}
                        <NavLink
                          to="/ManagePartnerInterest/AllocateSponsorsInvSlices"
                          className="tabHeadText footertext"
                        >
                          Allocate slices
                        </NavLink>{" "}
                        to allocate investors in this investment.{" "}
                      </span>
                    </div>
                  </div>
                </form>
                <form>
                  <div className="mt-2">
                    <label className="inputLabel inputLabelColor ml-2 mt-4 view-convert">
                      View Investor&apos;s cash balance{" "}
                    </label>
                    <div
                      className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
                      style={{ height: "auto" }}
                    >
                      <div
                        className="d-block sm:d-flex sm:flex-column pl-0 col-12 sm:col-12 ml-4"
                        // style={{ height: "auto" }}
                      >
                        <div className="ml-3">
                          <DataTableComponent
                            valueData={cashList || []}
                            fieldsElements={dataElementCash}
                            resizableColumns={true}
                            columnResizeMode="expand"
                            scrollable={true}
                            className="gridcell"
                            // footerElement={footerGroup}
                            isPaginationAllowed={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <form>
                  <div className="mt-3">
                    <label className="inputLabel inputLabelColor ml-2 mt-4  ">
                      View Soft Commits
                    </label>
                    <div
                      className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
                      style={{ height: "auto" }}
                    >
                      <div
                        className="d-block sm:d-flex sm:flex-column pl-0 col-12 sm:col-12 ml-4"
                        // style={{ height: "auto" }}
                      >
                        <div className="ml-3">
                          <DataTableComponent
                            valueData={softList}
                            fieldsElements={dataElement}
                            footerElement={footerGroup}
                            isPaginationAllowed={true}
                            resizableColumns={true}
                            columnResizeMode="expand"
                            scrollable={true}
                            className="gridcell"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
