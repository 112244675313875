import React from "react";
import { Outlet, NavLink } from "react-router-dom";

const Portfolio = () => {
  return (
    <form className=" h-100 " action="#">
      <div className="tabHeader-wrap">
        <NavLink
          to="dashboard"
          className={({ isActive }) =>
            isActive ? "tabHeadText Active" : "tabHeadText"
          }
        >
          Dashboard
        </NavLink>{" "}
        <NavLink
          to="Investment"
          className={({ isActive }) =>
            isActive ? "tabHeadText Active" : "tabHeadText"
          }
        >
          Investments
        </NavLink>
        <NavLink
          to="Transaction"
          className={({ isActive }) =>
            isActive ? "tabHeadText Active" : "tabHeadText"
          }
        >
          Transactions
        </NavLink>
      </div>{" "}
      <Outlet />
    </form>
  );
};

export default Portfolio;
