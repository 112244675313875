import { Button } from "primereact/button";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { EnableOrDisableUserInADThunk } from "../../../store/features/admin/enableordisableuserInad/slice";
import { GetAdminMenuUsersDetailsThunk } from "../../../store/features/admin/getadminmenuusersdetails/slice";
import { SendDynamicMailThunk } from "../../../store/features/admin/senddynamicmail/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { exportExcel } from "../../../utils/excel-dataformat";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";
export default function ManageFundUserAccountsAdmin() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const GetMenuList = useAppSelector(
    (state) => state.GetAdminMenuUsersDetails?.userAccountData
  );

  const profile = useAppSelector((state) => state.profile.data![0]);

  const [loader, setLoader] = useState(false);
  const [inviteEmail, setInviteEmail] = useState<any>({});
  const [inviteVisible, setInviteVisible] = useState(false);
  const [resetCredData, setResetCredData] = useState<any>({});
  const [enableDisabled, setEnableDisabled] = useState<any>({});
  const [credentialsVisible, setCredentialsVisible] = useState(false);
  const [enableDisabledVisible, setEnableDisabledVisible] = useState(false);
  const [openDailog, setOpenDailog] = useState(false);

  const resetCredentials = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = (id: any) => {
      console.log("resetCredentials", rowData);
      setCredentialsVisible(true);
      setResetCredData(rowData);
      setInviteEmail(rowData);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={() => handleEdit(rowData.Id)}
            disabled={!rowData.isAccoutEnable}
          >
            <span className="text-white">Reset Credentials</span>
          </Button>
        </div>
      </div>
    );
  };

  const onResetCredentials = () => {
    const req = {
      emailId: inviteEmail.email,
      legalEntityId: fundDropdown.fundid,
      roleId: 5,
      type: "Reset_Credentials",
      userName: `${inviteEmail.firstName} ${inviteEmail.lastName}`,
    };
    setLoader(true);
    dispatch(SendDynamicMailThunk(req))
      .then(() => {
        setCredentialsVisible(false);
        setLoader(false);
        successToastMessage(
          toast,
          " An email has been sent to the user with a reset password link"
        );
      })
      .catch(() => {
        setLoader(false);
        errorToastMessage(toast);
      });
  };

  const enableDisable = (rowData: any) => {
    // Pass rowData as a parameter
    console.log("product->>>>>>>>>", rowData);
    const handleEdit = (id: any) => {
      setEnableDisabledVisible(true);
      setEnableDisabled(rowData);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={() => handleEdit(rowData.Id)}
            disabled={
              (!rowData.isAccountExistInAD && rowData.azureObjectId === null) ||
              rowData.email === profile.emailID
            }
          >
            {rowData.isAccountExistInAD && rowData.isAccoutEnable ? (
              <span className="text-white">disable</span>
            ) : (
              <span className="text-white">Enable</span>
            )}
          </Button>
        </div>
      </div>
    );
  };
  const onEnableDisabled = () => {
    console.log("Learining React", enableDisabled);
    // Pass rowData as a parameter
    const reqParam = {
      emaild: enableDisabled.email,
      status: !enableDisabled.isAccoutEnable,
      usertypeid: 6,
    };
    dispatch(EnableOrDisableUserInADThunk(reqParam)).then(() => {
      setLoader(true);
      setEnableDisabledVisible(false);
      const req = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetAdminMenuUsersDetailsThunk(req))
        .then(() => {
          setLoader(false);
          if (
            enableDisabled.isAccountExistInAD &&
            enableDisabled.isAccoutEnable
          ) {
            successToastMessage(toast, "Account successfully disabled");
          } else {
            successToastMessage(toast, "Account successfully enabled");
          }
        })
        .catch(() => {
          setLoader(false);
          errorToastMessage(toast);
        });
    });
  };
  const invite = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = (id: any) => {
      console.log("product----", inviteEmail);
      setInviteVisible(true);
      setInviteEmail(rowData);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={() => handleEdit(rowData.Id)}
            style={{ whiteSpace: "nowrap" }}
            disabled={rowData.isAccountExistInAD}
          >
            <span className="text-white">Invite User</span>
          </Button>
        </div>
      </div>
    );
  };
  const onInviteSend = () => {
    const req = {
      emailId: inviteEmail.email,
      legalEntityId: fundDropdown.fundid,
      roleId: 5,
      type: "Invite_User",
      userName: `${inviteEmail.firstName} ${inviteEmail.lastName}`,
    };
    setLoader(true);

    dispatch(SendDynamicMailThunk(req))
      .then(() => {
        setInviteVisible(false);
        setLoader(false);
      })
      .catch(() => setLoader(false));
  };
  const dataTableElements = [
    {
      field: "email",
      header: "Email",
      style: { minWidth: "280px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.email}>
          {rowData?.email}
        </div>
      ),
    },
    {
      field: "firstName",
      header: "First Name",
      style: { minWidth: "250px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.firstName}>
          {rowData?.firstName}
        </div>
      ),
    },
    {
      field: "lastName",
      header: "Last Name",
      style: { minWidth: "250px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.lastName}>
          {rowData?.lastName}
        </div>
      ),
    },
    {
      // field: "ein",
      header: "",
      body: (rowData: any) => resetCredentials(rowData), // Pass rowData to editButton
      style: { minWidth: "200px" },
    },
    {
      // field: "taxPayerId",
      header: "",
      body: (rowData: any) => enableDisable(rowData), // Pass rowData to editButton
      style: { minWidth: "200px" },
    },
    {
      // field: "Business Name",
      header: "",
      body: (rowData: any) => invite(rowData), // Pass rowData to editButton
      style: { minWidth: "200px" },
    },
  ];

  const excelTableHeaders = [
    {
      field: "email",
      header: "Email",
    },
    {
      field: "firstName",
      header: "First Name",
    },
    {
      field: "lastName",
      header: "Last Name",
    },
    // {
    //   field: "isAccountExistInAD",
    //   header: "Is Account Exist In AD",
    //   dataType: "yesno",
    // },
    {
      field: "isMultiAccountExist",
      header: "Is Multi Account Exist",
      dataType: "yesno",
    },
    {
      field: "isAccoutEnable",
      header: "Is Account Enable",
      dataType: "yesno",
    },
  ];

  // useEffect(() => {
  //   setLoader(true);
  //   const req = {
  //     LegalEntityId: fundDropdown.fundid,
  //   };
  //   dispatch(GetAdminMenuUsersDetailsThunk(req))
  //     .then(() => {
  //       setLoader(false);
  //     })
  //     .catch(() => {
  //       setLoader(false);
  //     });
  // }, []);
  useEffect(() => {
    if (fundDropdown.fundid) {
      setLoader(true);

      const req = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetAdminMenuUsersDetailsThunk(req))
        .then(() => {
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [fundDropdown.fundid]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loader && <Loader />}
        <Dialog
          visible={inviteVisible}
          className="w-100 md:w-4 DialogInvestment"
          onHide={() => setInviteVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            {`This will send an email to ${inviteEmail.email} inviting them to create an account on the Avestor Manager Portal. Are you sure?`}
            {/* {` Send Email to Invite User ${inviteEmail.email}. Are you sure?`}{" "} */}
          </h4>

          <div className="w-75 align-self-center mx-auto flex align-items-center justify-content-between">
            <Button
              onClick={onInviteSend}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setInviteVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>
        <Dialog
          visible={credentialsVisible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setCredentialsVisible(false)}
        >
          {resetCredData.isMultiAccountExist ? (
            <>
              {" "}
              <h4 className="pageHeader text-center mb-5 pt-5">
                An email will be sent to the user with a reset password link.
                Are you sure?
              </h4>
            </>
          ) : (
            <>
              {" "}
              <h4 className="pageHeader text-center mb-5 pt-5">
                An email will be sent to the user with a reset password link.
                Are you sure?{" "}
              </h4>
            </>
          )}

          <div className=" align-self-center flex align-items-center justify-content-between">
            <Button
              onClick={onResetCredentials}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setCredentialsVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>

        <Dialog
          visible={enableDisabledVisible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setEnableDisabledVisible(false)}
        >
          {enableDisabled.isAccoutEnable ? (
            <>
              {" "}
              <h4 className="pageHeader text-center mb-5 pt-5">
                This will disable user&apos;s access in Sponsor Portal for all
                the funds, Are you sure?
              </h4>
            </>
          ) : (
            <>
              {" "}
              <h4 className="pageHeader text-center mb-5 pt-5">
                This will enable user&apos;s access in Sponsor Portal for all
                the funds, Are you sure?{" "}
              </h4>
            </>
          )}

          <div className=" align-self-center flex align-items-center justify-content-between">
            <Button
              onClick={onEnableDisabled}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setEnableDisabledVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader">Sponsor Portal Logins</h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(
                  excelTableHeaders,
                  GetMenuList,
                  "Sponsor Portal Logins",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                GetMenuList === undefined ||
                GetMenuList?.length === 0 ||
                GetMenuList === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  GetMenuList === undefined ||
                  GetMenuList?.length === 0 ||
                  GetMenuList === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            {/* <div className=""> */}
            <DataTableComponent
              valueData={GetMenuList!}
              fieldsElements={dataTableElements}
              isPaginationAllowed={true}
              // isDownload={true}
              scrollable={true}
              className="gridcell deletebtn"
              columnResizeMode="expand"
              maxHeight={"calc(-180px + 100vh)"}
              isFullView={true}
              setOpenDailog={setOpenDailog}
              openDailog={openDailog}
            />
            {/* </div> */}
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
