import { Skeleton } from "primereact/skeleton";
import React from "react";
const OpportunitySkeleton = () => {
  return (
    <>
      <div className="col-md-4">
        <div className="card mb-5 ProdCardSKeleton">
          <Skeleton width="100%" className="skeletonImg mb-2"></Skeleton>
          <div className="propDetail-wrap">
            <Skeleton
              width="100%"
              className="skeletonProdCardDetails mb-4"
            ></Skeleton>
            <Skeleton
              width="100%"
              className="skeletonProdCardDetails mb-4"
            ></Skeleton>
            <Skeleton
              width="100%"
              className="skeletonProdCardDetails mb-4"
            ></Skeleton>
            <div className="d-flex">
              <Skeleton
                width="48%"
                className="skeletonProdCardBtn mb-4 mx-2"
              ></Skeleton>
              <Skeleton
                width="48%"
                className="skeletonProdCardBtn mb-4 mx-2"
              ></Skeleton>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card mb-5 ProdCardSKeleton">
          <Skeleton width="100%" className="skeletonImg mb-2"></Skeleton>
          <div className="propDetail-wrap">
            <Skeleton
              width="100%"
              className="skeletonProdCardDetails mb-4"
            ></Skeleton>
            <Skeleton
              width="100%"
              className="skeletonProdCardDetails mb-4"
            ></Skeleton>
            <Skeleton
              width="100%"
              className="skeletonProdCardDetails mb-4"
            ></Skeleton>
            <div className="d-flex">
              <Skeleton
                width="48%"
                className="skeletonProdCardBtn mb-4 mx-2"
              ></Skeleton>
              <Skeleton
                width="48%"
                className="skeletonProdCardBtn mb-4 mx-2"
              ></Skeleton>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card mb-5 ProdCardSKeleton">
          <Skeleton width="100%" className="skeletonImg mb-2"></Skeleton>
          <div className="propDetail-wrap">
            <Skeleton
              width="100%"
              className="skeletonProdCardDetails mb-4"
            ></Skeleton>
            <Skeleton
              width="100%"
              className="skeletonProdCardDetails mb-4"
            ></Skeleton>
            <Skeleton
              width="100%"
              className="skeletonProdCardDetails mb-4"
            ></Skeleton>
            <div className="d-flex">
              <Skeleton
                width="48%"
                className="skeletonProdCardBtn mb-4 mx-2"
              ></Skeleton>
              <Skeleton
                width="48%"
                className="skeletonProdCardBtn mb-4 mx-2"
              ></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OpportunitySkeleton;
