import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { navbarDropdown } from "../../store/navdropdown/slice";

// interface IDropdownElements {
//   registredinverstment: any;
//   setregistredinverstment: any;
//   registerinverstmenList: any;
//   nonregistredinverstment: any;
//   setnonregistredinverstment: any;
//   nonregisterinverstmenList: any;
// }

interface RegisterDropdownProps {
  showLimitedPartnerDropdown?: boolean;
  showRegisteredInvestorDropdown?: boolean;
}

const RegisterDropdownComponent: React.FC<RegisterDropdownProps> = ({
  showLimitedPartnerDropdown = true,
  showRegisteredInvestorDropdown = true,
}) => {
  // ...   const dispatch = useAppDispatch();
  //   const location = window.location.pathname;
  //   console.log(location);
  const dispatch = useAppDispatch();

  const dropdown = useAppSelector((state) => state.navbardropdown.data);

  console.log("dropdown.registerid", dropdown);

  const registerinvestorModel = useAppSelector(
    (state) => state.registerinvestor.data
  );
  const nonregisterinvestorModel = useAppSelector(
    (state) => state.nonregisterinvestor.data
  );

  const [registredinverstment, setregistredinverstment] = useState<any>(
    dropdown.registerid
  );
  const [nonregistredinverstment, setnonregistredinverstment] = useState<any>(
    dropdown.nonregisterid
  );

  const registerinverstmenList: { code: number; name: string }[] = [];
  for (let i = 0; i < registerinvestorModel?.length; i++) {
    registerinverstmenList.push({
      code: registerinvestorModel![i].investorID,
      name: registerinvestorModel![i].investorName,
    });
  }
  const nonregisterinverstmenList: { code: number; name: string }[] = [];
  for (let i = 0; i < nonregisterinvestorModel?.length; i++) {
    nonregisterinverstmenList.push({
      code: nonregisterinvestorModel![i].investorID,
      name: nonregisterinvestorModel![i].investorName,
    });
  }

  useEffect(() => {
    if (registredinverstment > 0) {
      const dropdownparameter = {
        fundid: dropdown.fundid,
        registerid: registredinverstment,
        nonregisterid: 0,
      };
      dispatch(navbarDropdown(dropdownparameter));
    }
  }, [registredinverstment]);

  useEffect(() => {
    if (nonregistredinverstment > 0) {
      const dropdownparameter = {
        fundid: dropdown.fundid,
        registerid: 0,
        nonregisterid: nonregistredinverstment,
      };
      console.log("nonRegister Method called 1st");
      dispatch(navbarDropdown(dropdownparameter));
    }
  }, [nonregistredinverstment]);

  //   useEffect(() => {
  //     if (
  //       dropdown.fundid !== undefined &&
  //       dropdown.fundid > 0 &&
  //       (nonregistredinverstment === undefined ||
  //         (nonregistredinverstment === 0 && registredinverstment === 0))
  //     ) {
  //       console.log("Method Callled Summary");
  //       setregistredinverstment(registerinverstmenList[0]?.code);
  //     } else {
  //       setregistredinverstment(dropdown.registerid);
  //     }
  //   }, [dropdown.fundid]);

  return (
    <>
      {showRegisteredInvestorDropdown && (
        <div className="d-block float-left sm:flex-column p-2 md:col-6 col-12">
          <label className="inputLabel">Registered Investor</label>
          <Dropdown
            name="Registered Investor *"
            value={dropdown.nonregisterid}
            resetFilterOnHide={true}
            showFilterClear={true}
            onChange={(e) => {
              setnonregistredinverstment(e.value);
              setregistredinverstment(0);
            }}
            style={{ width: "100%" }}
            options={nonregisterinverstmenList}
            optionLabel="name"
            optionValue="code"
            placeholder="Registered Investor"
            filter
          />
        </div>
      )}
      {showLimitedPartnerDropdown && (
        <div className="d-block float-left sm:flex-column md:col-6 col-6">
          <label className="inputLabel">Limited Partner</label>
          <Dropdown
            name="Limited Partner"
            value={dropdown.registerid}
            resetFilterOnHide={true}
            showFilterClear={true}
            onChange={(e) => {
              setregistredinverstment(e.value);
              setnonregistredinverstment(0);
            }}
            style={{ width: "100%" }}
            options={registerinverstmenList}
            optionLabel="name"
            optionValue="code"
            placeholder="Select Limited Partner"
            filter
          />
        </div>
      )}
    </>
  );
};
export default RegisterDropdownComponent;
