import { Chart } from "primereact/chart";
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../store/hooks";

export default function DoughnutChartExcitedDeal() {
  // const [text, setText] = useState<number>();
  const accountValues = useAppSelector((state) => state.portfolio.exitedDeals);

  const amount: any[] = [];
  const amountType: string[] = [];
  const amountPercent: any[] = [];
  const exitedDeals = useAppSelector((state) => state.portfolio.exitedDeals);

  amount.push(
    accountValues?.totalNonProfitable,
    accountValues?.totalProfitable
  );

  amountType.push("Non Profitable Deals", "Profitable Deals");

  const [chartData, setChartData] = useState({});
  const [noChartData, setNoChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [noChartOptions, setNoChartOptions] = useState({});
  const [val, setVal] = useState(
    exitedDeals !== undefined ? exitedDeals?.existedCount : 0
  );
  const [lab, setLabel] = useState<any>();
  const [valPercent, setValPercent] = useState<any>();
  amount?.map((item) => {
    return amountPercent.push((item * 100) / val!);
  });
  const color = useAppSelector((state) => state.color);
  const dropdown = useAppSelector((state) => state.navbardropdown.data);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: amountType,
      datasets: [
        {
          data: amount,
          backgroundColor: [
            documentStyle.getPropertyValue("--background-color" || color.Color),
            documentStyle.getPropertyValue(
              "--lighter-color" || color.myColorLight
            ),
            documentStyle.getPropertyValue(
              "--Darker-color" || color.myColorDark
            ),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue(
              "--Darkest-color" || color.myColorDarkest
            ),
            documentStyle.getPropertyValue(
              "--Darkest-color" || color.myColorDarkest
            ),
            documentStyle.getPropertyValue(
              "--Darkest-color" || color.myColorDarkest
            ),
          ],
        },
      ],
    };
    const noData = {
      datasets: [
        {
          data: [100, 0, 0],
          backgroundColor: [
            documentStyle.getPropertyValue(
              "--noData-color" || localStorage.getItem("--noData-color")
            ),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue(
              "--noData-color" || localStorage.getItem("--noData-color")
            ),
          ],
        },
      ],
    };
    const options = {
      cutout: "80%",
      responsive: true,

      plugins: {
        tooltip: {
          enabled: false, // <-- this option disables tooltips
        },
        legend: false,
      },
      onHover: (
        _event: any,
        chartElement: {
          [x: string]: any;
          label: any;
        }[]
      ) => {
        if (chartElement && chartElement[0]) {
          const value =
            data.datasets[chartElement[0].datasetIndex].data[
              chartElement[0].index
            ];

          const label = data.labels[chartElement[0].index]
            .split("Deals")
            .join("");
          const valPer = (value * 100) / exitedDeals!.existedCount;
          setValPercent(valPer);
          setVal(value);
          setLabel(label);
        } else {
          setVal(val);
          setLabel(lab);
          setValPercent(valPercent);
        }
      },
    };
    const noOptions = {
      cutout: "80%",
      responsive: true,
      plugins: {
        tooltip: {
          enabled: false, // <-- this option disables tooltips
        },
      },
    };
    setChartData(data);
    setChartOptions(options);
    setNoChartData(noData);
    setNoChartOptions(noOptions);
    console.log("valueee", val);
  }, [
    exitedDeals,
    dropdown.fundid,
    dropdown.registerid,
    dropdown.nonregisterid,
  ]);

  exitedDeals === undefined
    ? console.log("accountValues")
    : console.log("accountValues 2", exitedDeals?.existedCount, val);
  return (
    <div className="card">
      {" "}
      {accountValues?.totalNonProfitable === 0 &&
      accountValues?.totalProfitable === 0 ? (
        <></>
      ) : (
        <>
          {accountValues !== undefined ? (
            <div>
              <Chart
                type="doughnut"
                data={chartData}
                options={chartOptions}
                className="w-full md:w-rem flex justify-content-center"
                style={{ width: "250px", height: "250px" }} // Set custom width and height
              />
              <div className="DoughnutChartData d-flex flex-column text-center">
                <div>
                  <span className="Doughnutcharttext ">{lab}</span>
                </div>
                <div className="d-flex">
                  <span className="Doughnutcharttext">{val} Deals</span>{" "}
                  {/* <span className="Doughnutcharttext">Deals </span> */}
                  {valPercent ? (
                    <>
                      {" "}
                      <span className="Doughnutcharttext">
                        | {Number(valPercent).toFixed(2)}
                      </span>
                      <span className="Doughnutcharttext">%</span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              {" "}
              <Chart
                type="doughnut"
                data={noChartData}
                options={noChartOptions}
                className="w-full md:w-rem flex justify-content-center"
                style={{ width: "250px", height: "250px" }} // Set custom width and height
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
