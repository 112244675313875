import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import React from "react";

interface IProps {
  labelData: any;
  handleTaxFormChange: any;
  k1InputLabels: any;
  scrollDisable: any;
}

export default function K1InputFormPart3(props: IProps) {
  const { labelData, handleTaxFormChange, scrollDisable } = props;

  const header = () => {
    return (
      <>
        <div className="m-1 flex flex-row col-12">
          <div className=" m-2 inputLabel ">
            {" "}
            <b className="partHeader m-2"> PART III </b> PARTNER&apos;S SHARE OF
            CURRENT YEAR INCOME, DEDUCTIONS, CREDITS, AND OTHER ITEMS
          </div>
        </div>
        <hr className="m-0" />
      </>
    );
  };
  return (
    <Card header={header} className="cardBorder">
      <div className="flex flex-column sm:flex-row">
        <div className="xl:col-6 sm:col-12">
          <div className="col-12 p-2">
            <label className="input-Label">
              (1){" "}
              {labelData.part3 && labelData.part3[0]
                ? labelData.part3[0].labelName
                : ""}
            </label>
            <div className="col-12 inputK1Form12">
              <InputText
                name="txt1CodePartIII"
                id="txt1CodePartIII"
                value={
                  labelData.part3 && labelData.part3[0]
                    ? labelData.part3[0].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[0].generatedRank,
                    "code",
                    labelData.part3[0].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt1PartIII"
                id="txt1PartIII"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[0]
                    ? labelData.part3[0].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[0].generatedRank,
                    "amount",
                    labelData.part3[0].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2">
            <label className="input-Label">
              (2){" "}
              {labelData.part3 && labelData.part3[1]
                ? labelData.part3[1].labelName
                : ""}
            </label>
            <div className="col-12 p-2 inputK1Form12">
              <InputText
                name="txt2CodePartIII"
                id="txt2CodePartIII"
                value={
                  labelData.part3 && labelData.part3[1]
                    ? labelData.part3[1].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[1].generatedRank,
                    "code",
                    labelData.part3[1].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt2PartIII"
                id="txt2PartIII"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[1]
                    ? labelData.part3[1].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[1].generatedRank,
                    "amount",
                    labelData.part3[1].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (3){" "}
              {labelData.part3 && labelData.part3[2]
                ? labelData.part3[2].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt3CodePartIII"
                id="txt3CodePartIII"
                value={
                  labelData.part3 && labelData.part3[2]
                    ? labelData.part3[2].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[2].generatedRank,
                    "code",
                    labelData.part3[2].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt3PartIII"
                id="txt3PartIII"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[2]
                    ? labelData.part3[2].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[2].generatedRank,
                    "amount",
                    labelData.part3[2].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (4){" "}
              {labelData.part3 && labelData.part3[3]
                ? labelData.part3[3].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt4CodePartIII"
                id="txt4CodePartIII"
                value={
                  labelData.part3 && labelData.part3[3]
                    ? labelData.part3[3].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[3].generatedRank,
                    "code",
                    labelData.part3[3].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt4PartIII"
                id="txt4PartIII"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[3]
                    ? labelData.part3[3].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[3].generatedRank,
                    "amount",
                    labelData.part3[3].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (5){" "}
              {labelData.part3 && labelData.part3[4]
                ? labelData.part3[4].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt5CodePartIII"
                id="txt5CodePartIII"
                value={
                  labelData.part3 && labelData.part3[4]
                    ? labelData.part3[4].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[4].generatedRank,
                    "code",
                    labelData.part3[4].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt5PartIII"
                id="txt5PartIII"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[4]
                    ? labelData.part3[4].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[4].generatedRank,
                    "amount",
                    labelData.part3[4].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
                placeholder=""
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (6a){" "}
              {labelData.part3 && labelData.part3[5]
                ? labelData.part3[5].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt6ACodePartIII"
                id="txt6ACodePartIII"
                value={
                  labelData.part3 && labelData.part3[5]
                    ? labelData.part3[5].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[5].generatedRank,
                    "code",
                    labelData.part3[5].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt6APartIII"
                id="txt6APartIII"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[5]
                    ? labelData.part3[5].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[5].generatedRank,
                    "amount",
                    labelData.part3[5].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (6b){" "}
              {labelData.part3 && labelData.part3[6]
                ? labelData.part3[6].labelName
                : ""}
            </label>
          </div>
          <div className="col-12 inputK1Form12">
            <InputText
              name="txt6BCodePartIII"
              id="txt6BCodePartIII"
              value={
                labelData.part3 && labelData.part3[6]
                  ? labelData.part3[6].code
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part3[6].generatedRank,
                  "code",
                  labelData.part3[6].boxName
                )
              }
              className="inputK1Form xl:col-2 sm:col-12"
            />
            <InputText
              name="txt6BPartIII"
              id="txt6BPartIII"
              type="number"
              onFocus={(e: any) => scrollDisable(e)}
              value={
                labelData.part3 && labelData.part3[6]
                  ? labelData.part3[6].amount
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part3[6].generatedRank,
                  "amount",
                  labelData.part3[6].boxName
                )
              }
              className="inputK1Form xl:col-10 sm:col-12"
            />
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (6c){" "}
              {labelData.part3 && labelData.part3[7]
                ? labelData.part3[7].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt6CCodePartIII"
                id="txt6CCodePartIII"
                value={
                  labelData.part3 && labelData.part3[7]
                    ? labelData.part3[7].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[7].generatedRank,
                    "code",
                    labelData.part3[7].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt6CPartIII"
                id="txt6CPart3III"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[7]
                    ? labelData.part3[7].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[7].generatedRank,
                    "amount",
                    labelData.part3[7].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (7){" "}
              {labelData.part3 && labelData.part3[8]
                ? labelData.part3[8].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt7CodePartIII"
                id="txt7CodePartIII"
                value={
                  labelData.part3 && labelData.part3[8]
                    ? labelData.part3[8].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[8].generatedRank,
                    "code",
                    labelData.part3[8].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt7PartIII"
                id="txt7PartIII"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[8]
                    ? labelData.part3[8].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[8].generatedRank,
                    "amount",
                    labelData.part3[8].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (8){" "}
              {labelData.part3 && labelData.part3[9]
                ? labelData.part3[9].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt8CodePartIII"
                id="txt8CodePartIII"
                value={
                  labelData.part3 && labelData.part3[9]
                    ? labelData.part3[9].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[9].generatedRank,
                    "code",
                    labelData.part3[9].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt8PartIII"
                id="txt8PartIII"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[9]
                    ? labelData.part3[9].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[9].generatedRank,
                    "amount",
                    labelData.part3[9].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (9a){" "}
              {labelData.part3 && labelData.part3[10]
                ? labelData.part3[10].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt9ACodePartIII"
                id="txt9ACodePartIII"
                value={
                  labelData.part3 && labelData.part3[10]
                    ? labelData.part3[10].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[10].generatedRank,
                    "code",
                    labelData.part3[10].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt9APartIII"
                id="txt9APartIII"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[10]
                    ? labelData.part3[10].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[10].generatedRank,
                    "amount",
                    labelData.part3[10].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (9b){" "}
              {labelData.part3 && labelData.part3[11]
                ? labelData.part3[11].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt9BCodePartIII"
                id="txt9BCodePartIII"
                value={
                  labelData.part3 && labelData.part3[11]
                    ? labelData.part3[11].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[11].generatedRank,
                    "code",
                    labelData.part3[11].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt9BPartIII"
                id="txt9BPartIII"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[11]
                    ? labelData.part3[11].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[11].generatedRank,
                    "amount",
                    labelData.part3[11].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
                placeholder=""
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (9c){" "}
              {labelData.part3 && labelData.part3[12]
                ? labelData.part3[12].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt9CCodePartIII"
                id="txt9CCodePartIII"
                value={
                  labelData.part3 && labelData.part3[12]
                    ? labelData.part3[12].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[12].generatedRank,
                    "code",
                    labelData.part3[12].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt9CPartIII"
                id="txt9CPartIII"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[12]
                    ? labelData.part3[12].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[12].generatedRank,
                    "amount",
                    labelData.part3[12].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (10){" "}
              {labelData.part3 && labelData.part3[13]
                ? labelData.part3[13].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt10CodePartIII"
                id="txt10CodePartIII"
                value={
                  labelData.part3 && labelData.part3[13]
                    ? labelData.part3[13].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[13].generatedRank,
                    "code",
                    labelData.part3[13].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt10PartIII"
                id="txt10PartIII"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[13]
                    ? labelData.part3[13].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[13].generatedRank,
                    "amount",
                    labelData.part3[13].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (11){" "}
              {labelData.part3 && labelData.part3[14]
                ? labelData.part3[14].labelName
                : ""}
            </label>
            <div className="col-12 pb-1">
              <InputText
                name="txt11CodePartIII1"
                id="txt11CodePartIII1"
                value={
                  labelData.part3 && labelData.part3[14]
                    ? labelData.part3[14].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[14].generatedRank,
                    "code",
                    labelData.part3[14].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt11PartIII1"
                id="txt11PartIII1"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[14]
                    ? labelData.part3[14].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[14].generatedRank,
                    "amount",
                    labelData.part3[14].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt11CodePartIII2"
                id="txt11CodePartIII2"
                value={
                  labelData.part3 && labelData.part3[15]
                    ? labelData.part3[15].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[15].generatedRank,
                    "code",
                    labelData.part3[15].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt11PartIII2"
                id="txt11PartIII2"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[15]
                    ? labelData.part3[15].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[15].generatedRank,
                    "amount",
                    labelData.part3[15].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt11CodePartIII3"
                id="txt11CodePartIII3"
                value={
                  labelData.part3 && labelData.part3[16]
                    ? labelData.part3[16].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[16].generatedRank,
                    "code",
                    labelData.part3[16].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt11PartIII3"
                id="txt11PartIII3"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[16]
                    ? labelData.part3[16].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[16].generatedRank,
                    "amount",
                    labelData.part3[16].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (12){" "}
              {/* {labelData.part1[0].headId === 0
                  ? labelData.part3[15].labelName
                  : labelData.part3[17].labelName} */}
              {labelData.part3 && labelData.part3[17]
                ? labelData.part3[17].labelName
                : ""}
            </label>

            <InputText
              name="txt12CodePartIII"
              id="txt12CodePartIII"
              value={
                labelData.part3 && labelData.part3[17]
                  ? labelData.part3[17].code
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part3[17].generatedRank,
                  "code",
                  labelData.part3[17].boxName
                )
              }
              className="inputK1Form xl:col-2 sm:col-12"
            />
            <InputText
              name="txt12PartIII"
              id="txt12PartIII"
              type="number"
              onFocus={(e: any) => scrollDisable(e)}
              value={
                labelData.part3 && labelData.part3[17]
                  ? labelData.part3[17].amount
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part3[17].generatedRank,
                  "amount",
                  labelData.part3[17].boxName
                )
              }
              className="inputK1Form xl:col-10 sm:col-12"
            />
          </div>

          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (13){" "}
              {/* {labelData.part1[0].headId === 0
                  ? labelData.part3[16].labelName
                  : labelData.part3[18].labelName} */}
              {labelData.part3 && labelData.part3[18]
                ? labelData.part3[18].labelName
                : ""}
            </label>
            <div className="col-12 pb-1">
              <InputText
                name="txt13CodePartIII1"
                id="txt13CodePartIII1"
                value={
                  labelData.part3 && labelData.part3[18]
                    ? labelData.part3[18].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[18].generatedRank,
                    "code",
                    labelData.part3[18].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt13PartIII1"
                id="txt13PartIII1"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[18]
                    ? labelData.part3[18].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[18].generatedRank,
                    "amount",
                    labelData.part3[18].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt13CodePartIII2"
                id="txt13CodePartIII2"
                value={
                  labelData.part3 && labelData.part3[19]
                    ? labelData.part3[19].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[19].generatedRank,
                    "code",
                    labelData.part3[19].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt13PartIII2"
                id="txt13PartIII2"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[19]
                    ? labelData.part3[19].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[19].generatedRank,
                    "amount",
                    labelData.part3[19].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt13CodePartIII3"
                id="txt13CodePartIII3"
                value={
                  labelData.part3 && labelData.part3[20]
                    ? labelData.part3[20].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[20].generatedRank,
                    "code",
                    labelData.part3[20].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt13PartIII3"
                id="txt13PartIII3"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[20]
                    ? labelData.part3[20].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[20].generatedRank,
                    "amount",
                    labelData.part3[20].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (14){" "}
              {/* {labelData.part1[0].headId === 0
                  ? labelData.part3[17].labelName
                  : labelData.part3[21].labelName} */}
              {labelData.part3 && labelData.part3[21]
                ? labelData.part3[21].labelName
                : ""}
            </label>
            <div className="col-12 pb-1">
              <InputText
                name="txt14CodePartIII1"
                id="txt14CodePartIII1"
                value={
                  labelData.part3 && labelData.part3[21]
                    ? labelData.part3[21].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[21].generatedRank,
                    "code",
                    labelData.part3[21].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt14PartIII1"
                id="txt14PartIII1"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[21]
                    ? labelData.part3[21].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[21].generatedRank,
                    "amount",
                    labelData.part3[21].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt14CodePartIII2"
                id="txt14CodePartIII2"
                value={
                  labelData.part3 && labelData.part3[22]
                    ? labelData.part3[22].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[22].generatedRank,
                    "code",
                    labelData.part3[22].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt14PartIII2"
                id="txt14PartIII2"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[22]
                    ? labelData.part3[22].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[22].generatedRank,
                    "amount",
                    labelData.part3[22].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
        </div>
        <div className="xl:col-6 sm:col-12 inputK1Form12">
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (15){" "}
              {/* {labelData.part1[0].headId === 0
                ? labelData.part3[18].labelName
                : labelData.part3[23].labelName} */}
              {labelData.part3 && labelData.part3[23]
                ? labelData.part3[23].labelName
                : ""}
            </label>
            <div className="col-12 pb-1">
              <InputText
                name="txt15CodePartIII1"
                id="txt15CodePartIII1"
                value={
                  labelData.part3 && labelData.part3[23]
                    ? labelData.part3[23].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[23].generatedRank,
                    "code",
                    labelData.part3[23].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt15PartIII1"
                id="txt15PartIII1"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[23]
                    ? labelData.part3[23].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[23].generatedRank,
                    "amount",
                    labelData.part3[23].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt15CodePartIII2"
                id="txt15CodePartIII2"
                value={
                  labelData.part3 && labelData.part3[24]
                    ? labelData.part3[24].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[24].generatedRank,
                    "code",
                    labelData.part3[24].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt15PartIII2"
                id="txt15PartIII2"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[24]
                    ? labelData.part3[24].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[24].generatedRank,
                    "amount",
                    labelData.part3[24].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (16){" "}
              {/* {labelData.part1[0].headId === 0
                ? labelData.part3[19].labelName
                : labelData.part3[25].labelName} */}
              {labelData.part3 && labelData.part3[25]
                ? labelData.part3[25].labelName
                : ""}
            </label>
            <div className="col-12 pb-1">
              <InputText
                name="txt16CodePartIII1"
                id="txt16CodePartIII1"
                value={
                  labelData.part3 && labelData.part3[25]
                    ? labelData.part3[25].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[25].generatedRank,
                    "code",
                    labelData.part3[25].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt16PartIII1"
                id="txt16PartIII1"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[25]
                    ? labelData.part3[25].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[25].generatedRank,
                    "amount",
                    labelData.part3[25].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
                placeholder=""
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt16CodePartIII2"
                id="txt16CodePartIII2"
                value={
                  labelData.part3 && labelData.part3[26]
                    ? labelData.part3[26].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[26].generatedRank,
                    "code",
                    labelData.part3[26].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt16PartIII2"
                id="txt16PartIII2"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[26]
                    ? labelData.part3[26].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[26].generatedRank,
                    "amount",
                    labelData.part3[26].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt16CodePartIII3"
                id="txt16CodePartIII3"
                value={
                  labelData.part3 && labelData.part3[27]
                    ? labelData.part3[27].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[27].generatedRank,
                    "code",
                    labelData.part3[27].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt16PartIII3"
                id="txt16PartIII3"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[27]
                    ? labelData.part3[27].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[27].generatedRank,
                    "amount",
                    labelData.part3[27].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt16CodePartIII4"
                id="txt16CodePartIII4"
                value={
                  labelData.part3 && labelData.part3[28]
                    ? labelData.part3[28].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[28].generatedRank,
                    "code",
                    labelData.part3[28].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt16PartIII4"
                id="txt16PartIII4"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[28]
                    ? labelData.part3[28].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[28].generatedRank,
                    "amount",
                    labelData.part3[28].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt16CodePartIII5"
                id="txt16CodePartIII5"
                value={
                  labelData.part3 && labelData.part3[29]
                    ? labelData.part3[29].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[29].generatedRank,
                    "code",
                    labelData.part3[29].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt16PartIII5"
                id="txt16PartIII5"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[29]
                    ? labelData.part3[29].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[29].generatedRank,
                    "amount",
                    labelData.part3[29].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt16CodePartIII6"
                id="txt16CodePartIII6"
                value={
                  labelData.part3 && labelData.part3[30]
                    ? labelData.part3[30].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[30].generatedRank,
                    "code",
                    labelData.part3[30].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt16PartIII6"
                id="txt16PartIII6"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[30]
                    ? labelData.part3[30].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[30].generatedRank,
                    "amount",
                    labelData.part3[30].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt16CodePartIII7"
                id="txt16CodePartIII7"
                value={
                  labelData.part3 && labelData.part3[31]
                    ? labelData.part3[31].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[31].generatedRank,
                    "code",
                    labelData.part3[31].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt16PartIII7"
                id="txt16PartIII7"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[31]
                    ? labelData.part3[31].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[31].generatedRank,
                    "amount",
                    labelData.part3[31].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>

          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (17){" "}
              {/* {labelData.part1[0].headId === 0
                ? labelData.part3[20].labelName
                : labelData.part3[32].labelName} */}
              {labelData.part3 && labelData.part3[32]
                ? labelData.part3[32].labelName
                : ""}
            </label>
            <div className="col-12 pb-1">
              <InputText
                name="txt17CodePartIII1"
                id="txt17CodePartIII1"
                value={
                  labelData.part3 && labelData.part3[32]
                    ? labelData.part3[32].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[32].generatedRank,
                    "code",
                    labelData.part3[32].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt17PartIII1"
                id="txt17PartIII1"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[32]
                    ? labelData.part3[32].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[32].generatedRank,
                    "amount",
                    labelData.part3[32].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt17CodePartIII2"
                id="txt17CodePartIII2"
                value={
                  labelData.part3 && labelData.part3[33]
                    ? labelData.part3[33].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[33].generatedRank,
                    "code",
                    labelData.part3[33].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt17PartIII2"
                id="txt17PartIII2"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[33]
                    ? labelData.part3[33].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[33].generatedRank,
                    "amount",
                    labelData.part3[33].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt17CodePartIII3"
                id="txt17CodePartIII3"
                value={
                  labelData.part3 && labelData.part3[34]
                    ? labelData.part3[34].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[34].generatedRank,
                    "code",
                    labelData.part3[34].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt17PartIII3"
                id="txt17PartIII3"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[34]
                    ? labelData.part3[34].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[34].generatedRank,
                    "amount",
                    labelData.part3[34].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (18){" "}
              {/* {labelData.part1[0].headId === 0
                ? labelData.part3[21].labelName
                : labelData.part3[35].labelName} */}
              {labelData.part3 && labelData.part3[35]
                ? labelData.part3[35].labelName
                : ""}
            </label>
            <div className="col-12 pb-1">
              <InputText
                name="txt18CodePartIII1"
                id="txt18CodePartIII1"
                value={
                  labelData.part3 && labelData.part3[35]
                    ? labelData.part3[35].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[35].generatedRank,
                    "code",
                    labelData.part3[35].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt18PartIII1"
                id="txt18PartIII1"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[35]
                    ? labelData.part3[35].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[35].generatedRank,
                    "amount",
                    labelData.part3[35].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt18CodePartIII2"
                id="txt18CodePartIII2"
                value={
                  labelData.part3 && labelData.part3[36]
                    ? labelData.part3[36].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[36].generatedRank,
                    "code",
                    labelData.part3[36].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt18PartIII2"
                id="txt18PartIII2"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[36]
                    ? labelData.part3[36].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[36].generatedRank,
                    "amount",
                    labelData.part3[36].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt18CodePartIII3"
                id="txt18CodePartIII3"
                value={
                  labelData.part3 && labelData.part3[37]
                    ? labelData.part3[37].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[37].generatedRank,
                    "code",
                    labelData.part3[37].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt18PartIII3"
                id="txt18PartIII3"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[37]
                    ? labelData.part3[37].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[37].generatedRank,
                    "amount",
                    labelData.part3[37].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (19){" "}
              {/* {labelData.part1[0].headId === 0
                ? labelData.part3[22].labelName
                : labelData.part3[38].labelName} */}
              {labelData.part3 && labelData.part3[38]
                ? labelData.part3[38].labelName
                : ""}
            </label>
            <div className="col-12 pb-1">
              <InputText
                name="txt19CodePartIII1"
                id="txt19CodePartIII1"
                value={
                  labelData.part3 && labelData.part3[38]
                    ? labelData.part3[38].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[38].generatedRank,
                    "code",
                    labelData.part3[38].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt19PartIII1"
                id="txt19PartIII1"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[38]
                    ? labelData.part3[38].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[38].generatedRank,
                    "amount",
                    labelData.part3[38].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt19CodePartIII2"
                id="txt19CodePartIII2"
                value={
                  labelData.part3 && labelData.part3[39]
                    ? labelData.part3[39].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[39].generatedRank,
                    "code",
                    labelData.part3[39].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt19PartIII2"
                id="txt19PartIII2"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[39]
                    ? labelData.part3[39].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[39].generatedRank,
                    "amount",
                    labelData.part3[39].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (20){" "}
              {/* {labelData.part1[0].headId === 0
                ? labelData.part3[23].labelName
                : labelData.part3[40].labelName} */}
              {labelData.part3 && labelData.part3[40]
                ? labelData.part3[40].labelName
                : ""}
            </label>
            <div className="col-12 pb-1">
              <InputText
                name="txt20CodePartIII1"
                id="txt20CodePartIII1"
                value={
                  labelData.part3 && labelData.part3[40]
                    ? labelData.part3[40].code
                    : ""
                }
                //   labelData.part1[0].headId === 0
                //     ? labelData.part3[23].code
                //     : labelData.part3[40].code
                // }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,

                    labelData.part3[40].generatedRank,
                    "code",
                    labelData.part3[40].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt20PartIII1"
                id="txt20PartIII1"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[40]
                    ? labelData.part3[40].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[40].generatedRank,
                    "amount",
                    labelData.part3[40].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt20CodePartIII2"
                id="txt20CodePartIII2"
                value={
                  labelData.part3 && labelData.part3[41]
                    ? labelData.part3[41].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[41].generatedRank,
                    "code",
                    labelData.part3[41].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
                placeholder=""
              />
              <InputText
                name="txt20PartIII2"
                id="txt20PartIII2"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[41]
                    ? labelData.part3[41].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[41].generatedRank,
                    "amount",
                    labelData.part3[41].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt20CodePartIII3"
                id="txt20CodePartIII3"
                value={
                  labelData.part3 && labelData.part3[42]
                    ? labelData.part3[42].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[42].generatedRank,
                    "code",
                    labelData.part3[42].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt20PartIII3"
                id="txt20PartIII3"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[42]
                    ? labelData.part3[42].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[42].generatedRank,
                    "amount",
                    labelData.part3[42].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt20CodePartIII4"
                id="txt20CodePartIII4"
                value={
                  labelData.part3 && labelData.part3[43]
                    ? labelData.part3[43].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[43].generatedRank,
                    "code",
                    labelData.part3[43].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt20PartIII4"
                id="txt20PartIII4"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[43]
                    ? labelData.part3[43].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[43].generatedRank,
                    "amount",
                    labelData.part3[43].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt20CodePartIII5"
                id="txt20CodePartIII5"
                value={
                  labelData.part3 && labelData.part3[44]
                    ? labelData.part3[44].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[44].generatedRank,
                    "code",
                    labelData.part3[44].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt20PartIII5"
                id="txt20PartIII5"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[44]
                    ? labelData.part3[44].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[44].generatedRank,
                    "amount",
                    labelData.part3[44].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 pb-1">
              <InputText
                name="txt20CodePartIII6"
                id="txt20CodePartIII6"
                value={
                  labelData.part3 && labelData.part3[45]
                    ? labelData.part3[45].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[45].generatedRank,
                    "code",
                    labelData.part3[45].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt20PartIII6"
                id="txt20PartIII6"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[45]
                    ? labelData.part3[45].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[45].generatedRank,
                    "amount",
                    labelData.part3[45].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (20Z-1){" "}
              {/* {labelData.part1[0].headId === 0
                ? labelData.part3[24].labelName
                : labelData.part3[44].labelName} */}
              {labelData.part3 && labelData.part3[46]
                ? labelData.part3[46].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt20Z1CodePartIII1"
                id="txt20Z1CodePartIII1"
                value={
                  labelData.part3 && labelData.part3[46]
                    ? labelData.part3[46].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[46].generatedRank,
                    "code",
                    labelData.part3[46].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt20Z1PartIII1"
                id="txt20Z1PartIII1"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[46]
                    ? labelData.part3[46].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[46].generatedRank,
                    "amount",
                    labelData.part3[46].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (20Z-2){" "}
              {/* {labelData.part1[0].headId === 0
                ? labelData.part3[25].labelName
                : labelData.part3[45].labelName} */}
              {labelData.part3 && labelData.part3[47]
                ? labelData.part3[47].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt20Z2CodePartIII1"
                id="txt20Z1CodePartIII1"
                value={
                  labelData.part3 && labelData.part3[47]
                    ? labelData.part3[47].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[47].generatedRank,
                    "code",
                    labelData.part3[47].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt20Z2PartIII1"
                id="txt20Z2PartIII1"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[47]
                    ? labelData.part3[47].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[47].generatedRank,
                    "amount",
                    labelData.part3[47].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
          </div>
          <div className="col-12 p-2 inputK1Form12">
            <label className="input-Label">
              (20Z-3){" "}
              {/* {labelData.part1[0].headId === 0
                ? labelData.part3[26].labelName
                : labelData.part3[46].labelName} */}
              {labelData.part3 && labelData.part3[48]
                ? labelData.part3[48].labelName
                : ""}
            </label>
            <div className="col-12">
              <InputText
                name="txt20Z3CodePartIII1"
                id="txt20Z3CodePartIII1"
                value={
                  labelData.part3 && labelData.part3[48]
                    ? labelData.part3[48].code
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[48].generatedRank,
                    "code",
                    labelData.part3[48].boxName
                  )
                }
                className="inputK1Form xl:col-2 sm:col-12"
              />
              <InputText
                name="txt20Z3PartIII1"
                id="txt20Z3PartIII1"
                type="number"
                onFocus={(e: any) => scrollDisable(e)}
                value={
                  labelData.part3 && labelData.part3[48]
                    ? labelData.part3[48].amount
                    : ""
                }
                onChange={(e) =>
                  handleTaxFormChange(
                    e,
                    labelData.part3[48].generatedRank,
                    "amount",
                    labelData.part3[48].boxName
                  )
                }
                className="inputK1Form xl:col-10 sm:col-12"
              />
            </div>
            <div className="col-12 p-2">
              <label className="input-Label">
                (20Z-4){" "}
                {/* {labelData.part1[0].headId === 0
                  ? labelData.part3[27].labelName
                  : labelData.part3[47].labelName} */}
                {labelData.part3 && labelData.part3[49]
                  ? labelData.part3[49].labelName
                  : ""}
              </label>
              <div className="col-12">
                <InputText
                  name="txt20Z4CodePartIII1"
                  id="txt20Z4CodePartIII1"
                  value={
                    labelData.part3 && labelData.part3[49]
                      ? labelData.part3[49].code
                      : ""
                  }
                  onChange={(e) =>
                    handleTaxFormChange(
                      e,
                      labelData.part3[49].generatedRank,
                      "code",
                      labelData.part3[49].boxName
                    )
                  }
                  className="inputK1Form xl:col-2 sm:col-12"
                />
                <InputText
                  name="txt20Z4PartIII1"
                  id="txt20Z4PartIII1"
                  type="number"
                  onFocus={(e: any) => scrollDisable(e)}
                  // onWheel={(e: any) => document.activeElement.blur()}
                  value={
                    labelData.part3 && labelData.part3[49]
                      ? labelData.part3[49].amount
                      : ""
                  }
                  onChange={(e) =>
                    handleTaxFormChange(
                      e,
                      labelData.part3[49].generatedRank,
                      "amount",
                      labelData.part3[49].boxName
                    )
                  }
                  className="inputK1Form xl:col-10 sm:col-12"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
