import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { addInvestmentStep1Service } from "../../../../services/investment/investment";
import { getInvestmentService } from "../../../../services/investment/investment";
import error from "../../../../utils/error";
import { IInvestmentGetState } from ".";
// import { IAddInvestmentState, IInvestmentData } from ".";

const initialState: IInvestmentGetState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    productDetails: [],
    pageDetails: {
      totalCount: 0,
      totalPages: 0,
    },
  },
};
export const getThunkInvestment = createAsyncThunk(
  "investment/getInvestment",
  async (data: any) => {
    try {
      const response = await getInvestmentService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "investment/getInvestment",
  initialState,
  reducers: {
    setForm: (state, action) => {
      state.data = action.payload;
      //
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkInvestment.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkInvestment.fulfilled, (state, action) => {
      state.loading = "succeeded";

      if (action.payload.data !== null) {
        state.data.productDetails = action.payload.data.investmentsAndDeals;
        state.data.pageDetails = action.payload.data.pageCount;
      } else {
        state.data.productDetails = [];
        state.data.pageDetails!.totalCount = 0;
        state.data.pageDetails!.totalPages = 0;
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;

      // console.log("state.data 1", state.data);
    });
    builder.addCase(getThunkInvestment.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});
export const { setForm } = slice.actions;
export default slice.reducer;
