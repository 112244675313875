import "bootstrap/dist/css/bootstrap.min.css";
import {
  // AuthenticationResult,
  EventMessage,
  // EventPayload,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { msalConfig } from "./b2c/b2c-config";
// import { getConfig } from "./b2c/env-config";
import ErrorBoundary from "./components/error-boundry";
import * as serviceWorker from "./serviceWorker";
import { setVaiable } from "./store/b2c/slice";
import store from "./store/index";
import "gridjs/dist/theme/mermaid.css";
import "./fonts/font.scss";
import { removeConsoleLogs } from "./utils/remove-console";
import { getEnvConfig } from "./utils/sponsor/helper";
import "jspdf";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

const env = getEnvConfig();

// console.log("msalConfig", msalConfig);
// const b2cConfig: any = getConfig(env);
store.dispatch(setVaiable(env));

// console.log(store.getState());
// console.log("b2cConfig", b2cConfig);
const msalInstance = new PublicClientApplication(msalConfig);
// console.log("msalInstance", msalInstance);
// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  // console.log("Msal add token");
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: EventMessage) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS
  ) {
    // console.log("Event Type", event.eventType);
    const payload: any = event.payload;

    localStorage.setItem("token", payload!.idToken);
    msalInstance.setActiveAccount(payload.account);
  }
});
const url = window.location.hostname;
removeConsoleLogs(!url.includes("localhost"));

ReactDOM.render(
  <ErrorBoundary>
    <BrowserRouter>
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </Provider>
    </BrowserRouter>
  </ErrorBoundary>,
  document.querySelector("#root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
