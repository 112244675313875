import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from "react";

interface IProps {
  labelData: any;
  handleTaxFormChange: any;
  k1InputLabels: any;
}

export default function K1InputFormPart1(props: IProps) {
  const { labelData, handleTaxFormChange } = props;
  const [dCheckBox, setDCheckBox] = useState(false);
  // const [onchangedata, setonchange] = useState([]);

  const header = () => {
    return (
      <>
        <div className="m-1 flex flex-row col-12">
          <div className=" m-2 inputLabel ">
            {" "}
            <b className="partHeader m-2"> PART I </b> INFORMATION ABOUT THE
            PARTNERSHIP
          </div>
        </div>
        <hr className="m-0" />
      </>
    );
  };
  useEffect(() => {
    if (labelData.part1.length > 0 && labelData.part1[0].headId !== 0) {
      setDCheckBox(!!labelData.part1[3].textValue);
    }
  }, [labelData]);
  return (
    <>
      <Card header={header} className="cardBorder">
        <div className="col-12 flex flex-column sm:flex-row">
          <div className=" p-2  sm:col-12">
            <label className="input-Label">
              (A) {labelData && labelData.part1[0]?.labelName}
            </label>
            <InputText
              name="txtA"
              id="txtA"
              value={labelData.part1[0].textValue}
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part1[0].generatedRank,
                  "textValue",
                  labelData.part1[0].boxName
                )
              }
              className="inputK1Form"
              placeholder=""
            />
          </div>
        </div>
        <div className="col-12 flex flex-column sm:flex-row">
          <div className=" p-2  sm:col-12">
            <label className="input-Label">
              (B) {labelData && labelData.part1[1]?.labelName}
            </label>
            <InputTextarea
              name="txtB"
              id="txtB"
              rows={5}
              value={labelData.part1[1].textValue}
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part1[1].generatedRank,
                  "textValue",
                  labelData.part1[1].boxName
                )
              }
              className="inputK1Form"
              placeholder=""
            />
          </div>
        </div>
        <div className="col-12 flex flex-column sm:flex-row">
          <div className=" p-2  sm:col-12">
            <label className="input-Label">
              (C) {labelData && labelData.part1[2]?.labelName}
            </label>
            <InputText
              name="txt3"
              id="txt3"
              value={labelData.part1[2].textValue}
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part1[2].generatedRank,
                  "textValue",
                  labelData.part1[2].boxName
                )
              }
              className="inputK1Form"
              placeholder=""
            />
          </div>
        </div>
        <div className="col-12 flex flex-column sm:flex-row">
          <div className=" p-2  sm:col-12">
            <label className="input-Label"> (D)</label>
            <Checkbox
              name="checkD"
              id="checkD"
              onChange={(e) => {
                const updatedValue = !dCheckBox;
                setDCheckBox(!dCheckBox);
                handleTaxFormChange(
                  e,
                  labelData.part1[3].generatedRank,
                  "textValue",
                  labelData.part1[3].boxName,
                  updatedValue === true ? "P" : ""
                );
              }}
              checked={labelData.part1[3].textValue === "P"}
              className=" ml-2"
              placeholder=""
            />

            <label className="input-Label ml-2">
              {labelData && labelData.part1[3]?.labelName}
            </label>
          </div>
        </div>
      </Card>
    </>
  );
}
