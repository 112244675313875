import { Chart } from "primereact/chart";
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
// import { getThunkPortfolio } from "../../store/portfolio/slice";

export default function StackedBar() {
  const [chartData, setChartData] = useState({});
  const [noChartData, setNoChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [noChartOptions, setNoChartOptions] = useState({});

  // const dispatch = useAppDispatch();
  const liquidity = useAppSelector((state) => state.portfolio.liquidities);

  const graph: number[] = [];
  const graphaxix: any[] = [];

  liquidity?.map((item) => {
    return graph.push(+item.actualYear);
  });

  liquidity?.map((item) => {
    return graphaxix.push(+item.capitalInvested);
  });

  useEffect(() => {
    // dispatch(getThunkPortfolio());

    const documentStyle = getComputedStyle(document.documentElement);
    // const textColor = documentStyle.getPropertyValue("--text-color");
    // const textColorSecondary = documentStyle.getPropertyValue(
    //   "--text-color-secondary"
    // );
    // const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: graph,
      datasets: [
        {
          type: "bar",
          label: "$",

          backgroundColor: documentStyle.getPropertyValue("--background-color"),
          data: graphaxix,
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      barPercentage: graph.length > 2 ? 0.5 : 0.1,
      borderRadius: 10,
      // categoryPercentage: 0.5,

      plugins: {
        tooltips: {
          mode: "index",
          intersect: false,
          // callbacks: {
          //   label: (tooltipItem: any, d: any) => {
          //     const value =
          //       d.datasets[tooltipItem.datasetIndex].d[tooltipItem.index];
          //     return `$${value}`;
          //   },
          // },
        },

        legend: false,
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            display: false,
          },
          ticks: {
            callback: (value: any) => `$${value}`, // Add the dollar sign to the tick label
          },
        },
      },
    };

    const noData = {
      // labels: ["Q1", "Q2", "Q3", "Q4"],
      datasets: [
        {
          label: "Sales",
          // data: [540, 325, 702, 620],
          backgroundColor: [documentStyle.getPropertyValue("--noData-color")],

          borderWidth: 1,
        },
      ],
    };
    const noOptions = {
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        tooltips: {
          mode: "index",
          intersect: false,
        },
        legend: false,
      },
    };

    setChartData(data);
    setNoChartData(noData);
    setChartOptions(options);
    setNoChartOptions(noOptions);
  }, [liquidity]);

  return (
    <div className="card">
      {graphaxix.length > 0 ? (
        <Chart type="bar" data={chartData} options={chartOptions} />
      ) : (
        <Chart type="bar" data={noChartData} options={noChartOptions} />
      )}
    </div>
  );
}
