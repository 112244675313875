import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isFundExistService } from "../../../services/profile/profile";
import error from "../../../utils/error";
import { IIsFundExistState } from ".";

const initialState: IIsFundExistState = {
  data: false,
  loading: "loading",
  error: undefined,
};

export const isFundExistThunk = createAsyncThunk(
  "profile/isFundExist",
  async (slug: string = "", thunkAPI) => {
    try {
      console.log("thunkAPI", thunkAPI);
      const response = await isFundExistService(slug);

      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "isFundExist",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(isFundExistThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(isFundExistThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload._response.success;
    });
    builder.addCase(isFundExistThunk.rejected, (state, action) => {
      state.loading = "failed";
      // errorFundlogo
      // action.payload contains error information
      state.error = error(action.payload);
      // state.error = action.payload
    });
  },
});

export default slice.reducer;
