import { Chart } from "primereact/chart";

import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
export default function DoughnutChartDemo() {
  const [chartData, setChartData] = useState({});
  const [noChartData, setNoChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [noChartOptions, setNoChartOptions] = useState({});

  // const [text, setText] = useState<number>();
  // const accountValues = useAppSelector((state) => state.portfolio.accountValue);
  const assets = useAppSelector((state) => state.portfolio.assets);
  // const total = useAppSelector((state) => state.portfolio.accountValueTotal);
  const color = useAppSelector((state) => state.color);

  const amount: any[] = [];
  const amountType: string[] = [];
  const amountPercent: any[] = [];
  const deals: any[] = [];
  const totalAmmount: any[] = [];

  const result = assets?.filter((fruit) => fruit.amountType !== "Total");

  result?.map((item) => {
    return amount.push(item.deals.toString());
  });

  assets?.map((item) => {
    return amountType.push(item.amountType);
  });

  assets?.map((item) => {
    return deals.push(item.deals);
  });
  assets?.map((item) => {
    return item.amountType === "Total" && totalAmmount.push(item.amount);
  });
  assets?.map((item) => {
    return amountPercent.push((item.deals * 100) / totalAmmount[0]);
  });

  const sum = deals.reduce((acc, curr) => acc + curr, 0);

  console.log("sum", sum);

  const [val, setVal] = useState(sum);
  const [lab, setLabel] = useState<any>();
  const [valPercent, setValPercent] = useState<any>();
  console.log("sum 1", val);
  useEffect(() => {
    setVal(sum);
  }, [sum]);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: amountType,
      datasets: [
        {
          data: amount,
          backgroundColor: [
            documentStyle.getPropertyValue("--background-color" || color.Color),
            documentStyle.getPropertyValue(
              "--lighter-color" || color.myColorLight
            ),
            documentStyle.getPropertyValue(
              "--Darker-color" || color.myColorDark
            ),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue(
              "--Darkest-color" || color.myColorDarkest
            ),
            documentStyle.getPropertyValue(
              "--Darkest-color" || color.myColorDarkest
            ),
            documentStyle.getPropertyValue(
              "--Darkest-color" || color.myColorDarkest
            ),
          ],
        },
      ],
    };
    const noData = {
      datasets: [
        {
          data: [100, 0, 0],
          backgroundColor: [
            documentStyle.getPropertyValue(
              "--noData-color" || localStorage.getItem("--noData-color")
            ),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue(
              "--noData-color" || localStorage.getItem("--noData-color")
            ),
          ],
        },
      ],
    };
    const options = {
      tooltips: false,

      onBlur: () => {},
      cutout: "80%",
      responsive: true,
      plugins: {
        tooltip: {
          enabled: false, // <-- this option disables tooltips
        },
        legend: false,
      },

      onHover: (
        _event: any,
        chartElement: {
          [x: string]: any;
          label: any;
        }[]
      ) => {
        if (chartElement && chartElement[0]) {
          const value =
            data.datasets[chartElement[0].datasetIndex].data[
              chartElement[0].index
            ];
          const label = data.labels[chartElement[0].index];
          // JSON.stringify(data);

          const valPer = (value * 100) / deals[0];
          setValPercent(valPer);
          setVal(value);
          setLabel(label);
        } else {
          setVal(sum);
          setLabel(lab);
          setValPercent(valPercent);
        }
      },
    };

    const noOptions = {
      cutout: "80%",
      responsive: true,
      plugins: {
        tooltip: {
          enabled: false, // <-- this option disables tooltips
        },
      },
    };
    setChartData(data);
    setChartOptions(options);
    setNoChartData(noData);
    setNoChartOptions(noOptions);
  }, [assets]);
  useEffect(() => {}, [val]);

  return (
    <div className="card">
      {deals[0] === 0 ? (
        <></>
      ) : (
        <>
          {amount.length > 0 ? (
            <div>
              <Chart
                type="doughnut"
                data={chartData}
                options={chartOptions}
                className="w-full md:w-rem flex justify-content-center"
                style={{ width: "250px", height: "250px" }} // Set custom width and height
              />
              <div className="DoughnutChartData d-flex flex-column text-center">
                <div>
                  <span className="Doughnutcharttext ">{lab}</span>
                </div>
                <div className="d-flex">
                  <span className="Doughnutcharttext mx-2">{val} Deals</span>{" "}
                  {/* <span className="Doughnutcharttext">Deals </span> */}
                  {valPercent ? (
                    <>
                      {" "}
                      <span className="Doughnutcharttext">
                        | {Number(valPercent).toFixed(2)}
                      </span>
                      <span className="Doughnutcharttext">%</span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <Chart
                type="doughnut"
                data={noChartData}
                options={noChartOptions}
                className="w-full md:w-rem flex justify-content-center"
                style={{ width: "250px", height: "250px" }} // Set custom width and height
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
