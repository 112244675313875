import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import AmountDisplay from "../../../components/amount-display";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
import Loader from "../../../components/loader/Loader";
import { getAllocatedPoolFundsByInvestorId } from "../../../services/PoolFund";
import { useAppSelector } from "../../../store/hooks";
import {
  CurrentAllocationType,
  PoolFundOutPutType,
  PoolFundsByInvestorIdParamType,
} from "../../../types";
import currencyFormat from "../../../utils/currency-formatter";
import { convertUTCToLocalDate } from "../../../utils/date-formatter";

const CurrentAllocation: React.FC = () => {
  const toast = useRef<Toast>(null);
  const [isOpenDailog, setIsOpenDailog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [investerStakeData, setInvestorStakeData] = useState<
    PoolFundOutPutType[]
  >([]);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const addPoolFundData = useAppSelector((state) => state.AddPoolFunds.data);
  const currentAllocation = useAppSelector(
    (state) => state.getAllAllocatedInvesterPoolFund.data.currentAllocation
  );

  const getReverseStake = async (param: PoolFundsByInvestorIdParamType) => {
    setLoading(true);
    try {
      const response = await getAllocatedPoolFundsByInvestorId(param);
      if (response.data.success) {
        const list = [...response.data.data.output];
        setInvestorStakeData(list);
        setIsOpenDailog(true);
      } else {
        setInvestorStakeData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setInvestorStakeData([]);
      console.log(error);
    }
  };

  const viewStake = (rowData: CurrentAllocationType) => {
    const handleViewStake = async () => {
      try {
        const param = {
          legalEntityId: fundDropdown.fundid,
          investmentId: addPoolFundData.investmentId,
          lPPartnerId: rowData.lpPartnerID,
        };
        getReverseStake(param);
      } catch (error) {
        setInvestorStakeData([]);
        console.log("error", error);
      }
    };
    return (
      <Button
        icon="pi pi-eye"
        className="btn-nav"
        title="View Invested Balance"
        onClick={(e) => {
          e.preventDefault();
          handleViewStake();
        }}
      />
    );
  };

  const lastWithdraw = (rowData: CurrentAllocationType) => {
    return (
      <>
        {rowData.withrawalAmount ? (
          <div className="last-withdraw">
            {currencyFormat(rowData.withrawalAmount)}
            <br />
            <span>
              (Withdrawl processed on{" "}
              {convertUTCToLocalDate(rowData.lastWithrawalDate)})
            </span>
          </div>
        ) : (
          <div className="last-withdraw text-dark">
            N/A
            <br />
            <span>(no withdrawals from pool)</span>
          </div>
        )}
      </>
    );
  };

  const dataTableElement = [
    {
      field: "lpPartnerName",
      header: "Investor Name",
    },
    {
      field: "total",
      header: "Investment Balance",
      body: (rowData: CurrentAllocationType) => currencyFormat(rowData.total),
    },
    {
      field: "capitalAmount",
      header: "Capital Amount",
      body: (rowData: CurrentAllocationType) =>
        currencyFormat(rowData.capitalAmount),
    },
    {
      field: "reinvestAmount",
      header: "Reinvest Amount",
      body: (rowData: CurrentAllocationType) =>
        currencyFormat(rowData.reinvestAmount),
    },
    {
      header: "Withdrwal",
      body: lastWithdraw,
    },
    { header: "View Invested Balance", body: viewStake },
  ];
  const dataTableElementStake = [
    {
      field: "lpPartnerName",
      header: "Investor Name",
      noFilter: true,
    },

    {
      field: "units",
      header: "Allocated Investment Balance",
      body: (rowData: PoolFundOutPutType) => (
        <AmountDisplay amount={rowData?.addOnUnits ? rowData?.addOnUnits : 0}>
          {currencyFormat(rowData.addOnUnits)}
        </AmountDisplay>
      ),
    },
    { field: "unitsType", header: "Units Type" },
    {
      field: "maxStartDate",
      header: "Start Date",
      dataType: "date",
      body: (rowData: PoolFundOutPutType) =>
        convertUTCToLocalDate(rowData.maxStartDate),
    },
    {
      field: "endDate",
      header: "End Date",
      dataType: "date",
      body: (rowData: PoolFundOutPutType) =>
        rowData.endDate ? convertUTCToLocalDate(rowData.endDate) : "N/A",
    },
    { field: "assetClass", header: "Class" },
  ];

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <Dialog
        visible={isOpenDailog}
        onHide={() => {
          setIsOpenDailog(false);
          setInvestorStakeData([]);
        }}
        className="w-100 md:w-10 pb-1"
        headerClassName="no-header"
      >
        <div className="mt-2">
          <h4 className="pageHeader mt-2 flex justify-content-center">
            Invested Balance
          </h4>
          <div className="mt-2">
            <DataTableComponent
              valueData={investerStakeData || []}
              fieldsElements={dataTableElementStake}
              className="allocateSliceTable"
              noGridLines={true}
            />
          </div>
        </div>
      </Dialog>
      <DataTableComponent
        valueData={currentAllocation || []}
        fieldsElements={dataTableElement}
        noGridLines={true}
        isPaginationAllowed={true}
      />
    </>
  );
};

export default CurrentAllocation;
