import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SaveReconciliationStatusService } from "../../../../services/Accounting/accounting";
import error from "../../../../utils/error";
import {
  ISaveReconciliationStatusState,
  ISaveReconciliationStatusparameters,
} from ".";

const initialState: ISaveReconciliationStatusState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const SaveReconciliationStatusThunk = createAsyncThunk(
  "SaveReconciliationStatus",
  async (data: ISaveReconciliationStatusparameters) => {
    try {
      const response = await SaveReconciliationStatusService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "SaveReconciliationStatus",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(SaveReconciliationStatusThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      SaveReconciliationStatusThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload.data
          ? action.payload.data[0].totalMonthlyExpenses
          : [];
      }
    );
    builder.addCase(SaveReconciliationStatusThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
