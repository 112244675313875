import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadAcrredFileLink } from "../../../../../../services/investor/investor";
import error from "../../../../../../utils/error";
import { IUploadAccredPara } from ".";

interface IEsignDocument {
  data?: string;
  // statusData?: IRedotResponse;
  loading: string;
  error?: string | null;
}

const initialState: IEsignDocument = {
  data: "",
  // statusData: undefined,
  loading: "loading",
  error: undefined,
};
export const uploadAcrredFileThunk = createAsyncThunk(
  "accredutation/uploadAcrredFile",
  async (data: IUploadAccredPara) => {
    try {
      const response = await uploadAcrredFileLink(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "accredutation/uploadAcrredFile",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(uploadAcrredFileThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(uploadAcrredFileThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data.pdfLetterUrl;
      // state.statusData = action.payload.data.statusData[0];
    });
    builder.addCase(uploadAcrredFileThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
      throw state.error;
    });
  },
});

export default slice.reducer;
