import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { addInvestmentStep1Service } from "../../../../services/investment/investment";
import { createFolderFilemanager } from "../../../../services/Filemanger/filemanager";
import error from "../../../../utils/error";
import { initialAddFolderState } from "./payload";
import { IAddFolderData, IAddFolderState } from ".";

const initialState: IAddFolderState = {
  success: false,
  message: "",
  loading: "loading",
  result: undefined,
  error: "",
  data: initialAddFolderState,
};
export const createFolderFilemanagerThunk = createAsyncThunk(
  "fileManagement/CreateFolderFM",
  async (data: IAddFolderData) => {
    try {
      const response = await createFolderFilemanager(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "addfolder",
  initialState,
  reducers: {
    setForm: (state, action) => {
      state.data = action.payload;
    },
    // setFormInitial: (state, action) => {
    //   console.log("op", action.payload);
    //   state = action.payload;
    //   console.log("s", state.data);
    //   //
    // },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(createFolderFilemanagerThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(createFolderFilemanagerThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";

      if (action.payload.data !== null) {
        state.data = action.payload.data.productDetails[0];
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(createFolderFilemanagerThunk.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
      throw new Error(error(state.error));
    });
  },
});
export const { setForm } = slice.actions;
export default slice.reducer;
