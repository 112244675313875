import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllocatedInvestorsByInvestmentPoolfunds } from "../../../services/PoolFund";
import error from "../../../utils/error";
import { GetAllocatedInvesterType } from "./type";

const initialState: GetAllocatedInvesterType = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    pooledFundsInvestmentDetails: [],
    output: [],
    currentAllocation: [],
  },
};
export const getAllocatedInvestorsByInvestmentPoolfundsThunk = createAsyncThunk(
  "getAllocatedInvestorsByInvestmentPoolFunds",
  async (data: any) => {
    try {
      console.log("data", data);
      const response = await getAllocatedInvestorsByInvestmentPoolfunds(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getAllocatedInvestorsByInvestmentPoolFunds",
  initialState,
  reducers: {
    resetAllocaterInvestoresByInvestmentPoolFund(state) {
      state.data = initialState.data;
    },
  },
  extraReducers(builder): void {
    builder.addCase(
      getAllocatedInvestorsByInvestmentPoolfundsThunk.pending,
      (state) => {
        state.loading = "loading";
      }
    );
    builder.addCase(
      getAllocatedInvestorsByInvestmentPoolfundsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(
          "hr getAllocatedInvestorsByInvestmentPoolfundsThunk",
          action.payload.success
        );
        state.data = action.payload.success
          ? action.payload.data
          : initialState.data;
        state.success = action.payload.success;
        state.message = action.payload.message;
      }
    );
    builder.addCase(
      getAllocatedInvestorsByInvestmentPoolfundsThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        throw new Error(error(action.error));
      }
    );
  },
});
export const { resetAllocaterInvestoresByInvestmentPoolFund } = slice.actions;
export default slice.reducer;
