import { FormikValues } from "formik";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import ShowDocumentDailogbox from "../../../../components/dialogbox/show-document-dialogbox/ShowDocumentDailogbox";
import Loader from "../../../../components/loader/Loader";
import { getIAEnrollmentDataThunk } from "../../../../store/Investor/investor-approval/verify-agreement/digi-signer/get-ia-enrollment-data/slice";
import { getDocumentLinkSignatureDualSignerThunk } from "../../../../store/Investor/investor-approval/verify-agreement/get-document-link-signature-dual-signer/slice";
import { getStatusAndFlagThunk } from "../../../../store/features/investor/get-investor-status/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getSasKeyThunk } from "../../../../store/sas-key/slice";
import { setFormikFieldsForAccredation } from "../../../../utils/investing-approval/multi-investing-account/set-formik-data";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

interface IProps {
  investorId: number;
  legalEntityId: number;
  investingAccountId: number;
  formik: FormikValues;
  isFormFieldInvalid: any;
  getFormErrorMessage: any;
}

const FundLegalDocuments: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();

  const [visibleShowDocument, setVisibleShowDocument] = useState(false);
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState(false);

  const [sasKey, setsasKey] = useState("");

  const toast = useRef<Toast>(null);

  const [resetTab, setResetTab] = useState(0);

  const getIAEnrollmentDataLinkData = useAppSelector(
    (state) => state.getIAEnrollmentData.data
  );
  const getIAEnrollmentDataLinkState = useAppSelector(
    (state) => state.getIAEnrollmentData.loading
  );
  const getDocumentLinkSignatureDualSignerData = useAppSelector(
    (state) => state.getDocumentLinkSignatureDualSigner.data
  );

  const payload = {
    investorId: props.investorId,
    legalEntityId: props.legalEntityId,
    investingAccountId: props.investingAccountId,
  };
  useEffect(() => {
    dispatch(getIAEnrollmentDataThunk(payload));
  }, [props.investorId, props.legalEntityId, props.investingAccountId]);

  useEffect(() => {
    if (getIAEnrollmentDataLinkData && props.formik) {
      // console.log("getIAEnrollmentDataLinkData", getIAEnrollmentDataLinkData);
      setFormikFieldsForAccredation(props.formik, getIAEnrollmentDataLinkData);
    }
  }, [getIAEnrollmentDataLinkData]);

  const onConfirm = () => {
    setConfirmDialog(false);
    setLoader(true);
    setResetTab(1);
    const obj = { ...payload, isResend: true };
    dispatch(getDocumentLinkSignatureDualSignerThunk(obj))
      .then(() => {
        dispatch(getIAEnrollmentDataThunk(payload));
        dispatch(
          getStatusAndFlagThunk({
            InvestorId: props.investorId,
            LegalEntityId: props.legalEntityId,
            InvestingAccountId: props.investingAccountId,
          })
        );
        setLoader(false);
        successToastMessage(toast, "Email sent successfully ");
      })
      .catch(() => {
        setLoader(false);
        errorToastMessage(toast);
      })
      .finally(() => {
        setResetTab(0);
      });
  };
  const GetInvestorSignatureStatus = () => {
    if (
      (getIAEnrollmentDataLinkData?.isSignCompleted === true &&
        getIAEnrollmentDataLinkData?.signer === "Signer 1") ||
      getIAEnrollmentDataLinkData?.signer === "Signer 2"
    ) {
      return (
        <Button
          // className="btn-nav d-block  d-flex justify-content-center"
          className="BtnBlue d-block  d-flex justify-content-center my-0"
          onClick={(e) => {
            e.preventDefault();
            console.log("Send email");
            setConfirmDialog(true);
          }}
        >
          RESEND FOR SIGNATURE
        </Button>
      );
    }

    if (props.investingAccountId) {
      return (
        <Button
          // className="btn-nav d-block  d-flex justify-content-center"
          className="BtnBlue d-block  d-flex justify-content-center my-0"
          onClick={(e) => {
            e.preventDefault();
            setLoader(true);
            console.log("Send email");
            const obj = { ...payload, isResend: false };
            dispatch(getDocumentLinkSignatureDualSignerThunk(obj))
              .then(() => {
                setLoader(false);
                successToastMessage(toast, "Email sent successfully ");
              })
              .catch(() => {
                setLoader(false);
                errorToastMessage(toast);
              });
          }}
        >
          SEND EMAIL
        </Button>
      );
    }
    return <></>;
  };

  const GetManagerSignatureStatus = () => {
    if (
      getIAEnrollmentDataLinkData?.isDualSigner === true &&
      getIAEnrollmentDataLinkData?.signer === "Signer 2" &&
      getIAEnrollmentDataLinkData?.isSignCompleted === true
    ) {
      return (
        <div className="grid">
          <div className="field col-12 md:col-6 lg:col-4 text-left">
            <label htmlFor="accountType" className="inputLabel">
              Fund Manager Signature Status:
            </label>
            {/* Signed */}
            <Message severity="success" text="SIGNED" />
          </div>
        </div>
      );
    }

    if (
      (getIAEnrollmentDataLinkData?.isDualSigner === true &&
        getIAEnrollmentDataLinkData?.isSignCompleted === true &&
        getIAEnrollmentDataLinkData?.signer === "Signer 1") ||
      (getIAEnrollmentDataLinkData?.isDualSigner === true &&
        getIAEnrollmentDataLinkData?.signer === "Signer 2" &&
        getIAEnrollmentDataLinkData?.isSignCompleted === false)
    ) {
      return (
        <div className="formgrid grid">
          <div className="field col-12 md:col-6 lg:col-4">
            <label htmlFor="accountType" className="inputLabel">
              Fund Manager Signature Status:
            </label>
            {/* Signed */}
            <Message severity="error" text="NOT SIGNED" />
          </div>
          <div className="field col-12 md:col-6 lg:col-4  lg:mx-3">
            <label htmlFor="accountType" className="inputLabel">
              &nbsp;
            </label>
            <Button
              // label="REVIEW/SIGN AGREEMENT"
              className="BtnBlue  d-block  d-flex justify-content-center col-12 m-0"
              onClick={(e) => {
                e.preventDefault();
                console.log("Review Review/Sign Agreement");
                setLoader(true);
                const obj = { ...payload, isResend: false };
                dispatch(getDocumentLinkSignatureDualSignerThunk(obj))
                  .then(() => {
                    setVisible(true);
                    setLoader(false);
                    // toast.current?.show({
                    //   severity: "success",
                    //   summary: "success",
                    //   detail: "Email sent successfully ",
                    //   life: 3000,
                    // });
                    dispatch(
                      getStatusAndFlagThunk({
                        InvestorId: props.investorId,
                        LegalEntityId: props.legalEntityId,
                        InvestingAccountId: props.investingAccountId,
                      })
                    );
                  })
                  .catch(() => {
                    setLoader(false);
                    toast.current?.show({
                      severity: "error",
                      summary: "Error",
                      detail: "OOPs something went wrong",
                      life: 3000,
                    });
                  });
              }}
            >
              REVIEW/SIGN AGREEMENT
            </Button>
          </div>
        </div>
      );
    }
    return <></>;
  };
  const generateSASKey = (legalEntityId: any) => {
    return dispatch(getSasKeyThunk(legalEntityId))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          return response.payload.data.sasKey;
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const openDigiSignDoc = async (e: any) => {
    e.preventDefault();
    //    alert("aa --", getESignData?.isSignatureCompleted!);
    // if (getESignData?.isSignatureCompleted) {
    // alert(uploadedAccredDocumentLink);
    let key = "";
    setLoader(true);
    key = await generateSASKey(props.legalEntityId);
    // setsasKey(key);
    setsasKey(key);
    // }
    // setVisible(true);
    setVisibleShowDocument(true);
    console.log(getDocumentLinkSignatureDualSignerData);
    console.log(visible);
  };

  const onDialogClose = () => {
    setLoader(true);
    setVisible(false);

    setTimeout(() => {
      dispatch(getIAEnrollmentDataThunk(payload))
        .then(() => {
          dispatch(
            getStatusAndFlagThunk({
              InvestorId: props.investorId,
              LegalEntityId: props.legalEntityId,
              InvestingAccountId: props.investingAccountId,
            })
          );
        })
        .finally(() => {
          setLoader(false);
        });
    }, 1000 * 10);
  };

  const onShowDocumentDialogClose = () => {
    setVisibleShowDocument(false);
  };

  return (
    <>
      {resetTab ? (
        <Skeleton width="100%" height="100px"></Skeleton>
      ) : (
        <>
          {" "}
          <Accordion activeIndex={[]} className="my-3">
            <AccordionTab
              header="Fund Legal Documents"
              // disabled={
              //   (+props.investingAccountId === 0 ||
              //     (typeof +props.investingAccountId !== "number" &&
              //       !getIAEnrollmentDataLinkData?.isSignCompleted &&
              //       getIAEnrollmentDataLinkData?.signer === "Signer 1") ||
              //     (getIAEnrollmentDataLinkData?.signer === "" &&
              //       getIAEnrollmentDataLinkData?.isOlduser !== true)) ??
              //   false
              // }
            >
              <div className="mb-4">
                <h4 className="sectionHeader">Fund Legal Documents</h4>
                {getIAEnrollmentDataLinkState === "succeeded" && (
                  <div className="fromSection">
                    <div className="formgrid grid">
                      {getIAEnrollmentDataLinkData?.isOlduser ? (
                        <div className="field col-12 text-left">
                          <label htmlFor="accountType" className="inputLabel">
                            Investor signature Status{" "}
                          </label>
                          <p>
                            Investor has signed agreements using Signnow. Signed
                            copies were send via email
                          </p>
                        </div>
                      ) : (
                        <>
                          <div className="field col-12 md:col-6 lg:col-4">
                            <label className="inputLabel">
                              Investor Signature Status:
                            </label>

                            <div className=" align-self-center ">
                              {(getIAEnrollmentDataLinkData?.isSignCompleted ===
                                true &&
                                getIAEnrollmentDataLinkData?.signer ===
                                  "Signer 1") ||
                              getIAEnrollmentDataLinkData?.signer ===
                                "Signer 2" ? (
                                <Message severity="success" text="SIGNED" />
                              ) : (
                                ""
                                // <Message severity="error" text="NOT SIGNED" />
                              )}
                              {getIAEnrollmentDataLinkData?.isSignCompleted ===
                                false &&
                                (getIAEnrollmentDataLinkData?.signer ===
                                  "Signer 1" ||
                                  getIAEnrollmentDataLinkData?.signer ===
                                    "") && (
                                  <Message severity="error" text="NOT SIGNED" />
                                )}
                              {props.getFormErrorMessage("isDualSigner")}
                            </div>
                          </div>
                          <div className="field col-12 md:col-6 lg:col-2">
                            <label className="inputLabel">&nbsp;</label>
                            <div className="align-self-center flex md:mx-3">
                              <Button
                                className="BtnBlue  d-flex justify-content-center my-0"
                                disabled={
                                  typeof getIAEnrollmentDataLinkData?.signDocumentLink !==
                                  "string"
                                }
                                // onClick={(e) => {
                                //   e.preventDefault();
                                //   console.log();
                                //   if (
                                //     typeof getIAEnrollmentDataLinkData?.signDocumentLink ===
                                //     "string"
                                //   ) {
                                //     setVisibleShowDocument(true);
                                //   }
                                // }}
                                onClick={(e) => openDigiSignDoc(e)}
                              >
                                VIEW
                              </Button>
                            </div>
                          </div>
                          <div className="field col-12 md:col-6 lg:col-3">
                            <label className="inputLabel ">&nbsp;</label>
                            <div className="align-self-center flex d-block d-flex">
                              <GetInvestorSignatureStatus />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <GetManagerSignatureStatus />
                  </div>
                )}
                {getIAEnrollmentDataLinkState === "loading" && (
                  <Skeleton width="100%" height="100px"></Skeleton>
                )}
              </div>
            </AccordionTab>
          </Accordion>
        </>
      )}

      {visibleShowDocument && (
        <ShowDocumentDailogbox
          openDialog={visibleShowDocument}
          setOpenDialog={setVisibleShowDocument}
          documentLink={getIAEnrollmentDataLinkData?.signDocumentLink + sasKey}
          onDialogClose={onShowDocumentDialogClose}
        />
      )}

      {visible && (
        <ShowDocumentDailogbox
          openDialog={visible}
          setOpenDialog={setVisible}
          documentLink={getDocumentLinkSignatureDualSignerData?.responseURL}
          onDialogClose={onDialogClose}
        />
      )}

      {confirmDialog && (
        <ConfirmDailogbox
          openDialog={confirmDialog}
          setOpenDialog={setConfirmDialog}
          onConfirm={onConfirm}
          onCancel={() => {
            setConfirmDialog(false);
          }}
          textContent="After clicking on resend, you will need to wait for Investor to sign / re-sign the agreement before you can approve this step. Are you OK to proceed?"
        />
      )}
      {loader && <Loader />}
      <Toast ref={toast} className="themeToast" />
    </>
  );
};

export default FundLegalDocuments;
