import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetDistributeEarningsService,
  GetInvestmentReturnsAndTypeService,
} from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IGetEarningandReturnTypeState } from ".";

const initialState: IGetEarningandReturnTypeState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    investmentReturns: [],
    returnType: [],
  },
};
export const getThunkAllocateEarning = createAsyncThunk(
  "getAllocateEarning/getEarnings",
  async (data: any) => {
    try {
      const response = await GetInvestmentReturnsAndTypeService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const getThunkDistributeEarning = createAsyncThunk(
  "getAllocateEarning/distributeEarnings",
  async (data: any) => {
    try {
      console.log("data", data);
      const response = await GetDistributeEarningsService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getAllocateEarning",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkAllocateEarning.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkAllocateEarning.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("hr", action.payload._response);
      if (action.payload._response.data !== null) {
        state.data.investmentReturns =
          action.payload._response.data.investmentReturns;
        state.data.returnType =
          action.payload._response.data.investmentReturnType;
      }
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(getThunkAllocateEarning.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
    builder.addCase(getThunkDistributeEarning.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkDistributeEarning.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("distribute", action.payload._response);
      if (action.payload._response.data !== null) {
        state.data.investmentReturns =
          action.payload._response.data.investmentReturns;
        state.data.returnType =
          action.payload._response.data.investmentReturnType;
      }
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(getThunkDistributeEarning.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
