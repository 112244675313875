import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from "react";
interface IProps {
  labelData: any;
  handleTaxFormChange: any;
  k1InputLabels: any;
  scrollDisable: any;
}

export default function K1InputFormPart2(props: IProps) {
  const { labelData, handleTaxFormChange, k1InputLabels, scrollDisable } =
    props;
  const [mCheckBox, setMCheckBox] = useState(false);
  const [mCheckBox2, setMCheckBox2] = useState(false);
  const [gCheckBox1, setGCheckBox1] = useState(false);
  const [gCheckBox2, setGCheckBox2] = useState(false);
  const [hCheckBox1, setHCheckBox1] = useState(false);
  const [hCheckBox2, setHCheckBox2] = useState(false);
  const [i2CheckBox, setI2CheckBox] = useState(false);
  const [taxCheckBox, setTaxCheckBox] = useState(false);
  const [gaapCheckBox, setGaapCheckBox] = useState(false);
  const [section704CheckBox, setSection704CheckBox] = useState(false);
  const [otherCheckBox, setOtherCheckBox] = useState(false);

  const header = () => {
    return (
      <>
        <div className="m-1 flex flex-row col-12">
          <div className=" m-2 inputLabel ">
            {" "}
            <b className="partHeader m-2"> PART II </b> INFORMATION ABOUT THE
            PARTNER
          </div>
        </div>
        <hr className="m-0" />
      </>
    );
  };
  useEffect(() => {
    if (
      k1InputLabels.length > 0 &&
      k1InputLabels[0].headId !== 0 &&
      labelData.part2.length > 0 &&
      labelData.part2[0].headId !== 0
    ) {
      setGCheckBox1(!!labelData.part2[2]?.textValue);
      setGCheckBox2(!!labelData.part2[3]?.textValue);
      setHCheckBox1(!!labelData.part2[4]?.textValue);
      setHCheckBox2(!!labelData.part2[5]?.textValue);
      setI2CheckBox(!!labelData.part2[7]?.textValue);
      setTaxCheckBox(!!labelData.part2[22]?.textValue);
      setGaapCheckBox(!!labelData.part2[23]?.textValue);
      setSection704CheckBox(!!labelData.part2[24]?.textValue);
      setOtherCheckBox(!!labelData.part2[25]?.textValue);

      setMCheckBox(!!labelData.part2[26]?.textValue);
      setMCheckBox2(!!labelData.part2[27]?.textValue);
    }
  }, [labelData]);

  return (
    <Card header={header} className="cardBorder mt-2">
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2  sm:col-12">
          <label className="input-Label">
            (E){" "}
            {labelData.part2 && labelData.part2[0]
              ? labelData.part2[0]?.labelName
              : ""}
          </label>
          <InputText
            name="txtE"
            id="txtE"
            value={
              labelData.part2 && labelData.part2[0]
                ? labelData.part2[0]?.textValue
                : ""
            }
            onChange={(e) =>
              handleTaxFormChange(
                e,
                labelData.part2[0].generatedRank,
                "textValue",
                labelData.part2[0].boxName
              )
            }
            className="inputK1Form"
            placeholder=""
          />
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2  sm:col-12">
          <label className="input-Label">
            (F){" "}
            {labelData.part2 && labelData.part2[1]
              ? labelData.part2[1].labelName
              : ""}
          </label>
          <InputTextarea
            name="txtF"
            id="txtF"
            rows={5}
            value={
              labelData.part2 && labelData.part2[1]
                ? labelData.part2[1]?.textValue
                : ""
            }
            onChange={(e) =>
              handleTaxFormChange(
                e,
                labelData.part2[1].generatedRank,
                "textValue",
                labelData.part2[1].boxName
              )
            }
            className="inputK1Form"
            placeholder=""
          />
        </div>
      </div>

      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2  sm:col-12 flex flex-column sm:flex-row ">
          <div className="xl:col-1 sm:col-12">
            <label className="input-Label"> (G)</label>
          </div>
          <div className="xl:col-5 sm:col-12 flex">
            <Checkbox
              name="checkG1"
              id="checkG1"
              onChange={(e) => {
                const updatedValue = !gCheckBox1;

                setGCheckBox1(!gCheckBox1);
                handleTaxFormChange(
                  e,
                  labelData.part2[2].generatedRank,
                  "textValue",
                  labelData.part2[2].boxName,
                  updatedValue === true ? "G1" : ""
                );
              }}
              checked={
                labelData.part2 &&
                labelData.part2[2] &&
                labelData.part2[2]?.textValue === "G1"
              }
              className=" ml-2"
            />
            <label className="input-Label ml-2">
              {labelData.part2 && labelData.part2[2]
                ? labelData.part2[2].labelName
                : ""}
            </label>
          </div>
          <div className="xl:col-6 sm:col-12 flex">
            <>
              <Checkbox
                name="checkG2"
                id="checkG2"
                onChange={(e) => {
                  const updatedValue = !gCheckBox2;
                  setGCheckBox2(!gCheckBox2);
                  handleTaxFormChange(
                    e,
                    labelData.part2[3].generatedRank,
                    "textValue",
                    labelData.part2[3].boxName,
                    updatedValue === true ? "G2" : ""
                  );
                }}
                checked={
                  labelData.part2 &&
                  labelData.part2[3] &&
                  labelData.part2[3]?.textValue === "G2"
                }
                className=" ml-2"
              />
              <label className="input-Label ml-2">
                {labelData.part2 && labelData.part2[3]
                  ? labelData.part2[3].labelName
                  : ""}
              </label>
            </>
          </div>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2  sm:col-12 flex flex-column sm:flex-row ">
          <div className="xl:col-1 sm:col-12">
            <label className="input-Label"> (H)</label>
          </div>
          <div className="xl:col-5 sm:col-12 flex">
            <Checkbox
              name="checkH1"
              id="checkH1"
              onChange={(e) => {
                const updatedValue = !hCheckBox1;

                setHCheckBox1(!hCheckBox1);
                handleTaxFormChange(
                  e,
                  labelData.part2[4].generatedRank,
                  "textValue",
                  labelData.part2[4].boxName,
                  updatedValue === true ? "H1" : ""
                );
              }}
              checked={
                labelData.part2 &&
                labelData.part2[4] &&
                labelData.part2[4]?.textValue === "H1"
              }
              className=" ml-2"
              placeholder=""
            />
            <label className="input-Label ml-2 col-4">
              {labelData.part2 && labelData.part2[4]
                ? labelData.part2[4].labelName
                : ""}
            </label>
          </div>
          <div className="xl:col-6 sm:col-12 flex">
            <>
              <Checkbox
                name="checkH2"
                id="checkH2"
                onChange={(e) => {
                  const updatedValue = !hCheckBox2;
                  setHCheckBox2(!hCheckBox2);
                  handleTaxFormChange(
                    e,
                    labelData.part2[5].generatedRank,
                    "textValue",
                    labelData.part2[5].boxName,
                    updatedValue === true ? "H2" : ""
                  );
                }}
                checked={
                  labelData.part2 &&
                  labelData.part2[5] &&
                  labelData.part2[5]?.textValue === "H2"
                }
                className=" ml-2"
              />
              <label className="input-Label ml-2 col-4">
                {labelData.part2 && labelData.part2[5]
                  ? labelData.part2[5].labelName
                  : ""}
              </label>
            </>
          </div>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2  sm:col-12">
          <label className="input-Label">
            (I1){" "}
            {labelData.part2 && labelData.part2[6]
              ? labelData.part2[6].labelName
              : ""}
          </label>
          <InputText
            name="txtI1"
            id="txtI1"
            value={
              labelData.part2[6]?.textValue === null ||
              labelData.part2[6]?.textValue === undefined
                ? ""
                : labelData.part2[6]?.textValue
            }
            onChange={(e) => {
              handleTaxFormChange(
                e,
                labelData.part2[6].generatedRank,
                "textValue",
                labelData.part2[6].boxName
              );
            }}
            className="inputK1Form"
            placeholder=""
          />
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2  sm:col-12">
          <label className="input-Label">
            {" "}
            (I2){" "}
            {labelData.part2 && labelData.part2[7]
              ? labelData.part2[7].labelName
              : ""}
          </label>
          <Checkbox
            name="txtI2"
            id="txtI2"
            onChange={(e) => {
              const updatedValue = !i2CheckBox;
              setI2CheckBox(!i2CheckBox);
              handleTaxFormChange(
                e,
                labelData.part2[7].generatedRank,
                "textValue",
                labelData.part2[7].boxName,
                updatedValue === true ? "I2" : ""
              );
            }}
            checked={
              labelData.part2 &&
              labelData.part2[7] &&
              labelData.part2[7]?.textValue === "I2"
            }
            className=" ml-2"
          />
          <label className="input-Label ml-2"> check here</label>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2  sm:col-12">
          <label className="input-Label">
            (J){" "}
            {labelData.part2 && labelData.part2[8]
              ? labelData.part2[8].labelName
              : ""}
          </label>
          {/* table for J */}
          <div className="table-responsive inputK1Form12">
            <table className=" table table-bordered">
              <tbody>
                <tr className="border-0">
                  <th></th>
                  <th>Beginning</th>
                  <th>Ending</th>
                </tr>

                <tr className="border-0">
                  <td>
                    {labelData.part2 && labelData.part2[9]
                      ? labelData.part2[9].labelName
                      : ""}
                  </td>
                  <td>
                    <InputText
                      name="txtJProfit1"
                      id="txtJProfit1"
                      type="number"
                      onFocus={(e: any) => scrollDisable(e)}
                      value={
                        labelData.part2 && labelData.part2[9]
                          ? labelData.part2[9].beginningAmount
                          : ""
                      }
                      onChange={(e) =>
                        handleTaxFormChange(
                          e,
                          labelData.part2[9].generatedRank,
                          "beginningAmount",
                          labelData.part2[9].boxName
                        )
                      }
                      className="inputK1Form"
                    />
                    <span>%</span>
                  </td>
                  <td>
                    <InputText
                      name="txtJProfit2"
                      id="txtJProfit2"
                      type="number"
                      onFocus={(e: any) => scrollDisable(e)}
                      value={
                        labelData.part2 && labelData.part2[9]
                          ? labelData.part2[9].endingAmount
                          : ""
                      }
                      onChange={(e) =>
                        handleTaxFormChange(
                          e,
                          labelData.part2[9].generatedRank,
                          "endingAmount",
                          labelData.part2[9].boxName
                        )
                      }
                      className="inputK1Form"
                    />
                    <span>%</span>
                  </td>
                </tr>
                <tr className="border-0">
                  <td>
                    {labelData.part2 && labelData.part2[10]
                      ? labelData.part2[10].labelName
                      : ""}
                  </td>
                  <td>
                    <InputText
                      name="txtJLoss1"
                      id="txtJLoss1"
                      type="number"
                      onFocus={(e: any) => scrollDisable(e)}
                      value={
                        labelData.part2 && labelData.part2[10]
                          ? labelData.part2[10].beginningAmount
                          : ""
                      }
                      onChange={(e) =>
                        handleTaxFormChange(
                          e,
                          labelData.part2[10].generatedRank,
                          "beginningAmount",
                          labelData.part2[10].boxName
                        )
                      }
                      className="inputK1Form"
                    />
                    <span>%</span>
                  </td>
                  <td>
                    <InputText
                      name="txtJLoss2"
                      id="txtJLoss2"
                      type="number"
                      onFocus={(e: any) => scrollDisable(e)}
                      value={
                        labelData.part2 && labelData.part2[10]
                          ? labelData.part2[10].endingAmount
                          : ""
                      }
                      onChange={(e) =>
                        handleTaxFormChange(
                          e,
                          labelData.part2[10].generatedRank,
                          "endingAmount",
                          labelData.part2[10].boxName
                        )
                      }
                      className="inputK1Form"
                    />
                    <span>%</span>
                  </td>
                </tr>
                <tr className="border-0">
                  <td>
                    {labelData.part2 && labelData.part2[11]
                      ? labelData.part2[11].labelName
                      : ""}
                  </td>
                  <td>
                    <InputText
                      name="txtJCapital1"
                      id="txtJCapital1"
                      type="number"
                      onFocus={(e: any) => scrollDisable(e)}
                      value={
                        labelData.part2 && labelData.part2[11]
                          ? labelData.part2[11].beginningAmount
                          : ""
                      }
                      onChange={(e) =>
                        handleTaxFormChange(
                          e,
                          labelData.part2[11].generatedRank,
                          "beginningAmount",
                          labelData.part2[11].boxName
                        )
                      }
                      className="inputK1Form"
                      placeholder=""
                    />
                    <span>%</span>
                  </td>
                  <td>
                    <InputText
                      name="txtJCapital2"
                      id="txtJCapital2"
                      type="number"
                      onFocus={(e: any) => scrollDisable(e)}
                      value={
                        labelData.part2 && labelData.part2[11]
                          ? labelData.part2[11].endingAmount
                          : ""
                      }
                      onChange={(e) =>
                        handleTaxFormChange(
                          e,
                          labelData.part2[11].generatedRank,
                          "endingAmount",
                          labelData.part2[11].boxName
                        )
                      }
                      className="inputK1Form"
                      placeholder=""
                    />
                    <span>%</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2  sm:col-12">
          <label className="input-Label">
            (K){" "}
            {labelData.part2 && labelData.part2[12]
              ? labelData.part2[12].labelName
              : ""}
          </label>
          {/* table for k */}
          <div className="table-responsive inputK1Form12">
            <table className=" table table-bordered">
              <tbody>
                <tr className="border-0">
                  <th></th>
                  <th>Beginning</th>
                  <th>Ending</th>
                </tr>

                <tr className="border-0">
                  <td>
                    {labelData.part2 && labelData.part2[13]
                      ? labelData.part2[13].labelName
                      : ""}
                  </td>
                  <td>
                    <span className="mr-1">$</span>
                    <InputText
                      name="txtKNonRecourse1"
                      id="txtKNonRecourse1"
                      type="number"
                      onFocus={(e: any) => scrollDisable(e)}
                      value={
                        labelData.part2 && labelData.part2[13]
                          ? labelData.part2[13].beginningAmount
                          : ""
                      }
                      onChange={(e) =>
                        handleTaxFormChange(
                          e,
                          labelData.part2[13].generatedRank,
                          "beginningAmount",
                          labelData.part2[13].boxName
                        )
                      }
                      className="inputK1Form"
                    />
                  </td>
                  <td>
                    <span className="mr-1">$</span>
                    <InputText
                      name="txtKNonRecourse2"
                      id="txtKNonRecourse2"
                      type="number"
                      onFocus={(e: any) => scrollDisable(e)}
                      value={
                        labelData.part2 && labelData.part2[13]
                          ? labelData.part2[13].endingAmount
                          : ""
                      }
                      onChange={(e) =>
                        handleTaxFormChange(
                          e,
                          labelData.part2[13].generatedRank,
                          "endingAmount",
                          labelData.part2[13].boxName
                        )
                      }
                      className="inputK1Form"
                    />
                  </td>
                </tr>
                <tr className="border-0">
                  <td
                    className=" m-2"
                    style={{ whiteSpace: "normal", wordBreak: "break-word" }}
                  >
                    {labelData.part2 && labelData.part2[14]
                      ? labelData.part2[14].labelName
                      : ""}
                  </td>
                  <td>
                    <span className="mr-1">$</span>
                    <InputText
                      name="txtKQualified1"
                      id="txtKQualified1"
                      type="number"
                      onFocus={(e: any) => scrollDisable(e)}
                      value={
                        labelData.part2 && labelData.part2[14]
                          ? labelData.part2[14].beginningAmount
                          : ""
                      }
                      onChange={(e) =>
                        handleTaxFormChange(
                          e,
                          labelData.part2[14].generatedRank,
                          "beginningAmount",
                          labelData.part2[14].boxName
                        )
                      }
                      className="inputK1Form"
                    />
                  </td>
                  <td>
                    <span className="mr-1">$</span>
                    <InputText
                      name="txtKQualified2"
                      id="txtKQualified2"
                      type="number"
                      onFocus={(e: any) => scrollDisable(e)}
                      value={
                        labelData.part2 && labelData.part2[14]
                          ? labelData.part2[14].endingAmount
                          : ""
                      }
                      onChange={(e) =>
                        handleTaxFormChange(
                          e,
                          labelData.part2[14].generatedRank,
                          "endingAmount",
                          labelData.part2[14].boxName
                        )
                      }
                      className="inputK1Form"
                    />
                  </td>
                </tr>
                <tr className="border-0">
                  <td>
                    {labelData.part2 && labelData.part2[15]
                      ? labelData.part2[15].labelName
                      : ""}
                  </td>
                  <td>
                    <span className="mr-1">$</span>
                    <InputText
                      name="txtKRecourse1"
                      id="txtKRecourse1"
                      type="number"
                      onFocus={(e: any) => scrollDisable(e)}
                      value={
                        labelData.part2 && labelData.part2[15]
                          ? labelData.part2[15].beginningAmount
                          : ""
                      }
                      onChange={(e) =>
                        handleTaxFormChange(
                          e,
                          labelData.part2[15].generatedRank,
                          "beginningAmount",
                          labelData.part2[15].boxName
                        )
                      }
                      className="inputK1Form"
                    />
                  </td>
                  <td>
                    <span className="mr-1">$</span>
                    <InputText
                      name="txtKRecourse2"
                      id="txtKRecourse2"
                      type="number"
                      onFocus={(e: any) => scrollDisable(e)}
                      value={
                        labelData.part2 && labelData.part2[15]
                          ? labelData.part2[15].endingAmount
                          : ""
                      }
                      onChange={(e) =>
                        handleTaxFormChange(
                          e,
                          labelData.part2[15].generatedRank,
                          "endingAmount",
                          labelData.part2[15].boxName
                        )
                      }
                      className="inputK1Form"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2  sm:col-12">
          <label className="input-Label">
            {" "}
            (L){" "}
            {labelData.part2 && labelData.part2[16]
              ? labelData.part2[16].labelName
              : ""}
          </label>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2 flex flex-column sm:flex-row sm:col-12 ml-3">
          <div className=" xl:col-6 sm:col-12">
            <label className="input-Label">
              {" "}
              {labelData.part2 && labelData.part2[17]
                ? labelData.part2[17].labelName
                : ""}
            </label>
          </div>
          <div className="xl:col-6 sm:col-12 flex flex-row inputK1Form12">
            <span className="m-2">$</span>
            <InputText
              name="txtL1"
              id="txtL1"
              type="number"
              onFocus={(e: any) => scrollDisable(e)}
              value={
                labelData.part2 && labelData.part2[17]
                  ? labelData.part2[17].amount
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part2[17].generatedRank,
                  "amount",
                  labelData.part2[17].boxName
                )
              }
              className="inputK1Form input-Label-Code"
            />
          </div>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row inputK1Form12">
        <div className=" p-2 flex flex-column sm:flex-row sm:col-12 ml-3">
          <div className="xl:col-6 sm:col-12 ">
            <label className="input-Label">
              {" "}
              {labelData.part2 && labelData.part2[18]
                ? labelData.part2[18].labelName
                : ""}
            </label>
          </div>
          <div className="xl:col-6 sm:col-12 flex flex-row">
            <span className="m-2">$</span>
            <InputText
              name="txtL2"
              id="txtL2"
              type="number"
              onFocus={(e: any) => scrollDisable(e)}
              value={
                labelData.part2 && labelData.part2[18]
                  ? labelData.part2[18].amount
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part2[18].generatedRank,
                  "amount",
                  labelData.part2[18].boxName
                )
              }
              className="inputK1Form input-Label-Code"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row inputK1Form12">
        <div className=" p-2 flex flex-column sm:flex-row sm:col-12 ml-3">
          <div className="xl:col-6 sm:col-12">
            <label className="input-Label">
              {" "}
              {labelData.part2 && labelData.part2[19]
                ? labelData.part2[19].labelName
                : ""}
            </label>
          </div>
          <div className="xl:col-6 sm:col-12 flex flex-row">
            <span className=" m-2">$</span>
            <InputText
              name="txtL3"
              id="txtL3"
              type="number"
              onFocus={(e: any) => scrollDisable(e)}
              value={
                labelData.part2 && labelData.part2[19]
                  ? labelData.part2[19].amount
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part2[19].generatedRank,
                  "amount",
                  labelData.part2[19].boxName
                )
              }
              className="inputK1Form input-Label-Code"
            />
          </div>
        </div>
      </div>
      {/* array sequence is up down */}
      <div className="col-12 flex flex-column sm:flex-row inputK1Form12">
        <div className=" p-2 flex flex-column sm:flex-row sm:col-12 ml-3">
          <div className="xl:col-6 sm:col-12">
            <label className="input-Label">
              {" "}
              {labelData.part2 && labelData.part2[29]
                ? labelData.part2[29].labelName
                : ""}
            </label>
          </div>
          <div className="xl:col-6 sm:col-12 flex flex-row">
            <span className="m-2">$</span>
            <InputText
              name="txtL4"
              id="txtL4"
              type="number"
              onFocus={(e: any) => scrollDisable(e)}
              value={
                labelData.part2 && labelData.part2[29]
                  ? labelData.part2[29].amount
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part2[29].generatedRank,
                  "amount",
                  labelData.part2[29].boxName
                )
              }
              className="inputK1Form input-Label-Code"
              placeholder=""
            />
          </div>
        </div>
      </div>
      {/*  end */}
      <div className="col-12 flex flex-column sm:flex-row inputK1Form12">
        <div className=" p-2 flex flex-column sm:flex-row sm:col-12 ml-3">
          <div className="xl:col-6 sm:col-12">
            <label className="input-Label">
              {" "}
              {labelData.part2 && labelData.part2[20]
                ? labelData.part2[20].labelName
                : ""}
            </label>
          </div>
          <div className="xl:col-6 sm:col-12 flex flex-row">
            <span className="m-2">$</span>
            <InputText
              name="txtL5"
              id="txtL5"
              type="number"
              onFocus={(e: any) => scrollDisable(e)}
              value={
                labelData.part2 && labelData.part2[20]
                  ? labelData.part2[20].amount
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part2[20].generatedRank,
                  "amount",
                  labelData.part2[20].boxName
                )
              }
              className="inputK1Form input-Label-Code"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row inputK1Form12">
        <div className=" p-2 flex flex-column sm:flex-row sm:col-12 ml-3">
          <div className="xl:col-6 sm:col-12">
            <label className="input-Label">
              {" "}
              {labelData.part2 && labelData.part2[21]
                ? labelData.part2[21].labelName
                : ""}
            </label>
          </div>
          <div className="xl:col-6 sm:col-12 flex flex-row">
            <span className="m-2">$</span>
            <InputText
              name="txtL6"
              id="txtL6"
              type="number"
              onFocus={(e: any) => scrollDisable(e)}
              value={
                labelData.part2 && labelData.part2[21]
                  ? labelData.part2[21].amount
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part2[21].generatedRank,
                  "amount",
                  labelData.part2[21].boxName
                )
              }
              className="inputK1Form input-Label-Code"
              placeholder=""
            />
          </div>
        </div>
      </div>
      {/* checkbox started for L */}
      <div className="col-12 flex flex-column sm:flex-row ml-3">
        <div className="col-4">
          <Checkbox
            name="checkL7"
            id="checkL7"
            onChange={(e) => {
              const updatedValue = !taxCheckBox;
              setTaxCheckBox(!taxCheckBox);
              handleTaxFormChange(
                e,
                labelData.part2[22].generatedRank,
                "textValue",
                labelData.part2[22].boxName,
                updatedValue === true ? "T" : ""
              );
            }}
            checked={
              labelData.part2 &&
              labelData.part2[22] &&
              labelData.part2[22]?.textValue === "T"
            }
            className=" m-2"
            placeholder=""
          />
          <label className="input-Label">
            {" "}
            {labelData.part2 && labelData.part2[22]
              ? labelData.part2[22].labelName
              : ""}
          </label>
        </div>
        <div className="col-3">
          <Checkbox
            name="checkL8"
            id="checkL8"
            onChange={(e) => {
              const updatedValue = !gaapCheckBox;
              setGaapCheckBox(!gaapCheckBox);
              handleTaxFormChange(
                e,
                labelData.part2[23].generatedRank,
                "textValue",
                labelData.part2[23].boxName,
                updatedValue === true ? "G" : ""
              );
            }}
            checked={
              labelData.part2 &&
              labelData.part2[23] &&
              labelData.part2[23]?.textValue === "G"
            }
            className=" m-2"
            placeholder=""
          />
          <label className="input-Label">
            {" "}
            {labelData.part2 && labelData.part2[23]
              ? labelData.part2[23].labelName
              : ""}
          </label>
        </div>
        <div className="col-5 ml-0">
          <Checkbox
            name="checkL9"
            id="checkL9"
            onChange={(e) => {
              const updatedValue = !section704CheckBox;
              setSection704CheckBox(!section704CheckBox);
              handleTaxFormChange(
                e,
                labelData.part2[24].generatedRank,
                "textValue",
                labelData.part2[24].boxName,
                updatedValue === true ? "S" : ""
              );
            }}
            checked={
              labelData.part2 &&
              labelData.part2[24] &&
              labelData.part2[24]?.textValue === "S"
            }
            className=" mr-0 ml-0 m-2"
          />
          <label className="input-Label ml-1">
            {" "}
            {labelData.part2 && labelData.part2[24]
              ? labelData.part2[24].labelName
              : ""}
          </label>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2 flex flex-row sm:col-12  ml-2">
          <div className="col-6">
            <div>
              <Checkbox
                name="checkL10"
                id="checkL10"
                onChange={(e) => {
                  const updatedValue = !otherCheckBox;
                  setOtherCheckBox(!otherCheckBox);
                  handleTaxFormChange(
                    e,
                    labelData.part2[25].generatedRank,
                    "textValue",
                    labelData.part2[25].boxName,
                    updatedValue === true ? "O" : ""
                  );
                }}
                checked={
                  labelData.part2 &&
                  labelData.part2[25] &&
                  labelData.part2[25]?.textValue === "O"
                }
                className="m-2"
              />
              <label className="input-Label">
                {" "}
                {labelData.part2 && labelData.part2[25]
                  ? labelData.part2[25].labelName
                  : ""}
              </label>
            </div>
            <InputText
              name="txtL11"
              id="txtL11"
              value={
                labelData.part2 && labelData.part2[28]
                  ? labelData.part2[28]?.textValue
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part2[28].generatedRank,
                  "textValue",
                  labelData.part2[28].boxName
                )
              }
              className="inputK1Form input-Label-Code"
            />
          </div>
        </div>
      </div>
      {/* end L */}
      {/* M section */}
      <div className="col-12 flex flex-column sm:flex-row">
        <label className="input-Label">
          {" "}
          (M){" "}
          {labelData.part2 && labelData.part2[26]
            ? labelData.part2[26].labelName
            : ""}
        </label>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className="col-6">
          <Checkbox
            name="checkM1"
            id="checkM1"
            // value="M1"
            onChange={(e) => {
              const updatedValue = !mCheckBox;
              setMCheckBox(!mCheckBox);
              handleTaxFormChange(
                e,
                labelData.part2[26].generatedRank,
                "textValue",
                labelData.part2[26].boxName,
                updatedValue === true ? "M1" : ""
              );
            }}
            //  checked={K1Form[30].textValue === "M1"}
            checked={
              labelData.part2 &&
              labelData.part2[26] &&
              labelData.part2[26]?.textValue === "M1"
            }
            className=" ml-2"
            placeholder=""
          />
          <label className="input-Label ml-2">Yes</label>
        </div>
        <div className="col-6">
          <Checkbox
            name="checkM2"
            id="checkM2"
            // value="M2"
            onChange={(e) => {
              const updatedValue = !mCheckBox2;

              setMCheckBox2(!mCheckBox2);
              handleTaxFormChange(
                e,
                labelData.part2[27].generatedRank,
                "textValue",
                labelData.part2[27].boxName,
                updatedValue === true ? "M2" : ""
              );
            }}
            checked={
              labelData.part2 &&
              labelData.part2[27] &&
              labelData.part2[27]?.textValue === "M2"
            }
            className=" ml-2"
          />
          <label className="input-Label ml-2">No</label>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className="p-2 ml-2">
          <label className="input-Label">
            If “Yes,” attach statement (see instructions)
          </label>
        </div>
      </div>
      {/* end M section */}
      {/* N section */}
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2 flex flex-row sm:col-12 ">
          <label className="input-Label">
            {" "}
            (N){" "}
            {/* {labelData.part1[0].headId === 0
              ? labelData.part2[29].labelName
              : labelData.part2[30].labelName} */}
            {labelData.part2 && labelData.part2[30]
              ? labelData.part2[30].labelName
              : ""}
          </label>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2 flex flex-column sm:flex-row sm:col-12 ml-3">
          <div className="xl:col-6 sm:col-12">
            <label className="input-Label">
              {" "}
              {/* {labelData.part1[0].headId === 0
                ? labelData.part2[30].labelName
                : labelData.part2[31].labelName} */}
              {labelData.part2 && labelData.part2[31]
                ? labelData.part2[31].labelName
                : ""}
            </label>

            <Calendar
              id="beginningN"
              name="beginningN"
              value={
                labelData.part2[31].textValue === "" ||
                labelData.part2[31].textValue === null
                  ? undefined
                  : new Date(labelData.part2[31]?.textValue)
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part2[31].generatedRank,
                  "textValue",
                  labelData.part2[31].boxName
                )
              }
              className="inputK1Form"
              placeholder="mm/dd/yyyy"
              readOnlyInput
            />
          </div>
          <div className="xl:col-6 sm:col-12 flex flex-row inputK1Form12">
            <span className="  m-2">$</span>
            <InputText
              name="txtNAmount1"
              id="txtNAmount1"
              type="number"
              onFocus={(e: any) => scrollDisable(e)}
              value={
                labelData.part2 && labelData.part2[31]
                  ? labelData.part2[31].beginningAmount
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part2[31].generatedRank,
                  "beginningAmount",
                  labelData.part2[31].boxName
                )
              }
              className="inputK1Form input-Label-Code"
              style={{ height: "46px" }}
            />
          </div>
        </div>
      </div>
      <div className="col-12 flex flex-column sm:flex-row">
        <div className=" p-2 flex flex-column sm:flex-row sm:col-12 ml-3">
          <div className="xl:col-6 sm:col-12">
            <label className="input-Label">
              {" "}
              {labelData.part2 && labelData.part2[32]
                ? labelData.part2[32].labelName
                : ""}
            </label>

            <Calendar
              id="endingN"
              name="endingN"
              value={
                labelData.part2[32].textValue === "" ||
                labelData.part2[32].textValue === null
                  ? undefined
                  : new Date(labelData.part2[32]?.textValue)
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part2[32].generatedRank,
                  "textValue",
                  labelData.part2[32].boxName
                )
              }
              className="inputK1Form"
              placeholder="mm/dd/yyyy"
              readOnlyInput
            />
          </div>
          <div className="xl:col-6 sm:col-12 flex flex-row inputK1Form12">
            <span className=" m-2">$</span>
            <InputText
              name="txtNAmount2"
              id="txtNAmount2"
              type="number"
              onFocus={(e: any) => scrollDisable(e)}
              value={
                labelData.part2 && labelData.part2[32]
                  ? labelData.part2[32].endingAmount
                  : ""
              }
              onChange={(e) =>
                handleTaxFormChange(
                  e,
                  labelData.part2[32].generatedRank,
                  "endingAmount",
                  labelData.part2[32].boxName
                )
              }
              className="inputK1Form input-Label-Code"
              style={{ height: "46px" }}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}
