import { createSlice } from "@reduxjs/toolkit";
import {
  thirdPartyApiData,
  bankMailInfoData,
  edgarFilingInfoData,
  fundManageApi,
} from "./payload";

const initialState = {
  data: {
    fundManageApiData: {
      PreviousState: fundManageApi,
      NewState: fundManageApi,
    },
    bankMailInfoApiData: {
      PreviousState: bankMailInfoData,
      NewState: bankMailInfoData,
    },
    thirdPartyApi: {
      PreviousState: thirdPartyApiData,
      NewState: thirdPartyApiData,
    },
    edgarFilingApiData: {
      PreviousState: edgarFilingInfoData,
      NewState: edgarFilingInfoData,
    },
  },
};

const slice = createSlice({
  name: "createUpdateSetupState",
  initialState,
  reducers: {
    setStateChangeCheck: (state, action) => {
      console.log(" as", action.payload);
      state.data = action.payload;
      //
    },
  },
});
export const { setStateChangeCheck } = slice.actions;
export default slice.reducer;
