// import { useMsal } from "@azure/msal-react";
// import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import AppRoutes from "../../AppRoutes";

import { logoutHelper } from "../../utils/helper";
import { checkTokenExp } from "../../utils/sponsor/permission.helper";
import ChatbotComponent from "../chatbot/ChatbotComponent";
import Footer from "../footer/Footer";
import { HelpVideoComponent } from "../help-video/HelpVideoComponent";
import IdealTimeOut from "../idle-timer/IdealTimeOut";
import Navbar from "../navbar/Navbar";
import { ScrollToTop } from "../scroll-to/ScrollTo";
import SideNavigation from "../sidebar/Sidenavigation";

const DashboardPage = () => {
  // const [visibleRight, setVisibleRight] = useState(false);
  // const profileData = useAppSelector((state) => state.profile.data![0]);

  useEffect(() => {
    const isTokenExp = checkTokenExp();

    // const fundWebsite = getFundName();

    if (isTokenExp) {
      //  const tenant = localStorage.getItem("tenentName");
      // localStorage.removeItem("token");
      // msal("logout", instance, tenant, inProgress);
      logoutHelper();
    } else {
      // console.log("logged In");
    }
  });
  // useEffect(() => {
  //   dispatch(
  //     setChatBotState({
  //       visibleRightState: visibleRight,
  //     })
  //   );
  // }, []);
  return (
    <div className="navbar main-wrapper">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <SideNavigation />
        <div className="main-panel ">
          <div className="page-background">
            <ChatbotComponent />
            <HelpVideoComponent />
            <AppRoutes />
            <ScrollToTop />
          </div>
          <Footer />
        </div>
      </div>
      <IdealTimeOut />
    </div>
  );
};

export default DashboardPage;
