// import { getConfig } from "../../b2c/env-config";

import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function isFundExistService(slug: string) {
  let tenant = localStorage.getItem("tenentName");
  if (!tenant) {
    tenant = "null";
  }
  if (slug) {
    tenant = slug;
  }
  return apiFactory().get(
    `${envVariable.REACT_APP_API_INVESTOR_SPNG_URL}/Users/IsFundExist?fundName=${tenant}`
  );
}

export function getUserProfileDetailsService(data: any) {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_ADMIN_MANAGEMENT_URL}/SponsorDetails/GetSponsorDetails?Slug=${data.Slug}`
  );
}

export function getAllColorService() {
  const tenant = localStorage.getItem("tenentName");

  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ASSETS_SPNG_URL
    }/Assets/getFundColor?fundName=${tenant}`
  );
}

export function getFundLogoService() {
  const tenant = localStorage.getItem("tenentName");
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ASSETS_SPNG_URL
    }/Assets/getFundLogo?fundName=${tenant}`
  );
}

export function getFundBannerService() {
  const tenant = localStorage.getItem("tenentName");
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ASSETS_SPNG_URL
    }/Assets/getFundBanner?fundName=${tenant}`
  );
}

export function getFundImageService() {
  const tenant = localStorage.getItem("tenentName");
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ASSETS_SPNG_URL
    }/Assets/getFundImage?fundName=${tenant}`
  );
}
