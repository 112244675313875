import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addUpdateManagerFeesConfig,
  deleteManagerFeesConfigById,
  getManagerFeesConfigByIdPoolfunds,
} from "../../services/ManagerFees";
import error from "../../utils/error";

const initialState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: { output: [] },
};
export const addUpdateManagerFeesConfigThunk = createAsyncThunk(
  "addUpdateManagerFeesConfigThunk",
  async (data: any) => {
    try {
      console.log("data", data);
      const response = await addUpdateManagerFeesConfig(data);
      console.log("response exit", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const getManagerFeesConfigByIdPoolfundsThunk = createAsyncThunk(
  "getManagerFeesConfigByIdPoolfundsThunk",
  async (data: any) => {
    try {
      console.log("data", data);
      const response = await getManagerFeesConfigByIdPoolfunds(data);
      console.log("response exit", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const deleteManagerFeesConfigByIdThunk = createAsyncThunk(
  "deleteManagerFeesConfigById",
  async (data: number) => {
    try {
      const response = await deleteManagerFeesConfigById(data);
      console.log("deleteManagerFeesConfigById response", response);
      return response.data;
    } catch (error_) {
      console.log("error", error_);
    }
  }
);

const slice = createSlice({
  name: "addUpdateManagerFeesConfig",
  initialState,
  reducers: {
    resetManagerFees(state) {
      state.data = initialState.data;
    },
  },
  extraReducers(builder): void {
    builder.addCase(addUpdateManagerFeesConfigThunk.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(
      addUpdateManagerFeesConfigThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log("hr addUpdateManagerFeesConfigThunk", action.payload);
        state.data = action.payload.success
          ? action.payload.data
          : initialState.data;
      }
    );
    builder.addCase(
      addUpdateManagerFeesConfigThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        throw new Error(error(action.error));
      }
    );
    builder.addCase(getManagerFeesConfigByIdPoolfundsThunk.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(
      getManagerFeesConfigByIdPoolfundsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(
          "hr getManagerFeesConfigByIdPoolfundsThunk",
          action.payload
        );
        state.data = action.payload.success
          ? action.payload.data
          : initialState.data;
      }
    );
    builder.addCase(
      getManagerFeesConfigByIdPoolfundsThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        throw new Error(error(action.error));
      }
    );
  },
});
export const managerFeesActions = slice.actions;
export default slice.reducer;
