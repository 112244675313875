import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetPartnerProDataByInvestmentService } from "../../../../services/taxmanagement/tax";
import error from "../../../../utils/error";
import {
  IGetPartnerProDataByInvestmentState,
  IGetPartnerProDataByInvestmentparameters,
} from ".";

const initialState: IGetPartnerProDataByInvestmentState = {
  success: false,
  message: "",
  loading: "loading",
  data: "",
  data1: "",
  data2: "",
};
export const GetPartnerProDataByInvestmentThunk = createAsyncThunk(
  "TaxManagement/GetPartnerProDataByInvestment",
  async (data: IGetPartnerProDataByInvestmentparameters) => {
    try {
      const response = await GetPartnerProDataByInvestmentService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "TaxManagement/GetPartnerProDataByInvestment",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetPartnerProDataByInvestmentThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      GetPartnerProDataByInvestmentThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data
          ? action.payload._response.data[0]?.[0]?.result
          : [];
        state.data1 = action.payload._response.data
          ? action.payload._response.data[1]?.[0]?.result
          : [];
        state.data2 = action.payload._response.data
          ? action.payload._response.data[2]?.[0]?.result
          : [];
      }
    );
    builder.addCase(GetPartnerProDataByInvestmentThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
