import { createSlice } from "@reduxjs/toolkit";
interface IInitialState {
  data: {
    dealsState: any;
    investmentsState: any;
  };
}

const initialState: IInitialState = {
  data: {
    dealsState: [],
    investmentsState: [],
  },
};

const slice = createSlice({
  name: "sideNavPanel",
  initialState,
  reducers: {
    setSideNavPanel: (state, action) => {
      console.log("s", action.payload);
      state.data.dealsState = action.payload.dealsState;
      state.data.investmentsState = action.payload.investmentsState;
      //
    },
  },
});
export const { setSideNavPanel } = slice.actions;
export default slice.reducer;
