import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useMemo, useState } from "react";
import AddMoney from "../../../components/add-money";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
import Loader from "../../../components/loader/Loader";
import { getThunkInvestmentIdByProduct } from "../../../store/features/operation/allocate-earning/get-investment-id-product/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addPoolFundsActions } from "../../../store/poolfunds/add-pool-funds-state";
import { InvestorListType } from "../../../store/poolfunds/add-pool-funds-state/type";
import {
  getAllInvestorListPoolFunds,
  resetAllInvesterListPoolFund,
} from "../../../store/poolfunds/get-all-Investor-list-pool-funds";
import { resetAllocaterInvestoresByInvestmentPoolFund } from "../../../store/poolfunds/get-allocatedinvestors-Investment-poolfunds";
import { getThunkAllInvestmentDealPooledFundByLegalEntityId } from "../../../store/poolfunds/get-investmentdeal-for-pooledfund";
// import { getAllocatedInvestorsByInvestmentPoolfundsThunk } from "../../../store/poolfunds/get-allocatedinvestors-Investment-poolfunds";
import currencyFormat from "../../../utils/currency-formatter";
import { formatDateMMDDYYY } from "../../../utils/date-formatter";
import { errorToastMessage } from "../../../utils/toast-message";
import StepButtons from "../StepButtons";

type PoolFundStep1Props = {
  handlePrevious: () => void;
  handleNext: () => void;
  toast: any;
};

const PoolFundStep1: React.FC<PoolFundStep1Props> = ({
  handleNext,
  handlePrevious,
  toast,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const addPoolFundData = useAppSelector((state) => state.AddPoolFunds.data);
  const allInvestorPoolFundList = useAppSelector(
    (state) => state.getAllInvestorPoolFund.data.output
  );
  const pooledFundsInvestmentDetails = useAppSelector(
    (state) => state.getAllInvestorPoolFund.data.pooledFundsInvestmentDetails
  );
  const investmentList = useAppSelector(
    (state) => state.getAllInvestorDealListPoolFunds.data.investments
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDialogMoney, setOpenDialogMoney] = useState<boolean>(false);
  const [lpPartnerId, setLpPartnerId] = useState<number>(0);

  console.log("addPoolFundData", addPoolFundData);

  console.log("investmentList", investmentList);
  console.log("allInvestorPoolFundList", allInvestorPoolFundList);
  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameter = {
        legalEntityId: fundDropdown.fundid,
      };
      dispatch(
        getThunkAllInvestmentDealPooledFundByLegalEntityId(parameter)
      ).finally(() => {});
    }
  }, [fundDropdown]);

  useEffect(() => {
    console.log("in effect addPoolFundData", addPoolFundData);
    if (fundDropdown.fundid && addPoolFundData.productId) {
      setLoading(true);
      const param = { productId: addPoolFundData.productId };
      dispatch(getThunkInvestmentIdByProduct(param))
        .then((response) => {
          if (
            response.payload._response.data !== null &&
            response.payload._response.data.externalInvestmentId.key !== 0 &&
            response.payload._response.data.externalInvestmentId !== 0
          ) {
            dispatch(
              addPoolFundsActions.setAddPoolFundData({
                ...addPoolFundData,
                investmentId:
                  response.payload._response.data.externalInvestmentId.key,
              })
            );
            const allocationData = {
              legalEntityId: fundDropdown.fundid,
              investmentId:
                response.payload._response.data.externalInvestmentId.key,
            };
            dispatch(getAllInvestorListPoolFunds(allocationData)).finally(() =>
              setLoading(false)
            );
          } else {
            errorToastMessage(toast, "Investment Id is not found");
            dispatch(addPoolFundsActions.resetAddPoolFundData());
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          dispatch(addPoolFundsActions.resetAddPoolFundData());
          console.log("error", error);
        });
      // dispatch(getAllocatedInvestorsByInvestmentPoolfundsThunk(parameter));
    }
  }, [addPoolFundData.productId]);

  const investmentDropdownList: any = useMemo(
    () =>
      investmentList!.length > 0
        ? investmentList?.map((item: any) => {
            return {
              name: item.productName,
              code: item.productID,
            };
          })
        : [],
    [investmentList]
  );

  const selectButton = (rowData: any) => {
    const selected = addPoolFundData.investorList.some(
      (data: any) => data.lpPartnerId === rowData.lpPartnerId
    );

    const onSelect = () => {
      const list: any = [...addPoolFundData.investorList];
      console.log("data", list);
      const result = list!.some(
        (data: any) => data.lpPartnerId === rowData.lpPartnerId
      );
      if (!result) {
        // add the data into the list
        const rowDataEdit = {
          ...rowData,
          allocationDate: pooledFundsInvestmentDetails?.lastAllocationDate
            ? formatDateMMDDYYY(
                new Date(pooledFundsInvestmentDetails?.lastAllocationDate)
              )
            : pooledFundsInvestmentDetails?.investmentStartDate
            ? formatDateMMDDYYY(
                new Date(pooledFundsInvestmentDetails?.investmentStartDate)
              )
            : "",
          unitsToAllocate: 0,
          cashEquivalent: 0,
          managerFees: 0,
          isReinvested:
            pooledFundsInvestmentDetails?.isReinvest || rowData.isReinvest,
        };
        console.log("rowate", rowDataEdit);
        list.push(rowDataEdit);
      } else {
        // remove the data from the list
        const index = list!.findIndex(
          (data: any) => data.lpPartnerId === rowData.lpPartnerId
        );
        list.splice(index, 1);
      }
      dispatch(
        addPoolFundsActions.setAddPoolFundData({
          ...addPoolFundData,
          investorList: list,
        })
      );
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            type="button"
            className={
              selected
                ? "btn-allocationActive align-items-center justify-content-center"
                : "btn-allocation align-items-center justify-content-center"
            }
            disabled={rowData.cashBalance <= 0}
            onClick={(e) => {
              e.preventDefault();
              onSelect();
            }}
          >
            <span>{selected ? "Selected" : "Select"}</span>
          </Button>
        </div>
      </div>
    );
  };

  const addMoneyAction = (rowData: any) => {
    return (
      <>
        <Button
          className="btn-nav"
          onClick={(e) => {
            e.preventDefault();
            setOpenDialogMoney(true);
            setLpPartnerId(rowData.lpPartnerId);
          }}
        >
          Add Money
        </Button>
      </>
    );
  };
  const dataTableElement = [
    {
      field: "name",
      header: "Investor Name",
    },
    {
      field: "requestChange",
      header: "Request Change",
      body: (rowData: any) => (
        <div className="amount-green">
          {currencyFormat(rowData.requestChange)}
        </div>
      ),
    },
    {
      field: "softCommit_Amount",
      header: "Soft Commit Amount",
      body: (rowData: any) => currencyFormat(rowData.softCommit_Amount),
      // style: { width: "11rem" },
    },
    {
      field: "cashBalance",
      header: "Cash Balance",
      body: (rowData: any) =>
        currencyFormat(
          rowData.cashBalance,
          rowData.cashBalance <= 0 && rowData.cashBalance > -1 ? 4 : 2
        ),
    },
    { field: "", header: "Select", body: selectButton },
    { field: "", header: "Add Money", body: addMoneyAction },
  ];

  const handleRadioChange = (value: any) => {
    dispatch(
      addPoolFundsActions.setAddPoolFundData({
        ...addPoolFundData,
        isDateChange: value,
      })
    );
  };
  const onDialogCancel = () => {
    setOpenDialog(false);
    dispatch(
      addPoolFundsActions.setAddPoolFundData({
        ...addPoolFundData,
        isDateChange: false,
      })
    );
  };
  const handleChange = (e: any) => {
    const { value } = e.target;
    dispatch(
      addPoolFundsActions.setAddPoolFundData({
        ...addPoolFundData,
        investorList: [],
        isDateChange: false,
        productId: value,
        investmentName: e?.originalEvent?.target?.ariaLabel,
      })
    );
  };

  const onCancel = () => {
    dispatch(addPoolFundsActions.resetAddPoolFundData());
    dispatch(resetAllInvesterListPoolFund());
    dispatch(resetAllocaterInvestoresByInvestmentPoolFund());
    handlePrevious();
  };

  const handlePopContinue = () => {
    setLoading(true);
    const allocationData = {
      legalEntityId: fundDropdown.fundid,
      investmentId: addPoolFundData.investmentId,
    };
    dispatch(getAllInvestorListPoolFunds(allocationData))
      .then(() => {
        setLoading(false);
        setOpenDialog(false);
        handleNext();
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
        errorToastMessage(toast);
      });
  };

  const onContinue = () => {
    if (!addPoolFundData.investmentId) {
      errorToastMessage(toast, "Please select investment");
      return;
    }
    if (addPoolFundData.investorList.length > 0) {
      dispatch(
        addPoolFundsActions.setAddPoolFundData({
          ...addPoolFundData,
          isDateChange: true,
        })
      );
      handlePopContinue();
    } else {
      errorToastMessage(toast, "Please select one investor");
    }
  };

  useEffect(() => {
    if (addPoolFundData.investorList?.length === 0) {
      return;
    }
    dispatch(
      addPoolFundsActions.setAddPoolFundData({
        ...addPoolFundData,
        investorList: addPoolFundData.investorList.map(
          (obj: InvestorListType) => {
            return {
              ...obj,
              allocationDate: pooledFundsInvestmentDetails?.lastAllocationDate
                ? formatDateMMDDYYY(
                    new Date(pooledFundsInvestmentDetails?.lastAllocationDate)
                  )
                : pooledFundsInvestmentDetails?.investmentStartDate
                ? formatDateMMDDYYY(
                    new Date(pooledFundsInvestmentDetails?.investmentStartDate)
                  )
                : "",
            };
          }
        ),
      })
    );
  }, [pooledFundsInvestmentDetails]);

  return (
    <>
      {loading ? <Loader /> : <></>}

      <AddMoney
        openDialog={openDialogMoney}
        setOpenDialog={setOpenDialogMoney}
        lpPartnerId={lpPartnerId}
        setLpPartnerId={setLpPartnerId}
      />

      <Dialog
        visible={openDialog}
        onHide={() => onDialogCancel()}
        className="w-100 md:w-5 pb-1"
        headerClassName="no-header"
      >
        <div className="mt-2">
          <h4 className="pageHeader">
            By default, all investors are allocated their units on the
            investment start date.
          </h4>
          <h4 className="pageHeader mt-2"> Do you need to change this?</h4>
          <div className="p-2 col-12">
            <div className="mt-2 flex">
              <div className="flex align-items-center pr-4 mb-1">
                <RadioButton
                  inputId="changeDate"
                  name="changeDate"
                  // value="no"
                  onChange={(e) => handleRadioChange(false)}
                  checked={addPoolFundData.isDateChange === false}
                />

                <label htmlFor="changeDate" className="ml-2 mb-0">
                  No
                </label>
              </div>
              <div className="flex align-items-center pr-4">
                <RadioButton
                  inputId="changeDate"
                  name="changeDate"
                  // value="yes"
                  onChange={(e) => handleRadioChange(true)}
                  checked={addPoolFundData.isDateChange === true}
                />

                <label htmlFor="changeDate" className="ml-2 mb-0">
                  Yes
                </label>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <p>
              You should only do this if you are back-filling allocations. For
              example, if you placed the investment with your own capital
              initially, and are re-allocating it to investors after the fact.
            </p>
            <p className="mt-2">
              Changing these dates can cause issues if done without reason.
            </p>
          </div>
          <div className="flex flex-row flex-wrap sm:flex-none p-0 col-12 justify-content-center ">
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-nav"
                onClick={(e) => {
                  e.preventDefault();
                  onDialogCancel();
                }}
              >
                <span className="ml-auto mr-auto ">Cancel</span>
              </Button>
            </div>
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-navActive"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handlePopContinue();
                }}
              >
                <span className="ml-auto mr-auto ">Continue</span>
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <div className="mt-4">
        <div className="formgrid grid px-2">
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
            <label className="inputLabel">Select Investment</label>
            <Dropdown
              inputId="investment"
              name="investment"
              value={addPoolFundData.productId}
              options={investmentDropdownList}
              filter
              optionLabel="name"
              optionValue="code"
              placeholder="Select Investment"
              className="card-inputBox"
              // showOnFocus
              onChange={(e) => {
                e.preventDefault();
                handleChange(e);
              }}
            />
          </div>
        </div>
        <div className="formgrid grid px-2 mt-3">
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
            <label className="inputLabel">Select Investor Accounts</label>

            <h6 className="mt-2">
              Below, you can select one or multiple investors to allocate units
              to.
            </h6>
          </div>
        </div>
      </div>
      <DataTableComponent
        valueData={allInvestorPoolFundList || []}
        fieldsElements={dataTableElement}
        noGridLines={true}
        isPaginationAllowed={true}
        perPage={[5, 10, 20, 100, 200, 500]}
        className="allocateSliceTable"
      />
      <StepButtons
        firstBtnLabel="Cancel"
        secondBtnLabel="Continue"
        handleFirstBtn={onCancel}
        handleSecondBtn={onContinue}
      />
    </>
  );
};

export default PoolFundStep1;
