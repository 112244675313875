import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { RiAddFill } from "react-icons/ri";
import DataTableComponent from "../../../../../components/data-table/DataTableComponent";
import ConfirmDailogbox from "../../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../../components/loader/Loader";
import {
  addThunkProjectUpdate,
  deleteThunkProjectUpdateById,
  getThunkProjectUpdates,
} from "../../../../../store/features/investment/update/project-update/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  convertLocalDateToUTC,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../../utils/toast-message";

export default function FormProjectUpdate() {
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<any>(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [projectUpdateData, setProjectUpdateData] = useState<any>([]);
  const [projetcUpdateId, setProjectUpdateId] = useState<any>(0);
  const [projetcEditId, setProjectEditId] = useState<any>(0);
  const [editDate, setEditdate] = useState<any>();

  const stateInvestment = useAppSelector((state) => state.addInvestment);
  const projectUpdateList = useAppSelector(
    (state) => state.projectUpdateLog.data
  );
  const formik = useFormik({
    initialValues: {
      date: "",
      investmentUpdate: "",
    },
    validate: (data) => {
      //
      const errors: any = {};
      if (!data.date) {
        errors.date = "Select a date ";
      }
      if (!data.investmentUpdate) {
        errors.investmentUpdate = "Enter investment update";
      }

      return errors;
    },
    onSubmit: (data) => {
      console.log(data);
      setLoading(true);
      const formData = {
        id: !edit ? 0 : projetcEditId,
        productId: stateInvestment.data?.productID,
        logDate: convertLocalDateToUTC(formik.values.date),
        log: formik.values.investmentUpdate,
        action: !edit ? "Create" : "Update",
      };
      console.log("rowData-", edit, editDate, formData);

      setLoading(false);
      console.log("form", formData);
      dispatch(addThunkProjectUpdate(formData))
        .then(() => {
          successToastMessage(toast, "Data save successfully");
          if (edit) {
            setEdit(false);
          }
          formik.resetForm();
        })
        .catch(() => {
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoading(false);
          setOpenAddDialog(false);
        });
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };
  const actionDeleteTemplate = (rowData: any) => {
    const handleDelete = () => {
      console.log(rowData);
      setProjectUpdateId(rowData.id);
      setOpenDialog(true);
    };
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text  iconButton"
          onClick={(e) => {
            e.preventDefault();
            handleDelete();
          }}
        />
      </React.Fragment>
    );
  };

  const actionEditTemplate = (rowData: any) => {
    const handleEdit = () => {
      console.log("rowData", rowData);
      setProjectEditId(rowData.id);
      setEditdate(rowData.logDate);
      setOpenAddDialog(true);
      setEdit(true);
      formik.setFieldValue("date", new Date(rowData.logDate));
      formik.setFieldValue("investmentUpdate", rowData.log);
    };
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text  iconButton"
          onClick={(e) => {
            e.preventDefault();
            handleEdit();
          }}
        />
      </React.Fragment>
    );
  };
  console.log(
    "rowData-date",
    formik.values.date,
    formik.values.investmentUpdate
  );
  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const dataTableElement = [
    {
      field: "logDate",
      header: "Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.logDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "log",
      header: "Project Update",
    },

    {
      // field: "Date",
      header: "",
      body: actionDeleteTemplate,
      className: "text-center",
    },
    {
      // field: "Date",
      header: "",
      body: actionEditTemplate,
      className: "text-center",
    },
  ];

  const onDelete = () => {
    console.log("test");
    if (projetcUpdateId !== 0) {
      setOpenDialog(false);
      setLoading(true);
      const parameter = {
        id: projetcUpdateId,
      };
      dispatch(deleteThunkProjectUpdateById(parameter))
        .then(() => {
          successToastMessage(toast, "Data delete successfully");
        })
        .catch(() => {
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoading(false);
          setProjectUpdateId(0);
        });
    }
  };
  const onHideAddDocument = () => {
    console.log("test");
    formik.resetForm();
    setOpenAddDialog(false);

    setLoading(false);
  };
  const onCancel = () => {
    setOpenDialog(false);
    setProjectUpdateId(0);
  };

  useEffect(() => {
    setLoading(true);
    if (stateInvestment.data !== undefined) {
      const param = {
        productId: stateInvestment.data?.productID,
      };

      dispatch(getThunkProjectUpdates(param))
        .then((response) => {
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
            setLoading(false);
          } else if (response.meta.requestStatus === "fulfilled") {
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (projectUpdateList?.length !== 0) {
      setProjectUpdateData(
        getListDateConversion(projectUpdateList, ["logDate"])
      );
    } else {
      setProjectUpdateData([]);
    }
  }, [projectUpdateList]);

  console.log("d", projectUpdateList, projectUpdateData);
  return (
    <>
      {loading ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />

      {/* <p
        className="m-0 text-left viewTable"
        // style={liStyle}
        dangerouslySetInnerHTML={{ __html: string }}
      ></p> */}
      <ConfirmDailogbox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onConfirm={onDelete}
        onCancel={onCancel}
        textContent="Are you sure you want to delete ?"
      />

      <Dialog
        visible={openAddDialog}
        className="w-100 md:w-4"
        onHide={() => onHideAddDocument()}
        header={"Add Project Update"}
        headerClassName="text-center flex justify-content-center align-item-center pageHeader display-1"
        contentClassName="top-lr-content p-0"
      >
        <div className="m-0">
          <hr className="m-0" />
          <form>
            <div className="mt-1">
              <div className="d-block sm:d-flex sm:flex-column  col-12">
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 ">
                    <label className="inputLabel">Date</label>
                    <Calendar
                      name="date"
                      value={
                        formik.values.date === "" || formik.values.date === null
                          ? undefined
                          : new Date(formik.values.date)
                      }
                      onChange={(e) => handleChange(e)}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("date"),
                        "card-inputBox": true,
                      })}
                      placeholder="mm/dd/yyyy"
                      readOnlyInput
                    />

                    {getFormErrorMessage("date")}
                  </div>
                </div>
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 ">
                    <label className="inputLabel">Investment Update</label>
                    <InputTextarea
                      name="investmentUpdate"
                      value={formik.values.investmentUpdate}
                      onChange={(e) => handleChange(e)}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("investmentUpdate"),
                        "card-inputBox": true,
                        projectUpdate: true,
                      })}
                      // className="w-100 projectUpdate"
                      placeholder="Enter Investment Update"
                      rows={5}
                    />

                    {getFormErrorMessage("investmentUpdate")}
                  </div>
                </div>
              </div>

              <div className="d-block sm:d-flex sm:flex-column  col-12">
                <div className="flex flex-row flex-wrap sm:flex-none justify-content-center col-12 ">
                  <div className="d-flex flex-column r p-2 col-12 sm:col-5 annualClass">
                    <Button
                      className="btn-dialog "
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                      }}
                    >
                      <span className="ml-auto mr-auto ">Submit</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      <form>
        <div className="mt-2">
          <div style={{ minHeight: "400px" }}>
            <label className="inputLabel inputLabelColor ml-2">
              Add Project Update
            </label>

            <div
              className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
              style={{ height: "auto" }}
            >
              <div className="d-block sm:d-flex sm:flex-column  col-12 ml-2">
                <div className="flex flex-row flex-wrap sm:flex-none col-12 ">
                  <div className="d-flex flex-column  p-2 ">
                    <Button
                      className="btn-dialog"
                      onClick={(e) => {
                        e.preventDefault();
                        // formik.handleSubmit();
                        // uploadDoc();
                        setOpenAddDialog(true);
                      }}
                    >
                      <span className="ml-auto mr-auto ">
                        Add Project Update
                      </span>
                      <span className="p-2">
                        <RiAddFill />
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <label className="inputLabel inputLabelColor ml-2 mt-4 ">
              View Project Updates
            </label>

            <div
              className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
              style={{ height: "auto" }}
            >
              <div
                className="d-block sm:d-flex sm:flex-column col-12 sm:col-12 ml-2"
                // style={{ height: "auto" }}
              >
                <div className="ml-3">
                  <DataTableComponent
                    valueData={projectUpdateData || []}
                    fieldsElements={dataTableElement}
                    isPaginationAllowed={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
