import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { getOpportunityRelevantDocumentsService } from "../../../services/opportunity/opportunity";
import { IOpportunityInvestment } from "../../../types/index";
// import apiFactory from "../../utils/api";
import error from "../../../utils/error";

interface IOpportunityInvestmentState {
  Data?: IOpportunityInvestment[] | null;
  // currentPage: number;
  loading: string;
  error?: string | null;
}
const initialState: IOpportunityInvestmentState = {
  Data: undefined,
  loading: "loading",
  error: undefined,
};
export const getOpportunityRelevantDocumentsThunk = createAsyncThunk(
  "opportunity/getOpportunityRelevantDocuments",
  async (id: string) => {
    try {
      const response = await getOpportunityRelevantDocumentsService(id);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "opportunityRelevantDocuments",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getOpportunityRelevantDocumentsThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      getOpportunityRelevantDocumentsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.Data = action.payload.data;
        // state.Data = action.payload.opportunitiesData;
        // state.currentPage += 1;
      }
    );
    builder.addCase(
      getOpportunityRelevantDocumentsThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});

export const getOpportunityRelevantDocumentsData = (state: RootState) => {
  return state.opportunityRelevantDocuments.Data;
};
export const getOpportunityRelevantDocumentsStatus = (state: RootState) =>
  state.opportunityRelevantDocuments.loading;
// export const getAllOpportunitiesError = (state: RootState) =>
//   state.opportunity.error;

export default slice.reducer;
