import { FormikValues } from "formik";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
// import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React from "react";
import { useAppSelector } from "../../../../../store/hooks";
import {
  formatZipCode,
  isNumberAndHypen,
  onlyCharacter,
} from "../../../../../utils/helper";
import { setCountryCode } from "../../../../../utils/investing-approval/multi-investing-account/set-formik-data";

interface IProps {
  accountTypeID: number;
  formik: FormikValues;
  isFormFieldInvalid: any;
  getFormErrorMessage: any;
}

const SecondaryAccountHolder: React.FC<IProps> = (props) => {
  const countriesDropdown = useAppSelector((state) => {
    return state.investingAccountsLookups.countriesDropdown;
  });
  const stateDropdown = useAppSelector((state) => {
    return state.investingAccountsLookups.stateDropdown;
  });
  const formatPhoneNumber = (value: string) => {
    const phoneNumberOnly = value ? value!.replace(/\D/g, "") : value;

    let maskedPhoneNumber = "";

    if (phoneNumberOnly) {
      if (props.formik.values.secCountryId !== 229) {
        const isValidPhoneNumber = /^[\d-]*$/.test(value);
        if (isValidPhoneNumber) {
          maskedPhoneNumber = value; // Truncate to 20 characters if exceeds
        }
      } else {
        // If countryId is 229, follow the existing masking logic
        if (phoneNumberOnly.length <= 3) {
          maskedPhoneNumber = phoneNumberOnly;
        } else if (phoneNumberOnly.length <= 6) {
          maskedPhoneNumber =
            phoneNumberOnly.slice(0, 3) + "-" + phoneNumberOnly.slice(3);
        } else {
          maskedPhoneNumber =
            phoneNumberOnly.slice(0, 3) +
            "-" +
            phoneNumberOnly.slice(3, 6) +
            "-" +
            phoneNumberOnly.slice(6, 10);
        }
      }
    }

    return maskedPhoneNumber;
  };
  return (
    <>
      <div className="row d-flex justify-content-start">
        <h4 className="cardHeader">Secondary Account Holder</h4>
      </div>
      <div>
        <div className="formgrid grid">
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Secondary First Name <span className="text-red-500">*</span>
            </label>
            <InputText
              name="secFirstName"
              value={props.formik.values.secFirstName}
              // unmask={true}
              placeholder="Enter First Name"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "secFirstName",
                  onlyCharacter(e.target.value)
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("secFirstName"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("secFirstName")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Secondary Last Name <span className="text-red-500">*</span>
            </label>
            <InputText
              name="secLastName"
              value={props.formik.values.secLastName}
              // unmask={true}
              placeholder="Enter Last Name"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "secLastName",
                  onlyCharacter(e.target.value)
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("secLastName"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("secLastName")}
          </div>
          <div className="field col-12 text-left">
            <label className="inputLabel">
              Secondary SSN <span className="text-red-500">*</span>
            </label>
            <InputMask
              autoClear={false}
              name="secSSN"
              value={props.formik.values.secSSN}
              // unmask={true}
              mask="999-99-9999"
              // unmask={true}
              placeholder="xxx-xx-xxxx"
              onChange={(e) => {
                props.formik.setFieldValue("secSSN", e.target.value);
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("secSSN"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("secSSN")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Secondary Phone Number <span className="text-red-500">*</span>
            </label>
            <div className="p-inputgroup inputgrp-h47 flex-1">
              <span className="p-inputgroup-addon">
                {props.formik.values.secCountryCode
                  ? "+" + props.formik.values.secCountryCode
                  : ""}{" "}
              </span>
              {props.formik.values.secCountryId !== 229 ? (
                <InputText
                  name="secPhoneNumber"
                  id="secPhoneNumber"
                  placeholder=""
                  maxLength={20}
                  value={
                    props.formik.values.secPhoneNumber
                      ? formatPhoneNumber(props.formik.values.secPhoneNumber)
                      : props.formik.values.secPhoneNumber
                  }
                  onChange={(e) => {
                    if (isNumberAndHypen(e.target.value)) {
                      props.formik.setFieldValue(
                        "secPhoneNumber",
                        formatPhoneNumber(e.target.value)
                      );
                    }
                  }}
                  className={classNames({
                    "p-invalid": props.isFormFieldInvalid("secPhoneNumber"),
                    // "card-inputBox": true,
                    inputphoneNo: true,
                  })}
                />
              ) : (
                <InputText
                  name="secPhoneNumber"
                  id="secPhoneNumber"
                  placeholder="xxx-xxx-xxxx"
                  maxLength={20}
                  value={
                    props.formik.values.secPhoneNumber
                      ? formatPhoneNumber(
                          props.formik.values.secPhoneNumber.split("-").join("")
                        )
                      : props.formik.values.secPhoneNumber
                  }
                  onChange={(e) => {
                    props.formik.setFieldValue(
                      "secPhoneNumber",
                      formatPhoneNumber(e.target.value).split("-").join("")
                    );
                  }}
                  className={classNames({
                    "p-invalid": props.isFormFieldInvalid("secPhoneNumber"),
                    // "card-inputBox": true,
                    inputphoneNo: true,
                  })}
                />
              )}{" "}
            </div>

            {props.getFormErrorMessage("secPhoneNumber")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Secondary Email <span className="text-red-500">*</span>
            </label>
            <InputText
              name="secEmail"
              value={props.formik.values.secEmail}
              // unmask={true}
              placeholder="Enter Email"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "secEmail",
                  e.target.value?.trimStart()
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("secEmail"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("secEmail")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Secondary Address Line 1 <span className="text-red-500">*</span>
            </label>
            <InputText
              name="secAddress"
              value={props.formik.values.secAddress}
              // unmask={true}
              placeholder="Enter address line 1"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "secAddress",
                  e.target.value?.trimStart()
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("secAddress"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("secAddress")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">Secondary Address Line 2</label>
            <InputText
              name="secAddress2"
              value={props.formik.values.secAddress2}
              // unmask={true}
              placeholder="Enter address line 2"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "secAddress2",
                  e.target.value?.trimStart()
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("secAddress2"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("secAddress2")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Secondary Country <span className="text-red-500">*</span>
            </label>
            <Dropdown
              name="secCountryId"
              value={props.formik.values.secCountryId}
              showOnFocus
              options={countriesDropdown}
              filter
              optionLabel="value"
              optionValue="key"
              placeholder="Select Country"
              // className="card-inputBox"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "secCountryCode",
                  setCountryCode(e.value)
                );
                props.formik.setFieldValue("secZipCode", "");
                props.formik.setFieldValue("secCountryId", e.value);
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("secCountryId"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("secCountryId")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Secondary City <span className="text-red-500">*</span>
            </label>
            <InputText
              name="secCity"
              value={props.formik.values.secCity}
              placeholder="Enter City"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "secCity",
                  onlyCharacter(e.target.value?.trimStart())
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("secCity"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("secCity")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Secondary ZIP Code <span className="text-red-500">*</span>
            </label>
            <InputText
              name="secZipCode"
              value={props.formik.values.secZipCode}
              // unmask={true}
              placeholder="Enter ZIP Code"
              maxLength={props.formik.values.secCountryId === 229 ? 10 : 20}
              onChange={(e) => {
                const zipCode = formatZipCode(
                  e.target.value,
                  props.formik.values.secCountryId
                );
                props.formik.setFieldValue("secZipCode", zipCode);
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("secZipCode"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("secZipCode")}
          </div>
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Secondary State <span className="text-red-500">*</span>
            </label>

            {props.formik.values.secCountryId !== 229 ? (
              <InputText
                name="secState"
                value={props.formik.values.secState}
                placeholder="Select State "
                onChange={(e) => {
                  props.formik.setFieldValue(
                    "secState",
                    onlyCharacter(e.target.value?.trimStart())
                  );
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid("secState"),
                  "card-inputBox": true,
                })}
              />
            ) : (
              <Dropdown
                name="secState"
                value={props.formik.values.secState}
                options={stateDropdown}
                filter
                showOnFocus
                optionLabel="value"
                optionValue="key"
                placeholder="Select secState"
                // className="card-inputBox"
                onChange={(e) => {
                  console.log("e", e);
                  props.formik.setFieldValue("secState", e.value);
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid("secState"),
                  "card-inputBox": true,
                })}
              />
            )}
            {props.getFormErrorMessage("secState")}
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondaryAccountHolder;
