import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetSummaryDetailsService } from "../../../../../services/taxmanagement/tax";
import error from "./../../../../../utils/error";
import { IGetSummaryDetailsState, IGetSummaryDetailsparameters } from ".";

const initialState: IGetSummaryDetailsState = {
  success: false,
  message: "",
  loading: "loading",
  data1: "",
  data2: "",
  data3: "",
  supplierCashIntrest: 0,
};
export const GetSummaryDetailsThunk = createAsyncThunk(
  "TaxManagement/GetSummaryDetails",
  async (data: IGetSummaryDetailsparameters) => {
    try {
      const response = await GetSummaryDetailsService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "TaxManagement/GetSummaryDetails",
  initialState,
  reducers: {
    resetGetSummaryDetails(state) {
      state.data1 = "";
      state.data2 = "";
      state.data3 = "";
      state.supplierCashIntrest = 0;
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetSummaryDetailsThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetSummaryDetailsThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data1 =
        action.payload._response.data &&
        action.payload._response.data.partnerSummary[0]?.[0]?.result
          ? JSON.parse(
              action.payload._response.data.partnerSummary[0]?.[0]?.result
            )
          : [];
      state.data2 =
        action.payload._response.data &&
        action.payload._response.data.partnerSummary[1]?.[0]?.result
          ? JSON.parse(
              action.payload._response.data.partnerSummary[1]?.[0]?.result
            )
          : [];
      state.data3 =
        action.payload._response.data &&
        action.payload._response.data.partnerSummary[2]?.[0]?.result
          ? JSON.parse(
              action.payload._response.data.partnerSummary[2]?.[0]?.result
            )
          : [];
      state.supplierCashIntrest = action.payload._response.data
        ? action.payload._response.data.supplierIntList[0]?.supplierCashIntrest
        : 0;
      // state.data1 = action.payload._response.data
      //   ? JSON.parse(action.payload._response.data[0]?.[0]?.result)
      //   : [];
      // state.data2 = action.payload._response.data
      //   ? JSON.parse(action.payload._response.data[1]?.[0]?.result)
      //   : [];
      // state.data3 = action.payload._response.data
      //   ? JSON.parse(action.payload._response.data[2]?.[0]?.result)
      //   : [];
    });
    builder.addCase(GetSummaryDetailsThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export const { resetGetSummaryDetails } = slice.actions;

export default slice.reducer;
