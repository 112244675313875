import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { addInvestmentStep1Service } from "../../../../services/investment/investment";
import { getInvestmentByLegalEntityIdService } from "../../../../services/investment/investment";
import error from "../../../../utils/error";
import { IInvestmentGetAllState } from ".";
// import { IAddInvestmentState, IInvestmentData } from ".";

const initialState: IInvestmentGetAllState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    investments: [],
    deals: [],
  },
};
export const getThunkAllInvestmentByUserId = createAsyncThunk(
  "getAllInvestmentByUserId",
  async (data: any) => {
    try {
      const response = await getInvestmentByLegalEntityIdService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getAllInvestmentByUserId",
  initialState,
  reducers: {
    // setForm: (state, action) => {
    //   state.data = action.payload;
    //   //
    // },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkAllInvestmentByUserId.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getThunkAllInvestmentByUserId.fulfilled,
      (state, action) => {
        state.loading = "succeeded";

        if (action.payload.data !== null) {
          state.data.investments = action.payload.data.investments;
          state.data.deals = action.payload.data.deals;
        } else {
          state.data.investments = [];
          state.data.deals = [];
        }
        state.message =
          action.payload.message === null ? undefined : action.payload.message;

        // console.log("state.data 1", state.data);
      }
    );
    builder.addCase(getThunkAllInvestmentByUserId.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});

export default slice.reducer;
