import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteFolderFile } from "../../../../services/Filemanger/filemanager";
import error from "../../../../utils/error";
import { IFileManagerFolderDetails } from ".";

const initialState: IFileManagerFolderDetails = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    FolderDetails: [],
  },
};
export const deleteFolderFileThunk = createAsyncThunk(
  "FileManagement/DeleteBlobByUrl",
  async (data: any) => {
    try {
      const response = await deleteFolderFile(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "deleteBlobFileManager",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(deleteFolderFileThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(deleteFolderFileThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(
        "action.payload._response.data==",
        action.payload.response.data.documents
      );
      state.data.FolderDetails = action.payload
        ? action.payload.response.data.documents
        : [];
    });
    builder.addCase(deleteFolderFileThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
