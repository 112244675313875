import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";
type managerFeesType = {
  id: number;
  investmentId: number;
  lowerFundingAmount: number;
  upperFundingAmount: number;
  preSetPercentage: number;
  feesType: number;
  fixedAmount: number;
};
export const addUpdateManagerFeesConfig = (data: managerFeesType[]) => {
  return apiFactory().post(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/AddUpdateManagerFeesConfig`,
    data
  );
};

export const getManagerFeesConfigByIdPoolfunds = (data: {
  id: number;
  investmentId: number;
  managerFeesType: number;
}) => {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/GetManagerFeesConfigByIdPoolfunds?Id=${data.id}&InvestmentId=${data.investmentId}&ManagerFeesType=${data.managerFeesType}`
  );
};

export const getManagerFeesTypes = () => {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/getManagerFeesTypes`
  );
};

export const getExitUnitsTotalDataByLPPartnerID = (data: {
  investmentId: number;
  lPPartnerID: number;
}) => {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/GetExitUnitsTotalDataByLPPartnerID?InvestmentId=${data.investmentId}&LPPartnerID=${data.lPPartnerID}`
  );
};

export const getInvestorDropdownListByInvestmentId = (investmentId: number) => {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/GetInvestorDropdownListByInvestmentId?InvestmentId=${investmentId}`
  );
};

export const deleteManagerFeesConfigById = (id: number) => {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_OPERATION_URL}/PooledFunds/DeleteManagerFeesConfigById?Id=${id}`
  );
};
