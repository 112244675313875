// import { useMsal } from "@azure/msal-react";

import { useMsal } from "@azure/msal-react";
// import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";

import { Toast } from "primereact/toast";

import React, { useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";

interface IProps {
  isSponsor: boolean;
  isRegisteredFund: boolean;
}

const Enrollment: React.FC<IProps> = (props) => {
  const { instance } = useMsal();
  //   const navigate = useNavigate();
  const toast = useRef<Toast>(null);

  const tenentName = localStorage.getItem("tenentName");
  // const urlPath = window.location.pathname.slice(1);

  // if (urlPath && urlPath !== tenentName) {
  //   localStorage.removeItem("token");
  //   instance.logoutRedirect({
  //     postLogoutRedirectUri: "/",
  //   });
  //   console.log("Logout");
  //  }

  useEffect(() => {
    if (!props.isSponsor || !props.isRegisteredFund) {
      localStorage.removeItem("token");
      localStorage.setItem("accessDeniedSlug", tenentName!);

      instance.logoutRedirect({
        postLogoutRedirectUri: "/accessDenied",
      });

      console.log("!props.isSponsor", !props.isSponsor);
    }
  }, [props.isSponsor, !props.isRegisteredFund]);

  //   class="page-background "
  return (
    <div className="enrollment-body">
      <div className="">
        <div className="row d-flex justify-content-center">
          <div className="col">
            {/* <div className="card">
              {(!props.isSponsor || !props.isRegisteredFund) && (
                <Card>
                  <p className="m-0 pb-1">Invalid Username or Password</p>
                </Card>
              )}
            </div> */}
            <ProgressSpinner />
          </div>
        </div>
      </div>
      <Toast ref={toast} className="themeToast"></Toast>
    </div>
  );
};
export default Enrollment;
