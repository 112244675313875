import { IgetAzureLoginHistoryparameters } from "../../store/features/getazureloginhistory";
import { IGetSponsorRegulatoryDashboardDetailsParameter } from "../../store/features/reports/Getsponsorregulatorydashboarddetails";
import { IInvestorsinFundParameter } from "../../store/features/reports/Investors-in-Fund";
import { IOutstandingBlueSkyFilingParameter } from "../../store/features/reports/Outstanding-Bluesky-Filings";
import { ISetupFundParameter } from "../../store/features/reports/Setup-Fund-Report";
import { IBlueSkyFilingParameter } from "../../store/features/reports/blue-sky-filing";
import { ICumulativeEarningParameter } from "../../store/features/reports/cumulative-earning";
import { IGetCurrentOpportunitiesParameter } from "../../store/features/reports/get-current-opportunities";
import { IGetInvestorsVerifiedByAccreddParameter } from "../../store/features/reports/getinvestors-verified-by-accredd";
import { IInvestmentAllocationReportStateParameter } from "../../store/features/reports/investment-allocation-report";
import { IInvestmentreturnsBySupplierReportParameter } from "../../store/features/reports/investment-returns-by-supplier-report";
import { IInvestorLoginHistoryParameter } from "../../store/features/reports/investor-login-history";
import { ILateInvestmentParameter } from "../../store/features/reports/late-investment-exits";
import { ISearchInvestorEmployeeParameter } from "../../store/features/reports/search-investor-employee";
import { ISoftCommitParameter } from "../../store/features/reports/soft-commits";
import { IAllTransactionParameters } from "../../store/features/reports/transaction-reports/all-transaction";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";
import { IGetConsolidatedDashboardDetailsParameter } from "./../../store/features/reports/getconsolidateddashboarddetails/index";

export function getLateInvestmenttExitsService(data: ILateInvestmentParameter) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetMissedExitDateList?LegalEntityId=${data.legalEntityId}`
  );
}

export function getSoftCommitService(data: ISoftCommitParameter) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetSoftCommitsDataByFund?LegalEntityId=${data.legalEntityId}`
  );
}
export function getConsolidatedplatformService(userName: string) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/ConsolidatedPlatformReport?UserName=${userName}`
  );
}
export function getInvestorsinFundReportService(
  data: IInvestorsinFundParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/InvestorListReport?LegalEntityId=${data.legalEntityId}`
  );
}

export function getBlueSkyFilingReportService(data: IBlueSkyFilingParameter) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/BlueSkyFilingDataByFundReport?LegalEntityId=${data.legalEntityId}`
  );
}

export function getOutstandingBlueSkyFilingReportService(
  data: IOutstandingBlueSkyFilingParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/OutstandingBlueskyFilings?LegalEntityId=${data.legalEntityId}`
  );
}

export function getCumulativeEarningReportService(
  data: ICumulativeEarningParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/CumulativeEarningsDataReport?LegalEntityId=${
      data.legalEntityId
    }&LPPartnerID=${data.LPPartnerID}`
  );
}

export function getSetupFundReportService(data: ISetupFundParameter) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/SetupFundReport?LegalEntityId=${data.legalEntityId}`
  );
}

export function getSearchInvestorEmployeeReportService(
  data: ISearchInvestorEmployeeParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetEmployeeList?FirstName=${data.FirstName}&LastName=${
      data.LastName
    }&Email=${data.Email}&BusinessName=${data.BusinessName}&LegalEntityId=${
      data.LegalEntityId
    }&UserTypeId=${data.UserTypeId}
    `
  );
}

export function getEarningsErrataReportService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetReturnsDistributionErrata?LegalEntityId=${data.legalEntityId}`
  );
}

export function getInvestorAssetsService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/InvestorAccOverviewReport?LegalEntityId=${data.legalEntityId}`
  );
}

export function getInvestorLoginHistoryService(
  data: IInvestorLoginHistoryParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetInvestorLoginHistory?LegalEntityId=${
      data.legalEntityId
    }&UserID=${data.UserID}&Days=${data.Days}`
  );
}

export function getInvestmentreturnsBySupplierReportService(
  data: IInvestmentreturnsBySupplierReportParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetInvestmentReturnBySupplier?LegalEntityId=${
      data.legalEntityId
    }&StartDate=${data.startDate}&EndDate=${data.endDate}&InvReturnTypeID=${
      data.invReturnTypeID
    }`
  );
}

export function getInvestmentAllocationReportService(
  data: IInvestmentAllocationReportStateParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetAllocatedInvestmentReport?LegalEntityId=${
      data.LegalEntityId
    }&ProductID=${data.ProductID}`
  );
}

export function getCurrentOpportunitiesService(
  data: IGetCurrentOpportunitiesParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetCurrentOpportunities?LegalEntityId=${data.legalEntityId}`
  );
}

export function getInvestorsVerifiedByAccreddService(
  data: IGetInvestorsVerifiedByAccreddParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetApprovedAccredInvestorsByFund?LegalEntityId=${
      data.LegalEntityId
    }`
  );
}
export function getApprovedAccreddInvestorsForAllFundsdService(
  data: IGetInvestorsVerifiedByAccreddParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetApprovedAccreddInvestorsForAllFunds?LegalEntityId=${
      data.LegalEntityId
    }`
  );
}
export function getgetAnnualizedReturnsCompletedService(obj: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetAnnualizedReturns?LegalEntityId=${obj.legalEntityId}`
  );
}

export function getAllRegisterPortfolioInvestmentService(obj: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetRegistredInvestorList?LegalEntityId=${obj.LegalEntityId}`
  );
}
export function getAllNonRegisterPortfolioInvestmentService(obj: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetNonRegisteredInvestorList?LegalEntityId=${
      obj.LegalEntityId
    }`
  );
}

export function getGetSponsorRegulatoryDashboardDetailsService(
  obj: IGetSponsorRegulatoryDashboardDetailsParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetSponsorRegulatoryDashboardDetails?LegalEntityId=${
      obj.LegalEntityId
    }`
  );
}

export function getGetConsolidatedDashboardDetailsService(
  obj: IGetConsolidatedDashboardDetailsParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/GetConsolidatedDashboardDetails?LegalEntityId=${obj.LegalEntityId}`
  );
}

export function getgetAzureLoginHistoryService(
  data: IgetAzureLoginHistoryparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/getAzureLoginHistory?LegalEntityId=${data.LegalEntityId}&Days=${
      data.Days
    }
    `
  );
}
export function getAllTransactionService(data: IAllTransactionParameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_REPORTS_URL
    }/Report/ManageTransactionReports?LegalEntityId=${
      data.legalEntityId
    }&Year=${data.year}&ReportType=${data.type}`
  );
}
