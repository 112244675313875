import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getThunkSoftCommits } from "../../../../store/features/reports/soft-commits/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import { exportExcel } from "../../../../utils/excel-dataformat";

export default function SoftCommits() {
  const [softCommitData, setSoftCommitData] = useState<any>();
  const dispatch = useAppDispatch();
  const softCommits = useAppSelector((state) => state.softCommit.data);
  const total = useAppSelector((state) => state.softCommit.totalSoftCommit);
  const [loading, setloading] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);

  // const getSoftCommitDateConversion = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedExitDate = {
  //       ...d,
  //       targetCloseDate: new Date(d.targetCloseDate),
  //     };
  //     return modifiedExitDate;
  //   });
  // };
  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData.targetCloseDate);
    return dateFormat;
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: any) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  useEffect(() => {
    setloading(true);
    if (dropdown.fundid !== undefined) {
      const parameters = {
        legalEntityId: dropdown.fundid,
      };
      dispatch(getThunkSoftCommits(parameters))
        .then(() => {
          setloading(false);

          // setdataTableElements(prepareData(exitDate));
        })
        .catch(() => {
          setloading(false);
        });
    } else {
      setloading(false);
    }
  }, [dropdown.fundid]);

  useEffect(() => {
    setSoftCommitData(getListDateConversion(softCommits, ["targetCloseDate"]));
  }, [softCommits]);

  const formatCurrencyAnyData = (rowData: any) => {
    const finalValue = Number(rowData);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const formatCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.amount);
  };

  const dataTableElements = [
    {
      field: "legalEntityName",
      header: "Fund Name",
      style: { minWidth: "120px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.legalEntityName}>
          {rowData?.legalEntityName}
        </div>
      ),
    },

    {
      field: "investorName",
      header: "Investor Account",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.investorName}>
          {rowData?.investorName}
        </div>
      ),
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
      style: { width: "230px" },
    },
    {
      field: "productName",
      header: "Product Name",
      style: { width: "170px" },
    },
    {
      field: "targetCloseDate",
      header: "Target Close Date",
      dataType: "date",
      style: { width: "220px" },
      body: formatDate,
      filterElement: dateFilterTemplate,
    },
    {
      field: "amount",
      header: "Amount",
      style: { width: "100px" },
      body: formatCurrency,
    },
  ];
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const excelTableHeaders = [
    { field: "productId", header: "Product Id" },
    {
      field: "legalEntityName",
      header: "Fund Name",
    },

    {
      field: "investorName",
      header: "Investor Account",
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
    },
    {
      field: "productName",
      header: "Product Name",
    },
    {
      field: "targetCloseDate",
      header: "Target Close Date",
      dataType: "date",
    },
    {
      field: "amount",
      header: "Amount",
      dataType: "currency",
    },
  ];
  return (
    <>
      {loading && <Loader />}
      <div className="xl:col-12 md:col-6  p-0 flex align-items-center">
        <div className="card col-10 pgHeaderWrap p-0 ">
          <h1 className="pageHeader fs-2 m-0">Soft Commits By Fund</h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className="flex justify-content-end gap-2 md:col-2">
          <div className="flex align-items-center gap-2 mb-2">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(
                  excelTableHeaders,
                  softCommitData,
                  "Soft Commits By Fund",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                softCommitData === undefined ||
                softCommitData?.length === 0 ||
                softCommitData === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
          </div>
          <div className="flex align-items-center gap-2 mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                softCommitData === undefined ||
                softCommitData?.length === 0 ||
                softCommitData === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      {/* <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Soft Commits By Fund</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div> */}
      {/* <div className="grid">
        <div className="col-12">
          <div className="card my-3 tabWithoutboxshadow">
            <Card>
              <h4 className="cardHeader">Soft Commits By Fund</h4>
              <div className="row mt-2 d-flex justify-content-between">
                {" "} */}
      {/* <div className="col-sm-4 mb-2">
              <RiSearchLine className="mr-2" />
              <InputText
                // value={filterValue}
                // onChange={(e) => setFilterValue(e.target.value)}
                placeholder="Search..."
              />
            </div> */}
      {/* <div className="col-sm-4 mt-2">
                  <h4 className="font-bold">
                    {" "}
                    Total soft commits: {formatCurrencyAnyData(total)}
                  </h4>
                </div>
              </div> */}
      <div className="col-sm-4 m-0 p-0 pl-2">
        <h4 className="font-bold">
          {" "}
          Total soft commits: {formatCurrencyAnyData(total)}
        </h4>
      </div>
      <DataTableComponent
        valueData={softCommitData}
        fieldsElements={dataTableElements}
        isDownload={true}
        isPaginationAllowed={true}
        scrollable={true}
        maxHeight={"calc(-180px + 100vh)"}
        openDailog={openDailog}
        setOpenDailog={setOpenDailog}
        fileName="Soft Commits By Fund"
        className="gridcell"
      />
      {/* //       </Card> */}
      {/* //     </div>
      //   </div>
      // </div> */}
    </>
  );
}
