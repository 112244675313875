import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllTransactionService } from "../../../../../services/reports/reports";
import error from "../../../../../utils/error";
import { IAllTransactionParameters, IAllTransactionState } from ".";

const initialState: IAllTransactionState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const getManageTransactionThunk = createAsyncThunk(
  "AllTransactions",
  async (data: IAllTransactionParameters) => {
    try {
      const response = await getAllTransactionService(data);
      console.log("res", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "AllTransactions",
  initialState,
  reducers: {
    resetManangeReport: (state) => {
      state.data = undefined;
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(getManageTransactionThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getManageTransactionThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      if (action.payload._response.data !== null) {
        state.data = action.payload._response.data;
      }
    });
    builder.addCase(getManageTransactionThunk.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});
export const { resetManangeReport } = slice.actions;
export default slice.reducer;
