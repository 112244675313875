import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import DataTableComponent from "../../../../../components/data-table/DataTableComponent";
import Loader from "../../../../../components/loader/Loader";
import { scrollToSection } from "../../../../../components/scroll-to/ScrollTo";
import { setAllocationSliceInvestorList } from "../../../../../store/features/operation/allocation-slices/add-investors-allocation-state/slice";
import { addThunkCreateUpdateAllocateData } from "../../../../../store/features/operation/allocation-slices/create-update-allocation/slice";
// import { getThunkAllInvestorAllocateData } from "../../../../../store/features/operation/allocation-slices/get-all-investor-allocation/slice";
import { initialPayloadGetState } from "../../../../../store/features/operation/allocation-slices/get-all-investor-allocation";
import { setAllInvestorAllocateData } from "../../../../../store/features/operation/allocation-slices/get-all-investor-allocation/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  convertLocalDateToUTC,
  formatDateMMDDYYY,
} from "../../../../../utils/date-formatter";
import { errorToastMessage } from "../../../../../utils/toast-message";
import AllocationSliceUnits from "./AllocationSliceUnits";

interface IProps {
  handleNext: any;
  handlePrevious: any;
}
export default function AllocationStep1(props: IProps) {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState<any>();
  const [dataUpdated, setDataUpdated] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const allocationSliceInvestorList = useAppSelector(
    (state) => state.allocationSliceInvestorState.data
  );
  const allocationInvestorData = useAppSelector(
    (state) => state.getAllInvestorAllocationData.data
  );
  // for the dialog box date
  const dateInputbox = (rowData: any) => {
    return (
      <>
        <InputText
          name="allocationDate"
          value={formatDateMMDDYYY(new Date(rowData.allocationDate))}
          className="card-inputBox"
          disabled
        ></InputText>
      </>
    );
  };
  // for the dialog box unit
  const UnitsInputbox = (rowData: any) => {
    return (
      <>
        <InputText
          name="allocateUnits"
          value={rowData.allocatedUnits}
          className="card-inputBox"
          disabled
        ></InputText>
      </>
    );
  };

  const dataTableElement = [
    {
      field: "lpPartnerName",
      header: "Investor Account",
      noFilter: true,
      noSort: true,
      // style: { width: "70px" },
    },
    {
      field: "allocationDate",
      header: "Allocation Date",
      body: dateInputbox,
      noFilter: true,
      noSort: true,
    },
    {
      field: "allocatedUnits",
      header: "Allocated Units",
      body: UnitsInputbox,
      noFilter: true,
      noSort: true,
    },
  ];

  const onUpdate = () => {
    // it will return true
    const hasError = errorMessageState.some(
      (error: any) =>
        error.unitsToAllocateError !== "" || error.cashEquivalentError !== ""
    );
    // it will return true
    const hasNull = allocationSliceInvestorList?.investorList.every(
      (unitCheck: any) =>
        unitCheck.unitsToAllocate === null || unitCheck.unitsToAllocate === 0
    );

    // const nullData = allocationSliceInvestorList?.investorList.find(
    //   (unitCheck: any) =>
    //     unitCheck.unitsToAllocate === null || unitCheck.unitsToAllocate === 0
    // );

    if (!hasError && !hasNull) {
      setOpenConfirmDialog(true);
      const allList: any = [...allocationSliceInvestorList.investorList];

      const list: any = [];
      for (const item of allList) {
        if (item.unitsToAllocate !== null && item.unitsToAllocate !== 0) {
          const obj = {
            lpPartnerId: item.lpPartnerId,
            lpPartnerName: item.name,
            allocationDate: item.allocationDate,
            allocatedUnits: item.unitsToAllocate,
            prevAllocatedUnits: 0,
            lpPartnerStakeTrackingId: 0,
          };
          list.push(obj);
        }
      }

      setDataUpdated(list);
    } else if (hasNull) {
      errorToastMessage(
        toast,
        "Please allocate units for atleast one investor"
      );
    } else {
      errorToastMessage(toast, "Please check the allocated units");
    }
  };
  console.log("tets-------", dataUpdated);
  const onFinalize = () => {
    setLoading(true);
    const list: any = dataUpdated.map((obj: any) => {
      const newData = {
        ...obj,
        allocationDate: convertLocalDateToUTC(obj.allocationDate),
      };

      return newData;
    });
    console.log("List2---", list);
    const parameter = {
      action: "Create",
      stakeTrackingId: allocationInvestorData.investmentData?.stakeTrackingId,
      legalEntityId: fundDropdown.fundid,
      investmentId: allocationSliceInvestorList.investmentId,
      totalUnits: allocationInvestorData.investmentData?.availableUnits,
      unitPrice: allocationInvestorData.investmentData?.unitPrice,
      allocationData: list,
      remainingInvestmentUnits: 0,
    };
    dispatch(addThunkCreateUpdateAllocateData(parameter))
      .then((response) => {
        setOpenDialog(true);
        setOpenConfirmDialog(false);
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onCancel = () => {
    // reseting the state of investor
    const list = allocationSliceInvestorList.investorList.map((data: any) => {
      return {
        ...data,
        unitsToAllocate: 0,
        cashEquivalent: 0,
        remainingCash: data.cashBalance,
        classApplytoEdit: "",
      };
    });
    dispatch(
      setAllocationSliceInvestorList({
        ...allocationSliceInvestorList,
        investorList: list,
        // showAllocatedInvestor: false,
        allocatedInvestorTab: undefined,
      })
    );

    props.handlePrevious();
  };

  const handleOk = () => {
    setOpenDialog(false);
    dispatch(
      setAllocationSliceInvestorList({
        ...allocationSliceInvestorList,
        investorList: [],
        investmentId: 0,
        productId: 0,
        // allocatedInvestorTab: 0,
        isDateChange: false,
      })
    );
    dispatch(setAllInvestorAllocateData(initialPayloadGetState));
    // const allocationData = {
    //   legalEntityId: fundDropdown.fundid,
    //   investmentId: allocationSliceInvestorList.investmentId,
    // };
    // dispatch(getThunkAllInvestorAllocateData(allocationData))
    //   .catch(() => {
    //     errorToastMessage(toast);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });

    setTimeout(() => {
      props.handleNext();
      scrollToSection("allocatedInvestor");
    }, 100);
  };
  return (
    <>
      <Toast ref={toast} className="themeToast" />
      <Dialog
        visible={openDialog}
        className="w-100 md:w-3"
        headerClassName="no-header"
        contentClassName="p-0 m-0"
        closable={false}
        blockScroll
        onHide={() => setOpenDialog(false)}
      >
        <p className="pageHeader text-center pt-5">
          Units allocated successfully!
        </p>

        <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
          <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleOk();
              }}
              className="btn-navActive"
            >
              <span className="ml-auto mr-auto"> Ok</span>
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={openConfirmDialog}
        onHide={() => setOpenConfirmDialog(false)}
        className="w-100 md:w-7 pb-1"
        headerClassName="no-header"
      >
        <div className="mt-2">
          <h3 className="pageHeader">Confirm Changes:</h3>
          <p className="pageHeader mt-2">
            Please confirm all details below are correct before submission.
          </p>

          <div className="mt-2">
            <DataTableComponent
              valueData={dataUpdated || []}
              fieldsElements={dataTableElement}
              className="allocateSliceTable editAllocateDialog"
              noGridLines={true}
            />
          </div>
          <div className="flex flex-row flex-wrap sm:flex-none p-2 col-12 justify-content-center ">
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-nav"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenConfirmDialog(false);
                }}
              >
                <span className="ml-auto mr-auto ">Cancel</span>
              </Button>
            </div>
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-navActive"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  onFinalize();
                }}
              >
                <span className="ml-auto mr-auto ">Finalize and Allocate</span>
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      {loading === true ? <Loader /> : <></>}

      <div className="headercard d-flex grid">
        <div className="col-12 md:col-6">
          <h2 style={{ fontWeight: "bold" }}>Allocate Units</h2>
        </div>
        <div className="col-12 md:col-6 end-div">
          <h6>Step 3 - Allocate Units</h6>
        </div>
      </div>

      <div>
        <ProgressBar
          value={100}
          showValue={false}
          className="m-2"
        ></ProgressBar>
      </div>
      <AllocationSliceUnits
        errorMessageState={errorMessageState}
        setErrorMessageState={setErrorMessageState}
      />

      <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center ">
        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
          <Button
            className="btn-nav"
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
          >
            <span className="ml-auto mr-auto ">Cancel</span>
          </Button>
        </div>
        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
          <Button
            className="btn-navActive"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onUpdate();
            }}
          >
            <span className="ml-auto mr-auto ">Submit</span>
          </Button>
        </div>
      </div>
    </>
  );
}
