import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";

// import { Divider } from "primereact/divider";
import { TabPanel, TabView } from "primereact/tabview";
import { classNames } from "primereact/utils";
import React, { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import { getGAAPAccountingProfitLossThunk } from "../../store/features/gaap-accounting/get-gaap-accounting-profit-loss/slice";
import { getReportTypeListThunk } from "../../store/features/gaap-accounting/get-report-type-list/slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import currencyFormat from "../../utils/currency-formatter";
import "./ProfitLoss.scss";
import {
  convertLocalDateToUTC,
  dateFormatterModified,
} from "../../utils/date-formatter";
import TransactionList from "./TransactionList ";

const ProfitLoss: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [loading, setloading] = useState(false);

  const dispatch = useAppDispatch();

  const reportTypeList = useAppSelector(
    (State) => State.getReportTypeList.data
  );

  const LoadingState = useAppSelector(
    (State) => State.getGAAPAccountingProfitLoss.loading
  );

  const gAAPAccountingProfitLoss = useAppSelector(
    (State) => State.getGAAPAccountingProfitLoss.data
  );

  console.log("reportTypeList", gAAPAccountingProfitLoss);

  const formik = useFormik({
    initialValues: {
      dateRange: "",
      fromDate: "",
      toDate: "",
      reportTypeId: "",
    },
    validate: (data: any) => {
      console.log("validate", data);
      const errors: any = {};

      if (!data?.fromDate) {
        errors.fromDate = "Date is required";
      }
      if (!data?.toDate) {
        errors.toDate = "Date is required";
      }
      if (!data?.reportTypeId) {
        errors.reportTypeId = "Report Type is required";
      }

      return errors;
    },
    onSubmit: (data: any) => {
      console.log("onSubmit", data);

      // startDate:
      // startdate !== "" && startdate !== undefined
      //   ? convertLocalDateToUTC(startdate)
      //   : startdate,
      setloading(true);

      dispatch(
        getGAAPAccountingProfitLossThunk({
          fromDate: convertLocalDateToUTC(data.fromDate),
          toDate: convertLocalDateToUTC(data.toDate),
          reportTypeId: data.reportTypeId,
        })
      )
        .catch(() => {
          console.log("OOps something went wrong");
        })
        .finally(() => {
          setloading(false);
        });
      // dispatch(
      //   getGAAPAccountingBalanceSheetThunk({
      //     selectedDate: convertLocalDateToUTC(data.selectedDate),
      //     reportTypeId: data.reportTypeId,
      //   })
      // )
      //   .catch(() => {
      //     console.log("OOps something went wrong");
      //   })
      //   .finally(() => {
      //     setloading(false);
      //   });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);
  // const getFormErrorMessage = (name: any) => {
  //   return isFormFieldInvalid(name) ? (
  //     <small className="p-error d-block">{formikErrors[name]}</small>
  //   ) : (
  //     ""
  //   );
  // };

  useEffect(() => {
    dispatch(getReportTypeListThunk());
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader"> Profit & Loss</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2  flex justify-content-end">
          <Button className="btn-nav">Export</Button>
        </div>
      </div>
      <div className="grid">
        <div className="grid pt-0 col-12">
          <div className="col-12 pt-0 pr-0">
            <div className="card my-3 tabWithoutboxshadow">
              <Card>
                <div className="formgrid grid px-2 align-items-end bg-secondary pb-3">
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-2">
                    <label className="inputLabel">Date Range</label>
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
                    <label className="inputLabel"></label>
                    <Calendar
                      value={formik.values.dateRange}
                      view="year"
                      dateFormat="yy"
                      onChange={(e) => {
                        formik.setFieldValue("dateRange", e.value);
                        formik.setFieldValue("fromDate", e.value);
                        formik.setFieldValue("toDate", e.value);
                      }}
                      placeholder="Select Year"
                    />
                  </div>
                  <div className="d-flex sm:d-flex sm:flex-row flex-row p-2 col-12 sm:col-5 gap-1">
                    <div className="flex-column">
                      <Calendar
                        value={formik.values.fromDate}
                        onChange={(e) => {
                          formik.setFieldValue("fromDate", e.value);
                        }}
                        placeholder="Select date"
                        showIcon
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("fromDate"),
                          // "w-full": true,
                        })}
                      />
                      {/* {getFormErrorMessage("fromDate")} */}
                    </div>
                    <Calendar
                      value={formik.values.toDate}
                      onChange={(e) => formik.setFieldValue("toDate", e.value)}
                      placeholder="Select date"
                      showIcon
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("toDate"),
                        // "w-full": true,
                      })}
                    />
                    {/* {getFormErrorMessage("toDate")} */}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-2 text-right">
                    <Button
                      className="btn-navActive"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                      }}
                      type="submit"
                    >
                      Update Report
                    </Button>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-3 my-3">
                    <label className="inputLabel">
                      Compare to prior period
                    </label>
                  </div>
                  <div className="col-7"></div>
                  <div className="col-2">
                    <label className="inputLabel">Report Type</label>
                  </div>
                  <div className="col-3">
                    <Dropdown
                      options={reportTypeList}
                      value={formik.values.reportTypeId}
                      onChange={(e) => {
                        formik.setFieldValue("reportTypeId", e.value);
                      }}
                      placeholder="Select report type"
                      optionLabel="value"
                      optionValue="key"
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("reportTypeId"),
                        "card-inputBox": true,
                      })}
                    />
                    {/* {getFormErrorMessage("reportTypeId")} */}
                  </div>
                  <div className="col-7"></div>
                </div>
                {LoadingState === "succeeded" ? (
                  <>
                    {" "}
                    <div className="flex flex-wrap justify-content-center gap-3 align-items-end mt-5 mb-5">
                      <div className="flex flex-column px-2 text-center">
                        <label className="text-500 font-bold">Income</label>
                        <span className="fs-3">
                          {currencyFormat(
                            gAAPAccountingProfitLoss.value?.totalIncome || 0
                          )}
                        </span>
                      </div>
                      <div className="px-2 text-center">
                        <span className="fs-3 text-500"> + </span>
                      </div>
                      <div className="flex flex-column px-2 text-center">
                        <label className="text-500 font-bold">
                          Cost of Goods Sold
                        </label>
                        <span className="fs-3">
                          {" "}
                          {currencyFormat(
                            gAAPAccountingProfitLoss.value?.totalCostGoodSold ||
                              0
                          )}
                        </span>
                      </div>
                      <div>
                        <span className="fs-3 text-500"> - </span>
                      </div>
                      <div className="flex flex-column px-2 text-center">
                        <label className="text-500 font-bold">
                          Operating Expenses
                        </label>
                        <span className="fs-3">
                          {currencyFormat(
                            gAAPAccountingProfitLoss?.totalOperatingExpenses
                          )}
                        </span>
                      </div>
                      <div className="px-2 text-center">
                        <span className="fs-3 text-500"> = </span>
                      </div>
                      <div className="flex flex-column px-2 text-center">
                        <label className="text-500 font-bold">Net Profit</label>
                        <span className="fs-3 amount-red">
                          {currencyFormat(gAAPAccountingProfitLoss?.netProfit)}
                        </span>
                      </div>
                    </div>
                    <div className="border-top" />
                    <div className="balancetab">
                      <TabView
                        activeIndex={activeIndex}
                        onTabChange={(e) => setActiveIndex(e.index)}
                      >
                        <TabPanel header="Summary">
                          <div className="flex-between p-2">
                            <div className="fw-bold text-uppercase">
                              Accounts
                            </div>
                            <div className="fw-bold">
                              {dateFormatterModified(formik.values.fromDate)} to{" "}
                              {dateFormatterModified(formik.values.toDate)}
                            </div>
                          </div>

                          <div className="flex-between p-2 border-bottom">
                            <div className="">
                              <label>Income</label>
                            </div>
                            <div>
                              <label>
                                {currencyFormat(
                                  gAAPAccountingProfitLoss.value?.totalIncome ||
                                    0
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="flex-between p-2 border-bottom">
                            <div className="">
                              <label>Cost of goods sold</label>
                            </div>
                            <div>
                              <label>
                                {currencyFormat(
                                  gAAPAccountingProfitLoss.value
                                    ?.totalCostGoodSold || 0
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="flex-between p-2 bg-secondary">
                            <div>
                              <div className="fw-bold">Gross Profit</div>
                              <div className="small-label">
                                As a percentage of Total Income
                              </div>
                            </div>
                            <div>
                              <div className="fw-bold">
                                {currencyFormat(
                                  gAAPAccountingProfitLoss.value?.totalIncome ||
                                    0
                                )}
                              </div>
                              <div className="small-label">0.00%</div>
                            </div>
                          </div>
                          <div className="flex-between p-2 border-bottom">
                            <div className="">
                              <label>Operating Expenses</label>
                            </div>
                            <div>
                              <label>
                                {" "}
                                {currencyFormat(
                                  gAAPAccountingProfitLoss?.totalOperatingExpenses
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="flex-between p-2 bg-secondary">
                            <div>
                              <div className="fw-bold">Net Profit</div>
                              <div className="small-label">
                                As a percentage of Total Income
                              </div>
                            </div>
                            <div>
                              <div className="fw-bold">
                                {currencyFormat(
                                  gAAPAccountingProfitLoss.value?.netProfit || 0
                                )}
                              </div>
                              <div className="small-label">0.00%</div>
                            </div>
                          </div>
                          <div className="mt-4 flex justify-content-center">
                            <Button
                              className="btn-nav border-radius-2"
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveIndex(1);
                              }}
                            >
                              Show Details
                            </Button>
                          </div>
                        </TabPanel>
                        <TabPanel header="Details">
                          <div className="flex-between p-2">
                            <div className="fw-bold text-uppercase">
                              Accounts
                            </div>
                            <div className="fw-bold">
                              {dateFormatterModified(formik.values.fromDate)} to{" "}
                              {dateFormatterModified(formik.values.toDate)}
                            </div>
                          </div>
                          <div className="flex-between p-2 bg-secondary">
                            <div className="fw-bold">Total Income</div>
                            <div className="fw-bold">
                              {currencyFormat(
                                gAAPAccountingProfitLoss.value?.totalIncome || 0
                              )}
                            </div>
                          </div>

                          <div className="flex-between p-2 bg-secondary mt-4">
                            <div className="fw-bold">
                              Total cost of goods sold
                            </div>
                            <div className="fw-bold">
                              {currencyFormat(
                                gAAPAccountingProfitLoss.value
                                  ?.totalCostGoodSold || 0
                              )}
                            </div>
                          </div>

                          <div className="flex-between p-2 bg-secondary mt-4">
                            <div>
                              <div className="fw-bold">Gross Profit</div>
                              <div className="small-label">
                                As a percentage of Total Income
                              </div>
                            </div>
                            <div>
                              <div className="fw-bold">
                                {currencyFormat(
                                  gAAPAccountingProfitLoss.value?.totalIncome ||
                                    0
                                )}
                              </div>
                              <div className="small-label flex-end">0.00%</div>
                            </div>
                          </div>

                          {gAAPAccountingProfitLoss.transactionName ? (
                            <TransactionList
                              transactions={
                                gAAPAccountingProfitLoss.transactionName
                              }
                            />
                          ) : (
                            <div className="flex-between p-2 bg-secondary mt-4">
                              <div>
                                <div className="fw-bold">
                                  Operating Expenses
                                </div>
                              </div>
                              <div>
                                <div className="fw-bold">
                                  {currencyFormat(
                                    gAAPAccountingProfitLoss?.totalOperatingExpenses
                                  )}
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="flex-between p-2 bg-secondary mt-4">
                            <div>
                              <div className="fw-bold">Net Profit</div>
                              <div className="small-label">
                                As a percentage of Total Income
                              </div>
                            </div>
                            <div>
                              <div className="fw-bold">
                                {currencyFormat(
                                  gAAPAccountingProfitLoss.value?.netProfit || 0
                                )}
                              </div>
                              <div className="small-label flex-end">0.00%</div>
                            </div>
                          </div>

                          <div className="mt-4 flex justify-content-center">
                            <Button
                              className="btn-nav border-radius-2"
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveIndex(0);
                              }}
                            >
                              Show Summary
                            </Button>
                          </div>
                        </TabPanel>
                      </TabView>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfitLoss;
