import { createSlice } from "@reduxjs/toolkit";
export const initialStateCreateSetupFund = {
  data: {
    add: false,
    cancel: false,
    fundId: 0,
  },
};
const initialState = {
  data: {
    add: false,
    cancel: false,
    fundId: 0,
  },
};

const slice = createSlice({
  name: "createUpdateSetupState",
  initialState,
  reducers: {
    setAddCancelState: (state, action) => {
      console.log(" as", action.payload);
      state.data = action.payload;
      //
    },
  },
});
export const { setAddCancelState } = slice.actions;
export default slice.reducer;
