import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccrediationLetterService } from "../../../../../../services/investor/investor";
import error from "../../../../../../utils/error";
import { IGetAccrediationLetterPayload } from ".";

interface IEsignDocument {
  data?: string;
  loading: string;
  error?: string | null;
}

const initialState: IEsignDocument = {
  data: "",
  loading: "loading",
  error: undefined,
};
export const getAccrediationLetterThunk = createAsyncThunk(
  "accreditation/getAccrediationLetter",
  async (data: IGetAccrediationLetterPayload) => {
    try {
      const response = await getAccrediationLetterService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "accreditation/getAccrediationLetter",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getAccrediationLetterThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getAccrediationLetterThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      //
      state.data = action.payload._response.data.pdfLetterUrl;
    });
    builder.addCase(getAccrediationLetterThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
