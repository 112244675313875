import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SaveNotesService } from "../../../../../services/Accounting/accounting";
import error from "../../../../../utils/error";
import { ISaveNotesState, ISaveNotesparameters } from ".";

const initialState: ISaveNotesState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const SaveNotesThunk = createAsyncThunk(
  "Accounting/SaveNotes",
  async (data: ISaveNotesparameters) => {
    try {
      const response = await SaveNotesService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Accounting/SaveNotes",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(SaveNotesThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(SaveNotesThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload
        ? action.payload._response.data.managementFeeList
        : [];
    });
    builder.addCase(SaveNotesThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
