import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOpportunityInvestmentDetailsService } from "../../../services/opportunity/opportunity";
import { RootState } from "../../../store";
import { IOpportunityInvestmentDetails } from "../../../types/index";
import error from "../../../utils/error";

interface IOpportunityState {
  Data?: IOpportunityInvestmentDetails | null;
  loading: string;
  error?: string | null;
}
const initialState: IOpportunityState = {
  Data: undefined,
  loading: "loading",
  error: undefined,
};
export const getOpportunitiyInvestmentDetailsThunk = createAsyncThunk(
  "opportunity/getOpportunitiyInvestmentDetails",
  async (id: number) => {
    try {
      const response = await getOpportunityInvestmentDetailsService(id);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "opportunityDetails",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getOpportunitiyInvestmentDetailsThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      getOpportunitiyInvestmentDetailsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.Data = action.payload.data[0];
      }
    );
    builder.addCase(
      getOpportunitiyInvestmentDetailsThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});

export const getOpportunitiyInverstmentDetailData = (state: RootState) => {
  return state.opportunityDetails.Data;
};
export const getOpportunityDetailsStatus = (state: RootState) =>
  state.opportunityDetails.loading;
// export const getAllOpportunitiesError = (state: RootState) =>
//   state.opportunityDetails.error;

export default slice.reducer;
