import { Button } from "primereact/button";
// import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import DataTableComponent from "../../../../../components/data-table/DataTableComponent";
import InvestmentDropdownFilter from "../../../../../components/investment-filter/InvestmentDropdownFilter";
import Loader from "../../../../../components/loader/Loader";

import { getThunkAllInvestmentByUserId } from "../../../../../store/features/investment/all-list/slice";
import { getThunkInvestmentById } from "../../../../../store/features/investment/create/slice";
import { getThunkInvestmentIdByProduct } from "../../../../../store/features/operation/allocate-earning/get-investment-id-product/slice";
import {
  initialPayloadAddState,
  setAllocationSliceInvestorList,
} from "../../../../../store/features/operation/allocation-slices/add-investors-allocation-state/slice";
import {
  getThunkAllInvestorAllocateData,
  setAllInvestorAllocateData,
} from "../../../../../store/features/operation/allocation-slices/get-all-investor-allocation/slice";
import { setAllocatedInvestorState } from "../../../../../store/features/operation/allocation-slices/get-allocated-investor/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import currencyFormat from "../../../../../utils/currency-formatter";
import { formatDateMMDDYYY } from "../../../../../utils/date-formatter";
import { errorToastMessage } from "../../../../../utils/toast-message";
import AddMoney from "./AddMoney";

interface IProps {
  onContinue: any;
  onCancel: any;
  productObjectByFilter: any;
  setProductObjectByFilter: any;
}

export default function AllocationList(props: IProps) {
  const {
    onContinue,
    onCancel,
    productObjectByFilter,
    setProductObjectByFilter,
  } = props;
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  // const [ProductId, setProductId] = useState(0);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const [openDialog, setOpenDialog] = useState(false);
  const [lpPartnerId, setLpPartnerId] = useState(0);
  const [InfoMessage, setInfoMessage] = useState("");
  const allocationSliceInvestorList = useAppSelector(
    (state) => state.allocationSliceInvestorState.data
  );

  const investmentList = useAppSelector(
    (state) => state.getListInvestmentsUserId.data.investments
  );

  const allocationInvestorData = useAppSelector(
    (state) => state.getAllInvestorAllocationData.data
  );

  // for Investment Dropdown
  const investmentDropdownList: any = [];

  investmentList?.map((item: any) => {
    //
    return investmentDropdownList.push({
      name: item.productName,
      code: item.productID,
    });
  });
  // end

  // select Button on Grid
  const selectButton = (rowData: any) => {
    // to check the selected Investor

    const selected = allocationSliceInvestorList.investorList.some(
      (data: any) => data.lpPartnerId === rowData.lpPartnerId
    );

    const onSelect = () => {
      const list: any = [...allocationSliceInvestorList.investorList];
      const result = list!.some(
        (data: any) => data.lpPartnerId === rowData.lpPartnerId
      );
      if (result !== true) {
        // add the data into the list
        const rowDataEdit = {
          ...rowData,
          allocationDate: allocationInvestorData.investmentData
            ?.lastAllocationDate
            ? allocationInvestorData.investmentData?.lastAllocationDate
            : "",
          unitsToAllocate: 0,
          cashEquivalent: 0,
          remainingCash: rowData.cashBalance,
          classApplytoEdit: "",
        };
        list.push(rowDataEdit);
      } else {
        // remove the data from the list
        const index = list!.findIndex(
          (data: any) => data.lpPartnerId === rowData.lpPartnerId
        );
        list.splice(index, 1);
      }
      dispatch(
        setAllocationSliceInvestorList({
          ...allocationSliceInvestorList,
          investorList: list,
        })
      );
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          {rowData.cashBalance !== 0 ? (
            <>
              {" "}
              <Button
                type="button"
                className={
                  selected === true
                    ? "btn-allocationActive align-items-center justify-content-center"
                    : "btn-allocation align-items-center justify-content-center"
                }
                onClick={(e) => {
                  e.preventDefault();
                  onSelect();
                }}
              >
                <span>{selected ? "Selected" : "Select"}</span>
              </Button>
            </>
          ) : (
            <>
              {" "}
              <Button
                type="button"
                className="btn-allocationActive align-items-center justify-content-center p-1"
                onClick={(e) => {
                  e.preventDefault();
                  // onSelect();
                  setOpenDialog(true);
                  setLpPartnerId(rowData.lpPartnerId);
                }}
              >
                <span>Add Money</span>
              </Button>
            </>
          )}
        </div>
      </div>
    );
  };

  // end
  const dataTableElement = [
    {
      field: "name",
      header: "Investor Account",
    },
    {
      field: "softCommit_Amount",
      header: "Soft Commit Amount",
      // style: { width: "11rem" },
      body: (rowData: any) => currencyFormat(rowData.softCommit_Amount),
    },
    {
      field: "cashBalance",
      header: "Cash Balance",
      body: (rowData: any) =>
        currencyFormat(
          rowData.cashBalance,
          rowData.cashBalance <= 0 && rowData.cashBalance > -1 ? 4 : 2
        ),
    },
    { field: "", header: "", body: selectButton },
  ];

  // handle the dropdown change  event
  // const handleChange = (e: any) => {
  //   const { value } = e.target;
  //   setProductId(value);
  //   dispatch(
  //     setAllocationSliceInvestorList({
  //       ...allocationSliceInvestorList,
  //       investorList: [],
  //       isDateChange: false,
  //       productId: value,
  //       allocatedInvestorTab: undefined,
  //       investmentName: e?.originalEvent?.target?.ariaLabel,
  //     })
  //   );
  // };

  // for calling the getAllInvestment api
  useEffect(() => {
    setLoading(true);
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      // setProductId(0);
      setProductObjectByFilter({
        ...productObjectByFilter,
        productID: 0,
      });
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        userId: profileData?.userID,
      };
      dispatch(getThunkAllInvestmentByUserId(parameter)).finally(() => {
        setLoading(false);
      });
    }
  }, [fundDropdown]);

  // for calling the grid data
  useEffect(() => {
    if (productObjectByFilter.productID !== 0) {
      setLoading(true);
      const parameter = { productId: productObjectByFilter.productID };
      dispatch(getThunkInvestmentIdByProduct(parameter))
        .then((response) => {
          if (
            response.payload._response.data !== null &&
            response.payload._response.data.externalInvestmentId.key !== 0 &&
            response.payload._response.data.externalInvestmentId !== 0
          ) {
            dispatch(
              getThunkInvestmentById(productObjectByFilter?.productID)
            ).then((resp) => {
              console.log(
                "resp.payload.data.investorDetails[0]",
                resp.payload.data.investorDetails[0]
              );
              setProductObjectByFilter({
                ...productObjectByFilter,
                lookupCodeObj:
                  resp.payload.data.investorDetails[0].supplierProductCode,
                address:
                  resp.payload.data.investorDetails[0].productListingAddress,
                zipCode: resp.payload.data.investorDetails[0].zipCode,
              });
              dispatch(
                setAllocationSliceInvestorList({
                  ...allocationSliceInvestorList,
                  investorList: [],
                  isDateChange: false,
                  investmentId:
                    response.payload._response.data.externalInvestmentId.key,
                  productId: productObjectByFilter.ProductID,
                  allocatedInvestorTab: undefined,
                  investmentName:
                    resp.payload.data.investorDetails[0].productName,
                })
              );
            });
            dispatch(
              setAllocationSliceInvestorList({
                ...allocationSliceInvestorList,
                investmentId:
                  response.payload._response.data.externalInvestmentId.key,
              })
            );
            const allocationData = {
              legalEntityId: fundDropdown.fundid,
              investmentId:
                response.payload._response.data.externalInvestmentId.key,
            };

            dispatch(getThunkAllInvestorAllocateData(allocationData))
              .catch(() => {
                errorToastMessage(toast);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            errorToastMessage(toast, "Investment Id is not found");
            dispatch(setAllocationSliceInvestorList(initialPayloadAddState));
            setProductObjectByFilter({
              productID: 0,
              lookupCodeObj: "",
              address: "",
              zipCode: "",
            });
            setLoading(false);
          }
        })
        .catch(() => {
          errorToastMessage(toast);
          setLoading(false);
          dispatch(setAllocationSliceInvestorList(initialPayloadAddState));
        });
    } else {
      dispatch(setAllocationSliceInvestorList(initialPayloadAddState));
      dispatch(
        setAllInvestorAllocateData({ output: [], investmentData: undefined })
      );
      dispatch(
        setAllocatedInvestorState({ output: [], investmentData: undefined })
      );
    }
  }, [productObjectByFilter.productID]);

  useEffect(() => {
    setInfoMessage("");
    if (
      allocationInvestorData.investmentData &&
      allocationInvestorData.investmentData.availableUnits === 0
    ) {
      dispatch(
        setAllocationSliceInvestorList({
          ...allocationSliceInvestorList,
          allocatedInvestorTab: 0,
        })
      );
      setInfoMessage("All units for this investment have been allocated");
    } else if (
      allocationInvestorData.investmentData &&
      allocationInvestorData.investmentData.isEarningDistributed
    ) {
      setInfoMessage("Earnings have been distributed for this investment");
    }
  }, [allocationInvestorData.investmentData]);

  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <AddMoney
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        lpPartnerId={lpPartnerId}
        setLpPartnerId={setLpPartnerId}
      />

      <div>
        <div className="mt-4">
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
              {/* <label className="inputLabel">Select Investment</label>
              <Dropdown
                inputId="investment"
                name="investment"
                value={allocationSliceInvestorList.productId}
                options={
                  investmentDropdownList === null ? [] : investmentDropdownList
                }
                filter
                optionLabel="name"
                optionValue="code"
                placeholder="Select Investment"
                className="card-inputBox"
                // showOnFocus
                resetFilterOnHide={true}
                showFilterClear={true}
                onChange={(e) => {
                  e.preventDefault();
                  handleChange(e);
                }}
              /> */}

              <InvestmentDropdownFilter
                listPage={false}
                setProductObjectByFilter={setProductObjectByFilter}
                productObjectByFilter={productObjectByFilter}
                productState="PreFunded"
                pooledFund="Fixed"
                showAllocationDropDown={true}
              />

              <div className="formgrid grid">
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                  <label className="inputLabel">Investment Id </label>
                  <InputText
                    name="productId"
                    value={
                      productObjectByFilter?.productID === 0
                        ? ""
                        : productObjectByFilter?.productID
                    }
                    // value={investmentID === 0 ? "" : investmentID}
                    // onChange={(e) => handleChange(e)}
                    className={classNames({
                      "p-invalid": false,
                      "card-inputBox": true,
                    })}
                    placeholder="Investment Id"
                    disabled
                  />
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                  <label className="inputLabel">Lookup Code </label>
                  <InputText
                    name="lookupCode"
                    value={productObjectByFilter?.lookupCodeObj}
                    // onChange={(e) => handleChange(e)}
                    className="card-inputBox"
                    placeholder="Lookup code"
                    disabled
                  />
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                  <label className="inputLabel">Zip Code </label>
                  <InputText
                    name="zipCode"
                    value={productObjectByFilter?.zipCode}
                    // onChange={(e) => handleChange(e)}
                    className="card-inputBox"
                    placeholder="Zip Code"
                    disabled
                  />
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                  <label className="inputLabel">Address </label>
                  <InputText
                    name="address"
                    value={productObjectByFilter?.address}
                    // onChange={(e) => handleChange(e)}
                    className="card-inputBox"
                    placeholder="Address"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="formgrid grid px-2 pt-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
              <label className="inputLabel">Investment Start Date:</label>
              <h3>
                {allocationInvestorData.investmentData?.investmentStartDate
                  ? formatDateMMDDYYY(
                      new Date(
                        allocationInvestorData.investmentData?.investmentStartDate!
                      )
                    )
                  : ""}
              </h3>
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
              <label className="inputLabel">
                Most Recent Allocation Date:{" "}
              </label>
              <h3>
                {allocationInvestorData.investmentData?.lastAllocationDate
                  ? formatDateMMDDYYY(
                      new Date(
                        allocationInvestorData.investmentData?.lastAllocationDate!
                      )
                    )
                  : ""}
              </h3>
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
              <label className="inputLabel">Available Units:</label>
              <h3>{allocationInvestorData.investmentData?.availableUnits} </h3>
            </div>
          </div>

          {InfoMessage ? (
            <>
              <div className="allocate-block m-2">
                <div className="m-2 text-white">
                  <p className="m-0">{`Note: ${InfoMessage}`}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="formgrid grid px-2 mt-3">
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
                  <label className="inputLabel">Select Investor Accounts</label>

                  <h6 className="mt-2">
                    Below, you can select one or multiple investor accounts to
                    allocate units to.
                  </h6>
                </div>
              </div>
              <DataTableComponent
                valueData={allocationInvestorData.output || []}
                // valueData={testdata || []}
                fieldsElements={dataTableElement}
                noGridLines={true}
                isPaginationAllowed={true}
                className="allocateSliceTable"
              />

              <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center ">
                <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                  <Button
                    className="btn-nav"
                    onClick={(e) => {
                      e.preventDefault();
                      onCancel();
                    }}
                  >
                    <span className="ml-auto mr-auto ">Cancel</span>
                  </Button>
                </div>
                <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                  <Button
                    className="btn-navActive"
                    type="submit"
                    disabled={
                      allocationSliceInvestorList.investorList.length === 0
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      onContinue();
                    }}
                  >
                    <span className="ml-auto mr-auto ">Continue</span>
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
