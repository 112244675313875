import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { saveQuestData } from "../../services/quest";
import error from "../../utils/error";

const initialState = {
  Data: [],
  loading: "loading",
  error: undefined,
};

export const storeQuestThunk = createAsyncThunk(
  "capital-quest",
  async (data: any) => {
    try {
      const response = await saveQuestData(data);
      return response;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const questSlice = createSlice({
  name: "capital-quest",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(storeQuestThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(storeQuestThunk.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(storeQuestThunk.rejected, (state, action) => {
      state.loading = "failed";
      state.error = error(action.payload);
    });
  },
});

export default questSlice.reducer;
