import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStepStatusService } from "../../../../services/investor/investor";

import error from "../../../../utils/error";
import { IgetStepStatusPayload } from ".";

interface IgetStepStatus {
  data?: {
    iaEnrollentInfoStatus: string;
    investorProfileStatus: string;
  };
  loading: string;
  error?: string | null;
}

const initialState: IgetStepStatus = {
  data: undefined,
  loading: "loading",
  error: undefined,
};

export const getStepStatusThunk = createAsyncThunk(
  "investor/investor-approval/getStepStatus",
  async (data: IgetStepStatusPayload) => {
    try {
      const response = await getStepStatusService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "investor/investor-approval/getStepStatus",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getStepStatusThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getStepStatusThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload._response.data;
      console.log("action", action.payload._response.data);
    });
    builder.addCase(getStepStatusThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
