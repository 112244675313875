import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const initialPayloadUpdateData = {
  productId: 0,
  investmentId: 0,
  investorList: [],
  investorListInitialState: [],
  isDateChange: false,
  showAllocatedInvestor: false,
  remainingInvestmentUnits: 0,
};
export const initialState = {
  loading: "loading",
  data: {
    productId: 0,
    investmentId: 0,
    investorList: [],
    investorListInitialState: [],
    isDateChange: false,
    showAllocatedInvestor: false,
    remainingInvestmentUnits: 0,
  },
};

export const setUpdateAllocationSliceState = createAsyncThunk(
  "setUpdateAllocationSliceState",
  async (_data: any) => {
    return _data;
  }
);

const slice = createSlice({
  name: "setUpdateAllocationSliceState",
  initialState,
  reducers: {},
  // reducers: {
  //   setUpdateAllocationSliceState: (state, action) => {
  //     console.log(" as", action.payload);
  //     state.data = action.payload;
  //     //
  //   },
  // },
  extraReducers(builder): void {
    builder.addCase(
      setUpdateAllocationSliceState.fulfilled,
      (state, action: any) => {
        state.loading = "succeeded";
        state.data = action.payload;
      }
    );
  },
});

export default slice.reducer;
