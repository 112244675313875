// import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
// import { RiSearchLine } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getAnnualizedReturnsCompletedCommits } from "../../../../store/features/reports/annualized-returns-completed/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import { formatanyepercentage } from "../../../../utils/helper";
// import AnnualizedReturnsCompletedSkeleton from "./AnnualizedReturnsCompletedSkeleton";

export default function AnnualizedReturnsCompleted() {
  const dispatch = useAppDispatch();

  const annualizedReturnsCompletedmmodel = useAppSelector(
    (state) => state.annualizedReturnsCompleted.data
  );
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  const [loading, setloading] = useState<any>(false);

  const [exitDate, setExitDate] = useState<any>();
  // console.log("h", misssedExitDates);
  // const getExitDateConversion = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedExitDate = {
  //       ...d,
  //       fundingDate: new Date(d.fundingDate),
  //     };
  //     return modifiedExitDate;
  //   });
  // };
  const formatDate = (rowData: any) => {
    // const dateFormat = rowData?.fundingDate?.toLocaleDateString("en-us", {
    //   day: "2-digit",
    //   month: "2-digit",
    //   year: "numeric",
    // });
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const formatCurrencyAnyData = (rowData: any) => {
    const finalValue = Number(rowData);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };

  const formatactualReturnPercentage = (rowData: any) => {
    return formatanyepercentage(rowData.actualReturn);
  };
  const formatprojectedReturnPercentage = (rowData: any) => {
    if (rowData?.projectedReturn?.includes("%")) {
      return rowData.projectedReturn;
    }
    return formatanyepercentage(rowData.projectedReturn);
  };
  const formatprincipalReturnedCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.principalReturned);
  };
  const formatprincipalInvestedCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.principalInvested);
  };
  const formattotalEarningsCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.totalEarnings);
  };
  const formatoriginalPrincipalInvestedCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.originalPrincipalInvested);
  };

  const dataTableElements = [
    {
      field: "supplierName",
      header: "Supplier Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.supplierName}>
          {rowData?.supplierName}
        </div>
      ),
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
      style: { minWidth: "180px" },
    },
    {
      field: "externalInvestmentID",
      header: "External Investment Id",
      style: { minWidth: "200px" },
    },
    {
      field: "category",
      header: "Category",
      style: { minWidth: "100px" },
    },
    {
      field: "fundingDate",
      header: "Funding Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.fundingDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "180px" },
    },

    {
      field: "estExitDate",
      header: "Est. Exit Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.estExitDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "180px" },
    },
    {
      field: "actualExitDate",
      header: "Actual Exit Date",
      dataType: "date",
      style: { minWidth: "250px" },
      body: (rowData: any) => formatDate(rowData.actualExitDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "estVsActual",
      header: "Est Vs Actual",
      style: { minWidth: "230px" },
    },
    {
      field: "originalPrincipalInvested",
      header: "Ori Principal Invested",
      style: { minWidth: "230px" },
      body: formatoriginalPrincipalInvestedCurrency,
    },
    {
      field: "daysInvested",
      header: "Days Invested",
      style: { minWidth: "200px" },
    },
    {
      field: "totalEarnings",
      header: "Total Earnings",
      style: { minWidth: "200px" },
      body: formattotalEarningsCurrency,
    },
    {
      field: "projectedReturn",
      header: "Projected Return",
      style: { minWidth: "180px" },
      body: formatprojectedReturnPercentage,
    },
    {
      field: "actualReturn",
      header: "Actual Return (annualized)",
      style: { minWidth: "240px" },
      body: formatactualReturnPercentage,
    },
    {
      field: "principalInvested",
      header: "Principal Invested",
      style: { minWidth: "230px" },
      body: formatprincipalInvestedCurrency,
    },
    {
      field: "principalReturned",
      header: "Principal Returned",
      style: { minWidth: "230px" },
      body: formatprincipalReturnedCurrency,
    },
  ];
  const exportExcelHeader = [
    {
      field: "supplierName",
      header: "Supplier Name",
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
    },
    {
      field: "externalInvestmentID",
      header: "External Investment Id",
    },
    {
      field: "category",
      header: "Category",
    },
    {
      field: "fundingDate",
      header: "Funding Date",
      dataType: "date",
    },

    {
      field: "estExitDate",
      header: "Est. Exit Date",
      dataType: "date",
    },
    {
      field: "actualExitDate",
      header: "Actual Exit Date",
      dataType: "date",
    },
    {
      field: "estVsActual",
      header: "Est Vs Actual",
    },
    {
      field: "originalPrincipalInvested",
      header: "Ori Principal Invested",
      dataType: "currency",
    },
    {
      field: "daysInvested",
      header: "Days Invested",
    },
    {
      field: "totalEarnings",
      header: "Total Earnings",
      dataType: "currency",
    },
    {
      field: "projectedReturn",
      header: "Projected Return",
      dataType: "percent",
    },
    {
      field: "actualReturn",
      header: "Actual Return (annualized)",
      dataType: "percent",
    },
    {
      field: "principalInvested",
      header: "Principal Invested",
      dataType: "currency",
    },
    {
      field: "principalReturned",
      header: "Principal Returned",
      dataType: "currency",
    },
    {
      field: "productID",
      header: "Product ID",
    },
  ];

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  useEffect(() => {
    if (dropdown.fundid !== undefined && dropdown.fundid > 0) {
      setExitDate([]);
      const parameters = {
        legalEntityId: dropdown.fundid,
      };
      setloading(true);
      dispatch(getAnnualizedReturnsCompletedCommits(parameters))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    }
  }, [dropdown.fundid]);
  useEffect(() => {
    setExitDate(
      getListDateConversion(annualizedReturnsCompletedmmodel, [
        "fundingDate",
        "estExitDate",
        "actualExitDate",
      ])
    );
  }, [annualizedReturnsCompletedmmodel]);
  console.log(
    "annualizedReturnsCompletedmmodel",
    annualizedReturnsCompletedmmodel
  );
  // const rowDta = 10;
  return (
    <>
      {loading && <Loader />}
      <DataTableComponent
        valueData={exitDate || []}
        fieldsElements={dataTableElements}
        isPaginationAllowed={true}
        isDownload={true}
        scrollable={true}
        excelFieldsElements={exportExcelHeader}
        HeadingName="Annualized Returns-Completed Investments Report"
        fileName="Annualized Returns-Completed Investments Report"
        isFullView={true}
        className="gridcell"
      />
    </>
  );
}
