import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCustomerTypeService } from "../../../../services/finance/finance";
// import { IAddBankWithPlaidRequestBody } from "../../../../types";
import { ILegalEntityAndPartnerID } from "../../../../types";
import error from "../../../../utils/error";

interface ICustomerType {
  data: string;
  loading: string;
  error?: string | null;
}

const initialState: ICustomerType = {
  data: "",
  loading: "loading",
  error: undefined,
};

export const getCustomerTypeThunk = createAsyncThunk(
  "plaid/getCustomerType",
  async (data: ILegalEntityAndPartnerID) => {
    try {
      const response = await getCustomerTypeService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "plaid/getCustomerType",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getCustomerTypeThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getCustomerTypeThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("Acount type", action.payload._response.data.accountType);
      state.data = action.payload._response.data
        ? action.payload._response.data.accountType
        : undefined;
    });
    builder.addCase(getCustomerTypeThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
