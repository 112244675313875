/* eslint-disable react/react-in-jsx-scope */
import { Outlet, NavLink } from "react-router-dom";

const Opportunities = () => {
  return (
    <form className=" h-100" action="#">
      <div className="tabHeader-wrap">
        <NavLink
          to="../Opportunities/AllOpportunities"
          className={({ isActive }) =>
            isActive ? "tabHeadText Active" : "tabHeadText"
          }
        >
          All Opportunities
        </NavLink>{" "}
        <NavLink
          to="../Opportunities/Equity"
          className={({ isActive }) =>
            isActive ? "tabHeadText Active" : "tabHeadText"
          }
        >
          Equity
        </NavLink>
        <NavLink
          to="../Opportunities/Debt"
          className={({ isActive }) =>
            isActive ? "tabHeadText Active" : "tabHeadText"
          }
        >
          Debt
        </NavLink>
      </div>{" "}
      <Outlet />
    </form>
  );
};

export default Opportunities;
