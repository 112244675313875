import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import {
  initialPayloadAddState,
  setAllocationSliceInvestorList,
} from "../../../../../store/features/operation/allocation-slices/add-investors-allocation-state/slice";
import { setAllInvestorAllocateData } from "../../../../../store/features/operation/allocation-slices/get-all-investor-allocation/slice";
import { setAllocatedInvestorState } from "../../../../../store/features/operation/allocation-slices/get-allocated-investor/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { errorToastMessage } from "../../../../../utils/toast-message";
import AllocationList from "./AllocationList";

interface IProps {
  handleNext: any;
  handlePrevious: any;
}
export default function AllocationStep1(props: IProps) {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [productObjectByFilter, setProductObjectByFilter] = useState<any>({
    productID: 0,
    lookupCodeObj: "",
    address: "",
    zipCode: "",
  });
  const allocationSliceInvestorList = useAppSelector(
    (state) => state.allocationSliceInvestorState.data
  );
  const handleRadioChange = (value: any) => {
    dispatch(
      setAllocationSliceInvestorList({
        ...allocationSliceInvestorList,
        isDateChange: value,
      })
    );
  };
  const onDialogCancel = () => {
    setOpenDialog(false);
    dispatch(
      setAllocationSliceInvestorList({
        ...allocationSliceInvestorList,
        isDateChange: false,
      })
    );
  };
  const onContinue = () => {
    if (allocationSliceInvestorList.investorList.length > 0) {
      setOpenDialog(true);
      dispatch(
        setAllocationSliceInvestorList({
          ...allocationSliceInvestorList,
          // showAllocatedInvestor: false,
          allocatedInvestorTab: undefined,
        })
      );
    } else {
      errorToastMessage(toast, "Please select one investor");
    }
  };
  const onCancel = () => {
    // dispatch(
    //   setAllocationSliceInvestorList({
    //     ...allocationSliceInvestorList,
    //     investorList: [],
    //     productId: 0,
    //     investmentId: 0,
    //     // showAllocatedInvestor: false,
    //     allocatedInvestorTab: undefined,
    //   })
    // );
    dispatch(setAllocationSliceInvestorList(initialPayloadAddState));
    dispatch(
      setAllInvestorAllocateData({ output: [], investmentData: undefined })
    );
    dispatch(
      setAllocatedInvestorState({ output: [], investmentData: undefined })
    );
    setProductObjectByFilter({
      productID: 0,
      lookupCodeObj: "",
      address: "",
      zipCode: "",
    });
    props.handlePrevious();
  };
  useEffect(() => {
    setProductObjectByFilter({
      productID: 0,
      lookupCodeObj: "",
      address: "",
      zipCode: "",
    });
  }, [fundDropdown.fundid]);
  return (
    <>
      <Toast ref={toast} className="themeToast" />
      <Dialog
        visible={openDialog}
        onHide={() => onDialogCancel()}
        className="w-100 md:w-5 pb-1"
        headerClassName="no-header"
      >
        <div className="mt-2">
          <h4 className="pageHeader">
            By default, all investor accounts are allocated their units on the
            investment start date.
          </h4>
          <h4 className="pageHeader mt-2"> Do you need to change this?</h4>
          <div className="p-2 col-12">
            <div className="mt-2">
              <div className="flex align-items-center pr-4 mb-1">
                <RadioButton
                  inputId="changeDate"
                  name="changeDate"
                  // value="no"
                  onChange={(e) => handleRadioChange(false)}
                  checked={allocationSliceInvestorList.isDateChange === false}
                />

                <label htmlFor="changeDate" className="ml-2 mb-0">
                  No
                </label>
              </div>
              <div className="flex align-items-center pr-4">
                <RadioButton
                  inputId="changeDate"
                  name="changeDate"
                  // value="yes"
                  onChange={(e) => handleRadioChange(true)}
                  checked={allocationSliceInvestorList.isDateChange === true}
                />

                <label htmlFor="changeDate" className="ml-2 mb-0">
                  Yes
                </label>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <p>
              You should only do this if you are back-filling allocations. For
              example, if you placed the investment with your own capital
              initially, and are re-allocating it to investors after the fact.
            </p>
            <p className="mt-2">
              Changing these dates can cause issues if done without reason.
            </p>
          </div>
          <div className="flex flex-row flex-wrap sm:flex-none p-0 col-12 justify-content-center ">
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-nav"
                onClick={(e) => {
                  e.preventDefault();
                  onDialogCancel();
                }}
              >
                <span className="ml-auto mr-auto ">Cancel</span>
              </Button>
            </div>
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-navActive"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenDialog(false);
                  props.handleNext();
                }}
              >
                <span className="ml-auto mr-auto ">Continue</span>
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <div className="headercard d-flex grid">
        <div className="col-12 md:col-6">
          <h2 style={{ fontWeight: "bold" }}>Allocate Units</h2>
        </div>
        <div className="col-12 md:col-6 end-div">
          <h6>Step 1 - Select Investment</h6>
        </div>
      </div>

      <div>
        <ProgressBar value={20} showValue={false} className="m-2"></ProgressBar>
      </div>
      <AllocationList
        onCancel={onCancel}
        onContinue={onContinue}
        productObjectByFilter={productObjectByFilter}
        setProductObjectByFilter={setProductObjectByFilter}
      />
    </>
  );
}
