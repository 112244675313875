import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDashboardInfoService } from "../../../services/dashboard";
import error from "../../../utils/error";
import { IGetDashboardInfoState, IGetDashboardInfoparameters } from ".";

const initialState: IGetDashboardInfoState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  accountOverview: undefined,
  dynamicValues1: "",
  dynamicValues2: "",
  portfolioSize: undefined,
};
export const GetDashboardInfoThunk = createAsyncThunk(
  "DashboardManagement/GetDashboardInfo",
  async (data: IGetDashboardInfoparameters) => {
    try {
      const response = await GetDashboardInfoService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "DashboardManagement/GetDashboardInfo",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetDashboardInfoThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetDashboardInfoThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload
        ? action.payload._response.data.dashboard
        : [];
      state.accountOverview = action.payload
        ? action.payload._response.data.accountOverview
        : [];
      state.dynamicValues1 = action.payload
        ? action.payload._response.data.dynamicValues1.jsonpath
        : [];
      state.dynamicValues2 = action.payload
        ? action.payload._response.data.dynamicValues2.jsonpath2
        : [];
      state.portfolioSize = action.payload
        ? action.payload._response.data.portfolioSize
        : [];
    });
    builder.addCase(GetDashboardInfoThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
