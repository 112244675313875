import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { getPlaidLinkTokenService } from "../../../../services/finance/finance";
// import { getPlaidLinkTokenService } from "../../../../services/finance/finance";
// import { ILegalEntityAndPartnerID } from "../../../../types/index";
import { getPlaidLinkTokenService } from "../../../../services/Dwolla/dwolla";
import error from "../../../../utils/error";

// interface IResponsePlaidToken {

//         expiration: string;
//         link_token: string;
//         request_id: string;

// }
interface IPlaidLinkToken {
  data: {
    expiration: string;
    link_token: string;
    request_id: string;
  };
  loading: string;
  error?: string | null;
}

const initialState: IPlaidLinkToken = {
  data: {
    expiration: "",
    link_token: "",
    request_id: "",
  },
  loading: "loading",
  error: undefined,
};

export const getPlaidLinkTokenThunk = createAsyncThunk(
  "plaid/getPlaidLinkToken",
  async () => {
    try {
      const response = await getPlaidLinkTokenService();
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "plaid/getPlaidLinkToken",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getPlaidLinkTokenThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getPlaidLinkTokenThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      //
      state.data = action.payload._response.data;
      // state.currentPage += 1;
    });
    builder.addCase(getPlaidLinkTokenThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
