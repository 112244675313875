import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestorDocumentTypeDropdownService } from "../../../../../services/investor/investor";
import error from "../../../../../utils/error";
import { IInvestorDocumentTypeDropDownState } from ".";

const initialState: IInvestorDocumentTypeDropDownState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkInvestorDocumentTypeDropdown = createAsyncThunk(
  "getInvestorDocumentTypeDropdown",
  async (data: any) => {
    try {
      const response = await getInvestorDocumentTypeDropdownService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getInvestorDocumentTypeDropdown",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkInvestorDocumentTypeDropdown.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getThunkInvestorDocumentTypeDropdown.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(action.payload);
        state.data =
          action.payload._response.data !== null
            ? action.payload._response.data.documentType
            : [];
        state.success = action.payload._response.success;
        state.message = action.payload._response.message;
      }
    );
    builder.addCase(
      getThunkInvestorDocumentTypeDropdown.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
