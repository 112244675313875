import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFundDocumentTypeDropDownService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IFundDocumentTypeDropdownState } from ".";

const initialState: IFundDocumentTypeDropdownState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
  filteredDropdown: [],
};
export const getThunkFundDocumentTypeDropdown = createAsyncThunk(
  "getFundDocumentTypeDropdownList",
  async (data: any) => {
    try {
      const response = await GetFundDocumentTypeDropDownService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getFundDocumentTypeDropdownList",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkFundDocumentTypeDropdown.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getThunkFundDocumentTypeDropdown.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(action.payload);
        state.data =
          action.payload._response.data !== null
            ? action.payload._response.data.doclist
            : [];
        state.filteredDropdown =
          action.payload._response.data !== null
            ? action.payload._response.data.doclist?.filter(
                (doc: any) =>
                  !(
                    doc.key === "25" ||
                    doc.key === "26" ||
                    doc.key === "27" ||
                    doc.key === "28"
                  )
              )
            : [];
        state.success = action.payload._response.success;
        state.message = action.payload._response.message;
      }
    );
    builder.addCase(
      getThunkFundDocumentTypeDropdown.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
