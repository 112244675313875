import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { goToTop } from "../../../../components/scroll-to/ScrollTo";
import { setProductId } from "../../../../store/features/investment/investment-filter/investment-filter-id/slice";
import { addThunkInvestmentReturn } from "../../../../store/features/operation/allocate-earning/add-investment-return/slice";
import { getThunkCalculateEarning } from "../../../../store/features/operation/allocate-earning/calculate-earnings/slice";
import {
  getThunkAllocateEarning,
  getThunkDistributeEarning,
} from "../../../../store/features/operation/allocate-earning/get-earning-data/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  convertLocalDateToUTC,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

const AllocateEarningList: React.FC<any> = ({
  productObjectByFilter,
  setProductObjectByFilter,
  investmentID,
  setInvestmentId,
  setEditData,
  openDailog,
  setOpenDailog,
  setLoadForm,
  setShowAddEarnings,
}) => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [investmentReturnID, setInvestmentReturnId] = useState(0);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [emailStatus, setEmailStatus] = useState("N");

  // const [shouldFreeze, setShouldFreeze] = useState(window.innerWidth >= 768);
  const earningobjInitialState = {
    supplier: "",
    investmentName: "",
    investmentReturnType: "",
    returnAmount: 0,
    returnDate: "",
    investmentReturnTypeId: 0,
    equityDistriAllocationDate: "",
  };
  const [earningObj, setEarningObj] = useState(earningobjInitialState);

  const earningData = useAppSelector(
    (state) => state.getAllocatedEarningData.data.investmentReturns
  );
  const calculateEarning = useAppSelector(
    (state) => state.getCalculateEarning.data
  );

  const resetProductObjState = () => {
    setProductObjectByFilter({
      productID: 0,
      lookupCodeObj: "",
      address: "",
      zipCode: "",
      fundingDate: "",
    });
  };

  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };

  const calculateEarningData = useMemo(() => {
    return calculateEarning?.length > 0
      ? getListDateConversion(calculateEarning, [
          "startDate",
          "endDate",
          "earningsDate",
          "equityCashDistiAllocationDate",
          "earningsProcessedDate",
        ])
      : [];
  }, [calculateEarning]);

  const allocateEarningsData = useMemo(() => {
    return earningData?.length > 0
      ? getListDateConversion(earningData, [
          "returnDate",
          "actualExitDate",
          "equityCashDistiAllocationDate",
        ])
      : [];
  }, [earningData]);

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      setLoading(true);
      const param = {
        legalEntityID: fundDropdown.fundid,
        investmenId: investmentID,
      };
      dispatch(getThunkAllocateEarning(param))
        .then((response) => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fundDropdown.fundid, investmentID]);

  const computeSum = (
    totalFieldName: string,
    calculateDataForEarning = calculateEarning
  ) => {
    if (calculateDataForEarning !== undefined) {
      const { length } = calculateDataForEarning;
      let count = 0;
      for (let i = 0; i < length; i += 1) {
        count += Number(
          typeof calculateDataForEarning[i][totalFieldName] === "object"
            ? calculateDataForEarning[i][totalFieldName]
            : calculateDataForEarning[i][totalFieldName] || 0
        );
      }
      console.log("yeys", Math.round(count));
      return Math.round(count * 100) / 100;
    }
    return 0;
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const distributeButton = (rowData: any) => {
    const handleDistribute = () => {
      dispatch(setProductId({ productId: 0, resetFilters: true }));
      resetProductObjState();
      // eslint-disable-next-line unicorn/no-null
      setEditData(null);

      setLoading(true);
      const parameter = {
        investmentReturnTypeID: rowData.investmentReturnTypeID,
        investmentReturnID: rowData.investmentReturnID,
      };
      const partialExist =
        Number(rowData.returnAmount) < Number(rowData.totalAmount);
      setInvestmentReturnId(rowData.investmentReturnID);
      dispatch(getThunkCalculateEarning(parameter))
        .then((response) => {
          if (rowData.investmentReturnTypeID === 3 && !partialExist) {
            setOpenConfirmBox(true);
          } else {
            setOpenDialog(true);
            setLoading(true);
          }
          setErrorMessage2("");
          if (
            rowData.unitPriceCalculated === 0 &&
            rowData.investmentReturnTypeID === 3
          ) {
            setErrorMessage2(
              "All the capital for the selected investment has already been returned. Please reverify your previous distributions."
            );
          }

          if (
            response.payload._response.success !== false &&
            response.payload._response.data.output.length > 0
          ) {
            const total = computeSum(
              "earningsShare",
              response.payload._response.data.output
            );
            if (
              currencyFormat(rowData.returnAmount) !== currencyFormat(total)
            ) {
              setErrorMessage(
                `Return Amount and total of proposed distribution is different. Please check the data before distributing this . If the difference is just a few cents you can proceed to distribute. If the difference is much larger, we highly recommend you contact support@avestorinc.com for further assistance.`
              );
            } else {
              setErrorMessage("");
              setErrorMessage2("");
            }
          } else {
            if (response.payload._response.message !== "No data found") {
              setErrorMessage(response.payload._response.message);
            } else {
              setErrorMessage("");
            }
            setLoading(false);
          }
        })
        .catch(() => {
          errorToastMessage(toast);
          setInvestmentReturnId(0);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });

      setEarningObj({
        supplier: rowData.supplier,
        investmentName: rowData.product,
        investmentReturnType: rowData.investmentReturnType,
        returnAmount: rowData.returnAmount,
        returnDate: formatDate(rowData.returnDate),
        investmentReturnTypeId: rowData.investmentReturnTypeID,
        equityDistriAllocationDate: "",
      });
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              handleDistribute();
            }}
          >
            <span className="text-white">Distribute</span>
          </Button>
        </div>
      </div>
    );
  };
  const editButton = (rowData: any) => {
    const handleEdit = () => {
      const editData = {
        returnDate: rowData.returnDate,
        returnType: rowData.investmentReturnTypeID,
        allocationDate: rowData.equityCashDistiAllocationDate,
        amount: rowData.returnAmount,
        productId: rowData.productId,
        investmentReturnID: rowData.investmentReturnID,
      };
      setEditData(editData);
      setInvestmentId(rowData.externalInvestmentID);
      dispatch(
        setProductId({ productId: rowData.productId, resetFilters: false })
      );
      setProductObjectByFilter({
        ...productObjectByFilter,
        productID: rowData.productId,
      });
      setShowAddEarnings(false);
      setLoadForm(true);
      goToTop();
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              handleEdit();
            }}
          >
            <span className="text-white">Edit</span>
          </Button>
        </div>
      </div>
    );
  };
  const deleteButton = (rowData: any) => {
    const handleDelete = () => {
      //   formik.resetForm();
      //   formik.values.amount = 0;
      //   formik.setFieldValue("productId", productObjectByFilter?.productID);
      dispatch(setProductId({ productId: 0, resetFilters: true }));
      resetProductObjState();
      // eslint-disable-next-line unicorn/no-null
      setEditData(null);
      setInvestmentReturnId(rowData.investmentReturnID);
      setEarningObj({
        supplier: rowData.supplier,
        investmentName: rowData.product,
        investmentReturnType: rowData.investmentReturnType,
        returnAmount: rowData.returnAmount,
        returnDate:
          rowData.returnDate === null
            ? ""
            : convertLocalDateToUTC(rowData.returnDate),
        investmentReturnTypeId: rowData.investmentReturnTypeID,
        equityDistriAllocationDate:
          rowData.equityCashDistiAllocationDate === null
            ? rowData.equityCashDistiAllocationDate
            : convertLocalDateToUTC(rowData.equityCashDistiAllocationDate),
      });
      setInvestmentId(rowData.externalInvestmentID);
      setOpenDeleteDialog(true);
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              handleDelete();
            }}
          >
            <span className="text-white">Delete</span>
          </Button>
        </div>
      </div>
    );
  };
  const onCancelDelete = () => {
    setInvestmentReturnId(0);
    setInvestmentId(0);
    setEarningObj(earningobjInitialState);
    setOpenDeleteDialog(false);
  };
  const onDelete = () => {
    setOpenDeleteDialog(false);
    setLoading(true);
    if (investmentReturnID !== 0) {
      const parameter = {
        action: "Delete",
        investmentReturnId: investmentReturnID,
        investmentId: investmentID,
        returnTypeId: earningObj.investmentReturnTypeId,
        returnAmount: earningObj.returnAmount,
        returnDate: earningObj.returnDate,
        equityCashDistiAllocationDate: earningObj.equityDistriAllocationDate,
      };
      dispatch(addThunkInvestmentReturn(parameter))
        .then((response) => {
          successToastMessage(toast, "Data is deleted successfully");
          setEarningObj(earningobjInitialState);
          const param = {
            legalEntityID: fundDropdown.fundid,
            investmenId: investmentID,
          };
          dispatch(getThunkAllocateEarning(param));
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
          setInvestmentReturnId(0);
          setInvestmentId(0);
        });
    }
  };
  const handleDistribution = () => {
    setLoading(true);
    const parameter = {
      investmentReturnTypeId: earningObj.investmentReturnTypeId,
      investmentReturnId: investmentReturnID,
      legalEntityId: fundDropdown.fundid,
      emailStatus,
    };
    dispatch(getThunkDistributeEarning(parameter))
      .then((response) => {
        if (response.meta.requestStatus === "rejected") {
          errorToastMessage(toast);
          setLoading(false);
        } else if (response.meta.requestStatus === "fulfilled") {
          setLoading(false);
          successToastMessage(toast, "Processed Successfully.");
          setOpenDialog(false);
          setInvestmentReturnId(0);
          setEarningObj(earningobjInitialState);
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onConfirm = () => {
    setOpenDialog(true);
    setOpenConfirmBox(false);
    setEmailStatus("Y");
  };
  const onCancel = () => {
    setOpenDialog(true);
    setOpenConfirmBox(false);
    setEmailStatus("N");
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     setShouldFreeze(window.innerWidth >= 768);
  //   };

  //   // Attach the event listener
  //   window.addEventListener("resize", handleResize);
  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  const dataTableElement = [
    {
      field: "product",
      header: "Investment Name",
      // frozen: shouldFreeze && true,
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.product}>
          {rowData?.product}
        </div>
      ),
    },
    {
      field: "supplierCode",
      header: "Lookup Code",
      // frozen: shouldFreeze && true,
      style: { minWidth: "175px" },
    },
    {
      field: "returnDate",
      header: "Return Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.returnDate),
      filterElement: dateFilterTemplate,
      // frozen: shouldFreeze && true,
      style: { minWidth: "175px" },
    },
    {
      field: "returnAmount",
      header: "Return Amount",
      body: (rowData: any) => currencyFormat(rowData.returnAmount),
      // frozen: shouldFreeze && true,
      className: "123",
      style: { minWidth: "175px" },
    },
    {
      field: "investmentReturnType",
      header: "Investment Return Type",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.investmentReturnType}>
          {rowData?.investmentReturnType}
        </div>
      ),
    },

    {
      field: "unitPriceCalculated",
      header: "Unit Price Calculated",
      style: { minWidth: "230px" },
    },
    {
      field: "supplier",
      header: "Supplier",
      style: { minWidth: "160px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.supplier}>
          {rowData?.supplier}
        </div>
      ),
    },

    {
      field: "investmentReturnID",
      header: "Return Id",
      style: { minWidth: "100px" },
    },
    {
      field: "actualExitDate",
      header: "Actual Exit Date",
      dataType: "date",
      style: { minWidth: "200px" },
      body: (rowData: any) =>
        rowData.actualExitDate === null
          ? ""
          : formatDate(rowData.actualExitDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "",
      header: "Distribute",
      body: distributeButton,
      style: { minWidth: "160px" },
    },
    { field: "", header: "", body: editButton, style: { minWidth: "160px" } },
    { field: "", header: "", body: deleteButton, style: { minWidth: "160px" } },
  ];
  const dataTableElementDistribute = [
    {
      field: "investmentReturnID",
      header: "Return Id",
      style: { minWidth: "160px" },
    },
    {
      field: "externalInvestmentID",
      header: "External Investment Id",
      style: { minWidth: "220px" },
    },
    {
      field: "lpPartnerID",
      header: "LP Partner Id",
      style: { minWidth: "160px" },
    },
    {
      field: "invName",
      header: "Partner Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.invName}>
          {rowData?.invName}
        </div>
      ),
    },
    {
      field: "units",
      header: "Units",
      style: { minWidth: "160px" },
      // body: (rowData: any) => rowData.units,
    },
    {
      field: "earningsShare",
      header: "Earnings Share",
      style: { minWidth: "160px" },
      body: (rowData: any) =>
        currencyFormat(Number.parseFloat(rowData.earningsShare).toFixed(2)),
    },
    {
      field: "startDate",
      header: "Start Date",
      dataType: "date",
      style: { minWidth: "160px" },
      body: (rowData: any) => formatDate(rowData.startDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "endDate",
      header: "End Date",
      dataType: "date",
      style: { minWidth: "160px" },
      body: (rowData: any) => formatDate(rowData.endDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "earningsDate",
      header: "Earnings Date",
      dataType: "date",
      style: { minWidth: "160px" },
      body: (rowData: any) => formatDate(rowData.earningsDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "equityCashDistiAllocationDate",
      header: "EqAllocDate",
      dataType: "date",
      style: { minWidth: "160px" },
      body: (rowData: any) => formatDate(rowData.equityCashDistiAllocationDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "earningsProcessedDate",
      header: "Earnings Processed Date",
      dataType: "date",
      style: { minWidth: "200px" },
      body: (rowData: any) => formatDate(rowData.earningsProcessedDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "daysInvested",
      header: "Days Invested",
      style: { minWidth: "160px" },
    },
    {
      field: "earningsRatePDPU",
      header: "Earnings Rate PDPU",
      style: { minWidth: "180px" },
    },
  ];
  const footerGroup = (
    <ColumnGroup>
      {calculateEarning?.length !== 0 && (
        <Row>
          <Column footer="Total:" colSpan={5} />
          <Column
            footer={currencyFormat(computeSum("earningsShare"))}
            colSpan={8}
          />
        </Row>
      )}
    </ColumnGroup>
  );
  const onHideDialog = () => {
    setEarningObj(earningobjInitialState);
    setInvestmentReturnId(0);
    setOpenDialog(false);
  };
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <ConfirmDailogbox
        openDialog={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        onConfirm={onDelete}
        onCancel={onCancelDelete}
        textContent="Are you sure you want to delete ?"
      />
      <Dialog
        visible={openDialog}
        onHide={() => onHideDialog()}
        headerClassName="no-header"
        contentClassName="top-lr-content"
        style={{ width: "90vw" }}
      >
        {loading === true ? <Loader /> : <></>}
        <div className="mt-4">
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Supplier </label>
              <InputText
                name="supplier"
                value={earningObj.supplier}
                className="card-inputBox"
                disabled
              />
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Investment Name </label>
              <InputText
                name="investmentName"
                value={earningObj.investmentName}
                className="card-inputBox"
                disabled
              />
            </div>
          </div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">Investment Return Type </label>
              <InputText
                name="investmentReturnType"
                value={earningObj.investmentReturnType}
                className="card-inputBox"
                disabled
              />
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">Return Amount</label>
              <InputNumber
                name="returnAmount"
                value={earningObj.returnAmount}
                className="card-inputBox"
                mode="currency"
                currency="USD"
                locale="en-US"
                disabled
              />
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
              <label className="inputLabel">Return Date </label>
              <InputText
                name="returnDate"
                value={earningObj.returnDate}
                className="card-inputBox"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <DataTableComponent
            valueData={calculateEarningData}
            fieldsElements={dataTableElementDistribute}
            isPaginationAllowed={true}
            footerElement={footerGroup}
            scrollable={true}
            className="gridcell deletebtn"
          />
        </div>

        <small className="p-error flex justify-content-center errormsg-size mt-3">
          {errorMessage}
        </small>
        <small className="p-error flex justify-content-center mt-1  errormsg-size">
          {errorMessage2}
        </small>
        <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
          <div className="d-flex flex-column p-2 col-12 sm:col-2 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleDistribution();
              }}
              // disabled={calculateEarning.length === 0 || errorMessage !== ""}
              disabled={computeSum("earningsShare") === 0}
              className="blueButtonShadow "
            >
              <span className="ml-auto mr-auto">Distribute</span>
            </Button>
          </div>
        </div>
      </Dialog>
      <ConfirmDailogbox
        openDialog={openConfirmBox}
        setOpenDialog={setOpenConfirmBox}
        onConfirm={onConfirm}
        onCancel={onCancel}
        textContent=" Would you like to notify investor about this investment exit?"
      />
      <DataTableComponent
        valueData={allocateEarningsData}
        fieldsElements={dataTableElement}
        isPaginationAllowed={true}
        scrollable={true}
        columnResizeMode="expand"
        className="gridcell deletebtn"
        // columnResizeMode="expand"
        openDailog={openDailog}
        setOpenDailog={setOpenDailog}
      />
    </>
  );
};

export default AllocateEarningList;
