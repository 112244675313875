import { useFormik } from "formik";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../components/loader/Loader";
import { goToTop } from "../../../../components/scroll-to/ScrollTo";
import { addThunkFundProfile } from "../../../../store/features/operation/setup-fund/add-fund-profile/slice";
import { getThunkCheckGPEMailExist } from "../../../../store/features/operation/setup-fund/check-gpmail-exist/slice";
import { getThunkCheckFundSlug } from "../../../../store/features/operation/setup-fund/check-slug-exits/slice";
import { setAddCancelState } from "../../../../store/features/operation/setup-fund/create-update-state/slice";
// import { GetSetupFundPayload } from "../../../../store/features/operation/setup-fund/get-setup-fund";
import { getThunkSetupFundEntityList } from "../../../../store/features/operation/setup-fund/get-setup-entity-list/slice";
import {
  getThunkSetupFund,
  // setSetupFundForm,
} from "../../../../store/features/operation/setup-fund/get-setup-fund/slice";
import { setStateChangeCheck } from "../../../../store/features/operation/setup-fund/setup-fund-state-check/slice";
import { getUserProfileDetailsThunk } from "../../../../store/features/profile/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  regexForAlphaNumericNoNumber,
  regexForEmail,
} from "../../../../utils/sponsor/constants";
import {
  errorToastMessage,
  // infoToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";
import { isValidUrl } from "../../../../utils/url-validation";
import SetupFundGPLLC from "./SetupFundGPLLC";
import SetupFundProfile from "./SetupFundProfile";
interface IProps {
  pageName: any;
}

export default function SetupFundAndManager(props: IProps) {
  const { pageName } = props;
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const setupFundInfo = useAppSelector((state) => state.getSetupFundInfo.data);
  const [loading, setLoading] = useState(false);
  const statecheckSetupFund = useAppSelector(
    (state) => state.statecheckSetupFund.data
  );
  // const [legalId, setLegalId] = useState(0);
  const addCancelSetup = useAppSelector(
    (state) => state.addCancelSetupState.data
  );
  const entityListId = useAppSelector(
    (state) => state.getSetupFundEntityList.data
  );
  const getSetupFundData = async (
    formik: any,
    legalEntityId?: any,
    gpEntityId?: any
  ) => {
    const parameters = {
      legalEntityId,
      gpLegalEntityId: gpEntityId || 0,
    };

    await dispatch(getThunkSetupFund(parameters))
      .then((response) => {
        if (
          // response.meta.requestStatus === "fulfilled" &&
          response.payload._response.data === null ||
          response.payload._response.data.fundProfileDetails!.length === 0
        ) {
          // dispatch(setSetupFundForm({ data: GetSetupFundPayload }));
          formik.resetForm();
        } else if (
          response.payload._response.data.fundGPProfileDetails!.length === 0
        ) {
          formik.setFieldValue("gpllcName", "");

          formik.setFieldValue("gpRegisteredState", "");
          formik.setFieldValue("gpEin", "");
          formik.setFieldValue("gpAddress", "");
          formik.setFieldValue("gpCity", "");
          formik.setFieldValue("gpState", "");
          formik.setFieldValue("gpZipCode", "");
          formik.setFieldValue("fundGPEmail", "");

          // formik.setFieldValue("fundAdminEmail", "");
        }
      })
      .catch(() => {
        // errorToastMessage(toast);
        //  dispatch(setSetupFundForm({ data: GetSetupFundPayload }));
        formik.resetForm();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      fundTypeId: 0,
      fundInitialSetupDate: "",
      investorSignUp: false,
      showFilledDeals: false,
      entityType: "3",
      fundSlug: "",
      fundName: "",
      registeredState: "",
      primaryFundEmail: "",
      ein: "",
      phoneNumber: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      unitPrice: 0,
      digisignerPPMTempId: "",
      dualSigner: false,
      signFundPPM: true,
      fundWebsite: "",
      emailLogoUrl: "",
      fundPrimaryColour: "#004d82",
      gpllcName: "",
      gpEntityType: "2",
      gpRegisteredState: "",
      gpEin: "",
      gpAddress: "",
      gpCity: "",
      gpState: "",
      gpZipCode: "",
      fundGPEmail: "",
      previousGPEmail: "",
      // fundGPPassword: "",
      // fundAdminEmail: "",
      // fundAdminPassword: "",
    },
    validate: (data) => {
      const errors: any = {};
      if (!data.fundSlug) {
        errors.fundSlug = "Fund slug is required";
      }
      if (data.fundSlug && !regexForAlphaNumericNoNumber.test(data.fundSlug)) {
        errors.fundSlug = "Please use alphabets with lowercase & number ";
      }
      if (data.emailLogoUrl && !isValidUrl(data.emailLogoUrl)) {
        errors.emailLogoUrl = "Enter valid url";
      }
      if (data.fundWebsite && !isValidUrl(data.fundWebsite)) {
        errors.fundWebsite = "Enter valid fundWebsite";
      }
      if (!data.fundTypeId) {
        errors.fundTypeId = "Select fund type";
      }
      if (!data.fundName) {
        errors.fundName = "Fund name is required";
      }

      if (data.ein && !/^\d{2}-\d{7}$/.test(data.ein)) {
        errors.ein = "Please enter valid ein number";
      }
      if (!data.unitPrice) {
        errors.unitPrice = "Unit price  is required";
      }
      if (data.unitPrice && !/^(1|10)$/.test(data.unitPrice.toString())) {
        errors.unitPrice = "Unit price is $1 or $10 only";
      }
      if (!data.registeredState) {
        errors.registeredState = "Select registered state";
      }
      if (!data.state) {
        errors.state = "Select state";
      }
      if (!data.city) {
        errors.city = "City is required";
      }
      if (!data.zipCode) {
        errors.zipCode = "Zip code is required";
      }
      if (!/^#[\da-f]{6}$/.test(data.fundPrimaryColour)) {
        errors.fundPrimaryColour = "Enter valid color code";
      }
      if (!data.gpllcName) {
        errors.gpllcName = "GP name is required";
      }

      if (!data.gpRegisteredState) {
        errors.gpRegisteredState = "Select registered state";
      }
      if (!data.gpState) {
        errors.gpState = "Select  state";
      }
      if (!data.gpCity) {
        errors.gpCity = "City is required";
      }
      if (!data.gpZipCode) {
        errors.gpZipCode = "Zip code is required";
      }
      if (data.gpEin && !/^\d{2}-\d{7}$/.test(data.gpEin)) {
        errors.gpEin = "Please enter 9 digit ein number";
      }
      if (!data.fundGPEmail) {
        errors.fundGPEmail = "GP email is required";
      }

      if (data.fundGPEmail && !regexForEmail.test(data.fundGPEmail)) {
        errors.fundGPEmail = "Enter valid email";
      }

      // if (!data.fundAdminEmail) {
      //   errors.fundAdminEmail = "Admin email is required";
      // }
      if (!data.primaryFundEmail) {
        errors.primaryFundEmail = "Email is required";
      }

      if (data.primaryFundEmail && !regexForEmail.test(data.primaryFundEmail)) {
        errors.primaryFundEmail = "Enter valid email";
      }

      return errors;
    },
    onSubmit: async () => {
      setLoading(true);
      let checkFundExistV: any;
      // console.log("f", legalId);
      const parameter = {
        urlName: formik.values.fundSlug,
        legalEntityId:
          setupFundInfo?.fundProfileDetails === undefined
            ? 0
            : addCancelSetup.fundId,
      };

      await dispatch(getThunkCheckFundSlug(parameter))
        .then((responseData) => {
          if (responseData.meta.requestStatus === "fulfilled") {
            // const tenentName = localStorage.getItem("tenentName");
            // dispatch(getUserProfileDetailsThunk({ Slug: tenentName }));
            checkFundExistV = responseData.payload._response.data.ifSlugExits;
            if (checkFundExistV === false) {
              dispatch(
                getThunkCheckGPEMailExist({
                  newGPEmail: formik.values.fundGPEmail,
                  oldGPEmail:
                    formik.values.previousGPEmail.trim() !==
                    formik.values.fundGPEmail.trim()
                      ? formik.values.previousGPEmail
                      : "",
                  legalEntityId:
                    setupFundInfo?.fundProfileDetails?.legalEntityID || 0,
                })
              ).then((resBody) => {
                console.log("tetst", resBody);
                if (resBody.meta.requestStatus === "fulfilled") {
                  if (resBody.payload._response.data.existFlag === true) {
                    errorToastMessage(toast, resBody.payload._response.message);
                    setLoading(false);
                  } else {
                    const formData = {
                      action:
                        setupFundInfo?.fundProfileDetails === undefined
                          ? "Create"
                          : "Update",
                      legalEntityID:
                        setupFundInfo?.fundProfileDetails?.legalEntityID || 0,
                      fundTypeId: formik.values.fundTypeId.toString(),
                      isAllowSignUp: formik.values.investorSignUp,
                      showFilledDeals: formik.values.showFilledDeals,
                      fundName: formik.values.fundName,
                      fundEntityTypeId: formik.values.entityType,
                      fundState: formik.values.registeredState,
                      addressFundState: formik.values.state,
                      fundEIN: formik.values.ein,
                      fundPrimaryColour: formik.values.fundPrimaryColour,
                      lpAddress: formik.values.address,
                      lpCity: formik.values.city,
                      lpZipCode: formik.values.zipCode,
                      urlName: formik.values.fundSlug,
                      logoUrl: formik.values.emailLogoUrl,
                      fundWebsite: formik.values.fundWebsite,
                      mobile: formik.values.phoneNumber,
                      digiTemplateID: formik.values.digisignerPPMTempId,
                      isDualSigner: formik.values.dualSigner,
                      isPPMSigner: formik.values.signFundPPM,
                      unitPrice: formik.values.unitPrice,
                      // fundEmail: formik.values.fundAdminEmail,
                      // fundPassword: formik.values.fundAdminPassword,
                      gpllcName: formik.values.gpllcName,
                      gpEntityType: +formik.values.gpEntityType,
                      gpState: formik.values.gpRegisteredState,
                      addressGPState: formik.values.gpState,
                      gpein: formik.values.gpEin,
                      gpAddress: formik.values.gpAddress,
                      gpCity: formik.values.gpCity,
                      gpZipCode: formik.values.gpZipCode,
                      gpEmail: formik.values.fundGPEmail
                        ? formik.values.fundGPEmail?.trim()
                        : formik.values.fundGPEmail,
                      primaryFundEmail: formik.values.primaryFundEmail
                        ? formik.values.primaryFundEmail?.trim()
                        : formik.values.primaryFundEmail,
                      fundGPEmailUpdate:
                        formik.values.previousGPEmail.trim() !==
                        formik.values.fundGPEmail.trim(),
                      previousGPEmail: formik.values.previousGPEmail,
                      // gpPassword: formik.values.fundGPPassword,
                    };
                    setLoading(true);
                    dispatch(addThunkFundProfile(formData))
                      .then((response) => {
                        if (response.payload._response.success === false) {
                          errorToastMessage(toast);
                          setLoading(false);
                        } else {
                          if (formData.action === "Update") {
                            localStorage.setItem(
                              "tenentName",
                              formik.values.fundSlug
                            );
                            const tenentName =
                              localStorage.getItem("tenentName");
                            dispatch(
                              getUserProfileDetailsThunk({ Slug: tenentName })
                            );
                          }

                          successToastMessage(toast, "Data saved successfully");
                          // setLegalId(
                          //   response.payload._response.data[0].legalEntityId
                          // );
                          const parameterEntity = {
                            legalEntityId:
                              response.payload._response.data[0].legalEntityId,
                          };
                          dispatch(
                            getThunkSetupFundEntityList(parameterEntity)
                          );
                          getSetupFundData(
                            formik,
                            response.payload._response.data[0].legalEntityId,
                            response.payload._response.data[0].gpLegalEntityId
                          );

                          // const fundManagerTab = FundManagerInfoFunction(
                          //   setupFundInfo,
                          //   statecheckSetupFund.fundManageApiData,
                          //   entityListId
                          // );
                          // dispatch(
                          //   setStateChangeCheck({
                          //     ...statecheckSetupFund,
                          //     fundManageApiData: fundManagerTab,
                          //   })
                          // );
                          // setLoading(false);
                          dispatch(
                            setAddCancelState({
                              ...addCancelSetup,
                              fundId:
                                response.payload._response.data[0]
                                  .legalEntityId,
                            })
                          );
                        }
                      })
                      .catch((error: any) => {
                        console.log("error", error);
                        setLoading(false);
                        errorToastMessage(toast);
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  }
                } else {
                  setLoading(false);
                  errorToastMessage(toast);
                }
              });
            } else {
              errorToastMessage(toast, "Fund Already Exist");
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .finally(() => {
          // setLoading(false);
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    console.log("ad in Manager", addCancelSetup);
    if (addCancelSetup.add === true) {
      console.log("v in fund manager", addCancelSetup.add);
      // dispatch(setSetupFundForm({ data: GetSetupFundPayload }));
      formik.resetForm();
      formik.setFieldValue("primaryFundEmail", "");
    }

    // else {
    //   formik.resetForm();
    //   if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
    //     setLegalId(fundDropdown.fundid);
    //     getSetupFundData(
    //       formik,
    //       fundDropdown.fundid,
    //       entityListId?.gpllcEntityId || 0
    //     );
    //   }
    // }
  }, [fundDropdown, entityListId, addCancelSetup.add]);
  // useEffect(() => {
  //   setLegalId(addCancelSetup.fundId);
  // }, [addCancelSetup.fundId]);
  useEffect(() => {
    console.log("fff");
    setErrorMessage("");
  }, []);
  useEffect(() => {
    if (setupFundInfo?.fundProfileDetails !== undefined) {
      console.log(
        "xy in fund manager",
        setupFundInfo?.fundProfileDetails?.fundPrimaryColour
      );
      formik.setFieldValue(
        "fundInitialSetupDate",
        entityListId?.dateEstablished
      );
      formik.setFieldValue(
        "fundName",
        setupFundInfo?.fundProfileDetails?.fundName
      );
      formik.setFieldValue(
        "fundEntityTypeId",
        setupFundInfo?.fundProfileDetails?.fundEntityTypeId
      );
      formik.setFieldValue(
        "fundTypeId",
        setupFundInfo?.fundProfileDetails?.fundTypeId
      );
      formik.setFieldValue(
        "investorSignUp",
        setupFundInfo?.fundProfileDetails?.isAllowSignUp
      );
      formik.setFieldValue(
        "showFilledDeals",
        setupFundInfo?.fundProfileDetails?.showFilledDeals
      );
      formik.setFieldValue(
        "fundSlug",
        setupFundInfo?.fundProfileDetails?.urlName
      );
      formik.setFieldValue(
        "registeredState",
        setupFundInfo?.fundProfileDetails?.fundState
      );
      formik.setFieldValue("ein", setupFundInfo?.fundProfileDetails?.fundEIN);
      formik.setFieldValue(
        "phoneNumber",
        setupFundInfo?.fundProfileDetails?.mobile
      );
      formik.setFieldValue(
        "address",
        setupFundInfo?.fundProfileDetails?.lpAddress
      );
      formik.setFieldValue("city", setupFundInfo?.fundProfileDetails?.lpCity);
      formik.setFieldValue(
        "state",
        setupFundInfo?.fundProfileDetails?.addressFundState
      );
      formik.setFieldValue(
        "zipCode",
        setupFundInfo?.fundProfileDetails?.lpZipCode
      );
      formik.setFieldValue(
        "unitPrice",
        setupFundInfo?.fundProfileDetails?.unitPrice
      );
      formik.setFieldValue(
        "digisignerPPMTempId",
        setupFundInfo?.fundProfileDetails?.digiTemplateID
      );
      formik.setFieldValue(
        "dualSigner",
        setupFundInfo?.fundProfileDetails?.isDualSigner
      );
      formik.setFieldValue(
        "signFundPPM",
        setupFundInfo?.fundProfileDetails?.isPPMSigner
      );
      formik.setFieldValue(
        "fundWebsite",
        setupFundInfo?.fundProfileDetails?.fundWebsite
      );
      formik.setFieldValue(
        "emailLogoUrl",
        setupFundInfo?.fundProfileDetails?.logoUrl
      );
      formik.setFieldValue(
        "fundPrimaryColour",
        setupFundInfo?.fundProfileDetails?.fundPrimaryColour === null
          ? "#004d82"
          : setupFundInfo?.fundProfileDetails?.fundPrimaryColour
      );
    }
    console.log("outside", setupFundInfo?.fundAdminData);
    if (setupFundInfo?.fundAdminData !== undefined) {
      console.log("inside");
      formik.setFieldValue(
        "primaryFundEmail",
        setupFundInfo?.fundAdminData.primaryFundAdminEmail
      );
    } else {
      formik.setFieldValue("primaryFundEmail", "");
    }

    if (setupFundInfo?.fundGPProfileDetails !== undefined) {
      formik.setFieldValue(
        "gpllcName",
        setupFundInfo?.fundGPProfileDetails?.gpllcName
      );
      // formik.setFieldValue(
      //   "entityType",
      //   setupFundInfo?.fundGPProfileDetails?.gpEntityType
      // );
      formik.setFieldValue(
        "gpRegisteredState",
        setupFundInfo?.fundGPProfileDetails?.gpState
      );
      formik.setFieldValue("gpEin", setupFundInfo?.fundGPProfileDetails?.gpein);
      formik.setFieldValue(
        "gpAddress",
        setupFundInfo?.fundGPProfileDetails?.address
      );
      formik.setFieldValue("gpCity", setupFundInfo?.fundGPProfileDetails?.city);
      formik.setFieldValue(
        "gpState",
        setupFundInfo?.fundGPProfileDetails?.addressGPState
      );
      formik.setFieldValue(
        "gpZipCode",
        setupFundInfo?.fundGPProfileDetails?.zipCode
      );
      formik.setFieldValue(
        "fundGPEmail",
        setupFundInfo?.fundGPProfileDetails?.gpEmail
      );
      formik.setFieldValue(
        "previousGPEmail",
        setupFundInfo?.fundGPProfileDetails?.gpEmail
      );
      // formik.setFieldValue(
      //   "fundAdminEmail",
      //   setupFundInfo?.fundGPEmailData?.fundAdminEmail
      // );
    }
  }, [setupFundInfo]);

  useEffect(() => {
    const list = { ...statecheckSetupFund.fundManageApiData };
    list.NewState = formik.values;
    dispatch(
      setStateChangeCheck({ ...statecheckSetupFund, fundManageApiData: list })
    );
  }, [formik.values]);
  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      setErrorMessage(
        "Please fill all the required field in fund profile and fund manager"
      );
    } else {
      setErrorMessage("");
    }
  }, [formik.errors]);

  return (
    <>
      {setupFundInfo.fundProfileDetails === undefined ? (
        addCancelSetup.add !== true ? (
          <Loader />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <div>
        <div className="center-card">
          <div className="col-12 pt-2 p-0">
            <div>
              <form>
                <div className="mt-2">
                  <Accordion className="p-0" activeIndex={0}>
                    <AccordionTab header="Fund Profile" tabIndex={0}>
                      <SetupFundProfile
                        pageName={pageName}
                        formik={formik}
                        getFormErrorMessage={getFormErrorMessage}
                        isFormFieldInvalid={isFormFieldInvalid}
                      />
                    </AccordionTab>

                    <AccordionTab header="Fund Manager" tabIndex={1}>
                      <SetupFundGPLLC
                        pageName={pageName}
                        formik={formik}
                        getFormErrorMessage={getFormErrorMessage}
                        isFormFieldInvalid={isFormFieldInvalid}
                      />
                    </AccordionTab>
                  </Accordion>

                  {pageName !== "Reports" ? (
                    <div className="flex flex-column flex-wrap sm:flex-none  col-12 align-items-center">
                      <div className="d-flex flex-column  ">
                        {" "}
                        <small className="p-error">{errorMessage}</small>
                      </div>
                      <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
                        <Button
                          className="btn-dialog "
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            goToTop();
                            formik.handleSubmit();
                          }}
                        >
                          <span className="ml-auto mr-auto ">Save</span>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
