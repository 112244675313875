import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SaveFinakK1GenerationService } from "../../../../../../services/taxmanagement/tax";
import error from "../../../../../../utils/error";
import {
  ISaveFinakK1GenerationState,
  ISaveFinakK1Generationparameters,
} from ".";

const initialState: ISaveFinakK1GenerationState = {
  success: false,
  message: "",
  loading: "loading",
  data1: undefined,
  data2: undefined,
};
export const SaveFinakK1GenerationThunk = createAsyncThunk(
  "TaxManagement/SaveFinakK1Generation",
  async (data: ISaveFinakK1Generationparameters) => {
    try {
      const response = await SaveFinakK1GenerationService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "TaxManagement/SaveFinakK1Generation",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(SaveFinakK1GenerationThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(SaveFinakK1GenerationThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data1 = action.payload
        ? action.payload._response.data.partnerDetails
        : [];
      state.data2 = action.payload
        ? action.payload._response.data.capitalAccCalList
        : [];
    });
    builder.addCase(SaveFinakK1GenerationThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
