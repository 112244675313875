import { useFormik } from "formik";
import JoditReact from "jodit-react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";

import "jodit/build/jodit.min.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../components/loader/Loader";
import {
  addThunkSection,
  deleteThunkSectionById,
  // addThunkSection,
  setSection,
} from "../../../../../store/features/investment/update/section/create/slice";
// import { getThunkSectionProductById } from "../../../../../store/features/investment/update/section/list/slice";
import { getThunkSectionProductById } from "../../../../../store/features/investment/update/section/list/slice";
import { setPageName } from "../../../../../store/features/investment/update/update-page/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../../utils/toast-message";

interface IProps {
  pageName: string;
  setPage?: any;
  setVisibleView: any;
  visibleView: any;
  setActiveSectionId?: any;
  activeSectionId?: any;
  activeIndex?: any;
  product?: any;
  setSect?: any;
  setActiveIndex?: any;
}

export const CreateSection = (props: IProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const sectionListState = useAppSelector((state) => state.listSection.data);
  const sectionState = useAppSelector((state) => state.addSection.data);
  const toast = useRef<Toast>(null);
  const stateInvestment = useAppSelector((state) => state.addInvestment);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const [valueData, setValueData] = useState<any>();
  const [index, setIndex] = useState<any>(props.activeIndex);

  // const [openDialog, setOpenDialog] = useState<boolean>(false);
  const pageData = useAppSelector((state) => state.updatePage);
  const updatePageName = useAppSelector((state) => state.updatePage.data);
  const navigate = useNavigate();

  const updatePage = (name: any, subName?: any) => {
    console.log("f", pageData);
    dispatch(
      setPageName({ ...updatePageName, pageName: name, subPageName: subName })
    );
    //  dispatch(setPageName(data));
  };
  const [config, setConfig] = useState<any>({
    fullsize: false,
    globalFullSize: false,
  });
  const formik = useFormik({
    initialValues: {
      sectionTitle: "",
      sectionContent: "",
    },
    validate: (data) => {
      //
      const errors: any = {};
      if (!data.sectionTitle) {
        errors.sectionTitle = "Section Title is required";
      }
      if (!data.sectionContent) {
        errors.sectionContent = "Section Content is required";
      }

      return errors;
    },
    onSubmit: (data) => {
      setConfig({
        fullsize: false,
        globalFullSize: false,
      });
      setLoading(true);
      // const formData = {
      //   id: 0,
      //   productId:
      //     stateInvestment.data === undefined
      //       ? 0
      //       : stateInvestment.data.productID,
      //   sectionTitle: formik.values.sectionTitle,
      //   sectionContent: valueData,
      //   createdBy: profileData?.userID,
      //   status: 0,
      // };
      console.log("form submit", sectionState);
      dispatch(addThunkSection(sectionState!))
        .then((response) => {
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (
            response.meta.requestStatus === "fulfilled" &&
            response.payload.data[0].id !== 0
          ) {
            successToastMessage(toast, "Section Created");
            updatePage("Content", "List");
          }
        })
        .catch((error: any) => {
          console.log("error", error);
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  console.log("list", sectionListState);
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  console.log("val change", index);

  const handlePreview = (id: any, i: any) => {
    console.log("val onPrev", index);

    setIndex(index);
    props.setActiveIndex(index);
    setConfig({
      fullsize: false,
      globalFullSize: false,
    });
    // props.setActiveIndex(props.activeIndex);
    props.setVisibleView(true);
    console.log("12--", props.activeIndex);
    if (sectionState?.id !== null && sectionState?.id === undefined) {
      // const sectionId = sectionState?.id;
      props.setActiveSectionId(id);
    }
    setLoading(true);

    const reqBody = {
      ProductId: props.product,
      SectionId: props.activeSectionId,
    };

    dispatch(getThunkSectionProductById(reqBody))
      .then((res: any) => {
        if (res.payload.data) {
          props.setSect((prevSections: any) => {
            const updatedSections = prevSections.map(
              (section: any, idx: number) => {
                if (idx === i) {
                  return {
                    ...section,
                    sectionContent: res.payload.data[0].sectionContent,
                  };
                }
                return section;
              }
            );
            return updatedSections;
          });
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // console.log(valueContent);
  const setOpenDialog = () => {
    dispatch(setPageName({ ...updatePageName, openDialog: false }));
  };
  const handleContinue = () => {
    console.log("useeffect 2", updatePageName.clickPageName);
    if (
      updatePageName.clickPageName === "SoftCommit" ||
      updatePageName.clickPageName === "PreFunded"
    ) {
      dispatch(
        setPageName({
          ...updatePageName,
          offering: updatePageName.clickPageName,
          pageName: "OfferingInfo",
          subPageName: "",
          openDialog: false,
        })
      );
      navigate("/ManageOffering");
    } else {
      dispatch(
        setPageName({
          ...updatePageName,
          pageName: updatePageName.clickPageName,
          subPageName: "",
          openDialog: false,
        })
      );
    }
  };

  const handleStateChange = () => {
    console.log("hj", sectionState);
    dispatch(
      setSection({
        ...sectionState,
        productId: stateInvestment.data?.productID,
        sectionTitle: formik.values.sectionTitle,
        // sectionContent: formik.values.sectionContent,
        sectionContent: valueData,
        createdBy: profileData?.userID,
      })
    );
    console.log("ff", sectionState);
  };
  console.log("s", sectionState);
  const deleteSection = async (id: any) => {
    await dispatch(deleteThunkSectionById(id));
    updatePage("Content", "List");
  };

  useEffect(() => {
    formik.setFieldValue("sectionTitle", sectionState?.sectionTitle);
    formik.setFieldValue("sectionContent", sectionState?.sectionContent);
    setValueData(sectionState?.sectionContent);
  }, [sectionState]);
  useEffect(() => {
    if (valueData !== undefined) {
      formik.setFieldValue("sectionContent", valueData);
    }
  }, [valueData]);
  useEffect(() => {
    if (pageData.data.subPageName !== "Create") {
      setConfig({
        fullsize: false,
        globalFullSize: false,
        placeholder: "",
      });
    }
  }, [pageData.data.subPageName]);
  useEffect(() => {
    setIndex(props.activeIndex);
  }, []);
  console.log("val", index);
  useEffect(() => {
    if (props.visibleView) {
      console.log("val 1", props.activeIndex, index);
      setIndex(index);
    } else {
      console.log("val 2", index);
      setIndex(index);
    }
  }, [props.visibleView]);

  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <Dialog
        visible={updatePageName.openDialog}
        className="w-100 md:w-4"
        onHide={() => setOpenDialog()}
        headerClassName="no-header"
        contentClassName="top-lr-content"
      >
        <p className="pageHeader text-center mb-5 pt-5">
          If you change page now, you will lose all of your saved changes. Would
          you like to proceed?
        </p>

        <div className="flex flex-row flex-wrap sm:flex-none  col-12">
          <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleContinue();
              }}
              className="btn-nav"
            >
              <span className="ml-auto mr-auto"> Continue</span>
            </Button>
          </div>
          <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setOpenDialog();
              }}
              className="btn-navActive "
            >
              <span className="ml-auto mr-auto"> Take me Back</span>
            </Button>
          </div>
        </div>
      </Dialog>
      <form>
        <div className="mt-4">
          {pageData.data.subPageName === "Create" ? (
            <label className="inputLabel inputLabelColor ml-2">
              New Section:
            </label>
          ) : (
            <label className="inputLabel inputLabelColor ml-2">
              New Section:
            </label>
          )}

          <div
            className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
            style={{ height: "auto" }}
          >
            <div className="formgrid grid px-2 ml-3">
              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-8">
                <label className="inputLabel">Section Title</label>
                <InputText
                  name="sectionTitle"
                  value={
                    formik.values.sectionTitle === undefined
                      ? ""
                      : formik.values.sectionTitle
                  }
                  onChange={(e: any) =>
                    formik.setFieldValue("sectionTitle", e.target.value)
                  }
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("sectionTitle"),
                    "card-inputBox": true,
                  })}
                  placeholder="Enter Section Title"
                />
                {getFormErrorMessage("sectionTitle")}
              </div>
            </div>

            <div className="flex flex-row flex-wrap sm:flex-none  col-12  ml-3 ">
              <div
                className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-12"
                style={{
                  position: "relative",
                }}
              >
                <label className="inputLabel">Section Content</label>
                <div className="richTextEditior">
                  <JoditReact
                    onChange={(content: any) => {
                      setValueData(content);
                    }}
                    value={
                      formik.values.sectionContent === undefined
                        ? ""
                        : formik.values.sectionContent
                    }
                    // style={{ height: "100vh" }}
                    config={config}
                  />
                </div>
                {getFormErrorMessage("sectionContent")}
              </div>
            </div>
            <div className="xl:flex flex-wrap sm:flex-none  col-12 pl-0 ml-3 mt-3">
              <div className="flex flex-row flex-wrap sm:flex-none col-9">
                <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                  <label className="inputLabel">&nbsp;</label>

                  <Button
                    className="btn-dialog"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("bdjfn");
                      setConfig({
                        fullsize: false,
                        globalFullSize: false,
                      });
                      handleStateChange();
                      formik.handleSubmit();
                    }}
                  >
                    <span className="ml-auto mr-auto ">Submit Section </span>
                  </Button>
                </div>
                <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                  <label className="inputLabel">&nbsp;</label>

                  <Button
                    className="btn-dialog"
                    onClick={(e) => {
                      e.preventDefault();
                      setConfig({
                        fullsize: false,
                        globalFullSize: false,
                      });
                      handlePreview(props.activeSectionId, index);
                    }}
                    disabled={sectionListState.length <= 0}
                  >
                    <span className="ml-auto mr-auto ">Preview Section </span>
                  </Button>
                </div>
                {pageData.data.subPageName === "Create" ? (
                  <></>
                ) : (
                  <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                    <label className="inputLabel">&nbsp;</label>

                    <Button
                      className="btn-dialog"
                      onClick={(e) => {
                        e.preventDefault();
                        setConfig({
                          fullsize: false,
                          globalFullSize: false,
                        });
                        deleteSection(sectionState?.id);
                      }}
                    >
                      <span className="ml-auto mr-auto ">Delete Section </span>
                    </Button>
                  </div>
                )}
              </div>
              <div className="flex flex-row flex-wrap sm:flex-none col-3 sm:col-9 xl:justify-content-end">
                <div className="d-flex flex-column p-2 xl:lg-8 sm:col-10">
                  <label className="inputLabel">&nbsp;</label>
                  <Button
                    className="btn-dialog"
                    onClick={(e) => {
                      e.preventDefault();
                      updatePage("Content", "List");
                    }}
                  >
                    <span className="ml-auto mr-auto ">Cancel</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
