import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AddUploadK1InvestorDocumentService,
  DeleteUploadK1InvestorDocumentService,
  GetUploadInvestorK1ListService,
} from "../../../../../services/taxmanagement/tax";
import error from "../../../../../utils/error";
import { IUploadInvestorK1ListState } from ".";

const initialState: IUploadInvestorK1ListState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkUploadInvestorK1List = createAsyncThunk(
  "UploadInvestorK1/getList",
  async (data: any) => {
    try {
      const response = await GetUploadInvestorK1ListService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const addThunkInvestorK1Document = createAsyncThunk(
  "UploadInvestorK1/add",
  async (data: any) => {
    try {
      const response = await AddUploadK1InvestorDocumentService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const deleteThunkInvestorK1Document = createAsyncThunk(
  "UploadInvestorK1/delete",
  async (data: any) => {
    try {
      const response = await DeleteUploadK1InvestorDocumentService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "UploadInvestorK1",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkUploadInvestorK1List.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkUploadInvestorK1List.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      state.data =
        action.payload._response.data !== null
          ? action.payload._response.data
          : [];
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(getThunkUploadInvestorK1List.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });

    builder.addCase(addThunkInvestorK1Document.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(addThunkInvestorK1Document.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      // if (action.payload._response.data !== null) {
      //   state.data = action.payload._response.data;
      // }
      state.data =
        action.payload._response.data !== null
          ? action.payload._response.data
          : [];
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(addThunkInvestorK1Document.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
    builder.addCase(deleteThunkInvestorK1Document.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      deleteThunkInvestorK1Document.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(action.payload);
        state.data =
          action.payload._response.data !== null
            ? action.payload._response.data
            : [];
        state.success = action.payload._response.success;
        state.message = action.payload._response.message;
      }
    );
    builder.addCase(deleteThunkInvestorK1Document.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
