import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
// import { InputText } from "primereact/inputtext";
// import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";

import { Skeleton } from "primereact/skeleton";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { useCallback, useEffect, useRef, useState } from "react";
import * as React from "react";
import "./setupfundentity.scss";
import "./dwolla.css";
import {
  RiAddFill,
  // RiHomeOfficeFill,
  // RiMailForbidLine,
  // RiProfileLine,
} from "react-icons/ri";
import {
  PlaidLinkOnSuccess,
  // PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from "react-plaid-link";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { GetFundsBankListThunk } from "../../../../store/dwolla/bank/slice";
import {
  GetDwollaCustomerInfoThunk,
  SaveDwollaCustomerInfoThunk,
} from "../../../../store/dwolla/customer/slice";
import {
  DeleteOwnersIdThunk,
  GetOwnersListThunk,
  SaveDwollaOwnerInfoThunk,
  UpdateCertiOwnersIdThunk,
} from "../../../../store/dwolla/owneraccount/slice";

import { GetDwollaTokenThunk } from "../../../../store/dwolla/token/slice";
import { deleteBankAccountThunk } from "../../../../store/finance/deleteBankAccount/slice";
import { checkOTPACHThunk } from "../../../../store/finance/otp/checkOTPACH/slice";
import { reSendACHOTPThunk } from "../../../../store/finance/otp/reSendACHOTP/slice";
import { sendACHOTPThunk } from "../../../../store/finance/otp/sendACHOTP/slice";
import { addBankWithPlaidThunk } from "../../../../store/finance/plaid/addBankWithPlaid/slice";
import { getPlaidLinkTokenThunk } from "../../../../store/finance/plaid/getPlaidLinkToken/slice";
import { setFundPrimaryAccountThunk } from "../../../../store/finance/setFundPrimaryAccount/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { envVariable } from "../../../../utils/sponsor/helper";

const dwolla = window.dwolla;
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "dwolla-customer-create": any;
      "dwolla-business-vcr": any;
      "dwolla-personal-vcr": any;
      "window.dwolla": any;
      "dwolla-beneficial-owners": any;
      "dwolla-document-upload": any;
      "dwolla-customer-update": any;
      "dwolla-funding-source-create": any;
      "dwolla-micro-deposits-verify": any;
      "dwolla-balance-display": any;
      "dwolla-payin": any;
      "dwolla-business-vcr-update": any;
    }
  }
}
declare global {
  interface Window {
    dwolla: any;
  }
}

const SetupFundEntity = () => {
  const toast = useRef<Toast>(null);
  const firstUpdate = useRef(true);
  const dispatch = useAppDispatch();
  const navigage = useNavigate();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [addBankClicked, setAddBankClicked] = useState(false);
  const [fundBeneficialOwners, setFundBeneficialOwners] = useState(false);

  const [benificialCertifiedchecked, setBenificialCertifiedchecked] =
    useState(true);
  const [showfundBeneficialOwners, setshowFundBeneficialOwners] =
    useState<boolean>(false);
  const [beneficialOwnersId, setBeneficialOwnersId] = useState("");
  const [showloader, setshowloader] = useState(false);
  const [checked, setChecked] = useState(false);
  const [barpoints, setbarpoints] = useState(33);
  // const [customerid, setCustomerid] = useState();
  console.log(beneficialOwnersId);
  const customerinfo = useAppSelector((state) => state.dwollaCustomerInfo.data);
  const customerinfoStatus = useAppSelector(
    (state) => state.dwollaCustomerInfo.loading
  );
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const ownerslist = useAppSelector(
    (state) => state.setupFundentityOwnersReducer.data
  );
  const apiloader = useAppSelector(
    (state) => state.setupFundentityOwnersReducer.loading
  );
  const bankList = useAppSelector((state) => state.fundBank.data);
  const [visible, setVisible] = useState(false);
  const tokenmodel = useAppSelector((state) => state.dwollaToken.data);
  const loading = useAppSelector((state) => state.dwollaToken.loading);
  const plaidToken = useAppSelector((state) => state.plaidToken.data);
  const [certifiedconfirmDialog, setcertifiedconfirmDialog] = useState(false);
  const [certifiedId, setcertifiedId] = useState("");

  const [certifiedDeleteconfirmDialog, setcertifiedDeleteconfirmDialog] =
    useState(false);
  const [deletecertifiedId, setdeletecertifiedId] = useState("");

  const [bankDeleteconfirmDialog, setbankDeleteconfirmDialog] = useState(false);
  const [deletebankId, setdeletebankIdId] = useState<any>();

  const { id } = useParams();
  function dwollaAPIToken(req: any, additional: any) {
    return { token: tokenmodel };
    // return { token: "lfHs9mFOIWfKAhFE3oyi19fMkOqykO91gTPclajrEjTeLnHY5w" };
  }
  // const dropdown = useAppSelector((state) => state.navbardropdown.data);
  // useEffect(() => {
  //   if (dropdown.fundid !== undefined && dropdown.fundid > 0) {
  //     dispatch(GetDwollaTokenThunk());
  //   }
  // }, [dropdown.fundid]);

  React.useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    navigage("/SetupFundEntityList");
  }, [fundDropdown.fundid]);

  const onCancel = () => {
    setcertifiedconfirmDialog(false);
    setcertifiedDeleteconfirmDialog(false);
    setbankDeleteconfirmDialog(false);
  };

  function SaveCustomerInfo(location: any) {
    console.log(location);
    if (location !== undefined) {
      setshowloader(true);
      const parameters = {
        invOREntityID: +id!,
        dwollaOwnerId: location.toString(),
        dwollaFundId: "",
        type: "Fund",
        createdBy: profileData?.userID,
      };
      if (location.includes("beneficial-owners")) {
        dispatch(SaveDwollaOwnerInfoThunk(parameters)).then((res) => {
          setshowloader(false);
        });
      } else {
        dispatch(SaveDwollaCustomerInfoThunk(parameters)).then((res) => {
          toast.current?.show({
            severity: "success",
            // summary: "Success",
            life: 5000,
            detail: "Fund Added successfully",
            // life: 3000,
          });
          setshowloader(false);
          const custparameters = {
            invOREntityID: +id!,
            dwollaOwnerId: "",
            dwollaFundId: "",
            type: "Fund",
            createdBy: profileData?.userID,
          };
          dispatch(GetDwollaCustomerInfoThunk(custparameters));
        });
      }
    }
  }

  useEffect(() => {
    if (ownerslist !== undefined && ownerslist?.length > 0) {
      const result = ownerslist.find(function (e: any) {
        return e.statusCertified === "certified";
      });
      if (result !== undefined) {
        setshowFundBeneficialOwners(true);
        setBenificialCertifiedchecked(false);
      } else {
        setshowFundBeneficialOwners(false);
        setBenificialCertifiedchecked(true);
      }
    } else {
      if (customerinfo.isCertified === "certified") {
        setshowFundBeneficialOwners(true);
        setBenificialCertifiedchecked(false);
      } else {
        setshowFundBeneficialOwners(false);
      }
    }
  }, [ownerslist, customerinfo]);

  useEffect(() => {
    if (tokenmodel !== undefined && tokenmodel !== "") {
      dwolla.configure({
        environment: envVariable!.REACT_APP_DWOLLA_CONFIGURE,
        styles: "/global/css/index.css",
        token: (req: any) =>
          Promise.resolve(dwollaAPIToken(req, { blah: "abcd" })),
        success: (res: any) => {
          console.log("res", res);
          const promise1 = Promise.resolve(res);
          promise1.then((value) => {
            console.log(value);
            const customers = "customers";
            if (res.resource.includes(customers)) {
              console.log(res.response.location);
              SaveCustomerInfo(res.response.location);
            }
          });
        },
        error: (err: any) => Promise.resolve(err),
      });
    } else {
      dispatch(GetDwollaTokenThunk());
    }
  }, [tokenmodel]);

  // useEffect(() => {
  //   if (
  //     tokenmodel !== undefined &&
  //     tokenmodel !== "" &&
  //     customerinfo !== undefined
  //   ) {
  //     console.log("Token Method Called");
  //     dwolla.configure({
  //       environment: "sandbox",
  //       styles: "/global/css/index.css",
  //       token: (req: any) =>
  //         Promise.resolve(dwollaAPIToken(req, { blah: "abcd" })),
  //       success: (res: any) => {
  //         console.log("res", res);
  //         const promise1 = Promise.resolve(res);
  //         promise1.then((value) => {
  //           console.log(value);
  //           const customers = "customers";
  //           if (res.resource.includes(customers)) {
  //             console.log(res.response.location);
  //             SaveCustomerInfo(res.response.location);
  //           }
  //         });
  //       },
  //       error: (err: any) => Promise.resolve(err),
  //     });
  //   } else {
  //     dispatch(GetDwollaTokenThunk());
  //   }
  // }, [customerinfo, tokenmodel]);

  useEffect(() => {
    if (apiloader === "loading") {
      setshowloader(true);
    } else {
      setshowloader(false);
    }
  }, [apiloader]);

  useEffect(() => {
    const parameters = {
      invOREntityID: +id!,
      dwollaOwnerId: "",
      dwollaFundId: "",
      type: "Fund",
      createdBy: profileData?.userID,
    };
    const bankparameters = {
      InvOrEntityID: +id!,
      AvCustomerType: "Fund",
    };
    dispatch(GetDwollaCustomerInfoThunk(parameters));
    dispatch(GetFundsBankListThunk(bankparameters));
    setButtonClicked(true);
    dispatch(getPlaidLinkTokenThunk());
  }, [id]);

  function handleBankClick() {
    setAddBankClicked(true);
    setButtonClicked(false);
    setFundBeneficialOwners(false);
  }
  const confirmDelete = () => {
    setcertifiedDeleteconfirmDialog(false);
    const parameters = {
      id: deletecertifiedId,
    };
    dispatch(DeleteOwnersIdThunk(parameters));
    setshowFundBeneficialOwners(false);
  };

  const DeleteOwnerCertifedfund = (data: any) => {
    // confirmDialog({
    //   message: "Are you sure you want to delete?",
    //   header: "Confirmation",
    //   icon: "pi pi-exclamation-triangle",
    //   accept: () => confirmDelete(data.dwollaOwnerId),
    // });
    setdeletecertifiedId(data.dwollaOwnerId);
    setcertifiedDeleteconfirmDialog(true);
    setBeneficialOwnersId(data.dwollaOwnerId);
  };
  const actionBodyDeleteTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className=" p-button-rounded p-button-text iconButton"
          onClick={() => DeleteOwnerCertifedfund(rowData)}
          type="button"
        />
      </React.Fragment>
    );
  };

  function ConfirmdeleteAccount() {
    setbankDeleteconfirmDialog(false);
    setshowloader(true);

    dispatch(
      deleteBankAccountThunk({
        Id: deletebankId!.id,
        DwollaAccountID: deletebankId!.dwollaAccountID,
        CustomerId: deletebankId!.customerId,
      })
    )
      .then((res) => {
        if (res.payload._response.success) {
          toast.current?.show({
            severity: "success",
            // summary: "Success",
            // life: 5000,
            detail: "Bank Account deleted successfully",
            // life: 3000,
          });
          const bankparameters = {
            InvOrEntityID: +id!,
            AvCustomerType: "Fund",
          };
          dispatch(GetFundsBankListThunk(bankparameters));
          setshowloader(false);
          setshowloader(false);
        } else {
          setshowloader(false);

          toast.current?.show({
            severity: "error",
            // summary: "Success",
            // life: 5000,
            detail: "Something went wrong",
            // life: 3000,
          });
        }
      })
      .catch((error) => {
        console.log("Opps something went wrong", error);
        setshowloader(false);

        toast.current?.show({
          severity: "error",
          // summary: "Success",
          // life: 5000,
          detail: "Something went wrong",
          // life: 3000,
        });
      });
  }

  const DeleteBankAccoundfund = (data: any) => {
    // confirmDialog({
    //   message: "Are you sure you want to delete?",
    //   header: "Confirmation",
    //   icon: "pi pi-exclamation-triangle",
    //   accept: () => ConfirmdeleteAccount(data),
    // });
    setbankDeleteconfirmDialog(true);
    setdeletebankIdId(data);
  };

  const actionBodyBankDeleteTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className=" p-button-rounded p-button-text iconButton"
          onClick={() => DeleteBankAccoundfund(rowData)}
          type="button"
        />
      </React.Fragment>
    );
  };

  function renderButtonSwitch(param: any) {
    switch (param) {
      case "verified":
        return (
          <React.Fragment>
            <span className="p-tag p-component p-tag-success StatusSuccssCls">
              <span className="p-tag-value"> Status : Completed</span>
              <span></span>
            </span>
          </React.Fragment>
        );
      case "":
        return (
          <React.Fragment>
            <span className="p-tag p-component p-tag-Warning StatuserrorCls">
              <span className="p-tag-value"> Status : Not started </span>
              <span></span>
            </span>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <span className="p-tag p-component p-tag-success StatuswaringCls ">
              <span className="p-tag-value"> Status : In Progress</span>
              <span></span>
            </span>
          </React.Fragment>
        );
    }
  }
  useEffect(() => {
    if (customerinfo.status !== "verified") {
      setbarpoints(33);
    } else if (
      customerinfo.status === "verified" &&
      customerinfo.isCertified !== "certified"
    ) {
      setbarpoints(66);
    } else {
      setbarpoints(100);
    }
  }, [customerinfo]);

  const getSeverity = (value: any) => {
    switch (value) {
      case "certified":
        return "success";

      case "LOWSTOCK":
        return "warning";

      case "Not certified":
        return "danger";

      default:
        return "warning";
    }
  };

  const actionSetbenificialBodyStatus = (rowData: any) => {
    if (rowData?.statusCertified !== null) {
      return (
        <React.Fragment>
          <div className="card flex flex-wrap gap-2">
            {/* <Chip label={rowData.dwollaStatus} icon="pi pi-check-circle" /> */}
            <Tag
              value={rowData.statusCertified}
              severity={getSeverity(rowData.statusCertified)}
            ></Tag>
          </div>
        </React.Fragment>
      );
    }
  };

  const getBankStatusDisabledStatus = () => {
    if (customerinfo.status !== "verified") {
      return true;
    } else if (ownerslist?.length === 0) {
      return customerinfo.isCertified !== "certified";
    } else if (ownerslist !== undefined && ownerslist?.length > 0) {
      const result = ownerslist.find(function (e: any) {
        return e.statusCertified === "certified";
      });
      return result === undefined;
    } else {
      return true;
    }
  };

  const getbenificalButtonStatus = () => {
    if (customerinfo.status !== "verified") {
      return (
        <React.Fragment>
          <span className="p-tag p-component StatuserrorCls">
            <span className="p-tag-value">
              {" "}
              Status : Waiting for previous step
            </span>
            <span></span>
          </span>
        </React.Fragment>
      );
    } else if (ownerslist?.length === 0) {
      return (
        <React.Fragment>
          {customerinfo.isCertified !== "certified" ? (
            <span className="p-tag p-component StatuserrorCls">
              <span className="p-tag-value">Status : Not started</span>
              <span></span>
            </span>
          ) : (
            <span className="p-tag p-component StatusSuccssCls">
              <span className="p-tag-value">Status : Completed </span>
              <span></span>
            </span>
          )}
        </React.Fragment>
      );
    } else if (benificialCertifiedchecked === true) {
      return (
        <React.Fragment>
          <span className="p-tag p-component StatuswaringCls">
            <span className="p-tag-value"> Status : In Progress</span>
            <span></span>
          </span>
        </React.Fragment>
      );
    } else if (benificialCertifiedchecked === false) {
      return (
        <React.Fragment>
          <span className="p-tag p-component StatusSuccssCls">
            <span className="p-tag-value"> Status : Completed</span>
            <span></span>
          </span>
        </React.Fragment>
      );
    }
  };

  const getbankButtonSubStatus = () => {
    if (bankList === undefined || bankList?.length === 0) {
      return (
        <React.Fragment>
          <span className="p-tag p-component StatuserrorCls">
            <span className="p-tag-value"> Status : Not started</span>
            <span></span>
          </span>
        </React.Fragment>
      );
    }
    if (bankList !== undefined && bankList?.length > 0) {
      const result = bankList.find(function (e: any) {
        return e.verified === "Verified";
      });
      return result !== undefined ? (
        <React.Fragment>
          <span className="p-tag p-component StatusSuccssCls">
            <span className="p-tag-value"> Status : Completed</span>
            <span></span>
          </span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span className="p-tag p-component StatuswaringCls">
            <span className="p-tag-value"> Status : In Progress</span>
            <span></span>
          </span>
        </React.Fragment>
      );
    }
  };

  const getBankButtonStatus = () => {
    if (customerinfo.status !== "verified") {
      return (
        <React.Fragment>
          <span className="p-tag p-component StatuserrorCls">
            <span className="p-tag-value">
              {" "}
              Status : Waiting for previous step
            </span>
            <span></span>
          </span>
        </React.Fragment>
      );
    } else if (ownerslist?.length === 0) {
      if (bankList !== undefined && bankList?.length > 0) {
        return getbankButtonSubStatus();
      }
      return customerinfo.isCertified === "certified" ? (
        <React.Fragment>
          <span className="p-tag p-component StatuserrorCls">
            <span className="p-tag-value"> Status : Not started</span>
            <span></span>
          </span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span className="p-tag p-component StatuserrorCls">
            <span className="p-tag-value">
              {" "}
              Status : Waiting for previous step
            </span>
            <span></span>
          </span>
        </React.Fragment>
      );
    } else if (ownerslist !== undefined && ownerslist?.length > 0) {
      const result = ownerslist.find(function (e: any) {
        return e.statusCertified === "certified";
      });
      return result !== undefined ? (
        getbankButtonSubStatus()
      ) : (
        <React.Fragment>
          <span className="p-tag p-component StatuserrorCls">
            <span className="p-tag-value">
              {" "}
              Status : Waiting for previous step
            </span>
            <span></span>
          </span>
        </React.Fragment>
      );
    }
  };

  const ownersdataTableElements = [
    {
      field: "firstName",
      header: "First Name",
    },
    {
      field: "lastName",
      header: "Last Name",
    },
    {
      field: "statusVerified",
      header: "Status Verified",
    },
    {
      field: "lastAction",
      header: "Last Action",
    },
    {
      field: "statusCertified",
      header: "Status Certified",
      body: actionSetbenificialBodyStatus,
    },
    {
      header: "Action",
      body: actionBodyDeleteTemplate,
    },
  ];
  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken: string, metadata: any) => {
      // const data = prepareData(metadata, getCustomerType);

      const data = {
        invOREntityID: id!.toString(),
        processorToken: "",
        accountName: metadata.account.name,
        account_id: metadata.account_id,
        avCustomerType: "Fund",

        metaData: {
          status: metadata.status,
          link_session_id: metadata.link_session_id,
          institution: metadata.institution,
          accounts: metadata.accounts,
          account: metadata.account,
          account_id: metadata.account_id,
          transfer_status: metadata.transfer_status,
          public_token: metadata.public_token,
        },
      };

      setshowloader(true);

      dispatch(addBankWithPlaidThunk(data))
        .then((res) => {
          setshowloader(false);
          setChecked(false);
          console.log("Bank Info", res);
          toast.current?.show({
            severity: "success",
            // summary: "Success",
            life: 5000,
            detail: res.payload._response.message,
            // life: 3000,
          });
          const bankparameters = {
            InvOrEntityID: +id!,
            AvCustomerType: "Fund",
          };
          dispatch(getPlaidLinkTokenThunk());
          dispatch(GetFundsBankListThunk(bankparameters));
        })
        .catch(() => {
          dispatch(getPlaidLinkTokenThunk());
        });
    },
    []
  );
  const config = {
    token: plaidToken.link_token,
    onSuccess,
  };
  const { open, ready } = usePlaidLink(
    config
    // onEvent
    // onExit
  );

  // formik
  const formik = useFormik({
    initialValues: {
      otp: undefined,
    },
    validate: (data: any) => {
      const errors: { otp?: string } = {};
      if (!data.otp) {
        errors.otp = "OTP is required";
      }
      if (data.otp && data.otp.toString().length < 6) {
        errors.otp = "Number Should be 6 digit";
      }

      return errors;
    },
    onSubmit: (data: any) => {
      formik.resetForm();
      dispatch(
        checkOTPACHThunk({ OTP: data.otp, UserId: profileData.userID })
      ).then((response) => {
        if (response.payload._response.success) {
          // open();
          toast.current?.show({
            severity: "success",
            // summary: "Success",
            life: 5000,
            detail: "OTP Verified successfully",
            // life: 3000,
          });

          setVisible(false);
          open();
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: "OTP EXPIRED. Please Try again",
            life: 3000,
          });
        }
      });
    },
  });
  const checkAccountType = () => {
    dispatch(
      sendACHOTPThunk({
        email: profileData.emailID,
        legalEntityID: fundDropdown.fundid,
        userID: profileData.userID,
      })
    )
      .then(() => {
        toast.current?.show({
          severity: "success",
          life: 5000,
          detail: "OTP has been Send to your registered Email ID",
        });
      })
      .catch(() => {
        toast.current?.show({
          severity: "error",
          // summary: "Success",
          life: 5000,
          detail: "Opps somwthing went wrong",
          // life: 3000,
        });
      });

    // setDwollaVisible(true);
    setVisible(true);
  };

  const doucmentCompleteNameTemplate = (item: any) => {
    return (
      <>
        {" "}
        {item.default === "No" && (
          <Button
            className="btn-dialog w-12rem"
            label="Switch to primary"
            onClick={(e) => {
              e.preventDefault();
              setshowloader(true);

              dispatch(
                setFundPrimaryAccountThunk({
                  fundId: item.id,
                  customerId: item.customerId,
                })
              ).then((res) => {
                setshowloader(false);
                if (res.payload._response.success) {
                  toast.current?.show({
                    severity: "success",
                    // summary: "Success",
                    life: 5000,
                    detail: res.payload._response.message,
                    // life: 3000,
                  });
                  const bankparameters = {
                    InvOrEntityID: +id!,
                    AvCustomerType: "Fund",
                  };
                  dispatch(GetFundsBankListThunk(bankparameters));
                } else {
                  toast.current?.show({
                    severity: "error",
                    // summary: "Success",
                    life: 5000,
                    detail: "res.payload._response.message",
                    // life: 3000,
                  });
                }
              });
            }}
          />
        )}
      </>
    );
  };

  const bankListTableElements = [
    {
      field: "accountHolderName",
      header: "Account Name",
    },
    {
      field: "bankName",
      header: "Bank Name",
    },
    {
      field: "accountNo",
      header: "Account No",
    },
    {
      field: "accountType",
      header: "Account Type",
    },
    {
      field: "verified",
      header: "Status",
    },
    // {
    //   field: "default",
    //   header: "Primary Account",
    // },
    {
      field: "Actions",
      header: "Make Primary",
      body: doucmentCompleteNameTemplate,
    },
    {
      header: "Action",
      body: actionBodyBankDeleteTemplate,
    },
  ];
  useEffect(() => {
    const parameters = {
      LegalEntityId: id,
      Input: "Fund",
    };
    dispatch(GetOwnersListThunk(parameters));
  }, [id]);

  function handleClick() {
    setButtonClicked(true);
    setAddBankClicked(false);
    setFundBeneficialOwners(false);
    // eslint-disable-next-line unicorn/prefer-query-selector
    const name = document.getElementById("dwolla-document-submit");
    if (name !== null) {
      name!.removeAttribute("disabled");
    }
  }
  function handleBeneficialOwnersClick() {
    setButtonClicked(false);
    setAddBankClicked(false);
    setFundBeneficialOwners(true);
  }
  const certifiedownerid = () => {
    setcertifiedconfirmDialog(false);
    const parameters = {
      id: certifiedId,
    };
    dispatch(UpdateCertiOwnersIdThunk(parameters)).then((res) => {
      const custparameters = {
        invOREntityID: +id!,
        dwollaOwnerId: "",
        dwollaFundId: "",
        type: "Fund",
        createdBy: profileData?.userID,
      };
      dispatch(GetDwollaCustomerInfoThunk(custparameters));
    });
  };

  function showCertifuedBeneficialOwnersClick() {
    // confirmDialog({
    //   message: "Are you sure you want to certify?",
    //   header: "Confirmation",
    //   icon: "pi pi-exclamation-triangle",
    //   accept: () => certifiedownerid(customerinfo.dwollaId),
    // });
    setcertifiedconfirmDialog(true);
    setcertifiedId(customerinfo.dwollaId);
  }

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <>
      <Button
        className="BtnBackpg"
        onClick={(e) => {
          e.preventDefault();
          navigage(-1); // Go back one page

          // navigage(-1);
        }}
      >
        <span className="bi bi-chevron-compact-left"></span> Back to List
      </Button>
      {loading === "loading" || showloader === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      {/* <ConfirmDialog /> */}
      <ConfirmDailogbox
        openDialog={certifiedconfirmDialog}
        setOpenDialog={setcertifiedconfirmDialog}
        onConfirm={certifiedownerid}
        onCancel={onCancel}
        textContent={
          showfundBeneficialOwners === false
            ? "Are you sure you want to certify beneficial owners?"
            : "Are you sure you want to undo certification? You will need to certify beneficial owners again before you can initiate bank transactions."
        }
      />
      <ConfirmDailogbox
        openDialog={certifiedDeleteconfirmDialog}
        setOpenDialog={setcertifiedDeleteconfirmDialog}
        onConfirm={confirmDelete}
        onCancel={onCancel}
        textContent="Are you sure you want to delete?"
      />
      <ConfirmDailogbox
        openDialog={bankDeleteconfirmDialog}
        setOpenDialog={setbankDeleteconfirmDialog}
        onConfirm={ConfirmdeleteAccount}
        onCancel={onCancel}
        textContent="Are you sure you want to delete?"
      />
      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        className="w-100 md:w-3 "
      >
        {/* <h2 className="h2-dialog">Enter One Time Pascode</h2> */}
        <div className="card flex justify-content-center">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-column gap-2 softComAmt"
          >
            <label
              htmlFor="username "
              className="label-pascode pt-3 text-center"
            >
              Enter the passcode sent to your registered email
            </label>
            <div className="p-inputgroup flex-1">
              <InputText
                id="value"
                name="otp"
                // useGrouping={false}
                value={formik.values.otp}
                maxLength={6}
                // required
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  formik.setFieldValue("otp", value);
                }}
                // className="card-inputBox"
                className={classNames({
                  "p-invalid": isFormFieldInvalid("value"),
                  "card-inputBox": true,
                })}
                // onChange={formik.handleChange}
              />
            </div>
            {getFormErrorMessage("otp")}
            {/* <Button type="submit" label="Submit" /> */}
            <div className="mt-2 d-flex">
              <Button
                label="SUBMIT PASSCODE"
                type="submit"
                // onClick={props.onModalClose}

                // className=" btn-dialog w47 mx-1"
                className=" btn-dialog w-full mx-1"
                disabled={formik.isSubmitting}
              />
            </div>
            {/* <div className="mt-2 d-flex">
              <Button
                label="SUBMIT PASSCODE"
                type="submit"
                // onClick={props.onModalClose}
                className=" btn-dialog w47 mx-1"
              />
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(
                    reSendACHOTPThunk({
                      email: profileData.emailID,
                      legalEntityID: fundDropdown.fundid,
                      userID: profileData.userID,
                    })
                  );

                  toast.current?.show({
                    severity: "success",
                    // summary: "Success",
                    life: 5000,
                    detail: "OTP has been Send to your registered Email ID",
                    // life: 3000,
                  });
                }}
                label="RESEND PASSCODE"
                className="  btn-cancel w47 mx-1 "
                // onClick={onSubmit}
                autoFocus
              />
            </div> */}
            <div className="flex flex-row-reverse flex-wrap ">
              <div className="flex align-items-center justify-content-center  m-2">
                <a
                  className="resend-passcode"
                  onClick={(e) => {
                    setshowloader(true);
                    // alert("tesing");
                    e.preventDefault();
                    dispatch(
                      reSendACHOTPThunk({
                        email: profileData.emailID,
                        legalEntityID: profileData.legalEntityID,
                        userID: profileData.userID,
                      })
                    )
                      .then(() => {
                        toast.current?.show({
                          severity: "success",
                          // summary: "Success",
                          life: 5000,
                          detail:
                            "OTP has been sent to your registered Email ID",
                          // life: 3000,
                        });
                      })
                      .catch(() => {
                        toast.current?.show({
                          severity: "error",
                          summary: "Error",
                          detail:
                            "Opps something went wrong.Please try again later",
                          life: 3000,
                        });
                      })
                      .finally(() => {
                        formik.resetForm();
                        setshowloader(false);
                      });
                  }}
                >
                  Resend Passcode
                </a>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      <div>
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader"></h1>
          </div>
        </div>
        <div className=" h-100 center-card">
          <div className="col-12 " style={{ width: "100%" }}>
            <div className="card my-3">
              <Card>
                <div className="headercard d-flex grid">
                  <div className="col-12 md:col-6">
                    <h2 style={{ fontWeight: "bold" }}>
                      Setup Fund Entity Information
                    </h2>
                  </div>
                  <div className="col-12 md:col-6 end-div">
                    <h6>Fund Setup - Create Fund Entity </h6>
                  </div>
                </div>
                <div>
                  <ProgressBar
                    value={barpoints}
                    showValue={false}
                    className="m-2"
                  ></ProgressBar>
                </div>
                {/* <div className="card my-3 ">
                  <Card>
                    <h4 className="cardHeader">Overview</h4>
                    <div className="flex flex-column md:flex-row">
                      <div className="acc-value-details w:100 md:w-4">
                        <div className="acc-value-details-icon">
                          <RiHomeOfficeFill className="icons-wrap" />
                        </div>
                        <div className="acc-value-details-text">
                          <h5
                            className="acc-value-details-textHeader"
                            style={{
                              textTransform: "uppercase",
                              lineHeight: "3",
                              fontSize: "14px",
                            }}
                          >
                            {" "}
                            {customerinfo?.firstName} {customerinfo?.lastName}{" "}
                          </h5>
                        </div>
                      </div>
                      <div className="acc-value-details w:100 md:w-4">
                        <div className="acc-value-details-icon">
                          <RiMailForbidLine className="icons-wrap" />
                        </div>
                        <div className="acc-value-details-text">
                          <h5
                            className="acc-value-details-textHeader"
                            style={{
                              lineHeight: "3",
                              fontSize: "14px",
                            }}
                          >
                            {customerinfo?.email}
                          </h5>
                        </div>
                      </div>
                      <div className="acc-value-details w:100 md:w-4">
                        <div className="acc-value-details-icon">
                          <RiProfileLine className="icons-wrap" />
                        </div>
                        <div className="acc-value-details-text">
                          <h5
                            className="acc-value-details-textHeader"
                            style={{
                              lineHeight: "3",
                              fontSize: "14px",
                            }}
                          >
                            {customerinfo?.status}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div> */}

                {customerinfoStatus === "succeeded" ? (
                  <div>
                    <div className="formgrid grid px-2">
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6"></div>
                      <div className="d-flex flex-column p-2 col-12"></div>
                      <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
                        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                          <label className="inputLabel">&nbsp;</label>

                          <Button
                            className="btn-dialog "
                            type="button"
                            onClick={handleClick}
                          >
                            <span className="ml-auto mr-auto mb-2">
                              Add Fund Entity
                            </span>

                            {renderButtonSwitch(customerinfo.status)}
                          </Button>
                          <label className="inputLabel">&nbsp;</label>
                        </div>
                        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                          <label className="inputLabel">&nbsp;</label>

                          <Button
                            className="btn-dialog "
                            onClick={handleBeneficialOwnersClick}
                            disabled={customerinfo.status !== "verified"}
                          >
                            <span className="ml-auto mr-auto">
                              Add Beneficial Owners
                            </span>
                            {getbenificalButtonStatus()}
                            {/* {customerinfo.status !== "verified" ? (
                              <span className="p-tag p-component StatuserrorCls">
                                <span className="p-tag-value">
                                  {" "}
                                  Status : Waiting for previous step
                                </span>
                                <span></span>
                              </span>
                            ) : (
                              <span className="p-tag p-component StatuswaringCls">
                                <span className="p-tag-value">
                                  {" "}
                                  Status :In Progress
                                </span>
                                <span></span>
                              </span>
                            )} */}
                          </Button>
                          <label className="inputLabel">&nbsp;</label>
                        </div>
                        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                          <label className="inputLabel">&nbsp;</label>
                          <Button
                            className="btn-dialog "
                            type="button"
                            onClick={handleBankClick}
                            disabled={getBankStatusDisabledStatus()}
                          >
                            <span className="ml-auto mr-auto ">
                              Add Bank Info
                            </span>
                            {getBankButtonStatus()}
                            {/* {customerinfo.status !== "verified" ? (
                              <span className="p-tag p-component StatuserrorCls">
                                <span className="p-tag-value">
                                  {" "}
                                  Status : Waiting for previous step
                                </span>
                                <span></span>
                              </span>
                            ) : (
                              <></>
                            )} */}
                          </Button>
                          <label className="inputLabel">&nbsp;</label>
                        </div>
                        <div className="col-12">
                          {buttonClicked ? (
                            <div>
                              {customerinfo.email === "" ? (
                                <div className="">
                                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12">
                                    <Card>
                                      <h4 className="cardHeader">
                                        Add Fund Entity Info
                                      </h4>
                                      <p>
                                        <b>Note :</b> For email and name, please
                                        provide the email address, legal first
                                        name and legal last name of the business
                                        owner.
                                      </p>

                                      <div className="dropInContainer">
                                        <dwolla-business-vcr
                                          terms="https://www.avestorinc.com/terms-and-conditions"
                                          privacy="https://www.avestorinc.com/privacy-policy"
                                        ></dwolla-business-vcr>
                                      </div>
                                    </Card>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  {customerinfo?.status === "verified" ||
                                  customerinfo?.status === "deactivated" ||
                                  customerinfo?.lastAction ===
                                    "Customer verification document uploaded" ? (
                                    <>
                                      <div>
                                        <Card>
                                          <h4 className="cardHeader">
                                            Fund Admin / Fund Manager
                                            Information
                                          </h4>

                                          <div>
                                            <div className="formgrid grid px-2">
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  First Name
                                                </label>

                                                <InputText
                                                  name="firstName"
                                                  value={
                                                    customerinfo?.firstName
                                                  }
                                                  disabled
                                                  className="card-inputBox"
                                                  placeholder="First Name"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Last Name{" "}
                                                </label>
                                                <InputText
                                                  name="lastName"
                                                  value={customerinfo?.lastName}
                                                  disabled
                                                  placeholder="Last Name"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Email Id{" "}
                                                </label>
                                                <InputText
                                                  name="lastName"
                                                  value={customerinfo?.email}
                                                  disabled
                                                  placeholder="Email"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Type{" "}
                                                </label>
                                                <InputText
                                                  name="lastName"
                                                  value={customerinfo?.type}
                                                  disabled
                                                  placeholder="Type"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Address{" "}
                                                </label>
                                                <InputText
                                                  name="lastName"
                                                  value={customerinfo?.address1}
                                                  disabled
                                                  placeholder="Address"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  City{" "}
                                                </label>
                                                <InputText
                                                  name="lastName"
                                                  value={customerinfo?.city}
                                                  disabled
                                                  placeholder="City"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  State{" "}
                                                </label>
                                                <InputText
                                                  name="State"
                                                  value={customerinfo?.state}
                                                  disabled
                                                  placeholder="State"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Zip Code{" "}
                                                </label>
                                                <InputText
                                                  name="Zip Code"
                                                  value={
                                                    customerinfo?.postalCode
                                                  }
                                                  disabled
                                                  placeholder="Zip Code"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="col-12">
                                                <h4 className="cardHeader">
                                                  Fund Business Information
                                                </h4>
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Business Name{" "}
                                                </label>
                                                <InputText
                                                  name="businessName"
                                                  value={
                                                    customerinfo?.businessName
                                                  }
                                                  disabled
                                                  placeholder="businessName"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Business Type{" "}
                                                </label>
                                                <InputText
                                                  name="BusinessType"
                                                  value={
                                                    customerinfo?.businessType
                                                  }
                                                  disabled
                                                  placeholder="businessType"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Business Classification{" "}
                                                </label>
                                                <InputText
                                                  name="Business Classification"
                                                  value={
                                                    customerinfo?.businessClassification
                                                  }
                                                  disabled
                                                  placeholder="Business Classification"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Fund EIN{" "}
                                                </label>
                                                <InputText
                                                  name="Fund EIN"
                                                  value={customerinfo?.ein}
                                                  disabled
                                                  placeholder="Fund EIN "
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="col-12">
                                                <h4 className="cardHeader">
                                                  Fund Controller Information
                                                </h4>
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  First Name
                                                </label>

                                                <InputText
                                                  name="accholderfirstName"
                                                  value={
                                                    customerinfo?.accholderfirstName
                                                  }
                                                  disabled
                                                  className="card-inputBox"
                                                  placeholder="First Name"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Last Name{" "}
                                                </label>
                                                <InputText
                                                  name="lastName"
                                                  value={
                                                    customerinfo?.accholderlastName
                                                  }
                                                  disabled
                                                  placeholder="Last Name"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Title{" "}
                                                </label>
                                                <InputText
                                                  name="Title"
                                                  value={customerinfo?.title}
                                                  disabled
                                                  placeholder="Title"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Date Of Birth{" "}
                                                </label>
                                                <InputText
                                                  name="Date Of Birth"
                                                  value={
                                                    customerinfo?.dateOfBirth
                                                  }
                                                  disabled
                                                  placeholder="Date Of Birth"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  SSN{" "}
                                                </label>
                                                <InputText
                                                  name="SSN"
                                                  value={customerinfo?.ssn}
                                                  disabled
                                                  placeholder="SSN"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Address1{" "}
                                                </label>
                                                <InputText
                                                  name="AcountAddress1"
                                                  value={
                                                    customerinfo?.accholderaddress1
                                                  }
                                                  disabled
                                                  placeholder="Address1"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  Address2{" "}
                                                </label>
                                                <InputText
                                                  name="AcountAddress2"
                                                  value={
                                                    customerinfo?.accholderaddress2
                                                  }
                                                  disabled
                                                  placeholder="Address2"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  City{" "}
                                                </label>
                                                <InputText
                                                  name="lastName"
                                                  value={
                                                    customerinfo?.accholdercity
                                                  }
                                                  disabled
                                                  placeholder="City"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  State{" "}
                                                </label>
                                                <InputText
                                                  name="State"
                                                  value={
                                                    customerinfo?.stateProvinceRegion
                                                  }
                                                  disabled
                                                  placeholder="State"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                              <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                                                <label className="inputLabel">
                                                  {" "}
                                                </label>
                                                <InputText
                                                  name="Zip Code"
                                                  value={
                                                    customerinfo?.accholderpostalCode
                                                  }
                                                  disabled
                                                  placeholder="Zip Code"
                                                  className="card-inputBox"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </Card>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <div className="main">
                                        <div className="d-block sm:d-flex sm:flex-column p-2 col-12">
                                          <h2>Add Fund Entity</h2>
                                          <div
                                            // className="dropInContainer"
                                            className={
                                              customerinfo.status === "document"
                                                ? "dropInContainerDocument"
                                                : "dropInContainer"
                                            }
                                          >
                                            <dwolla-business-vcr
                                              terms="https://www.avestorinc.com/terms-and-conditions"
                                              privacy="https://www.avestorinc.com/privacy-policy"
                                              customerId={customerinfo.dwollaId}
                                            ></dwolla-business-vcr>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-12">
                          {fundBeneficialOwners ? (
                            <>
                              {showfundBeneficialOwners === false ? (
                                <div className="col-12">
                                  <div className="row d-block sm:d-flex sm:flex-column p-2 col-12 text-center">
                                    <div className="dropInContainer">
                                      <div className="container">
                                        <dwolla-beneficial-owners
                                          customerId={customerinfo.dwollaId}
                                        ></dwolla-beneficial-owners>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="grid">
                                <div className="col-12">
                                  <div className="card my-3 tabWithoutboxshadow">
                                    <Card>
                                      <h4 className="cardHeader">
                                        Beneficial Owners
                                      </h4>
                                      <div className="row my-4 d-flex justify-content-between">
                                        {" "}
                                      </div>
                                      <div>
                                        {showfundBeneficialOwners === true ? (
                                          <p>
                                            <b>Note :</b> Use Below option if
                                            you need to add more beneficial
                                            owners or delete an existing owner.
                                            This will allow you to add new
                                            owners or delete existing and
                                            recertify with new owners.
                                          </p>
                                        ) : (
                                          <p>
                                            {customerinfo.businessType ===
                                            "soleProprietorship" ? (
                                              <p>
                                                Sole proprietorships are exempt
                                                from adding beneficial owners.
                                              </p>
                                            ) : (
                                              <p>
                                                {" "}
                                                Once all beneficial owners are
                                                added, you need to certify the
                                                owners by clicking the check box
                                                below.
                                              </p>
                                            )}

                                            <label className="container-checkbox my-3">
                                              {customerinfo.businessType ===
                                              "soleProprietorship" ? (
                                                <span>
                                                  {" "}
                                                  I hereby certify that the
                                                  information provided for Fund
                                                  Owner Controller is complete
                                                  and accurate and I certify
                                                  that there are no beneficial
                                                  owners.
                                                </span>
                                              ) : (
                                                <span>
                                                  {" "}
                                                  I hereby certify that the
                                                  information provided for Fund
                                                  Owner Controller and
                                                  Beneficial Owners is complete
                                                  and accurate.
                                                </span>
                                              )}

                                              <input
                                                type="checkbox"
                                                checked={
                                                  !benificialCertifiedchecked
                                                }
                                                onChange={(e) =>
                                                  setBenificialCertifiedchecked(
                                                    !benificialCertifiedchecked
                                                  )
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                          </p>
                                        )}
                                        <Button
                                          className="btn-dialog mb-3"
                                          disabled={benificialCertifiedchecked}
                                        >
                                          <span
                                            className="ml-auto mr-auto"
                                            onClick={
                                              showCertifuedBeneficialOwnersClick
                                            }
                                          >
                                            {showfundBeneficialOwners === false
                                              ? "Certify Beneficial Owners"
                                              : "Undo certification and update beneficial owners"}
                                          </span>
                                        </Button>
                                      </div>
                                      <DataTableComponent
                                        valueData={ownerslist!}
                                        fieldsElements={ownersdataTableElements}
                                        isDownload={false}
                                        isPaginationAllowed={true}
                                      />
                                    </Card>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-12">
                          {addBankClicked ? (
                            <>
                              <div className="row d-block sm:d-flex sm:flex-column p-2 col-12">
                                <div className="card pgHeaderWrap md:my-2 mt-3">
                                  <h1 className="pageHeader">Bank Accounts</h1>
                                  <div className="card my-3 tabWithoutboxshadow">
                                    <Card>
                                      <h4 className="cardHeader">Bank Info</h4>
                                      <DataTableComponent
                                        valueData={bankList!}
                                        fieldsElements={bankListTableElements}
                                        isDownload={true}
                                        isPaginationAllowed={true}
                                      />
                                    </Card>
                                  </div>
                                  <p className="pageSubHeader mb-3">
                                    Link and manage your bank accounts.
                                  </p>
                                  <label className="container-checkbox my-3">
                                    <span>
                                      {" "}
                                      By checking this box you confirm that you
                                      have read and agree to Dwolla&apos;s
                                      <a
                                        href="https://www.dwolla.com/legal/tos"
                                        className="linkthemetxt"
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        {" "}
                                        Terms of Service
                                      </a>{" "}
                                      and Dwolla{" "}
                                      <a
                                        href="https://www.dwolla.com/legal/privacy"
                                        className="linkthemetxt"
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        {" "}
                                        Privacy Policy
                                      </a>
                                    </span>
                                    <input
                                      type="checkbox"
                                      checked={checked}
                                      onChange={(e) => setChecked(!checked)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  {checked ? (
                                    <div className="d-flex justify-content-start mt-2">
                                      <Button
                                        className="BtnBlue flex align-items-center justify-content-center w-3"
                                        type="button"
                                        disabled={!ready}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          checkAccountType();
                                        }}
                                      >
                                        <span>AGREE AND ADD BANK</span>
                                        <RiAddFill className="AddNew-icon text-xl" />
                                      </Button>
                                    </div>
                                  ) : (
                                    <div className="d-flex justify-content-start mt-2">
                                      <Button
                                        className="BtnBlue flex align-items-center justify-content-center w-3"
                                        type="button"
                                        disabled={!checked}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          checkAccountType();
                                        }}
                                      >
                                        <span>AGREE AND ADD BANK</span>
                                        <RiAddFill className="AddNew-icon text-xl" />
                                      </Button>
                                    </div>
                                  )}
                                  <h3>Business Accounts</h3>

                                  <ul className="list-disc text-sm">
                                    <li>
                                      Require additional information before your
                                      account can be connected
                                    </li>
                                    <li>
                                      Send the following to your fund manager
                                    </li>
                                    <ul>
                                      <li>IRS EIN letter for the LLC</li>
                                      <li>
                                        Social security number and a copy of
                                        your driver&apos;s license
                                      </li>
                                    </ul>
                                  </ul>
                                  <h3>SD-IRA Accounts</h3>
                                  <ul className="list-disc text-sm">
                                    <li>
                                      SD-IRA accounts cannot be connected
                                      directly to the Avestor portal
                                    </li>
                                    <li>
                                      To receive ACH distributions, send your
                                      SD-IRA routing number & account number to
                                      your fund manager
                                    </li>
                                  </ul>
                                </div>
                                {/* <div className="dropInContainer">
                                  <dwolla-funding-source-create
                                    customerId="a8fe265b-7560-4646-984b-26d9906cad3a"
                                    initiateMicroDeposits
                                  ></dwolla-funding-source-create>
                                </div> */}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Skeleton width="100%" height="150px"></Skeleton>
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupFundEntity;
