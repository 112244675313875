import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HelpVedioGetVideoLinkService } from "../../../../../services/sponser/sponser";
import error from "../../../../../utils/error";
import {
  IHelpVideoGetVideoLinkparameters,
  IHelpVideoGetVideoLinkState,
} from ".";

const initialState: IHelpVideoGetVideoLinkState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};

export const HelpVedioGetVideoLinkThunk = createAsyncThunk(
  "Sponser/HelpVedioGetVideoLink",
  async (data: IHelpVideoGetVideoLinkparameters) => {
    try {
      const response = await HelpVedioGetVideoLinkService(data);

      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Sponser/HelpVedioGetVideoLink",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(HelpVedioGetVideoLinkThunk.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(HelpVedioGetVideoLinkThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data[0][0] : [];
      console.log("From Slice", state.data);
    });
    builder.addCase(HelpVedioGetVideoLinkThunk.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default slice.reducer;
