import { Button } from "primereact/button";
// import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import React, { useEffect, useMemo, useRef, useState } from "react";
import AmountDisplay from "../../../components/amount-display";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
import Loader from "../../../components/loader/Loader";
// import { getExitUnitsTotalDataByLPPartnerID } from "../../../services/ManagerFees";
import {
  checkEarningsBeforeReverse,
  getAllocatedPoolFundsByInvestorId,
} from "../../../services/PoolFund";
import { getThunkInvestmentIdByProduct } from "../../../store/features/operation/allocate-earning/get-investment-id-product/slice";
import { getThunkEarningsEntries } from "../../../store/features/operation/reverse-earning-allocation/get-earnings-entries/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getInvestorDropdownListByInvestmentIdThunk,
  resetInvestorDropDown,
  reverseAllocatedPoolFundsByStakeIdThunk,
} from "../../../store/poolfunds/exit-pool-funds";
import {
  getAfterEarningsStatckbyInvestmentReturnIDThunk,
  resetAllEarningsStatckbyInvestmentReturn,
} from "../../../store/poolfunds/get-after-earning-stake-byinvestment";
import { getThunkAllInvestmentDealPooledFundByLegalEntityId } from "../../../store/poolfunds/get-investmentdeal-for-pooledfund";
import {
  PoolFundOutPutType,
  PoolFundsByInvestorIdParamType,
} from "../../../types";
import currencyFormat from "../../../utils/currency-formatter";
import { convertUTCToLocalDate } from "../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
import ReverseEarningSection from "../../features/operation/reverse-earning-allocation/ReverseEarningSectionContinuous";

const ReversePoolFund: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [productId, setPropductId] = useState<number>(0);
  const [investmentId, setInvestmentId] = useState<number>(0);
  const [lPPartnerID, setLpPatnerId] = useState<number>(0);
  const [isOpenRevers, setIsOpenReverse] = useState<boolean>(false);
  const [investerStakeData, setInvestorStakeData] = useState<
    PoolFundOutPutType[]
  >([]);
  const [openDailog, setOpenDailog] = useState(false);
  const [stakeParam, setStakeParam] = useState<PoolFundOutPutType | any>();
  const [rowSelectData, setRowSelectData] = useState<any>();
  const [openDialog, setOpenDialog] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const profileData = useAppSelector((state) => state.profile.data![0]);

  const investorDropDown = useAppSelector(
    (state) => state.exitPoolFund.investorDropDown
  );
  const investmentList = useAppSelector(
    (state) => state.getAllInvestorDealListPoolFunds.data.investments
  );

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        userId: profileData?.userID,
      };
      dispatch(
        getThunkAllInvestmentDealPooledFundByLegalEntityId(parameter)
      ).finally(() => {});
    }
  }, [fundDropdown]);

  const getReverseStake = async (param: PoolFundsByInvestorIdParamType) => {
    setLoading(true);
    try {
      const response = await getAllocatedPoolFundsByInvestorId(param);
      if (response.data.success) {
        const list = [...response.data.data.output];
        list[0].isButton = true;
        setInvestorStakeData(list);
      } else {
        setInvestorStakeData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setInvestorStakeData([]);
      console.log("error", error);
    }
  };
  console.log("rowSelectData", rowSelectData, "jjj", stakeParam);

  const handleReverse = () => {
    setLoading(true);
    const paramReverse = {
      legalEntityId: fundDropdown.fundid,
      investmentId,
      stakeTrackingId: stakeParam.stakeTrackingID,
    };

    dispatch(reverseAllocatedPoolFundsByStakeIdThunk(paramReverse))
      .then((res) => {
        setLoading(false);
        if (res.payload.success) {
          successToastMessage(toast, res.payload.message);
        } else {
          errorToastMessage(
            toast,
            res.payload.message || "Something went wrong!"
          );
        }
        const param = {
          legalEntityId: fundDropdown.fundid,
          investmentId,
          lPPartnerId: lPPartnerID,
        };
        setIsOpenReverse(false);
        setStakeParam("");
        getReverseStake(param);
      })
      .catch(() => {
        errorToastMessage(toast, "Something went wrong!");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (fundDropdown.fundid && investmentId && lPPartnerID) {
      const param = {
        legalEntityId: fundDropdown.fundid,
        investmentId,
        lPPartnerId: lPPartnerID,
      };
      getReverseStake(param);
    }
  }, [fundDropdown.fundid, investmentId, lPPartnerID]);

  useEffect(() => {
    if (fundDropdown.fundid && productId) {
      setInvestorStakeData([]);
      dispatch(resetInvestorDropDown());
      setLpPatnerId(0);
      setLoading(true);
      const param = { productId };
      dispatch(getThunkInvestmentIdByProduct(param))
        .then((response) => {
          if (
            response.payload._response.data !== null &&
            response.payload._response.data.externalInvestmentId.key !== 0 &&
            response.payload._response.data.externalInvestmentId !== 0
          ) {
            setInvestmentId(
              response.payload._response.data.externalInvestmentId.key
            );
            dispatch(
              getInvestorDropdownListByInvestmentIdThunk(
                response.payload._response.data.externalInvestmentId.key
              )
            ).finally(() => setLoading(false));
          } else {
            errorToastMessage(toast, "Investment Id is not found");
            setInvestmentId(0);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
        });
    }
  }, [fundDropdown.fundid, productId]);

  const investmentDropdownList: any = useMemo(
    () =>
      investmentList!.length > 0
        ? investmentList?.map((item: any) => {
            return {
              name: item.productName,
              code: item.productID,
            };
          })
        : [],
    [investmentList]
  );
  const lpPatnerDropDownList: any = useMemo(
    () =>
      investorDropDown!.length > 0
        ? investorDropDown?.map((obj: any) => {
            return {
              name: obj.lpPartnerName,
              code: obj.lpPartnerID,
            };
          })
        : [],
    [investorDropDown]
  );

  const onReveserClick = (rowData: PoolFundOutPutType) => {
    const checkBeforeReverse = async () => {
      setLoading(true);
      try {
        const param = {
          lPPartnerId: rowData.lpPartnerId,
          investmentId: rowData.investmentID,
          stakeTrackingID: rowData.stakeTrackingID,
        };
        const response = await checkEarningsBeforeReverse(param);
        if (response.data.success)
          setStakeParam({ ...rowData, ...response.data.data.output?.[0] });
        else setStakeParam(rowData);
        setIsOpenReverse(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("errrr", error);
      }
    };
    return (
      <>
        {rowData?.isButton ? (
          <Button
            className="btn-navActive widthdeletebtn"
            onClick={(e) => {
              e.preventDefault();
              checkBeforeReverse();
            }}
          >
            Reverse
          </Button>
        ) : (
          <Button
            className="btn-navActive widthdeletebtn"
            onClick={(e) => {
              e.preventDefault();
            }}
            disabled={true}
          >
            Reverse
          </Button>
        )}
      </>
    );
  };

  const dataTableElement = [
    {
      field: "lpPartnerName",
      header: "Investor Name",
      noFilter: true,
      style: { minWidth: "200px" },
    },

    {
      field: "units",
      header: "Allocated Investment Balance",
      style: { minWidth: "270px" },
      body: (rowData: PoolFundOutPutType) => (
        <AmountDisplay amount={rowData.addOnUnits ? rowData.addOnUnits : 0}>
          {currencyFormat(rowData.addOnUnits)}
        </AmountDisplay>
      ),
    },
    {
      field: "unitsType",
      header: "Units Type",
      style: { minWidth: "200px" },
    },
    {
      field: "maxStartDate",
      header: "Start Date",
      dataType: "date",
      body: (rowData: PoolFundOutPutType) =>
        convertUTCToLocalDate(rowData.maxStartDate),
      style: { minWidth: "200px" },
    },
    {
      field: "endDate",
      header: "End Date",
      dataType: "date",
      body: (rowData: PoolFundOutPutType) =>
        rowData?.endDate ? convertUTCToLocalDate(rowData.endDate) : "N/A",
      style: { minWidth: "200px" },
    },
    { field: "assetClass", header: "Class", style: { minWidth: "200px" } },
    {
      header: "Reverse",
      body: onReveserClick,
      style: { minWidth: "200px" },
    },
  ];

  const handleChange = (e: any) => {
    const { value } = e.target;
    setPropductId(value);
  };

  const onDialogCancel = (val: boolean) => {
    setStakeParam("");
    setIsOpenReverse(val);
    setOpenDialog(val);
    setRowSelectData({});
  };

  const handleDistribute = (rowData: any) => {
    setRowSelectData(rowData);
    setLoading(true);
    const parameter = {
      investmentReturnTypeId: rowData.investmentReturnTypeID,
      investmentReturnId: rowData.investmentReturnID,
      investmentId: rowData.investmentID,
    };
    dispatch(getThunkEarningsEntries(parameter)).then(() => {
      setLoading(false);
      setOpenDialog(true);
      setIsOpenReverse(false);
    });
    dispatch(
      getAfterEarningsStatckbyInvestmentReturnIDThunk({
        investmentReturnId: rowData.investmentReturnID,
        investmentId: rowData.investmentID,
      })
    );
  };

  const onDistubuteDailogClose = (val: boolean) => {
    setOpenDialog(val);
    setIsOpenReverse(val);
    setStakeParam("");
    setRowSelectData({});
    const param = {
      legalEntityId: fundDropdown.fundid,
      investmentId,
      lPPartnerId: lPPartnerID,
    };
    getReverseStake(param);
    dispatch(resetAllEarningsStatckbyInvestmentReturn());
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <Dialog
        visible={false}
        onHide={() => {
          setIsOpenReverse(false);
          // setInvestorStakeData([]);
        }}
        className="w-100 md:w-10 pb-1"
        headerClassName="no-header"
      >
        <div className="mt-2">
          <h4 className="pageHeader mt-2 flex justify-content-center">
            Stake Reverse
          </h4>
          <div className="mt-2">
            {/* <DataTableComponent
              valueData={investerStakeData || []}
              fieldsElements={dataTableElementExit}
              className="allocateSliceTable"
              noGridLines={true}
            /> */}
          </div>
        </div>
      </Dialog>
      <ReverseEarningSection
        openDialog={openDialog}
        setOpenDialog={onDistubuteDailogClose}
        rowSelectData={rowSelectData}
        setRowSelectData={setRowSelectData}
      />

      <Dialog
        visible={isOpenRevers}
        className="w-100 md:w-3 pb-1"
        onHide={() => onDialogCancel(false)}
      >
        <h4 className="pageHeader text-center mb-5 pt-5">
          {stakeParam?.investmentReturnID && stakeParam?.investmentReturnID > 0
            ? `You can not reverse this allocation. if you want to reverse this allocation please reverse  earning (${stakeParam.investmentReturnID})`
            : "Are you sure, you want to reverse the units"}
        </h4>
        {stakeParam?.investmentReturnID &&
        stakeParam?.investmentReturnID > 0 ? (
          <div className=" flex  flex-wrap align-self-center  align-items-center justify-content-between">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleDistribute(stakeParam);
              }}
              className="blueButtonShadow flex flex-1  align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round"
            >
              View Earning
            </Button>
          </div>
        ) : (
          <>
            <div className=" flex  flex-wrap align-self-center  align-items-center justify-content-between">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  stakeParam?.investmentReturnID &&
                  stakeParam?.investmentReturnID > 0
                    ? onDialogCancel(false)
                    : handleReverse();
                }}
                className="blueButtonShadow flex flex-1 flex align-items-center justify-content-center  font-bold  m-2 px-5 py-3 border-round"
              >
                Yes
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onDialogCancel(false);
                }}
                className="blueButtonShadow flex flex-1  align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round"
              >
                No
              </Button>
            </div>
          </>
        )}
      </Dialog>

      <div className="col-12 flex">
        <div className="card col-11 p-0 pgHeaderWrap ">
          <h1 className="pageHeader fs-2">Reverse Allocations</h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className="col-1 p-0 align-items-center flex justify-content-end">
          <Button
            className="btn-navActive"
            type="button"
            icon="pi pi-window-maximize"
            disabled={
              investerStakeData === undefined ||
              investerStakeData?.length === 0 ||
              investerStakeData === null
            }
            onClick={() => setOpenDailog(true)}
            title="View Full Screen"
          />
        </div>
      </div>
      {/* <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Reverse Allocations</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div> */}
      {/* <div className="grid">
        <div className="grid pt-0 col-12">
          <div className="col-12 pt-0 pr-0"> */}
      <div className="card">
        {/* <Card> */}
        <div className="mt-1 col-12 gap-4 flex">
          <div className="formgrid grid p-0 col-4">
            <div className="d-block sm:d-flex sm:flex-column pl-3 p-0 col-12 ">
              {/* <label className="inputLabel">Select Investment</label> */}
              <Dropdown
                inputId="investment"
                name="investment"
                value={productId}
                options={investmentDropdownList}
                filter
                optionLabel="name"
                optionValue="code"
                placeholder="Select Investment"
                className="card-inputBox"
                // showOnFocus
                onChange={(e) => {
                  e.preventDefault();
                  handleChange(e);
                }}
              />
            </div>
          </div>
          <div className="formgrid grid p-0 col-4">
            <div className="d-block sm:d-flex sm:flex-column pl-3 p-0 col-12  ">
              {/* <label className="inputLabel">Select Investor Account</label> */}
              <Dropdown
                inputId="investment"
                name="investment"
                value={lPPartnerID}
                options={lpPatnerDropDownList}
                filter
                optionLabel="name"
                optionValue="code"
                placeholder="Select Investor Account"
                className="card-inputBox"
                // showOnFocus
                onChange={(e) => {
                  e.preventDefault();
                  setLpPatnerId(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="formgrid grid p-0">
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
            <h6>
              Most recent allocations have to be reversed first. Also, if there
              are earnings processed after this allocation, please reverse those
              first.
            </h6>
          </div>
        </div>
        <DataTableComponent
          valueData={investerStakeData || []}
          fieldsElements={dataTableElement}
          noGridLines={true}
          openDailog={openDailog}
          setOpenDailog={setOpenDailog}
          className="gridcell deletebtn "
          columnResizeMode="expand"
          scrollable={true}
        />
        {/* </Card> */}
      </div>
      {/* </div>
        </div>
      </div> */}
    </>
  );
};

export default ReversePoolFund;
