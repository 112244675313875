import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import React from "react";

interface IProps {
  visibleView: boolean;
  setVisibleView: (visible: boolean) => void;
  setActiveSectionId: (id: number | null) => void;
  activeSectionId: number | null;
  activeIndex: number; // Add activeIndex prop
  handleTabChange: (e: any) => void;
  sections: any[];
  onHide: () => void;
}

export const ViewSection = (props: IProps) => {
  return (
    <Dialog
      header="View Section"
      visible={props.visibleView}
      style={{ width: "50vw" }}
      onHide={props.onHide}
    >
      <TabView
        activeIndex={props.activeIndex}
        onTabChange={props.handleTabChange}
      >
        {props.sections.map((section, index) => (
          <TabPanel header={section.sectionTitle} key={section.id}>
            <div>
              <p
                dangerouslySetInnerHTML={{ __html: section.sectionContent }}
              ></p>
            </div>
          </TabPanel>
        ))}
      </TabView>
    </Dialog>
  );
};
