import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../b2c/b2c-config";
import currencyFormat from "./currency-formatter";
import { convertUTCToLocalDate } from "./date-formatter";

const msalInstance = new PublicClientApplication(msalConfig);

// console.log("request  msalInstance", msalInstance);
// console.log("request  msalInstance", msalInstance.getAllAccounts()[0]);

export const LightenDarkenColor = (col: string, amt: number) => {
  let usePound = false;
  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  const num = Number.parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00_ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x00_00_ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

export const formatanyepercentage = (rowData: any) => {
  console.log("Percentage Here", rowData);
  // if (rowData.toString().includes("%")) {
  //   return rowData;
  // }
  // return `${Number(rowData).toFixed(2)}%`;
  return rowData && rowData.toString().includes("%")
    ? rowData
    : Number(rowData) + "%";
};
export function formatTimestamp(timestamp: string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true, // Use 12-hour clock format
  };

  const formattedDate = new Date(timestamp).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
}

export const formatPhoneNumber = (value: string) => {
  // Remove non-digit characters
  const phoneNumberOnly = value ? value!.replace(/\D/g, "") : value;

  let maskedPhoneNumber = "";

  if (phoneNumberOnly) {
    if (phoneNumberOnly.length <= 3) {
      maskedPhoneNumber = phoneNumberOnly;
    } else if (phoneNumberOnly.length <= 6) {
      maskedPhoneNumber =
        phoneNumberOnly.slice(0, 3) + "-" + phoneNumberOnly.slice(3);
    } else {
      maskedPhoneNumber =
        phoneNumberOnly.slice(0, 3) +
        "-" +
        phoneNumberOnly.slice(3, 6) +
        "-" +
        phoneNumberOnly.slice(6, 10);
    }
  }

  return maskedPhoneNumber;
};

export const formatPhoneNumberExceptUS = (value: string, countryID: number) => {
  const phoneNumberOnly = value ? value!.replace(/\D/g, "") : value;

  let maskedPhoneNumber = "";

  if (phoneNumberOnly) {
    if (countryID !== 229) {
      const isValidPhoneNumber = /^[\d-]*$/.test(value);
      if (isValidPhoneNumber) {
        maskedPhoneNumber = value; // Truncate to 20 characters if exceeds
      }
    } else {
      // If countryId is 229, follow the existing masking logic
      if (phoneNumberOnly.length <= 3) {
        maskedPhoneNumber = phoneNumberOnly;
      } else if (phoneNumberOnly.length <= 6) {
        maskedPhoneNumber =
          phoneNumberOnly.slice(0, 3) + "-" + phoneNumberOnly.slice(3);
      } else {
        maskedPhoneNumber =
          phoneNumberOnly.slice(0, 3) +
          "-" +
          phoneNumberOnly.slice(3, 6) +
          "-" +
          phoneNumberOnly.slice(6, 10);
      }
    }
  }

  return maskedPhoneNumber;
};

export const formatZipCode = (value: any, countryId: any) => {
  // Remove hyphens and non-digit characters

  // Add hyphen after every 5 digits if length is greater than 5
  if (countryId === 229) {
    let formattedValue = value.replace(/[^\d-]/g, "");
    if (formattedValue.length > 5 && formattedValue.length <= 10) {
      const digitsOnly = value.replace(/\D/g, "");
      formattedValue = digitsOnly;
      formattedValue = formattedValue.replace(/(\d{5})/g, "$1-");
    }
    return formattedValue;
  } else {
    return value;
  }
};

export const onlyCharacter = (value: any) => {
  const inputValue = value;
  // Allow only characters (a-z, A-Z)
  // const onlyCharacters = inputValue.replace(/[^A-Za-z]/g, "");
  const onlyCharacters = inputValue.replace(/[^ A-Za-z]/g, "");
  return onlyCharacters;
};

export const onlyNumber = (value: any) => {
  const inputValue = value;
  const onlyNumbers = inputValue.replace(/\D/g, "");
  return onlyNumbers;
};

export const acquireTokenSilently = () => {
  const request = {
    scopes: [],
    account: msalInstance.getAllAccounts()[0],
  };
  msalInstance
    .acquireTokenSilent(request)
    .then((response: any) => {
      localStorage.setItem("token", response.idToken);
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const logoutHelper = (postLogoutRedirectURL: any = ""): void => {
  localStorage.removeItem("token");
  localStorage.removeItem("enrollment");
  localStorage.getItem("tenentName");
  let redirectURL: any = "";

  redirectURL = postLogoutRedirectURL || localStorage.getItem("tenentName");

  msalInstance.logoutRedirect({
    postLogoutRedirectUri: `/${redirectURL}`,
  });
};

export const isNumberAndHypen = (value: any) => {
  const re = /^[\d-]*$/;
  if (value === "" || re.test(value)) {
    return true;
  }
  return false;
};
export const convertToLowerCase = (inputString: any) => {
  // Check if the input is a string
  if (typeof inputString !== "string") {
    return "";
  }
  // Convert the string to lowercase
  return inputString.toLowerCase();
};

export function capitalizeFirstLetter(str: string | undefined) {
  if (str === undefined) {
    return ""; // or handle as desired or throw error for undefine
  }

  return str.charAt(0).toUpperCase() + (str.length > 1 ? str.slice(1) : "");
}
export const formatYesNo = (val: boolean) => {
  return val ? "YES" : "NO";
};

export const formatStringCurrency = (rowData: any) => {
  const finalValue = rowData.search("$")
    ? Number.parseFloat(rowData.replace("$", ""))
    : Number(rowData);
  const parseValue = currencyFormat(Number(finalValue));
  return `${parseValue}`;
};
export const formatStringCurrencyNew = (rowData: any) => {
  if (typeof rowData === "string") {
    return rowData?.search("$")
      ? Number.parseFloat(rowData.replace("$", "").replace(",", ""))
      : Number(rowData);
  } else {
    return rowData; // Already a number, return as-is
  }
};

export const formatCUMCurrency = (rowData: any) => {
  const finalValue = rowData.search("$")
    ? Number.parseFloat(rowData.replace("$", ""))
    : Number(rowData);
  const parseValue = currencyFormat(Number(finalValue));
  const parseValue2 = parseValue.replace("$", "");
  console.log("ParseValue->>>>>", parseValue2, finalValue);
  return Number.parseFloat(parseValue2) < 0
    ? `($${parseValue2})`
    : `${parseValue}`;
};

export const formatCUMCurrencyNew = (rowData: any) => {
  return rowData.search("$")
    ? Number.parseFloat(rowData.replace("$", ""))
    : Number(rowData);
};

export const formatPercent = (value: any) =>
  value && value.toString().includes("%") ? value : Number(value) + "%";

export const formatpercentage = (rowData: any) => {
  return `${Number(rowData).toFixed(2)}%`;
};

export const formatHelper = (key: string, val: any) => {
  switch (key) {
    case "string":
      return val;
    case "date":
      return convertUTCToLocalDate(val);
    case "currency":
      return Number(val) || 0;
    case "yesno":
      return formatYesNo(val);
    case "noyes":
      return formatYesNo(val);
    case "percent":
      return formatPercent(val);
    case "percentage":
      return formatpercentage(val);
    case "diffcurrency":
      return formatCUMCurrencyNew(val);
    case "stringCurrency":
      return formatStringCurrencyNew(val);
    case "timestamp":
      return formatTimestamp(val);
    default:
      return val;
  }
};

export const transformedHeader = (title: string) => {
  return title
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space between camelCase
    .replace(/^\w/, (c: any) => c.toUpperCase()) // Capitalize the first letter
    .trim();
};

export function convertExcelHeaders(
  headers: any,
  dataType: string,
  excludeCurrencyDataType: any[]
) {
  const result = [];
  for (const item of headers) {
    const base = {
      field: item?.field,
      header:
        item?.header?.length > 0
          ? transformedHeader(item?.header)
          : transformedHeader(item?.field),
    };
    result.push(
      excludeCurrencyDataType.includes(item?.field)
        ? base
        : { ...base, dataType }
    );
  }
  return result;
}
