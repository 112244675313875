import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import Loader from "../../../../components/loader/Loader";
import { goToTop } from "../../../../components/scroll-to/ScrollTo";
// import { getThunkPortfolioAssetDropdown } from "../../../../store/assetdropdown/slice";
import { addThunkEdgarFiling } from "../../../../store/features/operation/setup-fund/add-edgar-filing/slice";
import { getThunkSetupFund } from "../../../../store/features/operation/setup-fund/get-setup-fund/slice";
import { getThunkSetupFundEdgarReport } from "../../../../store/features/operation/setup-fund/setup-fund-edgar-report/slice";
import { setStateChangeCheck } from "../../../../store/features/operation/setup-fund/setup-fund-state-check/slice";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  convertLocalDateToUTC,
  convertUTCToLocalDate,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
// import { regexForPassword } from "../../../../utils/sponsor/constants";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

interface IProps {
  pageName: any;
}

export default function SetupFundEdgarFiling(props: IProps) {
  const { pageName } = props;
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [disableField, setDisableField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edgarReportData, setEdgarReportData] = useState<any>();
  const setupFundInfo = useAppSelector((state) => state.getSetupFundInfo.data);
  const edgarReport = useAppSelector((state) => state.edgarReportList.data);
  const [openDailog, setOpenDailog] = useState(false);
  const addCancelSetup = useAppSelector(
    (state) => state.addCancelSetupState.data
  );
  const entityListId = useAppSelector(
    (state) => state.getSetupFundEntityList.data
  );
  const statecheckSetupFund = useAppSelector(
    (state) => state.statecheckSetupFund.data
  );
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getSetupFundData = async (
    formik: any,
    legalEntityId?: any,
    gpEntityId?: any
  ) => {
    const parameters = {
      legalEntityId,
      gpLegalEntityId: gpEntityId || 0,
    };

    setLoading(true);
    await dispatch(getThunkSetupFund(parameters))
      .then((response) => {
        if (
          // response.meta.requestStatus === "fulfilled" &&
          response.payload._response.data === null ||
          response.payload._response.data.edgarDetails!.length === 0
        ) {
          formik.resetForm();
        }
      })
      .catch(() => {
        //  errorToastMessage(toast);
        formik.resetForm();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const formik = useFormik({
    initialValues: {
      cikNumber: "",
      edgarFilingDate: "",
      edgarPassword: "",
      edgarPasswordExpiration: "",
      ccc: "",
      pmac: "",
      passphrase: "",
      SecAccessionNumber: "",
    },
    validate: (data) => {
      const errors: any = {};
      // if (data.edgarPassword && !regexForPassword.test(data.edgarPassword)) {
      //   errors.edgarPassword = "Password Invalid";
      // }
      // if (!data.edgarFilingDate) {
      //   errors.edgarFilingDate = "Please select a date";
      // }
      // if (!data.edgarPasswordExpiration) {
      //   errors.edgarPasswordExpiration = "Please select a date";
      // }
      return errors;
    },
    onSubmit: () => {
      setLoading(true);
      console.log("isise");
      const edgarFilingDateValue =
        setupFundInfo?.edgarDetails?.edgarFilingDate !== "" &&
        setupFundInfo?.edgarDetails?.edgarFilingDate !== null &&
        setupFundInfo?.edgarDetails?.edgarFilingDate !== undefined
          ? setupFundInfo?.edgarDetails?.edgarFilingDate.split("T")[0] ===
            convertLocalDateToUTC(formik.values.edgarFilingDate).split("T")[0]
            ? setupFundInfo?.edgarDetails?.edgarFilingDate
            : convertLocalDateToUTC(formik.values.edgarFilingDate)
          : formik.values.edgarFilingDate === "" ||
            formik.values.edgarFilingDate === null
          ? // eslint-disable-next-line unicorn/no-null
            null
          : convertLocalDateToUTC(formik.values.edgarFilingDate);

      const edgarPasswordExpirationValue =
        setupFundInfo?.edgarDetails?.edgarPasswordExpiration !== "" &&
        setupFundInfo?.edgarDetails?.edgarPasswordExpiration !== null &&
        setupFundInfo?.edgarDetails?.edgarPasswordExpiration !== undefined
          ? setupFundInfo?.edgarDetails?.edgarPasswordExpiration.split(
              "T"
            )[0] ===
            convertLocalDateToUTC(formik.values.edgarPasswordExpiration).split(
              "T"
            )[0]
            ? setupFundInfo?.edgarDetails?.edgarPasswordExpiration
            : convertLocalDateToUTC(formik.values.edgarPasswordExpiration)
          : formik.values.edgarPasswordExpiration === "" ||
            formik.values.edgarPasswordExpiration === null
          ? // eslint-disable-next-line unicorn/no-null
            null
          : convertLocalDateToUTC(formik.values.edgarPasswordExpiration);

      console.log(
        "test---------------",
        edgarFilingDateValue,
        edgarPasswordExpirationValue
      );
      // const edgarFilingDate =
      //   formik.values.edgarFilingDate === null ||
      //   formik.values.edgarFilingDate === ""
      //     ? // eslint-disable-next-line unicorn/no-null
      //       null
      //     : convertLocalDateToUTC(formik.values.edgarFilingDate);
      // const edgarPasswordExpiration =
      //   formik.values.edgarFilingDate === null ||
      //   formik.values.edgarFilingDate === ""
      //     ? // eslint-disable-next-line unicorn/no-null
      //       null
      //     : convertLocalDateToUTC(formik.values.edgarPasswordExpiration);
      // console.log("UTC for Edger", edgarFilingDate);

      const formData = {
        action: setupFundInfo.edgarDetails === undefined ? "Create" : "Update",
        legalEntityID: setupFundInfo?.fundProfileDetails.legalEntityID,
        cikNumber: formik.values.cikNumber,
        edgarFilingDate: edgarFilingDateValue,
        edgarPassword: formik.values.edgarPassword,
        edgarPasswordExpiration: edgarPasswordExpirationValue,
        ccc: formik.values.ccc,
        pmac: formik.values.pmac,
        passphase: formik.values.passphrase,
        latestSECAccessionNumber: formik.values.SecAccessionNumber,
      };
      console.log("ki", formData);
      dispatch(addThunkEdgarFiling(formData))
        .then((response) => {
          if (response.payload._response.success === false) {
            errorToastMessage(toast);
          } else {
            successToastMessage(toast, "Data saved successfully");
            // dispatch(getThunkPortfolioAssetDropdown()).then(() => {
            // dispatch(getUserProfileDetailsThunk({ Slug: tenentName! }));
            // });
            getSetupFundData(
              formik,
              setupFundInfo?.fundProfileDetails.legalEntityID,
              entityListId?.gpllcEntityId || 0
            );
          }
        })
        .catch((error: any) => {
          console.log("error", error);

          errorToastMessage(toast);
          // setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    // if (name === "edgarPasswordExpiration" || name === "edgarFilingDate") {
    //   const dateValue = dateFormatter(value);
    //   formik.setFieldValue(name, dateValue);
    // } else {
    formik.setFieldValue(name, value);
    // }
  };
  const formatDate = (rowData: any) => {
    // const dateFormat = dateFormatter(rowData);
    const dateFormat = formatDateMMDDYYY(rowData);

    return dateFormat;
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const dataTableElements = [
    {
      field: "cikNumber",
      header: "CIK Number",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.cikNumber}>
          {rowData?.cikNumber}
        </div>
      ),
    },
    {
      field: "edgarFilingDate",
      header: "Edgar Filing Date",
      style: { minWidth: "200px" },
      body: (rowData: any) =>
        rowData.edgarFilingDate === null
          ? ""
          : formatDate(rowData.edgarFilingDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "edgarPasswordExpiration",
      header: "Edgar Password Expiration",
      style: { minWidth: "240px" },
      body: (rowData: any) =>
        rowData.edgarPasswordExpiration === null
          ? ""
          : formatDate(rowData.edgarPasswordExpiration),
      filterElement: dateFilterTemplate,
    },
    {
      field: "ccc",
      header: "CCC",
      style: { minWidth: "140px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.ccc}>
          {rowData?.ccc}
        </div>
      ),
    },
    {
      field: "pmac",
      header: "PMAC",
      style: { minWidth: "140px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.pmac}>
          {rowData?.pmac}
        </div>
      ),
    },
    {
      field: "passphase",
      header: "Passphrase",
      style: { minWidth: "140px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.passphase}>
          {rowData?.passphase}
        </div>
      ),
    },
    {
      field: "latestSECAccessionNumber",
      header: "Latest SEC Accession Number",
      style: { minWidth: "240px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.latestSECAccessionNumber}>
          {rowData?.latestSECAccessionNumber}
        </div>
      ),
    },
    {
      field: "modifiedBy",
      header: "Updated By",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.modifiedBy}>
          {rowData?.modifiedBy}
        </div>
      ),
    },
    {
      field: "modifiedDate",
      header: "Date Updated",
      dataType: "date",
      style: { minWidth: "180px" },
      body: (rowData: any) =>
        rowData.modifiedDate === null ? "" : formatDate(rowData.modifiedDate),
      filterElement: dateFilterTemplate,
    },
  ];

  useEffect(() => {
    if (pageName === "Reports") {
      setDisableField(true);
      if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
        dispatch(getThunkSetupFundEdgarReport(fundDropdown.fundid));
      }
    } else {
      setDisableField(false);
    }
    if (addCancelSetup.add === true) {
      formik.resetForm();
    }
    // else {
    //   if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
    //     getSetupFundData(
    //       formik,
    //       fundDropdown.fundid,
    //       entityListId?.gpllcEntityId || 0
    //     );
    //   }
    // }
  }, [fundDropdown, addCancelSetup]);
  useEffect(() => {
    setEdgarReportData(
      getListDateConversion(edgarReport, [
        "edgarFilingDate",
        "edgarPasswordExpiration",
        "modifiedDate",
      ])
    );
  }, [edgarReport]);
  useEffect(() => {
    if (setupFundInfo?.edgarDetails !== undefined) {
      formik.setFieldValue("cikNumber", setupFundInfo.edgarDetails?.cikNumber);
      formik.setFieldValue(
        "edgarFilingDate",
        setupFundInfo?.edgarDetails?.edgarFilingDate === null ||
          setupFundInfo?.edgarDetails?.edgarFilingDate === ""
          ? setupFundInfo?.edgarDetails?.edgarFilingDate
          : convertUTCToLocalDate(setupFundInfo?.edgarDetails?.edgarFilingDate)
      );
      formik.setFieldValue(
        "edgarPassword",
        setupFundInfo?.edgarDetails?.edgarPassword
      );
      formik.setFieldValue(
        "edgarPasswordExpiration",
        setupFundInfo?.edgarDetails?.edgarPasswordExpiration === null ||
          setupFundInfo?.edgarDetails?.edgarPasswordExpiration === ""
          ? setupFundInfo?.edgarDetails?.edgarPasswordExpiration
          : convertUTCToLocalDate(
              setupFundInfo?.edgarDetails?.edgarPasswordExpiration
            )
      );
      formik.setFieldValue("ccc", setupFundInfo?.edgarDetails?.ccc);
      formik.setFieldValue("pmac", setupFundInfo?.edgarDetails?.pmac);
      formik.setFieldValue(
        "passphrase",
        setupFundInfo?.edgarDetails?.passphase
      );
      formik.setFieldValue(
        "SecAccessionNumber",
        setupFundInfo?.edgarDetails?.latestSECAccessionNumber
      );
    }
  }, [setupFundInfo]);

  useEffect(() => {
    const list = { ...statecheckSetupFund.edgarFilingApiData };
    const list2 = { ...formik.values };
    console.log("2233", typeof list2.edgarPasswordExpiration);
    const dateCorrectList = {
      ...list2,
      edgarPasswordExpiration:
        list2.edgarPasswordExpiration !== null &&
        list2.edgarPasswordExpiration !== ""
          ? formatDateMMDDYYY(new Date(list2.edgarPasswordExpiration))
          : "",
      edgarFilingDate:
        list2.edgarFilingDate !== null && list2.edgarFilingDate !== ""
          ? formatDateMMDDYYY(new Date(list2.edgarFilingDate))
          : "",
    };
    list.NewState = dateCorrectList;
    dispatch(
      setStateChangeCheck({ ...statecheckSetupFund, edgarFilingApiData: list })
    );
  }, [formik.values]);
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <div>
        <div className=" h-100 center-card">
          <div className="col-12 ">
            <div className="card my-3">
              <form>
                <div className="mt-4">
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">CIK Number </label>
                      <InputText
                        name="cikNumber"
                        value={formik.values.cikNumber}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("cikNumber"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter cik number"
                      />
                      {getFormErrorMessage("cikNumber")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="card-subheader">
                        Edgar Filing Date
                      </label>
                      <div>
                        <Calendar
                          name="edgarFilingDate"
                          value={
                            formik.values.edgarFilingDate === "" ||
                            formik.values.edgarFilingDate === null
                              ? undefined
                              : new Date(formik.values.edgarFilingDate)
                          }
                          disabled={disableField === true}
                          onChange={(e) => handleChange(e)}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("edgarFilingDate"),
                            "card-inputBox": true,
                          })}
                          // minDate={todayDate}
                          placeholder="mm/dd/yyyy"
                          readOnlyInput
                        />
                        {getFormErrorMessage("edgarFilingDate")}
                      </div>
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">Edgar Password</label>
                      <InputText
                        name="edgarPassword"
                        type={showPassword ? "text" : "password"}
                        value={formik.values.edgarPassword}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("edgarPassword"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Password"
                      />
                      <span
                        className="password-eye-icon"
                        // style={{ right: "50px" }}
                        onClick={togglePasswordVisibility}
                      >
                        {" "}
                        {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                      </span>

                      {getFormErrorMessage("edgarPassword")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="card-subheader">
                        Edgar Password Expiration
                      </label>
                      <div>
                        <Calendar
                          name="edgarPasswordExpiration"
                          value={
                            formik.values.edgarPasswordExpiration === "" ||
                            formik.values.edgarPasswordExpiration === null
                              ? undefined
                              : new Date(formik.values.edgarPasswordExpiration)
                          }
                          onChange={(e) => handleChange(e)}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid(
                              "edgarPasswordExpiration"
                            ),
                            "card-inputBox": true,
                          })}
                          disabled={disableField === true}
                          // minDate={todayDate}
                          placeholder="mm/dd/yyyy"
                          readOnlyInput
                        />
                        {getFormErrorMessage("edgarPasswordExpiration")}
                      </div>
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    {" "}
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">CCC </label>
                      <InputText
                        name="ccc"
                        value={formik.values.ccc}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("ccc"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter CCC"
                      />
                      {getFormErrorMessage("ccc")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">PMAC </label>
                      <InputText
                        name="pmac"
                        value={formik.values.pmac}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("pmac"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter pmac"
                      />
                      {getFormErrorMessage("pmac")}
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">Passphrase </label>
                      <InputText
                        name="passphrase"
                        value={formik.values.passphrase}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("passphrase"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter passphrase"
                      />
                      {getFormErrorMessage("passphrase")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">
                        Latest SEC Accession Number{" "}
                      </label>
                      <InputText
                        name="SecAccessionNumber"
                        value={formik.values.SecAccessionNumber}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("SecAccessionNumber"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter number"
                      />
                      {getFormErrorMessage("SecAccessionNumber")}
                    </div>
                  </div>
                  {pageName !== "Reports" ? (
                    <></>
                  ) : (
                    <>
                      <div className=" col-12 flex justify-content-end">
                        <div className="flex align-items-center gap-2 ml-2 mb-2">
                          <Button
                            className="btn-navActive"
                            type="button"
                            icon="pi pi-window-maximize"
                            disabled={
                              edgarReportData === undefined ||
                              edgarReportData?.length === 0 ||
                              edgarReportData === null
                            }
                            onClick={() => setOpenDailog(true)}
                            title="View Full Screen"
                          />
                        </div>
                      </div>
                      <DataTableComponent
                        valueData={edgarReportData || []}
                        fieldsElements={dataTableElements}
                        isPaginationAllowed={true}
                        className="gridcell deletebtn"
                        columnResizeMode="expand"
                        scrollable={true}
                        resizableColumns={true}
                        openDailog={openDailog}
                        setOpenDailog={setOpenDailog}
                      />
                    </>
                  )}
                </div>
              </form>
            </div>
            {pageName !== "Reports" ? (
              <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
                <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
                  <Button
                    className="btn-dialog "
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      // handleChangeState();
                      goToTop();
                      formik.handleSubmit();
                    }}
                  >
                    <span className="ml-auto mr-auto ">Save</span>
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
