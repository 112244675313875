import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestorDetailsForAdmintService } from "../../../services/profiledetails/profiledetails";
import error from "../../../utils/error";
import {
  IGetInvestorDetailsForAdminparameters,
  IGetInvestorDetailsForAdminState,
} from ".";

const initialState: IGetInvestorDetailsForAdminState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  sideLogo: undefined,
};
export const GetInvestorDetailsForAdmin = createAsyncThunk(
  "InvestorDetailsForAdmin",
  async (data: IGetInvestorDetailsForAdminparameters) => {
    try {
      const response = await getInvestorDetailsForAdmintService(data);
      console.log("InvestorDetailsForAdmin", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "InvestorDetailsForAdmin",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetInvestorDetailsForAdmin.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetInvestorDetailsForAdmin.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data
        ? action.payload.data.investorDetails[0]
        : [];
      state.sideLogo = action.payload.data
        ? action.payload.data.investorDetails[0].fundLogo
        : [];

      console.log("state..investorDetails[0]", state.sideLogo);
    });
    builder.addCase(GetInvestorDetailsForAdmin.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
