import { IGetDashboardInfoparameters } from "../store/dashboard/dashboardmanagement";
import apiFactory from "../utils/api";
import { envVariable } from "../utils/sponsor/helper";

export function GetDashboardInfoService(data: IGetDashboardInfoparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/DashboardManagement/GetDashboardInfo?LegalEntityId=${data.LegalEntityId}
    `
  );
}
