import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserMenuService } from "../../../../services/profile/menus/menus";
import error from "../../../../utils/error";
import { IDashboardMenuState } from ".";

const initialState: IDashboardMenuState = {
  success: false,
  message: "",
  data: {
    getStuffList: [],
  },
  loading: "",
};
export const getThunkDashboardMenus = createAsyncThunk(
  "dashboardMenus",
  async (data: any) => {
    try {
      const response = await getUserMenuService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "dashboardMenus",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkDashboardMenus.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkDashboardMenus.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data =
        action.payload._response.data === null
          ? []
          : action.payload._response.data;
    });
    builder.addCase(getThunkDashboardMenus.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
