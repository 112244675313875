import "./Opportunities.scss";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
// import DashboardDropdownComponent from "../../../../components/dashboard-dropdown/DashboardDropdownComponent";
import ErrorUi from "../../../../components/ErrorUi";
import RegisterDropdownComponent from "../../../../components/dashboard-dropdown/RegisterDropdown";
import OpportunityCard from "../../../../components/opportunities/OpportunityCard";
import OpportunitySkeleton from "../../../../components/opportunities/OpportunitySkeleton";
// import { getThunkPortfolioNonRegisterInvestorListDropdown } from "../../../../store/features/dropdown/nonregisteredinvestorlist/slice";
// import { getThunkPortfolioRegisterInvestorListDropdown } from "../../../../store/features/dropdown/registeredinvestorlist/slice";
// import { getPortfolioGetLegalEntityList } from "../../../../store/features/dropdown/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  getAllOpportunitiesData,
  getOpportunitiesThunk,
} from "../../../../store/opportunity/slice";

// import Opportunities from "./Oppotunities";

const Debt = () => {
  const dispatch = useAppDispatch();
  const [page, setpage] = useState(1);
  const debtOpp = useAppSelector(getAllOpportunitiesData);
  const status = useAppSelector((state) => state.legalEntityList.loading);
  const totalPages = useAppSelector(
    (state) => state.opportunity.pageDetails?.totalPages
  );
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  // const funddropdownModel = useAppSelector(
  //   (state) => state.legalEntityList.data
  // );
  // const registerinvestorModel = useAppSelector(
  //   (state) => state.registerinvestor.data
  // );
  // const nonregisterinvestorModel = useAppSelector(
  //   (state) => state.nonregisterinvestor.data
  // );
  // const legalEntityList: { code: number; name: string }[] = [];
  // const [leagalentity, setLeagalentity] = useState<any>();
  // const [registredinverstment, setregistredinverstment] = useState<any>();
  // const [nonregistredinverstment, setnonregistredinverstment] = useState<any>();
  // for (let i = 0; i < funddropdownModel?.length; i++) {
  //   legalEntityList.push({
  //     code: funddropdownModel![i].legalEntityID,
  //     name: funddropdownModel![i].legalEntityName,
  //   });
  // }

  // const registerinverstmenList: { code: number; name: string }[] = [];
  // for (let i = 0; i < registerinvestorModel?.length; i++) {
  //   registerinverstmenList.push({
  //     code: registerinvestorModel![i].investorID,
  //     name: registerinvestorModel![i].investorName,
  //   });
  // }
  // const nonregisterinverstmenList: { code: number; name: string }[] = [];
  // for (let i = 0; i < nonregisterinvestorModel?.length; i++) {
  //   nonregisterinverstmenList.push({
  //     code: nonregisterinvestorModel![i].investorID,
  //     name: nonregisterinvestorModel![i].investorName,
  //   });
  // }

  // useEffect(() => {
  //   const InvestorListparameters = {
  //     LegalEntityId: leagalentity,
  //   };
  //   if (leagalentity !== undefined) {
  //     dispatch(
  //       getThunkPortfolioRegisterInvestorListDropdown(InvestorListparameters)
  //     );
  //   }
  // }, [leagalentity]);

  // useEffect(() => {
  //   const InvestorListparameters = {
  //     LegalEntityId: leagalentity,
  //   };
  //   if (leagalentity !== undefined) {
  //     dispatch(
  //       getThunkPortfolioNonRegisterInvestorListDropdown(InvestorListparameters)
  //     );
  //   }
  // }, [leagalentity]);

  // const LegalEntityparameters = {
  //   Email: "softlabsavestor%40gmail.com",
  //   RoleId: 1,
  // };

  // useEffect(() => {
  //   dispatch(getPortfolioGetLegalEntityList(LegalEntityparameters));
  // }, []);

  const getNextOpportunities = (e: any) => {
    e.preventDefault();
    setpage((prevState) => prevState + 1);
  };

  const getPrevOpportunities = (e: any) => {
    e.preventDefault();
    setpage((prevState) => prevState - 1);
  };

  let content;
  switch (status) {
    case "loading":
      content = <OpportunitySkeleton />;
      break;
    case "succeeded":
      content = debtOpp!.map((product: any) => {
        return (
          <OpportunityCard
            p={product}
            key={product.productID}
            selectedValue={""}
          />
        );
      });

      break;
    case "failed":
      content = <h1>Error</h1>;
      break;
  }

  useEffect(() => {
    if (
      dropdown.nonregisterid !== undefined &&
      dropdown.fundid !== undefined &&
      dropdown?.nonregisterid! > 0
    ) {
      const paramerts = {
        lpPartnerID: dropdown.nonregisterid.toString(),
        category: "Equity",
        deals: "All deal",
        availablePageNo: 1,
        pageSize: 6,
        legalEntityId: dropdown.fundid,
      };
      const updateBoday = {
        ...paramerts,
        availablePageNo: page,
      };
      dispatch(getOpportunitiesThunk(updateBoday));
    }
    return () => {
      //
    };
  }, [page, dropdown.fundid, dropdown.nonregisterid]);

  useEffect(() => {
    if (
      dropdown.registerid !== undefined &&
      dropdown.fundid !== undefined
      // &&
      // dropdown?.registerid! > 0
    ) {
      const paramerts = {
        lpPartnerID: dropdown.registerid.toString(),
        category: "Equity",
        deals: "All deal",
        availablePageNo: 1,
        pageSize: 6,
        legalEntityId: dropdown.fundid,
      };
      const updateBoday = {
        ...paramerts,
        availablePageNo: page,
      };
      dispatch(getOpportunitiesThunk(updateBoday));
    }
    return () => {
      //
    };
  }, [page, dropdown.fundid, dropdown.registerid, dropdown.nonregisterid]);

  // useEffect(() => {
  //   debtOpp = [];
  //   totalPages = 0;
  // }, [dispatch, nonregistredinverstment]);
  return (
    <>
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Equity Opportunities</h1>
            <span className="subheader">
              View and commit to all current opportunities
            </span>
          </div>
          {/* <Opportunities /> */}
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div>
            <RegisterDropdownComponent />
          </div>
        </div>
        {/* <div className="row col-12 p-4">
          <DashboardDropdownComponent
            leagalentity={leagalentity}
            setLeagalentity={setLeagalentity}
            legalEntityList={legalEntityList}
            registredinverstment={registredinverstment}
            setregistredinverstment={setregistredinverstment}
            registerinverstmenList={registerinverstmenList}
            nonregistredinverstment={nonregistredinverstment}
            setnonregistredinverstment={setnonregistredinverstment}
            nonregisterinverstmenList={nonregisterinverstmenList}
          />
        </div> */}
      </div>
      <div className="grid">{content}</div>
      {status === "succeeded" && totalPages! > 0 && (
        <div className="row pt-4">
          <div className="col-sm-4"></div>

          <div className="col-sm-4 d-flex justify-content-center">
            <div className=" mt-3 p-0 pagination ">
              <Button
                onClick={getPrevOpportunities}
                disabled={page === 1}
                className="BtnBlue"
              >
                Prev Page
              </Button>
              <li className="page-item active">
                <span className="BtnBlue rounded ">{page}</span>
              </li>
              <Button
                disabled={page === totalPages}
                onClick={getNextOpportunities}
                className="BtnBlue float-right"
              >
                Next Page
              </Button>
            </div>
          </div>

          <div className="col-sm-4 mt-3 text-end">
            <li className="page-item active BtnBlue rounded">
              {totalPages! > 0 ? (
                <div>
                  {" "}
                  <span>{page}</span>
                  <span className="font-bold ml-1">of </span>
                  <span>{totalPages!}</span>
                </div>
              ) : (
                <></>
              )}{" "}
            </li>
          </div>
        </div>
      )}
      {status === "succeeded" && totalPages! === 0 && (
        <div className="row pt-4">
          <div className="col-sm-4"></div>

          <div className="col-sm-4 d-flex justify-content-center">
            <div className=" mt-3 p-0 pagination ">
              <h3>No Data Found</h3>
            </div>
          </div>
        </div>
      )}
      {status === "failed" && totalPages! === 0 && <ErrorUi />}
    </>
  );
};
export default Debt;
