import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllColorService } from "../../../services/profile/profile";
import error from "../../../utils/error";
// import apiFactory from "../../utils/api";
interface IColorState {
  data?: {}[] | null;
  myColorLightest: string;
  myColorLight: string;
  myColorDark: string;
  myColorDarkest: string;
  Color: string;
  loading: string;
  error?: string | null;
}

const initialState: IColorState = {
  data: undefined,
  loading: "loading",
  error: undefined,
  myColorLightest: "",
  myColorLight: "",
  myColorDark: "",
  myColorDarkest: "",
  Color: "",
};

export const getThunkColor = createAsyncThunk("Profile/color", async () => {
  try {
    const response = await getAllColorService();
    return response.data;
  } catch (error_) {
    console.log(error_);
    throw new Error(error(error_));
  }
});

const slice = createSlice({
  name: "Profile/color",
  initialState,
  reducers: {
    setColor: (state, action) => {
      // state.Data = action.payload;
      state.Color = action.payload.Color;
      state.myColorLightest = action.payload.myColorLightest;
      state.myColorLight = action.payload.myColorLight;
      state.myColorDark = action.payload.myColorDark;
      state.myColorDarkest = action.payload.myColorDarkest;
      // console.log("setColor action", state, action);
      // console.log("setColor state", state.myColorDarkest);
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkColor.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getThunkColor.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(getThunkColor.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export const { setColor } = slice.actions;

export default slice.reducer;
