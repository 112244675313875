import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetFolderTreeFM,
  GetFoldersFiles,
  SaveFolderTransfer,
  getFolderDetailsByParentFolderIdFileManager,
} from "../../../../services/Filemanger/filemanager";
import error from "../../../../utils/error";
import { IFileManagerFolderDetails } from ".";

const initialState: IFileManagerFolderDetails = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    FolderDetails: [],
  },
};
export const getFolderDetailsByParentFolderIdFileManagerThunk =
  createAsyncThunk(
    "FileManagement/getFolderDetailsByParentFolderId",
    async (data: any) => {
      try {
        const response = await getFolderDetailsByParentFolderIdFileManager(
          data
        );
        return response.data;
      } catch (error_) {
        console.log("er", error_);
        throw new Error(error(error_));
      }
    }
  );

export const GetFoldersFilesThunk = createAsyncThunk(
  "FileManagement/GetFoldersFiles",
  async (data: any) => {
    try {
      const response = await GetFoldersFiles(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const GetFolderTreeFMThunk = createAsyncThunk(
  "FileManagement/GetFolderTreeFM",
  async (data: any) => {
    try {
      const response = await GetFolderTreeFM(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const SaveFolderTransferThunk = createAsyncThunk(
  "FileManagement/SaveFolderTransfer",
  async (data: any) => {
    try {
      const response = await SaveFolderTransfer(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "GetPrimaryFolderFileManager",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(
      getFolderDetailsByParentFolderIdFileManagerThunk.pending,
      (state) => {
        state.loading = "loading";
        //   state.error = undefined;
      }
    );
    builder.addCase(
      getFolderDetailsByParentFolderIdFileManagerThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(
          "action.payload._response.data==",
          action.payload.response.data.documents
        );
        state.data.FolderDetails = action.payload
          ? action.payload.response.data.documents
          : [];
      }
    );
    builder.addCase(
      getFolderDetailsByParentFolderIdFileManagerThunk.rejected,
      (state) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
