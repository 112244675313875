import { IGetGPPartnerparameters } from "../../store/features/accounting/debitcreditinvestoraccounts/GetGPPartner";
import { IFundEnrollmentPayload, IIsAccountExistPayload } from "../../types";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function isAccountExistService(data: IIsAccountExistPayload) {
  // const email = data.email.replace("+", "%2B");
  // As discussed with Ashish. He will get email id from token
  return apiFactory().get(
    `${envVariable.REACT_APP_API_ADMIN_MANAGEMENT_URL}/SponsorDetails/IsSponsorAccountExist?FundName=${data.fundName}`
  );
}
export function getGetGPPartnerService(data: IGetGPPartnerparameters) {
  return apiFactory().get(
    `${envVariable.REACT_APP_API_ADMIN_MANAGEMENT_URL}/SponsorDetails/GetGPPartner?LegalEntityId=${data.LegalEntityId}`
  );
}

export function fundEnrollmentService(data: IFundEnrollmentPayload) {
  return apiFactory().post(
    `${envVariable.REACT_APP_API_INVESTOR_SPNG_URL}/Users/FundEnrollment`,
    data
  );
}
