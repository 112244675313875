import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllPortfolioProductListService } from "../../../../services/portfolio/portfolio";
import error from "../../../../utils/error";
import { ILegalEntityparameters, ILegalEntityparametersState } from ".";

const initialState: ILegalEntityparametersState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  supplierList: [],
};
export const getPortfolioProductList = createAsyncThunk(
  "Portfolio/GetPortfolioProductList",
  async (data: ILegalEntityparameters) => {
    try {
      const response = await getAllPortfolioProductListService(data);
      console.log("ProductList", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Portfolio/GetPortfolioProductList",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getPortfolioProductList.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getPortfolioProductList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data
        ? action.payload.data.productList[0]
        : [];
      state.supplierList = action.payload.data
        ? action.payload.data.productList[1]
        : [];
    });
    builder.addCase(getPortfolioProductList.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
