import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { SaveAllAllocationSListService } from "../../../../services/operation/operation";
import error from "../../../../utils/error";
import { IGetallocationslicesState } from "../allocation-slices";

const initialState: IGetallocationslicesState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: undefined,
};

export const SaveThunkAllocateSlices = createAsyncThunk(
  "saveAllocateslices/saveallocationslices",
  async (data: any) => {
    try {
      const response = await SaveAllAllocationSListService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "saveAllocateslices/saveallocationslices",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(SaveThunkAllocateSlices.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(SaveThunkAllocateSlices.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload ? action.payload._response.data : [];
    });
    builder.addCase(SaveThunkAllocateSlices.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
