import {
  IAddBankWithPlaidRequestBody,
  ICheckACHOTPSParameter,
  IDeleteBankAccountPayLoad,
  ILegalEntityAndPartnerID,
  IPostCheckTransactionStatusPayload,
  IPostCustomerDwollaPayload,
  IPostFundPrimaryAccountPayload,
  IRequestWithdrawalFund,
  ISendACHOTPSParameter,
  ITransferFromInvestorPayload,
} from "../../types";
// import { IAssetdropdownparameter } from "../../types";
import apiFactory from "../../utils/api";
// import { envVariable as envVar } from "../../utils/gateway/helper";
import { envVariable as envVar } from "../../utils/sponsor/helper";

// export async function getPlaidLinkTokenService() {
//   console.log("services called");
//   return apiFactory().get(
//     `${newenv!.REACT_APP_API_FINANCE_URL}api/Plaid/GetPlaidLinkToken`
//   );
// }

export async function addBankWithPlaidService(
  metaData: IAddBankWithPlaidRequestBody
) {
  return apiFactory().post(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/AddBankWithPlaid`,
    metaData
  );
}

export async function getCustomerTypeService(data: ILegalEntityAndPartnerID) {
  return apiFactory().get(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/GetCustomerType?LegalEntityId=${
      data.legalEntityID
    }&InvestorId=${data.investorID}&partnerID=${data.partnerID}`
  );
}

export async function bindFundBankAccListService(data: {
  InvOrEntityId: number;
  AvCustomerType: string;
}) {
  return apiFactory().get(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/GetBankAccList?InvOrEntityId=${
      data.InvOrEntityId
    }&AvCustomerType=${data.AvCustomerType}`
  );
}

// Make everything dynamic
// export async function sendACHOTPService(data: ISendACHOTPSParameter) {
//   const email = data.email.replace("+", "%2B");

//   return apiFactory().get(
//     `${
//       envVar!.REACT_APP_API_FINANCE_URL
//     }Plaid/SendOTP?EmailId=${email}&LegalEntityId=${
//       data.legalEntityID
//     }&UserId=${data.userID}`
//   );
// }

export async function checkOTPACHService(data: ICheckACHOTPSParameter) {
  return apiFactory().get(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/validateOTP?OTP=${
      data.OTP
    }&UserId=${data.UserId}`
  );
}

export async function reSendACHOTPService(data: ISendACHOTPSParameter) {
  const email = data.email.replace("+", "%2B");

  return apiFactory().get(
    `${
      envVar!.REACT_APP_API_FINANCE_URL
    }/Plaid/ReSendOTP?EmailId=${email}&LegalEntityId=${
      data.legalEntityID
    }&UserId=${data.userID}`
  );
}

export async function isCustomerExistService(data: { InvestorId: number }) {
  return apiFactory().get(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/isCustomerExist?InvestorId=${
      data.InvestorId
    }`
  );
}

export async function getPartnerInvestorInfoService(data: {
  partnerID: string;
}) {
  return apiFactory().get(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/GetPartnerInfo?PartnerID=${
      data.partnerID
    }`
  );
}

export async function postCustomerService(data: IPostCustomerDwollaPayload) {
  return apiFactory().post(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/AddCustomer`,
    data
  );
}

export async function getBankListService(data: { investorID: number }) {
  return apiFactory().get(
    `${
      envVar!.REACT_APP_API_FINANCE_URL
    }/Plaid/GetBankDropDownList?InvestorId=${data.investorID}`
  );
}

export async function postFundPrimaryAccount(
  data: IPostFundPrimaryAccountPayload
) {
  return apiFactory().put(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/UpdatePrimaryAccount`,
    data
  );
}

export async function postDeleteBankAccountService(
  data: IDeleteBankAccountPayLoad
) {
  return apiFactory().delete(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/DeleteBankAccount?Id=${
      data.Id
    }&DwollaAccountID=${data.DwollaAccountID}&CustomerId=${data.CustomerId}`
  );
}

// Transfer Money
export async function transferFromInvestorService(
  data: ITransferFromInvestorPayload
) {
  return apiFactory().post(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/TransferMoney`,
    data
  );
}

export async function getFundDetailsService(data: ILegalEntityAndPartnerID) {
  return apiFactory().get(
    `${
      envVar!.REACT_APP_API_FINANCE_URL
    }/Plaid/GetFundTransferDetails?InvestorId=${
      data.investorID
    }&LegalEntityId=${data.legalEntityID}`
  );
}

export async function getWirelessTransactionService(
  data: ILegalEntityAndPartnerID
) {
  return apiFactory().get(
    `${
      envVar!.REACT_APP_API_FINANCE_URL
    }/Plaid/GetDwollaTransactions?LegalEntityId=0&InvestorId=${data.investorID}`
  );
}

export async function getInvLEPartnerIdService(data: ILegalEntityAndPartnerID) {
  return apiFactory().get(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/GetInvLEPartnerId?InvestorId=${
      data.investorID
    }`
  );
}

export async function getAccountStatementService(data: any) {
  return apiFactory().get(
    `${
      envVar!.REACT_APP_API_FINANCE_URL
    }/Plaid/getAccountStatement?LPPartnerId=${data.partnerID}&LegalEntityId=${
      data.legalEntityID
    }`
  );
}

export async function getLEPartnerIdService(data: ILegalEntityAndPartnerID) {
  return apiFactory().get(
    `${
      envVar!.REACT_APP_API_FINANCE_URL
    }/Plaid/GetLEPartnerId?LegalEntityInvId=1212&Type=type`
  );
}

export function postCheckTransactionStatusService(
  data: IPostCheckTransactionStatusPayload
) {
  return apiFactory().post(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/PostCheckTransactionStatus`,
    data
  );
}

export function requestWithdrawalFundService(data: IRequestWithdrawalFund) {
  return apiFactory().post(
    `${envVar!.REACT_APP_API_FINANCE_URL}/Plaid/RequestWithdrawalFund`,
    data
  );
}
// /api/Plaid/RequestWithdrawalFund
