import { TabView, TabPanel } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import { getThunkSectionProductById } from "../../store/features/investment/update/section/list/slice";
import { useAppDispatch } from "../../store/hooks";

interface IProps {
  id: string;
}

const OpportunityProjectDetails: React.FC<IProps> = (props) => {
  const [sections, setSections] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchSections = async () => {
      const reqBody = {
        ProductId: props.id,
        SectionId: 0,
      };
      try {
        const response: any = await dispatch(
          getThunkSectionProductById(reqBody)
        );
        if (response.payload.success) {
          setSections(response.payload.data);

          // Fetch content for the first section and set it to state
          if (response.payload.data.length > 0) {
            const firstSection = response.payload.data[0];
            const contentReqBody = {
              ProductId: firstSection.productId,
              SectionId: firstSection.id,
            };
            const contentResponse: any = await dispatch(
              getThunkSectionProductById(contentReqBody)
            );
            if (
              contentResponse.payload.success &&
              contentResponse.payload.data.length > 0
            ) {
              setSections((prevSections: any) => {
                const updatedSections = [...prevSections];
                updatedSections[0] = {
                  ...updatedSections[0],
                  sectionContent:
                    contentResponse.payload.data[0].sectionContent,
                };
                return updatedSections;
              });
            }
          }
        } else {
          console.error(response.payload.message);
        }
      } catch (error) {
        console.error("Failed to fetch sections", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSections();
  }, [dispatch, props.id]);

  const handleTabChange = async (e: any) => {
    const newActiveIndex = e.index;
    setActiveIndex(newActiveIndex);

    const selectedSection = sections[newActiveIndex];
    const reqBody = {
      ProductId: selectedSection.productId,
      SectionId: selectedSection.id,
    };

    try {
      const response: any = await dispatch(getThunkSectionProductById(reqBody));
      console.log("response 1", response.payload);

      if (response.payload.data) {
        setSections((prevSections: any) => {
          const updatedSections = prevSections.map(
            (section: any, index: any) => {
              if (index === newActiveIndex) {
                return {
                  ...section,
                  sectionContent: response.payload.data[0].sectionContent,
                };
              }
              return section;
            }
          );
          console.log("updatedSections", updatedSections);
          return updatedSections;
        });
      } else {
        console.error(response.payload.message);
      }
    } catch (error) {
      console.error("Failed to fetch section content", error);
    }
  };
  console.log("sections", sections);
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {sections.length > 0 ? (
        <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
          {sections.map((section: any) => (
            <TabPanel key={section.id} header={section.sectionTitle}>
              <p
                className="m-0 text-left"
                dangerouslySetInnerHTML={{ __html: section.sectionContent }}
              ></p>
            </TabPanel>
          ))}
        </TabView>
      ) : (
        <p className="m-0 text-left">No project Details available</p>
      )}
    </>
  );
};

export default OpportunityProjectDetails;
