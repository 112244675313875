import * as xlsx from "xlsx";
import { getConfig } from "../../b2c/env-config";

export const getEnvConfig: any = () => {
  const url = window.location.hostname;
  let env: any;

  if (url.includes("demo")) {
    env = "demo";
  } else if (url.includes("localhost")) {
    env = "local";
  } else if (url.includes("dev")) {
    env = "dev";
  } else if (url.includes("qa")) {
    env = "qa";
  } else if (url.includes("uat")) {
    env = "uat";
  } else {
    env = "prod";
  }

  return env;
};

const env: string = getEnvConfig();

export const appConfig: any = () => {
  const appconfig = getConfig(env);
  return appconfig;
};

export const saveAsExcelFile = (buffer: any, fileName: string) => {
  import("file-saver").then((module) => {
    if (module && module.default) {
      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });

      module.default.saveAs(
        data,
        fileName +
          //  + "_export_" + Date.now()
          EXCEL_EXTENSION
      );
    }
  });
};
export const exportExcel = (valueData: any, fileName?: string) => {
  // import("xlsx").then((xlsx) => {

  const worksheet = xlsx.utils.json_to_sheet(valueData);
  const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  const excelBuffer = xlsx.write(workbook, {
    bookType: "xlsx",
    type: "array",
    // });
  });
  saveAsExcelFile(excelBuffer, fileName || "Data");
};

export const envVariable = appConfig();
