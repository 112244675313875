import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestorListService } from "../../../../services/view-gather-softcommit/view-gather-softcommit";
import error from "../../../../utils/error";
import { IInvestorListState } from ".";

const initialState: IInvestorListState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkInvestorList = createAsyncThunk(
  "getInvestorList",
  async (data: any) => {
    try {
      const response = await getInvestorListService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getInvestorList",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkInvestorList.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkInvestorList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      state.data =
        action.payload.data !== null ? action.payload.data.userList : [];
      state.message = action.payload.message;
    });
    builder.addCase(getThunkInvestorList.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
