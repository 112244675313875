// import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
// import { RiSearchLine } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getInvestorsinFundCommits } from "../../../../store/features/reports/Investors-in-Fund/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";

// import InvestorsinFundSkeleton from "./InvestorsinFundSkeleton";

export default function InvestorsinFund() {
  const dispatch = useAppDispatch();
  const portState = useAppSelector((state) => state.investorsinFund.loading);
  const dropdownModel = useAppSelector((state) => state.legalEntityList.data);
  const investorsinFundmodel = useAppSelector(
    (state) => state.investorsinFund?.data
  );
  const [exitDate, setExitDate] = useState<any>();

  const maskData = (data: any) => {
    const noMaskValue = data.slice(-4);
    const maskValue = data.slice(0, -4);
    const maskedPart = maskValue.replace(/./g, (char: any) => {
      return /[\dA-Za-z]/.test(char) ? "x" : char;
    });
    const value = maskedPart + noMaskValue;

    return value;
  };
  const maskNumberConversion = (data: any) => {
    return [...(data || [])].map((d) => {
      const modifiedMask = {
        ...d,
        ein: maskData(d.ein),
        taxPayerId: maskData(d.taxPayerId),
      };
      return modifiedMask;
    });
  };
  const legalEntityList: { code: number; name: string }[] = [];
  for (let i = 0; i < dropdownModel?.length; i++) {
    legalEntityList.push({
      code: dropdownModel![i].legalEntityID,
      name: dropdownModel![i].legalEntityName,
    });
  }

  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData.fundingDate);
    return dateFormat;
  };

  const formateyesno = (rowData: any) => {
    return rowData.accredited === true ? "Yes" : "NO";
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const dataTableElements = [
    {
      field: "investorAccount",
      header: "Investor Account",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.investorAccount}>
          {rowData?.investorAccount}
        </div>
      ),
    },
    {
      field: "emailAddress",
      header: "Email",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.emailAddress}>
          {rowData?.emailAddress}
        </div>
      ),
    },
    {
      field: "address",
      header: "Address",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.address}>
          {rowData?.address}
        </div>
      ),
    },

    {
      field: "city",
      header: "City",
      style: { minWidth: "150px" },
    },
    {
      field: "usState",
      header: "State",
      style: { minWidth: "100px" },
    },
    {
      field: "zipCode",
      header: "Zip Code",
      style: { minWidth: "200px" },
    },
    {
      field: "partnerType",
      header: "Partner Type",
      style: { minWidth: "200px" },
    },
    {
      field: "businessName",
      header: "Business Name",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.businessName}>
          {rowData?.businessName}
        </div>
      ),
    },
    {
      field: "ein",
      header: "EIN",
      style: { minWidth: "100px" },
    },
    {
      field: "taxPayerId",
      header: "TaxPayer Id",
      style: { minWidth: "200px" },
    },
    {
      field: "fundType",
      header: "Fund Type",
      style: { minWidth: "200px" },
    },

    {
      field: "fundingDate",
      header: "Funding Date",
      dataType: "date",
      body: formatDate,
      filterElement: dateFilterTemplate,
      style: { minWidth: "200px" },
    },
    {
      field: "accredited",
      header: "Accredited",
      body: formateyesno,
      style: { minWidth: "200px" },
    },
  ];
  const exportExcelData = [
    {
      field: "investorAccount",
      header: "Investor Account",
    },
    {
      field: "emailAddress",
      header: "Email",
    },
    {
      field: "address",
      header: "Address",
    },

    {
      field: "city",
      header: "City",
    },
    {
      field: "usState",
      header: "State",
    },
    {
      field: "zipCode",
      header: "Zip Code",
    },
    {
      field: "partnerType",
      header: "Partner Type",
    },
    {
      field: "businessName",
      header: "Business Name",
    },
    {
      field: "ein",
      header: "EIN",
    },
    {
      field: "taxPayerId",
      header: "TaxPayer Id",
    },
    {
      field: "fundType",
      header: "Fund Type",
    },

    {
      field: "fundingDate",
      header: "Funding Date",
      dataType: "date",
    },
    {
      field: "accredited",
      header: "Accredited",
      dataType: "yesno",
    },
  ];

  let parameters = {
    legalEntityId: 3,
  };

  const dropdown = useAppSelector((state) => state.navbardropdown.data);

  useEffect(() => {
    parameters = {
      legalEntityId: dropdown.fundid,
    };
    dispatch(getInvestorsinFundCommits(parameters));
  }, [dropdown.fundid]);

  useEffect(() => {
    setExitDate(
      maskNumberConversion(
        getListDateConversion(investorsinFundmodel, ["fundingDate"])
      )
    );
  }, [investorsinFundmodel]);
  // const rowDta = 10;
  const dt = useRef<DataTable>(null);
  // const exportCSV = (selectionOnly: any) => {
  //   dt.current!.exportCSV({ selectionOnly });
  // };
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      {portState === "loading" ? <Loader /> : <></>}
      {/* <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Investors in Fund Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div> */}
      {/* <div className="grid">
        <div className="col-12"> */}
      {/* <div className="card"> */}
      {/* <Card> */}
      {/* <h4 className="cardHeader">Investors in Fund Report</h4>
            <div className="row my-4 d-flex justify-content-between"> </div> */}

      {/* <div className="flex align-items-center justify-content-end gap-2">
              <Button
                className="blueButtonShadow"
                type="button"
                // icon="pi pi-file"
                // rounded
                onClick={() => exportCSV(false)}
                data-pr-tooltip="CSV"
              >
                {" "}
                <span className="blueButtonShadowText">Download </span>
              </Button>
            </div> */}
      <DataTableComponent
        valueData={exitDate}
        fieldsElements={dataTableElements}
        ref={dt}
        scrollable={true}
        isFullView={true}
        excelFieldsElements={exportExcelData}
        HeadingName="Investors in Fund Report"
        isPaginationAllowed={true}
        isDownload={true}
        columnResizeMode="expand"
        maxHeight="calc(-133px + 100vh)"
        fileName="Investors in Fund Report"
        className="gridcell"
      />
      {/* </Card> */}
      {/* </div> */}
      {/* </div>
      </div> */}
    </>
  );
}
