import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IGetDwollaCustomerInfoState } from "..";
import {
  GetDwollaCustomerInfoService,
  SaveDwollaCustomerInfoService,
} from "../../../services/Dwolla/dwolla";
import error from "../../../utils/error";

const initialState: IGetDwollaCustomerInfoState = {
  success: false,
  message: "",
  loading: "loading",
  data: {
    invOREntityID: "",
    firstName: "",
    lastName: "",
    email: "",
    type: "",
    address1: "",
    city: "",
    state: "",
    postalCode: "",
    accholderfirstName: "",
    accholderlastName: "",
    title: "",
    ssn: "",
    dateOfBirth: "",
    accholderaddress1: "",
    accholderaddress2: "",
    accholdercity: "",
    stateProvinceRegion: "",
    accholderpostalCode: "",
    country: "",
    businessClassification: "",
    businessType: "",
    businessName: "",
    ein: "",
    avCustomerType: "",
    dwollaId: "",
    status: "",
    lastAction: "",
    isCertified: "",
  },
};
export const GetDwollaCustomerInfoThunk = createAsyncThunk(
  "dwollaCompo/DwollaCustomerInfo",
  async (data: any) => {
    try {
      const response = await GetDwollaCustomerInfoService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const SaveDwollaCustomerInfoThunk = createAsyncThunk(
  "dwollaCompo/SaveDwollaCustomerInfo",
  async (data: any) => {
    try {
      const response = await SaveDwollaCustomerInfoService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "dwollaCompo",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetDwollaCustomerInfoThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetDwollaCustomerInfoThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";

      state.data =
        action.payload.data !== null ? action.payload.data : initialState.data;
    });
    builder.addCase(GetDwollaCustomerInfoThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });

    builder.addCase(SaveDwollaCustomerInfoThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });

    builder.addCase(SaveDwollaCustomerInfoThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";

      state.data =
        action.payload.data !== null ? action.payload.data : initialState.data;
    });
    builder.addCase(SaveDwollaCustomerInfoThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
