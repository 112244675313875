// import { IInvestmentData } from "../../store/features/investment/create";
import { IGetDAVByEffectiveDateparameters } from "../../store/Investor/setupinvestorfees/getdavbyeffectivedate";
import { IGetNonRegisteredInvestorListparameters } from "../../store/Investor/setupinvestorfees/getnonregisteredinvestorlist";
import { IFilterInvestmentParameter } from "../../store/features/investment/investment-filter";
import { ISectionListParameter } from "../../store/features/investment/update/section/list";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function addInvestmentService(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_INVESTMENT_URL}/Investments/CreateInvestment`,
    data
  );
}

export function addInvestmentUploadFileService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/UploadInvestmentFile`,
    data
  );
}

export function bulkUpdateindexThunkInvestmentService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/BulkImageIndexUpdate`,
    data
  );
}

export function getInvestmentService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetInvestmentsAndDealsCards`,
    data
  );
}
// export function getInvestmentByFilterService(data: any) {
//   return apiFactory().get(
//     `${
//       envVariable!.REACT_APP_API_SPONSOR_URL
//     }Investments/GetInvestmentsAndDealsCardsWithFilter?LegalEntityId=${
//       data.legalEntityId
//     }&SupplierId=${data.supplierId}&City=${data.city}&StateCode=${
//       data.stateCode
//     }&LookUpCode=${data.supplierProductCode}&ProductName=${data.productName}`
//   );
// }

export function getInvestmentByFilterService(data: IFilterInvestmentParameter) {
  data.pooledFund = data.pooledFund ? data.pooledFund : "all";
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetInvestmentsAndDealsCardsWithFilter`,
    data
  );
}

export function getInvestmentByLegalEntityIdService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetInvestmentsAndDealsCardsByLegalEntityId?LegalEntityId=${
      data.legalEntityId
    }&UserId=${data.userId}`
  );
}

export function getInvestmentByIdService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetInvestmentDealByProductId?ProductID=${data}`
  );
}
export function reverseInvestmentToDealService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/ConvertInvestmentToDeal?ProductID=${data}`
  );
}
export function deleteInvestmentFileService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/DeleteInvestmentFile?InvestmentDocumnetsId=${
      data.prodImgId
    }&IsDoc=${data.isDoc}`
  );
}
export function getInvestmentFilebyProductIdService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetInvestmentFileByProductId?ProductId=${
      data.ProductId
    }&IsDoc=${data.IsDoc}`
  );
}

export function setPrimaryInvestmentFileService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/SetPrimaryInvestmentFile?InvestmentDocumnetsId=${
      data.id
    }&IsPrimary=${data.isPrimary}`
  );
}
export function GetNonRegisteredInvestorListService(
  data: IGetNonRegisteredInvestorListparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetNonRegisteredInvestorList?LegalEntityId=${
      data.LegalEntityId
    }
    `
  );
}

// section
export function addSectionService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/CreateAndUpdateInvestmentInformationSection`,
    data
  );
}

export function getSectionByProductIdService(data: ISectionListParameter) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/getInvestmentInformationSectionByProductId?ProductId=${
      data.ProductId
    }&SectionId=${data.SectionId}`
  );
}

export function getSectionByIdService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/getInvestmentInformationSectionById?Id=${data}`
  );
}
export function deleteSectionByIdService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/DeleteInvestmentInformationSection?Id=${data}`
  );
}

export function bulkUpdateIndexThunkSectionService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/BulkProductInformationSectionIndexUpdate`,
    data
  );
}

export function GetDAVByEffectiveDateService(
  data: IGetDAVByEffectiveDateparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestorFees/GetDAVByEffectiveDate?EffectiveDate=${
      data.EffectiveDate
    }&PartnerId=${data.PartnerId}`
  );
}
// softCommit
export function getSoftCommitListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetSoftCommitsByProductId?ProductId=${data}`
  );
}

// project update

export function AddProjectUpdateService(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_INVESTMENT_URL}/Investments/CreateProductLog`,
    data
  );
}

export function GetProjectUpdateService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/getProjectLogByProductId?ProductId=${data.productId}`
  );
}

export function DeleteProjectUpdateService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/DeleteProductLog?Id=${data.id}`
  );
}
// delete deal

export function DeleteDealService(data: any) {
  console.log("data", data);
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/ArchiveProduct?ProductId=${data.productId}&Type=${data.type}`
  );
}

export const getInvestmentsAndDealsCardsByLegalEntityIdForPooledFunds = (
  data: any
) => {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/GetInvestmentsAndDealsCardsByLegalEntityIdForPooledFunds?LegalEntityId=${
      data.legalEntityId
    }`
  );
};

export function deletePooledfundInvestmentbyProductIdService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/DeletePooledfundInvestmentbyProductId?ProductId=${
      data.productId
    }`
  );
}
