import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchEarningsTransactions from "../SearchEarningsTransactions";
import AllocateAllEarning from "../allocate-earning";
import ReverseEaringAllocationTab from "../reverse-earning-allocation";

const ManageEarning: React.FC = () => {
  const [tabTitle, setTabTitle] = useState<string>("");
  const location = useLocation();
  const handleTabTitle = (path: string) => {
    switch (path) {
      case "/ManageInvestmentReturn/Index":
        return "allocateEarning";
      case "/SearchEarningsTransactions":
        return "searchTrans";
      case "/ReverseEarningDistribution/Index":
        return "reverseEarning";
      default:
        return "allocateEarning";
    }
  };
  useEffect(() => {
    setTabTitle(handleTabTitle(location.pathname));
  }, [location.pathname]);
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tab: string
  ) => {
    e.preventDefault();
    setTabTitle(tab);
  };
  return (
    <form className=" h-100 " action="#">
      <div className="tabHeader-wrap">
        <Button
          className={`${
            tabTitle === "allocateEarning"
              ? "tabHeadText Active"
              : "tabHeadText "
          }`}
          onClick={(e) => handleClick(e, "allocateEarning")}
        >
          <span className="ml-auto mr-auto "> Allocate Earnings</span>
        </Button>
        <Button
          className={`${
            tabTitle === "reverseEarning"
              ? "tabHeadText Active"
              : "tabHeadText "
          }`}
          onClick={(e) => handleClick(e, "reverseEarning")}
        >
          <span className="ml-auto mr-auto "> Reverse Earnings</span>
        </Button>
        <Button
          className={`${
            tabTitle === "searchTrans" ? "tabHeadText Active" : "tabHeadText "
          }`}
          onClick={(e) => handleClick(e, "searchTrans")}
        >
          <span className="ml-auto mr-auto "> Search Transactions</span>
        </Button>
      </div>
      {tabTitle === "allocateEarning" ? <AllocateAllEarning /> : <></>}
      {tabTitle === "reverseEarning" ? <ReverseEaringAllocationTab /> : <></>}
      {tabTitle === "searchTrans" ? <SearchEarningsTransactions /> : <></>}
    </form>
  );
};

export default ManageEarning;
