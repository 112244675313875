import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
// import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useRef } from "react";
// import { useAppSelector } from "../../store/hooks";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addNewSoftCommitThunk } from "../../store/opportunity/soft-commit/slice";
import {
  errorToastMessage,
  successToastMessage,
} from "../../utils/toast-message";
interface IProps {
  ID: string;
  productState: string;
  onModalClose: () => void;
  openModal: boolean;
}
const OpportunitySoftCommitPop1: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const profile = useAppSelector((state) => state.profile.data![0]);
  const onSubmit = (amt: string) => {
    // e.preventDefault();
    const paramter = {
      investorID: profile.investorID,
      legalEnityId: profile.legalEntityID,
      productId: props.ID,
      partnerId: profile.partnerID,
      walkingUserId: 0,
      amount: amt,
      loginId: 0,
    };

    dispatch(addNewSoftCommitThunk(paramter))
      .then((res) => {
        console.log("response", res);
      })
      .catch((error) => {
        errorToastMessage(toast);
        console.log("errror", error);
      });

    successToastMessage(
      toast,
      "Your request has been submitted to the fund manager and they will reach out to you with next steps"
    );
    props.onModalClose();
  };

  const formik = useFormik({
    initialValues: {
      value: undefined,
    },
    validate: (data: any) => {
      const errors: { value?: string } = {};
      if (data.value && data.value.toString().length >= 9) {
        errors.value = "Number should be 8 digit";
      }
      if (data.value && data.value > 20_000_000_000) {
        errors.value = "Number should be less then $20B";
      }
      if (!data.value) {
        errors.value =
          props.productState === "PreFunded"
            ? "Request investment amount is required."
            : "Soft commit amount is required.";
      }
      if (data.value <= 0) {
        errors.value = `Enter a valid amount.`;
      }

      return errors;
    },
    onSubmit: (data: any) => {
      console.log(onSubmit(data.value));
      formik.resetForm();
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);
  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <>
      <Dialog
        visible={props.openModal}
        dismissableMask={true}
        className="w-100 md:w-3"
        onHide={props.onModalClose}
        // footer={footerContent}
      >
        <h2 className="h2-dialog">
          {props.productState === "PreFunded"
            ? "Request Investment"
            : "Soft Commit"}
        </h2>
        <p className="m-0 pb-4 dialog-p">
          Submit your request in this deal. Your Request will be reviewed and
          approved by fund manager overseeing this deal.
        </p>
        <div className="card flex justify-content-center">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-column gap-2 softComAmt"
          >
            <label htmlFor="username " className="label-opp">
              {}
              {props.productState === "PreFunded"
                ? "Request Investment Amount"
                : "Soft Commit Amount"}
            </label>
            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon">$</span>
              <InputNumber
                id="value"
                name="value"
                value={formik.values.value}
                onChange={(e) => {
                  formik.setFieldValue("value", e.value);
                }}
                // onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("value"),
                })}
              />
            </div>
            {getFormErrorMessage("value")}
            {/* <Button type="submit" label="Submit" /> */}

            <div className="mt-2 flex justify-content-center">
              <Button
                label="Cancel"
                onClick={props.onModalClose}
                className="btn-cancel w47 mx-1 btn-nav"
                type="button"
              />
              <Button
                type="submit"
                label="Submit"
                className="btn-dialog w47 mx-1 "
                // onClick={onSubmit}
                autoFocus
              />
            </div>
          </form>
        </div>
      </Dialog>
      <Toast ref={toast} className="themeToast" />
    </>
  );
};
export default OpportunitySoftCommitPop1;
