import { ProgressBar } from "primereact/progressbar";
import React, { useMemo } from "react";
type StepProgressBarProps = {
  mainLabel: string;
  subLabel: string;
  currentStep: number;
  noOfSteps: number;
  isCapital?: boolean;
};
const StepProgressBar: React.FC<StepProgressBarProps> = ({
  mainLabel,
  subLabel,
  currentStep,
  noOfSteps,
  isCapital = true,
}) => {
  const barVal = useMemo(
    () => (currentStep / noOfSteps) * 100,
    [currentStep, noOfSteps]
  );
  return (
    <>
      <div className="headercard d-flex grid">
        <div className="col-12 md:col-6">
          <h2 className={`cardHeader  ${isCapital ? "text-capitalize" : ""}`}>
            {mainLabel}
          </h2>
        </div>
        <div className="col-12 md:col-6 end-div">
          <h6>
            Step {currentStep} - {subLabel}
          </h6>
        </div>
      </div>
      <div>
        <ProgressBar
          value={Math.floor(barVal)}
          showValue={false}
          className="m-2"
        ></ProgressBar>
      </div>
    </>
  );
};

export default StepProgressBar;
