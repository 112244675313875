import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addUpdateInvestorInviteService } from "../../../../services/investor/investor";
import error from "../../../../utils/error";
import {
  IAddUpdateInvestorInviteState,
  IAddUpdateInvestorInvitePayload,
} from ".";

// src/store/Investor/invite-investor/get-investor-invite-list

const initialState: IAddUpdateInvestorInviteState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  error: undefined,
};
export const addUpdateInvestorInviteThunk = createAsyncThunk(
  "Investor/invite-investor/addUpdateInvestorInvite",
  async (data: IAddUpdateInvestorInvitePayload) => {
    try {
      const response = await addUpdateInvestorInviteService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Investor/invite-investor/addUpdateInvestorInvite",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(addUpdateInvestorInviteThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(addUpdateInvestorInviteThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data : [];
    });
    builder.addCase(addUpdateInvestorInviteThunk.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      state.error = error(action.payload);
      throw error(action.payload);
    });
  },
});

export default slice.reducer;
