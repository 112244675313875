import { Card } from "primereact/card";
import { Skeleton } from "primereact/skeleton";

import { Tooltip } from "primereact/tooltip";
import React, { useEffect } from "react";
import {
  RiPercentLine,
  RiMapPinLine,
  RiCalendarCheckLine,
  RiCalendarTodoLine,
  RiCalendar2Line,
  RiHome4Line,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getOpportunitiyInverstmentDetailData,
  getOpportunitiyInvestmentDetailsThunk,
  getOpportunityDetailsStatus,
} from "../../store/opportunity/opportunity-investment-details/slice";
import currencyFormat from "../../utils/currency-formatter";
import { convertUTCToLocalDate } from "../../utils/date-formatter";

interface IProps {
  id: string;
}

const OpportunityInvestment: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();

  const investmentDetailStatus = useAppSelector(getOpportunityDetailsStatus);
  const investmentDetails = useAppSelector(
    getOpportunitiyInverstmentDetailData
  );

  const formatPercent = (value: any) => `${value}`;

  const countryName =
    investmentDetails?.country !== "United States"
      ? investmentDetails?.country
      : "";

  useEffect(() => {
    dispatch(getOpportunitiyInvestmentDetailsThunk(+props.id));
  }, [dispatch]);
  if (
    investmentDetails?.targetCloseDate !== undefined &&
    investmentDetails?.targetCloseDate !== null
  ) {
    console.log(
      "investmentDetails?.targetCloseDate->>>>",
      convertUTCToLocalDate(investmentDetails?.targetCloseDate, "mm//dd/yyyy")
    );
  }

  return (
    <>
      {investmentDetailStatus === "succeeded" ? (
        <Card>
          <h4 className="cardHeader">Investment Details</h4>
          <div className="custom-switches">
            {investmentDetails?.productListingAddress ? (
              <div className="acc-value-details my-4">
                <div className="acc-value-details-icon">
                  <RiMapPinLine className="icons-wrap" />
                </div>
                <div className="acc-value-details-text">
                  <p className="acc-value-details-textHeader">
                    Investment Address
                  </p>
                  <Tooltip
                    target=".custom-target-icon"
                    style={{
                      width: "300px",
                    }}
                  />
                  <p
                    className="acc-value-details-textValue addressDetailOver custom-target-icon"
                    // title={`${investmentDetails?.productListingAddress},${
                    //   investmentDetails?.cityStateCode
                    // },${countryName && `${countryName},`}${
                    //   investmentDetails?.zipCode
                    // }`}
                    data-pr-tooltip={`${
                      investmentDetails?.productListingAddress
                    },${investmentDetails?.cityStateCode},${
                      countryName && ` ${countryName},`
                    }${investmentDetails?.zipCode}`}
                    data-pr-position="bottom"
                  >
                    {investmentDetails?.productListingAddress},{" "}
                    {investmentDetails?.cityStateCode},{" "}
                    {countryName && `${countryName},`}
                    {investmentDetails?.zipCode}
                    &nbsp;
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {investmentDetails?.interestRate ? (
              <div className="acc-value-details my-4">
                <div className="acc-value-details-icon">
                  <RiPercentLine className="icons-wrap" />
                </div>
                <div className="acc-value-details-text">
                  <p className="acc-value-details-textHeader">
                    Total Interest (DEBT) / IRR (Equity)
                  </p>
                  <p className="acc-value-details-textValue detailsTextOppInvestment">
                    <span
                      data-pr-tooltip={investmentDetails?.interestRate}
                      data-pr-position="right"
                      title={investmentDetails?.interestRate}
                    >
                      {formatPercent(investmentDetails?.interestRate)}
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* PreFunded, SoftCommit */}
            {investmentDetails?.productState === "PreFunded" &&
            investmentDetails?.estExitDate ? (
              <div className="acc-value-details my-4">
                <div className="acc-value-details-icon">
                  <RiCalendar2Line className="icons-wrap" />
                </div>
                <div className="acc-value-details-text">
                  <p className="acc-value-details-textHeader">
                    Estimate Exit Date
                  </p>
                  <p className="acc-value-details-textValue">
                    {convertUTCToLocalDate(
                      investmentDetails?.estExitDate.toString(),
                      "mm/dd/yyyy"
                    ).toString()}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {investmentDetails?.productState === "SoftCommit" &&
            investmentDetails?.targetCloseDate ? (
              <div className="acc-value-details my-4">
                <div className="acc-value-details-icon">
                  <RiCalendar2Line className="icons-wrap" />
                </div>
                <div className="acc-value-details-text">
                  <p className="acc-value-details-textHeader">
                    Target Close Date:
                  </p>
                  <p className="acc-value-details-textValue">
                    {convertUTCToLocalDate(
                      investmentDetails?.targetCloseDate.toString(),
                      "mm/dd/yyyy"
                    ).toString()}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {investmentDetails?.category ? (
              <div className="acc-value-details my-4">
                <div className="acc-value-details-icon">
                  <RiHome4Line className="icons-wrap" />
                </div>
                <div className="acc-value-details-text">
                  <p className="acc-value-details-textHeader">Asset Class </p>
                  <p className="acc-value-details-textValue">
                    {investmentDetails?.category}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {investmentDetails?.actualExitDate ? (
              <div className="acc-value-details my-4">
                <div className="acc-value-details-icon">
                  <RiCalendarCheckLine className="icons-wrap" />
                </div>
                <div className="acc-value-details-text">
                  <p className="acc-value-details-textHeader">
                    Actual Exit Date
                  </p>
                  <p className="acc-value-details-textValue">
                    {convertUTCToLocalDate(
                      investmentDetails?.actualExitDate.toString(),
                      "mm/dd/yyyy"
                    ).toString()}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {investmentDetails?.fundingDate ? (
              <div className="acc-value-details my-4">
                <div className="acc-value-details-icon">
                  <RiCalendarTodoLine className="icons-wrap" />
                </div>
                <div className="acc-value-details-text">
                  <p className="acc-value-details-textHeader">Funding Date </p>
                  <p className="acc-value-details-textValue">
                    {convertUTCToLocalDate(
                      investmentDetails?.fundingDate.toString(),
                      "mm/dd/yyyy"
                    ).toString()}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}

            {investmentDetails?.remainingAllocation ? (
              <div className="acc-value-details my-4">
                <div className="acc-value-details-icon">
                  <RiMoneyDollarCircleLine className="icons-wrap" />
                </div>
                <div className="acc-value-details-text">
                  <p className="acc-value-details-textHeader">
                    Remaining Allocation{" "}
                  </p>
                  <p className="acc-value-details-textValue">
                    {currencyFormat(investmentDetails?.remainingAllocation)}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Card>
      ) : (
        <Skeleton width="100%" height="150px"></Skeleton>
      )}
    </>
  );
};

export default OpportunityInvestment;
