import { createSlice } from "@reduxjs/toolkit";

export const initialPayloadAddState = {
  productId: 0,
  investmentId: 0,
  investmentName: "",
  investorList: [],
  isDateChange: false,
  // showAllocatedInvestor: false,
  allocatedInvestorTab: undefined,
};
export const initialState = {
  data: {
    productId: 0,
    investmentId: 0,
    investmentName: "",
    investorList: [],
    isDateChange: false,
    // showAllocatedInvestor: false,
    allocatedInvestorTab: undefined,
  },
};

const slice = createSlice({
  name: "createAllocationSliceState",
  initialState,
  reducers: {
    setAllocationSliceInvestorList: (state, action) => {
      console.log(" astest", action.payload);
      state.data = action.payload;
      //
    },
  },
});
export const { setAllocationSliceInvestorList } = slice.actions;
export default slice.reducer;
