import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getGAAPAccountingBalanceSheetService } from "../../../../services/gaap-accounting/gaap-accounting";
import error from "../../../../utils/error";
import {
  IGetGAAPAccountingBalanceSheetParameter,
  getGAAPAccountingBalanceSheetState,
} from ".";

const initialState: getGAAPAccountingBalanceSheetState = {
  data: undefined,
  loading: "loading",
  error: undefined,
  total: {
    total: 0,
    TotalAssets: 0,
    TotalLiabilities: 0,
    TotalEquity: 0,
  },
};

export const getGAAPAccountingBalanceSheetThunk = createAsyncThunk(
  "gaapAccounting/getGAAPAccountingBalanceSheet",
  async (data: IGetGAAPAccountingBalanceSheetParameter) => {
    try {
      const response = await getGAAPAccountingBalanceSheetService(data);

      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getGAAPAccountingBalanceSheet",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getGAAPAccountingBalanceSheetThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      getGAAPAccountingBalanceSheetThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data?.[0];
        if (action.payload._response.data?.[0]) {
          const obj = action.payload._response.data?.[0];
          state.total.total =
            obj.totalCashBank + obj.tobeReceived + obj.tobePaidOut;
        }
      }
    );
    builder.addCase(
      getGAAPAccountingBalanceSheetThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // errorFundlogo
        // action.payload contains error information
        state.error = error(action.payload);
        // state.error = action.payload
      }
    );
  },
});

export default slice.reducer;
