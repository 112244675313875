import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

// import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../../components/loader/Loader";
import { GetFundLevelReconcileIncomeThunk } from "../../../../store/features/taxmanagement/1099/interestincomeatfundlevel/getfundlevelreconcileincome/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import { exportExcel } from "../../../../utils/excel-dataformat";
// import dateFormatter from "../../../../utils/date-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function InterestIncomeAtFundLevel() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  // const [getMenuListDownload, setGetMenuListDownload] = useState<any>([]);
  const previousYear = new Date().getFullYear();
  const currentYear = previousYear - 1;
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const GetMenuList = useAppSelector(
    (state) => state.GetFundLevelReconcileIncome?.data
  );
  const [stateName, setName] = useState<any>(false);
  const [loading, setloading] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);

  const formik = useFormik({
    initialValues: {
      Date: currentYear.toString(),
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter year";
      }

      return errors;
    },
    onSubmit: async () => {
      setloading(true);
      const params = {
        LegalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };
      try {
        await dispatch(GetFundLevelReconcileIncomeThunk(params));
        setName(false);
        setloading(false);
      } catch (error) {
        // Handle error if needed
        console.log(error);
        setloading(false);
      } finally {
        setloading(false);
      }
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  // useEffect(() => {
  //   document.body.classList.add("no-scroll");
  //   document.body.style.overflowX = "unset";
  //   document.documentElement.style.overflowX = "unset";
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //     document.body.style.overflowX = "hidden";
  //     document.documentElement.style.overflowX = "hidden";
  //   };
  // }, []);

  const dataTableElements = [
    {
      field: "supplierName",
      header: "Supplier Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.supplierName}>
          {rowData?.supplierName}
        </div>
      ),
    },
    {
      field: "january",
      header: "January",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) =>
      //   currencyFormat(Number.parseFloat(rowData.january)), // Pass rowData to editButton
    },
    {
      field: "february",
      header: "February",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) =>
      //   currencyFormat(Number.parseFloat(rowData.february)), // Pass rowData to editButton
    },
    {
      field: "march",
      header: "March",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) => currencyFormat(Number.parseFloat(rowData.march)), // Pass rowData to editButton
    },
    {
      field: "april",
      header: "April",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) => currencyFormat(Number.parseFloat(rowData.april)), // Pass rowData to editButton
    },
    {
      field: "may",
      header: "May",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) => currencyFormat(Number.parseFloat(rowData.may)), // Pass rowData to editButton
    },
    {
      field: "june",
      header: "June",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) => currencyFormat(Number.parseFloat(rowData.june)), // Pass rowData to editButton
    },
    {
      field: "july",
      header: "July",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) => currencyFormat(Number.parseFloat(rowData.july)), // Pass rowData to editButton
    },
    {
      field: "august",
      header: "August",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) => currencyFormat(Number.parseFloat(rowData.august)), // Pass rowData to editButton
    },
    {
      field: "september",
      header: "September",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) =>
      //   currencyFormat(Number.parseFloat(rowData.september)), // Pass rowData to editButton
    },
    {
      field: "october",
      header: "October",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) =>
      //   currencyFormat(Number.parseFloat(rowData.october)), // Pass rowData to editButton
    },
    {
      field: "november",
      header: "November",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) =>
      //   currencyFormat(Number.parseFloat(rowData.november)), // Pass rowData to editButton
    },
    {
      field: "december",
      header: "December",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) =>
      //   currencyFormat(Number.parseFloat(rowData.december)), // Pass rowData to editButton
    },
    {
      field: "totalIncome",
      header: "Total Income",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) =>
      //   currencyFormat(Number.parseFloat(rowData.totalIncome)), // Pass rowData to editButton
    },
    {
      field: "box1Amount",
      header: "Box1 Amount",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) =>
      //   currencyFormat(Number.parseFloat(rowData.box1Amount)), // Pass rowData to editButton
    },
    {
      field: "delta",
      header: "Delta",
      style: { width: "210px", minWidth: "180px" },

      // body: (rowData: any) => currencyFormat(Number.parseFloat(rowData.delta)), // Pass rowData to editButton
    },
  ];
  const exportExcelHeaderElement = [
    {
      field: "supplierName",
      header: "Supplier Name",
    },
    {
      field: "january",
      header: "January",
    },
    {
      field: "february",
      header: "February",
    },
    {
      field: "march",
      header: "March",
    },
    {
      field: "april",
      header: "April",
    },
    {
      field: "may",
      header: "May",
    },
    {
      field: "june",
      header: "June",
    },
    {
      field: "july",
      header: "July",
    },
    {
      field: "august",
      header: "August",
    },
    {
      field: "september",
      header: "September",
    },
    {
      field: "october",
      header: "October",
    },
    {
      field: "november",
      header: "November",
    },
    {
      field: "december",
      header: "December",
    },
    {
      field: "totalIncome",
      header: "Total Income",
    },
    {
      field: "box1Amount",
      header: "Box1 Amount",
    },
    {
      field: "delta",
      header: "Delta",
    },
  ];
  // console.log("GetMenuList", GetMenuList?.[0]);

  // useEffect(() => {
  //   setName(true);
  // }, [formik.values.Date]);
  const dataList: any[] = GetMenuList!?.length > 0 ? [...GetMenuList!] : [];
  console.log("DataList", dataList);
  const list = dataList?.map((element: any) => {
    const obj = { ...element };
    console.log("OBJECTHERE", obj);
    if (obj?.january !== "") {
      obj.january = +obj?.january;
      obj.january = currencyFormat(obj.january);
    }
    if (obj?.february !== "") {
      obj.february = +obj?.february;
      obj.february = currencyFormat(obj.february);
    }
    if (obj?.march !== "") {
      obj.march = +obj?.march;
      obj.march = currencyFormat(obj.march);
    }
    if (obj?.april !== "") {
      obj.april = +obj?.april;
      obj.april = currencyFormat(obj.april);
    }
    if (obj?.may !== "") {
      obj.may = +obj?.may;
      obj.may = currencyFormat(obj.may);
    }
    if (obj?.june !== "") {
      obj.june = +obj?.june;
      obj.june = currencyFormat(obj.june);
      console.log("June", obj.june);
    }
    if (obj?.july !== "") {
      obj.july = +obj?.july;
      obj.july = currencyFormat(obj.july);
    }
    if (obj?.august !== "") {
      obj.august = +obj?.august;
      obj.august = currencyFormat(obj.august);
    }
    if (obj?.september !== "") {
      obj.september = +obj?.september;
      obj.september = currencyFormat(obj.september);
    }
    if (obj?.october !== "") {
      obj.october = +obj?.october;
      obj.october = currencyFormat(obj.october);
    }
    if (obj?.november !== "") {
      obj.november = +obj?.november;
      obj.november = currencyFormat(obj.november);
    }
    if (obj?.december !== "") {
      obj.december = +obj?.december;
      obj.december = currencyFormat(obj.december);
    }
    if (obj?.totalIncome !== "") {
      obj.totalIncome = +obj?.totalIncome;
      obj.totalIncome = currencyFormat(obj.totalIncome);
    }
    if (obj?.box1Amount !== "") {
      obj.box1Amount = +obj?.box1Amount;
      obj.box1Amount = currencyFormat(obj.box1Amount);
    }
    if (obj?.delta !== "") {
      obj.delta = +obj?.delta;
      obj.delta = currencyFormat(obj.delta);
    }
    return obj;
  });
  console.log("List", list);

  useEffect(() => {
    // formik.resetForm();

    setName(true);
    setloading(true);
    const params = {
      LegalEntityId: fundDropdown.fundid,
      Year: formik.values.Date,
    };
    try {
      dispatch(GetFundLevelReconcileIncomeThunk(params));
      setName(false);
      setloading(false);
    } catch (error) {
      // Handle error if needed
      console.log(error);
      setloading(false);
    } finally {
      setloading(false);
    }
    formik.resetForm();
    // console.log("currentYear", currentYear, formik.values.Date);
  }, [fundDropdown.fundid]);
  useEffect(() => {
    // formik.resetForm();
    console.log(
      "inside--",
      typeof formik.values.Date,
      typeof currentYear.toString()
    );

    if (formik.values.Date.toString() === currentYear.toString()) {
      console.log("inside");
      setloading(true);
      const params = {
        LegalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };
      try {
        dispatch(GetFundLevelReconcileIncomeThunk(params));
        setName(false);
        setloading(false);
      } catch (error) {
        // Handle error if needed
        console.log(error);
        setloading(false);
      } finally {
        setloading(false);
      }
    } else {
      setName(true);
    }
  }, [formik.values.Date]);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">
              1099: Interest income at fund level
            </h1>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(
                  exportExcelHeaderElement,
                  list,
                  "1099: Interest income at fund level",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                list === undefined || list?.length === 0 || list === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  list === undefined || list?.length === 0 || list === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card">
            {/* <Card> */}
            <>
              <div className="formgrid grid">
                <div className="d-block sm:d-flex sm:flex-column mr-4 md:col-6 col-12 p-1 mt-2 ml-2">
                  <div className=" d-flex justify-content-center">
                    <Calendar
                      name="Date"
                      value={
                        formik.values.Date === null || formik.values.Date === ""
                          ? undefined
                          : new Date(+formik.values.Date, 0, 1)
                      }
                      onChange={(e) => {
                        const selectedYear = new Date(
                          Number(e.value)
                        ).getFullYear();
                        formik.setFieldValue("Date", selectedYear);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("Date"),
                        "card-inputBox": true,
                      })}
                      placeholder="Select year"
                      view="year"
                      dateFormat="yy"
                    />
                    {getFormErrorMessage("Date")}
                  </div>
                </div>
                <div className="mt-2 p-1">
                  <Button
                    className="mr-4 btn-nav w-7rem justify-content-center"
                    onClick={(e) => {
                      e.preventDefault();
                      formik.resetForm();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="btn-nav mr-4 w-7rem justify-content-center"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("clicked");
                      formik.handleSubmit();
                    }}
                  >
                    Show
                  </Button>
                </div>
              </div>
              {/* </div> */}
            </>
            <div className="mt-2">
              {GetMenuList && !stateName && (
                <DataTableComponent
                  valueData={list!}
                  fieldsElements={dataTableElements}
                  isPaginationAllowed={true}
                  // isDownload={true}
                  fileName="FundIncome"
                  scrollable={true}
                  className="gridcell"
                  columnResizeMode="expand"
                  maxHeight={"calc(-225px + 100vh)"}
                  isFullView={true}
                  setOpenDailog={setOpenDailog}
                  openDailog={openDailog}
                />
              )}
              {/* </Card> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
