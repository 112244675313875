import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { RiDragMoveFill } from "react-icons/ri";
import Loader from "../../../../../components/loader/Loader";
import {
  getThunkSectionById,
  setSection,
} from "../../../../../store/features/investment/update/section/create/slice";
import {
  bulkUpdateIndexThunkSection,
  getThunkSectionProductById,
} from "../../../../../store/features/investment/update/section/list/slice";
import { setPageName } from "../../../../../store/features/investment/update/update-page/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../../utils/toast-message";
import { CreateSection } from "./CreateSection";
import { ViewSection } from "./ViewSection";

interface IProps {
  pageName: string;
  clickPageName?: string;
}

export const FormContent = (props: IProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const sectionState = useAppSelector((state) => state.addSection.data);
  const [visibleView, setVisibleView] = useState(false);
  const toast = useRef<Toast>(null);
  const pageData = useAppSelector((state) => state.updatePage.data);
  const [sections, setSections] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const stateInvestment = useAppSelector((state) => state.addInvestment);
  const [listObj, setListObj] = useState<any[]>();
  const [activeSectionId, setActiveSectionId] = useState<any>();

  const updateAllIndex = (data: any) => {
    console.log("da", data);
    try {
      dispatch(bulkUpdateIndexThunkSection(data)).then((response) => {
        console.log("response", response);
        if (response.meta.requestStatus === "rejected") {
          errorToastMessage(toast);
        } else if (response.meta.requestStatus === "fulfilled") {
          successToastMessage(toast, "Section saved");
          const reqBody = {
            ProductId: stateInvestment.data?.productID,
            SectionId: 0,
          };
          dispatch(getThunkSectionProductById(reqBody)).then((res: any) => {
            if (res.payload.success) {
              setListObj(res.payload.data);
              setSections(res.payload.data);
              setVisibleView(false);
            } else {
              console.error(res.payload.message);
            }
          });
        }
      });
    } catch (error) {
      console.log("Opps something went wrong", error);
    }
  };

  const updateFileIndex = (dataSection: any) => {
    console.log("before", dataSection);
    const afterrearrangeSection = JSON.parse(JSON.stringify(dataSection));
    if (dataSection !== undefined) {
      for (let i = 0; i < dataSection?.length; ++i) {
        afterrearrangeSection[i]!.itemIndex = i + 1;
      }
    }
    if (afterrearrangeSection) {
      updateAllIndex(afterrearrangeSection);
    }
  };

  const handleDragStart = (e: any, id: number) => {
    e.dataTransfer.setData("imageId", id);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    const imageId = e.dataTransfer.getData("imageId");
    const draggedImage = listObj?.find(
      (image) => image.id.toString() === imageId
    );
    const dropzoneId = e.currentTarget.dataset.id;
    const dropzoneIndex = listObj?.findIndex(
      (image) => image.id.toString() === dropzoneId
    );
    const newFile = listObj?.filter((image) => image.id.toString() !== imageId);
    newFile?.splice(dropzoneIndex!, 0, draggedImage);
    setListObj(newFile);
    console.log("drop", newFile);
    updateFileIndex(newFile);
  };

  const resetForm = () => {
    dispatch(
      setSection({
        ...sectionState,
        id: 0,
        productId: 0,
        sectionTitle: "",
        sectionContent: "",
        createdBy: 0,
      })
    );
  };

  const updatePage = (name: any, subName?: any) => {
    dispatch(
      setPageName({ ...pageData, pageName: name, subPageName: subName })
    );
  };

  const handleEdit = (id: number, index: any) => {
    dispatch(getThunkSectionById(id));
    setActiveSectionId(id);
    setActiveIndex(index);
    updatePage("Content", "Edit");
  };

  const handleView = (id: number, index: number) => {
    setActiveSectionId(id);
    setActiveIndex(index);
    setVisibleView(true);
    setLoading(true);

    const selectedSection = sections[index];
    const reqBody = {
      ProductId: selectedSection.productId,
      SectionId: selectedSection.id,
    };

    dispatch(getThunkSectionProductById(reqBody))
      .then((res: any) => {
        if (res.payload.data) {
          setSections((prevSections: any) => {
            const updatedSections = prevSections.map(
              (section: any, idx: number) => {
                if (idx === index) {
                  return {
                    ...section,
                    sectionContent: res.payload.data[0].sectionContent,
                  };
                }
                return section;
              }
            );
            return updatedSections;
          });
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (pageData.subPageName) {
      const fetchSections = async () => {
        const reqBody = {
          ProductId: stateInvestment.data?.productID,
          SectionId: 0,
        };
        try {
          const response: any = await dispatch(
            getThunkSectionProductById(reqBody)
          );
          if (response.payload.success) {
            setListObj(response.payload.data);
            setSections(response.payload.data);
          } else {
            console.error(response.payload.message);
          }
        } catch (error) {
          console.error("Failed to fetch sections", error);
        } finally {
          setLoading(false);
        }
      };

      fetchSections();
    }
  }, [pageData.offering, pageData.pageName, pageData.subPageName]);

  const handleTabChange = async (e: any) => {
    const newActiveIndex = e.index;
    setActiveIndex(newActiveIndex);
    setLoading(true);

    const selectedSection = sections[newActiveIndex];
    const reqBody = {
      ProductId: selectedSection.productId,
      SectionId: selectedSection.id,
    };

    try {
      const response: any = await dispatch(getThunkSectionProductById(reqBody));
      if (response.payload.data) {
        setSections((prevSections: any) => {
          const updatedSections = prevSections.map(
            (section: any, idx: number) => {
              if (idx === newActiveIndex) {
                return {
                  ...section,
                  sectionContent: response.payload.data[0].sectionContent,
                };
              }
              return section;
            }
          );
          return updatedSections;
        });
        setLoading(false);
      } else {
        console.error(response.payload.message);
      }
    } catch (error) {
      console.error("Failed to fetch section content", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updatePage("Content", "List");
  }, []);

  let content;
  switch (pageData.subPageName) {
    case "Create":
      content = (
        <CreateSection
          pageName={pageData.subPageName}
          visibleView={visibleView}
          setVisibleView={setVisibleView}
          setActiveSectionId={setActiveSectionId}
          activeSectionId={activeSectionId}
        />
      );
      break;
    case "Edit":
      content = (
        <CreateSection
          pageName={pageData.subPageName}
          visibleView={visibleView}
          setVisibleView={setVisibleView}
          setActiveSectionId={setActiveSectionId}
          activeSectionId={activeSectionId}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          product={stateInvestment.data?.productID}
          setSect={setSections}
        />
      );
      break;
    case "List":
      content = (
        <form>
          <div className="mt-2">
            <label className="inputLabel inputLabelColor ml-2">Sections:</label>
            <div
              className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
              style={{ height: "auto" }}
            >
              <div className="flex flex-row flex-wrap sm:flex-none col-12 ml-3">
                <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                  <Button
                    className="btn-dialog"
                    onClick={(e) => {
                      e.preventDefault();
                      resetForm();
                      setActiveSectionId(0);
                      updatePage("Content", "Create");
                    }}
                  >
                    <span className="ml-auto mr-auto">Create New</span>
                  </Button>
                </div>
              </div>
              {listObj?.map((data, index) => (
                <div
                  className="row col-12 ml-4 pb-2"
                  key={data.id}
                  data-id={data.id}
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, data.id)}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <div className="col-md-11 col-sm-10 p-2 flex flex-col border border-dark">
                    <div className="col-md-9 col-sm-9">
                      <div className="propName flex align-items-center text-wrap-balance">
                        {data.sectionTitle}
                      </div>
                    </div>
                    <div className="button flex justify-content-around md:justify-content-end text-center col-md-3 col-sm-3">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleView(data.id, index); // Pass the index as well
                        }}
                        className="btn-content"
                      >
                        View
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleEdit(data.id, index);
                        }}
                        className="btn-content"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                  <div className="col-md-1 col-sm-2 flex align-items-center">
                    <Button
                      onClick={(e: any) => e.preventDefault()}
                      onDragStart={(e) => handleDragStart(e, data.id)}
                      className="btn-drag dragging p-0"
                      style={{ cursor: "grab" }}
                    >
                      <RiDragMoveFill />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </form>
      );
      break;
  }

  const handleClose = () => {
    const reqBody = {
      ProductId: stateInvestment.data?.productID,
      SectionId: 0,
    };
    dispatch(getThunkSectionProductById(reqBody)).then((response: any) => {
      if (response.payload.success) {
        setListObj(response.payload.data);
        setSections(response.payload.data);
        setVisibleView(false);
      } else {
        console.error(response.payload.message);
      }
    });
  };

  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <ViewSection
        visibleView={visibleView}
        setVisibleView={setVisibleView}
        setActiveSectionId={setActiveSectionId}
        activeSectionId={activeSectionId}
        activeIndex={activeIndex}
        handleTabChange={handleTabChange}
        sections={sections}
        onHide={handleClose}
      />

      {content}
    </>
  );
};
