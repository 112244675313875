import { Button } from "primereact/button";
// import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import React, { useEffect, useRef, useState } from "react";
import "./ViewInvestmentDetails.scss";

import { useNavigate, useParams } from "react-router-dom";
// import { getThunkAllInvestmentByUserId } from "../../../../store/features/investment/all-list/slice";
import Loader from "../../../../components/loader/Loader";
import { getThunkInvestmentById } from "../../../../store/features/investment/create/slice";
// import { setSideNavPanel } from "../../../../store/features/investment/update/side-nav/slice";
import { setPageName } from "../../../../store/features/investment/update/update-page/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import FormDetails from "../../common-component/form-component/ContinuousFormDetails";
import { FormDocument } from "../../common-component/form-component/ContinuousFormDocument";
import { FormImages } from "../../common-component/form-component/ContinuousFormImages";
import { FormContent } from "./content-tab/FormContent";
import FormProjectUpdate from "./project-update-tab/FormProjectUpdate";

// import { CreateStep7 } from "../create/create-forms/CreateStep7";

interface IProps {
  id: string;
  setOffering: any;
}
export default function ViewInvestmentDetails(props: IProps) {
  const { id } = useParams();
  const navigate = useNavigate();
  const firstUpdate = useRef(true);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const stateInvestment = useAppSelector((state) => state.addInvestment);
  // const [updateName, setUpdateName] = useState("");
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const pageUpdateName = useAppSelector((state) => state.updatePage.data);
  React.useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    navigate("/Continuous/ManageOffering");
  }, [fundDropdown.fundid]);
  useEffect(() => {
    setLoading(true);
    dispatch(getThunkInvestmentById(+id!)).finally(() => {
      setLoading(false);
    });
  }, [id]);

  const updatePageNameClick = (name: any) => {
    // console.log("useeffect 1");
    console.log("data", name);
    console.log("ad", pageUpdateName.subPageName);
    if (
      pageUpdateName.subPageName === undefined ||
      pageUpdateName.subPageName === "List" ||
      pageUpdateName.subPageName === ""
    ) {
      console.log("ds", pageUpdateName);

      dispatch(setPageName({ ...pageUpdateName, pageName: name }));
    } else {
      // const data = {
      //   pageName: name,
      //   openDialog: true,
      // };
      console.log("dss");
      // setUpdateName(name);
      dispatch(
        setPageName({
          ...pageUpdateName,
          clickPageName: name,
          openDialog: true,
        })
      );
    }
  };

  useEffect(() => {
    updatePageNameClick("OfferingInfo");
  }, []);

  let content;
  switch (pageUpdateName.pageName) {
    case "OfferingInfo":
      content = <FormDetails pageName="viewDetails" />;
      break;
    case "Documents":
      content = <FormDocument pageName="viewDetails" />;
      break;
    case "Images":
      content = <FormImages pageName="ViewDetails" />;
      break;
    case "Content":
      content = <FormContent pageName={""} />;
      break;
    case "ProjectUpdate":
      content = <FormProjectUpdate />;
      break;
  }
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <div className=" h-100 center-card">
        <div className="col-12 p-0">
          <div className="card my-2">
            <Card>
              <div className="headercard d-flex grid">
                <div className="col-12 md:col-6 sm:col-6 pl-2 p-2">
                  <h2 className="m-0">{stateInvestment.data?.productName}</h2>
                </div>
                <div className="col-12 md:col-6 sm:col-6 p-1 end-div">
                  <h4 className="m-0">
                    {stateInvestment.data?.supplierProductCode}
                  </h4>
                  <div className="bagde-btn bagde-btn-bgf ml-2 text-dark fs-5 p-3">
                    {stateInvestment.data?.isPooledFund
                      ? "Continuous"
                      : "Fixed"}
                  </div>
                </div>
              </div>
              <div className="grid">
                <div className="flex flex-row flex-wrap p-1 m-0 sm:flex-none col-12">
                  <div className="flex flex-wrap xl:col-10 p-0 sm:col-10 ">
                    <div className="flex pr-0 xl:col-2 md:col-3 sm:col-1 col-12">
                      <Button
                        className={
                          pageUpdateName.pageName === "OfferingInfo"
                            ? "btn-pageActive buttonWidth "
                            : "btn-page buttonWidth"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          updatePageNameClick("OfferingInfo");
                        }}
                      >
                        <span className="ml-auto mr-auto ">Offering Info</span>
                      </Button>
                    </div>
                    <div className="flex pr-0 xl:col-2 md:col-3 sm:col-1 col-12">
                      <Button
                        className={
                          pageUpdateName.pageName === "Content"
                            ? "btn-pageActive buttonWidth  "
                            : "btn-page buttonWidth "
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          updatePageNameClick("Content");
                        }}
                      >
                        <span className="ml-auto mr-auto ">Content</span>
                      </Button>
                    </div>
                    <div className="flex pr-0 xl:col-2 md:col-3 sm:col-2 col-12">
                      <Button
                        className={
                          pageUpdateName.pageName === "Documents"
                            ? "btn-pageActive  buttonWidth md:p-2 "
                            : "btn-page buttonWidth md:p-2"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          updatePageNameClick("Documents");
                        }}
                      >
                        <span className="ml-auto mr-auto ">Documents</span>
                      </Button>
                    </div>
                    <div className="flex pr-0 xl:col-2 md:col-3 sm:col-2 col-12">
                      <Button
                        className={
                          pageUpdateName.pageName === "Images"
                            ? "btn-pageActive  buttonWidth"
                            : "btn-page buttonWidth"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          updatePageNameClick("Images");
                        }}
                      >
                        <span className="ml-auto mr-auto">Images/ Videos</span>
                      </Button>
                    </div>

                    <div className="flex pr-0 xl:col-2 md:col-3 sm:col-2 col-12">
                      <Button
                        className={
                          pageUpdateName.pageName === "ProjectUpdate"
                            ? "btn-pageActive  buttonWidth"
                            : "btn-page buttonWidth"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          updatePageNameClick("ProjectUpdate");
                        }}
                      >
                        <span className="ml-auto mr-auto">Project Update</span>
                      </Button>
                    </div>
                  </div>
                  <div className="d-flex flex-column xl:col-2 md:col-3 sm:col-2 col-12 pr-0">
                    <div className="flex justify-content-end ">
                      <Button
                        className="btn-page p-3 w-100"
                        // type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          localStorage.setItem(
                            "navigationPage",
                            "Manage Offering"
                          );
                          id !== undefined &&
                            navigate(`../../OpportunityDetails/${+id}`);
                        }}
                      >
                        <span className="ml-auto mr-auto ">
                          View Investor View
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="m-0" />

              <div className="grid my-3">
                <div className="xl:flex md:flex lg:flex sm:flex-none p-0 col-12">
                  <div className="xl:col-12 sm:col-12 p-0">{content}</div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
