import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { RiAddFill } from "react-icons/ri";
import DataTableComponent from "../../../../../components/data-table/DataTableComponent";

import Loader from "../../../../../components/loader/Loader";
import { goToTop } from "../../../../../components/scroll-to/ScrollTo";
import { getThunkLookupCode } from "../../../../../store/features/dropdown/lookup-code/slice";
import { getThunkSupplier } from "../../../../../store/features/dropdown/supplier/slice";
import { getThunkAgreementDropDown } from "../../../../../store/features/e-sign/setup-e-sign/agreement-dropdown/slice";
import { addThunkSetupEsign } from "../../../../../store/features/e-sign/setup-e-sign/setup-create/slice";
import { getThunkSetupEsignList } from "../../../../../store/features/e-sign/setup-e-sign/setup-list/slice";
import { getThunkInvestmentByFilter } from "../../../../../store/features/investment/investment-filter/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { getSasKeyThunk } from "../../../../../store/sas-key/slice";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../../utils/toast-message";

export default function StepupESign() {
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const [setupEsignId, setSetupEsignId] = useState<any>();
  const [loading, setLoading] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const supplierDropDown = useAppSelector((state) => state.supplierList.data);
  const [openDialog, setOpenDialog] = useState(false);
  const filterInvestment = useAppSelector(
    (state) => state.investmentByFilter.data
  );
  const [templateLink, setTemplateLink] = useState<any>("");
  const [add, setAdd] = useState(false);
  const [showTab, setShowTab] = useState(true);
  const [edit, setEdit] = useState(false);
  const agreementType = useAppSelector((state) => state.agreementDropDown.data);
  const lookupDropDownState = useAppSelector(
    (state) => state.lookupDropDown.data
  );
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const setUpEsignList = useAppSelector((state) => state.setupESign.data!);
  const supplierDropdownList: any = [];
  const [openDailog, setOpenDailog] = useState(false);

  supplierDropDown?.map((item: any) => {
    return supplierDropdownList.push({
      name: item.supplierName,
      code: item.supplierID,
    });
  });

  const investmentDropDown: any = [];

  filterInvestment?.map((item: any) => {
    return investmentDropDown.push({
      name: item.productName,
      code: item.productID,
    });
  });

  const getAllSetupEsignList = () => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameter = {
        legalEntityId: fundDropdown.fundid,
      };

      dispatch(getThunkSetupEsignList(parameter)).then(() => {
        setLoading(false);
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      supplierID: 0,
      supplierProductCode: "",
      productID: 0,
      agreementType: 0,
      templateId: "",
      documentName: "",
    },
    validate: (data) => {
      const errors: any = {};
      if (!data.supplierID) {
        errors.supplierID = " Supplier is required";
      }
      if (!data.supplierProductCode) {
        errors.supplierProductCode = "Lookup code is required";
      }

      if (!data.productID) {
        errors.productID = "Investment is required";
      }

      if (!data.agreementType) {
        errors.agreementType = "Agreement type is required";
      }
      if (!data.templateId) {
        errors.templateId = "TemplateId is required";
      }
      if (!data.documentName) {
        errors.documentName = "Document name is required";
      }
      return errors;
    },
    onSubmit: () => {
      // console.log("p", productIdFromFilter);
      setLoading(true);
      const dataSetupEsign = {
        id: setupEsignId || 0,
        templateId: formik.values.templateId,
        documentName: formik.values.documentName,
        legalEntityId: fundDropdown.fundid,
        active: true,
        prodAgreementTypeId: formik.values.agreementType,
        productId: formik.values.productID,
        createdBy: profileData?.userID,
        dualSignerEnabled: false,
        ppmSignerEnabled: false,
      };

      dispatch(addThunkSetupEsign(dataSetupEsign))
        .then((response) => {
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (response.meta.requestStatus === "fulfilled") {
            console.log("hello", response);
            if (response.payload.success === false) {
              errorToastMessage(toast, "Template Already Exist");
            } else if (response.payload.success === true) {
              successToastMessage(toast, "Data is saved successfully");
              getAllSetupEsignList();
              setAdd(false);
              setEdit(false);
              setShowTab(true);
              formik.resetForm();
              setSetupEsignId(0);
            }
          }
        })
        .catch((error: any) => {
          console.log("error", error);
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const onAddClick = () => {
    formik.resetForm();
    setAdd(true);
    setEdit(false);
    setShowTab(true);
  };

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = (id: any) => {
      console.log("product", rowData);
      formik.setFieldValue("supplierID", rowData.supplierId);
      formik.setFieldValue("productID", rowData.productId);
      formik.setFieldValue("agreementType", rowData.agreementId);
      formik.setFieldValue("supplierProductCode", rowData.supplierProductCode);
      formik.setFieldValue("templateId", rowData.templateId);
      formik.setFieldValue("documentName", rowData.documentName);
      setSetupEsignId(rowData.id);
      setAdd(false);
      setEdit(true);
      setShowTab(false);
      goToTop();
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            disabled={rowData.productState === "Exited"}
            onClick={() => handleEdit(rowData.Id)}
          >
            <span className="text-white">Edit</span>
          </Button>
        </div>
      </div>
    );
  };
  const viewTemplateButton = (rowData: any) => {
    const handleView = () => {
      console.log("tets", rowData);
      setTemplateLink("");

      if (
        rowData.documentLink &&
        rowData.documentLink.includes("blob.core.windows.net")
      ) {
        setLoading(true);
        dispatch(getSasKeyThunk(fundDropdown.fundid.toString()))
          .then((response) => {
            if (response.meta.requestStatus === "fulfilled") {
              setTemplateLink(
                rowData.documentLink + response.payload.data.sasKey
              );
              setOpenDialog(true);
              // return response.payload.data.sasKey;
            }
          })
          .catch(() => {
            errorToastMessage(toast);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        errorToastMessage(toast, "Template not found");
      }
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            disabled={!rowData.documentLink}
            onClick={() => handleView()}
          >
            <span className="text-white">View Template</span>
          </Button>
        </div>
      </div>
    );
  };

  console.log("templateLink", templateLink);

  const dataTableElements = [
    {
      field: "templateId",
      header: "Template Id",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.templateId}>
          {rowData?.templateId}
        </div>
      ),
    },
    {
      field: "documentName",
      header: "Document Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.documentName}>
          {rowData?.documentName}
        </div>
      ),
    },
    // {
    //   field: "legalEntityName",
    //   header: "Fund Name",
    // },
    {
      field: "agreementName",
      header: "Agreement Type",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.agreementName}>
          {rowData?.agreementName}
        </div>
      ),
    },
    {
      field: "productName",
      header: "Investment Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.productName}>
          {rowData?.productName}
        </div>
      ),
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.supplierProductCode}>
          {rowData?.supplierProductCode}
        </div>
      ),
    },
    {
      header: "",
      body: viewTemplateButton,
      style: { width: "210px", minWidth: "180px" },
    },
    {
      // field: "Edit",
      header: "",
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
      style: { width: "210px", minWidth: "180px" },
    },
  ];

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "supplierID") {
      console.log("inside");
      formik.setFieldValue("supplierProductCode", "");
      formik.setFieldValue("productID", 0);
    }
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      formik.resetForm();
      const parameter = {
        legalEntityId: fundDropdown.fundid,
      };
      setLoading(true);
      getAllSetupEsignList();
      dispatch(getThunkSupplier(parameter));
      dispatch(getThunkAgreementDropDown());
    }
  }, [fundDropdown]);
  useEffect(() => {
    if (
      fundDropdown.fundid !== undefined &&
      fundDropdown.fundid > 0 &&
      formik.values.supplierID !== 0
    ) {
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        supplierId:
          formik.values.supplierID === 0 ? 0 : formik.values.supplierID,
      };

      dispatch(getThunkLookupCode(parameter));

      const parameters =
        formik.values.supplierProductCode !== ""
          ? {
              legalEntityId: fundDropdown.fundid,
              supplierId: formik.values.supplierID,
              productState: "",
              lookUpCode: formik.values.supplierProductCode,
            }
          : {
              legalEntityId: fundDropdown.fundid,
              supplierId: formik.values.supplierID,
              productState: "",
              // lookUpCode: formik.values.supplierProductCode ?
            };

      dispatch(getThunkInvestmentByFilter(parameters));
    }
  }, [
    fundDropdown,
    formik.values.supplierID,
    formik.values.supplierProductCode,
  ]);

  useEffect(() => {
    const product = filterInvestment.find(
      (pro) => pro.supplierProductCode === formik.values.supplierProductCode
    );
    console.log("pto", product);
    if (product) {
      formik.setFieldValue("productID", product?.productID);
    }
  }, [formik.values.supplierProductCode]);

  useEffect(() => {
    const product = filterInvestment.find(
      (pro) => pro.productID === formik.values.productID
    );
    if (product) {
      formik.setFieldValue("supplierProductCode", product?.supplierProductCode);
    }
  }, [formik.values.productID]);
  const handleCancel = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading === true ? <Loader /> : <></>}
        <Dialog
          visible={openDialog}
          onHide={() => handleCancel()}
          headerClassName="no-header"
          contentClassName="top-lr-content"
          className="dialogImageFix"
        >
          <iframe
            src={templateLink}
            allowFullScreen
            style={{ width: "100%", height: "80vh" }}
          ></iframe>
        </Dialog>
        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Setup e-Sign on Demand</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                setUpEsignList === undefined ||
                setUpEsignList?.length === 0 ||
                setUpEsignList === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>

        <div className="grid col-12">
          <div className="col-12">
            <div className="card my-3 tabWithoutboxshadow">
              <Card>
                <div className="grid justify-content-start">
                  {" "}
                  {!add ||
                    (edit && <div className="tabHeader-wrap mb-3 col-6"></div>)}
                  {showTab && (
                    // <div className="tabHeader-wrap mb-3   col-6">
                    <Button
                      onClick={onAddClick}
                      className="btn-navActive mr-0 m-2"
                    >
                      <span className="ml-auto mr-auto ">
                        {" "}
                        Add Template{" "}
                        <span className="pl-2">
                          <RiAddFill />
                        </span>
                      </span>
                    </Button>
                    // </div>
                  )}
                  {edit && (
                    <div className="tabHeader-wrap mb-3 col-6">
                      <span className="tabHeadText Active"> Edit Template</span>
                    </div>
                  )}
                </div>
                {add || edit ? (
                  <>
                    <div className="">
                      <div className="formgrid grid px-2">
                        <div className="d-block sm:d-flex sm:flex-column  col-3 sm:col-6">
                          <label className="inputLabel">Supplier *</label>

                          <Dropdown
                            inputId="supplierID"
                            name="supplierID"
                            value={formik.values.supplierID}
                            options={
                              supplierDropdownList === null
                                ? []
                                : supplierDropdownList
                            }
                            filter
                            optionLabel="name"
                            optionValue="code"
                            placeholder="Select Supplier"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("supplierID"),
                              "card-inputBox": true,
                            })}
                          />
                          {getFormErrorMessage("supplierID")}
                        </div>

                        <div className="d-block sm:d-flex sm:flex-column  col-3 sm:col-6">
                          <label className="inputLabel">Lookup code *</label>
                          <Dropdown
                            inputId="supplierProductCode"
                            name="supplierProductCode"
                            value={
                              formik.values.supplierProductCode === null
                                ? ""
                                : formik.values.supplierProductCode
                            }
                            options={
                              lookupDropDownState === null
                                ? []
                                : lookupDropDownState?.lookupDetails
                            }
                            filter
                            optionLabel="value"
                            optionValue="value"
                            placeholder="Select Lookup code"
                            // className="card-inputBox"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid(
                                "supplierProductCode"
                              ),
                              "card-inputBox": true,
                            })}
                          />
                          {getFormErrorMessage("supplierProductCode")}
                        </div>

                        <div className="d-flex flex-column  col-3 sm:col-6 phoneInput">
                          <label className="inputLabel">Investment *</label>
                          <Dropdown
                            inputId="productID"
                            name="productID"
                            value={
                              formik.values.productID === 0
                                ? ""
                                : formik.values.productID
                            }
                            options={
                              investmentDropDown === null
                                ? []
                                : investmentDropDown
                            }
                            filter
                            optionLabel="name"
                            optionValue="code"
                            placeholder="Select Investment"
                            // className="card-inputBox"
                            onChange={(e) => {
                              handleChange(e);
                              // formik.setFieldValue("productName", e.target.value);
                            }}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("productID"),
                              "card-inputBox": true,
                            })}
                          />
                          {getFormErrorMessage("productID")}
                        </div>

                        <div className="d-block sm:d-flex sm:flex-column  col-3 sm:col-6">
                          <label className="inputLabel">Agreement Type *</label>

                          <Dropdown
                            inputId="agreementType"
                            name="agreementType"
                            value={
                              formik.values.agreementType === 0
                                ? ""
                                : formik.values.agreementType
                            }
                            options={
                              agreementType === null ? [] : agreementType
                            }
                            filter
                            optionLabel="value"
                            optionValue="key"
                            placeholder="Select Agreement Type"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("agreementType"),
                              "card-inputBox": true,
                            })}
                          />
                          {getFormErrorMessage("agreementType")}
                        </div>
                      </div>

                      <div className="formgrid grid px-2">
                        <div className="d-block sm:d-flex sm:flex-column  col-3 sm:col-6">
                          <label className="inputLabel">Template Id *</label>

                          <InputText
                            name="templateId"
                            value={formik.values.templateId}
                            onChange={(e) => handleChange(e)}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("templateId"),
                              "card-inputBox": true,
                            })}
                            placeholder="Enter Template Id"
                          />
                          {getFormErrorMessage("templateId")}
                        </div>

                        <div className="d-block sm:d-flex sm:flex-column  col-3 sm:col-6">
                          <label className="inputLabel">Document Name *</label>

                          <InputText
                            name="documentName"
                            value={formik.values.documentName}
                            onChange={(e) => handleChange(e)}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("documentName"),
                              "card-inputBox": true,
                            })}
                            placeholder="Enter Document Name"
                          />
                          {getFormErrorMessage("documentName")}
                        </div>

                        <div className="d-flex col-3 justify-content-center align-items-center mt-1">
                          <Button
                            className="mr-4 btn-nav w-7rem justify-content-center"
                            onClick={(e) => {
                              e.preventDefault();
                              setSetupEsignId(0);
                              formik.resetForm();
                              setEdit(false);
                              setShowTab(true);
                              setAdd(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="btn-nav w-7rem justify-content-center"
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();
                              formik.handleSubmit();
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="mt-2 col-12 p-0">
                  <DataTableComponent
                    valueData={setUpEsignList}
                    fieldsElements={dataTableElements}
                    isPaginationAllowed={true}
                    scrollable={true}
                    className="gridcell deletebtn"
                    columnResizeMode="expand"
                    maxHeight={"calc(-265px + 100vh)"}
                    isFullView={true}
                    setOpenDailog={setOpenDailog}
                    openDailog={openDailog}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
