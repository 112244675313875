// import { InteractionStatus } from "@azure/msal-browser";
// import { useMsal } from "@azure/msal-react";
// import React, { useEffect } from "react";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ScrollToTopWhenPageRender from "./components/scroll-to-top-when-page-render/ScrollToTopWhenPageRender";
// import { checkTokenExp } from "./utils/gateway/permission.helper";

function withRouter(Component: any) {
  function ComponentWithRouterProperty(properties: any) {
    // const { instance, inProgress } = useMsal();

    const location = useLocation();
    const navigate = useNavigate();
    const parameters = useParams();

    // useEffect(() => {
    //   const isTokenExp = checkTokenExp();

    //   if (isTokenExp) {
    //     if (inProgress === InteractionStatus.None) {
    //       localStorage.clear();
    //       instance.logoutRedirect({
    //         postLogoutRedirectUri: "/",
    //       });
    //     } else {
    //       console.log("else InteractionStatus");
    //     }
    //   } else {
    //     console.log("logged In");
    //   }
    // });

    return (
      <>
        <ScrollToTopWhenPageRender />
        <Component
          {...properties}
          router={{ location, navigate, params: parameters }}
        />
      </>
    );
  }

  return ComponentWithRouterProperty;
}

export default withRouter;
