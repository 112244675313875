import { useFormik } from "formik";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect } from "react";
import { setForm } from "../../../../../store/features/investment/create/slice";
// import { setFormData } from "../../../../../store/features/investment/new-investment";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
type CreateInvestmentStep2Props = {
  handlePrevious: () => void;
  handleNext: () => void;
  toast: any;
};
const CreateInvestmentStep2: React.FC<CreateInvestmentStep2Props> = ({
  handlePrevious,
  handleNext,
  toast,
}) => {
  const stateInvetsment = useAppSelector((state) => state.addInvestment.data);
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      equityInvestment: "",
    },
    validate: (data) => {
      const errors: any = {};
      if (!data.equityInvestment) {
        errors.equityInvestment = "Equity investment is required";
      }
      return errors;
    },
    onSubmit: (values) => {
      dispatch(
        setForm({
          ...stateInvetsment,
          equityInvestment: values.equityInvestment,
        })
      );
      handleNext();
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const { values, setFieldValue, handleSubmit } = formik;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  useEffect(() => {
    setFieldValue("equityInvestment", stateInvetsment?.equityInvestment);
  }, [stateInvetsment]);
  return (
    <>
      <Toast ref={toast} className="themeToast" />
      <div className="formgrid grid px-2">
        <div className="d-flex flex-column p-2 col-12">
          <label className="inputLabel">
            How does the offering you are creating pay its investors?
          </label>
          <div className="d-flex mt-2">
            <div className="flex align-items-center pr-4">
              <div className="my-3 flex align-items-center">
                <RadioButton
                  inputId="equityInvestment"
                  name="equityInvestment"
                  value="no"
                  onChange={(e) =>
                    setFieldValue("equityInvestment", e.target.value)
                  }
                  checked={values.equityInvestment === "no"}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("equityInvestment"),
                  })}
                />

                <label
                  // htmlFor="productState"
                  className="ml-2 mb-0"
                >
                  <b>Debt:</b> Interest or dividend income(Fund will receive a
                  1099-INT/DIV)
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="flex align-items-center pr-4">
              <div className="my-3 flex align-items-center">
                <RadioButton
                  inputId="equityInvestment"
                  name="equityInvestment"
                  value="yes"
                  onChange={(e) =>
                    setFieldValue("equityInvestment", e.target.value)
                  }
                  checked={values.equityInvestment === "yes"}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("equityInvestment"),
                  })}
                />

                <label
                  // htmlFor="productState"
                  className="ml-2 mb-0"
                >
                  <b>Equity:</b> Operating income and capital gains (Fund will
                  receive a K-1)
                </label>
              </div>
            </div>
          </div>
          {getFormErrorMessage("equityInvestment")}
        </div>
        <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
          <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
            <label className="inputLabel">&nbsp;</label>

            <Button
              className="btn-nav btnFocus"
              // tabIndex={0}

              onClick={(e) => {
                e.preventDefault();
                handlePrevious();
              }}
            >
              <span className="ml-auto mr-auto ">Back</span>
            </Button>
            <label className="inputLabel">&nbsp;</label>
          </div>
          <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
            <label className="inputLabel">&nbsp;</label>
            <Button
              className="btn-dialog btnFocus"
              type="submit"
              // tabIndex={1}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <span className="ml-auto mr-auto ">Next</span>
            </Button>
            <label className="inputLabel">&nbsp;</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateInvestmentStep2;
