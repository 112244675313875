import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AddExpensesService } from "../../../../../services/Accounting/accounting";
import error from "../../../../../utils/error";
import { IAddExpensesState, IAddExpensesparameters } from ".";

const initialState: IAddExpensesState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const AddExpensesThunk = createAsyncThunk(
  "RoleManagement/AddExpenses",
  async (data: IAddExpensesparameters) => {
    try {
      const response = await AddExpensesService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "RoleManagement/AddExpenses",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(AddExpensesThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(AddExpensesThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data
        ? action.payload.data[0].totalMonthlyExpenses
        : [];
    });
    builder.addCase(AddExpensesThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
