import { FormikValues } from "formik";

// import { classNames } from "primereact/utils";
// import { InputText } from "primereact/inputtext";
// import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
// import { classNames } from "primereact/utils";
import React from "react";
import MemberInformation from "../member-information/MemberInformation";

interface IProps {
  title: string;
  information: string;
  fieldName: string;
  subHeader: string;
  noOfRadioButton: number;
  accountTypeID: number;
  formik: FormikValues;
  isFormFieldInvalid: any;
  getFormErrorMessage: any;
}

const Information: React.FC<IProps> = (props) => {
  console.log("props", props);
  console.log("props.formik.values", props.formik.values);
  console.log("props.formik.values", props.formik.values);

  const information = props.information;
  const title = props.title;
  const fieldName = props.fieldName;
  const values = props.formik.values[information];
  const noOfMember = props.formik.values[fieldName];
  const subHeader = props.subHeader;
  const noOfRadioButton = props.noOfRadioButton;

  // const memberInformation = props.formik.values.memberInformation;

  const pushMemberInformation = (num: number) => {
    console.log("values", values);
    let dynamic = "ssn";
    if (information === "officerInfo") {
      dynamic = "title";
    }
    for (let i = 0; i < num!; i++) {
      if (values?.length < num!) {
        values.push({
          firstName: "",
          lastName: "",
          [dynamic]: "",
          birthDate: "",
          phoneNumber: "",
          email: "",
          addressLine1: "",
          addressLine2: "",
          country: 229,
          city: "",
          zipCode: "",
          state: "",
          countryCode: "1",
        });
      }
    }

    if (values.length > num!) {
      values.splice(num, values.length);
    }
    props.formik.setFieldValue(fieldName, num);
    props.formik.setFieldValue(information, values);
  };

  const radioButton = (num: any) => {
    console.log(
      "radioButton ===================",
      props.formik.values.action,
      fieldName
    );
    const radioElement = [];
    for (let i = 1; i <= num; i++) {
      radioElement.push(
        <div className="flex align-items-center">
          <RadioButton
            key={`${fieldName}${i}`}
            inputId={`${fieldName}${i}`}
            name={`${fieldName}`}
            value={i}
            disabled={
              props.formik.values.action === "Update" &&
              i < props.formik.values[fieldName]
            }
            checked={props.formik.values[fieldName] === i}
            onChange={(e) => {
              pushMemberInformation(e.value);
            }}
          />
          <label htmlFor={`${fieldName}${i}`} className="ml-2">
            {i}
          </label>
        </div>
      );
    }
    return radioElement;
  };

  return (
    <>
      <div className="row d-flex justify-content-start">
        <h4 className="investorHeader">{title} Information</h4>
        <p className="card-subheader mb-3">
          Please include yourself below, if applicable.
        </p>
      </div>
      <div>
        <div className="formgrid grid">
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              {subHeader} <span className="text-red-500">*</span>
            </label>
            <div className="flex flex-wrap gap-3">
              {" "}
              {radioButton(noOfRadioButton)}
            </div>

            {props.getFormErrorMessage(fieldName)}
          </div>
        </div>
      </div>

      {+noOfMember > 0 && (
        <MemberInformation
          // key={index}
          information={props.information}
          title={props.title}
          fieldNameOfNoRecord={fieldName}
          formik={props.formik}
          isFormFieldInvalid={props.isFormFieldInvalid}
          getFormErrorMessage={props.getFormErrorMessage}
        />
      )}
    </>
  );
};

export default Information;
