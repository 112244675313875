import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addNewPoolfundsUnits } from "../../../services/PoolFund";
import error from "../../../utils/error";

const initialState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const addNewPoolfundsUnitsThunk = createAsyncThunk(
  "addNewPoolfundsUnitsThunk",
  async (data: any) => {
    try {
      console.log("data", data);
      const response = await addNewPoolfundsUnits(data);
      console.log("response", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "addNewPoolfundsUnits",
  initialState,
  reducers: {
    resetAddNewPoolFundData(state) {
      state.data = initialState.data;
    },
  },
  extraReducers(builder): void {
    builder.addCase(addNewPoolfundsUnitsThunk.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(addNewPoolfundsUnitsThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("hr addNewPoolfundsUnitsThunk", action.payload.success);
      if (action.payload.success) {
        state.data = action.payload.data;
      }
      state.success = action.payload.success;
      state.message = action.payload.message;
    });
    builder.addCase(addNewPoolfundsUnitsThunk.rejected, (state, action) => {
      state.loading = "failed";
      throw new Error(error(action.error));
    });
  },
});
export const { resetAddNewPoolFundData } = slice.actions;
export default slice.reducer;
