import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAfterEarningsStatckbyInvestmentReturnID } from "../../../services/PoolFund";
import error from "../../../utils/error";
import { GetAfterEarningsStatckbyInvestmentReturnType } from "./type";

const initialState: GetAfterEarningsStatckbyInvestmentReturnType = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    output: [],
  },
};
export const getAfterEarningsStatckbyInvestmentReturnIDThunk = createAsyncThunk(
  "getAfterEarningsStatckbyInvestmentReturnID",
  async (data: any) => {
    try {
      console.log("data", data);
      const response = await getAfterEarningsStatckbyInvestmentReturnID(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getAfterEarningsStatckbyInvestmentReturnID",
  initialState,
  reducers: {
    resetAllEarningsStatckbyInvestmentReturn(state) {
      state.data = initialState.data;
    },
  },
  extraReducers(builder): void {
    builder.addCase(
      getAfterEarningsStatckbyInvestmentReturnIDThunk.pending,
      (state) => {
        state.loading = "loading";
      }
    );
    builder.addCase(
      getAfterEarningsStatckbyInvestmentReturnIDThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(
          "hr getAfterEarningsStatckbyInvestmentReturnIDThunk",
          action.payload.success
        );

        const list: any = { ...action.payload.data };
        if (list?.output?.length > 0) {
          list.output[0].isButton = true;
        }
        state.data = action.payload.success ? list : initialState.data;
        state.success = action.payload.success;
        state.message = action.payload.message;
      }
    );
    builder.addCase(
      getAfterEarningsStatckbyInvestmentReturnIDThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        throw new Error(error(action.error));
      }
    );
  },
});
export const { resetAllEarningsStatckbyInvestmentReturn } = slice.actions;
export default slice.reducer;
