import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestorAssetsService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";
import { IInvestorAssetsState } from ".";

const initialState: IInvestorAssetsState = {
  success: false,
  message: "",
  data: [],
  loading: "",
};

export const getThunkInvestorAssets = createAsyncThunk(
  "investor-assets",
  async (data: any) => {
    try {
      const response = await getInvestorAssetsService(data);
      // console.log("res", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "softCommits",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkInvestorAssets.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkInvestorAssets.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("lo", action.payload);
      state.data = action.payload._response.data
        ? action.payload._response.data
        : [];
    });
    builder.addCase(getThunkInvestorAssets.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
