import { useFormik } from "formik";
import Persona from "persona";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import useDebouncedCallback from "../../../custom-hooks/useDebouncedCallback";
import ConfirmDailogbox from "../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import ShowDocumentDailogbox from "../../../components/dialogbox/show-document-dialogbox/ShowDocumentDailogbox";
import Loader from "../../../components/loader/Loader";
import ScrollToError from "../../../components/scroll-to-error/ScrollToError";
import { addUpdateInvestorCumIAInfoThunk } from "../../../store/Investor/investor-approval/add-update-investor-cum-ia-Info/slice";
import { resetStateForGetInvAccrediationStatusDetails } from "../../../store/Investor/investor-approval/verify-agreement/accreditation/get-inv-accrediation-status/slice";
import { getIAEnrollmentDataThunk } from "../../../store/Investor/investor-approval/verify-agreement/digi-signer/get-ia-enrollment-data/slice";
import { resetInvestingAccountDetailsById } from "../../../store/Investor/investor-approval/verify-agreement/investing-account/get-investing-account-details/slice";
import { getInvestorProfileDetailsThunk } from "../../../store/Investor/investor-approval/verify-profile/get-data/slice";
import { getPersonaThunk } from "../../../store/Investor/investor-approval/verify-profile/get-persona-img-url/slice";
import { addInvestorProfileThunk } from "../../../store/Investor/investor-approval/verify-profile/post-data/slice";
import { uploadPersonaImgFileThunk } from "../../../store/Investor/investor-approval/verify-profile/upload-persona-img/slice";
import { getStatusAndFlagThunk } from "../../../store/features/investor/get-investor-status/slice";
import { getprofileData } from "../../../store/features/profile/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getSasKeyThunk } from "../../../store/sas-key/slice";
import {
  convertLocalDateToUTC,
  dateFormatterModified,
} from "../../../utils/date-formatter";
import { formatZipCode, isNumberAndHypen } from "../../../utils/helper";
import initialState from "../../../utils/investing-approval/multi-investing-account/initial-value";
import {
  corporationPayload,
  individualPayload,
  jointAccountPayload,
  multiMemberLLCPayload,
  partnershipPayload,
  selfDirectedIRAPayload,
  singleMemberLLCPayload,
  solo401kPayload,
  trustPayload,
} from "../../../utils/investing-approval/multi-investing-account/prepare-payload";
import {
  setCountryCode,
  setFormikFieldsForPersonalProfile,
} from "../../../utils/investing-approval/multi-investing-account/set-formik-data";
import {
  commonValidation,
  corportationValidation,
  individualValidation,
  jointAccountValidation,
  multiLLCMembersValidation,
  parternshipValidation,
  selfDirectedIRAValidation,
  singleMemberLLCValidation,
  solo401kValidation,
  step1Validation,
  trustValidation,
} from "../../../utils/investing-approval/multi-investing-account/validation";
import { envVariable } from "../../../utils/sponsor/helper";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
import InvestingAccountInformation from "./Investing-account-information/InvestingAccountInformation";
import Accreditation from "./accreditation/Accreditation";
import FundLegalDocuments from "./fund-legal-documents/FundLegalDocuments";
import InternalNotes from "./investor-information/internal-notes/InternalNotes";

interface Iprops {
  isInvestor?: boolean;
}
const Enroll = (props: Iprops) => {
  const topRef = useRef<HTMLDivElement>(null);
  const toast = useRef<Toast>(null);
  const fileRef = useRef<any>(null);

  const embeddedClientRef = useRef<any>();

  const { investorId, legalEntityId, investingAccountId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [sasKey, setsasKey] = useState("");

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [warningDialog, setWarningDialog] = useState(false);

  const [loader, setLoader] = useState(false);
  const [personaVisible, setPersonaVisible] = useState(false);
  const [errorText, setErrorText] = useState(false);

  const investorProfileState = useAppSelector(
    (State) => State.getInvestorProfileDetails.loading
  );

  const stateDropdown = useAppSelector(
    (State) => State.investingAccountsLookups.stateDropdown
  );

  const countryDropdown = useAppSelector((state) => {
    return state.investingAccountsLookups.countriesDropdown;
  });

  const investorProfile = useAppSelector(
    (State) => State.getInvestorProfileDetails.Data
  );
  // const approveAndSaveButtonStatus = useAppSelector(
  //   (State) => State.getStatusAndFlag.data
  // );

  // const approveAndSaveButtonState = useAppSelector(
  //   (State) => State.getStatusAndFlag.loading
  // );

  // Get IA Enrollment Data
  // const getIAEnrollmentDataLinkData = useAppSelector(
  //   (state) => state.getIAEnrollmentData.data
  // );
  // const getIAEnrollmentDataLinkState = useAppSelector(
  //   (state) => state.getIAEnrollmentData.loading
  // );

  const sponsorData = useAppSelector(getprofileData);
  const callInvestorProfileDetailsList = () => {
    if (investorId && legalEntityId && investingAccountId) {
      const parameters: any = {
        InvestorId: investorId,
        LegalEntityId: legalEntityId,
        InvestingAccountId: investingAccountId,
      };
      setLoader(true);

      dispatch(getStatusAndFlagThunk(parameters));
      dispatch(getInvestorProfileDetailsThunk(parameters))
        .then(() => {
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const addUpdateInvestorCumIAInfoPayload = async (
    accoutnInfo: any,
    data: any,
    action: any
  ) => {
    if (investorId && legalEntityId && investingAccountId) {
      console.log("datacfhjylk", data);
      const payload = accoutnInfo;
      payload.iaEnrollmentRemark = data.internalNotes;

      const investor = {
        investorId,
        legalEntityId,
        investingAccountId,
        step: "step2",
        status: "A",
        remark: data.internalNotes,
        invGuidanceInfo: undefined,

        // lastAccreditationVerified:
        //   getIAEnrollmentDataLinkData?.lastAccreditationVerifiedDate !== "" &&
        //   getIAEnrollmentDataLinkData?.lastAccreditationVerifiedDate !== null &&
        //   getIAEnrollmentDataLinkData?.lastAccreditationVerifiedDate !==
        //     undefined
        //     ? getIAEnrollmentDataLinkData?.lastAccreditationVerifiedDate?.split(
        //         "T"
        //       )[0] ===
        //       convertLocalDateToUTC(data.lastAccreditationVerified).split(
        //         "T"
        //       )[0]
        //       ? getIAEnrollmentDataLinkData?.lastAccreditationVerifiedDate
        //       : convertLocalDateToUTC(data.lastAccreditationVerified)
        //     : data.lastAccreditationVerified === "" ||
        //       data.lastAccreditationVerified === null
        //     ? data.lastAccreditationVerified
        //     : convertLocalDateToUTC(data.lastAccreditationVerified),

        lastAccreditationVerified: data.lastAccreditationVerified
          ? convertLocalDateToUTC(data.lastAccreditationVerified)
          : // eslint-disable-next-line unicorn/no-null
            null, // accreditation section have this data

        // accreditationExpires:
        //   getIAEnrollmentDataLinkData?.dateAccreditationExpires !== "" &&
        //   getIAEnrollmentDataLinkData?.dateAccreditationExpires !== null &&
        //   getIAEnrollmentDataLinkData?.dateAccreditationExpires !== undefined
        //     ? getIAEnrollmentDataLinkData?.dateAccreditationExpires?.split(
        //         "T"
        //       )[0] ===
        //       convertLocalDateToUTC(data.accreditationExpires).split("T")[0]
        //       ? getIAEnrollmentDataLinkData?.dateAccreditationExpires
        //       : convertLocalDateToUTC(data.accreditationExpires)
        //     : data.accreditationExpires === "" ||
        //       data.accreditationExpires === null
        //     ? data.accreditationExpires
        //     : convertLocalDateToUTC(data.accreditationExpires),
        accreditationExpires: data.accreditationExpires
          ? convertLocalDateToUTC(data.accreditationExpires)
          : // eslint-disable-next-line unicorn/no-null
            null, // accreditation section have this data
        isAccredited: data.isAccredited,
        isPPMByPassed: data.isPPMByPassed,
        isAccreditationByPassed: data.isAccreditationByPassed,
      };

      console.log("addUpdateInvestorCumIAInfoThunk payload", payload, investor);

      const investorProfileDetails = {
        investorID: investorId,
        legalEntityID: legalEntityId,
        investingAccountID: investingAccountId,
        firstName: data.firstNamePP,
        lastName: data.lastNamePP,
        emailAddress: data.emailPP ? data.emailPP?.trim() : data.emailPP,
        mobilePhone: data.phonePP,
        address: data.addressPP,
        address2: data.apartmentPP,
        city: data.cityPP,
        countryID: data.countryIdPP,
        imageURL: data.documentLinkPP,
        stateProvince: data.statePP,
        zipCode: data.zipcodePP,
        electronicCommunication: data.electronicCommunicationPP,
        electronicK1Delivery: data.electronicK1DeliveryPP,
        documentLink: data.documentLinkPP,
        isApprove: data.isApprove,
        investorGuidance: data.investorGuidancePP,
        stepInvestorProfileRemark: data.internalNotesPP,
      };

      await dispatch(
        addUpdateInvestorCumIAInfoThunk({
          accountTypeId: data.accountType ? data.accountType : 0,
          isApprove: data.isApprove,
          investingAccountInfo: JSON.stringify(payload),
          stepEnrollmentParam: JSON.stringify(investor),
          investorProfileDetails,
        })
      )
        .then((res: any) => {
          if (res.payload._response.success) {
            if (res.payload._response.data?.showErrorToast === true) {
              errorToastMessage(toast, res.payload._response.message);
              return;
            }
            successToastMessage(toast, "Data have been saved successfully");
            if (data.isApprove) {
              successToastMessage(toast, "Approve has been successfully");
              navigate("/investorsList");
              return;
            }

            const parameters: any = {
              InvestorId: investorId,
              LegalEntityId: legalEntityId,
              InvestingAccountId: investingAccountId,
            };

            dispatch(getStatusAndFlagThunk(parameters));
            dispatch(
              getIAEnrollmentDataThunk({
                investorId: +investorId,
                legalEntityId: +legalEntityId,
                investingAccountId: +investingAccountId,
              })
            );
            if (res.payload._response.data?.investingAccountId) {
              navigate(
                `../Enroll/${investorId}/${legalEntityId}/${res.payload._response.data?.investingAccountId}`,
                {
                  replace: true,
                }
              );
            }
          } else {
            errorToastMessage(toast);
          }
        })
        .catch(() => {
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoader(false);
          action.setSubmitting(false);
        });
    }

    // formik.resetForm();
  };

  const formik = useFormik({
    initialValues: initialState,
    validate: (data: any) => {
      console.log("validate", data);
      let errors: any = {};
      //    Create 17-08-2023 Dev 9
      // mailing address and account Name validation
      errors = step1Validation(data, errors);
      errors = commonValidation(data, errors);

      switch (data.accountType) {
        case 1:
          errors = individualValidation(data, errors);
          break;
        case 2:
          errors = trustValidation(data, errors);
          break;
        case 3:
          errors = singleMemberLLCValidation(data, errors);
          break;
        case 4:
          errors = solo401kValidation(data, errors);
          break;
        case 5:
          errors = selfDirectedIRAValidation(data, errors);
          break;
        case 6:
          errors = jointAccountValidation(data, errors);
          break;
        case 7:
          errors = multiLLCMembersValidation(data, errors);
          break;
        case 8:
          errors = parternshipValidation(data, errors);
          break;
        case 9:
          errors = corportationValidation(data, errors);
          break;
      }

      if (Object.keys(errors).length > 0) {
        errors.form = "Form has incomplete items, marked in red.";
      }

      return errors;
    },
    onSubmit: (data: any, action) => {
      console.log("data accreditationExpires onSubmit", data);

      let step2AccountInformation: any = [];
      switch (data.accountType) {
        case 1:
          step2AccountInformation = individualPayload(data, investorId);
          break;
        case 3:
          step2AccountInformation = singleMemberLLCPayload(data, investorId);
          break;
        case 4:
          step2AccountInformation = solo401kPayload(data, investorId);
          break;
        case 5:
          step2AccountInformation = selfDirectedIRAPayload(data, investorId);
          break;
        case 6:
          step2AccountInformation = jointAccountPayload(data, investorId);
          break;
        case 7:
          step2AccountInformation = multiMemberLLCPayload(data, investorId);
          break;
        case 2:
          step2AccountInformation = trustPayload(data, investorId);
          break;
        case 8:
          step2AccountInformation = partnershipPayload(data, investorId);
          break;
        case 9:
          step2AccountInformation = corporationPayload(data, investorId);
          break;
      }
      console.log("data accreditationExpires", data);

      if (
        data.isPPMByPassed &&
        (data.isAccreditationByPassed === true ||
          sponsorData?.[0].fundType === "506b" ||
          sponsorData?.[0].fundType?.toLowerCase() !== "reg cf") &&
        data.isConfimredAndApproved === true
      ) {
        setWarningDialog(true);
      } else {
        setLoader(true);
        addUpdateInvestorCumIAInfoPayload(
          step2AccountInformation,
          data,
          action
        );
        formik.setTouched({}, false);
      }
    },
  });

  const uploadDoc = async (accreditPdf: any) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("filename", accreditPdf);

    if (investorId && legalEntityId && investingAccountId) {
      formik.handleSubmit();
      await dispatch(
        uploadPersonaImgFileThunk({
          investorId,
          legalEntityId,
          investingAccountId,
          filename: formData,
        })
      )
        .then((response) => {
          if (response.payload.success) {
            successToastMessage(toast, "File Uploaded");
            callInvestorProfileDetailsList();
          } else {
            errorToastMessage(toast);
          }
        })
        .catch(() => {
          // console.log("error", error);
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoader(false);
          fileRef?.current?.clear();
        });
    }
  };

  const uploadImageFile = async (e: any) => {
    const file = e.files[0];
    const reader = new FileReader();

    // addEventListener
    reader.addEventListener("load", () => {
      const fileType = file.type;
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "image/jpg" ||
        fileType === "application/pdf"
      ) {
        setErrorText(false);
        uploadDoc(file);
      } else {
        setErrorText(true);
        fileRef?.current.clear();
      }
    });

    // reader.onerror = function() {
    //
    // };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      console.log("isValid useEffect", formik.errors);
      if (formik.values.isRequiredFieldCompleted) {
        formik.setFieldValue("isRequiredFieldCompleted", false);
      }
    } else {
      if (!formik.values.isRequiredFieldCompleted) {
        formik.setFieldValue("isRequiredFieldCompleted", true);
      }
    }
  }, [formik.errors]);

  // const debounced = useDebouncedCallback((field: any, value: any) => {
  //   formik.setFieldValue(field, value);
  // }, 400);

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any, index = "", fieldName = "") => {
    let touched;
    let error;
    if (index !== "" && fieldName !== "") {
      touched = formikTouched[name]?.[index]?.[fieldName];
      error = formikErrors[name]?.[index]?.[fieldName];
    } else {
      touched = formikTouched[name];
      error = formikErrors[name];
    }
    return !!(touched && error);
    // return true;
  };

  const getFormErrorMessage = (name: any, index = "", fieldName = "") => {
    let error;
    index !== "" && fieldName !== ""
      ? (error = formikErrors[name]?.[index]?.[fieldName])
      : (error = formikErrors[name]);

    return (
      isFormFieldInvalid(name, index, fieldName) && (
        <small className="p-error">{error}</small>
      )
    );
  };

  console.log("formik.errors", formikErrors, formikTouched);
  useEffect(() => {
    callInvestorProfileDetailsList();

    return () => {};
  }, [investorId, legalEntityId, investingAccountId]);

  useEffect(() => {
    console.log("investorProfile", investorProfile);
    if (investorProfile?.length) {
      const getData = investorProfile[0];
      console.log("getData", getData);
      if (getData) {
        setFormikFieldsForPersonalProfile(formik, getData);
      }
    } else {
      formik.resetForm();
    }
  }, [investorProfile]);

  const createClient = () => {
    setConfirmDialog(false);
    setLoader(true);

    // setStatus("loading");
    // setFlowType("embedded");
    console.log(
      "envVariable.REACT_APP_TEMPLET_ID",
      envVariable.REACT_APP_TEMPLET_ID
    );
    const client = new Persona.Client({
      // host: "production",
      // templateId: "tmpl_qezZQRNBsrrVyUD613MwcBL9",

      templateId: envVariable.REACT_APP_TEMPLET_ID,
      templateVersionId: "",
      // environment: "sandbox",
      environment: envVariable.REACT_APP_PERSONA_ENV,

      //
      onLoad: () => {
        // setStatus("loaded");
      },
      onReady: () => {
        // setStatus("ready");
        setLoader(false);

        client.open();
      },
      onCancel: () => {
        setLoader(false);
      },
      onComplete: ({ inquiryId, status }) => {
        const sendData: any = {
          investorID: investorId,
          inquiryId,
          inqStatus: status,
          Type: "G",
        };
        // console.log("sendData", sendData);
        // dispatch(getPersonaThunk(sendData)).then((res) => {
        //   if (res.payload.success) {
        //     console.log("res", res);
        //   }
        // });
        setLoader(true);
        formik.handleSubmit();

        dispatch(getPersonaThunk(sendData))
          .then((res) => {
            // setPersonalUrl(res.payload.data);
            console.log("res", res.payload._response.data);
            if (res.payload._response.success) {
              const paramter = {
                investorID: investorId,
                legalEntityID: legalEntityId,
                firstName: formik.values.firstNamePP?.trim(),
                lastName: formik.values.lastNamePP?.trim(),
                emailAddress: formik.values.emailPP
                  ? formik.values.emailPP?.trim()
                  : formik.values.emailPP,
                mobilePhone: formik.values.phonePP!
                  ? formik.values

                      .phonePP!.split("-")
                      .join("")
                      .split("+1")
                      .join("")
                      ?.trim()
                  : "",
                address:
                  formik.values.addressPP || formik.values.addressPP !== null
                    ? formik.values.addressPP
                    : "",
                address2:
                  formik.values.apartmentPP ||
                  formik.values.apartmentPP !== null
                    ? formik.values.apartmentPP
                    : "",
                city:
                  formik.values.cityPP || formik.values.cityPP !== null
                    ? formik.values.cityPP
                    : "",
                stateProvince:
                  formik.values.statePP || formik.values.statePP !== null
                    ? formik.values.statePP
                    : "",
                zipCode:
                  formik.values.zipcodePP?.length === 6
                    ? formik.values.zipcodePP.split("-").join("")
                    : formik.values.zipcodePP,
                // stepIDStatus: statusStep1,
                // stepIDRemark: "",
                // createdBy: 0,
                electronicCommunication:
                  formik.values.electronicCommunicationPP,
                electronicK1Delivery: formik.values.electronicK1DeliveryPP,
                imageURL: res.payload._response.data
                  ? res.payload._response.data
                  : "",
                DocumentLink: res.payload._response.data
                  ? res.payload._response.data
                  : "",

                // activity,
                // stepIDStatus: "",
                // docId: 0,
                // isApprove: 0,
                isApprove: formik.values.isApprove,

                countryID: +formik.values.countryIdPP,
              };
              console.log("paramter", paramter);

              dispatch(addInvestorProfileThunk(paramter))
                .then((response) => {
                  console.log(response);

                  callInvestorProfileDetailsList();
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            // callInvestorProfileDetailsList();
            // console.log(parameters);
            // await dispatch(getInvestorProfileDetailsThunk(parameters)).then(
            //   (response) => {
            //     // profileDetails!.documentLink
            //     //   ? setActivity("A")
            //     //   : setActivity("E");

            //     const getImageUrl = response.payload.data
            //       ? response.payload._response.data.investorProfile![0]
            //           .documentLink
            //       : [];

            //     // setPersonalUrl(getImageUrl);
            //     formik.setValues("documentLink", getImageUrl);
            //   }
            // );
            // setVisible(true);
          })
          .catch((error: any) => {
            console.log("error", error);
          })
          .finally(() => {
            // setLoader(false);
          });

        // if (investorId && legalEntityId && investingAccountId) {
        //   const parameters: any = {
        //     InvestorId: investorId,
        //     LegalEntityId: legalEntityId,
        //     InvestingAccountId: investingAccountId,
        //   };
        //   dispatch(getStatusAndFlagThunk(parameters));
        //   dispatch(getInvestorProfileDetailsThunk(parameters));
        // }
      },
      onError: (error) => {
        console.log(error);
      },
      onEvent: (name, meta) => {
        dispatch({ type: "append", log: { name, meta } });
      },
    });
    embeddedClientRef.current = client;

    window.addEventListener("cancel", (force: any) => {
      if (client) {
        client.cancel(force);
        setLoader(true);
      } else {
        console.log("Initialize client first");
      }
    });
  };

  const onCancel = () => {
    setConfirmDialog(false);
  };

  const onDialogClose = () => {
    setPersonaVisible(false);
  };

  const onConfirm = () => {
    setLoader(false);
    setConfirmDialog(false);
    createClient();
  };

  useEffect(() => {
    dispatch(resetStateForGetInvAccrediationStatusDetails());
    dispatch(resetInvestingAccountDetailsById());
  }, []);

  const disableApproveButton = () => {
    let isDisable = true;
    console.log(
      "disableApproveButton",
      "isRequiredFieldCompleted",
      formik.values.isRequiredFieldCompleted,
      "isKYCCompleted",
      formik.values.isKYCCompleted,
      "isAccreditationCompleted",
      formik.values.isAccreditationCompleted,
      "fundType",
      sponsorData?.[0].fundType,
      "isFundLegalDocumentsCompleted",
      formik.values.isFundLegalDocumentsCompleted,
      "isPPMByPassed",
      formik.values.isPPMByPassed,
      "isAccreditationByPassed",
      formik.values.isAccreditationByPassed
    );

    if (
      formik.values.isRequiredFieldCompleted &&
      formik.values.isKYCCompleted &&
      (formik.values.isAccreditationCompleted === true ||
        sponsorData?.[0].fundType === "506b" ||
        sponsorData?.[0].fundType?.toLowerCase() === "reg cf") &&
      formik.values.isFundLegalDocumentsCompleted &&
      formik.values.isPPMByPassed === false &&
      formik.values.isAccreditationByPassed === false
    ) {
      isDisable = false;
    } else if (
      formik.values.isRequiredFieldCompleted &&
      formik.values.isKYCCompleted &&
      ((formik.values.isAccreditationByPassed === false &&
        (sponsorData?.[0].fundType === "506b" ||
          sponsorData?.[0].fundType?.toLowerCase() === "reg cf")) ||
        formik.values.isAccreditationByPassed ||
        formik.values.isAccreditationCompleted === true) &&
      ((formik.values.isPPMByPassed === false &&
        formik.values.isFundLegalDocumentsCompleted === true) ||
        (formik.values.isPPMByPassed === true &&
          formik.values.isFundLegalDocumentsCompleted === false))
    ) {
      isDisable = false;
    }
    console.log("disableApproveButton", isDisable);
    return isDisable;
  };

  const generateSASKey = () => {
    const docLinkLeId: string =
      investorProfile![0].docLinkLeId !== undefined
        ? investorProfile![0]?.docLinkLeId
        : "0";
    return dispatch(getSasKeyThunk(docLinkLeId))
      .then((response: any) => {
        if (response.meta.requestStatus === "fulfilled") {
          return response.payload.data.sasKey;
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const onDialogOpen = async () => {
    let key = "";
    setLoader(true);
    key = await generateSASKey();
    setsasKey(key);
    setPersonaVisible(true);
    // setVisible(true);
  };
  const activeBarData2 = localStorage.getItem("activeBarValue");
  console.log("ActiveBar DAta", activeBarData2);
  const formatPhoneNumber = (value: string) => {
    const phoneNumberOnly = value ? value!.replace(/\D/g, "") : value;

    let maskedPhoneNumber = "";

    if (phoneNumberOnly) {
      if (formik.values.countryIdPP !== 229) {
        const isValidPhoneNumber = /^[\d-]*$/.test(value);
        if (isValidPhoneNumber) {
          maskedPhoneNumber = value; // Truncate to 20 characters if exceeds
        }
      } else {
        // If countryId is 229, follow the existing masking logic
        if (phoneNumberOnly.length <= 3) {
          maskedPhoneNumber = phoneNumberOnly;
        } else if (phoneNumberOnly.length <= 6) {
          maskedPhoneNumber =
            phoneNumberOnly.slice(0, 3) + "-" + phoneNumberOnly.slice(3);
        } else {
          maskedPhoneNumber =
            phoneNumberOnly.slice(0, 3) +
            "-" +
            phoneNumberOnly.slice(3, 6) +
            "-" +
            phoneNumberOnly.slice(6, 10);
        }
      }
    }

    return maskedPhoneNumber;
  };

  return (
    <>
      <div ref={topRef}>
        <div>
          <div className="flex flex-row flex-wrap sm:flex-none col-12">
            <div className="d-flex flex-column p-2 col-12 md:col-1 flex justify-content-center">
              Show:
            </div>
            <div className="d-flex flex-column p-2 col-12 md:col-3">
              <Button
                className={`${
                  activeBarData2 === "investors" ? "btn-navActive" : "btn-nav"
                }  mt-2`}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/investorsList?source=investors");
                }}
              >
                <span className="ml-auto mr-auto ">Investors</span>
              </Button>
            </div>
            <div className="d-flex flex-column p-2 col-12 md:col-3">
              <Button
                className={`${
                  activeBarData2 === "inRegistration"
                    ? "btn-navActive"
                    : "btn-nav"
                }  mt-2`}
                onClick={(e) => {
                  e.preventDefault();
                  // setPage("inRegistration");
                  navigate("/investorsList?source=inRegistration");
                }}
              >
                <span className="ml-auto mr-auto ">In Registration</span>
              </Button>
            </div>
          </div>
        </div>
        {investorProfileState === "succeeded" &&
          investorId &&
          legalEntityId &&
          investingAccountId && (
            <>
              <div className="card mb-5">
                <Card className="EnrollCard">
                  <div className="topError">
                    {/* {+investingAccountId === 0 ||
                    (!getIAEnrollmentDataLinkData?.isSignCompleted &&
                      getIAEnrollmentDataLinkData?.signer === "Signer 1") ||
                    (getIAEnrollmentDataLinkData?.signer === "" &&
                      getIAEnrollmentDataLinkData?.isOlduser !== true) ? (
                      <h1 className="pb-1">
                        Investor has not provided any investing account
                        information
                      </h1>
                    ) : (
                      <></>
                    )} */}
                    {Object.keys(formikErrors).length > 0 &&
                      Object.keys(formikTouched).length > 0 && (
                        <>
                          <small></small>
                          {getFormErrorMessage("form")}
                        </>
                      )}
                  </div>
                  <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="grid border-bottom-2 border-200 mb-4">
                      <div className="col-12 md:col-6">
                        {investorProfile?.[0]?.firstName && (
                          <h3 className="pageHeader mt-2">
                            {" "}
                            {investorProfile?.[0]?.firstName}{" "}
                            {investorProfile?.[0]?.lastName} Enrollment
                          </h3>
                        )}
                      </div>
                      <div className="col-12 md:col-6 flex justify-content-end">
                        <p className="m-0 align-self-center">
                          Account Created:{" "}
                          {dateFormatterModified(
                            investorProfile?.[0]?.createdDate
                          )}
                        </p>
                      </div>
                    </div>
                    <Accordion activeIndex={0} className="my-3">
                      <AccordionTab header="Personal Profile">
                        <div className="mb-4">
                          <h4 className="sectionHeader">Personal Profile:</h4>
                          <div className="fromSection">
                            <div className="formgrid grid">
                              <div className="field col-12 md:col-6">
                                <label className="inputLabel">
                                  First Name{" "}
                                  <span className="text-red-500"> *</span>
                                </label>
                                <InputText
                                  name="firstNamePP"
                                  value={formik.values.firstNamePP?.trimStart()}
                                  autoComplete="off"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "firstNamePP",
                                      e.target.value?.trimStart()
                                    );
                                    console.log(e);
                                    // debounced("firstName", e.target.value?.trimStart());
                                  }}
                                  className={classNames({
                                    "p-invalid":
                                      isFormFieldInvalid("firstNamePP"),
                                    "card-inputBox": true,
                                  })}
                                  // placeholder="First Name"
                                />
                                {getFormErrorMessage("firstNamePP")}
                              </div>
                              <div className="field col-12 md:col-6">
                                <label className="inputLabel">
                                  Last Name{" "}
                                  <span className="text-red-500"> *</span>
                                </label>

                                <InputText
                                  name="lastNamePP"
                                  value={formik.values.lastNamePP?.trimStart()}
                                  autoComplete="off"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "lastNamePP",
                                      e.target.value?.trimStart()
                                    );
                                    console.log(e);
                                    // debounced("firstName", e.target.value?.trimStart());
                                  }}
                                  className={classNames({
                                    "p-invalid":
                                      isFormFieldInvalid("lastNamePP"),
                                    "card-inputBox": true,
                                  })}
                                  // placeholder="First Name"
                                />
                                {getFormErrorMessage("lastNamePP")}
                              </div>
                              <div className="field col-12 md:col-6">
                                <label className="inputLabel">
                                  Email <span className="text-red-500"> *</span>
                                </label>

                                <InputText
                                  name="emailPP"
                                  value={formik.values.emailPP?.trimStart()}
                                  autoComplete="off"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "emailPP",
                                      e.target.value?.trimStart()
                                    );
                                    console.log(e);
                                    // debounced("firstName", e.target.value?.trimStart());
                                  }}
                                  disabled
                                  className={classNames({
                                    "p-invalid": isFormFieldInvalid("emailPP"),
                                    "card-inputBox": true,
                                  })}
                                  // placeholder="First Name"
                                />
                                {getFormErrorMessage("emailPP")}
                              </div>
                              <div className="field col-12 md:col-6">
                                <label className="inputLabel">
                                  Phone Number{" "}
                                  <span className="text-red-500"> *</span>
                                </label>

                                <div className="p-inputgroup inputgrp-h47 flex-1">
                                  <span className="p-inputgroup-addon">
                                    {formik.values.countryCodePP
                                      ? "+" + formik.values.countryCodePP
                                      : ""}
                                  </span>
                                  {formik.values.countryIdPP !== 229 ? (
                                    <InputText
                                      name="phonePP"
                                      id="phonePP"
                                      type="text"
                                      // value={phoneNumber}
                                      maxLength={20}
                                      value={
                                        formik.values.phonePP
                                          ? formatPhoneNumber(
                                              formik.values.phonePP
                                            )
                                          : formik.values.phonePP
                                      }
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        if (isNumberAndHypen(e.target.value)) {
                                          formik.setFieldValue(
                                            "phonePP",
                                            formatPhoneNumber(e.target.value)
                                          );
                                        }
                                      }}
                                      placeholder={""}
                                      className={classNames({
                                        "p-invalid":
                                          isFormFieldInvalid("phonePP"),
                                        // "card-inputBox": true,
                                        "w-full inputphoneNo": true,
                                      })}
                                    />
                                  ) : (
                                    <InputText
                                      name="phonePP"
                                      id="phonePP"
                                      type="text"
                                      // value={phoneNumber}
                                      maxLength={20}
                                      value={
                                        formik.values.phonePP
                                          ? formatPhoneNumber(
                                              formik.values.phonePP
                                                .split("-")
                                                .join("")
                                            )
                                          : formik.values.phonePP
                                      }
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "phonePP",

                                          formatPhoneNumber(e.target.value)
                                            .split("-")
                                            .join("")
                                        );
                                      }}
                                      placeholder={"xxx-xxx-xxxx"}
                                      className={classNames({
                                        "p-invalid":
                                          isFormFieldInvalid("phonePP"),
                                        // "card-inputBox": true,
                                        "w-full inputphoneNo": true,
                                      })}
                                    />
                                  )}
                                </div>
                                {getFormErrorMessage("phonePP")}
                              </div>
                              <div className="field col-12 md:col-3">
                                <label className="inputLabel">
                                  Country{" "}
                                  <span className="text-red-500"> *</span>
                                </label>

                                <Dropdown
                                  inputId="CountryPP"
                                  name="CountryPP"
                                  value={formik.values.countryIdPP}
                                  options={countryDropdown}
                                  filter
                                  optionLabel="value"
                                  optionValue="key"
                                  placeholder="Select Country"
                                  // showOnFocus
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "countryCodePP",
                                      setCountryCode(e.value)
                                    );

                                    formik.setFieldValue("zipcodePP", "");
                                    formik.setFieldValue(
                                      "countryIdPP",
                                      e.value
                                    );
                                    formik.setFieldValue("statePP", "");
                                  }}
                                  className={classNames({
                                    "p-invalid":
                                      isFormFieldInvalid("countryIdPP"),
                                    "card-inputBox text-left": true,
                                  })}
                                />

                                {getFormErrorMessage("countryIdPP")}
                              </div>{" "}
                              <div className="field col-12 md:col-3">
                                <label className="inputLabel">
                                  Address{" "}
                                  <span className="text-red-500">*</span>
                                </label>

                                <InputText
                                  name="addressPP"
                                  value={formik.values.addressPP}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "addressPP",
                                      e.target.value?.trimStart()
                                    )
                                  }
                                  className={classNames({
                                    "p-invalid":
                                      isFormFieldInvalid("addressPP"),
                                    "card-inputBox": true,
                                  })}
                                  placeholder="Address"
                                />
                                {getFormErrorMessage("addressPP")}
                              </div>
                              <div className="field col-12 md:col-3">
                                <label className="inputLabel ">
                                  City
                                  {/* <span className="text-red-500"> *</span> */}
                                </label>
                                <InputText
                                  name="city"
                                  value={formik.values.cityPP}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "cityPP",
                                      e.target.value
                                    )
                                  }
                                  className={classNames({
                                    "p-invalid": isFormFieldInvalid("cityPP"),
                                    "card-inputBox": true,
                                  })}
                                  placeholder="City"
                                />
                                {getFormErrorMessage("cityPP")}
                              </div>
                              <div className="field col-12 md:col-3">
                                <label className="inputLabel">
                                  State <span className="text-red-500"> *</span>
                                </label>
                                {formik.values.countryIdPP === 229 ? (
                                  <Dropdown
                                    inputId="statePP"
                                    name="State"
                                    value={formik.values.statePP}
                                    options={stateDropdown}
                                    // filter
                                    optionLabel="value"
                                    optionValue="key"
                                    placeholder="Select State"
                                    // showOnFocus
                                    onChange={(e) => {
                                      formik.setFieldValue("statePP", e.value);
                                    }}
                                    className={classNames({
                                      "p-invalid":
                                        isFormFieldInvalid("statePP"),
                                      "card-inputBox text-left": true,
                                    })}
                                  />
                                ) : (
                                  <InputText
                                    name="State"
                                    value={formik.values.statePP?.trim()}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "statePP",
                                        e.target.value
                                      );
                                    }}
                                    className={classNames({
                                      "p-invalid":
                                        isFormFieldInvalid("statePP"),
                                      "card-inputBox": true,
                                    })}
                                    placeholder="State"
                                    maxLength={10}
                                  />
                                )}

                                {getFormErrorMessage("statePP")}
                              </div>
                              <div className="field col-12 md:col-6">
                                <label className="inputLabel ">
                                  Zip Code:{" "}
                                  <span className="text-red-500"> *</span>
                                </label>

                                <InputText
                                  name="zipcode"
                                  value={formik.values.zipcodePP}
                                  onChange={(e) => {
                                    const zipCode = formatZipCode(
                                      e.target.value,
                                      formik.values.countryIdPP
                                    );
                                    formik.setFieldValue("zipcodePP", zipCode);
                                  }}
                                  className={classNames({
                                    "p-invalid":
                                      isFormFieldInvalid("zipcodePP"),
                                    "card-inputBox": true,
                                  })}
                                  placeholder="zipcode"
                                  maxLength={
                                    formik.values.countryIdPP === 229 ? 10 : 20
                                  }
                                />
                                {getFormErrorMessage("zipcodePP")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionTab>
                    </Accordion>
                    <Accordion activeIndex={0} className="my-3">
                      <AccordionTab
                        header="Know Your Customer (KYC) & Anti-Money Laundering (AML)
                        Check"
                      >
                        <div className="mb-4">
                          <h4 className="sectionHeader">
                            Know Your Customer (KYC) & Anti-Money Laundering
                            (AML) Check
                          </h4>
                          <div className="fromSection">
                            <div className="formgrid grid">
                              <div className="field">
                                <label className="inputLabel d-flex justify-content-start mb-3">
                                  Government ID Verification Status:{" "}
                                </label>

                                <div className=" align-self-center ">
                                  {/* <Button
                                    // redButtonTextBorder
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                    className={`${
                                      formik.values.documentLinkPP
                                        ? "greenButtonTextBorder"
                                        : "redButtonTextBorder"
                                    }   d-block  d-flex justify-content-center col-12`}
                                  >
                                    {formik.values.documentLinkPP
                                      ? "COMPLETE"
                                      : "NOT COMPLETE"}
                                  </Button> */}

                                  {formik.values.documentLinkPP ? (
                                    <Message
                                      severity="success"
                                      text="COMPLETE"
                                    />
                                  ) : (
                                    <Message
                                      severity="error"
                                      text="NOT COMPLETE"
                                    />
                                  )}
                                </div>
                                {/* {getFormErrorMessage("firstName")} */}
                              </div>{" "}
                              {formik.values.documentLinkPP && (
                                <div className="field ">
                                  <label className="inputLabel d-flex justify-content-start  mb-5">
                                    {" "}
                                  </label>

                                  <div className=" align-self-center flex md:mx-3 mx-2">
                                    <Button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        onDialogOpen();
                                      }}
                                      className="BtnBlue d-flex justify-content-center my-0"
                                    >
                                      VIEW
                                    </Button>
                                  </div>
                                  {/* {getFormErrorMessage("firstName")} */}
                                </div>
                              )}
                              <div className="field ">
                                <label className="inputLabel d-flex justify-content-start mb-5">
                                  {" "}
                                </label>

                                <div className=" align-self-center flex  d-block d-flex col-12 mr-3">
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setConfirmDialog(true);
                                    }}
                                    // className="btn-nav d-block  d-flex justify-content-center "
                                    className="BtnBlue d-block  d-flex justify-content-center my-0"
                                  >
                                    {formik.values.documentLinkPP
                                      ? "RE-VERIFY"
                                      : "VERIFY"}
                                  </Button>
                                </div>
                                {/* {getFormErrorMessage("firstName")} */}
                              </div>
                              <div className="field ">
                                <label className="inputLabel d-flex justify-content-start  xl:mb-5 lg:mb-0">
                                  {" "}
                                </label>

                                <div className=" align-self-center flex ">
                                  <FileUpload
                                    mode="basic"
                                    name="demo"
                                    // url="/api/upload"
                                    ref={fileRef}
                                    auto={true}
                                    accept="image/*,pdf/*"
                                    customUpload={true}
                                    chooseLabel={"MANUAL UPLOAD"}
                                    className="btn-dialog border-round-md w-100 UploadAccreBtn"
                                    uploadHandler={uploadImageFile}
                                  />
                                </div>

                                {errorText ? (
                                  <span className="text-xs text-red-600">
                                    Please upload files having extensions :
                                    .jpg, .jpeg, .png , .pdf only
                                  </span>
                                ) : (
                                  <></>
                                )}
                                {/* {getFormErrorMessage("firstName")} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionTab>
                    </Accordion>
                    {/* {investorId && legalEntityId && investingAccountId && ( */}
                    <>
                      <Accreditation
                        investorId={+investorId}
                        legalEntityId={+legalEntityId}
                        investingAccountId={+investingAccountId}
                        formik={formik}
                        isFormFieldInvalid={isFormFieldInvalid}
                        getFormErrorMessage={getFormErrorMessage}
                      />
                      <InvestingAccountInformation
                        investorID={investorId}
                        legalEntityID={legalEntityId}
                        investingAccountID={investingAccountId}
                        formik={formik}
                        isFormFieldInvalid={isFormFieldInvalid}
                        getFormErrorMessage={getFormErrorMessage}
                      />
                      <FundLegalDocuments
                        investorId={+investorId}
                        legalEntityId={+legalEntityId}
                        investingAccountId={+investingAccountId}
                        formik={formik}
                        isFormFieldInvalid={isFormFieldInvalid}
                        getFormErrorMessage={getFormErrorMessage}
                      />
                      <InternalNotes
                        investingAccountId={+investingAccountId}
                        formik={formik}
                        isFormFieldInvalid={isFormFieldInvalid}
                        getFormErrorMessage={getFormErrorMessage}
                      />
                    </>
                    {/* <div className="grid"> */}
                    {formik.values.isFundLegalDocumentsCompleted === false && (
                      <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-12">
                        <div className="flex align-items-center">
                          <label className="container-checkbox">
                            <span
                              className={
                                !investingAccountId ? "opacity-60" : ""
                              }
                            >
                              Approve this investor without fund agreement
                              signatures
                            </span>
                            <input
                              type="checkbox"
                              // value="SoftCommit"
                              name="isPPMByPassed"
                              onChange={(e) => {
                                console.log("e", e.target.checked);
                                formik.setFieldValue(
                                  "isPPMByPassed",
                                  e.target.checked
                                );
                              }}
                              checked={formik.values.isPPMByPassed === true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    )}
                    {formik.values.isAccreditationCompleted === false &&
                      sponsorData?.[0].fundType !== "506b" &&
                      sponsorData?.[0].fundType?.toLowerCase() !== "reg cf" && (
                        <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-12">
                          <div className="d-flex mt-2">
                            <div className="flex align-items-center ">
                              <label className="container-checkbox ">
                                <span>
                                  Approve this investor account without
                                  accreditation letter verification
                                </span>
                                <input
                                  type="checkbox"
                                  // value="SoftCommit"
                                  name="isAccreditationByPassed"
                                  onChange={(e) => {
                                    console.log("e", e.target.checked);
                                    formik.setFieldValue(
                                      "isAccreditationByPassed",
                                      e.target.checked
                                    );
                                  }}
                                  checked={
                                    formik.values.isAccreditationByPassed ===
                                    true
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    <div className="field col-12">
                      <div className="flex flex-column md:flex-row flex-wrap justify-content-center">
                        {props.isInvestor !== true && (
                          <Button
                            className="BtnBlue flex align-items-center w-100 sm:mb-3 md:w-2 md:mr-4"
                            label="APPROVE"
                            type="submit"
                            disabled={disableApproveButton()}
                            onClick={(e) => {
                              e.preventDefault();
                              formik.setFieldValue("isApprove", 1);
                              formik.setFieldValue(
                                "isConfimredAndApproved",
                                true
                              );
                              formik.handleSubmit();
                            }}
                          />
                        )}

                        <Button
                          className="BtnBlue flex align-items-center w-100 sm:mb-3 md:w-2 md:mr-4"
                          label="SAVE"
                          // disabled={formik.isSubmitting}
                          onClick={(e) => {
                            e.preventDefault();
                            formik.setFieldValue("isApprove", 0);
                            formik.setFieldValue(
                              "isConfimredAndApproved",
                              false
                            );

                            formik.handleSubmit();
                          }}
                        ></Button>
                        <Button
                          className="BtnBlue flex align-items-center w-100 md:w-2"
                          // className="btn-nav flex align-items-center w-100 md:w-2"
                          label="CANCEL"
                          // disabled={formik.isSubmitting}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                          }}
                        ></Button>
                      </div>
                    </div>
                  </form>
                </Card>
              </div>
              <ScrollToError formik={formik} />
            </>
          )}
        {investorProfileState === "loading" && (
          <Skeleton width="100%" height="350px"></Skeleton>
        )}
      </div>
      <Toast ref={toast} className="themeToast" />
      {loader && <Loader />}
      <ConfirmDailogbox
        openDialog={confirmDialog}
        setOpenDialog={setConfirmDialog}
        onConfirm={onConfirm}
        onCancel={onCancel}
        textContent="Are you sure you want to re-verify your identity document"
      />
      <ShowDocumentDailogbox
        openDialog={personaVisible}
        setOpenDialog={setPersonaVisible}
        documentLink={formik.values.documentLinkPP + sasKey}
        onDialogClose={onDialogClose}
      />

      <Dialog
        header={`${investorProfile?.[0]?.firstName}  ${investorProfile?.[0]?.lastName} Approval`}
        headerClassName="warningDialogHeader"
        visible={warningDialog}
        style={{ width: "50vw" }}
        onHide={() => setWarningDialog(false)}
      >
        <div className="grid">
          <div className="col">
            <p className="warningDialog-paraHeader font-semibold pt-4">
              Note:{" "}
            </p>
            <p>
              While you can continue without these sections being complete, note
              that we <span className="font-semibold">strongly</span> advise
              against doing so for compliance reasons unless{" "}
              <span className="font-semibold">absolutely</span> necessary
            </p>
            <p>Review your registration compliance check below: </p>
          </div>
        </div>
        <div className="grid">
          <div className="md:col-6 col-12">
            <label>Personal Profile</label>
            <div className="completestatus status-label">
              <label className="mb-0">Completed</label>
            </div>
          </div>
          <div className="md:col-6 col-12">
            <label>KYC & AML Checks</label>
            <div
              className={`${
                formik.values.isKYCCompleted ? "completestatus" : "reject"
              }  status-label`}
            >
              <label className="mb-0">
                {formik.values.isKYCCompleted ? "Completed" : "Incomplete"}{" "}
              </label>
            </div>
          </div>

          {sponsorData?.[0].fundType !== "506b" &&
            sponsorData?.[0].fundType?.toLowerCase() !== "reg cf" && (
              <div className="md:col-6 col-12">
                <label>Accreditation</label>
                <div
                  className={`${
                    formik.values.isAccreditationCompleted
                      ? "completestatus"
                      : "reject"
                  }  status-label`}
                >
                  <label className="mb-0">
                    {formik.values.isAccreditationCompleted
                      ? "Completed"
                      : "Incomplete"}{" "}
                  </label>

                  {/* accountType */}
                </div>
              </div>
            )}

          <div className="md:col-6 col-12">
            <label>Investing Account Information</label>
            <div
              className={`${
                formik.values.accountType ? "completestatus" : "reject"
              }  status-label`}
            >
              <label className="mb-0">
                {formik.values.accountType ? "Completed" : "Incomplete"}{" "}
              </label>
            </div>
          </div>
          <div className="md:col-6 col-12">
            <label>Fund Legal Documents</label>
            <div
              className={`${
                formik.values.isFundLegalDocumentsCompleted
                  ? "completestatus"
                  : "reject"
              }  status-label`}
            >
              <label className="mb-0">
                {formik.values.isFundLegalDocumentsCompleted
                  ? "Completed"
                  : "Incomplete"}{" "}
              </label>
            </div>
          </div>
        </div>
        <div className="grid mt-2">
          <div className="md:col-6 col-12">
            <Button
              className="BtnBlue w-full"
              onClick={(e) => {
                e.preventDefault();
                setWarningDialog(false);
              }}
            >
              Back to Review
            </Button>
          </div>
          <div className="md:col-6 col-12">
            <Button
              className="BtnBlue w-full"
              onClick={(e) => {
                e.preventDefault();
                formik.setFieldValue("isApprove", 1);
                formik.setFieldValue("isConfimredAndApproved", false);
                setWarningDialog(false);
                formik.handleSubmit();
              }}
            >
              Confirm & Approve User
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Enroll;
