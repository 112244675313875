import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import { RiTeamFill } from "react-icons/ri";
import Loader from "../../../components/loader/Loader";
import { GetConsolidatedDashboardDetailsThunk } from "../../../store/features/reports/getconsolidateddashboarddetails/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import currencyFormat from "../../../utils/currency-formatter";
import { convertUTCToLocalDate } from "../../../utils/date-formatter";

export default function ConsolidatedPlatformDashboard() {
  const dispatch = useAppDispatch();
  const [loading, setloading] = useState<any>(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const consolidateDashboard = useAppSelector((state) => {
    return state.GetConsolidatedDashboardDetails.data;
  });

  useEffect(() => {
    if (fundDropdown.fundid !== undefined) {
      const req = {
        LegalEntityId: fundDropdown.fundid,
      };
      setloading(true);

      dispatch(GetConsolidatedDashboardDetailsThunk(req))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    }
  }, [fundDropdown.fundid]);

  console.log("consolidateDashboard", consolidateDashboard?.[2]);
  // const formatDate = (dateString: string | number | Date) => {
  //   const date = new Date(dateString);

  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
  //   const year = String(date.getFullYear());

  //   return `${day}-${month}-${year}`;
  // };
  //   console.log("Supplier", JSON.parse(Supplier));
  return (
    <>
      <div className="grid">
        {loading && <Loader />}

        <div className=" col-12">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Consolidated Platform Dashboard</h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className="card my-3 tabWithoutboxshadow">
            <Card>
              <div className="grid mb-3">
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon ">
                      <RiTeamFill className="icons-wrap " />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader mb-1">
                        # of funds
                      </p>
                      {consolidateDashboard?.map((item: any, key: any) => {
                        console.log("item", item);
                        return (
                          <div
                            key={key}
                            className="d-flex justify-content-center"
                          >
                            {" "}
                            <p className="acc-value-details-textValue">
                              {item[0].leCount}{" "}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon ">
                      <RiTeamFill className="icons-wrap " />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader d-flex justify-content-center mb-1">
                        Fund AUM
                      </p>
                      <div className="d-flex justify-content-center">
                        {" "}
                        <p className="acc-value-details-textValue">
                          {consolidateDashboard
                            ? currencyFormat(
                                consolidateDashboard?.[1][0].fundAUM
                              )
                            : ""}
                          {/* {number[][]?.[2]?.map(())}
                            {consolidateDashboard?.[2]?.[0].investorAUM}{" "} */}
                        </p>
                      </div>
                      <div className="d-flex justify-content-center">
                        {" "}
                        <p className="acc-value-details-textValue">
                          <p className="acc-value-details-textValue">
                            DAV date :{"  "}
                            {consolidateDashboard
                              ? convertUTCToLocalDate(
                                  consolidateDashboard?.[1][0].maxDate
                                )
                              : ""}
                            {/* {number[][]?.[2]?.map(())}
                            {consolidateDashboard?.[2]?.[0].investorAUM}{" "} */}
                          </p>
                          {/* {item[0].maxDate}{" "} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon ">
                      <RiTeamFill className="icons-wrap " />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader d-flex justify-content-center mb-1">
                        Investor AUM
                      </p>
                      <div>
                        <div className="d-flex justify-content-center">
                          {" "}
                          <p className="acc-value-details-textValue">
                            {consolidateDashboard
                              ? currencyFormat(
                                  consolidateDashboard?.[2][0].investorAUM
                                )
                              : ""}
                            {/* {number[][]?.[2]?.map(())}
                            {consolidateDashboard?.[2]?.[0].investorAUM}{" "} */}
                          </p>
                        </div>
                        <div className="d-flex justify-content-center">
                          {" "}
                          <p className="acc-value-details-textValue">
                            <p className="acc-value-details-textValue">
                              DAV date :{"  "}
                              {consolidateDashboard
                                ? convertUTCToLocalDate(
                                    consolidateDashboard?.[2][0].maxDate
                                  )
                                : ""}
                              {/* {number[][]?.[2]?.map(())}
                            {consolidateDashboard?.[2]?.[0].investorAUM}{" "} */}
                            </p>
                            {/* {item[0].maxDate}{" "} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon ">
                      <RiTeamFill className="icons-wrap " />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader d-flex justify-content-center mb-1">
                        # of investors
                      </p>
                      <div>
                        <div className="d-flex justify-content-center">
                          {" "}
                          <p className="acc-value-details-textValue">
                            {consolidateDashboard
                              ? consolidateDashboard?.[3][0].invCount
                              : ""}
                            {/* {number[][]?.[2]?.map(())}
                            {consolidateDashboard?.[2]?.[0].investorAUM}{" "} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid mb-3">
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon ">
                      <RiTeamFill className="icons-wrap " />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader d-flex justify-content-center mb-1">
                        # of fund operation users
                      </p>
                      <div>
                        <div className="d-flex justify-content-center">
                          {" "}
                          <p className="acc-value-details-textValue">
                            {consolidateDashboard
                              ? consolidateDashboard?.[4][0].userCount
                              : ""}
                            {/* {number[][]?.[2]?.map(())}
                            {consolidateDashboard?.[2]?.[0].investorAUM}{" "} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon ">
                      <RiTeamFill className="icons-wrap " />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader d-flex justify-content-center mb-1">
                        # of registrations in process
                      </p>
                      <div>
                        <div className="d-flex justify-content-center">
                          {" "}
                          <p className="acc-value-details-textValue">
                            {consolidateDashboard
                              ? consolidateDashboard?.[5][0].regProcessCount
                              : ""}
                            {/* {number[][]?.[2]?.map(())}
                            {consolidateDashboard?.[2]?.[0].investorAUM}{" "} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon ">
                      <RiTeamFill className="icons-wrap " />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader d-flex justify-content-center mb-1">
                        # of deals
                      </p>
                      <div>
                        <div className="d-flex justify-content-center">
                          {" "}
                          <p className="acc-value-details-textValue">
                            {consolidateDashboard
                              ? consolidateDashboard?.[6][0].dealsCount
                              : ""}
                            {/* {number[][]?.[2]?.map(())}
                            {consolidateDashboard?.[2]?.[0].investorAUM}{" "} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon ">
                      <RiTeamFill className="icons-wrap " />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader d-flex justify-content-center mb-1">
                        # of investments
                      </p>
                      <div>
                        <div className="d-flex justify-content-center">
                          {" "}
                          <p className="acc-value-details-textValue">
                            {consolidateDashboard
                              ? consolidateDashboard?.[7][0].investmentCount
                              : ""}
                            {/* {number[][]?.[2]?.map(())}
                            {consolidateDashboard?.[2]?.[0].investorAUM}{" "} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid mb-3">
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon ">
                      <RiTeamFill className="icons-wrap " />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader d-flex justify-content-center mb-1">
                        # number of soft commits
                      </p>
                      <div>
                        <div className="d-flex justify-content-center">
                          {" "}
                          <p className="acc-value-details-textValue">
                            {consolidateDashboard
                              ? consolidateDashboard?.[8][0].softCommitCount
                              : ""}
                            {/* {number[][]?.[2]?.map(())}
                            {consolidateDashboard?.[2]?.[0].investorAUM}{" "} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon ">
                      <RiTeamFill className="icons-wrap " />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader d-flex justify-content-center mb-1">
                        # value of soft commits
                      </p>
                      <div>
                        <div className="d-flex justify-content-center">
                          {" "}
                          <p className="acc-value-details-textValue">
                            {consolidateDashboard
                              ? currencyFormat(
                                  consolidateDashboard?.[9][0].softCommitValue
                                )
                              : ""}
                            {/* {number[][]?.[2]?.map(())}
                            {consolidateDashboard?.[2]?.[0].investorAUM}{" "} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
