import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { RiAddFill, RiExternalLinkFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
// import { goToTop } from "../../../../components/scroll-to/ScrollTo";
import { getThunkDropdown } from "../../../../store/features/dropdown/dropdown-list/slice";
import {
  deleteThunkInvestmentFile,
  getThunkInvestmentFilebyProductid,
  uploadThunkInvestmentFile,
} from "../../../../store/features/investment/upload-file/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { isValidUrlRegexNew } from "../../../../utils/investing-approval/multi-investing-account/validation";
import {
  errorToastMessage,
  // infoToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

// import { setForm } from "../../../../../store/features/investment/create/slice";

interface IProps {
  pageName: string;
  handlePrevious?: any;
  handleNext?: any;
  offering?: any;
}

export const FormDocument = (props: IProps) => {
  const fileRef = useRef<any>(null);
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const [visibleViewUpload, setVisibleViewUpload] = useState(false);
  const [docLink, setDocLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [productdocLink, setproductdocLink] = useState("");
  const stateInvestment = useAppSelector((state) => state.addInvestment);
  const stateDropDownLists = useAppSelector((state) => state.dropdownList.data);
  const uplodedFiles = useAppSelector((state) => state.uplodedFiles.data);

  const [fileState, setFileState] = useState<any>();
  const [contentType, setContentType] = useState("");
  // const [compress, setCompress] = useState(false);
  const [productImgId, setProductImgId] = useState(0);
  const [DocName, setDocName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [add, setAdd] = useState(true);
  const parameters = {
    legalEntityID: 3,
    supplierID: 3,
  };
  useEffect(() => {
    dispatch(getThunkDropdown(parameters));
  }, []);

  const regexTest = /(.*?).(pdf|png|jpeg|txt|jpg)$/;
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const confirmDeleteDocument = (rowData?: any) => {
    // const param = {
    //   prodImgId: rowData.prodImgId,
    //   isDoc: true,
    // };
    setLoading(true);
    setOpenDialog(false);
    const param = {
      prodImgId: productImgId,
      isDoc: true,
    };
    dispatch(deleteThunkInvestmentFile(param)).then((response) => {
      if (response.meta.requestStatus === "rejected") {
        errorToastMessage(toast);
        setLoading(false);
      } else if (response.meta.requestStatus === "fulfilled") {
        if (response.payload.success === false) {
          errorToastMessage(toast, "File not Deleted");
          setLoading(false);
        } else {
          successToastMessage(toast, "File Deleted");
          setProductImgId(0);
          setLoading(false);
        }
      }
    });
  };

  const deleteButton = (rowData: any) => {
    setOpenDialog(true);
    setProductImgId(rowData.prodImgId);
  };

  const formik = useFormik({
    initialValues: {
      documentType: "",
      documentDescription: "",
      mediaType: "File",
      files: undefined,
      documentUrl: "",
    },
    validate: (data) => {
      //
      const errors: any = {};
      if (!data.documentType) {
        errors.documentType = "Select a document type ";
      }
      if (!data.documentDescription) {
        errors.documentDescription = "Select a document description";
      }
      if (
        data?.mediaType !== "File" &&
        data.documentUrl &&
        !isValidUrlRegexNew(data.documentUrl)
      ) {
        errors.documentUrl = "Enter a valid url";
      }
      if (!data.mediaType) {
        errors.mediaType = "Select a media type";
      }

      return errors;
    },
    onSubmit: (data) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      uploadDoc();
    },
  });

  useEffect(() => {
    setLoading(true);
    if (stateInvestment.data !== undefined) {
      const param = {
        ProductId: stateInvestment.data?.productID,
        IsDoc: true,
      };
      dispatch(getThunkInvestmentFilebyProductid(param)).then((response) => {
        if (response.meta.requestStatus === "rejected") {
          errorToastMessage(toast);
          setLoading(false);
        } else if (response.meta.requestStatus === "fulfilled") {
          setLoading(false);
        }
      });
    }
  }, [stateInvestment]);

  useEffect(() => {
    if (uplodedFiles !== undefined) {
      const newFile = uplodedFiles?.filter(
        (image) =>
          image.docTypeId !== 20 &&
          image.docTypeId !== 41 &&
          image.docTypeId !== 21
      );
      setFileState(newFile);
    }
  }, [uplodedFiles]);

  const uploadDoc = async (fileuploaded?: any) => {
    setLoading(true);
    if (
      (formik.values.files === undefined || formik.values.files === "") &&
      docLink === ""
    ) {
      if (formik.values.mediaType === "File") {
        errorToastMessage(toast, "Please select file");
      } else {
        errorToastMessage(toast, "Please enter url");
      }

      setLoading(false);
      return;
    }

    if (
      formik.values.documentType !== "" &&
      formik.values.documentDescription !== "" &&
      formik.values.mediaType !== ""
    ) {
      const data = new FormData();
      setOpenAddDialog(false);
      if (formik.values.mediaType === "File") {
        data.append(
          "files",
          formik.values.files === undefined ? " " : formik.values.files
        );
        data.append("contentType", contentType);
      }
      // else {
      //   data.append("files", "");
      // }
      data.append("documentTypeId", formik.values.documentType.toString());
      data.append("mediaType", formik.values.mediaType.toString());
      data.append("doNotCompress", "false");
      data.append("prodImgId", productImgId.toString());
      data.append("docDesc", formik.values.documentDescription);
      data.append("docExtension", "");
      data.append("documentSize", "0");
      data.append("createdBy", profileData.userID.toString());
      data.append("imageIndex", "0");
      data.append("documentLink", productdocLink);
      data.append("documentName", DocName);
      data.append("isDoc", "true");

      if (stateInvestment.data !== undefined) {
        data.append(
          "legalEntityID",
          stateInvestment.data.legalEntityID.toString()
        );
        data.append("productId", stateInvestment.data.productID.toString());
      }

      try {
        await dispatch(uploadThunkInvestmentFile(data)).then((response) => {
          console.log("resp", response);
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (response.meta.requestStatus === "fulfilled") {
            if (response.payload.success === false) {
              errorToastMessage(toast, "File not Uploaded");
            } else {
              if (formik.values.mediaType === "File") {
                successToastMessage(toast, "File Uploaded");
              } else {
                successToastMessage(toast, "Url Uploaded");
              }
              setProductImgId(0);
            }
          }
        });
        formik.resetForm();
        setAdd(true);
        formik.setFieldValue("documentType", "");

        formik.setFieldValue("documentDescription", "");
        formik.setFieldValue("files", "");
        setDocLink("");
        setproductdocLink("");
        setContentType("");
        setDocName("");
        setLoading(false);

        // fileRef?.current.clear();
      } catch (error) {
        // fileRef?.current.clear();
        formik.resetForm();

        setAdd(true);
        console.log("Opps something went wrong", error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const actionBodyEditTemplate = (rowData: any) => {
    const editProduct = (rowDataobj: any) => {
      setOpenAddDialog(true);
      setAdd(false);
      console.log("iop", rowData);
      formik.values.documentType = rowData?.docTypeId.toString();
      formik.values.documentDescription = rowData?.docDesc;
      if (rowData.contentType === null) {
        setproductdocLink(rowData.viewLink);
        formik.setFieldValue("mediaType", "URL");
      } else {
        setproductdocLink(rowData.viewLink);
        formik.setFieldValue("mediaType", "File");
        setDocName(rowData?.documentName);
      }

      setContentType(rowData.contentType);
      setProductImgId(rowData?.prodImgId);
      setDocLink(rowData?.viewLink);

      formik.setFieldValue("files", rowData.viewLink);
      // goToTop();
    };
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className=" p-button-rounded p-button-text iconButton"
          onClick={() => {
            setDocName("");
            editProduct(rowData);
          }}
          type="button"
        />
      </React.Fragment>
    );
  };
  const actionBodyDeleteTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text  iconButton"
          onClick={(e) => {
            e.preventDefault();
            deleteButton(rowData);
          }}
        />
      </React.Fragment>
    );
  };
  const actionBodyViewTemplate = (rowData: any) => {
    const viewUploadedFile = (rowDataObj: any) => {
      setVisibleViewUpload(true);
      setDocLink(rowData.viewLink);
    };
    return (
      <React.Fragment>
        {rowData.contentType !== null ? (
          <div className="icons-Link">
            <Button
              icon="pi pi-eye"
              className="p-button-rounded p-button-text iconButton "
              onClick={(e) => {
                e.preventDefault();
                viewUploadedFile(rowData);
              }}
            />
          </div>
        ) : (
          <NavLink
            to={
              rowData.viewLink === null
                ? ""
                : rowData?.viewLink.startsWith("http")
                ? rowData.viewLink
                : `http://${rowData.viewLink}`
            }
            target={rowData.viewLink === null ? undefined : "_blank"}
            className="icons-Link"
          >
            <div>
              <RiExternalLinkFill />
            </div>
          </NavLink>
        )}
      </React.Fragment>
    );
  };
  const DocumentName = (rowData: any) => {
    return (rowData.documentName === "" || rowData.documentName === null) &&
      rowData.contentType === null
      ? rowData.productLink
      : rowData.documentName;
  };
  const dataElement = [
    {
      header: "View",
      body: actionBodyViewTemplate,
    },
    {
      field: "documentName",
      header: "Document Name",
      body: DocumentName,
    },
    {
      field: "documentType",
      header: "Document Type",
    },
    {
      field: "docDesc",
      header: "Description",
    },
    {
      header: "",
      body: actionBodyEditTemplate,
      className: "doctbleCenterTxt",
      // bodyClassName: "text-align-center",
    },
    {
      header: "",
      className: "doctbleCenterTxt",
      body: actionBodyDeleteTemplate,
    },
  ];

  const uploadFile = async (e: any) => {
    console.log("file", e);
    const file = e.files[0];
    const regex =
      /(.*?).(docx|doc|pdf|xml|bmp|ppt|xls|png|jpeg|xlsx|txt|pptx|rtf|jpg)$/;

    if (!regex.test(file.name.toLowerCase())) {
      errorToastMessage(
        toast,
        "Upload failed. Please Use Images,Excel,Word,Power points, Plain Text Documents format only"
      );
      fileRef?.current.clear();
      return;
    }

    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 100) {
      errorToastMessage(
        toast,
        "Upload failed. Please ensure the file size does not exceed 100mb"
      );
      fileRef?.current.clear();
      return;
    }
    setDocName(file.name);
    // infoToastMessage(toast, "Please save the selected file");
    formik.setFieldValue("files", file);
    setContentType(file.type);
    setDocLink(URL.createObjectURL(file));

    fileRef?.current.clear();
  };
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "mediaType") {
      setDocLink("");
      setproductdocLink("");
      setDocName("");
      formik.setFieldValue("files", "");

      if (fileRef.current !== null) {
        fileRef?.current.clear();
        formik.setFieldValue("files", "");
      }
    }
    formik.setFieldValue(name, value);
  };
  // const showDocumentName = () => {
  //   if (DocName !== "") {
  //     if (DocName.length > 15) {
  //       const truncatedName = DocName.slice(0, 15) + "...";
  //       const extension = DocName.split(".").pop(); // Get the file extension
  //       return `${truncatedName}.${extension}`;
  //     } else {
  //       return DocName;
  //     }
  //   }
  // };
  const onHideAddDocument = () => {
    console.log("test");
    setOpenAddDialog(false);
    setAdd(true);
    formik.resetForm();
    setDocLink("");
    setproductdocLink("");
    setContentType("");
    setDocName("");
    setLoading(false);
    setProductImgId(0);
    formik.setFieldValue("documentType", "");
    formik.setFieldValue("documentDescription", "");
    formik.setFieldValue("documentUrl", "");
    formik.setFieldValue("mediaType", "File");
    formik.setFieldValue("files", "");

    // fileRef?.current.clear();
  };
  useEffect(() => {}, []);

  const onCancel = () => {
    setOpenDialog(false);
    setProductImgId(0);
  };

  console.log("docLink", docLink);
  return (
    <>
      {loading ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <ConfirmDailogbox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onConfirm={confirmDeleteDocument}
        onCancel={onCancel}
        textContent="Are you sure you want to delete ?"
      />

      <Dialog
        header=""
        // maximizable
        visible={visibleViewUpload}
        // style={{ width: "50vw" }}
        className="dialogClassWidth"
        style={window.innerWidth > 600 ? { width: "50%" } : { width: "100%" }}
        onHide={() => {
          setVisibleViewUpload(false);
        }}
        closable={true}
      >
        <div className="m-0">
          {!docLink.includes("jpeg") &&
          !docLink.includes("jpg") &&
          !docLink.includes("png") &&
          !docLink.includes("pdf") &&
          !docLink.includes("txt") &&
          !regexTest.test(DocName.toLowerCase()) ? (
            <div className="downloading flex justify-content-center">
              The File is Downloading!!!
            </div>
          ) : (
            <></>
          )}

          {!docLink.includes("jpeg") &&
          !docLink.includes("jpg") &&
          !docLink.includes("png") ? (
            <iframe
              src={docLink}
              allowFullScreen
              style={{ width: "100%", height: "25vh" }}
            ></iframe>
          ) : (
            <img src={docLink} alt={"Doc Name"} style={{ width: "100%" }} />
          )}
        </div>
      </Dialog>
      <Dialog
        visible={openAddDialog}
        className="w-100 md:w-4"
        onHide={() => onHideAddDocument()}
        header={add === true ? "Add Document" : "Edit Document"}
        headerClassName="text-center flex justify-content-center align-item-center pageHeader display-1"
        contentClassName="top-lr-content p-0"
      >
        <div className="m-0">
          <hr className="m-0" />
          <form>
            <div className="mt-1">
              <div className="d-block sm:d-flex sm:flex-column  col-12">
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6">
                    <label className="inputLabel">Document Type</label>

                    <Dropdown
                      inputId="documentType"
                      name="documentType"
                      value={formik.values.documentType}
                      options={
                        stateDropDownLists === null
                          ? []
                          : stateDropDownLists.documentsTypes
                      }
                      filter
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select Document Type"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("documentType"),
                        "card-inputBox": true,
                      })}
                    />
                    {getFormErrorMessage("documentType")}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6">
                    <label className="inputLabel">Document Description</label>
                    <InputText
                      name="documentDescription"
                      value={formik.values.documentDescription}
                      onChange={(e) => handleChange(e)}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("documentDescription"),
                        "card-inputBox": true,
                      })}
                      placeholder="Enter Description"
                    />

                    {getFormErrorMessage("documentDescription")}
                  </div>
                </div>
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6">
                    <label className="inputLabel">Media Type</label>

                    <Dropdown
                      inputId="mediaType"
                      name="mediaType"
                      value={formik.values.mediaType}
                      options={
                        stateDropDownLists === null
                          ? []
                          : stateDropDownLists.mediaTypes
                      }
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select Media Type"
                      // className="card-inputBox"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("mediaType"),
                        "card-inputBox": true,
                      })}
                    />

                    {getFormErrorMessage("mediaType")}
                  </div>

                  {formik.values.mediaType === "URL" ? (
                    <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6">
                      <label className="inputLabel">Document Url</label>
                      <InputText
                        name="documentUrl"
                        value={productdocLink}
                        onChange={(e: any) => {
                          setproductdocLink(e.target.value);
                          setDocLink(e.target.value);
                          handleChange(e);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("documentUrl"),
                          "card-inputBox": true,
                        })}
                        // className="card-inputBox"
                        placeholder="Enter Document Url"
                      />

                      {getFormErrorMessage("documentUrl")}
                    </div>
                  ) : (
                    <>
                      <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6 flex justify-content-center">
                        <FileUpload
                          mode="basic"
                          name="demo"
                          ref={fileRef}
                          auto={true}
                          // maxFileSize={3_000_000}
                          customUpload={true}
                          chooseLabel="Select file"
                          className="btn-dialog border-round-md w-100 UploadAccreBtn"
                          uploadHandler={uploadFile}
                        />
                      </div>

                      <div className="d-flex flex-column p-2 col-12 align-self-end">
                        {DocName === "" || DocName === undefined ? (
                          <></>
                        ) : (
                          <label className="inputLabel">
                            File Name:{" "}
                            <NavLink
                              className="document-link"
                              onClick={(e) => {
                                e.preventDefault();
                                setVisibleViewUpload(true);
                              }}
                              to={""}
                            >
                              {DocName}
                            </NavLink>
                          </label>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="d-block sm:d-flex sm:flex-column  col-12">
                <div className="flex flex-row flex-wrap sm:flex-none justify-content-center col-12 ">
                  <div className="d-flex flex-column r p-2 col-12 sm:col-5 annualClass">
                    <Button
                      className="btn-dialog "
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        // setOpenAddDialog(true);
                      }}
                      disabled={
                        (formik.values.files === undefined ||
                          formik.values.files === "") &&
                        productdocLink === ""
                      }
                    >
                      <span className="ml-auto mr-auto ">Add Document</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      <form>
        <div className={props.pageName === "viewDetails" ? "mt-2" : "mt-4"}>
          <div style={{ minHeight: "400px" }}>
            <label className="inputLabel inputLabelColor ml-2">
              Upload Document
            </label>

            <div
              className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
              style={{ height: "auto" }}
            >
              <div className="d-block sm:d-flex sm:flex-column  col-12 ml-2">
                <div className="flex flex-row flex-wrap sm:flex-none col-12 ">
                  <div className="d-flex flex-column  p-2 ">
                    <Button
                      className="btn-dialog"
                      onClick={(e) => {
                        e.preventDefault();
                        // formik.handleSubmit();
                        // uploadDoc();
                        setOpenAddDialog(true);
                      }}
                    >
                      <span className="ml-auto mr-auto ">Add Document</span>
                      <span className="p-2">
                        <RiAddFill />
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <label className="inputLabel inputLabelColor ml-2 mt-4 ">
              View/Edit Documents
            </label>

            <div
              className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
              style={{ height: "auto" }}
            >
              <div
                className="d-block sm:d-flex sm:flex-column col-12 sm:col-12 ml-2"
                // style={{ height: "auto" }}
              >
                <div className="ml-3">
                  <DataTableComponent
                    valueData={fileState !== null ? fileState : []}
                    fieldsElements={dataElement}
                    isPaginationAllowed={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {props.pageName === "viewDetails" ? (
            <></>
          ) : (
            <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
              <div className="d-flex flex-column p-2 col-12 sm:col-4 annualClass">
                <Button
                  className="btn-nav btnFocus"
                  onClick={(e) => {
                    e.preventDefault();
                    props.handlePrevious();
                  }}
                  disabled
                >
                  <span className="ml-auto mr-auto ">Back</span>
                </Button>
              </div>
              <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                <Button
                  className="btn-dialog btnFocus"
                  onClick={(e) => {
                    e.preventDefault();
                    props.handleNext();
                  }}
                >
                  <span className="ml-auto mr-auto ">
                    {fileState?.length > 0 ? "Next" : "Skip"}
                  </span>
                </Button>
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
};
