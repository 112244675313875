import { Button } from "primereact/button";
import React, { useState } from "react";
// import { RiSearchLine } from "react-icons/ri";
import DailyAum from "./DailyAum";
import ReverseDailyAum from "./ReverseDailyAum";

const AssetsUnderManagement = () => {
  // Access query parameters by their names
  const [dailyAmu, setDailyAmu] = useState(true);
  const [reverseAmu, setReverseAmu] = useState(false);

  return (
    <div>
      <div className="flex flex-row flex-wrap sm:flex-none col-12">
        <div className="d-flex flex-column p-2 xl:col-1 md:col-1 sm:col-2 flex justify-content-center">
          Show:
        </div>
        <div className="d-flex flex-column p-2 xl:col-2 md:col-2 sm:col-2">
          <Button
            className={`${dailyAmu ? "btn-navActive" : "btn-nav"}  mt-2`}
            onClick={(e) => {
              e.preventDefault();
              setDailyAmu(true);
              setReverseAmu(false);
            }}
          >
            <span className="ml-auto mr-auto ">Calculate AUM</span>
          </Button>
        </div>
        <div className="d-flex flex-column p-2 xl:col-2 md:col-3 sm:col-3">
          <Button
            // className={"btn-nav mt-2"}
            className={`${reverseAmu ? "btn-navActive" : "btn-nav"}  mt-2`}
            onClick={(e) => {
              e.preventDefault();

              setDailyAmu(false);
              setReverseAmu(true); // navigate("/investments");
            }}
          >
            <span className="ml-auto mr-auto ">Review Investor AUM</span>
          </Button>
        </div>
        {/* <div className="d-flex flex-column p-2 xl:col-2 md:col-3 sm:col-3"> */}
      </div>
      <div className="grid">
        {dailyAmu ? <DailyAum /> : <ReverseDailyAum />}
      </div>
    </div>
  );
};

export default AssetsUnderManagement;
