import { useMsal } from "@azure/msal-react";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { loginRequest, b2cPolicies } from "../../b2c/b2c-config";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { convertToLowerCase } from "../../utils/helper";
import DefaultLogin from "../features/b2c/login/DefaultLogin";
import { isFundExistThunk } from "./../../store/features/fund-exist/slice";

const Signup = () => {
  const { instance } = useMsal();
  const { slug } = useParams();
  const dispatch = useAppDispatch();

  const queryParams = new URLSearchParams(window.location.search);

  let email: any;

  if (queryParams.has("email")) {
    console.log("signup========================");
    const encodedEmail = queryParams.get("email");

    const decodedEmail = encodeURIComponent(encodedEmail!);
    email = decodedEmail;
    // email = encodedEmail1;
    console.log("encodeURIComponent Email:", email);
  } else {
    console.log("Email not found in query parameters.");
  }

  //  Get Tenant name via url
  const isFundExist = useAppSelector((state) => state.isFundExist.data);
  const loading = useAppSelector((state) => state.isFundExist.loading);
  console.log("loading", loading);
  console.log("isFundExist", isFundExist);
  console.log("sluggg ====", slug);

  const tenantName = slug;

  localStorage.setItem("tenentName", convertToLowerCase(slug!));

  console.log("Decoded Email:", email);

  useEffect(() => {
    dispatch(isFundExistThunk(""));
  }, []);

  useEffect(() => {
    if (isFundExist) {
      const loginRequest1 = {
        authority: b2cPolicies.authorities.signUp.authority,
        // extraQueryParameters: { tenant: tenantName!, email: email! },
        extraQueryParameters: { tenant: tenantName!, email: email! },
        ...loginRequest,
      };

      instance
        .loginRedirect(loginRequest1)
        .then((res) => {
          alert(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isFundExist]);

  return (
    <>
      {loading === "succeeded" ? (
        isFundExist ? (
          <ProgressSpinner />
        ) : (
          <DefaultLogin />
        )
      ) : (
        <ProgressSpinner />
      )}
    </>
  );
};
export default Signup;
