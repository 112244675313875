export const thirdPartyApiData = {
  // action: "",
  // fundLPEntityId: 0,
  hubSpotAPIKey: "",
  chargebeeID: "",
  chargebeeSubscriptionID: "",
  chargebeePlanID: "",
  activeCampaignAccountName: "",
  activeCampaigApiToken: "",
  goHighLevelAPIToken: "",
};

export const fundManageApi = {
  fundTypeId: 0,
  fundInitialSetupDate: "",
  investorSignUp: false,
  showFilledDeals: false,
  entityType: "3",
  fundSlug: "",
  fundName: "",
  registeredState: "",
  primaryFundEmail: "",
  ein: "",
  phoneNumber: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  unitPrice: 0,
  digisignerPPMTempId: "",
  dualSigner: false,
  signFundPPM: true,
  fundWebsite: "",
  emailLogoUrl: "",
  fundPrimaryColour: "#004d82",
  gpllcName: "",
  gpEntityType: "2",
  gpRegisteredState: "",
  gpEin: "",
  gpAddress: "",
  gpCity: "",
  gpState: "",
  gpZipCode: "",
  fundGPEmail: "",
};

export const bankMailInfoData = {
  bankName: "",
  bankAddress: "",
  beneficiaryAccountName: "",
  beneficiaryAddress: "",
  beneficiaryAccountNumber: "",
  achTransferABANumber: "",
  wireTransferABANumber: "",
  swiftbicNumber: "",
  checkPayableTo: "",
  checkAddress: "",
  checkCity: "",
  checkState: "",
  checkZipCode: "",
  checkCountry: "",
};

export const edgarFilingInfoData = {
  cikNumber: "",
  edgarFilingDate: "",
  edgarPassword: "",
  edgarPasswordExpiration: "",
  ccc: "",
  pmac: "",
  passphrase: "",
  SecAccessionNumber: "",
};

// export const fundManageApi = {
//   fundName: "",
//   fundTypeId: "",
//   isAllowSignUp: false,
//   showFilledDeals: false,
//   fundEntityTypeId: 0,
//   fundState: "",
//   addressFundState: "",
//   fundEIN: "",
//   lpAddress: "",
//   lpCity: "",
//   lpZipCode: "",
//   urlName: "",
//   logoUrl: "",
//   fundWebsite: "",
//   mobile: "",
//   digiTemplateID: "",
//   isDualSigner: false,
//   isPPMSigner: false,
//   unitPrice: 0,
//   primaryFundEmail: "",
//   fundEmail: "",
//   fundPassword: "",
//   gpllcName: "",
//   gpEntityType: 0,
//   gpState: "",
//   addressGPState: "",
//   gpein: "",
//   gpAddress: "",
//   gpCity: "",
//   gpZipCode: "",
//   gpEmail: "",
//   gpPassword: "",
//   fundPrimaryColour: "",
// };
