import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { addInvestmentStep1Service } from "../../../../services/investment/investment";
import { getInvestmentByFilterService } from "../../../../services/investment/investment";
import error from "../../../../utils/error";
import { IFilterInvestmentParameter, IFilterInvestmentState } from ".";
// import { IAddInvestmentState, IInvestmentData } from ".";

const initialState: IFilterInvestmentState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkInvestmentByFilter = createAsyncThunk(
  "getInvestmentFilter",
  async (data: IFilterInvestmentParameter) => {
    try {
      console.log("lo", data);
      const response = await getInvestmentByFilterService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getInvestmentFilter",
  initialState,
  reducers: {
    resetInvestmentStateFilter(state) {
      state.data = [];
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkInvestmentByFilter.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkInvestmentByFilter.fulfilled, (state, action) => {
      state.loading = "succeeded";

      state.data =
        action.payload.data !== null
          ? action.payload.data.investmentsAndDeals
          : [];
      state.message =
        action.payload.message === null ? undefined : action.payload.message;

      // console.log("state.data 1", state.data);
    });
    builder.addCase(getThunkInvestmentByFilter.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});
export const { resetInvestmentStateFilter } = slice.actions;
export default slice.reducer;
