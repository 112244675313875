import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IGetSetupFundListState } from "..";
import { CanceltransferService } from "../../../services/Dwolla/dwolla";
import error from "../../../utils/error";

const initialState: IGetSetupFundListState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};

export const CanceltransferThunk = createAsyncThunk(
  "dwollaTransfercancel/cancel",
  async (data: any) => {
    try {
      const response = await CanceltransferService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "dwollaTransfercancel/cancel",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(CanceltransferThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(CanceltransferThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data[0] : [];
    });
    builder.addCase(CanceltransferThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
