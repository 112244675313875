// import { InteractionStatus } from "@azure/msal-browser";
// import { useMsal } from "@azure/msal-react";
// import { Button } from "primereact/button";
// import { InputText } from "primereact/inputtext";
import axios from "axios";
import { TieredMenu } from "primereact/tieredmenu";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import {
  RiUserShared2Line,
  RiUserSettingsLine,
  RiLogoutCircleLine,
  // RiNotificationLine,
  // RiHeadphoneFill,
  // RiFileZipLine,
  RiQuestionFill,
  RiCouponLine,
  RiQuestionnaireLine,
  RiTeamLine,
  RiRobotLine,
  // RiCustomerService2Line,
} from "react-icons/ri";
import "./Navbar.scss";
import {
  // NavLink,
  useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";
// import { getThunkPortfolioAssetDropdown } from "../../store/assetdropdown/slice";
import { setChatBotState } from "../../store/features/chat-bot-state/slice";
import { setColor } from "../../store/features/color/slice";
import { getThunkPortfolioNonRegisterInvestorListDropdown } from "../../store/features/dropdown/nonregisteredinvestorlist/slice";
import { getThunkPortfolioRegisterInvestorListDropdown } from "../../store/features/dropdown/registeredinvestorlist/slice";
import { getPortfolioGetLegalEntityList } from "../../store/features/dropdown/slice";
import { getUserProfileDetailsThunk } from "../../store/features/profile/slice";
import {
  setBannerState,
  setImageState,
  setUrlState,
} from "../../store/getasset/slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { navbarDropdown } from "../../store/navdropdown/slice";
import { getSasKeyThunk } from "../../store/sas-key/slice";

import { LightenDarkenColor, logoutHelper } from "../../utils/helper";
import { envVariable } from "../../utils/sponsor/helper";
import { errorToastMessage } from "../../utils/toast-message";
import DashboardDropdownComponent from "../dashboard-dropdown/DashboardDropdownComponent";

// import { msal } from "../msal/msal";
// import { msalInstance } from "../msal/msal";
// import { b2cPolicies } from "../../b2c/b2c-config";

let preventFirstTimeLoad = false;

export default function Navbar() {
  const navigate = useNavigate();
  const addCancelState = useAppSelector(
    (state) => state.addCancelSetupState.data
  );
  const profileData = useAppSelector((state) => state.profile.data![0]);
  console.log("profile", profileData);
  const changePassword = () => {
    // const token = localStorage.getItem("token");
    // if (token) {
    //   msal("changePassword", instance, tenentName, inProgress);
    // } else {
    //   const tenant = localStorage.getItem("tenentName");
    //   localStorage.removeItem("tenentName");
    //   localStorage.removeItem("token");
    //   msal("logout", instance, tenant, inProgress);
    // }
    navigate("/changePassword");
  };
  const logout = async () => {
    logoutHelper();
    // navigate("/logout");
  };

  // const accountStatement = () => {
  //   // navigate("accountStatement");
  //   navigate("accountStatement");

  //   // url: "/SignInvestmentDocuments",
  // };
  const videoRef = useRef<HTMLVideoElement>(null);

  const [leagalentity, setLeagalentity] = useState<any>();
  const [sayKey, setSayKey] = useState<any>("");

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const GetVideoLinkHere = useAppSelector(
    (state) => state.HelpVedioGetVideoLink?.data
  );

  const dispatch = useAppDispatch();
  const registerinvestorModel = useAppSelector(
    (state) => state.registerinvestor.data
  );

  const nonregisterinvestorModel = useAppSelector(
    (state) => state.nonregisterinvestor.data
  );
  const funddropdownModel = useAppSelector(
    (state) => state.legalEntityList.data
  );
  const location = window.location.pathname;

  const legalEntityList: { code: number; name: string; url: string }[] = [
    {
      code: -1,
      name: "",
      url: "",
    },
  ];

  if (
    (location === "/ConsolidatedPlatformDashboard" ||
      location === "/Report/OutstandingBlueskyFilings" ||
      location === "/Report/SoftCommitsByFund" ||
      location === "/Report/CurrentOpportunitiesAcrossFunds" ||
      location === "/Report/InvestorsVerifiedByAccreddReport") &&
    profileData.userTypeId === 1
  ) {
    legalEntityList.push({
      code: 0,
      name: "All Funds",
      url: "ariel",
    });
  }
  // const invRegistrationId = useAppSelector(
  //   (state) => state.PortfolioAssetDropdown.invRegistrationId
  // );
  // profileData[0]?.legalEntityID

  // console.log("hgcyhfunddropdownModel", funddropdownModel);

  const [registredinverstment, setregistredinverstment] = useState<any>();
  const [nonregistredinverstment, setnonregistredinverstment] = useState<any>();
  for (let i = 0; i < funddropdownModel?.length; i++) {
    legalEntityList.push({
      code: funddropdownModel![i].legalEntityID,
      name: funddropdownModel![i].legalEntityName,
      url: funddropdownModel![i].urlName,
    });
  }

  const registerinverstmenList: { code: number; name: string }[] = [];
  for (let i = 0; i < registerinvestorModel?.length; i++) {
    registerinverstmenList.push({
      code: registerinvestorModel![i].investorID,
      name: registerinvestorModel![i].investorName,
    });
  }
  const nonregisterinverstmenList: { code: number; name: string }[] = [];
  for (let i = 0; i < nonregisterinvestorModel?.length; i++) {
    nonregisterinverstmenList.push({
      code: nonregisterinvestorModel![i].investorID,
      name: nonregisterinvestorModel![i].investorName,
    });
  }
  // const LegalEntityparameters = {
  //   Email: "softlabsavestor@gmail.com",
  // };
  const generateSASKey = (legalEntityId: any) => {
    console.log("outside", legalEntityId);
    if (legalEntityId !== null && legalEntityId !== undefined) {
      console.log("inside", legalEntityId);
      return dispatch(getSasKeyThunk(legalEntityId))
        .then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            setSayKey(response.payload.data.sasKey);
            return response.payload.data.sasKey;
          }
        })
        .catch(() => {
          errorToastMessage(toast);
        });
    }
  };

  const fundColor = (
    url: any,
    fundid: number = 1,
    userProfileFlag: boolean = true
  ) => {
    const urls = localStorage.getItem("tenentName");
    dispatch(setUrlState(url));
    if (fundid !== 0 && userProfileFlag) {
      console.log("getUserProfileDetailsThunk Called multiple time");

      dispatch(getUserProfileDetailsThunk({ Slug: url }));
    }
    const BaseUrl = envVariable!.REACT_APP_API_ASSETS_SPNG_URL;
    // dispatch(getSasKeyThunk(leagalentity !== 0 ? leagalentity : 3)).then(
    //   (response: any) => {
    //     if (response.meta.requestStatus === "fulfilled") {
    // const key = response.payload.data.sasKey;
    // console.log("key", key);
    axios
      .get(
        `${BaseUrl}/Assets/getFundLogo?fundName=${urls}
            `
      )
      .then((resp) => {
        dispatch(setImageState(resp.data.response.data.blobURL));
        if (resp.status === 200) {
          // setLogoStatus(true);
        }
        // console.log(resp.data.response.data.blobURL);
      });
    //     }
    //   }
    // );

    axios
      .get(`${BaseUrl}/Assets/getFundBanner?fundName=${url}`)
      .then((resp) => {
        // setLoader(true);

        console.log("akanksha", resp.data.response.data.blobURL);
        dispatch(setBannerState(resp.data.response.data.blobURL));
        console.log(resp.data.response.data.blobURL);
        if (resp.status === 200) {
          // setImgStatus(true);
        }
      });
    axios
      .get(
        `${BaseUrl}/Assets/getFundColor?fundName=${url}
        `
      )
      .then((resp) => {
        // console.log("resp", resp);
        // setLoader(true);
        const color = resp.data.response.data.primaryColour;

        console.log("coooo", color);
        const myColorLightest = LightenDarkenColor(color, 200);
        const myColorLight = LightenDarkenColor(color, 50);
        const myColorDark = LightenDarkenColor(color, 20);
        const myColorDarkest = LightenDarkenColor(color, 80);
        // console.log("color", color);
        dispatch(
          setColor({
            Color: color,
            myColorLightest,
            myColorLight,
            myColorDark,
            myColorDarkest,
          })
        );
        const root = document.documentElement;

        // if (color) {
        //   // setLoader(false);
        //   root?.style.setProperty("--background-color", color);
        // } else {
        //   root?.style.setProperty("--background-color", "#004d82");
        // } // console.log(resp.data.response.data.blobURL);
        if (color) {
          root?.style.setProperty("--background-color", color);
          root?.style.setProperty("--lightest-color", myColorLightest);
          root?.style.setProperty("--lighter-color", myColorLight);
          root?.style.setProperty("--Darker-color", myColorDark);
          root?.style.setProperty("--Darkest-color", myColorDarkest);
          root?.style.setProperty("--noData-color", "#C8C8C8");
        } else {
          root?.style.setProperty("--noData-color", "#C8C8C8");
          root?.style.setProperty("--background-color", "#004D82");
          // root?.style.setProperty("--text-color", "#FF0000");
        }
      });
  };

  useEffect(() => {
    if (addCancelState.cancel === true) {
      const tenentName = localStorage.getItem("tenentName");
      (async () => {
        dispatch(getUserProfileDetailsThunk({ Slug: tenentName! })).then(
          (res: any) => {
            if (res.payload?._response?.data !== null) {
              const LegalEntityparameters = {
                Email: res.payload?._response?.data[0]?.emailID,
              };
              dispatch(
                getPortfolioGetLegalEntityList(LegalEntityparameters)
              ).then(() => {
                legalEntityList.map((item: any) => {
                  return (
                    item.code === leagalentity && fundColor(item.url, 1, false)
                  );
                });
              });
            }
          }
        );
      })();
    }
  }, [addCancelState.cancel]);

  useEffect(() => {
    setLeagalentity(profileData?.legalEntityID);
  }, [profileData?.legalEntityID]);

  useEffect(() => {
    if (fundDropdown?.fundid === 0 || fundDropdown?.fundid === undefined) {
      setLeagalentity(profileData?.legalEntityID);
      localStorage.setItem("tenentName", profileData?.legalEntityName);
    }
  }, [location, profileData?.legalEntityID]);

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid !== null) {
      if (preventFirstTimeLoad) {
        legalEntityList.map((item: any) => {
          return (
            item.code === leagalentity &&
            fundColor(item.url, fundDropdown.fundid)
          );
        });
      }
      preventFirstTimeLoad = true;
    }
  }, [fundDropdown.fundid]);

  useEffect(() => {
    const InvestorListparameters = {
      LegalEntityId: leagalentity,
    };

    if (leagalentity !== undefined) {
      dispatch(
        getThunkPortfolioRegisterInvestorListDropdown(InvestorListparameters)
      ).then((res: any) => {
        const dropdownparameter = {
          fundid: leagalentity,
          registerid: res.payload.data.investorList
            ? res.payload.data.investorList[0].investorID
            : 0,
          nonregisterid: 0,
        };

        dispatch(navbarDropdown(dropdownparameter));
      });
      dispatch(
        getThunkPortfolioNonRegisterInvestorListDropdown(InvestorListparameters)
      );
    }
  }, [leagalentity]);

  useEffect(() => {
    const dropdownparameter = {
      fundid: leagalentity,
      registerid: registredinverstment,
      nonregisterid: 0,
    };
    dispatch(navbarDropdown(dropdownparameter));
  }, [registredinverstment]);

  useEffect(() => {
    const dropdownparameter = {
      fundid: leagalentity,
      registerid: 0,
      nonregisterid: nonregistredinverstment,
    };
    dispatch(navbarDropdown(dropdownparameter));
  }, [nonregistredinverstment]);

  console.log("HelpVideoLinkNavbar", GetVideoLinkHere);

  const itemsSub = [
    {
      label: profileData?.firstName
        ? `${profileData?.firstName
            ?.charAt(0)
            .toUpperCase()}${profileData?.lastName?.charAt(0).toUpperCase()}`
        : undefined,
      icon: <RiUserSettingsLine className="mr-3 userLoginAvtar" />,
      items: [
        {
          label: `${profileData?.firstName} ${profileData?.lastName}`,
          icon: <RiUserShared2Line className="mr-2 " />,
          template: (item: any, options: any) => {
            return (
              <div
                className={classNames(
                  options.className,
                  "w-full p-link flex align-items-center "
                )}
              >
                {/* <button
                  onClick={(e) => options.onClick(e)}
                  className=" p-link flex align-items-center"
                >
                  <Image width="38" src={user} />
                </button> */}
                <div className="text-center ml-auto mr-auto">
                  <span className="font-bold imgText">
                    {profileData?.firstName} {profileData?.lastName}
                  </span>
                  {/* <span className="text-sm">Agent</span> */}
                </div>
              </div>
            );
          },
        },
        {
          separator: true,
        },

        {
          label: "Change Password",
          icon: <RiUserShared2Line className="mr-2 " />,
          command: () => changePassword(),
        },
        // {
        //   label: "Account Statement",
        //   icon: <RiFileZipLine className="mr-2 " />,
        //   command: () => accountStatement(),
        // },

        {
          label: "Log Out",
          icon: <RiLogoutCircleLine className="mr-2 " />,
          command: () => logout(),
        },
      ],
    },
  ];
  useEffect(() => {
    console.log("tets------------");
    if (GetVideoLinkHere?.videoLink) {
      // Sponsor Help Video goes under AvestorInc container
      // LegalEntityId for AvestorInc container is 0
      console.log("tets344------------");
      generateSASKey(0);
    }
  }, [GetVideoLinkHere?.videoLink]);

  const itemsSub1 = [
    {
      icon: <RiQuestionFill className="notificationBtn clsCulsor" />,
      // icon: <RiQuestionFill className="mr-3 userLoginAvtar" />,
      items: [
        {
          label: " AI Assistant (Beta)",
          icon: <RiRobotLine className="mr-2 " />,
          command: () => {
            dispatch(setChatBotState({ visibleRightState: true }));
          },
        },
        {
          label: "Support Site",
          icon: <RiQuestionnaireLine className="mr-2 " />,
          command: () => {
            window.open(`${envVariable?.REACT_APP_FAQs}`);
          },
        },
        {
          label: "Submit a Ticket",
          icon: <RiCouponLine className="mr-2 " />,
          command: () => {
            window.open(`${envVariable?.REACT_APP_Boldesk}`);
          },
        },

        {
          label: "Community",
          icon: <RiTeamLine className="mr-2 " />,
          command: () => {
            window.open(`${envVariable?.REACT_APP_Community}`);
          },
        },
      ],
    },
  ];

  console.log("sasKey Here", sayKey);

  useEffect(() => {
    const targetNode = document.querySelector(".p-menuitem") as HTMLElement;
    if (targetNode) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.attributeName === "class") {
            const classList = [...targetNode.classList];
            if (classList.includes("p-menuitem-active")) {
              console.log("p-menuitem-active class was added");
            } else {
              console.log("p-menuitem-active class was removed");
              const videoElements = document.querySelectorAll('[id^="video-"]');
              for (const videoElement of videoElements) {
                console.log("Each Video Element", videoElement);
                if (videoElement instanceof HTMLVideoElement) {
                  videoElement.pause();
                }
              }
              videoRef.current?.pause();
            }
          }
        }
      });

      const config = { attributes: true, attributeFilter: ["class"] };

      observer.observe(targetNode, config);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    const menuElement = document.querySelector(".profileDropdown"); // Replace with the appropriate selector

    const checkMenuState = () => {
      if (menuElement) {
        const isActive = menuElement.classList.contains("p-menuitem-active");
        console.log("IsActive", isActive);
      }
    };

    const observer = new MutationObserver(checkMenuState);

    if (menuElement) {
      observer.observe(menuElement, {
        attributes: true,
        attributeFilter: ["class"],
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const itemsSub2 = [
    {
      icon: <RiQuestionFill className="notificationBtn clsCulsor" />,
      items: [
        {
          label: "",
          icon: (
            <>
              <div className="flex flex-direction-column videozindex">
                <div>
                  {" "}
                  <p
                    className="support-text-vdo"
                    style={{ marginBottom: "10px" }}
                  >
                    Need help with this Page?
                  </p>
                </div>

                <div className="needhelpvideo  mr-1">
                  <div>
                    <video
                      controls
                      src={GetVideoLinkHere?.videoLink + sayKey}
                      id={`video-`}
                      className="vdo-frame"
                    ></video>
                  </div>
                </div>
              </div>
            </>
          ),
        },
        {
          separator: true,
        },
        {
          disabled: true,
          icon: (
            <>
              {" "}
              <span className="pointereventnone">Other Resource:</span>
            </>
          ),
        },
        {
          label: " AI Assistant (Beta)",
          icon: <RiRobotLine className="mr-2 " />,
          command: () => {
            dispatch(setChatBotState({ visibleRightState: true }));
          },
        },
        {
          label: "Support Site",
          icon: <RiQuestionnaireLine className="mr-2 " />,
          command: () => {
            window.open(`${envVariable?.REACT_APP_FAQs}`);
          },
        },
        {
          label: "Submit a Ticket",
          icon: <RiCouponLine className="mr-2 " />,
          command: () => {
            window.open(`${envVariable?.REACT_APP_Boldesk}`);
          },
        },

        {
          label: "Community",
          icon: <RiTeamLine className="mr-2 " />,
          command: () => {
            window.open(`${envVariable?.REACT_APP_Community}`);
          },
        },
      ],
    },
  ];
  const itemsSub3 = [
    {
      icon: <RiQuestionFill className="notificationBtn clsCulsor" />,
      items: [
        {
          label: "",
          icon: (
            <>
              <div className="flex flex-direction-column">
                <div>
                  {" "}
                  <p
                    className="support-text-vdo"
                    style={{ marginBottom: "10px" }}
                  >
                    Need help with this Page?
                  </p>
                </div>

                <div className="needhelpvideo  mr-1">
                  <div>
                    <iframe
                      src={GetVideoLinkHere?.videoLink}
                      allowFullScreen
                    ></iframe>
                    {/* <video
                      playsInline
                      loop
                      controls
                      autoPlay
                      src={GetVideoLinkHere?.videoLink}
                    ></video> */}
                  </div>
                </div>
              </div>
            </>
          ),
        },
        {
          separator: true,
        },
        {
          disabled: true,
          icon: (
            <>
              {" "}
              <span className="pointereventnone">Other Resource:</span>
            </>
          ),
        },
        {
          label: " AI Assistant (Beta)",
          icon: <RiRobotLine className="mr-2 " />,
          command: () => {
            dispatch(setChatBotState({ visibleRightState: true }));
          },
        },
        {
          label: "Support Site",
          icon: <RiQuestionnaireLine className="mr-2 " />,
          command: () => {
            window.open(`${envVariable?.REACT_APP_FAQs}`);
          },
        },
        {
          label: "Submit a Ticket",
          icon: <RiCouponLine className="mr-2 " />,
          command: () => {
            window.open(`${envVariable?.REACT_APP_Boldesk}`);
          },
        },

        {
          label: "Community",
          icon: <RiTeamLine className="mr-2 " />,
          command: () => {
            window.open(`${envVariable?.REACT_APP_Community}`);
          },
        },
      ],
    },
  ];
  let videoLink: any = "";
  videoLink = GetVideoLinkHere?.videoLink;

  const finalItemSub =
    videoLink !== "" && videoLink !== null && videoLink !== undefined
      ? videoLink.includes("loom")
        ? itemsSub3
        : itemsSub2
      : itemsSub1;

  return (
    <nav className="navbar  default-layout-navbar col-lg-12 col-12 p-0 d-flex flex-row border-bottom">
      <div className="navbar-menu-wrapper d-flex position-relative">
        <div className="search-field d-none d-block mr-auto"></div>

        <ul className="navbar-nav">
          <div className="d-flex flex-row">
            <DashboardDropdownComponent
              leagalentity={leagalentity}
              setLeagalentity={setLeagalentity}
              legalEntityList={legalEntityList}
              registredinverstment={registredinverstment}
              setregistredinverstment={setregistredinverstment}
              registerinverstmenList={registerinverstmenList}
              nonregistredinverstment={nonregistredinverstment}
              setnonregistredinverstment={setnonregistredinverstment}
              nonregisterinverstmenList={nonregisterinverstmenList}
            />
          </div>
          <div className="card py-2 bg-white d-flex flex-row">
            {/* <li className="nav-item nav-settings px-1">
              <RiNotificationLine className="notificationBtn" />
            </li> */}
            {/* <li className="nav-item nav-settings px-1">
              <RiHeadphoneFill
                className="notificationBtn clsCulsor"
                onClick={() => props.setVisibleRight(true)}
              />
            </li> */}
            {/* <li className="nav-item nav-settings px-1">
              <NavLink
                to={envVariable.REACT_APP_HELP_CENTER_URL}
                target="_blank"
                className="anchor-icon"
              >
                <RiQuestionFill
                  className="notificationBtn clsCulsor"
                  // onClick={() => props.setVisibleRight(true)}
                />
              </NavLink>
            </li> */}

            <li className="nav-item nav-settings px-1">
              <TieredMenu
                className="bg-white font-bold p-0 profileDropdown videozindex w-auto "
                model={finalItemSub}
              />
            </li>
            {/* <li className="nav-item nav-settings px-1">
              <NavLink to="/Support" className="anchor-icon">
                <RiCustomerService2Line
                  className="notificationBtn clsCulsor"
                  onClick={() => props.setVisibleRight(true)}
                />
              </NavLink>
            </li> */}
          </div>

          <div className="card py-2 bg-white d-flex flex-row ">
            {/* <Image src={user} /> */}
            <li className="nav-item nav-settings d-lg-block px-1">
              <TieredMenu
                className="bg-white border-1 font-bold p-0 profileDropdown1 videozindex w-auto"
                model={itemsSub}
              />
            </li>
          </div>
        </ul>
      </div>
    </nav>
  );
}
