import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getAllPortfolioInvestmentService } from "../../services/portfolio/portfolio";
import {
  IAccountValue,
  IAsset,
  IDiversificationElement,
  IExitedDeals,
  IInvestment,
  IInvestmentPage,
  ILiquidity,
  ITransaction,
} from "../../types";
// import { RootState } from "../../store";
// import { IPortfolio } from "../../types/index";
// import apiFactory from "../../utils/api";
import error from "../../utils/error";
interface IPortfolioState {
  data?: {}[] | null;
  accountValue?: IAccountValue[] | null;
  assets?: IAsset[] | [];
  liquidities?: ILiquidity[] | null;
  exitedDeals?: IExitedDeals | null;
  investment?: IInvestment[] | [];
  transaction?: ITransaction[] | [];
  investmentPage?: IInvestmentPage | null;
  mapDiversification?: IDiversificationElement[] | null;

  loading: string;
  error?: string | null;
}

interface IObject {
  activeTab: string;
  activePage: number;
  fund: string;
  investorID: number;
  partnerID: number;
  legalEntityID: number;
}

const initialState: IPortfolioState = {
  data: undefined,
  accountValue: undefined,
  loading: "loading",
  error: undefined,
};

export const getThunkPortfolioInvestment = createAsyncThunk(
  "Portfolio/getInvestments",
  async (IObject: IObject) => {
    try {
      const response = await getAllPortfolioInvestmentService(IObject);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Portfolio/Investment",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkPortfolioInvestment.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getThunkPortfolioInvestment.fulfilled, (state, action) => {
      state.loading = "succeeded";
      //
      if (action.payload.fetchInvestmentsList.length > 0) {
        state.investment = action.payload.fetchInvestmentsList;
        state.investmentPage = action.payload.pageDetails;
      } else {
        state.investment = [];
        state.investmentPage = undefined;
      }
    });
    builder.addCase(getThunkPortfolioInvestment.rejected, (state, action) => {
      state.loading = "failed";

      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export const getAllPortfoliosData = (state: RootState) => {
  return state.portfolio.data;
};
// export const getAllOpportunitiesStatus = (state: RootState) =>
//   state.opportunity.loading;
// export const getAllOpportunitiesError = (state: RootState) =>
//   state.opportunity.error;
export default slice.reducer;
