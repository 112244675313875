import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLookUpbyAccountIdService } from "../../../../services/investor/investor";
import error from "../../../../utils/error";
import {
  IAccountHolderRelationshipDropdown,
  IAccountOwnershipDropdown,
  IAccountTypeId,
  ISubAccountTypesDropdown,
  ITaxClassificationsDropdown,
  ITrustRevocabilityDropdown,
  ITrustTypeDropdown,
} from ".";

interface IGetLookUpbyAccountId {
  subAccountTypesDropdown?: ISubAccountTypesDropdown[] | [];
  taxClassificationsDropdown?: ITaxClassificationsDropdown[] | [];
  accountHolderRelationshipDropdown?: IAccountHolderRelationshipDropdown[] | [];
  accountOwnershipDropdown?: IAccountOwnershipDropdown[] | [];
  trustRevocabilityDropdown?: ITrustRevocabilityDropdown[] | [];
  trustTypeDropdown?: ITrustTypeDropdown[] | [];
  loading: string;
  error?: string | null;
}

const initialState: IGetLookUpbyAccountId = {
  subAccountTypesDropdown: [],
  taxClassificationsDropdown: [],
  accountHolderRelationshipDropdown: [],
  accountOwnershipDropdown: [],
  trustRevocabilityDropdown: [],
  trustTypeDropdown: [],
  loading: "loading",
  error: undefined,
};

export const getLookUpbyAccountIdThunk = createAsyncThunk(
  "investor/investor-approval/GetLookUpbyAccountId",
  async (data: IAccountTypeId) => {
    try {
      const response = await getLookUpbyAccountIdService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "investor/investor-approval/GetLookUpbyAccountId",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getLookUpbyAccountIdThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getLookUpbyAccountIdThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("action.payload._response", action.payload._response);
      state.subAccountTypesDropdown = action.payload._response.data
        ?.subAccountType
        ? action.payload._response.data?.subAccountType
        : [];
      state.taxClassificationsDropdown = action.payload._response.data
        ?.TaxClassification
        ? action.payload._response.data?.TaxClassification
        : [];
      state.accountHolderRelationshipDropdown = action.payload._response.data
        ?.AccountHolderRelationship
        ? action.payload._response.data?.AccountHolderRelationship
        : [];
      state.accountOwnershipDropdown = action.payload._response.data
        ?.AccountOwnership
        ? action.payload._response.data?.AccountOwnership
        : [];

      state.trustRevocabilityDropdown = action.payload._response.data
        ?.TrustRevocability
        ? action.payload._response.data?.TrustRevocability
        : [];

      state.trustTypeDropdown = action.payload._response.data?.TrustType
        ? action.payload._response.data?.TrustType
        : [];

      // state.Data = [];
    });
    builder.addCase(getLookUpbyAccountIdThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
