import jwt_decode from "jwt-decode";

export interface IPermission {
  resource?: string;
  scopes: string[];
}

// This variable needs to be removed once token provides resource based permissions
const resources: any = {
  Api: "api",
  Key: "key",
  Policy: "policy",
};

// This will get jwt access token from localstorage
function getLocalAccessToken() {
  const data = JSON.parse(
    localStorage.getItem(
      "06371af7-fc67-421b-8835-194a1212c0af-b2c_1_login.82fa7491-aeb5-4c22-b435-2472fee17d4c-investordev.b2clogin.com-idtoken-1e1af3ef-5f0c-4a33-b3aa-18ef56d40b43----"
    ) || "{}"
  );

  if (Object.keys(data).length > 0) {
    const decode_token = JSON.stringify(jwt_decode(data.secret));
    const decode_token1 = JSON.parse(decode_token);

    //

    const tokenExp = decode_token1.exp;
    //

    const date = new Date();
    const ms: number = date.getTime();
    const con = 1000;
    const sec: number = ms / con;

    console.log(sec, tokenExp);
  }
  console.log(data);
  // let user: any = localStorage.getItem("user") || undefined;
  // if (user) {
  //   user = JSON.parse(user);
  // }
  // return user?.accessToken;
  return "Testing";
}

export function checkTokenExp() {
  const data = localStorage.getItem("token");

  if (data) {
    // console.log(jwt_decode(data));
    const decode_token: any = jwt_decode(data);
    // console.log("decode", decode_token.exp);

    const tokenExp = decode_token.exp;
    // console.log(tokenExp);

    const date = new Date();
    const ms: number = date.getTime();
    const con = 1000;
    const currentTime: number = ms / con;

    // return tokenExp < currentTime ? false : true;
    return !!(tokenExp < currentTime);
  }
  // return true token is not present
  return true;
  // return false;
}

export function getTokenExpTime() {
  const data = localStorage.getItem("token");

  if (data) {
    // console.log(jwt_decode(data));
    const decode_token: any = jwt_decode(data);
    // console.log("decode", decode_token.exp);

    const tokenExp = decode_token.exp;
    // console.log(tokenExp);

    const date = new Date();
    const ms: number = date.getTime();
    const con = 1000;
    const currentTime: number = ms / con;
    const diff = tokenExp - currentTime;
    const mins_diff = Math.floor((diff % 3600) / 60);

    const tokenMin = Math.floor((tokenExp % 3600) / 60);

    console.log(
      "tokenExp",
      tokenExp,
      "tokenMin",
      tokenMin,
      "currentTime",
      currentTime,
      "Min diff",
      mins_diff,
      "diff",
      diff
    );

    return mins_diff;
    // return tokenExp < currentTime ? false : true;
  }
  return 45;
}

export const getFundName: any = () => {
  const fundWebsiteLocalStorage = localStorage.getItem("fundWebsite");
  if (!fundWebsiteLocalStorage) {
    console.log("inside");
    return process.env.REACT_APP_DEFAULT_FUND_NAME;
  } else {
    return fundWebsiteLocalStorage;
  }
};

// This will decode jwt access token
export const getDecodeToken: any = () => {
  const token = getLocalAccessToken();
  const isTokenValid = checkTokenExp();
  console.log("isTokenValid", isTokenValid);
  // let decoded;
  // if (token) {
  //   decoded = jwt_decode(token);
  // }
  // // return decoded;
  // console.log(decoded);
  console.log(token);
  console.log(isTokenValid);
  return "getDecodeToken";
};

function permissionFunction(token: any) {
  return [
    {
      resource: resources.Api,
      scopes: token && token.permission !== undefined ? token.permission : "",
    },
    {
      resource: resources.Key,
      scopes: token && token.permission !== undefined ? token.permission : "",
    },
    {
      resource: resources.Policy,
      scopes: token && token.permission !== undefined ? token.permission : "",
    },
  ];
}
// This will get current logged-in user's resource based permissions
export default function getUserPermissions(resource: string) {
  const token = getDecodeToken();
  // Uncomment below variable once Token provides resource based permissions
  // const permissions: IPermission[] = token.permission;

  // Token should provide resource based permission and then we will remove hard-code part
  const permissions: IPermission[] = permissionFunction(token);

  let resourcePermission: IPermission = {
    resource: "",
    scopes: [],
  };

  const userResources: string[] = [];
  if (permissions && permissions.length > 0) {
    // This will get resource specific permissions/scopes
    if (resource) {
      for (const item of permissions) {
        if (item.resource === resource) {
          resourcePermission = item;
          break;
        }
      }
      return resourcePermission;
    }
    // This will get all user permitted resources
    else {
      for (const item of permissions) {
        userResources.push(item.resource as string);
      }
      return userResources;
    }
  }
}
