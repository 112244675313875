import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import ReverseEarningAllocation from "./ReverseEarningAllocation";
import ReverseEarningAllocationContinuous from "./ReverseEarningAllocationContinuous";

const ReverseEaringAllocationTab = () => {
  const [activeIndexMainTab, setActiveIndexMainTab] = useState<number>(0);
  const [openDailog, setOpenDailog] = useState(false);
  const ReverseEarningAllocationContiniousInfo = useAppSelector(
    (state) => state.getEarningsDataContinuous.data
  );
  const ReverseEarningAllocationInfo = useAppSelector(
    (state) => state.getEarningsData.data
  );
  console.log(
    "ReverseEarningAllocationInfo",
    openDailog,
    ReverseEarningAllocationInfo,
    ReverseEarningAllocationContiniousInfo,
    activeIndexMainTab
  );

  return (
    <>
      {/* <div className="grid"> */}
      <div className="col-12 flex p-1">
        <div className="card col-11 p-0 pgHeaderWrap ">
          <h1 className="pageHeader fs-2">
            {" "}
            {activeIndexMainTab === 1
              ? "Continuous Reverse Earning Allocation"
              : "Reverse Earning Allocation"}
          </h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className="col-1 p-0 align-items-center flex justify-content-end">
          <Button
            className="btn-navActive"
            type="button"
            icon="pi pi-window-maximize"
            disabled={
              (activeIndexMainTab === 1 &&
                !ReverseEarningAllocationContiniousInfo) ||
              (activeIndexMainTab === 0 && !ReverseEarningAllocationInfo)
            }
            onClick={() => setOpenDailog(true)}
            title="View Full Screen"
          />
        </div>
      </div>
      {/* <div className="col-12 md:col-9  pl-1 p-0">
        <h1 className="pageHeader">
          {activeIndexMainTab === 1
            ? "Continuous Reverse Earning Allocation"
            : "Reverse Earning Allocation"}
        </h1>
        <p className="pageSubHeader"> </p>
      </div> */}
      {/* </div> */}
      {/* <div className="grid col-12">
        <div className="col-12"> */}
      <div className="card">
        <TabView
          activeIndex={activeIndexMainTab}
          onTabChange={(e) => setActiveIndexMainTab(e.index)}
          className="pl-2 remove-pad"
        >
          <TabPanel header="Fixed">
            <ReverseEarningAllocation
              activeIndex={activeIndexMainTab}
              openDailog={openDailog}
              setOpenDailog={setOpenDailog}
            />
          </TabPanel>
          <TabPanel header="Continuous">
            <ReverseEarningAllocationContinuous
              activeIndex={activeIndexMainTab}
              openDailog={openDailog}
              setOpenDailog={setOpenDailog}
            />
          </TabPanel>
        </TabView>
      </div>
      {/* </div>
      </div> */}
    </>
  );
};

export default ReverseEaringAllocationTab;
