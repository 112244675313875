import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetCapitalLossDetailsService } from "../../../../../services/taxmanagement/tax";

import error from "./../../../../../utils/error";
import {
  IGetCapitalLossDetailsState,
  IGetCapitalLossDetailsparameters,
} from ".";

const initialState: IGetCapitalLossDetailsState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  data2: undefined,
};
export const GetCapitalLossDetailsThunk = createAsyncThunk(
  "TaxManagement/GetCapitalLossDetails",
  async (data: IGetCapitalLossDetailsparameters) => {
    try {
      const response = await GetCapitalLossDetailsService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "TaxManagement/GetCapitalLossDetails",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetCapitalLossDetailsThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetCapitalLossDetailsThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload._response.data
        ? action.payload._response.data.capitalLossDetails[0]
        : [];
      state.data2 = action.payload._response.data
        ? action.payload._response.data.capitalLossSummary[0]
        : [];
    });
    builder.addCase(GetCapitalLossDetailsThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
