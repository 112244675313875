export const initialInvestmentState = {
  productID: 0,
  legalEntityID: 0,
  productState: "",
  supplierID: 0,
  supplierProductCode: "",
  productName: "",
  productListingAddress: "",
  address2: "",
  isPhysicalAddress: "yes",
  city: "",
  stateCode: "",
  zipCode: "",
  riskMultiplier: 0,
  interestRate: "",
  accruedInterestRate: 0,
  exitTotalRate: 0,
  investmentLengthID: 0,
  equityInvestment: "yes",
  ratepayout: 0,
  assetClassID: 0,
  feeTypeID: 0,
  listPrice: 0,
  targetRaiseAmt: 0,
  accruedInterest: 0,
  fundingDate: undefined,
  targetCloseDate: undefined,
  numberOfUnits: 0,
  unitPriceCalculated: 10,
  estExitDate: undefined,
  actualExitDate: undefined,
  createdBy: 0,
  dateCreated: "",
  lastModifiedBy: 0,
  dateLastModified: "",
  earningsPayoutTiming: "",
  productLink: "",
  externalInvestmentID: 0,
  longitude: 0,
  latitude: 0,
  dealHealth: "",
  excludeFromMarketing: true,
  showRemainingAllocation: false,
  isAllocationDone: false,
  isDistributionDone: false,
  isEarningCreated: false,
  isEditAssetClass: false,
  loginId: 0,
  isFinalstep: false,
  countryId: 0,
  isPooledFund: false,
  descriptions: "",
  timingOfDeposits: "",
  timingOfWithdrawals: "",
  isLockOutPeriod: "",
  lockOutPeriod: undefined,
  eastimatedMonthlyReturn: undefined,
  isReinvest: false,
  isCustomtimingOfDeposits: false,
  isCustomtimingOfWithdrawals: false,
};
