import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
// import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import DataTableComponent from "../../../../../components/data-table/DataTableComponent";
import InvestmentDropdownFilter from "../../../../../components/investment-filter/InvestmentDropdownFilter";
import Loader from "../../../../../components/loader/Loader";
import { getThunkAllInvestmentByUserId } from "../../../../../store/features/investment/all-list/slice";
import { getThunkInvestmentById } from "../../../../../store/features/investment/create/slice";
import { getThunkInvestmentIdByProduct } from "../../../../../store/features/operation/allocate-earning/get-investment-id-product/slice";
import { addThunkCreateUpdateAllocateData } from "../../../../../store/features/operation/allocation-slices/create-update-allocation/slice";
import {
  initialPayloadUpdateData,
  setUpdateAllocationSliceState,
} from "../../../../../store/features/operation/allocation-slices/edit-investor-allocation-state/slice";
import { initialPayloadGetAllocatedListData } from "../../../../../store/features/operation/allocation-slices/get-allocated-investor";
import {
  getThunkAllocatedInvestorListData,
  // resetAllocatedInvestorState,
  setAllocatedInvestorState,
} from "../../../../../store/features/operation/allocation-slices/get-allocated-investor/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  convertLocalDateToUTC,
  formatDateMMDDYYY,
} from "../../../../../utils/date-formatter";
import { errorToastMessage } from "../../../../../utils/toast-message";
import EditAllocationUnits from "./EditAllocationUnits";

export default function EditAllocationStep() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  // const [ProductId, setProductId] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSuccessBox, setOpenSuccessBox] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState<any>();
  const [dataUpdated, setDataUpdated] = useState([]);
  const [upadateClick, setUpdateClick] = useState(false);
  const [productObjectByFilter, setProductObjectByFilter] = useState<any>({
    productID: 0,
    lookupCodeObj: "",
    address: "",
    zipCode: "",
  });
  const updateAllocationSliceState = useAppSelector(
    (state) => state.updateAllocationSliceState.data
  );
  const investmentIdState = useAppSelector(
    (state) => state.getInvestmentIdByProduct.data.externalInvestmentId?.key
  );
  const allocatedInvestor = useAppSelector(
    (state) => state.getAllocatedInvestorList.data
  );
  const investmentList = useAppSelector(
    (state) => state.getListInvestmentsUserId.data.investments
  );
  const investmentDropdownList: any = [];

  investmentList?.map((item: any) => {
    //
    return investmentDropdownList.push({
      name: item.productName,
      code: item.productID,
    });
  });

  const onCancel = () => {
    dispatch(setUpdateAllocationSliceState(initialPayloadUpdateData));
    dispatch(setAllocatedInvestorState(initialPayloadGetAllocatedListData));
    // setProductId(0);
    setUpdateClick(false);
    setProductObjectByFilter({
      productID: 0,
      lookupCodeObj: "",
      address: "",
      zipCode: "",
    });
    // setTimeout(() => {
    //   // props.handlePrevious();
    // }, 50);
  };

  // for the dialog box date
  const dateInputbox = (rowData: any) => {
    return (
      <>
        <InputText
          name="allocationDate"
          value={formatDateMMDDYYY(new Date(rowData.allocationDate))}
          className="card-inputBox"
          disabled
        ></InputText>
      </>
    );
  };
  // for the dialog box unit
  const UnitsInputbox = (rowData: any) => {
    return (
      <>
        <InputText
          name="allocateUnits"
          value={rowData.allocatedUnits}
          className="card-inputBox"
          disabled
        ></InputText>
      </>
    );
  };
  // for the dialog box table
  const dataTableElement = [
    {
      field: "lpPartnerName",
      header: "Investor Account",
      noFilter: true,
      noSort: true,
      // style: { width: "70px" },
    },
    {
      field: "allocationDate",
      header: "Allocation Date",
      body: dateInputbox,
      noFilter: true,
      noSort: true,
    },
    {
      field: "allocatedUnits",
      header: "Allocated Units",
      body: UnitsInputbox,
      noFilter: true,
      noSort: true,
    },
  ];

  const onFinalize = () => {
    setLoading(true);
    const list: any = dataUpdated.map((obj: any) => {
      const newData = {
        ...obj,
        allocationDate: convertLocalDateToUTC(obj.allocationDate),
      };

      return newData;
    });
    console.log("List2---", list);
    const parameter = {
      action: "Update",
      stakeTrackingId: allocatedInvestor.investmentData?.stakeTrackingId,
      legalEntityId: fundDropdown.fundid,
      investmentId: updateAllocationSliceState.investmentId,
      totalUnits: allocatedInvestor.investmentData?.availableUnits,
      unitPrice: allocatedInvestor.investmentData?.unitPrice,
      allocationData: list,
      remainingInvestmentUnits:
        updateAllocationSliceState.remainingInvestmentUnits,
    };
    dispatch(addThunkCreateUpdateAllocateData(parameter))
      .then((response) => {
        dispatch(
          setUpdateAllocationSliceState({
            ...updateAllocationSliceState,
            investorList: [],
            investorListInitialState: [],
          })
        );
        setOpenSuccessBox(true);
        setLoading(false);
        setOpenDialog(false);
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onUpdate = () => {
    const hasError = errorMessageState.some(
      (error: any) =>
        error.unitsToAllocateError !== "" ||
        error.cashEquivalentError !== "" ||
        error.allocationDateError !== ""
    );
    const hasNull = updateAllocationSliceState?.investorList.some(
      (unitCheck: any) =>
        unitCheck.partnerType !== "GP"
          ? unitCheck.editUnitsToAllocate === null ||
            unitCheck.editUnitsToAllocate === 0
          : false
    );

    if (!hasError && !hasNull) {
      setOpenDialog(true);
      const allList: any = [...updateAllocationSliceState.investorList];
      const list: any = [];
      for (const item of allList) {
        if (
          new Date(item.editAllocatedDate).setHours(0, 0, 0) !==
            new Date(item.maxStartDate).setHours(0, 0, 0) ||
          item.units !== item.editUnitsToAllocate
        ) {
          const obj = {
            lpPartnerId: item.lpPartnerId,
            lpPartnerName: item.lpPartnerName,
            allocationDate: item.editAllocatedDate,
            allocatedUnits: item.editUnitsToAllocate,
            prevAllocatedUnits: item.units,
            lpPartnerStakeTrackingId: item.stakeTrackingID,
          };
          list.push(obj);
        }
      }

      setDataUpdated(list);
    } else if (hasNull) {
      errorToastMessage(toast, "Please allocate units for all investors");
    } else {
      errorToastMessage(toast, "Please check the allocated units");
    }
  };
  const handleOk = () => {
    setOpenSuccessBox(false);
    const allocationData = {
      legalEntityId: fundDropdown.fundid,
      investmentId: investmentIdState,
    };
    setLoading(true);
    dispatch(getThunkAllocatedInvestorListData(allocationData)).finally(() => {
      setLoading(false);
    });
  };
  // const handleChange = (e: any) => {
  //   const { value } = e.target;
  //   setProductId(value);
  //   dispatch(
  //     setUpdateAllocationSliceState({
  //       ...updateAllocationSliceState,
  //       investorList: [],
  //       investorListInitialState: [],
  //       productId: value,
  //     })
  //   );
  // };
  useEffect(() => {
    if (updateAllocationSliceState.investorList.length > 0) {
      const IsEdit = updateAllocationSliceState?.investorList.some(
        (checkEdit: any) =>
          new Date(checkEdit.editAllocatedDate).setHours(0, 0, 0) !==
            new Date(checkEdit.maxStartDate).setHours(0, 0, 0) ||
          checkEdit.units !== checkEdit.editUnitsToAllocate
      );
      setUpdateClick(IsEdit);
    }
  }, [updateAllocationSliceState.investorList]);

  useEffect(() => {
    dispatch(setUpdateAllocationSliceState(initialPayloadUpdateData));
    dispatch(setAllocatedInvestorState(initialPayloadGetAllocatedListData));
    // setProductId(0);
    setProductObjectByFilter({
      productID: 0,
      lookupCodeObj: "",
      address: "",
      zipCode: "",
    });
    // dispatch(resetAllocatedInvestorState());
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      setLoading(true);
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        userId: profileData?.userID,
      };
      dispatch(getThunkAllInvestmentByUserId(parameter)).finally(() => {
        setLoading(false);
      });
    }
  }, [fundDropdown]);
  useEffect(() => {
    if (productObjectByFilter.productID !== 0) {
      setLoading(true);
      const parameter = { productId: productObjectByFilter.productID };
      dispatch(getThunkInvestmentIdByProduct(parameter))
        .then((response) => {
          if (
            response.payload._response.data !== null &&
            response.payload._response.data.externalInvestmentId.key !== 0 &&
            response.payload._response.data.externalInvestmentId !== 0
          ) {
            dispatch(
              getThunkInvestmentById(productObjectByFilter?.productID)
            ).then((resp) => {
              console.log(
                "resp.payload.data.investorDetails[0]",
                resp.payload.data.investorDetails[0]
              );
              setProductObjectByFilter({
                ...productObjectByFilter,
                lookupCodeObj:
                  resp.payload.data.investorDetails[0].supplierProductCode,
                address:
                  resp.payload.data.investorDetails[0].productListingAddress,
                zipCode: resp.payload.data.investorDetails[0].zipCode,
              });
            });
            dispatch(
              setUpdateAllocationSliceState({
                ...updateAllocationSliceState,
                investorList: [],
                investorListInitialState: [],
                productId: productObjectByFilter.productId,
              })
            );
            const allocationData = {
              legalEntityId: fundDropdown.fundid,
              investmentId:
                response.payload._response.data.externalInvestmentId.key,
            };

            dispatch(getThunkAllocatedInvestorListData(allocationData))
              .catch(() => {
                errorToastMessage(toast);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            errorToastMessage(toast, "Investment Id is not found");
            setProductObjectByFilter({
              productID: 0,
              lookupCodeObj: "",
              address: "",
              zipCode: "",
            });
            setLoading(false);
            dispatch(setUpdateAllocationSliceState(initialPayloadUpdateData));
            dispatch(
              setAllocatedInvestorState(initialPayloadGetAllocatedListData)
            );
          }
        })
        .catch(() => {
          errorToastMessage(toast);
          setLoading(false);
          dispatch(setUpdateAllocationSliceState(initialPayloadUpdateData));
          dispatch(
            setAllocatedInvestorState(initialPayloadGetAllocatedListData)
          );
        });
    } else {
      dispatch(setUpdateAllocationSliceState(initialPayloadUpdateData));
      dispatch(setAllocatedInvestorState(initialPayloadGetAllocatedListData));
    }
  }, [productObjectByFilter.productID]);
  useEffect(() => {
    if (allocatedInvestor.output.length > 0) {
      const list: any = [];
      for (const item of allocatedInvestor.output) {
        const rowDataEdit = {
          ...item,
          // cashBalance: item.cashBalance + item.units * item.unitPrice,
          cashBalance: item.cashBalance,
          editAllocatedDate: item.maxStartDate,
          editUnitsToAllocate: item.units,
          cashEquivalent: item.units * item.unitPrice,
          remainingCash: item.cashBalance,
          classApplytoEdit: "",
        };
        list.push(rowDataEdit);
      }

      dispatch(
        setUpdateAllocationSliceState({
          ...updateAllocationSliceState,
          investorList: list,
          investorListInitialState: list,
          investmentId: investmentIdState,
        })
      );
    } else {
      dispatch(setUpdateAllocationSliceState(initialPayloadUpdateData));
    }
  }, [allocatedInvestor.output]);
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />

      <Dialog
        visible={openSuccessBox}
        className="w-100 md:w-3"
        headerClassName="no-header"
        closable={false}
        blockScroll
        onHide={() => setOpenSuccessBox(false)}
      >
        <p className="pageHeader text-center pt-5">
          Allocation updated successfully!
        </p>

        <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
          <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleOk();
              }}
              className="btn-navActive"
            >
              <span className="ml-auto mr-auto"> Ok</span>
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={openDialog}
        onHide={() => setOpenDialog(false)}
        className="w-100 md:w-7 pb-1"
        headerClassName="no-header"
      >
        <div className="mt-2">
          <h3 className="pageHeader">Confirm Changes:</h3>
          <p className="pageHeader mt-2">
            Some edits to allocations are irreversible, please confirm all
            details below are correct before submission.
          </p>

          <div className="mt-2">
            <DataTableComponent
              valueData={dataUpdated || []}
              fieldsElements={dataTableElement}
              className="allocateSliceTable editAllocateDialog"
              noGridLines={true}
            />
          </div>
          <div className="flex flex-row flex-wrap sm:flex-none p-2 col-12 justify-content-center ">
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-nav"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenDialog(false);
                }}
              >
                <span className="ml-auto mr-auto ">Cancel</span>
              </Button>
            </div>
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-navActive"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  onFinalize();
                }}
              >
                <span className="ml-auto mr-auto ">Confirm & Submit</span>
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <div>
        <div className=" h-100 center-card">
          <div className="col-12 ">
            <div className="card my-3">
              <Card>
                <div className="headercard d-flex grid">
                  <div className="col-12 md:col-6">
                    <h2 style={{ fontWeight: "bold" }}>Edit Allocations</h2>
                  </div>
                  <div className="col-12 md:col-6 end-div">
                    <h6>Edit Date & Unit </h6>
                  </div>
                </div>

                <div>
                  <ProgressBar
                    value={100}
                    showValue={false}
                    className="m-2"
                  ></ProgressBar>
                </div>

                <div>
                  <div className="mt-4">
                    <div className="formgrid grid px-2">
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
                        {/* <label className="inputLabel">Select Investment</label>
                        <Dropdown
                          inputId="investment"
                          name="investment"
                          value={ProductId}
                          options={
                            investmentDropdownList === null
                              ? []
                              : investmentDropdownList
                          }
                          filter
                          resetFilterOnHide={true}
                          showFilterClear={true}
                          optionLabel="name"
                          optionValue="code"
                          placeholder="Select Investment"
                          className="card-inputBox"
                          // showOnFocus
                          onChange={(e) => {
                            e.preventDefault();
                            handleChange(e);
                          }}
                        /> */}

                        <InvestmentDropdownFilter
                          listPage={false}
                          setProductObjectByFilter={setProductObjectByFilter}
                          productObjectByFilter={productObjectByFilter}
                          productState="PreFunded"
                          pooledFund="Fixed"
                        />

                        <div className="formgrid grid">
                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                            <label className="inputLabel">Investment Id </label>
                            <InputText
                              name="productId"
                              value={
                                productObjectByFilter?.productID === 0
                                  ? ""
                                  : productObjectByFilter?.productID
                              }
                              // value={investmentID === 0 ? "" : investmentID}
                              // onChange={(e) => handleChange(e)}
                              className="card-inputBox"
                              placeholder="Investment Id"
                              disabled
                            />
                          </div>
                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                            <label className="inputLabel">Lookup Code </label>
                            <InputText
                              name="lookupCode"
                              value={productObjectByFilter?.lookupCodeObj}
                              // onChange={(e) => handleChange(e)}
                              className="card-inputBox"
                              placeholder="Lookup code"
                              disabled
                            />
                          </div>
                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                            <label className="inputLabel">Zip Code </label>
                            <InputText
                              name="zipCode"
                              value={productObjectByFilter?.zipCode}
                              // onChange={(e) => handleChange(e)}
                              className="card-inputBox"
                              placeholder="Zip Code"
                              disabled
                            />
                          </div>
                          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3 annualClass">
                            <label className="inputLabel">Address </label>
                            <InputText
                              name="address"
                              value={productObjectByFilter?.address}
                              // onChange={(e) => handleChange(e)}
                              className="card-inputBox"
                              placeholder="Address"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="allocate-block m-2">
                      <div className="m-2">
                        <p className="pb-2 mb-0">Note:</p>
                        <p className="pb-1 mb-0">
                          1) Allocation date for a particular lot cannot be
                          greater than allocation date of any subsequent lot.
                        </p>
                        <p className="pb-1 mb-0">
                          2) If change date is disabled for a particular record,
                          you need to first change the allocation date for the
                          lot above it.
                        </p>
                        <p className="pb-1 mb-0">
                          3) If both change date and units are disabled, it
                          would mean that some distributions have been processed
                          for these investors. You need to first reverse all
                          existing distributions affecting those investor
                          accounts before you can update their allocation date
                          and units.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <EditAllocationUnits
                  errorMessageState={errorMessageState}
                  setErrorMessageState={setErrorMessageState}
                />

                <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center ">
                  <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                    <Button
                      className="btn-nav"
                      onClick={(e) => {
                        e.preventDefault();
                        onCancel();
                      }}
                    >
                      <span className="ml-auto mr-auto ">Cancel</span>
                    </Button>
                  </div>
                  <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                    <Button
                      className="btn-navActive"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        onUpdate();
                      }}
                      disabled={!upadateClick}
                    >
                      <span className="ml-auto mr-auto ">Update</span>
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
