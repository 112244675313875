import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
// import { RiSearchLine } from "react-icons/ri";
import { RiAddFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import ManageInvestor from "./manageinvestor/Manage-Investor";
import Viewapproveregistrations from "./view-approve-registrations/View-&-approve-registrations";

const InvestorsList = () => {
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  // Access query parameters by their names
  const source =
    queryParams.get("source") === "investors" ||
    queryParams.get("source") === "inRegistration"
      ? queryParams.get("source")
      : "investors";

  const [page, setPage] = useState(source);
  const [filterValue, setFilterValue] = useState("");
  if (page !== null) {
    localStorage.setItem("activeBarValue", page);
  }
  const [openDailog, setOpenDailog] = useState(false);
  // console.log("Navigateing Page->>>>", page);
  const InvestorTab = useAppSelector((state) => state.GetInvestorList.data);
  console.log("InvestorTab", InvestorTab);

  return (
    <div>
      {page === "investors" ? (
        <>
          {" "}
          <div className="md:flex col-12 p-0">
            <div className="col-12 md:col-6 p-0 d-flex justify-content-start">
              <h1>Investors:</h1>
            </div>
            <div className="col-12 md:col-6 p-0 d-flex justify-content-center md:justify-content-end">
              {/* </div>
          <div className="d-flex flex-column p-2  xl:col-2 sm:col-1"> */}
              <Button
                className="btn-navActive m-0 p-0 col-12 md:col-4"
                // type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("../SendInviteToRegister");
                }}
              >
                <span className="ml-auto mr-auto ">
                  {" "}
                  Invite To Register
                  <span className="pl-2">
                    <RiAddFill />
                  </span>
                </span>
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="col-12 col-sm-11 col-md-12 p-0 col-xl-12">
            <div className="flex justify-content-between flex-wrap">
              <div className="flex align-items-center p-0 justify-content-center">
                <h1>Users in registration:</h1>
              </div>
              <div className="flex align-items-center p-0 col-4 justify-content-end">
                {/* </div>
          <div className="d-flex flex-column p-2  xl:col-2 sm:col-1"> */}
                <Button
                  className="btn-navActive m-0"
                  // type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("../SendInviteToRegister");
                  }}
                >
                  <span className="ml-auto mr-auto ">
                    Invite To Register
                    <span className="pl-2">
                      <RiAddFill />
                    </span>
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="md:flex md:col-12 p-0">
        <div className="col-12 md:col-1">
          <div className="d-flex flex-column p-0 m-0 md:mt-3">
            <span>Show:</span>
          </div>
        </div>
        <div className="col-12 md:col-3 p-0">
          <div className="d-flex flex-column p-0 ">
            <Button
              className={`${
                page === "investors" ? "btn-navActive" : "btn-nav"
              }  mt-2 d-flex justify-content-center`}
              onClick={(e) => {
                e.preventDefault();
                navigate("/investorsList?source=investors");

                setPage("investors");
              }}
            >
              <span className="">Investors</span>
            </Button>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="d-flex flex-column p-0">
            <Button
              // className={"btn-nav mt-2"}
              className={`${
                page === "inRegistration" ? "btn-navActive" : "btn-nav"
              }  m-0`}
              onClick={(e) => {
                e.preventDefault();
                setPage("inRegistration");
                // navigate("/investments");
                navigate("/investorsList?source=inRegistration");
              }}
            >
              <span className="ml-auto mr-auto ">In Registration</span>
            </Button>
          </div>
        </div>
        {page === "investors" ? (
          <>
            {" "}
            <div className="col-12 md:col-4">
              <div className="d-flex flex-column p-0  search-investor">
                <span className="p-input-icon-left m-0 w-full">
                  {/* <RiSearchLine className="" /> */}
                  {/* <InputText
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              placeholder="Search..."
              className="col-12"
            /> */}
                  <span className="p-input-icon-right searchbox-wrap">
                    <i className="pi pi-search" />
                    <InputText
                      placeholder="Search..."
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                      className="w-full"
                    />
                  </span>
                </span>
              </div>
            </div>
            <div className="col-12 md:col-1">
              <div className="flex justify-content-end">
                {" "}
                <Button
                  className="btn-navActive"
                  type="button"
                  icon="pi pi-window-maximize"
                  disabled={!(page === "investors" && InvestorTab)}
                  onClick={() => setOpenDailog(true)}
                  title="View Full Screen"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="col-12 md:col-5">
              <div className="d-flex flex-column p-0">
                <span className="p-input-icon-left m-0 w-full">
                  {/* <RiSearchLine className="" /> */}
                  {/* <InputText
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          placeholder="Search..."
          className="col-12"
        /> */}
                  <span className="p-input-icon-right searchbox-wrap">
                    <i className="pi pi-search" />
                    <InputText
                      placeholder="Search..."
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                      className="w-full"
                    />
                  </span>
                </span>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="grid">
        {page === "investors" ? (
          <ManageInvestor
            openDialog={openDailog}
            setOpenDialog={setOpenDailog}
            filterValue={filterValue}
          />
        ) : (
          <Viewapproveregistrations filterValue={filterValue} />
        )}
      </div>
    </div>
  );
};

export default InvestorsList;
