import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DailyAccountProcessListService } from "../../../../../services/Accounting/accounting";
import error from "../../../../../utils/error";
import {
  IDailyAccountProcessListState,
  IDailyAccountProcessListparameters,
} from ".";

const initialState: IDailyAccountProcessListState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const DailyAccountProcessListThunk = createAsyncThunk(
  "Accounting/DailyAccountProcessList",
  async (data: IDailyAccountProcessListparameters) => {
    try {
      const response = await DailyAccountProcessListService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Accounting/DailyAccountProcessList",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(DailyAccountProcessListThunk.pending, (state) => {
      state.data = undefined;
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(DailyAccountProcessListThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload._response.data.dailyAccountProcessList
        ? action.payload._response.data.dailyAccountProcessList
        : [];
    });
    builder.addCase(DailyAccountProcessListThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
