import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import React, { useEffect, useMemo, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
import Loader from "../../../components/loader/Loader";
import { getThunkInvestmentIdByProduct } from "../../../store/features/operation/allocate-earning/get-investment-id-product/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addNewPoolfundsUnitsThunk } from "../../../store/poolfunds/add-new-poolfunds-units";
import {
  editPoolFundsActions,
  getAllocatedInvesterEditPooledFundThunk,
} from "../../../store/poolfunds/edit-pool-funds-slice";
import { InvestentListType } from "../../../store/poolfunds/edit-pool-funds-slice/type";
import { getThunkAllInvestmentDealPooledFundByLegalEntityId } from "../../../store/poolfunds/get-investmentdeal-for-pooledfund";
import { ManagerFeesType } from "../../../types";
import currencyFormat from "../../../utils/currency-formatter";
import {
  convertLocalDateToUTC,
  formatDateMMDDYYY,
} from "../../../utils/date-formatter";
import {
  errorToastMessage,
  infoToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
import DateTemplate from "../../features/common-component/DateTemplate";

type EditPoolFundStep1Props = {
  handlePrevious: () => void;
  handleNext: () => void;
  toast: any;
};

const EditPoolFundStep1: React.FC<EditPoolFundStep1Props> = ({
  handlePrevious,
  handleNext,
  toast,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [investorData, setInvestorData] = useState([]);
  const [manualData, setManualData] = useState<any>();
  const [manualFeesId, setManualFeesId] = useState<any>("");
  const [managerFee, setManagerFee] = useState<any>(0);
  const [errorMessageState, setErrorMessageState] = useState<any>([]);
  const [hasCashError, sethascashError] = useState(false);
  const [hasUnitError, sethasUnitError] = useState(false);
  const [hasFinalUnitError, setHasFinalUnitError] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const editPoolFundData = useAppSelector((state) => state.editPoolFunds.data);
  const managerFees = useAppSelector(
    (state) => state.editPoolFunds.data.managerFees
  );
  const investmentList = useAppSelector(
    (state) => state.getAllInvestorDealListPoolFunds.data.investments
  );
  console.log("editPoolFundData", editPoolFundData);

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameter = {
        legalEntityId: fundDropdown.fundid,
      };
      setLoading(true);
      dispatch(
        getThunkAllInvestmentDealPooledFundByLegalEntityId(parameter)
      ).finally(() => {
        setLoading(false);
      });
    }
    return () => {
      dispatch(editPoolFundsActions.resetEditPoolFundData());
    };
  }, [fundDropdown.fundid]);

  useEffect(() => {
    const investorList: any = [...editPoolFundData?.investorList];
    const list: any = [];
    for (const item of investorList) {
      const investorObj = {
        lpPartnerId: item.lpPartnerId,
        stakeTrackingID: item.stakeTrackingID,
        unitsToAllocateError: "",
        cashEquivalentError: "",
        finalUnitError: "",
        allocationDateError: "",
        isFieldRequiredError: true,
      };
      list.push(investorObj);
    }
    setErrorMessageState(list);
  }, [investorData]);

  useEffect(() => {
    if (errorMessageState !== undefined) {
      sethasUnitError(
        errorMessageState.some(
          (error: any) => error.unitsToAllocateError !== ""
        )
      );
      sethascashError(
        errorMessageState.some((error: any) => error.cashEquivalentError !== "")
      );
      setHasFinalUnitError(
        errorMessageState.some((error: any) => error.finalUnitError !== "")
      );
    }
  }, [errorMessageState]);
  useEffect(() => {
    if (fundDropdown.fundid && editPoolFundData.productId) {
      setLoading(true);
      const param = { productId: editPoolFundData.productId };
      dispatch(getThunkInvestmentIdByProduct(param))
        .then((response) => {
          if (
            response.payload._response.data !== null &&
            response.payload._response.data.externalInvestmentId.key !== 0 &&
            response.payload._response.data.externalInvestmentId !== 0
          ) {
            dispatch(
              editPoolFundsActions.setEditPoolFundData({
                ...editPoolFundData,
                investmentId:
                  response.payload._response.data.externalInvestmentId.key,
              })
            );
            const parameter = {
              legalEntityId: fundDropdown.fundid,
              investmentId:
                response.payload._response.data.externalInvestmentId.key,
            };
            dispatch(getAllocatedInvesterEditPooledFundThunk(parameter))
              .then((result) => {
                setInvestorData(result.payload.data.output);
                if (
                  result.payload.data.pooledFundsInvestmentDetails
                    ?.isDistributed
                ) {
                  infoToastMessage(
                    toast,
                    "Can not edit this investement, Please revert the earnings & exits"
                  );
                }
              })
              .catch(() => {
                setInvestorData([]);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            errorToastMessage(toast, "Investment Id is not found");
            dispatch(editPoolFundsActions.resetEditPoolFundData());

            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          dispatch(editPoolFundsActions.resetEditPoolFundData());
          console.log("error", error);
        });
    }
  }, [editPoolFundData.productId]);
  const investmentDropdownList = useMemo(
    () =>
      investmentList?.map((item: any) => {
        return {
          name: item.productName,
          code: item.productID,
        };
      }),
    [investmentList]
  );

  const calculatedEarning = useMemo(() => {
    let totalUnits = 0;
    const list: InvestentListType[] = editPoolFundData.investorList;
    for (const element of list) {
      totalUnits +=
        Number(
          element.cashEquivalent !== undefined
            ? element.cashEquivalent
            : element.totalAmount
        ) - Number(element.managerFees);
    }
    return totalUnits;
  }, [editPoolFundData.investorList]);

  const investortList = useMemo(
    () => [...editPoolFundData.investorList],
    [editPoolFundData.investorList]
  );

  const editUnits = (rowData: InvestentListType | any) => {
    let error = false;
    let selectedError: any;
    if (errorMessageState !== undefined) {
      selectedError = errorMessageState.find(
        (data: any) =>
          data.lpPartnerId === rowData.lpPartnerId &&
          data.stakeTrackingID === rowData.stakeTrackingID
      );
      error = selectedError?.unitsToAllocateError !== "";
    }
    const handleInputChange = (e: any, data: InvestentListType) => {
      const newValue = e.value;
      const remainingCashBalance =
        data.totalAmount < newValue
          ? data.cashBalance - (newValue - data.totalAmount)
          : data.cashBalance + (data.totalAmount - newValue);
      const res: any = managerFees?.find(
        (per: ManagerFeesType) =>
          newValue >= per.lowerFundingAmount &&
          newValue < per.upperFundingAmount
      );
      const percent = res?.preSetPercentage ? res?.preSetPercentage : 0;
      const fees = (newValue * 1 * percent) / 100;
      const updatedRowData = {
        ...data,
        cashEquivalent: newValue,
        remainingCashBalance: remainingCashBalance,
        managerFees: data.managerFees
          ? data.managerFees
          : res?.fixedAmount
          ? res?.fixedAmount
          : fees,
      };

      dispatch(
        editPoolFundsActions.setEditPoolFundData({
          ...editPoolFundData,
          investorList: editPoolFundData.investorList.map((list: any) => {
            if (
              list.lpPartnerId === updatedRowData.lpPartnerId &&
              list.stakeTrackingID === updatedRowData.stakeTrackingID
            ) {
              return { ...updatedRowData, isDisable: false };
            } else if (
              list.lpPartnerId === updatedRowData.lpPartnerId &&
              list.stakeTrackingID !== updatedRowData.stakeTrackingID
            ) {
              return {
                ...list,
                remainingCashBalance: remainingCashBalance,
                isDisable: true,
              };
            }
            return list;
          }),
        })
      );
      const modifyErrorList = (
        unitsError: any,
        cashError: any,
        finalUnitError: any = ""
      ) =>
        errorMessageState.map((obj: any) => {
          const newData =
            obj?.lpPartnerId === data.lpPartnerId &&
            obj.stakeTrackingID === updatedRowData.stakeTrackingID &&
            newValue >= 0
              ? {
                  ...obj,
                  unitsToAllocateError: unitsError,
                  cashEquivalentError: cashError,
                  finalUnitError: finalUnitError,
                }
              : obj?.lpPartnerId === data.lpPartnerId &&
                obj.stakeTrackingID !== updatedRowData.stakeTrackingID &&
                newValue === null
              ? {
                  ...obj,
                  unitsToAllocateError: "",
                  cashEquivalentError: "",
                  finalUnitError: "",
                }
              : obj;
          return newData;
        });
      const remainingTotal = updatedRowData.remainingCashBalance
        ? updatedRowData.remainingCashBalance - newValue
        : updatedRowData.cashBalance - newValue;
      const cashBalance = updatedRowData.remainingCashBalance
        ? updatedRowData.remainingCashBalance
        : updatedRowData.cashBalance;

      if (
        remainingTotal < 0 &&
        newValue > cashBalance &&
        newValue > updatedRowData.totalAmount + updatedRowData.cashBalance &&
        newValue !== 0
      ) {
        setErrorMessageState(
          modifyErrorList(
            "Amount allocated is higher than available cash balance",
            "Amount allocated is higher than available cash balance"
          )
        );
      } else if (
        newValue > cashBalance &&
        newValue > updatedRowData.totalAmount + updatedRowData.cashBalance &&
        newValue !== 0
      ) {
        setErrorMessageState(
          modifyErrorList(
            "",
            "Amount allocated is higher than available cash balance"
          )
        );
      } else if (
        remainingTotal < 0 &&
        newValue > updatedRowData.totalAmount + updatedRowData.cashBalance
      ) {
        setErrorMessageState(
          modifyErrorList(
            "Amount allocated is higher than available cash balance",
            ""
          )
        );
      } else if (
        (updatedRowData.cashEquivalent !== undefined
          ? updatedRowData.cashEquivalent
          : updatedRowData.totalAmount) -
          updatedRowData.managerFees <=
        0
      ) {
        setErrorMessageState(
          modifyErrorList(
            newValue === 0 || newValue === null
              ? "Allocate Amount can not be zero or null"
              : "",
            "",
            "Final Allocation shoud not be nagative"
          )
        );
      } else {
        const resetErrors = errorMessageState.map((obj: any) => ({
          ...obj,
          unitsToAllocateError: "",
          finalUnitError: "",
        }));
        const resetCashErrors = resetErrors.map((obj: any) =>
          obj?.lpPartnerId === data.lpPartnerId &&
          obj.stakeTrackingID === updatedRowData.stakeTrackingID
            ? { ...obj, cashEquivalentError: "" }
            : obj
        );
        setErrorMessageState(resetCashErrors);
      }
    };
    return (
      <>
        {editPoolFundData?.investmentDetails?.isDistributed ||
        rowData?.isDisable ? (
          <div className="flex ml-2">{currencyFormat(rowData.totalAmount)}</div>
        ) : (
          <div className="flex flex-column">
            <div className="flex align-items-center justify-content-center">
              <InputNumber
                name="txtunits"
                id="txtunits"
                value={
                  rowData.cashEquivalent !== undefined
                    ? rowData.cashEquivalent
                    : rowData.totalAmount !== 0
                    ? rowData.totalAmount
                    : undefined
                }
                onChange={(e) => handleInputChange(e, rowData)}
                onValueChange={(e) => handleInputChange(e, rowData)}
                className={classNames({
                  "p-invalid": error,
                })}
                min={0}
                mode="currency"
                currency="USD"
                locale="en-US"
                minFractionDigits={2}
                placeholder="Enter Amount"
                disabled={
                  editPoolFundData.investmentDetails?.isDistributed ||
                  rowData?.isDisable
                }
              />
              {error === true ? (
                <RiErrorWarningLine
                  title="Amount allocated is higher than available cash balance"
                  className="iconError"
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </>
    );
  };
  const cashEquField = (rowData: InvestentListType) => {
    let selectedError: any;
    let error: any = false;
    if (errorMessageState !== undefined) {
      selectedError = errorMessageState.find(
        (data: any) =>
          data.lpPartnerId === rowData.lpPartnerId &&
          data.stakeTrackingID === rowData.stakeTrackingID
      );
      error = selectedError?.cashEquivalentError !== "";
    }

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <label
            className={`w-100 ${
              rowData.cashEquivalent !== undefined ? "text-green" : ""
            }`}
          >
            {currencyFormat(
              rowData.cashEquivalent !== undefined
                ? rowData.cashEquivalent
                : rowData.totalAmount
                ? rowData.totalAmount
                : rowData.addOnUnits || 0
            )}
          </label>
          {error === true ? (
            <RiErrorWarningLine
              title="Amount allocated is higher than available cash balance"
              className="iconError"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };
  const handleReInvested = (data: any) => {
    const handleCheckBox = (e: any) => {
      const updatedRowData = {
        ...data,
        isReinvest: e.checked,
      };
      dispatch(
        editPoolFundsActions.setEditPoolFundData({
          ...editPoolFundData,
          investorList: editPoolFundData.investorList.map(
            (list: InvestentListType) => {
              if (
                list.lpPartnerId === updatedRowData.lpPartnerId &&
                list.stakeTrackingID === updatedRowData.stakeTrackingID
              ) {
                return updatedRowData;
              } else if (list.lpPartnerId === updatedRowData.lpPartnerId) {
                return { ...list, isReinvest: e.checked };
              }
              return list;
            }
          ),
        })
      );
    };

    return (
      <div className="flex align-items-center gap-1">
        <Checkbox
          name="data"
          onChange={(e) => handleCheckBox(e)}
          checked={data.isReinvest}
          disabled={
            editPoolFundData.investmentDetails?.isReinvest ||
            editPoolFundData.investmentDetails?.isDistributed ||
            data.isDisable
          }
        />
      </div>
    );
  };

  const editDates = (rowData: any) => {
    let selectedError: any;
    let error: any = false;

    if (errorMessageState !== undefined) {
      selectedError = errorMessageState.find(
        (data: any) =>
          data.lpPartnerId === rowData.lpPartnerId &&
          data.stakeTrackingID === rowData.stakeTrackingID
      );
      error = selectedError?.allocationDateError !== "";
    }
    const filteredDataRow = editPoolFundData.investorList.filter(
      (item) =>
        item.lpPartnerId === rowData.lpPartnerId &&
        item.stakeTrackingID !== rowData.stakeTrackingID
    );
    const lessThanSelectedRow = filteredDataRow
      .reverse()
      .find((item) => item.stakeTrackingID < rowData.stakeTrackingID);

    const greaterThanSelectedRow = filteredDataRow
      .reverse()
      .find((item) => item.stakeTrackingID > rowData.stakeTrackingID);

    const handleInputChange = (e: any, data: any) => {
      const { value } = e.target;
      const updateRowData = {
        ...data,
        maxStartDate: formatDateMMDDYYY(new Date(value)),
      };
      /* eslint-disable no-param-reassign */
      const filteredData = editPoolFundData.investorList.filter(
        (item) =>
          item.lpPartnerId === updateRowData.lpPartnerId &&
          item.stakeTrackingID !== updateRowData.stakeTrackingID
      );

      const lessThanSelected = filteredData
        .reverse()
        .find((item) => item.stakeTrackingID < updateRowData.stakeTrackingID);

      const greaterThanSelected = filteredData
        .reverse()
        .find((item) => item.stakeTrackingID > updateRowData.stakeTrackingID);

      const modifyErrorList = (dateError: any) =>
        errorMessageState.map((obj: any) => {
          return obj?.lpPartnerId === data.lpPartnerId &&
            obj.stakeTrackingID === updateRowData.stakeTrackingID
            ? {
                ...obj,
                allocationDateError: dateError,
              }
            : obj;
        });
      if (
        greaterThanSelected &&
        new Date(value) > new Date(greaterThanSelected?.maxStartDate)
      ) {
        errorToastMessage(
          toast,
          `Allocation date can not be greater than ${greaterThanSelected.maxStartDate}`
        );
      }
      if (
        lessThanSelected &&
        new Date(value) < new Date(lessThanSelected?.maxStartDate)
      ) {
        errorToastMessage(
          toast,
          `Allocation date can not be less than ${lessThanSelected.maxStartDate}`
        );
      }

      if (
        new Date(value).setHours(0, 0, 0, 0) <
        new Date(
          editPoolFundData.investmentDetails?.investmentStartDate!
        ).setHours(0, 0, 0, 0)
      ) {
        setErrorMessageState(
          modifyErrorList(
            "The Allocation Date is earlier than permitted. Allocation Date has to be the same or greater than the Previous Allocation Date."
          )
        );
      } else if (
        greaterThanSelected &&
        new Date(value).setHours(0, 0, 0, 0) >
          new Date(greaterThanSelected?.maxStartDate).setHours(0, 0, 0, 0)
      ) {
        setErrorMessageState(
          modifyErrorList(
            `Allocation date can not be greater than ${greaterThanSelected.maxStartDate}`
          )
        );
      } else if (
        lessThanSelected &&
        new Date(value).setHours(0, 0, 0, 0) <
          new Date(lessThanSelected?.maxStartDate).setHours(0, 0, 0, 0)
      ) {
        setErrorMessageState(
          modifyErrorList(
            `Allocation date can not be less than ${lessThanSelected.maxStartDate}`
          )
        );
      } else {
        setErrorMessageState(modifyErrorList(""));
        dispatch(
          editPoolFundsActions.setEditPoolFundData({
            ...editPoolFundData,
            investorList: editPoolFundData.investorList.map((obj: any) => {
              if (
                obj.lpPartnerId === data.lpPartnerId &&
                obj.stakeTrackingID === updateRowData.stakeTrackingID
              ) {
                return { ...updateRowData, isDisable: false };
              } else if (obj.lpPartnerId === updateRowData.lpPartnerId) {
                return { ...obj, isDisable: true };
              }
              return obj;
            }),
          })
        );
      }
    };

    return (
      <>
        {editPoolFundData.investmentDetails?.isDistributed ||
        rowData.isDisable ? (
          formatDateMMDDYYY(new Date(rowData.maxStartDate))
        ) : (
          <>
            <div className="flex flex-column">
              <div className="flex align-items-center justify-content-center">
                <Calendar
                  name="allocationDate"
                  value={
                    rowData.maxStartDate
                      ? new Date(rowData.maxStartDate)
                      : undefined
                  }
                  minDate={
                    new Date(
                      lessThanSelectedRow
                        ? lessThanSelectedRow.maxStartDate
                        : editPoolFundData.investmentDetails
                            ?.investmentStartDate!
                    )
                  }
                  maxDate={
                    greaterThanSelectedRow
                      ? new Date(greaterThanSelectedRow.maxStartDate)
                      : undefined
                  }
                  onChange={(e) => handleInputChange(e, rowData)}
                  placeholder="Select Date"
                  className="card-inputBox"
                  readOnlyInput
                  disabled={
                    editPoolFundData.investmentDetails?.isDistributed ||
                    rowData.isDisable
                  }
                  dateTemplate={DateTemplate}
                />
                {error && (
                  <RiErrorWarningLine
                    title={selectedError?.allocationDateError || ""}
                    className="iconError"
                  />
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const finalAllocationAmount = (rowData: InvestentListType) => {
    let error = false;
    let selectedError: any;
    if (errorMessageState !== undefined) {
      selectedError = errorMessageState.find(
        (data: any) =>
          data.lpPartnerId === rowData.lpPartnerId &&
          data.stakeTrackingID === rowData.stakeTrackingID
      );
      error = selectedError?.finalUnitError !== "";
    }
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <span
            className={rowData.cashEquivalent !== undefined ? "text-green" : ""}
          >
            {rowData.managerFees && rowData.addOnUnits
              ? currencyFormat(
                  (rowData.cashEquivalent !== undefined
                    ? rowData.cashEquivalent
                    : rowData.totalAmount
                    ? rowData.totalAmount
                    : rowData.addOnUnits) - rowData.managerFees
                )
              : currencyFormat(
                  rowData.cashEquivalent !== undefined
                    ? rowData.cashEquivalent
                    : rowData.totalAmount
                    ? rowData.totalAmount
                    : rowData.addOnUnits || 0
                )}
          </span>
          {error === true ? (
            <RiErrorWarningLine
              title="Final Allocation (after fees) Should not be negative number"
              className="iconError"
              size={25}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const handleManagerFees = (data: InvestentListType) => {
    return (
      <>
        <div className="flex align-items-center flex-column">
          <label className="mr-2">{currencyFormat(data.managerFees)}</label>
          {editPoolFundData.investmentDetails?.isDistributed ||
          data.isDisable ? (
            <></>
          ) : (
            <label
              className="card-subheader link-underline w-max"
              onClick={(e) => {
                e.preventDefault();
                setManagerFee(data.managerFees ? data.managerFees : 0);
                setManualData(data);
                setManualFeesId(data.lpPartnerId);
              }}
            >
              Enter Manually
            </label>
          )}
        </div>
      </>
    );
  };

  const checkCashbalance = (rowData: any) => {
    return (
      <>
        <span
          className={rowData.cashEquivalent !== undefined ? "text-green" : ""}
        >
          {currencyFormat(
            rowData.remainingCashBalance !== undefined
              ? rowData.remainingCashBalance
              : rowData.cashBalance,
            rowData.cashBalance <= 0 && rowData.cashBalance > -1 ? 4 : 2
          )}
        </span>
      </>
    );
  };

  const checkError = () => {
    if (errorMessageState !== undefined) {
      return errorMessageState.some(
        (error: any) =>
          error.unitsToAllocateError !== "" ||
          error.cashEquivalentError !== "" ||
          error.finalUnitError !== ""
        // ||
        // error.allocationDateError !== ""
      );
    }
    return false;
  };
  const dataTableElement = [
    {
      field: "lpPartnerName",
      header: "Investor",
      noFilter: true,
      noSort: true,
      style: { width: "12rem" },
    },
    {
      header: "Allocation Date",
      body: editDates,
      style: { width: "10rem" },
    },
    {
      header: "Cash Balance",
      body: checkCashbalance,
    },
    {
      header: "Allocate Amount ",
      body: editUnits,
      bodyClassName: "p-1",
    },

    {
      header: "Allocation Amount (before fees)",
      body: cashEquField,
    },
    {
      header: "Manager Fees",
      body: handleManagerFees,
    },
    {
      header: "Final Allocation (after fees)",
      body: finalAllocationAmount,
    },
    {
      header: "Earnings Reinvested",
      body: handleReInvested,
    },
  ];
  const onBackBtn = () => {
    dispatch(editPoolFundsActions.resetEditPoolFundData());
    setInvestorData([]);
    setErrorMessageState([]);
    handlePrevious();
  };
  const handleChange = (e: any) => {
    const { value } = e.target;
    dispatch(
      editPoolFundsActions.setEditPoolFundData({
        ...editPoolFundData,
        productId: value,
      })
    );
  };

  const handleFeesInput = () => {
    const updatedRowData = {
      ...manualData,
      managerFees: managerFee,
      isDisable: false,
    };
    dispatch(
      editPoolFundsActions.setEditPoolFundData({
        ...editPoolFundData,
        investorList: editPoolFundData.investorList.map(
          (list: InvestentListType) => {
            if (
              list.lpPartnerId === updatedRowData.lpPartnerId &&
              list.stakeTrackingID === updatedRowData.stakeTrackingID
            ) {
              return updatedRowData;
            } else if (list.lpPartnerId === updatedRowData.lpPartnerId) {
              return { ...list, isDisable: true };
            }
            return list;
          }
        ),
      })
    );
    const modifyErrorList = (
      unitsError: any,
      cashError: any,
      finalUnitError: any = ""
    ) =>
      errorMessageState.map((obj: any) => {
        const newData =
          obj?.lpPartnerId === updatedRowData.lpPartnerId &&
          obj.stakeTrackingID === updatedRowData.stakeTrackingID &&
          (updatedRowData.cashEquivalent !== undefined
            ? updatedRowData.cashEquivalent
            : updatedRowData.totalAmount) -
            updatedRowData.managerFees <=
            0
            ? {
                ...obj,
                finalUnitError: finalUnitError,
              }
            : obj;
        return newData;
      });

    if (
      (updatedRowData.cashEquivalent !== undefined
        ? updatedRowData.cashEquivalent
        : updatedRowData.totalAmount) -
        updatedRowData.managerFees <=
      0
    ) {
      setErrorMessageState(
        modifyErrorList("", "", "Final Allocation shoud not be nagative")
      );
    } else {
      const resetCashErrors = errorMessageState.map((obj: any) =>
        obj?.lpPartnerId === updatedRowData.lpPartnerId
          ? { ...obj, finalUnitError: "" }
          : obj
      );
      setErrorMessageState(resetCashErrors);
    }
    setManagerFee(0);
    setManualData("");
    setManualFeesId("");
  };

  const onDialogCancel = () => {
    setManagerFee(0);
    setManualData("");
    setManualFeesId("");
  };

  const onRestClick = () => {
    setLoading(true);
    const parameter = {
      legalEntityId: fundDropdown.fundid,
      investmentId: editPoolFundData.investmentId,
    };
    dispatch(getAllocatedInvesterEditPooledFundThunk(parameter))
      .then((result) => {
        setInvestorData(result.payload.data.output);
      })
      .catch(() => {
        setInvestorData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onUpdate = () => {
    const hasError = errorMessageState.some(
      (error: any) =>
        error.unitsToAllocateError !== "" || error.cashEquivalentError !== ""
    );
    // it will return true
    const hasNull = editPoolFundData?.investorList.some(
      (unitCheck: InvestentListType) => {
        return (
          unitCheck.totalAmount === null ||
          unitCheck.totalAmount === 0 ||
          (unitCheck.cashEquivalent !== undefined
            ? unitCheck.cashEquivalent
            : unitCheck.totalAmount) -
            unitCheck.managerFees <=
            0
        );
      }
    );
    if (!hasError && !hasNull) {
      setLoading(true);
      const allList = [...editPoolFundData.investorList];
      const list = allList.map((item: InvestentListType | any) => {
        return {
          lpPartnerId: item.lpPartnerId,
          allocationDate: convertLocalDateToUTC(item.maxStartDate),
          allocatedUnits:
            item.totalAmount && item.managerFees
              ? (item.cashEquivalent !== undefined
                  ? item.cashEquivalent
                  : item.totalAmount) - item.managerFees
              : item.cashEquivalent !== undefined
              ? item.cashEquivalent
              : item.totalAmount,
          prevAllocatedUnits: 0,
          lpPartnerStakeTrackingId: 0,
          managerFees: item.managerFees,
          isReinvest: item.isReinvest,
        };
      });
      const parameter = {
        action: "Update",
        legalEntityId: fundDropdown.fundid,
        investmentId: editPoolFundData.investmentId,
        stakeTrackingId: 0,
        totalUnits: 0,
        unitPrice: 0,
        poolfundsUnitsData: list,
      };
      dispatch(addNewPoolfundsUnitsThunk(parameter))
        .then((response: any) => {
          handleNext();
          setLoading(false);
          const message = response?.payload?.message;
          response?.payload?.success
            ? successToastMessage(toast, message)
            : errorToastMessage(toast, message);

          onRestClick();
        })
        .catch((error: any) => {
          setLoading(false);
          errorToastMessage(toast);
          console.log("err", error);
        });
    } else if (hasNull) {
      errorToastMessage(toast, "Please allocate units for all investors");
    } else {
      errorToastMessage(toast, "Please check the allocated units");
    }
  };

  const onRowClassName = (data: any) => {
    return data?.isDisable ? "bg-secondary" : "";
  };
  return (
    <>
      {loading ? <Loader /> : <></>}
      <Dialog
        visible={!!manualFeesId}
        onHide={() => onDialogCancel()}
        className="w-100 md:w-3 pb-1"
        headerClassName="no-header"
      >
        <div className="mt-2">
          <h4 className="pageHeader mt-2 flex justify-content-center">
            Please add manager fees{" "}
          </h4>
          <div className="p-2 col-12 flex justify-content-center">
            <div className="mt-2 flex">
              <div className="flex justify-content-center align-items-center pr-4 mb-1">
                <InputNumber
                  value={managerFee !== 0 ? managerFee : undefined}
                  min={0}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  placeholder="Enter Manager Fees"
                  minFractionDigits={2}
                  onChange={(e) => setManagerFee(e.value || 0)}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row flex-wrap sm:flex-none p-0 col-12 justify-content-center ">
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-nav justify-content-center"
                onClick={(e) => {
                  e.preventDefault();
                  onDialogCancel();
                }}
              >
                <span className="ml-auto mr-auto ">Cancel</span>
              </Button>
            </div>
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-navActive justify-content-center"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleFeesInput();
                }}
              >
                <span className="ml-auto mr-auto ">Submit</span>
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <div className="mt-4">
        <div className="formgrid grid px-2">
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
            <label className="inputLabel">Select Investment</label>
            <Dropdown
              inputId="investment"
              name="investment"
              value={editPoolFundData.productId}
              options={investmentDropdownList || []}
              filter
              optionLabel="name"
              optionValue="code"
              placeholder="Select Investment"
              className="card-inputBox"
              // showOnFocus
              onChange={(e) => {
                e.preventDefault();
                handleChange(e);
              }}
            />
          </div>
        </div>

        {editPoolFundData.investmentDetails && (
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-6">
              <label className="inputLabel">Investment Start Date:</label>
              <h3>
                {editPoolFundData.investmentDetails?.investmentStartDate ? (
                  formatDateMMDDYYY(
                    new Date(
                      editPoolFundData.investmentDetails?.investmentStartDate
                    )
                  )
                ) : (
                  <></>
                )}
              </h3>
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-6">
              <label className="inputLabel">
                Most Recent Allocation Date:{" "}
              </label>
              <h3>
                {editPoolFundData.investmentDetails?.lastAllocationDate ? (
                  formatDateMMDDYYY(
                    new Date(
                      editPoolFundData.investmentDetails?.lastAllocationDate
                    )
                  )
                ) : (
                  <></>
                )}
              </h3>
            </div>
          </div>
        )}
        <div className="formgrid grid px-2 mt-3">
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 ">
            <label className="inputLabel">Investor Accounts</label>
            {editPoolFundData.investmentDetails?.isDistributed && (
              <h6 className="mt-2 warning-text">
                Note: Can not edit this investement, Please revert the earnings
                & exits.
              </h6>
            )}
          </div>
        </div>
      </div>
      <div className="formgrid grid px-2 col-12 allocatedTable">
        <DataTableComponent
          valueData={investortList || []}
          fieldsElements={dataTableElement}
          noGridLines={true}
          isPaginationAllowed={true}
          className="allocateSliceTable"
          rowClassName={onRowClassName}
        />

        <div className="formgrid grid px-2">
          <div className="iconError mb-3  p-2">
            <ul>
              {(hasCashError || hasUnitError) && (
                <li>
                  Amount allocated is higher than available cash balance or
                  amount is zero
                </li>
              )}
              {hasFinalUnitError && (
                <li>
                  Final Allocation (after fees) Should not be negative number or
                  zero
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {editPoolFundData.investorList.length > 0 && (
        <div className="formgrid grid px-2">
          <div className="d-block sm:d-flex sm:flex-column p-3 col-12 flex align-items-end">
            <label className="inputLabel">Total amount being allocated:</label>
            <h3 className={`text-${calculatedEarning <= 0 ? "red" : "green"}`}>
              {currencyFormat(calculatedEarning)}
            </h3>
          </div>
        </div>
      )}
      <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center ">
        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
          <Button
            className="btn-nav"
            onClick={(e) => {
              e.preventDefault();
              onBackBtn();
            }}
          >
            <span className="ml-auto mr-auto ">Cancel</span>
          </Button>
        </div>
        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
          <Button
            className="btn-navActive"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onUpdate();
            }}
            disabled={
              editPoolFundData.investmentDetails?.isDistributed ||
              !editPoolFundData.investmentId ||
              editPoolFundData.investorList.length === 0 ||
              checkError()
            }
          >
            <span className="ml-auto mr-auto ">Update</span>
          </Button>
        </div>
        {editPoolFundData.investorList?.length > 0 && (
          <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
            <Button
              className="btn-navActive"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onRestClick();
              }}
              disabled={
                editPoolFundData.investmentDetails?.isDistributed ||
                !editPoolFundData.investmentId ||
                editPoolFundData.investorList.length === 0
              }
            >
              <span className="ml-auto mr-auto ">Reset</span>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default EditPoolFundStep1;
