import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    visibleRightState: false,
    // setVisibleRightState: (val: any) => {},
  },
};

const slice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    setChatBotState: (state, action) => {
      console.log(" as234", action.payload);
      state.data = action.payload;
      //
    },
  },
});
export const { setChatBotState } = slice.actions;
export default slice.reducer;
