import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOpportunitiesService } from "../../services/opportunity/opportunity";
import { RootState } from "../../store";
import { IOpportunity } from "../../types/index";
// import apiFactory from "../../utils/api";
import error from "../../utils/error";

interface IParameter {
  lpPartnerID: string;
  category: string;
  deals: string;
  availablePageNo: number;
  pageSize: number;
  legalEntityId: number;
}
interface IOpportunityState {
  opportunitiesData?: IOpportunity[] | null;
  pageDetails?: { totalCount: number; totalPages: number } | null;
  loading: string;
  error?: string | null;
}
const initialState: IOpportunityState = {
  opportunitiesData: [],
  pageDetails: undefined,
  loading: "loading",
  error: undefined,
};
export const getOpportunitiesThunk = createAsyncThunk(
  "opportunity/getOpportunities",
  async (body: IParameter) => {
    try {
      const response = await getOpportunitiesService(body);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "opportunity",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getOpportunitiesThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getOpportunitiesThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.opportunitiesData = action.payload.opportunitiesData;
      state.pageDetails = action.payload.pageDetails;
      // state.currentPage += 1;
    });
    builder.addCase(getOpportunitiesThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
      state.opportunitiesData = [];
      state.pageDetails = undefined;
    });
  },
});
export const getAllOpportunitiesData = (state: RootState) => {
  return state.opportunity.opportunitiesData;
};
export const getAllOpportunitiesStatus = (state: RootState) =>
  state.opportunity.loading;
export const getAllOpportunitiesError = (state: RootState) =>
  state.opportunity.error;
export default slice.reducer;
