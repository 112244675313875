import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { loginRequest } from "../b2c/b2c-config";
// import Loader from "../../../../components/loader/Loader";
import { getThunkColor } from "../../../../store/features/color/slice";
import {
  getFundImageThunk,
  getFundLogoThunk,
} from "../../../../store/features/profile/slice";
// import {
//   // setBannerState,
//   // setImageState,
// } from "../../../../store/getasset/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
// import { getSasKeyThunk } from "../../../../store/sas-key/slice";
import { envVariable } from "../../../../utils/sponsor/helper";
// import { errorToastMessage } from "../../../../utils/toast-message";

const DefaultLandingPage = () => {
  // const navigate = useNavigate();
  console.log(" default landing--------");
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  // console.log("slug", slug);
  // const { instance } = useMsal();
  const toast = useRef<Toast>(null);
  const [loader, setLoader] = useState(false);

  const sideLogo = useAppSelector((state) => state.profile.sideLogo);
  const fundImage = useAppSelector((state) => state.profile.fundImage);

  useEffect(() => {
    setLoader(true);
    (async () => {
      await dispatch(getFundLogoThunk());
      await dispatch(getFundImageThunk());
      setLoader(false);
    })();
    dispatch(getThunkColor()).then((res: any) => {
      console.log("res", res);
      const color = res.payload.response?.data?.primaryColour;
      // console.log("color", color);
      const root = document.documentElement;
      if (color) {
        // setLoader(false);
        root?.style.setProperty("--background-color", color);
      } else {
        root?.style.setProperty("--background-color", "#004d82");
      }
    });
  }, []);

  return (
    <>
      <Toast ref={toast} className="themeToast" />
      {/* {loader && <Loader />} */}
      {loader ? (
        <ProgressSpinner />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 px-0">
              <div className="default-form-body">
                <div className="loginform-logo">
                  <img className="img-fluid py-1 fundLogo" src={sideLogo} />
                </div>
                <div className="formwrap">
                  <div className="formCont">
                    <div className="panel-default">
                      <div className="panel-body">
                        <div className="heading flex align-items-center justify-content-center ">
                          <h1 role="heading" className="mainHeader">
                            Welcome To Manager Portal
                          </h1>
                        </div>
                        <div className="buttons">
                          {/* <Button
                            className="default-btn w-full"
                            onClick={handleLogin2}
                          >
                            Sign in
                          </Button> */}
                          <a
                            className="default-btn w-full"
                            href={`/NewLogin/${slug}`}
                          >
                            <span className="default-signin-text">Sign in</span>
                          </a>
                          {/* Sponsor don't need signup */}
                          {/* <a
                            className="default-signup-btn w-full"
                            href={`/signup/${slug}`}
                          >
                            <span className="default-btn-text">
                              Don&apos;t have an account?{" "}
                            </span>
                            <span className="default-signup-btn-text">
                              Sign up now
                            </span>
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="justify-content-center d-flex align-self-end pb-4">
                  <a
                    href="https://www.avestorinc.com"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      className="img-fluid avestorabslogo"
                      src={envVariable.REACT_APP_LOGO}
                      // src="https://b2cflowav.blob.core.windows.net/b2c/common/avestor-logo.png"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 loginSideImgWrap">
              <img src={fundImage} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DefaultLandingPage;
