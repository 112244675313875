import { FormikValues } from "formik";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";
import React from "react";
import { useAppSelector } from "../../../../../store/hooks";
import { onlyCharacter } from "../../../../../utils/helper";

interface IProps {
  accountTypeID: number;
  formik: FormikValues;
  isFormFieldInvalid: any;
  getFormErrorMessage: any;
}

const TaxInformation: React.FC<IProps> = (props) => {
  // console.log("props", props);
  const taxClassificationsDropdown = useAppSelector(
    (state) => state.getLookUpbyAccountId.taxClassificationsDropdown
  );
  const accountOwnershipDropdown = useAppSelector(
    (state) => state.getLookUpbyAccountId.accountOwnershipDropdown
  );
  const trustTypeDropdown = useAppSelector(
    (state) => state.getLookUpbyAccountId.trustTypeDropdown
  );
  const revocabilityDropdown = useAppSelector(
    (state) => state.getLookUpbyAccountId.trustRevocabilityDropdown
  );

  const stateDropdown = useAppSelector((state) => {
    return state.investingAccountsLookups.stateDropdown;
  });

  return (
    <>
      {/* <h4 className="cardHeader mt-4">Tax Information</h4> */}
      <h4 className="investorHeader mt-4">Tax Information</h4>
      <div className="formgrid grid">
        {props.accountTypeID === 3 && (
          <>
            <div className="field col-12 md:col-6 text-left">
              <label className="inputLabel">
                Is this LLC regarded or disregarded?{" "}
                <span className="text-red-500">*</span>
              </label>
              <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="Regarded"
                    name="Regarded"
                    value={false}
                    checked={props.formik.values.disRegarded === false}
                    onChange={(e) => {
                      console.log("Yes e", e);
                      props.formik.setFieldValue("disRegarded", e.value);
                    }}
                  />
                  <label htmlFor="Regarded" className="ml-2">
                    Regarded
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="Disregarded"
                    name="Disregarded"
                    value={true}
                    checked={props.formik.values.disRegarded === true}
                    onChange={(e) => {
                      console.log("No e", e);
                      props.formik.setFieldValue("disRegarded", e.value);
                    }}
                  />
                  <label htmlFor="Disregarded" className="ml-2">
                    Disregarded
                  </label>
                </div>
              </div>
              {props.getFormErrorMessage("disRegarded")}
            </div>
          </>
        )}
        {props.accountTypeID === 2 && (
          <>
            <div className="field col-12 md:col-6 text-left">
              <label className="inputLabel">
                Does this trust use an SSN or EIN?{" "}
                <span className="text-red-500">*</span>
              </label>
              <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="SSN"
                    name="SSN"
                    value={false}
                    checked={props.formik.values.isEIN === false}
                    onChange={(e) => {
                      console.log("Yes e", e);
                      props.formik.setFieldValue("isEIN", e.value);
                    }}
                  />
                  <label htmlFor="SSN" className="ml-2">
                    SSN
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="EIN"
                    name="EIN"
                    value={true}
                    checked={props.formik.values.isEIN === true}
                    onChange={(e) => {
                      console.log("No e", e);
                      props.formik.setFieldValue("isEIN", e.value);
                    }}
                  />
                  <label htmlFor="EIN" className="ml-2">
                    EIN
                  </label>
                </div>
              </div>
              {props.getFormErrorMessage("isEIN")}
            </div>
          </>
        )}
      </div>
      <div className="formgrid grid">
        {props.accountTypeID === 1 && (
          <>
            <div className="field col-12 md:col-6 text-left">
              <label className="inputLabel">
                First Name <span className="text-red-500">*</span>
              </label>
              <InputText
                name="firstName"
                value={props.formik.values.firstName}
                // unmask={true}
                placeholder="Enter First Name"
                onChange={(e) => {
                  props.formik.setFieldValue(
                    "firstName",
                    onlyCharacter(e.target.value)
                  );
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid("firstName"),
                  "card-inputBox": true,
                })}
              />
              {props.getFormErrorMessage("firstName")}
            </div>
            <div className="field col-12 md:col-6 text-left">
              <label className="inputLabel">
                Last Name <span className="text-red-500">*</span>
              </label>
              <InputText
                name="lastName"
                value={props.formik.values.lastName}
                // unmask={true}
                placeholder="Enter Last Name"
                onChange={(e) => {
                  props.formik.setFieldValue(
                    "lastName",
                    onlyCharacter(e.target.value)
                  );
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid("lastName"),
                  "card-inputBox": true,
                })}
              />
              {props.getFormErrorMessage("lastName")}
            </div>
          </>
        )}
        {props.accountTypeID === 5 && (
          <div className="field col-12 md:col-6">
            <label className="inputLabel">Custodian Name </label>
            <InputText
              name="custodianName"
              placeholder="Enter custodian name"
              value={props.formik.values.custodianName}
              onChange={(e: any) => {
                props.formik.setFieldValue(
                  "custodianName",
                  e.target.value?.trimStart()
                );
              }}
              // className="card-inputBox"
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("custodianName"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("custodianName")}
          </div>
        )}
        {(props.accountTypeID === 3 ||
          props.accountTypeID === 7 ||
          props.accountTypeID === 8 ||
          props.accountTypeID === 9) && (
          <div className="field col-6 text-left">
            <label className="inputLabel">
              Entity Name <span className="text-red-500">*</span>
            </label>
            <InputText
              name="entityName"
              value={props.formik.values.entityName}
              placeholder="Enter your entity name"
              onChange={(e: any) => {
                props.formik.setFieldValue(
                  "entityName",
                  e.target.value?.trimStart()
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("entityName"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("entityName")}
          </div>
        )}
        {/* SSN */}
        {(props.accountTypeID === 1 ||
          (props.accountTypeID === 3 &&
            props.formik.values.disRegarded === true) ||
          (props.accountTypeID === 2 &&
            props.formik.values.isEIN === false)) && (
          <div className="field col-12  md:col-6 text-left">
            <label className="inputLabel">
              Social Security Number <span className="text-red-500">*</span>
            </label>
            <InputMask
              autoClear={false}
              name="ssn"
              value={props.formik.values.socialSecurityNumber}
              mask="999-99-9999"
              // unmask={true}
              placeholder="xxx-xx-xxxx"
              onChange={(e) => {
                props.formik.setFieldValue(
                  "socialSecurityNumber",
                  e.target.value
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("socialSecurityNumber"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("socialSecurityNumber")}
          </div>
        )}
        {props.accountTypeID === 4 && (
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Account Title <span className="text-red-500">*</span>
            </label>
            <InputText
              name="accountTitle"
              value={props.formik.values.accountTitle}
              placeholder="Enter Your Account Title"
              onChange={(e: any) => {
                props.formik.setFieldValue(
                  "accountTitle",
                  e.target.value?.trimStart()
                );
              }}
              // className="card-inputBox"
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("accountTitle"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("accountTitle")}
          </div>
        )}
        {((props.accountTypeID === 2 && props.formik.values.isEIN === true) ||
          (props.accountTypeID === 3 &&
            props.formik.values.disRegarded === false) ||
          props.accountTypeID === 4 ||
          props.accountTypeID === 5 ||
          props.accountTypeID === 7 ||
          props.accountTypeID === 8 ||
          props.accountTypeID === 9 ||
          props.accountTypeID === 10) && (
          <div className="field col-12  md:col-6 text-left">
            <label className="inputLabel">
              Entity EIN <span className="text-red-500">*</span>
            </label>
            <InputMask
              autoClear={false}
              name="ein"
              value={props.formik.values.ein}
              placeholder="xx-xxxxxxx"
              //              unmask={true}
              mask="99-9999999"
              onChange={(e: any) => {
                props.formik.setFieldValue("ein", e.target.value);
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("ein"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("ein")}
          </div>
        )}

        {props.accountTypeID === 2 && (
          <>
            <div className="field col-12 md:col-6 text-left">
              <label className="inputLabel">
                Where was the trust established?{" "}
                <span className="text-red-500">*</span>
              </label>
              {props.formik.values.countryId !== 229 ? (
                <InputText
                  name="trustEstablishmentPlace"
                  value={props.formik.values.trustEstablishmentPlace?.trimStart()}
                  placeholder="Enter Establishment State"
                  onChange={(e: any) => {
                    props.formik.setFieldValue(
                      "trustEstablishmentPlace",
                      e.target.value
                    );
                  }}
                  // className="card-inputBox"
                  className={classNames({
                    "p-invalid": props.isFormFieldInvalid(
                      "trustEstablishmentPlace"
                    ),
                    "card-inputBox": true,
                  })}
                />
              ) : (
                <Dropdown
                  name="trustEstablishmentPlace"
                  value={props.formik.values.trustEstablishmentPlace}
                  options={stateDropdown}
                  showOnFocus
                  filter
                  optionLabel="value"
                  optionValue="key"
                  placeholder="Select Establishment State"
                  // className="card-inputBox"
                  onChange={(e) => {
                    console.log("e", e);
                    props.formik.setFieldValue(
                      "trustEstablishmentPlace",
                      e.value
                    );
                  }}
                  className={classNames({
                    "p-invalid": props.isFormFieldInvalid(
                      "trustEstablishmentPlace"
                    ),
                    "card-inputBox": true,
                  })}
                />
              )}
              {props.getFormErrorMessage("trustEstablishmentPlace")}
            </div>

            <div className="field col-12 md:col-6 text-left">
              <label className="inputLabel">
                Revocability <span className="text-red-500">*</span>
              </label>

              <Dropdown
                name="revocabilityId"
                value={props.formik.values.revocabilityId}
                options={revocabilityDropdown}
                filter
                showOnFocus
                optionLabel="value"
                optionValue="key"
                placeholder="Select Revocability "
                // className="card-inputBox"
                onChange={(e) => {
                  props.formik.setFieldValue("revocabilityId", e.value);
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid("revocabilityId"),
                  "card-inputBox": true,
                })}
              />
              {props.getFormErrorMessage("revocabilityId")}
            </div>
            <div className="field col-12 md:col-6 text-left">
              <label className="inputLabel">
                Trust Type <span className="text-red-500">*</span>
              </label>

              <Dropdown
                name="trustTypeId"
                value={props.formik.values.trustTypeId}
                options={trustTypeDropdown}
                filter
                showOnFocus
                optionLabel="value"
                optionValue="key"
                placeholder="Select Trust Type"
                // className="card-inputBox"
                onChange={(e) => {
                  props.formik.setFieldValue("trustTypeId", e.value);
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid("trustTypeId"),
                  "card-inputBox": true,
                })}
              />
              {props.getFormErrorMessage("trustTypeId")}
            </div>
          </>
        )}
        {props.accountTypeID === 6 && (
          <>
            <div className="field col-12 md:col-6 text-left">
              <label className="inputLabel">
                Primary Account First Name{" "}
                <span className="text-red-500">*</span>
              </label>
              <InputText
                name="primaryAccountFirstName"
                value={props.formik.values.primaryAccountFirstName}
                // unmask={true}
                placeholder="Enter First Name"
                onChange={(e) => {
                  props.formik.setFieldValue(
                    "primaryAccountFirstName",
                    onlyCharacter(e.target.value?.trimStart())
                  );
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid(
                    "primaryAccountFirstName"
                  ),
                  "card-inputBox": true,
                })}
              />
              {props.getFormErrorMessage("primaryAccountFirstName")}
            </div>
            <div className="field col-12 md:col-6 text-left">
              <label className="inputLabel">
                Primary Account Last Name{" "}
                <span className="text-red-500">*</span>
              </label>
              <InputText
                name="primaryAccountLastName"
                value={props.formik.values.primaryAccountLastName}
                // unmask={true}
                placeholder="Enter Last Name"
                onChange={(e) => {
                  props.formik.setFieldValue(
                    "primaryAccountLastName",
                    onlyCharacter(e.target.value?.trimStart())
                  );
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid(
                    "primaryAccountLastName"
                  ),
                  "card-inputBox": true,
                })}
              />
              {props.getFormErrorMessage("primaryAccountLastName")}
            </div>
            <div className="field col-12 md:col-6 text-left">
              <label className="inputLabel">
                Primary Account Holder SSN{" "}
                <span className="text-red-500">*</span>
              </label>
              <InputMask
                autoClear={false}
                name="primaryAccountHolderSSN"
                value={props.formik.values.primaryAccountHolderSSN}
                mask="999-99-9999"
                // unmask={true}
                placeholder="xxx-xx-xxxx"
                onChange={(e) => {
                  props.formik.setFieldValue(
                    "primaryAccountHolderSSN",
                    e.target.value
                  );
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid(
                    "primaryAccountHolderSSN"
                  ),
                  "card-inputBox": true,
                })}
              />
              {props.getFormErrorMessage("primaryAccountHolderSSN")}
            </div>
            <div className="field col-12 md:col-6 text-left">
              <label className="inputLabel">
                Account Ownership <span className="text-red-500">*</span>
              </label>
              <Dropdown
                name="accountOwnershipId"
                value={props.formik.values.accountOwnershipId}
                options={accountOwnershipDropdown}
                filter
                showOnFocus
                optionLabel="value"
                optionValue="key"
                placeholder="Select Ownership Type"
                // className="card-inputBox"
                onChange={(e) => {
                  console.log("accountOwnershipId", e);
                  props.formik.setFieldValue("accountOwnershipId", e.value);
                }}
                className={classNames({
                  "p-invalid": props.isFormFieldInvalid("accountOwnershipId"),
                  "card-inputBox": true,
                })}
              />
              {props.getFormErrorMessage("accountOwnershipId")}
            </div>
          </>
        )}
        {props.accountTypeID === 5 && (
          <div className="field col-12 md:col-6">
            <label className="inputLabel">Custodian Account Name</label>
            <InputText
              name="custodianAccountName"
              value={props.formik.values.custodianAccountName}
              placeholder="Enter Business Name"
              onChange={(e: any) => {
                props.formik.setFieldValue(
                  "custodianAccountName",
                  e.target.value
                );
              }}
              // className="card-inputBox"
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("custodianAccountName"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("custodianAccountName")}
          </div>
        )}
        {(props.accountTypeID === 7 ||
          props.accountTypeID === 8 ||
          props.accountTypeID === 9) && (
          <div className="field col-12 md:col-6 text-left">
            <label className="inputLabel">
              Tax classification <span className="text-red-500">*</span>
            </label>
            <Dropdown
              name="taxclassification"
              value={props.formik.values.taxclassification}
              options={taxClassificationsDropdown}
              filter
              showOnFocus
              optionLabel="value"
              optionValue="key"
              placeholder="Select Tax Classification"
              // className="card-inputBox"
              onChange={(e) => {
                console.log("taxClassification", e);
                props.formik.setFieldValue("taxclassification", e.value);
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("taxclassification"),
                "card-inputBox": true,
              })}
            />
            {props.getFormErrorMessage("taxclassification")}
          </div>
        )}
      </div>
    </>
  );
};

export default TaxInformation;
