import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
// import { ProgressBar } from "primereact/progressbar";
import React, { useEffect, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import DataTableComponent from "../../../../../components/data-table/DataTableComponent";
import { setUpdateAllocationSliceState } from "../../../../../store/features/operation/allocation-slices/edit-investor-allocation-state/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import currencyFormat from "../../../../../utils/currency-formatter";
import {
  // convertLocalDateToUTC,
  datewithTime,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../../utils/date-formatter";
interface IProps {
  errorMessageState: any;
  setErrorMessageState: any;
}
export default function AllocationUnits(props: IProps) {
  const dispatch = useAppDispatch();
  const [hasUnitError, sethasUnitError] = useState(false);
  const [hasCashError, sethascashError] = useState(false);
  const [hasDateError, setHasDateError] = useState(false);
  const { errorMessageState, setErrorMessageState } = props;
  const allocatedInvestor = useAppSelector(
    (state) => state.getAllocatedInvestorList.data
  );
  const [allocatedInvestorListData, setAllocatedInvestorListData] = useState<
    any[]
  >([]);
  const updateAllocationSliceState = useAppSelector(
    (state) => state.updateAllocationSliceState.data
  );

  const dateTemplate = (date: any) => {
    if (date.selectable) {
      return <span className="dateHighlight">{date.day}</span>;
    }

    return date.day;
  };
  const editDates = (rowData: any) => {
    let selectedError: any;
    let error: any = false;
    const MinMaxDateSame =
      new Date(rowData.maxDifferenceDate).setHours(0, 0, 0) ===
      new Date(rowData.minDifferenceDate).setHours(0, 0, 0);
    if (errorMessageState !== undefined) {
      selectedError = errorMessageState.find(
        (data: any) => data.stakeTrackingID === rowData.stakeTrackingID
      );
      error = selectedError.allocationDateError !== "";
    }
    const handleInputChange = (e: any, data: any) => {
      const newValue = datewithTime(e.target.value);

      const List: any = [...updateAllocationSliceState.investorList];
      const indexofElement = List.findIndex(
        (items: any) => items.stakeTrackingID === data.stakeTrackingID
      );
      const newList = List.map((item: any) => {
        const prevItem = List[indexofElement + 1];
        const nextItem = List[indexofElement - 1];

        if (prevItem && item.stakeTrackingID === prevItem.stakeTrackingID) {
          return {
            ...item,
            minDifferenceDate: newValue,
          };
        }
        if (nextItem && item.stakeTrackingID === nextItem.stakeTrackingID) {
          return {
            ...item,
            maxDifferenceDate: newValue,
          };
        }
        if (item.stakeTrackingID === data.stakeTrackingID) {
          return {
            ...item,
            editAllocatedDate: newValue,
          };
        }
        return item;
      });
      dispatch(
        setUpdateAllocationSliceState({
          ...updateAllocationSliceState,
          investorList: newList,
        })
      );

      const modifyErrorList = (dateError: any) =>
        errorMessageState.map((obj: any) => {
          const newData =
            obj?.stakeTrackingID === data.stakeTrackingID
              ? {
                  ...obj,
                  allocationDateError: dateError,
                }
              : obj;
          return newData;
        });

      if (
        new Date(newValue).setHours(0, 0, 0, 0) >=
          new Date(data.maxDifferenceDate).setHours(0, 0, 0, 0) &&
        (data.minDifferenceDate
          ? new Date(newValue).setHours(0, 0, 0, 0) <=
            new Date(data.minDifferenceDate).setHours(0, 0, 0, 0)
          : true)
      ) {
        setErrorMessageState(modifyErrorList(""));
      } else {
        setErrorMessageState(modifyErrorList("Date Error"));
      }
    };
    return (
      <>
        {rowData.partnerType !== "GP" ? (
          <div className="flex flex-column">
            <div className="flex align-items-center justify-content-center">
              <Calendar
                name="allocationDate"
                value={
                  rowData.allocationDate !== null
                    ? new Date(rowData.editAllocatedDate)
                    : undefined
                }
                minDate={
                  rowData.maxDifferenceDate
                    ? new Date(rowData.maxDifferenceDate)
                    : undefined
                }
                maxDate={
                  rowData.minDifferenceDate
                    ? new Date(rowData.minDifferenceDate)
                    : undefined
                }
                dateTemplate={dateTemplate}
                disabled={rowData.isEarningDistributed || MinMaxDateSame}
                onChange={(e) => handleInputChange(e, rowData)}
                placeholder="Select Date"
                className="card-inputBox allocatedTable"
                readOnlyInput
              />
              {error === true ? (
                <RiErrorWarningLine title="" className="iconError" />
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <label className="ml-2">
            {formatDateMMDDYYY(new Date(rowData.maxStartDate))}
          </label>
        )}
      </>
    );
  };

  const cashEquField = (rowData: any) => {
    let selectedError: any;
    let error: any = false;
    if (errorMessageState !== undefined) {
      selectedError = errorMessageState.find(
        (data: any) => data.stakeTrackingID === rowData.stakeTrackingID
      );
      error = selectedError.cashEquivalentError !== "";
    }

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center">
          <label className={` w-100 ${rowData.classApplytoEdit}`}>
            {currencyFormat(rowData.cashEquivalent)}
          </label>
          {error === true ? (
            <RiErrorWarningLine
              title="Amount allocated is higher than available cash balance"
              className="iconError"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const cashBalance = (rowData: any) => {
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center">
          <label className={` w-100 ${rowData.classApplytoEdit}`}>
            {currencyFormat(
              rowData.remainingCash,
              rowData.remainingCash <= 0 && rowData.remainingCash > -1 ? 4 : 2
            )}
          </label>
        </div>
      </div>
    );
  };

  const handleCalculatedEarning = (
    list: any = updateAllocationSliceState.investorList
  ) => {
    let totalUnits = 0;
    for (const element of list) {
      if (element.partnerType !== "GP") {
        totalUnits += Number(element.editUnitsToAllocate);
      }
    }

    return totalUnits;
  };

  const calculateUnitsTotal = (
    list: any = updateAllocationSliceState.investorList
  ) => {
    let totalUnits = 0;
    for (const element of list) {
      if (element.partnerType !== "GP") {
        totalUnits += Number(element.units);
      }
    }

    return totalUnits;
  };

  const AvailPlusAllocatedUnits = () => {
    const totalUnits = calculateUnitsTotal();

    const AvailPlusAllocated = Number(
      allocatedInvestor.investmentData?.availableUnits !== null &&
        allocatedInvestor.investmentData?.availableUnits !== undefined
        ? allocatedInvestor.investmentData?.availableUnits + totalUnits
        : 0
    );
    return AvailPlusAllocated;
  };

  const calculateunitsRemaining = (list?: any) => {
    let remainingTotal = 0;
    // const totalUnits = calculateUnitsTotal();
    const allocated = handleCalculatedEarning(list);
    const AvailPlusAllocated = AvailPlusAllocatedUnits();
    remainingTotal = Number(AvailPlusAllocated - Number(allocated));
    return remainingTotal;
  };
  const editUnits = (rowData: any) => {
    let selectedError: any;
    let error: any = false;
    if (errorMessageState !== undefined) {
      selectedError = errorMessageState.find(
        (data: any) => data.stakeTrackingID === rowData.stakeTrackingID
      );
      error = selectedError.unitsToAllocateError !== "";
    }

    const handleInputUnitChange = (e: any, data: any) => {
      const newValue = e.value;
      const cashCal = newValue * allocatedInvestor.investmentData?.unitPrice!;
      const cashBalanceRoundoff = Math.round(data.cashBalance * 100) / 100;
      const cashTotal = cashBalanceRoundoff + data.units * data.unitPrice;
      const remainingCashBalance =
        data.units === newValue ? cashBalanceRoundoff : cashTotal - cashCal;
      const lessCashBalance = newValue <= data.units;
      const list = [...updateAllocationSliceState.investorList];

      const newList = list.map((item: any) => {
        if (item.stakeTrackingID === data.stakeTrackingID) {
          return {
            ...item,
            // editAllocatedDate: convertLocalDateToUTC(data.editAllocatedDate),
            editUnitsToAllocate: newValue,
            cashEquivalent: cashCal,
            remainingCash: remainingCashBalance,
            classApplytoEdit:
              newValue !== data.units ? "allocateSliceCellHighLight" : "",
          };
        }
        return item;
      });
      // const totalallocatedUnits = handleCalculatedEarning(newList);

      const remainingTotal = calculateunitsRemaining(newList);
      dispatch(
        setUpdateAllocationSliceState({
          ...updateAllocationSliceState,
          investorList: newList,
          remainingInvestmentUnits: remainingTotal,
        })
      );

      // const cashCal = newValue * allocatedInvestor.investmentData?.unitPrice!;

      // to set the error message in state
      const modifyErrorList = (unitsError: any, cashError: any) =>
        errorMessageState.map((obj: any) => {
          const newData =
            obj?.stakeTrackingID === data.stakeTrackingID && newValue > 0
              ? {
                  ...obj,
                  unitsToAllocateError: unitsError,
                  cashEquivalentError: cashError,
                }
              : obj?.stakeTrackingID === data.stakeTrackingID &&
                newValue === null
              ? { ...obj, unitsToAllocateError: "", cashEquivalentError: "" }
              : obj;
          return newData;
        });

      if (
        remainingTotal < 0 &&
        !lessCashBalance &&
        remainingCashBalance < 0 &&
        cashCal !== 0
      ) {
        setErrorMessageState(
          modifyErrorList(
            "Total units requested are higher than available units. ",
            "Amount allocated is higher than available cash balance"
          )
        );
      } else if (
        !lessCashBalance &&
        remainingCashBalance < 0 &&
        cashCal !== 0
      ) {
        setErrorMessageState(
          modifyErrorList(
            "",
            "Amount allocated is higher than available cash balance"
          )
        );
      } else if (remainingTotal < 0) {
        setErrorMessageState(
          modifyErrorList(
            "Total units requested are higher than available units. ",
            ""
          )
        );
      } else {
        const resetErrors = errorMessageState.map((obj: any) => ({
          ...obj,
          unitsToAllocateError: "",
        }));
        const resetCashErrors = resetErrors.map((obj: any) =>
          obj?.stakeTrackingID === data.stakeTrackingID
            ? { ...obj, cashEquivalentError: "" }
            : obj
        );
        setErrorMessageState(resetCashErrors);
      }
    };
    return (
      <>
        {rowData.partnerType !== "GP" ? (
          <>
            <div className="flex flex-column">
              <div className="flex align-items-center justify-content-center">
                {" "}
                <InputNumber
                  name="txtunits"
                  id="txtunits"
                  value={rowData.editUnitsToAllocate}
                  min={0}
                  disabled={rowData.isEarningDistributed}
                  onChange={(e) => handleInputUnitChange(e, rowData)}
                  onValueChange={(e) => handleInputUnitChange(e, rowData)}
                  className="w-100"
                />
                {error === true ? (
                  <RiErrorWarningLine
                    title="Total units requested are higher than available units. "
                    className="iconError"
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <label className="ml-2">{rowData.units} </label>
          </>
        )}
      </>
    );
  };
  // const dateFilterTemplate = (options: any) => {
  //   return (
  //     <Calendar
  //       value={options.value}
  //       onChange={(e) => options.filterCallback(e.value, options.index)}
  //       dateFormat="mm/dd/yy"
  //       placeholder="mm/dd/yyyy"
  //       mask="99/99/9999"
  //     />
  //   );
  // };
  const dataTableElement = [
    {
      field: "rowNumber",
      header: "Lot#",
      style: { maxWidth: "6rem" },
    },
    {
      field: "lpPartnerName",
      header: "Investor Account",
    },
    // {
    //   field: "cashBalance",
    //   header: "Cash Balance",
    //   body: (rowData: any) => currencyFormat(rowData.cashBalance),
    // },
    {
      field: "editAllocatedDate",
      header: "Change Date",
      body: editDates,
      // dataType: "date",
      // filterElement: dateFilterTemplate,
    },
    {
      field: "endDate",
      header: "End Date",
      body: (rowData: any) =>
        rowData.endDate ? formatDateMMDDYYY(new Date(rowData.endDate)) : "",
      // dataType: "date",
      // filterElement: dateFilterTemplate,
    },
    {
      field: "remainingCash",
      header: "Cash Balance",
      // body: (rowData: any) => currencyFormat(rowData.remainingCash),
      body: cashBalance,
    },
    {
      field: "units",
      header: "Allocated Units",
      // body: editUnits,
      bodyClassName: "ml-2",
    },
    {
      field: "units",
      header: "Units to Allocate",
      body: editUnits,
      bodyClassName: "p-1",
    },
    {
      field: "cashEquivalent",
      header: "Cash Equivalent",
      body: cashEquField,
      bodyClassName: "h-100",
    },
  ];

  useEffect(() => {
    const investorList: any = [...updateAllocationSliceState?.investorList];
    const list: any = [];
    for (const item of investorList) {
      const investorObj = {
        stakeTrackingID: item.stakeTrackingID,
        unitsToAllocateError: "",
        cashEquivalentError: "",
        allocationDateError: "",
      };
      list.push(investorObj);
    }
    setErrorMessageState(list);
  }, [updateAllocationSliceState?.investorListInitialState]);
  useEffect(() => {
    if (errorMessageState !== undefined) {
      sethasUnitError(
        errorMessageState.some(
          (error: any) => error.unitsToAllocateError !== ""
        )
      );
      sethascashError(
        errorMessageState.some((error: any) => error.cashEquivalentError !== "")
      );

      setHasDateError(
        errorMessageState.some((error: any) => error.allocationDateError !== "")
      );
    }
  }, [errorMessageState]);

  useEffect(() => {
    setAllocatedInvestorListData(
      getListDateConversion(updateAllocationSliceState.investorList, [
        "editAllocatedDate",
      ])
    );
  }, [updateAllocationSliceState.investorList]);

  console.log(
    "yes",
    updateAllocationSliceState.investorList,
    allocatedInvestor.investmentData
  );
  console.log("yes------------", allocatedInvestorListData);
  return (
    <>
      <div>
        <div className="mt-4">
          <div>
            <div className="mt-4">
              <div className="formgrid grid px-2 ">
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
                  <label className="inputLabel">Investment Start Date</label>
                  <h3>
                    {allocatedInvestor.investmentData?.investmentStartDate
                      ? formatDateMMDDYYY(
                          new Date(
                            allocatedInvestor.investmentData?.investmentStartDate!
                          )
                        )
                      : ""}
                  </h3>
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
                  <label className="inputLabel">
                    Most Recent Allocation Date:
                  </label>
                  <h3>
                    {allocatedInvestor.investmentData?.lastAllocationDate
                      ? formatDateMMDDYYY(
                          new Date(
                            allocatedInvestor.investmentData?.lastAllocationDate!
                          )
                        )
                      : ""}
                  </h3>
                </div>
              </div>
              <div className="formgrid grid px-2 ">
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
                  <label className="inputLabel">Available Units</label>
                  <h3>
                    {/* {allocatedInvestor.investmentData?.totalInvestmentUnits} */}
                    {/* {calculateUnitsTotal()} */}
                    {AvailPlusAllocatedUnits()}
                  </h3>
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
                  <label className="inputLabel">Units allocated</label>
                  <h3>{handleCalculatedEarning()}</h3>
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
                  <label className="inputLabel">Units remaining:</label>
                  <h3>
                    {/* {allocatedInvestor.investmentData?.availableUnits} */}
                    {calculateunitsRemaining()}
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="d-block sm:d-flex sm:flex-column pt-0 p-2 col-12">
            <DataTableComponent
              // valueData={updateAllocationSliceState.investorList || []}
              valueData={allocatedInvestorListData || []}
              fieldsElements={dataTableElement}
              isPaginationAllowed={true}
              noGridLines={true}
              className="allocateSliceTable"
            />

            <div className="formgrid grid px-2">
              <div className="iconError mb-3  p-2">
                <ul>
                  {hasUnitError ? (
                    <li>
                      Total units requested are higher than available units.
                    </li>
                  ) : (
                    ""
                  )}
                  {hasCashError ? (
                    <li>
                      Amount allocated is higher than available cash balance{" "}
                    </li>
                  ) : (
                    ""
                  )}
                  {hasDateError ? <li>Date Error</li> : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
