/* eslint-disable unicorn/no-null */
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import React, { memo, useEffect, useRef, useState } from "react";
import { RiFilterLine } from "react-icons/ri";
import { getInvestingAccountsLookupsThunk } from "../../store/Investor/investor-approval/investing-accounts-lookups/slice";
import { getThunkLookupCode } from "../../store/features/dropdown/lookup-code/slice";
import { getThunkSupplier } from "../../store/features/dropdown/supplier/slice";
import {
  getThunkInvestmentByFilter,
  resetInvestmentStateFilter,
} from "../../store/features/investment/investment-filter/slice";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import currencyFormat from "../../utils/currency-formatter";

interface IProps {
  listPage: boolean;
  filterReset?: boolean;
  setFilterReset?: any;
  pageName?: any;
  setProductObjectByFilter?: any;
  productObjectByFilter?: any;
  productState?: any;
  productId?: any;
  setproductId?: any;
  pooledFund?: any;
  showAllocationDropDown?: any;
}
function InvestmentDropdownFilter(props: IProps) {
  const op = useRef<any>(null);
  const [filter, setFilter] = useState(false);
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();

  const supplier = useAppSelector((state) => state.supplierList.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const investmentFilerId = useAppSelector(
    (state) => state.investmentFilterById.data
  );
  const pageUpdateName = useAppSelector((state) => state.updatePage.data);
  const [productInfo, setProductInfo] = useState<any>();
  const lookupDropDownState = useAppSelector(
    (state) => state.lookupDropDown.data
  );
  const listDealInvestment = useAppSelector(
    (state) => state.investmentByFilter.data
  );
  const countryDropdown = useAppSelector(
    (state) => state.investingAccountsLookups.countriesDropdown
  );

  console.log("listDealInvestment", listDealInvestment);

  const [supplierId, setsupplierId] = useState<any>();
  const [city, setcity] = useState<any>();
  const [stateCode, setstateCode] = useState<any>();
  const [lookUpCode, setlookUpCode] = useState<any>();
  const [infomsg, setinfomsg] = useState<any>("");
  const [countryId, setCountryId] = useState<any>(0);
  const supplierDropdownList: any = [];
  const countryValue: any = countryDropdown
    ? countryDropdown?.find((item) => item.value === "United States")
    : 0;
  supplier?.map((item: any) => {
    //
    return supplierDropdownList.push({
      name: item.supplierName,
      code: item.supplierID,
    });
  });

  const formik = useFormik({
    initialValues: {
      productName: 0,
      supplierId: 0,
      supplierProductCode: "",
      city: "",
      stateCode: "",
      countryId: 0,
    },
    onSubmit: (data) => {},
  });
  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        supplierId:
          formik.values.supplierId === 0 ? 0 : formik.values.supplierId,
        productState: props.productState,
      };

      dispatch(getThunkLookupCode(parameter));
      dispatch(getInvestingAccountsLookupsThunk());
    }
  }, [fundDropdown.fundid, formik.values.supplierId]);

  useEffect(() => {
    if (
      fundDropdown.fundid !== undefined &&
      fundDropdown.fundid > 0 &&
      props.productObjectByFilter?.productID !== undefined
    ) {
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        ProductState: props.productState,
        pooledFund: props.pooledFund ? props.pooledFund : "all",
      };

      console.log(
        "getThunkInvestmentByFilter",
        parameter,
        fundDropdown.fundid,
        pageUpdateName.offering,
        props.productObjectByFilter?.productID
      );
      dispatch(getThunkInvestmentByFilter(parameter)).finally(() => {});
    }
  }, [
    fundDropdown.fundid,
    pageUpdateName.offering,
    props.productObjectByFilter?.productID,
  ]);

  const handleApiCall = async () => {
    console.log("formik", formik.values);
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameters = {
        legalEntityId: fundDropdown.fundid,
        supplierId: formik.values.supplierId,
        lookUpCode: formik.values.supplierProductCode,
        city: formik.values.city,
        stateCode: formik.values.stateCode,
        productName: "",
        productState: "",
        productId: investmentFilerId.productId,
        countryId: formik.values.countryId || null,
        pooledFund: props.pooledFund !== undefined ? props.pooledFund : "all",
      };

      await dispatch(getThunkInvestmentByFilter(parameters)).then(
        (response) => {
          if (response.meta.requestStatus === "rejected") {
            //
          } else if (response.meta.requestStatus === "fulfilled") {
            if (response.payload.data !== null) {
              setProductInfo(response.payload.data.investmentsAndDeals);
            } else {
              toast.current?.show({
                severity: "info",
                summary: "Info Message",
                detail: "No data found",
                life: 3000,
              });
              dispatch(resetInvestmentStateFilter());
              setProductInfo([]);
            }
          }
        }
      );
    }
  };
  const DropdownList: any = [];

  listDealInvestment?.map((item: any) => {
    //
    return DropdownList.push({
      key:
        props.showAllocationDropDown === true
          ? `${item.productName} - [RemainingAllocation - ${currencyFormat(
              item.remainingAllocation * item.unitPriceCalculated
            )}]`
          : item.productName,
      code: item.productID,
    });
  });
  useEffect(() => {
    if (props.filterReset === true) {
      console.log("reset inside");
      setFilter(false);
      formik.resetForm();
    }
  }, [props.filterReset === true]);
  console.log("d", props.filterReset);
  const handleChange = (e?: any) => {
    formik.setFieldValue("productName", "");
  };
  const productNameChange = (e: any) => {
    if (props.setFilterReset) {
      props.setFilterReset(false);
    }
    formik.setFieldValue("productName", e.value ? e.value : e.value);
  };
  const serachinvestment = () => {
    const parameter = {
      legalEntityId: fundDropdown.fundid,
      ProductState: props.productState,
      SupplierId: supplierId,
      City: city,
      StateCode: stateCode,
      LookUpCode: lookUpCode,
      countryId: countryId || null,
      pooledFund: props.pooledFund ? props.pooledFund : "all",
    };

    dispatch(getThunkInvestmentByFilter(parameter))
      .then((response) => {
        if (response.payload.data !== null) {
          if (response.payload.data.investmentsAndDeals?.length === 1) {
            setinfomsg("");
            props.setProductObjectByFilter({
              ...props.productObjectByFilter,
              productID:
                response.payload.data?.investmentsAndDeals[0]?.productID,
            });
          } else {
            const info =
              response.payload.data.investmentsAndDeals?.length +
              " records match filter criteria, select one from below";
            setinfomsg(info);
          }
        } else {
          toast.current?.show({
            severity: "info",
            summary: "Info Message",
            detail: "No data found",
            life: 3000,
          });
          dispatch(resetInvestmentStateFilter());
          setinfomsg("");
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameters = {
        legalEntityId: fundDropdown.fundid,
        productState: props.productState,
      };

      dispatch(getThunkSupplier(parameters));
    }
    formik.resetForm();
  }, [fundDropdown, pageUpdateName.offering]);

  // to select the product based on the supplier code and investmentId
  useEffect(() => {
    console.log("productInfo", productInfo);
    if (productInfo !== undefined) {
      if (
        formik.values.supplierProductCode !== "" &&
        productInfo.length === 1
      ) {
        formik.setFieldValue("productName", productInfo[0].productName);
        op.current.hide();
        setFilter(false);
        if (props.setFilterReset) {
          props.setFilterReset(false);
        }
      } else if (
        investmentFilerId.productId !== 0 &&
        productInfo.length === 1
      ) {
        formik.setFieldValue("productName", productInfo[0].productName);
        op.current.hide();
        setFilter(false);
        if (props.setFilterReset) {
          props.setFilterReset(false);
        }
      }
    }
  }, [productInfo]);
  useEffect(() => {
    console.log("investmentId useeffect", investmentFilerId);
    formik.values.supplierId = 0;
    formik.values.supplierProductCode = "";
    formik.values.city = "";
    formik.values.stateCode = "";
    formik.values.productName = 0;
    formik.resetForm();
    console.log("dd", investmentFilerId.productId);
    if (
      investmentFilerId.productId !== undefined &&
      investmentFilerId.productId !== 0
    ) {
      formik.resetForm();

      handleApiCall();
    }
  }, [investmentFilerId]);

  const onHide = () => {
    // formik.resetForm();
    setFilter(false);
  };

  const resetFunction = () => {
    //
    const parameter = {
      legalEntityId: fundDropdown.fundid,
      ProductState: props.productState,
      pooledFund: props.pooledFund ? props.pooledFund : "all",
    };

    console.log(
      "getThunkInvestmentByFilter",
      parameter,
      fundDropdown.fundid,
      pageUpdateName.offering,
      props.productObjectByFilter?.productID
    );
    dispatch(getThunkInvestmentByFilter(parameter)).finally(() => {});
  };
  useEffect(() => {
    setProductInfo([]);
  }, []);
  return (
    <>
      <Toast ref={toast} className="themeToast" />
      {infomsg !== undefined && infomsg !== "" ? (
        <Tag value={infomsg} className="StatusSuccssClsdrop"></Tag>
      ) : (
        <></>
      )}

      <div className="formgrid flex p-0 xl:col-12 md:col-10 sm:col-10">
        <div className="d-flex flex-column p-0 col-12">
          {props.listPage !== true ? (
            <>
              <label className="inputLabel">Investment Name</label>
            </>
          ) : (
            <></>
          )}

          <div className="d-flex flex-row flex-wrap align-items-start">
            <div className="p-0 col-lg-11 col-md-9 col-sm-12">
              <div className="p-0 col-12">
                <Dropdown
                  inputId="productID"
                  name="productName"
                  value={props?.productObjectByFilter?.productID}
                  options={DropdownList === null ? [] : DropdownList}
                  filter
                  optionLabel="key"
                  optionValue="code"
                  style={{ width: "100%" }}
                  aria-label="key"
                  resetFilterOnHide={true}
                  showFilterClear={true}
                  placeholder="Select Investment Name"
                  onChange={(e: any) => {
                    console.log(
                      " e?.originalEvent?.target?.ariaLabel",
                      e?.originalEvent?.target?.ariaLabel
                    );
                    props.setProductObjectByFilter({
                      ...props.productObjectByFilter,
                      productID: e.value,
                      productName: e?.originalEvent?.target?.ariaLabel,
                    });
                    productNameChange(e);
                    console.log("evenet", e);
                  }}
                  className="card-inputBox"
                />
              </div>
            </div>
            <div className="pl-3 mt-4 md:mt-0 col-lg-1 col-md-3 col-sm-12 p-0 d-flex justify-content-center">
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setFilter(!filter);
                  op.current.toggle(e);
                }}
                className={filter ? "btn-navActive p-2" : "btn-nav p-2"}
              >
                <RiFilterLine className="filterIcon" />
              </Button>
            </div>
          </div>
        </div>

        <OverlayPanel ref={op} showCloseIcon onHide={onHide} className="p-2">
          <div className="flex-wrap p-0 sm:flex-none col-12">
            <div className="d-flex flex-column pl-0 pt-2 p-1">
              <label className="inputLabel" style={{ fontSize: "16px" }}>
                Supplier
              </label>
              <Dropdown
                inputId="supplierId"
                name="supplierId"
                value={supplierId}
                options={
                  supplierDropdownList === null ? [] : supplierDropdownList
                }
                filter
                resetFilterOnHide={true}
                showFilterClear={true}
                optionLabel="name"
                optionValue="code"
                aria-label="name"
                placeholder="Select supplier"
                className="card-inputBox"
                onChange={(e: any) => {
                  handleChange(e);
                  formik.setFieldValue("supplierId", e.value);
                  setsupplierId(e.value);
                }}
              />
            </div>
            <div className="d-flex flex-column pt-2 p-1 ">
              <label className="inputLabel" style={{ fontSize: "16px" }}>
                Lookup code
              </label>
              <Dropdown
                inputId="supplierProductCode"
                name="supplierProductCode"
                value={lookUpCode}
                options={
                  lookupDropDownState === null
                    ? []
                    : lookupDropDownState?.lookupDetails
                }
                filter
                resetFilterOnHide={true}
                showFilterClear={true}
                optionLabel="value"
                optionValue="value"
                aria-label="value"
                placeholder="Select Lookup code"
                className="card-inputBox"
                onChange={(e) => {
                  handleChange(e);
                  formik.setFieldValue("supplierProductCode", e.value);
                  setlookUpCode(e.value);
                }}
              />
            </div>
            <div className="d-flex flex-column pt-2 p-1 ">
              <label className="inputLabel" style={{ fontSize: "16px" }}>
                City
              </label>
              <Dropdown
                inputId="city"
                name="city"
                value={city}
                options={
                  lookupDropDownState === null ? [] : lookupDropDownState?.city
                }
                filter
                optionLabel="value"
                optionValue="value"
                placeholder="Select city"
                className="card-inputBox"
                resetFilterOnHide={true}
                showFilterClear={true}
                disabled={supplierDropdownList.length === 0}
                onChange={(e) => {
                  handleChange(e);
                  formik.setFieldValue("city", e.value);
                  setcity(e.value);

                  //
                }}
              />
            </div>
            <div className="d-flex flex-column pt-2 p-1 ">
              <label className="inputLabel" style={{ fontSize: "16px" }}>
                Country
              </label>
              <Dropdown
                inputId="countryId"
                name="countryId"
                value={countryId}
                options={countryDropdown === null ? [] : countryDropdown}
                filter
                resetFilterOnHide={true}
                showFilterClear={true}
                optionLabel="value"
                optionValue="key"
                aria-label="value"
                placeholder="Select Country"
                className="card-inputBox"
                disabled={supplierDropdownList.length === 0}
                onChange={(e: any) => {
                  handleChange(e);
                  formik.setFieldValue("countryId", e.value);
                  setCountryId(e.value);
                  setstateCode(null);
                }}
              />
            </div>
            <div className="d-flex flex-column pt-2 p-1 ">
              <label className="inputLabel" style={{ fontSize: "16px" }}>
                State
              </label>
              {countryId === countryValue.key ||
              countryId === 0 ||
              countryId === null ? (
                <Dropdown
                  inputId="stateCode"
                  name="stateCode"
                  value={stateCode}
                  options={
                    lookupDropDownState === null
                      ? []
                      : lookupDropDownState?.stateDetails
                  }
                  filter
                  resetFilterOnHide={true}
                  showFilterClear={true}
                  optionLabel="value"
                  optionValue="key"
                  aria-label="value"
                  placeholder="Select State"
                  className="card-inputBox"
                  disabled={supplierDropdownList.length === 0}
                  onChange={(e: any) => {
                    handleChange(e);
                    formik.setFieldValue("stateCode", e.value);
                    setstateCode(e.value);

                    //
                  }}
                />
              ) : (
                <InputText
                  name="stateCode"
                  value={stateCode}
                  onChange={(e) => {
                    handleChange(e);
                    setstateCode(e.target.value);
                  }}
                  className="card-inputBox"
                  placeholder="State"
                  maxLength={100}
                />
              )}
            </div>
            <div className="d-flex flex-row pt-2 p-1">
              <div className="d-flex flex-column p-2">
                <Button
                  className="btn-navActive"
                  onClick={(e) => {
                    e.preventDefault();
                    setsupplierId(null);
                    setcity(null);
                    setlookUpCode(null);
                    setstateCode(null);
                    setCountryId(null);
                    props.setProductObjectByFilter({
                      productID: 0,
                      lookupCodeObj: "",
                      address: "",
                      zipCode: "",
                      fundingDate: "",
                    });
                    formik.resetForm();
                    resetFunction();
                    setinfomsg("");
                    op.current.toggle(false);
                  }}
                >
                  <span className="ml-auto mr-auto ">Clear Filters</span>
                </Button>
              </div>
              <div className="d-flex flex-column p-2">
                <Button
                  className="btn-navActive"
                  onClick={(e) => {
                    e.preventDefault();
                    serachinvestment();
                    setFilter(false);
                    props.setProductObjectByFilter({
                      productID: 0,
                      lookupCodeObj: "",
                      address: "",
                      zipCode: "",
                      fundingDate: "",
                    });
                    op.current.toggle(false);
                  }}
                >
                  <span className="ml-auto mr-auto ">Apply Filter</span>
                </Button>
              </div>
            </div>
          </div>
        </OverlayPanel>
      </div>
    </>
  );
}

export default memo(InvestmentDropdownFilter);
