// import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Skeleton } from "primereact/skeleton";
import React from "react";

const InvestmentAllocationReportSkeleton = () => {
  return (
    <>
      <div className="grid">
        <div className="col-6 mt-4">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Investment Allocation Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-6">
          <div></div>
        </div>
      </div>

      <div className="grid">
        <div className="col-12">
          <div className="card my-3 ">
            <Card>
              <h4 className="cardHeader">Investment Allocation Report</h4>
              <Skeleton className="col-12 md:col-6" height="5rem"></Skeleton>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestmentAllocationReportSkeleton;
