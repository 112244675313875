import { Button } from "primereact/button";
import { Card } from "primereact/card";
// import { Column } from "primereact/column";

import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { EnableOrDisableUserInADThunk } from "../../../store/features/admin/enableordisableuserInad/slice";
import { GetAdminMenuUsersDetailsThunk } from "../../../store/features/admin/getadminmenuusersdetails/slice";
import { SendDynamicMailThunk } from "../../../store/features/admin/senddynamicmail/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";
export default function ManageFundAdminAccountsAdmin() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const getRevision = useAppSelector((state) => state.GetRevisionList.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  console.log("getRevision", getRevision);
  const GetMenuList = useAppSelector(
    (state) => state.GetAdminMenuUsersDetails?.adminAccountData
  );

  const [loader, setLoader] = useState(false);
  const [inviteEmail, setInviteEmail] = useState<any>({});
  const [inviteVisible, setInviteVisible] = useState(false);
  const [resetCredData, setResetCredData] = useState<any>({});
  const [enableDisabled, setEnableDisabled] = useState<any>({});
  const [credentialsVisible, setCredentialsVisible] = useState(false);
  const [enableDisabledVisible, setEnableDisabledVisible] = useState(false);

  const resetCredentials = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = (id: any) => {
      console.log("resetCredentials", rowData);
      setCredentialsVisible(true);
      setResetCredData(rowData);
      setInviteEmail(rowData);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={() => handleEdit(rowData.Id)}
            disabled={!rowData.isAccoutEnable}
          >
            <span className="text-white">Reset Credentials</span>
          </Button>
        </div>
      </div>
    );
  };

  const onResetCredentials = () => {
    const req = {
      emailId: inviteEmail.email,
      legalEntityId: fundDropdown.fundid,
      roleId: 6,
      type: "Reset_Credentials",
      userName: `${inviteEmail.firstName} ${inviteEmail.lastName}`,
    };
    setLoader(true);
    dispatch(SendDynamicMailThunk(req))
      .then(() => {
        setCredentialsVisible(false);
        setLoader(false);
        successToastMessage(toast, "Email is send to your email id");
      })
      .catch(() => {
        setLoader(false);
        errorToastMessage(toast);
      });
  };

  const enableDisable = (rowData: any) => {
    // Pass rowData as a parameter
    console.log("product", rowData);
    const handleEdit = (id: any) => {
      setEnableDisabledVisible(true);
      setEnableDisabled(rowData);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={() => handleEdit(rowData.Id)}
            disabled={
              !rowData.isAccountExistInAD && rowData.azureObjectId === null
            }
          >
            {rowData.isAccountExistInAD && rowData.isAccoutEnable ? (
              <span className="text-white">disable</span>
            ) : (
              <span className="text-white">Enable</span>
            )}
          </Button>
        </div>
      </div>
    );
  };

  const onEnableDisabled = () => {
    // Pass rowData as a parameter
    const reqParam = {
      emaild: enableDisabled.email,
      status: !enableDisabled.isAccoutEnable,
      usertypeid: 5,
    };
    dispatch(EnableOrDisableUserInADThunk(reqParam)).then(() => {
      setLoader(true);
      setEnableDisabledVisible(false);
      const req = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetAdminMenuUsersDetailsThunk(req))
        .then(() => {
          setLoader(false);
          if (
            enableDisabled.isAccountExistInAD &&
            enableDisabled.isAccoutEnable
          ) {
            successToastMessage(toast, "Account successfully disabled");
          } else {
            successToastMessage(toast, "Account successfully enabled");
          }
        })
        .catch(() => {
          setLoader(false);
          errorToastMessage(toast);
        });
    });
  };
  const invite = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = (id: any) => {
      console.log("product----", inviteEmail);
      setInviteVisible(true);
      setInviteEmail(rowData);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={() => handleEdit(rowData.Id)}
            style={{ whiteSpace: "nowrap" }}
            disabled={rowData.isAccountExistInAD}
          >
            <span className="text-white">Invite User</span>
          </Button>
        </div>
      </div>
    );
  };

  const onInviteSend = () => {
    const req = {
      emailId: inviteEmail.email,
      legalEntityId: fundDropdown.fundid,
      roleId: 6,
      type: "Invite_User",
      userName: `${inviteEmail.firstName} ${inviteEmail.lastName}`,
    };
    setLoader(true);

    dispatch(SendDynamicMailThunk(req))
      .then(() => {
        setInviteVisible(false);
        setLoader(false);
      })
      .catch(() => setLoader(false));
  };

  const dataTableElements = [
    {
      field: "email",
      header: "Email",
    },
    {
      field: "firstName",
      header: "First Name",
    },
    {
      field: "lastName",
      header: "Last Name",
    },
    {
      // field: "ein",
      header: "",
      body: (rowData: any) => resetCredentials(rowData), // Pass rowData to editButton
    },
    {
      // field: "taxPayerId",
      header: "",
      body: (rowData: any) => enableDisable(rowData), // Pass rowData to editButton
    },
    {
      // field: "Business Name",
      header: "",
      body: (rowData: any) => invite(rowData), // Pass rowData to editButton
    },
  ];

  useEffect(() => {
    setLoader(true);
    if (fundDropdown.fundid) {
      const req = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetAdminMenuUsersDetailsThunk(req))
        .then(() => {
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [fundDropdown.fundid]);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loader && <Loader />}
        <Dialog
          visible={inviteVisible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setInviteVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            {`This will send an email to ${inviteEmail.email} inviting them to create an account on the Avestor Manager Portal. Are you sure?`}
            {/* {` Send Email to Invite User ${inviteEmail.email}. Are you sure?`}{" "} */}
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-between">
            <Button
              onClick={onInviteSend}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setInviteVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>
        <Dialog
          visible={credentialsVisible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setCredentialsVisible(false)}
        >
          {resetCredData.isMultiAccountExist ? (
            <>
              {" "}
              <h4 className="pageHeader text-center mb-5 pt-5">
                This action will reset your credentials for GP and Admin
                Accounts. Are you sure?
              </h4>
            </>
          ) : (
            <>
              {" "}
              <h4 className="pageHeader text-center mb-5 pt-5">
                This action will reset your credentials, Are you sure?{" "}
              </h4>
            </>
          )}

          <div className=" align-self-center flex align-items-center justify-content-between">
            <Button
              onClick={onResetCredentials}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setCredentialsVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>

        <Dialog
          visible={enableDisabledVisible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setEnableDisabledVisible(false)}
        >
          {enableDisabled.isAccoutEnable ? (
            <>
              {" "}
              <h4 className="pageHeader text-center mb-5 pt-5">
                This will disable user&apos;s access in Investor Portal for all
                the funds, Are you sure?
              </h4>
            </>
          ) : (
            <>
              {" "}
              <h4 className="pageHeader text-center mb-5 pt-5">
                This will enable user&apos;s access in Investor Portal for all
                the funds, Are you sure?{" "}
              </h4>
            </>
          )}

          <div className=" align-self-center flex align-items-center justify-content-between">
            <Button
              onClick={onEnableDisabled}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setEnableDisabledVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Fund admin login</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card my-3 tabWithoutboxshadow">
            <Card>
              <> </>{" "}
              <div className="mt-2">
                <DataTableComponent
                  valueData={GetMenuList!}
                  fieldsElements={dataTableElements}
                  isPaginationAllowed={true}
                  isDownload={true}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
