import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useEffect, useState } from "react";

// import { setSetupTabNameState } from "../../../../store/features/operation/setup-fund/setup-fund-tabname/slice";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";
interface IProps {
  pageName: any;
  formik: any;
  getFormErrorMessage: any;
  isFormFieldInvalid: any;
}

export default function SetupFundGPLLC(props: IProps) {
  const { pageName, formik, getFormErrorMessage, isFormFieldInvalid } = props;
  // const dispatch = useAppDispatch();
  const stateDropDownList = useAppSelector((state) => state.dropdownList.data);
  // const tabNameField = useAppSelector((state) => state.setupFundTabName.data);
  const fundType = useAppSelector((state) => state.getFundTypeDropdown.data);
  const [disableField, setDisableField] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    // dispatch(setSetupTabNameState({ ...tabNameField, changeState: true }));
    if (name === "gpZipCode") {
      const digitsOnly = value.replace(/\D/g, "");
      // Add hyphen after every 5 digits if length is greater than 5
      let formattedValue = digitsOnly;
      if (formattedValue.length > 5) {
        formattedValue = formattedValue.replace(/(\d{5})/g, "$1-");
      }
      //
      formik.setFieldValue(name, formattedValue);
    } else if (name === "gpEin") {
      const digitsOnly = value.replace(/\D/g, "");
      let formattedValue = digitsOnly;
      if (formattedValue.length >= 2) {
        formattedValue =
          formattedValue.slice(0, 2) + "-" + formattedValue.slice(2);
      }
      formik.setFieldValue(name, formattedValue);
    } else {
      formik.setFieldValue(name, value);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Backspace" && formik.values.ein.includes("-")) {
      e.preventDefault();

      // Remove the hyphen when backspacing
      const newEin = formik.values.ein.slice(0, -1);
      formik.setFieldValue("ein", newEin);
    }
  };

  useEffect(() => {
    if (pageName === "Reports") {
      setDisableField(true);
    } else {
      setDisableField(false);
    }
  }, []);

  return (
    <>
      <div>
        <div className=" h-100 center-card">
          <div className="col-12 p-0">
            <div className="card">
              <div className="headercard d-flex grid"></div>
              <form>
                <div>
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">GP LLC Name *</label>
                      <InputText
                        name="gpllcName"
                        value={formik.values.gpllcName}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("gpllcName"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter GP LLC name"
                      />
                      {getFormErrorMessage("gpllcName")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">Entity Type *</label>
                      <Dropdown
                        inputId="gpEntityType"
                        name="gpEntityType"
                        value={formik.values.gpEntityType}
                        options={fundType === null ? [] : fundType?.entitytype}
                        optionLabel="value"
                        optionValue="key"
                        disabled
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("gpEntityType"),
                          "card-inputBox": true,
                        })}
                      />

                      {getFormErrorMessage("gpEntityType")}
                    </div>
                  </div>

                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">
                        Registered in State *
                      </label>
                      <Dropdown
                        inputId="gpRegisteredState"
                        name="gpRegisteredState"
                        value={formik.values.gpRegisteredState}
                        options={
                          stateDropDownList === null
                            ? []
                            : stateDropDownList.stateDetails
                        }
                        filter
                        disabled={disableField === true}
                        optionLabel="value"
                        optionValue="key"
                        placeholder="Select State"
                        // className="card-inputBox"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("gpRegisteredState"),
                          "card-inputBox": true,
                        })}
                      />

                      {getFormErrorMessage("gpRegisteredState")}
                    </div>

                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">EIN</label>
                      <div className="p-inputgroup flex-1">
                        <InputText
                          name="gpEin"
                          value={formik.values.gpEin}
                          onChange={(e) => handleChange(e)}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("gpEin"),
                            "card-inputBox": true,
                          })}
                          onKeyDown={(e) => handleKeyDown(e)}
                          maxLength={10}
                          disabled={disableField === true}
                          placeholder="XX-XXXXXXX"
                        />
                      </div>
                      {getFormErrorMessage("gpEin")}
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">Address</label>
                      <InputText
                        name="gpAddress"
                        value={formik.values.gpAddress}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("gpAddress"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter address"
                      />
                      {getFormErrorMessage("gpAddress")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">City *</label>
                      <InputText
                        name="gpCity"
                        value={formik.values.gpCity}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("gpCity"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="City"
                      />
                      {getFormErrorMessage("gpCity")}
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">State *</label>
                      <Dropdown
                        inputId="gpState"
                        name="gpState"
                        value={formik.values.gpState}
                        options={
                          stateDropDownList === null
                            ? []
                            : stateDropDownList.stateDetails
                        }
                        filter
                        disabled={disableField === true}
                        optionLabel="value"
                        optionValue="key"
                        placeholder="Select State"
                        // className="card-inputBox"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("gpState"),
                          "card-inputBox": true,
                        })}
                      />

                      {getFormErrorMessage("gpState")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">ZIP Code *</label>
                      <InputText
                        name="gpZipCode"
                        value={formik.values.gpZipCode}
                        onChange={handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("gpZipCode"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        maxLength={10}
                        placeholder="Enter zip code"
                      />
                      {formik.errors.gpZipCode && formik.touched.gpZipCode && (
                        <div className="error">
                          {getFormErrorMessage("gpZipCode")}{" "}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">Fund GP Email *</label>
                      <InputText
                        name="fundGPEmail"
                        value={formik.values.fundGPEmail}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("fundGPEmail"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        // onBlur={() => checkPasswordExist("GP")}
                        placeholder="Enter fund GP email"
                      />
                      {getFormErrorMessage("fundGPEmail")}
                    </div>

                    {/* <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">Fund Admin Email *</label>
                      <InputText
                        name="fundAdminEmail"
                        value={formik.values.fundAdminEmail}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("fundAdminEmail"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        // onBlur={() => checkPasswordExist("Admin")}
                        placeholder="Enter fund admin email"
                      />
                      {getFormErrorMessage("fundAdminEmail")}
                    </div> */}
                  </div>
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-12 flex justify-content-center ">
                      <div className="inputLabelNotification">
                        {/* <div> - </div> */}
                        {/* <div className="ml-2 mb-1"> */}
                        Note: Providing an email here does not create a fund
                        manager login. To create a login for fund manager and
                        other fund users please go to{" "}
                        <NavLink
                          to="/RoleManagement/ManageUser"
                          className="card-subheader link-supplier "
                        >
                          Manage users
                        </NavLink>
                        , add the users and then go to{" "}
                        <NavLink
                          to="/ManageFundUserAccountsAdmin"
                          className="card-subheader link-supplier "
                        >
                          Manage logins
                        </NavLink>{" "}
                        to invite the fund user to create a login and password.{" "}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
