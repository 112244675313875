import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getgetAnnualizedReturnsCompletedService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";
import { IAnnualizedReturnsCompletedState } from ".";

const initialState: IAnnualizedReturnsCompletedState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  totalCount: 0,
};
export const getAnnualizedReturnsCompletedCommits = createAsyncThunk(
  "annualizedReturnsCompleted",
  async (input: any) => {
    try {
      const response = await getgetAnnualizedReturnsCompletedService(input);
      console.log("Anzures", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "annualizedReturnsCompleted",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getAnnualizedReturnsCompletedCommits.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getAnnualizedReturnsCompletedCommits.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data
          ? action.payload._response.data[0]
          : [];
      }
    );
    builder.addCase(
      getAnnualizedReturnsCompletedCommits.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
