import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageNotFound from "../../images/ImageNotFoundNew.png";
import currencyFormat from "../../utils/currency-formatter";
import { convertUTCToLocalDate } from "../../utils/date-formatter";
import OpportunitySoftCommitPop from "./OpportunitySoftCommitPop";
// import OpportunitySoftCommitPop1 from "./OpportunitySoftCommitPop1";
interface IProps {
  p: {
    productID: number;
    productName: string;
    category: string;
    city: string;
    state: string;
    country: string;
    estExitDate: string;
    remainingAllocation: number;
    targetReturn: number;
    productPrimaryImage: string;
    productState: string;
    showRemainingAllocation: boolean;
    targetCloseDate: string;
  };
  selectedValue: any;
}
const OpportunityCard: React.FC<IProps> = (props) => {
  const ID: string = props.p.productID.toString();

  console.log("props--", props.selectedValue === "Allocated");
  const [visible, setVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const onCloseModal = () => {
    setVisible(false);
  };

  const countryName =
    props.p.country === "United States" ? "" : props.p.country;
  // propTypeEquity
  const categoryTypeClass =
    props.p.category === "Equity" ? "propTypeEquity" : "propTypeDebt";
  const img = props.p.productPrimaryImage
    ? props.p.productPrimaryImage
    : ImageNotFound;

  const remainingAllocation = currencyFormat(props.p.remainingAllocation);

  return (
    <>
      <div className="col-12 col-sm-6 col-md-6 col-xl-4">
        <div className="card ProdCard">
          <Card>
            <div className="prodimg-wrap">
              <Image src={img} alt="Image" imageClassName="ProdImg" />
              {countryName && (
                <span className="imageIconOverlay" title="International">
                  <i className="bi bi-globe2"></i>
                </span>
              )}
            </div>
            <div className="propDetail-wrap">
              <div className="grid propDetailTitle-wrap">
                <div className="col-12 md:col-7">
                  <p className="propName">{props.p.productName}</p>
                  <p
                    className="propAddr propCityCountry"
                    title={`${props.p.city}, ${props.p.state} ${
                      countryName && `, ${countryName}`
                    }`}
                  >
                    {props.p.city}, {props.p.state}
                    {countryName && `, ${countryName}`}
                  </p>
                </div>
                <div className="col-12 md:col-5">
                  {props.p.category && (
                    <div className="">
                      <p className={categoryTypeClass}>{props.p.category}</p>
                    </div>
                  )}
                  {/* <p className={categoryTypeClass}>{props.p.category}</p> */}
                </div>
              </div>
              {/* <p className="propName">{props.p.productName.slice(0, 20)}</p> */}
              <div className="propDetails">
                <p className="detailsHeader">Target Return:</p>
                {/* {props.p.targetReturn.toString().includes("%") ? (
                  props.p.targetReturn ? (
                    <p className="detailsValue">
                      {props.p.targetReturn} &nbsp;
                    </p>
                  ) : (
                    <p className="detailsValue">&nbsp;</p>
                  )
                ) : props.p.targetReturn ? (
                  <p className="detailsValue">{props.p.targetReturn} %&nbsp;</p>
                ) : (
                  <p className="detailsValue"></p>
                )} */}
                {props.p.targetReturn ? (
                  <p className="detailsValue detailsValueWrap">
                    <span
                      data-pr-tooltip={props.p.targetReturn}
                      data-pr-position="right"
                      title={props.p.targetReturn.toString()}
                    >
                      {props.p.targetReturn} &nbsp;
                    </span>
                  </p>
                ) : (
                  <p className="detailsValue">&nbsp;</p>
                )}
              </div>
              <div className="propDetails">
                {props.p.productState === "PreFunded" ? (
                  <p className="detailsHeader">Estimated Exit: </p>
                ) : (
                  <p className="detailsHeader">Target Close Date: </p>
                )}

                {props.p.productState === "PreFunded" ? (
                  props.p.estExitDate ? (
                    <p className="detailsValue">
                      {convertUTCToLocalDate(
                        props.p.estExitDate,
                        "mm/dd/yyyy"
                      ).toString()}
                      &nbsp;
                    </p>
                  ) : (
                    <p className="detailsValue">&nbsp;</p>
                  )
                ) : props.p.targetCloseDate ? (
                  <p className="detailsValue">
                    {convertUTCToLocalDate(
                      props.p.targetCloseDate,
                      "mm/dd/yyyy"
                    ).toString()}
                    &nbsp;
                  </p>
                ) : (
                  <p className="detailsValue">&nbsp;</p>
                )}
              </div>
              {/* {props.p.showRemainingAllocation && (
                <div className="propDetails">
                  <p className="detailsHeader">Remaining Allocation:</p>
                  <p className="detailsValue">{remainingAllocation} &nbsp;</p>
                </div>
              )} */}

              {props.p.showRemainingAllocation ? (
                <div className="propDetails">
                  <p className="detailsHeader">Remaining Allocation:</p>
                  <p className="detailsValue">{remainingAllocation} &nbsp;</p>
                </div>
              ) : (
                <div className="propDetails">
                  <p className="detailsHeader">&nbsp;</p>
                  <p className="detailsValue">&nbsp;</p>
                </div>
              )}

              <div className="button flex justify-content-between">
                {/* <Link to={`../../OpportunityDetails/${ID}`}> */}
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    localStorage.setItem("navigationPage", "Opportunities");
                    navigate(`../../OpportunityDetails/${ID}`);
                  }}
                  className="BtnBlue w-47"
                >
                  View Details
                </Button>
                {/* </Link> */}
                <Button
                  className="BtnWhite w-47 "
                  onClick={(e) => {
                    e.preventDefault();
                    setVisible(true);
                  }}
                  // disabled={props.selectedValue === "Allocated"}
                  disabled={true}
                  label={
                    props.p.productState === "PreFunded"
                      ? "Request Investment"
                      : "Soft Commit"
                  }
                />

                <OpportunitySoftCommitPop
                  productState={props.p.productState}
                  ID={ID}
                  onModalClose={onCloseModal}
                  openModal={visible}
                />
                {/* <OpportunitySoftCommitPop
                  ID={ID}
                  onModalClose={onCloseModal}
                  openModal={visible}
                /> */}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
export default OpportunityCard;
