import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Dialog } from "primereact/dialog";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import AmountDisplay from "../../../../components/amount-display";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
// import { getThunkEarningsEntries } from "../../../../store/features/operation/reverse-earning-allocation/get-earnings-entries/slice";

import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { getThunkReverseData } from "../../../../store/features/operation/reverse-earning-allocation/get-earnings-data/slice";
import { getThunkLastInvestmentReturnId } from "../../../../store/features/operation/reverse-earning-allocation/last-investment-return-id/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { reverseAllocatedPoolFundsByStakeIdThunk } from "../../../../store/poolfunds/exit-pool-funds";
import {
  getAfterEarningsStatckbyInvestmentReturnIDThunk,
  resetAllEarningsStatckbyInvestmentReturn,
} from "../../../../store/poolfunds/get-after-earning-stake-byinvestment";
import { PoolFundOutPutType } from "../../../../types";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  convertUTCToLocalDate,
  // dateFormatter
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

interface IProps {
  openDialog: any;
  setOpenDialog: any;
  rowSelectData: any;
  setRowSelectData: any;
}
export default function ReverseEarningSection(props: IProps) {
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [loading, setLoading] = useState(false);
  const [tabName, setTabName] = useState("PartnerTransaction");
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [earningPartnerTransaction, setEarningPartnerTransaction] =
    useState<any>();
  const [earningInvestmentReturn, setEarningInvestmentReturn] = useState<any>();
  const [earningUnitPrice, setEarningUnitPrice] = useState<any>();
  const [earningInvestmentStake, setEarningInvestmentStake] = useState<any>();
  const [earningExternalInvestment, setEarningExternalInvestment] =
    useState<any>();
  const [earningProduct, setEarningProduct] = useState<any>();
  const [openDialogReverse, setOpenDialogReverse] = useState(false);
  const earningEntriesPartnerTransaction = useAppSelector(
    (state) => state.getEarningEntries.data.lpAccountTransaction
  );
  const earningEntries = useAppSelector(
    (state) => state.getEarningEntries.data
  );
  const earningEntriesInvestmentReturn = useAppSelector(
    (state) => state.getEarningEntries.data.invReturn
  );
  const earningEntriesUnitPrice = useAppSelector(
    (state) => state.getEarningEntries.data.unitPriceValue
  );
  const earningEntriesInvestmentStake = useAppSelector(
    (state) => state.getEarningEntries.data.invStake
  );
  const earningEntriesExternalInvestment = useAppSelector(
    (state) => state.getEarningEntries.data.externalInv
  );
  const earningEntriesProduct = useAppSelector(
    (state) => state.getEarningEntries.data.prod
  );
  const getAllAfterEarningStakeInvestment = useAppSelector(
    (state) => state.getAfterEarningStakeByinvestment.data.output
  );
  const formatCurrencyAnyData = (rowData: any) => {
    const finalValue = Number(rowData);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const formatDate = (rowData: any) => {
    // const dateFormat = dateFormatter(rowData);
    const dateFormat = formatDateMMDDYYY(rowData);

    return dateFormat;
  };
  const dataTablePartnerAccTransaction = [
    {
      field: "invName",
      header: "Investor Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.invName}>
          {rowData?.invName}
        </div>
      ),
    },
    {
      field: "transactionTypeID",
      header: "Transaction Type Id",
      style: { minWidth: "180px" },
    },
    {
      field: "credit",
      header: "Credit",
      style: { minWidth: "180px" },
      body: (rowData: any) =>
        rowData.credit === null ? "" : formatCurrencyAnyData(rowData.credit),
    },
    {
      field: "transactionDate",
      header: "Transaction Date",
      style: { minWidth: "180px" },
      dataType: "date",
      body: (rowData: any) =>
        rowData.transactionDate === null
          ? ""
          : formatDate(rowData.transactionDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "investmentID",
      header: "External Investment Id",
      style: { minWidth: "200px" },
    },
    {
      field: "investmentReturnID",
      header: "Investment Return Id",
      style: { minWidth: "200px" },
    },
    { field: "createdBy", header: "Created By", style: { minWidth: "150px" } },
    {
      field: "dateCreated",
      header: "Created Date",
      dataType: "date",
      style: { minWidth: "160px" },
      body: (rowData: any) =>
        rowData.dateCreated === null ? "" : formatDate(rowData.dateCreated),
      filterElement: dateFilterTemplate,
    },
  ];
  const dataTableInvestmentReturn = [
    {
      field: "investmentReturnID",
      header: "Return Id",
      style: { minWidth: "160px" },
    },
    {
      field: "investmentID",
      header: "External Investment Id",
      style: { minWidth: "200px" },
    },
    {
      field: "investmentReturnType",
      header: "Investment Return Type",
      style: { minWidth: "210px" },
    },
    {
      field: "returnDate",
      header: "Return Date",
      dataType: "date",
      style: { minWidth: "180px" },
      body: (rowData: any) =>
        rowData.returnDate === null ? "" : formatDate(rowData.returnDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "amount",
      header: "Amount",
      style: { minWidth: "160px" },
      body: (rowData: any) =>
        rowData.amount === null ? "" : formatCurrencyAnyData(rowData.amount),
    },
    {
      field: "partnerDistributionDate",
      header: "Partner Distribution Date",
      style: { minWidth: "230px" },
      dataType: "date",
      body: (rowData: any) =>
        rowData.partnerDistributionDate === null
          ? ""
          : formatDate(rowData.partnerDistributionDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "equityCashDistiAllocationDate",
      header: "Equity Allocation Date",
      style: { minWidth: "220px" },
      dataType: "date",
      body: (rowData: any) =>
        rowData.equityCashDistiAllocationDate === null
          ? ""
          : formatDate(rowData.equityCashDistiAllocationDate),
      filterElement: dateFilterTemplate,
    },
  ];
  const dataTableUnitPrice = [
    {
      field: "unitPrice",
      header: "Unit Price",
      body: (rowData: any) =>
        rowData.unitPrice === null
          ? ""
          : formatCurrencyAnyData(rowData.unitPrice),
    },
    {
      field: "startDate",
      header: "Start Date",
      dataType: "date",
      body: (rowData: any) =>
        rowData.startDate === null ? "" : formatDate(rowData.startDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "endDate",
      header: "End Date",
      dataType: "date",
      body: (rowData: any) =>
        rowData.endDate === null ? "" : formatDate(rowData.endDate),
      filterElement: dateFilterTemplate,
    },
  ];
  const dataTableInvestmentStake = [
    { field: "investmentID", header: "External Investment Id" },
    { field: "lpPartnerID", header: "LP Partner Id" },
    { field: "units", header: "Investment Balance" },
    {
      field: "startDate",
      header: "Start Date",
      dataType: "date",
      body: (rowData: any) =>
        rowData.startDate === null ? "" : formatDate(rowData.startDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "endDate",
      header: "End Date",
      dataType: "date",
      body: (rowData: any) =>
        rowData.endDate === null ? "" : formatDate(rowData.endDate),
      filterElement: dateFilterTemplate,
    },
    { field: "legalEntityAccountTransID", header: "Fund Account Trans Id" },
  ];

  const dataTableExternalInvestment = [
    { field: "legalEntityName", header: "Fund Name" },

    {
      field: "investmentDate",
      header: "Investment Date",
      dataType: "date",
      body: (rowData: any) =>
        rowData.investmentDate === null
          ? ""
          : formatDate(rowData.investmentDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "amount",
      header: "Amount",
      body: (rowData: any) =>
        rowData.amount === null ? "" : formatCurrencyAnyData(rowData.amount),
    },
    { field: "units", header: "Investment Balance" },
    { field: "productName", header: "Investment Name" },
  ];
  const dataTableProduct = [
    { field: "productState", header: "Investment State" },
    { field: "supplierProductCode", header: "Lookup Code" },
    { field: "productName", header: "Investment Name" },
    {
      field: "actualExitDate",
      header: "Actual Exit Date",
      dataType: "date",
      body: (rowData: any) =>
        rowData.actualExitDate === null
          ? ""
          : formatDate(rowData.actualExitDate),
      filterElement: dateFilterTemplate,
    },
  ];

  console.log("IProps", props);
  const handleReverse = (rowData: PoolFundOutPutType) => {
    setLoading(true);
    const paramReverse = {
      legalEntityId: fundDropdown.fundid,
      investmentId: rowData.investmentID,
      stakeTrackingId: rowData.stakeTrackingID,
    };

    dispatch(reverseAllocatedPoolFundsByStakeIdThunk(paramReverse))
      .then((res) => {
        // setLoading(false);
        if (res.payload.success) {
          successToastMessage(toast, res.payload.message);
        } else {
          errorToastMessage(
            toast,
            res.payload.message || "Something went wrong!"
          );
        }
        dispatch(
          getAfterEarningsStatckbyInvestmentReturnIDThunk({
            investmentReturnId: props.rowSelectData.investmentReturnID,
            investmentId: props.rowSelectData.investmentID,
          })
        ).finally(() => setLoading(false));
      })
      .catch(() => {
        errorToastMessage(toast, "Something went wrong!");
        setLoading(false);
      });
  };

  const onReveserClick = (rowData: PoolFundOutPutType) => {
    return (
      <>
        {rowData?.isButton ? (
          <Button
            className="btn-navActive"
            onClick={(e) => {
              e.preventDefault();
              handleReverse(rowData);
            }}
          >
            Reverse
          </Button>
        ) : (
          <Button
            className="btn-navActive"
            onClick={(e) => {
              e.preventDefault();
            }}
            disabled={true}
          >
            Reverse
          </Button>
        )}
      </>
    );
  };
  const dataTableElementReverse = [
    {
      field: "lpPartnerName",
      header: "Investor Name",
      noFilter: true,
    },

    {
      field: "units",
      header: "Allocated Investment Balance",
      body: (rowData: PoolFundOutPutType) => (
        <AmountDisplay amount={rowData.addOnUnits ? rowData.addOnUnits : 0}>
          {currencyFormat(rowData.addOnUnits)}
        </AmountDisplay>
      ),
    },
    {
      field: "unitsType",
      header: "Units Type",
    },
    {
      field: "maxStartDate",
      header: "Start Date",
      dataType: "date",
      body: (rowData: PoolFundOutPutType) =>
        convertUTCToLocalDate(rowData.maxStartDate),
    },
    {
      field: "endDate",
      header: "End Date",
      dataType: "date",
      body: (rowData: PoolFundOutPutType) =>
        rowData?.endDate ? convertUTCToLocalDate(rowData.endDate) : "N/A",
    },
    { field: "assetClass", header: "Class" },
    {
      header: "Reverse",
      body: onReveserClick,
    },
  ];

  const computeSum = (
    totalFieldName: string,
    // calculateDataForEarning = calculateEarning
    earningText = earningPartnerTransaction
  ) => {
    if (earningText !== undefined) {
      const { length } = earningText;

      let count = 0;
      for (let i = 0; i < length; i += 1) {
        count += Number(
          typeof earningText[i][totalFieldName] === "object"
            ? earningText[i][totalFieldName]
            : earningText[i][totalFieldName] || 0
        );
      }

      return formatCurrencyAnyData(count);
    }
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total:"
          colSpan={2}
          // footerStyle={{ textAlign: "right" }}
        />
        <Column footer={computeSum("credit")} />
        <Column colSpan={5} />
      </Row>
    </ColumnGroup>
  );

  const partnerTransaction = () => {
    return (
      <>
        {" "}
        <DataTableComponent
          key={tabName}
          scrollable={true}
          valueData={earningPartnerTransaction || []}
          fieldsElements={dataTablePartnerAccTransaction}
          isPaginationAllowed={true}
          footerElement={footerGroup}
          className="gridcell"
          resizableColumns={true}
        />
      </>
    );
  };
  const unitPrice = () => {
    return (
      <>
        {" "}
        <DataTableComponent
          key={tabName}
          scrollable={true}
          valueData={earningUnitPrice || []}
          fieldsElements={dataTableUnitPrice}
          isPaginationAllowed={true}
          className="gridcell"
          resizableColumns={true}
        />
      </>
    );
  };
  const investmentStake = () => {
    return (
      <>
        {" "}
        <DataTableComponent
          key={tabName}
          scrollable={true}
          valueData={earningInvestmentStake || []}
          fieldsElements={dataTableInvestmentStake}
          isPaginationAllowed={true}
          className="gridcell"
          resizableColumns={true}
        />
      </>
    );
  };
  const externalInvestment = () => {
    return (
      <>
        {" "}
        <DataTableComponent
          key={tabName}
          valueData={earningExternalInvestment || []}
          fieldsElements={dataTableExternalInvestment}
          scrollable={true}
          isPaginationAllowed={true}
          className="gridcell"
          resizableColumns={true}
        />
      </>
    );
  };
  const product = () => {
    return (
      <>
        {" "}
        <DataTableComponent
          key={tabName}
          valueData={earningProduct || []}
          fieldsElements={dataTableProduct}
          scrollable={true}
          isPaginationAllowed={true}
          className="gridcell"
          resizableColumns={true}
        />
      </>
    );
  };
  let content;
  switch (tabName) {
    case "PartnerTransaction":
      content = partnerTransaction();
      break;
    case "UnitPrice":
      content = unitPrice();
      break;
    case "InvestmentStake":
      content = investmentStake();
      break;
    case "ExternalInvestment":
      content = externalInvestment();
      break;
    case "Product":
      content = product();
      break;
    default:
      content = <></>;
      break;
  }

  const handleReverseEarning = async () => {
    setLoading(true);
    setOpenDialogConfirm(false);
    let investmentReturnIdData = 0;
    await dispatch(
      getThunkLastInvestmentReturnId(props.rowSelectData?.investmentID)
    ).then((reponse) => {
      investmentReturnIdData =
        reponse.payload._response.data.investmentReturnId;
      console.log("ghyu", reponse.payload._response.data.investmentReturnId);
    });
    console.log("gh", investmentReturnIdData);
    if (investmentReturnIdData !== props.rowSelectData?.investmentReturnID) {
      errorToastMessage(
        toast,
        "There are more distributions done after this – Please reverse those before this one"
      );
      setLoading(false);
    } else {
      if (investmentReturnIdData > 0) {
        const parameter = {
          investmentReturnTypeId: props.rowSelectData?.investmentReturnTypeID,
          investmentReturnId: investmentReturnIdData,
          legalEntityId: fundDropdown.fundid,
        };
        console.log(parameter);
        dispatch(getThunkReverseData(parameter))
          .then(() => {
            setLoading(false);
            successToastMessage(toast, "Reverse Allocation is done");
            setTabName("PartnerTransaction");
            props.setOpenDialog(false);
          })
          .catch(() => {
            errorToastMessage(toast);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
  useEffect(() => {
    setEarningPartnerTransaction(
      getListDateConversion(earningEntriesPartnerTransaction, [
        "transactionDate",
        "dateCreated",
      ])
    );
    setEarningInvestmentReturn(
      getListDateConversion(earningEntriesInvestmentReturn, [
        "returnDate",
        "partnerDistributionDate",
        "equityCashDistiAllocationDate",
      ])
    );
    setEarningUnitPrice(
      getListDateConversion(earningEntriesUnitPrice, ["startDate", "endDate"])
    );
    setEarningInvestmentStake(
      getListDateConversion(earningEntriesInvestmentStake, [
        "startDate",
        "endDate",
      ])
    );
    setEarningExternalInvestment(
      getListDateConversion(earningEntriesExternalInvestment, [
        "investmentDate",
      ])
    );
    setEarningProduct(
      getListDateConversion(earningEntriesProduct, ["actualExitDate"])
    );
  }, [earningEntries]);

  const onCancel = () => {
    setOpenDialogConfirm(false);
  };

  return (
    <>
      <Toast ref={toast} className="themeToast" />
      <ConfirmDailogbox
        openDialog={openDialogConfirm}
        setOpenDialog={setOpenDialogConfirm}
        onConfirm={handleReverseEarning}
        onCancel={onCancel}
        textContent="Are you sure you want to reverse ?"
      />
      <Dialog
        visible={openDialogReverse}
        onHide={() => {
          setOpenDialogReverse(false);
        }}
        className="w-100 md:w-10 pb-1"
        headerClassName="no-header"
      >
        {loading === true ? <Loader /> : <></>}
        <div className="mt-2">
          <h4 className="pageHeader mt-2 flex justify-content-center">
            Stake Reverse
          </h4>
          <div className="mt-2">
            <DataTableComponent
              valueData={getAllAfterEarningStakeInvestment || []}
              fieldsElements={dataTableElementReverse}
              scrollable={true}
              className="allocateSliceTable gridcell"
              noGridLines={true}
              resizableColumns={true}
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={props.openDialog}
        onHide={() => {
          props.setOpenDialog(false);
          dispatch(resetAllEarningsStatckbyInvestmentReturn());
          setTabName("PartnerTransaction");
        }}
        headerClassName="no-header"
        contentClassName="top-lr-content"
        style={{ width: "90vw" }}
      >
        {loading === true ? <Loader /> : <></>}

        {props.rowSelectData?.investmentReturnTypeID === 3 ? (
          <div className="  col-12">
            <div className="tabHeader-wrap grid">
              <div className="ml-2 mr-2">
                <Button
                  className={
                    tabName === "PartnerTransaction"
                      ? "tabHeadText Active"
                      : "tabHeadText"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setTabName("PartnerTransaction");
                  }}
                >
                  <span className="ml-auto mr-auto ">
                    Partner Account Transactions
                  </span>
                </Button>
              </div>

              <div className="ml-2 mr-2">
                <Button
                  className={
                    tabName === "UnitPrice"
                      ? "tabHeadText Active"
                      : "tabHeadText"
                  }
                  onClick={(e) => {
                    e.preventDefault();

                    setTabName("UnitPrice");
                  }}
                >
                  <span className="ml-auto mr-auto ">Unit Price</span>
                </Button>
              </div>
              <div className="ml-2 mr-2">
                <Button
                  className={
                    tabName === "InvestmentStake"
                      ? "tabHeadText Active"
                      : "tabHeadText"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setTabName("InvestmentStake");
                  }}
                >
                  <span className="ml-auto mr-auto ">Investment Stake</span>
                </Button>
              </div>
              <div className="ml-2 mr-2">
                <Button
                  className={
                    tabName === "ExternalInvestment"
                      ? "tabHeadText Active"
                      : "tabHeadText"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setTabName("ExternalInvestment");
                  }}
                >
                  <span className="ml-auto mr-auto "> Investment</span>
                </Button>
              </div>
              <div className="ml-2 mr-2">
                <Button
                  className={
                    tabName === "Product" ? "tabHeadText Active" : "tabHeadText"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setTabName("Product");
                  }}
                >
                  <span className="ml-auto mr-auto ">Product</span>
                </Button>
              </div>
            </div>{" "}
          </div>
        ) : (
          <></>
        )}

        <div className="mt-4">{content}</div>
        {/* <small className="p-error">{errorMessage}</small> */}
        {getAllAfterEarningStakeInvestment.length > 0 ? (
          <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
            <div className="d-flex flex-column p-2 col-12 sm:col-2 ">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  // handleReverseEarning();
                  setOpenDialogReverse(true);
                }}
                className="blueButtonShadow"
                disabled={earningPartnerTransaction?.length === 0}
              >
                <span className="ml-auto mr-auto">Reverse Earning</span>
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
            <div className="d-flex flex-column p-2 col-12 sm:col-2 ">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  // handleReverseEarning();
                  setOpenDialogConfirm(true);
                }}
                className="blueButtonShadow"
                disabled={earningPartnerTransaction?.length === 0}
              >
                <span className="ml-auto mr-auto">Reverse Earning</span>
              </Button>
            </div>
          </div>
        )}
        <div className="mt-4">
          <DataTableComponent
            valueData={earningInvestmentReturn || []}
            fieldsElements={dataTableInvestmentReturn}
            scrollable={true}
            isPaginationAllowed={true}
            className="gridcell"
            resizableColumns={true}
          />
        </div>
      </Dialog>
    </>
  );
}
