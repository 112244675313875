import { useFormik } from "formik";
import { Button } from "primereact/button";

import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../../components/loader/Loader";
import {
  addThunkInvestment,
  setForm,
} from "../../../../../store/features/investment/create/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  convertLocalDateToUTC,
  convertUTCToLocalDate,
} from "../../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../../utils/toast-message";

interface IProps {
  handlePrevious: any;
  handleNext: any;
  offering: any;
}

export const CreateStep3 = (props: IProps) => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const stateInvestment = useAppSelector((state) => state.addInvestment);
  const [loading, setLoading] = useState(false);
  const stateDropDownLists = useAppSelector((state) => state.dropdownList.data);
  const formik = useFormik({
    initialValues: {
      interestRate: "",
      equityInvestment: "",
      assetClassID: "",
      investmentLengthID: "",
      earningsPayoutTiming: "",
      estExitDate: "",
    },
    validate: (data) => {
      //
      const errors: any = {};
      if (!data.interestRate) {
        errors.interestRate = "Interest rate is required";
      }
      if (data.interestRate && data.interestRate.length > 50) {
        errors.interestRate =
          "Enter interest rate with maximum length of 50 characters";
      }
      // if (data.interestRate && !/^[\d.]*$/.test(data.interestRate)) {
      //   errors.interestRate = "Enter only digits";
      // }

      if (!data.equityInvestment) {
        errors.equityInvestment = "Equity investment is required";
      }
      if (!data.assetClassID) {
        errors.assetClassID = "Asset class is required";
      }
      if (!data.investmentLengthID) {
        errors.investmentLengthID = "Investment length is required";
      }
      if (!data.earningsPayoutTiming) {
        errors.earningsPayoutTiming = "Earnings timing is required";
      }
      if (!data.estExitDate) {
        errors.estExitDate = "Estimate exit date is required";
      }
      return errors;
    },

    onSubmit: async (data) => {
      setLoading(true);
      await dispatch(addThunkInvestment(stateInvestment.data!))
        .then((response) => {
          console.log("resp", response);
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (
            response.meta.requestStatus === "fulfilled" &&
            response.payload.data.productDetails[0].productID !== 0
          ) {
            successToastMessage(toast, stateInvestment.message);
            props.handleNext();
          }
        })
        .catch((error: any) => {
          console.log("error", error);
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleChangeState = () => {
    dispatch(
      setForm({
        ...stateInvestment.data,
        interestRate: formik.values.interestRate,
        equityInvestment: formik.values.equityInvestment,
        assetClassID: formik.values.assetClassID,
        investmentLengthID: formik.values.investmentLengthID,
        earningsPayoutTiming: formik.values.earningsPayoutTiming,
        estExitDate: convertLocalDateToUTC(formik.values.estExitDate),
      })
    );
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    formik.setFieldValue("interestRate", stateInvestment.data?.interestRate);
    formik.setFieldValue(
      "equityInvestment",
      stateInvestment.data?.equityInvestment
    );
    formik.setFieldValue("assetClassID", stateInvestment.data?.assetClassID);
    formik.setFieldValue(
      "investmentLengthID",
      stateInvestment.data?.investmentLengthID
    );
    formik.setFieldValue(
      "earningsPayoutTiming",
      stateInvestment.data?.earningsPayoutTiming
    );
    formik.setFieldValue(
      "estExitDate",
      stateInvestment.data?.estExitDate === null ||
        stateInvestment.data?.estExitDate === ""
        ? stateInvestment.data?.estExitDate
        : convertUTCToLocalDate(stateInvestment.data?.estExitDate!)
    );
    // stateInvestment.data?.estExitDate);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <div>
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader"></h1>
          </div>
        </div>
        <div className=" h-100 center-card">
          <div className="col-12 " style={{ width: "80%" }}>
            <div className="card my-3">
              <Card>
                <div className="headercard d-flex grid">
                  <div className="col-12 md:col-6">
                    <h2 style={{ fontWeight: "bold" }}>
                      Create New {props.offering}
                    </h2>
                  </div>
                  <div className="col-12 md:col-6 end-div">
                    <h6>Earnings Info - Step 3</h6>
                  </div>
                </div>
                <div>
                  <ProgressBar
                    value={42}
                    showValue={false}
                    className="m-2"
                  ></ProgressBar>
                </div>

                <form>
                  <div className="mt-4">
                    <div className="d-flex flex-column p-2 col-12">
                      <label className="inputLabel">Investment Type</label>

                      <div className="d-flex flex-column p-0 col-12">
                        <div className="d-flex mt-2">
                          <div className="flex align-items-center pr-4">
                            <div className="my-3 test-class">
                              <RadioButton
                                inputId="equityInvestment"
                                name="equityInvestment"
                                value="yes"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                checked={
                                  formik.values.equityInvestment === "yes"
                                }
                              />

                              <label
                                // htmlFor="productState"
                                className="ml-2 mb-0"
                              >
                                Equity (Fund receives capital gains at sale)
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-2">
                          <div className="flex align-items-center pr-4">
                            <div className="my-3 test-class">
                              <RadioButton
                                inputId="equityInvestment"
                                name="equityInvestment"
                                value="no"
                                onChange={(e) => handleChange(e)}
                                checked={
                                  formik.values.equityInvestment === "no"
                                }
                              />

                              <label
                                // htmlFor="productState"
                                className="ml-2 mb-0"
                              >
                                Debt (Fund receives fixed income)
                              </label>
                            </div>
                          </div>
                        </div>
                        {getFormErrorMessage("productState")}
                      </div>
                    </div>
                    <div className="formgrid grid px-2">
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-12">
                        <label className="inputLabel">IRR/Interest Rate</label>
                        <InputText
                          name="interestRate"
                          // value={
                          //   formik.values.interestRate === undefined ||
                          //   formik.values.interestRate === 0
                          //     ? ""
                          //     : formik.values.interestRate
                          // }
                          value={formik.values.interestRate}
                          onChange={(e) => handleChange(e)}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("interestRate"),
                            "card-inputBox": true,
                          })}
                          // maxLength={10}
                          placeholder="Enter Interest Rate"
                        />
                        {getFormErrorMessage("interestRate")}
                      </div>
                    </div>
                    <div className="formgrid grid px-2">
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                        <label className="inputLabel">Asset Class</label>
                        <Dropdown
                          inputId="assetClassID"
                          name="assetClassID"
                          value={formik.values.assetClassID}
                          options={
                            stateDropDownLists === null
                              ? []
                              : stateDropDownLists.assetClassDetails
                          }
                          filter
                          showOnFocus
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select Asset Class"
                          // className="card-inputBox"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("assetClassID"),
                            "card-inputBox": true,
                          })}
                        />

                        {getFormErrorMessage("assetClassID")}
                      </div>
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                        <label className="inputLabel">
                          How long is the Investment?
                        </label>
                        <Dropdown
                          inputId="investmentLengthID"
                          name="investmentLengthID"
                          value={formik.values.investmentLengthID}
                          options={
                            stateDropDownLists === null
                              ? []
                              : stateDropDownLists.investmentLength
                          }
                          filter
                          showOnFocus
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select Duration"
                          // className="card-inputBox"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={classNames({
                            "p-invalid":
                              isFormFieldInvalid("investmentLengthID"),
                            "card-inputBox": true,
                          })}
                        />

                        {getFormErrorMessage("investmentLengthID")}
                      </div>
                    </div>
                    <div className="formgrid grid px-2">
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                        <label className="inputLabel">
                          When will earnings will paid out?
                        </label>
                        <Dropdown
                          inputId="earningsPayoutTiming"
                          name="earningsPayoutTiming"
                          value={formik.values.earningsPayoutTiming}
                          options={
                            stateDropDownLists === null
                              ? []
                              : stateDropDownLists.earningsPayoutTiming
                          }
                          filter
                          showOnFocus
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select earnings timing"
                          // className="card-inputBox"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid(
                              "earningsPayoutTiming"
                            ),
                            "card-inputBox": true,
                          })}
                        />

                        {getFormErrorMessage("earningsPayoutTiming")}
                      </div>
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                        <label className="inputLabel">
                          Estimated Exit Date
                        </label>

                        <Calendar
                          name="estExitDate"
                          value={
                            formik.values.estExitDate === "" ||
                            formik.values.estExitDate === null
                              ? undefined
                              : new Date(formik.values.estExitDate)
                          }
                          onChange={(e) => handleChange(e)}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("estExitDate"),
                            "card-inputBox": true,
                          })}
                          placeholder="Select Exit Date"
                          readOnlyInput
                        />

                        {getFormErrorMessage("estExitDate")}
                      </div>
                    </div>

                    <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
                      <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                        <Button
                          className="btn-nav btnFocus"
                          onClick={(e) => {
                            e.preventDefault();
                            props.handlePrevious();
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth", // Smooth scrolling
                            });
                          }}
                        >
                          <span className="ml-auto mr-auto ">Back</span>
                        </Button>
                      </div>
                      <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                        <Button
                          className="btn-dialog btnFocus"
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            handleChangeState();
                            formik.handleSubmit();
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth", // Smooth scrolling
                            });
                          }}
                        >
                          <span className="ml-auto mr-auto ">Next</span>
                        </Button>
                        <label className="inputLabel">&nbsp;</label>
                      </div>
                    </div>
                  </div>
                </form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
