import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getManagerFeesTypes } from "../../services/ManagerFees";
import error from "../../utils/error";

const initialState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getManagerFeesTypesThunk = createAsyncThunk(
  "getManagerFeesTypesThunk",
  async () => {
    try {
      const response = await getManagerFeesTypes();
      console.log("response exit", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getManagerFeesTypes",
  initialState,
  reducers: {
    resetManagerFeesTypes(state) {
      state.data = initialState.data;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getManagerFeesTypesThunk.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(getManagerFeesTypesThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("hr getManagerFeesTypesThunk", action.payload);
      state.data = action.payload.success
        ? action.payload.data
        : initialState.data;
    });
    builder.addCase(getManagerFeesTypesThunk.rejected, (state, action) => {
      state.loading = "failed";
      throw new Error(error(action.error));
    });
  },
});
export const managerFeesTypeActions = slice.actions;
export default slice.reducer;
