import { FormikValues } from "formik";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { classNames } from "primereact/utils";
import React, { memo, useEffect, useState } from "react";
// import { RiDeleteBin5Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { deleteInvestingAccountEnrollmentThunk } from "../../../../store/Investor/investor-approval/delete-investing-account-enrollment/slice";
import { getLookUpbyAccountIdThunk } from "../../../../store/Investor/investor-approval/get-lookup-by-accountId/slice";
import {
  getInvestingAccountDetailsByIdThunk,
  resetInvestingAccountDetailsById,
} from "../../../../store/Investor/investor-approval/verify-agreement/investing-account/get-investing-account-details/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { initialInvestingAccountValue } from "../../../../utils/investing-approval/multi-investing-account/initial-value";
import {
  setFormikFieldsForCorporation,
  setFormikFieldsForIndividual,
  setFormikFieldsForJointAccount,
  setFormikFieldsForMultiMemberLLC,
  setFormikFieldsForPartnership,
  setFormikFieldsForSelfDirectedIRA,
  setFormikFieldsForSingleMemberLLC,
  setFormikFieldsForTrust,
  setFormikFieldsForsolo401k,
} from "../../../../utils/investing-approval/multi-investing-account/set-formik-data";
import AccountMailingInformation from "../investor-information/account-mailing-information/AccountMailingInformation";
import BeneficiaryInformation from "../investor-information/beneficiary-information/BeneficiaryInformation";
import Information from "../investor-information/common-information/Information";
import CustodianInformation from "../investor-information/custodian-information/CustodianInformation";
import SecondaryAccountHolder from "../investor-information/secondary-account-holder/SecondaryAccountHolder";
import TaxInformation from "../investor-information/tax-information/TaxInformation";

interface IProps {
  investorID: string;
  legalEntityID: string;
  investingAccountID: string;
  //   accountTypeID: number;
  formik: FormikValues;
  isFormFieldInvalid: any;
  getFormErrorMessage: any;
}

const InvestingAccountInformation: React.FC<IProps> = (props) => {
  const investingAccountID = props.investingAccountID;
  const dispatch = useAppDispatch();

  const [loader, setLoader] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const navigate = useNavigate();

  const investingAccountDetailsData = useAppSelector(
    (state) => state.getInvestingAccountDetailsById.data
  );

  const additionalMembers = useAppSelector(
    (state) => state.getInvestingAccountDetailsById.additionalMembers
  );

  const investingAccountDetailsState = useAppSelector(
    (state) => state.getInvestingAccountDetailsById.loading
  );

  const accountTpesDropdown = useAppSelector((state) => {
    return state.investingAccountsLookups.accountTpesDropdown;
  });

  const approveAndSaveButtonStatus = useAppSelector(
    (State) => State.getStatusAndFlag.data
  );
  // Get IA Enrollment Data
  // const getIAEnrollmentDataLinkData = useAppSelector(
  //   (state) => state.getIAEnrollmentData.data
  // );
  // const getIAEnrollmentDataLinkState = useAppSelector(
  //   (state) => state.getIAEnrollmentData.loading
  // );

  const [accountType, setAccountType] = useState(0);

  const informationData = {
    2: {
      title: "Trustee",
      information: "trusteeInfo",
      fieldName: "noOfTrustees",
      subHeader: "How many trustees are in this trust?",
    },
    7: {
      title: "Beneficial Member",
      information: "memberInformation",
      fieldName: "noBeneficialMemberInformation",
      subHeader: "How many people own 25% or more of the entity?",
    },
    8: {
      title: "Partner",
      information: "partnerInfo",
      fieldName: "noOfPartnerInfo",
      subHeader: "How many partners are in this trust?",
    },
    9: {
      title: "Executive Officer",
      information: "officerInfo",
      fieldName: "noOfOfficerInfo",
      subHeader: "How many Executive Officer?",
    },
  };

  useEffect(() => {
    console.log(
      "useEffect InvestingAccountInformation props.investingAccountID",
      props.investingAccountID,
      props.legalEntityID
    );
    console.log("props.investingAccountID", props.investingAccountID);
    const body = {
      investingAccountID: +props.investingAccountID,
      legalEntityID: +props.legalEntityID,
    };
    dispatch(getInvestingAccountDetailsByIdThunk(body));
  }, [props.investingAccountID, props.legalEntityID]);

  useEffect(() => {
    if (accountType) {
      dispatch(getLookUpbyAccountIdThunk({ accountTypeId: +accountType }));
    }
    // console.log("account type change", accountType);
  }, [accountType]);

  useEffect(() => {
    if (investingAccountID && investingAccountDetailsData !== undefined) {
      setAccountType(investingAccountDetailsData?.accountTypeId);
      props.formik.setFieldValue("action", "Update");
      props.formik.setFieldValue(
        "accountType",
        investingAccountDetailsData.accountTypeId
      );

      switch (investingAccountDetailsData.accountTypeId) {
        case 1:
          setFormikFieldsForIndividual(
            props.formik,
            investingAccountDetailsData
          );
          break;
        case 2:
          setFormikFieldsForTrust(
            props.formik,
            investingAccountDetailsData,
            additionalMembers
          );
          break;
        case 3:
          setFormikFieldsForSingleMemberLLC(
            props.formik,
            investingAccountDetailsData
          );
          break;
        case 4:
          setFormikFieldsForsolo401k(props.formik, investingAccountDetailsData);
          break;
        case 5:
          setFormikFieldsForSelfDirectedIRA(
            props.formik,
            investingAccountDetailsData
          );
          break;
        case 6:
          setFormikFieldsForJointAccount(
            props.formik,
            investingAccountDetailsData
          );
          // addUpdateIAJointAccount(data, formik);
          break;
        case 7:
          setFormikFieldsForMultiMemberLLC(
            props.formik,
            investingAccountDetailsData,
            additionalMembers
          );
          break;
        case 8:
          setFormikFieldsForPartnership(
            props.formik,
            investingAccountDetailsData,
            additionalMembers
          );
          break;
        case 9:
          setFormikFieldsForCorporation(
            props.formik,
            investingAccountDetailsData,
            additionalMembers
          );
          break;
      }
    }
  }, [investingAccountDetailsData, investingAccountID]);

  const onCancel = () => {
    setConfirmDialog(false);
  };

  const onConfirm = () => {
    setConfirmDialog(false);
    setLoader(true);
    props.formik.setFieldValue("accountType", "");
    props.formik.setValues({ ...initialInvestingAccountValue });
    setAccountType(0);
    dispatch(resetInvestingAccountDetailsById());
    dispatch(
      deleteInvestingAccountEnrollmentThunk({
        investorId: +props.investorID,
        legalEntityId: +props.legalEntityID,
        investingAccountId: +props.investingAccountID,
      })
    )
      .then(() => {
        navigate(`../Enroll/${props.investorID}/${props.legalEntityID}/0`, {
          replace: true,
        });
      })
      .finally(() => setLoader(false));
  };

  console.log(investingAccountDetailsData);
  return (
    <>
      <Accordion activeIndex={[]} className="my-3">
        <AccordionTab
          header="Investing Account Information"
          // disabled={
          //   (+props.investingAccountID === 0 ||
          //     (typeof +props.investingAccountID !== "number" &&
          //       !getIAEnrollmentDataLinkData?.isSignCompleted &&
          //       getIAEnrollmentDataLinkData?.signer === "Signer 1") ||
          //     (getIAEnrollmentDataLinkData?.signer === "" &&
          //       getIAEnrollmentDataLinkData?.isOlduser !== true)) ??
          //   false
          // }
        >
          <div className="mb-4">
            <div className="md:flex md:flex-wrap md:justify-content-between">
              <h4 className="sectionHeader">Investing Account Information</h4>
              {/* {investingAccountDetailsState === "succeeded" &&
                approveAndSaveButtonStatus?.isDelFundEnrollEnabled && (
                  <>
                    <div className=" align-self-center md:mb-0 mb-3">
                      <Button
                        // redButtonTextBorder
                        onClick={(e) => {
                          e.preventDefault();
                          setConfirmDialog(true);
                        }}
                        className="redButtonTextBorder d-block  d-flex justify-content-center col-12"
                      >
                        Delete fund enrollment
                      </Button>
                    </div>
                  </>
                )} */}

              {investingAccountDetailsState === "succeeded" &&
                approveAndSaveButtonStatus?.isDelFundEnrollEnabled &&
                props.formik.values.action === "Update" &&
                investingAccountID &&
                +investingAccountID > 0 && (
                  <>
                    <div className=" align-self-center md:mb-0 mb-3">
                      <Button
                        // redButtonTextBorder
                        onClick={(e) => {
                          e.preventDefault();
                          setConfirmDialog(true);
                        }}
                        className="redButtonTextBorder d-block  d-flex justify-content-center col-12"
                      >
                        Delete fund enrollment
                      </Button>
                    </div>
                  </>
                )}
            </div>
            {investingAccountDetailsState === "loading" && (
              <Skeleton width="100%" height="100px"></Skeleton>
            )}
            {investingAccountDetailsState === "succeeded" && (
              <div className="fromSection">
                <div className="formgrid grid">
                  <div className="field col-12 md:col-6">
                    <label htmlFor="accountType" className="inputLabel">
                      Account Type <span className="text-red-500">*</span>
                    </label>

                    <Dropdown
                      id="accountType"
                      name="accountType"
                      value={props.formik.values.accountType}
                      // showOnFocus
                      // onChange={setAccountTypeAndName}
                      disabled={props.formik.values.action === "Update"}
                      options={accountTpesDropdown}
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select Account Type"
                      onChange={(e) => {
                        console.log("accountType sdad", e);
                        console.log(
                          "accountType formik.values.action",
                          props.formik.values.action
                        );
                        if (props.formik.values.action !== "Update") {
                          props.formik.setFieldValue("action", "Create");
                          const accountTypeName = accountTpesDropdown
                            ?.filter((obj) => obj.key === e.value)
                            .map((obj) => obj.value);

                          // console.log("accountTypeName", accountTypeName);

                          //   props.formik.setTouched({}, false);
                          //   props.formik.resetForm();
                          if (accountTypeName && accountTypeName?.length > 0) {
                            props.formik.setFieldValue(
                              "myAccountName",
                              accountTypeName[0]
                            );
                          }
                        }
                        props.formik.setFieldValue("accountType", e.value);
                        setAccountType(e.value);
                      }}
                      className={classNames({
                        "p-invalid": props.isFormFieldInvalid("accountType"),
                        "w-full": true,
                      })}
                    />
                    {props.getFormErrorMessage("accountType")}
                  </div>
                  {accountType ? (
                    <div className="field col-12 md:col-6">
                      <label className="inputLabel d-flex justify-content-start">
                        Account Name <span className="text-red-500">*</span>
                      </label>

                      <InputText
                        name="myAccountName"
                        // value={props.formik.values.myAccountName}
                        value={props.formik.values.myAccountName}
                        // unmask={true}
                        // disabled
                        placeholder="Name your account"
                        onChange={(e) => {
                          props.formik.setFieldValue(
                            "myAccountName",
                            e.target.value?.trimStart()
                          );
                        }}
                        className={classNames({
                          "p-invalid":
                            props.isFormFieldInvalid("myAccountName"),
                          "card-inputBox": true,
                        })}
                      />
                      {props.getFormErrorMessage("myAccountName")}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {accountType ? (
                  <>
                    {accountType === 5 ? (
                      <CustodianInformation
                        accountTypeID={+accountType}
                        formik={props.formik}
                        isFormFieldInvalid={props.isFormFieldInvalid}
                        getFormErrorMessage={props.getFormErrorMessage}
                      />
                    ) : (
                      <TaxInformation
                        accountTypeID={+accountType}
                        formik={props.formik}
                        isFormFieldInvalid={props.isFormFieldInvalid}
                        getFormErrorMessage={props.getFormErrorMessage}
                      />
                    )}
                    {/* render component */}
                    <AccountMailingInformation
                      accountTypeID={+accountType}
                      formik={props.formik}
                      isFormFieldInvalid={props.isFormFieldInvalid}
                      getFormErrorMessage={props.getFormErrorMessage}
                    />
                    {/* {(accountType === 7 || accountType === 8) && (
                      <PartnershipDetails
                        formik={formik}
                        isFormFieldInvalid={isFormFieldInvalid}
                        getFormErrorMessage={getFormErrorMessage}
                      />
                    )} */}
                    {(accountType === 1 || accountType === 3) && (
                      <BeneficiaryInformation
                        accountTypeID={+accountType}
                        formik={props.formik}
                        isFormFieldInvalid={props.isFormFieldInvalid}
                        getFormErrorMessage={props.getFormErrorMessage}
                      />
                    )}
                    {accountType === 6 && (
                      <SecondaryAccountHolder
                        accountTypeID={+accountType}
                        formik={props.formik}
                        isFormFieldInvalid={props.isFormFieldInvalid}
                        getFormErrorMessage={props.getFormErrorMessage}
                      />
                    )}
                    {/* {accountType === 7 && (
                        <BeneficialMemberInformation
                          accountTypeID={+accountType}
                          formik={formik}
                          isFormFieldInvalid={isFormFieldInvalid}
                          getFormErrorMessage={getFormErrorMessage}
                        />
                      )} */}
                    {(accountType === 2 ||
                      accountType === 7 ||
                      accountType === 8 ||
                      accountType === 9) && (
                      <Information
                        {...informationData[accountType]}
                        noOfRadioButton={4}
                        accountTypeID={+accountType}
                        formik={props.formik}
                        isFormFieldInvalid={props.isFormFieldInvalid}
                        getFormErrorMessage={props.getFormErrorMessage}
                      />
                    )}
                    {/* <AccreditationLetter />
                    <FundEnrollment /> */}
                    {/* <div className="flex justify-content-end flex-wrap">
                      <Button
                        type="submit"
                        label="Save"
                        className="BtnBlue flex align-items-center justify-content-center w-2"
                        onClick={(e) => {
                          e.preventDefault();
                          // formik.handleSubmit();
                        }}
                      />
                    </div> */}
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </AccordionTab>
      </Accordion>
      {loader && <Loader />}
      {confirmDialog && (
        <ConfirmDailogbox
          openDialog={confirmDialog}
          setOpenDialog={setConfirmDialog}
          onConfirm={onConfirm}
          onCancel={onCancel}
          textContent="Are you sure you want to delete investing account"
        />
      )}
    </>
  );
};

export default memo(InvestingAccountInformation);
