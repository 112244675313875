import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSearchInvestorEmployeeReportService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";
import {
  ISearchInvestorEmployeeParameter,
  ISearchInvestorEmployeeState,
} from ".";

const initialState: ISearchInvestorEmployeeState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const getSearchInvestorEmployeeReport = createAsyncThunk(
  "SearchInvestorEmployee",
  async (data: ISearchInvestorEmployeeParameter) => {
    try {
      const response = await getSearchInvestorEmployeeReportService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "SearchInvestorEmployee",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getSearchInvestorEmployeeReport.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getSearchInvestorEmployeeReport.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data
          ? action.payload._response.data
          : [];
      }
    );
    builder.addCase(
      getSearchInvestorEmployeeReport.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
