import React from "react";
type AmountDisplayProp = {
  amount: number;
  children: any;
};
const AmountDisplay: React.FC<AmountDisplayProp> = ({ amount, children }) => {
  return (
    <div className={`${amount < 0 ? "amount-red" : "amount-green"}`}>
      {children}
    </div>
  );
};

export default AmountDisplay;
