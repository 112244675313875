export interface IGetAllocatedInvestorData {
  stakeTrackingID: number;
  lpPartnerID: number;
  investmentID: number;
  productName: string;
  productCode: string;
  earningsPayoutTiming: string;
  dealHealth: string;
  assetClass: string;
  initialInvAlloc: number;
  maxDiverseAlloc: number;
  maxInvAlloc: number;
  units: number;
  unitPrice: number;
  maxStartDate: string;
  maxStartDateString: string;
  lpPartnerName: string;
  cashBalance: number;
  investmentStartDate: string;
  minDifferenceDate: string;
  maxDifferenceDate: string;
  isEarningDistributed: boolean;
  partnerType: string;
  isReversible: boolean;
}

export interface IGetAllocatedInvestorObject {
  output: IGetAllocatedInvestorData[];
  investmentData?: {
    stakeTrackingId: number;
    unitPrice: number;
    availableUnits: number;
    lastAllocationDate: string;
    investmentStartDate: string;
    message: string;
    totalInvestmentUnits: number;
    equityInvestment: string;
  };
}

export interface IGetAllocatedInvestorListState {
  success: boolean;
  message: string;
  loading: string;
  error: any;
  data: IGetAllocatedInvestorObject;
}

export const initialPayloadGetAllocatedListData = {
  output: [],
  investmentData: undefined,
};
