import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AddGPProfileService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IAddGPProfilesState } from ".";

const initialState: IAddGPProfilesState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: undefined,
};
export const addThunkGPProfile = createAsyncThunk(
  "addGPProfile",
  async (data: any) => {
    try {
      const response = await AddGPProfileService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "addGPProfile",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(addThunkGPProfile.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(addThunkGPProfile.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      if (action.payload._response !== null) {
        state.data = action.payload._response.data;
      }
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(addThunkGPProfile.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
      throw new Error(error(action.error));
    });
  },
});

export default slice.reducer;
