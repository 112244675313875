import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetEarningsEntriesService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IGetEarningsEntriesState } from ".";

const initialState: IGetEarningsEntriesState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    lpAccountTransaction: [],
    invReturn: [],
    unitPriceValue: [],
    invStake: [],
    externalInv: [],
    prod: [],
  },
};
export const getThunkEarningsEntries = createAsyncThunk(
  "getEarningsEntries",
  async (data: any) => {
    try {
      console.log("data", data);
      const response = await GetEarningsEntriesService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getEarningsEntries",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkEarningsEntries.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkEarningsEntries.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("hr", action.payload._response);
      if (action.payload._response.data !== null) {
        state.data = action.payload._response.data;
      }
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(getThunkEarningsEntries.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
      // throw new Error(error(action.error));
    });
  },
});

export default slice.reducer;
