// import React from "react";
// import { MSAL } from "../../../../components/msal/MsalPage";
// import { LOGIN } from "../../../../utils/sponsor/constants";

// const Login = () => {
//   return <MSAL pageName={LOGIN} />;
// };
// export default Login;

import { ProgressSpinner } from "primereact/progressspinner";
import React, { useCallback, useEffect, useState } from "react";
//  useEffect
// import { useCookies } from "react-cookie";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { isFundExistThunk } from "../../../../store/features/fund-exist/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { convertToLowerCase } from "../../../../utils/helper";
import DefaultLandingPage from "../default-page/DefaultLandingPage";
import Logout from "../logout/Logout";
import DefaultLogin from "./DefaultLogin";

interface IProps {
  source?: string;
}
const Login: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const dispatch = useAppDispatch();
  // const [cookies, , removecookies] = useCookies(["tenentName"]);
  // console.log(" Login--------");
  // alert(props.source);
  const location = useLocation();
  const newPathname = location.pathname.replace(/^\/login/, "");
  const isFundExist = useAppSelector((state) => state.isFundExist.data);

  localStorage.removeItem("enrollment");
  localStorage.removeItem("token");

  const [isLoading, setIsLoading] = useState(true);
  const [isThunkCompleted, setIsThunkCompleted] = useState(false);
  // const [showSpinner, setShowSpinner] = useState(false);

  const navigateToDefaultLandingPage = useCallback(() => {
    setIsLoading(true);
    navigate(`${newPathname}`);
  }, [navigate, newPathname]);

  const navigateToDefaultLogin = useCallback(() => {
    setIsLoading(true);
    navigate("/");
  }, [navigate]);

  // useEffect(() => {
  //   const checkLogout = () => {
  //     setShowSpinner(true);
  //     navigateToDefaultLogin();
  //   };

  //   if (localStorage.getItem("tenentName") === "Logout") {
  //     checkLogout();
  //   } else {
  //     setIsLoading(true);
  //     navigateToDefaultLandingPage();
  //   }
  // }, [
  //   navigateToDefaultLogin,
  //   navigateToDefaultLandingPage,
  //   localStorage.getItem("tenentName") === "Logout",
  // ]);

  // localStorage.getItem("tenentName") === "Logout" && navigateToDefaultLogin();

  // console.log("slug  chck", slug);

  useEffect(() => {
    localStorage.setItem("tenentName", convertToLowerCase(slug!));
    localStorage.getItem("tenentName");
    // console.log("call chck");
    if (localStorage.getItem("onlogout") !== "logout") {
      setIsLoading(true);
      setTimeout(() => {
        dispatch(isFundExistThunk("")).then(() => {
          setIsThunkCompleted(true);
          setIsLoading(false);
          // setIsThunkCompleted(true);
        });
      }, 4000);
    }
    // else {
    //   localStorage.removeItem("onlogout");
    // }
  }, []);

  useEffect(() => {
    // console.log("location.pathname", location.pathname);
    if (location.pathname !== "/") {
      // console.log("location. if", isFundExist);
      localStorage.setItem("tenentName", convertToLowerCase(slug!));
      setIsLoading(true);
      navigateToDefaultLandingPage();

      // dispatch(isFundExistThunk());
    } else {
      // console.log("location. else", cookies, setCookie);
      // console.log("else useEffeect =================");
      setIsLoading(true);
      navigateToDefaultLogin();
    }
  }, [location.pathname, isFundExist]);

  // console.log("isLoading", isLoading);
  useEffect(() => {
    if (isThunkCompleted) {
      setIsLoading(false);
    }
  }, [isThunkCompleted]);

  if (localStorage.getItem("onlogout") === "logout") {
    return <Logout />;
  }

  if (isLoading) {
    return <ProgressSpinner />;
  }

  return (
    <>
      {isLoading ? (
        <ProgressSpinner /> // Replace with your desired spinner component
      ) : (
        <>
          {location.pathname !== "/" && isFundExist ? (
            <DefaultLandingPage />
          ) : (
            <DefaultLogin />
          )}
        </>
      )}
    </>
  );
};
export default Login;
