import {
  IAccrediationLetterParameter,
  IGetESignDocuemtParameter,
  IInvestorIDAndLegalEntityID,
  // IGetPersona,
  IInvestorAccountSetup,
  IInvestorProfile,
  ILegalEntityAndPartnerID,
  IUploadAccredPara,
} from "../../types";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function updateInvRegistrationService(data: IInvestorAccountSetup) {
  return apiFactory().put(`InvestingAccount/updateInvRegistration`, data);
}

export function getInvestorAccountService(data: ILegalEntityAndPartnerID) {
  return apiFactory().get(
    `InvestingAccount/getInvestinAccountDetails?InvestorId=${data.investorID}&LegalEntityId=${data.legalEntityID}`
  );
}

export function getInvestorAccountDropdownService() {
  return apiFactory().get(`InvestingAccount/getInvestingAccountSetupDropdown`);
}

export function addInvestorProfileService(data: IInvestorProfile) {
  return apiFactory().post(`InvestingAccount/addInvestorProfileDetails`, data);
}

export function getInvestorProfileDetailService(data: any) {
  return apiFactory().get(
    `InvestingAccount/getInvestorProfileDetails?InvestorId=${data.InvestorId}&RisklevelID=0&DocumentId=0&LegalEntityId=${data.LegalEntityId}`
  );
}

export function getInvRegistrationService(data: ILegalEntityAndPartnerID) {
  return apiFactory().get(
    `InvestingAccount/getInvRegistration?InvestorId=${data.investorID}&LegalEntityId=${data.legalEntityID}`
  );
}

export function getESignDocumentService(data: IGetESignDocuemtParameter) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestingAccount/GetESignDocument?InvestorId=${data.investorID}&LoginId=${
      data.userID
    }&LegalEntityId=${data.legalEntityID}`
  );
}

// export function getPersonaService(data: IGetPersona) {
//   return apiFactory().get(
//     `InvestingAccount/getPersonaImgUrl?investorId=${data.investorID}&inquiryId=${data.inquiryId}&inqStatus=${data.inqStatus}&Type=${data.Type}"`
//   );
// }
export function addPersonaService() {
  return apiFactory().post(
    `InvestingAccount/getInvRegistration?InvestorId="&LegalEntityId="`
  );
}

export function getEmbedUIAcreddLinkService(data: IGetESignDocuemtParameter) {
  return apiFactory().get(
    `InvestingAccount/GetEmbedUIAcredd?InvestorId=${data.investorID}&LegalEntityId=${data.legalEntityID}`
  );
}

export function getAccrediationLetterService(
  data: IAccrediationLetterParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/InvestingAccount/GetAccrediationLetter?ExternalUniqueID=${
      data.ExternalUniqueID
    }&InvestorId=${data.investorID}&LegalEntityId=${data.legalEntityID}`
  );
}

// Not using now
export function getInvAccrediationStatusService(
  data: IGetESignDocuemtParameter
) {
  return apiFactory().get(
    `InvestingAccount/GetInvAccrediationStatusDetails?InvestorId=${data.investorID}&LegalEntityId=${data.legalEntityID}`
  );
}

export function getStatusOfAccrediationLetterService(uniqueLink: string) {
  return apiFactory().get(
    `InvestingAccount/GetStatusOfAccrediationLetter?ExternalUniqueID=${uniqueLink}`
  );
}

export function uploadAcrredFileLink(data: IUploadAccredPara) {
  return apiFactory().post(
    `InvestingAccount/UploadAccredFile?LegalEntityId=${data.legalEntityID}&InvestorId=${data.investorID}`,
    data.filename,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function getRedDotStatusService(data: IInvestorIDAndLegalEntityID) {
  return apiFactory().get(
    `InvestingAccount/GetRedDotStatus?InvestorId=${data.investorID}&LegalEntityId=${data.legalEntityId}`
  );
}

export function getSSNService(data: { InvestorId: number; SSN: string }) {
  return apiFactory().get(
    `InvestingAccount/IsTaxpayerIdExist?SSN=${data.SSN}&InvestorId=${data.InvestorId}`
  );
}

export function getEINService(data: {
  InvestorId: number;
  EIN: string;
  LegalEntityId: number;
}) {
  return apiFactory().get(
    `InvestingAccount/IsEINIdExist?EIN=${data.EIN}&InvestorId=${data.InvestorId}&LegalEntityId=${data.LegalEntityId}`
  );
}
