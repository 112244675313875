const dateFormatter = (dateString: any) => {
  const date = new Date(dateString);
  const dateFormat = date?.toLocaleDateString("localee", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return dateFormat;
};

export default dateFormatter;

// Function to convert UTC time to a local Date object

// Function to convert UTC time to local time
export const convertToLocalTime = (utcDateString: any) => {
  // Create a Date object using the UTC time
  const utcDate = new Date(utcDateString + "z");

  // Convert the Date object to a string in the local timezone
  const localDateString = utcDate.toLocaleString();

  return localDateString;
};

export const dateFormatterModified = (dateString: any) => {
  if (dateString) {
    const date = new Date(dateString);
    const dateFormat = date?.toLocaleDateString("en-us", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    console.log("dateFormatterModified", dateFormat);

    if (dateFormat === "Invalid Date") {
      return "";
    }

    return dateFormat;
  }
  return "";
};
// This function will add the T 00 to the selected date value
export const datewithTime = (utcDate: string | Date) => {
  const localDate = new Date(utcDate);
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, "0");
  const day = String(localDate.getDate()).padStart(2, "0");

  const datewithTimeFormat = `${year}-${month}-${day}T00:00:00`;
  return datewithTimeFormat;
};

export const formatDateMMDDYYY = (utcDate: Date | null) => {
  if (utcDate) {
    // console.log("utcDate formatDateMMDDYYY", typeof utcDate);
    const month = String(utcDate.getMonth() + 1).padStart(2, "0"); // January is 0!
    const day = String(utcDate.getDate()).padStart(2, "0");
    const year = utcDate.getFullYear();
    return `${month}/${day}/${year}`;
    // return new Date(`${month}/${day}/${year}`);
  }
  return "";
};

// Convert Local Date to UTC Date
export const convertLocalDateToUTC = (date: string | Date) => {
  if (date) {
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");

    const utcDate = `${year}-${month}-${day}`;
    return utcDate;
  }

  return date;
};

// export const convertLocalDateToUTC = (date: string | Date) => {
//   const localDate = new Date(date);
//   const utcDateString = localDate.toISOString();
//   return utcDateString;
// };

// Convert UTC Date to Local

export const convertUTCToLocalDate = (date: string | Date, format = "") => {
  if (date) {
    const utcDate = new Date(date);
    // if (format === "mm/dd/yyyy") {
    //   return formatDateMMDDYYY(utcDate);
    // }
    return formatDateMMDDYYY(utcDate);
  }
  return "";
};

export const hasDate = (obj: any) => {
  for (const key in obj) {
    if (typeof obj[key] === "string" && !Number.isNaN(Date.parse(obj[key]))) {
      console.log("true", true);

      const utcDate = new Date(obj[key]);
      const localDateAndTime = utcDate.toLocaleString();
      obj[key] = localDateAndTime;
    }
  }

  return obj;
};

export const getListDateConversion = (data: any, objList: any[]) => {
  // console.log("INNNN", data);
  return [...(data || [])].map((d) => {
    const modifiedDate = {
      ...d,
    };

    for (const field of objList) {
      if (
        d[field] &&
        d[field] !== null &&
        typeof d[field] === "string" &&
        !Number.isNaN(Date.parse(d[field]))
      ) {
        // const value = d[field].toString().includes("T")
        //   ? d[field]
        //   : `${d[field]}T00:00:00`;
        // console.log(d[field], value);
        // modifiedDate[field] = new Date(value);
        modifiedDate[field] = new Date(d[field]);
        // modifiedDate[field] = convertUTCToLocalDate(d[field]);
      }
    }
    // console.log("OUTTT", modifiedDate);
    return modifiedDate;
  });
};
