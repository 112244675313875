import { Button } from "primereact/button";
import React, { useState } from "react";
import { RiAddFill, RiLayoutGridLine, RiListCheck } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../../components/loader/Loader";
import { initialInvestmentState } from "../../../../../store/features/investment/create/payload";
import { setForm } from "../../../../../store/features/investment/create/slice";
// import { getThunkInvestment } from "../../../../../store/features/investment/list/slice";
import { setPageName } from "../../../../../store/features/investment/update/update-page/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import CardComponent from "../../../common-component/card-component/CardComponentContinuous";
import ListComponent from "../../../common-component/list-component/ListComponentContinuous";
// import InvestmentsPageSkeleton from "../InvestmentsPageSkeleton";

export default function Deals(props: any) {
  // const { offering } = props;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [layout, setLayout] = useState("gridLine");
  // const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const stateInvestment = useAppSelector((state) => state.addInvestment);
  const investment = useAppSelector(
    (state) => state.getInvestment.data.productDetails
  );
  const pageUpdateName = useAppSelector((state) => state.updatePage.data);
  const status = useAppSelector((state) => state.getInvestment.loading);
  const pageDetails = useAppSelector(
    (state) => state.getInvestment.data.pageDetails
  );

  // const [windowWidth, setWindowWidth] = useState<any>(window.innerWidth);
  // const breakpointend = 768;
  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  // const [page, setpage] = useState(1);
  // const [supplierId, setsupplierId] = useState();
  // const [city, setcity] = useState();
  // const [stateCode, setstateCode] = useState();
  // const [lookUpCode, setlookUpCode] = useState();
  // const [productId, setproductId] = useState();
  // const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  // const SearchwithFilters = (e: any) => {
  //   e.preventDefault();
  // };

  // useEffect(() => {
  //   if (
  //     fundDropdown.fundid !== undefined &&
  //     fundDropdown.fundid > 0 &&
  //     pageUpdateName.offering !== ""
  //   ) {
  //     const parameter = {
  //       pageNo: 1,
  //       pageSize: 30,
  //       legalEntityId: fundDropdown.fundid,
  //       productState: pageUpdateName.offering,
  //       // supplierId,
  //       // city,
  //       // stateCode,
  //       // lookUpCode,
  //       // productId,
  //     };
  //     const updateBoday = {
  //       ...parameter,
  //       pageNo: page,
  //     };
  //     dispatch(getThunkInvestment(updateBoday));
  //   }
  // }, [page, fundDropdown, pageUpdateName.offering]);
  // useEffect(() => {
  //   setpage(1);
  // }, [fundDropdown, pageUpdateName.offering]);

  const getNextPage = (e: any) => {
    e.preventDefault();
    props.setpage((prevState: number) => prevState + 1);
  };

  const getPrevPage = (e: any) => {
    e.preventDefault();
    props.setpage((prevState: number) => prevState - 1);
  };

  const handleAddClick = (e: any) => {
    e.preventDefault();
    dispatch(setPageName({ ...pageUpdateName, createPage: true }));
    dispatch(setForm({ ...stateInvestment.data, ...initialInvestmentState }));

    navigate("/Continuous/NewInvestment");
  };
  const listClass =
    layout === "listgrid"
      ? "xl:col-12  p-0 pr-3 sm:col-12"
      : "grid xl:col-12 p-0 sm:col-12";

  let content;
  switch (status) {
    case "loading":
      content = <Loader />;
      break;
    case "succeeded":
      content =
        layout === "listgrid" ? (
          <>
            <ListComponent
              products={investment}
              layout={layout}
              stateOffering={pageUpdateName.offering}
            />
          </>
        ) : (
          investment!.map((product: any) => {
            return (
              <CardComponent
                p={product}
                key={product.productID}
                layout={layout}
              />
            );
          })
        );

      break;
    // case "failed":
    //   content = <></>;
    //   break;
  }
  return (
    <>
      <div className="col-12 pt-2 p-0">
        <div className="flex flex-row flex-wrap sm:flex-none col-12 pl-0">
          <div className="d-flex flex-column p-0 xl:col-7 sm:col-7 flex justify-content-center">
            {pageUpdateName.offering === "SoftCommit" ? (
              <h2 className="m-0">Deals List:</h2>
            ) : (
              <h2 className="m-0">Investments List:</h2>
            )}
          </div>
          <div className="d-flex flex-column p-0 pr-2 xl:col-5 sm:col-5">
            <div className="flex justify-content-end ">
              <Button
                // disabled={page === totalPages}
                onClick={() => {
                  setLayout("listgrid");
                }}
                // className="btnWrap m-3"
                className={
                  layout === "listgrid"
                    ? "btn-navActive p-2 squareBtn m-2 "
                    : "btn-nav p-2 squareBtn p-2 m-2 "
                }
              >
                <RiListCheck className="iconInvestment" />
              </Button>
              {/* </div>
          <div className="d-flex flex-column p-2 xl:col-1 sm:col-2"> */}
              <Button
                // disabled={page === totalPages}
                onClick={() => setLayout("gridLine")}
                // className="btnWrap m-3"
                // autoFocus
                className={
                  layout === "gridLine"
                    ? "btn-navActive squareBtn p-2 m-2"
                    : "btn-nav squareBtn p-2 m-2"
                }
              >
                <RiLayoutGridLine className="iconInvestment" />
              </Button>
              {/* </div>
          <div className="d-flex flex-column p-2  xl:col-2 sm:col-1"> */}
              <Button
                className="btn-navActive mr-0 m-2"
                // type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleAddClick(e);
                }}
              >
                {pageUpdateName.offering === "SoftCommit" ? (
                  <span className="ml-auto mr-auto ">
                    {" "}
                    Create New Deal
                    <span className="pl-2">
                      <RiAddFill />
                    </span>
                  </span>
                ) : (
                  <span className="ml-auto mr-auto ">
                    Create New Investment
                    <span className="pl-1">
                      <RiAddFill />
                    </span>
                  </span>
                )}
              </Button>
            </div>
          </div>
        </div>

        <div
          className={listClass}
          // style={{ overflowY: "auto", height: "600px" }}
        >
          {content}
          {status === "succeeded" && pageDetails!.totalPages === 0 && (
            <div className="row col-12 pt-4 ">
              {/* <div className="col-sm-6"></div> */}

              <div className="col-sm-6 d-flex">
                <div className=" mt-3 p-0 pagination ">
                  <h3>No Data Found</h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="text-center col-12">
        {" "}
        {status === "succeeded" && pageDetails!.totalPages > 0 && (
          <div className="row ">
            <div className="col-sm-4"></div>

            <div className="col-sm-4 d-flex justify-content-center">
              <div className="p-0 pagination ">
                <Button
                  onClick={getPrevPage}
                  disabled={props.page === 1}
                  className="BtnBlue"
                  autoFocus={false}
                >
                  Prev Page
                </Button>
                <div className="page-item active">
                  <li className=" page-item BtnBlue rounded ">{props.page}</li>
                </div>
                <Button
                  disabled={props.page === pageDetails?.totalPages}
                  onClick={getNextPage}
                  className="BtnBlue float-right"
                  autoFocus={false}
                >
                  Next Page
                </Button>
              </div>
            </div>

            <div className="col-sm-4 text-end">
              <li className="page-item active BtnBlue rounded">
                {pageDetails!.totalPages > 0 ? (
                  <div>
                    {" "}
                    <span>{props.page}</span>
                    <span className="ml-1">of </span>
                    <span>{pageDetails!.totalPages}</span>
                  </div>
                ) : (
                  <></>
                )}{" "}
              </li>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
