export interface IGetAllocationObject {
  lpPartnerId: number;
  name: string;
  softCommit_Amount: number;
  cashBalance: number;
}
export interface IGetInvestorAllocationData {
  output: IGetAllocationObject[];
  investmentData?: {
    stakeTrackingId: number;
    unitPrice: number;
    availableUnits: number;
    lastAllocationDate: string;
    investmentStartDate: string;
    message: string;
    totalInvestmentUnits: number;
    equityInvestment: string;
    isEarningDistributed: boolean;
  };
}

export interface IGetAllInvestorAllocationState {
  success: boolean;
  message: string;
  loading: string;
  error: any;
  data: IGetInvestorAllocationData;
}
export const initialPayloadGetState = {
  output: [],
  investmentData: undefined,
};
