// import { IInvestmentData } from "../../store/features/investment/create";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function addInvestmentService(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_INVESTMENT_URL}/Investments/CreateInvestment`,
    data
  );
}

export function getPrivateFileService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/getPrivateDocuments?legalEntityID=${
      data.legalEntityID
    }&folderName=${data.folderName}`
  );
}

export function getPublicFileService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/getSharedFilesOnDocType?doctypeId=${data.doctypeId}`
  );
}

export function getPrimaryFolderFileManager() {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/GetMasterDirectory`
  );
}

export function GetFoldersFiles(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/GetFoldersFiles?LegalEntityId=${
      data?.legalEntityId
    }&TypeId=${data?.typeId}&IsShared=${data?.IsShared}&FolderId=${
      data?.folderId
    }`
  );
}

export function GetFolderTreeFM(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/GetFolderTreeFM?LegalEntityId=${
      data?.legalEntityId
    }&TypeId=${data?.typeId}&IsShared=${data?.isShared}&TransferType=${
      data?.transferType
    }&ExcludeFolderId=${data?.excludeFolderId}&IsFile=${data?.IsFile}`
  );
}

export function SaveFolderTransfer(data: any) {
  const queryString = new URLSearchParams({
    NewFolderId: data.NewFolderId,
    LegalEntityId: data.LegalEntityId,
    TypeId: data.TypeId,
    TransferType: data.TransferType,
    IsShared: data.IsShared,
    FolderId: data.FolderId,
    FileId: data.FileId,
    Rename: data.Rename,
  });
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/SaveFolderTransfer?${queryString}`
  );
}

export function getFolderDetailsByParentFolderIdFileManager(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/GetFoldersByParentFolderID?ParentFolderID=${data}`
  );
}
export function getFolderDetailsByFolderIdFileManager(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/GetFolderDetailsByFolderId?FolderID=${data}`
  );
}

export function getContainerNameByLegalEntityIDFileManager(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/getContainerNameByFund?legalEntityID=${data.fundid}`
  );
}
export function getFilesByFolderIDFileManager(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/GetFileByFolderIdFileManger?FolderID=${data}`
  );
}
export function deleteBlobFileManager(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/DeleteBlobByUrl?blobURL=${data}`
  );
}
export function deleteFolderFile(data: any) {
  const queryString = new URLSearchParams({
    FolderId: data.FolderId,
    FileId: data.FileId,
    legalEntityId: data.legalEntityId,
    TypeId: data.typeId,
    IsLink: data.IsLink,
  });
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/DeleteFolderFileFM?${queryString}`
  );
}
export function createFolderFilemanager(data: any) {
  const queryString = new URLSearchParams({
    TypeId: data.TypeId,
    LegalEntityId: data.LegalEntityId,
    NewFolderName: data.NewFolderName,
    ParentFolderID: data.ParentFolderID,
    Description: data.Description,
    IsShared: data.IsShared,
  });
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/CreateFolderFM?${queryString}`
  );
}

export function deleteDirectoryInsideContainer(data: any) {
  const queryString = new URLSearchParams({
    folderID: data.folderID,
    folderPath: data.folderPath,
    containerName: data.containerName,
  });
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/DeleteDirectoryInsideContainer?${queryString}`
  );
}
export function EditFolderFileByIdFileManger(data: any) {
  const queryString = new URLSearchParams(data);
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FileManager_URL
    }/FileManagement/EditFolderByFolderIdFM?${queryString}`
  );
}

export function uploadFileFilemanager(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_FileManager_URL}/FileManagement/uploadfileFM`,
    data
  );
}

// section
