import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getConsolidatedplatformService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";
import { IConsolidatedPlatformReportState } from ".";

const initialState: IConsolidatedPlatformReportState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const getConsolidateplatfomsCommits = createAsyncThunk(
  "consolidateplatfoms",
  async (userName: any) => {
    try {
      const response = await getConsolidatedplatformService(userName);
      console.log("res", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "consolidateplatfoms",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getConsolidateplatfomsCommits.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getConsolidateplatfomsCommits.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload._response.data;
      }
    );
    builder.addCase(getConsolidateplatfomsCommits.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
