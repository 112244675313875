import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";

import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { RiArrowDropRightFill } from "react-icons/ri";

import Loader from "../../../../components/loader/Loader";
import { GetProdductListThunk } from "../../../../store/features/taxmanagement/getpartnerprodatabyinvestment/getproductlist/slice";
import { getThunkK1InputSupplierLabel } from "../../../../store/features/taxmanagement/k1-input-supplier/get-label-form/slice";
import { addThunkK1InputSupplierForm } from "../../../../store/features/taxmanagement/k1-input-supplier/save-form/slice";
import { getThunkUploadK1RevisionDropdown } from "../../../../store/features/taxmanagement/upload-investork1/revision-dropdown/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import "./K1InputSupplier.scss";
import {
  convertLocalDateToUTC,
  // convertUTCToLocalDate,
  formatDateMMDDYYY,
} from "../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";
import K1InputFormPart1 from "./k1-form-parts/K1InputFormPart1";
import K1InputFormPart2 from "./k1-form-parts/K1InputFormPart2";
import K1InputFormPart3 from "./k1-form-parts/K1InputFormPart3";

// import { generateObj } from "./k1-input";

export default function K1InputSupplier() {
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const previousYear: any = new Date().getFullYear();
  const currentYear = previousYear - 1;
  const [loading, setLoading] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState("");
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const revisionDropdown = useAppSelector(
    (state) => state.revisionDropdown.data
  );

  const equityInvestmentList = useAppSelector(
    (state) => state.GetProdductList?.data
  );
  const k1InputLabels = useAppSelector(
    (state) => state.getK1InputSupplierLabel.data.k1LablesList
  );
  // const isEditFlag = useAppSelector(
  //   (state) => state.getK1InputSupplierLabel.data.isEditFlag[0]
  // );
  const [K1Form, setK1Form] = useState<any>([]);
  const [labelData, setLabelData] = useState<any>({
    part1: [],
    part2: [],
    part3: [],
  });
  const dataSeparation = () => {
    const part1List = [];
    const part2List = [];
    const part3List = [];
    for (const item of K1Form) {
      if (item.boxName === "PART I") {
        part1List.push(item!);
      }
      if (item.boxName === "PART II") {
        part2List.push(item!);
      }
      if (item.boxName === "PART III") {
        part3List.push(item!);
      }
    }

    setLabelData({
      ...labelData,
      part1: part1List,
      part2: part2List,
      part3: part3List,
    });
  };
  const formik = useFormik({
    initialValues: {
      year: currentYear.toString(),
      revisionId: 0,
      equityInvestmentId: 0,
    },
    validate: (data) => {
      const errors: any = {};
      if (!data.equityInvestmentId) {
        errors.equityInvestmentId = " Select equity investment";
      }
      if (!data.year) {
        errors.year = "Year is required";
      }

      if (!data.revisionId) {
        errors.revisionId = "Select revision";
      }

      return errors;
    },
    onSubmit: (data) => {
      const parameters = {
        legalEntityId: fundDropdown.fundid,
        taxYear: formik.values.year,
        equityInvestmentId: formik.values.equityInvestmentId,
        revisionId: formik.values.revisionId,
      };
      setLoading(true);
      dispatch(getThunkK1InputSupplierLabel(parameters))
        .then((response) => {
          if (response.payload !== undefined) {
            setLoading(false);
            // setK1FormCopy(response.payload._response.data);
          }
        })
        .catch(() => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const formikForm = useFormik({
    initialValues: {
      beginningHead: "",
      endingHead: "",
    },
    validate: (dataForm) => {
      const errors: any = {};
      if (!dataForm.beginningHead) {
        errors.beginningHead = " Select Beginning Date";
      }
      if (!dataForm.endingHead) {
        errors.endingHead = "Select Ending Date";
      }

      return errors;
    },
    onSubmit: (data) => {
      setLoading(true);
      formikForm.errors = {};
      const revisionName = revisionDropdown?.find(
        (revision) => revision.key === formik.values.revisionId
      );

      const parameters = {
        headerId: labelData.part1[0].headId || 0,
        legalEntityId: fundDropdown.fundid,
        year: formik.values.year.toString(),
        equityProductId: formik.values.equityInvestmentId,
        finalAmended: formik.values.revisionId,
        revisionText: revisionName?.value,
        beginning: convertLocalDateToUTC(formikForm.values.beginningHead),
        ending: convertLocalDateToUTC(formikForm.values.endingHead),
        k1ProductChildDetails: K1Form,
      };
      dispatch(addThunkK1InputSupplierForm(parameters))
        .then((response) => {
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (response.meta.requestStatus === "fulfilled") {
            successToastMessage(toast, "Data saved successfully");
            const getParameters = {
              legalEntityId: fundDropdown.fundid,
              taxYear: formik.values.year,
              equityInvestmentId: formik.values.equityInvestmentId,
              revisionId: formik.values.revisionId,
            };
            setLoading(true);
            dispatch(getThunkK1InputSupplierLabel(getParameters));
          }
          setLoading(false);
        })
        .catch((error: any) => {
          console.log("error", error);
          errorToastMessage(toast);
          setLoading(false);
        });
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const formikFormTouched: any = formikForm.touched;
  const formikFormErrors: any = formikForm.errors;
  const isFormFieldInvalid2 = (name: any) =>
    !!(formikFormTouched[name] && formikFormErrors[name]);
  const getFormErrorMessage2 = (name: any) => {
    return isFormFieldInvalid2(name) ? (
      <small className="p-error">{formikFormErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const errorMessage = () => {
    (formikForm.values.beginningHead === null ||
      formikForm.values.beginningHead === "") &&
    (formikForm.values.endingHead === null ||
      formikForm.values.endingHead === "")
      ? setErrorMessageText("Error occured. Please see above.")
      : setErrorMessageText("");
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "year") {
      if (value !== null) {
        const onlyYear = value.getFullYear();

        formik.setFieldValue(name, onlyYear);
      }
    } else {
      formik.setFieldValue(name, value);
    }
  };
  const handleHeadDateChange = (e: any) => {
    const { name, value } = e.target;
    // const dateFormat = value.toLocaleDateString("en-us", {
    //   day: "2-digit",
    //   month: "2-digit",
    //   year: "numeric",
    // });
    const dateFormat = formatDateMMDDYYY(value);
    formikForm.setFieldValue(name, dateFormat);
  };

  useEffect(() => {
    setLoading(true);
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameters = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetProdductListThunk(parameters)).finally(() => {
        setLoading(false);
      });
      dispatch(getThunkUploadK1RevisionDropdown());
    }
    formik.resetForm();
  }, [fundDropdown]);
  useEffect(() => {
    if (k1InputLabels.length > 0) {
      // dataSeparation();
      formikForm.setFieldValue("beginningHead", k1InputLabels[0].beginningDate);
      formikForm.setFieldValue("endingHead", k1InputLabels[0].endingDate);
      setK1Form(k1InputLabels);
    }
  }, [k1InputLabels]);
  useEffect(() => {
    if (K1Form.length > 0) {
      dataSeparation();
    }
  }, [K1Form]);

  useEffect(() => {
    if (formik.values.year !== "") {
      formik.setFieldValue("equityInvestmentId", 0);
    }
  }, [fundDropdown.fundid]);

  useEffect(() => {
    formikForm.resetForm();
    setErrorMessageText("");
    setK1Form([]);
    setLabelData({
      part1: [],
      part2: [],
      part3: [],
    });
  }, [fundDropdown.fundid, formik.values]);

  console.log("testing--------------------", labelData.part2[31]?.textValue);
  const handleTaxFormChange = (
    e: any,
    generatedRank?: any,
    objField?: any,
    boxNameData?: any,
    checkBoxData?: any,
    dateData?: any,
    idData?: any
  ) => {
    const { name, value } = e.target;

    let dateFormat: any;
    // if (name === "beginningN" || name === "endingN") {
    //   dateFormat = formatDateMMDDYYY(value);
    // }

    if (name === "beginningN" || name === "endingN") {
      //  dateFormat = formatDateMMDDYYY(value);
      dateFormat = convertLocalDateToUTC(value);
    }
    // eslint-disable-next-line unicorn/no-null
    const newValue = value === "" ? null : value;
    setK1Form(
      [...K1Form].map((obj) => {
        return obj.generatedRank === generatedRank &&
          obj.boxName === boxNameData
          ? checkBoxData
            ? {
                ...obj,
                [objField]: checkBoxData,
              }
            : name === "beginningN" || name === "endingN"
            ? {
                ...obj,
                [objField]: dateFormat,
              }
            : {
                ...obj,
                [objField]: newValue,
              }
          : obj;
      })
    );
  };
  const scrollDisable = (e: any) => {
    e.target.addEventListener(
      "wheel",
      function (event: any) {
        event.preventDefault();
      },
      { passive: false }
    );
  };
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">K-1: Input K-1 Forms from Suppliers</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
        <div className="grid col-12">
          <div className="col-12">
            <div className="card my-3 tabWithoutboxshadow">
              <Card>
                <>
                  <div className="mb-2 mt-2">
                    <div className="formgrid grid px-2">
                      <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
                        <label className="inputLabel">Equity Investment*</label>
                        <Dropdown
                          inputId="equityInvestmentId"
                          name="equityInvestmentId"
                          value={
                            formik.values.equityInvestmentId === 0
                              ? ""
                              : formik.values.equityInvestmentId
                          }
                          options={
                            equityInvestmentList === null
                              ? []
                              : equityInvestmentList
                          }
                          filter
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select equity investment"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={classNames({
                            "p-invalid":
                              isFormFieldInvalid("equityInvestmentId"),
                            "card-inputBox": true,
                          })}
                        />
                        {getFormErrorMessage("equityInvestmentId")}
                      </div>
                      <div className="d-flex flex-column p-2 col-12 sm:col-3">
                        <label className="inputLabel">Year *</label>

                        <Calendar
                          name="year"
                          value={
                            formik.values.year === null ||
                            formik.values.year === ""
                              ? undefined
                              : new Date(+formik.values.year, 0, 1)
                          }
                          onChange={(e) => handleChange(e)}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("year"),
                            "card-inputBox": true,
                          })}
                          view="year"
                          dateFormat="yy"
                          placeholder="Select year"
                          readOnlyInput
                        />
                        {getFormErrorMessage("year")}
                      </div>
                      <div className="d-flex flex-column p-2 col-12 sm:col-3">
                        <label className="inputLabel">Revision *</label>
                        <Dropdown
                          inputId="revisionId"
                          name="revisionId"
                          value={
                            formik.values.revisionId === 0
                              ? ""
                              : formik.values.revisionId
                          }
                          options={
                            revisionDropdown === null ? [] : revisionDropdown
                          }
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select revision"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("revisionId"),
                            "card-inputBox": true,
                          })}
                        />
                        {getFormErrorMessage("revisionId")}
                      </div>
                    </div>
                    <div className="formgrid grid px-2"></div>
                  </div>
                  <div className="d-flex justify-content-center  mt-2 mb-3">
                    <Button
                      type="submit"
                      className="btn-nav w-7rem justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                      }}
                    >
                      Show
                    </Button>
                  </div>
                </>
              </Card>
            </div>
            {k1InputLabels.length > 0 && labelData.part1.length > 0 ? (
              <Card>
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                    <div className="col-12 flex flex-column sm:flex-row">
                      <div className=" p-2 xl:col-6 sm:col-12">
                        <h1>Schedule K-1 (Form 1065)</h1>
                        <div>
                          Department of the Treasury Internal Revenue Service
                        </div>
                      </div>
                      <div className="p-2 xl:col-6 sm:col-12 flex flex-column align-items-end justify-content-center">
                        <h6>{formik.values.year}</h6>
                        <div className="pt-6">
                          For calendar year {formik.values.year}, or tax year
                        </div>
                      </div>
                    </div>
                    {/* Beginning and Ending head */}
                    <div className="col-12 flex flex-column sm:flex-row">
                      <div className=" p-2 xl:col-6 sm:col-12">
                        <label className="inputLabel">Beginning</label>
                        <Calendar
                          id="beginningHead"
                          name="beginningHead"
                          value={
                            formikForm.values.beginningHead === null ||
                            formikForm.values.beginningHead === ""
                              ? undefined
                              : new Date(formikForm.values.beginningHead)
                          }
                          onChange={(e) => handleHeadDateChange(e)}
                          className="inputK1Form"
                          placeholder="mm/dd/yyyy"
                          readOnlyInput
                          style={{ border: "0" }}
                        />
                        {getFormErrorMessage2("beginningHead")}
                      </div>
                      <div className=" p-2 xl:col-6 sm:col-12">
                        <label className="inputLabel">Ending</label>
                        <Calendar
                          id="endingHead"
                          name="endingHead"
                          value={
                            formikForm.values.endingHead === null ||
                            formikForm.values.endingHead === ""
                              ? undefined
                              : new Date(formikForm.values.endingHead)
                          }
                          onChange={(e) => handleHeadDateChange(e)}
                          className="inputK1Form"
                          placeholder="mm/dd/yyyy"
                          readOnlyInput
                        />
                        {getFormErrorMessage2("endingHead")}
                      </div>
                    </div>
                    <div className="col-12 flex flex-column sm:flex-row">
                      <div className="inputLabel p-2">
                        Partner’s Share of Income, Deductions, Credits, etc.
                      </div>
                    </div>
                    <div className="col-12 flex flex-column sm:flex-row">
                      <div className=" p-2">
                        <RiArrowDropRightFill className="icon-k1form" />
                        See back of form and separate instructions.
                      </div>
                    </div>

                    <div className="col-12 ">
                      {/* Part I Form */}

                      <K1InputFormPart1
                        labelData={labelData}
                        handleTaxFormChange={handleTaxFormChange}
                        k1InputLabels={k1InputLabels}
                      />

                      {/* Part 2 Form */}

                      <K1InputFormPart2
                        labelData={labelData}
                        handleTaxFormChange={handleTaxFormChange}
                        k1InputLabels={k1InputLabels}
                        scrollDisable={scrollDisable}
                      />
                    </div>
                  </div>
                  {/* Part III form */}
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                    <K1InputFormPart3
                      labelData={labelData}
                      handleTaxFormChange={handleTaxFormChange}
                      k1InputLabels={k1InputLabels}
                      scrollDisable={scrollDisable}
                    />
                    {/* {isEditFlag.isEdit === true ? ( */}
                    <div className="flex flex-column">
                      <div className="d-flex justify-content-center  mt-3 mb-3 ">
                        <Button
                          type="submit"
                          className="btn-nav w-7rem justify-content-center"
                          // disabled={isEditFlag.isEdit === false}
                          onClick={(e) => {
                            e.preventDefault();
                            errorMessage();
                            formikForm.handleSubmit();
                          }}
                        >
                          Save
                        </Button>
                      </div>
                      <div className="d-flex justify-content-center">
                        <small className="p-error">{errorMessageText}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
