import { Button } from "primereact/button";
// import { Card } from "primereact/card";
// import { Chip } from "primereact/chip";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
// import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
// import { RiSearchLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import {
  GetfundListThunk,
  deletefundListThunk,
} from "../../../../store/dwolla/slice";
import { GetDwollaTokenThunk } from "../../../../store/dwolla/token/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { exportExcel } from "../../../../utils/excel-dataformat";

export default function SetupInvestorDwollaBank() {
  const dispatch = useAppDispatch();
  const portState = useAppSelector(
    (state) => state.dwollasetupFundentity.loading
  );
  // const [viewConfirm, setViewConfirm] = useState("");
  const fundlist = useAppSelector((state) => state.dwollasetupFundentity.data);
  const [visible, setVisible] = useState(false);
  const [helpvisible, setHelpVisible] = useState(false);
  const [openDailog, setOpenDailog] = useState(false);

  const navigate = useNavigate();
  const profileData = useAppSelector((state) => state.profile.data![0]);
  function confirmDeletefund(id: any) {
    const parameters = {
      invOREntityID: id!,
      dwollaOwnerId: "",
      dwollaFundId: "",
      type: "IndividualInv",
      createdBy: profileData?.userID,
    };
    dispatch(deletefundListThunk(parameters));
  }

  const Deletefund = (data: any) => {
    confirmDialog({
      message: "Are you sure you want to delete?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => confirmDeletefund(data.investorID),
    });
  };
  const Editfund = (data: any) => {
    navigate(`/viewSetupInvestorBank/${data.investorID}/${data.lpPartnerID}`);
  };

  const ViewStatus = (id: any) => {
    setVisible(true);

    console.log(id);
  };

  const actionBodyEditTemplate = (rowData: any) => {
    return rowData?.dwollaStatus === "verified" ? (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className=" p-button-rounded p-button-text iconButton"
          onClick={() => Editfund(rowData)}
          type="button"
        />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Button
          icon="pi pi-plus"
          className=" p-button-rounded p-button-text iconButton"
          onClick={() => Editfund(rowData)}
          type="button"
        />
      </React.Fragment>
    );
  };

  const actionBodysubTemplate = (rowData: any) => {
    console.log(rowData);
    return rowData?.dwollaStatus === "verify-with-document" ? (
      <React.Fragment>
        <Button
          icon="pi pi-info-circle"
          className="p-button-rounded p-button-text  iconButton"
          // severity="danger"
          onClick={(e) => {
            e.preventDefault();
            ViewStatus(rowData);
          }}
        />
      </React.Fragment>
    ) : (
      <></>
    );
  };

  const getIcon = (value: any) => {
    switch (value) {
      case "verified":
        return "pi pi-check-circle";

      case "LOWSTOCK":
        return "warning";

      case "OUTOFSTOCK":
        return "danger";

      default:
        return "pi pi-times-circle";
    }
  };

  const getSeverity = (value: any) => {
    switch (value) {
      case "verified":
        return "success";

      case "LOWSTOCK":
        return "warning";

      case "OUTOFSTOCK":
        return "danger";

      default:
        return "warning";
    }
  };
  const actionSetBodyStatus = (rowData: any) => {
    if (rowData?.dwollaStatus !== null) {
      return (
        <React.Fragment>
          <div className="col-12 flex flex-wrap">
            <div className="col-10">
              <div className="card flex flex-wrap gap-2">
                {/* <Chip label={rowData.dwollaStatus} icon="pi pi-check-circle" /> */}
                <Tag
                  value={rowData.dwollaStatus}
                  severity={getSeverity(rowData.dwollaStatus)}
                  icon={getIcon(rowData.dwollaStatus)}
                ></Tag>
              </div>
            </div>
            <div className="col-2">{actionBodysubTemplate(rowData)}</div>
          </div>
        </React.Fragment>
      );
    }

    //   return rowData?.dwollaStatus === "verified" ? (
    //     <React.Fragment>
    //       <div className="card flex flex-wrap gap-2">
    //         {/* <Chip label={rowData.dwollaStatus} icon="pi pi-check-circle" /> */}
    //         <Tag
    //           value={rowData.dwollaStatus}
    //           severity={getSeverity(rowData.dwollaStatus)}
    //         ></Tag>
    //         ;
    //       </div>
    //     </React.Fragment>
    //   ) : (
    //     <React.Fragment>
    //       <div className="card flex flex-wrap gap-2">
    //         <Chip label={rowData.dwollaStatus} icon="pi pi-times" />
    //       </div>
    //     </React.Fragment>
    //   );
    // } else {
    //   return <></>;
    // }
  };

  const actionBodyDeleteTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text  iconButton"
          // severity="danger"
          onClick={(e) => {
            e.preventDefault();
            Deletefund(rowData);
          }}
        />
      </React.Fragment>
    );
  };

  const dataTablehelpElements = [
    {
      field: "EventTopicName",
      header: "Last Action",
    },
    {
      field: "Description",
      header: "Description",
    },
  ];

  const Helpfunddata = [
    {
      EventTopicName: "Customer created",
      Description: "A Customer was created",
    },
    {
      EventTopicName: "Customer KBA verification needed",
      Description:
        "The retry identity verification attempt failed due insufficient scores on the submitted data. The end user will have a single kba attempt to answer a set of “out of wallet” questions about themselves for identity verification.",
    },
    {
      EventTopicName: "Customer KBA verification failed",
      Description:
        "The end user failed KBA verification and was unable to correctly answer at least three KBA questions.",
    },
    {
      EventTopicName: "Customer KBA verification passed",
      Description:
        "The end user was able to correctly answer at least three KBA questions.",
    },
    {
      EventTopicName: "Customer reverification needed",
      Description:
        "Incomplete information was received for a Customer; updated information is needed to verify the Customer..",
    },
    {
      EventTopicName: "Customer verification document needed",
      Description: "Additional documentation is needed to verify a Customer.",
    },
    {
      EventTopicName: "Customer verification document uploaded",
      Description: "A verification document was uploaded for a Customer.",
    },
    {
      EventTopicName: "Customer verification document failed",
      Description: "A verification document was rejected for a Customer.",
    },
    {
      EventTopicName: "Customer verification document approved",
      Description: "A verification document was approved for a Customer.",
    },
    {
      EventTopicName: "Customer verified",
      Description: "A Customer was verified.",
    },
  ];
  const dataTableElements = [
    {
      field: "customerName",
      header: "Customer Name ",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.customerName}>
          {rowData?.customerName}
        </div>
      ),
    },
    {
      field: "dwollaStatus",
      header: "Status",
      body: actionSetBodyStatus,
      style: { minWidth: "180px" },
    },
    {
      header: "Last Action",
      // body: actionBodysubTemplate,
      field: "lastAction",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.lastAction}>
          {rowData?.lastAction}
        </div>
      ),
    },
    {
      header: "View/Create",
      body: actionBodyEditTemplate,
    },
    {
      header: "Delete",
      body: actionBodyDeleteTemplate,
    },
  ];
  const excelTableHeaders = [
    {
      field: "customerName",
      header: "Fund Name",
    },
    {
      field: "dwollaStatus",
      header: "Status",
    },
    {
      header: "Last Action",
      field: "lastAction",
    },
    {
      field: "investorID",
      header: "Investor ID",
    },
  ];

  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  useEffect(() => {
    if (dropdown.fundid !== undefined && dropdown.fundid > 0) {
      const parameters = {
        LegalEntityId: dropdown.fundid,
        Input: "I",
      };
      dispatch(GetfundListThunk(parameters));
      dispatch(GetDwollaTokenThunk());
    }
  }, [dropdown.fundid]);
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  // const rowDta = 10;

  let content;
  switch (portState) {
    case "loading":
      content = <Loader />;
      break;
    case "succeeded":
      content = (
        <>
          <ConfirmDialog />
          <Dialog
            header="Fund Status"
            visible={visible}
            style={{ width: "50vw" }}
            onHide={() => setVisible(false)}
          >
            <p className="m-0">
              Controller identification (id, license, passport) document is
              required to complete verification. Please upload an identity
              document. Documents can take up to 2 business days to be verified.
            </p>
          </Dialog>
          <Dialog
            header="Fund Last Action Info"
            visible={helpvisible}
            style={{ width: "50vw" }}
            onHide={() => setHelpVisible(false)}
          >
            <DataTableComponent
              valueData={Helpfunddata!}
              fieldsElements={dataTablehelpElements}
              isPaginationAllowed={true}
            />
          </Dialog>

          <div className="grid col-12 p-0">
            <div className="col-12 md:col-9  pl-1 p-0">
              <h1 className="pageHeader fs-2">
                {" "}
                Setup Investor Bank Account Information
              </h1>
              <p className="pageSubHeader"> </p>
            </div>
            <div className="flex justify-content-end gap-2 md:col-3">
              <div className="flex align-items-center gap-2 mb-2">
                <Button
                  className="p-button btn-dialog"
                  type="button"
                  // icon="pi pi-file"
                  // rounded
                  onClick={(e) => {
                    e.preventDefault();
                    setHelpVisible(true);
                  }}
                  style={{ float: "right" }}
                  data-pr-tooltip="Help"
                >
                  {" "}
                  <span className="p-button-help">Help </span>
                </Button>
              </div>
              <div className="flex align-items-center gap-2 mb-2">
                <Button
                  className="remove-shadow"
                  type="button"
                  // icon="pi pi-file"
                  onClick={() =>
                    exportExcel(
                      excelTableHeaders,
                      fundlist || [],
                      "Setup Investor Bank Account Information",
                      true
                    )
                  }
                  data-pr-tooltip="exportExcel"
                  disabled={
                    fundlist === undefined ||
                    fundlist?.length === 0 ||
                    fundlist === null
                  }
                >
                  {" "}
                  <span className="blueButtonShadowText">Download </span>
                </Button>
              </div>
              <div className="flex align-items-center gap-2 mb-2">
                <Button
                  className="btn-navActive"
                  type="button"
                  icon="pi pi-window-maximize"
                  disabled={
                    fundlist === undefined ||
                    fundlist?.length === 0 ||
                    fundlist === null
                  }
                  onClick={() => setOpenDailog(true)}
                  title="View Full Screen setuppages"
                />
              </div>
            </div>
          </div>

          {/* <div className="grid">
            <div className="col-12"> */}
          <div className="card">
            {/* <Card> */}
            {/* <h4 className="cardHeader">
              Setup Investor Bank Account Information
              <Button
                className="p-button btn-dialog"
                type="button"
                // icon="pi pi-file"
                // rounded
                onClick={(e) => {
                  e.preventDefault();
                  setHelpVisible(true);
                }}
                style={{ float: "right" }}
                data-pr-tooltip="Help"
              >
                {" "}
                <span className="p-button-help">Help </span>
              </Button>
            </h4> */}
            {/* <div className="row my-4 d-flex justify-content-between"> </div> */}
            <DataTableComponent
              valueData={fundlist!}
              fieldsElements={dataTableElements}
              // isDownload={true}
              openDailog={openDailog}
              setOpenDailog={setOpenDailog}
              className="gridcell setuppages"
              isPaginationAllowed={true}
              columnResizeMode="expand"
              resizableColumns={true}
              scrollable={true}
              maxHeight="calc(-145px + 100vh)"
            />
            {/* </Card> */}
          </div>
          {/* </div>
          </div> */}
        </>
      );
      break;
  }
  return <>{content}</>;
}
