import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import DataTableComponent from "../../../../../components/data-table/DataTableComponent";
import { setAllocationSliceInvestorList } from "../../../../../store/features/operation/allocation-slices/add-investors-allocation-state/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  // convertLocalDateToUTC,
  datewithTime,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../../utils/date-formatter";
import { errorToastMessage } from "../../../../../utils/toast-message";

interface IProps {
  handleNext: any;
  handlePrevious: any;
}
export default function AllocationStep2(props: IProps) {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [errorState, setErrorState] = useState<any>();
  const [hasError, setHasError] = useState<boolean>();
  const allocationSliceInvestorList = useAppSelector(
    (state) => state.allocationSliceInvestorState.data
  );

  const [investorListData, setInvestorListData] = useState<any[]>([]);
  const allocationInvestorData = useAppSelector(
    (state) => state.getAllInvestorAllocationData.data
  );

  const dateTemplate = (date: any) => {
    if (date.selectable) {
      return <span className="dateHighlight">{date.day}</span>;
    }

    return date.day;
  };
  // edit the column in grid
  const editDates = (rowData: any) => {
    let selectedError: any;
    let error: any = false;

    if (errorState !== undefined) {
      selectedError = errorState.find(
        (data: any) => data.lpPartnerId === rowData.lpPartnerId
      );
      error = selectedError.allocationDateError !== "";
    }
    const handleInputChange = (e: any, data: any) => {
      const newValue = datewithTime(e.target.value);
      const updatedRowData = { ...data, allocationDate: newValue };

      dispatch(
        setAllocationSliceInvestorList({
          ...allocationSliceInvestorList,
          investorList: allocationSliceInvestorList.investorList.map(
            (objInv: any) => {
              if (objInv.lpPartnerId === updatedRowData.lpPartnerId) {
                return updatedRowData;
              }
              return objInv;
            }
          ),
        })
      );

      const modifyErrorList = (dateError: any) =>
        errorState.map((obj: any) => {
          const newData =
            obj?.lpPartnerId === data.lpPartnerId
              ? {
                  ...obj,
                  allocationDateError: dateError,
                }
              : obj;
          return newData;
        });

      if (
        new Date(newValue).setHours(0, 0, 0, 0) <
        new Date(
          allocationInvestorData.investmentData?.lastAllocationDate!
        ).setHours(0, 0, 0, 0)
      ) {
        setErrorState(
          modifyErrorList(
            "The Allocation Date is earlier than permitted. Allocation Date has to be the same or greater than the Previous Allocation Date."
          )
        );
      } else {
        setErrorState(modifyErrorList(""));
      }
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center w-50 ">
          {allocationSliceInvestorList.isDateChange === true ? (
            <>
              <Calendar
                name="allocationDate"
                value={
                  rowData.allocationDate !== null
                    ? new Date(rowData.allocationDate)
                    : undefined
                }
                minDate={
                  new Date(
                    allocationInvestorData.investmentData?.lastAllocationDate!
                  )
                }
                onChange={(e) => handleInputChange(e, rowData)}
                placeholder="Select Date"
                className="card-inputBox"
                dateTemplate={dateTemplate}
                readOnlyInput
              />
              {error === true ? (
                <RiErrorWarningLine title="" className="iconError" />
              ) : (
                <></>
              )}
            </>
          ) : (
            <label className="w-100">
              {formatDateMMDDYYY(rowData.allocationDate)}
            </label>
          )}
        </div>
      </div>
    );
  };

  console.log(
    "yes",
    allocationSliceInvestorList,
    allocationInvestorData.investmentData
  );
  // end
  // const dateFilterTemplate = (options: any) => {
  //   return (
  //     <Calendar
  //       value={options.value}
  //       onChange={(e) => options.filterCallback(e.value, options.index)}
  //       dateFormat="mm/dd/yy"
  //       placeholder="mm/dd/yyyy"
  //       mask="99/99/9999"
  //     />
  //   );
  // };
  const dataTableElement = [
    {
      field: "name",
      header: "Selected Investors",
      // noFilter: true,
      // noSort: true,
    },
    {
      field: "allocationDate",
      header: `${
        allocationSliceInvestorList.isDateChange ? "Set Date" : "Selected Date"
      }`,
      body: editDates,

      // dataType: "date",
      // filterElement: dateFilterTemplate,
    },
  ];
  // On Back the investor list state not reset
  const onBack = () => {
    props.handlePrevious();
  };
  const onContinue = () => {
    if (!hasError) {
      dispatch(
        setAllocationSliceInvestorList({
          ...allocationSliceInvestorList,
          allocatedInvestorTab: undefined,
        })
      );
      props.handleNext();
    } else {
      errorToastMessage(toast, "Please check the allocate date");
    }
  };

  useEffect(() => {
    const investorList: any = [...allocationSliceInvestorList?.investorList];
    const list: any = [];
    for (const item of investorList) {
      const investorObj = {
        lpPartnerId: item.lpPartnerId,
        allocationDateError: "",
      };
      list.push(investorObj);
    }
    setErrorState(list);
  }, []);

  // to set the state for displaying error message on ui
  useEffect(() => {
    if (errorState !== undefined) {
      setHasError(
        errorState.some((error: any) => error.allocationDateError !== "")
      );
    }
  }, [errorState]);
  useEffect(() => {
    setInvestorListData(
      getListDateConversion(allocationSliceInvestorList.investorList, [
        "allocationDate",
      ])
    );
  }, [allocationSliceInvestorList.investorList]);
  return (
    <>
      <Toast ref={toast} className="themeToast" />
      <div className="headercard d-flex grid">
        <div className="col-12 md:col-6">
          <h2 style={{ fontWeight: "bold" }}>Allocate Units</h2>
        </div>
        <div className="col-12 md:col-6 end-div">
          <h6>Step 2 - Allocation Dates</h6>
        </div>
      </div>

      <div>
        <ProgressBar value={60} showValue={false} className=""></ProgressBar>
      </div>

      <div>
        <div className="mt-4">
          <div className="formgrid grid px-2">
            <div className="d-flex flex-row p-2 col-12  sm:col-12">
              <label className="inputLabel pr-2 flex align-items-center">
                Investment Name:
              </label>{" "}
              <h3>{allocationSliceInvestorList.investmentName}</h3>
            </div>
          </div>
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
              <label className="inputLabel">Investment Start Date:</label>
              <h3>
                {allocationInvestorData.investmentData?.investmentStartDate
                  ? formatDateMMDDYYY(
                      new Date(
                        allocationInvestorData.investmentData?.investmentStartDate!
                      )
                    )
                  : ""}
              </h3>
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
              <label className="inputLabel">
                Most Recent Allocation Date:{" "}
              </label>
              <h3>
                {allocationInvestorData.investmentData?.lastAllocationDate
                  ? formatDateMMDDYYY(
                      new Date(
                        allocationInvestorData.investmentData?.lastAllocationDate!
                      )
                    )
                  : ""}
              </h3>
            </div>
          </div>
          {allocationInvestorData.investmentData?.equityInvestment ===
            "yes" && (
            <div className="allocate-block m-2">
              <div className="m-2 text-white">
                <p>Note:</p>
                <p className="mt-2 ">
                  When setting an allocation date after the investment start
                  date, a pro-rata share of ALL FUTURE EARNINGS will be
                  allocated to the manager.
                </p>
              </div>
            </div>
          )}

          <div className="d-block sm:d-flex sm:flex-column p-2 col-12">
            <label className="inputLabel">Set Allocation Dates</label>

            <DataTableComponent
              // valueData={allocationSliceInvestorList.investorList || []}
              valueData={investorListData || []}
              fieldsElements={dataTableElement}
              isPaginationAllowed={true}
              noGridLines={true}
              className="allocateSliceTable"
            />
          </div>

          <div className="formgrid grid px-2">
            <div className="iconError mb-3  p-2">
              <ul>
                {hasError ? (
                  <li>
                    The Allocation Date is earlier than permitted. Allocation
                    Date has to be the same or greater than the most recent
                    allocation date.
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center ">
        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
          <Button
            className="btn-nav"
            onClick={(e) => {
              e.preventDefault();
              onBack();
            }}
          >
            <span className="ml-auto mr-auto ">Back</span>
          </Button>
        </div>
        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
          <Button
            className="btn-navActive"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onContinue();
            }}
          >
            <span className="ml-auto mr-auto ">Continue</span>
          </Button>
        </div>
      </div>
    </>
  );
}
