import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import { RiTeamFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import { getGetSponsorRegulatoryDashboardDetailsThunk } from "../../../store/features/reports/Getsponsorregulatorydashboarddetails/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

export default function SponsorRegulatoryDashboard() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [loading, setloading] = useState<any>(false);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const sponsorDashboard = useAppSelector(
    (state) => state.GetSponsorRegulatoryDashboardDetails.data
  );

  console.log("sponsorDashboard", sponsorDashboard);

  useEffect(() => {
    const req = {
      LegalEntityId: fundDropdown.fundid,
    };
    setloading(true);

    dispatch(getGetSponsorRegulatoryDashboardDetailsThunk(req))
      .then(() => {
        setloading(false);
      })
      .catch(() => {
        setloading(false);
      });
  }, [fundDropdown.fundid]);

  //   console.log("Supplier", JSON.parse(Supplier));
  return (
    <>
      <div className="grid">
        {loading && <Loader />}

        <div className=" col-12">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Sponsor Regulatory Dashboard</h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className="card my-3 tabWithoutboxshadow">
            <Card>
              <div className="grid mb-3 ">
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon ">
                      <RiTeamFill className="icons-wrap " />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader">Investors</p>
                      {sponsorDashboard?.map((item: any, key: any) => {
                        return (
                          <div
                            key={key}
                            className="d-flex justify-content-center"
                          >
                            {" "}
                            <p
                              className="acc-value-details-textValue sponsorDashboard"
                              onClick={() => navigate("/Master/InvestorList")}
                            >
                              {item[0].invCount}{" "}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon">
                      <RiTeamFill className="icons-wrap" />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader">
                        Accredited Investors
                      </p>
                      {sponsorDashboard?.map((item: any, key: any) => {
                        return (
                          <div
                            key={key}
                            className="d-flex justify-content-center"
                          >
                            <p
                              className="acc-value-details-textValue sponsorDashboard"
                              onClick={() => navigate("/Master/InvestorList")}
                            >
                              {item[0].accInvCount}{" "}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon">
                      <RiTeamFill className="icons-wrap" />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader">
                        States Requiring BlueSky Filing{" "}
                      </p>
                      {sponsorDashboard?.map((item: any, key: any) => {
                        return (
                          <div
                            key={key}
                            className="d-flex justify-content-center"
                          >
                            <p
                              className="acc-value-details-textValue sponsorDashboard"
                              onClick={() =>
                                navigate("/Report/BlueSkyFilingReport")
                              }
                            >
                              {item[0].penExpCount}{" "}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="d-flex flex-column align-items-center">
                    <div className="acc-value-details-icon">
                      <RiTeamFill className="icons-wrap" />
                    </div>
                    <div className="acc-value-details-text">
                      <p className="acc-value-details-textHeader">
                        States BlueSky Filing Completed{" "}
                      </p>
                      {sponsorDashboard?.map((item: any, key: any) => {
                        return (
                          <div
                            key={key}
                            className="d-flex justify-content-center"
                          >
                            <p
                              className="acc-value-details-textValue sponsorDashboard"
                              onClick={() =>
                                navigate("/Report/BlueSkyFilingReport")
                              }
                            >
                              {item[0].compCount}{" "}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
