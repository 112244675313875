import React from "react";
import { useAppSelector } from "../../../../../store/hooks";
import FormDetails from "../../../common-component/form-component/ContinuousFormDetails";
type CreateInvestmentStep9Props = {
  handlePrevious: () => void;
  handleNext: () => void;
  toast: any;
};
const CreateInvestmentStep9: React.FC<CreateInvestmentStep9Props> = ({
  handlePrevious,
  handleNext,
}) => {
  const stateInvetsment = useAppSelector((state) => state.addInvestment.data);
  return (
    <>
      <FormDetails
        pageName="CreateNew"
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        offering={
          stateInvetsment?.productState === "SoftCommit" ? "Deal" : "Investment"
        }
      />
    </>
  );
};

export default CreateInvestmentStep9;
