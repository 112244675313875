import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAdminMenuUsersDetailsService } from "../../../../services/admin/admin";
import error from "../../../../utils/error";
import {
  IGetAdminMenuUsersDetailsState,
  IGetAdminMenuUsersDetailsparameters,
} from ".";

const initialState: IGetAdminMenuUsersDetailsState = {
  success: false,
  message: "",
  loading: "loading",
  adminAccountData: undefined,
  userAccountData: undefined,
  investorAccountData: undefined,
};
export const GetAdminMenuUsersDetailsThunk = createAsyncThunk(
  "AdminMenu/GetAdminMenuUsersDetails",
  async (data: IGetAdminMenuUsersDetailsparameters) => {
    try {
      const response = await GetAdminMenuUsersDetailsService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "AdminMenu/GetAdminMenuUsersDetails",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetAdminMenuUsersDetailsThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      GetAdminMenuUsersDetailsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.adminAccountData = action.payload
          ? action.payload.data.fundAdminAccounts
          : [];
        state.userAccountData = action.payload
          ? action.payload.data.fundUserAccounts
          : [];
        state.investorAccountData = action.payload
          ? action.payload.data.investorAccounts
          : [];
      }
    );
    builder.addCase(GetAdminMenuUsersDetailsThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
