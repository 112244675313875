import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getCurrentOpportunitiesReport } from "../../../../store/features/reports/get-current-opportunities/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
export default function GetCurrentOpportunities() {
  const dispatch = useAppDispatch();

  const CurrentOpportunities = useAppSelector(
    (state) => state.GetCurrentOpportunities.data
  );
  const [loading, setloading] = useState<any>(false);
  const [exitDate, setExitDate] = useState<any>();

  console.log("CurrentOpportunities", CurrentOpportunities);

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  //   const formatCurrency = (rowData: any) => {
  //     const finalValue = Number(rowData.fees).toFixed(2);
  //     const parseValue = currencyFormat(Number(finalValue));
  //     return `${parseValue}`;
  //   };
  const stateNameWithCountry = (rowData: any) => {
    const name = rowData.state
      ? rowData.state +
        (rowData.country && rowData.country !== "United States"
          ? ", " + rowData.country!
          : "")
      : "";
    return (
      <span title={name} className="left-align">
        {name}
      </span>
    );
  };
  const excelTableHeaders = [
    {
      field: "legalEntityName",
      header: "Fund Name",
      dataType: "string",
    },
    {
      field: "category",
      header: "Asset Class",
      dataType: "string",
    },
    {
      field: "productName",
      header: "Investment Name",
      dataType: "string",
    },
    {
      field: "remainingAllocation",
      header: "Remaining Allocation",
      dataType: "currency",
    },
    {
      field: "fundingDate",
      header: "Funding Date",
      dataType: "date",
    },
    {
      field: "targetReturn",
      header: "Target Return",
      dataType: "string",
    },
    {
      field: "estExitDate",
      header: "Estimated Exit Date",
      dataType: "date",
    },
    {
      field: "city",
      header: "City",
      dataType: "string",
    },
    {
      field: "state",
      header: "State",
      dataType: "string",
    },
    {
      field: "investmentID",
      header: "Investment ID",
      dataType: "string",
    },
    {
      field: "productID",
      header: "Product ID",
      dataType: "string",
    },
    { field: "createdDate", header: "Creation Date", dataType: "date" },
  ];

  const dataTableElements = [
    {
      field: "legalEntityName",
      header: "Fund Name",
      style: { minWidth: "150px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.legalEntityName}>
          {rowData?.legalEntityName}
        </div>
      ),
    },
    {
      field: "category",
      header: "Asset Class",
      style: { minWidth: "150px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.category}>
          {rowData?.category}
        </div>
      ),
    },
    {
      field: "productName",
      header: "Investment Name",
      style: { minWidth: "260px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.productName}>
          {rowData?.productName}
        </div>
      ),
      //   body: formatDate,
      //   filterElement: dateFilterTemplate,
    },
    {
      field: "remainingAllocation",
      header: "Remaining Allocation",
      style: { minWidth: "190px" },
      body: (rowData: any) => currencyFormat(rowData.remainingAllocation),
    },
    {
      field: "fundingDate",
      header: "Funding Date",
      dataType: "date",
      style: { minWidth: "180px" },
      body: (rowData: any) => {
        return rowData.fundingDate
          ? formatDateMMDDYYY(rowData.fundingDate)
          : rowData.fundingDate;
      },
      filterElement: dateFilterTemplate,
    },
    {
      field: "targetReturn",
      header: "Target Return",
      style: { minWidth: "170px" },
      //   body: formatFilingDate,
      //   filterElement: dateFilterTemplate,
    },
    {
      field: "estExitDate",
      header: "Estimated Exit Date",
      dataType: "date",
      style: { minWidth: "180px" },
      body: (rowData: any) => {
        return rowData.estExitDate
          ? formatDateMMDDYYY(rowData.estExitDate)
          : rowData.estExitDate;
      },
      filterElement: dateFilterTemplate,
    },
    {
      field: "city",
      header: "City",
      style: { minWidth: "90px" },
    },
    {
      field: "state",
      header: "State",
      style: { minWidth: "90px" },
      body: stateNameWithCountry,
    },
    {
      field: "createdDate",
      header: "Creation Date",
      dataType: "date",
      style: { minWidth: "180px" },
      body: (rowData: any) => {
        return rowData.createdDate
          ? formatDateMMDDYYY(rowData.createdDate)
          : rowData.createdDate;
      },
      filterElement: dateFilterTemplate,
    },
  ];

  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  // let parameters = {
  //   legalEntityId: 0,
  // };
  // const entityListparameters = {
  //   Email: "softlabsavestor%40gmail.com",
  //   UserTypeId: 1,
  // };
  useEffect(() => {
    setloading(true);
    if (dropdown.fundid !== undefined) {
      const parameters = {
        legalEntityId: dropdown.fundid,
      };
      dispatch(getCurrentOpportunitiesReport(parameters))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    } else {
      setloading(false);
    }
  }, [dropdown.fundid]);

  useEffect(() => {
    setExitDate(
      getListDateConversion(CurrentOpportunities, [
        "fundingDate",
        "estExitDate",
        "createdDate",
      ])
    );
  }, [CurrentOpportunities]);
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);
  return (
    <>
      {loading && <Loader />}
      {/* <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Current Opportunities Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div> */}
      {/* <div className="grid">
        <div className="col-12">
          <div className="card my-3 tabWithoutboxshadow">
            <Card> */}
      {/* <h4 className="cardHeader">Get Current Opportunities Report</h4> */}
      <DataTableComponent
        valueData={exitDate || []}
        fieldsElements={dataTableElements}
        isPaginationAllowed={true}
        isDownload={true}
        HeadingName="Get Current Opportunities Report"
        maxHeight="calc(-145px + 100vh)"
        scrollable={true}
        excelFieldsElements={excelTableHeaders}
        isFullView={true}
        columnResizeMode="expand"
        fileName="Get Current Opportunities Report"
        className="gridcell"
      />
      {/* </Card>
          </div>
        </div>
      </div> */}
    </>
  );
}
