import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetFundDocumentListService,
  addFundDocumentService,
  deleteFundDocumentService,
} from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IFundDocumentListState } from ".";

const initialState: IFundDocumentListState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkFundDocumentList = createAsyncThunk(
  "FundDocument/get",
  async (data: any) => {
    try {
      const response = await GetFundDocumentListService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const addThunkFundDocumentList = createAsyncThunk(
  "FundDocument/add",
  async (data: any) => {
    try {
      const response = await addFundDocumentService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

export const deleteThunkFundDocument = createAsyncThunk(
  "FundDocument/delete",
  async (data: any) => {
    try {
      const response = await deleteFundDocumentService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "FundDocument",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkFundDocumentList.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkFundDocumentList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      state.data =
        action.payload._response.data !== null
          ? action.payload._response.data
          : [];
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(getThunkFundDocumentList.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });

    builder.addCase(addThunkFundDocumentList.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(addThunkFundDocumentList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      state.data =
        action.payload._response.data !== null
          ? action.payload._response.data
          : [];
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(addThunkFundDocumentList.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });

    builder.addCase(deleteThunkFundDocument.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(deleteThunkFundDocument.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      state.data =
        action.payload._response.data !== null
          ? action.payload._response.data
          : [];
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(deleteThunkFundDocument.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
