import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteInvestorService } from "../../../../services/investor/investor";
import error from "../../../../utils/error";
import { IDeleteInvestorState, IDeleteInvestorparameters } from ".";

const initialState: IDeleteInvestorState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const deleteInvestorThunk = createAsyncThunk(
  "Investor/DeleteInvestor",
  async (data: IDeleteInvestorparameters) => {
    try {
      const response = await deleteInvestorService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Investor/DeleteInvestor",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(deleteInvestorThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(deleteInvestorThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data : [];
    });
    builder.addCase(deleteInvestorThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
