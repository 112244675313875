// import axios from "axios";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import "./SideNavigation.scss";
// import { Menu } from "primereact/menu";
// import {
//   RiHome5Line,
//   RiHome5Fill,
//   RiPieChartLine,
//   RiPieChartFill,
// } from "react-icons/ri";
// import { NavLink } from "react-router-dom";
// import { PanelMenu } from "primereact/panelmenu";
// import { classNames } from "primereact/utils";

import React, { useEffect, useState } from "react";
import {
  //  RiArrowDropDownLine,
  // RiDashboard3Fill,
  RiDashboard3Line,
  RiDraftLine,
  RiFileCopy2Line,
  RiPencilRuler2Line,
  RiPieChartLine,
  RiSecurePaymentLine,
  RiUserLine,
  RiMenuLine,
  RiQuestionLine,
  RiSettings2Line,
  RiLayoutMasonryLine,
  RiAdminLine,
  RiBankLine,
  RiFileChartLine,
  RiNotification2Line,
  RiFolder2Line,
} from "react-icons/ri";
import { NavLink } from "react-router-dom";
import AvesterColor from "../../images/avestorColorLogo.png";
import user from "../../images/avestorMini.png";
// import {
//   // getFundLogoThunk,
//   getUserProfileDetailsThunk,
// } from "../../store/features/profile/slice";
// import { setSidebarState } from "../../store/features/sidebar/slice";
import { getThunkUserMenu } from "../../store/features/user-menu/slice";
// import { setImageState } from "../../store/getasset/slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
// import { getSasKeyThunk } from "../../store/sas-key/slice";
// import { envVariable } from "../../utils/sponsor/helper";

export interface MenuItem {
  id: number;
  menuName: string;
  menuURL: string;
  subParentId: number;
  sequenceId: number;
  divider: string;
  sortCol: string;
  parentId: number;
  subMenu: MenuItem[];
}

const SideNavigation = () => {
  const dispatch = useAppDispatch();
  // const [loading, setLoading] = useState(false);
  const matchMediaDesktop = window.matchMedia("(min-width:992px)");
  const matchMediaMobile = window.matchMedia("(max-width:991px)");
  // const fundLogo = useAppSelector((state) => state.getAssetResponseStatus.data);
  const [getVisibleStatus, setSidebarState] = useState(true);
  const [getMobileVisibleStatus, setMobileSidebarState] = useState(false);
  // const menus = useAppSelector((state) => state.userMenu);
  const menus = useAppSelector((state) => state.userMenu.data);
  const [menuData, setMenuData] = useState<any[]>();
  const [visibleMenus, setVisibleMenus] = useState("");
  const [visibleSubmenus, setVisibleSubmenus] = useState("");

  const imageBanner = useAppSelector(
    (state) => state.getAssetResponseStatus.data
  );

  useEffect(() => {
    const controller = new AbortController();
    console.log("GetSponsorMenus getThunkUserMenu called multiple time");
    dispatch(getThunkUserMenu());
    return () => {
      controller.abort();
    };
  }, []);

  const getIcon = (icon: any) => {
    // Add logic to determine which icon to use based on the name
    console.log("icon.menuName", icon.menuName);
    switch (icon.menuName) {
      case "Home": {
        return getVisibleStatus ? (
          <RiDashboard3Line className={"mr-1"} />
        ) : (
          <RiDashboard3Line className={" "} />
        );
      }
      case "Files": {
        return getVisibleStatus ? (
          <RiFolder2Line className={"mr-1"} />
        ) : (
          <RiFolder2Line className={" "} />
        );
      }
      case "Operations": {
        return getVisibleStatus ? (
          <RiPencilRuler2Line className={"mr-1"} />
        ) : (
          <RiPencilRuler2Line className={" "} />
        );
      }
      case "Investors": {
        return getVisibleStatus ? (
          <RiUserLine className={"mr-1"} />
        ) : (
          <RiUserLine className={" "} />
        );
      }
      case "Investments": {
        return getVisibleStatus ? (
          <RiPieChartLine className={"mr-1"} />
        ) : (
          <RiPieChartLine className={" "} />
        );
      }
      case "Tax": {
        return getVisibleStatus ? (
          <RiFileCopy2Line className={"mr-1"} />
        ) : (
          <RiFileCopy2Line className={" "} />
        );
      }
      case "Report": {
        return getVisibleStatus ? (
          <RiDraftLine className={"mr-1"} />
        ) : (
          <RiDraftLine className={" "} />
        );
      }
      case "Accounting": {
        return getVisibleStatus ? (
          <RiSecurePaymentLine className={"mr-1"} />
        ) : (
          <RiSecurePaymentLine className={" "} />
        );
      }
      case "Need help?": {
        return getVisibleStatus ? (
          <RiQuestionLine className={"mr-1"} />
        ) : (
          <RiQuestionLine className={" "} />
        );
      }
      case "Manage Account": {
        return getVisibleStatus ? (
          <RiSettings2Line className={"mr-1"} />
        ) : (
          <RiSettings2Line className={" "} />
        );
      }
      case "Dashboard": {
        return getVisibleStatus ? (
          <RiLayoutMasonryLine className={"mr-1"} />
        ) : (
          <RiLayoutMasonryLine className={"mr-1"} />
        );
      }
      case "Admin": {
        return getVisibleStatus ? (
          <RiAdminLine className={"mr-1"} />
        ) : (
          <RiAdminLine className={"mr-1"} />
        );
      }
      case "ACH": {
        return getVisibleStatus ? (
          <RiBankLine className={"mr-1"} />
        ) : (
          <RiBankLine className={"mr-1"} />
        );
      }
      case "Reports": {
        return getVisibleStatus ? (
          <RiFileChartLine className={"mr-1"} />
        ) : (
          <RiFileChartLine className={"mr-1"} />
        );
      }
      case "Notifications": {
        return getVisibleStatus ? (
          <RiNotification2Line className={"mr-1"} />
        ) : (
          <RiNotification2Line className={"mr-1"} />
        );
      }
      case "Fund": {
        return getVisibleStatus ? (
          <RiSecurePaymentLine className={"mr-1"} />
        ) : (
          <RiSecurePaymentLine className={"mr-1"} />
        );
      }

      default: {
        return <RiDashboard3Line className="mr-1" />;
      }
    }
  };
  console.log("menuData", menuData);

  // useEffect(() => {
  //   dispatch(getFundLogoThunk()).then((res) => {
  //     console.log("getFundLogoThunk", res.payload.response.data.blobURL);
  //     dispatch(setImageState(res.payload.response.data.blobURL));
  //   });
  // }, []);
  const toggleIn = () => {
    setSidebarState((prevVisibleStatus) => {
      const newVisibleStatus = !prevVisibleStatus;
      // Perform any immediate actions with the newVisibleStatus
      // console.log("1", newVisibleStatus);
      if (newVisibleStatus) {
        document.body.classList.remove("sidebar-icon-only");
      } else {
        document.body.classList.add("sidebar-icon-only");
      }
      return newVisibleStatus;
    });
  };
  const toggleMenu = (menuId: any, event: React.MouseEvent) => {
    event.preventDefault();
    console.log("idd", menuId);
    if (visibleMenus === menuId) {
      setVisibleMenus("");
    } else {
      setVisibleMenus(menuId);
    }
  };
  const toggleSubmenu = (menuId: any, event: React.MouseEvent) => {
    event.preventDefault();
    console.log("idd", menuId);
    if (visibleSubmenus === menuId) {
      setVisibleSubmenus("");
    } else {
      setVisibleSubmenus(menuId);
    }
  };
  console.log("po", visibleSubmenus);
  const toggleInMob = () => {
    setMobileSidebarState((prevVisibleStatus: any) => {
      const newVisibleStatus = !prevVisibleStatus;
      // Perform any immediate actions with the newVisibleStatus
      // console.log("1", newVisibleStatus);
      // document.body.classList.toggle("sidebar-icon-only");

      return newVisibleStatus;
    });
  };

  const desktopView = () => {
    return (
      <>
        {" "}
        <div className="desktopNav layout-sidebar text-left  ">
          <div
            className={
              getVisibleStatus
                ? "sidebar fixed-top border-right"
                : "sidebar fixed-top border-right col-2"
            }
          >
            <div>
              {/* // logo */}
              <div className="d-block text-center">
                {getVisibleStatus ? (
                  <div className="nav-item nav-profile pb-0">
                    <Image
                      src={imageBanner.image}
                      alt="Image"
                      imageClassName="sidebar-fund-logo"
                      // width="120"
                    />
                  </div>
                ) : (
                  <div className="p-5"></div>
                )}
              </div>
              {/* text */}
              <div className="w-full pt-4 ">
                {getVisibleStatus ? (
                  <p className="sideNavHeader">
                    {/* {profileData?.legalEntityName} */}
                    {/* Admin Dashboard */}
                  </p>
                ) : (
                  <p className="sideNavHeader"></p>
                )}
              </div>
            </div>
            <div className="navwwrap">
              <ul className="nav relative">
                <div
                  className="mb-6rem"
                  style={
                    getVisibleStatus
                      ? { overflowY: "scroll", height: "74%" }
                      : { height: "74%" }
                  }
                >
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    {getVisibleStatus ? (
                      <>
                        <div>
                          {menuData?.map((data: any) => {
                            const Icon = getIcon(data);
                            const isSubMenuVisible = visibleMenus === data.id;
                            return (
                              <div key={data.id}>
                                <NavLink
                                  // key={data.id}
                                  // to={data.menuURL}
                                  to={data.menuURL === null ? "" : data.menuURL}
                                  onClick={
                                    data.parentId === 0 && data.menuURL !== null
                                      ? undefined
                                      : (event) => toggleMenu(data.id, event)
                                  }
                                  target={
                                    data.menuName === "Member Benefits"
                                      ? "_blank"
                                      : undefined
                                  }
                                  className={({ isActive }) =>
                                    isActive &&
                                    data.menuURL !== null &&
                                    data.parentId === 0
                                      ? "parentmenu-active"
                                      : "parentmenu"
                                  }
                                >
                                  {Icon}
                                  <span>{data.menuName}</span>
                                  {data.subMenu.length > 0 ? (
                                    <Button
                                      data-testid="toggle-button"
                                      className={
                                        !isSubMenuVisible
                                          ? "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-downarrow"
                                          : "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-downarrow"
                                      }
                                      type="button"
                                      onClick={(event) =>
                                        toggleMenu(data.id, event)
                                      }
                                    >
                                      {!isSubMenuVisible ? (
                                        <span className=" bi bi-chevron-down"></span>
                                      ) : (
                                        <span className=" bi bi-chevron-up"></span>
                                      )}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </NavLink>

                                {isSubMenuVisible ? (
                                  <>
                                    {data.subMenu.map(
                                      (dataInside: any, i: any) => {
                                        // console.log(
                                        //   "menu",
                                        //   dataInside.menuName.length
                                        // );
                                        const isSubMenusMenusVisible =
                                          visibleSubmenus === dataInside.id;
                                        const menuClassName =
                                          dataInside.menuName.length > 24
                                            ? "navlinktxtactiveSubmenu"
                                            : " ";

                                        return (
                                          <div
                                            className={
                                              dataInside.menuURL === "#"
                                                ? "secondMenuWithSubMenuWrap"
                                                : "secondMenuWrap"
                                            }
                                            key={dataInside.id}
                                          >
                                            <NavLink
                                              key={dataInside.id}
                                              to={
                                                dataInside.menuURL === "#" ||
                                                dataInside.menuURL === null
                                                  ? ""
                                                  : dataInside.menuURL
                                              }
                                              onClick={(event) => {
                                                dataInside.subMenu.length >
                                                0 ? (
                                                  toggleSubmenu(
                                                    dataInside.id,
                                                    event
                                                  )
                                                ) : (
                                                  <></>
                                                );
                                              }}
                                              className={({ isActive }) =>
                                                isActive
                                                  ? `secondMenu-active  ${menuClassName}`
                                                  : `secondMenu ${menuClassName}`
                                              }
                                            >
                                              <span className="ml-3"></span>
                                              <span className="mr-2 greyDash">
                                                -
                                              </span>
                                              <span>{dataInside.menuName}</span>
                                              {dataInside.subMenu.length > 0 ? (
                                                <Button
                                                  data-testid="toggle-button"
                                                  className={
                                                    !isSubMenusMenusVisible
                                                      ? "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-downarrow"
                                                      : "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-downarrow"
                                                  }
                                                  type="button"
                                                  onClick={(event) =>
                                                    toggleSubmenu(
                                                      dataInside.id,
                                                      event
                                                    )
                                                  }
                                                >
                                                  {!isSubMenusMenusVisible ? (
                                                    <span className=" bi bi-chevron-down"></span>
                                                  ) : (
                                                    <span className=" bi bi-chevron-up"></span>
                                                  )}
                                                </Button>
                                              ) : (
                                                <></>
                                              )}
                                            </NavLink>
                                            {isSubMenusMenusVisible ? (
                                              <>
                                                {dataInside.subMenu.map(
                                                  (menusData: any) => {
                                                    const menuClassNameSub =
                                                      menusData.menuName
                                                        .lenght > 38
                                                        ? "navlinktxtactiveSubmenuBigLenght"
                                                        : menusData.menuName
                                                            .length > 22
                                                        ? "navlinktxtactiveSubmenu"
                                                        : " ";
                                                    return (
                                                      <NavLink
                                                        key={menusData.id}
                                                        to={
                                                          menusData.menuURL ===
                                                          null
                                                            ? ""
                                                            : menusData.menuURL
                                                        }
                                                        className={({
                                                          isActive,
                                                        }) =>
                                                          isActive
                                                            ? `thirdMenu-active  ${menuClassNameSub}`
                                                            : `thirdMenu  ${menuClassNameSub}`
                                                        }
                                                        // className="navlinktxt"
                                                      >
                                                        <span className="ml-5"></span>
                                                        <span className="mr-2 greyDash">
                                                          -
                                                        </span>
                                                        <span>
                                                          {menusData.menuName}
                                                        </span>
                                                      </NavLink>
                                                    );
                                                  }
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          {menuData?.map((data: any) => {
                            const Icon = getIcon(data);
                            // const isSubMenuVisible = visibleMenus === data.id;
                            return (
                              <div key={data.id}>
                                <NavLink
                                  key={data.id}
                                  // to={data.menuURL}
                                  to={data.menuURL === null ? "" : data.menuURL}
                                  onClick={
                                    data.id === 1
                                      ? undefined
                                      : (event) => toggleMenu(data.id, event)
                                  }
                                  className={({ isActive }) =>
                                    isActive && data.id === 1
                                      ? "navlinkbuttonactive"
                                      : "navlinknotactive "
                                  }
                                >
                                  {Icon} <div className="mb-2"></div>
                                </NavLink>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className="d-flex"
                    style={{ flexDirection: "column" }}
                  ></div>
                </div>

                <div className="sidebarFooter">
                  {getVisibleStatus ? (
                    <div className="greyLogo  d-flex justify-content-center">
                      <Image src={AvesterColor} width="95" />
                    </div>
                  ) : (
                    <div className="fixed-bottom p-3 pb-4 ">
                      <Image width="38" src={user} />
                    </div>
                  )}
                </div>
              </ul>

              <div className="layout-menuitem-text d-flex align-items-center">
                <Button
                  data-testid="toggle-button"
                  className={
                    getVisibleStatus
                      ? "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-leftarrow"
                      : "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-rightarrow"
                  }
                  type="button"
                  onClick={() => toggleIn()}
                >
                  {getVisibleStatus ? (
                    <span className=" bi bi-chevron-left"></span>
                  ) : (
                    <span className=" bi bi-chevron-right"></span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const mobView = () => {
    return (
      <>
        {" "}
        {matchMediaMobile && (
          <div className="MobNav">
            {" "}
            <div className="layout-menuitem-text d-flex align-items-center">
              <Button
                className={
                  !getMobileVisibleStatus
                    ? "navbar-toggler toggle-button-mob"
                    : "navbar-toggler toggle-button-mob"
                }
                type="button"
                onClick={() => toggleInMob()}
              >
                {getMobileVisibleStatus ? (
                  <RiMenuLine className="iconwrap" />
                ) : (
                  <RiMenuLine className="iconwrap" />
                )}
              </Button>
            </div>
            <div className="layout-sidebar text-left  ">
              <div
                className={
                  getMobileVisibleStatus ? "sidebar fixed-top border-right" : ""
                }
              >
                <div>
                  <div className="d-block text-center">
                    {getMobileVisibleStatus ? (
                      <div className="nav-item nav-profile pb-0 mt-4 h-3rem">
                        {imageBanner ? (
                          <Image
                            src={imageBanner.image}
                            alt="Image"
                            width="150"
                          />
                        ) : undefined}
                      </div>
                    ) : undefined}
                  </div>
                  <div className="d-flex">
                    {getMobileVisibleStatus ? (
                      <p className="sideNavHeader">
                        {/* {profileData?.legalEntityName} */}
                        Admin Dashboard
                      </p>
                    ) : (
                      <p className="sideNavHeader"></p>
                    )}
                  </div>
                </div>
                {getMobileVisibleStatus ? (
                  <div className="navwwrap">
                    <ul className="nav relative">
                      <div
                        style={{
                          height: "100%",
                        }}
                      >
                        <div
                          className="d-flex "
                          style={{
                            flexDirection: "column",
                            overflowY: "scroll",
                            height: "70%",
                          }}
                        >
                          {getMobileVisibleStatus ? (
                            <>
                              <div>
                                {menuData?.map((data: any) => {
                                  const Icon = getIcon(data);
                                  const isSubMenuVisible =
                                    visibleMenus === data.id;
                                  return (
                                    <div key={data.id}>
                                      <NavLink
                                        key={data.id}
                                        // to={data.menuURL}
                                        to={
                                          data.menuURL === null
                                            ? ""
                                            : data.menuURL
                                        }
                                        onClick={
                                          data.parentId === 0 &&
                                          data.menuURL !== null
                                            ? undefined
                                            : (event) =>
                                                toggleMenu(data.id, event)
                                        }
                                        target={
                                          data.menuName === "Member Benefits"
                                            ? "_blank"
                                            : undefined
                                        }
                                        className={({ isActive }) =>
                                          isActive &&
                                          (data.parentId === 0 ||
                                            data.menuURL !== null)
                                            ? "navlinktxtactive"
                                            : "navlinktxt"
                                        }
                                      >
                                        {Icon}
                                        <span>{data.menuName}</span>
                                        {data.subMenu.length > 0 ? (
                                          <Button
                                            data-testid="toggle-button"
                                            className={
                                              !isSubMenuVisible
                                                ? "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-downarrow"
                                                : "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-downarrow"
                                            }
                                            type="button"
                                            onClick={(event) =>
                                              toggleMenu(data.id, event)
                                            }
                                          >
                                            {!isSubMenuVisible ? (
                                              <span className=" bi bi-chevron-down"></span>
                                            ) : (
                                              <span className=" bi bi-chevron-up"></span>
                                            )}
                                          </Button>
                                        ) : (
                                          <></>
                                        )}
                                      </NavLink>

                                      {isSubMenuVisible ? (
                                        <>
                                          {data.subMenu.map(
                                            (dataInside: any) => {
                                              // console.log(
                                              //   "menu",
                                              //   dataInside.menuName.length
                                              // );
                                              const isSubMenusMenusVisible =
                                                visibleSubmenus ===
                                                dataInside.id;
                                              const menuClassName =
                                                dataInside.menuName.length > 24
                                                  ? "navlinktxtactiveSubmenu"
                                                  : " ";

                                              return (
                                                <>
                                                  <NavLink
                                                    key={dataInside.id}
                                                    // to={dataInside.menuURL}
                                                    to={
                                                      dataInside.menuURL ===
                                                        "#" ||
                                                      dataInside.menuURL ===
                                                        null
                                                        ? ""
                                                        : dataInside.menuURL
                                                    }
                                                    onClick={(event) => {
                                                      dataInside.subMenu
                                                        .length > 0 ? (
                                                        toggleSubmenu(
                                                          dataInside.id,
                                                          event
                                                        )
                                                      ) : (
                                                        <></>
                                                      );
                                                    }}
                                                    className={({ isActive }) =>
                                                      isActive
                                                        ? `navlinktxtactive ${menuClassName}`
                                                        : `navlinktxt ${menuClassName}`
                                                    }
                                                  >
                                                    <span className="ml-3"></span>
                                                    <span className="mr-2 greyDash">
                                                      -
                                                    </span>
                                                    <span>
                                                      {dataInside.menuName}
                                                    </span>
                                                    {dataInside.subMenu.length >
                                                    0 ? (
                                                      <Button
                                                        data-testid="toggle-button"
                                                        className={
                                                          !isSubMenusMenusVisible
                                                            ? "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-downarrow"
                                                            : "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-downarrow"
                                                        }
                                                        type="button"
                                                        onClick={(event) =>
                                                          toggleSubmenu(
                                                            dataInside.id,
                                                            event
                                                          )
                                                        }
                                                      >
                                                        {!isSubMenusMenusVisible ? (
                                                          <span className=" bi bi-chevron-down"></span>
                                                        ) : (
                                                          <span className=" bi bi-chevron-up"></span>
                                                        )}
                                                      </Button>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </NavLink>
                                                  {isSubMenusMenusVisible ? (
                                                    <>
                                                      {dataInside.subMenu.map(
                                                        (menusData: any) => {
                                                          const menuClassNameSub =
                                                            menusData.menuName
                                                              .lenght > 38
                                                              ? "navlinktxtactiveSubmenuBigLenght mb-2"
                                                              : menusData
                                                                  .menuName
                                                                  .length > 22
                                                              ? "navlinktxtactiveSubmenu mb-2"
                                                              : "mb-1";
                                                          return (
                                                            <NavLink
                                                              key={menusData.id}
                                                              to={
                                                                menusData.menuURL ===
                                                                null
                                                                  ? ""
                                                                  : menusData.menuURL
                                                              }
                                                              // className={({
                                                              //   isActive,
                                                              // }) =>
                                                              //   isActive
                                                              //     ? "navlinktxtactiveSubmenu "
                                                              //     : "navlinktxtSubmenu"
                                                              // }
                                                              className={({
                                                                isActive,
                                                              }) =>
                                                                isActive
                                                                  ? `navlinktxtactive ${menuClassNameSub}`
                                                                  : `navlinktxt ${menuClassNameSub}`
                                                              }
                                                              // className="navlinktxt"
                                                            >
                                                              <span className="ml-5"></span>
                                                              <span className="mr-2 greyDash">
                                                                -
                                                              </span>
                                                              <span>
                                                                {
                                                                  menusData.menuName
                                                                }
                                                              </span>
                                                            </NavLink>
                                                          );
                                                        }
                                                      )}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                {menuData?.map((data: any) => {
                                  const Icon = getIcon(data);
                                  // const isSubMenuVisible = visibleMenus === data.id;
                                  return (
                                    <div key={data.id}>
                                      <NavLink
                                        key={data.id}
                                        // to={data.menuURL}
                                        to={
                                          data.menuURL === null
                                            ? ""
                                            : data.menuURL
                                        }
                                        onClick={
                                          data.id === 1
                                            ? undefined
                                            : (event) =>
                                                toggleMenu(data.id, event)
                                        }
                                        className={({ isActive }) =>
                                          isActive && data.id === 1
                                            ? "navlinkbuttonactive"
                                            : "navlinknotactive "
                                        }
                                      >
                                        {Icon} <div className="mb-2"></div>
                                      </NavLink>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      {/* {partnerID <= 0 && ( */}
                      <div className="align-self-baseline sidebarFooter">
                        {getMobileVisibleStatus ? (
                          <div className="greyLogo mx-auto text-center">
                            <Image src={AvesterColor} width="95" />
                          </div>
                        ) : undefined}
                      </div>
                    </ul>

                    <div className="layout-menuitem-text d-flex align-items-center">
                      <Button
                        data-testid="toggle-button"
                        className={
                          getMobileVisibleStatus
                            ? "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-leftarrow"
                            : "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-rightarrow"
                        }
                        type="button"
                        onClick={() => toggleInMob()}
                      >
                        {getMobileVisibleStatus ? (
                          <span className=" bi bi-chevron-left"></span>
                        ) : undefined}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <ul className="nav relative">
                      <div className="mb-6rem"></div>
                      {/* {partnerID <= 0 && ( */}
                      <div className="align-self-baseline sidebarFooter">
                        {getMobileVisibleStatus ? (
                          <div className="greyLogo mx-auto text-center">
                            <Image src={AvesterColor} width="95" />
                          </div>
                        ) : undefined}
                      </div>
                    </ul>

                    <div className="layout-menuitem-text d-flex align-items-center">
                      <Button
                        data-testid="toggle-button"
                        className={
                          getMobileVisibleStatus
                            ? "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-leftarrow"
                            : "navbar-toggler navbar-toggler align-self-center togglebtn-wrap-rightarrow"
                        }
                        type="button"
                        onClick={() => toggleInMob()}
                      >
                        {getMobileVisibleStatus ? (
                          <span className=" bi bi-chevron-left"></span>
                        ) : undefined}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  // const toggleMenu = () => {
  //   setVisible(!visible);
  // };

  function convertToTree(menuItems: any): MenuItem[] {
    const objMenu: any = {};
    const roots: MenuItem[] = [];

    for (const item of menuItems) {
      objMenu[item.id] = { ...item, subMenu: [] };
    }
    for (const item of menuItems) {
      const parent = objMenu[item.parentId];
      if (parent) {
        parent.subMenu.push(objMenu[item.id]);
      } else {
        roots.push(objMenu[item.id]);
      }
    }
    return roots;
  }

  useEffect(() => {
    setMenuData(convertToTree(menus));
  }, [menus]);

  useEffect(() => {
    const handleResize = () => {
      setSidebarState(true);
    };

    // setMobileSidebarState(false);
    if (matchMediaDesktop.matches) {
      desktopView();
    }
    matchMediaDesktop.addEventListener("change", handleResize);

    window.addEventListener("change", handleResize); // Listen for resize event

    return () => {
      if (matchMediaDesktop.matches) {
        matchMediaDesktop.removeEventListener("change", handleResize);
        desktopView();
      }
      window.removeEventListener("change", handleResize); // Clean up resize event listener
    };
  }, [matchMediaDesktop.matches, getVisibleStatus]);

  useEffect(() => {
    const handleResizeMob = () => {
      setMobileSidebarState(false);
      // console.log("class list", document.body.classList);
      document.body.classList.remove("sidebar-icon-only");
    };
    // setSidebarState(true);

    if (matchMediaMobile.matches) {
      mobView();

      // console.log("mob use", getMobileVisibleStatus);
      // console.log("desk use  on mob", getVisibleStatus);
    } else {
      setMobileSidebarState(false);
    }
    matchMediaMobile.addEventListener("change", handleResizeMob);
    window.addEventListener("change", handleResizeMob); // Listen for resize event

    return () => {
      if (matchMediaMobile.matches) {
        matchMediaMobile.removeEventListener("change", handleResizeMob);
        mobView();
      }
      window.removeEventListener("change", handleResizeMob); // Clean up resize event listener
    };
  }, [matchMediaMobile.matches, getMobileVisibleStatus]);

  return (
    <div>
      {matchMediaDesktop && desktopView()}

      {matchMediaMobile && mobView()}
    </div>
  );
};
export default SideNavigation;
