import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { RootState } from "/=..";
import { getAllPortfolioInvestmentDropdownService } from "../../../services/portfolio/portfolio";
import { IInvestmentDropdown } from "../../../types";

// import { RootState } from "../../store";
// import { IPortfolio } from "../../types/index";
// import apiFactory from "../../utils/api";
import error from "../../../utils/error";
interface IPortfolioState {
  data?: {}[] | null;
  dropdown?: IInvestmentDropdown[] | [];

  loading: string;
  error?: string | null;
}

const initialState: IPortfolioState = {
  data: undefined,
  loading: "loading",
  error: undefined,
};

interface Iparameters {
  investorID: number;
  partnerID: number;
  legalEntityID: number;
}

export const getThunkPortfolioInvestmentDropdown = createAsyncThunk(
  "Portfolio/getInvestmentsDropdown",
  async (data: Iparameters) => {
    try {
      const response = await getAllPortfolioInvestmentDropdownService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Portfolio/InvestmentDropdown",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkPortfolioInvestmentDropdown.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      getThunkPortfolioInvestmentDropdown.fulfilled,
      (state, action) => {
        state.loading = "succeeded";

        state.data = action.payload.categories;
      }
    );
    builder.addCase(
      getThunkPortfolioInvestmentDropdown.rejected,
      (state, action) => {
        state.loading = "failed";

        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});

// export const getAllPortfoliosData = (state: RootState) => {
//   return state.portfolio.data;
// };
// export const getAllOpportunitiesStatus = (state: RootState) =>
//   state.opportunity.loading;
// export const getAllOpportunitiesError = (state: RootState) =>
//   state.opportunity.error;
export default slice.reducer;
