import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
// import { DataTable } from "primereact/datatable";
// import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
// import { RiSearchLine } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getThunkLateInvestmentExits } from "../../../../store/features/reports/late-investment-exits/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
// import LateInvestmentExitsSkeleton from "./LateInvestmentExitsSkeleton";

export default function LateInvestmentExits() {
  const dispatch = useAppDispatch();
  // const portState = useAppSelector((state) => state.navbardropdown.loading);
  const lateInvestmentExits = useAppSelector(
    (state) => state.missedExitDate.data
  );
  const [exitDate, setExitDate] = useState<any>();
  const [loader, setLoader] = useState(false);

  // const getExitDateConversion = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedExitDate = {
  //       ...d,
  //       estExitDate: new Date(d.estExitDate),
  //     };
  //     return modifiedExitDate;
  //   });
  // };
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  // const formatDate = (rowData: any) => {
  //   const dateFormat = formatDateMMDDYYY(rowData.estExitDate);

  //   return dateFormat;
  // };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  useEffect(() => {
    setLoader(true);
    if (dropdown.fundid !== undefined && dropdown.fundid > 0) {
      const parameters = {
        legalEntityId: dropdown.fundid,
      };

      dispatch(getThunkLateInvestmentExits(parameters))
        .then(() => {
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  }, [dropdown.fundid]);
  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };
  useEffect(() => {
    setExitDate(getListDateConversion(lateInvestmentExits, ["estExitDate"]));
  }, [lateInvestmentExits]);

  const stateNameWithCountry = (rowData: any) => {
    const name = rowData.state
      ? rowData.state +
        (rowData.country && rowData.country !== "United States"
          ? ", " + rowData.country
          : "")
      : "";
    return name;
  };
  const dataTableElements = [
    {
      field: "updatedToday",
      header: "Updated Today",
      style: { minWidth: "160px" },
    },
    {
      field: "estExitDate",
      header: "Est.Exit Date",
      dataType: "date",
      style: { minWidth: "150px" },
      body: (rowData: any) => formatDate(rowData.estExitDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
      style: { minWidth: "150px" },
    },
    {
      field: "productName",
      header: "Investment Name",
      style: { minWidth: "300px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.productName}>
          {rowData?.productName}
        </div>
      ),
    },
    {
      field: "supplierName",
      header: "Supplier Name",
      style: { minWidth: "150px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.supplierName}>
          {rowData?.supplierName}
        </div>
      ),
    },
    {
      field: "productListingAddress",
      header: "Address",
      style: { minWidth: "220px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.productListingAddress}>
          {rowData?.productListingAddress}
        </div>
      ),
    },
    {
      field: "city",
      header: "City",
      style: { minWidth: "120px" },
    },
    {
      field: "state",
      header: "State",
      body: stateNameWithCountry,
      style: { minWidth: "120px" },
    },
  ];
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const excelTableHeaders = [
    {
      field: "productID",
      header: "Product Id",
    },
    {
      field: "updatedToday",
      header: "Updated Today",
    },
    {
      field: "estExitDate",
      header: "Est.Exit Date",
      dataType: "date",
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
    },
    {
      field: "productName",
      header: "Investment Name",
    },
    {
      field: "supplierName",
      header: "Supplier Name",
    },
    {
      field: "productListingAddress",
      header: "Address",
    },
    {
      field: "city",
      header: "City",
    },
    {
      field: "state",
      header: "State",
    },
  ];
  return (
    <>
      {loader && <Loader />}
      {/* <div className="grid">
        {loader && <Loader />}

        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Late Investment Exits Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div> */}
      {/* <div className="grid"> */}
      {/* <div className="col-12">
        <div className="card my-3 tabWithoutboxshadow">
          <Card> */}
      {/* <h4 className="cardHeader">Late Investment Exits Report</h4>
      <div className="row my-4 d-flex justify-content-between"> </div> */}
      <DataTableComponent
        valueData={exitDate}
        fieldsElements={dataTableElements}
        isPaginationAllowed={true}
        isDownload={true}
        excelFieldsElements={excelTableHeaders}
        isFullView={true}
        // resizableColumns={false}
        // columnResizeMode="fit"
        HeadingName="Late Investment Exits Report"
        maxHeight="calc(-133px + 100vh)"
        scrollable={true}
        columnResizeMode="expand"
        fileName="Late Investment Exits Report"
        className="gridcell"
      />
      {/* </Card>
        </div>
      </div> */}
      {/* </div> */}
    </>
  );
}
