import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    visible: false,
  },
  loading: "loading",
  error: undefined,
};

const slice = createSlice({
  name: "sideBarState",
  initialState,
  reducers: {
    setSidebarState: (state) => {
      console.log("toggle inside slice");

      state.data.visible = !state.data.visible;
    },
  },
});
export const { setSidebarState } = slice.actions;
export default slice.reducer;
