import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addInvestorProfileService } from "../../../../../services/investor/investor";
import error from "../../../../../utils/error";
import { IInvestorProfilePayload } from ".";

interface InvestorProfile {
  // data?: IRedotResponse;
  data?: string;
  loading: string;
  error?: string | null;
}

const initialState: InvestorProfile = {
  data: undefined,
  loading: "loading",
  error: undefined,
};

export const addInvestorProfileThunk = createAsyncThunk(
  "investors/addInvestorProfile",
  async (data: IInvestorProfilePayload) => {
    try {
      const response = await addInvestorProfileService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "investors/addInvestorProfile",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(addInvestorProfileThunk.pending, (state) => {
      state.loading = "loading";
      // state.error = undefined;
    });
    builder.addCase(addInvestorProfileThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data;
    });
    builder.addCase(addInvestorProfileThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
      throw error(action.payload);
    });
  },
});

export default slice.reducer;
