import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MSAL from "./components/msal/MsalPage";
import DashboardSkeleton from "./pages/features/DashboardSkeleton";
import { getThunkMap } from "./store/Googlemap/slice";
import { getInvestingAccountsLookupsThunk } from "./store/Investor/investor-approval/investing-accounts-lookups/slice";
// import { getThunkPortfolioAssetDropdown } from "./store/assetdropdown/slice";
import { GetDwollaTokenThunk } from "./store/dwolla/token/slice";
import { getThunkColor, setColor } from "./store/features/color/slice";
import { getPortfolioGetLegalEntityList } from "./store/features/dropdown/slice";
import { isFundExistThunk } from "./store/features/fund-exist/slice";
import {
  getFundLogoThunk,
  getFundBannerThunk,
  getUserProfileDetailsThunk,
} from "./store/features/profile/slice";
import { setBannerState, setImageState } from "./store/getasset/slice";
import { useAppDispatch, useAppSelector } from "./store/hooks";

import { isAccountExistThunk } from "./store/multi-fund-registration/IsFundAccountExist/slice";
import { LightenDarkenColor, logoutHelper } from "./utils/helper";
import { TOKEN } from "./utils/sponsor/constants";
import { checkTokenExp } from "./utils/sponsor/permission.helper";

let onlyFirstTime = true;
const AuthPage = () => {
  const dispatch = useAppDispatch();

  const profileIsLoadFirst = useAppSelector(
    (state) => state.profile.isLoadFirsttime
  );

  const [isSulgValid, setIsSulgValid] = useState<boolean>(false);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const tenant = localStorage.getItem("tenentName");
  const urlPath = window.location.pathname.slice(1);

  const isTokenExp = checkTokenExp();

  if (isTokenExp) {
    logoutHelper();
  }

  useEffect(() => {
    (async () => {
      if (
        localStorage.getItem("tenentName") === "null" ||
        !localStorage.getItem("tenentName")
      ) {
        const resp: any = await dispatch(isFundExistThunk(""));
        const slugNames = await resp.payload._response.data.slugDetails;

        if (slugNames.includes(urlPath)) {
          localStorage.setItem("tenentName", urlPath);
        } else {
          localStorage.setItem("tenentName", slugNames[0]);
        }
        setIsSulgValid(true);
      } else {
        console.log("Check url", urlPath, urlPath.includes("/"));
        if (urlPath && !urlPath.includes("/")) {
          const resp: any = await dispatch(isFundExistThunk(urlPath));

          if (resp.payload._response.data.isFundExist) {
            navigate("/", {
              replace: true,
            });
            localStorage.setItem("tenentName", urlPath);
          }
        } else {
          await dispatch(isFundExistThunk(""));
        }
        setIsSulgValid(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (isSulgValid) {
      (async () => {
        // this will fire only on first render;
        if (onlyFirstTime && !profileIsLoadFirst) {
          if (tenant && tenant !== "noslug" && tenant !== "Logout") {
            dispatch(getFundBannerThunk())
              .then((res) => {
                console.log(
                  "getFundBannerThunk",
                  res.payload.response.data.blobURL
                );
                dispatch(setBannerState(res.payload.response.data.blobURL));
              })
              .catch((error: any) => {
                console.log("error", error);
              });
            dispatch(getFundLogoThunk())
              .then((res) => {
                console.log(
                  "getFundLogoThunk",
                  res.payload.response.data.blobURL
                );
                dispatch(setImageState(res.payload.response.data.blobURL));
              })
              .catch((error: any) => {
                console.log("error", error);
              });

            console.log("Loading issue debugggin");
          }
          const tenentName = localStorage.getItem("tenentName");

          if (!isTokenExp) {
            try {
              await dispatch(getThunkMap());

              await dispatch(getInvestingAccountsLookupsThunk());
              // await dispatch(getThunkPortfolioAssetDropdown()).then(() => {});
              console.log("getUserProfileDetailsThunk Called multiple time");
              await dispatch(
                getUserProfileDetailsThunk({ Slug: tenentName! })
              ).then((res: any) => {
                if (res.payload?._response?.data !== null) {
                  const LegalEntityparameters = {
                    Email: res.payload?._response?.data[0]?.emailID,
                  };
                  dispatch(
                    getPortfolioGetLegalEntityList(LegalEntityparameters)
                  );
                }
              });
              await dispatch(isAccountExistThunk({ fundName: tenentName! }));
              await dispatch(GetDwollaTokenThunk());
            } catch (error) {
              console.log("oops something went wrong", error);
            }
          }
          onlyFirstTime = false;
        }
      })();
    }

    return () => {};
  }, [token, isSulgValid]);

  useEffect(() => {
    // removeConsoleLogs(false);
    if (isSulgValid && tenant && tenant !== "noslug" && tenant !== "Logout") {
      dispatch(getThunkColor())
        .then((res) => {
          const Color = res.payload.response.data.primaryColour;
          const myColorLightest = LightenDarkenColor(Color, 200);
          const myColorLight = LightenDarkenColor(Color, 50);
          const myColorDark = LightenDarkenColor(Color, 20);
          const myColorDarkest = LightenDarkenColor(Color, 80);
          // console.log("light", myColorDark);
          dispatch(
            setColor({
              Color,
              myColorLightest,
              myColorLight,
              myColorDark,
              myColorDarkest,
            })
          );
          const root = document.documentElement;
          root?.style.setProperty("--light-gray", "#f5f5f7");
          if (Color) {
            root?.style.setProperty("--background-color", Color);
            root?.style.setProperty("--lightest-color", myColorLightest);
            root?.style.setProperty("--lighter-color", myColorLight);
            root?.style.setProperty("--Darker-color", myColorDark);
            root?.style.setProperty("--Darkest-color", myColorDarkest);
            root?.style.setProperty("--noData-color", "#C8C8C8");
          } else {
            root?.style.setProperty("--noData-color", "#C8C8C8");
            root?.style.setProperty("--background-color", "#004D82");
            // root?.style.setProperty("--text-color", "#FF0000");
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        });
      localStorage.setItem("dropdownChanged", "0");
    }
  }, [isSulgValid]);

  const url = window.location.pathname;

  return (
    <>
      {isSulgValid ? (
        url.toLowerCase() !== "/logout" ? (
          <MSAL pageName={TOKEN} />
        ) : (
          ""
        )
      ) : (
        <DashboardSkeleton />
      )}
    </>
  );
};

export default AuthPage;
