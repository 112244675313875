import { FormikValues } from "formik";
// import { Card } from "primereact/card";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React from "react";
import { formatPhoneNumber } from "../../../../../utils/helper";

interface IProps {
  accountTypeID: number;
  formik: FormikValues;
  isFormFieldInvalid: any;
  getFormErrorMessage: any;
}

const CustodianInformation: React.FC<IProps> = (props) => {
  return (
    <>
      <div className="row d-flex justify-content-start">
        <h4 className="investorHeader">Custodian Information</h4>
      </div>

      <div className="formgrid grid">
        <div className="field col-12 md:col-6 text-left">
          <label className="inputLabel">
            SDIRA Account Title <span className="text-red-500">*</span>
          </label>
          <InputText
            name="accountTitleSDIRA"
            value={props.formik.values.accountTitleSDIRA}
            // unmask={true}
            placeholder="Enter the Account Title of your SDIRA"
            onChange={(e) => {
              props.formik.setFieldValue(
                "accountTitleSDIRA",
                e.target.value?.trimStart()
              );
            }}
            className={classNames({
              "p-invalid": props.isFormFieldInvalid("accountTitleSDIRA"),
              "card-inputBox": true,
            })}
          />
          {props.getFormErrorMessage("accountTitleSDIRA")}
        </div>
        <div className="field col-12 md:col-6 text-left">
          <label className="inputLabel">
            Custodian Website <span className="text-red-500">*</span>{" "}
            <span className="text-xs">
              (Enter the complete URL with http:// or https://)
            </span>
          </label>
          <InputText
            name="custodianWebsite"
            value={props.formik.values.custodianWebsite}
            // unmask={true}
            placeholder="Enter your Custodian's Website"
            onChange={(e) => {
              props.formik.setFieldValue(
                "custodianWebsite",
                e.target.value?.trimStart()
              );
            }}
            className={classNames({
              "p-invalid": props.isFormFieldInvalid("custodianWebsite"),
              "card-inputBox": true,
            })}
          />
          {props.getFormErrorMessage("custodianWebsite")}
        </div>
        <div className="field col-12 md:col-6 text-left">
          <label className="inputLabel">
            Custodian Email <span className="text-red-500">*</span>
          </label>
          <InputText
            name="custodianEmail"
            value={props.formik.values.custodianEmail}
            // unmask={true}
            placeholder="Enter your Custodian’s Email"
            onChange={(e) => {
              props.formik.setFieldValue(
                "custodianEmail",
                e.target.value?.trimStart()
              );
            }}
            className={classNames({
              "p-invalid": props.isFormFieldInvalid("custodianEmail"),
              "card-inputBox": true,
            })}
          />
          {props.getFormErrorMessage("custodianEmail")}
        </div>
        <div className="field col-12 md:col-6 text-left">
          <label className="inputLabel">
            Custodian Phone Number <span className="text-red-500">*</span>
          </label>
          <div className="p-inputgroup inputgrp-h47 flex-1">
            <span className="p-inputgroup-addon">
              {/* {props.formik.values.countryCode
                ? "+" + props.formik.values.countryCode
                : "+1"}{" "} */}
              +1
            </span>
            <InputText
              name="custodianPhoneNumber"
              id="custodianPhoneNumber"
              placeholder="xxx-xxx-xxxx"
              value={
                props.formik.values.custodianPhoneNumber
                  ? formatPhoneNumber(
                      props.formik.values.custodianPhoneNumber
                        .split("-")
                        .join("")
                    )
                  : props.formik.values.custodianPhoneNumber
              }
              onChange={(e) => {
                props.formik.setFieldValue(
                  "custodianPhoneNumber",
                  formatPhoneNumber(e.target.value).split("-").join("")
                );
              }}
              className={classNames({
                "p-invalid": props.isFormFieldInvalid("custodianPhoneNumber"),
                inputphoneNo: true,
              })}
            />{" "}
          </div>
          {props.getFormErrorMessage("custodianPhoneNumber")}
        </div>
        <div className="field col-12 md:col-6 text-left">
          <label className="inputLabel">
            Custodian Name <span className="text-red-500">*</span>
          </label>
          <InputText
            name="custodianName"
            value={props.formik.values.custodianName}
            // unmask={true}
            placeholder="Enter your Custodian’s Name"
            onChange={(e) => {
              props.formik.setFieldValue(
                "custodianName",
                e.target.value?.trimStart()
              );
            }}
            className={classNames({
              "p-invalid": props.isFormFieldInvalid("custodianName"),
              "card-inputBox": true,
            })}
          />
          {props.getFormErrorMessage("custodianName")}
        </div>
        <div className="field col-12 md:col-6 text-left">
          <label className="inputLabel">
            Custodian EIN <span className="text-red-500">*</span>
          </label>
          <InputMask
            autoClear={false}
            name="custodianEIN"
            value={props.formik.values.ein}
            placeholder="xx-xxxxxxx"
            mask="99-9999999"
            onChange={(e) => {
              props.formik.setFieldValue("ein", e.target.value);
            }}
            className={classNames({
              "p-invalid": props.isFormFieldInvalid("ein"),
              "card-inputBox": true,
            })}
          />
          {props.getFormErrorMessage("ein")}
        </div>
        <div className="field col-12 md:col-6 text-left">
          <label className="inputLabel">
            Custodian Routing Number <span className="text-red-500">*</span>
          </label>
          <InputText
            name="custodianRoutingNumber"
            value={props.formik.values.custodianRoutingNumber}
            // unmask={true}
            // useGrouping={false}
            placeholder="Enter Custodian's Routing Number"
            // onChange={(e) => {
            //   props.formik.setFieldValue(
            //     "custodianRoutingNumber",
            //     e.target.value
            //   );
            // }}
            onChange={(e) => {
              const numericValue = e.target.value.replace(
                /[^\d\sA-Za-z-]/g,
                ""
              );

              props.formik.setFieldValue(
                "custodianRoutingNumber",
                numericValue
              );
            }}
            className={classNames({
              "p-invalid": props.isFormFieldInvalid("custodianRoutingNumber"),
              // "card-inputBox": true,
              "w-full": true,
            })}
          />
          {props.getFormErrorMessage("custodianRoutingNumber")}
        </div>
        <div className="field col-12 md:col-6 text-left">
          <label className="inputLabel">
            Custodian Account Number <span className="text-red-500">*</span>
          </label>
          <InputText
            name="custodianAccountNumber"
            value={props.formik.values.custodianAccountNumber}
            // unmask={true}
            // useGrouping={false}
            placeholder="Enter Custodian's Account Number"
            onChange={(e) => {
              const numericValue = e.target.value.replace(
                /[^\d\sA-Za-z-]/g,
                ""
              );

              props.formik.setFieldValue(
                "custodianAccountNumber",
                numericValue
              );
            }}
            className={classNames({
              "p-invalid": props.isFormFieldInvalid("custodianAccountNumber"),
              // "card-inputBox": true,
              "w-full": true,
            })}
          />
          {props.getFormErrorMessage("custodianAccountNumber")}
        </div>
      </div>
    </>
  );
};

export default CustodianInformation;
