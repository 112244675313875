import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFromFundListService } from "../../../services/Dwolla/dwolla";
import error from "../../../utils/error";
import { IFundDropdownFromState } from ".";

const initialState: IFundDropdownFromState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const GetFromFundListThunk = createAsyncThunk(
  "FundTransfer/FromDropdown",
  async (data: any) => {
    try {
      const response = await GetFromFundListService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "FundTransfer/Dropdown",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetFromFundListThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(GetFromFundListThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data : [];
    });
    builder.addCase(GetFromFundListThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
