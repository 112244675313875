// import { getConfig } from "../../b2c/env-config";
import { ISoftCommitparamter } from "../../types";
import apiFactory from "../../utils/api";
// import { getEnvConfig } from "../../utils/gateway/helper";
import { envVariable } from "../../utils/sponsor/helper";

// const env: string = getEnvConfig();
// const envVaiable = getConfig(env);

export function getOpportunitiesService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/Opportunity/getOpportunities?lpPartnerID=${data.lpPartnerID}&category=${
      data.category
    }&deals=${data.deals}&availablePageNo=${
      data.availablePageNo
    }&pageSize=6&legalEntityId=${data.legalEntityId}`
  );
}

export function getOpportunityImagesDataService(id: number) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/Opportunity/getOpportunityImagesData?productId=${id}`
  );
}

export function getOpportunityInvestmentDetailsService(id: number) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/Opportunity/getOpportunityDetails?productId=${id}`
  );
}

export function getOpportunityProjectUpdatesService(id: string) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/Opportunity/getOpportunityProjectUpdates?productId=${id}`
  );
}

export function getOpportunityRelevantDocumentsService(id: string) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/Opportunity/getOpportunityRelevantDocuments?productId=${id}`
  );
}

export function getOpportunityProjectDetailsService(id: string) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/Opportunity/getOpportunityProjectDetails?productId=${id}`
  );
}

export function createNewSoftCommitService(data: ISoftCommitparamter) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/Opportunity/addInvestmentAndSoftcommit`,
    data
  );
}

export function getfilledDealsForFundService(id: number) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTOR_SPNG_URL
    }/Opportunity/getFilledDealsOnFlag?legalEntityID=${id}`
  );
}
