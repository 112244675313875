import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HelpVedioGetVideoLinkThunk } from "../../store/features/sponser/manage-help-videos/getvideolink/slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const HelpVideoComponent = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentPath = location.pathname;
  console.log("Current Path:", currentPath);
  const menuurl = {
    MenuUrl: currentPath,
  };

  useEffect(() => {
    dispatch(HelpVedioGetVideoLinkThunk(menuurl)).finally(() => {});
  }, [currentPath]);
  const GetVideoLinkHere = useAppSelector(
    (state) => state.HelpVedioGetVideoLink?.data
  );
  console.log("GetVideoLinkHere", GetVideoLinkHere?.videoLink);
  // const url = GetVideoLinkHere?.videoLink;
  return <></>;
};
