import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetK1InputSupplierLabelService } from "../../../../../services/taxmanagement/tax";
import error from "../../../../../utils/error";
import { IK1InputSupplierLabelState } from ".";

const initialState: IK1InputSupplierLabelState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    k1LablesList: [],
  },
};
export const getThunkK1InputSupplierLabel = createAsyncThunk(
  "getK1InputSupplierLabel",
  async (data: any) => {
    try {
      const response = await GetK1InputSupplierLabelService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getK1InputSupplierLabel",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkK1InputSupplierLabel.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkK1InputSupplierLabel.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("set", action.payload);
      state.data.k1LablesList =
        action.payload._response.data !== null
          ? action.payload._response.data.k1LablesList
          : [];

      state.success = action.payload._response.success;
      state.message =
        action.payload._response.message === null
          ? undefined
          : action.payload._response.message;
    });
    builder.addCase(getThunkK1InputSupplierLabel.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});
export default slice.reducer;
