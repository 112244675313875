import { Button } from "primereact/button";
// import { TabView, TabPanel } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import ManagementDashboard from "../dashboard/ManagementDashboard";
import Dashboard from "../features/Dashboard";

export default function DashboardTabView() {
  const [tabName, setTabName] = useState("GetStuff");
  useEffect(() => {
    setTabName("GetStuff");
  }, []);

  let content;
  switch (tabName) {
    case "GetStuff":
      content = <Dashboard />;
      break;
    case "Overview":
      content = <ManagementDashboard />;
      break;
    default:
      content = <></>;
      break;
  }
  return (
    <div className="tabview-demo dashboard-tabs">
      {/* <TabView>
        <TabPanel header="Get Stuff Done">
          <Dashboard /> 
        </TabPanel>
        <TabPanel header="Overview">
          <ManagementDashboard /> 
        </TabPanel>
      </TabView> */}
      {tabName === "GetStuff" ? (
        <div className="grid">
          <div className="col-12 md:col-6 mt-2">
            <div className="card pgHeaderWrap md:my-2 mt-3">
              <h1 className="pageHeader">Get Stuff Done</h1>
              <span className="pageSubHeader">
                Functional map for fund management
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 mt-2">
            <div></div>
          </div>
        </div>
      ) : (
        <div className="grid">
          <div className="col-12 md:col-6 mt-2">
            <div className="card pgHeaderWrap md:my-2 mt-3">
              <h1 className="pageHeader">Fund Dashboard</h1>
              <span className="pageSubHeader">Fund Overview Statistics</span>
            </div>
          </div>
          <div className="col-12 md:col-6 mt-2">
            <div></div>
          </div>
        </div>
      )}

      <div className="tabHeader-wrap grid">
        <div className="m-2">
          <Button
            className={
              tabName === "GetStuff" ? "tabHeadText Active" : "tabHeadText"
            }
            onClick={(e) => {
              e.preventDefault();
              setTabName("GetStuff");
            }}
          >
            <span className="ml-auto mr-auto ">Get Stuff Done</span>
          </Button>
        </div>
        <div className="m-2">
          <Button
            className={
              tabName === "Overview" ? "tabHeadText Active" : "tabHeadText"
            }
            onClick={(e) => {
              e.preventDefault();
              setTabName("Overview");
            }}
          >
            <span className="ml-auto mr-auto ">Fund Dashboard</span>
          </Button>
        </div>
      </div>

      <div className="mt-2">{content}</div>
    </div>
  );
}
