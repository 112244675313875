import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
// import { RiSearchLine } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getConsolidateplatfomsCommits } from "../../../../store/features/reports/consolidated-platfrom/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";

export default function ConsolidatedPlatform() {
  const dispatch = useAppDispatch();
  const profileData = useAppSelector((state) => state.profile.data![0]);

  const consolidatedPlatformmodel = useAppSelector(
    (state) => state.consolidatedPlatform.data
  );
  const [loading, setloading] = useState<any>(false);

  const [exitDate, setExitDate] = useState<any>();
  // console.log("h", misssedExitDates);
  // const getExitDateConversion = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedExitDate = {
  //       ...d,
  //       fundMaxDate: new Date(d.fundMaxDate),
  //     };
  //     return modifiedExitDate;
  //   });
  // };
  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const formatCurrencyAnyData = (rowData: any) => {
    const finalValue = Number(rowData);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const formatfundAUMCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.fundAUM);
  };
  const formatinvestorAUMCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.investorAUM);
  };
  const formatsoftCommitValueCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.softCommitValue);
  };

  const dataTableElements = [
    {
      field: "legalEntityName",
      header: "Legal Entity Name",
      style: { minWidth: "240px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.legalEntityName}>
          {rowData?.legalEntityName}
        </div>
      ),
    },
    {
      field: "leCount",
      header: "# of funds",
      style: { minWidth: "150px" },
    },
    {
      field: "fundAUM",
      header: "Fund AUM",
      body: formatfundAUMCurrency,
      style: { minWidth: "200px" },
    },
    {
      field: "fundMaxDate",
      header: "Fund DAV Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.fundMaxDate),
      filterElement: dateFilterTemplate,
    },
    {
      field: "investorAUM",
      header: "Investor AUM",
      body: formatinvestorAUMCurrency,
    },
    {
      field: "invMaxDate",
      header: "Inv DAV Date",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.invMaxDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "150px" },
    },
    {
      field: "invCount",
      header: "# of investors",
      style: { minWidth: "150px" },
    },
    {
      field: "userCount",
      header: "# of fund operation users",
      style: { minWidth: "230px" },
    },
    {
      field: "regProcessCount",
      header: "# of registrations in process",
      style: { minWidth: "240px" },
    },
    {
      field: "dealsCount",
      header: "# of deals",
      style: { minWidth: "150px" },
    },
    {
      field: "investmentCount",
      header: "# of investments",
      style: { minWidth: "180px" },
    },
    {
      field: "softCommitCount",
      header: "# number of soft commits",
      style: { minWidth: "230px" },
    },
    {
      field: "softCommitValue",
      header: "# value of soft commits",
      body: formatsoftCommitValueCurrency,
      style: { minWidth: "220px" },
    },
  ];

  const excelTableHeaders = [
    {
      field: "legalEntityName",
      header: "Legal Entity Name",
    },
    {
      field: "leCount",
      header: "# of funds",
    },
    {
      field: "fundAUM",
      header: "Fund AUM",
      dataType: "currency",
    },
    {
      field: "fundMaxDate",
      header: "Fund DAV Date",
      dataType: "date",
    },
    {
      field: "investorAUM",
      header: "Investor AUM",
      dataType: "currency",
    },
    {
      field: "invMaxDate",
      header: "Inv DAV Date",
      dataType: "date",
    },
    {
      field: "invCount",
      header: "# of investors",
    },
    {
      field: "userCount",
      header: "# of fund operation users",
    },
    {
      field: "regProcessCount",
      header: "# of registrations in process",
    },
    {
      field: "dealsCount",
      header: "# of deals",
    },
    {
      field: "investmentCount",
      header: "# of investments",
    },
    {
      field: "softCommitCount",
      header: "# number of soft commits",
    },
    {
      field: "softCommitValue",
      header: "# value of soft commits",
      dataType: "currency",
    },
  ];
  useEffect(() => {
    setloading(true);

    if (profileData && profileData?.emailID !== "") {
      const hasSpecialCharacter = /[!#$%&()*+,./:;<>?@[\\\]^_{}~-]/.test(
        profileData.emailID
      );

      dispatch(
        getConsolidateplatfomsCommits(
          hasSpecialCharacter
            ? encodeURIComponent(profileData.emailID)
            : profileData.emailID
        )
      )
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    } else {
      setloading(false);
    }
  }, [profileData]);
  useEffect(() => {
    setExitDate(
      getListDateConversion(consolidatedPlatformmodel, [
        "fundMaxDate",
        "invMaxDate",
      ])
    );
  }, [consolidatedPlatformmodel]);
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  // const rowDta = 10;

  return (
    <>
      {loading && <Loader />}
      {/* <div className="grid">
        {loading && <Loader />}

        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Consolidated Platform Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div> */}
      {/* <div className="grid">
        <div className="col-12">
          <div className="card my-3 tabWithoutboxshadow">
            <Card>
              <h4 className="cardHeader">Consolidated Platform Report</h4>
              <div className="row my-4 d-flex justify-content-between">
                {" "} */}
      {/* <div className="col-sm-4 mb-2">
              <RiSearchLine className="mr-2" />
              <InputText
                // value={filterValue}
                // onChange={(e) => setFilterValue(e.target.value)}
                placeholder="Search..."
              />
            </div> */}
      {/* </div> */}
      <DataTableComponent
        valueData={exitDate}
        fieldsElements={dataTableElements}
        isPaginationAllowed={true}
        isDownload={true}
        scrollable={true}
        isFullView={true}
        columnResizeMode="expand"
        excelFieldsElements={excelTableHeaders}
        maxHeight={"calc(-133px + 100vh)"}
        HeadingName="Consolidated Platform Report"
        className="gridcell"
        fileName="Consolidated Platform Report"
      />
      {/* <DataTable
            className="investmentTbl"
            paginator={consolidatedPlatformmodel!.length > rowDta}
            rows={10}
            // ref={dt}
            rowsPerPageOptions={[10, 20, 50]}
            value={exitDate}
            showGridlines
            responsiveLayout="scroll"
            // globalFilter={filterValue}
            // sortOrder={-1}
          >
            <Column
              
              sortable
              filter
              style={{ width: "30" }}
            ></Column>
            <Column
              field="leCount"
              header="# of funds"
              sortable
              filter
            ></Column>
            <Column
              field="fundAUM"
              header="Fund AUM"
              sortable
              filter
            ></Column>
            <Column
              field="fundMaxDate"
              header="Fund DAV Date"
              sortable
              filter
              dataType="date"
              body={formatDate}
              filterElement={dateFilterTemplate}
            ></Column>
            <Column
              field="investorAUM"
              header="Investor AUM"
              sortable
              filter
            ></Column>
            <Column
              field="invCount"
              header="# of investors"
              sortable
              filter
            ></Column>
            <Column
              field="userCount"
              header="# of fund operation users"
              // style={{ width: "30%" }}
              sortable
              // body={doucmentCompleteNameTemplate}
              filter
              // className="ivestmentColumn"
            ></Column>
            <Column
              field="regProcessCount"
              header="# of registrations in process"
              sortable
              filter
              // filterElement={dateFilterTemplate}
            ></Column>
            <Column
              field="dealsCount"
              header="# of deals"
              sortable
              filter
            ></Column>
            <Column
              field="investmentCount"
              header="# of investments"
              sortable
              filter
            ></Column>
            <Column
              field="softCommitCount"
              header="# number of soft commits"
              sortable
              filter
            ></Column>
            <Column
              field="softCommitValue"
              header="# value of soft commits"
              sortable
              filter
            ></Column>
           
            <Column
              field="invMaxDate"
              header="Inv DAV Date"
              sortable
              filter
            ></Column>
          </DataTable>{" "} */}
      {/* </Card>
          </div>
        </div>
      </div> */}
    </>
  );
}
