import { Button } from "primereact/button";
import React, { useState } from "react";
import { RiAddFill } from "react-icons/ri";
import { setAddCancelState } from "../../../../store/features/operation/setup-fund/create-update-state/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import SetupFundForm from "../../common-component/set-up-form/SetupFundForm";

export default function SetupFund() {
  const dispatch = useAppDispatch();
  const [showButton, setShowButton] = useState(false);
  const addCancelSetup = useAppSelector(
    (state) => state.addCancelSetupState.data
  );
  const onCancel = () => {
    dispatch(
      setAddCancelState({ ...addCancelSetup, add: false, cancel: true })
    );
    setShowButton(false);
  };
  return (
    <>
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Setup Fund </h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div className=" mb-2 pt-0 col-12 flex justify-content-end">
            {showButton === false ? (
              <Button
                onClick={() => {
                  dispatch(
                    setAddCancelState({
                      add: true,
                      cancel: false,
                      fundId: 0,
                    })
                  );
                  setShowButton(true);
                }}
                className="btn-navActive m-2"
              >
                <span className="ml-auto mr-auto ">
                  {" "}
                  Create New Fund
                  <span className="pl-2">
                    <RiAddFill />
                  </span>
                </span>
              </Button>
            ) : (
              <></>
            )}

            {showButton === true ? (
              <Button
                onClick={() =>
                  // dispatch(setAddCancelState({ add: false, cancel: true }))
                  onCancel()
                }
                className="btn-navActive m-2"
              >
                {" "}
                <span className="ml-auto mr-auto "> Cancel</span>
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <SetupFundForm pageName="SetupFund" />
    </>
  );
}
