// import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
// import { Toast } from "primereact/toast";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, {
  useEffect,
  useRef,
  // useRef,
  useState,
} from "react";

import { RiCloseCircleLine, RiCheckboxCircleLine } from "react-icons/ri";

import DataTableComponent from "../../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../../components/loader/Loader";
import { GetRoleListThunk } from "../../../../store/features/accountatatement/rolemanagement/managerole/getmanagerole/slice";
import { GetCopyRoleListThunk } from "../../../../store/features/accountatatement/rolemanagement/role-capability/getcopyrolelist/slice";
import { GetCapabilityMenuListThunk } from "../../../../store/features/accountatatement/rolemanagement/role-capability/getmenulist/slice";
import { SetGrantRevokeMenuThunk } from "../../../../store/features/accountatatement/rolemanagement/role-capability/revokemenu/slice";
import { SaveMenuCapabilityThunk } from "../../../../store/features/accountatatement/rolemanagement/role-capability/savemenucapability/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { successToastMessage } from "../../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function ManageRoleCapability() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  //   const [id, setId] = useState<any>();
  //   const [name, setName] = useState<any>();
  const [copyAccess, setCopyAccess] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState<any>(false);
  const [roleState, setRoleState] = useState<any>();

  const profileData = useAppSelector((state) => state.profile.data![0]);
  const getRoleList = useAppSelector((state) => state.GetRoleList.data);
  const getCopyRoleList = useAppSelector((state) => state.GetCopyRoleList.data);
  const getmenulist = useAppSelector(
    (state) => state.GetCapabilityMenuList.data
  );
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const [openDailog, setOpenDailog] = useState(false);

  const formik = useFormik({
    initialValues: {
      role: "",
      copyRole: "",
    },
    validate: (data: any) => {
      const errors: any = {};

      if (data.role === "") {
        errors.role = "Please select role";
      }
      if (!data.copyRole) {
        errors.copyRole = "Please select role to copy from";
      }

      return errors;
    },
    onSubmit: async () => {
      setCopyAccess(true);
      const data = {
        RoleId: +formik.values.copyRole,
      };
      setloading(true);
      dispatch(GetCapabilityMenuListThunk(data)).then(() => {
        setloading(false);
      });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const editButton = (rowData: any) => {
    // Pass rowData as a parameter

    const handleEdit = () => {
      //   setCountryId(rowData.id);
      const revokData = {
        RoleId: rowData.roleId ? rowData.roleId : formik.values.role,
        MenuId: rowData.id,
        Type: rowData.role ? "R" : "G",
        LoginId: profileData.userID,
      };
      const data = {
        RoleId: +formik.values.role,
      };
      setloading(true);

      dispatch(SetGrantRevokeMenuThunk(revokData)).then(() => {
        setloading(false);
        dispatch(GetCapabilityMenuListThunk(data));
      });
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={handleEdit}
          >
            {rowData.role ? (
              <span className="text-white">Revoke</span>
            ) : (
              <span className="text-white">Grant</span>
            )}
          </Button>
        </div>
      </div>
    );
  };

  const onSave = () => {
    console.log("onsave");

    setCopyAccess(false);
    const req = {
      FromRoleId: +formik.values.copyRole,
      ToRoleId: +formik.values.role,
      LoginId: profileData.userID,
    };
    setloading(true);
    dispatch(SaveMenuCapabilityThunk(req)).then(() => {
      successToastMessage(toast, "Data saved successfully");
      setloading(false);

      const data = {
        RoleId: +formik.values.role,
      };
      dispatch(GetCapabilityMenuListThunk(data));
      formik.resetForm();
      formik.setFieldValue("role", roleState);
    });
  };

  const stepStatus = (rowData: any) => {
    return rowData.role ? (
      // Code to execute if condition1 is true
      <RiCheckboxCircleLine className="icons-wrap" />
    ) : (
      // Code to execute if condition2 is true and condition1 is false
      <RiCloseCircleLine className="icons-wrap-cross" />
    );
  };

  const dataTableElements = [
    {
      field: "value",
      header: "Access",
      style: { minWidth: "200px" },
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
    },

    {
      field: "targetReturn",
      header: "",
      style: { minWidth: "120px" },
      body: stepStatus,
    },
    {
      field: "menuName",
      header: "Menu Name",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.menuName}>
          {rowData?.menuName}
        </div>
      ),
      //   body: (rowData: any) => deleteButton(rowData),
    },
  ];

  //   const resetForm = () => {
  //     // setName("");
  //   };

  useEffect(() => {
    const req = {
      // eslint-disable-next-line unicorn/no-null
      UserId: 0,
      LegalEntityId: 0,
    };
    setloading(true);
    dispatch(GetRoleListThunk(req));
    dispatch(GetCopyRoleListThunk()).then(() => {
      setloading(false);
    });
  }, []);
  useEffect(() => {
    formik.resetForm();
  }, [fundDropdown?.fundid]);
  useEffect(() => {
    const data = {
      RoleId: +formik.values.role,
    };

    dispatch(GetCapabilityMenuListThunk(data));
  }, [+formik.values.role, fundDropdown?.fundid]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      <div className="grid">
        {loading && <Loader />}
        <Toast ref={toast} className="themeToast" />

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Manage Role-Capability Mapping</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              // disabled={
              //   getmenulist === undefined ||
              //   getmenulist?.length === 0 ||
              //   getmenulist === null
              // }
              disabled={!formik.values.role}
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Are you sure you want to delete ?
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              //   onClick={onDelete}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>

        <div className="col-12">
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column md:col-4 col-4 ">
              {/* <label className="inputLabel">Select Role *</label> */}
              <Dropdown
                name="role"
                style={{ width: "100%" }}
                options={getRoleList}
                optionLabel="value"
                optionValue="key"
                filter
                placeholder="Select Role*"
                onChange={(e) => {
                  formik.setFieldValue("role", e.value);
                  setRoleState(e.value);
                }}
                value={formik.values.role}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("role"),
                  "card-inputBox": true,
                })}
              />
              {getFormErrorMessage("role")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column md:col-4 col-4 ">
              {/* <label className="inputLabel">Select Role to Copy From *</label> */}
              <Dropdown
                name="copyRole"
                style={{ width: "100%" }}
                options={getCopyRoleList}
                optionLabel="value"
                optionValue="key"
                filter
                placeholder="Select Role to Copy From*"
                onChange={(e) => formik.setFieldValue("copyRole", e.value)}
                value={formik.values.copyRole}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("copyRole"),
                  "card-inputBox": true,
                })}
              />
              {getFormErrorMessage("copyRole")}
            </div>
            <div className="d-flex sm:flex-column md:col-4 col-4 align-content-center md:justify-content-start justify-content-center">
              {/* <label className="inputLabel">&nbsp;</label> */}
              {copyAccess ? (
                <Button
                  className="mr-4 btn-nav justify-content-center"
                  type="button"
                  onClick={onSave}
                >
                  Save{" "}
                </Button>
              ) : (
                <Button
                  className="mr-4 btn-nav justify-content-center"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  COPY ACCESS{" "}
                </Button>
              )}
            </div>
          </div>
          {formik.values.role && (
            <div className="">
              {/* <Card> */}
              <DataTableComponent
                valueData={getmenulist!}
                fieldsElements={dataTableElements}
                isPaginationAllowed={true}
                className="gridcell deletebtn"
                columnResizeMode="expand"
                scrollable={true}
                maxHeight={"calc(-220px + 100vh)"}
                isFullView={true}
                setOpenDailog={setOpenDailog}
                openDailog={openDailog}
              />
              {/* </Card> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
