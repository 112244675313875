import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFundTypeListService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IFundTypeDropdownState } from ".";

const initialState: IFundTypeDropdownState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: undefined,
};
export const getThunkFundTypeDropdown = createAsyncThunk(
  "fundTypeDropdown",
  async () => {
    try {
      const response = await GetFundTypeListService();
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "fundTypeDropdown",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkFundTypeDropdown.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkFundTypeDropdown.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);

      state.data =
        action.payload._response.data !== null
          ? action.payload._response.data
          : {
              fundtype: [],
              entitytype: [],
            };
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(getThunkFundTypeDropdown.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
