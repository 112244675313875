import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFundProfileService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IGetSetupFundState } from ".";

const initialState: IGetSetupFundState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    fundProfileDetails: undefined,
    fundGPEmailData: undefined,
    fundAdminData: undefined,
    fundGPProfileDetails: undefined,
    fundbankAndMailingDetails: undefined,
    thirdPartyAPIkeys: undefined,
    edgarDetails: undefined,
  },
};
export const getThunkSetupFund = createAsyncThunk(
  "getSetupFund",
  async (data: any) => {
    try {
      const response = await GetFundProfileService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getSetupFund",
  initialState,
  reducers: {
    setSetupFundForm: (state, action) => {
      console.log("ac", action.payload);
      state.data = action.payload.data;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getThunkSetupFund.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkSetupFund.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("hr", action.payload._response.data);
      if (action.payload._response.data !== null) {
        // console.log("hfid", action.payload._response.data.edgarDetails[0]);
        state.data.edgarDetails = action.payload._response.data.edgarDetails[0];

        state.data.fundGPProfileDetails =
          action.payload._response.data.fundGPProfileDetails![0];
        state.data.fundProfileDetails =
          action.payload._response.data.fundProfileDetails![0];
        state.data.fundAdminData =
          action.payload._response.data.fundAdminData![0];
        state.data.fundbankAndMailingDetails =
          action.payload._response.data.fundbankAndMailingDetails![0];
        state.data.thirdPartyAPIkeys =
          action.payload._response.data.thirdPartyAPIkeys![0];
      }
      state.success = action.payload._response.success;
      state.message = action.payload._response.message;
    });
    builder.addCase(getThunkSetupFund.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      state.error = error(action.payload);
      throw new Error(state.error);
    });
  },
});

export const { setSetupFundForm } = slice.actions;
export default slice.reducer;
