import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";
import Loader from "../../components/loader/Loader";
import "./Quest.scss";
import { useAppDispatch } from "../../store/hooks";
import { storeQuestThunk } from "../../store/quest/slice";
import {
  formatQuestData,
  initialQuestValue,
  questValidation,
} from "../../utils/quest";
import {
  errorToastMessage,
  successToastMessage,
} from "../../utils/toast-message";

const Quest: React.FC = () => {
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: initialQuestValue,
    validate: (data: any) => {
      let errors: any = {};
      errors = questValidation(data, errors);
      return errors;
    },
    onSubmit: (data) => {
      setLoading(true);
      const res = formatQuestData(data);
      console.log("on submit", res);
      dispatch(storeQuestThunk(res))
        .then((response: any) => {
          if (response?.payload?.data?.success) {
            successToastMessage(
              toast,
              response?.payload?.data?.message || "Data submitted successfully"
            );
            formik.resetForm();
          } else {
            errorToastMessage(toast, "Something went wrong");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          errorToastMessage(toast, "Something went wrong");
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any, index = "", fieldName = "") => {
    let touched;
    let error;
    if (index !== "" && fieldName !== "") {
      touched = formikTouched[name]?.[index]?.[fieldName];
      error = formikErrors[name]?.[index]?.[fieldName];
    } else {
      touched = formikTouched[name];
      error = formikErrors[name];
    }
    return !!(touched && error);
    // return true;
  };

  const getFormErrorMessage = (name: any, index = "", fieldName = "") => {
    let error;
    index !== "" && fieldName !== ""
      ? (error = formikErrors[name]?.[index]?.[fieldName])
      : (error = formikErrors[name]);

    return (
      isFormFieldInvalid(name, index, fieldName) && (
        <small className="p-error">{error}</small>
      )
    );
  };

  return (
    <>
      <Toast ref={toast} className="themeToast" />
      {loading && <Loader />}
      <div className="container-fluid page-body-wrapper">
        <div className="main-quest">
          <div className="page-background bg-white">
            <div className="flex justify-content-center align-items-center banner-container">
              <div className="banner">
                Capital Quest Instructions & Scoring Sheet
              </div>
            </div>
            <div className="flex row mt-5" id="stage-0">
              <div className="col-md-2 col-sm-2">
                <div className="stage-one btn-bg-green rounded">
                  <a
                    href="https://www.avestorinc.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read Instructions
                  </a>
                </div>
              </div>
              <div className="col-md-10 col-sm-10 gap-2 flex flex-column">
                <div className="row flex gap-2 align-items-center ">
                  <div className="col-md-3 col-sm-3">
                    <Button
                      className="new-game"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.resetForm();
                      }}
                    >
                      New Game
                    </Button>
                  </div>
                  <div className="col-md-2 col-sm-2">
                    <div className="player-div rounded">
                      <span>Player 1</span>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-2">
                    <div className="player-div rounded">
                      <span>Player 2</span>
                    </div>{" "}
                  </div>
                  <div className="col-md-2 col-sm-2">
                    <div className="player-div rounded">
                      <span>Player 3</span>
                    </div>{" "}
                  </div>
                  <div className="col-md-2 col-sm-2">
                    <div className="player-div rounded">
                      <span>Player 4</span>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} className="p-fluid">
              <div className="flex row mt-2" id="stage-1">
                <div className="col-md-2 col-sm-2">
                  <div className="stage-one">
                    <span> Stage 1</span>
                  </div>
                </div>
                <div className="col-md-10 col-sm-10 gap-2 flex flex-column">
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-green col-md-3 col-sm-3">
                      <span>Name Your Fund</span>
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputText
                        className="fund-input-padding"
                        placeholder="Type here"
                        name="stage1_namefund1"
                        value={formik.values.stage1_namefund1}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_namefund1",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputText
                        className="fund-input-padding"
                        placeholder="Type here"
                        name="stage1_namefund2"
                        value={formik.values.stage1_namefund2}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_namefund2",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputText
                        className="fund-input-padding"
                        placeholder="Type here"
                        name="stage1_namefund3"
                        value={formik.values.stage1_namefund3}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_namefund3",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputText
                        className="fund-input-padding"
                        placeholder="Type here"
                        name="stage1_namefund4"
                        value={formik.values.stage1_namefund4}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_namefund4",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center  ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Activity 1 Points
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_activity1_point1"
                        value={
                          formik.values.stage1_activity1_point1 !== 0
                            ? formik.values.stage1_activity1_point1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_activity1_point1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_activity1_point2"
                        value={
                          formik.values.stage1_activity1_point2 !== 0
                            ? formik.values.stage1_activity1_point2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_activity1_point2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_activity1_point3"
                        value={
                          formik.values.stage1_activity1_point3 !== 0
                            ? formik.values.stage1_activity1_point3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_activity1_point3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_activity1_point4"
                        value={
                          formik.values.stage1_activity1_point4 !== 0
                            ? formik.values.stage1_activity1_point4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_activity1_point4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Activity 2 Points
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_activity2_point1"
                        value={
                          formik.values.stage1_activity2_point1 !== 0
                            ? formik.values.stage1_activity2_point1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_activity2_point1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_activity2_point2"
                        value={
                          formik.values.stage1_activity2_point2 !== 0
                            ? formik.values.stage1_activity2_point2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_activity2_point2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_activity2_point3"
                        value={
                          formik.values.stage1_activity2_point3 !== 0
                            ? formik.values.stage1_activity2_point3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_activity2_point3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_activity2_point4"
                        value={
                          formik.values.stage1_activity2_point4 !== 0
                            ? formik.values.stage1_activity2_point4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_activity2_point4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Activity 3 Points
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_activity3_point1"
                        value={
                          formik.values.stage1_activity3_point1 !== 0
                            ? formik.values.stage1_activity3_point1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_activity3_point1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_activity3_point2"
                        value={
                          formik.values.stage1_activity3_point2 !== 0
                            ? formik.values.stage1_activity3_point2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_activity3_point2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_activity3_point3"
                        value={
                          formik.values.stage1_activity3_point3 !== 0
                            ? formik.values.stage1_activity3_point3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_activity3_point3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_activity3_point4"
                        value={
                          formik.values.stage1_activity3_point4 !== 0
                            ? formik.values.stage1_activity3_point4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage1_activity3_point4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Total Points
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_total_point1"
                        value={
                          formik.values.stage1_total_point1 !== 0
                            ? formik.values.stage1_total_point1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage1_total_point1", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_total_point2"
                        value={
                          formik.values.stage1_total_point2 !== 0
                            ? formik.values.stage1_total_point2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage1_total_point2", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_total_point3"
                        value={
                          formik.values.stage1_total_point3 !== 0
                            ? formik.values.stage1_total_point3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage1_total_point3", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_total_point4"
                        value={
                          formik.values.stage1_total_point4 !== 0
                            ? formik.values.stage1_total_point4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage1_total_point4", e.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray-one col-md-3 col-sm-3">
                      Stage 1 Bouns $$$
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_bonus1"
                        value={
                          formik.values.stage1_bonus1 !== 0
                            ? formik.values.stage1_bonus1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage1_bonus1", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_bonus2"
                        value={
                          formik.values.stage1_bonus2 !== 0
                            ? formik.values.stage1_bonus2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage1_bonus2", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_bonus3"
                        value={
                          formik.values.stage1_bonus3 !== 0
                            ? formik.values.stage1_bonus3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage1_bonus3", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage1_bonus4"
                        value={
                          formik.values.stage1_bonus4 !== 0
                            ? formik.values.stage1_bonus4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage1_bonus4", e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex row mt-5" id="stage-2">
                <div className="col-md-2 col-sm-2">
                  <div className="stage-one">
                    <span> Stage 2</span>
                  </div>
                </div>
                <div className="col-md-10 col-sm-10 gap-2 flex flex-column">
                  <div className="row flex gap-2 align-items-center  ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Activity 4 Points
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_activity4_point1"
                        value={
                          formik.values.stage2_activity4_point1 !== 0
                            ? formik.values.stage2_activity4_point1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_activity4_point1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_activity4_point2"
                        value={
                          formik.values.stage2_activity4_point2 !== 0
                            ? formik.values.stage2_activity4_point2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_activity4_point2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_activity4_point3"
                        value={
                          formik.values.stage2_activity4_point3 !== 0
                            ? formik.values.stage2_activity4_point3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_activity4_point3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_activity4_point4"
                        value={
                          formik.values.stage2_activity4_point4 !== 0
                            ? formik.values.stage2_activity4_point4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_activity4_point4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Activity 5 Points
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_activity5_point1"
                        value={
                          formik.values.stage2_activity5_point1 !== 0
                            ? formik.values.stage2_activity5_point1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_activity5_point1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_activity5_point2"
                        value={
                          formik.values.stage2_activity5_point2 !== 0
                            ? formik.values.stage2_activity5_point2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_activity5_point2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_activity5_point3"
                        value={
                          formik.values.stage2_activity5_point3 !== 0
                            ? formik.values.stage2_activity5_point3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_activity5_point3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_activity5_point4"
                        value={
                          formik.values.stage2_activity5_point4 !== 0
                            ? formik.values.stage2_activity5_point4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_activity5_point4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Activity 6 Points
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_activity6_point1"
                        value={
                          formik.values.stage2_activity6_point1 !== 0
                            ? formik.values.stage2_activity6_point1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_activity6_point1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_activity6_point2"
                        value={
                          formik.values.stage2_activity6_point2 !== 0
                            ? formik.values.stage2_activity6_point2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_activity6_point2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_activity6_point3"
                        value={
                          formik.values.stage2_activity6_point3 !== 0
                            ? formik.values.stage2_activity6_point3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_activity6_point3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_activity6_point4"
                        value={
                          formik.values.stage2_activity6_point4 !== 0
                            ? formik.values.stage2_activity6_point4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_activity6_point4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray-one col-md-3 col-sm-3">
                      Total investores in your fund
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_totalinvester1"
                        value={
                          formik.values.stage2_totalinvester1 !== 0
                            ? formik.values.stage2_totalinvester1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_totalinvester1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_totalinvester2"
                        value={
                          formik.values.stage2_totalinvester2 !== 0
                            ? formik.values.stage2_totalinvester2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_totalinvester2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_totalinvester3"
                        value={
                          formik.values.stage2_totalinvester3 !== 0
                            ? formik.values.stage2_totalinvester3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_totalinvester3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        placeholder="Type here"
                        name="stage2_totalinvester4"
                        value={
                          formik.values.stage2_totalinvester4 !== 0
                            ? formik.values.stage2_totalinvester4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage2_totalinvester4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex row mt-5" id="stage-3">
                <div className="col-md-2 col-sm-2">
                  <div className="stage-one">
                    <span> Stage 3</span>
                  </div>
                </div>
                <div className="col-md-10 col-sm-10 gap-2 flex flex-column">
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Roll 1 capital raised
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll1_capital1"
                        value={
                          formik.values.stage3_roll1_capital1 !== 0
                            ? formik.values.stage3_roll1_capital1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll1_capital1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll1_capital2"
                        value={
                          formik.values.stage3_roll1_capital2 !== 0
                            ? formik.values.stage3_roll1_capital2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll1_capital2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll1_capital3"
                        value={
                          formik.values.stage3_roll1_capital3 !== 0
                            ? formik.values.stage3_roll1_capital3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll1_capital3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll1_capital4"
                        value={
                          formik.values.stage3_roll1_capital4 !== 0
                            ? formik.values.stage3_roll1_capital4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll1_capital4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Roll 2 capital raised
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll2_capital1"
                        value={
                          formik.values.stage3_roll2_capital1 !== 0
                            ? formik.values.stage3_roll2_capital1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll2_capital1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll2_capital2"
                        value={
                          formik.values.stage3_roll2_capital2 !== 0
                            ? formik.values.stage3_roll2_capital2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll2_capital2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll2_capital3"
                        value={
                          formik.values.stage3_roll2_capital3 !== 0
                            ? formik.values.stage3_roll2_capital3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll2_capital3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll2_capital4"
                        value={
                          formik.values.stage3_roll2_capital4 !== 0
                            ? formik.values.stage3_roll2_capital4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll2_capital4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Roll 3 {`(if needed)`} capital raised
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll3_capital1"
                        value={
                          formik.values.stage3_roll3_capital1 !== 0
                            ? formik.values.stage3_roll3_capital1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll3_capital1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll3_capital2"
                        value={
                          formik.values.stage3_roll3_capital2 !== 0
                            ? formik.values.stage3_roll3_capital2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll3_capital2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll3_capital3"
                        value={
                          formik.values.stage3_roll3_capital3 !== 0
                            ? formik.values.stage3_roll3_capital3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll3_capital3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll3_capital4"
                        value={
                          formik.values.stage3_roll3_capital4 !== 0
                            ? formik.values.stage3_roll3_capital4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll3_capital4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Roll 4 {`(if needed)`}capital raised
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll4_capital1"
                        value={
                          formik.values.stage3_roll4_capital1 !== 0
                            ? formik.values.stage3_roll4_capital1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll4_capital1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll4_capital2"
                        value={
                          formik.values.stage3_roll4_capital2 !== 0
                            ? formik.values.stage3_roll4_capital2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll4_capital2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll4_capital3"
                        value={
                          formik.values.stage3_roll4_capital3 !== 0
                            ? formik.values.stage3_roll4_capital3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll4_capital3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_roll4_capital4"
                        value={
                          formik.values.stage3_roll4_capital4 !== 0
                            ? formik.values.stage3_roll4_capital4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage3_roll4_capital4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray-one col-md-3 col-sm-3">
                      Total capital raised
                      <span>(including stage 1 Bonus $$$)</span>
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_totalcapital1"
                        value={
                          formik.values.stage3_totalcapital1 !== 0
                            ? formik.values.stage3_totalcapital1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage3_totalcapital1", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_totalcapital2"
                        value={
                          formik.values.stage3_totalcapital2 !== 0
                            ? formik.values.stage3_totalcapital2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage3_totalcapital2", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_totalcapital3"
                        value={
                          formik.values.stage3_totalcapital3 !== 0
                            ? formik.values.stage3_totalcapital3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage3_totalcapital3", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage3_totalcapital4"
                        value={
                          formik.values.stage3_totalcapital4 !== 0
                            ? formik.values.stage3_totalcapital4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage3_totalcapital4", e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex row mt-5" id="stage-4">
                <div className="col-md-2 col-sm-2">
                  <div className="stage-one">
                    <span> Stage 4</span>
                  </div>
                </div>
                <div className="col-md-10 col-sm-10 gap-2 flex flex-column">
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      100 Unit Apartment Renovation
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_apartment_reno1"
                        value={
                          formik.values.stage4_apartment_reno1 !== 0
                            ? formik.values.stage4_apartment_reno1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_apartment_reno1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_apartment_reno2"
                        value={
                          formik.values.stage4_apartment_reno2 !== 0
                            ? formik.values.stage4_apartment_reno2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_apartment_reno2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_apartment_reno3"
                        value={
                          formik.values.stage4_apartment_reno3 !== 0
                            ? formik.values.stage4_apartment_reno3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_apartment_reno3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_apartment_reno4"
                        value={
                          formik.values.stage4_apartment_reno4 !== 0
                            ? formik.values.stage4_apartment_reno4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_apartment_reno4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Car Wash New Construction
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_carwash_newcons1"
                        value={
                          formik.values.stage4_carwash_newcons1 !== 0
                            ? formik.values.stage4_carwash_newcons1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_carwash_newcons1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_carwash_newcons2"
                        value={
                          formik.values.stage4_carwash_newcons2 !== 0
                            ? formik.values.stage4_carwash_newcons2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_carwash_newcons2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_carwash_newcons3"
                        value={
                          formik.values.stage4_carwash_newcons3 !== 0
                            ? formik.values.stage4_carwash_newcons3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_carwash_newcons3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_carwash_newcons4"
                        value={
                          formik.values.stage4_carwash_newcons4 !== 0
                            ? formik.values.stage4_carwash_newcons4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_carwash_newcons4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Hotel Property Upgrade
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_hotel_property1"
                        value={
                          formik.values.stage4_hotel_property1 !== 0
                            ? formik.values.stage4_hotel_property1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_hotel_property1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_hotel_property2"
                        value={
                          formik.values.stage4_hotel_property2 !== 0
                            ? formik.values.stage4_hotel_property2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_hotel_property2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_hotel_property3"
                        value={
                          formik.values.stage4_hotel_property3 !== 0
                            ? formik.values.stage4_hotel_property3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_hotel_property3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_hotel_property4"
                        value={
                          formik.values.stage4_hotel_property4 !== 0
                            ? formik.values.stage4_hotel_property4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_hotel_property4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Oil & Gas Well Drilling
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_oil_gas1"
                        value={
                          formik.values.stage4_oil_gas1 !== 0
                            ? formik.values.stage4_oil_gas1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage4_oil_gas1", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_oil_gas2"
                        value={
                          formik.values.stage4_oil_gas2 !== 0
                            ? formik.values.stage4_oil_gas2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage4_oil_gas2", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_oil_gas3"
                        value={
                          formik.values.stage4_oil_gas3 !== 0
                            ? formik.values.stage4_oil_gas3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage4_oil_gas3", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_oil_gas4"
                        value={
                          formik.values.stage4_oil_gas4 !== 0
                            ? formik.values.stage4_oil_gas4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage4_oil_gas4", e.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      RV Campground Purchase
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_rv_camp1"
                        value={
                          formik.values.stage4_rv_camp1 !== 0
                            ? formik.values.stage4_rv_camp1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage4_rv_camp1", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_rv_camp2"
                        value={
                          formik.values.stage4_rv_camp2 !== 0
                            ? formik.values.stage4_rv_camp2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage4_rv_camp2", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_rv_camp3"
                        value={
                          formik.values.stage4_rv_camp3 !== 0
                            ? formik.values.stage4_rv_camp3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage4_rv_camp3", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_rv_camp4"
                        value={
                          formik.values.stage4_rv_camp4 !== 0
                            ? formik.values.stage4_rv_camp4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage4_rv_camp4", e.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Retail Center Renovation
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_retail_renovatio1"
                        value={
                          formik.values.stage4_retail_renovatio1 !== 0
                            ? formik.values.stage4_retail_renovatio1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_retail_renovatio1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_retail_renovatio2"
                        value={
                          formik.values.stage4_retail_renovatio2 !== 0
                            ? formik.values.stage4_retail_renovatio2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_retail_renovatio2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_retail_renovatio3"
                        value={
                          formik.values.stage4_retail_renovatio3 !== 0
                            ? formik.values.stage4_retail_renovatio3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_retail_renovatio3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_retail_renovatio4"
                        value={
                          formik.values.stage4_retail_renovatio4 !== 0
                            ? formik.values.stage4_retail_renovatio4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_retail_renovatio4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Day Care Center Purchase
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_daycare_purchase1"
                        value={
                          formik.values.stage4_daycare_purchase1 !== 0
                            ? formik.values.stage4_daycare_purchase1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_daycare_purchase1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_daycare_purchase2"
                        value={
                          formik.values.stage4_daycare_purchase2 !== 0
                            ? formik.values.stage4_daycare_purchase2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_daycare_purchase2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_daycare_purchase3"
                        value={
                          formik.values.stage4_daycare_purchase3 !== 0
                            ? formik.values.stage4_daycare_purchase3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_daycare_purchase3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_daycare_purchase4"
                        value={
                          formik.values.stage4_daycare_purchase4 !== 0
                            ? formik.values.stage4_daycare_purchase4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_daycare_purchase4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Self-Storage New Construction
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_self_storage_newcons1"
                        value={
                          formik.values.stage4_self_storage_newcons1 !== 0
                            ? formik.values.stage4_self_storage_newcons1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_self_storage_newcons1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_self_storage_newcons2"
                        value={
                          formik.values.stage4_self_storage_newcons2 !== 0
                            ? formik.values.stage4_self_storage_newcons2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_self_storage_newcons2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_self_storage_newcons3"
                        value={
                          formik.values.stage4_self_storage_newcons3 !== 0
                            ? formik.values.stage4_self_storage_newcons3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_self_storage_newcons3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_self_storage_newcons4"
                        value={
                          formik.values.stage4_self_storage_newcons4 !== 0
                            ? formik.values.stage4_self_storage_newcons4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_self_storage_newcons4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Senior Assisted Living Purchase
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_senior_assi_livpurchase1"
                        value={
                          formik.values.stage4_senior_assi_livpurchase1 !== 0
                            ? formik.values.stage4_senior_assi_livpurchase1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_senior_assi_livpurchase1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_senior_assi_livpurchase2"
                        value={
                          formik.values.stage4_senior_assi_livpurchase2 !== 0
                            ? formik.values.stage4_senior_assi_livpurchase2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_senior_assi_livpurchase2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_senior_assi_livpurchase3"
                        value={
                          formik.values.stage4_senior_assi_livpurchase3 !== 0
                            ? formik.values.stage4_senior_assi_livpurchase3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_senior_assi_livpurchase3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_senior_assi_livpurchase4"
                        value={
                          formik.values.stage4_senior_assi_livpurchase4 !== 0
                            ? formik.values.stage4_senior_assi_livpurchase4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_senior_assi_livpurchase4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Medical Office Renovation
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_medical_offrenovatio1"
                        value={
                          formik.values.stage4_medical_offrenovatio1 !== 0
                            ? formik.values.stage4_medical_offrenovatio1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_medical_offrenovatio1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_medical_offrenovatio2"
                        value={
                          formik.values.stage4_medical_offrenovatio2 !== 0
                            ? formik.values.stage4_medical_offrenovatio2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_medical_offrenovatio2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_medical_offrenovatio3"
                        value={
                          formik.values.stage4_medical_offrenovatio3 !== 0
                            ? formik.values.stage4_medical_offrenovatio3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_medical_offrenovatio3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_medical_offrenovatio4"
                        value={
                          formik.values.stage4_medical_offrenovatio4 !== 0
                            ? formik.values.stage4_medical_offrenovatio4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_medical_offrenovatio4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Hotel to Apartment Conversion
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_hotel_apartment_conversion1"
                        value={
                          formik.values.stage4_hotel_apartment_conversion1 !== 0
                            ? formik.values.stage4_hotel_apartment_conversion1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_hotel_apartment_conversion1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_hotel_apartment_conversion2"
                        value={
                          formik.values.stage4_hotel_apartment_conversion2 !== 0
                            ? formik.values.stage4_hotel_apartment_conversion2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_hotel_apartment_conversion2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_hotel_apartment_conversion3"
                        value={
                          formik.values.stage4_hotel_apartment_conversion3 !== 0
                            ? formik.values.stage4_hotel_apartment_conversion3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_hotel_apartment_conversion3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_hotel_apartment_conversion4"
                        value={
                          formik.values.stage4_hotel_apartment_conversion4 !== 0
                            ? formik.values.stage4_hotel_apartment_conversion4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_hotel_apartment_conversion4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Build to Rent Development
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_build_rentdevp1"
                        value={
                          formik.values.stage4_build_rentdevp1 !== 0
                            ? formik.values.stage4_build_rentdevp1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_build_rentdevp1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_build_rentdevp2"
                        value={
                          formik.values.stage4_build_rentdevp2 !== 0
                            ? formik.values.stage4_build_rentdevp2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_build_rentdevp2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_build_rentdevp3"
                        value={
                          formik.values.stage4_build_rentdevp3 !== 0
                            ? formik.values.stage4_build_rentdevp3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_build_rentdevp3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_build_rentdevp4"
                        value={
                          formik.values.stage4_build_rentdevp4 !== 0
                            ? formik.values.stage4_build_rentdevp4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_build_rentdevp4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray-one col-md-3 col-sm-3">
                      Total Capital Invested
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_total_capital_invested1"
                        value={
                          formik.values.stage4_total_capital_invested1 !== 0
                            ? formik.values.stage4_total_capital_invested1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_total_capital_invested1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_total_capital_invested2"
                        value={
                          formik.values.stage4_total_capital_invested2 !== 0
                            ? formik.values.stage4_total_capital_invested2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_total_capital_invested2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_total_capital_invested3"
                        value={
                          formik.values.stage4_total_capital_invested3 !== 0
                            ? formik.values.stage4_total_capital_invested3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_total_capital_invested3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage4_total_capital_invested4"
                        value={
                          formik.values.stage4_total_capital_invested4 !== 0
                            ? formik.values.stage4_total_capital_invested4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage4_total_capital_invested4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex row mt-5" id="stage-5">
                <div className="col-md-2 col-sm-2">
                  <div className="stage-one">
                    <span> Stage 5</span>
                  </div>
                </div>
                <div className="col-md-10 col-sm-10 gap-2 flex flex-column">
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Investment 1
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment1_1"
                        value={
                          formik.values.stage5_investment1_1 !== 0
                            ? formik.values.stage5_investment1_1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment1_1", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment1_2"
                        value={
                          formik.values.stage5_investment1_2 !== 0
                            ? formik.values.stage5_investment1_2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment1_2", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment1_3"
                        value={
                          formik.values.stage5_investment1_3 !== 0
                            ? formik.values.stage5_investment1_3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment1_3", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment1_4"
                        value={
                          formik.values.stage5_investment1_4 !== 0
                            ? formik.values.stage5_investment1_4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment1_4", e.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Investment 2
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment2_1"
                        value={
                          formik.values.stage5_investment2_1 !== 0
                            ? formik.values.stage5_investment2_1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment2_1", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment2_2"
                        value={
                          formik.values.stage5_investment2_2 !== 0
                            ? formik.values.stage5_investment2_2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment2_2", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment2_3"
                        value={
                          formik.values.stage5_investment2_3 !== 0
                            ? formik.values.stage5_investment2_3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment2_3", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment2_4"
                        value={
                          formik.values.stage5_investment2_4 !== 0
                            ? formik.values.stage5_investment2_4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment2_4", e.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Investment 3
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment3_1"
                        value={
                          formik.values.stage5_investment3_1 !== 0
                            ? formik.values.stage5_investment3_1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment3_1", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment3_2"
                        value={
                          formik.values.stage5_investment3_2 !== 0
                            ? formik.values.stage5_investment3_2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment3_2", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment3_3"
                        value={
                          formik.values.stage5_investment3_3 !== 0
                            ? formik.values.stage5_investment3_3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment3_3", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment3_4"
                        value={
                          formik.values.stage5_investment3_4 !== 0
                            ? formik.values.stage5_investment3_4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment3_4", e.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Investment 4 {`(if needed)`}
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment4_1"
                        value={
                          formik.values.stage5_investment4_1 !== 0
                            ? formik.values.stage5_investment4_1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment4_1", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment4_2"
                        value={
                          formik.values.stage5_investment4_2 !== 0
                            ? formik.values.stage5_investment4_2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment4_2", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment4_3"
                        value={
                          formik.values.stage5_investment4_3 !== 0
                            ? formik.values.stage5_investment4_3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment4_3", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment4_4"
                        value={
                          formik.values.stage5_investment4_4 !== 0
                            ? formik.values.stage5_investment4_4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment4_4", e.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray fund-btn-font-color col-md-3 col-sm-3">
                      Investment 5 {`(if needed)`}
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment5_1"
                        value={
                          formik.values.stage5_investment5_1 !== 0
                            ? formik.values.stage5_investment5_1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment5_1", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment5_2"
                        value={
                          formik.values.stage5_investment5_2 !== 0
                            ? formik.values.stage5_investment5_2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment5_2", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment5_3"
                        value={
                          formik.values.stage5_investment5_3 !== 0
                            ? formik.values.stage5_investment5_3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment5_3", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_investment5_4"
                        value={
                          formik.values.stage5_investment5_4 !== 0
                            ? formik.values.stage5_investment5_4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("stage5_investment5_4", e.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row flex gap-2 align-items-center ">
                    <div className="fund-btn btn-bg-gray-one col-md-3 col-sm-3">
                      Total capital Returned
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_total_capreturned_1"
                        value={
                          formik.values.stage5_total_capreturned_1 !== 0
                            ? formik.values.stage5_total_capreturned_1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage5_total_capreturned_1",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_total_capreturned_2"
                        value={
                          formik.values.stage5_total_capreturned_2 !== 0
                            ? formik.values.stage5_total_capreturned_2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage5_total_capreturned_2",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_total_capreturned_3"
                        value={
                          formik.values.stage5_total_capreturned_3 !== 0
                            ? formik.values.stage5_total_capreturned_3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage5_total_capreturned_3",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        placeholder="Type here"
                        name="stage5_total_capreturned_4"
                        value={
                          formik.values.stage5_total_capreturned_4 !== 0
                            ? formik.values.stage5_total_capreturned_4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "stage5_total_capreturned_4",
                            e.value
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex row mt-5" id="stage-6">
                <div className="col-md-2 col-sm-2">
                  <div className="stage-one btn-bg-green">
                    <span> Winner</span>
                  </div>
                </div>
                <div className="col-md-10 col-sm-10 gap-2 flex flex-column">
                  <div className="row flex gap-2">
                    <div className="fund-btn btn-bg-gray-one col-md-3 col-sm-3">
                      Fund Results{" "}
                      <span>
                        (Total Capital Returned minus Total Capital Invested)
                      </span>
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        className="winner-input"
                        placeholder="Type here"
                        name="winner_1"
                        value={
                          formik.values.winner_1 !== 0
                            ? formik.values.winner_1
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("winner_1", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        className=" winner-input"
                        placeholder="Type here"
                        name="winner_2"
                        value={
                          formik.values.winner_2 !== 0
                            ? formik.values.winner_2
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("winner_2", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        className=" winner-input"
                        placeholder="Type here"
                        name="winner_3"
                        value={
                          formik.values.winner_3 !== 0
                            ? formik.values.winner_3
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("winner_3", e.value);
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-2">
                      <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        minFractionDigits={2}
                        className=" winner-input"
                        placeholder="Type here"
                        name="winner_4"
                        value={
                          formik.values.winner_4 !== 0
                            ? formik.values.winner_4
                            : undefined
                        }
                        onChange={(e) => {
                          formik.setFieldValue("winner_4", e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex row mt-5" id="stage-7">
                <div className="col-md-2 col-sm-2">
                  <div className="fund-btn btn-bg-gray fund-btn-font-color">
                    Email id:-{" "}
                  </div>
                </div>
                <div className="col-md-10 col-sm-10 gap-2 flex flex-column">
                  <div className="row flex">
                    <div className="col-md-3 col-sm-3">
                      <InputText
                        placeholder="Enter your email"
                        name="email"
                        value={formik.values.email}
                        onChange={(e) => {
                          e.preventDefault();
                          formik.setFieldValue("email", e.target.value);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("email"),
                          "card-inputBox": true,
                        })}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex submit-container mt-2" id="submit">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                  className="fund-btn submit-btn"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quest;
