import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
// import { Column } from "primereact/column";
// import { ColumnGroup } from "primereact/columngroup";
// import { Row } from "primereact/row";
// import { Skeleton } from "primereact/skeleton";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { GetK1ReconciliationByInvestmentThunk } from "../../../store/features/taxmanagement/k1reconcilationinvestment/getfundinvestmentsreport/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import currencyFormat from "../../../utils/currency-formatter";
import { exportExcel } from "../../../utils/sponsor/helper";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";

export default function K1ReconciliationByInvestment() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const previousYear: any = new Date().getFullYear();
  const currentYear = previousYear - 1;
  // const portState = useAppSelector((state) => state.GetMenuList.loading);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const list = useAppSelector(
    (state) => state.GetK1ReconciliationByInvestment?.data2
  );
  const title = useAppSelector(
    (state) => state.GetK1ReconciliationByInvestment?.data1
  );

  const [dataCreateElemnts, setDataCreateElemnts] = useState<any>();
  const [dataTableElements, setdataTableElements] = useState<any>();
  const [stateName, setName] = useState<any>(false);
  const [loading, setloading] = useState<any>(false);
  const [columnSums, setColumnSums] = useState<{ [key: string]: any }>({});
  const [openDailog, setOpenDailog] = useState(false);

  // const datatableArrayObj: any = [];
  const datatableArrayObj: any = [];
  const i: any = title!;
  console.log("iii", i);
  const formatCurrency = (value: any) => {
    // Format the value as currency
    const formattedValue = currencyFormat(value);
    // const formattedValue = new Intl.NumberFormat("en-US", {
    //   style: "currency",
    //   currency: "USD", // Change this to your desired currency code
    // }).format(value);

    return formattedValue;
  };
  const prepareData = (lists: any, titles: any) => {
    if (lists && title) {
      // console.log("prepareData===", data);
      const values = lists;
      const items = titles!;

      console.log("K1ReconciliationByInvestor list", values);
      console.log("K1ReconciliationByInvestor title", items);

      console.log("values", values);
      console.log("items", items);

      for (const value of values) {
        if (!Object.isFrozen(value) && !Object.isSealed(value)) {
          const descriptorGeneratedRank = Object.getOwnPropertyDescriptor(
            value,
            "GeneratedRank"
          );
          if (descriptorGeneratedRank && descriptorGeneratedRank.configurable) {
            delete value.GeneratedRank;
          }

          const descriptorK1MasterId = Object.getOwnPropertyDescriptor(
            value,
            "K1MasterId"
          );
          if (descriptorK1MasterId && descriptorK1MasterId.configurable) {
            delete value.K1MasterId;
          }
        }
      }

      for (let r = 0; r < values?.length; r++) {
        // ... your processing logic ...
        const totalObj = { ...values?.[0] };
        console.log("prepareData totalObj", Object.keys(totalObj));
        const objTotal: any = {};
        for (const val of values) {
          // Loop through the lists to calculate column sums
          let totalSum = 0;
          console.log("prepareData totalObj", val);

          for (const key in val) {
            if (key in objTotal) {
              objTotal[key] += val[key];
            } else {
              objTotal[key] = val[key];
            }

            if (key === "4") {
              totalSum += val[key];
              console.log("4ajsjdsj", totalSum, val[key], key);
            } else {
              totalSum += val[key];
            }
          }
          const newData = Object.fromEntries(
            Object.entries(objTotal).filter(
              ([key]) => key !== "K1LabelName" && key !== "ChildLabelName"
            )
          );

          console.log("newData", newData);
          console.log("prepareData objTotal", objTotal);

          setColumnSums(newData);
        } // Update columnSums state
      }
      console.log("items====", values);

      for (let j = 0; j < values?.length; j++) {
        // let count = 0;
        // let lengthData = 0;
        const obj: any = {};
        for (const key in values[j]) {
          obj[key] =
            key === "ChildLabelName" || key === "K1LabelName"
              ? values[j][key]
              : formatCurrency(values[j][key]);

          // count++;
        }

        datatableArrayObj.push(obj);
      }
      console.log("datatableArrayObj", datatableArrayObj);
      return datatableArrayObj;
    } else {
      return [];
    }
  };

  console.log("columnSums===", columnSums);

  const createcolumns = (data: any) => {
    if (data !== undefined && data !== "" && data.length > 0) {
      //   console.log("datatableArrayObj====", datatableArrayObj);
      console.log("datatableArrayObj[0]====", data);
      console.log("datatableArrayObj key====", Object.keys(data[0]));

      // const value = Object.values(data[0]);
      const headerData = Object.keys(data[0]).map((key, index) => {
        console.log("createcolumns==", data);

        const headerValue = i.find(
          (item: any) => item.ProductId === Number(key)
        )?.ProductName;

        console.log("createcolumns headerValue==", headerValue);
        return {
          field: key,
          header: ["K1LabelName", "ChildLabelName"].includes(key)
            ? ""
            : headerValue,
          style: { minWidth: "250px" },
        };
      });
      const partnerIDIndex = headerData?.findIndex(
        (item: any) => item.field === "K1LabelName"
      );
      const partnerNameIndex = headerData?.findIndex(
        (item: any) => item.field === "ChildLabelName"
      );

      if (partnerIDIndex !== -1) {
        const partnerIDField = headerData?.splice(partnerIDIndex, 1)[0];
        headerData?.splice(0, 0, partnerIDField);
      }

      if (partnerNameIndex !== -1) {
        const partnerNameField = headerData?.splice(partnerNameIndex, 1)[0];
        headerData?.splice(1, 0, partnerNameField);
      }

      const prodTotalIndex = headerData.findIndex(
        (item) => item.field === "ProdTotal"
      );

      if (prodTotalIndex !== -1) {
        headerData[prodTotalIndex].header = "ProdTotal";
      }

      console.log("headerData", headerData);
      return headerData;
    } else {
      return [];
    }
  };
  useEffect(() => {
    const preData = prepareData(list, title);
    setdataTableElements(preData);
    setDataCreateElemnts(createcolumns(preData));
  }, [list, title]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      Date: currentYear.toString(),
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter year";
      }

      return errors;
    },
    onSubmit: () => {
      //  const pdata = prepareData(datatableArrayObj)
      // setdataTableElements(prepareData(datatableArrayObj));
      // setDataCreateElemnts(createcolumns(datatableArrayObj));
      setloading(true);

      const params = {
        LegalEntityId: fundDropdown.fundid,
        TaxYear: formik.values.Date,
      };

      dispatch(GetK1ReconciliationByInvestmentThunk(params))
        .then((res: any) => {
          console.log("clicked");
          setName(false);
          // setdataTableElements(prepareData(datatableArrayObj));
          // setDataCreateElemnts(createcolumns(datatableArrayObj));
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    },
  });

  useEffect(() => {
    if (formik.values.Date.toString() === currentYear.toString()) {
      setloading(true);

      const params = {
        LegalEntityId: fundDropdown.fundid,
        TaxYear: formik.values.Date,
      };

      dispatch(GetK1ReconciliationByInvestmentThunk(params))
        .then((res: any) => {
          console.log("clicked");
          setName(false);
          // setdataTableElements(prepareData(datatableArrayObj));
          // setDataCreateElemnts(createcolumns(datatableArrayObj));
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    } else {
      setName(true);
    }
  }, [formik.values.Date, fundDropdown.fundid]);

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Calculated Total Cash Distributions "
          colSpan={2}
          // footerStyle={{ textAlign: "right" }}
        />
        {Object.keys(columnSums).map((key, index) => (
          <Column
            key={index}
            footer={
              formatCurrency(columnSums[key])
              // typeof columnSums[key] !== "string"
              //   ? formatCurrency(columnSums[key])
              //   : "1"
            }
          />
        ))}
      </Row>
    </ColumnGroup>
  );

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    formik.resetForm();
  }, [fundDropdown.fundid]);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">K1 Reconciliation By Investment</h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(
                  dataTableElements,
                  "K1 Reconciliation By Investment"
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                dataTableElements === undefined ||
                dataTableElements?.length === 0 ||
                dataTableElements === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  dataTableElements === undefined ||
                  dataTableElements?.length === 0 ||
                  dataTableElements === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <>
              <div>
                <div className="formgrid grid col">
                  <div className="d-block sm:d-flex sm:flex-column md:col-6 mr-3 col-12">
                    {/* <label className="inputLabel">Year *</label> */}
                    <div className="card flex justify-content-center">
                      <Calendar
                        name="Date"
                        value={
                          formik.values.Date === null ||
                          formik.values.Date === ""
                            ? undefined
                            : new Date(+formik.values.Date, 0, 1)
                        }
                        onChange={(e) => {
                          const selectedYear = new Date(
                            Number(e.value)
                          ).getFullYear();
                          formik.setFieldValue("Date", selectedYear);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Date"),
                          "card-inputBox": true,
                        })}
                        placeholder="Select year"
                        view="year"
                        dateFormat="yy"
                      />
                      {getFormErrorMessage("Date")}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="mr-4 btn-nav w-7rem justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        // setIsFormSubmitted(true);
                        // console.log("clicked");
                        formik.resetForm();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      className="btn-nav w-7rem justify-content-center"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        // setIsFormSubmitted(true);
                        formik.handleSubmit();
                      }}
                    >
                      Show
                    </Button>
                  </div>
                </div>
              </div>
            </>
            <div>
              {dataTableElements && !stateName && (
                <DataTableComponent
                  valueData={dataTableElements}
                  fieldsElements={dataCreateElemnts}
                  footerElement={dataTableElements.length > 0 && footerGroup}
                  isDownload={true}
                  isPaginationAllowed={true}
                  fileName="K1 Reconcilation By Investment"
                  scrollable={true}
                  className="gridcell"
                  columnResizeMode="expand"
                  maxHeight={"calc(-260px + 100vh)"}
                  isFullView={true}
                  setOpenDailog={setOpenDailog}
                  openDailog={openDailog}
                />
              )}
            </div>
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
