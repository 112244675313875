import { IGetInvestorDetailsForAdminparameters } from "../../store/features/get-investor-details-for-admin";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function getInvestorDetailsForAdmintService(
  data: IGetInvestorDetailsForAdminparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_INVESTMENT_URL
    }/Investments/getInvestorDetailsForAdmin?LegalEntityID=${
      data.LegalEntityId
    }&InvestorID=${data.InvestorId}`
  );
}
