import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPersonaService } from "../../../../../services/investor/investor";
import error from "../../../../../utils/error";
import { IGetPersona } from ".";

interface InvestorProfileDetails {
  Data?: IGetPersona[] | [];
  loading: string;
  error?: string | null;
}

const initialState: InvestorProfileDetails = {
  Data: [],
  loading: "loading",
  error: undefined,
};

export const getPersonaThunk = createAsyncThunk(
  "investorProfile/getPersona",
  async (data: IGetPersona) => {
    try {
      const response = await getPersonaService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "investorProfile/getPersona",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getPersonaThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getPersonaThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.Data = action.payload.data.investorProfile;

      // state.Data = action.payload.opportunitiesData;
      // state.currentPage += 1;
    });
    builder.addCase(getPersonaThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
