import apiFactory from "../../../utils/api";
import { envVariable } from "../../../utils/sponsor/helper";

export function getUserMenuService(data?: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ADMIN_MANAGEMENT_URL
    }/MenuMaster/GetSponsorMenus?isGetStuffDone=${
      data !== undefined ? data : false
    }`
  );
}
