// import { IInvestmentData } from "../../store/features/investment/create";
import { IDeleteSupplierparameters } from "../../store/features/investment/deletesupplier";
import { IGetSupplierListparameters } from "../../store/features/investment/getsupplierlist";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function addUpdateSupplierService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/FundSupplier/AddUpdateSupplier`,
    data
  );
}
export function getSupplierListService(data: IGetSupplierListparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/FundSupplier/GetSupplierList?LegalEntityId=${data.LegalEntityId}`
  );
}

export function getSupplierTypesService() {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/FundSupplier/GetSupplierTypes`
  );
}

export function deleteSupplierService(data: IDeleteSupplierparameters) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_FUND_MANAGEMENT_URL
    }/FundSupplier/DeleteSupplier?SupplierID=${data.SupplierID}&LegalEntityId=${
      data.LegalEntityId
    }`
  );
}
