// import { Button } from "primereact/button";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Chip } from "primereact/chip";
// import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
// import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
// import { Tag } from "primereact/tag";
// import { InputText } from "primereact/inputtext";
// import { Tree } from "primereact/tree";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
// import { RiSearchLine } from "react-icons/ri";

import DataTableComponent from "../../../../components/data-table/DataTableComponent";
//

import Loader from "../../../../components/loader/Loader";
import { getThunkPortfolioRegisterInvestorListDropdown } from "../../../../store/features/dropdown/registeredinvestorlist/slice";
import {
  getThunkAllocateSlices,
  setAllocationInvestmentInfoList,
  // setAllocationInvestmentInfoList,
} from "../../../../store/features/operation/allocation-slices/slice";

import { SaveThunkAllocateSlices } from "../../../../store/features/operation/save-allocation-slice/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  // convertUTCToLocalDate,
  convertLocalDateToUTC,
  getListDateConversion,
  formatDateMMDDYYY,
  // formatDateMMDDYYY,
} from "../../../../utils/date-formatter";

export default function AllocateSlices() {
  const dispatch = useAppDispatch();
  const refTable = useRef<DataTable>(null);
  // const portState = useAppSelector((state) => state.allocationSlices.loading);
  const avallist = useAppSelector((state) => state.allocationSlices.data);
  const toast = useRef<Toast>(null);
  const [helpvisible, setHelpVisible] = useState(true);
  const [modalvisible, setmodalvisible] = useState(false);
  const [popupdata, setpopupdata] = useState<any>();
  // const [allocationdate, setallocationdate] = useState<any>(new Date());
  const [availableCredit, setavailableCredit] = useState<any>(0);
  const [showloader, setshowloader] = useState(false);

  const [openDailog, setOpenDailog] = useState(false);

  const [partnerId, setpartnerId] = useState(0);
  const [availableStakes, setAvailableStakes] = useState<any>();
  const [allocatedStakes, setAllocatedStakes] = useState<any>();
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  const registerInvestorList = useAppSelector(
    (state) => state.registerinvestor.data
  );
  const investorList: { code: number; name: string }[] = [];
  for (let i = 0; i < registerInvestorList?.length; i++) {
    investorList.push({
      code: registerInvestorList![i].investorID,
      name: registerInvestorList![i].investorName,
    });
  }
  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const dataTablehelpElements = [
    {
      field: "investmentID",
      header: "External Investment Id",
      style: { minWidth: "200px" },
    },
    {
      field: "productName",
      header: "Investment",
      style: { minWidth: "230px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.productName}>
          {rowData?.productName}
        </div>
      ),
    },

    {
      field: "productCode",
      header: "Investment Code",
      style: { minWidth: "200px" },
    },
    {
      field: "earningsPayoutTiming",
      header: "Earnings Timing",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.earningsPayoutTiming}>
          {rowData?.earningsPayoutTiming}
        </div>
      ),
    },

    {
      header: "Allocated Units",
      field: "units",
      style: { minWidth: "180px" },
    },

    {
      header: "Previous Allocation Date",
      field: "maxStartDate",
      dataType: "date",
      style: { minWidth: "230px" },
      body: (rowData: any) => formatDate(rowData.maxStartDate),
      filterElement: dateFilterTemplate,
    },
    {
      header: "Class",
      field: "assetClass",
      style: { minWidth: "160px" },
    },
  ];

  const getAllocationvalue = () => {
    setshowloader(true);
    const parameters = {
      LegalEntityId: dropdown.fundid,
      LPPartnerId: partnerId,
    };
    // setpartnerId(dropdown.registerid);
    dispatch(getThunkAllocateSlices(parameters)).then((response) => {
      setshowloader(false);
    });
  };
  const formik = useFormik({
    initialValues: {
      unit: 0,
      allocationDate: "",
    },
    validate: (data: any) => {
      const errors: { unit?: string; allocationDate?: string } = {};
      if (!data.unit || data.unit === 0) {
        errors.unit = "Unit is required";
      }
      if (data.unit > Number.parseInt(popupdata?.units)) {
        errors.unit = "You are investing more than available units";
      }

      // console.log(
      //   "Date compare 3",
      //   new Date(formik.values.allocationDate!).setHours(0, 0, 0, 0) <
      //     new Date(popupdata?.maxStartDate!).setHours(0, 0, 0, 0),
      //   new Date(formik.values.allocationDate)?.toDateString() <
      //     new Date(popupdata?.maxStartDate)?.toDateString()
      // );
      if (
        new Date(formik.values.allocationDate!).setHours(0, 0, 0, 0) <
        new Date(popupdata?.maxStartDate!).setHours(0, 0, 0, 0)
      ) {
        errors.allocationDate =
          "The Allocation Date is earlier than permitted. Allocation Date has to be the same or greater than the previous Allocation Date.";
      }

      const totalAmt =
        // Number.parseInt(popupdata?.initialInvAlloc) +
        Number.parseInt(data.unit) * Number.parseInt(popupdata?.unitPrice);

      const creditRoundoff = Math.round(Number(availableCredit) * 100) / 100;
      if (totalAmt > creditRoundoff || creditRoundoff === 0) {
        errors.unit = "You are investing more than your available balance";
      }
      // if (data.unit % 10 > 0) {
      //   errors.unit = "Allocate in multiples of 10.";
      // }

      return errors;
    },
    onSubmit: (data: any) => {
      setmodalvisible(false);
      setshowloader(true);
      const Requestdata = {
        action: "CREATE",
        legalEntityId: dropdown.fundid,
        lpPartnerId: partnerId,
        transactionDate: convertLocalDateToUTC(formik.values.allocationDate),
        investmentList: [
          {
            stakeTrackingID: popupdata?.stakeTrackingID,
            investmentID: popupdata?.investmentID,
            units: popupdata?.units,
            allocUnits: data?.unit,
            unitPrice: popupdata?.unitPrice,
          },
        ],
      };

      dispatch(SaveThunkAllocateSlices(Requestdata)).then((response) => {
        formik.resetForm();
        setshowloader(false);
        if (response.payload?._response?.success) {
          // open();
          getAllocationvalue();
          toast.current?.show({
            severity: "success",
            // summary: "Success",
            life: 5000,
            detail: response.payload?._response?.message,
            // life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail:
              response.payload?._response?.message ||
              "Oops Something went wrong",
            life: 3000,
          });
        }
      });
    },
  });
  const actionBodysubTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-text  iconButton"
          // severity="danger"
          onClick={(e) => {
            e.preventDefault();
            setmodalvisible(true);
            setpopupdata(rowData);
            // setallocationdate(convertUTCToLocalDate(rowData.maxStartDate));
            formik.setFieldValue("allocationDate", rowData.maxStartDate);
            console.log(rowData);
          }}
        />
      </React.Fragment>
    );
  };

  const dataTableElements = [
    {
      field: "productName",
      header: "Investment",
      style: { minWidth: "150px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.productName}>
          {rowData?.productName}
        </div>
      ),
    },

    {
      field: "productCode",
      header: "Lookup Code",
      style: { minWidth: "150px" },
    },
    {
      field: "earningsPayoutTiming",
      header: "Earnings Timing",
      style: { minWidth: "200px" },
    },
    {
      header: "Available Units",
      field: "units",
      style: { minWidth: "200px" },
    },
    {
      header: "Allocate Units",
      field: "allocUnits",
      body: actionBodysubTemplate,
      style: { minWidth: "200px" },
    },
    {
      field: "unitPrice",
      header: "Unit Price",
      style: { minWidth: "200px" },
    },
    {
      header: "Previous Allocation Date",
      field: "maxStartDate",
      dataType: "date",
      body: (rowData: any) => formatDate(rowData.maxStartDate),
      filterElement: dateFilterTemplate,
      style: { minWidth: "250px" },
    },
    {
      header: "Class",
      field: "assetClass",
      style: { minWidth: "140px" },
    },
    {
      header: "External Investment Id",
      field: "investmentID",
      style: { minWidth: "270px" },
    },
  ];

  useEffect(() => {
    if (avallist !== undefined) {
      setavailableCredit(avallist?.availableCredit);
      setAllocatedStakes(
        getListDateConversion(avallist.allocatedStakes, [
          "maxStartDate",
          "maxStartDateString",
        ])
      );
      setAvailableStakes(
        getListDateConversion(avallist.availableStakes, [
          "maxStartDate",
          "investmentStartDate",
          "maxStartDateString",
        ])
      );
    }
  }, [avallist]);

  useEffect(() => {
    if (dropdown.fundid !== undefined && dropdown.fundid > 0) {
      const pararmeter = {
        LegalEntityId: dropdown.fundid,
      };
      dispatch(getThunkPortfolioRegisterInvestorListDropdown(pararmeter));
      // getAllocationvalue();
    }
  }, [dropdown.fundid]);
  useEffect(() => {
    if (partnerId > 0) {
      getAllocationvalue();
    }
  }, [partnerId]);

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleDateChange = (e: any) => {
    // setallocationdate(dateUTC);
    console.log("popupdata allocationDate", e.target.value);
    formik.setFieldValue("allocationDate", e.target.value);
  };
  useEffect(() => {
    // formik.resetForm();

    setpartnerId(0);
    setavailableCredit(0);
    dispatch(setAllocationInvestmentInfoList(""));
  }, [dropdown]);

  const handleCancel = () => {
    setmodalvisible(false);
    formik.resetForm();
  };
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const investorOptionTemplate = (option: any) => {
    return (
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "inherit",
          cursor: "pointer",
        }}
        title={option.name}
      >
        {option.name}
      </div>
    );
  };
  return (
    <>
      {showloader === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />

      <Dialog
        visible={modalvisible}
        onHide={() => handleCancel()}
        className="w-100 md:w-5"
      >
        {/* <h2 className="h2-dialog">Enter One Time Pascode</h2> */}
        <div className="card flex justify-content-center">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-column gap-2 softComAmt"
          >
            <div>
              <div className="formgrid grid px-2">
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                  <label className="inputLabel">
                    Enter Unit for Allocation
                  </label>
                  <div className="">
                    <InputNumber
                      id="value"
                      name="unit"
                      value={
                        formik.values.unit === 0
                          ? undefined
                          : formik.values.unit
                      }
                      // maxLength={18}
                      // required
                      onValueChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue("unit", value);
                      }}
                      // className="card-inputBox"
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("value"),
                        "card-inputBox": true,
                      })}
                      placeholder="Enter units"
                      // onChange={formik.handleChange}
                    />
                  </div>
                  {getFormErrorMessage("unit")}
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                  <label className="inputLabel">Select Allocation Date</label>
                  <Calendar
                    name="allocationDate"
                    value={
                      formik.values.allocationDate === null ||
                      formik.values.allocationDate === ""
                        ? undefined
                        : formik.values.allocationDate
                    }
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("allocationDate"),
                      "card-inputBox": true,
                    })}
                    placeholder="Select Allocation Date"
                    onChange={(e) => {
                      handleDateChange(e);
                    }}
                    readOnlyInput
                  />
                  {getFormErrorMessage("allocationDate")}
                </div>
              </div>
              <div className="formgrid grid px-2">
                {" "}
                <div className=" d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6 annualClass">
                  <label className="inputLabel ">
                    Previous Allocation Date
                  </label>
                  <Calendar
                    name="previousAllocationDate"
                    value={
                      popupdata?.maxStartDate === "" ||
                      popupdata?.maxStartDate === null
                        ? undefined
                        : new Date(popupdata?.maxStartDate)
                    }
                    // className={classNames({
                    //   "p-invalid": isFormFieldInvalid("value"),
                    //   "card-inputBox": true,
                    // })}
                    className="card-inputBox"
                    disabled
                    readOnlyInput
                  />
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6 annualClass">
                  <label className="inputLabel">Investment Start Date</label>
                  <Calendar
                    name="investmentStartDate"
                    value={
                      popupdata?.investmentStartDate === "" ||
                      popupdata?.investmentStartDate === null
                        ? undefined
                        : new Date(popupdata?.investmentStartDate)
                    }
                    // className={classNames({
                    //   "p-invalid": isFormFieldInvalid("value"),
                    //   "card-inputBox": true,
                    // })}
                    className="card-inputBox"
                    disabled
                    readOnlyInput
                  />
                </div>
              </div>
              <label className="inputLabel">
                Note: Most investors are typically allocated on the investment
                start date.{" "}
              </label>
            </div>
            <div className="mt-2 d-flex">
              <Button
                label="Save"
                type="submit"
                // onClick={props.onModalClose}
                className=" btn-dialog w47 mx-1"
              />
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleCancel();
                }}
                label="Cancel"
                className="  btn-nav w47 mx-1 "
                // onClick={onSubmit}
                autoFocus
              />
            </div>
          </form>
        </div>
      </Dialog>
      <div className="col-12 flex">
        <div className="card col-11 p-0 pgHeaderWrap ">
          <h1 className="pageHeader fs-2">Allocate Slices</h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className="col-1 p-0 align-items-center flex justify-content-end">
          <Button
            className="btn-navActive"
            type="button"
            icon="pi pi-window-maximize"
            disabled={
              helpvisible
                ? availableStakes === undefined ||
                  availableStakes?.length === 0 ||
                  availableStakes === null
                : allocatedStakes === undefined ||
                  allocatedStakes?.length === 0 ||
                  allocatedStakes === null
            }
            onClick={() => setOpenDailog(true)}
            title="View Full Screen"
          />
        </div>
      </div>
      {/* <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Allocate Slices</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2"></div>
      </div> */}
      {/* <div className="grid"> */}
      {/* <div className="col-12"> */}
      <div className="card">
        {/* <Card> */}
        {/* <div className="mb-4 mt-2"> */}
        <div className="md:flex md:col-12 p-0">
          <div className="tabHeader-wrap col-6">
            <Button
              type="button"
              onClick={() => {
                setHelpVisible(true);
                // refTable.current.value = 1;
              }}
              className={
                helpvisible === true ? "tabHeadText Active " : "tabHeadText  "
              }
            >
              <span className="ml-auto mr-auto">Available for allocation</span>
            </Button>{" "}
            <Button
              className={
                helpvisible === false ? "tabHeadText Active  " : "tabHeadText  "
              }
              onClick={() => {
                setHelpVisible(false);
                // refTable.current.value = 2;
              }}
            >
              <span className="ml-auto mr-auto">Allocated to Investor</span>
            </Button>
          </div>
          <div className="col-12 md:col-3">
            <div className="d-flex flex-column p-0 m-0">
              {/* <label className="inputLabel">Investor Account </label> */}

              <Dropdown
                name="investor"
                value={partnerId}
                //   value={}
                onChange={(e) => {
                  setpartnerId(e.value);
                }}
                style={{ width: "100%" }}
                options={investorList}
                resetFilterOnHide={true}
                itemTemplate={investorOptionTemplate}
                showFilterClear={true}
                optionLabel="name"
                optionValue="code"
                placeholder="Select Investor Account"
                filter
              />
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="d-flex flex-column p-0 m-0">
              {/* <label className="inputLabel">Available Balance</label> */}
              <InputText
                // inputId="FundAmt"
                name="FundAmt"
                value={
                  availableCredit === 0 || availableCredit === undefined
                    ? availableCredit
                    : currencyFormat(availableCredit)
                }
                // mode="currency"
                // currency="USD"
                // // locale="en-US"
                placeholder="Available Balance"
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="formgrid grid px-2">
          <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
            <label className="inputLabel">&nbsp;</label>

            <label className="inputLabel">&nbsp;</label>
          </div>
          <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
            <label className="inputLabel">&nbsp;</label>

            <label className="inputLabel">&nbsp;</label>
          </div>
        </div> */}
      {/* </div> */}
      {helpvisible === true ? (
        <DataTableComponent
          ref={refTable}
          key={1}
          className="gridcell allocateslicefix"
          scrollable={true}
          columnResizeMode="expand"
          maxHeight="calc(-197px + 100vh)"
          valueData={availableStakes || []}
          fieldsElements={dataTableElements}
          // isDownload={true}
          openDailog={openDailog}
          setOpenDailog={setOpenDailog}
          isPaginationAllowed={true}
        />
      ) : (
        <DataTableComponent
          ref={refTable}
          key={2}
          className="gridcell"
          scrollable={true}
          valueData={allocatedStakes || []}
          fieldsElements={dataTablehelpElements}
          openDailog={openDailog}
          setOpenDailog={setOpenDailog}
          maxHeight="calc(-197px + 100vh)"
          columnResizeMode="expand"
          isPaginationAllowed={true}
          // isDownload={true}
        />
      )}
      {/* </Card> */}

      {/* </div> */}
      {/* </div> */}
    </>
  );
}
