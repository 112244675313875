import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AddUpdateAllocationService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { ICreateUpdateAllocationState } from ".";

const initialState: ICreateUpdateAllocationState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: undefined,
};

export const addThunkCreateUpdateAllocateData = createAsyncThunk(
  "addCreateUpdateAllocateData",
  async (data: any) => {
    try {
      const response = await AddUpdateAllocationService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "addCreateUpdateAllocateData",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(addThunkCreateUpdateAllocateData.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      addThunkCreateUpdateAllocateData.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        //  console.log("investmetn", action.payload._response);
        state.data =
          action.payload._response.data !== null
            ? action.payload._response.data
            : {
                output: [],
                investmentData: undefined,
              };
        // console.log("im", state.data);
      }
    );
    builder.addCase(
      addThunkCreateUpdateAllocateData.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
        throw new Error(error(action.error));
      }
    );
  },
});

export default slice.reducer;
