import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEmbedUIAcreddLinkService } from "../../../../../../services/investor/investor";
import error from "../../../../../../utils/error";
import { IGetInvAccrediationStatusPayload } from "../get-inv-accrediation-status";

interface IEsignDocument {
  data?: { custom_link: string; externalUniqueID: string; status_code: number };
  loading: string;
  error?: string | null;
}

const initialState: IEsignDocument = {
  data: undefined,
  loading: "loading",
  error: undefined,
};
export const getEmbedUIAcreddLinkThunk = createAsyncThunk(
  "accreditation/getEmbedUIAcreddLink",
  async (data: IGetInvAccrediationStatusPayload) => {
    try {
      const response = await getEmbedUIAcreddLinkService(data);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "accreditation/getEmbedUIAcreddLink",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getEmbedUIAcreddLinkThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getEmbedUIAcreddLinkThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";

      state.data = action.payload._response.data;
    });
    builder.addCase(getEmbedUIAcreddLinkThunk.rejected, (state, action) => {
      state.loading = "failed";
      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
