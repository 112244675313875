import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../components/loader/Loader";
import {
  getManageTransactionThunk,
  resetManangeReport,
} from "../../../../store/features/reports/transaction-reports/all-transaction/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getListDateConversion } from "../../../../utils/date-formatter";
import { exportExcel } from "../../../../utils/excel-dataformat";
import { errorToastMessage } from "../../../../utils/toast-message";
import AllTransaction from "./AllTransaction";

const ManageTransactionReports = () => {
  // const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("allTransactionReport");
  const [loading, setloading] = useState<any>(false);
  const [allTransactionData, setAllTransactionData] = useState<any>();
  const [openDailog, setOpenDailog] = useState(false);
  const toast = useRef<Toast>(null);

  const dispatch = useAppDispatch();

  const setTranscationTab = (tabName: string) => {
    dispatch(resetManangeReport());
    setTab(tabName);
  };
  const fundDropdown = useAppSelector(
    (state: any) => state.navbardropdown.data
  );
  const reportData = useAppSelector(
    (state: any) => state.getManageTransaction.data
  );

  const excelTableHeaders = [
    {
      field: "transactionId",
      header: "Transaction Id",
    },
    {
      field: "investorAccount",
      header: "Investor Account",
    },
    {
      field: "transactionDate",
      header: "Transaction Date",
      dataType: "date",
    },
    {
      field: "transactionType",
      header: "Transaction Type",
    },
    {
      field: "distributionType",
      header: "Distribution Type",
    },
    {
      field: "investmentName",
      header: "Investment Name",
    },
    {
      field: "creditAmount",
      header: "Credit Amount",
      dataType: "currency",
    },
    {
      field: "debitAmount",
      header: "Debit Amount",
      dataType: "currency",
    },
    { field: "notes", header: "Notes" },
  ];

  const formik = useFormik({
    initialValues: {
      Date: "",
    },
    validate: (data: any) => {
      const errors: any = {};
      if (!data.Date) {
        errors.Date = "Year is required";
      }

      return errors;
    },
    onSubmit: (data: any) => {
      setloading(true);
      dispatch(
        getManageTransactionThunk({
          legalEntityId: fundDropdown.fundid,
          year: data.Date,
          type: tab,
        })
      )
        .catch(() => {
          errorToastMessage(toast);
        })
        .finally(() => {
          setloading(false);
        });
    },
  });
  useEffect(() => {
    console.log("Tab values", tab, formik.values.Date, fundDropdown.fundid);
    if (tab && formik.values.Date && fundDropdown.fundid !== undefined) {
      setloading(true);
      dispatch(
        getManageTransactionThunk({
          legalEntityId: fundDropdown.fundid,
          year: +formik.values.Date,
          type: tab,
        })
      )
        .catch(() => {
          errorToastMessage(toast);
        })
        .finally(() => {
          setloading(false);
        });
    }
  }, [tab]);
  useEffect(() => {
    setAllTransactionData(
      getListDateConversion(reportData, ["transactionDate"])
    );
  }, [reportData]);

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);
  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    if (formik.values.Date) {
      dispatch(resetManangeReport());
    }
  }, [formik.values.Date]);

  useEffect(() => {
    dispatch(resetManangeReport());
    formik.resetForm();
  }, [fundDropdown.fundid]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  console.log("allTransactionData==========>", allTransactionData);

  return (
    <>
      {loading && <Loader />}
      <Toast ref={toast} className="themeToast" />
      {/* <Card> */}
      {/* <div className="grid"> */}
      {/* <div className="col-12"> */}
      {/* <div className="card tabWithoutboxshadow"> */}
      <>
        {" "}
        {/* <div className="formgrid grid px-2 "> */}
        <div className="card">
          <div className="row col-12 m-0 p-0 pt-2">
            <div className="col-12 md:col-3 m-0 mb-md-0 py-0">
              <div className="d-flex align-items-start flex-column">
                <Calendar
                  name="Date"
                  value={
                    formik.values.Date === null || formik.values.Date === ""
                      ? undefined
                      : new Date(+formik.values.Date, 0, 1)
                  }
                  onChange={(e) => {
                    const selectedYear = new Date(
                      Number(e.value)
                    ).getFullYear();
                    formik.setFieldValue("Date", selectedYear);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Date"),
                    "card-inputBox": true,
                  })}
                  placeholder="Select year"
                  view="year"
                  dateFormat="yy"
                />
                {getFormErrorMessage("Date")}
              </div>
            </div>
            <div className="col-12 md:col-2 py-0 m-0 mb-md-0">
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  className="mr-3 btn-nav justify-content-center"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("clicked");
                    formik.handleSubmit();
                  }}
                >
                  Show
                </Button>
                <Button
                  className="btn-nav justify-content-center"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(resetManangeReport());
                    formik.resetForm();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="col-12 md:col-7 py-0 flex justify-content-end">
              <Button
                className="remove-shadow"
                type="button"
                // icon="pi pi-file"
                // rounded
                onClick={() =>
                  exportExcel(
                    excelTableHeaders,
                    allTransactionData,
                    "Manage Transaction Reports",
                    true
                  )
                }
                data-pr-tooltip="exportExcel"
                disabled={
                  allTransactionData === undefined ||
                  allTransactionData?.length === 0 ||
                  allTransactionData === null
                }
              >
                {" "}
                <span className="blueButtonShadowText">Download </span>
              </Button>
              <div className="col-1 p-0 ml-2 align-items-center justify-content-end">
                <Button
                  className="btn-navActive"
                  type="button"
                  icon="pi pi-window-maximize"
                  disabled={
                    allTransactionData === undefined ||
                    allTransactionData?.length === 0 ||
                    allTransactionData === null
                  }
                  onClick={() => setOpenDailog(true)}
                  title="View Full Screen"
                />
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </>{" "}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </Card> */}
      <div className="tabHeader-wrap">
        <Button
          className={
            tab === "allTransactionReport"
              ? "tabHeadText Active"
              : "tabHeadText "
          }
          onClick={(e) => {
            e.preventDefault();
            setTranscationTab("allTransactionReport");
          }}
        >
          <span className="ml-auto mr-auto ">All Transactions</span>
        </Button>
        <Button
          className={
            tab === "capitalContributionsReport"
              ? "tabHeadText Active"
              : "tabHeadText "
          }
          onClick={(e) => {
            e.preventDefault();
            setTranscationTab("capitalContributionsReport");
          }}
        >
          <span className="ml-auto mr-auto ">Capital Contributions</span>
        </Button>
        <Button
          className={
            tab === "capitalWithdrawalsReport"
              ? "tabHeadText Active"
              : "tabHeadText "
          }
          onClick={(e) => {
            e.preventDefault();
            setTranscationTab("capitalWithdrawalsReport");
          }}
        >
          <span className="ml-auto mr-auto ">Capital Distributions</span>
        </Button>
      </div>
      <div className="mt-3">
        <AllTransaction
          pageName={tab}
          openDailog={openDailog}
          setOpenDailog={setOpenDailog}
        />
      </div>
    </>
  );
};

export default ManageTransactionReports;
