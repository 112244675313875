import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import React from "react";
import FormDetails from "../../../common-component/form-component/FormDetails";
interface IProps {
  handlePrevious: any;
  handleNext: any;
  offering: any;
}

export const CreateStep7 = (props: IProps) => {
  return (
    <>
      <div>
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader"></h1>
          </div>
        </div>
        <div className=" h-100 center-card">
          <div className="col-12 " style={{ width: "90%" }}>
            <div className="card my-3">
              <Card>
                <div className="headercard d-flex grid">
                  <div className="col-12 md:col-6">
                    <h2 style={{ fontWeight: "bold" }}>
                      Confirm New {props.offering}
                    </h2>
                  </div>
                  <div className="col-12 md:col-6 end-div">
                    <h6>Documents Info - Step 5</h6>
                  </div>
                </div>

                <div>
                  <ProgressBar
                    value={74}
                    showValue={false}
                    className="m-2"
                  ></ProgressBar>
                </div>

                <div className="col-12 md:col-6">
                  <h6>
                    Please confirm all the following information is correct
                  </h6>
                </div>
                <FormDetails
                  pageName="CreateNew"
                  handleNext={props.handleNext}
                  handlePrevious={props.handlePrevious}
                  offering={props.offering}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
