import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendExecuteEsignEmailService } from "../../../../../services/e-sign/e-sign-ondemand";
import error from "../../../../../utils/error";
import { ISendExecuteEmailState } from ".";

const initialState: ISendExecuteEmailState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const sendThunkExecuteEsignEmail = createAsyncThunk(
  "sendThunkExecuteEsignEmail",
  async (data: any) => {
    try {
      const response = await sendExecuteEsignEmailService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "sendThunkExecuteEsignEmail",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(sendThunkExecuteEsignEmail.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(sendThunkExecuteEsignEmail.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      state.data = action.payload.data !== null ? action.payload.data : [];
      state.message = action.payload.message;
    });
    builder.addCase(sendThunkExecuteEsignEmail.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
