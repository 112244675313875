// import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
// import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";

// import { InputSwitch, InputSwitchChangeParams } from "primereact/inputswitch";

// import { Row } from "primereact/row";

import React, { useEffect } from "react";

import {
  RiMoneyDollarBoxLine,
  RiLineChartFill,
  RiCalendar2Line,
  RiHandCoinLine,
  // RiNewspaperLine,
  RiHotelLine,
  RiBriefcase3Line,
  RiDownload2Line,
  RiUpload2Line,
  RiArrowDownLine,
  RiInformationFill,
  RiScalesLine,
} from "react-icons/ri";
import "primeflex/primeflex.scss";
import "./statisticsDashboard.scss";
// import { useNavigate } from "react-router-dom";
import blueIcon from "../../images/blueIcon.png";
import orangeIcon from "../../images/orangeIcon.png";
import yellowIcon from "../../images/yellowIcon.png";

// import { getThunkPortfolioNonRegisterInvestorListDropdown } from "../../store/features/dropdown/nonregisteredinvestorlist/slice";
// import { getThunkPortfolioRegisterInvestorListDropdown } from "../../store/features/dropdown/registeredinvestorlist/slice";
// import { getPortfolioGetLegalEntityList } from "../../store/features/dropdown/slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
// import { getAllPortfoliosData } from "../../store/portfolio/slice";
// import { getThunkPortfolio } from "../../store/portfolio/slice";
// import DataTableExample from "../DataTableExample";
import { getThunkPortfolio } from "../../store/portfolio/slice";
import currencyFormat from "../../utils/currency-formatter";
import Error from "../ErrorUi";
// import DashboardDropdownComponent from "../dashboard-dropdown/DashboardDropdownComponent";

import RegisterDropdownComponent from "../dashboard-dropdown/RegisterDropdown";
import GoogleMap from "../googleMap/googleMap";
import DoughnutChartDemo from "../graphs/account-value-doughnutChart";
import StackedBar from "../graphs/barGraph";
import DoughnutChartExcitedDeal from "../graphs/exited-Deal-doughnutChart";
// import StatisticsDashboardSkeleton from "./statisticsDashboardSkeleton";
import Loader from "../loader/Loader";

export interface itemBody {
  id: string;
  name: string;
  category: string;
  price: number;
}

export interface ILocation {
  id: any;
  category: string;
  data: {
    lat: number;
    lng: number;
  };
}
export default function StatisticsDashboard() {
  // const [totalValue, setTotal] = useState<any>({});

  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  // const [accountValue, setAcountValue] = useState<any>();
  // const [assets, setAssets] = useState<any>();
  // const [exitedDeals, setExitedDeals] = useState<any>();
  // const [mapD, setMapD] = useState<any>();
  const accountValue = useAppSelector((state) => state.portfolio.accountValue);
  const assets = useAppSelector((state) => state.portfolio.assets);
  // const total = useAppSelector((state) => state.portfolio.accountValueTotal);
  const exitedDeals = useAppSelector((state) => state.portfolio.exitedDeals);
  const mapD = useAppSelector((state) => state.portfolio.mapDiversification);
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  const funddropdownModel = useAppSelector(
    (state) => state.legalEntityList.data
  );
  // const registerinvestorModel = useAppSelector(
  //   (state) => state.registerinvestor.data
  // );
  // const nonregisterinvestorModel = useAppSelector(
  //   (state) => state.nonregisterinvestor.data
  // );
  const portState = useAppSelector((state) => state.legalEntityList.loading);

  // const profileData = useAppSelector((state) => state.profile.data![0]);

  const legalEntityList: { code: number; name: string }[] = [];

  for (let i = 0; i < funddropdownModel?.length; i++) {
    legalEntityList.push({
      code: funddropdownModel![i].legalEntityID,
      name: funddropdownModel![i].legalEntityName,
    });
  }
  const location: ILocation[] = [];
  // const amountPercent: any[] = [];
  const totalAmount: any[] = [];

  // assets?.map((item) => {
  //   return amountPercent.push((item.deals * 100) / total!.totalDeals);
  // });

  // const registerinverstmenList: { code: number; name: string }[] = [];
  // for (let i = 0; i < registerinvestorModel?.length; i++) {
  //   registerinverstmenList.push({
  //     code: registerinvestorModel![i].investorID,
  //     name: registerinvestorModel![i].investorName,
  //   });
  // }
  // const nonregisterinverstmenList: { code: number; name: string }[] = [];
  // for (let i = 0; i < nonregisterinvestorModel?.length; i++) {
  //   nonregisterinverstmenList.push({
  //     code: nonregisterinvestorModel![i].investorID,
  //     name: nonregisterinvestorModel![i].investorName,
  //   });
  // }

  assets?.map((item) => {
    return totalAmount.push(item.amount);
  });

  const formatCurrency = (value: any) => {
    const finalValue = Number(value);
    const parseValue = currencyFormat(Number(finalValue));

    return `${parseValue}`;
  };
  // const LegalEntityparameters = {
  //   Email: "avestorinc%2Bdwp-FundUser%40outlook.com",
  //   UserTypeId: 1,
  // };

  // useEffect(() => {
  //   dispatch(getPortfolioGetLegalEntityList(LegalEntityparameters));
  // }, []);

  useEffect(() => {
    if (
      dropdown.fundid !== undefined &&
      dropdown.nonregisterid !== undefined &&
      dropdown.nonregisterid > 0
    ) {
      const parameters = {
        investorID: 0,
        partnerID: 0,
        legalEntityID: dropdown.fundid,
      };
      dispatch(getThunkPortfolio(parameters));
    }
  }, [dropdown.nonregisterid]);

  useEffect(() => {
    if (
      dropdown.fundid !== undefined &&
      dropdown.registerid !== undefined
      // &&
      // dropdown.registerid > 0
    ) {
      const parameters = {
        investorID: 0,
        partnerID: dropdown.registerid,
        legalEntityID: dropdown.fundid,
      };
      dispatch(getThunkPortfolio(parameters));
    }
  }, [dropdown.fundid, dropdown.registerid]);

  let content;
  switch (portState) {
    case "loading":
      content = <Loader />;

      break;
    case "succeeded":
      if (mapD && mapD?.length > 0) {
        for (let i = 0; i < mapD!.length; i++) {
          location.push({
            id: mapD[i].productID,
            category: mapD![i].category,
            data: {
              lat: mapD![i].latitude,
              lng: mapD![i].longitude,
            },
          });
        }
      } else {
        console.log("no data found");
      }

      content = (
        <>
          <div className="grid">
            <div className="col-12 md:col-6 mt-2">
              <div className="card pgHeaderWrap md:my-2 mt-3">
                <h1 className="pageHeader">Portfolio Dashboard</h1>
                <span className="pageSubHeader">
                  Overview of your balance and account portfolio
                </span>
              </div>
            </div>
            <div className="col-12 md:col-6 mt-3">
              <div className="register-dropdown-wrapper">
                <RegisterDropdownComponent
                  showRegisteredInvestorDropdown={false}
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12 md:col-8">
              <div className="card my-3 ">
                <Card>
                  <h4 className="cardHeader">Account Overview</h4>
                  <p>Your account overview at a glance:</p>
                  <div className="flex flex-column md:flex-row">
                    <div className="acc-value-details w:100 md:w-4">
                      <div className="acc-value-details-icon">
                        <RiScalesLine className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Account Value{" "}
                        </p>
                        {accountValue?.length !== 0 ? (
                          <>
                            {" "}
                            {accountValue?.map((Item, i) => {
                              const amount = Number(Item.amount);
                              return Item.amountType === "Account Value" ? (
                                <>
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  >
                                    {currencyFormat(Number(amount))}
                                  </p>
                                </>
                              ) : (
                                <p
                                  key={i}
                                  className="acc-value-details-textValue"
                                >
                                  {" "}
                                </p>
                              );

                              // amountType
                              // amount
                            })}
                          </>
                        ) : (
                          <p className="acc-value-details-textValue">$0</p>
                        )}
                      </div>
                    </div>
                    <div className="acc-value-details w:100 md:w-4">
                      <div className="acc-value-details-icon">
                        <RiMoneyDollarBoxLine className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Total Earnings
                        </p>
                        {accountValue?.length !== 0 ? (
                          <>
                            {" "}
                            {accountValue?.map((Item, i) => {
                              const amount = Number(Item.amount);

                              return Item.amountType === "Earnings" ? (
                                <p
                                  key={i}
                                  className="acc-value-details-textValue"
                                >
                                  {currencyFormat(Number(amount))}
                                </p>
                              ) : (
                                <p
                                  key={i}
                                  className="acc-value-details-textValue"
                                ></p>
                              );
                              // amountType
                              // amount
                            })}
                          </>
                        ) : (
                          <p className="acc-value-details-textValue">$0</p>
                        )}
                      </div>
                    </div>
                    <div className="acc-value-details w:100 md:w-4">
                      <div className="acc-value-details-icon">
                        <RiHotelLine className="icons-wrap" />
                      </div>
                      <div className="acc-value-details-text">
                        <p className="acc-value-details-textHeader">
                          Available Cash
                        </p>
                        {accountValue?.length !== 0 ? (
                          <>
                            {" "}
                            {accountValue?.map((Item, i) => {
                              const amount = Number(Item.amount);

                              return Item.amountType === "Cash Balance" ? (
                                <p
                                  key={i}
                                  className="acc-value-details-textValue"
                                >
                                  {currencyFormat(
                                    Number(amount),
                                    amount <= 0 && amount > -1 ? 4 : 2
                                  )}
                                </p>
                              ) : (
                                <p
                                  key={i}
                                  className="acc-value-details-textValue"
                                ></p>
                              );
                              // amountType
                              // amount
                            })}{" "}
                          </>
                        ) : (
                          <p className="acc-value-details-textValue">$0</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>

              <div className="activeInvest-wrap card my-3">
                <Card>
                  <div className="d-flex mb-2">
                    <h4 className="cardHeader mr-auto">Asset Classes</h4>
                  </div>
                  <div>
                    <DataTable
                      value={assets}
                      showGridlines
                      responsiveLayout="scroll"
                      // footerColumnGroup={footerGroup}
                    >
                      <Column
                        field="amountType"
                        header="Category"
                        // footer="Total"
                      ></Column>
                      <Column
                        field="invGol"
                        header="Investment Goal"
                        // footer="Total"
                      ></Column>
                      <Column
                        field="deals"
                        header="Number of Deals"
                        // footer="0"
                      ></Column>
                      <Column
                        field="amount"
                        header="Value"
                        body={(rowData) => formatCurrency(rowData.amount)}
                      ></Column>
                    </DataTable>
                  </div>
                </Card>
              </div>
              <div className="liquidity-wrap card my-3">
                <Card>
                  <h4 className="cardHeader">Portfolio Liquidity</h4>
                  <p>Estimated based upon current investments</p>
                  <div>
                    <StackedBar />
                  </div>
                </Card>
              </div>
              <div className="activeInvest-wrap card">
                <Card>
                  <div className="md:flex justify-content-between">
                    <h4 className="cardHeader md:d-inline-block d-block">
                      Market Diversification
                    </h4>
                    <p className="md:d-inline-block d-block pr-3 themeTextColor font-semibold">
                      Toggle to Fullscreen for Investment Details{" "}
                      <RiArrowDownLine />
                    </p>
                  </div>
                  <div>
                    <GoogleMap location={location} />
                  </div>
                  <div className="d-flex">
                    <p className="pr-4 pt-2 mb-1">
                      <img src={blueIcon} />
                      <span>Residential Debt</span>
                    </p>
                    <p className="pr-4 pt-2 mb-1">
                      <img src={yellowIcon} />
                      <span>Commercial Debt</span>
                    </p>
                    <p className="pr-4 pt-2 mb-1">
                      <img src={orangeIcon} />
                      <span>Equity</span>
                    </p>
                  </div>
                  <p className="text-sm mb-0 themeTextColor">
                    <RiInformationFill /> Mouse hover on pins provides basic
                    investment information. Expand the map to see more details.
                  </p>
                </Card>
              </div>
            </div>
            <div className="col-12 md:col-4">
              {/* {partnerID <= 0 && (
                <div className="green-bg card my-3">
                  <Card>
                    {badgeStatus === "awaiting approval" ||
                    badgeStatusStep2_3 === "awaiting approval" ? (
                      <h4 className="cardHeaderWhite">
                        Continue Registration.
                      </h4>
                    ) : (
                      <h4 className="cardHeaderWhite">Lets get started.</h4>
                    )}
                    <p>Complete your account setup to add funds:</p>
                    {badgeStatus === "awaiting approval" ||
                    badgeStatusStep2_3 === "awaiting approval" ? (
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          // navigate(
                          //   `/../../../InvestorProfile/investorProfilePage`
                          // );
                        }}
                        className="BtnWhiteWithoutShadow"
                      >
                        Continue Setup
                      </Button>
                    ) : (
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          // navigate(
                          //   `/../../../InvestorProfile/investorProfilePage`
                          // );
                        }}
                        className="BtnWhiteWithoutShadow"
                      >
                        Start Setup
                      </Button>
                    )}
                  </Card>
                </div>
              )} */}
              <div className="accvalue-wrap card my-3">
                <Card>
                  <h4 className="cardHeader">Account Snapshot</h4>
                  <div className="position-relative">
                    <DoughnutChartDemo />
                  </div>
                  <div className="custom-switches">
                    {/* <p>Show Cash:</p> */}
                    <div>
                      {/* <InputSwitch
                        checked={checked}
                        onChange={(e: InputSwitchChangeParams) =>
                          setChecked(e.value)
                        }
                      /> */}
                    </div>
                    <div className="flex flex-column xl:flex-row snapshot-flex">
                      <div className="acc-value-details w-100 lg:w-50">
                        <div className="acc-value-details-icon">
                          <RiLineChartFill className="icons-wrap" />
                        </div>
                        <div className="acc-value-details-text">
                          <p className="acc-value-details-textHeader">
                            Invested Capital
                          </p>
                          {accountValue?.length !== 0 ? (
                            <>
                              {" "}
                              {accountValue?.map((Item, i) => {
                                const amount = Number(Item.amount);

                                return Item.amountType ===
                                  "Invested Capital" ? (
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  >
                                    {currencyFormat(Number(amount))}
                                  </p>
                                ) : (
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  ></p>
                                );
                                // amountType
                                // amount
                              })}{" "}
                            </>
                          ) : (
                            <p className="acc-value-details-textValue">$0</p>
                          )}
                        </div>
                      </div>
                      <div className="acc-value-details w-100 lg:w-50">
                        <div className="acc-value-details-icon">
                          <RiMoneyDollarBoxLine className="icons-wrap" />
                        </div>
                        <div className="acc-value-details-text">
                          <p className="acc-value-details-textHeader">
                            Cash Balance
                          </p>
                          {accountValue?.length !== 0 ? (
                            <>
                              {" "}
                              {accountValue?.map((Item, i) => {
                                const amount = Number(Item.amount);

                                return Item.amountType === "Cash Balance" ? (
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  >
                                    {currencyFormat(
                                      Number(amount),
                                      amount <= 0 && amount > -1 ? 4 : 2
                                    )}
                                  </p>
                                ) : (
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  ></p>
                                );
                                // amountType
                                // amount
                              })}{" "}
                            </>
                          ) : (
                            <p className="acc-value-details-textValue">$0</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-column xl:flex-row snapshot-flex">
                      <div className="acc-value-details w-100 lg:w-50">
                        <div className="acc-value-details-icon">
                          <RiDownload2Line className="icons-wrap" />
                        </div>
                        <div className="acc-value-details-text">
                          <p className="acc-value-details-textHeader">
                            Capital Deposits
                          </p>
                          {accountValue?.length !== 0 ? (
                            <>
                              {" "}
                              {accountValue?.map((Item, i) => {
                                const amount = Number(Item.amount);

                                return Item.amountType ===
                                  "Capital Deposits" ? (
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  >
                                    {currencyFormat(Number(amount))}
                                  </p>
                                ) : (
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  ></p>
                                );
                                // amountType
                                // amount
                              })}{" "}
                            </>
                          ) : (
                            <p className="acc-value-details-textValue">$0</p>
                          )}
                        </div>
                      </div>
                      <div className="acc-value-details w-100 lg:w-50">
                        <div className="acc-value-details-icon">
                          <RiUpload2Line className="icons-wrap" />
                        </div>
                        <div className="acc-value-details-text">
                          <p className="acc-value-details-textHeader">
                            Capital Withdrawals
                          </p>
                          {accountValue?.length !== 0 ? (
                            <>
                              {" "}
                              {accountValue?.map((Item, i) => {
                                const amount = Number(Item.amount);

                                return Item.amountType ===
                                  "Capital Withdrawals" ? (
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  >
                                    {currencyFormat(Number(amount))}
                                  </p>
                                ) : (
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  ></p>
                                );
                                // amountType
                                // amount
                              })}{" "}
                            </>
                          ) : (
                            <p className="acc-value-details-textValue">$0</p>
                          )}
                        </div>
                      </div>
                    </div>{" "}
                    <div className="flex flex-column xl:flex-row snapshot-flex">
                      <div className="acc-value-details w-100 lg:w-50">
                        <div className="acc-value-details-icon">
                          <RiHandCoinLine className="icons-wrap" />
                        </div>
                        <div className="acc-value-details-text">
                          <p className="acc-value-details-textHeader">
                            Earnings
                          </p>
                          {accountValue?.length !== 0 ? (
                            <>
                              {" "}
                              {accountValue?.map((Item, i) => {
                                const amount = Number(Item.amount);

                                return Item.amountType === "Earnings" ? (
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  >
                                    {currencyFormat(Number(amount))}
                                  </p>
                                ) : (
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  ></p>
                                );
                                // amountType
                                // amount
                              })}{" "}
                            </>
                          ) : (
                            <p className="acc-value-details-textValue">$0</p>
                          )}
                        </div>
                      </div>
                      {/* <div className="acc-value-details w-100 lg:w-50">
                        <div className="acc-value-details-icon">
                          <RiNewspaperLine className="icons-wrap" />
                        </div>
                        <div className="acc-value-details-text">
                          <p className="acc-value-details-textHeader">
                            Annual Fund Expenses
                          </p>
                          {accountValue?.length !== 0 ? (
                            <>
                              {" "}
                              {accountValue?.map((Item, i) => {
                                return Item.amountType === "Yearly Expenses" ? (
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  >
                                                                       ${currencyFormat(Number(amount))}

                                  </p>
                                ) : (
                                  <p
                                    key={i}
                                    className="acc-value-details-textValue"
                                  ></p>
                                );
                                // amountType
                                // amount
                              })}{" "}
                            </>
                          ) : (
                            <p className="acc-value-details-textValue">$0</p>
                          )}
                        </div>
                      </div> */}
                    </div>
                  </div>
                </Card>
              </div>
              <div className="card my-3">
                <Card>
                  <h4 className="cardHeader">Exited Deals</h4>
                  <div className="position-relative">
                    <DoughnutChartExcitedDeal />
                    {/* <span className="DoughnutChartData">data</span> */}
                  </div>
                  <div>
                    <div className="flex flex-column xl:flex-row snapshot-flex">
                      <div className="acc-value-details w-100 lg:w-50">
                        <div className="acc-value-details-icon">
                          <RiBriefcase3Line className="icons-wrap" />
                        </div>
                        <div className="acc-value-details-text">
                          <p className="acc-value-details-textHeader">
                            Exited Deals
                          </p>
                          {exitedDeals !== undefined ? (
                            <p className="acc-value-details-textValue">
                              {exitedDeals?.existedCount}
                            </p>
                          ) : (
                            <p className="acc-value-details-textValue">0</p>
                          )}
                        </div>
                      </div>
                      <div className="acc-value-details w-100 lg:w-50">
                        <div className="acc-value-details-icon">
                          <RiCalendar2Line className="icons-wrap" />
                        </div>
                        <div className="acc-value-details-text">
                          <p className="acc-value-details-textHeader">
                            Annualized Returns
                          </p>
                          {exitedDeals !== undefined ? (
                            <p className="acc-value-details-textValue">
                              {exitedDeals?.avgAnnulizedRetun.toFixed(2)} %
                            </p>
                          ) : (
                            <p className="acc-value-details-textValue">0</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </>
      );

      break;
    case "failed":
      content = <Error />;

      // content = data.map((product: any) => {
      //   return <OpportunityCard p={product} key={product.productID} />;
      // });
      break;
  }

  return <>{content}</>;
}
