import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPrimaryFolderFileManager } from "../../../../services/Filemanger/filemanager";
import error from "../../../../utils/error";
import { IFileManagerPrimaryFolders } from ".";

const initialState: IFileManagerPrimaryFolders = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    PrimaryFolders: [],
  },
};
export const getPrimaryFolderFileManagerThunk = createAsyncThunk(
  "FileManagement/GetMasterDirectory",
  async () => {
    try {
      const response = await getPrimaryFolderFileManager();
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "GetPrimaryFolderFileManager",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getPrimaryFolderFileManagerThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getPrimaryFolderFileManagerThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(
          "action.payload._response.data==",
          action.payload.response.data.documents
        );
        state.data.PrimaryFolders = action.payload
          ? action.payload.response.data.documents
          : [];
      }
    );
    builder.addCase(getPrimaryFolderFileManagerThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
