import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { K1ReconciliationByInvestorService } from "../../../../../services/taxmanagement/tax";
import error from "./../../../../../utils/error";
import {
  IK1ReconciliationByInvestorState,
  IK1ReconciliationByInvestorparameters,
} from ".";

const initialState: IK1ReconciliationByInvestorState = {
  success: false,
  message: "",
  loading: "loading",
  data1: "",
  data2: "",
};
export const K1ReconciliationByInvestorThunk = createAsyncThunk(
  "TaxManagement/K1ReconciliationByInvestor",
  async (data: IK1ReconciliationByInvestorparameters) => {
    try {
      const response = await K1ReconciliationByInvestorService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "TaxManagement/K1ReconciliationByInvestor",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(K1ReconciliationByInvestorThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      K1ReconciliationByInvestorThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data1 = action.payload._response.data
          ? action.payload._response.data[0]?.[0]?.result
          : [];
        state.data2 = action.payload._response.data
          ? action.payload._response.data[1]?.[0]?.result
          : [];
      }
    );
    builder.addCase(K1ReconciliationByInvestorThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
