export const LOGIN = "login";
export const LOGOUT = "logout";
export const CHANGEPASSWORD = "changepassword";
export const FORGOTPASSWORD = "forgotpassword";
export const TOKEN = "token";
export const regexForPassword =
  /(?=[\d!#$%&+=@A-Z^a-z]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&+=@^])(?=.{8,}).*$/;
// export const regexForEmail = /[\d%+._a-z-]+@[\d.a-z-]+\.[a-z]{2,3}/;
// export const regexForEmail = /[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,3}/i;
export const regexForEmail = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,64}$/;
export const regexForLowercaseAndNumber = /^[\da-z]*$/;
export const regexForLowercaseOnly = /^[a-z]*$/;
export const regexForAlphaNumericNoNumber = /^(?!\d*$)[\da-z]+$/;
export const regexForUrl =
  /^(https?:\/\/(?:www\.|(?!www))?[\dA-Za-z-]+(?:\.[A-Za-z]{2,})+)(?:\/[\dA-Za-z]+)?$/;

export const regexForNumberGreaterThanZero = /^[1-9]\d*(\.\d+)?$/;
