import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetLPPartnersByInvestmentIdService } from "../../../../../services/Accounting/accounting";
import error from "../../../../../utils/error";
import {
  IGetLPPartnersByInvestmentIdParameter,
  IGetLPPartnersByInvestmentIdState,
  Partner,
} from ".";

const initialState: IGetLPPartnersByInvestmentIdState = {
  success: false,
  message: "",
  loading: "loading",
  result: undefined,
  error: "",
  data: undefined,
  objData: undefined,
};
export const GetLPPartnersByInvestmentIdThunk = createAsyncThunk(
  "Accounting/GetLPPartnersByInvestmentId",
  async (data: IGetLPPartnersByInvestmentIdParameter) => {
    try {
      const response = await GetLPPartnersByInvestmentIdService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Accounting/GetLPPartnersByInvestmentId",
  initialState,
  reducers: {
    setForm: (state, action) => {
      state.data = action.payload;
      //
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetLPPartnersByInvestmentIdThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      GetLPPartnersByInvestmentIdThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload.data ? action.payload.data : [];
        state.objData = action.payload.data
          ? Object.fromEntries(
              action.payload.data.map(({ key, ...rest }: Partner) => [
                key,
                rest,
              ])
            )
          : undefined;

        // if (action.payload.data !== null) {
        // }
        state.message =
          action.payload.message === null ? undefined : action.payload.message;
      }
    );
    builder.addCase(
      GetLPPartnersByInvestmentIdThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        console.log("errorslice", action.error);
        //   // action.payload contains error information
        // state.error = error(action.payload);
        state.error = error(action.error);
      }
    );
  },
});
export const { setForm } = slice.actions;
export default slice.reducer;
