import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetNotificationCountService } from "../../../../services/operation/operation";
import error from "../../../../utils/error";
import { IGetNotificationCountState } from ".";

const initialState: IGetNotificationCountState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    notificationCount: {
      reviewSoftCommit: 0,
      convertToDeal: 0,
      reviewApproveRegistration: 0,
      blueSkyFilling: 0,
      allocateSlices: 0,
      allocateEarings: 0,
      executeEsignOnDemand: 0,
    },
  },
};
export const getThunkNotificationCount = createAsyncThunk(
  "getNotificationCount",
  async (data: any) => {
    try {
      const response = await GetNotificationCountService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getNotificationCount",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getThunkNotificationCount.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkNotificationCount.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("hr", action.payload);
      if (action.payload.data !== null) {
        state.data.notificationCount =
          action.payload.data.notificationCount[0][0];
      }
      state.success = action.payload.success;
      state.message = action.payload.message;
    });
    builder.addCase(getThunkNotificationCount.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
