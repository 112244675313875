import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../components/loader/Loader";
import { goToTop } from "../../../../components/scroll-to/ScrollTo";
import { addThunkThirdPartyApi } from "../../../../store/features/operation/setup-fund/add-third-party-api/slice";
// import { getThunkSetupFundEntityList } from "../../../../store/features/operation/setup-fund/get-setup-entity-list/slice";
import { getThunkSetupFund } from "../../../../store/features/operation/setup-fund/get-setup-fund/slice";
import { setStateChangeCheck } from "../../../../store/features/operation/setup-fund/setup-fund-state-check/slice";
// import { setSetupTabNameState } from "../../../../store/features/operation/setup-fund/setup-fund-tabname/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

interface IProps {
  pageName: any;
}
export default function SetupFundThirdParty(props: IProps) {
  const { pageName } = props;
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [disableField, setDisableField] = useState(false);
  const [loading, setLoading] = useState(false);
  const setupFundInfo = useAppSelector((state) => state.getSetupFundInfo.data);
  //  const tabNameField = useAppSelector((state) => state.setupFundTabName.data);
  const addThirdPartyApiState = useAppSelector(
    (state) => state.addThirdPartyApi.data
  );
  const addCancelSetup = useAppSelector(
    (state) => state.addCancelSetupState.data
  );
  const entityListId = useAppSelector(
    (state) => state.getSetupFundEntityList.data
  );
  const statecheckSetupFund = useAppSelector(
    (state) => state.statecheckSetupFund.data
  );
  const getSetupFundData = async (
    formik: any,
    legalEntityId?: any,
    gpEntityId?: any
  ) => {
    const parameters = {
      legalEntityId,
      gpLegalEntityId: gpEntityId || 0,
    };

    setLoading(true);
    await dispatch(getThunkSetupFund(parameters))
      .then((response) => {
        if (
          response.payload._response.data === null ||
          response.payload._response.data.thirdPartyAPIkeys!.length === 0
        ) {
          formik.resetForm();
          setLoading(false);
        }
      })
      .catch(() => {
        // errorToastMessage(toast);
        formik.resetForm();
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const formik = useFormik({
    initialValues: {
      hubSpotAPIKey: "",
      activeCampaignAccountName: "",
      activeCampaigApiToken: "",
      goHighLevelAPIToken: "",
      chargebeeID: "",
      chargebeeSubscriptionID: "",
      chargebeePlanID: "",
    },
    validate: (data) => {
      const errors: any = {};
      // if (!data.hubSpotAccessToken) {
      //   errors.hubSpotAccessToken = "Token is required";
      // }
      return errors;
    },
    onSubmit: () => {
      setLoading(true);
      const formData = {
        action:
          setupFundInfo.thirdPartyAPIkeys === undefined ? "Create" : "Update",
        fundLPEntityId: setupFundInfo?.fundProfileDetails?.legalEntityID,
        chargebeeID: formik.values.chargebeeID,
        chargebeeSubscriptionID: formik.values.chargebeeSubscriptionID,
        activeCampaignAccountName: formik.values.activeCampaignAccountName,
        activeCampaigApiToken: formik.values.activeCampaigApiToken,
        goHighLevelAPIToken: formik.values.goHighLevelAPIToken,
        hubSpotAPIKey: formik.values.hubSpotAPIKey,
        chargebeePlanID: formik.values.chargebeePlanID,
      };

      dispatch(addThunkThirdPartyApi(formData))
        .then((response) => {
          if (response.payload._response.success === false) {
            errorToastMessage(toast);
          } else {
            successToastMessage(toast, "Data saved successfully");
            // dispatch(
            //   setSetupTabNameState({ ...tabNameField, changeState: false })
            // );
            getSetupFundData(
              formik,
              setupFundInfo?.fundProfileDetails?.legalEntityID,
              entityListId?.gpllcEntityId || 0
            );
            // setLoading(false);
          }
        })
        .catch((error: any) => {
          console.log("error", error);

          errorToastMessage(toast);
          // setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };
  console.log("addThirdPartyApiState", addThirdPartyApiState);
  useEffect(() => {
    if (addCancelSetup.add === true) {
      formik.resetForm();
      setLoading(false);
    }
    // else {
    //   if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
    //     getSetupFundData(
    //       formik,
    //       fundDropdown.fundid,
    //       entityListId?.gpllcEntityId || 0
    //     );
    //   }
    //   setLoading(false);
    // }
  }, [fundDropdown, entityListId, addCancelSetup]);

  useEffect(() => {
    // dispatch(getThunkFundTypeDropdown());
    if (pageName === "Reports") {
      setDisableField(true);
    } else {
      setDisableField(false);
    }
  }, []);
  useEffect(() => {
    if (setupFundInfo?.thirdPartyAPIkeys !== undefined) {
      formik.setFieldValue(
        "hubSpotAPIKey",
        setupFundInfo?.thirdPartyAPIkeys?.hubSpotAPIKey
      );
      formik.setFieldValue(
        "activeCampaignAccountName",
        setupFundInfo?.thirdPartyAPIkeys?.activeCampaignAccountName
      );
      formik.setFieldValue(
        "activeCampaigApiToken",
        setupFundInfo?.thirdPartyAPIkeys?.activeCampaigApiToken
      );
      formik.setFieldValue(
        "goHighLevelAPIToken",
        setupFundInfo?.thirdPartyAPIkeys?.goHighLevelAPIToken
      );
      formik.setFieldValue(
        "chargebeeID",
        setupFundInfo?.thirdPartyAPIkeys?.chargebeeID
      );
      formik.setFieldValue(
        "chargebeeSubscriptionID",
        setupFundInfo?.thirdPartyAPIkeys?.chargebeeSubscriptionID
      );
      formik.setFieldValue(
        "chargebeePlanID",
        setupFundInfo?.thirdPartyAPIkeys?.chargebeePlanID === null
          ? ""
          : setupFundInfo?.thirdPartyAPIkeys?.chargebeePlanID
      );
    }
  }, [setupFundInfo]);
  useEffect(() => {
    const list = { ...statecheckSetupFund.thirdPartyApi };
    list.NewState = formik.values;
    dispatch(
      setStateChangeCheck({ ...statecheckSetupFund, thirdPartyApi: list })
    );
  }, [formik.values]);
  console.log("check state third", statecheckSetupFund);
  // useEffect(() => {
  //   if (setupFundInfo?.thirdPartyAPIkeys !== undefined) {
  //     const values1 = Object.values(setupFundInfo?.thirdPartyAPIkeys);
  //     const values2 = Object.values(formik.values);
  //     console.log("values", values1);
  //     console.log("values2", values2);
  //     const check = values1.every((value, index) => value === values2[index]);
  //     console.log("check state", check);
  //   }
  // }, [formik.values]);

  // useEffect(() => {
  //   if (
  //     setupFundInfo?.thirdPartyAPIkeys !== undefined &&
  //     addThirdPartyApiState !== undefined
  //   ) {
  //     const values1 = Object.values(setupFundInfo?.thirdPartyAPIkeys);
  //     const values2 = Object.values(addThirdPartyApiState);
  //     console.log("values", values1);
  //     console.log("values2", values2);
  //     const check = values1.every((value, index) => value === values2[index]);
  //     console.log("check state", check);
  //   }
  // }, [tabNameField.clickPageName]);
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <div>
        <div className=" h-100 center-card">
          <div className="col-12 ">
            <div className="card my-3">
              <div className="headercard d-flex grid"></div>
              <form>
                <div className="mt-4">
                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">
                        HubSpot Access Token{" "}
                      </label>
                      <InputText
                        name="hubSpotAPIKey"
                        value={formik.values.hubSpotAPIKey}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("hubSpotAPIKey"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter token"
                      />
                      {getFormErrorMessage("hubSpotAPIKey")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">GoHighlevel API Key</label>
                      <InputText
                        name="goHighLevelAPIToken"
                        value={formik.values.goHighLevelAPIToken}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid(
                            "goHighLevelAPIToken"
                          ),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter account name"
                      />
                      {getFormErrorMessage("goHighLevelAPIToken")}
                    </div>
                  </div>

                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">
                        Active Campaign Account Name{" "}
                      </label>
                      <InputText
                        name="activeCampaignAccountName"
                        value={formik.values.activeCampaignAccountName}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid(
                            "activeCampaignAccountName"
                          ),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter account name"
                      />
                      {getFormErrorMessage("activeCampaignAccountName")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                      <label className="inputLabel">
                        Active Campaign API Token
                      </label>
                      <InputText
                        name="activeCampaigApiToken"
                        value={formik.values.activeCampaigApiToken}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid(
                            "activeCampaigApiToken"
                          ),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter token"
                      />
                      {getFormErrorMessage("activeCampaigApiToken")}
                    </div>
                  </div>

                  <div className="formgrid grid px-2">
                    <label
                      style={{ fontSize: "22px" }}
                      className="inputLabel m-2 mb-4"
                    >
                      Chargebee
                    </label>
                  </div>

                  <div className="formgrid grid px-2">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                      <label className="inputLabel">
                        Chargebee Customer Id
                      </label>
                      <InputText
                        name="chargebeeID"
                        value={formik.values.chargebeeID}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("chargebeeID"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter customer id"
                      />
                      {getFormErrorMessage("chargebeeID")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                      <label className="inputLabel">
                        Chargebee Subscription Id
                      </label>
                      <InputText
                        name="chargebeeSubscriptionID"
                        value={formik.values.chargebeeSubscriptionID}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid(
                            "chargebeeSubscriptionID"
                          ),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter subscription id"
                      />
                      {getFormErrorMessage("chargebeeSubscriptionID")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                      <label className="inputLabel">Chargebee Plan Id</label>
                      <InputText
                        name="chargebeePlanID"
                        value={formik.values.chargebeePlanID}
                        onChange={(e) => handleChange(e)}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("chargebeePlanID"),
                          "card-inputBox": true,
                        })}
                        disabled={disableField === true}
                        placeholder="Enter plan id"
                      />
                      {getFormErrorMessage("chargebeePlanID")}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {pageName !== "Reports" ? (
              <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
                <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
                  <Button
                    className="btn-dialog "
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      // handleChangeState();
                      goToTop();
                      formik.handleSubmit();
                    }}
                  >
                    <span className="ml-auto mr-auto ">Save</span>
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
