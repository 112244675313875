import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useMemo, useRef, useState } from "react";
import AmountDisplay from "../../../components/amount-display";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
import Loader from "../../../components/loader/Loader";
import { getThunkEarningsEntries } from "../../../store/features/operation/reverse-earning-allocation/get-earnings-entries/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { reverseAllocatedPoolFundsByStakeIdThunk } from "../../../store/poolfunds/exit-pool-funds";
import {
  getAfterEarningsStatckbyInvestmentReturnIDThunk,
  resetAllEarningsStatckbyInvestmentReturn,
} from "../../../store/poolfunds/get-after-earning-stake-byinvestment";
// import { getAllInvestorListPoolFunds } from "../../../store/poolfunds/get-all-Investor-list-pool-funds";
import { getAllocatedInvestorsByInvestmentPoolfundsThunk } from "../../../store/poolfunds/get-allocatedinvestors-Investment-poolfunds";
import {
  PoolFundOutPutType,
  PoolFundsByInvestorIdParamType,
} from "../../../types";
import currencyFormat from "../../../utils/currency-formatter";
import {
  convertUTCToLocalDate,
  getListDateConversion,
} from "../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
import ReverseEarningSection from "../../features/operation/reverse-earning-allocation/ReverseEarningSectionContinuous";

const AllocatedInvester: React.FC = () => {
  const toast = useRef<Toast>(null);
  const [isOpenRevers, setIsOpenReverse] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [stakeParam, setStakeParam] = useState<
    PoolFundsByInvestorIdParamType | any
  >();
  const [rowSelectData, setRowSelectData] = useState<any>();
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useAppDispatch();
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const addPoolFundData = useAppSelector((state) => state.AddPoolFunds.data);
  const allocatedInvesterData = useAppSelector(
    (state) => state.getAllAllocatedInvesterPoolFund.data.output
  );

  const getAllAllocatedInvesterPoolFundData = () => {
    setLoading(true);
    const parameter = {
      legalEntityId: fundDropdown.fundid,
      investmentId: addPoolFundData.investmentId,
    };
    dispatch(getAllocatedInvestorsByInvestmentPoolfundsThunk(parameter))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const allocatedInvester = useMemo(() => {
    const result = getListDateConversion(allocatedInvesterData, [
      "maxStartDate",
    ]);
    if (result.length > 0) {
      result[0].isButton = true;
    }
    return result;
  }, [allocatedInvesterData]);
  console.log("allocatedInvester", allocatedInvester);

  // const viewStake = (rowData: PoolFundOutPutType) => {
  //   const handleViewStake = async (data: PoolFundOutPutType) => {
  //     try {
  //       const param = {
  //         legalEntityId: fundDropdown.fundid,
  //         investmentId: data.investmentID,
  //         lPPartnerId: data.lpPartnerId,
  //       };
  //       setStakeParam({ ...data, ...param });
  //       setIsOpenReverse(true);
  //     } catch (error) {
  //       setInvestorStakeData([]);
  //       console.log("error", error);
  //     }
  //   };
  //   return (
  //     <>
  //       {rowData?.isButton ? (
  //         <Button
  //           className="btn-navActive"
  //           onClick={(e) => {
  //             e.preventDefault();
  //             handleViewStake(rowData);
  //           }}
  //         >
  //           Reverse
  //         </Button>
  //       ) : (
  //         <Button
  //           className="btn-navActive"
  //           onClick={(e) => {
  //             e.preventDefault();
  //           }}
  //           disabled={true}
  //         >
  //           Reverse
  //         </Button>
  //       )}
  //     </>
  //   );
  // };

  const dataTableElement = [
    {
      field: "lpPartnerName",
      header: "Investor Name",
      // style: { width: "70px" },
      noFilter: true,
    },

    { field: "earningsPayoutTiming", header: "Earning Timing", noFilter: true },
    {
      field: "addOnUnits",
      header: "Allocated Investment Balance",
      body: (rowData: PoolFundOutPutType) => (
        <AmountDisplay amount={rowData?.addOnUnits ? rowData?.addOnUnits : 0}>
          {currencyFormat(rowData.addOnUnits)}
        </AmountDisplay>
      ),
    },
    {
      field: "unitsType",
      header: "Units Type",
    },
    {
      field: "maxStartDate",
      header: "Previous Allocation Date",
      dataType: "date",
      body: (rowData: PoolFundOutPutType) =>
        convertUTCToLocalDate(rowData.maxStartDate),
    },
    {
      field: "maxStartDate",
      header: "End Date",
      dataType: "date",
      body: (rowData: PoolFundOutPutType) =>
        rowData.endDate ? convertUTCToLocalDate(rowData.endDate) : "N/A",
    },
    { field: "assetClass", header: "Class" },
    // { header: "Reverse", body: viewStake },
  ];

  const onDialogCancel = (val: boolean) => {
    setStakeParam("");
    setIsOpenReverse(val);
    setOpenDialog(val);
    setRowSelectData({});
  };
  const onDistubuteDailogClose = (val: boolean) => {
    setOpenDialog(val);
    setIsOpenReverse(val);
    setStakeParam("");
    setRowSelectData({});
    dispatch(resetAllEarningsStatckbyInvestmentReturn());
    getAllAllocatedInvesterPoolFundData();
  };

  const handleDistribute = (rowData: any) => {
    setRowSelectData(rowData);
    setLoading(true);
    const parameter = {
      investmentReturnTypeId: rowData.investmentReturnTypeID,
      investmentReturnId: rowData.investmentReturnID,
      investmentId: rowData.investmentID,
    };
    dispatch(getThunkEarningsEntries(parameter)).then(() => {
      setLoading(false);
      setOpenDialog(true);
      setIsOpenReverse(false);
    });
    dispatch(
      getAfterEarningsStatckbyInvestmentReturnIDThunk({
        investmentReturnId: rowData.investmentReturnID,
        investmentId: rowData.investmentID,
      })
    );
  };

  const handleReverse = (data: any) => {
    setLoading(true);
    const param = {
      legalEntityId: fundDropdown.fundid,
      investmentId: addPoolFundData.investmentId,
      stakeTrackingId: data.stakeTrackingID,
    };
    dispatch(reverseAllocatedPoolFundsByStakeIdThunk(param))
      .then((res) => {
        setLoading(false);
        if (res.payload.success) {
          successToastMessage(toast, res.payload.message);
        } else {
          errorToastMessage(
            toast,
            res.payload.message || "Something went wrong!"
          );
        }
        getAllAllocatedInvesterPoolFundData();
        onDialogCancel(false);
      })
      .catch(() => {
        setLoading(false);
        errorToastMessage(toast, "Something went wrong!");
        getAllAllocatedInvesterPoolFundData();
      });
  };
  console.log("isOpenRevers", isOpenRevers, "opendailog", openDialog);

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />

      <ReverseEarningSection
        openDialog={openDialog}
        setOpenDialog={onDistubuteDailogClose}
        rowSelectData={rowSelectData}
        setRowSelectData={setRowSelectData}
      />

      <Dialog
        visible={isOpenRevers}
        className="w-100 md:w-3 pb-1"
        onHide={() => onDialogCancel(false)}
      >
        <h4 className="pageHeader text-center mb-5 pt-5">
          {stakeParam?.investmentReturnID && stakeParam?.investmentReturnID > 0
            ? `You can not reverse this allocation. if you want to reverse this allocation please reverse  earning (${stakeParam.investmentReturnID})`
            : "Are you sure, you want to reverse the units"}
        </h4>
        {stakeParam?.investmentReturnID &&
        stakeParam?.investmentReturnID > 0 ? (
          <div className=" flex  flex-wrap align-self-center  align-items-center justify-content-between">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleDistribute(stakeParam);
              }}
              className="blueButtonShadow flex flex-1  align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round"
            >
              View Earning
            </Button>
          </div>
        ) : (
          <>
            <div className=" flex  flex-wrap align-self-center  align-items-center justify-content-between">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  stakeParam?.investmentReturnID &&
                  stakeParam?.investmentReturnID > 0
                    ? onDialogCancel(false)
                    : handleReverse(stakeParam);
                }}
                className="blueButtonShadow flex flex-1 flex align-items-center justify-content-center  font-bold  m-2 px-5 py-3 border-round"
              >
                Yes
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onDialogCancel(false);
                }}
                className="blueButtonShadow flex flex-1  align-items-center justify-content-center font-bold m-2 px-5 py-3 border-round"
              >
                No
              </Button>
            </div>
          </>
        )}
      </Dialog>
      <DataTableComponent
        // valueData={[]}
        valueData={allocatedInvester || []}
        fieldsElements={dataTableElement}
        noGridLines={true}
        isPaginationAllowed={true}
      />
    </>
  );
};

export default AllocatedInvester;
