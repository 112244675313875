import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAllNonRegisterPortfolioInvestmentService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";

interface IPortfolioState {
  data: any;
  dropdown?: [];

  loading: string;
  error?: string | null;
}

const initialState: IPortfolioState = {
  data: [],
  loading: "loading",
  error: undefined,
};

interface Iparameters {
  LegalEntityId: number;
}

export const getThunkPortfolioNonRegisterInvestorListDropdown =
  createAsyncThunk(
    "Portfolio/getNonRegisterInvestorListDropdown",
    async (data: Iparameters) => {
      try {
        const response = await getAllNonRegisterPortfolioInvestmentService(
          data
        );
        return response.data;
      } catch (error_) {
        throw new Error(error(error_));
      }
    }
  );

const slice = createSlice({
  name: "Portfolio/getNonRegisterInvestorListDropdown",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(
      getThunkPortfolioNonRegisterInvestorListDropdown.pending,
      (state) => {
        state.loading = "loading";
        state.error = undefined;
      }
    );
    builder.addCase(
      getThunkPortfolioNonRegisterInvestorListDropdown.fulfilled,
      (state, action) => {
        state.loading = "succeeded";

        state.data = action.payload.data.investorList;
      }
    );
    builder.addCase(
      getThunkPortfolioNonRegisterInvestorListDropdown.rejected,
      (state, action) => {
        state.loading = "failed";

        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
