import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetSupplierDropdownListService } from "../../../../../../services/taxmanagement/tax";
import error from "../../../../../../utils/error";
import { ISupplierDropDownListParameter, ISupplierDropDownListState } from ".";

const initialState: ISupplierDropDownListState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkSupplierDropDownList = createAsyncThunk(
  "supplierDropDownList",
  async (data: ISupplierDropDownListParameter) => {
    try {
      const response = await GetSupplierDropdownListService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "supplierDropDownList",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkSupplierDropDownList.pending, (state) => {
      state.loading = "loading";
      state.data = [];
      //   state.error = undefined;
    });
    builder.addCase(getThunkSupplierDropDownList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("set", action.payload);
      if (action.payload.data !== null) {
        state.data = action.payload.data[0];
      }
      state.success = action.payload.success;
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(getThunkSupplierDropDownList.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      state.data = [];
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});
export default slice.reducer;
