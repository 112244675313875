import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import DataTableComponent from "../../../../../components/data-table/DataTableComponent";

// import InvestmentFilter from "../../../../../components/investment-filter/InvestmentFilter";
import InvestmentDropdownFilter from "../../../../../components/investment-filter/InvestmentDropdownFilter";
import Loader from "../../../../../components/loader/Loader";
// import { getThunkLookupCode } from "../../../../../store/features/dropdown/lookup-code/slice";
import { getThunkExecuteEsignCommitmentDropDown } from "../../../../../store/features/e-sign/execute-e-sign/commitment-dropdown/slice";
import {
  getThunkExecuteEsignAgreement,
  setExecuteEsignAgreement,
} from "../../../../../store/features/e-sign/execute-e-sign/execute-agreements/slice";
import {
  getThunkTemplateByProduct,
  resetTemplateList,
} from "../../../../../store/features/e-sign/execute-e-sign/get-template-by-productId/slice";
import { sendThunkExecuteEsignEmail } from "../../../../../store/features/e-sign/execute-e-sign/send-email/slice";
import { getThunkExecuteEsignStatusDropDown } from "../../../../../store/features/e-sign/execute-e-sign/status-dropdown/slice";
import { getThunkExecuteEsignDocument } from "../../../../../store/features/e-sign/execute-e-sign/view-sign-document/slice";
// import { getThunkInvestmentById } from "../../../../../store/features/investment/create/slice";
import { getThunkInvestmentByFilter } from "../../../../../store/features/investment/investment-filter/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { getSasKeyThunk } from "../../../../../store/sas-key/slice";
import { currencyFormat } from "../../../../../utils/currency-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../../utils/toast-message";

export default function ExecuteEsign() {
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const statusDropDown = useAppSelector(
    (state) => state.executeEsignStatus.data
  );
  const commitmentDropDown = useAppSelector(
    (state) => state.executeEsignCommitment.data
  );
  // const profileData = useAppSelector((state) => state.profile.data![0]);
  const [productIdFilter, setProductIdFilter] = useState(0);
  const [productObjectByFilter, setProductObjectByFilter] = useState<any>();
  const [notSend, setNotSend] = useState(false);
  const [resendDoc, setResendDoc] = useState(false);
  const [resendEmail, setResendEmail] = useState(false);
  const [status, setStatus] = useState<any>();
  const [openDialog, setOpenDialog] = useState(false);
  const [openViewTemplateDialog, setOpenViewTemplateDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [visibleViewUpload, setVisibleViewUpload] = useState(false);
  const [dailogboxState, SetDialogboxState] = useState(false);
  const [openDailog, setOpenDailog] = useState(false);
  const getTemplatebyProduct = useAppSelector(
    (state) => state.getTemplateByProduct.data
  );
  const [templateLink, setTemplateLink] = useState<any>("");
  // const [isDocumnetAvail, setIsDocumnetAvail] = useState(true);
  const [messageToShow, setMessageToShow] = useState("");
  const [sasKey, setsasKey] = useState("");
  const viewDocumentData = useAppSelector(
    (state) => state.viewSignDocument.data
  );

  const [selectedCustomers, setSelectedCustomers] = useState<any[]>([]);

  // const [selectedValues, setSelectedValues] = useState<any[]>();
  const executeAgreements = useAppSelector(
    (state) => state.getExecuteEsign.data!
  );

  const [documentLink, setDocumentLink] = useState("");

  console.log("documentLink", documentLink);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const filterInvestment = useAppSelector(
    (state) => state.investmentByFilter.data!
  );
  const [loading, setLoading] = useState(false);
  const investmentDropDown: any = [];

  filterInvestment?.map((item: any) => {
    //
    return investmentDropDown.push({
      name: item.productName,
      code: item.productID,
    });
  });

  const getAgreement = (
    productStatus: any,
    productCommitment: any,
    templateId: any
  ) => {
    setLoading(true);
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameter = {
        legalEntityID: fundDropdown.fundid,
        // supplierProductCode: formik.values.supplierProductCode,
        productId: productIdFilter,
        productStatus,
        productCommitment,
        templateId,
        // productId: productIdFilter,
      };

      dispatch(getThunkExecuteEsignAgreement(parameter))
        .then((response) => {
          console.log("response", response);
          if (response.payload.data === null) {
            if (response.payload.message !== "No data found") {
              setMessageToShow(response.payload.message);
              // setIsDocumnetAvail(false);
            } else {
              console.log("test------------");
              setMessageToShow("");
              // setIsDocumnetAvail(true);
            }
          } else {
            setMessageToShow("");
            // setIsDocumnetAvail(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      productID: 0,
      supplierProductCode: "",
      productStatus: 2,
      productCommitment: 3,
      templateId: "",
    },
    validate: (data) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: () => {
      getAgreement(
        formik.values.productStatus,
        formik.values.productCommitment,
        formik.values.templateId
      );
    },
  });

  console.log("sel", selectedCustomers);
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const formatCurrencyAnyData = (rowData: any) => {
    if (rowData.softcommitAmount === 0) {
      return "None";
    }
    const finalValue = Number(rowData.softcommitAmount);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const generateSASKey = (legalEntityId: any) => {
    setLoading(true);
    return dispatch(getSasKeyThunk(legalEntityId))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          return response.payload.data.sasKey;
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleviewDocument = async (rowData: any) => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        investorId: rowData.investorId,
        documentTempId: rowData.id,
        investingAccountId: rowData.investingAccountsId,
      };
      const key = await generateSASKey(fundDropdown.fundid.toString());
      setsasKey(key);
      setLoading(true);
      dispatch(getThunkExecuteEsignDocument(parameter))
        .then((response) => {
          setLoading(false);
          console.log(response);
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (response.meta.requestStatus === "fulfilled") {
            if (
              response.payload.data[0].documentLink !== "" &&
              response.payload.data[0].documentLink !== null
            ) {
              setVisibleViewUpload(true);
            } else {
              SetDialogboxState(true);
              setOpenDialog(true);
            }
          }
        })
        .catch((error: any) => {
          console.log("error", error);
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const bindAccountName = (rowData: any) => {
    return rowData?.investingAccountsId > 0 ? (
      <span
        className="left-align"
        title={rowData?.invName + " - " + rowData?.accountNickName}
      >
        {rowData?.invName + " - " + rowData?.accountNickName}
      </span>
    ) : (
      <span className="left-align" title={rowData?.invName}>
        {rowData?.invName}
      </span>
    );
  };

  const ViewDocumentButton = (rowData: any) => {
    // const viewUploadedFile = (key: any) => {
    //   setDocLink(rowData.documentLink + key);
    //   setVisibleViewUpload(true);
    // };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          {rowData.isSignatureCompleted === "Doc Signed" ? (
            <Button
              className="btn-dialog align-items-center justify-content-center signedbtn"
              onClick={() => handleviewDocument(rowData)}
            >
              <span className="text-white">View Signed Document</span>
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (
      productObjectByFilter?.productID > 0 &&
      productObjectByFilter?.productID !== undefined
    ) {
      setLoading(true);
      setDocumentLink("");
      setProductIdFilter(productObjectByFilter?.productID);
      setLoading(false);
    } else {
      setProductIdFilter(0);
      formik.resetForm();
      dispatch(setExecuteEsignAgreement([]));
      setDocumentLink("");
      dispatch(resetTemplateList());
    }
  }, [productObjectByFilter?.productID]);

  const dataTableElements = [
    {
      field: "invName",
      header: "Investor Account",
      body: bindAccountName,
      style: { minWidth: "230px" },
    },
    // {
    //   field: "userType",
    //   header: "User Type",
    // },
    {
      field: "softcommitAmount",
      header: "Soft Commit",
      body: formatCurrencyAnyData,
      style: { minWidth: "160px" },
    },
    {
      field: "isSignatureCompleted",
      header: "Status",
      style: { minWidth: "160px" },
    },
    {
      field: "name",
      header: "Document Type",
      style: { minWidth: "160px" },
    },
    {
      // field: "supplierProductCode",
      header: "View Document",
      body: ViewDocumentButton,
    },
  ];

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };
  const sendEmailFunction = () => {
    const list1: any = [];
    if (selectedCustomers) {
      for (const item of selectedCustomers) {
        const value = {
          investorId: item.investorId,
          investorName: item.invName,
          legalEntityId: fundDropdown.fundid,
          docTempId: item.id === null ? 0 : item.id,
          buttonType: resendEmail === true || resendDoc === true ? "R" : "",
          investingAccountsId: item.investingAccountsId,
        };
        list1.push(value);
      }
    }
    setLoading(true);
    dispatch(sendThunkExecuteEsignEmail(list1))
      .then((response) => {
        console.log(response);
        if (response.meta.requestStatus === "rejected") {
          setLoading(false);
          errorToastMessage(toast);
          setConfirmDialog(false);
        } else if (response.meta.requestStatus === "fulfilled") {
          setLoading(false);
          if (
            response.payload.success === false &&
            response.payload.data === null
          ) {
            errorToastMessage(toast, response.payload.message);
            setSelectedCustomers([]);
            setStatus("");
            setConfirmDialog(false);
          } else {
            successToastMessage(toast, "Email is send successfully");
            getAgreement(
              formik.values.productStatus,
              formik.values.productCommitment,
              formik.values.templateId
            );
            setSelectedCustomers([]);
            setStatus("");
            setConfirmDialog(false);
          }
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        errorToastMessage(toast);
      });
    console.log(list1);
  };

  const handleStatus = () => {
    console.log("ol");
    setOpenDialog(true);
  };

  const handleTheSelection = (data: any) => {
    if (data.length === 0) {
      setSelectedCustomers([]);
      setStatus("");
      return;
    }
    console.log("tetet", data);
    const selectedIsSignatureCompleted = data[0].isSignatureCompleted;
    let shouldSelect = true;

    for (const item of data) {
      if (item.isSignatureCompleted !== selectedIsSignatureCompleted) {
        shouldSelect = false;
        // setOpenDialog(true);
        handleStatus();
        break;
      }
    }

    if (shouldSelect) {
      setSelectedCustomers(data);
      setStatus(selectedIsSignatureCompleted);
    }
  };

  useEffect(() => {
    dispatch(setExecuteEsignAgreement({ data: [] }));
  }, []);

  useEffect(() => {
    setLoading(true);
    setMessageToShow("");
    formik.resetForm();
    setProductObjectByFilter({});
    setProductIdFilter(0);
    // setIsDocumnetAvail(true);
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      dispatch(resetTemplateList());
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        supplierId: 0,
        // productState: "PreFunded",
      };
      // console.log(
      //   "getThunkLookupCode called multiple time",
      //   fundDropdown.fundid
      // );
      // dispatch(getThunkLookupCode(parameter));

      dispatch(getThunkInvestmentByFilter(parameter));
      dispatch(getThunkExecuteEsignCommitmentDropDown());
      dispatch(getThunkExecuteEsignStatusDropDown()).finally(() => {
        setLoading(false);
      });
    }

    dispatch(setExecuteEsignAgreement([]));
  }, [fundDropdown.fundid]);

  useEffect(() => {
    switch (status) {
      case "Not Sent": {
        setNotSend(true);
        break;
      }
      case "Email Sent": {
        setResendEmail(true);
        break;
      }
      case "Doc Signed": {
        setResendDoc(true);
        break;
      }
      default: {
        setNotSend(false);
        setResendDoc(false);
        setResendEmail(false);
      }
    }
  }, [status, selectedCustomers]);

  console.log("q", loading);
  useEffect(() => {
    if (productIdFilter !== 0 && productIdFilter !== undefined) {
      setSelectedCustomers([]);
      setStatus("");
      getAgreement(
        formik.values.productStatus,
        formik.values.productCommitment,
        formik.values.templateId
      );
    }
  }, [
    productIdFilter,
    formik.values.productCommitment,
    formik.values.productStatus,
    formik.values.templateId,
  ]);

  useEffect(() => {
    if (productIdFilter !== 0) {
      setTemplateLink("");
      formik.setFieldValue("templateId", "");
      dispatch(getThunkTemplateByProduct(productIdFilter));
    }
  }, [productIdFilter]);

  useEffect(() => {
    if (getTemplatebyProduct?.length === 1) {
      formik.setFieldValue("templateId", getTemplatebyProduct[0].key);
      setDocumentLink(getTemplatebyProduct[0].documentLink);
    }
  }, [getTemplatebyProduct]);

  const handleView = async () => {
    setLoading(true);

    setTemplateLink("");
    const key = await generateSASKey(fundDropdown.fundid.toString());
    // const documentLink = getTemplatebyProduct?.[0].documentLink;
    if (documentLink && documentLink.includes("blob.core.windows.net")) {
      console.log("documentLink handleView", documentLink);
      setTemplateLink(documentLink + key);
      setOpenViewTemplateDialog(true);
    } else {
      errorToastMessage(toast, "Template not found");
      setLoading(false);
    }
  };
  const handleCancel = () => {
    setOpenViewTemplateDialog(false);
  };
  return (
    <>
      <Dialog
        visible={openDialog}
        className="w-100 md:w-3"
        onHide={() => setOpenDialog(false)}
        headerClassName="no-header"
        contentClassName="top-lr-content"
      >
        {dailogboxState ? (
          <p className="pageHeader text-center mb-5 pt-5">
            Document has not been signed by any investor.
          </p>
        ) : (
          <p className="pageHeader text-center mb-5 pt-5">
            When selecting multiple investors, please select investors with the
            same status.
          </p>
        )}

        <div className="flex flex-row flex-wrap sm:flex-none  col-12 justify-content-center">
          <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                // handleContinue();
                setOpenDialog(false);
                SetDialogboxState(false);
              }}
              className="blueButtonShadow d-block "
            >
              <span className="ml-auto mr-auto">Ok</span>
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        header=""
        maximizable
        visible={visibleViewUpload}
        style={{ width: "80vw" }}
        onHide={() => {
          setVisibleViewUpload(false);
        }}
        closable={true}
      >
        <div className="m-0">
          <iframe
            src={viewDocumentData?.documentLink + sasKey}
            // src={}
            style={{ width: "100%", height: "80vh" }}
          ></iframe>
        </div>
      </Dialog>
      <Dialog
        visible={confirmDialog}
        className="w-100 md:w-3"
        onHide={() => setConfirmDialog(false)}
        headerClassName="no-header"
        contentClassName="top-lr-content"
        closable={false}
      >
        {loading === true ? <Loader /> : <></>}
        <p className="pageHeader text-center mb-5 pt-5">
          You are about to email documents for signature to selected investors?
          Please confirm.
        </p>

        <div className="flex flex-row flex-wrap sm:flex-none  col-12">
          <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                sendEmailFunction();
              }}
              className="blueButtonShadow d-block "
            >
              <span className="ml-auto mr-auto">Yes</span>
            </Button>
          </div>
          <div className="d-flex flex-column p-2 col-12 sm:col-6 ">
            <Button
              onClick={(e) => {
                e.preventDefault();
                // handleContinue();
                setConfirmDialog(false);
              }}
              className="blueButtonShadow d-block "
            >
              <span className="ml-auto mr-auto">No</span>
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={openViewTemplateDialog}
        onHide={() => handleCancel()}
        headerClassName="no-header"
        contentClassName="top-lr-content"
        className="dialogImageFix"
      >
        <iframe
          src={templateLink}
          allowFullScreen
          style={{ width: "100%", height: "80vh" }}
        ></iframe>
      </Dialog>
      {loading ? <Loader /> : <></>}
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Send Deal Disclosures</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>

        <div className="grid col-12">
          <div className="col-12">
            <div className="card tabWithoutboxshadow">
              <Card>
                <>
                  {" "}
                  <div className="mb-2">
                    <label className="inputLabel inputLabelColor ml-2">
                      Select Deals
                    </label>
                    <div
                      className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2  formBody"
                      style={{ height: "auto" }}
                    >
                      <div className="col-12 p-0 ml-5 pl-2 pr-3 md:pr-0">
                        <InvestmentDropdownFilter
                          listPage={false}
                          setProductObjectByFilter={setProductObjectByFilter}
                          productObjectByFilter={productObjectByFilter}
                          productState="all"
                        />
                      </div>
                      <div className="formgrid pt-3 grid col-12 px-2 ml-5">
                        <div className="d-block sm:d-flex sm:flex-column pl-0  p-2 col-12 sm:col-9 ">
                          <label className="inputLabel">Deal Disclosure</label>
                          <Dropdown
                            inputId="templateId"
                            name="templateId"
                            value={formik.values.templateId}
                            options={
                              getTemplatebyProduct === null
                                ? []
                                : getTemplatebyProduct
                            }
                            showOnFocus
                            optionLabel="value"
                            optionValue="key"
                            placeholder="Deal Disclosure"
                            // className="card-inputBox"
                            onChange={(e) => {
                              console.log("Deal Disclosure", e);

                              const documentLinkObj: any =
                                getTemplatebyProduct!.find(
                                  (item) => item.key === e.value
                                );
                              if (documentLinkObj) {
                                console.log(
                                  "Deal Disclosure documentLink",
                                  documentLinkObj.documentLink
                                );
                                setDocumentLink(documentLinkObj.documentLink);
                              }
                              handleChange(e);
                              // formik.setFieldValue("productName", e.target.value);
                            }}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("templateId"),
                              "card-inputBox": true,
                            })}
                          />
                          {getFormErrorMessage("templateId")}
                        </div>
                        <div className="d-block sm:d-flex sm:flex-column p-2  col-12 sm:col-3">
                          <label className="inputLabel">&nbsp;</label>
                          <div className="flex align-items-center justify-content-center">
                            <Button
                              className="btn-dialog "
                              onClick={() => handleView()}
                              // disabled={formik.values.templateId === ""}
                              // disabled={
                              //   getTemplatebyProduct &&
                              //   getTemplatebyProduct.length > 0 &&
                              //   getTemplatebyProduct[0]?.documentLink
                              //     ? false
                              //     : true
                              // }
                              // disabled={
                              //   !getTemplatebyProduct ||
                              //   getTemplatebyProduct.length === 0 ||
                              //   !getTemplatebyProduct[0]?.documentLink
                              // }

                              disabled={
                                !documentLink ||
                                !documentLink.includes("blob.core.windows.net")
                              }
                            >
                              <span className="text-white">
                                View Deal Disclosure Template
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="formgrid grid col-12 px-2 ml-5">
                        <div className="d-block sm:d-flex sm:flex-column pl-0  p-2 col-12 sm:col-6 ">
                          <label className="inputLabel">Status *</label>
                          <Dropdown
                            inputId="productStatus"
                            name="productStatus"
                            value={formik.values.productStatus}
                            options={
                              statusDropDown === null ? [] : statusDropDown
                            }
                            showOnFocus
                            optionLabel="value"
                            optionValue="key"
                            placeholder="Select Status"
                            // className="card-inputBox"
                            onChange={(e) => {
                              handleChange(e);
                              // formik.setFieldValue("productName", e.target.value);
                            }}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("productStatus"),
                              "card-inputBox": true,
                            })}
                          />
                          {getFormErrorMessage("productStatus")}
                        </div>
                        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                          <label className="inputLabel">Soft Commit *</label>

                          <Dropdown
                            inputId="productCommitment"
                            name="productCommitment"
                            value={
                              // formik.values.productCommitment === 0
                              //   ? ""
                              //   :
                              formik.values.productCommitment
                            }
                            options={
                              commitmentDropDown === null
                                ? []
                                : commitmentDropDown
                            }
                            showOnFocus
                            optionLabel="value"
                            optionValue="key"
                            placeholder="Select Commitment"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            className={classNames({
                              "p-invalid":
                                isFormFieldInvalid("productCommitment"),
                              "card-inputBox": true,
                            })}
                          />
                          {getFormErrorMessage("productCommitment")}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-center ">
                    <Button
                      className="blueButtonShadow w-10rem"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        setSelectedCustomers([]);
                      }}
                      disabled={productIdFilter === ""}
                    >
                      Get Agreements
                    </Button>
                  </div> */}
                </>
                <div className="mb-2">
                  <label className="inputLabel inputLabelColor ml-2">
                    View Agreements
                  </label>
                  <div
                    className="formgrid flex sm:flex-row flex-wrap col-12 grid px-2 formBody"
                    style={{ height: "auto" }}
                  >
                    {messageToShow === "" ? (
                      <>
                        <div className="inputLabel textMessage col-12 ml-5 p-2">
                          Note : Investors need to be fully approved and
                          enrolled in the fund before deal disclosures can be
                          sent to them for review or signatures. Only fully
                          approved investors will appear in the list below.
                        </div>

                        <div className="xl:flex flex-wrap sm:flex-none p-0 col-12 col-6 ml-5 mt-1">
                          <div className="d-flex flex-column p-2 ">
                            {/* <label className="inputLabel">&nbsp;</label> */}

                            <Button
                              className="btn-dialog"
                              type="submit"
                              disabled={!notSend}
                              onClick={(e) => {
                                e.preventDefault();
                                // handleStateChange();
                                setConfirmDialog(true);
                              }}
                            >
                              <span className="ml-auto mr-auto ">
                                Send Disclosure
                              </span>
                            </Button>
                          </div>
                          <div className="d-flex flex-column p-2  ">
                            {/* <label className="inputLabel">&nbsp;</label> */}

                            <Button
                              className="btn-dialog"
                              disabled={!resendDoc}
                              onClick={(e) => {
                                e.preventDefault();
                                setConfirmDialog(true);
                              }}
                            >
                              <span className="ml-auto mr-auto ">
                                Send for Re-sign
                              </span>
                            </Button>
                          </div>

                          <div className="d-flex flex-column p-2 ">
                            {/* <label className="inputLabel">&nbsp;</label> */}

                            <Button
                              className="btn-dialog"
                              disabled={!resendEmail}
                              onClick={(e) => {
                                e.preventDefault();
                                setConfirmDialog(true);
                              }}
                            >
                              <span className="ml-auto mr-auto ">
                                Send Reminder
                              </span>
                            </Button>
                          </div>
                          <div className="d-flex flex-column p-2 ml-auto">
                            <Button
                              className="btn-navActive"
                              type="button"
                              icon="pi pi-window-maximize"
                              disabled={
                                executeAgreements === undefined ||
                                executeAgreements?.length === 0 ||
                                executeAgreements === null
                              }
                              onClick={() => setOpenDailog(true)}
                              title="View Full Screen"
                            />
                          </div>
                        </div>

                        <div className="mt-2 col-12 ml-5">
                          <DataTableComponent
                            valueData={executeAgreements}
                            fieldsElements={dataTableElements}
                            isPaginationAllowed={true}
                            isSelectionAllowed={true}
                            selectedCustomers={selectedCustomers}
                            setSelectedCustomers={setSelectedCustomers}
                            handleTheSelection={handleTheSelection}
                            scrollable={true}
                            openDailog={openDailog}
                            setOpenDailog={setOpenDailog}
                            // maxHeight={"calc(-133px + 100vh)"}
                            className="gridcell deletebtn viewSignDocBtn"
                          />
                        </div>
                      </>
                    ) : (
                      <div className="inputLabel textMessage col-12 ml-5 p-2">
                        {messageToShow}
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
