import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getAllPortfolioTransactionService } from "../../services/portfolio/portfolio";
import {
  IAccountValue,
  IAsset,
  IDiversificationElement,
  IExitedDeals,
  IInvestment,
  ILiquidity,
  ITransaction,
  ITransactionPage,
} from "../../types";
// import { RootState } from "../../store";
// import { IPortfolio } from "../../types/index";
// import apiFactory from "../../utils/api";
import error from "../../utils/error";
interface IPortfolioState {
  data?: {}[] | null;
  accountValue?: IAccountValue[] | null;
  assets?: IAsset[] | [];
  liquidities?: ILiquidity[] | null;
  exitedDeals?: IExitedDeals | null;
  investment?: IInvestment[] | [];
  transaction?: ITransaction[] | [];
  transactionPage?: ITransactionPage | null;
  mapDiversification?: IDiversificationElement[] | null;

  loading: string;
  error?: string | null;
}

interface IObject {
  activeTab: string;
  activePage: number;
  investorID: number;
  partnerID: number;
  legalEntityID: number;
}
const initialState: IPortfolioState = {
  data: undefined,
  accountValue: undefined,
  loading: "loading",
  error: undefined,
};

// change the endpoint

export const getThunkPortfolioTransaction = createAsyncThunk(
  "Portfolio/getTransactions",
  async (IObject: IObject) => {
    try {
      const response = await getAllPortfolioTransactionService(IObject);
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "Portfolio/transaction",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Transaction

    builder.addCase(getThunkPortfolioTransaction.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(getThunkPortfolioTransaction.fulfilled, (state, action) => {
      state.loading = "succeeded";

      if (action.payload.fetchTransactions.length > 0) {
        state.transaction = action.payload.fetchTransactions;
        state.transactionPage = action.payload.pageDetails;
      } else {
        state.transaction = [];
        state.transactionPage = undefined;
      }

      //
      //
      //
    });
    builder.addCase(getThunkPortfolioTransaction.rejected, (state, action) => {
      state.loading = "failed";

      // action.payload contains error information
      state.error = error(action.payload);
    });
  },
});

export const getAllPortfoliosData = (state: RootState) => {
  return state.portfolio.data;
};
// export const getAllOpportunitiesStatus = (state: RootState) =>
//   state.opportunity.loading;
// export const getAllOpportunitiesError = (state: RootState) =>
//   state.opportunity.error;
export default slice.reducer;
