import "./Opportunities.scss";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import ErrorUi from "../../../../components/ErrorUi";
// import DashboardDropdownComponent from "../../../../components/dashboard-dropdown/DashboardDropdownComponent";

import RegisterDropdownComponent from "../../../../components/dashboard-dropdown/RegisterDropdown";
import Loader from "../../../../components/loader/Loader";
import OpportunityCard from "../../../../components/opportunities/OpportunityCard";
// import OpportunitySkeleton from "../../../../components/opportunities/OpportunitySkeleton";
// import { getThunkPortfolioNonRegisterInvestorListDropdown } from "../../../../store/features/dropdown/nonregisteredinvestorlist/slice";
// import { getThunkPortfolioRegisterInvestorListDropdown } from "../../../../store/features/dropdown/registeredinvestorlist/slice";
// import { getPortfolioGetLegalEntityList } from "../../../../store/features/dropdown/slice";
import { getfilledDealsForFundService } from "../../../../services/opportunity/opportunity";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  getAllOpportunitiesData,
  getOpportunitiesThunk,
} from "../../../../store/opportunity/slice";
import { errorToastMessage } from "../../../../utils/toast-message";

// import Opportunities from "./Oppotunities";

// ProductService
const AllOpportunities = () => {
  const dispatch = useAppDispatch();

  // const error = useAppSelector(getAllOpportunitiesError);
  const [page, setpage] = useState(1);
  const [loading, setLoading] = useState(false);

  const Opprtunities = useAppSelector(getAllOpportunitiesData);
  // const status = useAppSelector(getAllOpportunitiesStatus);
  const status = useAppSelector((state) => state.legalEntityList.loading);
  const totalPages = useAppSelector(
    (state) => state.opportunity.pageDetails?.totalPages
  );
  const [showisfilled, setshowisfilled] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState("false");
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  const toast = useRef<Toast>(null);

  console.log("Legal entity id" + "  " + profileData?.legalEntityID);
  console.log("selectedDeal", selectedDeal);

  useEffect(() => {
    setLoading(true);
    if (selectedDeal === "Available") {
      console.log("Allfilled" + selectedDeal);
      const paramerts = {
        lpPartnerID: dropdown.registerid.toString(),
        category: "Available",
        deals: "Available",
        availablePageNo: 1,
        pageSize: 6,
        legalEntityId: dropdown.fundid,
      };
      const updateBoday = {
        ...paramerts,
        availablePageNo: page,
      };
      console.log("getOpportunities", updateBoday);
      dispatch(getOpportunitiesThunk(updateBoday)).finally(() => {
        setLoading(false);
      });
    } else if (selectedDeal === "Allocated") {
      console.log("Allfilled" + selectedDeal);
      const paramerts = {
        lpPartnerID: dropdown.registerid.toString(),
        category: "Available",
        deals: "Allocated",
        availablePageNo: 1,
        pageSize: 6,
        legalEntityId: dropdown.fundid,
      };
      const updateBoday = {
        ...paramerts,
        availablePageNo: page,
      };
      console.log("getOpportunities", updateBoday);
      dispatch(getOpportunitiesThunk(updateBoday)).finally(() => {
        setLoading(false);
      });
    }
  }, [selectedDeal]);

  useEffect(() => {
    if (dropdown.fundid) {
      getfilledDealsForFundService(dropdown.fundid)
        .then((response) => {
          setshowisfilled(response.data.data.showFilledDeals);
          setpage(1);
        })
        .catch(() => {
          errorToastMessage(toast);
        });
    }
  }, [dropdown.fundid]);

  const reinitializePage = () => {
    setpage(1);
  };
  const handleDealChange1 = (e: { value: any }) => {
    setSelectedDeal(e.value);
    reinitializePage();
  };
  // Define the options for the dropdown

  // Handle the dropdown selection change

  //
  //

  // const body = {
  //   lpPartnerID: "4",
  //   category: "ALL",
  //   availablePageNo: 1,
  //   pageSize: 6,
  //   legalEntityId: 3,
  // };
  // const funddropdownModel = useAppSelector(
  //   (state) => state.legalEntityList.data
  // );
  // const registerinvestorModel = useAppSelector(
  //   (state) => state.registerinvestor.data
  // );
  // const nonregisterinvestorModel = useAppSelector(
  //   (state) => state.nonregisterinvestor.data
  // );

  // const legalEntityList: { code: number; name: string }[] = [];
  // const [leagalentity, setLeagalentity] = useState<any>();
  // const [registredinverstment, setregistredinverstment] = useState<any>();
  // const [nonregistredinverstment, setnonregistredinverstment] = useState<any>();
  // for (let i = 0; i < funddropdownModel?.length; i++) {
  //   legalEntityList.push({
  //     code: funddropdownModel![i].legalEntityID,
  //     name: funddropdownModel![i].legalEntityName,
  //   });
  // }

  // const registerinverstmenList: { code: number; name: string }[] = [];
  // for (let i = 0; i < registerinvestorModel?.length; i++) {
  //   registerinverstmenList.push({
  //     code: registerinvestorModel![i].investorID,
  //     name: registerinvestorModel![i].investorName,
  //   });
  // }
  // const nonregisterinverstmenList: { code: number; name: string }[] = [];
  // for (let i = 0; i < nonregisterinvestorModel?.length; i++) {
  //   nonregisterinverstmenList.push({
  //     code: nonregisterinvestorModel![i].investorID,
  //     name: nonregisterinvestorModel![i].investorName,
  //   });
  // }

  // useEffect(() => {
  //   const InvestorListparameters = {
  //     LegalEntityId: leagalentity,
  //   };
  //   if (leagalentity !== undefined) {
  //     dispatch(
  //       getThunkPortfolioRegisterInvestorListDropdown(InvestorListparameters)
  //     );
  //   }
  // }, [leagalentity]);

  // useEffect(() => {
  //   const InvestorListparameters = {
  //     LegalEntityId: leagalentity,
  //   };
  //   if (leagalentity !== undefined) {
  //     dispatch(
  //       getThunkPortfolioNonRegisterInvestorListDropdown(InvestorListparameters)
  //     );
  //   }
  // }, [leagalentity]);

  // const LegalEntityparameters = {
  //   Email: "softlabsavestor%40gmail.com",
  //   RoleId: 1,
  // };

  // useEffect(() => {
  //   dispatch(getPortfolioGetLegalEntityList(LegalEntityparameters));
  // }, []);

  const getNextOpportunities = (e: any) => {
    e.preventDefault();
    setpage((prevState) => prevState + 1);
  };
  console.log("next is next " + page);
  const getPrevOpportunities = (e: any) => {
    e.preventDefault();
    setpage((prevState) => prevState - 1);
  };

  let content;
  switch (status) {
    case "loading":
      content = <Loader />;
      break;
    case "succeeded":
      content = Opprtunities!.map((product: any) => {
        // return <OpportunityCard p={product} key={product.productID} />;
        return (
          <OpportunityCard
            p={product}
            key={product.productID}
            selectedValue={selectedDeal}
          />
        );
      });

      break;
    case "failed":
      content = <ErrorUi />;
      break;
  }

  if (selectedDeal === "Available") {
    useEffect(() => {
      setLoading(true);
      if (
        dropdown.registerid !== undefined &&
        dropdown.fundid !== undefined
        // &&
        // dropdown?.registerid! > 0
      ) {
        const paramerts = {
          lpPartnerID: dropdown.registerid.toString(),
          category: "Available",
          deals: "Available",
          availablePageNo: 1,
          pageSize: 6,
          legalEntityId: dropdown.fundid,
        };

        const updateBoday = {
          ...paramerts,
          availablePageNo: page,
        };
        console.log("getOpportunities", updateBoday);
        dispatch(getOpportunitiesThunk(updateBoday)).finally(() => {
          setLoading(false);
        });
      }
      return () => {
        //
      };
    }, [dispatch, page, dropdown]);
  } else if (selectedDeal === "Allocated") {
    useEffect(() => {
      setLoading(true);
      if (
        dropdown.registerid !== undefined &&
        dropdown.fundid !== undefined
        // &&
        // dropdown?.registerid! > 0
      ) {
        const paramerts = {
          lpPartnerID: dropdown.registerid.toString(),
          category: "Available",
          deals: "Allocated",
          availablePageNo: 1,
          pageSize: 6,
          legalEntityId: dropdown.fundid,
        };

        const updateBoday = {
          ...paramerts,
          availablePageNo: page,
        };
        console.log("getOpportunities", updateBoday);
        dispatch(getOpportunitiesThunk(updateBoday)).finally(() => {
          setLoading(false);
        });
      }
      return () => {
        //
      };
    }, [dispatch, page, dropdown]);
  } else {
    useEffect(() => {
      setLoading(true);
      if (
        dropdown.registerid !== undefined &&
        dropdown.fundid !== undefined &&
        dropdown?.registerid! > 0
      ) {
        const paramerts = {
          lpPartnerID: dropdown.registerid.toString(),
          category: "ALL",
          deals: "Alldeal",
          availablePageNo: 1,
          pageSize: 6,
          legalEntityId: dropdown.fundid,
        };

        const updateBoday = {
          ...paramerts,
          availablePageNo: page,
        };
        console.log("getOpportunities", updateBoday);
        dispatch(getOpportunitiesThunk(updateBoday)).finally(() => {
          setLoading(false);
        });
      }
      return () => {
        //
      };
    }, [page, dropdown.registerid, dropdown.fundid]);

    useEffect(() => {
      setpage(1);
    }, [dropdown.registerid]);
  }

  if (selectedDeal === "Available") {
    useEffect(() => {
      setLoading(true);
      if (
        dropdown.nonregisterid !== undefined &&
        dropdown.fundid !== undefined &&
        dropdown?.nonregisterid! > 0
      ) {
        const paramerts = {
          lpPartnerID: dropdown.nonregisterid.toString(),
          category: "Available",
          deals: "Available",
          availablePageNo: 1,
          pageSize: 6,
          legalEntityId: dropdown.fundid,
        };

        const updateBoday = {
          ...paramerts,
          availablePageNo: page,
        };
        console.log("getOpportunities", updateBoday);
        dispatch(getOpportunitiesThunk(updateBoday)).finally(() => {
          setLoading(false);
        });
      }
      return () => {
        //
      };
    }, [dispatch, page, dropdown]);
  } else if (selectedDeal === "Allocated") {
    useEffect(() => {
      setLoading(true);
      if (
        dropdown.nonregisterid !== undefined &&
        dropdown.fundid !== undefined &&
        dropdown?.nonregisterid! > 0
      ) {
        const paramerts = {
          lpPartnerID: dropdown.nonregisterid.toString(),
          category: "Available",
          deals: "Allocated",
          availablePageNo: 1,
          pageSize: 6,
          legalEntityId: dropdown.fundid,
        };

        const updateBoday = {
          ...paramerts,
          availablePageNo: page,
        };
        console.log("getOpportunities", updateBoday);
        dispatch(getOpportunitiesThunk(updateBoday)).finally(() => {
          setLoading(false);
        });
      }
      return () => {
        //
      };
    }, [dispatch, page, dropdown]);
  } else {
    useEffect(() => {
      setLoading(true);

      if (
        dropdown.nonregisterid !== undefined &&
        dropdown.fundid !== undefined &&
        dropdown?.nonregisterid! > 0
      ) {
        const paramerts = {
          lpPartnerID: dropdown.nonregisterid.toString(),
          category: "ALL",
          deals: "Alldeal",
          availablePageNo: 1,
          pageSize: 6,
          legalEntityId: dropdown.fundid,
        };

        const updateBoday = {
          ...paramerts,
          availablePageNo: page,
        };
        console.log("getOpportunities", updateBoday);
        dispatch(getOpportunitiesThunk(updateBoday)).finally(() => {
          setLoading(false);
        });
      }
      return () => {
        //
      };
    }, [dispatch, page, dropdown.nonregisterid]);
  }
  const deals = [
    { name: "Available", code: "Available" },
    { name: "Fully Allocated", code: "Allocated" },
    // Add more options as needed
  ];
  // useEffect(() => {
  //   Opprtunities = [];
  //   totalPages = 0;

  //   console.log("method called", Opprtunities);
  // }, [dispatch, nonregistredinverstment]);

  useEffect(() => {
    content = <Loader />;
    console.log("method called content 2");
  }, [Opprtunities]);

  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader"> Opportunities</h1>
            <span className="subheader">
              View and commit to all current opportunities
            </span>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <RegisterDropdownComponent />
        </div>
        {/* <div className="col-12 md:col-6 mt-2">
          {" "}
          <Opportunities />
        </div> */}
        <div className="col-12 md:col-12 mt-2">
          {showisfilled ? (
            <div className="d-block " style={{ marginLeft: "53%" }}>
              <label className="inputLabel" style={{ paddingLeft: "50%" }}>
                Filled Deals
              </label>

              <Dropdown
                value={selectedDeal}
                options={deals}
                onChange={handleDealChange1}
                optionLabel="name"
                optionValue="code"
                placeholder="Select Deal"
                filter
                style={{ width: "50%", marginLeft: "49%" }}
              />
            </div>
          ) : undefined}
        </div>
      </div>
      <div className="grid">{content}</div>
      {/* <div className="grid ">
        {status === "succeeded" && totalPages! > 0 && (
          <div className="col-md-12 mt-3 p-0 pagination justify-content-center">
            <Button
              onClick={getPrevOpportunities}
              disabled={page === 1}
              className="BtnBlue"
            >
              Prev Page
            </Button>

            <li className="page-item active">
              <span className="BtnBlue rounded ">{page}</span>
            </li>

            <Button
              disabled={page === totalPages}
              onClick={getNextOpportunities}
              className="BtnBlue float-right"
            >
              Next Page
            </Button>
          </div>
        )}
        {status === "succeeded" && Opprtunities!.length <= 0 && (
          <h3>No data found</h3>
        )}
      </div> */}
      {status === "succeeded" && totalPages! > 0 && (
        <div className="row pt-4">
          <div className="col-sm-4"></div>

          <div className="col-sm-4 d-flex justify-content-center">
            <div className=" mt-3 p-0 pagination ">
              <Button
                onClick={getPrevOpportunities}
                disabled={page === 1}
                className="BtnBlue"
              >
                Prev Page
              </Button>
              <li className="page-item active">
                <span className="BtnBlue rounded ">{page}</span>
              </li>
              <Button
                disabled={page === totalPages}
                onClick={getNextOpportunities}
                className="BtnBlue float-right"
              >
                Next Page
              </Button>
            </div>
          </div>

          <div className="col-sm-4 mt-3 text-end">
            <li className="page-item active BtnBlue rounded">
              {totalPages! > 0 ? (
                <div>
                  {" "}
                  <span>{page}</span>
                  <span className="font-bold ml-1">of </span>
                  <span>{totalPages!}</span>
                </div>
              ) : (
                <></>
              )}{" "}
            </li>
          </div>
        </div>
      )}
      {status === "succeeded" && totalPages! === 0 && (
        <div className="row pt-4">
          <div className="col-sm-4"></div>

          <div className="col-sm-4 d-flex justify-content-center">
            <div className=" mt-3 p-0 pagination ">
              <h3>No Data Found</h3>
            </div>
          </div>
        </div>
      )}
      <Toast ref={toast} className="themeToast" />

      {status === "failed" && totalPages! === 0 && <ErrorUi />}
    </>
  );
};
export default AllOpportunities;
