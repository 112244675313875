import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
// import { Chip } from "primereact/chip";
// import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
// import { Tag } from "primereact/tag";
// import { InputText } from "primereact/inputtext";
// import { Tree } from "primereact/tree";
import React, { useEffect, useState } from "react";
// import { RiSearchLine } from "react-icons/ri";

import DataTableComponent from "../../../../components/data-table/DataTableComponent";
//

import Loader from "../../../../components/loader/Loader";
import { GetDwollaReportsThunk } from "../../../../store/dwolla/slice";
import { DwollaWebhooksInfoThunk } from "../../../../store/dwolla/webhooks/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  // dateFormatter,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import "./reportscss.scss";

export default function DwollaInvestorReports() {
  const dispatch = useAppDispatch();
  const portState = useAppSelector(
    (state) => state.dwollasetupFundentity.loading
  );
  const fundlist = useAppSelector((state) => state.dwollasetupFundentity.data);
  const webhooklist = useAppSelector((state) => state.dwollaWebhook.data);

  const [helpvisible, setHelpVisible] = useState(false);
  const [exitDate, setExitDate] = useState<any>();
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  const [optionvalue, setoptionvalue] = useState<any>("I");

  const getwebhooksdata = (rowData: any) => {
    const parameters = {
      Id: rowData.customerId,
    };
    dispatch(DwollaWebhooksInfoThunk(parameters));
  };
  const DropdownList: any = [
    { name: "Funds", code: "F" },
    { name: "Investors", code: "I" },
  ];
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log(name);
    setoptionvalue(value);
  };

  const actionBodyEditTemplate = (rowData: any) => {
    return rowData?.custStatus === "Yes" ? (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className=" p-button-rounded p-button-text iconButton"
          onClick={() => {
            setHelpVisible(true);
            getwebhooksdata(rowData);
          }}
          type="button"
        />
      </React.Fragment>
    ) : (
      <></>
    );
  };
  // const [selectedKey, setSelectedKey] = useState();

  const actionjsonTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <pre>{JSON.parse(JSON.stringify(rowData?.webResponse))}</pre>
      </React.Fragment>
    );
    // return rowData?.webResponse !== "" ? (
    //   <React.Fragment>
    //     <div className="card flex justify-content-center">
    //       <Tree
    //         value={JSON.parse(JSON.parse(JSON.stringify(rowData?.webResponse)))}
    //         selectionMode="single"
    //         // selectionKeys={selectedKey}
    //         // onSelectionChange={(e) => setSelectedKey(e.value)}
    //         className="w-full md:w-30rem"
    //       />
    //     </div>
    //   </React.Fragment>
    // ) : (
    //   <></>
    // );
  };

  // const getExitDateConversion = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedExitDate = {
  //       ...d,
  //       createdDate: new Date(d.createdDate),
  //     };
  //     return modifiedExitDate;
  //   });
  // };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const formatDate = (rowData: any) => {
    // return dateFormatter(rowData.createdDate);
    return formatDateMMDDYYY(rowData);
  };
  useEffect(() => {
    setExitDate(getListDateConversion(webhooklist, ["createdDate"]));
  }, [webhooklist]);

  const dataTablehelpElements = [
    {
      field: "topic",
      header: "Topic",
    },
    {
      field: "transactionId",
      header: "TransactionId",
    },
    {
      field: "transactionStatus",
      header: "Transaction Status",
    },
    {
      field: "webResponse",
      header: "Response",
      body: actionjsonTemplate,
    },

    {
      field: "createdDate",
      header: "Created Date",
      body: formatDate,
      filterElement: dateFilterTemplate,
    },
  ];

  //   const Helpfunddata = [
  //     {
  //       EventTopicName: "Customer created",
  //       Description: "A Customer was created",
  //     },
  //     {
  //       EventTopicName: "Customer KBA verification needed",
  //       Description:
  //         "The retry identity verification attempt failed due insufficient scores on the submitted data. The end user will have a single kba attempt to answer a set of “out of wallet” questions about themselves for identity verification.",
  //     },
  //     {
  //       EventTopicName: "Customer KBA verification failed",
  //       Description:
  //         "The end user failed KBA verification and was unable to correctly answer at least three KBA questions.",
  //     },
  //     {
  //       EventTopicName: "Customer KBA verification passed",
  //       Description:
  //         "The end user was able to correctly answer at least three KBA questions.",
  //     },
  //     {
  //       EventTopicName: "Customer reverification needed",
  //       Description:
  //         "Incomplete information was received for a Customer; updated information is needed to verify the Customer..",
  //     },
  //     {
  //       EventTopicName: "Customer verification document needed",
  //       Description: "Additional documentation is needed to verify a Customer.",
  //     },
  //     {
  //       EventTopicName: "Customer verification document uploaded",
  //       Description: "A verification document was uploaded for a Customer.",
  //     },
  //     {
  //       EventTopicName: "Customer verification document failed",
  //       Description: "A verification document was rejected for a Customer.",
  //     },
  //     {
  //       EventTopicName: "Customer verification document approved",
  //       Description: "A verification document was approved for a Customer.",
  //     },
  //     {
  //       EventTopicName: "Customer verified",
  //       Description: "A Customer was verified.",
  //     },
  //   ];
  const dataTableElements = [
    {
      field: "customerId",
      header: "ID",
    },

    {
      field: "customerName",
      header: "Customer Name",
    },
    {
      field: "dwollaPartnerId",
      header: "Dwolla Id",
    },
    {
      header: "Dwolla Customer",
      field: "custStatus",
    },
    {
      header: "Beneficial Owner",
      field: "beneficialOwnerStatus",
    },
    {
      header: "Bank Account",
      field: "bankStatus",
    },
    {
      header: "Account Type",
      field: "avCustomerType",
    },
    {
      header: "View Log",
      body: actionBodyEditTemplate,
    },
  ];

  useEffect(() => {
    if (dropdown.fundid !== undefined) {
      const parameters = {
        LegalEntityId: dropdown.fundid,
        Input: optionvalue,
      };
      dispatch(GetDwollaReportsThunk(parameters));
    }
  }, [dropdown.fundid, optionvalue]);

  // const rowDta = 10;

  let content;
  switch (portState) {
    case "loading":
      content = <Loader />;
      break;
    case "succeeded":
      content = (
        <>
          <Dialog
            header="Webhooks Info"
            visible={helpvisible}
            style={{ width: "80vw" }}
            onHide={() => setHelpVisible(false)}
          >
            <DataTableComponent
              valueData={exitDate!}
              fieldsElements={dataTablehelpElements}
              isPaginationAllowed={true}
            />
          </Dialog>
          ;
          <div className="grid">
            <div className="col-12 md:col-6 mt-2">
              <div className="card pgHeaderWrap md:my-2 mt-3">
                <h1 className="pageHeader">ACH Setup Report</h1>
                <p className="pageSubHeader"> </p>
              </div>
            </div>
            <div className="col-12 md:col-6 mt-2">
              <div>{/* <FundBannerImage /> */}</div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12">
              <div className="card my-3 tabWithoutboxshadow">
                <Card>
                  <h4 className="cardHeader">ACH Setup Report</h4>
                  <div className="row my-4 d-flex justify-content-between">
                    {" "}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                    <label className="card-subheader">Select Report Type</label>
                    <div>
                      <Dropdown
                        name="ID"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={optionvalue}
                        options={DropdownList}
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Select Options"
                      />
                    </div>
                  </div>
                  <DataTableComponent
                    valueData={fundlist!}
                    fieldsElements={dataTableElements}
                    isDownload={true}
                    isPaginationAllowed={true}
                  />
                </Card>
              </div>
            </div>
          </div>
        </>
      );
      break;
  }
  return <>{content}</>;
}
