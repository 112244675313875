import { useFormik } from "formik";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setForm } from "../../../../../store/features/investment/create/slice";
// import { setFormData } from "../../../../../store/features/investment/new-investment";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
type CreateInvestmentStep1Props = {
  handlePrevious: () => void;
  handleNext: () => void;
  toast: any;
};
const CreateInvestmentStep1: React.FC<CreateInvestmentStep1Props> = ({
  handlePrevious,
  handleNext,
}) => {
  const stateInvetsment = useAppSelector((state) => state.addInvestment.data);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      isPooledFund: false,
    },
    validate: () => {
      const errors: any = {};
      return errors;
    },
    onSubmit: (values) => {
      dispatch(
        setForm({
          ...stateInvetsment,
          isPooledFund: values.isPooledFund,
          legalEntityID: fundDropdown.fundid,
          loginId: profileData.userID,
        })
      );
      handleNext();
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const { values, setFieldValue, handleSubmit } = formik;
  console.log("values", values, "formikErrors", formikErrors);

  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    setFieldValue("isPooledFund", stateInvetsment?.isPooledFund);
  }, [stateInvetsment]);

  return (
    <>
      <div className="formgrid grid px-2">
        <div className="d-flex flex-column p-2 col-12">
          <label className="inputLabel">
            Are you creating a single or continuous offering?
          </label>
          <div className="d-flex mt-2">
            <div className="flex align-items-center pr-4">
              <div className="my-3 flex align-items-center">
                <RadioButton
                  inputId="isPooledFund"
                  name="isPooledFund"
                  value={false}
                  onChange={(e) =>
                    setFieldValue("isPooledFund", e.target.value)
                  }
                  checked={values.isPooledFund === false}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("isPooledFund"),
                  })}
                />

                <label className="ml-2 mb-0">
                  <b>Fixed:</b> Investors cannot add or withdraw capital after
                  initial investment, investment size is fixed .
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="flex align-items-center pr-4">
              <div className="my-3 flex align-items-center">
                <RadioButton
                  inputId="isPooledFund"
                  name="isPooledFund"
                  value={true}
                  onChange={(e) =>
                    setFieldValue("isPooledFund", e.target.value)
                  }
                  checked={values.isPooledFund === true}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("isPooledFund"),
                  })}
                />

                <label className="ml-2 mb-0">
                  <b>Continuous:</b> Investors can add or withdraw capital over
                  time, investment size is continuous
                </label>
              </div>
            </div>
          </div>
          {getFormErrorMessage("isPooledFund")}
        </div>
        <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
          <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
            <label className="inputLabel">&nbsp;</label>

            <Button
              className="btn-nav btnFocus"
              // tabIndex={0}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handlePrevious();
                navigate("/ManageOffering");
              }}
            >
              <span className="ml-auto mr-auto ">Cancel</span>
            </Button>
            <label className="inputLabel">&nbsp;</label>
          </div>
          <div className="d-flex flex-column p-2 col-12 sm:col-3 ">
            <label className="inputLabel">&nbsp;</label>
            <Button
              className="btn-dialog btnFocus"
              type="submit"
              // tabIndex={1}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
                //   formik.handleSubmit();
              }}
            >
              <span className="ml-auto mr-auto ">Next</span>
            </Button>
            <label className="inputLabel">&nbsp;</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateInvestmentStep1;
