import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
// import { TabView, TabPanel } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import {
  // RiArrowLeftSLine,
  // RiArrowRightSLine,
  RiSearchLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Error from "../../../../components/ErrorUi";
// import DashboardDropdownComponent from "../../../../components/dashboard-dropdown/DashboardDropdownComponent";

import RegisterDropdownComponent from "../../../../components/dashboard-dropdown/RegisterDropdown";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import Loader from "../../../../components/loader/Loader";
import { getThunkPortfolioTransaction } from "../../../../store/Transaction/slice";
import { getThunkPortfolioNonRegisterInvestorListDropdown } from "../../../../store/features/dropdown/nonregisteredinvestorlist/slice";
import { getThunkPortfolioRegisterInvestorListDropdown } from "../../../../store/features/dropdown/registeredinvestorlist/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  convertUTCToLocalDate,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import { exportExcel } from "../../../../utils/excel-dataformat";
// import TransactionSkeleton from "./TransactionSkeleton";

const Transaction = () => {
  // const dt = useRef<DataTable>(null);
  const navigate = useNavigate();

  const [activeIndex] = useState<number>(0);
  const [activePage] = useState<number>(1);
  const [filterValue, setFilterValue] = useState("");
  const [dataTransaction, setDataTransaction] = useState<any>();
  const [loading, setloading] = useState(false);
  const [openDailog, setOpenDailog] = useState(false);
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  const dispatch = useAppDispatch();
  const transaction = useAppSelector(
    (state) => state.portfolioTransaction.transaction
  );

  // const pageDetails = useAppSelector(
  //   (state) => state.portfolioTransaction.transactionPage
  // );
  const portState = useAppSelector((state) => state.legalEntityList.loading);
  // const profileData = useAppSelector((state) => state.profile.data![0]);

  const funddropdownModel = useAppSelector(
    (state) => state.legalEntityList.data
  );

  const legalEntityList: { code: number; name: string }[] = [];
  const [leagalentity] = useState<any>();

  for (let i = 0; i < funddropdownModel?.length; i++) {
    legalEntityList.push({
      code: funddropdownModel![i].legalEntityID,
      name: funddropdownModel![i].legalEntityName,
    });
  }

  useEffect(() => {
    const InvestorListparameters = {
      LegalEntityId: dropdown.fundid,
    };
    if (dropdown.fundid !== undefined) {
      dispatch(
        getThunkPortfolioRegisterInvestorListDropdown(InvestorListparameters)
      );
    }
  }, [dropdown.fundid]);

  useEffect(() => {
    const InvestorListparameters = {
      LegalEntityId: leagalentity,
    };
    if (leagalentity !== undefined) {
      dispatch(
        getThunkPortfolioNonRegisterInvestorListDropdown(InvestorListparameters)
      );
    }
  }, [leagalentity]);

  // const LegalEntityparameters = {
  //   Email: "softlabsavestor@gmail.com",
  //   UserTypeId: 1,
  // };

  // useEffect(() => {
  //   dispatch(getPortfolioGetLegalEntityList(LegalEntityparameters));
  // }, []);

  // const parameters = {
  //   investorID: 4,
  //   partnerID: 4,
  //   legalEntityID: 3,
  // };

  // const getTransactionWithDate = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedTransaction = {
  //       ...d,
  //       transactionDate: new Date(d.transactionDate),
  //     };
  //     return modifiedTransaction;
  //   });
  // };

  const formatCurrency = (value: string) => {
    const finalValue = Number(value);
    const parseValue = currencyFormat(Number(finalValue));

    return `${parseValue}`;
  };

  const doucmentCompleteNameTemplate = (item: any) => {
    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          // const url = `../../OpportunityDetails/${item.productid}`;
          localStorage.setItem("navigationPage", "Transaction");

          navigate(`../../OpportunityDetails/${item.productid}`);
          // window.history.replaceState({}, document.title, url);
          // navigate(`../../OpportunityDetails/${item.productid}`);
        }} // style={{ textDecoration: "underline" }}
        className="tabHeadText data-table-link-description left-align"
        title={item.productName}
      >
        {item.productName}
      </span>
    );
  };
  const formatDate = (rowData: any) => {
    const dateFormat: any = convertUTCToLocalDate(rowData.transactionDate);
    console.log("Format", dateFormat);
    return dateFormat;
  };

  useEffect(() => {
    setloading(true);
    setFilterValue("");
    let activeTab = "active";

    if (activeIndex === 0) {
      activeTab = "All";
    } else if (activeIndex === 1) {
      activeTab = "commercial debt";
    } else {
      activeTab = "residential debt";
    }
    if (dropdown.registerid !== undefined && dropdown.fundid !== undefined) {
      const dropdownparameters = {
        investorID: 0,
        partnerID: dropdown.registerid,
        legalEntityID: dropdown.fundid,
      };
      const obj = { activeTab, activePage, ...dropdownparameters };
      dispatch(getThunkPortfolioTransaction(obj)).finally(() => {
        setloading(false);
      });
    }
  }, [activeIndex, activePage, dropdown]);

  // const saveAsExcelFile = (buffer: any, fileName: string) => {
  //   import("file-saver").then((module) => {
  //     if (module && module.default) {
  //       const EXCEL_TYPE =
  //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //       const EXCEL_EXTENSION = ".xlsx";
  //       const data = new Blob([buffer], {
  //         type: EXCEL_TYPE,
  //       });

  //       module.default.saveAs(
  //         data,
  //         fileName +
  //           //  + "_export_" + Date.now()
  //           EXCEL_EXTENSION
  //       );
  //     }
  //   });
  // };

  // console.log("DataTransaction=>", downloadGridColumn);
  // useEffect(() => {
  //   transaction = [];
  // }, [nonregistredinverstment, leagalentity]);
  const excelTableHeaders = [
    {
      field: "transactionDate",
      header: "Transaction Date",
      dataType: "date",
    },
    {
      field: "transactionType",
      header: "Type",
      dataType: "string",
    },
    {
      field: "productName",
      header: "Description",
      dataType: "string",
    },
    {
      field: "investmentID",
      header: "Investment ID",
      dataType: "string",
    },
    {
      field: "notes",
      header: "Remark",
      dataType: "string",
    },
    {
      field: "credit",
      header: "Deposit",
      dataType: "currency",
    },
    {
      field: "debit",
      header: "Withdrawal",
      dataType: "currency",
    },
  ];

  useEffect(() => {
    setDataTransaction(
      getListDateConversion(transaction, ["transactionDate", "tranDate"])
    );
  }, [transaction]);

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  console.log("dataTransaction", dataTransaction);

  const dataTableElements = [
    {
      field: "transactionDate",
      header: "Transaction Date",
      style: { minWidth: "160px" },
      dataType: "date",
      body: formatDate,
      filterElement: dateFilterTemplate,
    },
    {
      field: "transactionType",
      header: "Type",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData.transactionType}>
          {rowData.transactionType}{" "}
        </div>
      ),
    },
    {
      field: "productName",
      header: "Description",
      style: { minWidth: "160px" },
      body: doucmentCompleteNameTemplate,
    },
    {
      field: "investmentID",
      header: "Investment ID",
      style: { minWidth: "160px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData.investmentID}>
          {rowData.investmentID}{" "}
        </div>
      ),
    },
    {
      field: "notes",
      header: "Remark",
      style: { minWidth: "160px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData.notes}>
          {rowData.notes}{" "}
        </div>
      ),
    },
    {
      field: "credit",
      header: "Deposit",
      style: { minWidth: "140px" },
      body: (rowData: any) => (
        <div className="text-right">{formatCurrency(rowData.credit)} </div>
      ),
    },
    {
      style: { minWidth: "140px" },
      field: "debit",
      header: "Withdrawal",
      body: (rowData: any) => (
        <div className="text-right">
          {rowData.debit ? formatCurrency(rowData.debit) : undefined}{" "}
        </div>
      ),
    },
  ];

  let content;
  switch (portState) {
    case "loading":
      content = <Loader />;
      break;
    case "succeeded":
      content = (
        <>
          {loading && <Loader />}
          <div className="grid">
            <div className="col-12 md:col-6 mt-2">
              <div className="card pgHeaderWrap md:my-2 mt-3">
                <h1 className="pageHeader">Transactions</h1>
                <p className="pageSubHeader">Transactions overview </p>
              </div>
            </div>

            <div className="col-12 md:col-6 mt-3">
              <div className="register-dropdown-wrapper">
                <RegisterDropdownComponent
                  showRegisteredInvestorDropdown={false}
                />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12">
              <div className="card my-3 tabWithoutboxshadow">
                <Card>
                  <h4 className="cardHeader">Transactions</h4>
                  <div className="my-2 d-flex flex-column md:flex-row justify-content-between  align-items-end">
                    {" "}
                    <div className="md:mb-0 mb-3">
                      <RiSearchLine className="mr-2" />
                      <InputText
                        className="md:w-auto w-95"
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        placeholder="Search..."
                      />
                    </div>
                    <div className="col-sm-3 flex align-items-center justify-content-end gap-2">
                      <>
                        <Button
                          className="blueButtonShadow"
                          type="button"
                          disabled={transaction?.length === 0}
                          onClick={() =>
                            exportExcel(
                              excelTableHeaders,
                              dataTransaction,
                              "Transaction",
                              true
                            )
                          }
                          data-pr-tooltip="CSV"
                        >
                          {" "}
                          <span className="blueButtonShadowText">
                            Download{" "}
                          </span>
                        </Button>
                        <Button
                          className="btn-navActive"
                          type="button"
                          icon="pi pi-window-maximize"
                          disabled={
                            dataTransaction === undefined ||
                            dataTransaction?.length === 0 ||
                            dataTransaction === null
                          }
                          onClick={() => setOpenDailog(true)}
                          title="View Full Screen"
                        />
                      </>
                    </div>
                  </div>
                  <DataTableComponent
                    valueData={dataTransaction}
                    fieldsElements={dataTableElements}
                    isPaginationAllowed={true}
                    isFullView={true}
                    excelFieldsElements={excelTableHeaders}
                    globalFilter={filterValue}
                    isDownload={true}
                    fileName="Investment Completed  Report"
                    scrollable={true}
                    className="gridcell"
                    columnResizeMode="expand"
                    setOpenDailog={setOpenDailog}
                    openDailog={openDailog}
                    maxHeight={"calc(-190px + 100vh)"}
                  />

                  {/* {transaction!.length > 0 ? (
                    <div className="flex justify-content-end mt-2">
                      <nav aria-label="...">
                        <ul className="pagination">
                          <Button
                            onClick={prevPage}
                            type="button"
                            icon={<RiArrowLeftSLine className="" />}
                            className="p-button-text Btnpage BtnPrev"
                            disabled={activePage === 1}
                          >
                            {" "}
                            <span>Prev</span>
                          </Button>
                          <li className="page-item active">
                            <span className="BtnBlue">{activePage}</span>
                          </li>
                          {pageDetails!.totalPages > 0 ? (
                            <li className="page-item active">
                              <span className="font-bold">of </span>
                              <span className="BtnBlue ">
                                {pageDetails?.totalPages}
                              </span>
                            </li>
                          ) : (
                            <></>
                          )}

                          <Button
                            onClick={nextPage}
                            type="button"
                            disabled={
                              pageDetails?.totalPages === activePage ||
                              pageDetails?.totalPages === 0
                            }
                            className="p-button-text Btnpage BtnNext"
                          >
                            <span>
                              Next <RiArrowRightSLine />{" "}
                            </span>
                          </Button>
                        </ul>
                      </nav>
                    </div>
                  ) : undefined} */}
                </Card>
              </div>
            </div>
          </div>
        </>
      );

      break;
    case "failed":
      content = <Error />;

      // content = data.map((product: any) => {
      //   return <OpportunityCard p={product} key={product.productID} />;
      // });
      break;
  }
  return <>{content}</>;
};

export default Transaction;
