import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSoftCommitService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";
import { ISoftCommitParameter, ISoftCommitState } from ".";

const initialState: ISoftCommitState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  totalSoftCommit: undefined,
};
export const getThunkSoftCommits = createAsyncThunk(
  "softCommits",
  async (data: ISoftCommitParameter) => {
    try {
      const response = await getSoftCommitService(data);
      console.log("res", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "softCommits",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkSoftCommits.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkSoftCommits.fulfilled, (state, action) => {
      state.loading = "succeeded";
      if (action.payload._response.data !== null) {
        state.data = action.payload._response.data[1];
        state.totalSoftCommit =
          action.payload._response.data[0][0].totalSoftCommits;
      }
    });
    builder.addCase(getThunkSoftCommits.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
