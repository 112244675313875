// import { Badge } from "primereact/badge";
import { Card } from "primereact/card";
import React, { useEffect } from "react";
import {
  RiStore2Line,
  RiShoppingCart2Line,
  RiExchangeFundsLine,
  RiDraftLine,
  RiPieChartLine,
  RiMailSendLine,
  RiUserAddLine,
  RiAccountCircleLine,
  RiHandCoinLine,
  RiCoinsLine,
  RiPercentLine,
  RiNewspaperLine,
  RiSlideshowLine,
  RiCloudLine,
  RiFileAddLine,
  RiFileTransferLine,
  RiImageEditLine,
  RiRobotLine,
  RiQuestionnaireLine,
  RiCouponLine,
  RiTeamLine,
} from "react-icons/ri";
import "./dashboard.scss";
import { NavLink } from "react-router-dom";

import Loader from "../../components/loader/Loader";
import { setChatBotState } from "../../store/features/chat-bot-state/slice";
// import { getThunkNotificationCount } from "../../store/features/notification/get-notification-count/slice";
import { getThunkDashboardMenus } from "../../store/features/home/get-dashboard-menus/slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { envVariable } from "../../utils/sponsor/helper";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  //  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const loadingState = useAppSelector((state) => state.userMenu.loading);
  const dashboardMenus = useAppSelector(
    (state) => state.dashboardMenus.data?.getStuffList
  );
  // const notificationCount = useAppSelector(
  //   (state) => state.getNotificationCount.data.notificationCount
  // );
  // const breakpointend = 769;
  // const [windowWidth, setWindowWidth] = useState<any>(window.innerWidth);
  // // const breakpointstart = 600;
  // const breakpointend = 769;
  console.log("dashboardMenus", dashboardMenus);
  useEffect(() => {
    // const handleResize = () => {
    //   setWindowWidth(window.innerWidth);
    // };
    // window.addEventListener("resize", handleResize);
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);
  useEffect(() => {
    dispatch(getThunkDashboardMenus(true));
  }, []);
  const openChatBot = () => {
    dispatch(setChatBotState({ visibleRightState: true }));
  };
  const getIconDashBoard = (icon: any) => {
    // Add logic to determine which icon to use based on the name
    console.log("icon.menuName", icon);
    switch (icon) {
      case "RiStore2Line":
        return <RiStore2Line className="icons-wrap-dashboard" />;

      case "RiShoppingCart2Line":
        return <RiShoppingCart2Line className="icons-wrap-dashboard" />;

      case "RiExchangeFundsLine":
        return <RiExchangeFundsLine className="icons-wrap-dashboard" />;

      case "RiDraftLine":
        return <RiDraftLine className="icons-wrap-dashboard" />;

      case "RiPieChartLine":
        return <RiPieChartLine className="icons-wrap-dashboard" />;

      case "RiHandCoinLine":
        return <RiHandCoinLine className="icons-wrap-dashboard" />;

      case "RiMailSendLine":
        return <RiMailSendLine className="icons-wrap-dashboard" />;
      case "RiUserAddLine":
        return <RiUserAddLine className="icons-wrap-dashboard" />;
      case "RiAccountCircleLine":
        return <RiAccountCircleLine className="icons-wrap-dashboard" />;
      case "RiCoinsLine":
        return <RiCoinsLine className="icons-wrap-dashboard" />;
      case "RiSlideshowLine":
        return <RiSlideshowLine className="icons-wrap-dashboard" />;
      case "RiFileAddLine":
        return <RiFileAddLine className="icons-wrap-dashboard" />;
      case "RiFileTransferLine":
        return <RiFileTransferLine className="icons-wrap-dashboard" />;
      case "RiImageEditLine":
        return <RiImageEditLine className="icons-wrap-dashboard" />;
      case "RiPercentLine":
        return <RiPercentLine className="icons-wrap-dashboard" />;
      case "RiCloudLine":
        return <RiCloudLine className="icons-wrap-dashboard" />;
      case "RiNewspaperLine":
        return <RiNewspaperLine className="icons-wrap-dashboard" />;
      default: {
        return <></>;
      }
    }
  };
  let content;
  loadingState === "succeeded"
    ? (content = (
        <>
          <div className="grid">
            <div className="col-12">
              <div className="card my-3 ">
                <Card>
                  <h2 className="cardHeader">Workspace</h2>

                  {dashboardMenus?.map((headMenu: any, index: any) => {
                    const emptyCount = 6 - headMenu.data.length;
                    return (
                      <>
                        <div
                          className="workspace-blockWrap grid m-2 ml-0 mr-0"
                          key={index}
                        >
                          {" "}
                          <div className="col workspace-block flex align-items-center justify-content-center">
                            <div
                              className="flex align-items-center justify-content-center"
                              style={{ margin: "auto" }}
                            >
                              {headMenu.section}
                            </div>
                          </div>
                          {headMenu?.data
                            .slice(0, 6)
                            ?.map((items: any, indexMenu: any) => {
                              const Icon = getIconDashBoard(items.homeMenuIcon);
                              console.log("k", items.homeMenuIcon);
                              return (
                                <div
                                  className="col workspace-block2 text-center"
                                  key={indexMenu}
                                >
                                  <div className="xl:flex lg:flex md:flex-none sm:flex-none arrow">
                                    <div
                                      className="flex-align"
                                      style={{ margin: "auto" }}
                                    >
                                      <div
                                        className="dashboard-value-details-icon mt-2"
                                        style={{ margin: "auto" }}
                                      >
                                        <NavLink
                                          to={items.menuURL}
                                          className="anchor-icon  "
                                          // onClick={(e) => e.preventDefault()}
                                        >
                                          <div>
                                            {/* <items.homeMenuIcon className="icons-wrap-dashboard" /> */}
                                            {Icon}
                                          </div>
                                        </NavLink>
                                      </div>

                                      <div
                                        className="flex align-items-center justify-content-center mt-3"
                                        style={{ margin: "auto" }}
                                      >
                                        <span className="text">
                                          {items.homeMenuLabel}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {headMenu.data.length < 6 && (
                            <>
                              {Array.from(
                                { length: emptyCount },
                                (indexTest: any) => {
                                  return (
                                    <div
                                      className="col workspace-block2 text-center"
                                      key={indexTest}
                                    ></div>
                                  );
                                }
                              )}
                            </>
                          )}
                        </div>
                        {headMenu?.data?.length > 6 && (
                          <div
                            className="workspace-blockWrap grid m-2 ml-0 mr-0"
                            key={index}
                          >
                            {" "}
                            {/* <div className="col workspace-block flex align-items-center justify-content-center">
                              <div
                                className="flex align-items-center justify-content-center"
                                style={{ margin: "auto" }}
                              >
                                {headMenu.section}
                              </div>
                            </div> */}
                            {headMenu?.data
                              .slice(6, headMenu?.data?.length)
                              ?.map((items: any, indexMenu: any) => {
                                const Icon = getIconDashBoard(
                                  items.homeMenuIcon
                                );
                                console.log("k", items.homeMenuIcon);
                                return (
                                  <div
                                    className="col workspace-block2 text-center"
                                    key={indexMenu}
                                  >
                                    <div className="xl:flex lg:flex md:flex-none sm:flex-none arrow">
                                      <div
                                        className="flex-align"
                                        style={{ margin: "auto" }}
                                      >
                                        <div
                                          className="dashboard-value-details-icon mt-2"
                                          style={{ margin: "auto" }}
                                        >
                                          <NavLink
                                            to={items.menuURL}
                                            className="anchor-icon  "
                                            // onClick={(e) => e.preventDefault()}
                                          >
                                            <div>
                                              {/* <items.homeMenuIcon className="icons-wrap-dashboard" /> */}
                                              {Icon}
                                            </div>
                                          </NavLink>
                                        </div>

                                        <div
                                          className="flex align-items-center justify-content-center mt-3"
                                          style={{ margin: "auto" }}
                                        >
                                          <span className="text">
                                            {items.homeMenuLabel}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            {headMenu?.data.slice(6, headMenu?.data?.length)
                              .length < 6 && (
                              <>
                                {Array.from(
                                  {
                                    length:
                                      6 -
                                      headMenu?.data.slice(
                                        6,
                                        headMenu?.data?.length
                                      ).length,
                                  },
                                  (indexTest: any) => {
                                    return (
                                      <div
                                        className="col workspace-block2 text-center"
                                        key={indexTest}
                                      ></div>
                                    );
                                  }
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </>
                    );
                  })}

                  <div className=" workspace-blockWrap grid m-2 ml-0 mr-0">
                    <div className="col workspace-block flex align-items-center justify-content-center">
                      <div
                        className="flex align-items-center justify-content-center"
                        style={{ margin: "auto" }}
                      >
                        Resources
                      </div>
                    </div>

                    <div className="col workspace-block2 text-center">
                      <div className="xl:flex lg:flex md:flex-none sm:flex-none arrow">
                        <div className="flex-align" style={{ margin: "auto" }}>
                          <div
                            className="dashboard-value-details-icon mt-2"
                            style={{ margin: "auto" }}
                          >
                            <NavLink
                              to=""
                              className="anchor-icon  "
                              onClick={(e) => {
                                openChatBot();
                              }}
                            >
                              <div>
                                <RiRobotLine className="icons-wrap-dashboard" />
                              </div>
                            </NavLink>
                          </div>
                          <div
                            className="flex align-items-center justify-content-center mt-3"
                            style={{ margin: "auto" }}
                          >
                            <span className="text">AI Assistant (Beta)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col workspace-block2 text-center">
                      <div className="xl:flex lg:flex md:flex-none sm:flex-none arrow">
                        <div className="flex-align" style={{ margin: "auto" }}>
                          <div
                            className="dashboard-value-details-icon mt-2"
                            style={{ margin: "auto" }}
                          >
                            <NavLink
                              to=""
                              className="anchor-icon  "
                              onClick={(e) => {
                                window.open(`${envVariable?.REACT_APP_FAQs}`);
                              }}
                            >
                              <div>
                                <RiQuestionnaireLine className="icons-wrap-dashboard" />
                              </div>
                            </NavLink>
                          </div>
                          <div
                            className="flex align-items-center justify-content-center mt-3"
                            style={{ margin: "auto" }}
                          >
                            <span className="text">Support Site</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col workspace-block2 text-center">
                      <div className="xl:flex lg:flex md:flex-none sm:flex-none arrow">
                        <div className="flex-align" style={{ margin: "auto" }}>
                          <div
                            className="dashboard-value-details-icon mt-2"
                            style={{ margin: "auto" }}
                          >
                            <NavLink
                              to=""
                              className="anchor-icon  "
                              onClick={(e) => {
                                window.open(
                                  `${envVariable?.REACT_APP_Boldesk}`
                                );
                              }}
                            >
                              <div>
                                <RiCouponLine className="icons-wrap-dashboard" />
                              </div>
                            </NavLink>
                          </div>
                          <div
                            className="flex align-items-center justify-content-center mt-3"
                            style={{ margin: "auto" }}
                          >
                            <span className="text">Submit a Ticket</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col workspace-block2 text-center">
                      <div className="xl:flex lg:flex md:flex-none sm:flex-none arrow">
                        <div className="flex-align" style={{ margin: "auto" }}>
                          <div
                            className="dashboard-value-details-icon mt-2"
                            style={{ margin: "auto" }}
                          >
                            <NavLink
                              to=""
                              className="anchor-icon  "
                              onClick={(e) => {
                                window.open(
                                  `${envVariable?.REACT_APP_Community}`
                                );
                              }}
                            >
                              <div>
                                <RiTeamLine className="icons-wrap-dashboard" />
                              </div>
                            </NavLink>
                          </div>
                          <div
                            className="flex align-items-center justify-content-center mt-3"
                            style={{ margin: "auto" }}
                          >
                            <span className="text">Community</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col workspace-block2 text-center">
                      <div className="xl:flex lg:flex md:flex-none sm:flex-none arrow"></div>
                    </div>
                    <div className="col workspace-block2 text-center">
                      <div className="xl:flex lg:flex md:flex-none sm:flex-none arrow"></div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </>
      ))
    : (content = <Loader />);

  return <>{content}</>;
}
