import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCommitmentService } from "../../../../../services/e-sign/e-sign-ondemand";
import error from "../../../../../utils/error";
import { IGetExecuteEsignCommitmentState } from ".";

const initialState: IGetExecuteEsignCommitmentState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkExecuteEsignCommitmentDropDown = createAsyncThunk(
  "commitmentList",
  async () => {
    try {
      const response = await getCommitmentService();
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "commitmentList",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkExecuteEsignCommitmentDropDown.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getThunkExecuteEsignCommitmentDropDown.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log(action.payload);
        if (action.payload.data !== null) {
          state.data = action.payload.data;
        }
      }
    );
    builder.addCase(
      getThunkExecuteEsignCommitmentDropDown.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        //   state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
