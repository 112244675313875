import { Button } from "primereact/button";
import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorUi = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="grid">
        <div className="col-12">
          <h1 className="pageHeader mb-7">Error</h1>
          <h4 className="cardHeaderWithoutIcon line-height-3">
            Apologies! It appears you have encountered an error. <br />
            Please click below to return home:
          </h4>
          <Button
            className="BtnBlue w-100 mt-5 mx-0 md:w-6 w-12"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/`);
            }}
          >
            <span>Take me back!</span>
          </Button>{" "}
        </div>
      </div>
    </>
  );
};
export default ErrorUi;
