import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addPoolFundsActions } from "../../../store/poolfunds/add-pool-funds-state";
import { InvestorListType } from "../../../store/poolfunds/add-pool-funds-state/type";
import { formatDateMMDDYYY } from "../../../utils/date-formatter";
import { errorToastMessage } from "../../../utils/toast-message";
import DateTemplate from "../../features/common-component/DateTemplate";
import StepButtons from "../StepButtons";

type PoolFundStep1Props = {
  handlePrevious: () => void;
  handleNext: () => void;
  toast: any;
};
const PoolFundStep2: React.FC<PoolFundStep1Props> = ({
  handleNext,
  handlePrevious,
  toast,
}) => {
  const [errorState, setErrorState] = useState<any>([]);
  const [hasError, setHasError] = useState<boolean>();
  const dispatch = useAppDispatch();
  const addPoolFundData = useAppSelector((state) => state.AddPoolFunds.data);
  const pooledFundsInvestmentDetails: any = useAppSelector(
    (state) => state.getAllInvestorPoolFund.data.pooledFundsInvestmentDetails
  );
  useEffect(() => {
    const investorList = [...addPoolFundData?.investorList];
    const list: any = [];
    for (const item of investorList) {
      const investorObj = {
        lpPartnerId: item.lpPartnerId,
        allocationDateError: "",
      };
      list.push(investorObj);
    }
    setErrorState(list);
  }, []);
  useEffect(() => {
    if (errorState !== undefined) {
      setHasError(
        errorState.some((error: any) => error.allocationDateError !== "")
      );
    }
  }, [errorState]);
  const editDates = (rowData: any) => {
    let selectedError: any;
    let error: any = false;

    if (errorState !== undefined) {
      selectedError = errorState.find(
        (data: any) => data.lpPartnerId === rowData.lpPartnerId
      );
      console.log(selectedError);
      error = selectedError?.allocationDateError !== "";
      console.log("ddd", error);
    }
    const handleInputChange = (e: any, data: any) => {
      const { value } = e.target;
      const updateRowData = {
        ...data,
        allocationDate: formatDateMMDDYYY(new Date(value)),
      };
      dispatch(
        addPoolFundsActions.setAddPoolFundData({
          ...addPoolFundData,
          investorList: addPoolFundData.investorList.map(
            (obj: InvestorListType) => {
              if (obj.lpPartnerId === data.lpPartnerId) {
                return updateRowData;
              }
              return obj;
            }
          ),
        })
      );

      const modifyErrorList = (dateError: any) =>
        errorState.map((obj: any) => {
          return obj?.lpPartnerId === data.lpPartnerId
            ? {
                ...obj,
                allocationDateError: dateError,
              }
            : obj;
        });
      if (
        new Date(value).setHours(0, 0, 0, 0) <
        new Date(pooledFundsInvestmentDetails?.lastAllocationDate!).setHours(
          0,
          0,
          0,
          0
        )
      ) {
        setErrorState(
          modifyErrorList(
            "The Allocation Date is earlier than permitted. Allocation Date has to be the same or greater than the Previous Allocation Date."
          )
        );
      } else {
        setErrorState(modifyErrorList(""));
      }
    };
    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          {addPoolFundData.isDateChange === true ? (
            <>
              <Calendar
                name="allocationDate"
                value={
                  rowData.allocationDate
                    ? new Date(rowData.allocationDate)
                    : undefined
                }
                minDate={
                  new Date(
                    pooledFundsInvestmentDetails?.lastAllocationDate! ||
                      pooledFundsInvestmentDetails?.investmentStartDate!
                  )
                }
                onChange={(e) => handleInputChange(e, rowData)}
                placeholder="Select Date"
                className="card-inputBox"
                readOnlyInput
                dateTemplate={DateTemplate}
              />
              {error && <RiErrorWarningLine title="" className="iconError" />}
            </>
          ) : (
            <label className="w-100">
              {rowData.allocationDate ? (
                formatDateMMDDYYY(new Date(rowData.allocationDate))
              ) : (
                <>N/A</>
              )}
            </label>
          )}
        </div>
      </div>
    );
  };

  const dataTableElement = [
    {
      field: "name",
      header: "Selected Investors",
    },
    {
      field: "allocationDate",
      header: `${addPoolFundData.isDateChange ? "Set Date" : "Selected Date"}`,
      body: editDates,
      dataType: "date",
    },
  ];

  const onBackBtn = () => {
    handlePrevious();
  };
  const onContinue = () => {
    if (!hasError) {
      dispatch(
        addPoolFundsActions.setAddPoolFundData({
          ...addPoolFundData,
          showAllocatedInvestor: false,
        })
      );
      handleNext();
    } else {
      errorToastMessage(toast, "Please check the allocate date");
    }
  };
  return (
    <>
      <div className="mt-4">
        <div className="formgrid grid px-2">
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-12">
            <label className="inputLabel pr-2 flex align-items-center">
              Investment Name:
              <span className="ml-1 bold">
                {addPoolFundData.investmentName}
              </span>
            </label>
          </div>
        </div>
        <div className="formgrid grid px-2">
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
            <label className="inputLabel">Investment Start Date:</label>
            <h3>
              {pooledFundsInvestmentDetails?.investmentStartDate ? (
                formatDateMMDDYYY(
                  new Date(pooledFundsInvestmentDetails?.investmentStartDate)
                )
              ) : (
                <></>
              )}
            </h3>
          </div>
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12  sm:col-4">
            <label className="inputLabel">Most Recent Allocation Date: </label>
            <h3>
              {pooledFundsInvestmentDetails?.lastAllocationDate ? (
                formatDateMMDDYYY(
                  new Date(pooledFundsInvestmentDetails?.lastAllocationDate)
                )
              ) : (
                <></>
              )}
            </h3>
          </div>
        </div>

        <div className="allocate-block m-2">
          <div className="m-2 text-white">
            <p>Note:</p>
            <p className="mt-2 ">
              When setting an allocation date after the investment start date, a
              pro-rata share of ALL FUTURE EARNINGS will be allocated to the
              manager.
            </p>
          </div>
        </div>
        <div className="d-block sm:d-flex sm:flex-column p-2 col-12">
          <label className="inputLabel">Set Allocation Dates</label>

          <DataTableComponent
            valueData={addPoolFundData.investorList || []}
            // valueData={demoData}
            fieldsElements={dataTableElement}
            isPaginationAllowed={true}
            noGridLines={true}
            className="allocateSliceTable"
          />
        </div>

        <div className="formgrid grid px-2">
          <div className="iconError mb-3  p-2">
            <ul>
              {hasError ? (
                <li>
                  The Allocation Date is earlier than permitted. Allocation Date
                  has to be the same or greater than the most recent allocation
                  date.
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>
      <StepButtons
        firstBtnLabel="Back"
        secondBtnLabel="Continue"
        handleFirstBtn={onBackBtn}
        handleSecondBtn={onContinue}
      />
    </>
  );
};

export default PoolFundStep2;
