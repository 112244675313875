import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getPortfolioAssetDropdownService } from "../../services/portfolio/portfolio";
import { IAssetDropdown } from "../../types";

import error from "../../utils/error";
interface IPortfolioState {
  AssetDropdown?: IAssetDropdown[] | [];

  loading: string;
  error?: string | null;
  invRegistrationId?: number;
}

const initialState: IPortfolioState = {
  AssetDropdown: [],
  loading: "loading",
  error: undefined,
  invRegistrationId: undefined,
};

// interface Iparameters {
//   investorID: number;
//   partnerID: number;
//   legalEntityID: number;
// }

// change the endpoint
export const getThunkPortfolioAssetDropdown = createAsyncThunk(
  "Portfolio/AssetDropdown",
  async () => {
    try {
      const response = await getPortfolioAssetDropdownService();
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Portfolio/AssetDropdown",
  initialState,
  reducers: {
    setInvRegistrationIdForFundName: (state, action) => {
      state.invRegistrationId = action.payload;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getThunkPortfolioAssetDropdown.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      getThunkPortfolioAssetDropdown.fulfilled,
      (state, action) => {
        state.loading = "succeeded";

        state.AssetDropdown = action.payload.funds;
      }
    );
    builder.addCase(
      getThunkPortfolioAssetDropdown.rejected,
      (state, action) => {
        state.loading = "failed";

        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});
export const { setInvRegistrationIdForFundName } = slice.actions;

export const getAllPortfoliosData = (state: RootState) => {
  return state.portfolio.data;
};
// export const getAllOpportunitiesStatus = (state: RootState) =>
//   state.opportunity.loading;
// export const getAllOpportunitiesError = (state: RootState) =>
//   state.opportunity.error;
export default slice.reducer;
