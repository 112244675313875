import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    fundid: 0,
    registerid: 0,
    nonregisterid: 0,
    slug: 0,
  },
  loading: "loading",
  error: undefined,
};

export const navbarDropdown = createAsyncThunk(
  "navBarState",
  async (_data: any) => {
    return _data;
  }
);

const slice = createSlice({
  name: "navBarState",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(navbarDropdown.fulfilled, (state, action: any) => {
      state.loading = "succeeded";

      state.data = action.payload;
    });
  },
});
export default slice.reducer;
