import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";

import { RiErrorWarningLine } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import Loader from "../../../../components/loader/Loader";
import { setProductId } from "../../../../store/features/investment/investment-filter/investment-filter-id/slice";
import { addThunkInvestmentReturnPooledFund } from "../../../../store/features/operation/allocate-earning/add-investment-return-continuous";
import { calculateEarningPercentageThunk } from "../../../../store/features/operation/allocate-earning/calculate-earning-percent";
import { getThunkAllocateEarningPooledFund } from "../../../../store/features/operation/allocate-earning/get-earning-data-pooledfund";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ManagerFeesType } from "../../../../types";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  convertLocalDateToUTC,
  formatDateMMDDYYY,
} from "../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

export default function AllocateEarningContinuous({
  productObjectByFilter,
  setProductObjectByFilter,
  investmentID,
  setInvestmentId,
  editData,
  setEditData,
  setLoadForm,
  setTab,
  setShowAddEarnings,
}: any) {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [openPercentageDailog, setOpenPercentageDailog] = useState(false);
  const [percentInputs, setPercentInputs] = useState<any>({
    percent: 0,
    returnDate: "",
  });
  const [percentInputsError, setPercentInputsError] = useState<any>({
    percent: false,
    returnDate: false,
  });
  const [totalEarnings, setTotalEarning] = useState<any>({});
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [investmentReturnID, setInvestmentReturnId] = useState(0);

  const calculateEarning = useAppSelector(
    (state) => state.getCalculateEarningPooledfund.data
  );

  const returnTypeData = useAppSelector(
    (state) => state.getAllocatedEarningDataPooledFund.data.returnType
  );
  const managerFees: any = useAppSelector(
    (state) => state.managerFees.data.output
  );

  const resetProductObjState = () => {
    setProductObjectByFilter({
      productID: 0,
      lookupCodeObj: "",
      address: "",
      zipCode: "",
      fundingDate: "",
    });
  };

  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };
  const formik = useFormik({
    initialValues: {
      returnType: "",
      amount: 0,
      returnDate: "",
      // eslint-disable-next-line unicorn/no-null
      allocationDate: null,
      emailStatus: "N",
      productId: "",
      managerFees: 0,
      isReinvest: true,
      investedEarning: 0,
      managerFeesPreSet: 0,
    },
    validate: (data) => {
      const errors: any = {};
      if (!data.returnType) {
        errors.returnType = "Return type is required";
      }
      if (!data.amount) {
        errors.amount = "Amount is required";
      }
      if (data.amount && data.amount > 20_000_000_000) {
        errors.amount = "Amount should be less than equal to $20B";
      }
      if (!data.returnDate) {
        errors.returnDate = "Return date is required";
      }
      if (!data.productId) {
        errors.productId = "Investment id is required";
      }
      if (data.managerFees && data.managerFees > data.amount) {
        errors.managerFees = "Manager fees should not be greater than amount";
      }

      return errors;
    },

    onSubmit: (data) => {
      console.log("investId in submit", investmentID);
      setLoading(true);
      const returnDateTest =
        formik.values.returnDate !== null &&
        formik.values.returnDate !== "" &&
        formik.values.returnDate !== undefined
          ? convertLocalDateToUTC(formik.values.returnDate)
          : formik.values.returnDate;

      const equityCashDistiAllocationDateTest =
        formik.values.allocationDate !== null &&
        formik.values.allocationDate !== "" &&
        formik.values.allocationDate !== undefined
          ? convertLocalDateToUTC(formik.values.allocationDate)
          : formik.values.allocationDate;

      const formData = {
        action: investmentReturnID === 0 ? "Create" : "Update",
        investmentReturnId: investmentReturnID || 0,
        investmentId: investmentID,
        returnTypeId: formik.values.returnType,
        returnAmount: formik.values.investedEarning,
        returnDate: returnDateTest,
        equityCashDistiAllocationDate: equityCashDistiAllocationDateTest,
        managerFees: formik.values.managerFees,
        isReinvest: formik.values.isReinvest,
        managerFeesPreSet: formik.values.managerFeesPreSet,
      };
      dispatch(addThunkInvestmentReturnPooledFund(formData))
        .then((resposne) => {
          successToastMessage(toast, "Data saved successfully");
          const param = {
            legalEntityID: fundDropdown.fundid,
            investmenId: investmentID,
          };
          dispatch(getThunkAllocateEarningPooledFund(param));
          formik.resetForm();
          formik.setFieldValue("amount", "");
          formik.setFieldValue("managerFeesPreSet", "");
          formik.setFieldValue("productId", productObjectByFilter?.productID);
          dispatch(setProductId({ productId: 0, resetFilters: true }));
          // eslint-disable-next-line unicorn/no-null
          setEditData(null);
          resetProductObjState();
          setInvestmentReturnId(0);
          setInvestmentId(0);
        })
        .catch(() => {
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  console.log("ProductId in general", productObjectByFilter);

  useEffect(() => {
    if (!editData) {
      if (
        formik.values.managerFees !== 0 &&
        formik.values.managerFeesPreSet !== 0
      ) {
        formik.setFieldValue("isReinvest", true);
      } else {
        formik.setFieldValue("isReinvest", false);
      }
    }
  }, [formik.values.managerFees, formik.values.managerFeesPreSet, editData]);
  console.log("calculateEarning", calculateEarning);

  const handleChange = (e: any) => {
    console.log("kl", e.target);
    const { name, value } = e.target;
    console.log(name, value);
    if (name === "returnDate" || name === "allocationDate") {
      const dateValue = value;
      console.log("Data Value", dateValue);
      formik.setFieldValue(name, dateValue);
    } else {
      formik.setFieldValue(name, value);
    }
  };

  const onReset = () => {
    formik.resetForm();
    formik.values.amount = 0;
    setInvestmentReturnId(0);
    dispatch(setProductId({ productId: 0, resetFilters: true }));
    resetProductObjState();
    setInvestmentId(0);
    // eslint-disable-next-line unicorn/no-null
    setEditData(null);
    setLoadForm(false);
    setTab("Fixed");
    setShowAddEarnings(true);
  };
  useEffect(() => {
    formik.resetForm();
    formik.setFieldValue("productId", productObjectByFilter?.productID);
  }, [fundDropdown.fundid]);

  useEffect(() => {
    if (editData) {
      formik.setFieldValue(
        "returnDate",
        editData.returnDate === null ? "" : formatDate(editData.returnDate)
      );
      formik.setFieldValue("returnType", editData.returnType);
      formik.setFieldValue("allocationDate", editData.allocationDate);
      formik.setFieldValue("amount", editData.amount);
      formik.setFieldValue("managerFeesPreSet", editData.managerFeesPreSet);
      formik.setFieldValue("managerFees", editData.managerFees);
      formik.setFieldValue("investedEarning", editData.investedEarning);
      formik.setFieldValue("productId", editData.productId);
      formik.setFieldValue("isReinvest", editData.isReinvest);
      setInvestmentReturnId(editData.investmentReturnID);
      setLoadForm(true);
    }
  }, [editData]);

  useEffect(() => {
    formik.setFieldValue("productId", productObjectByFilter?.productID);
    console.log("productId", productObjectByFilter?.productID);
  }, [productObjectByFilter?.productID]);

  const handlePerSetPercent = (val: any) => {
    const res = managerFees?.find(
      (per: ManagerFeesType) =>
        val >= per.lowerFundingAmount && val < per.upperFundingAmount
    );
    const percent = res?.preSetPercentage ? res?.preSetPercentage : 0;
    console.log("res", res, percent);
    const fees: any = (val * 1 * percent) / 100;
    formik.setFieldValue("managerFeesPreSet", percent);
    formik.setFieldValue(
      "managerFees",
      res?.fixedAmount ? res?.fixedAmount : Number.parseFloat(fees).toFixed(2)
    );
    const feesAmount = res?.fixedAmount
      ? res?.fixedAmount
      : Number.parseFloat(fees).toFixed(2);
    console.log("test val", val, val - feesAmount);

    formik.setFieldValue("investedEarning", val - feesAmount);

    console.log("fee", fees);
  };
  console.log("formik val", formik.values);

  const onDialogCancel = () => {
    setOpenPercentageDailog(false);
    setPercentInputs({ percent: 0, returnDate: "" });
    setTotalEarning({});
    setPercentInputsError({ percent: false, returnDate: false });
  };

  const copyEarning = (rowData: any) => {
    const onUseThis = () => {
      console.log("formik val", formik.values);
      formik.setFieldValue("amount", rowData?.totalearnings);
      formik.setFieldValue("returnDate", percentInputs?.returnDate);
      if (formik.values.managerFeesPreSet) {
        const fees =
          (rowData?.totalearnings * 1 * formik.values.managerFeesPreSet) / 100;
        formik.setFieldValue("managerFees", fees);
        formik.setFieldValue("investedEarning", rowData?.totalearnings - fees);
      } else {
        formik.setFieldValue("investedEarning", rowData?.totalearnings);
      }
      onDialogCancel();
    };
    return (
      <Button
        className="btn-navActive"
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          onUseThis();
        }}
        title="Use This Earning"
        icon="pi pi-copy"
      />
    );
  };

  const dataTableElement = [
    {
      header: "Prior Month Investor Earnings",
      body: (rowData: any) =>
        currencyFormat(rowData.priorMonthInvestorearnings),
    },
    {
      header: "Prior Month Manager Earnings",
      body: (rowData: any) => currencyFormat(rowData.priorMonthManagerearnings),
    },
    {
      header: "Start of Month Contribution Earnings",
      body: (rowData: any) =>
        currencyFormat(rowData.startOfMonthControbutionearnings),
    },
    {
      header: "Mid Month Contribution Earnings",
      body: (rowData: any) =>
        currencyFormat(rowData.midOfMonthControbutionearnings),
    },
    {
      header: "Total Earnings",
      body: (rowData: any) => currencyFormat(rowData.totalearnings),
    },
    {
      header: "",
      body: copyEarning,
    },
  ];

  const calculatePercentageAmout = () => {
    if (!percentInputs.percent || !percentInputs.returnDate) {
      if (!percentInputs.percent) {
        setPercentInputsError((prevError: any) => {
          return { ...prevError, percent: true };
        });
      }
      if (!percentInputs.returnDate) {
        setPercentInputsError((prevError: any) => {
          return { ...prevError, returnDate: true };
        });
      }
      errorToastMessage(toast, "Please Check the Values");
    } else {
      setPercentInputsError({ percent: false, returnDate: false });
      const param = { ...percentInputs };
      param.returnDate = convertLocalDateToUTC(percentInputs.returnDate);
      param.investmentID = investmentID;
      setLoading(true);
      dispatch(calculateEarningPercentageThunk(param))
        .then((response) => {
          if (response.payload._response.success) {
            const data = response.payload._response.data.output;
            setTotalEarning(data);
          } else {
            setTotalEarning({});
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    }
  };

  const handleDailogInputChange = (e: any) => {
    const { name, value } = e.target;
    const values = { ...percentInputs };
    values[name] = value;
    setPercentInputs(values);
    setPercentInputsError((prevError: any) => {
      return { ...prevError, [name]: !value };
    });
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <Dialog
        visible={openPercentageDailog}
        onHide={() => onDialogCancel()}
        headerClassName="no-header"
        contentClassName="top-lr-content"
        style={{ width: "50vw" }}
      >
        {loading === true ? <Loader /> : <></>}
        <div className="mt-4">
          <div className="formgrid grid px-2">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Percentage* </label>
              <InputNumber
                name="percent"
                value={
                  percentInputs.percent !== 0
                    ? percentInputs.percent
                    : undefined
                }
                onValueChange={(e) => handleDailogInputChange(e)}
                className={classNames({
                  "p-invalid": percentInputsError.percent,
                  "card-inputBox": true,
                })}
                placeholder="Enter Percentage"
                minFractionDigits={2}
              />
              {percentInputsError.percent && (
                <small className="p-error">Percentage is Required</small>
              )}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Return Date* </label>
              <Calendar
                name="returnDate"
                value={
                  percentInputs.returnDate === "" ||
                  percentInputs.returnDate === null
                    ? undefined
                    : new Date(percentInputs.returnDate)
                }
                onChange={(e) => handleDailogInputChange(e)}
                className={classNames({
                  "p-invalid": percentInputsError.returnDate,
                  "card-inputBox": true,
                })}
                placeholder="mm/dd/yyyy"
              />
              {percentInputsError.returnDate && (
                <small className="p-error">Return Date is Required</small>
              )}
            </div>
          </div>
          {totalEarnings.length > 0 && (
            <div className="formgrid grid px-2">
              <DataTableComponent
                valueData={totalEarnings}
                fieldsElements={dataTableElement}
                // className="allocateSliceTable"
              />
            </div>
          )}
          <div className="flex flex-row flex-wrap sm:flex-none p-0 col-12 justify-content-center ">
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-nav"
                onClick={(e) => {
                  e.preventDefault();
                  onDialogCancel();
                }}
              >
                <span className="ml-auto mr-auto ">Cancel</span>
              </Button>
            </div>
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-navActive"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  calculatePercentageAmout();
                  // handleFeesInput();
                }}
              >
                <span className="ml-auto mr-auto ">Calculate</span>
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <>
        <form>
          <div className="formgrid grid align-items-center">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Return Type * </label>
              <Dropdown
                name="returnType"
                value={formik.values.returnType}
                onChange={(e) => {
                  handleChange(e);
                }}
                options={returnTypeData === null ? [] : returnTypeData}
                optionLabel="value"
                optionValue="key"
                placeholder="Select return type"
                className={classNames({
                  "p-invalid": isFormFieldInvalid("returnType"),
                  "card-inputBox": true,
                })}
              />
              {getFormErrorMessage("returnType")}
              {productObjectByFilter?.equityInvestment?.toLowerCase() ===
                "yes" &&
                +formik?.values?.returnType === 1 && (
                  <>
                    <div>
                      <RiErrorWarningLine className="warningIcon" />
                      <span className="inputLabelNotification text-dark">
                        {" "}
                        Please check the earnings type you have selected. Your
                        investment is set as type equity which typically
                        generates equity cash distributions as income.
                      </span>
                    </div>
                  </>
                )}
              {productObjectByFilter?.equityInvestment?.toLowerCase() ===
                "no" &&
                +formik?.values?.returnType === 4 && (
                  <>
                    <div>
                      <RiErrorWarningLine className="warningIcon" />
                      <span className="inputLabelNotification text-dark">
                        {" "}
                        Please check the earnings type you have selected. Your
                        investment is set as type debt which typically generates
                        debt interest income.
                      </span>
                    </div>
                  </>
                )}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
              <label className="inputLabel">Amount * </label>
              <InputNumber
                name="amount"
                value={
                  formik.values.amount !== 0 ? formik.values.amount : undefined
                }
                //   formik.values.amount === 0
                //     ? undefined
                //     : formik.values.amount
                // }
                onValueChange={(e) => {
                  handleChange(e);
                  handlePerSetPercent(e.target.value);
                }}
                className={classNames({
                  "p-invalid ": isFormFieldInvalid("amount"),
                  "card-inputBox": true,
                  "single-border": true,
                })}
                // maxFractionDigits={2}
                mode="currency"
                currency="USD"
                locale="en-US"
                placeholder="Enter amount"
                // maxLength={20}
              />
              {getFormErrorMessage("amount")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-3">
              <label className="inputLabel"> </label>
              <Button
                className="btn-navActive"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenPercentageDailog(true);
                }}
              >
                Calculate Earnings by Percentage
              </Button>
            </div>
          </div>
          <div className="formgrid grid">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Return Date* </label>
              <Calendar
                name="returnDate"
                value={
                  formik.values.returnDate === "" ||
                  formik.values.returnDate === null
                    ? undefined
                    : new Date(formik.values.returnDate)
                }
                onChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("returnDate"),
                  "card-inputBox": true,
                })}
                placeholder="mm/dd/yyyy"
                readOnlyInput
              />
              {getFormErrorMessage("returnDate")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">
                Equity Cash Distribution Allocation Date{" "}
              </label>
              <Calendar
                name="allocationDate"
                value={
                  formik.values.allocationDate === "" ||
                  formik.values.allocationDate === null
                    ? undefined
                    : new Date(formik.values.allocationDate)
                }
                onChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("allocationDate"),
                  "card-inputBox": true,
                })}
                placeholder="mm/dd/yyyy"
                readOnlyInput
              />
            </div>
          </div>
          <div className="formgrid grid ">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Pre Set Percentage (%) </label>
              <InputNumber
                name="managerFeesPreSet"
                value={
                  formik.values.managerFeesPreSet !== 0
                    ? formik.values.managerFeesPreSet
                    : undefined
                }
                //   formik.values.amount === 0
                //     ? undefined
                //     : formik.values.amount
                // }
                onValueChange={(e) => {
                  handleChange(e);
                  const percent: any = e.target.value;
                  const fees = (formik.values.amount * 1 * percent) / 100;
                  formik.setFieldValue("managerFees", fees);
                  formik.setFieldValue(
                    "investedEarning",
                    formik.values.amount - fees
                  );
                }}
                className={classNames({
                  "p-invalid ": isFormFieldInvalid("managerFeesPreSet"),
                  "card-inputBox": true,
                  "single-border": true,
                })}
                // maxFractionDigits={2}
                placeholder="Pre  Set Percentage (%)"
                // maxLength={20}
              />
              {getFormErrorMessage("managerFeesPreSet")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Manager Fees </label>
              <InputNumber
                name="managerFees"
                value={
                  formik.values.managerFees !== 0
                    ? formik.values.managerFees
                    : undefined
                }
                //   formik.values.amount === 0
                //     ? undefined
                //     : formik.values.amount
                // }
                onValueChange={(e) => {
                  handleChange(e);
                  const updateVal: any = e.target.value;
                  formik.setFieldValue(
                    "investedEarning",
                    formik.values.amount - updateVal
                  );
                }}
                className={classNames({
                  "p-invalid ": isFormFieldInvalid("managerFees"),
                  "card-inputBox": true,
                  "single-border": true,
                })}
                // maxFractionDigits={2}
                mode="currency"
                currency="USD"
                locale="en-US"
                placeholder="Enter Manager Fees"
                // maxLength={20}
              />
              {getFormErrorMessage("managerFees")}
            </div>
          </div>
          <div className="formgrid grid ">
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6 ">
              <label className="inputLabel">Are Manager Fees Reinvested</label>
              <div className="d-flex mt-2">
                <div className="flex align-items-center pr-4">
                  <RadioButton
                    inputId="isReinvest"
                    name="isReinvest"
                    value={false}
                    onChange={(e) => handleChange(e)}
                    checked={formik.values.isReinvest === false}
                  />

                  <label htmlFor="isReinvest" className="ml-2 mb-0">
                    No
                  </label>
                </div>
                <div className="flex align-items-center pr-4">
                  <RadioButton
                    inputId="isReinvest"
                    name="isReinvest"
                    value={true}
                    onChange={(e) => handleChange(e)}
                    checked={formik.values.isReinvest === true}
                  />

                  <label htmlFor="isReinvest" className="ml-2 mb-0">
                    Yes
                  </label>
                </div>
              </div>{" "}
              {getFormErrorMessage("isReinvest")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
              <label className="inputLabel">Investor Earnings </label>
              <InputNumber
                name="investedEarning"
                value={
                  formik.values.investedEarning !== 0
                    ? formik.values.investedEarning
                    : undefined
                }
                //   formik.values.amount === 0
                //     ? undefined
                //     : formik.values.amount
                // }
                onValueChange={(e) => handleChange(e)}
                className={classNames({
                  "p-invalid ": isFormFieldInvalid("investedEarning"),
                  "card-inputBox": true,
                  "single-border": true,
                })}
                // maxFractionDigits={2}
                mode="currency"
                currency="USD"
                locale="en-US"
                placeholder="Invested Earning"
                // maxLength={20}
              />
              {getFormErrorMessage("investedEarning")}
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4 mb-3">
            <Button
              className="mr-4 btn-nav w-7rem justify-content-center"
              onClick={(e) => {
                e.preventDefault();
                onReset();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn-nav w-7rem justify-content-center"
              onClick={(e) => {
                e.preventDefault();
                // handleChangeState();
                formik.handleSubmit();
              }}
            >
              Save
            </Button>
          </div>
        </form>
      </>
    </>
  );
}
