import {
  IAddBankWithPlaidRequestBody,
  ICheckACHOTPSParameter,
  IDeleteBankAccountPayLoad,
  ILegalEntityAndPartnerID,
  IPostFundPrimaryAccountPayload,
  ISendACHOTPSParameter,
} from "../../types";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";

export function GetFundListService(data: any) {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/DwollaACH/GetCustomer?Input=${
      data.Input
    }&LegalEntityId=${data.LegalEntityId}`
  );
}

export function GetDwollaReportsService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/DwollaACH/GetInvestorDowllaCustomerReports?LegalEntityId=${
      data.LegalEntityId
    }&Input=${data.Input}`
  );
}
export function GetDwollaReportsWebhooksService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/DwollaACH/GetWebhooksDetails?Id=${data.Id}`
  );
}

export function GetDwollaTokenThunkService() {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/DwollaACH/GetDwollaToken`
  );
}

export async function getWirelessTransactionService(
  data: ILegalEntityAndPartnerID
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/Plaid/GetDwollaTransactions?LegalEntityId=${
      data.legalEntityID
    }&InvestorId=0`
  );
}

export async function getPlaidLinkTokenService() {
  console.log("services called");
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/Plaid/GetPlaidLinkToken`
  );
}

export async function sendACHOTPService(data: ISendACHOTPSParameter) {
  const email = data.email.replace("+", "%2B");

  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/Plaid/SendOTP?EmailId=${email}&LegalEntityId=${
      data.legalEntityID
    }&UserId=${data.userID}`
  );
}
export async function GetFromFundListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/DwollaACH/GetFundTransfertoGPLegalEntity?Type=${
      data.Type
    }&LegalEntityId=${data.legalEntityID}&UserId=${data.UserId}`
  );
}

export async function GetToFundListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/DwollaACH/GetDwollaToTransferList?Type=${data.Type}&TransferFrom=${
      data.TransferFrom
    }`
  );
}

export async function CanceltransferService(data: any) {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/DwollaACH/CancelTransfer?Input=${
      data.Input
    }&LegalEntityId=${data.LegalEntityId}`
  );
}

export function GetDwollaCustomerInfoService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/DwollaACH/GetCustomerDetailsById`,
    data
  );
}
export function FundTransferDwollaCInfoService(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/DwollaACH/TransferMoneyFundToGP`,
    data
  );
}

export function DeleteFundService(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/DwollaACH/DeleteCustomer`,
    data
  );
}

export async function postFundPrimaryAccount(
  data: IPostFundPrimaryAccountPayload
) {
  return apiFactory().put(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/Plaid/UpdatePrimaryAccount`,
    data
  );
}

export async function postDeleteBankAccountService(
  data: IDeleteBankAccountPayLoad
) {
  return apiFactory().delete(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/Plaid/DeleteBankAccount?Id=${
      data.Id
    }&DwollaAccountID=${data.DwollaAccountID}&CustomerId=${data.CustomerId}`
  );
}

export function SaveDwollaCustomerInfoService(data: any) {
  console.log("Services", data);
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/DwollaACH/CreateNewCustomer`,
    data
  );
}

export function SaveDwollaOwnerInfoInfoService(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/DwollaACH/CreateNewBeneficialOwners`,
    data
  );
}

export function GetFundOwnersListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/DwollaACH/GetBeneficialOwnerAccount?Input=${data.Input}&LegalEntityId=${
      data.LegalEntityId
    }`
  );
}
export function UpdateCertiOwnersIdThunkService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/DwollaACH/CertifyBeneficialOwners?Id=${data.id}`
  );
}

export function DeleteFundOwnersListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/DwollaACH/DeleteBeneficialOwners?Id=${data.id}`
  );
}

export function GetFundsBankListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/DwollaACH/GetFundBankAccList?InvOrEntityID=${
      data.InvOrEntityID
    }&AvCustomerType=${data.AvCustomerType}`
  );
}

export async function checkOTPACHService(data: ICheckACHOTPSParameter) {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/Plaid/validateOTP?OTP=${
      data.OTP
    }&UserId=${data.UserId}`
  );
}

export async function addBankWithPlaidService(
  metaData: IAddBankWithPlaidRequestBody
) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/Plaid/AddBankWithPlaid`,
    metaData
  );
}

export const getDocumentDwollaCustomerService = (data: number) => {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_FINANCE_URL
    }/DwollaACH/GetDocumentDwollaCustomer?LegalEntityId=${data}`
  );
};

export const getDwollaDocumentTypeService = () => {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/DwollaACH/GetDwollaDocumentType`
  );
};

export async function uploadDwollaDocumentsService(metaData: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_FINANCE_URL}/DwollaACH/UploadDwollaDocuments`,
    metaData
  );
}
