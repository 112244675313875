import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AddInputForm1099Service,
  DeleteInputForm1099Service,
} from "../../../../../../services/taxmanagement/tax";
import error from "../../../../../../utils/error";
import { IAddInputForm1099State } from ".";

const initialState: IAddInputForm1099State = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    id: 0,
    legalEntityId: 0,
    taxYear: "",
    supplierId: 0,
    box1Amount: 0,
    box15State: "",
    active: true,
    isAllocatedToGP: false,
  },
};
export const addThunkInputForm1099 = createAsyncThunk(
  "addinputform1099/add",
  async (data: any) => {
    try {
      const response = await AddInputForm1099Service(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const deleteThunkInputForm1099 = createAsyncThunk(
  "inputform1099/delete",
  async (data: any) => {
    try {
      const response = await DeleteInputForm1099Service(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "addinputform1099",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(addThunkInputForm1099.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(addThunkInputForm1099.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("set", action.payload);
      if (action.payload._response.data !== null) {
        state.data = action.payload._response.data.allocateInvestor;
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;
    });
    builder.addCase(addThunkInputForm1099.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });

    builder.addCase(deleteThunkInputForm1099.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(deleteThunkInputForm1099.fulfilled, (state, action) => {
      state.loading = "succeeded";
      // console.log("set", action.payload);
    });
    builder.addCase(deleteThunkInputForm1099.rejected, (state, action) => {
      state.loading = "failed";
      // console.log("errorslice", action.error);
      state.error = error(action.error);
    });
  },
});
export default slice.reducer;
