import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";

// import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";

import { classNames } from "primereact/utils";
import React, {
  useEffect,
  useRef,
  useState,
  // useState
} from "react";
// import { NavLink } from "react-router-dom";

// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import DataTableComponent from "../../../components/data-table/DataTableComponent";

import Loader from "../../../components/loader/Loader";
// import { GetNonRegisteredInvestorListThunk } from "../../../store/Investor/setupinvestorfees/getnonregisteredinvestorlist/slice";
import { getThunkPortfolioRegisterInvestorListDropdown } from "../../../store/features/dropdown/registeredinvestorlist/slice";
import { GetK1GenDetailsThunk } from "../../../store/features/taxmanagement/generation/getk1gendetails/slice";
import { SaveFinakK1GenerationThunk } from "../../../store/features/taxmanagement/generation/savegeneration/savefinakk1generation/slice";
import { GetSummaryDetailsThunk } from "../../../store/features/taxmanagement/summaryreportbypartner/getsummarydetails/slice";
import { getThunkUploadK1RevisionDropdown } from "../../../store/features/taxmanagement/upload-investork1/revision-dropdown/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { exportExcel } from "../../../utils/sponsor/helper";
import { successToastMessage } from "../../../utils/toast-message";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";
export default function Generation() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const previousYear: any = new Date().getFullYear();
  const currentYear = previousYear - 1;
  const genData1 = useAppSelector((state) => state.GetK1GenDetails.data1!);
  const genData2 = useAppSelector((state) => state.GetK1GenDetails.data2!);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const revisionList = useAppSelector((state) => state.revisionDropdown.data);

  const investorList = useAppSelector((state) => state.registerinvestor?.data);
  const [selectedPartnerName, setSelectedPartnerName] = useState("");
  const [selectedRevisionName, setRevisionName] = useState("");

  console.log("genData2", genData2);

  const list = useAppSelector((state) => state.GetSummaryDetails?.data2);
  const title = useAppSelector((state) => state.GetSummaryDetails?.data1);
  const data3 = useAppSelector((state) => state.GetSummaryDetails?.data3);

  const [dataCreateElemnts, setDataCreateElemnts] = useState<any>();
  const [dataTableElements, setdataTableElements] = useState<any>();
  const [stateName, setName] = useState<any>(false);
  const [loading, setloading] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);

  // const [dataFound, setDataFound] = useState<any>(true);

  console.log("Data3 here", data3);

  const datatableArrayObj: any = [];
  // const newData: any[] = []; // Declare an array to hold the new data

  const prepareData = (header: any, val: any, item: any, li: any) => {
    const values = val;
    const header2 = item;
    const header1 = li;
    console.log("prepareData values", values);
    console.log("prepareData header2", header2);
    console.log("prepareData header1", header1);
    console.log("header", header);
    if (values.length > 0 && header2.length > 0 && header1.length > 0) {
      console.log("values", values);
      // for (const valueObj of values) {
      //   const obj: any = {};
      //   console.log("valueObj", valueObj);
      //   // const itemsKey = Object.keys(valueObj);
      //   // for (const key in valueObj) {
      //   //   console.log("values", key);

      //   //   if (itemsKey.includes(key)) {
      //   //     // obj[items[key]?.InvName] = valueObj[key];
      //   //   }
      //   // }

      //   // datatableArrayObj.push(obj);
      // }

      return values;
    } else {
      return [];
    }
  };
  console.log("datatableArrayObj", datatableArrayObj);

  // const createcolumns = (data: any) => {
  //   console.log("data====", data); // Logging the prepared data for debugging

  //   return data.length > 0
  //     ? Object.keys(data[0]).map((key: string) => ({
  //         field: key,
  //         header: key,
  //       }))
  //     : [];
  // };
  const createcolumns = (val: any, item: any, li: any) => {
    const values = val;
    const header2 = item;
    const header1 = li;

    if (values.length > 0 && header2.length > 0 && header1.length > 0) {
      console.log("createcolumns data3 values", values);
      console.log("createcolumns title header2", header2);
      console.log("createcolumns list header1", header1);

      // const header1Keys = Object.keys(header1[0]).slice(3);

      const val1Keys: any = Object.values(values);

      // header1Keys.slice(0, 4);

      const hasOwnProperty = Object.prototype.hasOwnProperty;

      for (const obj of val1Keys) {
        try {
          // Check if the property 'Id' exists and is configurable
          if (hasOwnProperty.call(obj, "Id")) {
            const propertyDescriptor = Object.getOwnPropertyDescriptor(
              obj,
              "Id"
            );

            if (propertyDescriptor?.configurable) {
              delete obj.Id;
            } else {
              console.log("Property 'Id' is not configurable:", obj);
            }
          } else {
            console.log("Property 'Id' not found on object.");
          }
        } catch (error) {
          console.log("Error processing object:", error);
        }
      }

      // Uncomment this to log the objects after attempting deletion
      // console.log("Objects after deletion attempt", val1Keys);

      // console.log("header1Keys==", header1Keys);
      // const header2Keys = header2.flatMap((obj: any) => Object.values(obj));
      // console.log("val1Keys==", val1Keys);

      const headers = [
        "LableName",
        "ChildName",
        "Totals",
        // "LP Level",
        // "LPLevelVal",
        // ...header1Keys,
        // ...header2Keys,
      ];

      console.log("headers--abc", headers);

      const allData = headers.map((key: any) => ({
        field: key,
        header: key,
      }));
      // const
      // console.log("allData", allData);
      return allData;
    }
    return [];
  };

  useEffect(() => {
    if (data3?.length && title?.length && list?.length) {
      console.log("useEffect");
      const header = createcolumns(data3, title, list);
      setDataCreateElemnts(header);
      setdataTableElements(prepareData(header, data3, title, list));
      // setDataCreateElemnts(createcolumns(data3, title, list));
    } else {
      // Set to empty data
      setDataCreateElemnts([]);
      setdataTableElements([]);
    }
  }, [data3, title, list]);

  //   const [visible, setVisible] = useState(false);
  const formik = useFormik({
    initialValues: {
      Year: currentYear.toString(),
      Partner: "",
      Revision: "",
    },
    validate: (data: any) => {
      const errors: any = {};

      console.log(" validate", data);
      if (!data.Year) {
        errors.Year = "Please enter year";
      }
      if (!data.Partner) {
        errors.Partner = "Select investor";
      }
      if (!data.Revision) {
        errors.Revision = "Select revision";
      }

      return errors;
    },
    onSubmit: async () => {
      //
      setloading(true);
      console.log(" +formik.values.Revision", revisionList);
      const p = {
        LegalEntityId: fundDropdown.fundid,
        Year: formik.values.Year.toString(),
        PartnerId: +formik.values.Partner,
      };

      dispatch(GetK1GenDetailsThunk(p))
        .then(() => {
          // setVisible(true);
          const params = {
            LegalEntityId: fundDropdown.fundid,
            TaxYear: formik.values.Year.toString(),
            LPPartnerId: +formik.values.Partner,
          };
          //  const pdata = prepareData(datatableArrayObj)

          dispatch(GetSummaryDetailsThunk(params))
            .then(() => {
              setName(false);
              // setDataCreateElemnts(createcolumns(datatableArrayObj));
              // setdataTableElements(prepareData(datatableArrayObj));
              setloading(false);
            })
            .catch(() => {
              setloading(false);
            });
        })
        .catch(() => {
          setloading(false);
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  useEffect(() => {
    setName(true);
  }, [
    formik.values.Year.toString(),
    formik.values.Partner,
    formik.values.Revision,
  ]);

  useEffect(() => {
    if (fundDropdown.fundid) {
      const reqParam = {
        LegalEntityId: fundDropdown.fundid,
      };
      console.log("info");
      dispatch(getThunkPortfolioRegisterInvestorListDropdown(reqParam));
      setName(true);
      formik.resetForm();
    }
  }, [fundDropdown.fundid]);

  useEffect(() => {
    dispatch(getThunkUploadK1RevisionDropdown());
  }, []);

  // useEffect(() => {
  //   document.body.classList.add("no-scroll");
  //   document.body.style.overflowX = "unset";
  //   document.documentElement.style.overflowX = "unset";
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //     document.body.style.overflowX = "hidden";
  //     document.documentElement.style.overflowX = "hidden";
  //   };
  // }, []);

  const onSave = () => {
    const today = new Date();

    let genData1Value: any;
    let genData2Value: any;
    genData1 &&
      genData1!.map((item: any, i: any) => {
        if (i === 0) {
          genData1Value = item;
        }
        return item;
      });
    genData2!.map((r: any, i: any) => {
      const parts = selectedPartnerName.split("-");

      // Take the first part (before the hyphen)
      const result = parts[0].trim(); // Use trim to remove any leading/trailing spaces

      if (r.partnerName.includes(result)) {
        genData2Value = r;
      }
      return r;
    });

    console.log("genData1Value", genData1Value);
    console.log("genData2Value", genData2Value);

    const req = {
      id: +genData1Value ? +genData1Value.identifyingNumber : 0,
      legalEntityID: fundDropdown.fundid,
      year: formik.values.Year.toString(),
      investorId: +formik.values.Partner,
      revisionId: +formik.values.Revision,
      capitalAcc: genData2Value.bcb,
      capitalContri: genData2Value.cccy,
      yearIncDec: genData2Value.cyi,
      withdrawalsDis: genData2Value.wd,
      endingAccount: genData2Value.eca,
      deliveryDate: today,
      childData: JSON.parse(data3!),
    };
    dispatch(SaveFinakK1GenerationThunk(req)).then(() => {
      successToastMessage(toast, "Data Save Successfully!");
    });
  };
  console.log("DataTable Here", dataTableElements);
  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">K-1: Final K-1 Generation</h1>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            <Card>
              <>
                {" "}
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-2">
                    <label className="inputLabel">Year *</label>

                    <Calendar
                      name="Year"
                      value={
                        formik.values.Year === null || formik.values.Year === ""
                          ? undefined
                          : new Date(+formik.values.Year, 0, 1)
                      }
                      onChange={(e) => {
                        const selectedYear = new Date(
                          Number(e.target.value)
                        ).getFullYear();
                        formik.setFieldValue("Year", selectedYear);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("Year"),
                        "card-inputBox": true,
                      })}
                      view="year"
                      dateFormat="yy"
                      placeholder="Select year"
                    />
                    {getFormErrorMessage("Year")}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-4">
                    <label className="inputLabel">Partner Name *</label>
                    <Dropdown
                      name="Partner"
                      style={{ width: "100%" }}
                      options={investorList}
                      optionLabel="investorName"
                      optionValue="investorID"
                      placeholder="Partner Name"
                      onChange={(e) => {
                        const selectedInvestor = investorList!.find(
                          (investor: any) => investor.investorID === e.value
                        );

                        setSelectedPartnerName(
                          selectedInvestor ? selectedInvestor.investorName : ""
                        );
                        formik.setFieldValue("Partner", e.value); // Set the selected partner ID in the formik values
                      }}
                      value={formik.values.Partner}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("Partner"),
                        "card-inputBox": true,
                      })}
                    />
                    {getFormErrorMessage("Partner")}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-3">
                    <label className="inputLabel">Revision*</label>
                    <Dropdown
                      name="Revision"
                      style={{ width: "100%" }}
                      options={revisionList}
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select Revision"
                      onChange={(e) => {
                        formik.setFieldValue("Revision", e.value);
                        revisionList?.map((item: any) => {
                          return (
                            item.key === e.value && setRevisionName(item.value)
                          );
                        });
                      }}
                      value={formik.values.Revision}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("Revision"),
                        "card-inputBox": true,
                      })}
                    />
                    {getFormErrorMessage("Revision")}
                  </div>
                  <div className="d-flex justify-content-center col-3 flex align-items-center">
                    <Button
                      className="mr-4 btn-nav w-7rem justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.resetForm();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      className="btn-nav w-7rem justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        // setIsFormSubmitted(true);
                        console.log("clicked");
                        formik.handleSubmit();
                      }}
                    >
                      Show
                    </Button>
                  </div>
                </div>
              </>{" "}
            </Card>
          </div>
          {!stateName && (
            <div className="d-flex flex-column md:flex-row">
              {genData1 && (
                <div className=" my-3  col-12 md:col-6">
                  {genData1.map((item: any, i: any) => {
                    return (
                      i === 0 && (
                        <Card key={i} className="d-flex flex-column h-100">
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row col py-0">
                              <div className=" col-12 border-1 border-bottom-none">
                                <div className="py-2">
                                  <h5 className="mb-0 font-semibold">
                                    PART I: INFORMATION ABOUT THE PARTNER
                                  </h5>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row col py-0">
                              <div className=" col-6 border-1 border-bottom-none">
                                Partner`s Identifying Number{" "}
                              </div>
                              <div className=" col-6 border-1 border-bottom-none border-left-none">
                                {item.identifyingNumber}
                              </div>
                            </div>{" "}
                          </div>
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row col py-0">
                              <div className=" col-6 border-1 border-bottom-none">
                                Partner`s Name{" "}
                              </div>
                              <div className=" col-6 border-1 border-bottom-none border-left-none">
                                {item.partnerName}
                              </div>
                            </div>{" "}
                          </div>
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row col py-0">
                              <div className=" col-6 border-1 border-bottom-none">
                                Address, City, State & Zip code{" "}
                              </div>
                              <div className=" col-6 border-1 border-bottom-none border-left-none">
                                {item.address} {item.zipCode} {item.usState}
                              </div>
                            </div>{" "}
                          </div>{" "}
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row col py-0">
                              <div className=" col-6 border-1 border-bottom-none">
                                Partner Entity Type{" "}
                              </div>
                              <div className=" col-6 border-1 border-bottom-none border-left-none">
                                {item.accountType}
                              </div>
                            </div>{" "}
                          </div>{" "}
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row col py-0">
                              <div className=" col-6 border-1">
                                Revision Type{" "}
                              </div>
                              <div className=" col-6 border-1 border-left-none">
                                {formik.values.Revision && (
                                  <span>
                                    {/* {
                               revisionList!.find(
                                 () => formik.values.Revision
                               )?.value
                             } */}
                                    {selectedRevisionName}
                                  </span>
                                )}{" "}
                              </div>
                            </div>{" "}
                          </div>
                        </Card>
                      )
                    );
                  })}
                </div>
              )}
              {genData2 && (
                <div className=" my-3 col-12 md:col-6">
                  {genData2.map((item: any, i: any) => {
                    const parts = selectedPartnerName.split("-");

                    // Take the first part (before the hyphen)
                    const result = parts[0].trim(); // Use trim to remove any leading/trailing spaces

                    console.log(result);
                    console.log(
                      "  item.partnerName.includes(selectedPartnerName)",
                      item.partnerName.includes(result)
                    );

                    return (
                      item.partnerName.includes(result) && (
                        <Card key={i} className="d-flex flex-column h-100">
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row col py-0">
                              <div className=" col-12 border-1 border-bottom-none">
                                <div className="py-2">
                                  <h5 className="mb-0 font-semibold">
                                    PART II: L PARTNER’S CAPITAL ACCOUNT
                                    ANALYSIS
                                  </h5>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row col py-0">
                              <div className=" col-6 border-1 border-bottom-none">
                                Beginning Capital Account{" "}
                              </div>
                              <div className=" col-6 border-1 border-bottom-none border-left-none">
                                {item.bcb}
                              </div>
                            </div>{" "}
                          </div>
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row col py-0">
                              <div className=" col-6 border-1 border-bottom-none">
                                Capital Contributed during the Year{" "}
                              </div>
                              <div className=" col-6 border-1 border-bottom-none border-left-none">
                                {item.cccy}
                              </div>
                            </div>{" "}
                          </div>{" "}
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row col py-0">
                              <div className=" col-6 border-1 border-bottom-none">
                                Current Year Increase (decrease){" "}
                              </div>
                              <div className=" col-6 border-1 border-bottom-none border-left-none">
                                {item.cyi}
                              </div>
                            </div>{" "}
                          </div>{" "}
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row col py-0">
                              <div className=" col-6 border-1 border-bottom-none">
                                Withdrawals & Distributions{" "}
                              </div>
                              <div className=" col-6 border-1 border-bottom-none border-left-none">
                                {item.wd}
                              </div>
                            </div>{" "}
                          </div>{" "}
                          <div className="d-flex flex-row">
                            <div className="d-flex flex-row col py-0">
                              <div className=" col-6 border-1">
                                Ending Capital Account{" "}
                              </div>
                              <div className=" col-6 border-1 border-left-none">
                                {item.eca}
                              </div>
                            </div>{" "}
                          </div>
                        </Card>
                      )
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {dataTableElements && !stateName ? (
            <>
              {" "}
              <div className="card my-3 tabWithoutboxshadow">
                <Card>
                  <div className="flex">
                    <h5 className="mb-0 font-semibold flex align-items-center  md:col-10">
                      {" "}
                      PART III PARTNER&apos;S SHARE OF CURRENT YEAR INCOME,
                      DEDUCTIONS, CREDITS, AND OTHER ITEMS
                    </h5>
                    <div className=" col-12 md:col-2 gap-2 flex justify-content-end">
                      <Button
                        className="remove-shadow"
                        type="button"
                        // icon="pi pi-file"
                        // rounded
                        onClick={() =>
                          exportExcel(
                            dataTableElements,
                            "K-1: Final K-1 Generation"
                          )
                        }
                        data-pr-tooltip="exportExcel"
                        disabled={
                          dataTableElements === undefined ||
                          dataTableElements?.length === 0 ||
                          dataTableElements === null
                        }
                      >
                        <span className="blueButtonShadowText">Download </span>
                      </Button>
                      <div className="flex align-items-center mb-2">
                        <Button
                          className="btn-navActive"
                          type="button"
                          icon="pi pi-window-maximize"
                          disabled={
                            dataTableElements === undefined ||
                            dataTableElements?.length === 0 ||
                            dataTableElements === null
                          }
                          onClick={() => setOpenDailog(true)}
                          title="View Full Screen"
                        />
                      </div>
                    </div>
                  </div>

                  <>
                    {" "}
                    <div className="mb-4">
                      {/* <div className="mt-2"> */}
                      <DataTableComponent
                        valueData={dataTableElements}
                        fieldsElements={dataCreateElemnts}
                        // footerElement={footerGroup}
                        // isDownload={true}
                        isPaginationAllowed={true}
                        scrollable={true}
                        className="gridcell"
                        columnResizeMode="expand"
                        maxHeight={"calc(-250px + 100vh)"}
                        isFullView={true}
                        setOpenDailog={setOpenDailog}
                        openDailog={openDailog}
                      />
                      {/* </div> */}
                    </div>
                    <div className="d-flex justify-content-center mb-3 mt-4">
                      <Button
                        className="btn-nav w-7rem justify-content-center"
                        onClick={onSave}
                      >
                        Save
                      </Button>
                    </div>
                  </>
                </Card>
              </div>
            </>
          ) : (
            data3 === null && (
              <div className="card my-3 tabWithoutboxshadow">
                <Card>
                  <h5 className="mb-0 font-semibold">
                    {" "}
                    PART III PARTNER&apos;S SHARE OF CURRENT YEAR INCOME,
                    DEDUCTIONS, CREDITS, AND OTHER ITEMS
                  </h5>

                  <>
                    {" "}
                    <div className="mb-4">
                      <div className="mt-2">
                        <div className="mt-3 p-0 pagination d-flex justify-content-center">
                          <div className=" mt-3 p-0 pagination ml-4">
                            <h3>No Data Found</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </Card>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}
