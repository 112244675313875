import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { GetAllocatedInvestorService } from "../../../../../services/operation/operation";
import error from "../../../../../utils/error";
import { IGetAllocatedInvestorListState } from ".";

const initialState: IGetAllocatedInvestorListState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    output: [],
    investmentData: undefined,
  },
};

export const getThunkAllocatedInvestorListData = createAsyncThunk(
  "getAllocatedInvestorListData",
  async (data: any) => {
    try {
      const response = await GetAllocatedInvestorService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getAllocatedInvestorListData",
  initialState,
  reducers: {
    setAllocatedInvestorState: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder): void {
    builder.addCase(getThunkAllocatedInvestorListData.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getThunkAllocatedInvestorListData.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        console.log("investorList", action.payload._response);
        state.data =
          action.payload._response.data !== null
            ? action.payload._response.data
            : {
                output: [],
                investmentData: undefined,
              };
        console.log("im", state.data);
      }
    );
    builder.addCase(
      getThunkAllocatedInvestorListData.rejected,
      (state, action) => {
        state.loading = "failed";
        //   // action.payload contains error information
        state.error = error(action.error);
        throw new Error(error(state.error));
      }
    );
  },
});

export const { setAllocatedInvestorState } = slice.actions;
export default slice.reducer;
