import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
// import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";

import { ProgressBar } from "primereact/progressbar";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";
import React, { useEffect, useState } from "react";
import "../CreateInvestment.scss";
// import { getThunkLegalEntity } from "../../../../../store/features/dropdown/legal-entity/slice";
import { NavLink, useNavigate } from "react-router-dom";
// import { getThunkSupplier } from "../../../../../store/features/dropdown/supplier/slice";
// import { initialInvestmentState } from "../../../../../store/features/investment/create/payload";
import Loader from "../../../../../components/loader/Loader";
import { setForm } from "../../../../../store/features/investment/create/slice";
import { getSupplierListThunk } from "../../../../../store/features/investment/getsupplierlist/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
interface IProps {
  handleNextInvestment?: any;
}

// const onlyFirstTime = true;
export const CreateNewOffering = (props: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const legalEntity = useAppSelector((state) => state.legalEntity.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  // const supplier = useAppSelector((state) => state.supplierList.data);
  const stateInvestment = useAppSelector((state) => state.addInvestment);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const [loading, setLoading] = useState<any>(false);
  const GetSupplierList = useAppSelector(
    (state) => state.GetSupplierList?.data
  );
  // console.log("test", GetSupplierList);
  const formik = useFormik({
    initialValues: {
      // legalEntityID: 0,
      supplierID: 0,
      productState: "",
    },
    validate: (data) => {
      //
      const errors: any = {};
      // if (!data.legalEntityID) {
      //   errors.legalEntityID = "Select a Fund";
      // }
      if (!data.supplierID) {
        errors.supplierID = "Select a supplier";
      }
      if (!data.productState) {
        errors.productState = "Select a offering";
      }
      return errors;
    },
    onSubmit: (data) => {
      console.log(data);
      dispatch(
        setForm({
          ...stateInvestment.data,
          productState: formik.values.productState,
          legalEntityID: fundDropdown.fundid,
          supplierID: formik.values.supplierID,
          loginId: profileData.userID,
        })
      );
      props.handleNextInvestment();
      // formik.values.productState === "PreFunded" ? (
      //   props.handleNextInvestment()
      // ) : formik.values.productState === "SoftCommit" ? (
      //   props.handleNextDeal()
      // ) : (
      //   <></>
      // );
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  // const legalEntityDropdownList: any = [];
  // legalEntity?.map((item: any) => {
  //   //
  //   return legalEntityDropdownList.push({
  //     name: item.legalEntityName,
  //     code: item.legalEntityID,
  //   });
  // });
  const supplierDropdownList: any = [];

  GetSupplierList?.map((item: any) => {
    //
    return supplierDropdownList.push({
      name: item.supplierName,
      code: item.supplierID,
    });
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    console.log("on 4 useEffect");
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      setLoading(true);
      const parameters = {
        LegalEntityId: fundDropdown.fundid,
      };
      // dispatch(getThunkSupplier(parameters));
      dispatch(getSupplierListThunk(parameters)).finally(() => {
        setLoading(false);
      });
    }
  }, [fundDropdown.fundid]);

  useEffect(() => {
    // console.log("on 6 useEffect", stateInvestment);
    // console.log("1232", onlyFirstTime, stateInvestment.data?.productID);
    // if (onlyFirstTime === true) {
    //   console.log("inside if");
    //   formik.setFieldValue("supplierID", "");
    //   formik.setFieldValue("productState", "");
    //   onlyFirstTime = false;
    // } else {
    // formik.setFieldValue("legalEntityID", stateInvestment.data?.legalEntityID);
    formik.setFieldValue("supplierID", stateInvestment.data?.supplierID);
    formik.setFieldValue("productState", stateInvestment.data?.productState);
    // }
  }, [stateInvestment]);
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <div>
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader"></h1>
          </div>
        </div>
        <div className=" h-100 center-card">
          <div className="col-12 " style={{ width: "80%" }}>
            <div className="card my-3">
              <Card>
                <div className="headercard d-flex grid">
                  <div className="col-12 md:col-7">
                    <h2 style={{ fontWeight: "bold" }}>Create New Offering</h2>
                  </div>
                  <div className="col-12 md:col-5 end-div">
                    <h6>Offering Setup - Step 1</h6>
                  </div>
                </div>
                <div>
                  <ProgressBar
                    value={14}
                    showValue={false}
                    className="m-2"
                  ></ProgressBar>
                </div>

                <form>
                  <div>
                    <div className="formgrid grid px-2">
                      {/* <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                        <label className="card-subheader">Select Fund</label>
                        <div>
                          <Dropdown
                            name="legalEntityID"
                            value={formik.values.legalEntityID}
                            onChange={(e) => {
                              formik.setFieldValue("legalEntityID", e.value);
                              handleChange(e);
                            }}
                            options={legalEntityDropdownList}
                            optionLabel="name"
                            optionValue="code"
                            placeholder="Select fund from dropdown"
                            // className=" w-full"
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("legalEntityID"),
                              "card-inputBox": true,
                            })}
                          />
                          {getFormErrorMessage("legalEntityID")}
                        </div>
                      </div> */}
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                        <label className="card-subheader">
                          Select Supplier
                        </label>
                        <div>
                          <Dropdown
                            name="supplierID"
                            value={formik.values.supplierID}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            options={
                              supplierDropdownList === null
                                ? []
                                : supplierDropdownList
                            }
                            filter
                            optionLabel="name"
                            optionValue="code"
                            placeholder="Select supplier from dropdown"
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("supplierID"),
                              "card-inputBox": true,
                            })}
                          />
                          {getFormErrorMessage("supplierID")}
                        </div>
                        {/* <label className="card-subheader mt-2">
                          Don&apos;t see your supplier? Set it up first here.
                        </label> */}
                        <NavLink
                          to="/ManageFundSupplier"
                          className="card-subheader link-supplier mt-2"
                        >
                          Don&apos;t see your supplier? Set it up first here.{" "}
                        </NavLink>
                      </div>
                      <div className="d-flex flex-column p-2 col-12">
                        <label className="inputLabel">
                          Is this offering a deal or an investment?
                        </label>
                        <div className="d-flex mt-2">
                          <div className="flex align-items-center pr-4">
                            <div className="my-3 test-class">
                              <RadioButton
                                inputId="productState"
                                name="productState"
                                value="SoftCommit"
                                onChange={(e) => handleChange(e)}
                                checked={
                                  formik.values.productState === "SoftCommit"
                                }
                              />

                              <label
                                // htmlFor="productState"
                                className="ml-2 test-class-2 mb-0 d-inline"
                              >
                                Deal (Fund is looking to raise capital through
                                soft commits).
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-2">
                          <div className="flex align-items-center pr-4">
                            <div className="my-3 test-class">
                              <RadioButton
                                inputId="productState"
                                name="productState"
                                value="PreFunded"
                                onChange={(e) => handleChange(e)}
                                checked={
                                  formik.values.productState === "PreFunded"
                                }
                              />

                              <label
                                // htmlFor="productState"
                                className="ml-2 test-class-2 mb-0 d-inline"
                              >
                                Investment (Fund has already invested capital in
                                this oppurtunity).
                              </label>
                            </div>
                          </div>
                        </div>
                        {getFormErrorMessage("productState")}
                      </div>
                      <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
                        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                          <Button
                            className="btn-nav btnFocus"
                            // tabIndex={0}

                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/ManageOffering");
                            }}
                          >
                            <span className="ml-auto mr-auto ">Cancel</span>
                          </Button>
                        </div>
                        <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                          <Button
                            className="btn-dialog btnFocus"
                            type="submit"
                            // tabIndex={1}
                            onClick={(e) => {
                              e.preventDefault();
                              formik.handleSubmit();
                            }}
                          >
                            <span className="ml-auto mr-auto ">Next</span>
                          </Button>
                          <label className="inputLabel">&nbsp;</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
