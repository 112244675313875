import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getViewSoftCommitListService } from "../../../../services/view-gather-softcommit/view-gather-softcommit";
import error from "../../../../utils/error";
import { IViewSoftCommitListState } from ".";

const initialState: IViewSoftCommitListState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    softCommits: [],
    total: 0,
  },
};
export const getThunkViewSoftCommit = createAsyncThunk(
  "ViewSoftCommit/get",
  async (data: any) => {
    try {
      const response = await getViewSoftCommitListService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "ViewSoftCommit",
  initialState,
  reducers: {
    setViewSoftCommitState: (state, action) => {
      state.data.softCommits = action.payload.data.softCommits;
      state.data.total = action.payload.data.total;
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkViewSoftCommit.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkViewSoftCommit.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log(action.payload);
      if (action.payload.data !== null) {
        state.data.softCommits = action.payload.data.softCommits;
        state.data.total = action.payload.data.total[0].total;
      } else {
        state.data.softCommits = [];
        state.data.total = 0;
      }
      state.message = action.payload.message;
    });
    builder.addCase(getThunkViewSoftCommit.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export const { setViewSoftCommitState } = slice.actions;

export default slice.reducer;
