import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllDropDownListService } from "../../../../services/dropdown/dropdown";
import error from "../../../../utils/error";
import { IDropdownState } from ".";

const initialState: IDropdownState = {
  success: false,
  message: "",
  loading: "loading",
  data: {
    productDetails: [],
    stateDetails: [],
    assetClassDetails: [],
    feeType: [],
    supplierCode: [],
    dealHealth: [],
    investmentLength: [],
    equityInvestment: [],
    softCommmitProductDetails: [],
    softCommmitSupplierProductCode: [],
    city: [],
    zipcode: [],
    address: [],
    earningsPayoutTiming: [],
    documentsTypes: [],
    mediaTypes: [],
    earningsPayouttoFund: [],
    investorEarningsAccurual: [],
    investorwithdrawalsAccurual: [],
  },
};
export const getThunkDropdown = createAsyncThunk(
  "dropdownlist",
  async (data: any) => {
    try {
      const response = await getAllDropDownListService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "dropdownlist",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkDropdown.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkDropdown.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload._response.data;
    });
    builder.addCase(getThunkDropdown.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
