import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDocumentDwollaCustomerService } from "../../../services/Dwolla/dwolla";
import error from "../../../utils/error";
import { DwollCustomerInitType } from "./type";

const initialState: DwollCustomerInitType = {
  success: false,
  message: "",
  loading: "loading",
  data: [],
};
export const getDocumentDwollaCustomerThunk = createAsyncThunk(
  "dwollaCompo/DocumentDwollaCustomer",
  async (data: any) => {
    try {
      const response = await getDocumentDwollaCustomerService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "dwollaCompo",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getDocumentDwollaCustomerThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      getDocumentDwollaCustomerThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";

        state.data =
          action.payload.data !== null
            ? action.payload.data
            : initialState.data;
      }
    );
    builder.addCase(getDocumentDwollaCustomerThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
