import { useFormik } from "formik";
import { Button } from "primereact/button";

import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { ProgressBar } from "primereact/progressbar";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../../components/loader/Loader";
import {
  addThunkInvestment,
  setForm,
} from "../../../../../store/features/investment/create/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  convertLocalDateToUTC,
  convertUTCToLocalDate,
} from "../../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../../utils/toast-message";

interface IProps {
  handlePrevious: any;
  handleNext: any;
  offering: any;
}
export const CreateStep4 = (props: IProps) => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const stateInvestment = useAppSelector((state) => state.addInvestment);
  const formik = useFormik({
    initialValues: {
      targetRaiseAmt: undefined,
      targetCloseDate: "",
      excludeFromMarketing: undefined,
      showRemainingAllocation: undefined,
    },
    validate: (data) => {
      //
      const errors: any = {};
      if (stateInvestment.data?.productState === "SoftCommit") {
        if (!data.targetRaiseAmt) {
          errors.targetRaiseAmt = "Target raise amount is required";
        } else if (!/^[+-]?(\d*\.)?\d+$/.test(data.targetRaiseAmt)) {
          errors.targetRaiseAmt = "Target raise amount should be a number";
        }

        if (!data.targetCloseDate) {
          errors.targetCloseDate = "Target close date is required";
        }
        if (data.targetRaiseAmt && data.targetRaiseAmt > 20_000_000_000) {
          errors.targetRaiseAmt =
            "The target raise amount should be less than equal to $20B";
        }
      }
      // if (!data.excludeFromMarketing) {
      //   errors.excludeFromMarketing = "Select a supplier";
      // }
      // if (!data.showRemainingAllocation) {
      //   errors.showRemainingAllocation = "Select a supplier";
      // }

      return errors;
    },
    onSubmit: async (data) => {
      setLoading(true);
      await dispatch(addThunkInvestment(stateInvestment.data!))
        .then((response) => {
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (
            response.meta.requestStatus === "fulfilled" &&
            response.payload.data.productDetails[0].productID !== 0
          ) {
            successToastMessage(toast, stateInvestment.message);
            props.handleNext();
          }
        })
        .catch((error: any) => {
          console.log("error", error);
          errorToastMessage(toast);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const handleChangeState = () => {
    const targetCloseDate = convertLocalDateToUTC(
      formik.values.targetCloseDate
    );

    dispatch(
      setForm({
        ...stateInvestment.data,
        targetRaiseAmt: formik.values.targetRaiseAmt,
        targetCloseDate,
        showRemainingAllocation: formik.values.showRemainingAllocation,
        excludeFromMarketing: formik.values.excludeFromMarketing,
      })
    );
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    formik.setFieldValue(
      "targetRaiseAmt",
      stateInvestment.data?.targetRaiseAmt
    );
    formik.setFieldValue(
      "targetCloseDate",
      stateInvestment.data?.targetCloseDate === null ||
        stateInvestment.data?.targetCloseDate === ""
        ? stateInvestment.data?.targetCloseDate
        : convertUTCToLocalDate(stateInvestment.data?.targetCloseDate!)
    );
    formik.setFieldValue(
      "excludeFromMarketing",
      stateInvestment.data?.excludeFromMarketing
    );
    formik.setFieldValue(
      "showRemainingAllocation",
      stateInvestment.data?.showRemainingAllocation
    );
    dispatch(setForm({ ...stateInvestment.data, isFinalstep: false }));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <div>
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader"></h1>
          </div>
        </div>
        <div className=" h-100 center-card">
          <div className="col-12 " style={{ width: "80%" }}>
            <div className="card my-3">
              <Card>
                <div className="headercard d-flex grid">
                  <div className="col-12 md:col-6">
                    <h2 style={{ fontWeight: "bold" }}>
                      Create New {props.offering}
                    </h2>
                  </div>
                  <div className="col-12 md:col-6 end-div">
                    <h6>Raise Info - Step 4</h6>
                  </div>
                </div>
                <div>
                  <ProgressBar
                    value={56}
                    showValue={false}
                    className="m-2"
                  ></ProgressBar>
                </div>

                <form>
                  <div className="mt-4">
                    {stateInvestment?.data?.productState !== "SoftCommit" ? (
                      <></>
                    ) : (
                      <div className="formgrid grid px-2">
                        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                          <label className="inputLabel">
                            How much are you trying to raise?
                          </label>
                          <InputNumber
                            name="targetRaiseAmt"
                            value={
                              formik.values.targetRaiseAmt === 0
                                ? undefined
                                : formik.values.targetRaiseAmt
                            }
                            onValueChange={(e) => handleChange(e)}
                            // maxLength={23}
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            // maxFractionDigits={2}
                            className={classNames({
                              "p-invalid": isFormFieldInvalid("targetRaiseAmt"),
                              "w-100": true,
                            })}
                            placeholder="Enter $ amount"
                          />
                          {getFormErrorMessage("targetRaiseAmt")}
                        </div>
                        <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-6">
                          <label className="inputLabel">
                            What is the target close date?
                          </label>

                          <Calendar
                            name="targetCloseDate"
                            value={
                              formik.values.targetCloseDate === "" ||
                              formik.values.targetCloseDate === null
                                ? undefined
                                : new Date(formik.values.targetCloseDate)
                            }
                            onChange={(e) => handleChange(e)}
                            className={classNames({
                              "p-invalid":
                                isFormFieldInvalid("targetCloseDate"),
                              "card-inputBox": true,
                            })}
                            placeholder="Select Target close Date"
                            readOnlyInput
                          />

                          {getFormErrorMessage("targetCloseDate")}
                        </div>
                      </div>
                    )}
                    <div className="d-flex flex-column p-2 col-12">
                      <label className="inputLabel">
                        Make offering visible on opportunities page?
                      </label>
                      <div className="d-flex mt-2">
                        <div className="flex align-items-center pr-4">
                          <RadioButton
                            inputId="excludeFromMarketing"
                            name="excludeFromMarketing"
                            value={false}
                            onChange={(e) => handleChange(e)}
                            checked={
                              formik.values.excludeFromMarketing === false
                            }
                          />

                          <label
                            htmlFor="excludeFromMarketing"
                            className="ml-2 mb-0"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="flex align-items-center pr-4">
                          <RadioButton
                            inputId="excludeFromMarketing"
                            name="excludeFromMarketing"
                            value={true}
                            onChange={(e) => handleChange(e)}
                            checked={
                              formik.values.excludeFromMarketing === true
                            }
                          />

                          <label
                            htmlFor="excludeFromMarketing"
                            className="ml-2 mb-0"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      {getFormErrorMessage("excludeFromMarketing")}
                    </div>
                    {stateInvestment?.data?.productState !== "SoftCommit" ? (
                      <div className="d-flex flex-column p-2 col-12">
                        <label className="inputLabel">
                          {" "}
                          Show remaining allocation to investors?
                        </label>
                        <div className="d-flex mt-2">
                          <div className="flex align-items-center pr-4">
                            <RadioButton
                              inputId="showRemainingAllocation"
                              name="showRemainingAllocation"
                              value={true}
                              onChange={(e) => handleChange(e)}
                              checked={
                                formik.values.showRemainingAllocation === true
                              }
                            />

                            <label
                              htmlFor="showRemainingAllocation"
                              className="ml-2 mb-0"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="flex align-items-center pr-4">
                            <RadioButton
                              inputId="showRemainingAllocation"
                              name="showRemainingAllocation"
                              value={false}
                              onChange={(e) => handleChange(e)}
                              checked={
                                formik.values.showRemainingAllocation === false
                              }
                            />

                            <label
                              htmlFor="showRemainingAllocation"
                              className="ml-2 mb-0"
                            >
                              No
                            </label>
                          </div>
                        </div>
                        {getFormErrorMessage("showRemainingAllocation")}
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="flex flex-row flex-wrap sm:flex-none  col-12 ">
                      <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                        <Button
                          className="btn-nav btnFocus"
                          onClick={(e) => {
                            e.preventDefault();
                            props.handlePrevious();
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth", // Smooth scrolling
                            });
                          }}
                        >
                          <span className="ml-auto mr-auto ">Back</span>
                        </Button>
                      </div>
                      <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
                        <Button
                          className="btn-dialog btnFocus "
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            handleChangeState();
                            formik.handleSubmit();
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth", // Smooth scrolling
                            });
                          }}
                        >
                          <span className="ml-auto mr-auto ">Next</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
