import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    offering: "SoftCommit",
    pageName: "OfferingInfo",
    subPageName: "",
    openDialog: false,
    createPage: false,
    clickPageName: "",
    isReloadCardPage: false,
  },
};

const slice = createSlice({
  name: "updatePage",
  initialState,
  reducers: {
    setPageName: (state, action) => {
      console.log(" as", action.payload);
      state.data = action.payload;
      //
    },
  },
});
export const { setPageName } = slice.actions;
export default slice.reducer;
