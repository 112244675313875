import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestorsinFundReportService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";
import { IInvestorsinFundParameter, IInvestorsinFundReportState } from ".";

const initialState: IInvestorsinFundReportState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
  totalCount: 0,
};
export const getInvestorsinFundCommits = createAsyncThunk(
  "investorsinFundReport",
  async (data: IInvestorsinFundParameter) => {
    try {
      const response = await getInvestorsinFundReportService(data);
      console.log("res", response);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "investorsinFundReport",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(getInvestorsinFundCommits.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getInvestorsinFundCommits.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload
        ? action.payload._response.data?.investorList
        : [];
      state.totalCount = action.payload
        ? action.payload._response.data?.count
        : [];
    });
    builder.addCase(getInvestorsinFundCommits.rejected, (state, action) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
