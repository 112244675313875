import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { addInvestmentStep1Service } from "../../../../services/investment/investment";
import { getSoftCommitListService } from "../../../../services/investment/investment";
import error from "../../../../utils/error";
import { ISoftCommitState } from ".";

const initialState: ISoftCommitState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: {
    softCommits: [],
    total: [],
    cashbalance: [],
  },
};
export const getThunkAllSoftCommitList = createAsyncThunk(
  "getSoftCommitList",
  async (data: any) => {
    try {
      const response = await getSoftCommitListService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "getSoftCommitList",
  initialState,
  reducers: {
    setSoftCommitForm: (state, action) => {
      console.log("op", action.payload);
      state.data.softCommits = action.payload.softCommits;
      state.data.total = action.payload.total;
      state.data.cashbalance = action.payload.cashbalance;

      //
    },
  },
  extraReducers(builder): void {
    // Investment
    builder.addCase(getThunkAllSoftCommitList.pending, (state) => {
      state.loading = "loading";
      // state.error = undefined;
    });
    builder.addCase(getThunkAllSoftCommitList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("s", action.payload);
      if (action.payload.data !== null) {
        state.data.softCommits = action.payload.data.softCommits;
        state.data.cashbalance = action.payload.data.cashbalance;
        state.data.total = action.payload.data.total[0].total;
      } else {
        state.data.softCommits = [];
        state.data.cashbalance = [];
        state.data.total = 0;
      }
      state.message =
        action.payload.message === null ? undefined : action.payload.message;

      console.log("state.data 1", state.data);
    });
    builder.addCase(getThunkAllSoftCommitList.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});
export const { setSoftCommitForm } = slice.actions;
export default slice.reducer;
