import { useMsal } from "@azure/msal-react";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef } from "react";
import { getThunkColor } from "../../../../store/features/color/slice";
import {
  getFundImageThunk,
  getFundLogoThunk,
} from "../../../../store/features/profile/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { envVariable } from "../../../../utils/sponsor/helper";

const AccessDenied = () => {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();

  //   const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  // const [logo, setLogo] = useState("");
  // const [sideImage, setSideImage] = useState("");
  // const [loader, setLoader] = useState(false);
  // const sasKey = useAppSelector((state) => state.sasKey.data);
  const sideLogo = useAppSelector((state) => state.profile.sideLogo);
  const fundImage = useAppSelector((state) => state.profile.fundImage);

  const slug = localStorage.getItem("slug") ? localStorage.getItem("slug") : "";
  localStorage.removeItem("slug");

  console.log("instance", instance, slug);

  useEffect(() => {
    dispatch(getFundLogoThunk());
    dispatch(getFundImageThunk());
    dispatch(getThunkColor()).then((res: any) => {
      console.log("res", res);
      const color = res.payload.response?.data?.primaryColour;
      // console.log("color", color);
      const root = document.documentElement;
      if (color) {
        // setLoader(false);
        root?.style.setProperty("--background-color", color);
      } else {
        root?.style.setProperty("--background-color", "#004d82");
      }
    });
    // const tenant = localStorage.getItem("tenentName");
    // dispatch(getSasKeyThunk(tenant!)).catch(() => {
    //   toast.current?.show({
    //     severity: "error",
    //     summary: "Error",
    //     detail: "Oops! Something Went Wrong ",
    //     life: 3000,
    //   });
    // });
  }, []);

  // useEffect(() => {
  //   console.log("useEffect sasKey", sasKey);
  //   if (sideLogo && sasKey) {
  //     setLogo(sideLogo + sasKey);
  //   }
  //   if (fundImage && sasKey) {
  //     setSideImage(fundImage + sasKey);
  //   }
  // }, [sideLogo, fundImage, sasKey]);

  return (
    <>
      <Toast ref={toast} className="themeToast" />
      {/* {loader && <Loader />} */}
      {/* {loader ? (
        <ProgressSpinner />
      ) : ( */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 px-0">
            <div className="default-form-body">
              <div className="loginform-logo">
                <img className="img-fluid py-1 fundLogo" src={sideLogo} />
              </div>
              <div className="formwrap">
                <div className="formCont">
                  <div className="panel-default">
                    <div className="panel-body">
                      <div className="enrollmentTitle">
                        {/* <p className="">
                        You have not been granted access to this fund.
                      </p>
                      <p>
                        Please contact support@avestorinc.com for further
                        assistance
                      </p> */}
                        <h1 role="heading" className="mainHeader">
                          You have not been granted access to this fund.
                        </h1>
                        <br />
                        <br />
                        <h1 role="heading" className="mainHeader">
                          Click here to{" "}
                          <a
                            href={`${envVariable.REACT_APP_API_SPONSOR_BASEURL}${slug}`}
                          >
                            try
                          </a>{" "}
                          again
                        </h1>
                        <br />
                        <br />
                        <h1 role="heading" className="mainHeader">
                          Please contact{" "}
                          <a href="mailto:support@avestorinc.com">
                            support@avestorinc.com
                          </a>{" "}
                          for further assistance.{" "}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-content-center d-flex align-self-end pb-4">
                <a
                  href="https://www.avestorinc.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    className="img-fluid avestorabslogo"
                    src={envVariable.REACT_APP_LOGO}
                    // src="https://b2cflowav.blob.core.windows.net/b2c/common/avestor-logo.png"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 loginSideImgWrap">
            <img src={fundImage} />
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};
export default AccessDenied;
