import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestingAccountsLookupsService } from "../../../../services/investor/investor";

import error from "../../../../utils/error";
import {
  IInvestorAccountDropdown,
  IIsoCountriesDropdown,
  IStateDropdown,
} from ".";

interface InvestingAccountsLookups {
  Data?: string;
  loading: string;
  error?: string | null;
  accountTpesDropdown?: IInvestorAccountDropdown[] | [];
  countriesDropdown?: IIsoCountriesDropdown[] | [];
  stateDropdown?: IStateDropdown[] | [];
  callCode?: any;
}

const initialState: InvestingAccountsLookups = {
  Data: undefined,
  accountTpesDropdown: [],
  countriesDropdown: [],
  callCode: undefined,
  stateDropdown: [],
  loading: "loading",
  error: undefined,
  // callCode: undefined,
};

export const getInvestingAccountsLookupsThunk = createAsyncThunk(
  "investor/investor-approval/InvestingAccountsLookups",
  async () => {
    try {
      const response = await getInvestingAccountsLookupsService();
      return response.data;
    } catch (error_) {
      throw new Error(error(error_));
    }
  }
);
const slice = createSlice({
  name: "investor/investor-approval/InvestingAccountsLookups",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    builder.addCase(getInvestingAccountsLookupsThunk.pending, (state) => {
      state.loading = "loading";
      state.error = undefined;
    });
    builder.addCase(
      getInvestingAccountsLookupsThunk.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        if (
          action.payload._response.data?.isoCountries &&
          action.payload._response.data?.isoCountries?.length > 0
        ) {
          const result: any = {};

          for (const entry of action.payload._response.data?.isoCountries) {
            result[entry.key] = entry.callCode;
          }
          state.callCode = result;
        }
        state.accountTpesDropdown = action.payload._response.data?.accountTpes
          ? action.payload._response.data?.accountTpes
          : [];
        state.countriesDropdown = action.payload._response.data?.isoCountries
          ? action.payload._response.data?.isoCountries
          : [];

        state.stateDropdown = action.payload._response.data?.usStates
          ? action.payload._response.data?.usStates
          : [];
      }
    );
    builder.addCase(
      getInvestingAccountsLookupsThunk.rejected,
      (state, action) => {
        state.loading = "failed";
        // action.payload contains error information
        state.error = error(action.payload);
      }
    );
  },
});

export default slice.reducer;
