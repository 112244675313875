import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestorsVerifiedByAccreddService } from "../../../../services/reports/reports";
import error from "../../../../utils/error";
import {
  IGetInvestorsVerifiedByAccreddParameter,
  IGetInvestorsVerifiedByAccreddState,
} from ".";

const initialState: IGetInvestorsVerifiedByAccreddState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const GetInvestorsVerifiedByAccreddReport = createAsyncThunk(
  "GetInvestorsVerifiedByAccredd",
  async (data: IGetInvestorsVerifiedByAccreddParameter) => {
    try {
      const response = await getInvestorsVerifiedByAccreddService(data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "GetInvestorsVerifiedByAccredd",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(GetInvestorsVerifiedByAccreddReport.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(
      GetInvestorsVerifiedByAccreddReport.fulfilled,
      (state, action) => {
        state.loading = "succeeded";
        state.data =
          action.payload._response.data !== null
            ? action.payload._response.data
            : [];
      }
    );
  },
});

export default slice.reducer;
