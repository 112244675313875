export const errorToastMessage = (
  toast: any,
  message = "Oops! Something Went Wrong"
) => {
  toast.current?.show({
    severity: "error",
    summary: "Error",
    detail: message,
    life: 3000,
  });
};

export const successToastMessage = (toast: any, message = "") => {
  toast.current?.show({
    severity: "success",
    summary: "Success",
    detail: message,
    life: 3000,
  });
};

export const infoToastMessage = (
  toast: any,
  message = "",
  summaryMessage = ""
) => {
  toast.current?.show({
    severity: "info",
    summary: summaryMessage,
    detail: message,
    life: 3000,
  });
};
