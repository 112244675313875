// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { GetK1PartnerInformationThunk } from "../../../store/features/taxmanagement/partnerinformation/getk1partnerinformation/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import dateFormatter from "../../../utils/date-formatter";
import { exportExcel } from "../../../utils/sponsor/helper";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";
export default function PartnerInformation() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const portState = useAppSelector(
    (state) => state.GetK1PartnerInformation.loading
  );
  const getRevision = useAppSelector((state) => state.GetRevisionList.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [openDailog, setOpenDailog] = useState(false);

  console.log("getRevision", getRevision);
  const GetMenuList = useAppSelector(
    (state) => state.GetK1PartnerInformation?.data
  );

  const dataTableElements = [
    {
      field: "firstName",
      header: "First Name",
      style: { minWidth: "250px" },
    },
    {
      field: "lastName",
      header: "Last Name",
      style: { minWidth: "250px" },
    },
    {
      field: "accountNickName",
      header: "Investing Account Name",
      style: { minWidth: "250px" },
    },
    {
      field: "ein",
      header: "EIN",
      style: { minWidth: "250px" },

      //   body: (rowData: any) => dateFormatter(new Date(rowData.effectiveDate)),
    },
    {
      field: "taxPayerId",
      header: "TaxPayer Id",
      style: { minWidth: "250px" },
    },
    {
      field: "Business Name",
      header: "Business Name",
      style: { minWidth: "250px" },
    },
    {
      field: "address",
      header: "Address",
      style: { minWidth: "360px" },
    },
    {
      field: "legalEntityName",
      header: "Legal Entity Name",
      style: { minWidth: "250px" },
    },
    {
      field: "dateCreated",
      header: "Date Joined Partnership",
      body: (rowData: any) => dateFormatter(new Date(rowData.dateCreated)),
      style: { minWidth: "250px" },
    },
    {
      field: "custodianName",
      header: "Custodian Name",
      style: { minWidth: "250px" },
    },
    {
      field: "accountType",
      header: "Account Type",
      style: { minWidth: "250px" },
    },
    {
      field: "custodianAddress",
      header: "Custodian Address",
      style: { minWidth: "250px" },
    },
  ];

  useEffect(() => {
    if (fundDropdown.fundid) {
      const param = {
        LegalEntityId: fundDropdown.fundid,
      };
      console.log("info");
      dispatch(GetK1PartnerInformationThunk(param));
    }
  }, [fundDropdown.fundid]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">K-1: Part 1-Partner Information</h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(GetMenuList, "K-1: Part 1-Partner Information")
              }
              data-pr-tooltip="exportExcel"
              disabled={
                GetMenuList === undefined ||
                GetMenuList?.length === 0 ||
                GetMenuList === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  GetMenuList === undefined ||
                  GetMenuList?.length === 0 ||
                  GetMenuList === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <div>
              {portState === "loading" ? (
                <Loader />
              ) : (
                <DataTableComponent
                  valueData={GetMenuList!}
                  fieldsElements={dataTableElements}
                  isPaginationAllowed={true}
                  isDownload={true}
                  fileName="K1 Part 1 Summary"
                  scrollable={true}
                  className="gridcell"
                  columnResizeMode="expand"
                  maxHeight={"calc(-165px + 100vh)"}
                  isFullView={true}
                  setOpenDailog={setOpenDailog}
                  openDailog={openDailog}
                />
              )}
            </div>
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
