import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../components/loader/Loader";
import { GetGPPartnerThunk } from "../../store/features/accounting/debitcreditinvestoraccounts/GetGPPartner/slice";
import { SavePartnerAccountTransactionThunk } from "../../store/features/accounting/debitcreditinvestoraccounts/savepartneraccounttransaction/slice";
import { GetTransactionTypeThunk } from "../../store/features/accounting/debitcreditinvestoraccounts/transactiontype/slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addPoolFundsActions } from "../../store/poolfunds/add-pool-funds-state";
import { getAllInvestorListPoolFunds } from "../../store/poolfunds/get-all-Investor-list-pool-funds";
import { convertLocalDateToUTC } from "../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../utils/toast-message";

interface IProps {
  openDialog: any;
  setOpenDialog: any;
  lpPartnerId: any;
  setLpPartnerId: any;
}

const AddMoney: React.FC<IProps> = ({
  openDialog,
  setOpenDialog,
  lpPartnerId,
  setLpPartnerId,
}) => {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const addPoolFundData = useAppSelector((state) => state.AddPoolFunds.data);

  const investorList = useAppSelector((state) => state.registerinvestor?.data);
  const transactionType = useAppSelector(
    (state) => state.GetTransactionType?.data
  );
  const formik = useFormik({
    initialValues: {
      Date: "",
      Remark: "",
      transactionType: "",
      inv: "",
      partner: "",
      Withdraw: undefined,
      Deposit: undefined,
      productName: "",
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter transaction date";
      }
      if (!data.transactionType) {
        errors.type = "Please select transaction type";
      }
      if (!data.inv) {
        errors.inv = "Please select investor account";
      }
      if (!data.Deposit) {
        errors.Deposit = "Enter deposit amount";
      }

      if (data.Deposit && data.Deposit > 20_000_000_000) {
        errors.Deposit =
          "The deposit amount should be less than or equal to $20B";
      }

      return errors;
    },
    onSubmit: async (data) => {
      setLoading(true);

      const param = {
        lpPartnerID: +formik.values.inv,
        transactionTypeID: +formik.values.transactionType,
        credit: formik.values.Deposit ? formik.values.Deposit : 0,
        debit: formik.values.Withdraw ? formik.values.Withdraw : 0,
        transactionDate: convertLocalDateToUTC(formik.values.Date),
        notes: formik.values.Remark,
        loginId: profileData.userID,
      };

      dispatch(SavePartnerAccountTransactionThunk(param)).then((res: any) => {
        if (res?.payload) {
          successToastMessage(
            toast,
            res?.payload?.message || "Data saved successfully"
          );
          formik.resetForm();
          formik.setFieldValue("Date", new Date().toISOString());
          formik.setFieldValue(
            "transactionType",
            transactionType ? transactionType[0].key : ""
          );
          setLpPartnerId(0);
          setLoading(true);
          const allocationData = {
            legalEntityId: fundDropdown.fundid,
            investmentId: addPoolFundData.investmentId,
          };

          dispatch(getAllInvestorListPoolFunds(allocationData)).finally(() => {
            setLoading(false);
            setOpenDialog(false);
            dispatch(
              addPoolFundsActions.setAddPoolFundData({
                ...addPoolFundData,
                investorList: [],
              })
            );
          });
        } else {
          errorToastMessage(
            toast,
            res?.payload?.message || "Something went wrong"
          );
          formik.resetForm();
          setLoading(false);
          setOpenDialog(false);
          dispatch(
            addPoolFundsActions.setAddPoolFundData({
              ...addPoolFundData,
              investorList: [],
            })
          );
        }
      });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      setLoading(true);
      const reqParam = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetGPPartnerThunk(reqParam));
      dispatch(GetTransactionTypeThunk()).finally(() => {
        setLoading(false);
      });
    }
  }, [fundDropdown.fundid]);
  useEffect(() => {
    if (transactionType && investorList) {
      formik.setFieldValue("Date", new Date().toISOString());
      formik.setFieldValue("transactionType", transactionType[0].key);
      formik.setFieldValue("inv", lpPartnerId);
    }
  }, [investorList, lpPartnerId]);

  const onCancel = () => {
    setOpenDialog(false);
    formik.resetForm();
    setLpPartnerId(0);
    formik.setFieldValue("Date", new Date().toISOString());
    formik.setFieldValue(
      "transactionType",
      transactionType ? transactionType[0].key : ""
    );
  };

  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      <Dialog
        visible={openDialog}
        onHide={() => onCancel()}
        className="w-100 md:w-6 pb-1"
        headerClassName="no-header"
      >
        <div className="mt-2">
          <div className="mt-2">
            <div className="formgrid grid px-2 col">
              <div className="d-block sm:d-flex sm:flex-column p-2 col-6 ">
                <label className="inputLabel">Transaction Type *</label>
                <Dropdown
                  name="type"
                  style={{ width: "100%" }}
                  options={transactionType}
                  optionLabel="value"
                  optionValue="key"
                  placeholder="Select.."
                  disabled
                  value={formik.values.transactionType}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("transactionType"),
                    "card-inputBox": true,
                  })}
                />

                {getFormErrorMessage("transactionType")}
              </div>

              <div className="d-block sm:d-flex sm:flex-column p-2 col-6 ">
                <label className="inputLabel">Investor Account *</label>
                <Dropdown
                  name="inv"
                  style={{ width: "100%" }}
                  options={investorList}
                  optionLabel={"investorName"}
                  optionValue={"investorID"}
                  placeholder="Select.."
                  disabled
                  value={formik.values.inv}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("inv"),
                    "card-inputBox": true,
                  })}
                />
                {getFormErrorMessage("inv")}
              </div>

              <div className={"d-block sm:d-flex sm:flex-column p-2 col-4   "}>
                <label className="inputLabel">Deposit *</label>
                <InputNumber
                  name="Deposit"
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Deposit"),
                    "w-100": true,
                  })}
                  value={formik.values.Deposit}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  onValueChange={(e) => {
                    formik.setFieldValue("Deposit", e.target.value);
                  }}
                />
                {getFormErrorMessage("Deposit")}
              </div>

              <div className="d-block sm:d-flex sm:flex-column p-2 col-4">
                <label className="inputLabel">Transaction Date *</label>
                <Calendar
                  name="Date"
                  value={
                    formik.values.Date === null || formik.values.Date === ""
                      ? undefined
                      : new Date(formik.values.Date)
                  }
                  onChange={(e) => formik.setFieldValue("Date", e.target.value)}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Date"),
                    "card-inputBox": true,
                  })}
                  placeholder="mm/dd/yyyy"
                />
                {getFormErrorMessage("Date")}
              </div>
              <div className="d-block sm:d-flex sm:flex-column p-2 col-4">
                <label className="inputLabel">Remark</label>

                <InputText
                  name="Remark"
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Remark"),
                    "card-inputBox": true,
                  })}
                  onChange={(e) =>
                    formik.setFieldValue("Remark", e.target.value)
                  }
                  value={formik.values.Remark}
                />
                {getFormErrorMessage("Remark")}
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap sm:flex-none p-2 col-12 justify-content-center ">
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-nav"
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
              >
                <span className="ml-auto mr-auto ">Cancel</span>
              </Button>
            </div>
            <div className="d-flex flex-column p-2 col-12 sm:col-4 ">
              <Button
                className="btn-navActive"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <span className="ml-auto mr-auto ">Save</span>
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default AddMoney;
