import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BlueSkyStateDropdownService } from "../../../../../services/sponser/sponser";
import error from "../../../../../utils/error";
import {
  IBindBlueSkyStateDropdownState,
  IBindBlueSkyStateDropdownparameters,
} from ".";

const initialState: IBindBlueSkyStateDropdownState = {
  success: false,
  message: "",
  loading: "loading",
  data: undefined,
};
export const BlueSkyStateDropdownThunk = createAsyncThunk(
  "Sponser/BindBlueSkyStateDropdown",
  async (data: IBindBlueSkyStateDropdownparameters) => {
    try {
      const response = await BlueSkyStateDropdownService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "Sponser/BindBlueSkyStateDropdown",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(BlueSkyStateDropdownThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(BlueSkyStateDropdownThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data : [];
    });
    builder.addCase(BlueSkyStateDropdownThunk.rejected, (state) => {
      state.loading = "failed";
      //   // action.payload contains error information
      //   state.error = error(action.payload);
    });
  },
});

export default slice.reducer;
